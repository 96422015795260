import { useMemo } from "react";

export const useGetDataValues = (payload: any) => {
    
    const dataSetValues = useMemo(() => {
        let {firstDatasetValue, secondDatasetValue, firstDatasetColor, secondDatasetColor, thirdDatasetColor, thirdDatasetValue, percentage, isGoalCompletetion} = payload;

        let dataValues = [
            ...(firstDatasetValue ? [firstDatasetValue] : []), 
            ...(secondDatasetValue ? [secondDatasetValue] : []),
            ...(thirdDatasetValue ? [thirdDatasetValue] : [])
        ];
        let backgroundColor = [
            ...(firstDatasetValue ? [firstDatasetColor || '#3B792F'] : firstDatasetValue === undefined ? ['#3B792F'] : []), 
            ...(secondDatasetValue ? [secondDatasetColor || '#C44538'] : secondDatasetValue === undefined ? ['#C44538'] : []), 
            ...(thirdDatasetValue ? [thirdDatasetColor || '#EADB4A'] : [])
        ];

        const isOnlyPercentage = !firstDatasetValue && !secondDatasetValue && percentage && (!isGoalCompletetion ? true : !thirdDatasetValue);
        if(isOnlyPercentage){
            dataValues = [percentage, 100-percentage];
        }

        const isNotData = !firstDatasetValue && !secondDatasetValue && !percentage && (!isGoalCompletetion ? true : !thirdDatasetValue);
        if(isNotData){
            dataValues = [0, 100]
            backgroundColor = ['#707070'];
        }

        if(
            (
                (!firstDatasetValue && secondDatasetValue) ||
                (!firstDatasetValue && thirdDatasetValue) ||
                (!secondDatasetValue && firstDatasetValue) ||
                (!secondDatasetValue && thirdDatasetValue) ||
                (!thirdDatasetValue && firstDatasetValue) ||
                (!thirdDatasetValue && secondDatasetValue)
            ) && 
            percentage
        ){
            backgroundColor = [
                ...(firstDatasetValue ? [firstDatasetColor || '#3B792F'] : []), 
                ...(secondDatasetValue ? [secondDatasetColor || '#C44538'] : []),
                ...(thirdDatasetValue ? [thirdDatasetColor || '#EADB4A'] : [])
            ];
        }
        
        return {
            dataValues,
            backgroundColor,
            isNotData
        }
        
    },[payload]);

    return dataSetValues;
}