import Skeleton from "react-loading-skeleton";

const ReportCardSkeleton = ({ loading, i }: any) => {
  return (
    <>
      <tr
        className={i % 2 === 0 ? "progress__table-row skeleton" : "progress__table-row skeleton"}
      >
        {/* <td style={{ width: "50px" }}></td> */}
        <td className="className-data">
          <Skeleton
            baseColor="#00000033"
            highlightColor="#737373"
            width={"90%"}
            height={19}
            borderRadius={8}
            style={{ marginTop: 1 }}
          />
        </td>

        <td className="date">
          <Skeleton
            baseColor="#00000033"
            highlightColor="#737373"
            width={70}
            height={19}
            borderRadius={8}
          />
        </td>
        <td className="assesment-data">
          <Skeleton
            baseColor="#00000033"
            highlightColor="#737373"
            width={70}
            height={19}
            borderRadius={8}
          />
        </td>
        <td className="assesment-data">
          <Skeleton
            baseColor="#00000033"
            highlightColor="#737373"
            width={70}
            height={19}
            borderRadius={8}
          />
        </td>
        <td className="assesment-data">
          <Skeleton
            baseColor="#00000033"
            highlightColor="#737373"
            width={70}
            height={19}
            borderRadius={8}
          />
        </td>
        <td className="assesment-data">
          <Skeleton
            baseColor="#00000033"
            highlightColor="#737373"
            width={70}
            height={19}
            borderRadius={8}
          />
        </td>

        {/* <td style={{ width: "100px" }}></td> */}
      </tr>
    </>
  );
};

export default ReportCardSkeleton;
