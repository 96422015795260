import ActionType from "redux/actionTypes"
import { RootState } from "./combine"

interface video {

}

interface InitialState {
    videos: video[],
    videoDetails: any;
    videosLoading: boolean;
}
const initialState: InitialState = {
    videos: [],
    videoDetails: {},
    videosLoading: true
}

const MasteryVideoReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case ActionType.SET_MASTERY_VIDEOS_LOADING:
            return {
                ...state,
                videosLoading: true
            }
        case ActionType.GET_MASTERY_VIDEOS_SUCCESS:
            return {
                ...state,
                videos: action.payload?.videos,
                videosLoading: false
            }
        case ActionType.GET_MASTERY_VIDEOS_ERROR:
            return {
                ...state,
                videosLoading: false
            }
        case ActionType.GET_MASTERY_VIDEO_DETAILS_SUCCESS:
            return {
                ...state,
                videoDetails: action.payload
            }
            
        case ActionType.ADD_MASTERY_VIDEO_COMMENT_SUCCESS:
            return {
                ...state,
                videoDetails: action.payload
            }
        default:
            return state
    }
}

export default MasteryVideoReducer

export const masteryVideosLoadingSelector = (state: RootState) => state.masteryVidoes.videosLoading
export const allMasteryVideosSelector = (state: RootState) => state.masteryVidoes.videos
export const masteryVideoDetailsSelector = (state: RootState) => state.masteryVidoes.videoDetails