export const monthMapper = {
    January: 'JANUARY',
    February: 'FEBRUARY',
    March: 'MARCH',
    April: "APRIL",
    May: "MAY",
    June: "JUNE",
    July: "JULY",
    August: "AUGUST",
    September: "SEPTEMBER",
    October: "OCTOBER",
    November: "NOVEMBER",
    December: "DECEMBER"
}


export const monthToNumberMapper = {
    January: '01',
    February: '02',
    March: '03',
    April: "04",
    May: "05",
    June: "06",
    July: "07",
    August: "08",
    September: "09",
    October: "10",
    November: "11",
    December: "12"
}