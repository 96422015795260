import { monthToNumberMapper } from "./monthMapper";

export const createDate = (allMonths: any, index: number, date: string) => {
    const dateData = allMonths.length > 0 ? allMonths[index] : null;

    if(!dateData || !date){
        return null;
    }

    const calendarDate = date.split(' ').pop();
    const twoDigitDate = calendarDate?.length === 1 ? `0${calendarDate}` : calendarDate;
    const {month, year} = dateData;
    const monthNumber = monthToNumberMapper[month as keyof typeof monthToNumberMapper];
    const dateString = `${year}-${monthNumber}-${twoDigitDate}`.trim();

    return dateString;
}