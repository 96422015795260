/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import SearchIconGray from "assets/img/search-icon-gray.svg";
import ReportHeader from "components/common/report/header";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { DetailsInfoSection } from "./infoSection";
import Record from "./detailsRecord";
import StudentTableHeader from "./objectiveTableHeader";
import { DetailSkeleton } from "components/common/skeleton/ReportsSkeleton";
import ReactDOMServer from "react-dom/server";
import html2pdf from "html2pdf.js";
import DownloadPdf from "./Download/index";

const BasedReportDetails = (props: any) => {
  const [search, setSearch] = useState<string>("");
  const {
    setLang,
    data,
    setDetails,
    lang,
    handlePrint,
    isLoading,
    classroom_name,
    detailsRef,
    setDescriptionRowsHeights,
    answerRowsHeights,
  } = props;
  const { objectives, student_name } = useMemo(() => {
    if (props?.data) {
      return {
        ...props.data,
      };
    }
    return {
      objectives: [],
      student_name: "",
      student_id: "",
      id: 0,
    };
  }, [props]);

  useEffect(() => {
    return () => setDetails(null);
  }, []);

  useEffect(() => {
    const data = objectives;
    if (data?.length && detailsRef?.current) {
      setDescriptionRowsHeights(
        [...detailsRef.current.childNodes].map(
          (node: any) => node.offsetHeight * 0.68
        )
      );
    }
  }, [isLoading]);
  const information = useMemo(() => {
    if (search) {
      return objectives.filter(
        (record: any) =>
          record?.objective_number
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          record[
            lang === "en" ? "objective_description" : "objective_description_es"
          ]
            ?.toLowerCase()
            .startsWith(search.toLowerCase())
      );
    }
    return objectives;
  }, [objectives, search]);

  const onBack = () => {
    sessionStorage.removeItem("std-details");
    sessionStorage.removeItem("std-lang");
    setDetails(null);
    setLang("en");
  };

  const genPdf = () => {
    const htmlString = ReactDOMServer.renderToStaticMarkup(
      <DownloadPdf
        data={objectives}
        classroom_name={classroom_name}
        lang={lang}
        test_name={`Student: ${student_name}`}
        answerRowsHeights={answerRowsHeights}
      />
    );

    const opt = {
      margin: [5, 10, 5, 10],
      filename: `StandardBasedReport_${student_name}_${moment(
        new Date()
      ).format("M/DD/YY hh:mmA")}.pdf`,
      html2canvas: { scale: 2, letterRendering: true },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      jsPDF: { orientation: "p", unit: "mm", format: [216, 279] },
    };

    html2pdf()
      .from(htmlString)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then(function (pdf) {
        var totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.setFontSize(6);
          pdf.text(
            `© ${new Date().getFullYear()} Alpha Plus Educational Systems`,
            pdf.internal.pageSize.getWidth() - 120,
            pdf.internal.pageSize.getHeight() - 8
          );
        }
      })
      .save();
  };

  return (
    <div className="based-report hide-on-print">
      <ReportHeader
        setPrint={handlePrint}
        reportTitle="Standard Based Report"
        testName={`Student: ${student_name}`}
        onBack={onBack}
        downloadPdf={genPdf}
        standardBasedDetails={{ ...data, lang }}
        headerClassName="based-header"
      />
      <div className="based-report-container">
        <div className="based-report__info">
          <div className="based-report__info-search">
            {!isLoading && (
              <img
                src={SearchIconGray}
                className="report__search-icon animated-fade"
                alt="Search"
              />
            )}
            <input
              className={`form-control report__search-input ${
                isLoading ? "margin-left" : ""
              }`}
              type="search"
              name="Search"
              placeholder="Search Objectives"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <DetailsInfoSection />
        </div>

        <div className="based-report__main based-report-main">
          <div className="based-report-detail-container">
            <table lassName="based-report__detail">
              <StudentTableHeader />
              <tbody ref={detailsRef}>
                {!isLoading && information?.length === 0 && (
                  <tr className="based-report__detail-row">
                    <td className="report__table-notFound w-100 text-center">
                      No Objectives Found
                    </td>
                  </tr>
                )}
                {!isLoading ? (
                  information?.map((info: any) => {
                    return <Record {...info} lang={lang} />;
                  })
                ) : (
                  <DetailSkeleton rows={20} />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BasedReportDetails;
