import { triggerGoalWithClassroom } from "services/common";
import {
  getRemmediationReportData,
  getTeamMeetingData,
  triggerProfessionalDevelopmentGoal,
} from "services/professionalDevelopment";
import { PDFDocument } from "pdf-lib";
import { getPdfData } from "util/getPdfData";
import { GOALS_CONDITIONS } from "util/constants";
import { generateRemmediationReportData } from "util/getRemmediationReportData";
import Conatiner from "components/common/goalCard/container";
import Heading from "components/common/goalCard/heading";
import BodyContainer from "components/common/goalCard/body";
import Description from "components/common/goalCard/description";
import GoalsList from "components/common/goalCard/goalsList";

interface Task {
  id: number;
  checked: boolean;
  task_action_name: string;
  task_action_value: { url: string };
  task_condition_value: {
    key: any;
    name: string;
  };
  task_name: string;
  task: string;
}
interface IProps {
  goalId: number;
  heading?: string;
  description?: string;
  tasks: Task[];
  onClickGoal: any;
  updateGoalTask: any;
  completedBy?: string;
  isDisabled?: boolean;
  shortDescription?: string;
  executeAction?: boolean
}

const Card = (props: IProps) => {
  const {
    heading,
    description,
    onClickGoal,
    tasks,
    goalId,
    updateGoalTask,
    completedBy,
    isDisabled,
    shortDescription = "",
    executeAction = true
  } = props;

  const goToExternalUrl = (link: string) => {
    if (!link) {
      return;
    }

    // const alink = document.createElement('a');
    const downloadLink =
      link.startsWith("https") || link.startsWith("http") ? link : `//${link}`;
    window.open(downloadLink);
    // alink.click();
  };

  const onclickPdf = async (label: string) => {
    const classroomId = sessionStorage.getItem("c_classroom_id");
    if (!classroomId) {
      return;
    }

    const teamMeetingData = await getTeamMeetingData(parseInt(classroomId));
    if (!teamMeetingData) {
      console.log("team meeting data not found!");
      return;
    }

    const pdfTeamData = getPdfData(teamMeetingData);

    const url = "/Data_Team_Meeting_Worksheet_24-25.pdf";

    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    pdfTeamData.forEach((data: any) => {
      const { x, y, text, size } = data;
      firstPage.drawText(text, {
        x,
        y,
        size,
      });
    });

    const pdfBytes = await pdfDoc.save();
    const file = new Blob([pdfBytes], { type: "application/pdf" });

    const fileURL = URL.createObjectURL(file);

    const alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = `${label}` || "data_team_worksheet.pdf";
    alink.click();

    triggerGoalWithClassroom({
      classroomId: parseInt(classroomId),
      conditionName: GOALS_CONDITIONS.Generate_DTM_Worksheet,
    });
  };

  const getUrlFromTarget = (task: any) => {
    const { task_action_value, task_condition_value } = task;
    // const _task: any = 'summative-a1-mark-completed-toggle'
    // task_action_value['target']
    switch (task_action_value["target"]) {
      case "summative_a1_available_to_students_toggle":
        return `/classroom?studentAvailableTooltip=true&text=${task_action_value["text"]}`;
      case "summative-a1-mark-completed-toggle":
        return `/classroom?summativeA1MarkCompleted=true&text=${task_action_value["text"]}`;
      case "first-incomplete-formative":
        return `/classroom?firstIncompleteFormative=true&text=${task_action_value["text"]}`;
      case "formative-mark-completed":
        return `/classroom?formativeMarkCompleted=true&text=${task_action_value["text"]}&offset=${task_condition_value["key"]?.x}`;
      case "summative-b-availability-toggle":
        return `/classroom?summativeBAvailabilityToggle=true&text=${task_action_value["text"]}`;
      case "summative-b-mark-completed-toggle":
        return `/classroom?summativeBMarkCompleted=true&text=${task_action_value["text"]}`;
      case "summative-a2-mark-completed-toggle":
        return `/classroom?summativeA2MarkCompleted=true&text=${task_action_value["text"]}`;
      case "summative_a2_available_to_students_toggle":
        return `/classroom?summativeA2AvailableToggle=true&text=${task_action_value["text"]}`;
      case "openMasterReport":
        return `/classroom?openMasterReport=true&text=${task_action_value["text"]}`;
      default:
        return "";
    }
  };

  const downloadDTMRemmediationReport = async (objectiveId: number) => {
    const _classroomId = sessionStorage.getItem("c_classroom_id");
    const classroomId = _classroomId ? parseInt(_classroomId) : 0;
    const remmediationReportData = await getRemmediationReportData(
      classroomId,
      objectiveId
    );

    if (!remmediationReportData) {
      console.log("something went wrong in remmediation report API");
    } else {
      const url = "/DTM_Worksheet-Remediation_Report_fPDF-1_5.pdf";

      const existingPdfBytes = await fetch(url).then((res) =>
        res.arrayBuffer()
      );
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      const pages = pdfDoc.getPages();
      const firstPage = pages[0];

      const remmediatedReport = generateRemmediationReportData(
        remmediationReportData
      );

      remmediatedReport.forEach((data: any) => {
        const { x, y, text, size } = data;
        firstPage.drawText(text, {
          x,
          y,
          size,
        });
      });

      const pdfBytes = await pdfDoc.save();
      const file = new Blob([pdfBytes], { type: "application/pdf" });

      const fileURL = URL.createObjectURL(file);

      const alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = "remmediation_worksheet.pdf";
      alink.click();
    }
  };

  const onClickTask = async (task: any) => {
    if(!executeAction){
        return;
    }

    const {
      task_condition_value,
      task_action_name,
      task_action_value,
      checked,
      task_condition_name,
      id,
    } = task;
    const _classroomId = sessionStorage.getItem("c_classroom_id");
    const classroomId = _classroomId ? parseInt(_classroomId) : 0;

    // const _schoolId = sessionStorage.getItem("c_school_id");
    // const schoolId = _schoolId ? parseInt(_schoolId) : 0;

    let hasCase = false;
    const taskName = task_action_name?.toLowerCase();
    switch (taskName) {
      case "download file":
      case "open file pdf":
      case "open file":
        hasCase = !!task_action_value["url"];
        hasCase && goToExternalUrl(task_action_value["url"]);
        break;
      case "open mastery video":
        // hasCase = !!task_action_value['video-id']
        !!task_action_value["video-id"] &&
          window.open(
            `/mastery-videos?videoId=${task_action_value["video-id"]}`
          );
        break;
      case "open pacing calendar":
        // hasCase = true
        window.open(`/pacing-calendar?task_id=${id}&goal_id=${id}`);
        break;
      case "open w2a report":
        hasCase = true
        window.open(
          `/classroom/report/way-to-report/${classroomId}?task_id=${task.id}&goal_id=${goalId}`
        );
        break;
      case "open master report":
        hasCase = true
        const myWind: any = window.open(
          `/classroom/report/master-report/${classroomId}?task_id=${task.id}&goal_id=${goalId}`
        );
        if (myWind) {
          myWind["classroomId"] = classroomId;
        }
        break;
      case "open oag":
        hasCase = true
        const oagWindow: any = window.open(
          `/classroom/report/oag-report/${classroomId}`
        );
        if (oagWindow) {
          oagWindow["classroomId"] = classroomId;
        }
        break;
      case "open summative irr":
        const summativeId = task_action_value["value"];
        // hasCase = !!summativeId
        if(summativeId){
          hasCase = true;
          const irrSummativeWindow:any = window.open(
            `/classroom/report/item-response-report-summative/${summativeId}/${classroomId}?task_id=${task.id}&goal_id=${goalId}`
          );
          irrSummativeWindow['classroomId'] = classroomId;
        }
        break;
      case "open formative irr":
        const objectiveId = task_action_value["objective"];
        if(objectiveId){
          hasCase = true;
          const irrWindow:any = window.open(`
            /classroom/report/item-response-report-formative/${objectiveId}/${classroomId}?task_id=${task.id}&goal_id=${goalId}
          `);

          irrWindow['classroomId'] = classroomId;
        }
        break;
      case "generate dtm worksheet":
        hasCase = true;
        onclickPdf("data_team_meeting_report");
        break;
      case "open standards based report":
      case "open sbr in new tab":
        hasCase = true
        const sbrWindow:any = window.open(
          `${window.location.origin.toString()}/classroom/report/standard-base-report/${classroomId}?task_id=${id}&goal_id=${goalId}`
        );
        sbrWindow['classroomId'] = classroomId;
        break;
      case "show tooltip":
        const targetUrl = getUrlFromTarget(task);
        // hasCase = !!targetUrl
        targetUrl && window.open(targetUrl);
        break;
      case "open discussion":
        hasCase = true;
        window.open("/discussions");
        break;
      case "open classroom summative report": {
        const summativeId = task_action_value["value"];
        if(summativeId){
          hasCase = true;
          const sumativeWindow:any = window.open(
            `/classroom/report/summative/${summativeId}/${classroomId}?task_id=${id}&goal_id=${goalId}`
          );
          sumativeWindow['classroomId'] = classroomId;
        }
        break;
      }
      case "generate remediation report": {
        const objectiveId = task_action_value["objective"];
        hasCase = !!objectiveId;
        objectiveId && downloadDTMRemmediationReport(objectiveId);
        break;
      }
      case "open remediate report": {
        const objectiveId = task_condition_value?.key?.objective_id;
        hasCase = !!objectiveId;
        objectiveId &&
          window.open(
            `/classroom?openRemediateModal=${true}&objectiveId=${objectiveId}`
          );
        break;
      }
      case "open sar in new tab": {
        const summativeId = task_action_value["value"];
        if(summativeId){
          hasCase = true;
          const sarWindow:any = window.open(
            `/classroom/report/standard-at-risk/${classroomId}/${summativeId}?task_id=${id}&goal_id=${goalId}`
          );
          sarWindow['classroomId'] = classroomId;
        }
        break;
      }
      default:
        hasCase = false;
        break;
    }

    if (hasCase && !checked) {
      console.log({ id, goalId });
      updateGoalTask(task.id, goalId);
      if (task_condition_name?.toLowerCase() === "download one-time file") {
        triggerGoalWithClassroom({
          goal_id: goalId,
          task_id: task.id,
          classroomId: classroomId,
          conditionName: GOALS_CONDITIONS.Download_One_Time_File,
        });
      } else {
        triggerProfessionalDevelopmentGoal({
          goalId: goalId,
          taskId: task.id,
          classroomId,
          actionName: task.task_action_name,
        });
      }
    }
  };

  const onSelectGoal = () => {
    onClickGoal(goalId);
  };

  const descriptionHeight =
    completedBy && tasks?.length === 2 ? 110 : tasks?.length >= 2 ? 95 : 167;

  return (
    <>
      <Conatiner isDisabled={isDisabled} onClick={onSelectGoal}>
        <Heading goalId={goalId} heading={heading || ""} onClickGoal={onSelectGoal} />
        {completedBy && <p onClick={onSelectGoal} className="completed-date-label">{completedBy}</p>}
        <BodyContainer isAddClass={!!completedBy}>
          <Description
            description={shortDescription || description}
            descriptionHeight={descriptionHeight - (completedBy ? 30 : 0)}
            onClickGoal={onSelectGoal}
          />
          <GoalsList onClickTask={onClickTask} tasks={tasks} />
        </BodyContainer>
      </Conatiner>
    </>
  );
};

export default Card;
