import { SupportTicketSuperAdminContextProvider, GetTicketManagement, PutSupportTicketStatus, PutSupportTicketComment } from "interfaces/supportTicketSuperAdmin"
import { createContext, useEffect, useReducer, useState, useMemo } from "react"
import { useParams } from "react-router-dom"
import { getTicketDetails, getSupportTicketManagement, getSupportTicketStats, getSupportChat, putSupportTicketManagementStatus, putSupportTicketManagementComment, getActiveChats, getSuperAdmins, getSuperAdminChatById, putSuperAdminChat, getAdmins, getSupportTicketManagementComment } from "services/supportTicketSuperAdmin"
import { addReplyToTicket, getMasteryVideos, saveContactSupportTicket, uploadImageToS3 } from "services/suppot-ticket"
import { SupportTicketSuperAdminActions, SupportTicketSuperAdminReducer } from "./reducers/supportTicketSuperAdmin"
import { getMessages } from "services/suppot-ticket"

export const initialState: SupportTicketSuperAdminContextProvider = {
    commentLoading: false,
    setSupportTicketsLoading: null!,
    fetchSupportTicketManagementComment: null!,
    admins: [],
    ticketDetilsComments: {
        comments: [],
        count: 0
    },
    supportChat: {
        loading: false,
        chats: [],
        count: 0
    },
    fetchSuperAdmin: null!,
    supportTicketStats: {
        loading: false,
        new_tickets: 0,
        new_chats: 0,
        ticket_assigned: 0,
        tickets_by_status: {
            submitted: 0,
            in_progress: 0,
            need_more_info: 0,
            resolved: 0
        }
    },
    supportTickets: {
        count: 0,
        tickets: [],
        loading: false
    },
    fetchSupportTicketManagementStats: null!,
    fetchSupportTicketManagementStatsWithoutPageLoad: null!,
    fetchSupportTicketManagement: null!,
    fetchSupportTicketManagementWithoutPageLoad: null!,
    fetchSupportTicketDetails: null!,
    ticketDetails: {
        loading: true,
        details: null!,
        messages: [],
        comments: [],
        change_history: [],
        comment_count: 0,
        message_count: 0,
        chat_history: false,
        support_chat_id: null!
    },
    fetchSupportChat: null!,
    fetchSupportChatWithoutPageLoad: null!,
    onClickReply: null!,
    updateSupportTicketStatus: null!,
    updateSupportTicketComment: null!,
    selectedMessage: null!,
    setSelectedMessage: null!,
    addReply: null!,
    fetchActiveChats: null!,
    activeChats: {
        status: '',
        chats: [],
        assigned_chats: 0,
        unassigned_chats: 0,
        originalTicket: "",
    },
    superAdmins: {
        count: 0,
        super_admins: []
    },
    putChat: null!,
    fetchChatById: null!,
    singleAdminChat: null!,
    unsetChatById: null!,
    sendMessageSuperAdmin: null!,
    originalTicket: null!,
    videoOptions: [],
    addAttachmentToTicket: null!,
    createNewTicket: null!,
    fetchSupportTicketAdmins: null!,
    unsetAdmins: null!,
    fetchPreviousChatById: null!,
    fetchSupportTicketMessages: null!,
    setSupportChat: null!
}

export const SupportTicketSuperAdminContext = createContext<SupportTicketSuperAdminContextProvider>(null!)

const SupportTicketSuperAdminProvider = ({ children }: any) => {
    const [{ commentLoading, admins, singleAdminChat, superAdmins, supportChat, supportTicketStats, supportTickets, ticketDetails, ticketDetilsComments, activeChats }, dispatch] = useReducer(SupportTicketSuperAdminReducer, initialState);
    
    const { id } = useParams();

    const [showReplyModal, setShowReplyModal] = useState<boolean>(false);
    const [selectedMessage, setSelectedMessage] = useState(initialState.selectedMessage);
    const [superAdminQuery] = useState({
        limit: 1000,
        offset: 0,
        search: '',
        sort_by: '',
        order_by: '',
        ...(id && {for_ticket: true})
    });
    

    const ticketMessagesLoading = (loading: boolean) => {
        dispatch({
            type: SupportTicketSuperAdminActions.SET_MESSAGES_LOADING,
            payload: loading
        })
    }

    const fetchSupportTicketMessages = async (payload: any) => {
        ticketMessagesLoading(true);
        const messagesData = await getMessages({...payload, id});
        ticketMessagesLoading(false);
        
        messagesData && dispatch({
            type: SupportTicketSuperAdminActions.SET_TICKET_DETAILS_MESSAGES,
            payload: messagesData
        });
        
    }

    const setTicketDetailsLoading = (loading: boolean) => {
        dispatch({
            type: SupportTicketSuperAdminActions.SET_TICKET_DETAILS_LOADING,
            payload: loading
        });
    }

    const setCommentLoading = (loading: boolean) => {
        dispatch({
            type: SupportTicketSuperAdminActions.SET_COMMENT_LOADING,
            payload: loading
        });
    }

    const fetchSupportTicketAdmins = async (search: string) => {
        const res = await getAdmins(search);
        const modifiedAdmins = res ? res.admins.map((item: any, indx: number) => {
            const { last_name, first_name } = item;
            return {
                ...item,
                label: `${last_name}${first_name ? `, ${first_name}` : ''}`,
                value: indx
            }
        })
            : null;
        modifiedAdmins && dispatch({
            type: SupportTicketSuperAdminActions.SET_ADMINS,
            payload: modifiedAdmins
        });

        return modifiedAdmins;
    }

    const unsetAdmins = () => {
        dispatch({
            type: SupportTicketSuperAdminActions.UNSET_ADMINS
        });
    }

    const [videos, setVideos] = useState<Array<any>>([])

    const videoOptions = useMemo(() => {
        return videos?.map((video: any, index: number) => ({
            value: video.name,
            label: video.name,
            id: video.id,
            thumbnail_url: video.thumbnail_url,
            video_url: video.video_url
        })) || []
    }, [videos]);

    const sendMessageSuperAdmin = async (payload: any, messageData: any) => {
        const { message } = messageData;
        const chat = await putChat({ ...payload, message });
        chat && dispatch({
            type: SupportTicketSuperAdminActions.SET_SUPER_ADMIN_CHAT_MESSAGE,
            payload: chat
        })

        return chat;
    }

    const unsetChatById = async () => {
        dispatch({
            type: SupportTicketSuperAdminActions.UNSET_CHAT_BY_ID,
        })
    }

    const createNewTicket = async (payload: any) => {
        const data = await saveContactSupportTicket(payload);
        const tickets = data && data.data && data.data ? data.data : null;

        tickets && dispatch({
            type: SupportTicketSuperAdminActions.SET_SUPPORT_TICKETS,
            payload: tickets
        });

        return !!tickets;
    }

    const fetchChatById = async (payload: any) => {
        const chat = await getSuperAdminChatById(payload);
        chat && dispatch({
            type: SupportTicketSuperAdminActions.SET_CHAT_BY_ID,
            payload: chat
        })
    }
    
    const fetchSupportTicketManagementComment = async (payload: any) => {
        setCommentLoading(true);
        const commentsData = id ? await getSupportTicketManagementComment({...payload, ticketId: id}) : null;
        setCommentLoading(false);
        commentsData && dispatch({
            type: SupportTicketSuperAdminActions.SET_SUPPORT_MANAGEMENT_COMMENT,
            payload: commentsData
        });

        return !!commentsData;
    }

    const fetchPreviousChatById = async (payload: any) => {
        const { messages } = await getSuperAdminChatById(payload);
        messages && dispatch({
            type: SupportTicketSuperAdminActions.SET_SUPER_ADMIN_CHAT_MESSAGE,
            payload: messages
        })
    }

    const putChat = async (payload: any) => {
        const chat = await putSuperAdminChat(payload);
        chat && dispatch({
            type: SupportTicketSuperAdminActions.SET_SUPER_ADMIN_CHAT,
            payload: chat
        })
        return chat;
    }

    const fetchActiveChats = async () => {
        const activeChats = await getActiveChats();
        activeChats && dispatch({
            type: SupportTicketSuperAdminActions.SET_ACTIVE_CHATS,
            payload: activeChats
        })

        activeChats?.chats?.length > 0 && dispatch({
            type: SupportTicketSuperAdminActions.SET_CHAT_BY_ID,
            payload: activeChats?.chats[0]
        })
    }

    const fetchSupportTicketDetails = async (id: string) => {
        setTicketDetailsLoading(true);
        const ticketDetails = await getTicketDetails(id);

        ticketDetails
        ? dispatch({
            type: SupportTicketSuperAdminActions.SET_TICKET_DETAILS,
            payload: ticketDetails
        })
        : setTicketDetailsLoading(false);

    }

    const addReply = async (payload: any) => {
        // pass payload here 
        const response = await addReplyToTicket({ ticketId: id, ...payload, reply_of: selectedMessage.id });

        if (response) {
            ticketDetails.messages = response?.messages || ticketDetails.messages;
            ticketDetails.message_count = response?.count || ticketDetails.message_count;

            ticketDetails && dispatch({
                type: SupportTicketSuperAdminActions.SET_TICKET_DETAILS,
                payload: ticketDetails
            })
        }

        response && response.messages?.length > 0 && setSelectedMessage(response.messages[0]);
    }

    const setSupportTicketsLoading = (loading: boolean) => {
        dispatch({
            type: SupportTicketSuperAdminActions.SET_SUPPORT_TICKETS_LOADING,
            payload: loading
        })
    }

    const fetchSupportTicketManagement = async (payload: GetTicketManagement) => {
        setSupportTicketsLoading(true);
        const ticketsData = await getSupportTicketManagement(payload);
        ticketsData
        ? dispatch({
            type: SupportTicketSuperAdminActions.SET_SUPPORT_TICKETS,
            payload: ticketsData
        })
        : setSupportTicketsLoading(false);
    }

    const fetchSupportTicketManagementWithoutPageLoad = async (payload: GetTicketManagement) => {
        const ticketsData = await getSupportTicketManagement(payload);
        ticketsData
        && dispatch({
            type: SupportTicketSuperAdminActions.SET_SUPPORT_TICKETS,
            payload: ticketsData
        })
        
    }

    

    const setSupportTicketStatsLoading = (loading: boolean) => {
        dispatch({
            type: SupportTicketSuperAdminActions.SET_SUPPORT_TICKET_STATS_LOADING,
            payload: loading
        })
    }

    const fetchSupportTicketManagementStats = async (filter?: string) => {
        setSupportTicketStatsLoading(true);
        const stats = await getSupportTicketStats(filter);
        stats ? dispatch({
            type: SupportTicketSuperAdminActions.SET_SUPPORT_TICKET_STATS,
            payload: stats
        })
        : setSupportTicketStatsLoading(false);
    }


    

    const fetchSupportTicketManagementStatsWithoutPageLoad = async (filter?: string) => {
        const stats = await getSupportTicketStats(filter);
        stats && dispatch({
            type: SupportTicketSuperAdminActions.SET_SUPPORT_TICKET_STATS,
            payload: stats
        });
    }

    const setSupportChatLoading = (loading: boolean) => {
        dispatch({
            type: SupportTicketSuperAdminActions.SET_SUPPORT_CHAT_LOADING,
            payload: loading
        });
    }

    const setSupportChat = (payload: any) => {
        dispatch({
            type: SupportTicketSuperAdminActions.SET_SUPPORT_CHAT,
            payload: payload
        })
    }
    const fetchSupportChat = async (payload: GetTicketManagement) => {
        setSupportChatLoading(true);
        const chatData = await getSupportChat(payload);
        chatData
        ? setSupportChat(chatData)
        : setSupportChatLoading(false);
    }


    const fetchSupportChatWithoutPageLoad = async (payload: GetTicketManagement) => {
        const chatData = await getSupportChat(payload);
        chatData
        && setSupportChat(chatData)
    }
    

    const fetchMasteryVideos = async () => {
        const response = await getMasteryVideos()
        const { videos } = response || {};
        videos && setVideos(videos)
    }

    const onClickReply = () => {
        setShowReplyModal(!showReplyModal)
    }

    const updateSupportTicketStatus = async (payload: PutSupportTicketStatus) => {
        const ticketDetails = id ? await putSupportTicketManagementStatus({ ...payload, ticketId: id }) : null;
        ticketDetails && dispatch({
            type: SupportTicketSuperAdminActions.SET_SUPPORT_MANAGEMENT_STATUS,
            payload: ticketDetails
        });
    }

    const updateSupportTicketComment = async (payload: PutSupportTicketComment) => {
        const commentsData = id ? await putSupportTicketManagementComment({ ...payload, ticketId: id }) : null;
        commentsData && dispatch({
            type: SupportTicketSuperAdminActions.SET_SUPPORT_MANAGEMENT_COMMENT,
            payload: commentsData
        });

        return !!commentsData;
    }

    const fetchSuperAdmin = async (action: string) => {
        
        if(action === 'for_ticket') {
            superAdminQuery['for_ticket'] = true;
        } else {
            delete superAdminQuery['for_ticket'];
        }
        
        const superAdmins = await getSuperAdmins(superAdminQuery);
        superAdmins && dispatch({
            type: SupportTicketSuperAdminActions.SET_SUPER_ADMINS,
            payload: {
                superAdmins,
                for_ticket: superAdminQuery['for_ticket'] ? true: false
            }
        })
    }

    const originalTicket = useMemo(() => {
        if (ticketDetails) {
            const { messages } = ticketDetails
            return messages?.filter((message: any) => message.from === 'Original Ticket')[0] || null
        }
        return null!

    }, [ticketDetails])

    const addAttachmentToTicket = async (file: any) => {
        const response = await uploadImageToS3(file)
        return response[0]
    }

    useEffect(() => {
        fetchSupportTicketManagementStats();
        fetchMasteryVideos()

        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    useEffect(() => {
        !id && fetchSuperAdmin('for_ticket');
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [superAdminQuery])

    useEffect(() => {
        id && fetchSupportTicketDetails(id);
    }, [id])

    return <SupportTicketSuperAdminContext.Provider value={{
        setSupportTicketsLoading,
        unsetAdmins,
        createNewTicket,
        sendMessageSuperAdmin,
        unsetChatById,
        singleAdminChat,
        fetchChatById,
        putChat,
        superAdmins,
        activeChats,
        ticketDetilsComments,
        updateSupportTicketComment,
        updateSupportTicketStatus,
        fetchSupportChat,
        fetchSupportChatWithoutPageLoad,
        supportChat,
        supportTicketStats,
        supportTickets,
        fetchSupportTicketManagementStats,
        fetchSupportTicketManagementStatsWithoutPageLoad,
        fetchSupportTicketManagement,
        fetchSupportTicketManagementWithoutPageLoad,
        fetchSupportTicketDetails,
        ticketDetails,
        onClickReply,
        selectedMessage,
        setSelectedMessage,
        addReply,
        fetchActiveChats,
        originalTicket,
        videoOptions,
        addAttachmentToTicket,
        admins,
        fetchSupportTicketAdmins,
        fetchPreviousChatById,
        fetchSupportTicketManagementComment,
        commentLoading,
        fetchSupportTicketMessages,
        fetchSuperAdmin,
        setSupportChat
    }}>
        {children}
    </SupportTicketSuperAdminContext.Provider>
}
export default SupportTicketSuperAdminProvider