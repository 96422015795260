const AssessmentHeader = ({isSummative, summativeLength}: {isSummative?: boolean, summativeLength?: number}) => {
    return <thead>
        <tr className="summative__table-row tableRowHeight">
            <th className={`summative__table-objective ${(isSummative && !!summativeLength) &&  "summativeObejct" }`}>
                Objective and Description
            </th>
            <th className="summative__table-last text-center">
                Last <br /> Activity
            </th>
            <th className="summative__table-proficiency text-center">
                Students <br /> Assessed
            </th>
            <th className="summative__table-proficiency text-center">
                Proficiency
            </th>
            <th className="summative__table-review text-center align-bottom">
                {'Review Test'}
            </th>
            <th className="summative__table-edit text-center">
                Add/Edit <br /> Answers
            </th>
            <th className={`summative__table-${isSummative ? 'summative-reports' : 'reports'}`}>{isSummative ? 'Reports' : 'Report'}</th>
            {!isSummative && <th className="summative__table-remediate">Remediate</th>}
            <th className="summative__table-available text-center">
                Available to <br /> Students
            </th>
            <th className="summative__table-monitor text-center">
                Monitor
            </th>
        </tr>
    </thead>
}
export default AssessmentHeader