import React, { useEffect } from "react";
import IPage from "interfaces/page";
import logging from "config/logging";
import Theme from "components/theme/index";
import AnswersFormative from "components/classroom/answers/answers-formative";

const SummativeAddEditAnswers: React.FunctionComponent<IPage> = (props) => {
  
 useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props.name]);

  return (
    <>
      <Theme>
        <AnswersFormative/>
      </Theme>
    </>
  );
};

export default SummativeAddEditAnswers;
