import { FC, useContext, useState } from "react";
import { ReactComponent as PencilIcon } from "assets/img/pencil-icon-black-outline.svg";
import { QuestionnaireContext } from "./QuestionnaireContext";
import ChangeObjectiveModal from "./Modals/ChangeObjectiveModal";
import { Spinner } from "react-bootstrap";
import DeleteIcon from "assets/img/del-img-icon-black-outline_.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";
import { DeleteObjectiveModal } from "components/edit-objectives/objectives-modals";
import { deleteObjective } from "redux/actionCreators/objectives";
import { useNavigate } from "react-router-dom";

interface Props {
  isPreview: boolean;
}

const Footer: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    setShow,
    hasPreviousQuestion,
    hasNextQuestion,
    isSave,
    previousQuestion,
    nextQuestion,
    isTestUpdated,
    onRevert,
    setShowSaveModel,
    objectiveName,
    currentQuestion,
  } = useContext(QuestionnaireContext);

  const { assessmentName } = useSelector(
    (state: RootState) => state.cmsAssessment
  );

  const [revertLoading, setRevertLoading] = useState(false);

  const onClickRevert = async () => {
    setRevertLoading(true);
    await onRevert();
    setRevertLoading(false);
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const onDeleteObjective = () => {
    dispatch(deleteObjective(currentQuestion.objective_id, setDeleteModal));
    navigate("/cms/select-assessment");
  };

  const { isPreview } = props;
  return (
    <>
      <footer className="cmsFooter">
        <ChangeObjectiveModal />
        <div className="pg-container">
          <div className="cmsFooter-wrapper">
            <div className="cmsFooter__search">
              {isPreview ? (
                <></>
              ) : (
                <>
                  <span
                    className="cmsFooter__search-icon"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    <PencilIcon />
                  </span>
                  <span className="cmsFooter__search-text">
                    {objectiveName}
                  </span>
                </>
              )}
              {assessmentName?.indexOf("(Archived") > -1 && (
                <>
                  <span
                    className="cmsFooter__search-text"
                    style={{ marginLeft: -30 }}
                  >
                    Archived
                  </span>
                  <button className="btn">
                    <img
                      onClick={() => {
                        setDeleteModal(true);
                        //   setCurrentItem(item);
                      }}
                      src={DeleteIcon}
                      alt=""
                    />
                  </button>
                </>
              )}
            </div>
            <div className="cmsFooter__buttons">
              <div className="cmsFooter__buttons-wrapper">
                <button
                  disabled={
                    revertLoading || ((isPreview || !isTestUpdated) && !isSave)
                  }
                  onClick={onClickRevert}
                  className="btn cancel-btn light btn-medium revert-orange-btn"
                >
                  {revertLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Revert"
                  )}
                </button>
                <button
                  disabled={!hasPreviousQuestion}
                  onClick={previousQuestion}
                  className="btn cancel-btn btn-medium back-cancel-btn"
                >
                  Back
                </button>
                <button
                  disabled={!hasNextQuestion}
                  onClick={nextQuestion}
                  className="btn cancel-btn btn-medium back-cancel-btn"
                >
                  Next
                </button>
                <button
                  disabled={(isPreview || !isTestUpdated) && !isSave}
                  onClick={() => setShowSaveModel(true)}
                  className="btn success-btn btn-medium"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {deleteModal && (
        <DeleteObjectiveModal
          loading={false}
          isShow={deleteModal}
          headerText={`Delete Formative`}
          bodyText={`Are you sure you want to delete this formative? This action is not reversible.`}
          onCancel={() => setDeleteModal(false)}
          onDelete={onDeleteObjective}
        />
      )}
    </>
  );
};

export default Footer;
