import { PdAdminContextProvider } from "interfaces/pdAdmin";
import { createContext, useEffect, useReducer } from "react";
import {
  getPacingCalenderMonths,
  getPacingGradesSubjects,
} from "services/pacingCalender";
import {
  addResource,
  deleteResource,
  deleteTool,
  getAllGoals,
  getAllResources,
  getAllTools,
  getPdWeeklySchedule,
  getToolsIcon,
  putPdPrevToolsData,
  putPdPrevWeekData,
  putPdToolsData,
  UpdatePdToolsData,
  putPdWeekData,
  sortResource,
  updateResource,
} from "services/pdAdmin";
import { monthMapper } from "util/monthMapper";
import { PdAdminActions, PdAdminReducer } from "./reducers/pdAdmin";

export const initialState: PdAdminContextProvider = {
  unsetSelectedIcon: null!,
  setSelectedIcon: null!,
  fetchToolsIcon: null!,
  addTools: null!,
  updateTools: null!,
  updatePdAdminResource: null!,
  allMonths: {
    months: [],
    current: null!,
  },
  fetchMonths: null!,
  currentMonthIndex: 0,
  setCurrentMonthIndex: null!,
  fetchSubjectGrades: null!,
  gradeSubjects: [],
  setCurrentGradeSubjectIndex: null!,
  currentSubjectIndex: 0,
  handleNextPreviousMonth: null!,
  handleNextPreviousGrade: null!,
  updateSearch: null!,
  search: "",
  weeklySchedule: {
    loading: true,
    month: "",
    year: "",
    weeks: [],
  },
  fetchPdWeeklySchedule: null!,
  putPrevWeekData: null!,
  putWeekData: null!,
  resources: {
    loading: true,
    books: [],
    assessments_2021: [],
    assessments_2016: [],
    pd: [],
    other: [],
  },
  fetchAllResources: null!,
  putResources: null!,
  goals: {
    loading: true,
    month: "",
    year: "",
    weeks: [],
  },
  fetchPdAdminGoals: null!,
  fetchPdAdminTools: null!,
  tools: {
    loading: true,
    month: "",
    year: "",
    weeks: [],
  },
  deletePdAdminTool: null!,
  putPrevToolsData: null!,
  addNewResource: null!,
  deletePdAdminResource: null!,
  toolsIcons: [],
  setGoals: (payload: any) => {},
  selectedIcon: {
    id: 8,
    url: "https://aplustesting.org/district-logo-images/district_logo/tools/icon_8.svg",
  },
};

export const PdAdminContext = createContext<PdAdminContextProvider>(null!);

const PdAdminProvider = ({ children }: any) => {
  const [
    {
      selectedIcon,
      toolsIcons,
      tools,
      goals,
      resources,
      allMonths,
      currentMonthIndex,
      gradeSubjects,
      currentSubjectIndex,
      weeklySchedule,
      search,
    },
    dispatch,
  ] = useReducer(PdAdminReducer, initialState);
  const sessionClassroomId = sessionStorage.getItem("c_classroom_id") || null!;
  const classroomId = parseInt(sessionClassroomId)
    ? sessionClassroomId
    : `${6194}`;

  const unsetSelectedIcon = () => {
    dispatch({
      type: PdAdminActions.SET_SELECTED_ICON,
      payload: initialState.selectedIcon,
    });
  };

  const setSelectedIcon = (icon: any) => {
    dispatch({
      type: PdAdminActions.SET_SELECTED_ICON,
      payload: icon,
    });
  };

  const fetchToolsIcon = async () => {
    const toolsIcon = await getToolsIcon();
    toolsIcon &&
      dispatch({
        type: PdAdminActions.SET_TOOLS_ICONS,
        payload: toolsIcon.tools_icons,
      });
  };

  const updatePdAdminResource = async (data: any) => {
    const { prop, ...otherData } = data;
    const res = await updateResource(otherData);

    res &&
      res.updated &&
      dispatch({
        type: PdAdminActions.SET_UPDATE_RESOURCE,
        payload: { prop, data: otherData },
      });

    return res && res.updated;
  };

  const addTools = async (payload: any) => {
    const gradeSubjectData = gradeSubjects[currentSubjectIndex];
    const { grade_id, subject_id } = gradeSubjectData;

    const apiPayload = {
      ...payload,
      grade_id,
      subject_id,
      month: allMonths?.months[currentMonthIndex]?.month.toUpperCase() || "",
    };

    let formData = new FormData();
    Object.keys(apiPayload).forEach((key) => {
      formData.append(key, apiPayload[key as keyof typeof apiPayload]);
    });

    const tools = await putPdToolsData(formData);
    tools &&
      dispatch({
        type: PdAdminActions.SET_TOOLS,
        payload: tools,
      });

    return tools;
  };

  const updateTools = async (payload: any) => {
    const gradeSubjectData = gradeSubjects[currentSubjectIndex];
    const { grade_id, subject_id } = gradeSubjectData;
    const apiPayload = {
      ...payload,
      grade_id,
      subject_id,
      month: allMonths?.months[currentMonthIndex]?.month.toUpperCase() || "",
    };
    let formData = new FormData();

    Object.keys(apiPayload).forEach((key) => {
      formData.append(key, apiPayload[key as keyof typeof apiPayload]);
    });

    const tools = await UpdatePdToolsData(formData);
    tools &&
      dispatch({
        type: PdAdminActions.SET_TOOLS,
        payload: tools,
      });

    return tools;
  };

  const deletePdAdminResource = async (resourceId: number, prop: string) => {
    const res = await deleteResource(resourceId);

    res &&
      res.deleted &&
      dispatch({
        type: PdAdminActions.SET_DELETE_RESOURCE,
        payload: { prop, resourceId },
      });

    return res && res.deleted;
  };

  const addNewResource = async (payload: any) => {
    const { prop, ...otherData } = payload;
    const newResource = await addResource(otherData);
    newResource &&
      dispatch({
        type: PdAdminActions.SET_ADD_RESOURCE,
        payload: { prop, newResource },
      });

    return newResource;
  };

  const getPayload = () => {
    const monthData = allMonths.months[currentMonthIndex];
    const gradeSubjectData = gradeSubjects[currentSubjectIndex];

    const { month, year } = monthData;
    const monthFormatted = monthMapper[month as keyof typeof monthMapper];

    const { grade_id: gradeId, subject_id: subjectId } = gradeSubjectData;

    return { month: monthFormatted, year, gradeId, subjectId, search };
  };

  const putPrevToolsData = async (payload: any) => {
    const prevToolsData = await putPdPrevToolsData(payload);
    prevToolsData &&
      dispatch({
        type: PdAdminActions.SET_PD_PREV_TOOLS,
        payload: prevToolsData,
      });

    return !!prevToolsData;
  };

  const deletePdAdminTool = async (
    payload: {id: number, grade_id?: number, subject_id?: number},
    date_week_id: number,
    nextWeekId: number
  ) => {
    // const monthData = allMonths.months[currentMonthIndex];
    const res = await deleteTool(payload);
    res &&
      res.deleted &&
      dispatch({
        type: PdAdminActions.DELETE_TOOL,
        payload: { id: payload.id, date_week_id, nextWeekId },
      });

    return res && res.deleted;
  };

  const fetchPdAdminTools = async () => {
    setToolsLoading(true);
    const payload = getPayload();

    const allTools = await getAllTools(payload);
    allTools
      ? dispatch({
          type: PdAdminActions.SET_TOOLS,
          payload: allTools,
        })
      : setToolsLoading(false);
  };

  const fetchPdAdminGoals = async () => {
    setGoalsLoading(true);
    const payload = getPayload();

    const allGoals = await getAllGoals(payload);
    setGoalsLoading(false)
    if(allGoals){
      setGoals(allGoals);
    }
  };

  const setGoals = (payload: any) => {
    dispatch({
      type: PdAdminActions.SET_GOALS,
      payload: payload,
    })
  }

  const setResourcesLoading = (loading: boolean) => {
    dispatch({
      type: PdAdminActions.SET_RESOURCES_LOADING,
      payload: loading,
    });
  };

  const putResources = async (resources: any, data: any, category: any) => {
    dispatch({
      type: PdAdminActions.SET_RESOURCES,
      payload: resources,
    });
    await sortResource(resources, data, category);
  };

  const putPrevWeekData = async (payload: any) => {
    const prevWeekData = await putPdPrevWeekData(payload);
    prevWeekData &&
      dispatch({
        type: PdAdminActions.SET_PD_PREV_WEEK,
        payload: prevWeekData,
      });

    return !!prevWeekData;
  };

  const fetchAllResources = async (selectedGradeAndSubject?: any) => {
    setResourcesLoading(true);
    const gradeSubject = selectedGradeAndSubject
      ? selectedGradeAndSubject
      : gradeSubjects[currentSubjectIndex];
    const { grade_id: gradeId, subject_id: subjectId } = gradeSubject;

    const resources = await getAllResources({ gradeId, subjectId });

    resources
      ? dispatch({
          type: PdAdminActions.SET_RESOURCES,
          payload: resources,
        })
      : setResourcesLoading(false);

    return resources;
  };

  const putWeekData = async (payload: any) => {
    const weekData = await putPdWeekData(payload);
    weekData &&
      dispatch({
        type: PdAdminActions.SET_PD_WEEK,
        payload: weekData,
      });

    return weekData;
  };

  const updateSearch = (search: string) => {
    dispatch({
      type: PdAdminActions.SET_SEARCH,
      payload: search,
    });
  };

  const fetchMonths = async () => {
    const monthsData = await getPacingCalenderMonths({ classroomId: null });
    monthsData &&
      dispatch({
        type: PdAdminActions.SET_MONTHS,
        payload: monthsData,
      });
  };

  const setCurrentMonthIndex = (index: number) => {
    dispatch({
      type: PdAdminActions.SET_CURRENT_MONTH_INDEX,
      payload: index,
    });
  };

  const setCurrentGradeSubjectIndex = (index: number) => {
    dispatch({
      type: PdAdminActions.SET_CURRENT_SUBJECT_INDEX,
      payload: index,
    });
  };

  const fetchSubjectGrades = async () => {
    const { grade_subjects_array } = await getPacingGradesSubjects(classroomId);
    grade_subjects_array &&
      dispatch({
        type: PdAdminActions.SET_SUBJECTS,
        payload: grade_subjects_array,
      });
  };

  const setGoalsLoading = (loading: boolean) => {
    dispatch({
      type: PdAdminActions.SET_GOALS_LOADING,
      payload: loading,
    });
  };

  const setToolsLoading = (loading: boolean) => {
    dispatch({
      type: PdAdminActions.SET_TOOLS_LOADING,
      payload: loading,
    });
  };

  const setWeeklyScheduleLoading = (loading: boolean) => {
    dispatch({
      type: PdAdminActions.SET_WEEKLY_SCHEDULE_LOADING,
      payload: loading,
    });
  };

  const fetchPdWeeklySchedule = async () => {
    setWeeklyScheduleLoading(true);
    const payload = getPayload();

    const schedule = await getPdWeeklySchedule(payload);
    schedule
      ? dispatch({
          type: PdAdminActions.SET_WEEKLY_SCHEDULE,
          payload: schedule,
        })
      : setWeeklyScheduleLoading(false);
  };

  const handleNextPreviousMonth = (direction: string) => {
    //right icon clicked
    direction === "right" &&
      currentMonthIndex < 11 &&
      setCurrentMonthIndex(currentMonthIndex + 1);
    //left icon clicked
    direction === "left" &&
      currentMonthIndex > 0 &&
      setCurrentMonthIndex(currentMonthIndex - 1);
  };

  const handleNextPreviousGrade = (direction: string) => {
    //right icon clicked
    direction === "right" &&
      currentSubjectIndex < gradeSubjects.length - 1 &&
      setCurrentGradeSubjectIndex(currentSubjectIndex + 1);
    //left icon clicked
    direction === "left" &&
      currentSubjectIndex > 0 &&
      setCurrentGradeSubjectIndex(currentSubjectIndex - 1);
  };

  useEffect(() => {
    fetchMonths();
    fetchSubjectGrades();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PdAdminContext.Provider
      value={{
        setSelectedIcon,
        fetchToolsIcon,
        toolsIcons,
        addTools,
        updateTools,
        updatePdAdminResource,
        deletePdAdminResource,
        addNewResource,
        putPrevToolsData,
        deletePdAdminTool,
        tools,
        fetchPdAdminTools,
        fetchPdAdminGoals,
        goals,
        putResources,
        search,
        putWeekData,
        putPrevWeekData,
        fetchPdWeeklySchedule,
        weeklySchedule,
        updateSearch,
        handleNextPreviousGrade,
        handleNextPreviousMonth,
        currentSubjectIndex,
        setCurrentGradeSubjectIndex,
        gradeSubjects,
        fetchSubjectGrades,
        setCurrentMonthIndex,
        currentMonthIndex,
        fetchMonths,
        allMonths,
        fetchAllResources,
        resources,
        selectedIcon,
        unsetSelectedIcon,
        setGoals
      }}
    >
      {children}
    </PdAdminContext.Provider>
  );
};
export default PdAdminProvider;
