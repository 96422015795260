import React, { FC } from 'react';
import { HeaderSkeleton, FooterSkeleton } from "components/common/skeleton/ReportsSkeleton";
import ToolTip, { ObjectiveTooltipContent } from 'components/common/Tooltip';

interface Props {
    slicedQustions: any;
    questionId: number;
    isLoading: boolean;
    allStudents: any;
    isIsolated: (index: number) => boolean;
    OnClickQuestionNo: any
}

const AnswerMap = {
    0: 'A',
    1: 'B',
    2: 'C',
    3: 'D'
}

const Footer: FC<Props> = (props) => {
    const { slicedQustions, isLoading, allStudents, isIsolated, OnClickQuestionNo } = props;

    return (
        <div id="footer" className={`report__footer irr-footer ${allStudents.length > 15 ? 'move-up' : ''}`}>
            <div className={`report__total ${allStudents.length > 15 ? 'opacity-94' : ''}`}>

                <div className="report__total-title irr-total-title">Correct Answer</div>
                <div className={`report__total-count`}>
                    {!isLoading ? slicedQustions.map((question: any, index: number) => {
                        return <span className={`${isIsolated(index) && 'hidden'} report__total-total answer correct-bold`}>
                            {AnswerMap[question.answer as keyof typeof AnswerMap]}
                        </span>

                    })
                        : <div style={{ marginLeft: ' -2px' }}>
                            <FooterSkeleton rowColumns={25} />
                        </div>
                    }
                </div>

            </div>

            <div className={`report__total ${isLoading ? '' : 'dark-gray'} ${allStudents.length > 15 ? 'opacity-94' : ''}`}>
                <div className={`report__total-title irr-total-title ${!isLoading ? 'dark' :''}`}>Total Students</div>
                <div className={`report__total-count`}>
                    {!isLoading ? slicedQustions.map((question: any, index: number) => {
                        return (
                            <span className={`${isIsolated(index) && 'hidden'} report__total-total`}>
                                {question.total_students}
                            </span>
                        )
                    })

                        : <div style={{ marginLeft: ' -2px' }}>
                            <FooterSkeleton rowColumns={25} />
                        </div>
                    }
                </div>
            </div>

            <div className={`report__total`}>

                <div className="report__total-title irr-total-title">
                    <div className="d-flex align-items-center justify-content-between">

                        <span className="report__total-rectangle high">
                        </span>
                        <span>Total Correct</span>
                    </div>
                </div>
                <div className={`report__total-count`}>
                    {!isLoading ? slicedQustions.map((question: any, index: number) => {
                        return (
                            <span className={`${isIsolated(index) && 'hidden'} report__total-total`}>
                                {question.total_correct}
                            </span>
                        )
                    })
                        : <div style={{ marginLeft: ' -2px' }}>
                            <FooterSkeleton rowColumns={25} />
                        </div>
                    }
                </div>
            </div>

            <div className={`report__total ${isLoading ? '' : 'dark-after'}`}>
                <div className={`report__total-title irr-total-title incorrect ${!isLoading ? 'dark' : ''}`}>
                    <div className="d-flex align-items-center justify-content-between">
                        <span className="report__total-rectangle low"></span>
                        <span>Total Incorrect</span>
                    </div>
                </div>
                <div className={`report__total-incorrect`}>
                    {!isLoading ? slicedQustions.map((question: any, index: number) => {
                        return (
                            <div className="report__total-incorrectContainer">
                                <span className={`${isIsolated(index) && 'hidden'} report__total-total`}>
                                    {question.total_wrong}
                                </span>
                                <span className={`report__total-empty ${isIsolated(index) && 'd-none'}`}></span>
                                <span onClick={() => OnClickQuestionNo(index)} className={`${isIsolated(index) && 'objective-isolation'} position-relative report__total-objective`}>
                                    <ToolTip content={<ObjectiveTooltipContent
                                        name={question.objective_name}
                                        number={question.objective}
                                        description={question.objective_description}
                                    />}
                                    >
                                        <span
                                            className={`${!isIsolated(index) && 'font-bold'} report__total-objectiveData`}
                                        >
                                            {question.objective}
                                        </span>
                                    </ToolTip>
                                </span>
                            </div>
                        )
                    })
                        : <div style={{ marginLeft: ' -2px' }}>
                            <HeaderSkeleton rowColumns={25} height={104} />
                        </div>
                    }
                </div>

            </div>
        </div>
    )
}

export default Footer;