import ReportCard from "components/reportCard";
import Theme from "components/theme";

const ReportCards = () => {
  return (
    <Theme>
      <ReportCard></ReportCard>
    </Theme>
  );
};

export default ReportCards;
