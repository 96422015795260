/* eslint-disable react-hooks/exhaustive-deps */

import { CustomTableSelectCommon } from "components/common/selectComponent"
import { FC, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDistrictDetails, getSummativeAnalysisReport } from "redux/actionCreators/management"
import ActionType from "redux/actionTypes"
import { RootState } from "redux/reducers/combine"

const DropDowns: FC<{ id: number , school_id:number }> = ({ id , school_id }) => {

    const districtDetails = useSelector((state: RootState) => state.management.districtDetails)
    const [classroomDropDown, setClassroomDropDown] = useState<boolean>(false)
    const [summativeDropDown, setSummativeDropDown] = useState<boolean>(false)
    const dispatch = useDispatch()
    const [classroomDetails, setClassroomDetails] = useState(null)
    const [summative, setSummative] = useState(null)


    useEffect(() => {
        if (id) {
            dispatch(getDistrictDetails(id, school_id))
        }
    }, [id])

    useEffect(() => {
        if (classroomDetails) {
            const { gradeId, subjectId, subject } = classroomDetails
            gradeId && subjectId && summative && dispatch(getSummativeAnalysisReport(id, gradeId, subjectId, summative , school_id, subject))
        }


    }, [classroomDetails, summative])

    const { classroomsOptions } = useMemo(() => {
        if (districtDetails) {
            const { grade_subjects } = districtDetails
            if (!grade_subjects.length) {
                dispatch({
                    type: ActionType.SET_IS_REPORT_LOADING,
                    payload: false
                })
            }
            return {
                ...districtDetails,
                classroomsOptions: grade_subjects ? districtDetails.grade_subjects?.map((subject: any) => {
                    return {
                        value: {
                            subjectId: subject.fk_subject_id,
                            gradeId: subject.fk_grade_id,
                            has_data: subject.has_data,
                            subject : subject.name
                        },
                        label: subject.name,
                    }
                }) : [],
            }
        }
        return {
            classroomsOptions: []
        }

    }, [districtDetails])

    const { summativeOptions } = useMemo(() => {
        if (classroomDetails) {
            const { gradeId, subjectId } = classroomDetails;
            const { grade_subjects } = districtDetails;
            const { summatives } = grade_subjects?.find((grade: any) => grade.fk_grade_id === gradeId && grade.fk_subject_id === subjectId);
            console.log('summatives', summatives)
            return {
                summativeOptions: summatives.map((summative: any) => ({
                    value: summative,
                    label: summative
                }))
            }
        }
        return {
            summativeOptions: []
        }
    }, [classroomDetails])
    return <div className="d-flex summative-objective-analysis__header-center">
        <CustomTableSelectCommon
            isOpen={classroomDropDown}
            toggleSelect={() => setClassroomDropDown(!classroomDropDown)}
            options={classroomsOptions}
            onChange={(value: any) => setClassroomDetails(value)}
            name='grade_classes'
            placeholder='Select Class'
        />

        <CustomTableSelectCommon
            isOpen={summativeDropDown}
            toggleSelect={() => setSummativeDropDown(!summativeDropDown)}
            options={summativeOptions}
            onChange={(value: any) => setSummative(value)}
            name={'summatives'}
            placeholder='Select Test'
        />
    </div>
}
export default DropDowns
