import CustomModal from "components/common/Modal";
import { FC } from "react";

interface Props {
    isShow: boolean;
    closeModal: () => void;
}

const NearlyProficientModal : FC<Props> = ({isShow , closeModal}) =>{

    return (
        <CustomModal centered show={isShow} onHide={closeModal}>

        <div className="modal__header">
            <h6 className="modal__header-title">Nearly Proficient</h6>
        </div>
        <div className="modal__body">
            <p className="modal__body-text mb-0">Identifies objectives that are Nearly Proficient, also called "bubble objectives."</p>
        </div>
        <div className="modal__footer">
            <button
                className="btn success-btn"
                onClick={closeModal}
            >
                Got it
            </button>
        </div>
    </CustomModal>
    )
}
export default NearlyProficientModal 