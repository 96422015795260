/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { Input, TextAreaInput } from "../common/inputComponent";
import { TicketType } from "./ContactSupportModal";
import { checkIfNumber } from "util/index";
interface Step3Props {
  summary: string;
  description: string;
  data: any;
  textbookName: string;
  assessmentName: string;
  pageNumber: any;
  handleOnChange: (name: string, value: string) => void;
}

export const Step3: React.FC<Step3Props> = (props) => {
  const { summary, description, handleOnChange, data, textbookName, pageNumber, assessmentName } = props;
  return (
    <div className="support">
      <div className="support-container third-step">
        <p className="support-title third-title">
          Please enter the details of your issue below:
        </p>
        {data?.option === TicketType.BUG && (
          <>
            <div className="support__fields-summary">
              <Input
                fieldName="summary"
                isSubmitError={false}
                errorPresentBefore={false}
                value={summary}
                type="text"
                autoFocus={true}
                label="Summary"
                onChange={handleOnChange}
                error=""
              />
            </div>
            <TextAreaInput
              fieldName="description"
              isSubmitError={false}
              errorPresentBefore={false}
              value={description}
              label="Description of Bug/Issue"
              onChange={handleOnChange}
              error=""
              customClass="support-textarea"
            />
            <p className="support-description">
              Be sure to include all details necessary to reproduce your issue,
              including the date and time it occurred.
            </p>
          </>
        )}
        {data?.option === TicketType.TEXT_BOOK_ERROR && (
          <>
            <div className="support__fields-name">
              <Input
                fieldName="textbookName"
                isSubmitError={false}
                errorPresentBefore={false}
                value={textbookName}
                autoFocus={true}
                type="text"
                label="Textbook Name"
                onChange={handleOnChange}
                error=""
              />
            </div>
            <div className="support__fields-number">
              <Input
                fieldName="pageNumber"
                isSubmitError={false}
                errorPresentBefore={false}
                value={pageNumber}
                type="number"
                label="Page Number"
                onChange={handleOnChange}
                error=""
                onKeyDown = {(event: any) => checkIfNumber(event)}
              />
            </div>
            <TextAreaInput
              fieldName="description"
              isSubmitError={false}
              errorPresentBefore={false}
              value={description}
              label="Describe Textbook Error"
              onChange={handleOnChange}
              error=""
              customClass="support-textarea-medium"
            />
          </>
        )}
        {data?.option === TicketType.ASSESSMENT_ERROR && (
          <>
            <div className="support__fields-name">
              <Input
                fieldName="assessmentName"
                isSubmitError={false}
                errorPresentBefore={false}
                value={assessmentName}
                autoFocus={true}
                type="text"
                label="Assessment Name"
                onChange={handleOnChange}
                error=""
              />
            </div>
            <div className="support__fields-number">
              <Input
                fieldName="pageNumber"
                isSubmitError={false}
                errorPresentBefore={false}
                value={pageNumber}
                type="number"
                label="Question #"
                onChange={handleOnChange}
                error=""
                onKeyDown = {(event: any) => checkIfNumber(event)}
              />
            </div>
            <TextAreaInput
              fieldName="description"
              isSubmitError={false}
              errorPresentBefore={false}
              value={description}
              label="Describe Assessment Error"
              onChange={handleOnChange}
              error=""
              customClass="support-textarea-medium"
            />
          </>
        )}
        {data?.option === TicketType.FEEDBACK_FEATURE && (
          <>
            <div className="support__fields-summary">
              <Input
                fieldName="summary"
                isSubmitError={false}
                errorPresentBefore={false}
                value={summary}
                autoFocus={true}
                type="text"
                label="Summary"
                onChange={handleOnChange}
                error=""
              />
            </div>
            <TextAreaInput
              fieldName="description"
              isSubmitError={false}
              errorPresentBefore={false}
              value={description}
              label="Description of Suggestion/Feature Request"
              onChange={handleOnChange}
              error=""
              customClass="support-textarea"
            />
          </>
        )}
        {data?.option === TicketType.OTHER && (
          <>
            <div className="support__fields-summary">
              <Input
                fieldName="summary"
                isSubmitError={false}
                errorPresentBefore={false}
                value={summary}
                autoFocus={true}
                type="text"
                label="Summary"
                onChange={handleOnChange}
                error=""
              />
            </div>
            <TextAreaInput
              fieldName="description"
              isSubmitError={false}
              errorPresentBefore={false}
              value={description}
              label="Description"
              onChange={handleOnChange}
              error=""
              customClass="support-textarea"
            />
          </>
        )}
      </div>
    </div>
  );
};
