interface Question {
    id: number;
    option_values: {id: number, name: string}[];
    options: {id: number, option: string}[];
    question: string;
    type: string;
}
interface IProps {
    question: Question;
    answer: any;
    onChangeAnswer: Function;
}

const Questionnaire = ({question, answer, onChangeAnswer}: IProps) => {
    const {option_values, options} = question;

    const onAnswer = (id: number, option: number) => {
        let currentAnswer:any = {};

        if(answer){
            let isFound = false;
            const updatedOptions = answer?.options?.reduce((acc: any, curr: any, index: number) => {
                acc[index] = curr;
                if(curr.id === id){
                    acc[index] = {id, option};
                    isFound = true;
                }
                return acc;
            },[]);
            
            if(!isFound){
                updatedOptions.push({
                    id,
                    option
                });
            }
            currentAnswer = {
                ...answer,
                options: updatedOptions
            }
        }else{
            currentAnswer = {
                question_id: question?.id,
                options: [{id, option}]
            }
        }
        onChangeAnswer(currentAnswer);
    }

    return (
        <>
            
            <div className="ans-container">
                <div className="quiz-labels">
                    <div className="radio-col-1"></div>
                    {options.map((item: any) => {
                        const {id, option} = item;
                        return (
                            <div key={id} className="radio-col-1">{option}</div>
                        )
                    })}
                </div>
                <div className="ans-quiz">
                    <div className="option">
                        {option_values?.map((item: any) => {
                            const {id, name} = item;
                            let formattedName = id === 1 || id === 5 ? name?.replace(' ', '\n') : name;
                            formattedName = formattedName.replace('/', '/\n');
                            return (
                                <div key={id} className="radio-col-2">{formattedName}</div>
                            )
                        })}
                    </div>
                    
                    {options.map((option: any, index: number) => {
                        const {id} = option;
                        return (
                            <div className="option">
                                {option_values.map((_: any, idx: number) => {
                                    const isChecked = answer ? answer?.options?.some((item: any) => item.id === id && item.option === idx+1) : false
                                    return (
                                        <div className="radio-col-2">
                                            <input 
                                                onChange={() => onAnswer(id, idx+1)} 
                                                type="radio" 
                                                name={`radio1${index+1}`}
                                                value={idx+1}
                                                checked={isChecked}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
};

export default Questionnaire;
