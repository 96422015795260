/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useMemo } from "react";
import moment from "moment";
import { isChrome, browserHeights } from "util/index";
import PrintFooter from "components/common/report/Print/footer";
import checkIcon from "assets/img/checked-green.svg";
import Record from "./StandardBasedRecord";

interface Props {
  data: any[];
  classroom_name?: string;
  test_name?: string;
  lang: string;
  answerRowsHeights: any;
  school_name?: string;
  student_name?: string;
  className?: string;
}

const StandardBasedDownload: FC<Props> = (props) => {
  const {
    classroom_name,
    data: answers,
    test_name,
    lang,
    answerRowsHeights,
    school_name,
    student_name,
    className,
  } = props;

  const { answersSlices } = useMemo(() => {
    const patches = [];

    const PageHeight = () => {
      if (isChrome) {
        return 1100;
      }

      return 800;
    };

    let maxPageHeight = PageHeight();
    let heightSum = 0;
    for (let startIndex = 0, endIndex = 0; endIndex < answers?.length; ) {
      heightSum += answerRowsHeights[endIndex];

      const isHeightIncreased =
        heightSum > maxPageHeight - (!!startIndex ? 0 : 150);
      const isHeightEquals =
        heightSum === maxPageHeight - (!!startIndex ? 0 : 150);
      const isLastIndex = endIndex === answers.length - 1;

      if (isHeightIncreased || isHeightEquals || isLastIndex) {
        isHeightEquals && endIndex++;

        const slicedAnswers = answers.slice(
          startIndex,
          isLastIndex ? answers.length : endIndex
        );
        patches.push(
          slicedAnswers?.map((answer: any, index: number) => (
            <Record {...answer} lang={lang} index={index} />
          ))
        );

        if (isHeightIncreased || isHeightEquals) {
          startIndex = endIndex;
          heightSum = 0;
        } else if (isLastIndex) {
          endIndex++;
        }
      } else {
        endIndex++;
      }
    }

    return {
      answersSlices: patches.length
        ? patches
        : [
            <tr className="based-report__detail-row">
              <td className="report__table-notFound w-100 text-center">
                No Objectives Found
              </td>
            </tr>,
          ],
    };
  }, [answers, answerRowsHeights, lang]);

  const PrintContent = useMemo(() => {
    return answersSlices.map((slice: any, index: number) => (
      <div className={className}>
        <div className={`${index ? "add-page-break" : ""}`}>
          <div className="based-report-print standard-based-pdf" id="based-report">
            <div className="based-report-print__main">
              <div className="text-white">
                <div className="">
                  {/* Header  */}
                  {!index && (

                    <div className="print-report__header standard-based-report">
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <h3 className="title">
                            Standard Based Report
                            {student_name?.length ? `: ${student_name}` : ""}
                          </h3>
                          <p className="print-report__header-text">
                            {moment(new Date()).format("M-D-YY")}
                          </p>
                        </div>
                        <div>
                          <h3 className="title text-right">{classroom_name}</h3>
                          <p className="print-report__header-text text-right">
                            {school_name}
                          </p>
                        </div>
                      </div>
                    </div>

                  )}
                  {/* Info section */}
                  {!index && (
                    <div className="report__levels based__levels">
                      <div className="report__levels-container">
                        <p className="report__levels-title">
                          Performance Level (Percentage Score Range)
                        </p>

                        <div className="report__levels-percentage">
                          <div className="report__levels-row">
                            <div className="d-flex align-items-center">
                              <div className="box cheked-mark">
                                <img
                                  style={{
                                    marginBottom: "0px",
                                  }}
                                  src={checkIcon}
                                  alt=""
                                />
                              </div>

                              <p>Proficient</p>
                            </div>
                            <p>( 80% - 100%)</p>
                          </div>
                          <div className="report__levels-row">
                            <div className="d-flex align-items-center">
                              <div className="box non-proficient"></div>
                              <p>Non-Proficient</p>
                            </div>
                            <p>( 0% - 79%)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Table */}
                  <div className="table d-flex justify-content-center">
                    <table
                      id="sbr-table"
                      className={`based-report-print__table ${browserHeights} ${
                        !index ? "withHeaderPrint" : "withoutHeaderPrint"
                      }`}
                    >
                      
                      <thead>
                        <tr className="based-report__detail-header">
                          <th className="based-report__detail-objective">Objective</th>
                          <th className="based-report__detail-description">Description</th>
                          <th className="based-report__detail-weeks">
                            Nine Weeks
                            <span className="based-report__detail-headerWeeks">
                              <span className="week">1</span>
                              <span className="week">2</span>
                              <span className="week">3</span>
                              <span className="week">4</span>
                            </span>
                          </th>
                        </tr>
                      </thead>

                      <tbody>{slice}</tbody>

                    </table>
                  </div>

                </div>
              </div>
            </div>
            {/* Footer */}

            <PrintFooter />
          </div>
        </div>
      </div>
    ));
  }, [answersSlices, test_name, student_name]);

  return <>{PrintContent}</>;
};

export default StandardBasedDownload;
