const Discussion = () => {
    const titleId = "discussionIcon"
    return (
        <svg aria-labelledby={titleId} style={{position: "relative", top: "1px"}} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <title id={titleId}>Discussion</title>
            <path d="M24.007 1.01807H3.53104C2.90636 1.03574 2.3072 1.2915 1.83804 1.74073C1.57814 1.98762 1.37163 2.29403 1.23333 2.63798C1.09502 2.98194 1.02833 3.35494 1.03804 3.73024V17.1954C1.0383 17.9143 1.30083 18.6037 1.76793 19.1121C2.23502 19.6204 2.86846 19.9062 3.52904 19.9064H10.31L9.77204 26.1971L17.847 19.9064H24.008C24.6688 19.9064 25.3025 19.6208 25.7698 19.1124C26.2371 18.604 26.4998 17.9145 26.5 17.1954V3.73024C26.5 3.37398 26.4355 3.02121 26.3102 2.69209C26.185 2.36296 26.0013 2.06392 25.7698 1.81206C25.5383 1.5602 25.2634 1.36044 24.961 1.22421C24.6585 1.08797 24.3344 1.01807 24.007 1.01807Z" stroke="white" stroke-width="1.97" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
};

export default Discussion;
                