/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

// @ts-nocheck

import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Warning from "assets/img/warning-icon.png";
import {
    getActivityPDProgress
} from "redux/actionCreators/activity";
import Pagination from "components/pagination/pagination";
import {
  TableSkeleton,
} from "components/common/skeleton/TableSkeleton";
import { ProgressLimitSelect } from "components/common/selectComponent";
import { TableSelectCommon } from "components/common/selectComponent";
import { setFilterValues } from "redux/actionCreators/classroom";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowDownImg from "assets/img/table-class-before.svg";
import moment from "moment-timezone";
import { ClassroomTooltipContext } from "contexts/classroomTooltip";
import ClassroomTooltip from "components/common/classroomTooltip";
import Tabs from "components/common/Tabs/Tabs";
import { ProgressbarCore } from "components/theme/activityBar/progressBar";
import ActivitySkeleton from "./activity-table-skeleton";
import ClassroomName from "./classroomName";
import SortIndicatorIcon from "components/common/svgs/sort-indicator-icon";

interface RootState {
  auth: any;
  activity: any;
  classroom: any;
}

const options = [
  { value: "Master Report", label: "Master Report" },
  { value: "OAG", label: "OAG" },
  { value: "To Classroom", label: "To Classroom" },
  { value: "Manage", label: "Manage" },
  { value: "Way To A Report", label: "Way To A Report" },
];

const ActivityPDProgress = ({
  search, 
  currentPage, 
  offset, 
  limit, 
  setOffset,
  setGradeId,
  setTeacherId,
  setSchoolId,
  gradeId,
  schoolId,
  teacherId,
  setDistrictId,
  districtId,
  setSubjectId,
  subjectId,
  setCurrentPage,
  setCount,
  count
}: any) => {
    
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const app = useSelector((state: RootState) => state);
  const [classrooms, setClassrooms] = useState([]);
  // const [count, setCount] = useState(0);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [schoolIds, setSchoolIds] = useState([]);

  const firstRender = useRef<any>(true);
  
  const [sortBy, setSortBy] = useState("last_test_time");
  const [OrderByColumn, setOrderBy] = useState("DESC");
  
  const [animatedClassName, setAnimatedClassname] =
    useState(false);
    
    const [skeletonLoad, setSkeletonLoad] = useState(true);
    const [selectedId, setSelectedId] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (
      app.activity.type === "SET_FILTERS" &&
      app.activity.applyFilters === true
    ) {
      setIsLoading(true);
      setSchoolId(parseInt(app.activity.school_id));
      setGradeId(parseInt(app.activity.grade_id));
      setTeacherId(parseInt(app.activity.teacher_id));
      setDistrictId(parseInt(app.activity.district_id));
      setSubjectId(parseInt(app.activity.subject_id));
      let data = {
        offset,
        limit,
        sort_by: sortBy,
        order_by: OrderByColumn,
      };
      data.district_id = parseInt(app.activity.district_id);
      data.school_id = parseInt(app.activity.school_id);
      data.teacher_id = parseInt(app.activity.teacher_id);
      data.grade_id = parseInt(app.activity.grade_id);
      data.subject_id = parseInt(app.activity.subject_id);
      data.classroom_id = parseInt(
        app.activity.classroom_id
      );
      if (currentPage > 1) onPageChange(1);
      else dispatch(getActivityPDProgress({ ...data }));
    } else if (
      app.activity.type === "GET_ACTIVITY_PD_PROGRESS"
    ) {
      if (app.activity.payload?.data?.data?.count > 0) {
        setClassrooms(
          app.activity.payload.data.data
            .activity_pd_progress_info.classrooms
        );
        const school_ids =
          app.activity.payload.data.data.activity_pd_progress_info.classrooms.map(
            (x: any) => x.school_id
          );
        setSchoolIds(school_ids);
        setCount(app.activity.payload.data.data.count);
        // setIsW2AReport(app.activity?.payload?.data?.data?.way_two_a_available)
      } else {
        setClassrooms([]);
        setCount(0);
      }
      setIsLoading(false);
    } else if (
      app.activity.type === "GET_ACTIVITY_PD_PROGRESS_FAIL"
    ) {
      setIsLoading(false);
      setError(true);
      setMessage("No formatives found");
    }
  }, [app]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimatedClassname(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, [currentPage]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimatedClassname(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [animatedClassName]);

  const onPageChange = (pageNo: number) => {
    const newOffset = limit * pageNo - limit;
    setCurrentPage(pageNo);
    setOffset(newOffset);
  };

  useEffect(() => {
    if(firstRender.current){
      firstRender.current = false
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      getPdProgress(10, 0);
    }, 400);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  const getPdProgress = (
    limit: number = 10,
    newOffset?: number
  ) => {
    setIsLoading(true);
    dispatch(
      getActivityPDProgress({
        search: search,
        offset: newOffset ?? offset,
        limit,
        district_id: districtId,
        school_id: schoolId,
        grade_id: gradeId,
        teacher_id: teacherId,
        sort_by: sortBy,
        subject_id: subjectId,
        order_by: OrderByColumn,
      })
    );
  };

  const checkDuplicates = (a: any) => {
    return new Set(a).size !== 1;
  };

  useEffect(() => {
    if (count !== 0) {
        getPdProgress(Number(limit));
    }
  }, [limit, offset, OrderByColumn, sortBy]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSkeletonLoad(false);
    }, 800);

    return () => {
      clearTimeout(timer);
      setSkeletonLoad(true);
    };
  }, [isLoading]);

  const calculateHeight = (
    classrooms,
    currentSchoolName
  ) => {
    let total = classrooms.length;
    let count = 0;
    classrooms.map((eachClass) => {
      if (eachClass.school_name === currentSchoolName) {
        count += 1;
      }
    });
    let height = Math.abs((count / total) * 100);
    return { height: height, rows: count };
  };

  const setClassroomFilters = (x) => {
    const { selectedOption } = x;
    sessionStorage.setItem(
      "c_school_id",
      JSON.stringify(x.school_id)
    );
    sessionStorage.setItem(
      "c_grade_id",
      JSON.stringify(x.grade_id)
    );
    sessionStorage.setItem(
      "c_subject_id",
      JSON.stringify(x.subject_id)
    );
    sessionStorage.setItem(
      "c_district_id",
      JSON.stringify(x.district_id)
    );
    sessionStorage.setItem(
      "c_classroom_id",
      JSON.stringify(x.classroom_id)
    );
    dispatch(
      setFilterValues({
        filters: x,
        classroomSchoolId: x.school_id,
        classroomId: x.classroom_id,
        subject: x.subject_id,
        grade: x.grade_id,
      })
    );

    if (selectedOption === "OAG")
      navigate(
        `/classroom/report/oag-report/${x.classroom_id}`,
        {state: {classroomId: x.classroom_id}}
      );
    else if (selectedOption === "Master Report")
      navigate(
        `/classroom/report/master-report/${x.classroom_id}`,
        {state: {classroomId: x.classroom_id}}
      );
    else if (selectedOption === "Manage")
      navigate(
        `/management/class/${x.classroom_id}?schoolId=${x?.school_id}`
      );
    else if (selectedOption === "Way To A Report")
      navigate(
        `/classroom/report/way-to-report/${x.classroom_id}`
      );
    else navigate("/classroom");
  };

  const sortPdProgress = (col: string) => {
    setSortBy(col);

    if (
      OrderByColumn === "DESC" &&
      sortBy === col
    ) {
      setOrderBy("ASC");
    } else if (
      OrderByColumn === "ASC" &&
      sortBy === col
    ) {
      setOrderBy("DESC");
    } else {
      setOrderBy("DESC");
    }
  }

  const loading = skeletonLoad || isLoading;

//   console.log('isLoading', isLoading, skeletonLoad);
  return (
    <>
        <div className='progress-table-container'>
            <div className='table-responsive'>
              <table className='progress__table'>
                <thead>
                  <tr className='progress__table-headerRow'>
                    <th
                      colSpan={3}
                      className='progress__table-headerLast'
                      onKeyDown={e => e.key === "Enter" && sortPdProgress("last_test_time")}
                      onClick={() => {
                        sortPdProgress("last_test_time");
                      }}>
                      <p className='text-right'>Last</p>
                    </th>
                    <th
                      colSpan={2}
                      className='progress__table-headerFormative'>
                      <p className='font-medium'>
                        Goals
                      </p>
                    </th>
                    <th
                      colSpan={1}
                      className='progress__table-scheduled'>
                      <p>
                        Scheduled
                      </p>
                    </th>
                    <th
                      colSpan={1}
                      className='progress__table'>
                      <p>
                        Remediated
                      </p>
                    </th>
                    {/* <th
                      colSpan={1}
                      className='progress__table'>
                      <p>
                        Watched
                      </p>
                    </th> */}
                    
                  </tr>
                </thead>
                <tbody className='progress__table-body'>
                  <tr className='progress__table-row'>
                    <td
                      className='row-data-title className cursor-pointer'
                      role="button"
                      tabIndex={0}
                      onKeyDown={e => e.key === "Enter" && sortPdProgress("name")}
                      onClick={() => {
                        sortPdProgress("name");
                      }}>
                      Class Name
                      {sortBy === "name" && (                  
                        // <SortIndicatorIcon OrderByColumn={OrderByColumn}  style={{marginTop:"-3px"}}/>
                        <SortIndicatorIcon OrderByColumn={OrderByColumn}/>
                      )}
                    </td>
                    <td className="row-data-title hour-column">
                      Hour
                    </td>
                    <td
                      className='row-data-title assesment cursor-pointer'
                      role="button"
                      tabIndex={0}
                      onKeyDown={e => e.key === "Enter" && sortPdProgress("last_test_time")}
                      onClick={() => {
                        sortPdProgress("last_test_time");
                      }}>
                      Assessment
                      {sortBy === "last_test_time" && (
                        <SortIndicatorIcon OrderByColumn={OrderByColumn}/>
                      )}
                    </td>
                    <td
                      className='row-data-title proficiency'
                      role="button"
                      tabIndex={0}
                      onKeyDown={e => e.key === "Enter" && sortPdProgress("goals_viewed")}
                      onClick={() => {
                        sortPdProgress("goals_viewed");
                      }}>
                      Viewed
                      {sortBy === "goals_viewed" && (
                       <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                      )}
                    </td>
                    <td
                      className='row-data-title proficiency'
                      role="button"
                      tabIndex={0}
                      onKeyDown={e => e.key === "Enter" && sortPdProgress("goals_completed")}
                      onClick={() => {
                        sortPdProgress("goals_completed");
                      }}>
                      Completed
                      {sortBy === "goals_completed" && (
                       
                       <SortIndicatorIcon OrderByColumn={OrderByColumn} />

                      )}
                    </td>
                    <td
                      className='row-data-title proficiency'
                      role="button"
                      tabIndex={0}
                      onKeyDown={e => e.key === "Enter" && sortPdProgress("scheduled_assessments")}
                      onClick={() => {
                        sortPdProgress("scheduled_assessments");
                      }}>
                        Assessments
                      {sortBy === "scheduled_assessments" && (
                        <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                      )}
                    </td>
                    <td
                      className='row-data-title proficiency'
                      role="button"
                      tabIndex={0}
                      onKeyDown={e => e.key === "Enter" && sortPdProgress("remediated_students")}
                      onClick={() => {
                        sortPdProgress("remediated_students");
                      }}>
                        Students
                      {sortBy === "remediated_students" && (
                        <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                      )}
                    </td>
                    <td
                      className='row-data-title proficiency'
                      role="button"
                      tabIndex={0}
                      onClick={() => {
                        // setSortBy("watched_videos");

                        // if (
                        //   OrderByColumn === "DESC" &&
                        //   sortBy === "watched_videos"
                        // ) {
                        //   setOrderBy("ASC");
                        // } else if (
                        //   OrderByColumn === "ASC" &&
                        //   sortBy === "watched_videos"
                        // ) {
                        //   setOrderBy("DESC");
                        // } else {
                        //   setOrderBy("DESC");
                        // }
                      }}>
                        {/* Videos
                      {sortBy === "watched_videos" && (
                     
                         <SortIndicatorIcon OrderByColumn={OrderByColumn} />

                      )} */}
                    </td>
                    <td className='row-data-title options'>
                      Options
                    </td>
                  </tr>

                  {(
                    <>
                      {loading || classrooms?.length ? (
                        <>
                          {loading && [1,2,3,4,5,6,7,8,9,10].map(id => {
                            return (
                              <ActivitySkeleton
                                i={id-1}
                              />
                            )
                          })}
                          {!loading && classrooms &&
                            classrooms?.map((x: any, i) => {
                              let countIndex = 0;
                              let calculateRows =
                                calculateHeight(
                                  classrooms,
                                  x.school_name
                                ).rows;
                              let notYet = false
                                // x.formative_test_data
                                //   .total_tests_taken ===
                                //   0 &&
                                // !x.formative_test_data
                                //   .proficiency

                              let enableMasterAndClassroom = notYet;

                              if (
                                x.last_test_time != null &&
                                x.last_test_time_warning ===
                                  false
                              ) {
                                notYet = false;
                              } else if (
                                x.last_test_time != null &&
                                x.last_test_time_warning ===
                                  true
                              ) {
                                notYet = true;
                              }

                              return (
                                <>
                                  {(checkDuplicates(
                                    schoolIds
                                  ) === false ||
                                    sortBy === "name") &&
                                    sortBy !==
                                      "last_test_time" &&
                                    sortBy !==
                                      "viewed" &&
                                    sortBy !==
                                      "completed" &&
                                    sortBy !==
                                      "scheduled_assessments" &&
                                    sortBy !==
                                      "remediated_students" &&
                                    sortBy !==
                                      "watched+videos" && (
                                      <tr className='progress__table-badge'>
                                        {classrooms?.map(
                                          (
                                            eachClassRoom: any,
                                            insideIndex
                                          ) => {
                                            let schoolNameHeight =
                                              null;
                                            if (
                                              insideIndex ===
                                                0 ||
                                              classrooms[
                                                insideIndex
                                              ]
                                                .school_name !==
                                                classrooms[
                                                  insideIndex -
                                                    1
                                                ]
                                                  .school_name
                                            ) {
                                              schoolNameHeight =
                                                calculateHeight(
                                                  classrooms,
                                                  eachClassRoom.school_name
                                                );
                                              countIndex += 1;
                                            }
                                            if (
                                              !schoolNameHeight
                                            ) {
                                              return <></>;
                                            }

                                            return (
                                              <td
                                                className={`progress__table-badgeContent`}
                                                style={{
                                                  height: `${schoolNameHeight?.height}%`,
                                                  maxHeight:
                                                    "none",
                                                }}>
                                                {schoolNameHeight.rows >=
                                                  3 && (
                                                  <p className='animated-fade'>
                                                    {
                                                      eachClassRoom.school_name
                                                    }
                                                  </p>
                                                )}
                                              </td>
                                            );
                                          }
                                        )}
                                      </tr>
                                    )}
                                  <tr
                                    className={
                                      i % 2 === 0
                                        ? "progress__table-row"
                                        : "progress__table-row"
                                    }>
                                    <td className='className-data'>
                                      <div className='className-data-wrapper'>
                                        {loading ? (
                                          <></>
                                        ):(
                                          <ClassroomName
                                            setClassroomFilters={() => setClassroomFilters(x)}
                                            animatedClassName={animatedClassName}
                                            classroom_name={x.classroom_name}
                                          />
                                        )}
                                        {sortBy !==
                                        "name" ? (
                                          <>
                                            {loading ? (
                                              <></>
                                            ):(
                                              <span
                                                className={`className-data-badge ${
                                                  animatedClassName
                                                    ? "animtated-title"
                                                    : ""
                                                } ${
                                                  x
                                                    .school_name
                                                    .length >
                                                  27
                                                    ? "animated"
                                                    : ""
                                                }`}>
                                                {
                                                  x.school_name
                                                }
                                              </span>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {(checkDuplicates(
                                              schoolIds
                                            ) === true ||
                                              sortBy ===
                                                "name") &&
                                              calculateRows <=
                                                2 && (
                                                <>
                                                  {loading ? (
                                                    <></>
                                                  ):(
                                                    <span
                                                      className={`className-data-badge ${
                                                        animatedClassName
                                                          ? "animtated-title"
                                                          : ""
                                                      } ${
                                                        x
                                                          .school_name
                                                          .length >
                                                        27
                                                          ? "animated"
                                                          : ""
                                                      }`}>
                                                      {
                                                        x.school_name
                                                      }
                                                    </span>
                                                  )}
                                                </>
                                              )}
                                          </>
                                        )}
                                      </div>
                                    </td>
                                    <td className="hour-column">
                                      {loading ? (
                                        <></>
                                      ): x?.hour ? (
                                        <span className="chip">{x?.hour}</span>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                    <td className='assesment-data'>
                                      {loading && (
                                        <></>
                                      )}
                                      {!loading && notYet ? (
                                        <p className='warning-flag'>
                                          <img
                                            src={Warning}
                                            alt='warning icon'
                                          />
                                            {x.last_test_time ? (moment(
                                            x.last_test_time
                                            )
                                            .tz(
                                            "America/Chicago"
                                            )
                                            .format("M-D-YY")) : 'Not Yet'}
                                        </p>
                                      ) : !loading && x.last_test_time ? (
                                        moment(
                                          x.last_test_time
                                        )
                                          .tz(
                                            "America/Chicago"
                                          )
                                          .format("M-D-YY")
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>
                                      <ProgressbarCore
                                        width={x?.goals?.viewed === 0 && x?.goals?.total === 0
                                          ? 0
                                          : Math.ceil((x?.goals?.viewed / x?.goals?.total)*100)
                                        }
                                        isDisabled={loading || (x?.goals?.viewed === 0 && x?.goals?.total === 0
                                          ? true
                                          : false
                                        )}
                                      >
                                        <p>
                                          {x?.goals?.viewed === 0 && x?.goals?.total === 0
                                            ? "-"
                                            : `${x?.goals?.viewed} / ${x?.goals?.total}`
                                          }
                                        </p>
                                      </ProgressbarCore>
                                      {/* <div
                                        className={
                                          i % 2 === 0
                                            ? "data-graph"
                                            : "data-graph data-graph-dark"
                                        }>
                                        <span className='data-graph-data'>
                                            {x?.goals?.viewed === 0 && x?.goals?.total === 0
                                              ? "-"
                                              : `${x?.goals?.viewed} / ${x?.goals?.total}`
                                            }
                                        </span>
                                        <span
                                          className='data-graph-bg'
                                          style={{
                                            width:
                                              (x
                                                .goals
                                                ?.viewed *
                                                100) /
                                                x
                                                  .goals
                                                  ?.total +
                                              "%",
                                          }}></span>
                                      </div> */}
                                    </td>
                                    <td>
                                      <ProgressbarCore
                                        isProficient={parseInt(x.goals?.completed || 0) >= 70}
                                        color="#9080FF"
                                        width={parseInt(x.goals?.completed || 0)}
                                        isDisabled={loading}
                                      >
                                        <p>
                                          {x.goals?.completed || 0}%
                                        </p>
                                      </ProgressbarCore>
                                      {/* <div
                                        className={
                                          i % 2 === 0
                                            ? "data-graph"
                                            : "data-graph data-graph-dark"
                                        }>
                                        <span className='data-graph-data'>
                                          {parseInt(
                                                x
                                                  .goals
                                                  ?.completed || 0
                                              ) + "%"}
                                        </span>
                                        <span
                                          className={`data-graph-bg ${
                                            x
                                              .goals
                                              ?.completed >
                                            69
                                              ? "succeed"
                                              : "failed"
                                          }`}
                                          style={{
                                            width:
                                              x
                                                .goals
                                                ?.completed +
                                              "%",
                                          }}></span>
                                      </div> */}
                                    </td>
                                    <td>
                                      <ProgressbarCore
                                        color="#9080FF"
                                        width={x?.scheduled_assessments?.scheduled === 0
                                          && x?.scheduled_assessments?.total === 0
                                            ? 0
                                            : Math.ceil((x?.scheduled_assessments?.scheduled / x?.scheduled_assessments?.total)*100)
                                        }
                                        isDisabled={loading || (x?.scheduled_assessments?.scheduled === 0
                                          && x?.scheduled_assessments?.total === 0
                                            ? true
                                            : false
                                        )}
                                      >
                                        <p>
                                          {x?.scheduled_assessments?.scheduled === 0
                                            && x?.scheduled_assessments?.total === 0
                                              ? 0
                                              : `${x?.scheduled_assessments?.scheduled} / ${x?.scheduled_assessments?.total}`
                                          }
                                        </p>
                                      </ProgressbarCore>
                                      {/* <div
                                        className={
                                          i % 2 === 0
                                            ? "data-graph"
                                            : "data-graph data-graph-dark"
                                        }>
                                        <span className='data-graph-data'>
                                            {x?.scheduled_assessments?.scheduled === 0
                                                && x?.scheduled_assessments?.total === 0
                                                  ? "-"
                                                  : `${x?.scheduled_assessments?.scheduled} / ${x?.scheduled_assessments?.total}`
                                            }
                                        </span>
                                        <span
                                          className='data-graph-bg'
                                          style={{
                                            width:
                                              (x
                                                .scheduled_assessments
                                                ?.scheduled *
                                                100) /
                                                x
                                                  .scheduled_assessments
                                                  ?.total +
                                              "%",
                                          }}></span>
                                      </div> */}
                                    </td>

                                    <td>
                                      <ProgressbarCore
                                        color="#9080FF"
                                        width={x?.remediated_students?.remediated === 0
                                          && x?.remediated_students?.total === 0
                                            ? 0
                                            : Math.ceil((x?.remediated_students?.remediated / x?.remediated_students?.total)*100)
                                        }
                                        isDisabled={loading || (x?.remediated_students?.remediated === 0
                                          && x?.remediated_students?.total === 0
                                            ? true
                                            : false
                                        )}
                                      >
                                        <p>
                                          {x?.remediated_students?.remediated === 0
                                            && x?.remediated_students?.total === 0
                                              ? "-"
                                              : `${x?.remediated_students?.remediated} / ${x?.remediated_students?.total}`
                                          }
                                        </p>
                                      </ProgressbarCore>
                                      {/* <div
                                        className={
                                          i % 2 === 0
                                            ? "data-graph"
                                            : "data-graph data-graph-dark"
                                        }>
                                        <span className='data-graph-data'>
                                            {x?.remediated_students?.remediated === 0
                                                && x?.remediated_students?.total === 0
                                                  ? "-"
                                                  : `${x?.remediated_students?.remediated} / ${x?.remediated_students?.total}`
                                            }
                                        </span>
                                        <span
                                          className='data-graph-bg'
                                          style={{
                                            width:
                                              (x
                                                .remediated_students
                                                ?.remediated *
                                                100) /
                                                x
                                                  .remediated_students
                                                  ?.total +
                                              "%",
                                          }}></span>
                                      </div> */}
                                    </td>
                                    <td>
                                      {/* <ProgressbarCore
                                        color="#9080FF"
                                        width={x?.videos?.watched === 0
                                          && x?.videos?.total === 0
                                            ? 0
                                            : Math.ceil((x?.videos?.watched / x?.videos?.total)*100)
                                        }
                                        isDisabled={loading || (x?.videos?.watched === 0
                                          && x?.videos?.total === 0
                                            ? true
                                            : false
                                        )}
                                      >
                                        <p>
                                          {x?.videos?.watched === 0
                                            && x?.videos?.total === 0
                                              ? 0
                                              : `${x?.videos?.watched} / ${x?.videos?.total}`
                                          }
                                        </p>
                                      </ProgressbarCore> */}
                                    </td>
                                    <td
                                      className={`options ${loading ? 'disabled' : ''}`}>
                                      <div>
                                        <TableSelectCommon
                                          customClassName={`${
                                            classrooms.length ===
                                            2
                                              ? `center-start-${i}`
                                              : ""
                                          } ${
                                            classrooms.length ===
                                            1
                                              ? "single-select"
                                              : ""
                                          }`}
                                          isOpen={
                                            selectedId ===
                                            i + 1
                                          }
                                          toggleSelect={() => {
                                            if(loading) return;
                                            if (
                                              selectedId !==
                                              i + 1
                                            ) {
                                              setSelectedId(
                                                i + 1
                                              );
                                            } else {
                                              setSelectedId(
                                                0
                                              );
                                            }
                                          }}
                                          options={_.cloneDeep(
                                            options.map(
                                              (
                                                item: any
                                              ) => {
                                                item.disabled =
                                                    enableMasterAndClassroom &&
                                                  item.value !==
                                                    "To Classroom" &&
                                                  item.value !==
                                                    "Manage";
                                                if(item.value === 'Way To A Report'){
                                                    item.disabled = !x?.way_two_a_available;
                                                }
                                                return item;
                                              }
                                            )
                                          )}
                                          onChange={(
                                            value
                                          ) => {
                                            setClassroomFilters(
                                              {
                                                ...x,
                                                selectedOption:
                                                  value,
                                              }
                                            );
                                          }}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                        </>
                      ) : (
                        <>
                          <tr className='progress__table-row text-center'>
                            <td
                              colSpan={10}
                              className='no-data mt-4'>
                              <strong className=' p-2 mb-2 text-center'>
                                No records found
                              </strong>
                            </td>
                          </tr>
                        </>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
        </div>
    </>
  );
};

export default ActivityPDProgress;
