interface IProps {
  class?: string
}
const ReloadIconSvg = (props: IProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="33"
      viewBox="0 0 39 33"
      fill="none"
      xlinkTitle="Reload"
      className={props.class}
    >
      <g clipPath="url(#clip0_2723_7)">
        <path
          d="M30.857 0H8C3.58172 0 0 3.58172 0 8V25C0 29.4183 3.58172 33 8 33H30.857C35.2753 33 38.857 29.4183 38.857 25V8C38.857 3.58172 35.2753 0 30.857 0Z"
          fill="transparent"
        />
        <path
          d="M19.429 25.6742C17.7918 25.6733 16.1851 25.2311 14.778 24.3942L13.108 26.0642C14.8358 27.2073 16.8411 27.8608 18.9106 27.9552C20.9802 28.0496 23.0367 27.5814 24.8613 26.6003C26.686 25.6193 28.2107 24.162 29.2733 22.3836C30.3359 20.6051 30.8967 18.5719 30.896 16.5002H28.603C28.6001 18.9324 27.6326 21.2642 25.9128 22.984C24.1929 24.7038 21.8612 25.6713 19.429 25.6742ZM19.429 5.03321C16.3877 5.03321 13.4711 6.24134 11.3206 8.39182C9.17009 10.5423 7.96198 13.459 7.96198 16.5002H10.255C10.2518 14.8825 10.6773 13.2929 11.4881 11.8931C12.2989 10.4933 13.4661 9.3333 14.8709 8.53116C16.2757 7.72903 17.8679 7.31341 19.4856 7.32659C21.1032 7.33977 22.6884 7.78129 24.08 8.60621L25.75 6.93621C23.8764 5.69303 21.6774 5.03099 19.429 5.03321ZM26.309 16.5002H33.189L29.749 11.9132L26.309 16.5002ZM12.548 16.5002H5.66797L9.10797 21.0872L12.548 16.5002Z"
          fill="#537AB7"
        />
      </g>
      <defs>
        <clipPath id="clip0_2723_7">
          <rect width="38.857" height="33" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default ReloadIconSvg;
