import Tooltip from 'components/common/Tooltip'
import { FC } from 'react'

interface Props {
    showScore: boolean;
    proficiency: number;
    attempts: number;
    hideObjective: boolean;
    level: any
}

const ScoreBox: FC<Props> = (props) => {
    const { showScore, proficiency, attempts, hideObjective, level } = props

    const bgClass = proficiency < level?.FORMATIVE_PROFICIENCY ? "bg-eadb4a" : ""
    const borderProficiency = proficiency >= level?.FORMATIVE_PROFICIENCY ? 'border-proficient' : 'border-non-proficient';

    return (<Tooltip display={!showScore} content={proficiency}>
        <div className={`${borderProficiency} ${attempts > 1 ? 'prof-text' : ''}`}>
            <span style={{color: '#424242'}} className={`${hideObjective && 'visibility-hidden'} ${attempts > 1 && 'pt-2'} ${bgClass} report__table-numbers oag-font-12 small-font`}>
                {showScore && proficiency}
            </span>
            {attempts > 1 && (
                <span style={{...(!bgClass && {backgroundColor: 'unset', color: '#424242'})}} className={`${hideObjective && 'visibility-hidden'} ${bgClass} oag-bg report__table-numbers small-text`}>
                    {attempts}
                </span>
            )}
        </div>
    </Tooltip>
    )
}

export default ScoreBox;