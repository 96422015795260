/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useCallback, useRef } from "react";
import Tabs from "components/common/Tabs/Tabs";
import { useDropzone } from "react-dropzone";
import PdfIcon from "assets/img/big_pdf.svg";
import PdfImg from "assets/img/pdf-img.png";
import ZipIcon from "assets/img/zip-icon.svg";
import {
  Input,
  CustomInput,
  ValidationHint,
} from "components/common/inputComponent";
import { validateURL, handlePress, handleKeyUp } from "util/index";
import { Button } from "components/common/buttonComponent";
import { tabsToTypeMapper, TypeToTabsMapper } from "util/mappers";
import ChangeIcon from "./changeIcon";
import ToolTypeDropdown from "./toolTypeDropdown";
import GradeAndSubjectDropdown from "./gradeAndSubjectDropdown";
import ResourceDropdown from "./resourceDropdown";
import MasteryVideoDropdown from "./masteryVideoDropdown";
import { useDispatch, useSelector } from "react-redux";
import { allMasteryVideosSelector } from "redux/reducers/mastery-videos";
import { getMasteryVideos } from "redux/actionCreators/mastery-videos";
import PngIcon from "assets/img/steps-png-icon.svg";
import JpgIcon from "assets/img/steps-jpg-icon.svg";
import ChangeIconModal from "./changeIconModal";
import CustomModal from "components/common/Modal";
import Toggle from "components/common/Toggle";

const INIT_STATE = {
  id: 8,
  url: "https://aplustesting.org/district-logo-images/district_logo/tools/icon_8.svg",
  default_for: "Data Team Meeting Worksheet",
};

const INIT_STATE_For_File = {
  id: 5,
  url: "https://aplustesting.org/district-logo-images/district_logo/tools/icon_5.svg",
  default_for: "FILE_PDF",
};

const enum TABS {
  File = "File",
  URL = "URL",
  Resource = "Resource",
}

const enum TOOLTYPE {
  Data_Team_Meeting_Worksheet = "Data Team Meeting Worksheet",
  PD_Resource = "PD Resource",
  Way_to_A_Report = "Way to A Report",
  Mastery_Video_Series = "Mastery Video Series",
}

interface AddRecourceModalProps {
  isShow: boolean;
  onCancel: () => void;
  onAdd: (data: any) => Promise<any>;
  onUpdate: (data: any) => Promise<any>;
  data: any;
  gradeAndSubjects: any[];
  resources: any;
  fetchAllResources: Function;
  currSubAndGradeIndex: number;
  toolsIcons: any[];
}

export const AddToolModal: React.FC<AddRecourceModalProps> = ({
  toolsIcons,
  isShow,
  onCancel,
  onAdd,
  onUpdate,
  data,
  gradeAndSubjects,
  resources,
  fetchAllResources,
}) => {
  const dispatch = useDispatch();
  const masteryVideos = useSelector(allMasteryVideosSelector);

  const { formattedDate, tool, isAdd, isEdit } = data;
  const isDefault = isEdit || isAdd;

  const {
    type,
    link,
    label,
    tool_type,
    id,
    tools_icon,
    all_grades_subjects,
    resources: toolResources,
  } = tool || {};
  const tab = TypeToTabsMapper[type as keyof typeof TypeToTabsMapper];

  const [changeIconModal, setChangeIconModal] = useState(false);
  const [selectedSubAndGradeIndex, setSelectedSubAndGradeIndex] =
    useState<number>(-1);
  const [selectedResourceIndex, setSelectedResourceIndex] =
    useState<number>(-1);
  const [selectedMasteryVideo, setSelectedMasteryVideo] = useState<number>(-1);
  const [description, setDescription] = useState("");
  const [addToolsLoading, setAddToolsLoading] = useState<boolean>(false);
  const [toolType, setToolType] = useState<any>(
    isDefault ? tool_type : TOOLTYPE.Data_Team_Meeting_Worksheet
  );
  const [url, setUrl] = useState(isDefault ? link : "");
  const [title, setTitle] = useState(isDefault ? label : "");
  const [resourceFile, setResourceFile] = useState<any | null>(
    isEdit && type === "FILE" ? { name: link } : null
  );
  const [isDisabled, setDisabled] = useState(true);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [currentTab, setCurrentTab] = useState(isDefault ? tab : "Resource");
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState<any>(
    isDefault ? tools_icon : toolsIcons.length ? toolsIcons[7] : INIT_STATE
  );
  const [fileLoaded, setFileLoaded] = useState<any>(
    isEdit
      ? tools_icon
      : toolsIcons.length
      ? toolsIcons[4]
      : INIT_STATE_For_File
  );
  const [allGradeSubjects, setAllGradeSubjects] = useState(
    isDefault ? all_grades_subjects : false
  );

  const isChangedRef = useRef<boolean>(false);
  const firstRender = useRef<boolean>(true);

  useEffect(() => {
    if (
      tool?.tool_type === TOOLTYPE.PD_Resource &&
      gradeAndSubjects.length > 0
    ) {
      const subjectGradeIndex = gradeAndSubjects.findIndex(
        (item) =>
          item.grade_id === toolResources?.fk_grade_id &&
          item.subject_id === toolResources?.fk_subject_id
      );
      if (subjectGradeIndex !== -1) {
        setSelectedSubAndGradeIndex(subjectGradeIndex);
        fetchAllResources(gradeAndSubjects[subjectGradeIndex]).then(
          (res: any) => {
            const resIndex = res.pd.findIndex(
              (item: any) => item.id === toolResources.id
            );
            resIndex !== -1 && setSelectedResourceIndex(resIndex);
          }
        );
      }
    }
  }, [tool, gradeAndSubjects]);

  useEffect(() => {
    if (
      tool?.tool_type === TOOLTYPE.Mastery_Video_Series &&
      masteryVideos.length > 0
    ) {
      const masteryVideoIndex = masteryVideos.findIndex(
        (item: any) => item.id === tool.fk_mastery_video_id
      );
      if (masteryVideoIndex !== -1) {
        setSelectedMasteryVideo(masteryVideoIndex);
      }
    }
  }, [tool, masteryVideos]);

  useEffect(() => {
    if (isAdd) {
      return;
    }

    if (!isEdit && currentTab === TABS.File) {
      ModifyIcon(fileLoaded.default_for, "file");
    } else if (!isEdit && currentTab === TABS.Resource) {
      setToolType(TOOLTYPE.Data_Team_Meeting_Worksheet);
      ModifyIcon(
        toolsIcons.length ? toolsIcons[7].default_for : INIT_STATE.default_for,
        "resource"
      );
    }
  }, [currentTab, isAdd]);

  useEffect(() => {
    if (currentTab === TABS.File) {
      checkDisabled();
    }
  }, [resourceFile, title, currentTab]);

  useEffect(() => {
    if (currentTab === TABS.URL) {
      checkURLDisabled();
    }
  }, [description, url, title, currentTab]);

  useEffect(() => {
    if (currentTab === TABS.Resource) {
      checkResourceDisabled();
    }
  }, [url, title, currentTab]);

  useEffect(() => {
    if (firstRender) {
      firstRender.current = false;
      return;
    }

    isChangedRef.current = true;
  }, [selectedIcon]);

  const onSubmit = async () => {
    const errors = validateFields();
    let isError = false;
    if (currentTab === TABS.File) {
      isError = errors.resourceFile || errors.description;
    } else {
      isError = errors.url || errors.title;
    }

    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      setAddToolsLoading(true);
      let data: any = {};
      if (currentTab === TABS.File) {
        data = {
          description: description,
          index: 1,
        };

        if (!isEdit || resourceFile?.path?.length) {
          data["file"] = resourceFile;
        }
      } else if (currentTab === TABS.URL) {
        data = {
          link: url,
        };
      } else {
        data = {
          tool_type: toolType,
          ...(toolType === TOOLTYPE.PD_Resource && {
            resource_id: resources.pd[selectedResourceIndex].id,
          }),
          ...(toolType === TOOLTYPE.Mastery_Video_Series && {
            mastery_video_id: masteryVideos[selectedMasteryVideo].id,
          }),
        };
      }

      if (isEdit) {
        const tools = await onUpdate({
          ...data,
          type: tabsToTypeMapper[currentTab as keyof typeof tabsToTypeMapper],
          label: title,
          tool_icon_id: selectedIcon.id,
          ...(isEdit && { id }),
          ...(allGradeSubjects && { all_grades_subjects: allGradeSubjects }),
        });

        setAddToolsLoading(false);
        tools && handleCancel();
      } else {
        const tools = await onAdd({
          ...data,
          type: tabsToTypeMapper[currentTab as keyof typeof tabsToTypeMapper],
          label: title,
          tool_icon_id: selectedIcon.id,
          ...(allGradeSubjects && { all_grades_subjects: allGradeSubjects }),
        });

        setAddToolsLoading(false);
        tools && handleCancel();
      }
    }
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    isChangedRef.current = true;
    console.log(acceptedFiles, "FILES.....................");
    setResourceFile(acceptedFiles[0]);

    const splittedArray: [] = acceptedFiles[0].name.split(".");
    const extension = splittedArray[splittedArray.length - 1];

    let loadedFile = INIT_STATE_For_File.default_for;

    if (extension === "doc" || extension === "docx") {
      loadedFile = toolsIcons.length ? toolsIcons[0].default_for : "FILE_DOC";
    } else if (
      extension === "jpg" ||
      extension === "JPG" ||
      extension === "jpeg" ||
      extension === "JPEG"
    ) {
      loadedFile = toolsIcons.length ? toolsIcons[2].default_for : "FILE_JPG";
    } else if (extension === "zip") {
      loadedFile = toolsIcons.length ? toolsIcons[1].default_for : "FILE_ZIP";
    } else if (extension === "xls" || extension === "xlsx") {
      loadedFile = toolsIcons.length ? toolsIcons[3].default_for : "FILE_XLS";
    }

    ModifyIcon(loadedFile, "file");
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop,
  });

  const checkDisabled = () => {
    if (resourceFile && title) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const checkURLDisabled = () => {
    if (url && title) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const checkResourceDisabled = () => {
    if (title) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const validateFields = () => {
    const errors: any = {};
    if (currentTab === TABS.File) {
      if (resourceFile === null) {
        errors.resourceFile = "Required";
      }
    }
    if (title.trim().length === 0) {
      errors.title = "Required";
    }
    if (currentTab === TABS.URL) {
      if (url.trim().length > 0 && !validateURL(url)) {
        errors.url = "Invalid URL";
      }
      if (url.trim().length === 0) {
        errors.url = "Required";
      }
    }

    if (currentTab === TABS.Resource) {
      if (toolType.trim().length === 0) {
        errors.toolType = "Required";
      }
    }

    return errors;
  };

  const clearData = () => {
    setDescription("");
    setUrl("");
    setTitle("");
    setResourceFile(null);
    setDisabled(false);
    setClientErrors({});
    setIsSubmitError(false);
  };

  const handleCancel = () => {
    clearData();
    onCancel();
    setSelectedIcon({});
  };

  const ModifyIcon = (value: any, action: any) => {
    const filteredIcon = toolsIcons.filter((toolToFilter: any) => {
      return toolToFilter.default_for === value;
    });

    let iconToChange = filteredIcon.length
      ? filteredIcon[0]
      : toolsIcons.length
      ? toolsIcons[7]
      : INIT_STATE;

    if (action === "file") {
      iconToChange = filteredIcon.length
        ? filteredIcon[0]
        : toolsIcons.length
        ? toolsIcons[4]
        : INIT_STATE_For_File;
    }

    setSelectedIcon(iconToChange);
  };

  const handleOnChange = (name: string, value: string) => {
    isChangedRef.current = true;
    name === "url" && setUrl(value);
    name === "title" && setTitle(value);

    if (name === "toolType") {
      setToolType(value);
      setTitle("");
      setSelectedResourceIndex(-1);
      setSelectedSubAndGradeIndex(-1);
      setSelectedMasteryVideo(-1);
      ModifyIcon(value, "resource");
    }

    if (isSubmitError) {
      const isValid =
        name === "url" ? validateURL(value.trim()) : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "Required" });
      }
    }
  };

  const onChangeSubjectAndGrade = (index: string) => {
    isChangedRef.current = true;
    const selectedIndex = parseInt(index);
    setSelectedSubAndGradeIndex(selectedIndex);

    fetchAllResources(gradeAndSubjects[selectedIndex]);
  };

  const onChangeResource = (index: string) => {
    isChangedRef.current = true;
    const selectedIndex = parseInt(index);
    setSelectedResourceIndex(selectedIndex);

    const resource = resources.pd[selectedIndex];
    setTitle(resource.filename);
  };

  const onChangeMasteryVideo = (index: string) => {
    isChangedRef.current = true;
    const selectedIndex = parseInt(index);
    setSelectedMasteryVideo(selectedIndex);

    const video = masteryVideos[selectedIndex];
    setTitle(video.name);
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    dispatch(getMasteryVideos());
  }, [dispatch]);

  const isButtonDisabled =
    (!isEdit ? addToolsLoading : !isChangedRef.current || addToolsLoading) ||
    isDisabled;

  const onChangeAllGradeSubjects = (event: any) => {
    setAllGradeSubjects(event.target.checked);
  };

  return (
    <>
      <CustomModal
        centered
        show={isShow}
        onEscapeKeyDown={handleCancel}
        className="addToolModalWidth"
      >
        <div className="addToolModal">
          <div className="modal__header">
            <h6 className="modal__header-title">{`${
              isEdit ? "Edit" : "Add"
            } Tool ${formattedDate}`}</h6>
          </div>
          <div className="modal__tabs add-tool-modal ">
            <Tabs
              options={["URL", "Resource", "File"]}
              currentTab={currentTab}
              onChangeTab={(option: string) => {
                clearData();
                setCurrentTab(option);
              }}
              customClass="custom-padding"
              selectedOption={data?.tool?.type}
              isEdit={isEdit}
              minWidth={currentTab === 'URL' ? 55 : 110}
            />
          </div>
          <div className="modal__body">
            {currentTab === TABS.URL ? (
              <div className="modal__form modal-form">
                <ChangeIcon
                  url={selectedIcon.url}
                  onClickChangeIcon={() => setChangeIconModal(true)}
                />
                <ValidationHint isSubmitError={isSubmitError} />

                <CustomInput
                  fieldName="url"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["url"] !== undefined}
                  value={url}
                  type="text"
                  label="URL"
                  autoFocus={true}
                  onChange={handleOnChange}
                  error={clientErrors.url}
                  errorMessage="Invalid URL"
                  overlayClick={() =>
                    setClientErrors({
                      ...clientErrors,
                      url: "required",
                    })
                  }
                />
                <Input
                  fieldName="title"
                  isSubmitError={isSubmitError}
                  errorPresentBefore={clientErrors["title"] !== undefined}
                  value={title}
                  type="text"
                  label="Label"
                  onChange={handleOnChange}
                  error={clientErrors.title}
                />
              </div>
            ) : currentTab === "Resource" ? (
              <>
                <ChangeIcon
                  url={selectedIcon?.url}
                  onClickChangeIcon={() => setChangeIconModal(true)}
                />
                <div className="modal-form">
                  <ToolTypeDropdown
                    handleOnChange={handleOnChange}
                    toolType={toolType}
                  />
                  {toolType === TOOLTYPE.PD_Resource && (
                    <>
                      <GradeAndSubjectDropdown
                        label="Select Subject and Grade"
                        handleOnChange={onChangeSubjectAndGrade}
                        selectedValue={selectedSubAndGradeIndex}
                        options={gradeAndSubjects}
                      />

                      <ResourceDropdown
                        label="Select Resource"
                        handleOnChange={onChangeResource}
                        selectedValue={selectedResourceIndex}
                        options={resources.pd}
                        disabled={selectedSubAndGradeIndex === -1}
                      />
                    </>
                  )}

                  {toolType === TOOLTYPE.Mastery_Video_Series && (
                    <>
                      <MasteryVideoDropdown
                        label="Select Mastery Video"
                        handleOnChange={onChangeMasteryVideo}
                        selectedValue={selectedMasteryVideo}
                        options={masteryVideos}
                      />
                    </>
                  )}
                  {toolType !== TOOLTYPE.PD_Resource &&
                    toolType !== TOOLTYPE.Mastery_Video_Series && (
                      <div className="modal__form-row">
                        <label>Label</label>
                        <input
                          name="label"
                          type="text"
                          value={title}
                          onChange={(e: any) =>
                            handleOnChange("title", e.target.value)
                          }
                        />
                        <span className="d-none"></span>
                      </div>
                    )}
                </div>
              </>
            ) : (
              <>
                <div className="modal__form modal-form">
                  <ChangeIcon
                    url={selectedIcon.url}
                    onClickChangeIcon={() => setChangeIconModal(true)}
                  />
                  <p className="dragIcon">
                    Drag the resource to the area below or click Choose File
                  </p>
                  <small className="px-2 text-danger">
                    {clientErrors.resourceFile}
                  </small>
                  <div className="uploads__pdf">
                    <div
                      {...getRootProps({ className: "uploads__pdf-content" })}
                    >
                      <input {...getInputProps()} />
                      <div className="uploads__pdf-icon">
                        {resourceFile ? (
                          ""
                        ) : (
                          <div>
                            <img src={PdfIcon} alt="Pdf" />
                            <img
                              className="zip-icon ms-2"
                              src={ZipIcon}
                              alt="Zip"
                            />
                          </div>
                        )}

                        {(isEdit || resourceFile?.name.length) &&
                          (resourceFile?.name.endsWith(".jpg") ||
                          resourceFile?.name.endsWith(".jpeg") ||
                          resourceFile?.name.endsWith(".JPG") ||
                          resourceFile?.name.endsWith(".JPEG") ? (
                            <img className="" src={JpgIcon} alt="" />
                          ) : resourceFile?.name.endsWith(".png") ? (
                            <img className="" src={PngIcon} alt="" />
                          ) : resourceFile?.name.endsWith(".zip") ? (
                            <img className="" src={ZipIcon} alt="" />
                          ) : (
                            // default case.
                            <img src={PdfImg} alt="" />
                          ))}

                        <p>Drag Here</p>
                      </div>
                    </div>
                    <p className="text-white mt-1 mb-0">
                      {isEdit ? label : resourceFile?.name}
                    </p>
                    <div className="uploads__pdf-button">
                      <button
                        className="btn success-btn"
                        type="button"
                        onClick={() => {
                          open();
                        }}
                      >
                        Choose File
                      </button>
                    </div>
                  </div>
                  <div className="modal__form-row  dsawq">
                    <label>Label</label>
                    <input
                      type="text"
                      value={title}
                      onChange={(e: any) =>
                        handleOnChange("title", e.target.value)
                      }
                    />
                    <span className="d-none"></span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="grades-toggle">
            <p>All Subjects/Grades</p>
            <Toggle 
              checked={allGradeSubjects} 
              onChange={onChangeAllGradeSubjects} 
              tabIndex={0}  
              labelClassName="mb-0 me-3 mark-switch"/>
          </div>
          <div className="modal__footer mt-3">
            <Button
              buttonText="Cancel"
              onClick={handleCancel}
              className="btn cancel-btn"
              disabled={addToolsLoading}
              type="button"
            />
            <Button
              buttonText={isEdit ? "Save" : "Add"}
              onClick={onSubmit}
              className={`btn success-btn btn-lg add-btn ${
                isButtonDisabled && "btn-disabled"
              }`}
              loading={addToolsLoading}
              disabled={isButtonDisabled}
              type="button"
              id="save-button"
            />
          </div>
        </div>
      </CustomModal>
      {changeIconModal && (
        <ChangeIconModal
          onCancel={() => setChangeIconModal(false)}
          onSubmit={() => setChangeIconModal(false)}
          icons={toolsIcons}
          setSelectedIcon={(data: any) => {
            setSelectedIcon(data);
            isChangedRef.current = true;
          }}
          selectedIcon={selectedIcon}
        />
      )}
    </>
  );
};
