 /* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import IPage from "interfaces/page";
import logging from "config/logging";
import Theme from "components/theme/index";
import SuperAdmins from "components/management/super-admins";
import DistrictAdmins from "components/management/district-admins";
import Districts from "components/management/districts";
import Schools from "components/management/schools";
import Classes from "components/management/classes";
import Students from "components/management/students";
import { useSelector, useDispatch } from "react-redux";
import ActivityIndicator from "components/common/activityIndicator";
import StlButton from "components/common/stlButton";
import cookie from "js-cookie";
import StlSingleButton from "components/common/stlSingleDistrictButton";
import {
  setDistrictMId,
  setSchoolMId,
  setClassroomMId
} from "redux/actionCreators/management";
import useWindowScrollPosition from "hooks/useWindowScrollPosition";
import { AppCookies, SCROLL_VALUE_MANAGEMENT } from "util/constants";

interface RootState {
  auth: any;
  activity: any;
  management: any;
  classroom: any;
}

const ManagementPage: React.FunctionComponent<IPage> = (props) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { auth, activity, management, classroom } = useSelector(
    (state: RootState) => state
  );
  const navigate = useNavigate();
  const userRole = cookie.get(AppCookies.role);

  const scrollValue = useWindowScrollPosition(SCROLL_VALUE_MANAGEMENT, true);

  // const dispatch = useDispatch();
  // const [loading, setLoading] = useState<any>("false");
  // const [loaded, setLoaded] = useState<any>("false");
  // const [reload, setReload] = useState<any>(false);
  // const [url, setUrl] = useState<any>(null);
  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props.name]);

  useEffect(() => {
    if (userRole === "District Admin") {
      const findExisting = activity.districtsList.find((item:any) => item.id === management.district_m_id)
      const districtId = findExisting?.id ?? activity.districtsList[0]?.id
      const districtName = findExisting?.district_name ?? activity.districtsList[0]?.district_name
      !findExisting && dispatch(setDistrictMId(activity.districtsList[0]?.id))
      navigate(
        `/management/district/${districtId}`,{state: {name: districtName}}
      );
    } else if (userRole === "School Admin") {
      const findExisting = activity.schoolsList.find((item:any) => item.id === management.school_m_id)
      const schoolId = findExisting?.id ?? activity.schoolsList[0]?.id
      !findExisting && dispatch(setSchoolMId(activity.schoolsList[0]?.id))
      navigate(
        `/management/school/${schoolId}`
      );
    } else if (userRole === "Classroom Admin") {
      const classroom_id = Number(sessionStorage.getItem("classroom_m_id"));
      const findExisting = classroom.classrooms.find((item:any) => item.id === classroom_id)
      const classId =  findExisting?.id ?? classroom.classrooms[0]?.id
      !findExisting && dispatch(setClassroomMId(classroom.classrooms[0]?.id))
      navigate(
        `/management/class/${classId}?schoolId=${activity.schoolsList[0]?.id}`
      );
    }
  }, [pathname]);

  // useEffect(() => {
  //   const stlLoading = localStorage.getItem("stlLoading");

  //   setLoading(stlLoading);
  // }, [reload]);

  // useEffect(() => {
  //   let interval: any;

  //   if (management.stlLoading) {
  //     const report = async () => {
  //       let id: any = localStorage.getItem("id");
  //       id = parseInt(id);
  //       const data = await getStlReport(id);
  //       if (data.data.data) {
  //         // setLoaded(data.data.data.url ? "true" : false);
  //         // setUrl(data.data.data.url);
  //         dispatch({
  //           type: ActionType.SET_STL_LOADED,
  //           payload: data.data.data.url ? true : false,
  //         });
  //         // dispatch({
  //         //   type: ActionType.SET_STL_LOADING,
  //         //   payload: data.data.data.url ? true : false,
  //         // });
  //         dispatch({
  //           type: ActionType.SET_STL_URL,
  //           payload: data.data.data.url,
  //         });
  //         clearInterval(interval);
  //       }
  //     };

  //     interval = setInterval(() => {
  //       report();
  //     }, 3000);

  //     return () => clearInterval(interval);
  //   }
  //   if (!management.stlLoading && interval) {
  //     clearInterval(interval);
  //   }
  // }, [management.stlLoading]);

  const onNavigate = () => {
    sessionStorage.setItem(SCROLL_VALUE_MANAGEMENT, `${scrollValue}`)
  }
  return (
    <>
      <Theme>
        {auth.currentUser?.role === "Super Admin" && (
          <div
            className={`management-st-wrap management-accordian text-white  ${
              auth.currentUser?.role !== "Super Admin" ? "d-none" : ""
            }`}
          >
            <div className="flex-space">
              {activity?.active && (
                <ActivityIndicator completed={activity?.completed} />
              )}
              {management.stlLoading && <StlButton />}
          {management.stlSingleLoading && <StlSingleButton />}
            </div>

            {auth && auth.currentUser?.role === "Super Admin" ? (
              <SuperAdmins />
            ) : (
              ""
            )}
            {auth && auth.currentUser?.role !== "School Admin" ? (
              <>
                <DistrictAdmins />
                <Districts onNavigate={onNavigate} />
              </>
            ) : (
              ""
            )}
            <Schools onNavigate={onNavigate} page="super-admin" />
            <Classes onNavigate={onNavigate} setfirst={() => {}} />
            <Students onNavigate={onNavigate} page="super-admin" />
          </div>
        )}
      </Theme>
    </>
  );
};

export default ManagementPage;
