const LeftArrowYellow = (props: any) => {
  const { width, height } = props;
  const titleId = "nextArrowYellow";
  return (
    <svg
      aria-labelledby={titleId}
      xmlns="http://www.w3.org/2000/svg"
      width={width || "15"}
      height={height || "21"}
      viewBox="0 0 15 21"
      fill="none"
      opacity={props.opacity ? props.opacity : "1"}
      
    >
      <title id={titleId}>Previous</title>
      <path
        d="M14.7158 1.70282L14.7158 19.2972C14.7158 19.8475 14.4205 20.322 13.9258 20.5663C13.4312 20.8107 12.8736 20.7575 12.4344 20.4241L0.844279 11.627C0.488315 11.3568 0.284159 10.946 0.284159 10.5C0.284159 10.054 0.488315 9.64322 0.844279 9.37303L12.4344 0.575861C12.8736 0.242462 13.4312 0.189302 13.9259 0.433651C14.4205 0.678003 14.7158 1.15246 14.7158 1.70282Z"
        fill="#FFE000"
      />
      <path
        d="M0.568316 10.5C0.568316 10.8568 0.73164 11.1854 1.01641 11.4016L12.6065 20.1988C12.9579 20.4655 13.404 20.508 13.7997 20.3125C14.1954 20.117 14.4317 19.7375 14.4317 19.2972L14.4317 1.70282C14.4317 1.26253 14.1954 0.882963 13.7997 0.687485C13.404 0.492006 12.9579 0.534536 12.6065 0.801254L1.01641 9.59843C0.73164 9.81457 0.568316 10.1432 0.568316 10.5ZM9.17939e-07 10.5C9.62186e-07 9.99388 0.224048 9.48775 0.672142 9.14764L12.2622 0.350466C13.3846 -0.501434 15 0.296513 15 1.70282L15 19.2972C15 20.7035 13.3846 21.5014 12.2622 20.6495L0.672142 11.8524C0.224048 11.5122 8.73693e-07 11.0061 9.17939e-07 10.5Z"
        fill="white"
      />
    </svg>
  );
};

export default LeftArrowYellow;
