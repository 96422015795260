import { FC, useContext, useEffect, useState } from "react";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import { useNavigate } from "react-router-dom";
import AssignModal from "./AssignModal";
import { SupportTicketSuperAdminContext } from "contexts/supportTicketSuperAdmin";
import ArchivedChat from "components/common/archivedChat";
import { getSuperAdminChatById } from "services/supportTicketSuperAdmin";


const TicketDetailsHeader: FC = () => {
  const navigate = useNavigate();
  const {ticketDetails} = useContext(SupportTicketSuperAdminContext);
  const {details, support_chat_id, chat_history} = ticketDetails || {};
  const {from_admin_id = 0} = details || {};

  const [showModal, setShowModal] = useState(false);
  const [chatById, setChatById] = useState<any>(null!);
  const [chatQueryParams, setChatQueryParams] = useState<any>(null!)
  const [chatByIdLoading, setChatByIdLoading] = useState(false);
  const [selectedChat, setSelectedChat] = useState<any>(null!);

  const onClickChatHistory = () => {
    if(from_admin_id){
      window.open(`/super-admin/support-center?chatHistory=1&from=${from_admin_id}`, '_blank', 'noreferrer');
    }
  }


  const onClickChat = async (e: any, id: number) => {
    e.preventDefault();
    setChatById(id);
    setChatByIdLoading(true);
    document.getElementById("chat-session-cont")?.scrollIntoView();
    
    setChatQueryParams({
        limit: 50,
        offset: 0
    })
  }

  useEffect(() => {
    if(chatQueryParams && chatById){
        const fetchChat = async (chatById: any) => {
            const chat = await getSuperAdminChatById({...chatQueryParams, chatId: chatById});
            if(chat){
                if(chatQueryParams.offset === 0){
                    setSelectedChat(chat);
                }else{
                    setSelectedChat((prevChat: any) => ({
                        ...prevChat,
                        messages: [...prevChat.messages, ...chat.messages]
                    }));
                }
                
            }
            setChatByIdLoading(false);
        }
        fetchChat(chatById);
    }
  },[chatQueryParams, chatById])

  return (
    <>
      <div className="SupportTicket__TicketInfo__Header SupportTicketDetails__header  d-flex align-items-center justify-content-between ">
        <div className="d-flex align-items-center">
          <div className="SupportTicket__TicketInfo__Header__backArrow">
            <span className="accordion__header-button">
                <img
                  onClick={() => navigate(-1)}
                  src={ArrowIcon}
                  alt="arrow"
                  className="rotate-90"
                />
              </span>
          </div>
          <h3 className="text-white SupportTicket__TicketInfo__Header__text">Ticket Details</h3>
        </div>
        <div className="d-flex align-items-center headerButtons">
          <button onClick={() => setShowModal(true)} className="SupportTicket__TicketInfo__Header__button mr-3 assignBtn">Assign</button>
          {
            support_chat_id && (
              <button 
              className="SupportTicket__TicketInfo__Header__button attach-chat mr-3"
              style={{width: 'auto'}}
              onClick={(e) => onClickChat(e, support_chat_id)}
              >
                View Attached Chat
              </button>
            )
          }
          <button style={{opacity: from_admin_id && chat_history ? 1 : 0.4}} disabled={!from_admin_id || !chat_history} onClick={onClickChatHistory} className="SupportTicket__TicketInfo__Header__button chatHistoryBtn">Chat History</button>
        </div>
      </div>
      {showModal && (
        <AssignModal loading={false} onAdd={() => {}} onCancel={() => setShowModal(false)} />
      )}

      {chatById && (
          <ArchivedChat 
              chat={selectedChat}
              loading={chatByIdLoading}
              onClose={() => {
                  setChatById(null!);
                  setChatQueryParams(null!);
              }}
              setChatQueryParams={setChatQueryParams}
          />
      )}

    </>
  );
};
export default TicketDetailsHeader;
