/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import IPage from "interfaces/page";
import logging from "config/logging";
import Theme from "components/theme/index";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import ClassDetails from "components/management/class-details";
import StudentsForClass from "components/management/students-for-class";

import { useDispatch, useSelector } from "react-redux";
import { getClassBreadcrumb } from "redux/actionCreators/management";
import cookie from "js-cookie";
import { AppCookies } from "util/constants";

const ClassPage: React.FunctionComponent<IPage> = (props) => {
  const dispatch = useDispatch();

  const [classData, setClassData] = useState<any>({});
  const { id, name } = useParams();
  const { management, activity, classroom } = useSelector((state: any) => state);
  const navigate = useNavigate();
  const userRole = cookie.get(AppCookies.role);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0,0);
  },[]);

  useEffect(() => {
    if(userRole === 'Classroom Admin' && management.classroom_m_id > 0 && classroom.classrooms.length && activity.schoolsList.length){
        const selectedClass = classroom.classrooms.find(((item: any) => item.id  === management.classroom_m_id))
        navigate(`/management/class/${selectedClass?.id}?schoolId=${activity.schoolsList[0]?.id}`)
    }
 },[management.classroom_m_id,classroom.classrooms,activity.schoolsList])

  useEffect(() => {
    dispatch(getClassBreadcrumb(Number(id)));
    logging.info(`Loading ${props.name}`);
  }, [props.name,dispatch,id]);
  
  const selectedClass = classroom.classrooms.find(((item: any) => item.id  === management.classroom_m_id))
  const hideStudents = selectedClass?.students_count === 0 && userRole === 'Classroom Admin'

  return (
    <>
      <Theme> 
        <div className="management-st-wrap management-accordian text-white">
          {userRole !== "Classroom Admin" && <Breadcrumbs page="class" />}
          {id && (
            <ClassDetails classData={classData} setClassData={setClassData} setLoading={setLoading} classId={id || ""} classroomName={name || ""} />
          )}
          {hideStudents ? "" : (id && management?.breadcrumbs && management?.breadcrumbs?.grade !== 9) && 
            <StudentsForClass 
              district_id={classData?.district_id}
              schoolName={classData?.school_name || ''}
              isLoading={loading} 
              page="class" 
              schoolId={classData?.school_id || ''}  
              classId={id || ""} 
            />
          }
        </div>
      </Theme>
    </>
  );
};

export default ClassPage;
