import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import SearchIcon from "assets/img/search-icon.svg";
import EmptyRecord from "components/common/EmptyRecord";
import HtmlParser from "components/common/htmlParser";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import { SupportTicketSuperAdminContext } from "contexts/supportTicketSuperAdmin";
import { SingleTicketMessage } from "interfaces/supportTicketSuperAdmin";
import moment from "moment";
import _ from 'lodash';
import { updateMessageSeen } from "services/suppot-ticket";
import { useDispatch } from "react-redux";
import { fetchUserMenu } from "redux/actionCreators/auth";

const MessageTable = () => {

    const { ticketDetails: { messages, loading }, selectedMessage, setSelectedMessage, fetchSupportTicketMessages } = useContext(SupportTicketSuperAdminContext);
    const [callMassagesAPI, setCallMassagesAPI] = useState(false);
    const dispatch = useDispatch();

    const [queryParams, setQueryParams] = useState({
        limit: 20,
        offset: 0,
        search: ''
    });
    
    const {search} = queryParams;
    const onClickMessage = async (index: number) => {
        const message = messages[index];
        const {attachments} = message;
        
        setSelectedMessage({ ...messages[index], attachmentCount: attachments?.length || 0 });
        console.log('message:' , message);
        if(message?.id) {
            await updateMessageSeen(message.id);
            dispatch(fetchUserMenu());
            
            setCallMassagesAPI(res => !res);
        }

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSearch = useCallback(
        _.debounce((e) => {
            fetchSupportTicketMessages({search: e.target.value, offset: 0, limit: queryParams.limit});
        }, 1000),
        []
    );

    useEffect(() => {
        fetchSupportTicketMessages({offset: 0, limit: queryParams.limit});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callMassagesAPI]);
    
    // const onScroll = (e: any) => {
    //     console.log('onScroll');
    //     const isAtBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    //     if(!isAtBottom){
    //         return;
    //     }
        
    //     if(message_count === messages.length){
    //         console.log('no more messages!');
    //         return;
    //     }

    //     setQueryParams((prev: any) => ({
    //         ...prev,
    //         offset: (prev.offset + 1) * prev.limit
    //     }));

    //     fetchSupportTicketMessages({...queryParams, offset: (queryParams.offset+1) * queryParams.limit});
    // }

    const updatedMessages = useMemo(() => {
        const messageArray: any[] = [];
        const messagesInitialObj = {
            id: -1,
            message: '',
            color_hex: '',
            type: '',
            date: '',
            name: '',
            name_initials: ''
        }
        if(messages.length <= 5) {
            for(let i = 0; i < 5; i++) {
                if(messages[i] !== undefined){
                    messageArray.push(messages[i]);
                } else {
                    messageArray.push(messagesInitialObj);
                }
            }
    
        } else {
            for(let i = 0; i<messages.length; i++) {
                 messageArray.push(messages[i]);
            }
        }
        return messageArray;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages]);

    useEffect(() => {
        if(!selectedMessage) {
            const id = updatedMessages[0].id;
            if(id !== -1) {
                onClickMessage(0);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedMessages]);

    return (
        <div className=" SupportTicketDetails__detailedInfo__messages">
            <div className="SupportTicketDetails__detailedInfo__messages__header">
                <h2>Messages</h2>
                <div className="section-search-field animated mx-md-0 mx-3">
                    <div className="icon">
                        <img src={SearchIcon} alt="" />
                    </div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search Messages"
                        value={search}
                        onChange={(e) => {
                            setQueryParams((prev: any) => ({...prev, search: e.target.value}));
                            handleSearch(e);
                        }}
                    />
                </div>
            </div>
            <div className="overflow-auto tableContainer messages-history">
                <div className="messageTable">
                    <table className="SupportTicketDetails__detailedInfo__messages__table">
                        <thead className="SupportTicketDetails__detailedInfo__messages__table__header">
                            <tr>
                                <td className="SupportTicketCircle"></td>
                                <td className="pe-4 column1">Ticket Messages</td>
                                <td className="px-4">Sender</td>
                                <td className="px-4">Sent</td>
                            </tr>
                        </thead>
                        <tbody className="SupportTicketDetails__detailedInfo__messages__table__body">
                        {/* <TableSkeleton columns={6} count={4} height={80} />  */}
                            {loading ? 
                                <TableSkeleton columns={4} count={5} height={37} />
                            :
                                <>
                                    {updatedMessages?.length ?
                                        updatedMessages?.map(({ id, from, sender, sent, seen }: SingleTicketMessage, index: number) => {
                                            // className={`${index ? 'bodyRow' : 'maintableRow'}`}
                                            return <tr onClick={id !== -1 ? () =>  onClickMessage(index) : () => {} } className={`${selectedMessage?.id === id ? 'maintableRow' : 'bodyRow'}`}>
                                                
                                                <td className="bullet-column" style={{width: '45px'}}>
                                                    {!seen && id !== -1 && selectedMessage?.id !== id  &&  <p className="redCircle"></p>}
                                                </td>

                                                <td className=" pe-4 column1">
                                                    <p className={` fw-bold mb-0 ${from?.length > 15 && 'ellipsis-only'}`}>
                                                        <HtmlParser html={from || ''} />
                                                    </p>
                                                </td>
                                                <td className="px-4">{sender || ''}</td>
                                                <td className="px-4">{sent && (moment(sent)?.format("M-D-YY h:mm A") || '')}</td>
                                            </tr>
                                        })
                                    :
                                        <EmptyRecord />
                                    }
                                </>

                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div >

    )
}
export default MessageTable