/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import classnames from "classnames";
import ArrowIcon from "assets/img/steps-arrow.svg";

interface Step2Props {
  goToNext: (nextStep: number) => void;
  handleOption: (name: string, value: any) => void;
}

export const Step2: React.FC<Step2Props> = ({ goToNext, handleOption }) => {
  const options = [
    "The Screen I’m On Now",
    "Activity",
    "Classroom (including Monitor Screen)",
    "Management",
    "Login",
    "Student Portal",
    "Other",
  ];

  const handleClickOption = (name: string, value: any) => {
    handleOption(name, value === 'The Screen I’m On Now' ? window.location.href: value);
    goToNext(3);
  };

  return (
    <div className="supportStep support">
      <div className="support-container first-step">
        <p className="support-title">
          Select the screen you’re having trouble with:
        </p>
        {options.map((item, i) => {
          return (
            <div
              className={classnames(
                "support__steps",
                i % 2 === 0 ? "": "dark",
                i === 0 ? "first" : "",
                i === options.length - 1 ? "last" : "",
              )}
              onClick={() => handleClickOption("screen", item)}
            >
              <span>{item}</span>
              <img src={ArrowIcon} alt='select' className="support__steps-icon"/>
            </div>
          );
        })}
      </div>
    </div>
  );
};
