import React from "react";

const ClassRoomIcon = () => {
  const titleId = "activityIcon";
  return (
    <svg
      className="none-active"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      aria-labelledby={titleId}
    >
      <title id={titleId}>Classroom</title>
      <path
        d="M10.1248 0.621826L0.757812 2.97485V4.5399L10.1248 2.19014L19.4918 4.5399V2.97485L10.1248 0.621826ZM5.80181 5.32678C4.22744 5.37955 2.67326 5.72786 1.20781 6.35636L0.757812 6.57403V18.6548H8.88681C9.01127 18.8928 9.19143 19.0907 9.40897 19.2284C9.62651 19.3661 9.87367 19.4386 10.1253 19.4386C10.377 19.4386 10.6241 19.3661 10.8417 19.2284C11.0592 19.0907 11.2394 18.8928 11.3638 18.6548H19.4928V6.5773L19.0428 6.35963C17.5777 5.73125 16.0239 5.38294 14.4498 5.33004C12.9694 5.35859 11.5055 5.67407 10.1268 6.26167C8.74767 5.6727 7.28308 5.35611 5.80181 5.32678ZM5.80181 6.89509C7.03063 6.9464 8.24486 7.20336 9.40181 7.65694V16.3812C8.24233 15.9484 7.02744 15.717 5.80181 15.6955C4.5756 15.7102 3.35974 15.9418 2.20181 16.3812V7.65477C3.35468 7.18556 4.57136 6.92882 5.80181 6.89509ZM14.4478 6.89509C15.6784 6.92952 16.8951 7.187 18.0478 7.65694V16.3812C16.8899 15.9418 15.674 15.7102 14.4478 15.6955C13.2222 15.717 12.0073 15.9484 10.8478 16.3812V7.65477C12.0049 7.20192 13.2191 6.9457 14.4478 6.89509Z"
        fill="#194792"
      />
    </svg>
  );
};

export default ClassRoomIcon;
