import React from "react";
import IPage from "interfaces/page";
import Theme from "components/theme/index";
import Resources from "components/professionalDevelopment/resources";
import ProfesssionalDevelopmentProvider from "contexts/professionalDevelopment";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";
import { useSelectedClassroom } from "hooks/useSelectedClassroom";

const ClassroomResources: React.FunctionComponent<IPage> = () => {
  const app = useSelector((state: RootState) => state);
  const selectedClassroom = useSelectedClassroom();
  return (
    <>
      <Theme>
        {(app?.classroom?.grade === 9 || selectedClassroom?.students_count === 0) ? (
          <ProfesssionalDevelopmentProvider>
            <div className="management-st-wrap management-accordian text-white">
              <Resources />
            </div>
          </ProfesssionalDevelopmentProvider>
        ) : (
          <div className="management-st-wrap management-accordian text-white">
            <Resources />
          </div>
        )}
      </Theme>
    </>
  );
};

export default ClassroomResources;
