import { v4 as uuidv4 } from 'uuid';
import axios from 'util/axios'
import config from "config/config";


const getFileExtention = (filename: string) =>  filename.split('.').pop();

export const FileImage = async (file: any) => {

    const fileName = `${uuidv4()}.${getFileExtention(file.name)}`;
    const signedURL = await getS3SignUrl(fileName, file.type);
    
    if(!signedURL){
        return 
    }

    await pushImageToS3(signedURL, file);
    
    return `${config.defaults.s3BucketURL}/cms/${fileName}`
}

const getS3SignUrl = async (fileName: string, fileType: string) => {

    const signedResponse = await axios
    .post(`${config.defaults.api_url}/api/v3/cms/signed/url`, {
        "file_name": fileName,
        "file_type": fileType
    })

    return signedResponse.data?.data.url
}

const pushImageToS3 = async (presignedUrl: string, uploadPhoto: any) => {
    const contentType = (uploadPhoto.type && uploadPhoto.type==='image/svg+xml') ? 'image/svg+xml' : 'image/*';
    const myHeaders = new Headers({ 'Content-Type': contentType });

    await fetch(presignedUrl, {
        method: 'PUT',
        headers: myHeaders,
        body: uploadPhoto
    });
}