import Skeleton from "react-loading-skeleton";

interface IProps {
    isLoading?: boolean;
}

const LoadingSkeleton = ({isLoading=false}:IProps) => {
    return (
        <div className="tool-box">
            <div className="tool-img skeleton">
            <Skeleton enableAnimation={isLoading} baseColor="#00000033" highlightColor="#00000033" borderRadius={8} width={70} height={65} style={{margin: "0 auto"}} />
            </div>
            <div className="tool-icon skeleton">
                <Skeleton enableAnimation={isLoading} baseColor="#00000033" highlightColor="#00000033" borderRadius={8} width={90} height={16} style={{margin: "10px auto 0px"}} />
            </div>
        </div>
    )
};

export default LoadingSkeleton;
