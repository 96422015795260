import React from "react";
const titleId = "messageIcon";
const MessageIcon = ({ currentTab, title="Welcome" }: { currentTab?: string, title?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill="none"
      aria-labelledby={titleId}
    >
      <title id={titleId}>{title}</title>
      <path
        d="M3.92773 2C3.17925 2 2.57031 2.60894 2.57031 3.35742V18.4658C2.57031 19.2143 3.17925 19.8232 3.92773 19.8232H10.9639V23.4897L15.8512 19.8232H24.0723C24.8207 19.8232 25.4297 19.2143 25.4297 18.4658V3.35742C25.4297 2.60894 24.8207 2 24.0723 2H3.92773ZM3.92773 0H24.0723C25.9241 0 27.4297 1.50559 27.4297 3.35742V18.4658C27.4297 20.3176 25.9241 21.8232 24.0723 21.8232H16.5181L9.96584 26.7387C9.55141 27.043 8.96386 26.7439 8.96386 26.2298V21.8232H3.92773C2.07591 21.8232 0.570312 20.3176 0.570312 18.4658V3.35742C0.570312 1.50559 2.07591 0 3.92773 0Z"
        fill={currentTab ? currentTab === "welcome" ? "white" : "#3e3e3e":"white"}
      />
    </svg>
  );
};

export default MessageIcon;
