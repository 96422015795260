export const SupportTicketTypeMapper = {
    BUG: 'Bug',
    TEXT_BOOK_ERROR: 'Textbook Error',
    CHAT_TRANSCRIPT: 'Chat Transcript',
    ASSESSMENT_ERROR: 'Assessment Error',
    FEEDBACK_FEATURE: 'Feedback Error',
    OTHER: 'Other'
}

export const TicketDetailsStatusMapper = {
    SUBMITTED: 'Submitted',
    NEED_MORE_INFO: 'Need More Info',
    IN_PROGRESS: 'In Progress',
    RESOLVED: 'Resolved'
}

export const StatusClassMapper = {
    SUBMITTED: 'Subbmitted',
    NEED_MORE_INFO: 'NeedMoreInfo',
    IN_PROGRESS: 'InProgress',
    RESOLVED: 'Resolved',
    ASSIGNED: 'assigned'
}

export const getChatSessionsStatusClass = (status: string) => {
    switch(status){
        case 'TICKET_CREATED':
            return 'Subbmitted'
        case 'NEEDS_RESPONSE':
        case 'EXPIRED':
            return 'NeedMoreInfo'
        case 'CLOSED':
        case 'RESOLVED':
            return 'Resolved'
        case 'IN_PROGRESS':
            return 'InProgress'
    }
}

export const ChatSessionsStatusMapper = {
    TICKET_CREATED: 'Ticket Created',
    IN_PROGRESS: 'In Progress',
    RESOLVED: 'Resolved',
    NEEDS_RESPONSE: 'Needs Response',
    CLOSED: 'Closed',
    EXPIRED: 'Expired'
}

export const getStatuses = (statuses: string[]) => {
    return statuses.map(status => TicketDetailsStatusMapper[status as keyof typeof TicketDetailsStatusMapper]);
}


export const tabsToTypeMapper = {
  File: 'FILE',
  URL: 'URL',
  Resource: 'RESOURCE'
}

export const TypeToTabsMapper = {
    FILE: 'File',
    URL: 'URL',
    RESOURCE: 'Resource'
  }