import DiscussionIframe from "./discussionsFrame";
import DiscussionHeader from "./discussionsHeader";

const Discussions = () => {
  return (
    <div className="Discussions">
      <div className="Discussions__container position-relative">
        <div className="Discussions__container__header d-sm-flex align-items-center justify-content-between">
          <DiscussionHeader />
        </div>
        <div className="overflow-auto setting_border">
          <DiscussionIframe />
        </div>
      </div>
    </div>
  );
};
export default Discussions;
