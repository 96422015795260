const StudentTableHeader = () => {
  return (
    <thead>
      <tr className="based-report__detail-header">
        <th className="based-report__detail-objective">Objective</th>
        <th className="based-report__detail-description">Description</th>
        <th className="based-report__detail-weeks">Attempts</th>
        <th className="based-report__detail-weeks">
          Nine Weeks
          <div className="based-report__detail-headerWeeks">
            <div className="week">1</div>
            <div className="week">2</div>
            <div className="week">3</div>
            <div className="week">4</div>
          </div>
        </th>
      </tr>
    </thead>
  );
};
export default StudentTableHeader;
