import { FC } from "react";
import checkIcon from "assets/img/checked-green.svg";

interface Props {
  objective_number: string;
  objective_description: string;
  objective_description_es: any[];
  nine_week: any[];
  lang: string
}
const Record: FC<Props> = ({
  objective_number,
  objective_description,
  objective_description_es,
  nine_week,
  lang
}) => {
  return (
    <tr className="download-row">
      <td className="table__objective">
        <p className="maintext">{objective_number}</p>
      </td>
      <td className="table__description">
        <p className="maintext">{lang === 'en' ? objective_description : objective_description_es}</p>
      </td>
      <td className="table__nine">
        <div className="d-flex weeks">
          {nine_week?.map((item, index) => {
            return (
              <div key={index}>
                {item === "P" ? (
                  <div className="box proficient check-marked"><img src={checkIcon} alt=''/></div>
                ) : item === "B" ? (
                  <div className="box non-proficient" />
                ) : (
                  <div className="empty"/>
                )}
              </div>
            );
          })}
        </div>
      </td>
    </tr>
  );
};
export default Record;
