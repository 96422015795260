import Attachment from "assets/img/steps-png-icon.svg";
import NoAttachment from "assets/img/black_file.png";

import TicketReplayModel from "./ticketRepleyModal";
import { SupportTicketSuperAdminContext } from "contexts/supportTicketSuperAdmin";
import { useContext, useState } from "react";
import HtmlParser from "components/common/htmlParser";
import { TicketDetailsStatusMapper } from "util/mappers";
import ClipPathGroupSvg from "assets/csvs/ClipPathGroupSvg";
import ScrollingText from "components/common/ScrollingText";

const TicketMessageViewer = () => {
  const { selectedMessage, ticketDetails, setSelectedMessage } = useContext(
    SupportTicketSuperAdminContext
  );
  const [showModal, setShowModal] = useState<boolean>(false);

  if (!ticketDetails) {
    return null;
  }
  const { from, message, attachmentCount, attachments, reply_of, sender } =
    selectedMessage || {};
  console.log("selectedMessage: ", selectedMessage);
  const { details, messages } = ticketDetails;
  console.log("selectedMessage: ", ticketDetails);

  const msg = messages.find((item: any) => item.id === reply_of);
  const replyMessage = msg ? msg : null;
  const {
    status = "",
    assigned_to = "",
    assessment_name = "",
    text_book_name = "",
    page_number,
  } = details || {};
  const _status =
    TicketDetailsStatusMapper[
      status as keyof typeof TicketDetailsStatusMapper
    ] || "";

  console.log("assessment_name: ", assessment_name);
  console.log("assessment_name: ", !!assessment_name);
  console.log("text_book_name: ", text_book_name);
  console.log("text_book_name: ", !!text_book_name);
  console.log("page_number: ", page_number);
  console.log("page_number: ", !!page_number);

  const onClickFile = (file: any) => {
    if (!file) {
      return;
    }

    const { url, filename } = file;

    if (!url) {
      return;
    }

    const alink = document.createElement("a");
    alink.href = url;
    alink.download = `${filename}` || "file_from_alpha";
    alink.click();
  };

  const onClickViewMessage = () => {
    setSelectedMessage(replyMessage);
  };

  return (
    <>
      <div className="SupportTicket__messageViewer">
        <div className="SupportTicket__messageViewer__header d-flex align-items-center justify-content-between ">
          <h3 className="text-white SupportTicket__messageViewer__text">
            Message Viewer
          </h3>
          {selectedMessage && (
            <button
              style={{ opacity: assigned_to ? 1 : 0.4 }}
              disabled={!assigned_to}
              onClick={() => setShowModal(true)}
              type="button"
              className="SupportTicket__messageViewer__header__btn"
            >
              Reply
            </button>
          )}
        </div>
        <div className="SupportTicket__messageViewer__body d-flex ">
          <div className="SupportTicket__messageViewer__body__info">
            {selectedMessage ? (
              <>
                <div className=" tagline">
                  <div className="d-flex align-items-center justify-content-between flex-wrap ">
                    <p className="text-black mb-0">
                      <strong className="me-2">From:</strong> {sender}
                    </p>
                    <p className="text-black mb-0">
                      <strong className="me-2">Status Change:</strong> {_status}
                    </p>
                  </div>

                  {from === "Original Ticket" && (
                    <div className="mt-1">
                      {!!assessment_name && (
                        <span
                          style={{
                            marginRight: "15px",
                          }}
                        >
                          <strong className="me-2">Assessment Name:</strong>{" "}
                          {assessment_name}
                        </span>
                      )}

                      {!!text_book_name && (
                        <span
                          style={{
                            marginRight: "15px",
                          }}
                        >
                          <strong className="me-2">Book Name:</strong>{" "}
                          {text_book_name}
                        </span>
                      )}

                      {!!page_number && (
                        <span
                          style={{
                            marginRight: "15px",
                          }}
                        >
                          <strong className="me-2">Page Number:</strong>{" "}
                          {page_number}
                        </span>
                      )}
                    </div>
                  )}
                </div>
                <div className="SupportTicket__messageViewer__body__info__message-scroll">
                  <div className="text-black pb-3 pt-2">
                    <HtmlParser html={message || ""} />
                  </div>
                  {!!reply_of && (
                    <div className="SupportTicket__messageViewer__body__info__replied">
                      <p className="mb-0 fw-bold SupportTicket__messageViewer__body__info__replied__tag">
                        Message Being Replied To
                      </p>
                      <p className=" SupportTicket__messageViewer__body__info__replied__message">
                        <HtmlParser html={replyMessage?.message || ""} />
                      </p>
                      <div className="d-flex align-items-center justify-content-end">
                        {!!attachmentCount && (
                          <p className="mb-0 SupportTicket__messageViewer__body__info__replied__attachment">
                            <span
                              style={{
                                marginRight: "4px",
                                position: "relative",
                                bottom: "3px",
                              }}
                            >
                              <ClipPathGroupSvg />
                            </span>
                            {`${attachmentCount} ${
                              attachmentCount > 1 ? "Attachments" : "Attachment"
                            }`}
                          </p>
                        )}
                        <p
                          onClick={onClickViewMessage}
                          className="cursor-pointer mb-0 SupportTicket__messageViewer__body__info__replied__viewMessage"
                        >
                          View Message
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <p className="SupportTicket__messageViewer__body__info__selectMessage">
                Please select a message above
              </p>
            )}
          </div>
          <div className="SupportTicket__messageViewer__body__attachments">
            <p className="SupportTicket__messageViewer__body__attachments__text text-white">
              Attachments
            </p>
            {Array(3)
              .fill(null)
              .map((_, index) => {
                const file = attachments ? attachments[index]?.filename : null;
                return (
                  <div
                    onClick={() => onClickFile(attachments[index])}
                    className={`SupportTicket__messageViewer__body__attachments__Image ${
                      file ? "cursor-pointer" : ""
                    }`}
                  >
                    <img
                      src={file ? Attachment : NoAttachment}
                      alt="pngImage"
                    />
                    <ScrollingText tag="p">
                      {file || ""}
                    </ScrollingText>
                  </div>
                ); 
              })}
          </div>
        </div>
      </div>
      {showModal && (
        <TicketReplayModel
          show={showModal}
          onClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};

export default TicketMessageViewer;
