/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import PdfBlendIcon from "assets/img/big_pdf.svg";
import EditIcon from "assets/img/pencil-icon.svg";
import DeleteIcon from "assets/img/trash-icon.svg";
import PdfIcon from "assets/img/pdf-alt.svg";
import wwwIcon from "assets/img/www.png";
import DragIcon from "assets/img/drag-icon.svg";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import {
  AddRecourceModal,
  DeleteRecourceModal,
  UpdateRecourceModal,
} from "./resources-modals";
import { getSubjectAndGradeList } from "services/get-subgrade-list";
import {
  getAllResources,
  addResource,
  deleteResource,
  updateResource,
  sortResource,
  resetResources,
} from "redux/actionCreators/resources";
import { downloadPdfFile } from "util/index";
import ZipIcon from "assets/img/zip-icon.svg";

interface RootState {
  resources: any;
}

const EditResources = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resources } = useSelector((state: RootState) => state);
  const [type, setType] = useState("BOOKS");
  const [addResourceModal, setAddResourceModal] = useState(false);
  const [subAndGradeList, setSubAndGradeList] = useState<null | any>(null);
  const [selectedSubAndGrade, setSelectedSubAndGrade] = useState("0");
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [currentResource, setCurrentResource] = useState<null | any>(null);
  const [currentSubGrade, setCurrentSubGrade] = useState<null | any>(null);

  const getSubGrade = async () => {
    try {
      let response = await getSubjectAndGradeList(true);
      setSubAndGradeList(response?.data?.grade_subjects_array);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getSubGrade();
    dispatch(resetResources());
  }, []);

  useEffect(() => {
    if (selectedSubAndGrade !== "0") {
      let currentSubAndGrade =
        subAndGradeList &&
        subAndGradeList.filter(
          (item: any) => item.name === selectedSubAndGrade
        )[0];
      setCurrentSubGrade(currentSubAndGrade);
      dispatch(
        getAllResources(
          currentSubAndGrade.grade_id,
          currentSubAndGrade.subject_id
        )
      );
    } else if (type === "OTHER") {
      dispatch(getAllResources(0, 0));
    }
  }, [selectedSubAndGrade, resources.resourceFlag]);

  const onAddResource = (data: any) => {
    let finalData = {
      category: type,
      ...data,
    };
    if (currentSubGrade) {
      finalData = {
        ...finalData,
        grade_id: type === "OTHER" ? 0 : currentSubGrade.grade_id,
        subject_id: type === "OTHER" ? 0 : currentSubGrade.subject_id,
      };
    }
    dispatch(addResource(finalData, setAddResourceModal));
  };
  const onDeleteResource = () => {
    console.log(currentResource);
    dispatch(deleteResource(currentResource.id, setDeleteModal));
  };
  const onUpdateResource = (data: any) => {
    dispatch(updateResource(currentResource.id, data, setUpdateModal));
  };
  const handleEnd = (result: any) => {
    console.log(result, "END.......................");
    let resourcesCopy = { ...resources.allResources };
    if (type === "books".toUpperCase()) {
      let booksCopy = [...resourcesCopy.books];
      const [reorderedItem] = booksCopy.splice(result.source.index, 1);
      booksCopy.splice(result.destination.index, 0, reorderedItem);
      resourcesCopy.books = booksCopy;
      dispatch(sortResource(resourcesCopy, booksCopy, "books".toUpperCase()));
    } else if (type === "LAST_YEAR") {
      let assessmentsCopy = [...resourcesCopy.assessments_2016];
      const [reorderedItem] = assessmentsCopy.splice(result.source.index, 1);
      assessmentsCopy.splice(result.destination.index, 0, reorderedItem);
      resourcesCopy.assessments_2016 = assessmentsCopy;
      dispatch(sortResource(resourcesCopy, assessmentsCopy, type));
    } else if (type === "CURRENT_YEAR") {
      let assessmentsCopy = [...resourcesCopy.assessments_2021];
      const [reorderedItem] = assessmentsCopy.splice(result.source.index, 1);
      assessmentsCopy.splice(result.destination.index, 0, reorderedItem);
      resourcesCopy.assessments_2021 = assessmentsCopy;
      dispatch(sortResource(resourcesCopy, assessmentsCopy, type));
    } else if (type === "pd".toUpperCase()) {
      let pdCopy = [...resourcesCopy.pd];
      const [reorderedItem] = pdCopy.splice(result.source.index, 1);
      pdCopy.splice(result.destination.index, 0, reorderedItem);
      resourcesCopy.pd = pdCopy;
      dispatch(sortResource(resourcesCopy, pdCopy, "pd".toUpperCase()));
    } else if (type === "other".toUpperCase()) {
      let otherCopy = [...resourcesCopy.other];
      const [reorderedItem] = otherCopy.splice(result.source.index, 1);
      otherCopy.splice(result.destination.index, 0, reorderedItem);
      resourcesCopy.other = otherCopy;
      dispatch(sortResource(resourcesCopy, otherCopy, "other".toUpperCase()));
    }
  };

  const handleClickRecord = (item: any) => {
    if (item.is_file) {
      downloadPdfFile(item.file_url, item.filename, item.file_type);
    } else {
      let url = item.file_url;
      window.open(
        url.includes("http") ? item.file_url : "https://".concat(item.file_url),
        "_blank"
      );
    }
  };

  return (
    <>
      <div className="accordion__header open cursor-default">
        <div className="accordion__header-heading">
          <span className="accordion__header-button">
            <img
              onClick={() => navigate(-1)}
              src={ArrowIcon}
              alt="arrow"
              className="rotate-90"
            />
          </span>
          <h6>Edit Resources</h6>
        </div>
        <div className="resources__header-button">
          <button
            onClick={() => setAddResourceModal(true)}
            className={`btn outline-btn outline-btn-lg ${
              !currentSubGrade || selectedSubAndGrade === "0"
                ? type === "OTHER"
                  ? ""
                  : "disabled"
                : ""
            }`}
          >
            Add Resource
          </button>
        </div>
        {/* <div>
          <div className="accordion__header-buttons"></div>
        </div> */}
      </div>
      <div className="accordion__content">
        <div className="resources__header">
          <div className="resources__header-search">
            <div className="modal__form-welcome">
              <div className="modal__form-row">
                <label>Subject and Grade</label>
                <select
                  value={selectedSubAndGrade}
                  onChange={(e) => setSelectedSubAndGrade(e.target.value)}
                  placeholder=""
                  className={`form-select welcome-edit-select ${
                    type === "OTHER" ? "disabled" : ""
                  }`}
                  disabled={type === "OTHER"}
                >
                  <option value="0" disabled>
                    Select Subject and Grade
                  </option>
                  {subAndGradeList
                    ? subAndGradeList.map((item: any, i: number) => {
                        return (
                          <option key={i} value={item.name}>
                            {item.name}
                          </option>
                        );
                      })
                    : ""}
                </select>
              </div>
            </div>
            <div className="resources__tabs">
              <button
                className={`btn btn-sm resources__tabs-btn ${
                  type === "BOOKS" ? "active" : ""
                }`}
                onClick={() => setType("BOOKS")}
              >
                Books
              </button>

              <button
                className={`btn btn-sm resources__tabs-btn ${
                  type === "CURRENT_YEAR" ? "active" : ""
                }`}
                onClick={() => setType("CURRENT_YEAR")}
              >
                2024-25 Assessments
              </button>

              <button
                className={`btn btn-sm resources__tabs-btn ${
                  type === "LAST_YEAR" ? "active" : ""
                }`}
                onClick={() => setType("LAST_YEAR")}
              >
                2023-24 Assessments
              </button>
              <button
                className={`btn btn-sm resources__tabs-btn ${
                  type === "PD" ? "active" : ""
                }`}
                onClick={() => setType("PD")}
              >
                PD
              </button>
              <button
                className={`btn btn-sm resources__tabs-btn ${
                  type === "OTHER" ? "active" : ""
                }`}
                onClick={() => {
                  setType("OTHER");
                  selectedSubAndGrade !== "0" && setSelectedSubAndGrade("0");
                  dispatch(getAllResources(0, 0));
                }}
              >
                Other
              </button>
            </div>
          </div>
        </div>
        <div className="resources__files resources-files-container">
          <div>
            {type === "BOOKS" ? (
              <DragDropContext onDragEnd={handleEnd}>
                <Droppable droppableId="books">
                  {(provided) => (
                    <ul
                      className={`resources__files-list ${
                        resources.allResources &&
                        resources.allResources?.books?.length
                          ? "padding-bottom"
                          : ""
                      }`}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {resources.allResources &&
                      resources.allResources?.books?.length ? (
                        resources.allResources?.books.map(
                          (item: any, index: number) => (
                            <Draggable
                              key={index}
                              draggableId={index.toString()}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  style={{ cursor: "grabbing" }}
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  key={index}
                                  className={
                                    snapshot.isDragging
                                      ? "resources__files-item"
                                      : "resources__files-item"
                                  }
                                >
                                  <div
                                    className={`resources__files-name ${
                                      index % 2 === 0 ? "" : "light"
                                    }`}
                                    onClick={() => handleClickRecord(item)}
                                  >
                                    <div className="icon">
                                      {item.is_file ? (
                                        <img
                                          src={
                                            item.file_type === "ZIP"
                                              ? ZipIcon
                                              : PdfIcon
                                          }
                                          alt={
                                            item.file_type === "ZIP"
                                              ? "zip-icon........"
                                              : "pdf-icon........"
                                          }
                                          className="zip-icon"
                                        />
                                      ) : (
                                        <img
                                          src={wwwIcon}
                                          alt="url-icon........"
                                        />
                                      )}
                                    </div>
                                    <p className="resources__files-title">
                                      {item?.filename.split("/").pop()}
                                    </p>
                                  </div>
                                  <div
                                    className={`resources__files-description ${
                                      index % 2 === 0 ? "" : "dark"
                                    }`}
                                  >
                                    <p className="resources__files-desc">
                                      {item?.description}
                                    </p>
                                    <div className="resources__files-buttons">
                                      <div className="resources__files-edit">
                                        <img
                                          onClick={() => {
                                            console.log(item);
                                            setCurrentResource({
                                              ...item,
                                              index: index + 1,
                                            });
                                            setUpdateModal(true);
                                          }}
                                          src={EditIcon}
                                          alt="edit-icon"
                                        />
                                        <img
                                          onClick={() => {
                                            setCurrentResource({
                                              ...item,
                                              index: index + 1,
                                            });
                                            setDeleteModal(true);
                                          }}
                                          src={DeleteIcon}
                                          alt="delete-icon"
                                        />
                                      </div>
                                      <div className="resources__files-drag">
                                        <img src={DragIcon} alt="drag-icon" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          )
                        )
                      ) : (
                        <div className="resources__empty">
                          <div className="resources__empty-content">
                            <img
                              className="resources__empty-icon"
                              src={PdfIcon}
                              alt="PDF icon"
                            />
                            <p className="resources__empty-paragraph">
                              Click Add Resource to upload files
                            </p>
                          </div>
                        </div>
                      )}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              ""
            )}
            {type === "ASSESSMENTS_2022_23" ? (
              <DragDropContext onDragEnd={handleEnd}>
                <Droppable droppableId="assessments16">
                  {(provided) => (
                    <ul
                      className={`resources__files-list ${
                        resources.allResources &&
                        resources.allResources?.assessments_2016?.length
                          ? "padding-bottom"
                          : ""
                      }`}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {resources.allResources &&
                      resources.allResources?.assessments_2016?.length ? (
                        resources.allResources?.assessments_2016.map(
                          (item: any, index: number) => (
                            <Draggable
                              key={index}
                              draggableId={index.toString()}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  style={{ cursor: "grabbing" }}
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  key={index}
                                  className={
                                    snapshot.isDragging
                                      ? "resources__files-item"
                                      : "resources__files-item"
                                  }
                                >
                                  <div
                                    className={`resources__files-name ${
                                      index % 2 === 0 ? "" : "light"
                                    }`}
                                    onClick={() => handleClickRecord(item)}
                                  >
                                    <div className="icon">
                                      {item.is_file ? (
                                        <img
                                          src={
                                            item.file_type === "ZIP"
                                              ? ZipIcon
                                              : PdfIcon
                                          }
                                          alt={
                                            item.file_type === "ZIP"
                                              ? "zip-icon........"
                                              : "pdf-icon........"
                                          }
                                          className="zip-icon"
                                        />
                                      ) : (
                                        <img
                                          src={wwwIcon}
                                          alt="url-icon........"
                                        />
                                      )}
                                    </div>
                                    <p className="resources__files-title">
                                      {item?.filename.split("/").pop()}
                                    </p>
                                  </div>
                                  <div
                                    className={`resources__files-description ${
                                      index % 2 === 0 ? "" : "dark"
                                    }`}
                                  >
                                    <p className="resources__files-desc">
                                      {item?.description}
                                    </p>
                                    <div className="resources__files-buttons">
                                      <div className="resources__files-edit">
                                        <img
                                          onClick={() => {
                                            console.log(item);
                                            setCurrentResource({
                                              ...item,
                                              index: index + 1,
                                            });
                                            setUpdateModal(true);
                                          }}
                                          src={EditIcon}
                                          alt="edit-icon"
                                        />
                                        <img
                                          onClick={() => {
                                            setCurrentResource({
                                              ...item,
                                              index: index + 1,
                                            });
                                            setDeleteModal(true);
                                          }}
                                          src={DeleteIcon}
                                          alt="delete-icon"
                                        />
                                      </div>
                                      <div className="resources__files-drag">
                                        <img src={DragIcon} alt="drag-icon" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          )
                        )
                      ) : (
                        <div className="resources__empty">
                          <div className="resources__empty-content">
                            <img
                              className="resources__empty-icon"
                              src={PdfIcon}
                              alt="PDF icon"
                            />
                            <p className="resources__empty-paragraph">
                              Click Add Resource to upload files
                            </p>
                          </div>
                        </div>
                      )}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              ""
            )}
            {type === "CURRENT_YEAR" ? (
              <DragDropContext onDragEnd={handleEnd}>
                <Droppable droppableId="assessments21">
                  {(provided) => (
                    <ul
                      className={`resources__files-list ${
                        resources.allResources &&
                        resources.allResources?.assessments_2021?.length
                          ? "padding-bottom"
                          : ""
                      }`}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {resources.allResources &&
                      resources.allResources?.assessments_2021?.length ? (
                        resources.allResources?.assessments_2021.map(
                          (item: any, index: number) => (
                            <Draggable
                              key={index}
                              draggableId={index.toString()}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  style={{ cursor: "grabbing" }}
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  key={index}
                                  className={
                                    snapshot.isDragging
                                      ? "resources__files-item"
                                      : "resources__files-item"
                                  }
                                >
                                  <div
                                    className={`resources__files-name ${
                                      index % 2 === 0 ? "" : "light"
                                    }`}
                                    onClick={() => handleClickRecord(item)}
                                  >
                                    <div className="icon">
                                      {item.is_file ? (
                                        <img
                                          src={
                                            item.file_type === "ZIP"
                                              ? ZipIcon
                                              : PdfIcon
                                          }
                                          alt={
                                            item.file_type === "ZIP"
                                              ? "zip-icon........"
                                              : "pdf-icon........"
                                          }
                                          className="zip-icon"
                                        />
                                      ) : (
                                        <img
                                          src={wwwIcon}
                                          alt="url-icon........"
                                        />
                                      )}
                                    </div>
                                    <p className="resources__files-title">
                                      {item?.filename.split("/").pop()}
                                    </p>
                                  </div>
                                  <div
                                    className={`resources__files-description ${
                                      index % 2 === 0 ? "" : "dark"
                                    }`}
                                  >
                                    <p className="resources__files-desc">
                                      {item?.description}
                                    </p>
                                    <div className="resources__files-buttons">
                                      <div className="resources__files-edit">
                                        <img
                                          onClick={() => {
                                            console.log(item);
                                            setCurrentResource({
                                              ...item,
                                              index: index + 1,
                                            });
                                            setUpdateModal(true);
                                          }}
                                          src={EditIcon}
                                          alt="edit-icon"
                                        />
                                        <img
                                          onClick={() => {
                                            setCurrentResource({
                                              ...item,
                                              index: index + 1,
                                            });
                                            setDeleteModal(true);
                                          }}
                                          src={DeleteIcon}
                                          alt="delete-icon"
                                        />
                                      </div>
                                      <div className="resources__files-drag">
                                        <img src={DragIcon} alt="drag-icon" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          )
                        )
                      ) : (
                        <div className="resources__empty">
                          <div className="resources__empty-content">
                            <img
                              className="resources__empty-icon"
                              src={PdfIcon}
                              alt="PDF icon"
                            />
                            <p className="resources__empty-paragraph">
                              Click Add Resource to upload files
                            </p>
                          </div>
                        </div>
                      )}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              ""
            )}
            {type === "PD" ? (
              <DragDropContext onDragEnd={handleEnd}>
                <Droppable droppableId="pd">
                  {(provided) => (
                    <ul
                      className={`resources__files-list ${
                        resources.allResources &&
                        resources.allResources?.pd?.length
                          ? "padding-bottom"
                          : ""
                      }`}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {resources.allResources &&
                      resources.allResources?.pd?.length ? (
                        resources.allResources?.pd.map(
                          (item: any, index: number) => (
                            <Draggable
                              key={index}
                              draggableId={index.toString()}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  style={{ cursor: "grabbing" }}
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  key={index}
                                  className={
                                    snapshot.isDragging
                                      ? "resources__files-item"
                                      : "resources__files-item"
                                  }
                                >
                                  <div
                                    className={`resources__files-name ${
                                      index % 2 === 0 ? "" : "light"
                                    }`}
                                    onClick={() => handleClickRecord(item)}
                                  >
                                    <div className="icon">
                                      {item.is_file ? (
                                        <img
                                          src={
                                            item.file_type === "ZIP"
                                              ? ZipIcon
                                              : PdfIcon
                                          }
                                          alt={
                                            item.file_type === "ZIP"
                                              ? "zip-icon........"
                                              : "pdf-icon........"
                                          }
                                          className="zip-icon"
                                        />
                                      ) : (
                                        <img
                                          src={wwwIcon}
                                          alt="url-icon........"
                                        />
                                      )}
                                    </div>
                                    <p className="resources__files-title">
                                      {item?.filename.split("/").pop()}
                                    </p>
                                  </div>
                                  <div
                                    className={`resources__files-description ${
                                      index % 2 === 0 ? "" : "dark"
                                    }`}
                                  >
                                    <p className="resources__files-desc">
                                      {item?.description}
                                    </p>
                                    <div className="resources__files-buttons">
                                      <div className="resources__files-edit">
                                        <img
                                          onClick={() => {
                                            console.log(item);
                                            setCurrentResource({
                                              ...item,
                                              index: index + 1,
                                            });
                                            setUpdateModal(true);
                                          }}
                                          src={EditIcon}
                                          alt="edit-icon"
                                        />
                                        <img
                                          onClick={() => {
                                            setCurrentResource({
                                              ...item,
                                              index: index + 1,
                                            });
                                            setDeleteModal(true);
                                          }}
                                          src={DeleteIcon}
                                          alt="delete-icon"
                                        />
                                      </div>
                                      <div className="resources__files-drag">
                                        <img src={DragIcon} alt="drag-icon" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          )
                        )
                      ) : (
                        <div className="resources__empty">
                          <div className="resources__empty-content">
                            <img
                              className="resources__empty-icon"
                              src={PdfIcon}
                              alt="PDF icon"
                            />
                            <p className="resources__empty-paragraph">
                              Click Add Resource to upload files
                            </p>
                          </div>
                        </div>
                      )}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              ""
            )}
            {type === "OTHER" ? (
              <DragDropContext onDragEnd={handleEnd}>
                <Droppable droppableId="other">
                  {(provided) => (
                    <ul
                      className={`resources__files-list ${
                        resources.allResources &&
                        resources.allResources?.other?.length
                          ? "padding-bottom"
                          : ""
                      }`}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {resources.allResources &&
                      resources.allResources?.other?.length ? (
                        resources.allResources?.other.map(
                          (item: any, index: number) => (
                            <Draggable
                              key={index}
                              draggableId={index.toString()}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  style={{ cursor: "grabbing" }}
                                  {...provided.draggableProps}
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  key={index}
                                  className={
                                    snapshot.isDragging
                                      ? "resources__files-item"
                                      : "resources__files-item"
                                  }
                                >
                                  <div
                                    className={`resources__files-name ${
                                      index % 2 === 0 ? "" : "light"
                                    }`}
                                    onClick={() => handleClickRecord(item)}
                                  >
                                    <div className="icon">
                                      {item.is_file ? (
                                        <img
                                          src={
                                            item.file_type === "ZIP"
                                              ? ZipIcon
                                              : PdfIcon
                                          }
                                          alt={
                                            item.file_type === "ZIP"
                                              ? "zip-icon........"
                                              : "pdf-icon........"
                                          }
                                          className="zip-icon"
                                        />
                                      ) : (
                                        <img
                                          src={wwwIcon}
                                          alt="url-icon........"
                                        />
                                      )}
                                    </div>
                                    <p className="resources__files-title">
                                      {item?.filename.split("/").pop()}
                                    </p>
                                  </div>
                                  <div
                                    className={`resources__files-description ${
                                      index % 2 === 0 ? "" : "dark"
                                    }`}
                                  >
                                    <p className="resources__files-desc">
                                      {item?.description}
                                    </p>
                                    <div className="resources__files-buttons">
                                      <div className="resources__files-edit">
                                        <img
                                          onClick={() => {
                                            console.log(item);
                                            setCurrentResource({
                                              ...item,
                                              index: index + 1,
                                            });
                                            setUpdateModal(true);
                                          }}
                                          src={EditIcon}
                                          alt="edit-icon"
                                        />
                                        <img
                                          onClick={() => {
                                            setCurrentResource({
                                              ...item,
                                              index: index + 1,
                                            });
                                            setDeleteModal(true);
                                          }}
                                          src={DeleteIcon}
                                          alt="delete-icon"
                                        />
                                      </div>
                                      <div className="resources__files-drag">
                                        <img src={DragIcon} alt="drag-icon" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          )
                        )
                      ) : (
                        <div className="resources__empty">
                          <div className="resources__empty-content">
                            <img
                              className="resources__empty-icon"
                              src={PdfIcon}
                              alt="PDF icon"
                            />
                            <p className="resources__empty-paragraph">
                              Click Add Resource to upload files
                            </p>
                          </div>
                        </div>
                      )}
                      {provided.placeholder}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {addResourceModal && (
        <AddRecourceModal
          isShow={addResourceModal}
          loading={resources.addResourceLoading}
          onAdd={(data) => onAddResource(data)}
          onCancel={() => setAddResourceModal(false)}
        />
      )}
      <DeleteRecourceModal
        loading={resources.deleteResourceLoading}
        isShow={deleteModal}
        headerText={`Delete Resource`}
        bodyText={`Are you sure you want to delete this resource?`}
        onCancel={() => setDeleteModal(false)}
        onDelete={onDeleteResource}
      />
      {updateModal && (
        <UpdateRecourceModal
          resource={currentResource}
          isShow={updateModal}
          loading={resources.updateResourceLoading}
          onUpdate={(data) => onUpdateResource(data)}
          onCancel={() => setUpdateModal(false)}
        />
      )}
    </>
  );
};

export default EditResources;
