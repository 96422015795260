import ClassroomTooltip from "components/common/classroomTooltip";
import Assessments from "./assessments";
import Development from "./development";
import Header from "./header";
import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment";
import { HeaderTabs } from "interfaces/professionalDevelopment";
import { useCallback, useContext, useEffect } from "react";
import { ClassroomTooltipContext } from "contexts/classroomTooltip";
import { updateUserData } from "services/common";
import PlayIcon from "assets/img/play_btn.svg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";
import { TooltipType } from "interfaces/common";
import { ActionType } from "redux/actionTypes/index";
import { useLocation } from "react-router-dom";
import Socket from "services/core/socket";
import socketKeys from "util/types/socket-keys";
import { useCheckGoalTooltipExists } from "hooks/useGoalTooltip";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import StlSchoolButton from "components/common/stlSchoolAdminButton";
import StlClassButton from "components/common/stlClassAdminButton";
import { AppCookies } from "util/constants";

const { SOCKET_CLASSROOM_SUBSCRIBE_EVENT } = socketKeys;

const ProfessionalDevelopment = () => {
  const { activeHeaderTab, pdClassroom, formatives, classroomId, advanceWeek } =
    useContext(ProfessionalDevelopmentContext);
  const { tooltip, setTooltip, showNoGoalTooltip, showNoScheduledTooltip } =
    useContext(ClassroomTooltipContext);
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const { management } = useSelector((state: RootState) => state);

  const { week_date = "" } = pdClassroom;

  const showAssesments =
    pdClassroom ||
    activeHeaderTab === HeaderTabs.THIS_WEEK ||
    activeHeaderTab === HeaderTabs.Resources;

  const onClickHandler = useCallback(() => {
    setTooltip((prev: any) => {
      return prev + 1;
    });

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    const conCompleteTooltip = async () => {
      if (tooltip === 6 || (formatives.length === 0 && tooltip === 4)) {
        window.scrollTo(0, 0);
        setTooltip(null!);
        const updated = await updateUserData(TooltipType.CLASSROOM);
        if (updated) {
          dispatch({
            type: ActionType.USER_PROFILE_DATA,
            payload: { ...auth.colorProfile, tooltips_seen: true },
          });
        }

        document.body.removeEventListener("click", onClickHandler, true);
      }
    };
    conCompleteTooltip();
  }, [tooltip, formatives]);

  const { pathname } = useLocation();

  useEffect(() => {
    const token = auth?.currentUser?.token;
    const decodedToken: any = jwtDecode(token);
    const isForgotPassword = decodedToken?.forgot_password;

    if (
      pdClassroom.loading ||
      !auth.colorProfile ||
      (auth.colorProfile && auth.colorProfile.tooltips_seen) ||
      !(pathname === "/classroom") ||
      isForgotPassword
    ) {
      return;
    }

    setTooltip(1);
    document.body.addEventListener("click", onClickHandler, true);

    return () => {
      document.body.removeEventListener("click", onClickHandler, true);
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [pdClassroom]);

  useEffect(() => {
    const socketInstance = Socket.getInstance();
    socketInstance.emit(SOCKET_CLASSROOM_SUBSCRIBE_EVENT, {
      classroom_id: classroomId,
    });

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  const userRole = Cookies.get(AppCookies.role);

  const isGoalTooltip = useCheckGoalTooltipExists();

  const isBlur = !pdClassroom.loading && (tooltip === 4 || tooltip === 5);
  const isTransparent =
    !!classroomId &&
    (tooltip === 1 ||
      tooltip === 2 ||
      tooltip === 3 ||
      isGoalTooltip ||
      showNoGoalTooltip ||
      showNoScheduledTooltip);
  const isShowTootip =
    !pdClassroom.loading && tooltip === 1 && pathname === "/classroom";

  useEffect(() => {
    return () => {
      dispatch({
        type: ActionType.SET_STL_SCHOOL_LOADING,
        payload: false,
      });

      dispatch({
        type: ActionType.SET_STL_CLASS_LOADING,
        payload: false,
      });
    };
  }, []);

  return (
    <div className="ProfessionalDev">
      {!!week_date && (
        <>
          <div className="ProfessionalDev__weekHeaderBlock">
            <p className="ProfessionalDev__weekHeaderBlock__weekHeaderDate">
              Demo Week of {moment(week_date).format("dddd, MMM D")}
            </p>

            <p className="ProfessionalDev__weekHeaderBlock__weekHeaderAdvance">
              Advance
            </p>
            <img
              src={PlayIcon}
              onClick={(e: any) => {
                e.preventDefault();
                advanceWeek(classroomId);
              }}
              className="ProfessionalDev__weekHeaderBlock__weekHeaderPlayIcon cursor-pointer"
              alt="Advance"
            />
            <div></div>
            {isBlur && <div className="toolTip-bg"></div>}

            {isTransparent && (
              <div className="toolTip-bg toolTip-bg-transparent"></div>
            )}
          </div>
        </>
      )}
      <div className="summative-report-download-btn">
        {management.stlSchoolLoading &&
          management?.schoolStlPeriod &&
          userRole === "School Admin" && (
            <StlSchoolButton
              previous_year={
                management?.schoolStlPeriod === "2023-24" ? "true" : ""
              }
            />
          )}
        {management.stlClassLoading && userRole === "Classroom Admin" && (
          <StlClassButton />
        )}
      </div>
      <div>
        <div
          className={`ProfessionalDev__devSummary ${
            !!week_date
              ? ""
              : "ProfessionalDev__weekHeaderBlock__weekHeaderEmptyMargin"
          }`}
        >
          {isShowTootip && (
            <ClassroomTooltip
              className="ProfessionalDev__devSummary__toolTip"
              message="Start with Professional Development, which guides you to A+ Mastery."
            />
          )}
          <Header />
          <Development />
          {isBlur && <div className="toolTip-bg"></div>}

          {isTransparent && (
            <div className="toolTip-bg toolTip-bg-transparent"></div>
          )}

          {/* {!pdClassroom.loading && !is_enabled && classroomId > 0 && (
            <div className="permission">
              <div className="permission-text">
                <p>Your school doesn’t have access to Professional Development. Please contact your school administrator and let them know you’re interested in these new features.</p>
              </div>
            </div>
          )} */}
        </div>
        {showAssesments && <Assessments />}
      </div>
    </div>
  );
};
export default ProfessionalDevelopment;
