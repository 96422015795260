import Header from "components/common/report/Print/header";
import { FC, useMemo, useRef } from "react";
import { isChrome, isFirefox, isSafari } from "util/index";
import InfoSection from "../infoSection";
import Record from "../Table/body";
import TableHeader from "../Table/header";

const SummativeStudentsPrint: FC<{
  test_name: string;
  students: any;
  level: any;
}> = ({ test_name, students, level }) => {
  const indexRef = useRef<number>(0);
  const PrintContent = useMemo(() => {
    const toPrint = [];
    let currentPage = 0;
    indexRef.current = 0;

    const PageHeight = () => {
      if (isSafari) {
        return 870;
      }
      if (isFirefox) return 900;
      else if (isChrome) return 1000;

      return 900;
    };

    const recordGenerator = () => {
      const infoHeight = 190;
      const rowHeight = 25;
      let pageHeight = PageHeight();
      const recordsToPrint = [];

      pageHeight = currentPage ? pageHeight : pageHeight - infoHeight;

      while (pageHeight >= rowHeight) {
        const student = students[indexRef.current];

        if (indexRef.current >= students.length) {
          break;
        }

        if (student) {
          recordsToPrint.push(
            <Record student={student} index={indexRef.current} />
          );
        }
        indexRef.current++;
        pageHeight -= rowHeight;
      }
      return <>{recordsToPrint}</>;
    };

    while (indexRef.current < students.length) {
      toPrint.push(
        <div className={`${currentPage ? "add-page-break" : ""}`}>
          <Header
            title={`Classroom ${test_name?.split("-").pop()} Summative Report`}
            test_name={test_name || ""}
          />
          {!currentPage && (
            <div className="SummativeStudentsReport__uperRow">
              <div className="SummativeStudentsReport__info">
                <InfoSection level={level} />
              </div>
            </div>
          )}

          <div className="SummativeStudentsReport__tableContainer">
            <table className="SummativeStudentsReport__table">
              <thead className={`SummativeStudentsReport__table__head`}>
                <TableHeader />
              </thead>
              <tbody>{recordGenerator()}</tbody>
            </table>
          </div>
        </div>
      );
      currentPage++;
    }
    return toPrint;
  }, [students, test_name, level]);

  return <>{PrintContent}</>;
};
export default SummativeStudentsPrint;
