import axios from "util/axios";
import { ActionType } from "redux/actionTypes/index";
import { Dispatch } from "redux";
import config from "config/config";

export const getMasteryVideoQuiz = async (videoId: number) => {
            try {
                const url = `${config.defaults.api_url}/api/v3/mastery/videos/${videoId}/quiz`;
    
                const { data } = await axios.get(url, {
                    headers: {
                        apiKey: config.defaults.api_key!!,
                    },
                });
    
                return {data: data?.data, message: ''}
            } catch (e: any) {
                console.log('e', e?.response);
                return {data: null, message: e?.response?.data?.message === 'You are not permitted to access this api.' ? 'You are not allowed to do this quiz.' : 'No Quiz'};
            }
        };

export const getMasteryVideos =
    () =>
        async (dispatch: Dispatch) => {
            dispatch({
                type: ActionType.SET_MASTERY_VIDEOS_LOADING,
            });

            try {
                const url = `${config.defaults.api_url}/api/v3/mastery/videos`;

                const { data } = await axios.get(url, {
                    headers: {
                        apiKey: config.defaults.api_key!!,
                    },
                });

                dispatch({
                    type: ActionType.GET_MASTERY_VIDEOS_SUCCESS,
                    payload: data?.data,
                });
            } catch (e) {
                dispatch({
                    type: ActionType.GET_MASTERY_VIDEOS_ERROR,
                    payload: e,
                });
            }
        };

export const getMasteryVideoDetail =
    (
        id: string,
        limit: number=5,
        offset: number=0,
        search: string="",
    ) =>
        async (dispatch: Dispatch) => {
            // dispatch({
            //     type: ActionType.GET_SUPER_ADMIN_LOADING,
            // });

            try {
                const url = `${config.defaults.api_url}/api/v3/mastery/videos/${id}?limit=${limit}&offset=${offset}${search ? `&search=${search}` : ''}`;

                const { data } = await axios.get(url, {
                    headers: {
                        apiKey: config.defaults.api_key!!,
                    },
                });

                dispatch({
                    type: ActionType.GET_MASTERY_VIDEO_DETAILS_SUCCESS,
                    payload: data?.data
                });
            } catch (e) {
                dispatch({
                    type: ActionType.GET_MASTERY_VIDEOS_ERROR,
                    payload: e,
                });
            }
        };

export const postMasteryVideoQuiz = async (videoId: number) => {
            // dispatch({
            //     type: ActionType.GET_SUPER_ADMIN_LOADING,
            // });
    
            try {
                const url = `${config.defaults.api_url}/api/v3/mastery/videos/${videoId}/quiz`;
    
                const { data } = await axios.post(url, {
                    headers: {
                        apiKey: config.defaults.api_key!!,
                    },
                });
    
                return data?.data
            } catch (e) {
                return null;
            }
        };

export const putMasteryQuizQuestion = async (
        videoId: string,
        questionData: any
    ) =>    {
        
            try {
                const url = `${config.defaults.api_url}/api/v3/mastery/videos/${videoId}/quiz`;
    
                const { data } = await axios.put(url, questionData,{
                    headers: {
                        apiKey: config.defaults.api_key!!,
                    },
                });
    
                return data?.data
            } catch (e) {
                return null;
            }
        };

export const addMasteryVideoComment =
(
    videoId: string,
    postData: any
) =>
    async (dispatch: Dispatch) => {
        // dispatch({
        //     type: ActionType.GET_SUPER_ADMIN_LOADING,
        // });

        try {
            const url = `${config.defaults.api_url}/api/v3/mastery/videos/${videoId}/comment`;

            const { data } = await axios.post(url, postData,{
                headers: {
                    apiKey: config.defaults.api_key!!,
                },
            });

            dispatch({
                type: ActionType.ADD_MASTERY_VIDEO_COMMENT_SUCCESS,
                payload: data?.data,
            });
        } catch (e) {
            dispatch({
                type: ActionType.GET_MASTERY_VIDEOS_ERROR,
                payload: e,
            });
        }
    };
