import ScrollingText from "components/common/ScrollingText";


const ToolTitle = ({label}: {label: string}) => {

    return (
        <ScrollingText tag="p" elementStyles={{marginLeft:"auto", marginRight:"auto", width:"165px"}} >
            {label}
        </ScrollingText>
    )
};

export default ToolTitle;
