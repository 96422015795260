/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "lodash";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import EditIcon from "assets/img/edit-table.svg";
import DeleteIcon from "assets/img/delete-table.svg";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import SearchIcon from "assets/img/search-icon.svg";
import ArrowDownImg from "assets/img/drop-black-icon-down.svg";
import Pagination from "../pagination/pagination";
import { ClassAddModal } from "./management-add-modal";
import { ClassDeleteModal } from "./management-delete-modal";
import { CLassUpdateModal } from "./management-update-modal";
import { useDispatch, useSelector } from "react-redux";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import { LimitSelect } from "components/common/selectComponent";
import {default as SearchIconSVG} from "components/common/svgs/search-icon";
import {
  addClass,
  deleteClass,
  getClasses,
  setFlagForFetch,
  updateClass,
} from "../../redux/actionCreators/management";
import classNames from "util/classNames";
import SortIndicatorIcon from "components/common/svgs/sort-indicator-icon";
import PencilIconManagement from "components/common/svgs/pencil-icon-management";
import TrashIcon from "components/common/svgs/trash-icon";
import useSortBy from "hooks/useSortBy";

interface RootState {
  management: any;
  auth: any;
}

const Classes: React.FC<{
  onNavigate?: () => void;
  schoolId?: string;
  page?: string;
  setfirst: () => void;
}> = ({ schoolId, page, setfirst, onNavigate }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { management, auth } = useSelector((state: RootState) => state);
  const [open, setOpen] = useState(false);
  const [offSet, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<null | any>(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [modalBorder, setModalBorder] = useState(false);
  // const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [skeletonCount, setSkeletonCount] = useState(10);
 
  const [lastAddedId, setLastAddedId] = useState(0);
  const {sortBy, OrderByColumn, sortDataBy }= useSortBy("name", "ASC")

  useEffect(() => {
    let params = {
      offSet,
      limit,
      searchTerm,
      schoolId,
      sort_by: sortBy,
      order_by: OrderByColumn,
    };
    setfirst();
    dispatch(getClasses(params));
  }, [
    offSet,
    limit,
    management.classFlag,
    management.classAdminsFlag,
    dispatch,
    sortBy,
    OrderByColumn,
    schoolId,
  ]);

  const handleSearch = useCallback(
    debounce((val: any) => {
      setOpen(true);
      setCurrentPage(1);
      setOffSet(0);
      let params = {
        offSet: 0,
        limit,
        searchTerm: val,
        schoolId,
        sortBy,
        sort_by: sortBy,
        order_by: OrderByColumn,
      };
      dispatch(getClasses(params));

      if (inputRef.current !== null) {
        inputRef.current.focus();
      }
    }, 1000),
    []
  );

  useEffect(() => {
    if (management.lastAddedClassId > 0) {
      let params = {
        offSet: 0,
        limit: 10,
        searchTerm: "",
        schoolId,
        sortBy,
        sort_by: sortBy,
        order_by: OrderByColumn,
        new_class_id: management.lastAddedClassId,
      };
      setLastAddedId(management.lastAddedClassId);
      setSearchTerm("");
      dispatch(getClasses(params));
    }
    if (management.classPageNumber > 0) {
      setLimit(10);
      setCurrentPage(management.classPageNumber);
      setOffSet(management.classPageNumber * 10 - 10);
      management.classPageNumber === currentPage &&
        dispatch(setFlagForFetch("SET_CLASS_FLAG"));
      setTimeout(() => {
        setLastAddedId(0);
      }, 2000);
    }
  }, [management.lastAddedClassId, management.classPageNumber]);

  useEffect(() => {
    if (lastAddedId > 0 && !management.classesLoading) {
      const element = document.getElementById("class-data");
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [management.classes, management.classesLoading]);

  const doChangePage = (pageNo: number) => {
    let newOffset = pageNo * limit - limit;
    setCurrentPage(pageNo);
    setOffSet(newOffset);
  };

  const doSetDelModal = (item: any) => {
    setCurrentItem(item);
    setDeleteModal(true);
  };

  const doSetUpdateModal = (item: any) => {
    setCurrentItem(item);
    setUpdateModal(true);
  };

  const setPreviousPage = () => {
    if (currentPage > 1 && management.classes.length === 1) {
      doChangePage(currentPage - 1);
    } else {
      dispatch(setFlagForFetch("SET_CLASS_FLAG"));
    }
  };

  const onDeleteClass = () => {
    dispatch(deleteClass(currentItem.id, setDeleteModal, setPreviousPage));
  };

  const onUpdateClass = (data: any) => {
    dispatch(updateClass(currentItem.id, data, setUpdateModal));
  };

  const onAddClass = (data: any) => {
    dispatch(addClass({ ...data, school_id: Number(schoolId) }, setAddModal));
    !open && setOpen(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalBorder(true);
    }, 300);

    return () => {
      clearTimeout(timer);
      setModalBorder(false);
    };
  }, [open]);

  useEffect(() => {
    if (page === "school") {
      setOpen(true);
      setModalBorder(!modalBorder);
    }
  }, [page]);

  useEffect(() => {
    // const timer = setTimeout(() => {
    //     setSkeletonLoad(false);
    // }, 1000);

    if (limit - (currentPage * limit - management.studentsCount) < limit) {
      setSkeletonCount(
        limit - (currentPage * limit - management.studentsCount)
      );
    } else {
      setSkeletonCount(limit);
    }

    return () => {
      // clearTimeout(timer);
      // setSkeletonLoad(true);
    };
  }, [management.classesLoading, currentPage]);






  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={(e: any) => {
          if (e.key === "Enter" && !(
              (e.target as Element).nodeName === "INPUT" ||
              (e.target as Element).nodeName === "BUTTON" ||
              (e.target as Element).nodeName === "A") ) {
                setOpen(!open);
            }
          }
        }
        onClick={(e) => {
          if (
            (e.target as Element).nodeName === "INPUT" ||
            (e.target as Element).nodeName === "BUTTON" ||
            (e.target as Element).nodeName === "A"
          ) {
            return;
          }
          setOpen(!open);
        }}
        className={`accordion__header ${open ? "open" : ""} ${
          modalBorder ? "closeDelay" : ""
        }`}
      >
        <div className="accordion__header-heading">
          <span className="accordion__header-button">
            <img src={ArrowIcon} alt="arrow" className={open ? "rotate" : ""} />
          </span>
          <h6>Classes</h6>
          {!open ? (
            <div className="accordion__header-search animated">
            <SearchIconSVG title="Search Classes" style={{marginRight:"10px"}} />
              <input
                value={searchTerm}
                onChange={(e) => {
                  handleSearch(e.target.value);
                  setSearchTerm(e.target.value);
                }}
                type="text"
                className="form-control"
                placeholder="Search Classes"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="accordion__header-text d-none d-lg-block">
          {page === "school" && open ? (
            <p>
              Click on a class name below to manage. Click the pencil to edit.
            </p>
          ) : (
            ""
          )}
        </div>

        {auth && auth.currentUser?.role === "Super Admin" ? (
          <div>
            {page === "school" ? (
              <div className="accordion__header-buttons">
                <div className="accordion__header-btnContainer">
                  <button
                    tabIndex={0}
                    onClick={() => {
                      setAddModal(true);
                    }}
                    className="btn btn-focus-visible outline-btn outline-btn-lg"
                  >
                    Add Class
                  </button>
                </div>
              </div>
            ) : (
              <div className="accordion__header-buttons">
                <div className="accordion__header-btnContainer">
                  <button
                    tabIndex={0}
                    onClick={() => {
                      onNavigate && onNavigate();
                      navigate("/management/import/class");
                    }}
                    className="btn btn-focus-visible outline-btn outline-btn-lg"
                  >
                    Import CSV
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <Collapse in={open}>
        <div>
          <div className="accordion__content">
            <div className="section-search">
              <div className="section-search-field animated">
                <div className="icon">
                <SearchIconSVG fill="#323233" id="searchClasses" title="Search Classes"/>
                </div>
                <input
                  ref={inputRef}
                  value={searchTerm}
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    setSearchTerm(e.target.value);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Search Classes"
                />
              </div>

              <div className="d-flex align-items-center show-classes-dropdown">
                <span>Show</span>
                <LimitSelect
                  disableHandler={management?.classes?.length < 10}
                  onChangeHandler={(e: any) => setLimit(Number(e?.value))}
                />
                <span>Classes</span>
              </div>
            </div>

            <div className="class-table-container">
              <div className="table-responsive">
                <table className="class__table">
                  <thead>
                    <tr className="class__table-row">
                      <th
                      tabIndex={0}
                        className="class__table-name"
                        onKeyDown={(e) =>  {
                          if(e.key === "Enter"){
                            sortDataBy("name")
                          }
                        }}
                        onClick={() => {
                          sortDataBy("name");
                        }}
                      >
                        Class Name
                        {sortBy === "name" && (
                          <SortIndicatorIcon OrderByColumn = {OrderByColumn} /> 
                        )}
                      </th>

                      <th className="class__table-grade">Hour</th>

                      <th
                      tabIndex={0}
                        className="class__table-grade"
                        onClick={() => {
                          sortDataBy("fk_grade_id")
                        }}
                        onKeyDown={(e) =>  {
                          if(e.key === "Enter"){
                            sortDataBy("fk_grade_id")
                          }
                        }}

                      >
                        Grade
                        {sortBy === "fk_grade_id" && (
                         <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>

                      <th
                      tabIndex={0}
                        className="class__table-last"
                        onClick={() => {
                          sortDataBy("last_test_time")
                        }}
                        onKeyDown={(e)=>{
                          if(e.key==="Enter"){
                            sortDataBy("last_test_time")
                          }
                        }}
                      >
                        Last <br />
                        Assessment
                        {sortBy === "last_test_time" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th
                        tabIndex={0}
                        className="class__table-student"
                        onClick={() => {
                          sortDataBy("students_count")
                        }}
                        onKeyDown={(e)=>{
                          if(e.key==="Enter"){
                            sortDataBy("students_count")
                          }
                        }}
                      >
                        Students
                        {sortBy === "students_count" && (
                         <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th className="class__table-options">Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {skeletonLoad ? (
                                        <TableSkeleton columns={5} count={skeletonCount} height={36}/>
                                    ) : (
                                        ""
                                    )} */}
                    {management.classesLoading ? (
                      <TableSkeleton
                        columns={6}
                        count={skeletonCount}
                        height={36}
                      />
                    ) : management.classes?.length ? (
                      management.classes.map((item: any, i: number) => {
                        return (
                          <tr
                            key={i}
                            className={
                              lastAddedId === item.id
                                ? "class__table-row highlight-row"
                                : i % 2 !== 0
                                ? "class__table-row"
                                : "class__table-row"
                            }
                            id={lastAddedId === item.id ? "class-data" : ""}
                          >
                            <td className="name-data">
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  onNavigate && onNavigate();
                                  navigate(`/management/class/${item.id}`);
                                }}
                              >
                                {item?.name}
                              </span>
                            </td>
                            <td
                              className={classNames("text-center hour-column")}
                            >
                              <span
                                className={classNames(item?.hour ? "chip" : "")}
                              >
                                {item?.hour || ""}
                              </span>
                            </td>
                            <td className="text-center">
                              {item?.grade === 9 ? "K" : item?.grade}
                            </td>
                            <td className="text-center">
                              {item?.last_test_time
                                ? moment(item?.last_test_time).format("M-D-YY")
                                : "-"}
                            </td>
                            <td className="text-center">
                              {item?.students_count}
                            </td>
                            <td className="options-data">
                              <div className="d-flex justify-content-start align-items-center">
                               
                              <button 
                               tabIndex={0}
                               onKeyDown={(e: any) => {
                                 if (e.key === "Enter") {
                                      doSetUpdateModal(item)
                                    }
                                  }} 
                                onClick={()=>doSetUpdateModal(item)} 
                                className="management__table-editBtn" 
                                style={{border:"none", background:"none"}}>
                                <PencilIconManagement fill="#323233"/>
                                </button>

                                <button 
                               tabIndex={0}
                               onKeyDown={(e: any) => {
                                 if (e.key === "Enter") {
                                  doSetDelModal(item)
                                    }
                                  }} 
                                onClick={()=>doSetDelModal(item)} 
                                className="management__table-editBtn" 
                                style={{border:"none", background:"none"}}>
                                <TrashIcon fill="#323233"/>
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        {management.classes?.length === 0 &&
                          !management.classesLoading && (
                            <td colSpan={6} className="no-admin fade-in">
                              No Classes
                            </td>
                          )}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <Pagination
            totalCount={management?.classesCount}
            currentPage={currentPage}
            pageSize={limit}
            onPageChange={doChangePage}
          />
        </div>
      </Collapse>
      <ClassDeleteModal
        loading={management.classDeleteLoading}
        isShow={deleteModal}
        headerText={`Delete ${currentItem && currentItem?.name}`}
        bodyText={`Are you sure you want to delete this class?`}
        onCancel={() => setDeleteModal(false)}
        onDelete={onDeleteClass}
      />
      {updateModal && (
        <CLassUpdateModal
          isShow={updateModal}
          schoolId={schoolId ?? currentItem.fk_school_id}
          classRoom={currentItem}
          onCancel={() => setUpdateModal(false)}
          onUpdate={(data) => onUpdateClass(data)}
          loading={management.classUpdateLoading}
          teacherId={
            currentItem.admins_classrooms[0]
              ? currentItem.admins_classrooms[0].admins.id
              : ""
          }
          classId={currentItem.id}
        />
      )}
      {addModal && (
        <ClassAddModal
          schoolId={schoolId}
          isShow={addModal}
          loading={management.classAddLoading}
          onCancel={() => setAddModal(false)}
          onAdd={(data) => onAddClass(data)}
        />
      )}
    </>
  );
};

export default Classes;
