/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */

import React, { useEffect, useRef, useCallback, useState } from "react";
import classNames from "util/classNames";
import Select from "react-select";
import { selectStyles } from "components/theme/dropdownStyles";
import BackIcon from "assets/img/back_arrow_yellow.svg";
import ForwardIcon from "assets/img/forward_arrow_yellow.svg";

interface Option {
  value: string;
  label: string;
  id?: string | number;
  item: any;
}

interface DropdownWithLabelProps {
  label?: string;
  options: Option[];
  onSelect: (option: Option | null) => void;
  showLabel?: boolean;
  containerStyle?: string;
  selectStyle?: string;
  labelStyle?: string;
  open?: boolean;
  setOpen: (open: boolean | undefined) => void;
  isId?: boolean;
  disableHandler?: boolean;
  onChangeHandler: (option: Option | null) => void;
  noOptionsMessage: string;
  isSubmitError?: boolean;
  errorPresentBefore?: boolean;
  error?: boolean;
  customFilter?: (option: any, searchText: string) => boolean;
  placeHolder?: string;
  value?: Option;
  enableForwardBack?: boolean;
  isSearchable?: boolean;
  triggerNextClick?: number;
  triggerBackClick?: number;
}

const DropdownWithLabel: React.FC<DropdownWithLabelProps> = ({
  label,
  options,
  showLabel = true,
  containerStyle = "",
  labelStyle = "",
  selectStyle = "",
  open,
  setOpen,
  isId,
  disableHandler = false,
  onChangeHandler,
  noOptionsMessage,
  isSubmitError = false,
  errorPresentBefore = false,
  error = false,
  placeHolder = "Select or Type Name",
  customFilter,
  value,
  enableForwardBack = false,
  isSearchable = false,
  triggerNextClick = 0,
  triggerBackClick = 0,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(
    options.findIndex((option) => option.value === value?.value) || 0
  );

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(undefined);
      }
    },
    [setOpen]
  );

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, handleClickOutside]);

  const onKeyDown = (event: any) => {
    if (event.key === "Enter" && !open && !isId) {
      setOpen(true);
    } else if (event.key === "Enter" && !open && isId) {
      // Handle save logic here if needed
    } else if (
      (event?.currentTarget?.value || event.key === "ArrowDown") &&
      !open
    ) {
      setOpen(true);
    }
  };

  const onClick = () => {
    if (disableHandler === false) {
      setOpen(open ? undefined : true);
    }
  };

  const handleForwardClick = () => {
    if (selectedIndex < options.length - 1) {
      const newIndex = selectedIndex + 1;
      setSelectedIndex(newIndex);
      onChangeHandler(options[newIndex]);
    }
  };

  const handleBackClick = () => {
    if (selectedIndex > 0) {
      const newIndex = selectedIndex - 1;
      setSelectedIndex(newIndex);
      onChangeHandler(options[newIndex]);
    }
  };

  useEffect(() => {
    setSelectedIndex(
      options.findIndex((option) => option.value === value?.value) || 0
    );
  }, [value, options]);

  useEffect(() => {
    enableForwardBack && handleForwardClick();
  }, [triggerNextClick]);

  useEffect(() => {
    enableForwardBack && handleBackClick();
  }, [triggerBackClick]);

  return (
    <div
      className={classNames("dropdownsCommon", containerStyle)}
      ref={dropdownRef}
    >
      {showLabel && label && (
        <label className={classNames("dropdownsCommon-label", labelStyle)}>
          {label}
        </label>
      )}
      <div
        className={classNames(
          enableForwardBack ? "admin-selects__forward_back" : ""
        )}
      >
        {enableForwardBack && (
          <span
            className="admin-selects__icon_right_back"
            onClick={handleBackClick}
            tabIndex={0}
            role="button"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleBackClick()
              }
          }}
            style={{
              cursor: selectedIndex === 0 ? "not-allowed" : "pointer",
              opacity: selectedIndex === 0 ? 0.5 : 1,
            }}
          >
            <img src={BackIcon} alt="arrow" className="rotate-0" />
          </span>
        )}
        <div
          className={classNames("dropdownsCommon-select-box", selectStyle)}
          // onClick={onClick}
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              onClick();
            }
          }}
          role="button"
          tabIndex={0}
        >
          <Select
            isDisabled={disableHandler}
            isSearchable={isSearchable}
            options={options}
            filterOption={customFilter}
            styles={{
              ...selectStyles,
              menu: (base) => ({...base, zIndex: 2})
              // menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure the dropdown has a high z-index
            }}
            placeholder={
              <div>
                <span
                  className={
                    isSubmitError && errorPresentBefore
                      ? error
                        ? "red-circle"
                        : "green-circle"
                      : "d-none"
                  }
                ></span>
                <span
                  className={
                    isSubmitError && errorPresentBefore
                      ? "margin-helptext text-color-blue"
                      : "text-color-blue"
                  }
                >
                  {placeHolder}
                </span>
              </div>
            }
            onChange={onChangeHandler}
            menuIsOpen={open}
            value={value}
            className={classNames(
              "admin-selects__wrapper",
              enableForwardBack ? "dropdownsCommon-width210" : ""
            )}
            classNamePrefix="dropdownsCommon-select-box"
            autoFocus={true}
            onKeyDown={onKeyDown}
            noOptionsMessage={() => noOptionsMessage}
            components={{
              IndicatorSeparator: () => null,
            }}
            // menuPortalTarget={document.body}
            // menuPosition="fixed"
          />
        </div>
        {enableForwardBack && (
          <span
            tabIndex={0}
            role="button"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleForwardClick()
              }
          }}
            className="admin-selects__icon_left_forward"
            onClick={handleForwardClick}
            style={{
              cursor:
                selectedIndex === options.length - 1
                  ? "not-allowed"
                  : "pointer",
              opacity: selectedIndex === options.length - 1 ? 0.5 : 1,
            }}
          >
            <img src={ForwardIcon} alt="arrow" className="rotate-0" />
          </span>
        )}
      </div>
    </div>
  );
};

export default DropdownWithLabel;
