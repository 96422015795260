const Resource = () => {
  const titleId = "RosourcesIcon";

  return (
    <svg
      style={{ position: "relative", top: "1px" }}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      aria-labelledby={titleId}
    >
        <title id={titleId}>Resources</title>
      <path
        d="M26.711 0.535185H20.1C18.6249 0.53362 17.182 1.00448 15.951 1.88913L14.951 2.60635L13.951 1.88913C12.7231 1.006 11.2838 0.535185 9.812 0.535185H3.205C2.35946 0.53145 1.54709 0.892965 0.946211 1.54041C0.345328 2.18785 0.00502594 3.06832 0 3.98856V17.803C0.00634366 18.7224 0.347194 19.6016 0.947906 20.248C1.54862 20.8945 2.36023 21.2555 3.205 21.252L9.811 21.2292C10.8664 21.2359 11.8967 21.5799 12.774 22.2185L14.956 23.7857L17.14 22.225C18.0191 21.5931 19.0495 21.2566 20.103 21.2575H26.709C27.5545 21.2612 28.3669 20.8997 28.9678 20.2523C29.5687 19.6048 29.909 18.7244 29.914 17.8041V3.98856C29.909 3.0687 29.5689 2.18856 28.9685 1.54118C28.368 0.893799 27.5562 0.532028 26.711 0.535185ZM27.78 17.803C27.7784 18.1098 27.6651 18.4033 27.4648 18.6192C27.2646 18.8351 26.9938 18.9557 26.712 18.9545H20.1C18.6249 18.9529 17.182 19.4238 15.951 20.3084L14.951 21.0256L13.951 20.3084C12.7203 19.4232 11.2772 18.9523 9.802 18.9545H3.205C2.92315 18.9557 2.6524 18.8351 2.45217 18.6192C2.25194 18.4033 2.13859 18.1098 2.137 17.803V3.98856C2.13885 3.682 2.25232 3.38875 2.45252 3.17311C2.65272 2.95746 2.92333 2.83701 3.205 2.83817H9.812C10.865 2.8357 11.8954 3.17028 12.775 3.80027L14.957 5.3675L17.141 3.80027C18.0194 3.17072 19.0483 2.83579 20.1 2.83708H26.706C26.8461 2.83565 26.985 2.86432 27.1149 2.92147C27.2447 2.97861 27.3629 3.0631 27.4627 3.17008C27.5625 3.27706 27.6419 3.40443 27.6963 3.54489C27.7508 3.68534 27.7792 3.83612 27.78 3.98856V17.803Z"
        fill="white"
      />
      <path
        d="M4.97852 8.17969H11.9785C12.5308 8.17969 12.9785 8.66696 12.9785 9.26804C12.9785 9.86912 12.5308 10.3564 11.9785 10.3564H4.97852C4.42623 10.3564 3.97852 9.86912 3.97852 9.26804C3.97852 8.66696 4.42623 8.17969 4.97852 8.17969Z"
        fill="white"
      />
      <path
        d="M17.9785 8.17969H24.9785C25.5308 8.17969 25.9785 8.66696 25.9785 9.26804C25.9785 9.86912 25.5308 10.3564 24.9785 10.3564H17.9785C17.4262 10.3564 16.9785 9.86912 16.9785 9.26804C16.9785 8.66696 17.4262 8.17969 17.9785 8.17969Z"
        fill="white"
      />
      <path
        d="M4.97852 12.5332H11.9785C12.5308 12.5332 12.9785 13.0205 12.9785 13.6216C12.9785 14.2226 12.5308 14.7099 11.9785 14.7099H4.97852C4.42623 14.7099 3.97852 14.2226 3.97852 13.6216C3.97852 13.0205 4.42623 12.5332 4.97852 12.5332Z"
        fill="white"
      />
      <path
        d="M17.9785 12.5332H24.9785C25.5308 12.5332 25.9785 13.0205 25.9785 13.6216C25.9785 14.2226 25.5308 14.7099 24.9785 14.7099H17.9785C17.4262 14.7099 16.9785 14.2226 16.9785 13.6216C16.9785 13.0205 17.4262 12.5332 17.9785 12.5332Z"
        fill="white"
      />
    </svg>
  );
};

export default Resource;
