import Header from "components/common/report/Print/header";
import { FC, useMemo, useRef } from "react";

const TableHeader = () => {
  return (
    <tr className="studentPdf__table__row">
      <th className="studentPdf__table__row__name fontBold ">
        Student
      </th>
      <th className="studentPdf__table__row__studentId fontBold ">
        Student ID <br /> Last 5
      </th>
      <th className="studentPdf__table__row__notes fontBold">
        Notes
      </th>
    </tr>
  );
};

const Record: FC<{
  student: any;
  index: number;
}> = ({ student}) => {
  const { student_id_last5, first_name, last_name, notes } = student;
  const student_name = last_name + ', ' + first_name;
  return (
    <>
      <tr className="studentPdf__table__row">
        <td className="studentPdf__table__row__Name">
          <span className="ellipsis-animated cursor-pointer">
            <p
              className={`ellipsis-animated-text ${
                student_name.length > 18 ? "animated ellipsis-animation" : ""
              }`}
            >
              {student_name}
            </p>
          </span>
        </td>
        <td className="studentPdf__table__row__studentId">
          {student_id_last5?.slice(-5)}
        </td>

        <td className="studentPdf__table__row__pecentage">
          <span className="percentageAlignment">{notes}</span>
        </td>
      </tr>
    </>
  );

};
const ClassroomStudentsPDF: FC<{ test_name: string; students: any, district_name: string, class_name: string}> = ({
    district_name,
    class_name,
    students,
}) => {

  const indexRef = useRef<number>(0);
  const PrintContent = useMemo(() => {
    const toPrint = [];
    let currentPage = 0;
    indexRef.current = 0;


    const recordGenerator = () => {
      const rowHeight = 25;
      let pageHeight = 830;
      const recordsToPrint = [];

      while (pageHeight >= rowHeight) {
        const student = students[indexRef.current];

        if (indexRef.current >= students.length) {
          break;
        }

        if (student) {
          recordsToPrint.push(
            <Record student={student} index={indexRef.current} />
          );
        }
        indexRef.current++;
        pageHeight -= rowHeight;
      }
      return <>{recordsToPrint}</>;
    };

    while (indexRef.current < students?.length) {
      toPrint.push(
        <>
        { !!currentPage && <div className="html2pdf__page-break"></div>}
        
        <div >
          <Header
            title={class_name}
            class_name={district_name}
          />
          <div className="studentPdf__mainContainer">
            <table className="studentPdf__table">
              <thead className={`studentPdf__table__head`}>
                <TableHeader />
              </thead>
              <tbody>{recordGenerator()}</tbody>
            </table>
          </div>
        </div>
        </>
      );
      currentPage++;
    }
    return toPrint;
  }, [students,district_name,class_name]);

  return <div className="studentPdf">{PrintContent}</div>;
};
export default ClassroomStudentsPDF;
