import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment";
import { Fragment, useContext } from "react"
import Assessment from "./assessment";
import { SingleFormative } from "interfaces/professionalDevelopment";

const CompletedFormatives = ({
    formativeLoading,
    formatives,
    selectedFormative,
    setOpen
}: any) => {
    const { classroomId, isLoading, formatives: assessments } = useContext(ProfessionalDevelopmentContext);
    const allMoved = assessments.every((item: SingleFormative) => !item.is_completed);
    return (
        <>
            <div id="completed-formative-sec" className="formative-assessment-sec">
                <div className="assessment-filter-header">
                    <h2 className="secHeading">Completed Lessons and Formative Assessments</h2>
                </div>
                {(isLoading || formativeLoading) ? (
                    [0,1,2].map(id => {
                        return (
                            <Fragment key={id}>
                                <Assessment
                                    formative={{}}
                                    formativeLoading={formativeLoading}
                                />
                            </Fragment>
                        )
                    })
                ):formatives?.length > 0 ? (
                    formatives.map((formative: any) => {
                        // const isOpened = openedAccordians.findIndex((acc: number) => acc === formative.id) > -1;
                        return (
                            <Fragment key={formative.id}>
                                {formative.moved === false ? (
                                    <div className={`assessment-msg assessment-moved`}>
                                        <p className="text-center mb-0">
                                            This assessment has been moved to Lessons and Formative Assessments section.
                                        </p>
                                    </div>
                                ):(
                                    <Assessment
                                        formative={formative}
                                        setOpen={() => setOpen(formative)}
                                        open={false}
                                    />
                                )}
                            </Fragment>
                        )
                    })
                ):(
                    <>
                        {allMoved ? (
                            <div className={`assessment-msg assessment-moved`}>
                                <p className="text-center mb-0">
                                    Enable <i>Completed</i> on an assessment when all students have taken the assessment and have been remediated.
                                </p>
                            </div>
                        ):(
                            <table>
                                <tbody>
                                    <tr>
                                        <td colSpan={10} className="notfound__data">
                                        <div className="notfound__data-container">
                                            {classroomId ? (
                                                <p className="text-center text-shadow mb-0">
                                                    No Formative Found!
                                                </p>
                                            ):(
                                                <p className="text-center text-shadow mb-0">
                                                    Select a classroom above to view Formative Assessments.
                                                </p>
                                            )}
                                        </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </>
                )}
            </div>
            {/* {selectedFormative && (
                <FormativeModal closeModal={() => setOpen(null)}>
                    <div className="formative-assessment-sec">
                        <Assessment
                            formative={selectedFormative}
                            setOpen={() => setOpen(null)}
                            open={true}
                        />
                    </div>
                </FormativeModal>
            )} */}
        </>
    )
};

export default CompletedFormatives;
