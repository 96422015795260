import axios from "util/axios";
import config from "config/config";

export const getSubjectList = async () => {
  try {
    let response = await axios.get(
      `${config.defaults.api_url}/api/v3/admin/subjects`,
      {
        headers: {
          apiKey: config.defaults.api_key!
        }
      }
    );
    return  response.data
  } catch (e) {
    console.log(e);
  }
};
