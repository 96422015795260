import Cookies from "js-cookie";
import Formatives from "./formatives"
import Summatives from "./summatives"
import { useContext } from "react";
import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment";
import { AppCookies } from "util/constants";

const Assessments = () => {

    const { classrooms, classroomId, formatives, isLoading } = useContext(ProfessionalDevelopmentContext)

    const isClassAdmin = Cookies.get(AppCookies.role) === 'Classroom Admin';
    const has_kindergarten = Cookies.get(AppCookies.has_kindergarten) || Cookies.get(AppCookies.has_zero_students_classrooms);

    const selectedClass = classrooms.find((item: any) => item.id === classroomId)
    const isSingle = classrooms.length === 1 && has_kindergarten === 'true'
    const isKindergartenSelected = (selectedClass?.fk_grade_id === 9 || selectedClass?.students_count === 0) || isSingle

    if(isClassAdmin && isKindergartenSelected){
        return null
    }

    return <>
        <Summatives />
        
        { ( (isLoading || !classroomId) || (!!classroomId && !!formatives.length) ) && <Formatives /> }
    </>
}
export default Assessments