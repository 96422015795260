import React, { useEffect } from "react";
import IPage from "interfaces/page";
import logging from "config/logging";
import Theme from "components/theme/index";
import MonitorSummatives from "components/SummativeReport";


const SummativeReport: React.FunctionComponent<IPage> = (props) => {
  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props.name]);

  return (
    <>
      <Theme>
        <MonitorSummatives/>
      </Theme>
    </>
  );
};

export default SummativeReport;
