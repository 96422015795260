import React, { useEffect, useState } from "react";
import IPage from "interfaces/page";
import logging from "config/logging";
import Theme from "components/theme/index";
import ActivityProgress from "components/activity/activity-progress";
import ActivityStats from "components/activity/activity-stats";
import ActivityIndicator from "components/common/activityIndicator";
import { useSelector } from "react-redux";
import StlButton from "components/common/stlButton";
import StlSingleButton from "components/common/stlSingleDistrictButton";
import StlSchoolButton from "components/common/stlSchoolAdminButton";
import StlClassButton from "components/common/stlClassAdminButton";

export const enum ACTIVITY_TABS {
  Assessments = "Assessments",
  Chart = "Chart",
  PD = "PD",
}

const ActivityPage: React.FunctionComponent<IPage> = (props) => {
  const { activity, management } = useSelector((state: any) => state);

  const [currentTab, setCurrentTab] = useState(ACTIVITY_TABS.Assessments);

  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props.name]);

  return (
    <>
      <Theme>
        <div className="d-flex align-items-center">
          {activity?.active && (
            <ActivityIndicator completed={activity?.completed} />
          )}
          {management.stlLoading && <StlButton />}
          {management.stlSingleLoading && <StlSingleButton />}
          {management.stlSchoolLoading && <StlSchoolButton />}
          {management.stlClassLoading && <StlClassButton />}
        </div>
        <ActivityStats />
        <ActivityProgress
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      </Theme>
    </>
  );
};

export default ActivityPage;
