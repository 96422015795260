const RightArrowYellow = (props: any) => {
  const { width, height } = props;
  const titleId = "rightArrowYellow";
  return (
    <svg
      aria-labelledby={titleId}
      xmlns="http://www.w3.org/2000/svg"
      width={width || "15"}
      height={height || "21"}
      viewBox="0 0 15 21"
      fill="none"
      opacity={props.opacity ? props.opacity : "1"}
    >
      <title id={titleId}>Next</title>
      <path
        d="M0.284158 19.2972V1.70283C0.284158 1.15246 0.579483 0.678002 1.07415 0.433652C1.56882 0.189302 2.12638 0.242463 2.56562 0.575861L14.1557 9.37304C14.5117 9.64322 14.7158 10.054 14.7158 10.5C14.7158 10.946 14.5117 11.3568 14.1557 11.627L2.56562 20.4241C2.12638 20.7575 1.56882 20.8107 1.07415 20.5663C0.579483 20.322 0.284158 19.8475 0.284158 19.2972Z"
        fill="#FFE000"
      />
      <path
        d="M14.4317 10.5C14.4317 10.1432 14.2684 9.81458 13.9836 9.59843L2.39349 0.801248C2.04209 0.534536 1.59605 0.492004 1.20031 0.687487C0.804576 0.882965 0.568316 1.26253 0.568316 1.70282V19.2972C0.568316 19.7375 0.804576 20.117 1.20031 20.3125C1.59605 20.508 2.04209 20.4655 2.39349 20.1987L13.9836 11.4016C14.2684 11.1854 14.4317 10.8568 14.4317 10.5ZM15 10.5C15 11.0061 14.776 11.5122 14.3279 11.8524L2.73776 20.6495C1.6154 21.5014 0 20.7035 0 19.2972V1.70282C0 0.296513 1.6154 -0.501432 2.73776 0.350463L14.3279 9.14764C14.776 9.48776 15 9.99388 15 10.5Z"
        fill="white"
      />
    </svg>
  );
};

export default RightArrowYellow;
