import WarrningIcon from 'assets/img/warning-icon.png'
import { FC } from 'react'


const InfoSection: FC<{total_students:number}> = ({total_students}) => {
    return <>   <div className="auditReport__centerParagraph">
        <div className="auditReport__centerParagraph__maintext">
            <p className="">The Audit Report shows the number of licensed students versus enrolled students, and highlights schools having more students tested than are licensed.</p>
        </div>
        <div className="auditReport__centerParagraph__alertbox d-flex align-items-center justify-content-center mx-auto">
            <img src={WarrningIcon} alt="warning" />
            <p className="auditReport__centerParagraph__alertbox__text">Less than 5 student licenses available</p>
        </div>
    </div>
    <div className="auditReport__centerParagraph__total-students">
        <div className='student__total-container'>
            <p className="auditReport__centerParagraph__total-students__numbers">{total_students}</p>
            <p className="auditReport__centerParagraph__total-students__text">Students <br/> Tested</p>
        </div>
    </div>
        
    </>
}
export default InfoSection