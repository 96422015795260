import Cookies from "js-cookie";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMasterReport } from "redux/actionCreators/classroom";
import { RootState } from "redux/reducers/combine";
import { AppCookies } from "util/constants";

const useClassroomMasterReport = ()=>{
    const dispatch = useDispatch();
    const masterReport = useSelector(
        (state: RootState) => state.classroom.masterReport
      );
    const classroomId = useSelector(
        (state: RootState) => state.classroom.classroomId
      );

      useEffect(() => {
        if (!(window as any)["classroomId"]) {
          dispatch(getMasterReport(parseInt(classroomId)));
        }
      }, [classroomId, dispatch]);
      return { masterReport}


}


export default useClassroomMasterReport;


export const getSummativeId = (masterReport: any)=>{
    const assessmentType = Cookies.get(AppCookies.test_name)?.split("-")[1]?.trim() || "";
    const summative = masterReport?.data.test_stats.find((report: any) =>{
        const n = report.test_name.trim().split(" ")?.pop();
        if(n===assessmentType){
          return report
        }
        return null;
      }
      );

      return summative?.id;
}

