/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { QuestionnaireContext } from '../QuestionnaireContext'
import { ReactComponent as SaveIcon} from 'assets/img/save.svg';
import CustomModal from "components/common/Modal";

const EditModal: FC<{}> = () => {
    const {
        onSave,
        showSaveModel,
        setShowSaveModel,
        answerUpdated,
        updatedQuestions,
        setIsSave
    } = useContext(QuestionnaireContext);

    const [loading, setLoading] = useState(false);

    const onSaveQuestion = async () => {
        setLoading(true);
        await onSave();
        setLoading(false);
        setIsSave(false);
        setShowSaveModel(false);
    }

    const handlePress = (event :any) => {
        if(event.keyCode === 27){
            setShowSaveModel(false);
        }
    }

    useEffect(() => {
        showSaveModel && document.addEventListener('keydown', handlePress, false);

        return () => {
            document.removeEventListener('keydown', handlePress, false);
        }
    },[showSaveModel])

    return (
        <CustomModal
            centered
            show={showSaveModel}
            size="lg"
        >
            <div className="modal__header">
                <SaveIcon className="modal__header-icon" />
                <h6 className="modal__header-title">Save {updatedQuestions} Question{updatedQuestions > 1 ? 's':''}</h6>
            </div>
            <div className="modal__body">
                {answerUpdated ?
                    <p className="para-margin">
                        {`Are you sure you want to save changes to ${updatedQuestions} question${updatedQuestions > 1 ? 's':''}?
                        At least one answer has been changed and will cause all submitted assessments
                        to be regraded. These changes will go live in the Student Portal immediately.`}
                    </p>
                    :
                    <p className="para-margin">
                        {`Are you sure you want to save changes to ${updatedQuestions} question${updatedQuestions > 1 ? 's':''}?
                        These changes will go live in the Student Portal immediately.`}
                    </p>
                }
            </div>
            <div className="modal__footer ">
                <button
                    onClick={() => setShowSaveModel(false)}
                    className="btn cancel-btn btn-lg light mx-3"
                >
                    Cancel
                </button>
                <button
                    onClick={onSaveQuestion}
                    className="btn success-btn btn-xl"
                    disabled={loading}
                >
                    {loading ? (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    ) : (
                        "Save"
                    )}
                </button>

            </div>
        </CustomModal>
    );
};

export default EditModal;