/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  Fragment,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { Spinner } from "react-bootstrap";
import BackArrow from "assets/img/remediate-arrow.png";
import moment from "moment";
import Checkbox from "assets/img/checkbox.svg";
import { useReactToPrint } from "react-to-print";
import Print from "./print";
import Skeleton from "react-loading-skeleton";
import CustomModal from "components/common/Modal";

interface Props {
  onClose: () => void;
  onClickQuestionMark: Function;
  fetchFormativeRemediateData: Function;
  formativeRemediateList: any;
  formativeId: any;
  reEnableAssessments: (payload: any) => Promise<any>;
  classroomId: any;
  onNewAttemptAssigned: Function;
}

const RemediateModal = forwardRef<any, Props>(
  (
    {
      onNewAttemptAssigned,
      classroomId,
      reEnableAssessments,
      formativeId,
      formativeRemediateList,
      fetchFormativeRemediateData,
      onClickQuestionMark,
      onClose,
    },
    ref
  ) => {
    // console.log('formativeRemediateList', formativeRemediateList);

    const [loading, setLoading] = useState(true);
    const [assignLoading, setAssignLoading] = useState(false);
    const componentRef = useRef(null);

    const { students, objective, status_key, non_proficient } =
      formativeRemediateList;
    const isDataExists = students.length > 0;

    const studentsToAttempt = students?.filter((student: any) => {
      const isNonProficient = !student?.proficient;
      const isFirstAttemptAvailable = student?.score === null;
      const isSecondAttemptAvailable =
        student?.attempt_2_score === null &&
        student?.status_attempt_2 !== "In Progress";
      const isThirdAttemptAvailable =
        student?.attempt_3_score === null &&
        student?.status_attempt_3 !== "In Progress";

      return (
        isNonProficient &&
        (isFirstAttemptAvailable ||
          isSecondAttemptAvailable ||
          isThirdAttemptAvailable)
      );
    });

    const isAttemptButtonEnabled = studentsToAttempt?.length > 0;

    const closeModal = () => {
      onClose();
    };

    const fetchData = async (formativeId: any) => {
      await fetchFormativeRemediateData(formativeId);
      setLoading(false);
    };

    useEffect(() => {
      fetchData(formativeId);
    }, [formativeId]);

    const onClickAssignNewAttempt = async () => {
      setAssignLoading(true);
      const results = await Promise.all(
        studentsToAttempt?.map((item: any) => {
          return reEnableAssessments({
            student_id: item.id,
            formative_id: formativeId,
            classroom_id: classroomId,
          });
        })
      );
      setAssignLoading(false);

      const isSuccess = results.every((result: boolean) => result);
      if (isSuccess) {
        onNewAttemptAssigned();
      }
    };

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: `Remediate_Non-Proficient_Students_${
        formativeRemediateList?.objective
      }_${moment(new Date()).format("M/DD/YY hh:mmA")}`,
      removeAfterPrint: true,
      onAfterPrint: () => {
        window.close();
      },
    });

    const onClickPrint = () => {
      handlePrint();
    };

    const isAttemptDisabled =
      !isAttemptButtonEnabled || loading || assignLoading || !isDataExists;

    return (
      <>
        <CustomModal
          className="PacingCalender__AssessmentModel hide-on-print wayReport-modal assesment-remediated remediate-labels"
          centered
          show
          onEscapeKeyDown={closeModal}
        >
          <div id="remediate-modal" ref={ref}>
            <div className="d-flex modal-header">
              <div className="d-flex justify-content-between align-items-center">
                <div
                  tabIndex={0}
                  role="button"
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      closeModal();
                    }
                  }}
                  className="back-arrow"
                  onClick={closeModal}
                >
                  <img
                    src={BackArrow}
                    alt="arrow"
                    className="cursor-pointer rotate-90"
                  />
                </div>
                <div style={{ width: "calc(100% - 50px" }}>
                  <h2 className="modal__header-title">
                    Remediate Non-Proficient Students
                  </h2>
                  <h4 className="header-desc">
                    Students Below Were Non-Proficient and Have Yet to Complete
                    New Attempts
                  </h4>
                </div>
              </div>
              <button
                tabIndex={0}
                type="button"
                onClick={onClickPrint}
                className="print-btn"
              >
                Print
              </button>
            </div>

            {non_proficient !== null && (
              <div className="proficient-perc-label">
                {non_proficient}% of Class is Non-proficient
              </div>
            )}

            {loading ? (
              <>
                <div className="way-report-modal-table-wrapper">
                  <div className="overflow-auto rounded-2  PacingCalender__AssessmentModel__container">
                    <div className="rounded-2 PacingCalender__AssessmentModel__container__main">
                      <table className="PacingCalender__AssessmentModel__container__table modal-table rounded-2">
                        <div style={{ height: 0 }}>
                          <Skeleton
                            className="report-No"
                            baseColor="#00000033"
                            highlightColor="#737373"
                            borderRadius={0}
                            width={72}
                            height={24}
                          />
                        </div>
                        <thead className="PacingCalender__AssessmentModel__container__table__head">
                          <tr className=" headrow overflow-hidden">
                            <td className="text-white fw-bold td1 text-right">
                              Student Last
                            </td>
                            <td className="text-white fw-bold td2">
                              First Name
                            </td>
                            <td className="text-white fw-bold td3">
                              <span className="attempt">
                                Attempt 1 <br />
                                <span className="score">Score</span>
                              </span>
                            </td>
                            <td className="text-white fw-bold td3">
                              <span className="attempt">
                                Attempt 2 <br />
                                <span className="score">Score</span>
                              </span>
                            </td>
                            <td className="text-white fw-bold td3">
                              <span className="attempt">
                                Attempt 3 <br />
                                <span className="score">Score</span>
                              </span>
                            </td>
                            <td className="text-white fw-bold td4">
                              <p>Proficient</p>
                            </td>
                          </tr>
                        </thead>
                        <tbody className="PacingCalender__AssessmentModel__container__table__body">
                          {[1, 2, 3, 4, 5, 6].map((id) => {
                            return (
                              <Fragment>
                                <tr key={id} className="">
                                  <td className="text-white td1 text-right">
                                    <Skeleton
                                      baseColor="#00000033"
                                      highlightColor="#737373"
                                      borderRadius={8}
                                      width={100}
                                      height={22}
                                    />
                                  </td>
                                  <td className="text-white td2">
                                    <Skeleton
                                      baseColor="#00000033"
                                      highlightColor="#737373"
                                      borderRadius={8}
                                      width={70}
                                      height={22}
                                    />
                                  </td>
                                  <td className="text-white td3">
                                    <div
                                      className="skeleton-loading"
                                      style={{
                                        height: 22,
                                        position: "relative",
                                      }}
                                    >
                                      <Skeleton
                                        baseColor="#00000033"
                                        highlightColor="#737373"
                                        borderRadius={8}
                                        width={35}
                                        height={22}
                                      />
                                      <Skeleton
                                        className="label-date"
                                        baseColor="#00000033"
                                        highlightColor="#737373"
                                        borderRadius={20}
                                        width={45}
                                        height={22}
                                        style={{
                                          top: 0,
                                          backgroundColor: "#00000033",
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td className="text-white td3">
                                    <div
                                      className="skeleton-loading"
                                      style={{
                                        height: 22,
                                        position: "relative",
                                      }}
                                    >
                                      <Skeleton
                                        baseColor="#00000033"
                                        highlightColor="#737373"
                                        borderRadius={8}
                                        width={35}
                                        height={22}
                                      />
                                      <Skeleton
                                        className="label-date"
                                        baseColor="#00000033"
                                        highlightColor="#737373"
                                        borderRadius={20}
                                        width={45}
                                        height={22}
                                        style={{
                                          top: 0,
                                          backgroundColor: "#00000033",
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td className="text-white td3">
                                    <div
                                      className="skeleton-loading"
                                      style={{
                                        height: 22,
                                        position: "relative",
                                      }}
                                    >
                                      <Skeleton
                                        baseColor="#00000033"
                                        highlightColor="#737373"
                                        borderRadius={8}
                                        width={35}
                                        height={22}
                                      />
                                      <Skeleton
                                        className="label-date"
                                        baseColor="#00000033"
                                        highlightColor="#737373"
                                        borderRadius={20}
                                        width={45}
                                        height={22}
                                        style={{
                                          top: 0,
                                          backgroundColor: "#00000033",
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td className="text-white td4">
                                    <div
                                      className="checked-img"
                                      style={{ opacity: 0.4 }}
                                    >
                                      <img src={Checkbox} alt="Checked" />
                                    </div>
                                  </td>
                                </tr>
                              </Fragment>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            ) : students.length > 0 ? (
              <div className="way-report-modal-table-wrapper">
                <div className="overflow-auto rounded-2  PacingCalender__AssessmentModel__container">
                  <div className="rounded-2 PacingCalender__AssessmentModel__container__main">
                    <table className="PacingCalender__AssessmentModel__container__table modal-table rounded-2">
                      <span className="report-No">{objective}</span>
                      <thead className="PacingCalender__AssessmentModel__container__table__head">
                        <tr className=" headrow overflow-hidden">
                          <td className="text-white fw-bold td1 text-right">
                            Student Last
                          </td>
                          <td className="text-white fw-bold td2">First Name</td>
                          <td className="text-white fw-bold td3">
                            <span className="attempt">
                              Attempt 1 <br />
                              <span className="score">Score</span>
                            </span>
                          </td>
                          <td className="text-white fw-bold td3">
                            <span className="attempt">
                              Attempt 2 <br />
                              <span className="score">Score</span>
                            </span>
                          </td>
                          <td className="text-white fw-bold td3">
                            <span className="attempt">
                              Attempt 3 <br />
                              <span className="score">Score</span>
                            </span>
                          </td>
                          <td className="text-white fw-bold td4">
                            <p>Proficient</p>
                          </td>
                        </tr>
                      </thead>
                      <tbody className="PacingCalender__AssessmentModel__container__table__body">
                        {students.map((student: any) => {
                          const {
                            status_attempt_2,
                            status_attempt_3,
                            attempt_2_date,
                            attempt_3_date,
                            proficient,
                            first_name,
                            last_name,
                            id,
                            assessment_date,
                            score,
                            attempt_2_score,
                            attempt_3_score,
                          } = student;

                          return (
                            <Fragment key={id}>
                              <tr className="">
                                <td className="text-white td1 text-right">
                                  <span
                                    style={{ opacity: proficient ? 0.4 : 1 }}
                                  >
                                    {last_name}
                                  </span>
                                </td>
                                <td className="text-white td2 ">
                                  <span
                                    style={{ opacity: proficient ? 0.4 : 1 }}
                                  >
                                    {first_name}
                                  </span>
                                </td>
                                <td className="text-white td3">
                                  <div
                                    style={{ opacity: proficient ? 0.4 : 1 }}
                                    className="d-flex justify-content-start align-items-center"
                                  >
                                    <p>{`${score}%`}</p>
                                    {!!assessment_date && (
                                      <p className="label-date">
                                        {moment(assessment_date).format("M/D")}
                                      </p>
                                    )}
                                  </div>
                                </td>
                                <td className="text-white td3">
                                  <div
                                    style={{ opacity: proficient ? 0.4 : 1 }}
                                    className="d-flex justify-content-start align-items-center"
                                  >
                                    {status_attempt_2 === "In Progress" ? (
                                      <div className="yellow-frame">
                                        <div className="yellow"></div>
                                      </div>
                                    ) : (
                                      <p>
                                        {attempt_2_score !== null
                                          ? `${attempt_2_score}%`
                                          : ""}
                                      </p>
                                    )}
                                    {!!attempt_2_date &&
                                      (attempt_2_score !== null ||
                                        status_attempt_2 === "In Progress") && (
                                        <p className="label-date">
                                          {moment(attempt_2_date).format("M/D")}
                                        </p>
                                      )}
                                  </div>
                                </td>
                                <td className="text-white td3">
                                  <div
                                    style={{ opacity: proficient ? 0.4 : 1 }}
                                    className="d-flex justify-content-start align-items-center"
                                  >
                                    {status_attempt_3 === "In Progress" ? (
                                      <div className="yellow-frame">
                                        <div className="yellow"></div>
                                      </div>
                                    ) : (
                                      <p>
                                        {attempt_3_score !== null
                                          ? `${attempt_3_score}%`
                                          : ""}
                                      </p>
                                    )}
                                    {!!attempt_3_date &&
                                      (attempt_3_score !== null ||
                                        status_attempt_3 === "In Progress") && (
                                        <p className="label-date">
                                          {moment(attempt_3_date).format("M/D")}
                                        </p>
                                      )}
                                  </div>
                                </td>
                                <td className="text-white td4">
                                  {proficient ? (
                                    <div
                                      style={{ opacity: proficient ? 0.4 : 1 }}
                                      className="checked-img"
                                    >
                                      {/* <img src={Checkbox} alt="Checked" /> */}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        viewBox="0 0 22 22"
                                        fill="none"
                                      >
                                        <path
                                          d="M22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22C17.0751 22 22 17.0751 22 11Z"
                                          fill="#46AFB8"
                                        />
                                        <mask
                                          id="mask0_4396_11754"
                                          style={{ maskType: "luminance" }}
                                          maskUnits="userSpaceOnUse"
                                          x="3"
                                          y="5"
                                          width="16"
                                          height="12"
                                        >
                                          <path
                                            d="M3.82812 5.1748H18.176V16.6531H3.82812V5.1748Z"
                                            fill="white"
                                          />
                                        </mask>
                                        <g mask="url(#mask0_4396_11754)">
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M17.749 5.66635L17.7134 5.62916C17.1435 5.02335 16.2103 5.02335 15.6404 5.62916L8.87695 12.8178L6.36352 10.146C5.79362 9.54022 4.86043 9.54022 4.29053 10.146L4.25555 10.1838C3.68565 10.7903 3.68565 11.7804 4.25555 12.3871L7.8409 16.1987C8.4111 16.8045 9.34399 16.8045 9.91419 16.1987L17.749 7.86964C18.3183 7.26351 18.3183 6.27216 17.749 5.66635Z"
                                            fill="white"
                                          />
                                        </g>
                                      </svg>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  height: 335,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  No data available!
                </p>
              </div>
            )}
            <div className="remediate-modal-footer">
              <div className="d-flex">
                {isDataExists && (
                  <>
                    <div className="label">Key</div>
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="yellow"></div>
                        <p className="status">{status_key}</p>
                      </div>
                      <div className="label-date">Date</div>
                    </div>
                  </>
                )}
              </div>

              <div className="d-flex justify-content-end align-items-center">
                {isDataExists && (
                  <div
                    onClick={() => onClickQuestionMark()}
                    className="cursor-pointer info"
                  >
                    ?
                  </div>
                )}
                <button
                  style={{ opacity: isAttemptDisabled ? 0.4 : 1 }}
                  disabled={isAttemptDisabled}
                  onClick={onClickAssignNewAttempt}
                  type="button"
                  className="assign-attempt-btn"
                >
                  {assignLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Assign New Attempts"
                  )}
                </button>
              </div>
            </div>
          </div>
        </CustomModal>
        {formativeRemediateList && (
          <div ref={componentRef} className="printData">
            <Print report={formativeRemediateList} />
          </div>
        )}
      </>
    );
  }
);

export default RemediateModal;
