import { WayToAReportContextProvider } from "interfaces/wayToAReport"

export enum WayToAReportActions {
    SET_WAY_TO_A_REPORT = 'SET_WAY_TO_A_REPORT',
    SET_WAY_TO_A_REPORT_LOADING = 'SET_WAY_TO_A_REPORT_LOADING',
    SET_REMEDIATED_LIST = 'SET_REMEDIATED_LIST',
    SET_REMEDIATED_LIST_LOADING = 'SET_REMEDIATED_LIST_LOADING',
    GET_GOALS_LIST = 'GET_GOALS_LIST'
}


export const WayToAReportReducer = (state: WayToAReportContextProvider, action: any) => {
    const { type, payload } = action
    switch (type) {
        case WayToAReportActions.SET_WAY_TO_A_REPORT_LOADING:
            return {
                ...state,
                wayToAReport: {
                    ...state.wayToAReport,
                    loading: payload
                }
            }
        case WayToAReportActions.SET_WAY_TO_A_REPORT:
            return {
                ...state,
                wayToAReport: payload
            }
        case WayToAReportActions.SET_REMEDIATED_LIST_LOADING:
            return {
                ...state,
                remediatedList: {
                    ...state.remediatedList,
                    loading: payload
                }
            }
        case WayToAReportActions.SET_REMEDIATED_LIST:
            return {
                ...state,
                remediatedList: payload
            }
        case WayToAReportActions.GET_GOALS_LIST:
            return {
                ...state,
                goalsList : payload
            }

        default:
            return state
    }
}
