import React from "react";

const ExcelReportIcon = ({fill}:{fill:string}) => {
    const titleId = "ExcelReportIcon"
  return (
    <svg
      width="18"
      height="23"
      viewBox="0 0 18 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
    >
        <title id={titleId}>Report</title>
      <path
        d="M17.3629 4.4L13.3069 0.339004C13.1998 0.231203 13.0723 0.145732 12.9319 0.0875419C12.7915 0.0293514 12.6409 -0.000402265 12.4889 4.10706e-06H1.66091C1.35413 0.00026891 1.06 0.122252 0.843077 0.339174C0.626154 0.556096 0.504171 0.85023 0.503906 1.157V21.736C0.504171 22.0428 0.626154 22.3369 0.843077 22.5538C1.06 22.7708 1.35413 22.8927 1.66091 22.893H16.5449C16.8517 22.8927 17.1458 22.7708 17.3627 22.5538C17.5797 22.3369 17.7016 22.0428 17.7019 21.736V5.213C17.7017 5.06185 17.6716 4.91224 17.6134 4.77273C17.5552 4.63322 17.4701 4.50656 17.3629 4.4ZM13.1919 1.3L16.3999 4.508H13.5159C13.4297 4.508 13.347 4.47376 13.2861 4.41281C13.2251 4.35186 13.1909 4.2692 13.1909 4.183L13.1919 1.3ZM1.66091 0.763004H12.4289V4.186C12.4292 4.47448 12.5439 4.75106 12.7479 4.95504C12.9518 5.15903 13.2284 5.27374 13.5169 5.274H16.9399V13.719H1.26691V1.157C1.26691 1.05251 1.30842 0.952293 1.38231 0.878404C1.4562 0.804515 1.55641 0.763004 1.66091 0.763004ZM16.5449 22.13H1.66091C1.55641 22.13 1.4562 22.0885 1.38231 22.0146C1.30842 21.9407 1.26691 21.8405 1.26691 21.736V14.482H16.9389V21.736C16.9389 21.8405 16.8974 21.9407 16.8235 22.0146C16.7496 22.0885 16.6494 22.13 16.5449 22.13Z"
        fill={fill}
      />
      <path
        d="M13.6052 20.9291H11.9942C11.8964 20.9241 11.8042 20.8818 11.7368 20.8108C11.6693 20.7398 11.6317 20.6456 11.6317 20.5476C11.6317 20.4497 11.6693 20.3555 11.7368 20.2845C11.8042 20.2135 11.8964 20.1711 11.9942 20.1661H13.6052C13.669 20.1659 13.7302 20.1404 13.7753 20.0953C13.8205 20.0501 13.8459 19.989 13.8462 19.9251V19.0591C13.8459 18.9953 13.8205 18.9342 13.7753 18.889C13.7302 18.8439 13.669 18.8184 13.6052 18.8181H12.6172C12.352 18.8181 12.0976 18.7128 11.9101 18.5253C11.7225 18.3377 11.6172 18.0834 11.6172 17.8181V16.9521C11.6172 16.6869 11.7225 16.4326 11.9101 16.245C12.0976 16.0575 12.352 15.9521 12.6172 15.9521H13.7172C13.815 15.9572 13.9071 15.9995 13.9746 16.0705C14.042 16.1415 14.0797 16.2357 14.0797 16.3336C14.0797 16.4316 14.042 16.5258 13.9746 16.5968C13.9071 16.6678 13.815 16.7101 13.7172 16.7151H12.6172C12.5534 16.7154 12.4922 16.7409 12.4471 16.786C12.4019 16.8312 12.3765 16.8923 12.3762 16.9561V17.8221C12.3765 17.886 12.4019 17.9471 12.4471 17.9923C12.4922 18.0374 12.5534 18.0629 12.6172 18.0631H13.6052C13.8704 18.0631 14.1247 18.1685 14.3123 18.356C14.4998 18.5436 14.6052 18.7979 14.6052 19.0631V19.9291C14.6052 20.1944 14.4998 20.4487 14.3123 20.6362C14.1247 20.8238 13.8704 20.9291 13.6052 20.9291Z"
        fill={fill}
      />
      <path
        d="M10.3472 20.9454H8.82916C8.53608 20.9451 8.25507 20.8286 8.04774 20.6215C7.84041 20.4143 7.72369 20.1334 7.72316 19.8404V16.3254C7.72051 16.2736 7.72841 16.2219 7.74639 16.1733C7.76436 16.1247 7.79203 16.0803 7.82772 16.0427C7.8634 16.0051 7.90636 15.9752 7.95397 15.9548C8.00158 15.9344 8.05285 15.9238 8.10466 15.9238C8.15647 15.9238 8.20774 15.9344 8.25534 15.9548C8.30295 15.9752 8.34591 16.0051 8.3816 16.0427C8.41728 16.0803 8.44495 16.1247 8.46293 16.1733C8.4809 16.2219 8.48881 16.2736 8.48616 16.3254V19.8404C8.48642 19.9312 8.52268 20.0182 8.58697 20.0823C8.65127 20.1464 8.73836 20.1824 8.82916 20.1824H10.3472C10.445 20.1874 10.5371 20.2298 10.6046 20.3008C10.672 20.3717 10.7096 20.4659 10.7096 20.5639C10.7096 20.6618 10.672 20.756 10.6046 20.827C10.5371 20.898 10.445 20.9403 10.3472 20.9454Z"
        fill={fill}
      />
      <path
        d="M5.52701 18.4362L6.55001 16.5032C6.59735 16.4138 6.60724 16.3093 6.57752 16.2126C6.5478 16.116 6.48089 16.0351 6.39151 15.9877C6.30213 15.9404 6.19761 15.9305 6.10093 15.9602C6.00426 15.9899 5.92335 16.0568 5.876 16.1462L5.09601 17.6202L4.31601 16.1462C4.29257 16.102 4.26064 16.0628 4.22204 16.0308C4.18345 15.9989 4.13895 15.9749 4.09108 15.9602C4.04322 15.9455 3.99292 15.9403 3.94306 15.9451C3.8932 15.9498 3.84477 15.9643 3.80051 15.9877C3.75626 16.0112 3.71705 16.0431 3.68513 16.0817C3.65322 16.1203 3.62921 16.1648 3.61449 16.2126C3.59977 16.2605 3.59463 16.3108 3.59936 16.3607C3.60408 16.4105 3.61857 16.459 3.64201 16.5032L4.66501 18.4362L3.64201 20.3692C3.61857 20.4135 3.60408 20.4619 3.59936 20.5118C3.59463 20.5616 3.59977 20.6119 3.61449 20.6598C3.62921 20.7077 3.65322 20.7522 3.68513 20.7908C3.71705 20.8293 3.75626 20.8613 3.80051 20.8847C3.84477 20.9082 3.8932 20.9226 3.94306 20.9274C3.99292 20.9321 4.04322 20.9269 4.09108 20.9122C4.13895 20.8975 4.18345 20.8735 4.22204 20.8416C4.26064 20.8097 4.29257 20.7705 4.31601 20.7262L5.09601 19.2522L5.876 20.7262C5.89945 20.7705 5.93138 20.8097 5.96997 20.8416C6.00856 20.8735 6.05307 20.8975 6.10093 20.9122C6.1488 20.9269 6.1991 20.9321 6.24896 20.9274C6.29882 20.9226 6.34725 20.9082 6.39151 20.8847C6.43576 20.8613 6.47497 20.8293 6.50689 20.7908C6.5388 20.7522 6.5628 20.7077 6.57752 20.6598C6.59224 20.6119 6.59738 20.5616 6.59266 20.5118C6.58794 20.4619 6.57345 20.4135 6.55001 20.3692L5.52701 18.4362Z"
        fill={fill}
      />
    </svg>
  );
};

export default ExcelReportIcon;
