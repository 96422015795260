import { FC, useEffect, useState } from "react"
// import RightArraowIcon from "assets/img/right-arrow-polygon.svg"
import { TableSelectCommon } from "components/common/selectComponent";
import LeftArrowYellow from "components/common/svgs/left-arrow-yellow";
import RightArrowYellow from "components/common/svgs/right-arrow-yellow";


interface HeaderProp {
    options: Array<{ value: number, label: any }>,
    optionIndex: number,
    handleNextPrevious: Function,
    onChangeHandler: Function
}

const HeaderDropDown: FC<HeaderProp> = ({ options, optionIndex, handleNextPrevious, onChangeHandler }) => {
    const [show, setShow] = useState<boolean>(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState({
        right: false,
        left: false
    });

    useEffect(() => {

        if(optionIndex === 0) {
            setIsButtonDisabled((button) => {
                return {
                    ...button,
                    left: true
                }
            });
        } else {
            setIsButtonDisabled((button) => {
                return {
                    ...button,
                    left: false
                }
            });
        }

        if(options.length - 1 === optionIndex) {

        }

        if(options.length - 1 === optionIndex) {
            setIsButtonDisabled((button) => {
                return {
                    ...button,
                    right: true
                }
            });
        } else {
            setIsButtonDisabled((button) => {
                return {
                    ...button,
                    right: false
                }
            });
        }


    }, [optionIndex, options]);

    return <div className='position-relative pacingCalenderDropdown'>
        <div className="table-dropdown-container">
            <button disabled={isButtonDisabled.left} type="button" className="text-btn" onClick={() => handleNextPrevious('left')}>
                {
                    // isButtonDisabled.left ? 

                    // <svg xmlns="http://www.w3.org/2000/svg" width="24" height="33" viewBox="0 0 24 33" fill="none" opacity={0.271}>
                    //     <path d="M22.8 14.1C23.1726 14.3794 23.475 14.7418 23.6833 15.1584C23.8916 15.5749 24 16.0343 24 16.5C24 16.9657 23.8916 17.4251 23.6833 17.8416C23.475 18.2582 23.1726 18.6206 22.8 18.9L4.8 32.4C4.35429 32.7343 3.8243 32.9378 3.26942 32.9879C2.71453 33.0379 2.15668 32.9324 1.65836 32.6833C1.16004 32.4341 0.740952 32.0511 0.448048 31.5772C0.155144 31.1033 0 30.5571 0 30L0 3C0 2.44287 0.155144 1.89674 0.448048 1.42281C0.740952 0.94888 1.16004 0.565878 1.65836 0.31672C2.15668 0.067561 2.71453 -0.0379104 3.26942 0.0121235C3.8243 0.0621574 4.35429 0.26572 4.8 0.600001L22.8 14.1Z" fill="#46AFB8"/>
                    // </svg>

                    // :

                    // <svg xmlns="http://www.w3.org/2000/svg" width="24" height="33" viewBox="0 0 24 33" fill="none">
                    //     <path d="M22.8 14.1C23.1726 14.3794 23.475 14.7418 23.6833 15.1584C23.8916 15.5749 24 16.0343 24 16.5C24 16.9657 23.8916 17.4251 23.6833 17.8416C23.475 18.2582 23.1726 18.6206 22.8 18.9L4.8 32.4C4.35429 32.7343 3.8243 32.9378 3.26942 32.9879C2.71453 33.0379 2.15668 32.9324 1.65836 32.6833C1.16004 32.4341 0.740952 32.0511 0.448048 31.5772C0.155144 31.1033 0 30.5571 0 30L0 3C0 2.44287 0.155144 1.89674 0.448048 1.42281C0.740952 0.94888 1.16004 0.565878 1.65836 0.31672C2.15668 0.067561 2.71453 -0.0379104 3.26942 0.0121235C3.8243 0.0621574 4.35429 0.26572 4.8 0.600001L22.8 14.1Z" fill="#46AFB8"/>
                    // </svg>
                    isButtonDisabled.left ? <LeftArrowYellow opacity='0.271' /> : <LeftArrowYellow />
                    
                }
                
            </button>
            <TableSelectCommon
                isOpen={show}
                toggleSelect={() => setShow(!show)}
                options={options}
                name={options[optionIndex]?.label || ""}
                onChange={(value: string) => {
                    setShow(false)
                    onChangeHandler(value)
                }
                }
            />
            <button disabled={isButtonDisabled.right} type="button" className="text-btn" onClick={() => handleNextPrevious('right')}>
                {/* <img src={ClassroomFwdIcon} alt="RightArraow" className="activeBtn" /> */}
               

                {
                    // isButtonDisabled.right ? 

                    //     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="33" viewBox="0 0 24 33" fill="none" opacity={0.271}>
                    //         <path d="M22.8 14.1C23.1726 14.3794 23.475 14.7418 23.6833 15.1584C23.8916 15.5749 24 16.0343 24 16.5C24 16.9657 23.8916 17.4251 23.6833 17.8416C23.475 18.2582 23.1726 18.6206 22.8 18.9L4.8 32.4C4.35429 32.7343 3.8243 32.9378 3.26942 32.9879C2.71453 33.0379 2.15668 32.9324 1.65836 32.6833C1.16004 32.4341 0.740952 32.0511 0.448048 31.5772C0.155144 31.1033 0 30.5571 0 30L0 3C0 2.44287 0.155144 1.89674 0.448048 1.42281C0.740952 0.94888 1.16004 0.565878 1.65836 0.31672C2.15668 0.067561 2.71453 -0.0379104 3.26942 0.0121235C3.8243 0.0621574 4.35429 0.26572 4.8 0.600001L22.8 14.1Z" fill="#46AFB8"/>
                    //     </svg>

                    // :

                    //     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="33" viewBox="0 0 24 33" fill="none">
                    //         <path d="M22.8 14.1C23.1726 14.3794 23.475 14.7418 23.6833 15.1584C23.8916 15.5749 24 16.0343 24 16.5C24 16.9657 23.8916 17.4251 23.6833 17.8416C23.475 18.2582 23.1726 18.6206 22.8 18.9L4.8 32.4C4.35429 32.7343 3.8243 32.9378 3.26942 32.9879C2.71453 33.0379 2.15668 32.9324 1.65836 32.6833C1.16004 32.4341 0.740952 32.0511 0.448048 31.5772C0.155144 31.1033 0 30.5571 0 30L0 3C0 2.44287 0.155144 1.89674 0.448048 1.42281C0.740952 0.94888 1.16004 0.565878 1.65836 0.31672C2.15668 0.067561 2.71453 -0.0379104 3.26942 0.0121235C3.8243 0.0621574 4.35429 0.26572 4.8 0.600001L22.8 14.1Z" fill="#46AFB8"/>
                    //     </svg>
                    isButtonDisabled.right ? <RightArrowYellow opacity='0.271'/> : <RightArrowYellow />
                }

            </button>
        </div>
    </div>
}
export default HeaderDropDown