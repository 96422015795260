/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect} from "react";
import { Spinner } from "react-bootstrap";

import DeleteIcon from "assets/img/trash-icon.svg";
import CustomModal from "components/common/Modal";

interface SuperAdminDeleteModalProps {
  loading: boolean;
  isShow: boolean;
  headerText: string;
  bodyText: string;
  onCancel: () => void;
  onDelete: () => void;
}
export const SuperAdminDeleteModal: React.FC<SuperAdminDeleteModalProps> = ({
  isShow,
  headerText,
  bodyText,
  loading,
  onCancel,
  onDelete
}) => {
  function handleEsc(event: any) {
    if (event.keyCode === 27) {
      onCancel();
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);
  return (
    <CustomModal centered show={isShow}>
      <div className="modal__header">
        <img alt="trash" src={DeleteIcon} className="modal__header-icon" />
        <h6 className="modal__header-title">{headerText}</h6>
      </div>
      <div className="modal__body">
        <p className="modal__body-text">{bodyText}</p>
      </div>
      <div className="modal__footer">
        <button
          disabled={loading}
          className="btn cancel-btn"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          disabled={loading}
          className="btn danger-btn"
          onClick={onDelete}
        >
          {loading ?
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              /> : "Delete"
            }
        </button>
      </div>
    </CustomModal>
  );
};

interface DistrictDeleteModalProps {
  loading: boolean;
  isShow: boolean;
  headerText: string;
  bodyText: string;
  onCancel: () => void;
  onDelete: () => void;
}
export const DistrictAdminDeleteModal: React.FC<DistrictDeleteModalProps> = ({
  isShow,
  headerText,
  bodyText,
  loading,
  onCancel,
  onDelete
}) => {
  function handleEsc(event: any) {
    if (event.keyCode === 27) {
      onCancel();
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <CustomModal centered show={isShow}>
      <div className="modal__header">
        <img alt="trash" src={DeleteIcon} className="modal__header-icon" />
        <h6 className="modal__header-title">{headerText}</h6>
      </div>
      <div className="modal__body">
        <p className="modal__body-text">{bodyText}</p>
      </div>
      <div className="modal__footer">
        <button
          disabled={loading}
          className="btn cancel-btn"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          disabled={loading}
          className="btn danger-btn"
          onClick={onDelete}
        >
          {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Delete"}
        </button>
      </div>
    </CustomModal>
  );
};

interface DistrictDeleteModalProps {
  loading: boolean;
  isShow: boolean;
  headerText: string;
  bodyText: string;
  onCancel: () => void;
  onDelete: () => void;
}
export const DistrictDeleteModal: React.FC<DistrictDeleteModalProps> = ({
  isShow,
  headerText,
  bodyText,
  loading,
  onCancel,
  onDelete
}) => {
  function handleEsc(event: any) {
    if (event.keyCode === 27) {
      onCancel();
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);
  return (
    <CustomModal centered show={isShow}>
      <div className="modal__header">
        <img alt="trash" src={DeleteIcon} className="modal__header-icon" />
        <h6 className="modal__header-title">{headerText}</h6>
      </div>
      <div className="modal__body">
        <p className="modal__body-text">{bodyText}</p>
      </div>
      <div className="modal__footer">
        <button
          disabled={loading}
          className="btn cancel-btn"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          disabled={loading}
          className="btn danger-btn"
          onClick={onDelete}
        >
          {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Delete"}
        </button>
      </div>
    </CustomModal>
  );
};

interface ClassDeleteModalProps {
  loading: boolean;
  isShow: boolean;
  headerText: string;
  bodyText: string;
  onCancel: () => void;
  onDelete: () => void;
}
export const ClassDeleteModal: React.FC<ClassDeleteModalProps> = ({
  isShow,
  headerText,
  bodyText,
  loading,
  onCancel,
  onDelete
}) => {
  function handleEsc(event: any) {
    if (event.keyCode === 27) {
      onCancel();
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);
  return (
    <CustomModal centered show={isShow}>
      <div className="modal__header">
        <img alt="trash" src={DeleteIcon} className="modal__header-icon" />
        <h6 className="modal__header-title">{headerText}</h6>
      </div>
      <div className="modal__body">
        <p className="modal__body-text">{bodyText}</p>
      </div>
      <div className="modal__footer">
        <button
          disabled={loading}
          className="btn cancel-btn"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          disabled={loading}
          className="btn danger-btn"
          onClick={onDelete}
        >
          {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Delete"}
        </button>
      </div>
    </CustomModal>
  );
};

interface ClassAdminDeleteModalProps {
  loading: boolean;
  isShow: boolean;
  headerText: string;
  bodyText: string;
  onCancel: () => void;
  onDelete: () => void;
}
export const ClassAdminDeleteModal: React.FC<ClassAdminDeleteModalProps> = ({
  isShow,
  headerText,
  bodyText,
  loading,
  onCancel,
  onDelete
}) => {
  function handleEsc(event: any) {
    if (event.keyCode === 27) {
      onCancel();
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);
  return (
    <CustomModal centered show={isShow}>
      <div className="modal__header">
        <img alt="trash" src={DeleteIcon} className="modal__header-icon" />
        <h6 className="modal__header-title">{headerText}</h6>
      </div>
      <div className="modal__body">
        <p className="modal__body-text">{bodyText}</p>
      </div>
      <div className="modal__footer">
        <button
          disabled={loading}
          className="btn cancel-btn"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          disabled={loading}
          className="btn danger-btn"
          onClick={onDelete}
        >
          {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : headerText.includes('Remove') ? 'Remove' : "Delete"}
        </button>
      </div>
    </CustomModal>
  );
};

interface StudentDeleteModalProps {
  loading: boolean;
  isShow: boolean;
  headerText: string;
  bodyText: string;
  fromCsv?: boolean;
  onCancel: () => void;
  onDelete: (a: string) => void;
}
export const StudentDeleteModal: React.FC<StudentDeleteModalProps> = ({
  isShow,
  headerText,
  bodyText,
  loading,
  onCancel,
  onDelete,
  fromCsv = false
}) => {
  function handleEsc(event: any) {
    if (event.keyCode === 27) {
      onCancel();
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <CustomModal centered show={isShow}>
      <div className="modal__header">
        <img alt="trash" src={DeleteIcon} className="modal__header-icon" />
        <h6 className="modal__header-title">{headerText}</h6>
      </div>
      <div className="modal__body">
        <p className="modal__body-text">{bodyText}</p>
      </div>
      <div className="modal__footer p-0">
        <button
          disabled={loading}
          className="btn cancel-btn me-3"
          onClick={onCancel}
        >
          Cancel
        </button>
        {fromCsv ? (
          <button
            disabled={loading}
            className="btn danger-btn"
            onClick={() => onDelete("school")}
          >
            {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Delete"}
          </button>
        ) : (
          <>
            <button
              disabled={loading}
              className="btn danger-btn"
              onClick={() => onDelete("district")}
            >
              {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Entire District"}
            </button>
          </>
        )}
      </div>
    </CustomModal>
  );
};

interface StudentCleverDeleteModalProps {
  loading: boolean;
  isShow: boolean;
  headerText: string;
  bodyText: string;
  onCancel: () => void;
  onDelete: (a: string) => void;
}
export const StudentCleverDeleteModal: React.FC<StudentCleverDeleteModalProps> = ({
  isShow,
  headerText,
  bodyText,
  loading,
  onCancel,
  onDelete,
}) => {
  function handleEsc(event: any) {
    if (event.keyCode === 27) {
      onCancel();
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <CustomModal centered show={isShow}>
      <div className="modal__header">
        <img alt="trash" src={DeleteIcon} className="modal__header-icon" />
        <h6 className="modal__header-title">{headerText}</h6>
      </div>
      <div className="modal__body">
        <p className="modal__body-text">{bodyText}</p>
      </div>
      <div className="modal__footer p-0">
        <button
          disabled={loading}
          className="btn cancel-btn me-3"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          disabled={loading}
          className="btn danger-btn"
          onClick={() => onDelete("sldkj")}
        >
          {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Delete"}
        </button>
      </div>
    </CustomModal>
  );
};

interface SchoolAdminDeleteModalProps {
  loading: boolean;
  isShow: boolean;
  headerText: string;
  bodyText: string;
  onCancel: () => void;
  onDelete: () => void;
}
export const SchoolAdminDeleteModal: React.FC<SchoolAdminDeleteModalProps> = ({
  isShow,
  headerText,
  bodyText,
  loading,
  onCancel,
  onDelete
}) => {
  function handleEsc(event: any) {
    if (event.keyCode === 27) {
      onCancel();
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <CustomModal centered show={isShow}>
      <div>
        <div className="modal__header">
          <img alt="trash" src={DeleteIcon} className="modal__header-icon" />
          <h6 className="modal__header-title">{headerText}</h6>
        </div>
        <div className="modal__body">
          <p className="modal__body-text">{bodyText}</p>
        </div>
        <div className="modal__footer">
          <button
            disabled={loading}
            className="btn cancel-btn"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            disabled={loading}
            className="btn danger-btn"
            onClick={onDelete}
          >
            {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : headerText.includes('Remove') ? 'Remove' : "Delete"}
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

interface SchoolDeleteModalProps {
  loading: boolean;
  isShow: boolean;
  headerText: string;
  bodyText: string;
  onCancel: () => void;
  onDelete: () => void;
}

export const SchoolDeleteModal: React.FC<SchoolDeleteModalProps> = ({
  isShow,
  headerText,
  bodyText,
  loading,
  onCancel,
  onDelete,
}) => {

  function handleEsc(event: any) {
    if (event.keyCode === 27) {
      onCancel();
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);
  return (
    <CustomModal centered show={isShow}>
      <div className="modal__header">
        <img alt="trash" src={DeleteIcon} className="modal__header-icon" />
        <h6 className="modal__header-title">{headerText}</h6>
      </div>
      <div className="modal__body">
        <p className="modal__body-text">{bodyText}</p>
      </div>
      <div className="modal__footer">
        <button
          disabled={loading}
          className="btn cancel-btn"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          disabled={loading}
          className="btn danger-btn"
          onClick={onDelete}
        >
          {loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "Delete"}
        </button>
      </div>
    </CustomModal>
  );
};
