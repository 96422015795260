import ScrollingText from "components/common/ScrollingText";
import HtmlParser from "components/common/htmlParser";
import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment";
import { useContext} from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

const NotScheduledAssessment = ({ assessment }: any) => {
  const navigate = useNavigate();
  const {pdClassroom, isLoading } = useContext(ProfessionalDevelopmentContext);
  const { name, description, assessment_time, scheduled_color } = assessment;
  const pdEnabled = pdClassroom.is_enabled;
  
  const navigateToPacingCalendar =()=>{
    if(pdEnabled){
      navigate("/pacing-calendar", {
        state: { date: "Not Scheduled" },
      });
    }
  }
  return (
    <table
      className="table-main not-scheduled-table"
      style={{ captionSide: "top" }}
    >
      <tbody>
        <tr>
          <td className="notSchedule-label">
            {isLoading ? (
              <Skeleton
                baseColor="#b9b9b9"
                highlightColor="#cfcfcf"
                width={111}
                height={41}
                style={{ borderRadius: "0 8px 8px 0" }}
              />
            ) : (
              <span
                role="button"
                tabIndex={0}    
                onKeyDown={(e: any) => {
                  if(e.key === "Enter"){
                     navigateToPacingCalendar();
                  }
                }}
                onClick={() => {
                 navigateToPacingCalendar()
                }}
                style={{
                  backgroundColor: scheduled_color,
                  color: scheduled_color === "#686868" ? "#fff" : "#323232",
                  cursor: pdEnabled ? 'pointer':"default"
                }}
                className="no-schedule-data"
              >
                Not Scheduled
              </span>
            )}
          </td>
          <td className="name">
            {isLoading ? (
              <Skeleton
                baseColor="#00000033"
                highlightColor="#737373"
                width={140}
                height={22}
                style={{ borderRadius: "8px" }}
              />
            ) : (
              name
            )}
          </td>
          <td className="open-date-td">
            {isLoading ? (
              <Skeleton
                baseColor="#00000033"
                highlightColor="#737373"
                width={140}
                height={22}
                style={{ borderRadius: "8px" }}
              />
            ) : (
              <p className="open-until-label">{assessment_time}</p>
            )}
          </td>
          <td className="message">
            {isLoading ? (
              <Skeleton
                baseColor="#00000033"
                highlightColor="#737373"
                width="100%"
                height={22}
                style={{ borderRadius: "20px" }}
              />
            ) : (
              <ScrollingText tag="p">
                <HtmlParser html={description} />
              </ScrollingText>
            )}
          </td>
          <td className="availability-td">
            {isLoading ? (
              <>
                <Skeleton
                  baseColor="#00000033"
                  highlightColor="#737373"
                  width={140}
                  height={16}
                  style={{ borderRadius: "20px" }}
                />
                <Skeleton
                  baseColor="#00000033"
                  highlightColor="#737373"
                  width={140}
                  height={22}
                  style={{ borderRadius: "20px" }}
                />
              </>
            ) : (
              <>
                <p className="open-date">{assessment_time}</p>
                <p className="available">Not Available</p>
              </>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default NotScheduledAssessment;
