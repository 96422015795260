import moment from "moment";
import { FC } from "react";
import ScoreBox from "./ScoreBox";

interface Props {
  student: any;
  index: number;
  showScore: boolean;
  showObjectiveColumn: string;
  objectives: any;
  setIsolatedStudent: Function;
  level: any
}

const Student: FC<Props> = (props) => {
  const {
    student,
    index,
    showScore,
    showObjectiveColumn,
    objectives,
    setIsolatedStudent,
    level
  } = props;

  const bgClass =
    student.proficiency < level?.FORMATIVE_PROFICIENCY ? "bg-eadb4a" : "";
  
  const isHideRow = student.isShowIsolated === false; // dont use not sign (!) with student.isShowIsolated

  return (
    <tr
      key={index}
      className={`oag-row ${
        index % 2 !== 0 ? "dark-gray" : ""
      } ${index === 0 ? "first-row" : ""}`}>
      <td
        className={`report__table-name oag-name report__data-height ${
          index % 2 !== 0 ? "dark-name" : ""
        }`}>
        <span onClick={() => setIsolatedStudent(student)} className='ellipsis-animated'>
          <p
            className={`cursor-pointer ellipsis-animated-text ${student.isShowIsolated ? 'font-bold' : isHideRow ? 'objective-isolation' : ''} ${
              student.name.length > 14
                ? "animated ellipsis-animation"
                : ""
            }`}>
            {student.name}
          </p>
        </span>
      </td>

      <>
        {objectives.map((o: any, index: number) => {
          const objNumber = o.objective_number;
          const hideObjective = (showObjectiveColumn && showObjectiveColumn !== objNumber) || isHideRow;
          const objective = student.objectives[objNumber];
          return (
            <td
              key={index}
              data-tip={objective?.proficiency}
              style={{
                pageBreakInside: "avoid",
                pageBreakAfter: "auto",
              }}
              className={` report__table-data report__data-height`}>
              {objective ? (
                <ScoreBox
                  showScore={showScore}
                  hideObjective={hideObjective}
                  proficiency={objective.proficiency}
                  attempts={objective.attempts}
                  level={level}
                />
              ) : (
                <div
                  className={`${
                    objective?.proficiency >= 0
                      ? objective?.proficiency >= level?.FORMATIVE_PROFICIENCY
                        ? "border-proficient"
                        : "border-non-proficient"
                      : null
                  } prof-text`}>
                  <span
                    className={`report__table-numbers noObjective oag-font-12`}>
                    -
                  </span>
                </div>
              )}
            </td>
          );
        })}
      </>
      <td className='report__Date report__data-height'>
        <div className='d-flex align-items-center justify-content-center'>
        {student?.test_date
        ? <span className={`DateInfo ${isHideRow && 'visibility-hidden'}`}>
            {moment(student?.test_date).format("M-D-YY")}
          </span>
        : <span className="DateInfo">
            -
          </span>
        }
        </div>
      </td>
      <td className='report__data-proficiency report__data-height'>
        <div className='d-flex align-items-center justify-content-center'>
          {student.proficiency ? (
            <span
              style={{...(!bgClass && {color: "#424242"})}}
              className={`${bgClass} report__table-numbers ${isHideRow && 'visibility-hidden'}`}>
              {student.proficiency}
            </span>
          ) : (
            <span
              className={`report__table-numbers noObjective oag-font-12`}>
              {" "}
              -{" "}
            </span>
          )}
        </div>
      </td>
    </tr>
  );
};

export default Student;
