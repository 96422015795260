import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(CategoryScale, LinearScale, BarElement, ChartDataLabels);

export const getOptions = (totalData:any[]) =>{ 
  return  {
    responsive: true,
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
            label: function(context:any) {
                let label = context.dataset.label + ': ' +context.formattedValue;
                return label;
            }
        }
     },
      datalabels: {
        labels: {
          index: {
            align: "end",
            anchor: "end",
            color: '#454545',
            font: { size: 18 },
            formatter: function (value:any, ctx:any) {
              const datasetArray:any[] = [];
              ctx.chart.data.datasets.forEach((dataset:any) => {
                if (
                  dataset.data[ctx.dataIndex] !== undefined &&
                  ctx.dataset.stack === dataset.stack
                ) {
                  datasetArray.push(dataset.data[ctx.dataIndex]);
                }
              });
              function totalSum(total:number, datapoint:number) {
                return total + datapoint;
              }
              let sum = datasetArray.reduce(totalSum, 0);
              return ctx.datasetIndex % 2 !== 0 ? sum : "";
            },
            offset: 4
          },
          value: {
            align: "center",
            anchor: "center",
            backgroundColor:   function(ctx:any) {
              const datasetArray:any[] = [];
              ctx.chart.data.datasets.forEach((dataset:any) => {
                if (
                  dataset.data[ctx.dataIndex] !== undefined &&
                  ctx.dataset.stack === dataset.stack
                ) {
                  datasetArray.push(dataset.data[ctx.dataIndex]);
                }
              });
              return ctx.dataset.data[ctx.dataIndex] > 1 && !datasetArray.includes(0);
            },
            borderRadius: 8,
            color: "white",
            padding: { left: 10, right: 10, top: 6, bottom: 6 },
            font: { size: 16 },
            innerWidth: 30,
            height: 30,
            formatter: function (value:any, ctx: any) {
              const datasetArray:any[] = [];
              ctx.chart.data.datasets.forEach((dataset:any) => {
                if (
                  dataset.data[ctx.dataIndex] !== undefined &&
                  ctx.dataset.stack === dataset.stack
                ) {
                  datasetArray.push(dataset.data[ctx.dataIndex]);
                }
              });
              return value > 1 && !datasetArray.includes(0) ? value : "";
            },
          },
        },
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        max: (Math.max( ...totalData)) + (40 - (Math.max( ...totalData)) % 20),
        ticks:{
          minTicksLimit: 20
        }
      },
    },
  };
} 


const labels = ["Summative A1", "Summative B", "Summative A2"];

export const getData = (data1:number[], data2:number[], data3:number[], data4:number[]) => {
  return {
    labels,
    datasets: [
      {
        label: "Basic",
        data: data1,
        backgroundColor: "#EADB4A",
        stack: "Stack 0",
      },
      {
        label: "Below Basic",
        data: data2,
        backgroundColor: "#C44538",
        stack: "Stack 0",
      },
      {
        label: "Advanced",
        data: data3,
        backgroundColor: "#2D4194",
        stack: "Stack 1",
      },
      {
        label: "Proficient",
        data: data4,
        backgroundColor: "#3B792F",
        stack: "Stack 1",
      },
    ],
  };
};

export function GradeFrequencyGraph(props: any) {
  const { data } = props;
  let points1 = [];
  let points2 = [];
  let points3 = [];
  let points4 = [];
  for (let key in data?.frequency) {
    if (data.frequency.hasOwnProperty(key)) {
      points1.push(data.frequency[key]["basic"]);
      points2.push(data.frequency[key]["below_basic"]);
      points3.push(data.frequency[key]["advanced"]);
      points4.push(data.frequency[key]["proficient"]);
    }
  }
  let finalData = getData(points1, points2, points3, points4);
  const totalData = [ points1[0]+points2[0], points1[1]+points2[1], points1[2]+points2[2], points3[0]+points4[0], points3[1]+points4[1], points3[2]+points4[2]]
  const options = getOptions(totalData);
  return <Bar options={options as any} data={finalData} />;
}
