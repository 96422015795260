/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import CustomModal from "components/common/Modal";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";


interface PromptModalProps {
  isShow: boolean;
  headerText: string;
  bodyText: string;
  setModal:any;
  cancelText: string;
  okText: string
  action: () => Promise<void>;
  
}
export const PromptModal: React.FC<PromptModalProps> = ({
  isShow,
  headerText,
  bodyText,
  setModal,
  cancelText,
  okText,
  action
}) => {
  const [loading, setLoading] = useState(false);
  
  const onAction = async() => {
    setLoading(true);
    await action();
    setLoading(false);
  }
  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={() => setModal(false)}>
      <div className="modal__header">
        <h6 className="modal__header-title">{headerText}</h6>
      </div>
      <div className="modal__body">
        <p className="modal__body-text">{bodyText}</p>
      </div>
      <div className="modal__footer">
        <button
          className="btn cancel-btn"
          onClick={() => setModal(false)} >
          {cancelText}
        </button>
        <button
          disabled={loading}
          className="btn success-btn btn-lg pause-btn-width"
          onClick={onAction} >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
          />):(
            okText
          )}
        </button>
      </div>
    </CustomModal>
  );
};

