const StudentTableHeader = () => {

    return (
        <thead className='table-header mt-4'>
            <tr className="download-row">
            <th className='table-header__objective'>
                    Objective
                </th>
                <th className='table-header__description'>
                    Description
                </th>
                <th className='table-header__nine'>
                    Nine Weeks <br />
                     <div className="d-flex justify-content-center weeks">
                        <div className="week">1</div>
                        <div className="week">2</div>
                        <div className="week">3</div>
                        <div className="week">4</div>
                     </div> 
                </th> 
            </tr>
        </thead>
    )
}
export default StudentTableHeader