import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";

export const useSelectedClassroom = () => {
    const app = useSelector((state: RootState) => state);

    const selectedClassroom = app.classroom.classrooms?.find(
        (item: any) => item.id === app.classroom?.classroomId
    );
    
    return selectedClassroom;
}