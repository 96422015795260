import { useOutsideClick } from "hooks/useOutsideClick";
import { useEffect, useRef } from "react";
import { handlePress } from "util/index";
import LessonsBody from "./lessonsBody";
import EditIcon from "assets/img/pencil-icon.svg";
import CustomModal from "components/common/Modal";

interface UpdateObjectiveModalProps {
    currentObjective: any;
    isShow: boolean;
    onCancel: () => void;
    onClickEdit: () => void;
}
  
const ObjectiveFilesModal: React.FC<UpdateObjectiveModalProps> = ({
    currentObjective,
    isShow,
    onCancel,
    onClickEdit
}) => {
    const modalRef = useRef<any>();
    const cancel = () => {
      onCancel();
    };

    const onUpload = (fieldName: string) => {
        const aLink = document.createElement("a");
        if(currentObjective[fieldName]){
            aLink.href = currentObjective[fieldName]
            aLink.download = fieldName;
            aLink.click();
        }
    }

    useEffect(() => {
        isShow && document.addEventListener("keydown", handlePress);
        return () => {
            document.removeEventListener("keydown", handlePress);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    useOutsideClick(modalRef, cancel, true);
  
    return (
        <CustomModal className="lesson-file-modal" centered show={isShow} onEscapeKeyDown={cancel}>
            <div ref={modalRef} className="">
                <div className="d-flex">
                    <LessonsBody
                        data={currentObjective}
                        title="Click the PDF to download"
                        onClickPDF={onUpload}
                        isUpload={false}
                    />
                    <div>
                        <button className="btn p-0" onClick={onClickEdit}>
                            <img
                                src={EditIcon}
                                alt="edit"
                            />
                        </button>
                    </div>
                </div>
            </div>
        </CustomModal>
    );
};

export default ObjectiveFilesModal;