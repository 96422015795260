
// import ArrowLeftMonth from "assets/img/left-arrow-green-month.png";
// import ArrowRightMonth from "assets/img/right-arrow-green-month.png";
import ResourcesIcon from "assets/img/resources-box-icon.svg";
import DropDownArrow from "assets/img/drop-down-select.svg";
import Calendar from "assets/img/calendar-icon.svg";
import Tools from "assets/img/tools-icon.svg";
import Goal from "assets/img/checkmark.svg";
// import List from "assets/img/this-week-icon.svg";
import Chat from "assets/img/discussion.svg";
import { TabList, Tab } from "react-tabs";
import { useCallback, useContext, useState } from "react";
import _ from 'lodash';
import { PdAdminContext } from "contexts/pdAdmin";
import { PacingCalenderContext } from "contexts/pacingCalender";
import PacingDelModel from "components/pacingCalender/pacingCalenderDel";
import Heading from "components/common/heading";
import BackButton from "components/common/backButton";
import LeftArrowYellow from "components/common/svgs/left-arrow-yellow";
import RightArrowYellow from "components/common/svgs/right-arrow-yellow";
import SearchIcon from "components/common/svgs/search-icon";
import CrossDelIcon from "components/common/svgs/cross-del-icon";

const TabTemplate = [
  // {
  //   name: 'This Week',
  //   icon: List,
  //   description: "Describe the week's goals, milestones and activities. Point out resources that will help the teacher progress or are necessary for upcoming important dates."
  // },
  {
    name: 'Resources',
    icon: ResourcesIcon,
    description: "Add books, assessments, professional development, and other PDFs and URLs specific to a subject and grade. And here is a third line."
  },
  {
    name: 'Pacing Calendar',
    icon: Calendar,
    description: "Set the suggested pacing for each subject and grade. Teachers are given the option to use this suggested pacing the first time they open their pacing calendar."
  },
  {
    name: 'Tools',
    icon: Tools,
    description: "Describe the week's objectives, goals and activities. Point out resources that will help the teacher progress or are necessary for upcoming important dates."
  },
  {
    name: 'Goals',
    icon: Goal,
    description: "Goals provide guidance for teachers in their pursuit to A+ mastery. They contain concise instructions and tasks with conditions to be met by a specified date."
  },
  {
    name: 'Discussion',
    icon: Chat,
    description: "Describe the week's objectives, goals and activities. Point out resources that will help the teacher progress or are necessary for upcoming important dates."
  }
]

const ProfessionalDevelopmentHeader = ({ tabIndex, setTabIndex }: any) => {
  const {
    handleNextPreviousMonth,
    handleNextPreviousGrade,
    currentMonthIndex,
    setCurrentGradeSubjectIndex,
    setCurrentMonthIndex,
    gradeSubjects,
    currentSubjectIndex,
    updateSearch,
    allMonths,
    search
  } = useContext(PdAdminContext);
  const {
    handleNextPreviousMonth: handleNextPrevMonth,
    updateCurrentGrade,
    currentGradeIndex,
    updateCurrentMonth,
    clearAssessments,
    currentMonth,
    isAnyAssessment
  } = useContext(PacingCalenderContext);
  const {months} = allMonths;

  const tab = TabTemplate[tabIndex];
  const {description, name} = tab;
  const isResourcesTab = name === 'Resources';
  const isDiscussionTab = name === 'Discussion';
  const [query, setQuery] = useState('');
  const [modalDelShow, setModalDelShow] = useState(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  const handleSearch = useCallback(
    _.debounce((e) => {
      updateSearch(e.target.value);
    }, 1000),
    []
  );

  const onClickPrev = (e: any) => {
    handleNextPreviousGrade('left')
    updateCurrentGrade(currentGradeIndex - 1);
  }

  const onClickNext = () => {
    handleNextPreviousGrade('right')
    updateCurrentGrade(currentGradeIndex + 1);
  }

  const onChangeSubject = (e: any) => {
    setCurrentGradeSubjectIndex(parseInt(e.target.value));
    updateCurrentGrade(parseInt(e.target.value));
  }

  const onClickNextMonth = () => {
    handleNextPreviousMonth('right');
    handleNextPrevMonth('right');

  }

  const onClickPrevMonth = () => {
    handleNextPreviousMonth('left');
    handleNextPrevMonth('left');
  }

  const onChangeMonth = (e: any) => {
    const indx = parseInt(e.target.value);
    updateCurrentMonth(indx);
    setCurrentMonthIndex(indx);
  }

  const onClearAssessment = async (isSingle: boolean, handler: Function, type: any) => {
    console.log(isSingle);
    const isCleared = await clearAssessments(null, type);
    isCleared && handler();
  }

  const changeTab = (index: number) => {
    setTabIndex(index);
    updateSearch("");
    setQuery("");
  }

  const isMonthDropdownDisbaled = ((tabIndex === 0 || tabIndex === 3) && !!search);
  const isMonthDropdownAndSearchDisabled = tabIndex === 5; // This means Discussions Tab.

  return <>
    <div className={`position-relative adminProfessional__container__header ${tabIndex===0? "resources-rounded-border": tabIndex===1?"pacing-rounded-border":""}  ${tabIndex !== 0 ? '' : 'resources-no-padding'}`}>      
      <div className="adminProfessional__container__header__backAdmin d-inline-flex align-items-center pdAdminBox">
        <span className="adminProfessional__container__header__backAdmin__back">
          <BackButton/>
        </span>
        <Heading
          text="PD Admin"
          className="pd-admin-heading"
        />
      </div>
      <div className="adminProfessional__container__header__notchDropDown">
        <button
          type="button"
          tabIndex={0}
          className="adminProfessional__container__header__notchDropDown__arrowLeft"
          disabled={currentSubjectIndex <= 0 || isMonthDropdownAndSearchDisabled}
          onClick={onClickPrev}
        >
          <LeftArrowYellow />
        </button>
        <div className="adminProfessional__container__header__notchDropDown__dropDown position-relative">
          <select
            tabIndex={0}
            value={currentSubjectIndex}
            onChange={onChangeSubject}
            disabled={isMonthDropdownAndSearchDisabled}
            style={{ opacity: isMonthDropdownAndSearchDisabled ? 0.4 : 1 }}
            className="customFieldsFocused-active"
          >
            {gradeSubjects.map((item, index) => {
              const {name} = item;
              return (
                <option key={index} value={index}>{name}</option>
              )
            })}
          </select>
          <img className="dropDownArrow" src={DropDownArrow} alt="Down Arrow" />
        </div>
        <button
          type="button"
          tabIndex={0}
          className="adminProfessional__container__header__notchDropDown__arrowRight"
          onClick={onClickNext}
          disabled={currentSubjectIndex >= gradeSubjects.length - 1 || isMonthDropdownAndSearchDisabled}
        >
          <RightArrowYellow />
        </button>
      </div>
      <div className="adminProfessional__container__header__tabs">
        <TabList>
          {
            TabTemplate.map((tab, index) => {
              const { name, icon } = tab;
              const isDisabled = false;
              return <Tab tabIndex="0" disabled={isDisabled} key={index}
                selected={tabIndex === index}
                disabledClassName={'disabled'}
                selectedClassName="active"
                onClick={() => !isDisabled && changeTab(index)}
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    !isDisabled && changeTab(index)
                  }
                }}>
                <div style={{cursor: isDisabled ? 'default' : 'pointer' }} className="d-flex align-items-center">
                  <span>
                    <img src={icon} alt={name} />
                  </span>
                  <p className="ms-3">{name}</p>
                </div>
              </Tab>
            })
          }
        </TabList>
      </div>
      <p className="adminProfessional__container__header__desc">
        {description}
      </p>
    </div>
    {!isResourcesTab && <div className="adminProfessional__container__body position-relative">
      <div className={`adminProfessional__container__body__monthDropDown ${tabIndex === 1 ? "border-rounded-month-dropdown":""}`}>
        <button
          type="button"
          className={'adminProfessional__container__header__notchDropDown__arrowLeft ' + currentMonthIndex}
          style={(isDiscussionTab || currentMonthIndex <= 0 || isMonthDropdownDisbaled || isMonthDropdownAndSearchDisabled) ? { opacity: '0.4', transform: '' } : { transform: '' }}
          onClick={onClickPrevMonth}
          disabled={isDiscussionTab || currentMonthIndex <= 0 || isMonthDropdownDisbaled || isMonthDropdownAndSearchDisabled}
        >
          <LeftArrowYellow />
        </button>
        <div className="adminProfessional__container__body__monthDropDown__dropDown position-relative">
          <select
            value={currentMonthIndex}
            onChange={onChangeMonth}
            disabled={isDiscussionTab || isMonthDropdownDisbaled || isMonthDropdownAndSearchDisabled}
            className="customFieldsFocused-active"
          >
            {months.map((item, index) => {
              const {month} = item;
              return (
                <option key={index} value={index}>{month}</option>
              )
            })}
          </select>
          <img className="dropDownArrow" src={DropDownArrow} alt="Down Arrow" />
        </div>
        <button
          type="button"
          style={(isDiscussionTab || currentMonthIndex > 10 || isMonthDropdownDisbaled || isMonthDropdownAndSearchDisabled) ? { opacity: '0.4' } : {}}
          className="adminProfessional__container__header__notchDropDown__arrowRight"
          onClick={onClickNextMonth}
          disabled={isDiscussionTab || currentMonthIndex > 10 || isMonthDropdownDisbaled || isMonthDropdownAndSearchDisabled}
        >
         <RightArrowYellow />
        </button>
        {tabIndex === 1 && (
          <div className="delDataBtn">
            <button 
            style={{border:"none", background:"none"}}   
            tabIndex={0}  
            onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  setModalDelShow(true)
                }
              }}
              onClick={() => setModalDelShow(true)} 
              className={`deleteIcon allDataDel cursor-pointer`}>
                <CrossDelIcon />
             </button>
          </div>
        )}
      </div>
      <div className={`adminProfessional__container__body__searchSchedule ${tabIndex === 1 ? "border-rounded-pacing-search":""}`}>
        <span className="adminProfessional__container__body__searchSchedule__search">
          <SearchIcon title={`Search ${name}`} />
        </span>
        <div className="adminProfessional__container__body__searchSchedule__input">
          <input
            onChange={(e) => {
              handleSearch(e);
              setQuery(e.target.value)

            }}
            value={query}
            type="search"
            placeholder={`Search ${name}`}
            id="search-input"
            disabled={isMonthDropdownAndSearchDisabled}
          />
        </div>
      </div>
    </div>}

    {modalDelShow && (
      <PacingDelModel
        onClearAssessment={onClearAssessment}
        currentMonth={currentMonth?.month || ''}
        show={true}
        setModalShow={setModalDelShow}
        isAnyAssessment={isAnyAssessment}
      />
    )}
  </>
}
export default ProfessionalDevelopmentHeader