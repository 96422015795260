import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import {
    fromCognitoIdentityPool,
} from "@aws-sdk/credential-provider-cognito-identity";
import { Polly } from "@aws-sdk/client-polly";
import { getSynthesizeSpeechUrl } from "@aws-sdk/polly-request-presigner";
import config from "../../../../config/config";

//Parser
const parser = new DOMParser()
// Set the parameters
const speechParams = {
    OutputFormat: "mp3",
    SampleRate: "16000",
    Text: "",
    TextType: "text", 
    VoiceId: "Joey" // For example, "Matthew"
};

const client = new Polly({
    region: `${config.defaults.aws_region}`,
    credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: `${config.defaults.aws_region}` }),
        identityPoolId: `${config.defaults.aws_region}:${config.defaults.aws_poolId}` // IDENTITY_POOL_ID
    }),
});


const speakText = async (text: string, isMath: boolean = false) => {

    const numberPattern = /\d/;

    const numberPatternTest = numberPattern.test(text);

    console.log(isMath, 'check');
    if (numberPatternTest && isMath) {
        console.log('regexing');
        const pattern = /[-\d\s]+/;
        const res = pattern.test(text);

        const pattern2 = /-/g;
        const res2 = pattern2.test(text);

        if (res && res2) {
            text = text.replace(/-/g, 'minus');
        }

        const pattern3 = /[–\d\s]+/;
        const res3 = pattern3.test(text);

        const pattern4 = /–/g;
        const res4 = pattern4.test(text);

        if (res3 && res4) {
            text = text.replace(/–/g, 'minus');
        }
    }

    speechParams.Text =  `${parser.parseFromString(text, "text/html").body.textContent}`;

    try {
        let url = await getSynthesizeSpeechUrl({
            client, params: speechParams
        });

        return url;
    } catch (err) {
        console.log("Error", err);
        return "";
    }
};


export default speakText;

