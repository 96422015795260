import React, { useEffect } from "react";
import IPage from "interfaces/page";
import logging from "config/logging";
import Theme from "components/theme/index";
import ObjectiveAnalysisReport from "components/SummativeObjectiveReport";


const SummativeObjectiveAnalysisReport: React.FunctionComponent<IPage> = (props) => {
  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props.name]);

  return (
    <>
      <Theme>
          <ObjectiveAnalysisReport />
      </Theme>
    </>
  );
};

export default SummativeObjectiveAnalysisReport;
