interface Props{
    completed_tasks: number;
    tasks: number;
}

const MultiStepGoalIcon = ({completed_tasks, tasks}: Props) => {
    return (
        <svg width="25" height="25" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_109_4975)">
                <circle cx="13.5" cy="2.5" r="2.5" fill={completed_tasks > 0 ? '#46AFB8' : '#C9C9C9'} opacity={completed_tasks > 0 ? 1 : tasks > 0 ? 1 : 0.2}/>
            </g>
            <g filter="url(#filter1_d_109_4975)">
                <circle cx="20.5" cy="5.5" r="2.5" fill={completed_tasks > 1 ? '#46AFB8' : tasks > 1 ? '#C9C9C9' : '#D9D9D9'} opacity={completed_tasks > 1 ? 1 : tasks > 1 ? 1 : 0.2} />
            </g>
            <g filter="url(#filter2_d_109_4975)">
                <circle cx="23.5" cy="12.5" r="2.5" fill={completed_tasks > 2 ? '#46AFB8' : tasks > 2 ? '#C9C9C9' : '#D9D9D9'} opacity={completed_tasks > 2 ? 1 : tasks > 2 ? 1 : 0.2} />
            </g>
            <g filter="url(#filter3_d_109_4975)">
                <circle cx="20.5" cy="19.5" r="2.5" fill={completed_tasks > 3 ? '#46AFB8' : tasks > 3 ? '#C9C9C9' : '#D9D9D9'} opacity={completed_tasks > 3 ? 1 : tasks > 3 ? 1 : 0.2} />
            </g>
            <g filter="url(#filter4_d_109_4975)">
                <circle cx="13.5" cy="22.5" r="2.5" fill={completed_tasks > 4 ? '#46AFB8' : tasks > 4 ? '#C9C9C9' : '#D9D9D9'} opacity={completed_tasks > 4 ? 1 : tasks > 4 ? 1 : 0.2} />
            </g>
            <g filter="url(#filter5_d_109_4975)">
                <circle cx="6.5" cy="19.5" r="2.5" fill={completed_tasks > 5 ? '#46AFB8' : tasks > 5 ? '#C9C9C9' : '#D9D9D9'} opacity={completed_tasks > 5 ? 1 : tasks > 5 ? 1 : 0.2} />
            </g>
            <g filter="url(#filter6_d_109_4975)">
                <circle cx="3.5" cy="12.5" r="2.5" fill={completed_tasks > 6 ? '#46AFB8' : tasks > 6 ? '#C9C9C9' : '#D9D9D9'} opacity={completed_tasks > 6 ? 1 : tasks > 6 ? 1 : 0.2}/>
            </g>
            <g filter="url(#filter7_d_109_4975)">
            <circle cx="6.5" cy="5.5" r="2.5" fill={completed_tasks > 7 ? '#46AFB8' : tasks > 7 ? '#C9C9C9' : '#D9D9D9'} opacity={completed_tasks > 7 ? 1 : tasks > 7 ? 1 : 0.2} />
            </g>
            <defs>
            <filter id="filter0_d_109_4975" x="10" y="0" width="7" height="8" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="0.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.29 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_109_4975"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_109_4975" result="shape"/>
            </filter>
            <filter id="filter1_d_109_4975" x="17" y="3" width="7" height="8" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="0.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.29 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_109_4975"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_109_4975" result="shape"/>
            </filter>
            <filter id="filter2_d_109_4975" x="20" y="10" width="7" height="8" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="0.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.29 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_109_4975"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_109_4975" result="shape"/>
            </filter>
            <filter id="filter3_d_109_4975" x="17" y="17" width="7" height="8" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="0.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.29 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_109_4975"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_109_4975" result="shape"/>
            </filter>
            <filter id="filter4_d_109_4975" x="10" y="20" width="7" height="8" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="0.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.29 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_109_4975"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_109_4975" result="shape"/>
            </filter>
            <filter id="filter5_d_109_4975" x="3" y="17" width="7" height="8" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="0.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.29 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_109_4975"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_109_4975" result="shape"/>
            </filter>
            <filter id="filter6_d_109_4975" x="0" y="10" width="7" height="8" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="0.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.29 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_109_4975"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_109_4975" result="shape"/>
            </filter>
            <filter id="filter7_d_109_4975" x="3" y="3" width="7" height="8" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="0.5"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.29 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_109_4975"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_109_4975" result="shape"/>
            </filter>
            </defs>
        </svg>
    )
};

export default MultiStepGoalIcon;
