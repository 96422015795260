import { FC, useMemo } from 'react'
import Tooltip from 'components/common/Tooltip'
import { isNumber } from 'lodash'
import { getPerformanceLevels } from 'util/index';
interface Props {
    showScore: boolean;
    showObjectiveColumn: string;
    objectiveInfo: any;
    hideObjective: boolean;
    level: any;
}

const ScoreBox: FC<Props> = (props) => {
    const { showScore, objectiveInfo, hideObjective, level } = props
    const { percentage } = objectiveInfo

    const proficiencyLevel = useMemo(() => {
        const {advanceLevel, proficientLevel, basicLevel} = getPerformanceLevels(level);
        if (percentage >= advanceLevel)
            return "bg-194792"
        else if (percentage >= proficientLevel && percentage <= (advanceLevel-1))
            return "bg-3b792f"
        else if (percentage >= basicLevel && percentage <= (proficientLevel-1))
            return 'bg-eadb4a'
        else
            return "bg-c44538"
    }, [percentage, level])


    const isRounded = useMemo(() => {
        if (objectiveInfo?.formative_given)
            return 'rounded-circle'
        return ''
    }, [objectiveInfo])

    return (
        <Tooltip
            display={!showScore}
            content={objectiveInfo?.percentage}
        >
            <td className="report__table-data">
                {
                    <span className={`
                        report__table-numbers
                        ${hideObjective ? 'hidden' : ''}
                        ${objectiveInfo?.tested ? proficiencyLevel : 'empty-percentage' } ${objectiveInfo?.tested && isRounded}
                    `}>
                        <b >{showScore && (isNumber(objectiveInfo?.percentage) && objectiveInfo?.tested ? objectiveInfo?.percentage : '-') }</b>
                    </span>
                }
            </td>
        </Tooltip>
    )
}

export default ScoreBox;
