import SupportTicketHeader from "./supportTicketHeader";
import SupportTicketTable from "./supportTicketTable";
import TicketMessageViewer from "./ticketMessageViewer";

const SupportTickets = () => {

  return (
    <div className="SupportTicket">
      <div className="SupportTicket__TicketInfo">
        <SupportTicketHeader />
        <div className="overflow-auto">
          <SupportTicketTable />
        </div>
      </div>
      <TicketMessageViewer />
      </div>
  );
};

export default SupportTickets;
