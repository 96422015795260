/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState, useContext, useMemo } from "react";
import { QuestionnaireContext } from '../QuestionnaireContext'
import RichEditor from "../RichEditor";
import CustomModal from "components/common/Modal";


const EditModal: FC<{}> = () => {
    const {
        currentQuestion,
        editKey,
        onSaveEditor,
        setEditKey
    } = useContext(QuestionnaireContext)
    const [value, setValue] = useState<string>('');
    const [isSaveEnabled, setIsSaveEnabled] = useState<boolean>(false)
    useEffect(() => {
        if (!!editKey) {
            const data = currentQuestion[editKey as keyof typeof currentQuestion]
            setValue(data);
        } else {
            setValue('');
        }

        setIsSaveEnabled(false)
    }, [currentQuestion, editKey])

    const handlePress = (event: any) => {
        if (event.keyCode === 27) {
            setEditKey(null!);
        }
    }

    useEffect(() => {
        !!editKey && document.addEventListener('keydown', handlePress, false);

        return () => {
            document.removeEventListener('keydown', handlePress, false);
        }
    }, [editKey])

    const choiceTitle = useMemo(() => {

        switch (editKey) {
            case 'passage_directions':
                return 'Passage Instructions'
            case 'direction':
                return 'Question Instructions'
            case 'question':
                return 'Question'
            case 'choice_1':
                return 'Choice: A'
            case 'choice_2':
                return 'Choice: B'
            case 'choice_3':
                return 'Choice: C'
            case 'choice_4':
                return 'Choice: D'
            default:
                return ''
        }

    }, [editKey])

    return (
        <CustomModal
            centered
            show={!!editKey}
            size="lg"
            contentClassName="modal-content-cmsEdit"
            className="cms-edit-modal"
            backdropClassName="cms-edit-modal-backdrop"
        >
            <div>
                <div className="modal__header">
                    <h6 className="modal__header-title">{`Edit ${choiceTitle}`}</h6>
                </div>
                <div className="modal__body">
                    <div className="modal__form">
                        <div className="modal__form-row">
                            <RichEditor
                                value={value}
                                onChange={setValue}
                                setIsSaveEnable={() => setIsSaveEnabled(true)}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal__footer pt-3">
                    <button
                        onClick={() => setEditKey(null!)}
                        className="btn cancel-btn"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={() => onSaveEditor(value)}
                        className="btn success-btn btn-lg"
                        disabled={!isSaveEnabled}
                    >
                        Done
                    </button>

                </div>
            </div>
        </CustomModal>
    );
};

export default EditModal;
