/* eslint-disable react-hooks/exhaustive-deps */

import CustomModal from "components/common/Modal";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";

interface Props {
  show: boolean;
  summativeId?: number;
  formativeId?: number;
  setModalShow: any;
  currentMonth: string;
  onClearAssessment: Function;
  isAnyAssessment: boolean;
}

const PacingDelModel: React.FC<Props> = (props) => {
  const { show, setModalShow, currentMonth, onClearAssessment, isAnyAssessment } = props;

  const [loading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    await onClearAssessment(false, () => closeModal(), 'all');
    setIsSaving(false);
  };

  const closeModal = () => {
    setIsLoading(false);
    setModalShow(false);
  };

  const onClickClear = async () => {
    setIsLoading(true)
    await onClearAssessment(false, () => closeModal());
    setIsLoading(false);
  }

  return (
    <>
      <CustomModal centered show={show} onEscapeKeyDown={closeModal}>
        <div className="PacingCalender__DeleteModel">
          <div>
            <h2 className="modal__header-title px-2">Clear Schedule?</h2>
          </div>
        </div>
        <div>
          <p className="test-sm  px-2 mt-3">
          Remove all assessments dates for {currentMonth}.
          </p>
        </div>
        <div className="modal__footer  justify-content-end flex-wrap">
          <button
            type="button"
            className="btn cancel-btn PacingCalender__DeleteModel__cancelBtn"
            data-bs-dismiss="modal"
            onClick={() => setModalShow(false)}
          >
            Cancel
          </button>
          <div>
          <button
            type="button"
            className="btn me-3 PacingCalender__DeleteModel__clearMonth"
            data-bs-dismiss="modal"
            onClick={onClickClear}
            disabled={!isAnyAssessment}
          >
            {loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              `Clear ${currentMonth}`
            )}
          </button>
        
          <button
            type="button"
            className="btn PacingCalender__DeleteModel__clearMonth clear-all-month"
            onClick={handleSave}
          >
            {isSaving ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Clear All"
            )}
          </button>
          </div>
        </div>
      </CustomModal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default PacingDelModel;
