import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    registerables,
    Chart
} from "chart.js";
import { Bar } from "react-chartjs-2";
import _ from "lodash";
import ChartDataLabels from "chartjs-plugin-datalabels";
  
  Chart.register(...registerables);
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ChartDataLabels,
    PointElement,
    LineElement,
);
  
const getChartData = ( data: any, assessmentType:string) => {
    let labels: string[] = [];
    let dataLinesTemplate: any[] = [];
    let dataPointsTemplate: any[] = [];
    data = _.orderBy(data,)
    data?.map((item:any) => {
        labels.push(item.classroom_name);
        const assessmentData = assessmentType === "Formative" ? item.formative_test_data : item.summative_test_data;
        dataPointsTemplate.push(assessmentData.proficiency)
        dataLinesTemplate.push(assessmentData.total_tests_taken)
        return item;
    })
  
    return { dataLinesTemplate, dataPointsTemplate, labels };
};
  
export const getOptions = (lineData:any, barData:any, animations: boolean | undefined) => {
    return {
        animations: animations,
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
        mode: 'index',
        intersect: false,
      },
      stacked: false,
        plugins: {
            tooltip: {
                callbacks: {
                    label: function(context: any) {
                        let label = context.dataset.type === 'bar' ? 'Running Proficiency Rate' : 'Formative Tests Taken';

                        if (label) {
                            label += ': ' + context.raw + (context.dataset.type === 'bar' ? '%' : '');
                        }
                        return label;
                    }
                }
            },
            title: {
                display: true,
            },
            legend: {
                display: false,
            },
        },
        scales: {
            x:{
                ticks: {
                    color: '#323233',
                }
            },
            y: {
                type: 'linear',
                position: 'right',
                stacked: false,
                ticks: {
                    stepSize: 5,
                    color: '#323233',
                }
            },
            y1: {
                type: 'linear',
                position: 'left',
                grid: {
                    drawOnChartArea: false
                },
                ticks: {
                    stepSize: 10,
                    color: '#323233',
                }
            }
        },
    };
  }
  
export const getData = (data1: number[], data2: number[], labels: string[]) => {
    return {
        labels,
        datasets: [
            {
                type: "line",
                label: "Line Dataset",
                data: data2,
                borderColor: "#FFEC75",
                pointBackgroundColor: "#FFEC75",
                pointBorderColor: "#FFEC75",
                pointRadius: 3,
                yAxisID: 'y',
                datalabels: {
                    align: function(ctx:any){
                        const dataPoint1 = ctx.chart.getDatasetMeta(0)._dataset.data[ctx.dataIndex];
                        if(!dataPoint1) return "end";

                        const y1 = ctx.chart.getDatasetMeta(0).data[ctx.dataIndex].y;
                        const y2 = ctx.chart.getDatasetMeta(1).data[ctx.dataIndex].y;
                        const diff = y1 - y2;
                        const absDiff = Math.abs(diff)
                        if(absDiff <= 20){
                            return 'start';
                        }
                        return "end";
                    },
                    anchor: function(ctx:any){
                        const dataPoint1 = ctx.chart.getDatasetMeta(0)._dataset.data[ctx.dataIndex];
                        if(!dataPoint1) return "end";

                        const y1 = ctx.chart.getDatasetMeta(0).data[ctx.dataIndex].y;
                        const y2 = ctx.chart.getDatasetMeta(1).data[ctx.dataIndex].y;
                        const diff = y1 - y2;
                        const absDiff = Math.abs(diff)
                        if(absDiff <= 20){
                            return 'start';
                        }
                        return "end";
                    },
                    color:  function(ctx:any) {
                        return 'white'
                    },
                    font: { size: 16 },
                    backgroundColor: function(ctx:any) {
                        return '#46AFB8'
                    },
                    borderRadius: 17,
                    padding: { left: 6, right: 6, top: 4, bottom: 3 },
                },
            },
            {
                type: "bar",
                label: "Bar Dataset",
                data: data1,
                backgroundColor: '#537AB7',
                yAxisID: 'y1',
                datalabels: {
                    align: "end",
                    anchor: "end",
                    clamp: false,
                    color:  function(ctx:any) {
                    return "#454545"
                    },
                    font: { size: 16 },
                    formatter: function (value: any) {
                        return value > 0 ? value+ '%' : "";
                    },
                    backgroundColor: function(ctx:any) {
                        return 'transparent';
                    },
                },
            },
        ],
    };
};
  
export function ProgressChart(props: any) {
    const { data, assessmentType, animations } = props;
    let { dataLinesTemplate, dataPointsTemplate, labels } = getChartData(data, assessmentType);
    const finalData = getData(dataPointsTemplate, dataLinesTemplate, labels);
    const options = getOptions(dataLinesTemplate, dataPointsTemplate, animations);

    
  
    return (
      <div>
        <div >
          <Bar options={options as any} data={finalData as any} className="report__printChart-chart"/>
        </div>
      </div>
    );
}
  