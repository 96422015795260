// import React, { useState } from 'react'

import React, { useEffect, useRef } from "react";
import moment from "moment";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import Card from "components/professionalDevelopment/goals/card";
import CustomModal from "components/common/Modal";
import BackButton from "components/common/backButton";

const GoalCompletionModal: React.FC<any> = ({
  onClose,
  goalsList,
  loading,
  updateGoalTask,
  onClickGoal,
}) => {
  const modalRef = useRef<any>(null);

  // console.log('goalsList', goalsList);
  //}
  const { week_goals_available = [], week_goals_upcoming = [] } = goalsList;
  const isNoData =
    week_goals_available.length === 0 && week_goals_upcoming.length === 0;
  // const isSingle = (week_goals.length> 0 && recurring_week_goals.length === 0) || (week_goals.length===0 && recurring_week_goals.length>0);

  useEffect(() => {
    const outsideClick = (e: any) => {
      if (modalRef.current && modalRef.current.contains(e.target)) {
        return;
      }

      onClose();
    };

    document.addEventListener("click", outsideClick, true);

    return () => {
      document.removeEventListener("click", outsideClick, true);
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  if (loading) {
    return (
      <CustomModal
        className="wayReport-modal way-to-report-goals"
        centered
        show
        onEscapeKeyDown={onClose}
      >
        <div className="no-data-goal-skeleton">
          <table>
            <TableSkeleton count={1} columns={1} height={487} />
          </table>
        </div>
      </CustomModal>
    );
  }

  let formattedMonth = goalsList.month
    ? goalsList.month.charAt(0) + goalsList.month.toLowerCase().slice(1)
    : "";

  return (
    <CustomModal
      className="see-all-goals-modal"
      centered
      show
      onEscapeKeyDown={onClose}
    >
      <div ref={modalRef}>
        {!loading && (
          <div className="d-flex align-items-center modal-header">
            
            <BackButton onClick={onClose} onKeyDown={(e: React.KeyboardEvent<HTMLSpanElement>)=>{
              if(e.key==="Enter"){
                e.preventDefault();
                onClose()
              }
            }} />


            <div>
              <h2 className="modal__header-title">{formattedMonth} Goals</h2>
              <h4 className="header-desc">Click on a goal to view details</h4>
            </div>
          </div>
        )}

        <div className={`see-all-goals ${isNoData ? "no-data-goal" : ""}`}>
          {/* {loading && (
                        <div className="no-data-goal-skeleton">
                            <table>
                                <TableSkeleton count={1} columns={1} height={415} />
                            </table>
                        </div>
                    )} */}
          {!loading && week_goals_available.length > 0 && (
            <div className="see-goals-sec">
              <div className="see-goals-body">
                {week_goals_available.map((item: any, index: number) => {
                  const { available_date, goals, is_available } = item;
                  const formattedDate =
                    moment(available_date).format("dddd, MMMM D");
                  return (
                    <div key={index} className="available-date">
                      <div className="available-header">
                        {is_available ? "First" : "Will Be"} Available{" "}
                        {formattedDate}
                      </div>
                      <div className="available-body">
                        {goals.map((item: any, index: number) => {
                          const { id, description, complete_by, tasks, goal } =
                            item;
                          const heading = `Complete by ${moment(
                            complete_by
                          ).format("MMMM D")}`;
                          return (
                            <Card
                              completedBy={heading}
                              heading={goal}
                              description={description}
                              tasks={tasks}
                              goalId={id}
                              onClickGoal={onClickGoal}
                              updateGoalTask={updateGoalTask}
                              shortDescription={goal?.short_description || ""}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {!loading && week_goals_upcoming.length > 0 && (
            <div className="see-goals-sec">
              <div className="see-goals-body">
                {week_goals_upcoming.map((item: any, index: number) => {
                  const { available_date, is_available, goals } = item;
                  const heading =
                    available_date !== "This Week"
                      ? `${is_available ? "First" : "Will be"
                      } Available ${moment(available_date).format(
                        "dddd, MMMM D"
                      )}`
                      : available_date;
                  return (
                    <div key={index} className="available-date">
                      <div className="available-header">{heading}</div>
                      <div className="available-body">
                        {goals.map((item: any, index: number) => {
                          const { id, description, complete_by, tasks, goal } =
                            item;
                          const heading = `Complete by ${moment(
                            complete_by
                          ).format("MMMM D")}`;

                          return (
                            <Card
                              isDisabled
                              completedBy={heading}
                              heading={goal}
                              description={description}
                              tasks={tasks}
                              goalId={id}
                              onClickGoal={onClickGoal}
                              updateGoalTask={updateGoalTask}
                              shortDescription={item?.short_description || ""}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {!loading && isNoData && <p>No data available.</p>}
        </div>
      </div>
    </CustomModal>
  );
};

export default GoalCompletionModal;
