import React, { useEffect } from "react";
import IPage from "interfaces/page";
import logging from "config/logging";
import Theme from "components/theme/index";
import RiskReport from "components/StandardReports/Risk";


const StandardRiskReport: React.FunctionComponent<IPage> = (props) => {
  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props.name]);

  return (
    <>
      <Theme>
          <RiskReport />
      </Theme>
    </>
  );
};

export default StandardRiskReport;
