import React, { useEffect } from "react";
import IPage from "interfaces/page";
import logging from "config/logging";
import Theme from "components/theme/index";
import Report from "components/MasterReport.tsx";


const MasterReport: React.FunctionComponent<IPage> = (props) => {

  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props.name]);

  return (
    <>
      <Theme>
        <Report />
      </Theme>
    </>
  );
};

export default MasterReport;
