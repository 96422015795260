import { FC, Fragment, useMemo } from "react";
import { PDFDocument } from 'pdf-lib'
import { getTeamMeetingData } from "services/professionalDevelopment";
import { getPdfData } from "util/getPdfData";
import ToolTip from "components/common/Tooltip";
import { triggerGoalWithClassroom } from "services/common";
import { GOALS_CONDITIONS } from "util/constants";
import Aplus from "../../../assets/img/A-plus-tool.svg"

interface Props {
    tools: Array<any>;
    loading: boolean;
}

const Tool: FC<Props> = (props) => {
    const { tools, loading } = props;

    const classroomId = sessionStorage.getItem("c_classroom_id");


    const formattedTools = useMemo(() => {

        const copyOfCopiedTools = tools.filter(tool => {
                return tool.mastery_video_id === 1 || tool.mastery_video_id === 2 || tool.mastery_video_id === 3 || tool.mastery_video_id === 4 || tool.mastery_video_id === 5 || tool.mastery_video_id === null;
            });

        const copiedTools = [...copyOfCopiedTools];
        const formattedToolsArray = [];

        while (copiedTools.length) {
            formattedToolsArray.push(copiedTools.splice(0, 2));
        }

        return formattedToolsArray;
    }, [tools]);

    const openLinkInNewTab = (link: string) => {
        if (!link) {
            return;
        }

        window.open(link);
    }

    const toolTypeLinkHandler = (tool: any) => {
        const { tool_type, link, mastery_video_id, label } = tool;

        switch (tool_type) {
            case 'PD Resource':
                openLinkInNewTab(link)
                break;
            case 'Way to A Report':
                openLinkInNewTab(`/classroom/report/way-to-report/${classroomId}`)
                break;
            case 'Mastery Video Series':
                openLinkInNewTab(`/mastery-videos?videoId=${mastery_video_id}`)
                break;
            case 'Data Team Meeting Worksheet':
                onclickPdf(label);
                break;
            default:
                
        }
    }

    const onClickLabel = (tool: any) => {
        const { type, link } = tool;
        switch (type) {
            case 'URL':
            case 'FILE':
                openLinkInNewTab(link);
                break;
            case 'RESOURCE':
                toolTypeLinkHandler(tool);
                break;
            default:
                console.log('no type match');
        }
    }

    const onclickPdf = async (label: string) => {

        const classroomId = sessionStorage.getItem("c_classroom_id");
        if (!classroomId) {
            return;
        }

        const teamMeetingData = await getTeamMeetingData(parseInt(classroomId));
        if (!teamMeetingData) {
            console.log('team meeting data not found!');
            return;
        }

        const pdfTeamData = getPdfData(teamMeetingData);

        // const url = 'https://www.mediafire.com/file/kda81wurrjkek18/Data_Team_Meeting_Worksheet_23-24__Revised__6.1.23+%281%29.pdf#'
        const url = '/data_Team_Meeting_Worksheet.pdf';

        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        const pages = pdfDoc.getPages()
        const firstPage = pages[0];

        pdfTeamData.forEach((data: any) => {
            const { x, y, text, size } = data;
            firstPage.drawText(text, {
                x,
                y,
                size
            });
        })

        const pdfBytes = await pdfDoc.save();
        const file = new Blob([pdfBytes], { type: "application/pdf" });

        const fileURL = URL.createObjectURL(file);
        openLinkInNewTab(fileURL);
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.target = '_blank';
        alink.download = `${label}` || 'data_team_worksheet.pdf';
        alink.click();
        triggerGoalWithClassroom({ classroomId: parseInt(classroomId), conditionName: GOALS_CONDITIONS.Generate_DTM_Worksheet })
    }

    return (
        <div className="toolSec">
            <p className="toolHeader">Tools</p>
            {loading
                ? <div className="tools-no-data load-data">
                    <div className="tools">
                        <div className="tool-img">
                            <img src={Aplus} alt="Aplus" />
                        </div>
                        <div className="tool-skeleton">
                            <span></span>
                        </div>
                    </div>
                    <div className="tools">
                        <div className="tool-img">
                            <img src={Aplus} alt="Aplus" />
                        </div>
                        <div className="tool-skeleton">
                            <span></span>
                        </div>
                    </div>
                    <div className="tools">
                        <div className="tool-img">
                            <img src={Aplus} alt="Aplus" />
                        </div>
                        <div className="tool-skeleton">
                            <span></span>
                        </div>
                    </div>
                    <div className="tools">
                        <div className="tool-img">
                            <img src={Aplus} alt="Aplus" />
                        </div>
                        <div className="tool-skeleton">
                            <span></span>
                        </div>
                    </div>
                </div>
                : <div className="tools-room">
                    {formattedTools.length > 0 ? formattedTools.map((toolArray, index) => {
                        return (
                            <div key={index} className={`d-flex align-items-center flex-wrap w-100 ${index % 2 ? 'mt-0' : ''}`}>
                                { 
                                    toolArray.map((tool) => {
                                        const { label, id, tools_icon} = tool;
                                        return (
                                            <Fragment key={id}>
                                                 <div
                                                    className="option cursor-pointer"
                                                    onClick={() => onClickLabel(tool)}
                                                >
                                                    <div className="option-img">
                                                        <img src={tools_icon || ""} alt="HeadPhone" />
                                                    </div>
                                                    <div className="tool-tooltip">
                                                        <ToolTip content={label}>
                                                            <p>{label}</p>
                                                        </ToolTip>
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                        :
                        // <p style={{justifyContent: 'center'}} className="text-center text-shadow w-100 h-100 d-flex align-items-center mb-0">
                        //     No tools.
                        // </p>
                        <div className="tools-no-data">
                            <div className="tools">
                                <div className="tool-img">
                                    <img src={Aplus} alt="Aplus" />
                                </div>
                                <div className="tool-skeleton">
                                    
                                </div>
                            </div>
                            <div className="tools">
                                <div className="tool-img">
                                    <img src={Aplus} alt="Aplus" />
                                </div>
                                <div className="tool-skeleton">
                                    
                                </div>
                            </div>
                            <div className="tools">
                                <div className="tool-img">
                                    <img src={Aplus} alt="Aplus" />
                                </div>
                                <div className="tool-skeleton">
                                   
                                </div>
                            </div>
                            <div className="tools">
                                <div className="tool-img">
                                    <img src={Aplus} alt="Aplus" />
                                </div>
                                <div className="tool-skeleton">
                                    
                                </div>
                            </div>
                        </div>
                    }
                </div>}
        </div>
    )
}
export default Tool