import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "assets/img/pencil-icon.svg";
import WarningIcon from "assets/img/warning-icon.png";
import DeleteIcon from "assets/img/trash-icon.svg";
import { StudentCleverDeleteModal } from "components/management/management-delete-modal";
import { CleverUpdateModal } from "components/management/management-update-modal";
import {
  deleteStudentClever,
  updateStudentClever,
} from "redux/actionCreators/clever-csv-import";

interface RootState {
  cleverCsvImport: any;
}

const CsvResults = () => {
  const dispatch = useDispatch();
  const { cleverCsvImport } = useSelector((state: RootState) => state);

  const [studentDeleteModal, setStudentDeleteModal] = useState(false);
  const [currentStudent, setCurrentStudent] = useState<null | any>(null);
  const [studentModal, setStudentModal] = useState(false);

  const [studentIndex, setStudentIndex] = useState<null | any>(null);

  const doSetStudentModal = (studentIndex: number, item: any) => {
    setStudentIndex(studentIndex);
    setCurrentStudent(item);
    setStudentModal(true);
  };

  const doSetStudentDelModal = (studentIndex: number, item: any) => {
    setStudentIndex(studentIndex);
    setStudentDeleteModal(true);
    setCurrentStudent(item);
  };

  const onUpdateStudentClever = (data: any) => {
    dispatch(updateStudentClever(studentIndex, data, setStudentModal));
  };

  const onDeleteStudentClever = () => {
    dispatch(deleteStudentClever(studentIndex, setStudentDeleteModal));
  };

  return (
    <div className="section light-bg">
      <div className="section-header">
        <h3>Results</h3>
      </div>
      {cleverCsvImport.csvUploadSuccess ? (
        <div className="results">
          <div className="performance__clark-container">
            <div>
              <div className="performance__clark">
                <div className="performance__clark-header">
                  <section className="performance__clark-heading d-flex align-items-center">
                    <h3>Students</h3>
                    {cleverCsvImport.clientErrors ? (
                      <img
                        src={WarningIcon}
                        alt="warning-icon"
                        className="mx-2"
                      />
                    ) : (
                      ""
                    )}
                  </section>
                </div>
                <div className="performance__table-container">
                  <div className="table-responsive">
                    <table className="performance__table">
                      <thead>
                        <tr className="performance__table-row blue-row">
                          <th className="performance__table-name">
                            Student ID
                          </th>
                          <th className="performance__table-address">
                            Clever ID
                          </th>
                          <th className="performance__table-options">
                            Options
                          </th>
                          <th className="performance__table-error">Error</th>
                        </tr>
                      </thead>

                      <tbody>
                        {cleverCsvImport.csvResults
                          ? cleverCsvImport.csvResults.map(
                              (item: any, i: number) => {
                                return (
                                  <tr
                                    key={item.index}
                                    className={
                                      i % 2 !== 0
                                        ? "performance__table-row dark-row"
                                        : "performance__table-row"
                                    }
                                  >
                                    <td className="name-data">
                                      <div>
                                        {item.error ? (
                                          <img
                                            style={{
                                              transform: "translateX(-6px)",
                                            }}
                                            src={WarningIcon}
                                            alt="warning-icon"
                                            className="mx-2"
                                          />
                                        ) : (
                                          <div></div>
                                        )}
                                        {item?.id ? (
                                          item?.id
                                        ) : (
                                          <span className="error-ball"></span>
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        {item?.clever_id ? (
                                          item?.clever_id
                                        ) : (
                                          <span className="error-ball"></span>
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <div className="d-flex justify-content-start align-items-center">
                                        <img
                                          onClick={() =>
                                            doSetStudentModal(item.index, item)
                                          }
                                          src={EditIcon}
                                          alt=""
                                          className="management__table-editBtn"
                                        />
                                        <img
                                          onClick={() =>
                                            doSetStudentDelModal(item.index, item)
                                          }
                                          src={DeleteIcon}
                                          alt=""
                                          className="management__table-editBtn"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <p className="error-data">
                                        {item?.studentError ||
                                          item?.cleverError}
                                      </p>
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          : ""}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CleverUpdateModal
            isShow={studentModal}
            std_id={currentStudent?.id}
            clever_id={currentStudent?.clever_id}
            onCancel={() => setStudentModal(false)}
            onUpdate={onUpdateStudentClever}
            loading={false}
          />
          <StudentCleverDeleteModal
            loading={false}
            isShow={studentDeleteModal}
            headerText={`Delete it`}
            bodyText={`Are you sure you want to delete this?`}
            onCancel={() => setStudentDeleteModal(false)}
            onDelete={onDeleteStudentClever}
          />
        </div>
      ) : (
        <div className="results__empty">
          <p className="results__empty-paragraph">
            Upload a file above to get started.
          </p>
        </div>
      )}
    </div>
  );
};

export default CsvResults;
