import ScrollingText from "components/common/ScrollingText";


const ResourceNameHeading = ({name}:{name: string}) => {
 
  return (
    <ScrollingText tag="p" elementStyles={{fontWeight:700}}>
      {name}
    </ScrollingText>
  );
};

export default ResourceNameHeading;
