/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import BackArrow from "assets/img/accordian-box-icon.png";
import Pagination from "../pagination/pagination";
import ReviewIcon from "assets/img/review-test-btn.svg";
import { PacingCalenderContext } from "contexts/pacingCalender";
import EmptyRecord from "components/common/EmptyRecord";

interface Props {
  show: boolean;
  summativeId?: number;
  formativeId?: number;
  setModalSuggestedAssesment: any;
  setModalSuggestedShow: (show: boolean) => void;
}

const SuggestedAssesment: React.FC<Props> = (props) => {
  const { suggestedAssessments: {pacings: suggestedAssessments} } = useContext(PacingCalenderContext);
  const { show, setModalSuggestedAssesment, setModalSuggestedShow } = props;

  const [page, setPage] = useState(0);
  const [, setIsLoading] = useState(false);

  const closeModal = () => {
    setIsLoading(false);
    setModalSuggestedAssesment(false);
    setModalSuggestedShow(true);
  };
  const onPageChange = (page: number) => {
    setPage(page);
  };

  const total = suggestedAssessments?.length;
  return (
    <>
      <Modal
        className="PacingCalender__AssessmentModel"
        centered
        show={show}
        onEscapeKeyDown={closeModal}
      >
        <div>
          <div className="d-flex align-items-center">
            <span className="backBtn">
              <img
                alt=""
                onClick={closeModal}
                className="cursor-pointer"
                src={BackArrow}
              />
            </span>
            <div>
              <h2 className="modal__header-title px-2">Suggested Pacing</h2>
              <h4 className="text-white px-2">
                Alpha Plus Suggested Assessment Schedule
              </h4>
            </div>
          </div>
          {
            suggestedAssessments && suggestedAssessments.length ?
              suggestedAssessments?.map((assessments:any) => {
                const { month, suggestions } = assessments;
                return (
                  <div className="overflow-auto rounded-2  PacingCalender__AssessmentModel__container">
                    <div>
                      <p className="mt-4 px-3 fw-bold bg-transparent mb-1 ">
                        {month}
                      </p>
                    </div>
                    <div className="rounded-2 PacingCalender__AssessmentModel__container__main sugessted-modal">
                      <table className="PacingCalender__AssessmentModel__container__table rounded-2">
                        <thead className="PacingCalender__AssessmentModel__container__table__head">
                          <tr className=" headrow overflow-hidden">
                            <td className="text-white fw-bold px-3 py-2 td1">
                              Scheduled Date
                            </td>
                            <td className="text-white fw-bold px-3 py-2 td2">
                              Objective
                            </td>
                            <td className="text-white fw-bold px-3 py-2 td3">
                              Description
                            </td>
                          </tr>
                        </thead>
                        <tbody className="PacingCalender__AssessmentModel__container__table__body">
                          {suggestions.map((suggestion: any) => {
                            console.log('suggestion', suggestion);
                            const { scheduled_date_formatted, objective_name, description, summative, objective_id } = suggestion;
                            return (
                              <tr className="">
                                <td className="text-white px-3 py-2 td1">
                                  {scheduled_date_formatted}
                                </td>
                                <td className="text-white py-2 td2 d-flex">
                                  <img height={'16px'} className="TickIcon px-2" src={ReviewIcon} alt="" />
                                  <p className="mb-0">{`${objective_id ? `${objective_id} - ` : ''}${objective_name || summative}`}</p>
                                </td>
                                <td className="text-white px-3 py-2 td3">
                                  {description}
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )
              })
              : <table><EmptyRecord message="No suggestion added for this date." withoutBackground={true} /></table>
          }

          <Pagination
            totalCount={total}
            currentPage={page}
            pageSize={10}
            onPageChange={(page: number) => onPageChange(page)}
          />
        </div>
      </Modal>
    </>
  );
};

export default SuggestedAssesment;
