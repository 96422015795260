import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionType from "redux/actionTypes";

const StlSingleButton = () => {
  const [buttonText, setButtonText] = useState("");
  const { management } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  const download = () => {
    if (management.stlSingleUrl) {
      const link = document.createElement("a");
      link.href = management.stlSingleUrl;
      link.download = "stlSinglereport.xlsx";
      link.click();
      dispatch({
        type: ActionType.SET_SINGLE_STL_LOADING,
        payload: false,
      });
      dispatch({ type: ActionType.SET_SINGLE_STL_PERIOD, payload: "" });
      dispatch({
        type: ActionType.SET_SINGLE_STL_LOADED,
        payload: false,
      });
      localStorage.removeItem("stlSingleLoading");
    }
  };
  useEffect(() => {
    console.log(1);
  }, [management.stlSingleLoaded]);

  useEffect(() => {
    if (management.stlSingleLoaded) {
      if (management.stlSinglePeriod === "2023-24") {
        setButtonText("Download STL for 2023-24");
      } else {
        setButtonText("Download STL Report");
      }
    } else {
      setButtonText("Preparing STL Report");
    }
  }, [management.stlSinglePeriod, management.stlSingleLoaded]);

  return (
    <div className="download-report">
      <button
        onClick={download}
        className={`download-report__btn ${
          management.stlSingleLoaded ? "change-color" : ""
        }`}
      >
        {buttonText}
        {!management.stlSingleLoaded && (
          <div className="download-report__btn-flashing"></div>
        )}
        <span
          className="download-report__btn-text"
          onClick={(e) => {
            localStorage.removeItem("stlSingleLoading");
            dispatch({
              type: ActionType.SET_SINGLE_STL_LOADING,
              payload: false,
            });
            dispatch({ type: ActionType.SET_SINGLE_STL_PERIOD, payload: "" });
            e.stopPropagation();
          }}
        >
          <span className="download-report__btn-close">&times;</span>
        </span>
      </button>
    </div>
  );
};

export default StlSingleButton;
