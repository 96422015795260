import { FC } from "react";
import InfoSection from "./infoSection";
import PathIcon from "assets/img/path.svg";
import BorderedStartIcon from "assets/img/bordered-star-icon.svg";
import QuestionMark from "assets/img/question-mark-dark.svg";

interface Props {
  showScore?: boolean;
  setShowScore?: Function;
  isPrint: boolean;
  setShowModal?: Function;
  level?: any;
}

const ReportInfoContent: FC<Props> = ({
  showScore,
  setShowScore,
  isPrint,
  setShowModal,
  level,
}) => {
  return (
    <div
      className={`summative-objective-analysis__performance ${
        isPrint ? "print" : ""
      }`}
    >
      <div className="info-content">
        <p>
          The Summative Objective Analysis compares OAS objective proficiency on
          summative assessments across online classrooms by subject and grade
          level.
        </p>
        <p className="info-content__isolate">
          Click a class name or objective to isolate.
        </p>
      </div>
      <InfoSection level={level} />
      {!isPrint && (
        <div className="summative-objective-analysis__performance-objective">
          <div className="summative-objective-analysis__performance-rectangle">
            %
          </div>
          <p>Show Class Proficiency Percentage</p>
          <div
            className="switch switch-small mb-0"
            onClick={() => setShowScore && setShowScore(!showScore)}
          >
            <input
              tabIndex={0}
              className="switch-checkbox customFieldsFocused-active"
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              checked={showScore}
            />
            <span className="switch-slider switch-slider-small"></span>
          </div>
        </div>
      )}
      <div className="summative-objective-analysis__performance__description-content">
        <div>
          <img src={BorderedStartIcon} alt="star" />
          <span>Fewest Objectives at Risk</span>
        </div>
        <div>
          <img src={PathIcon} alt="path" />
          <span className="summative-objective-analysis__nearly-proficient">
            Nearly Proficient
          </span>
          {!isPrint && (
            <img
              className="cursor-pointer"
              src={QuestionMark}
              alt="question"
              onClick={() => setShowModal && setShowModal()}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default ReportInfoContent;
