import { PacingCalenderContext } from "contexts/pacingCalender";
import {
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import AssessmentBody from "./table/body";
import AssessmentHeader from "./table/header";
import PacingDelModel from "./pacingCalenderDel";
import SuggestionModel from "./SuggestionModel";
import SuggestedAssesment from "./suggestedAssesment";
import SheduledAssesmant from "./SheduledAssesmant";
import DeleteSingleAssessmentModal from "./deleteSingleAssessment";
import CommentModal from "components/classroom/modals/comment";
import { EmptyRecordPdAdmin } from "components/common/EmptyRecord";
import { createDate } from "util/createDate";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import { useLocation, useSearchParams } from "react-router-dom";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import { triggerGoalWithClassroom } from "services/common";
import { AppCookies, GOALS_CONDITIONS } from "util/constants";
import { ClassroomTooltipContext } from "contexts/classroomTooltip";
import { updateUserData } from 'services/common';
import { TooltipType } from "interfaces/common";
import AddAssesmentWarningModal from "./addWarningModal";
import SuggestedPacingCopyModal from "./copyModal";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";
import { useShowSurvey } from "hooks/useShowSurvey";
import Cookies from "js-cookie";
import FeedbackSurvey from "components/satisfaction-survey";

interface Props {
  modalDelShow: boolean;
  setModalDelShow: (show: boolean) => void;
  search?: string;
  setShowTooltipPacing?: Function;
}

const AssesssmentsTable = ({
  modalDelShow,
  setModalDelShow,
  search,
  setShowTooltipPacing
}: Props) => {
  const {
    classroomId,
    postPacingCalenderDates,
    suggestedAssessments,
    isSuperAdmin,
    currentMonthIndex,
    setScheduledDate,
    assessments,
    currentMonth,
    clearAssessments,
    putPacingCalender,
    allMonths,
    updateOrderBy,
    isAnyAssessment,
    editPacingCalenderDates,
    isAddDisabled,
    _setCurrentMonth,
    suggestedPacingFetched,
    setSuggestedPacingFetched,
    setSuggestedPacingCopyModal,
    suggestedPacingCopyModal,
    postPacingCalendarLoading,
    scheduledDate,
    setAssesments
  } = useContext(PacingCalenderContext);
  const { tooltip, setTooltip } = useContext(ClassroomTooltipContext);

  const { pacing_calendar_dates, pacing_calendar_exists } = assessments;

  const auth = useSelector((state: RootState) => state.auth);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const {getShowSurvey} = useShowSurvey();

  const [isMyOwnPacing, setIsMyOwnPacing] = useState<boolean>(null!);
  const [modalSuggestedShow, setModalSuggestedShow] = useState<boolean | null>(false);
  const [modalSuggestedAssesment, setModalSuggestedAssesment] = useState(false);
  const [modalSeheduleAssesment, setSeheduleAssesment] = useState(false);
  const [deleteAssessment, setDeleteAssessment] = useState(false);
  const [removeModal, setRemoveModal] = useState<any>(false);
  const [showAddWarning, setShowAddWarning] = useState(false);
  const [satisfactionSurvey, setSatisfactionSurvey] = useState(false);
  const showDeleteSingleModal = !!deleteAssessment;

  const isEditImportantDate =
    removeModal && removeModal.type && removeModal.type === "editModal";

  const onClickDelete = (assessment: any) => {
    setDeleteAssessment(assessment);
  };

  const onClearAssessment = async (
    isSingle: boolean,
    handler: Function,
    type?: "all"
  ) => {
    let isSuccess = false;
    isSuccess = isSingle
      ? await clearAssessments(deleteAssessment)
      : await clearAssessments(null, type);

    isSuccess && handler();
  };

  const onClickAdd = () => {
    pacing_calendar_exists || isMyOwnPacing
      ? setSeheduleAssesment(true)
      : setModalSuggestedShow(true);
  };

  const onClickRowIcon = (assessment: any) => {
    updateOrderBy("");

    setScheduledDate(assessment.date);
    isSuperAdmin ? setSeheduleAssesment(true) : onClickAdd();
  };

  const onSaveHandler = async (value: string) => {
    const date = isEditImportantDate ? removeModal.dateId : removeModal;
    const calendarDate = createDate(allMonths, currentMonthIndex, date);

    if (calendarDate) {
      const isSuccess = isEditImportantDate
        ? await editPacingCalenderDates({ date: calendarDate, title: value })
        : await postPacingCalenderDates({ date: calendarDate, title: value });
      isSuccess && setRemoveModal(null!);
    }
  };

  const { pacings } = useMemo(
    () =>
      isSuperAdmin
        ? suggestedAssessments
        : {
            pacings: pacing_calendar_dates as any,
            loading: assessments.loading,
          },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuperAdmin, pacing_calendar_dates, suggestedAssessments]
  );

  const filteredPacings = useMemo(() => {
    let datesHashMap = {};
    const modifiedPacings = pacings?.map((pacing: any) => {
      return {
        ...pacing,
        assessments: !Array.isArray(pacing.assessments)
          ? []
          : pacing.assessments.map((item: any) => {
              if (datesHashMap[item.date as keyof typeof datesHashMap]) {
                return {
                  ...item,
                  isAlreadyExists: true,
                };
              } else {
                datesHashMap = {
                  ...datesHashMap,
                  [item.date]: true,
                };

                return item;
              }
            }),
      };
    });

    return search
      ? modifiedPacings
          ?.map((pacing: any) => {
            return {
              ...pacing,
              assessments: pacing.assessments.filter((item: any) => {
                return (
                  item?.objective_full_name
                    ?.toLowerCase()
                    ?.includes(search.toLowerCase()) ||
                  item.summative_name
                    .toLowerCase()
                    .includes(search.toLowerCase()) ||
                  item.description.toLowerCase().includes(search.toLowerCase())
                );
              }),
            };
          })
          .filter((pacing: any) => pacing.assessments.length > 0)
      : modifiedPacings;
  }, [search, pacings]);

  // if user lands on page Pacing Calender with the month July or August
  // should popup the suggested modal
  useEffect(() => {
    if(!isMyOwnPacing &&
      !isSuperAdmin &&
      pacing_calendar_dates.length &&
      !pacing_calendar_exists  
    ){
      setModalSuggestedShow(true);
    }else{
      assessments?.loading === false && setModalSuggestedShow(null)
    }
    
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pacing_calendar_exists, pacing_calendar_dates, auth]);

  useEffect(() => {
    if (!isSuperAdmin && pacings?.length) {
      const { assessments } = pacings[0];
      assessments?.length &&
        assessments[0]?.date &&
        setScheduledDate(assessments[0].date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pacings]);

  useEffect(() => {
    if (location?.state && 'date' in location?.state && pacings?.length) {
      const date = location?.state.date;
      
      const month =
        date && date !== "Not Scheduled" ? moment(date).format("MMM") : null;
      
      const pacing = pacings.find((item: any) => {
        const pacingMonth = item.id.split("-")[0];
        return pacingMonth === month?.toLowerCase();
      }, []);

      pacing?.id && document.getElementById(pacing.id)?.scrollIntoView();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, pacings]);

  useEffect(() => {
    const task_id = searchParams.get("task_id");
    const goal_id = searchParams.get("goal_id");
    if (!isSuperAdmin && task_id && goal_id) {
      triggerGoalWithClassroom({
        classroomId,
        conditionName: GOALS_CONDITIONS.Open_Pacing_Calendar,
        task_id: parseInt(task_id),
        goal_id: parseInt(goal_id)
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const onClickHandler = useCallback(() => {
    console.log("onClickHandler")
    // setTooltip((prev: any) => {
    //   return prev + 1;
    // });

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    const isTooltipsSeen = assessments?.pacing_tooltips_seen && modalSuggestedShow === null && Cookies.get(AppCookies.showSurvey) !== "false";
    const isSatisfactionSurvey = getShowSurvey(isTooltipsSeen);

    if(isSatisfactionSurvey){
      setSatisfactionSurvey(true);
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  },[auth, assessments, isMyOwnPacing, suggestedPacingFetched])

  useEffect(() => {
    const onCompleteTooltip = async () => {
      if(tooltip === 8){
        setSuggestedPacingFetched(false);
        setSuggestedPacingCopyModal(null!);
        setTooltip(null!);
        const updated = await updateUserData(TooltipType.PACING);
        if(updated){
          setAssesments({
            ...assessments,
            pacing_tooltips_seen: true
          })
        }
        document.body.removeEventListener('click', onClickHandler, true)
      }
      
      if(tooltip === 7){
        setShowTooltipPacing && setShowTooltipPacing(false)
        if(window.innerWidth <= 1050){
          document.getElementById("assess-container")?.scrollTo({
            top: 0,
            left: window.innerWidth,
            behavior: 'smooth'
          })
        }
      }
    }

    onCompleteTooltip();
  },[tooltip]);

  useEffect(() => {
    if (assessments?.pacing_tooltips_seen || (!isMyOwnPacing && !suggestedPacingFetched)) {
      return;
    }

    setTooltip(null) //setTooltip(6);
    document.body.addEventListener("click", onClickHandler, true);

    return () => {
      document.body.removeEventListener("click", onClickHandler, true);
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [assessments, isMyOwnPacing, suggestedPacingFetched]);
  
  const showPDTooltip =
    (isMyOwnPacing || suggestedPacingFetched) && tooltip === 6;
  const showPDAssessmentTooltip = (isMyOwnPacing || suggestedPacingFetched) && tooltip === 7;

    useEffect(() => {
      if(showPDTooltip){
        setShowTooltipPacing && setShowTooltipPacing(true);
      }

      /* eslint-disable react-hooks/exhaustive-deps */
    },[showPDTooltip])

  return (
    <>
      <div className="PacingCalender">

        {/* {showPDAssessmentTooltip && (
          <ClassroomTooltip
            arrowDirection={"right"}
            className="ProfessionalDev__devSummary__toolTip pacing-calender-position-assessment"
            message="Use this to select an assessment to schedule."
          />
        )} */}

        <div id="assess-container" className="PacingCalender__container pdAdminContainer position-relative">
          <table className="PacingCalender__container__table  ">
            {(isSuperAdmin ? suggestedAssessments.loading : assessments?.loading) && classroomId > 0 ? (
              <tbody>
                <TableSkeleton baseColor="#2a539143" highlightColor="#5371b732" columns={4} count={25} height={53} />
              </tbody>
            ) : filteredPacings && filteredPacings?.length ? (
              filteredPacings?.map((assessment: any, index: number) => {
                const { name, assessments, id } = assessment;
                const noData = assessments.every((item: any) => item.id === null || item.id === 0)
                return (
                  <tbody id={id} key={id}>
                    <AssessmentHeader noData= {noData} index={index} showPDTooltip={showPDTooltip} date={name} isSuperAdmin={isSuperAdmin} />
                    <AssessmentBody
                      isSuperAdmin={isSuperAdmin}
                      onClickDelete={onClickDelete}
                      setModalSuggestedShow={onClickRowIcon}
                      assessments={assessments}
                      setRemoveModal={setRemoveModal}
                      dateId={name}
                      searchedValue={search}
                      isAddDisabled={isAddDisabled}
                      idx={index}
                      showPDAssessmentTooltip={showPDAssessmentTooltip}
                      showAddWarning={() => setShowAddWarning(true)}
                      scheduledDate={scheduledDate}
                      postPacingCalendarLoading={postPacingCalendarLoading}
                      noData={noData}
                    />
                    {/* <tr className="PacingCalender__container__table__bodyrow note">
                      <td colSpan={4} className="PacingCalender__container__table__bodyrow__infotext">
                        <span>
                          <Bulb />
                          Baseline benchmark (A1) should be administered by end of September.
                        </span>
                      </td>
                    </tr> */}
                  </tbody>
                );
              })
            ) : (
              <EmptyRecordPdAdmin
                text={
                  !classroomId
                    ? "Select classroom from the dropdown above"
                    : "No Results Found"
                }
              />
            )}
          </table>
        </div>
      </div>
      {showAddWarning && (
        <AddAssesmentWarningModal
          assessmentToDelete={deleteAssessment}
          setModalShow={() => setShowAddWarning(false)}
          isSuperAdmin={isSuperAdmin}
        />
      )}
      {showDeleteSingleModal && (
        <DeleteSingleAssessmentModal
          onClearSingleAssessment={onClearAssessment}
          assessmentToDelete={deleteAssessment}
          setModalShow={() => setDeleteAssessment(null!)}
          isSuperAdmin={isSuperAdmin}
        />
      )}
      {modalDelShow === true && (
        <PacingDelModel
          onClearAssessment={onClearAssessment}
          currentMonth={currentMonth?.month || ""}
          show={true}
          setModalShow={setModalDelShow}
          isAnyAssessment={isAnyAssessment}
        />
      )}
      {modalSuggestedShow === true && (
        <SuggestionModel
          show={true}
          setModalSuggestedAssesment={setModalSuggestedAssesment}
          setModalSuggestedShow={() => setModalSuggestedShow(null)}
          setSeheduleAssesment={() => {
            // setSeheduleAssesment(true);
            setIsMyOwnPacing(true);
          }}
          onClickUseSuggestedPacing={putPacingCalender}
          isCalenderExists={pacing_calendar_exists}
          setSuggestedPacingCopyModal={setSuggestedPacingCopyModal}
        />
      )}

      {suggestedPacingCopyModal && (
        <SuggestedPacingCopyModal
          setModal={(data:any) => {
            _setCurrentMonth(allMonths, assessments?.first_month);
            setSuggestedPacingCopyModal(data);
            (!assessments?.first_month || currentMonth.month === assessments?.first_month) && 
            setSuggestedPacingFetched(true)
          }}
        />
      )}

      {modalSuggestedAssesment === true && (
        <SuggestedAssesment
          show={true}
          setModalSuggestedAssesment={setModalSuggestedAssesment}
          setModalSuggestedShow={setModalSuggestedShow}
        />
      )}
      {modalSeheduleAssesment === true && (
        <SheduledAssesmant
          show={true}
          setModalShow={setSeheduleAssesment}
          setModalSuggestedShow={isSuperAdmin ? null! : setModalSuggestedShow}
        />
      )}

      {removeModal && (
        <CommentModal
          show={removeModal}
          reply={null!}
          title={
            isEditImportantDate ? "Edit Important Date" : "Add Important Date"
          }
          onClose={() => setRemoveModal(null!)}
          onSave={onSaveHandler}
          data={isEditImportantDate ? removeModal.data : ""}
          placeholder="Enter Text here"
          label="Text"
          disabled={!isEditImportantDate}
          submitButtonName={isEditImportantDate ? "Save" : "Post"}
        />
      )}

      {satisfactionSurvey && (
        <FeedbackSurvey
          satisfactionSurvey={satisfactionSurvey}
          closeModal={() => setSatisfactionSurvey(false)}
        />
      )}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default memo(AssesssmentsTable);
