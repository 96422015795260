import { useMemo } from "react";

export const useGetChartData = (payload: any) => {
    

    
    const chartData = useMemo(() => {
        const {dataValues, backgroundColor, isNotData} = payload;
        return {
            data: {
                datasets: [
                    {
                        data: [...dataValues],
                        backgroundColor: [
                            ...backgroundColor
                        ],
                        borderColor: [
                            ...backgroundColor
                        ],
                        borderWidth: 1,
                        padding: 10
                    },
                    
                ],
            },
            options: {
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 20,
                        right: 20,
                        bottom: 10
                    }
                },
                plugins: {
                    datalabels:{
                        clip: false,
                        display: !isNotData,
                        align: 'end',
                        backgroundColor: '#3E3E3E',
                        borderRadius: 8,
                        color: '#fff',
                        font: {
                            size: 17,
                            family: 'Nunito'
                        },
                        padding: (chart: any) => {
                            const value = chart.dataset.data[chart.dataIndex];
                            const isSingleDigit = value >= 0 && value <= 9;

                            if(isSingleDigit){
                                return {
                                    top: 4,
                                    bottom: 1,
                                    left: 9.5,
                                    right: 9.5
                                }
                            }
                            
                            return {
                                top: 5,
                                bottom: 1,
                                left: 5,
                                right: 5
                            }
                        }
                    },
                    tooltip:{
                        enabled: false,
                    }
                }
            },
        }
    },[payload]);

    return chartData;
}