export const grabInitialsIfMoreThanOneWord = (string: string) => {
    if(!string.length) return '';
    const names = string.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase().concat('. ');
    
    if (names.length > 1) {
        const lastWord = names[names.length - 1];
        initials += lastWord.charAt(0).toUpperCase() + lastWord.slice(1);
        // initials += names[names.length - 1].substring(0, 1).toUpperCase(); // for last word's first letter only.
    } else {
        // When we have only one word we do not need to send initials.
        return string;
    }

    return initials;
}