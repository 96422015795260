/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import QuestionMarkIcon from "assets/img/question-mark-tooltip-icon.png";
import CustomModal from "components/common/Modal";

interface Props {
  show: boolean;
  setModalShow: any;
  isRandom: boolean
}

const HowToReadFormative: React.FC<Props> = (props) => {
  const { show, setModalShow, isRandom } = props;

  return (
    <>
      <CustomModal centered show={show} onEscapeKeyDown={() => setModalShow(false)} >
        <div className="modal__header">
          <img
            src={QuestionMarkIcon}
            className="modal__header-iconQuestion"
            alt="Question"
          />
          <h2 className="modal__header-title">How to Read Progress</h2>
        </div>

        <div className="modal__subheader">
          <p className="modal__body-text mb-0">Questions are in order from left to right.</p>
        </div>
        <div className="modal__body ps-0 pe-0">
          <div className="read__progress-content">
            <ul className="read__progress-resultList">
              <li className="average_rating">
                1
              </li>
              <li className="average_rating">
                2
              </li>
              <li className="average_rating">
                3
              </li>
              <li className="average_rating">
                4
              </li>
              <li className="average_rating">
                5
              </li>
            </ul>
            <p className="read__progress-paragraph">
              As the student responds, the numbers will change to reflect their
              answer. The <strong>white</strong> outline indicates they are
              currently on that question. The box will be green if they answered
              correctly and red if the response was incorrect.
            </p>
            <ul className="read__progress-resultList u-mb-10">
              <li className="high_rating">
                A
              </li>
              <li className="low_rating">
                C
              </li>
              <li className="high_rating">
                D
              </li>
              <li className="high_rating">
                C
              </li>
              <li className="average_rating active">
                A
              </li>
            </ul>
          </div>
          {
            isRandom &&
            <p className="read__progress-paragraph">
              If the session is set to <strong>random choice order</strong>, each student will have their own unique random order of questions in the Student Portal. The question order shown here in Progress is the original order of the assessment and won’t match the student question numbers.            </p>
          }
        </div>
        <div className="modal__footer">
          <button
            type="button"
            className="btn success-btn"
            onClick={() => setModalShow(false)}
          >
            Got It
          </button>
        </div>
      </CustomModal>
    </>
  );
};

export default HowToReadFormative;
