import { useLayoutEffect } from 'react';
import Header from './Header';
import Footer from './Footer';
import QuestionContainer from './QuestionContainer';
import QuestionnaireContextProvider from './QuestionnaireContext'

const Assessment = () => {

    useLayoutEffect(()=>{
        document.body.style.backgroundColor = "unset";

         return() => {
            document.body.style.backgroundColor = "#194792"
        }
    }, [])

    return(
        <QuestionnaireContextProvider>
            <Header />
            <QuestionContainer />
            <Footer isPreview={false} />
        </QuestionnaireContextProvider>
    );
}

export default Assessment;