import React from "react";
// import logo from "assets/img/AlphaPlusLogo.png";
import cookie from "js-cookie";
import SelectDropdown from "assets/img/profile-dropdown-icon.svg";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "redux/actionCreators/auth";
import Logo from "components/common/svgs/logo";
import { AppCookies } from "util/constants";

const Header: React.FC<{}> = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const fullName: string = `${cookie.get(AppCookies.first_name)} ${cookie.get(
        AppCookies.last_name
    )}`;

    const initials = (fullName as any).match(/\b(\w)/g).join("");
    const userRole = cookie.get(AppCookies.role);

    const userLogout = (event: any) => {
        event.preventDefault();
    
        dispatch(logout());
    
        navigate('/')
    };

    
    return (
        <div className="cmsHeader">
            <div className="pg-container">
                <div className="cmsHeader-wrapper logo-fix">
                    <div className="cmsHeader__logo">
                        <a href="/#" className="cmsHeader__logo-link">
                            <Logo />
                            {/* <img src={logo} alt="Logo" className="cmsHeader__logo-img"/> */}
                        </a>
                    </div>

                    <div style={{opacity: location?.pathname === '/cms/select-assessment' ? 0.4 : 1}} className="text-decoration-none dropdown-toggle cmsHeader__user-wrapper">
                        <a
                        href="!#"
                        className="cmsHeader__user-wrapper"
                        id="loginuser"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        role="button"
                        >
                            <span className="cmsHeader__user-box">{initials}</span>

                            <div className="cmsHeader__user-info">
                                <p className="cmsHeader__user-name">{fullName}</p>
                                <p className="cmsHeader__user-detail">{userRole}</p>
                            </div>
                            <span className="cmsHeader__user-icon">
                                <img src={SelectDropdown} alt="selectDropdown" />
                            </span>
                        </a>
                        <ul
                        className="dropdown-menu text-small login-user-dropdown"
                        aria-labelledby="loginuser"
                        >
                        <li>
                            <a className="dropdown-item" href="!#">
                            Help
                            </a>
                        </li>
                        <li>
                            <a className="dropdown-item" href="!#">
                            Change Password
                            </a>
                        </li>
                        <li>
                            <a className="dropdown-item" href="!#">
                            Submit a Ticket
                            </a>
                        </li>
                        <li>
                            <hr className="dropdown-divider" />
                        </li>
                        <li>
                            <a
                            className="dropdown-item"
                            href="!#"
                            onClick={userLogout}
                            >
                            Log Out
                            </a>
                        </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
