/* eslint-disable react-hooks/exhaustive-deps */

import React, {useCallback, useEffect, useRef, useState} from "react";
import cryptoRandomString from "crypto-random-string";
import _ from "lodash";
import moment from "moment";
import {Collapse} from "react-bootstrap";
import EditIconGray from "assets/img/edit-table.svg";
import RPIconGray from "assets/img/chat-close.svg";
import DeleteIconGray from "assets/img/delete-table.svg";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import SearchIcon from "assets/img/search-icon.svg";
import SearchIconGray from "assets/img/search-icon-gray.svg";
import Pagination from "../pagination/pagination";
import {ClassAdminAddModal, ResetPasswordModal} from "./management-add-modal";
import {ClassAdminDeleteModal} from "../management/management-delete-modal";
import {ClassAdminUpdateModal} from "../management/management-update-modal";
import {useDispatch, useSelector} from "react-redux";
import {TableSkeleton} from "components/common/skeleton/TableSkeleton";
import {LimitSelect} from "components/common/selectComponent";
import {
    addClassAdmin,
    deleteClassAdmin,
    getClassAdmins,
    resetPasswordAdmin,
    setFlagForFetch,
    updateClassAdminDetail
} from "../../redux/actionCreators/management";
import {ViewClassesModal} from "./modals/viewClassesModal";
import useSortBy from "hooks/useSortBy";
import SortIndicatorIcon from "components/common/svgs/sort-indicator-icon";
// import ArrowDownImg from "assets/img/table-class-before.svg";

interface RootState {
    management: any;
    auth: any;
}

const ClassAdmins: React.FC<{ schoolId?: string; page?: string, first?: any }> = ({
                                                                                      schoolId,
                                                                                      page,
                                                                                      first
                                                                                  }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const {management, auth} = useSelector((state: RootState) => state);
    const [open, setOpen] = useState(false);
    const [offSet, setOffSet] = useState(0);
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [currentItem, setCurrentItem] = useState<null | any>(null);
    const [updateModal, setUpdateModal] = useState(false);
    const [modalBorder, setModalBorder] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [resetPassModdal, setResetPassModdal] = useState(false);
    const [randomPass, setRandomPass] = useState("");
    // const [skeletonLoad, setSkeletonLoad] = useState(true);
    const [skeletonCount, setSkeletonCount] = useState(10);
    const [openClassesModal, setOpenClassedModal] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [lastAddedId, setLastAddedId] = useState(0);

    const {sortBy, OrderByColumn, sortDataBy}=useSortBy("last_name", "ASC");
   

    useEffect(() => {
        let params = {
            offSet,
            limit,
            searchTerm,
            schoolId,
            sortBy,
            OrderByColumn
        };
        dispatch(getClassAdmins(params));
    }, [offSet, limit, management.classAdminsFlag, dispatch, sortBy, OrderByColumn, first, schoolId]);

    const handleSearch = useCallback(
        _.debounce((val: any) => {
            console.log(val);
            setOpen(true);
            setCurrentPage(1)
            setOffSet(0)
            let params = {
                offSet: 0,
                limit,
                searchTerm: val,
                schoolId,
                sortBy,
                OrderByColumn
            };
            dispatch(getClassAdmins(params));
            if (inputRef.current !== null) {
                inputRef.current.focus();
            }
        }, 1000),
        []
    );

    useEffect(() => {
        /* Fetch page number for last added record*/
        if (management.lastAddedClassAdminId > 0) {
            setLastAddedId(management.lastAddedClassAdminId)
            setSearchTerm('')
            let params = {
                offSet: 0,
                limit: 10,
                searchTerm: '',
                schoolId,
                sortBy,
                OrderByColumn,
                new_admin_id: management.lastAddedClassAdminId
            };
            dispatch(getClassAdmins(params));
        }
        /* Set page and fetch record of that page */
        if (management.classAdminPageNumber > 0) {
            setLimit(10)
            setCurrentPage(management.classAdminPageNumber)
            setOffSet((management.classAdminPageNumber * 10) - 10)
            management.classAdminPageNumber === currentPage && dispatch(setFlagForFetch('SET_CLASS_ADMIN_FLAG'))
            setTimeout(() => {
                setLastAddedId(0);
            }, 2000);
        }
    }, [management.lastAddedClassAdminId, management.classAdminPageNumber]);

    useEffect(() => {
        if (lastAddedId > 0 && !management.classAdminsLoading) {
            const element = document.getElementById('class-admin-data')
            element?.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    }, [management.classAdmins, management.classAdminsLoading]);

    const doChangePage = (pageNo: number) => {
        let newOffset = (pageNo * limit) - limit
        setCurrentPage(pageNo);
        setOffSet(newOffset);
    };

    const doSetDelModal = (item: any) => {
        console.log(item, "ITEM.................");
        setCurrentItem(item);
        setDeleteModal(true);
    };

    const doSetUpdateModal = (item: any) => {
        console.log(item, ".................");
        setCurrentItem(item);
        setUpdateModal(true);
    };

    const setPreviousPage = () => {
        if (currentPage > 1 && management.classAdmins.length === 1) {
            doChangePage(currentPage - 1)
        }
        else {
            dispatch(setFlagForFetch('SET_CLASS_ADMIN_FLAG'))
        }
    }

    const onDeleteClassAdmin = () => {
        console.log('2', currentItem)
        dispatch(
            deleteClassAdmin(
                currentItem?.classroom_id,
                currentItem?.id,
                setDeleteModal,
                setPreviousPage,
                true
            )
        );
    };

    const onUpdateCLassAdmin = (data: any) => {
        dispatch(
            updateClassAdminDetail(
                currentItem.id,
                data,
                setUpdateModal
            )
        );
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setModalBorder(true);
        }, 300);
        
        return () => {
            clearTimeout(timer);
            setModalBorder(false);
        }
    }, [open]);

    const doSetPasModal = (item: any) => {
        setCurrentItem(item);
        let newPass = cryptoRandomString({length: 10, type: "url-safe"});
        setRandomPass(newPass);
        setResetPassModdal(true);
    };
    const onCreatePassword = () => {
        let data = {
            admin_id: currentItem?.id,
            password: randomPass,
        };
        console.log(currentItem);
        dispatch(resetPasswordAdmin(data, setResetPassModdal));
        // setCurrentItem(null)
    };

    const onAddClassAdmin = (data: any) => {
        let finalData = {...data};
        dispatch(addClassAdmin(finalData, setAddModal));
        !open && setOpen(true)
    };

    useEffect(() => {
        // const timer = setTimeout(() => {
        //     setSkeletonLoad(false);
        // }, 800);

        if ((limit - ((currentPage * limit) - management.classAdminsCount)) < limit) {
            setSkeletonCount(limit - ((currentPage * limit) - management.classAdminsCount));
        } else {
            setSkeletonCount(limit);
        }

        return () => {
            // clearTimeout(timer);
            // setSkeletonLoad(true);
        }
    }, [management.classAdminsLoading]);

    const tableBodayHeight = (skeletonCount * 37) + 'px';

    return (
        <>
            <div
                className={`accordion__header ${open ? "open" : ""} ${
                    modalBorder ? "closeDelay" : ""
                    }`}
                onClick={(e) => {
                    if ((e.target as Element).nodeName === "INPUT" ||
                        (e.target as Element).nodeName === "BUTTON" ||
                        (e.target as Element).nodeName === "A") {
                        return;
                    }
                    setOpen(!open);
                }}
            >
                <div className="accordion__header-heading">
          <span
              className="accordion__header-button"
          >
            <img src={ArrowIcon} alt="arrow" className={open ? "rotate" : ""}/>
          </span>
                    <h6>Class Admins</h6>
                    {!open ? (
                        <div className="accordion__header-search animated">
                            <img src={SearchIcon} alt="Search"/>
                            <input
                                value={searchTerm}
                                onChange={(e) => {
                                    handleSearch(e.target.value);
                                    setSearchTerm(e.target.value);
                                }}
                                type="text"
                                className="form-control"
                                placeholder="Search Class Admins"
                            />
                        </div>
                    ) : (
                        ""
                    )}
                </div>
                <div className="accordion__header-text d-none d-lg-block">
                    {page === "school" && open ? (
                        <p>Select an admin below to manage. Use the pencil to edit.</p>
                    ) : (
                        ""
                    )}
                </div>
                {auth && auth.currentUser?.role === "Super Admin" ? (
                    <div>
                        {page === "school" ? (
                            <div className="accordion__header-buttons">
                                <div className="accordion__header-btnContainer">
                                    <button
                                        onClick={() => setAddModal(true)}
                                        className="btn outline-btn outline-btn-lg"
                                    >
                                        Add Class Admin
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="accordion__header-buttons">
                                <div className="accordion__header-btnContainer">
                                    <a className="btn outline-btn outline-btn-lg" href="/#">
                                        Import CSV
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
            <Collapse in={open}>
                <div>
                    <div className="accordion__content">
                        <div className="section-search">
                            <div className="section-search-field animated">
                                <div className="icon">
                                    <img src={SearchIconGray} alt="search"/>
                                </div>
                                <input
                                    ref={inputRef}
                                    value={searchTerm}
                                    onChange={(e) => {
                                        handleSearch(e.target.value);
                                        setSearchTerm(e.target.value);
                                    }}
                                    type="text"
                                    className="form-control"
                                    placeholder="Search Class Admins"
                                />
                            </div>

                            <div className="d-flex align-items-center show-classes-dropdown">
                                <span>Show</span>
                                <LimitSelect
                                    disableHandler={management?.classAdmins?.length < 10}
                                    onChangeHandler={(e: any) => setLimit(Number(e?.value))}
                                />
                                <span>Admins</span>
                            </div>
                        </div>

                        <div className="admins-table-container">
                            <div className="table-responsive">
                                <table className="admins__table">
                                    <thead>
                                    <tr className="admins__table-row">
                                        <th 
                                            tabIndex={0}
                                            className="admins__table-name" 
                                            onClick={() => sortDataBy("last_name")}
                                            onKeyDown={(e)=>{
                                                if(e.key==="Enter"){
                                                    sortDataBy("last_name")
                                                }
                                            }}
                                            >
                                            Admin Name
                                           {sortBy === "last_name" && 
                                        <SortIndicatorIcon OrderByColumn={OrderByColumn} stroke="#323233" />
                                            }     
                                        </th>
                                        <th 
                                            tabIndex={0}
                                            className="admins__table-email" 
                                            onClick={() => sortDataBy("email")}
                                            onKeyDown={(e)=>{
                                                if(e.key==="Enter"){
                                                    sortDataBy("email")
                                                }
                                            }}
                                            >Email
                                         {sortBy === "email" && 
                                            <SortIndicatorIcon OrderByColumn={OrderByColumn} stroke="#323233" />
                                        }  
                                        </th>
                                        <th 
                                            tabIndex={0}
                                            className="admins__table-title" 
                                            onClick={() => sortDataBy("title") }
                                            onKeyDown={(e)=> {
                                                if(e.key==="Enter"){
                                                    sortDataBy("title");
                                                }
                                            }} 
                                             >
                                                    Title
                                            {sortBy === "title" && 
                                           <SortIndicatorIcon OrderByColumn={OrderByColumn} stroke="#323233" />
                                            }  
                                        </th>
                                        <th className="admins__table-admin">
                                            Admin is <br/>
                                            Teacher
                                        </th>
                                        <th 
                                            tabIndex={0}
                                            className="admins__table-class class" 
                                            onClick={() =>sortDataBy("classroom_count") }
                                            onKeyDown={(e)=>{
                                                if(e.key==="Enter"){
                                                    sortDataBy("classroom_count")
                                                }
                                            }}
                                            >Classes
                                            {sortBy === "classroom_count" && 
                                         <SortIndicatorIcon OrderByColumn={OrderByColumn} stroke="#323233" />
                                            } 
                                        </th>
                                        <th 
                                        tabIndex={0}
                                        className="admins__table-login" 
                                        onClick={() => sortDataBy("last_login")}
                                        onKeyDown={(e)=>{
                                            if(e.key==="Enter"){
                                                sortDataBy("last_login")
                                            }
                                        }}
                                        >Last Login
                                         {sortBy === "last_login" && 
                                            <SortIndicatorIcon OrderByColumn={OrderByColumn} stroke="#323233" />
                                         }
                                        </th>
                                        {auth && auth.currentUser?.role === "Super Admin" && <th className="admins__table-login">PIN</th>}
                                        <th className="admins__table-options class">Options</th>
                                    </tr>
                                    </thead>
                                    <tbody style={{height: tableBodayHeight}}>
                                        
                                    {/* {skeletonLoad ? (
                                        <TableSkeleton columns={8} count={skeletonCount} height={36}/>
                                    ) : (
                                        ""
                                    )} */}
                                    {

                                    management.classAdminsLoading ?
                                        <TableSkeleton columns={8} count={skeletonCount} height={36}/>
                                    : management?.classAdmins?.length ? (
                                            management.classAdmins.map((item: any, i: number) => {
                                                return (
                                                    <tr
                                                        key={i}
                                                        className={
                                                            lastAddedId === item.id ? "admins__table-row highlight-row" : i % 2 !== 0
                                                                ? "admins__table-row"
                                                                : "admins__table-row"
                                                        }
                                                        id={lastAddedId === item.id ? 'class-admin-data' : ''}
                                                    >
                                                        <td className="name-data cursor-pointer fw-bold" onClick={() => {
                                                            setSelectedAdmin(item)
                                                            setOpenClassedModal(true)
                                                        }
                                                        }
                                                        >
                                                            {item?.last_name}, {item?.first_name}
                                                        </td>
                                                        <td className="email-data">
                                                            {item?.email}
                                                        </td>
                                                        <td className="title-data">{item?.title}</td>
                                                        <td className="text-center">
                                                            {item?.is_teacher ? "Yes" : "No"}
                                                        </td>
                                                        <td className="text-center cursor-pointer" onClick={() => {
                                                            setSelectedAdmin(item)
                                                            setOpenClassedModal(true)
                                                        }
                                                        }>
                                                            {item?.classrooms ? item?.classrooms : 0}
                                                        </td>
                                                        <td className="text-center">
                                                            {item?.last_login
                                                                ? moment(item?.last_login).format("M-D-YY")
                                                                : "-"}
                                                        </td>
                                                        {auth &&
                                                            auth.currentUser?.role === "Super Admin" && <td className="text-center">{item?.support_pin || ''}</td>}
                                                        <td className="options-data class">
                                                            <div
                                                                className="d-flex justify-content-start align-items-center">
                                                                <img
                                                                    onClick={() => doSetUpdateModal(item)}
                                                                    src={EditIconGray}
                                                                    alt="Edit"
                                                                    className="management__table-editBtn"
                                                                />
                                                                {auth &&
                                                                auth.currentUser?.role === "Super Admin" ? (
                                                                    <img
                                                                        onClick={() => doSetPasModal(item)}
                                                                        src={RPIconGray}
                                                                        alt=""
                                                                        className="management__table-editBtn"
                                                                    />
                                                                ) : (
                                                                    ""
                                                                )}
                                                                <img
                                                                    onClick={() => doSetDelModal(item)}
                                                                    src={DeleteIconGray}
                                                                    alt="Delete"
                                                                    className="management__table-editBtn"
                                                                />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) :
                                        <tr>
                                            {management?.classAdmins?.length === 0 && <td colSpan={8} className="no-admin">
                                                No Admins
                                            </td>}
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <Pagination
                        totalCount={management.classAdminsCount}
                        currentPage={currentPage}
                        pageSize={limit}
                        onPageChange={doChangePage}
                    />
                </div>
            </Collapse>
            {deleteModal &&
            <ClassAdminDeleteModal
                loading={management.classAdminsDeleteLoading}
                isShow={deleteModal}
                headerText={`Delete ${currentItem?.first_name} ${currentItem?.last_name}`}
                bodyText={`Are you sure you want to delete this class admin?`}
                onCancel={() => setDeleteModal(false)}
                onDelete={onDeleteClassAdmin}
            />
            }
            {updateModal && (
                <ClassAdminUpdateModal
                    isShow={updateModal}
                    first_name={currentItem?.first_name}
                    last_name={currentItem?.last_name}
                    input_email={currentItem?.email}
                    input_title={currentItem?.title || ''}
                    onCancel={() => setUpdateModal(false)}
                    onUpdate={(data) => onUpdateCLassAdmin(data)}
                    loading={management.classAdminsUpdateLoading}
                />
            )}
            {resetPassModdal && (
                <ResetPasswordModal
                    isShow={resetPassModdal}
                    loading={management?.resetPwLoading}
                    onCancel={() => setResetPassModdal(false)}
                    onAdd={onCreatePassword}
                    password={randomPass}
                    title={`${currentItem?.first_name} ${currentItem?.last_name}`}
                />
            )}
            {addModal &&
            <ClassAdminAddModal
                // classroom_id={Number(classId)}
                isShow={addModal}
                loading={management.classAdminsAddLoading}
                onCancel={() => setAddModal(false)}
                onAdd={(data: any) => onAddClassAdmin(data)}
                create
                school_id={schoolId}
            />
            }
            {openClassesModal &&
            <ViewClassesModal
                isShow={openClassesModal}
                data={selectedAdmin}
                onClose={() => setOpenClassedModal(false)}
            />
            }
        </>
    );
};

export default ClassAdmins;
