/* eslint-disable react-hooks/exhaustive-deps */

import { forwardRef } from "react";
import { Modal } from "react-bootstrap";
import QuestionMarkIcon from "assets/img/question-mark-tooltip-icon.png";

interface Props {
  show: boolean;
  closeModal: any;
}

const NewAttemptInfoModal = forwardRef<any, Props>((props, ref) => {
  const { show, closeModal } = props;

  return (
    <Modal
      centered
      show={show}
      onEscapeKeyDown={closeModal}
      className="remediate-modal-info"
    >
      <div ref={ref}>
        <div className="modal__header">
          <img
            className="question-mark-icon modal__header-icon mt-0"
            src={QuestionMarkIcon}
            alt=""
          />
          <h2 className="modal__header-title">Assign New Attempts</h2>
        </div>
        <div className="modal__body review-modal">
          <p className="modal__body-text mb-0">
            After giving a Formative Assessment, if less than 70% of the class
            were not proficient on the test, reteach the objective using a
            different instructional path. If the proficiency rate is greater
            than 70%, group students for remediation.
            <br />
            <br /> When planning remediation strategies, review the formative
            test questions and group students for remediation based on the
            misunderstanding of concepts within the questions. Once students
            have a better understanding of the objective, regive the formative.
            <br /> <br />
            <span className="fw-bold">Assign new attempts</span> will enable new
            attempts in the <span className="fw-bold">Student Portal</span> for
            all non-proficient students who have yet to complete three attempts
            and do not have an attempt in progress.
          </p>
        </div>
        <div className="modal__footer">
          <button
            type="button"
            className="btn success-btn"
            onClick={closeModal}
          >
            Got it
          </button>
        </div>
      </div>
    </Modal>
  );
});

export default NewAttemptInfoModal;
