import { useNavigate } from "react-router-dom";
import ArrowIcon from "assets/img/accordian-box-icon.png";

const BackButton = ({onClick, onKeyDown}:any) => {
    const navigate = useNavigate();
    return (
        <span   
            role="button"
            tabIndex={0}
            onKeyDown={(e: any) => {
                if(onKeyDown){
                    onKeyDown(e)
                }else if (e.key === "Enter") {
                    navigate(-1);
                }
            }}
            onClick={(e) => {
                onClick ? onClick(e) : navigate(-1);
            }} 
            className="accordion__header-button"
        >
            <img
                src={ArrowIcon}
                alt="arrow"
                className="rotate-90"
            />
        </span>
    )
};

export default BackButton;
