/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable */

import React, { useState, useEffect, useRef, useCallback } from "react";
import moment from "moment";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import EditIcon from "assets/img/edit-table.svg";
import DeleteIcon from "assets/img/delete-table.svg";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import ExcelReportIconGray from "assets/img/excel-report-gray-icon.svg";
import SearchIcon from "assets/img/search-icon.svg";
import {default as SearchIconSVG} from "components/common/svgs/search-icon";
import ArrowDownImg from "assets/img/drop-black-icon-down.svg";
import Pagination from "../pagination/pagination";
import { DistrictDeleteModal } from "./management-delete-modal";
import { DistrictUpdateModal } from "./management-update-modal";
import { DistrictAddModal } from "./management-add-modal";
import { useDispatch, useSelector } from "react-redux";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import { LimitSelect } from "components/common/selectComponent";
import {
  getDistricts,
  deleteDistrict,
  updateDistrict,
  addDistrict,
  setDistrictLogo,
  requestStlReport,
  setFlagForFetch,
  updateDemoDistrictToggle,
  updateDistrictList,
} from "../../redux/actionCreators/management";
import ActionType from "redux/actionTypes";
import PermissionHintModal from "./modals/permissionHintModal";
import SortIndicatorIcon from "components/common/svgs/sort-indicator-icon";
import PencilIconManagement from "components/common/svgs/pencil-icon-management";
import TrashIcon from "components/common/svgs/trash-icon";
import ExcelReportIcon from "components/common/svgs/excel-report-icon";
import useSortBy from "hooks/useSortBy";
import Toggle from "components/common/Toggle";

interface RootState {
  management: any;
  auth: any;
}

const Districts = (props: any) => {
  const { onNavigate } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { management, auth } = useSelector((state: RootState) => state);
  const [open, setOpen] = useState(false);
  const [offSet, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<null | any>(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [modalBorder, setModalBorder] = useState(false);
  // const [skeletonLoad, setSkeletonLoad] = useState(true);
  const {sortBy, OrderByColumn, sortDataBy} = useSortBy("name", "ASC");
 
  const [lastAddedId, setLastAddedId] = useState(0);
  const [reportDisableModal, setReportDisableModal] = useState(false);

  useEffect(() => {
    getDistrictsHandler();
  }, [offSet, limit, management.districtFlag, dispatch, sortBy, OrderByColumn]);

  useEffect(() => {
    const districtsData = management?.districts?.map((district: any) => {
      if (district.id === management.refreshDistrictId) {
        return {
          ...district,
          demo_district: management.refreshDistrictValue,
        };
      } else {
        return district;
      }
    });

    dispatch(
      updateDistrictList(
        districtsData,
        management?.districtsCount,
        management?.page
      )
    );
  }, [
    management.refreshDistrictList,
    management.refreshDistrictId,
    management.refreshDistrictValue,
  ]);

  const getDistrictsHandler = () => {
    dispatch(getDistricts(offSet, limit, searchTerm, sortBy, OrderByColumn));
  };

  /*eslint-disable */
  const handleSearch = useCallback(
    debounce((val: any) => {
      console.log(val);
      setOpen(true);
      setCurrentPage(1);
      setOffSet(0);
      dispatch(getDistricts(0, limit, val, sortBy, OrderByColumn));
      if (inputRef.current !== null) {
        inputRef.current.focus();
      }
    }, 1000),
    []
  );

  useEffect(() => {
    if (management.lastAddedDistrictId > 0) {
      setLastAddedId(management.lastAddedDistrictId);
      setSearchTerm("");
      dispatch(
        getDistricts(
          0,
          10,
          "",
          sortBy,
          OrderByColumn,
          management.lastAddedDistrictId
        )
      );
    }
    if (management.districtPageNumber > 0) {
      setLimit(10);
      setCurrentPage(management.districtPageNumber);
      setOffSet(management.districtPageNumber * 10 - 10);
      management.districtPageNumber === currentPage &&
        dispatch(setFlagForFetch("SET_DISTRICT_FLAG"));
      setTimeout(() => {
        setLastAddedId(0);
      }, 2000);
    }
  }, [management.lastAddedDistrictId, management.districtPageNumber]);

  useEffect(() => {
    if (lastAddedId > 0 && !management.districtsLoading) {
      const element = document.getElementById("district-data");
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [management.districts, management.districtsLoading]);

  const doChangePage = (pageNo: number) => {
    let newOffset = pageNo * limit - limit;
    setCurrentPage(pageNo);
    setOffSet(newOffset);
  };

  const doSetDelModal = (item: any) => {
    setCurrentItem(item);
    setDeleteModal(true);
  };

  const doSetUpdateModal = (item: any) => {
    setCurrentItem(item);
    setUpdateModal(true);
  };

  const setPreviousPage = () => {
    if (currentPage > 1 && management.districts.length === 1) {
      doChangePage(currentPage - 1);
    } else {
      dispatch(setFlagForFetch("SET_DISTRICT_FLAG"));
    }
  };

  const onDeleteDistrict = () => {
    dispatch(deleteDistrict(currentItem.id, setDeleteModal, setPreviousPage));
  };

  const onUpdateDistrict = (data: any) => {
    dispatch(updateDistrict(currentItem.id, data, setUpdateModal));
  };

  const onAddDistrict = (data: any) => {
    dispatch(addDistrict(data, setAddModal));
    !open && setOpen(true);
  };

  const requestStl = async () => {
    dispatch({ type: ActionType.SET_STL_LOADING, payload: true });
    localStorage.setItem("stlLoading", "true");
    const request = await requestStlReport();
    localStorage.setItem("id", request.data.data.id);
    dispatch({ type: ActionType.SET_STL_ID, payload: request.data.data.id });
    //
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalBorder(true);
    }, 300);

    return () => {
      clearTimeout(timer);
      setModalBorder(false);
    };
  }, [open]);

  useEffect(() => {
    // const timer = setTimeout(() => {
    //   setSkeletonLoad(false);
    // }, 800);

    return () => {
      // clearTimeout(timer);
      // setSkeletonLoad(true);
    };
  }, [management.districtsLoading]);


  const onSelectReport = (id: number) => {
    navigate(`report/summative-objective-analysis/${id}`);
    onNavigate && onNavigate();
  };

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={(e: any) => {
          if (e.key === "Enter" && !(
              (e.target as Element).nodeName === "INPUT" ||
              (e.target as Element).nodeName === "BUTTON" ||
              (e.target as Element).nodeName === "A") ) {
                setOpen(!open);
            }
          }
        }
        onClick={(e) => {
          if (
            (e.target as Element).nodeName === "INPUT" ||
            (e.target as Element).nodeName === "BUTTON" ||
            (e.target as Element).nodeName === "A"
          ) {
            return;
          }
          setOpen(!open);
        }}
        className={`accordion__header super-admin-district ${
          open ? "open" : ""
        } ${modalBorder ? "closeDelay" : ""}`}
      >
        <div className="accordion__header-heading">
          <span className="accordion__header-button">
            <img src={ArrowIcon} alt="arrow" className={open ? "rotate" : ""} />
          </span>
          <h6>Districts</h6>
          {!open ? (
            <div className="accordion__header-search animated">
              
              <SearchIconSVG title="Search Districts" style = {{marginRight: "10px"}} />
              <input
                value={searchTerm}
                onChange={(e) => {
                  handleSearch(e.target.value);
                  setSearchTerm(e.target.value);
                }}
                type="text"
                className="form-control"
                placeholder="Search Districts"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        {auth && auth.currentUser?.role === "Super Admin" ? (
          <div>
            <div className="accordion__header-buttons district">
              <div className="accordion__header-btnContainer district">
                <button
                  type="button"
                  tabIndex={0}
                  className="btn outline-btn outline-btn-lg"
                  // onClick={downloadAuditReportFile}
                  onClick={() => {
                    navigate("report/audit");
                    onNavigate && onNavigate();
                  }}
                >
                  Audit
                </button>
              </div>
              <div className="accordion__header-btnContainer district">
                <button
                  type="button"
                  tabIndex={0}
                  className="btn outline-btn outline-btn-lg"
                  onClick={requestStl}
                >
                  Summative Test Log
                </button>
              </div>
              <div className="accordion__header-btnContainer">
                <button
                  type="button"
                  tabIndex={0}
                  onClick={() => setAddModal(true)}
                  className="btn outline-btn outline-btn-lg"
                >
                  Add District
                </button>
              </div>
              <div className="accordion__header-btnContainer import-csv-btn">
                <button
                  type="button"
                  tabIndex={0}
                  onClick={() => {
                    navigate("/management/import/district");
                    onNavigate && onNavigate();
                  }}
                  className="btn outline-btn outline-btn-lg"
                >
                  Import CSV
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <Collapse in={open}>
        <div>
          <div className="accordion__content">
            <div className="section-search">
              <div className="section-search-field animated">
                <div className="icon">
                <SearchIconSVG fill="#323233" id="searchDistricts" title="Search Districts"/>
                  {/* <img src={SearchIconGray} alt="search" /> */}
                </div>
                <input
                  ref={inputRef}
                  value={searchTerm}
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    setSearchTerm(e.target.value);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Search Districts"
                />
              </div>

              <div className="d-flex align-items-center show-classes-dropdown">
                <span>Show</span>
                <LimitSelect
                  disableHandler={
                    management.districts && management.districts.length < 10
                  }
                  onChangeHandler={(e: any) => setLimit(Number(e?.value))}
                />
                <span>Districts</span>
              </div>
            </div>

            <div className="district-table-container">
              <div className="table-responsive">
                <table className="district__table">
                  <thead>
                    <tr className="district__table-row">
                      <th
                        tabIndex={0}
                        className="district__table-name"
                        onClick={() => sortDataBy("name")}
                        onKeyDown={e => e.key === "Enter" && sortDataBy("name")}
                      >
                        District Name
                        {sortBy === "name" && (
                           <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th
                        tabIndex={0}
                        className="district__table-last"
                        onClick={() => sortDataBy("last_assessment")}
                        onKeyDown={e => e.key === "Enter" && sortDataBy("last_assessment")}
                      >
                        Last <br /> Assessment
                        {sortBy === "last_assessment" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th
                        tabIndex={0}
                        className="district__table-school"
                        onClick={() => sortDataBy("schools")}
                        onKeyDown={e => e.key === "Enter" && sortDataBy("schools")}
                      >
                        Schools
                        {sortBy === "schools" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th className="district__table-report">
                        Summative <br /> Obj. Analysis <br /> Report
                      </th>
                      {auth && auth.currentUser?.role === "Super Admin" && (
                        <th className="school__table-available-access">
                          Demo District
                        </th>
                      )}
                      <th className="district__table-options">Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {skeletonLoad ? (
                      <TableSkeleton columns={6} count={limit} height={36} />
                    ) : (
                      ""
                    )} */}

                    {management.districtsLoading ? (
                      <TableSkeleton columns={6} count={limit} height={36} />
                    ) : management.districts && management.districts.length ? (
                      management.districts?.map((item: any, i: number) => {
                        return (
                          <tr
                            className={
                              lastAddedId === item.id
                                ? "district__table-row highlight-row"
                                : i % 2 !== 0
                                ? "district__table-row"
                                : "district__table-row"
                            }
                            id={lastAddedId === item.id ? "district-data" : ""}
                          >
                            <td className="name-data">
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  dispatch(setDistrictLogo(item.logo_filename));
                                  onNavigate && onNavigate();
                                  navigate(`/management/district/${item.id}`, {
                                    state: { name: item.name },
                                  });
                                }}
                              >
                                {item?.name}
                              </span>
                            </td>
                            <td>
                              {item?.last_assessment
                                ? moment(item?.last_assessment).format("M-D-YY")
                                : "-"}
                            </td>
                            <td>{item?.schools}</td>
                            <td
                             role="button"
                             tabIndex={item.has_data ? 0 : -1}
                             onKeyDown={(e: any) => {
                               if (e.key === "Enter") {
                                item.has_data
                                ? onSelectReport(item.id)
                                : setReportDisableModal(true)
                               }
                             }}
                              className={`report-data ${
                                item.has_data
                                  ? "cursor-pointer"
                                  : "disabled-item"
                              }`}
                              onClick={() =>
                                item.has_data
                                  ? onSelectReport(item.id)
                                  : setReportDisableModal(true)
                              }
                            >
                              <ExcelReportIcon fill="#323233" />
                              
                            </td>
                            {auth &&
                              auth.currentUser?.role === "Super Admin" && (
                                <td className="vertical-center text-center">
                                  <Toggle 
                                    inputName="Demo District"
                                    ariaLabel="Demo District"
                                    isSmall
                                    labelClassName="mb-0"
                                    tabIndex = {0}
                                    role = "button"
                                    checked={
                                      item?.demo_district ? true : false
                                    }
                                    onChange={(e) => {
                                      dispatch(
                                        updateDemoDistrictToggle(
                                          "",
                                          e.target.checked,
                                          item.id
                                        )
                                      );
                                    }}
                                  />
                                </td>
                              )}
                            <td className="options-data">
                              <div className="d-flex justify-content-start align-items-center">
                                <button  
                                  tabIndex={0}
                                  onClick={() => doSetUpdateModal(item)}
                                  onKeyDown={(e: any) => {
                                    if (e.key === "Enter") {
                                      doSetUpdateModal(item)
                                    }
                                  }}
                                  className="management__table-editBtn"
                                  style={{border:"none", background:"none"}}
                                  >
                                    <PencilIconManagement fill="#323233" />
                                </button>
                                <button 
                               tabIndex={0}
                               onKeyDown={(e: any) => {
                                 if (e.key === "Enter") {
									                  doSetDelModal(item)
                                    }
                                  }} 
                                onClick={()=>doSetDelModal(item)} 
                                className="management__table-editBtn" 
                                style={{border:"none", background:"none"}}>
								                <TrashIcon fill="#323233" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        {management?.districts?.length === 0 &&
                          !management.districtsLoading && (
                            <td colSpan={6} className="no-admin">
                              No Districts
                            </td>
                          )}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Pagination
            totalCount={management.districtsCount}
            currentPage={currentPage}
            pageSize={limit}
            onPageChange={doChangePage}
          />
        </div>
      </Collapse>
      <DistrictDeleteModal
        loading={management.districtDeleteLoading}
        isShow={deleteModal}
        headerText={`Delete ${currentItem && currentItem?.name}`}
        bodyText={`Are you sure you want to delete this district?`}
        onCancel={() => setDeleteModal(false)}
        onDelete={onDeleteDistrict}
      />
      {updateModal && (
        <DistrictUpdateModal
          isShow={updateModal}
          district_name={currentItem?.name}
          // file={""}
          id={currentItem.id}
          onCancel={() => setUpdateModal(false)}
          onUpdate={(data) => onUpdateDistrict(data)}
          loading={management.districtUpdateLoading}
        />
      )}
      {addModal && (
        <DistrictAddModal
          isShow={addModal}
          loading={management.districtAddLoading}
          onAdd={(data) => onAddDistrict(data)}
          onCancel={() => setAddModal(false)}
        />
      )}
      {reportDisableModal && (
        <PermissionHintModal
          isShow={reportDisableModal}
          onClose={() => setReportDisableModal(false)}
          onYes={() => setReportDisableModal(false)}
          message={`There is currently no assessment data for this report. Please try again once assessments have been submitted.`}
          customHeader="SOA Report Disabled"
        />
      )}
    </>
  );
};

export default Districts;
