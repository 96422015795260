import React, { useContext, useState, useEffect } from 'react';
import { ReactComponent as ShowIcon } from "assets/img/hide-icon-1.svg";
import { ReactComponent as HideIcon } from "assets/img/hide-icon-2.svg";
import { QuestionnaireContext } from "../Assessment/QuestionnaireContext";
import HtmlParser from 'components/common/htmlParser';

interface IChoice {
    choiceText: string;
    choice: string;
    option: number;
    answer: number;
}

const Choice: React.FC<IChoice> = ({ choice, choiceText, option, answer }) => {

    const {
        onSpeak,
        isSpeechIconClicked,
        isPreview,
        currentQuestion,
        keyToSpeak
    } = useContext(QuestionnaireContext);

    const [hide, setHide] = useState(false);

    useEffect(()=> {
        setHide(false)
    }, [currentQuestion])


    return (
        <>
            <div className="questions__question-icons">
                <button className="questions__question-showHide">
                    {
                        hide ?
                            <ShowIcon
                                onClick={() => setHide(prevState => !prevState)}
                            />
                            :
                            <HideIcon
                                onClick={() => setHide(prevState => !prevState)}
                            />
                    }
                </button>
                <span
                    className={`questions__question-suggest ${isPreview && answer === option ? "fill" : ""
                        }`}
                >
                    {choice}
                </span>
            </div>
            {!hide && <p className={`${isSpeechIconClicked && 'ondemandtts'} questions__question-choice ${keyToSpeak === `choice_${option+1}` && 'playtts' }` }
                onClick={() => onSpeak(`choice_${option + 1}`)}
            >
                <HtmlParser html={choiceText} id={`choice_${option+1}`} />
            </p>}
        </>
    );
}

export default Choice;