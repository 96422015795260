import { SupportTicketContextProvider } from "interfaces/supportTickets"

export enum SupportTicketActions {
    SET_SUPPORT_TICKETS = 'SET_SUPPORT_TICKETS',
    UNSET_SUPPORT_TICKETS = 'UNSET_SUPPORT_TICKETS',
    SET_SINGLE_SUPPORT_TICKET = 'SET_SINGLE_SUPPORT_TICKET',
    SET_VIDEOS = 'SET_VIDEOS',
    SET_SINGLE_MESSAGE = 'SET_SINGLE_MESSAGE',
    SET_SUPPORT_TICKETS_LOADING = 'SET_SUPPORT_TICKETS_LOADING',
    SET_SUPPORT_TICKET_MESSAGES = 'SET_SUPPORT_TICKET_MESSAGES',
    SET_SUPPORT_TICKET_MESSAGES_LOADING = 'SET_SUPPORT_TICKET_MESSAGES_LOADING',
    SET_MESSAGE_SEEN = 'SET_MESSAGE_SEEN',
    UNSET_ADMINS = 'UNSET_ADMINS',
    SET_ADMINS = 'SET_ADMINS'
}


export const SupportTicketReducer = (state: SupportTicketContextProvider, action: any) => {
    const { type, payload } = action
    switch (type) {
        case SupportTicketActions.SET_ADMINS:
            return {
                ...state,
                admins: payload
            }
        case SupportTicketActions.UNSET_ADMINS:
            return {
                ...state,
                admins: []
            }
        case SupportTicketActions.SET_SUPPORT_TICKETS:
            return {
                ...state,
                supportTickets: {
                    loading: false,
                    ...payload
                }
            }
        case SupportTicketActions.SET_SUPPORT_TICKETS_LOADING:
            return {
                ...state,
                supportTickets: {
                    ...state.supportTickets,
                    loading: payload,

                }
            }
        case SupportTicketActions.UNSET_SUPPORT_TICKETS:
            return {
                ...state,
                supportTickets: null!
            }
        case SupportTicketActions.SET_SINGLE_SUPPORT_TICKET:
            return {
                ...state,
                singleSupportTicket: payload
            }
        case SupportTicketActions.SET_SINGLE_MESSAGE:
            return {
                ...state,
                singleMessage: payload
            }
        case SupportTicketActions.SET_SUPPORT_TICKET_MESSAGES:
            const { offset } = payload;
            return {
                ...state,
                supportTicketMessages: {
                    ...state.supportTicketMessages,
                    count: payload.count,
                    messages: offset === 0 ? payload.messages : [...state.supportTicketMessages.messages, ...payload.messages],
                    loading: false
                }
            }
        case SupportTicketActions.SET_SUPPORT_TICKET_MESSAGES_LOADING:
            return {
                ...state,
                supportTicketMessages: {
                    ...state.supportTicketMessages,
                    loading: payload
                }
            }
        case SupportTicketActions.SET_MESSAGE_SEEN:
            const updatedMessages = state.supportTicketMessages.messages.map((item: any) => {
                return item.id === payload.id
                ? {
                    ...item,
                    seen: true
                }
                : item;
            });
            const isUnreadMessage = updatedMessages.some(message => !message.seen);
            return {
                ...state,
                supportTicketMessages: {
                    ...state.supportTicketMessages,
                    messages: updatedMessages
                },
                ...(isUnreadMessage && 
                {supportTickets: {
                    ...state.supportTickets,
                    tickets: state.supportTickets.tickets.map((item: any) => {
                        return item.id === payload.ticketId
                        ? {
                            ...item,
                            unread_messages: false
                        }
                        : item
                    })
                }})
            }
        default:
            return state
    }
}
