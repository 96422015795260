import React, {Fragment} from "react";
const Tabs: React.FC<{ onClickDisabledTab?: (item: any) => void, disabledTabs?: string[], minWidth?: number, options: any; onChangeTab: any; currentTab: any,customClass?: string, selectedOption?: string, isEdit?: any }> = ({
  options,
  onChangeTab,
  currentTab,
  customClass,
  selectedOption,
  isEdit,
  minWidth,
  disabledTabs=[],
  onClickDisabledTab
}) => {
  return (
    <div className="tabs-switch">
      {options.map((item: any, i:number) => {
        const isDisabled = disabledTabs.includes(item);

        let labelProps: any = {
          onKeyDown: (e:any) => e.key === "Enter" && onChangeTab(item),
          onClick:  () => onChangeTab(item),
          htmlFor: `radio-${i + 1}`
        };

        if (isEdit || isDisabled) {
          labelProps.onKeyDown = (e:any) => e.key === "Enter" && onClickDisabledTab && onClickDisabledTab(item);
          labelProps.onClick = () => onClickDisabledTab && onClickDisabledTab(item);
          labelProps.htmlFor= '';
        }

        return (
          <Fragment key={i}>
            <input 
              type="radio" 
              className={"tabs-switch-radio "  + (customClass && i === 0 ? customClass:'')}
              id={`radio-${i + 1}`} 
              name="tabs" 
              disabled={isEdit || isDisabled}
              checked={currentTab === item ? true : false}
              onChange={() => {}}
            />
            
            <label 
              tabIndex={0}
              
              className={`tabs-switch-tab ${(isEdit && currentTab !== item) || isDisabled ? 'tab-disable' : ''}`}
              id={`radio-label-${i+1}`}
              {...labelProps}
            >
              {item}
            </label>
          </Fragment>
        );
      })}
      <span className="tabs-switch-slider" style={{minWidth: minWidth || '73px'}}></span>
    </div>
  );
};

export default Tabs;