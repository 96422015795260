/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { Modal } from "react-bootstrap";

interface PermissionHintModalProps {
  isShow: boolean;
  onClose: () => void;
  message: string;
  onYes: () => void;
  customHeader?: string;
}

const PermissionHintModal: React.FC<PermissionHintModalProps> = ({
  isShow,
  onClose,
  onYes,
  message,
  customHeader
}) => {
  
  return (
    <Modal centered show={isShow} onEscapeKeyDown={onClose}>
      <div className="modal__header">
        <h6 className="modal__header-title">
          { customHeader ?? 'Permission'}
        </h6>
      </div>
      <div className="modal__body">
        <p className="modal__body-text">
         {message}
        </p>
      </div>
      <div className="modal__footer">
        <button
          className="btn success-btn btn-lg"
          onClick={onYes} 
        >
          Got It
        </button>
      </div>
    </Modal>
  );
};

export default PermissionHintModal;