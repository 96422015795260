
const TableHeader = () => {
    return <tr className="auditReport__table__row">
            <th className={`auditReport__table__row__directName`}>District Name</th>
            <th className={`auditReport__table__row__schoolName `}>School Name</th>
            <th className={`auditReport__table__row__warning`}></th>
            <th className={`auditReport__table__row__enrolledStudent`}>Students Enrolled</th>
            <th className={`auditReport__table__row__testedStudent`}>Students <br/> Tested</th>
            <th className={`auditReport__table__row__Licensed`}>Licensed</th>
        </tr>
}
export default TableHeader