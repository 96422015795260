/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef, useCallback } from "react";
import cryptoRandomString from "crypto-random-string";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import { LimitSelect } from "components/common/selectComponent";
import RPIcon from "assets/img/cross-icon.svg";
import EditIcon from "assets/img/pencil-icon.svg";
import DeleteIcon from "assets/img/trash-icon.svg";
import Pagination from "../pagination/pagination";
import { ClassAdminAddModal, ResetPasswordModal } from "./management-add-modal";
import { ClassAdminDeleteModal } from "../management/management-delete-modal";
import {
  ClassAdminUpdateModal,
  CLassUpdateModal,
} from "../management/management-update-modal";
import {
  getClassAdmins,
  updateClassAdminIsPrincipal,
  deleteClassAdmin,
  addClassAdmin,
  resetPasswordAdmin,
  updateClassAdminDetail,
  updateClass,
  setFlagForFetch,
  getClassBreadcrumb,
} from "../../redux/actionCreators/management";
import SearchIcon from "assets/img/search-icon.svg";
import { getClassroom } from "redux/actionCreators/classroom";
import ArrowDownImg from "assets/img/table-class-before.svg";
import PermissionHintModal from "./modals/permissionHintModal";
import ActionType from "redux/actionTypes";
import cookie from "js-cookie";
import BackButton from "components/common/backButton";
import useSortBy from "hooks/useSortBy";
import SortIndicatorIcon from "components/common/svgs/sort-indicator-icon";
import Toggle from "components/common/Toggle";
import { AppCookies } from "util/constants";

interface RootState {
  management: any;
  auth: any;
  activity: any;
  classroom: any;
}

const ClassDetails: React.FC<{
  classData: any;
  setClassData: Function;
  setLoading: Function;
  classId: string;
  classroomName: string;
}> = ({ classId, setLoading, setClassData, classData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { management, auth, classroom } = useSelector(
    (state: RootState) => state
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const [offSet, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<null | any>(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [updateClassModal, setUpdateClassModal] = useState(false);
  const [resetPassModdal, setResetPassModdal] = useState(false);
  const [randomPass, setRandomPass] = useState("");
  // const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [teacher, setTeacher] = useState<any>(null);
  const [lastAddedId, setLastAddedId] = useState(0);

  const { sortBy, OrderByColumn, sortDataBy } = useSortBy("last_name", "ASC");

  const [openHintModal, setOpenHintModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const userRole = cookie.get(AppCookies.role)

  const [isInputFocused, setIsInputFocused] = useState<any>("");
  useEffect(() => {
    let params = {
      offSet,
      limit,
      searchTerm,
      classId,
      sortBy,
      OrderByColumn,
    };
    dispatch(getClassAdmins(params));
    setTimeout(() => {
      getClass();
    }, 200);
  }, [
    offSet,
    limit,
    management.classAdminsFlag,
    dispatch,
    sortBy,
    OrderByColumn,
    classId,
  ]);

  const handleSearch = useCallback(
    _.debounce((val: any) => {
      console.log(val);
      setCurrentPage(1);
      setOffSet(0);
      let params = {
        offSet: 0,
        limit,
        searchTerm: val,
        classId,
        sortBy,
        OrderByColumn,
      };
      dispatch(getClassAdmins(params));
      if (inputRef.current !== null) {
        inputRef.current.focus();
      }
    }, 1000),
    []
  );

  useEffect(() => {
    /* Fetch page number for last added record*/
    if (management.lastAddedClassAdminId > 0) {
      setLastAddedId(management.lastAddedClassAdminId);
      setSearchTerm("");
      let params = {
        offSet: 0,
        limit: 10,
        searchTerm: "",
        classId,
        sortBy,
        OrderByColumn,
        new_admin_id: management.lastAddedClassAdminId,
      };
      dispatch(getClassAdmins(params));
    }
    /* Set page and fetch record of that page */
    if (management.classAdminPageNumber > 0) {
      setLimit(10);
      setCurrentPage(management.classAdminPageNumber);
      setOffSet(management.classAdminPageNumber * 10 - 10);
      management.classAdminPageNumber === currentPage &&
        dispatch(setFlagForFetch("SET_CLASS_ADMIN_FLAG"));
      setTimeout(() => {
        setLastAddedId(0);
      }, 2000);
    }
  }, [management.lastAddedClassAdminId, management.classAdminPageNumber]);

  useEffect(() => {
    if (lastAddedId > 0 && !management.classAdminsLoading) {
      const element = document.getElementById("class-admin-data");
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [management.classAdmins, management.classAdminsLoading]);

  const doChangePage = (pageNo: number) => {
    let newOffset = pageNo * limit - limit;
    setCurrentPage(pageNo);
    setOffSet(newOffset);
  };

  const doSetDelModal = (item: any) => {
    console.log(item, "ITEM.................");
    setCurrentItem(item);
    setDeleteModal(true);
  };

  const doSetUpdateModal = (item: any) => {
    console.log(item, ".................");
    setCurrentItem(item);
    setUpdateModal(true);
  };

  const doSetUpdateClassModal = () => {
    // setCurrentItem(item);
    if (management.classAdmins && management.classAdmins.length) {
      management.classAdmins.map((admin: any) => {
        if (admin.is_teacher) {
          setTeacher(admin.id);
        }
        return true;
      });
    }

    setUpdateClassModal(true);
  };

  const setPreviousPage = () => {
    if (currentPage > 1 && management.classAdmins.length === 1) {
      doChangePage(currentPage - 1);
    } else {
      dispatch(setFlagForFetch("SET_CLASS_ADMIN_FLAG"));
    }
  };

  const onDeleteClassAdmin = () => {
    dispatch(
      deleteClassAdmin(
        currentItem?.classes_list[0]?.id,
        currentItem?.id,
        setDeleteModal,
        setPreviousPage,
        false
      )
    );
  };

  const onUpdateCLassAdmin = (data: any) => {
    dispatch(updateClassAdminDetail(currentItem?.id, data, setUpdateModal));
  };

  const onAddClassAdmin = (data: any) => {
    let finalData = { ...data, classroom_id: Number(classId) };
    dispatch(addClassAdmin(finalData, setAddModal));
  };

  const doSetPasModal = (item: any) => {
    setCurrentItem(item);
    let newPass = cryptoRandomString({ length: 10, type: "url-safe" });
    setRandomPass(newPass);
    setResetPassModdal(true);
  };
  const onCreatePassword = () => {
    let data = {
      admin_id: currentItem?.id,
      password: randomPass,
    };
    console.log(currentItem);
    dispatch(resetPasswordAdmin(data, setResetPassModdal));
    // setCurrentItem(null)
  };

  useEffect(() => {
    // const timer = setTimeout(() => {
    //   setSkeletonLoad(false);
    // }, 800);

    return () => {
      // clearTimeout(timer);
      // setSkeletonLoad(true);
    };
  }, [management.classAdminsLoading]);

  const getClass = async () => {
    const classroomData = await getClassroom(+classId);
    setLoading(false);
    setClassData(classroomData?.data.data);
  };

  useEffect(() => {
    getClass();
  }, [classId]);

  const onUpdateClass = (data: any) => {
    dispatch(
      updateClass(+classId, data, () => {
        getClass();
        let params = {
          offSet: 0,
          limit: 10,
          searchTerm: "",
          classId,
          sortBy,
          OrderByColumn,
          new_admin_id: management.lastAddedClassAdminId,
        };
        dispatch(getClassBreadcrumb(Number(classId)));
        dispatch(getClassAdmins(params));
      })
    );
    setUpdateClassModal(false);
  };

  const requestStl = () => {
    dispatch({
      type: ActionType.SET_STL_CLASS_LOADING,
      payload: true,
    });
  };

  const classInfo = classroom.classrooms?.find(
    (item: any) => item.id === Number(classId)
  );
  const isOnlyResourcesClass =
    classInfo?.students_count === 0 || classInfo?.fk_grade_id === 9;

  const goToClassroomClicked = () => {
    sessionStorage.setItem("c_district_id", classData.district_id || 0);
    sessionStorage.setItem("c_school_id", classData.school_id || 0);
    sessionStorage.setItem("c_classroom_id", classData.id || 0);
    sessionStorage.setItem("c_subject_id", classData.fk_subject_id || 0);
    sessionStorage.setItem("c_grade_id", classData.grade || 0);

    navigate("/classroom");
  };

  const onClickBack = () => {
    window?.history?.length === 1 ? navigate("/management") : navigate(-1);
  };

  return (
    <>
      {/* {management.stlClassLoading && userRole === 'Classroom Admin' && <StlClassButton />} */}
      <div className="accordion__header class-detail-header open cursor-default">
        <div className="accordion__header-heading">
          {auth.currentUser?.role !== "Classroom Admin" && (
            <BackButton
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  onClickBack();
                }
              }}
              onClick={onClickBack}
            />
          )}
          <h6>Class: {classData?.classroom_name}</h6>
          {auth.currentUser?.role !== "Classroom Admin" && (
            <img
              tabIndex={0}
              role="button"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  doSetUpdateClassModal();
                }
              }}
              onClick={() => doSetUpdateClassModal()}
              src={EditIcon}
              alt="Edit"
              className="management__table-editBtn"
            />
          )}
        </div>
        {
          <div className="class-detail-btns">
            <div className="accordion__header-buttons">
              {/* {userRole === 'Classroom Admin' && classroom.classrooms.length === 1 && !isOnlyResourcesClass &&
              <div className="accordion__header-btnContainer">
                <button className="btn outline-btn outline-btn-lg" onClick={requestStl}>
                  Summative Test Log
                </button>
              </div>
            } */}
              {["Super Admin", "District Admin", "School Admin"].includes(
                auth.currentUser?.role
              ) && (
                <div className="accordion__header-btnContainer">
                  <button
                    className={`btn outline-btn outline-btn-lg`}
                    onClick={goToClassroomClicked}
                  >
                    To Classroom
                  </button>
                </div>
              )}
              <div className="accordion__header-btnContainer">
                <button
                  onClick={() => {
                    setAddModal(true);
                  }}
                  className={`btn outline-btn outline-btn-lg`}
                >
                  Add Admin
                </button>
              </div>
            </div>
          </div>
        }
      </div>
      <div className="accordion__subheader">
        <p>Class Admins</p>
      </div>
      <div>
        <div className=" border-radious-bottom">
          <div className="section-search class-admin-search">
            <div className="section-search-field animated">
              <div className="icon">
                <img src={SearchIcon} alt="search" />
              </div>
              <input
                ref={inputRef}
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  handleSearch(e.target.value);
                }}
                type="text"
                className="form-control"
                placeholder="Search Admins"
              />
            </div>
            <div className="d-flex align-items-center">
              <span>Show</span>
              <LimitSelect
                disableHandler={management?.classAdmins?.length < 10}
                onChangeHandler={(e: any) => setLimit(Number(e?.value))}
              />
              <span>Admins</span>
            </div>
          </div>
          <div className="management-table-container">
            <div className="table-responsive">
              <table className="admins__table">
                <thead>
                  <tr className="management__table-row">
                    <th
                      tabIndex={0}
                      className="admins__table-name"
                      onClick={() => sortDataBy("last_name")}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          sortDataBy("last_name");
                        }
                      }}
                    >
                      Admin Name
                      {sortBy === "last_name" && (
                        <SortIndicatorIcon
                          OrderByColumn={OrderByColumn}
                          stroke="white"
                        />
                      )}
                    </th>
                    <th
                      tabIndex={0}
                      className="admins__table-email"
                      onClick={() => sortDataBy("email")}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          sortDataBy("email");
                        }
                      }}
                    >
                      Email
                      {sortBy === "email" && (
                        <SortIndicatorIcon
                          OrderByColumn={OrderByColumn}
                          stroke="white"
                        />
                      )}
                    </th>
                    <th
                      tabIndex={0}
                      className="admins__table-title"
                      onClick={() => sortDataBy("title")}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          sortDataBy("title");
                        }
                      }}
                    >
                      Title
                      {sortBy === "title" && (
                        <SortIndicatorIcon
                          OrderByColumn={OrderByColumn}
                          stroke="white"
                        />
                      )}
                    </th>
                    <th
                      tabIndex={0}
                      className="admins__table-login"
                      onClick={() => sortDataBy("last_login")}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          sortDataBy("last_login");
                        }
                      }}
                    >
                      Last
                      <br />
                      Login
                      {sortBy === "last_login" && (
                        <SortIndicatorIcon
                          OrderByColumn={OrderByColumn}
                          stroke="white"
                        />
                      )}
                    </th>
                    <th className="admins__table-admin">
                      Admin <br /> is Teacher
                    </th>
                    {auth && auth.currentUser?.role === "Super Admin" && (
                      <th className="admins__table-login">PIN</th>
                    )}
                    <th className="admins__table-options">Options</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {skeletonLoad ? (
                    <TableSkeleton columns={6} count={4} height={36} />
                  ) : (
                    ""
                  )} */}

                  {management.classAdminsLoading ? (
                    <TableSkeleton
                      baseColor="#2a539143"
                      highlightColor="#5371b732"
                      columns={7}
                      count={4}
                      height={37}
                    />
                  ) : management?.classAdmins?.length ? (
                    management.classAdmins.map((item: any, i: number) => {
                      return (
                        <tr
                          key={i}
                          className={
                            lastAddedId === item.id
                              ? "admins__table-row management__table-row highlight-row "
                              : i % 2 !== 0
                              ? "admins__table-row management__table-row"
                              : "admins__table-row management__table-row dark-row-blue"
                          }
                          id={lastAddedId === item.id ? "class-admin-data" : ""}
                        >
                          <td className="name-data">
                            {item?.last_name}, {item?.first_name}
                          </td>
                          <td className="email-data">{item?.email}</td>
                          <td className="title-data">{item?.title}</td>
                          <td className="text-center">
                            {item?.last_login
                              ? moment(item?.last_login).format("M-D-YY")
                              : "-"}
                          </td>
                          <td className="text-center">
                            <Toggle
                              inputName="Is Teacher"
                              ariaLabel="Is Teacher"
                              checked={item?.is_teacher ? true : false}
                              isSmall={true}
                              disabled={
                                management.classAdmins.length === 1 &&
                                item?.is_teacher
                              }
                              activeDisable={userRole === "Classroom Admin"}
                              labelClassName="mb-0"
                              onChange={(e) => {
                                if (
                                  auth.currentUser?.role === "Classroom Admin"
                                ) {
                                  setModalType("Change Classroom Teacher");
                                  setOpenHintModal(true);
                                } else {
                                  !(
                                    management.classAdmins.length === 1 &&
                                    item?.is_teacher
                                  ) &&
                                    dispatch(
                                      updateClassAdminIsPrincipal(
                                        item.id,
                                        Number(classId),
                                        e.target.checked
                                      )
                                    );
                                }
                              }}
                            />
                          </td>
                          {auth && auth.currentUser?.role === "Super Admin" && (
                            <td className="text-center">
                              {item?.support_pin || ""}
                            </td>
                          )}
                          <td className="options-data">
                            <div className="d-flex justify-content-start align-items-center">
                              <img
                                tabIndex={0}
                                role="button"
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    doSetUpdateModal(item);
                                  }
                                }}
                                onClick={() => doSetUpdateModal(item)}
                                src={EditIcon}
                                alt="Edit"
                                className="management__table-editBtn"
                              />
                              {auth &&
                              auth.currentUser?.role === "Super Admin" ? (
                                <img
                                  tabIndex={0}
                                  role="button"
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      doSetPasModal(item);
                                    }
                                  }}
                                  onClick={() => doSetPasModal(item)}
                                  src={RPIcon}
                                  alt="Password"
                                  className="management__table-editBtn"
                                />
                              ) : (
                                ""
                              )}
                              <img
                                tabIndex={0}
                                role="button"
                                onClick={() => {
                                  if (
                                    auth.currentUser?.role ===
                                      "Classroom Admin" &&
                                    item?.is_teacher
                                  ) {
                                    setModalType("Remove Teacher");
                                    setOpenHintModal(true);
                                  } else {
                                    doSetDelModal(item);
                                  }
                                }}
                                src={DeleteIcon}
                                alt="Delete"
                                className={`management__table-editBtn ${
                                  auth.currentUser?.role ===
                                    "Classroom Admin" && item?.is_teacher
                                    ? "disabled-item"
                                    : ""
                                }`}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      {!management?.classAdmins?.length &&
                        !management.classAdminsLoading && (
                          <td colSpan={7} className="no-admin dark-table">
                            No Admins
                          </td>
                        )}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Pagination
          totalCount={management.classAdminsCount}
          currentPage={currentPage}
          pageSize={limit}
          onPageChange={doChangePage}
        />
      </div>
      <ClassAdminDeleteModal
        loading={management.classAdminsDeleteLoading}
        isShow={deleteModal}
        headerText={`Remove Admin: ${currentItem?.first_name} ${currentItem?.last_name}`}
        bodyText={`This admin will be removed from the class but will remain a class admin in the school. To delete them entirely from APO, delete them from the school. ${
          classData?.sharing_schedule
            ? "Pacing Calendar sharing for this grade and subject is turned on across classes for this admin. Removing the admin from this class will disable the shared calendar for this class."
            : ""
        }`}
        onCancel={() => setDeleteModal(false)}
        onDelete={onDeleteClassAdmin}
      />
      {updateClassModal && (
        <CLassUpdateModal
          isShow={updateClassModal}
          schoolId={classData.school_id}
          classRoom={classData}
          onCancel={() => setUpdateClassModal(false)}
          onUpdate={(data) => onUpdateClass(data)}
          loading={management.classUpdateLoading}
          teacherId={teacher}
          classId={classId}
        />
      )}
      {updateModal && (
        <ClassAdminUpdateModal
          isShow={updateModal}
          first_name={currentItem?.first_name}
          last_name={currentItem?.last_name}
          input_email={currentItem?.email}
          input_title={currentItem?.title || ""}
          onCancel={() => setUpdateModal(false)}
          onUpdate={(data) => onUpdateCLassAdmin(data)}
          loading={management.classAdminsUpdateLoading}
        />
      )}
      {addModal && (
        <ClassAdminAddModal
          classroom_id={Number(classId)}
          isShow={addModal}
          loading={management.classAdminsAddLoading}
          onCancel={() => setAddModal(false)}
          onAdd={(data: any) => onAddClassAdmin(data)}
        />
      )}
      {resetPassModdal && (
        <ResetPasswordModal
          isShow={resetPassModdal}
          loading={management?.resetPwLoading}
          onCancel={() => setResetPassModdal(false)}
          onAdd={onCreatePassword}
          password={randomPass}
          title={`${currentItem?.first_name} ${currentItem?.last_name}`}
        />
      )}
      {openHintModal && (
        <PermissionHintModal
          isShow={openHintModal}
          onClose={() => setOpenHintModal(false)}
          onYes={() => setOpenHintModal(false)}
          message={
            modalType === "Remove Teacher"
              ? "Please contact the school administrator to remove the teacher from this classroom."
              : "Please contact your school administrator to change the teacher of this classroom."
          }
          customHeader={modalType}
        />
      )}
    </>
  );
};

export default ClassDetails;
