const InfoSection = (level?: any) => {
  const performanceLevels = level?.level || [];
  return (
    <div className="SummativeStudentsReport__info-score">
      <h6 className="SummativeStudentsReport__info-title">
        Performance Level (Percentage Score Range*)
      </h6>
      <div className="SummativeStudentsReport__info-row">
        <div className="d-flex">
          <div className="SummativeStudentsReport__info-item advance"></div>
          <p className="text-dark mb-0">Advanced</p>
        </div>
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: `( ${performanceLevels[0]?.string} )`,
            }}
          ></span>
        </div>
      </div>
      <div className="SummativeStudentsReport__info-row">
        <div className="d-flex">
          <div className="SummativeStudentsReport__info-item proficient"></div>
          <p className="text-dark mb-0">Proficient</p>
        </div>
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: `( ${performanceLevels[1]?.string} )`,
            }}
          ></span>
        </div>
      </div>
      <div className="SummativeStudentsReport__info-row">
        <div className="d-flex">
          <div className="SummativeStudentsReport__info-item basic"></div>
          <p className="text-dark mb-0">Basic</p>
        </div>
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: `( ${performanceLevels[2]?.string} )`,
            }}
          ></span>
        </div>
      </div>
      <div className="SummativeStudentsReport__info-row">
        <div className="d-flex">
          <div className="SummativeStudentsReport__info-item belowBasic"></div>
          <p className="text-dark mb-0">Below Basic</p>
        </div>
        <div>
          <span
            dangerouslySetInnerHTML={{
              __html: `( ${performanceLevels[3]?.string} )`,
            }}
          ></span>
        </div>
      </div>
      <div className="SummativeStudentsReport__info-subtitle mt-2">
        <p className="text-dark mb-0">
          *Percentage of questions answered correctly
        </p>
      </div>
    </div>
  );
};

export default InfoSection;
