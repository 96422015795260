import { FC } from "react";
import { formatName, getPerformanceLevels } from "util/index";
import ScoreBox from "./ScoreBox";

interface Props {
  student: any;
  index: number;
  showScore: boolean;
  showObjectiveColumn: string;
  objectives: any;
  setIsolatedStudent: Function;
  level: any
}
const evaluatePerfomanceLevel = (percentage: number, level: any) => {
  const {advanceLevel, proficientLevel, basicLevel} = getPerformanceLevels(level);
  if (percentage >= advanceLevel) return "bg-194792";
  else if (percentage >= proficientLevel && percentage <= (advanceLevel-1)) return "bg-3b792f";
  else if (percentage >= basicLevel && percentage <= (proficientLevel-1)) return "bg-eadb4a";
  else return "bg-c44538";
};

const Student: FC<Props> = (props) => {
  const {
    student,
    index,
    showScore,
    showObjectiveColumn,
    objectives,
    setIsolatedStudent,
    level
  } = props;
  // const {objectives} = student

  const isHideRow = student.isShowIsolated === false; // dont use not sign (!) with student.isShowIsolated

  return (
    <tr
      className={`summative-row ${index % 2 !== 0 ? "dark-gray" : ""} ${
        index === 0 ? "first-row" : ""
      }`}
    >
      <td
        onClick={() => setIsolatedStudent(student)}
        className={`report__table-name summative-table-name report__data-height ${
          index % 2 !== 0 ? "dark-name" : ""
        }`}
      >
        <span className="ellipsis-animated">
          <p
            className={`cursor-pointer ellipsis-animated-text ${
              student.student_name.length > 16
                ? "animated ellipsis-animation"
                : ""
            } ${
              student.isShowIsolated
                ? "font-bold"
                : isHideRow
                ? "objective-isolation"
                : ""
            }`}
          >
            {formatName(student.student_name)}
          </p>
        </span>
      </td>

      {objectives?.map((objective: any, index: number) => {
        const objectiveInfo = student.objectives?.find(
          (studentObjective: any) =>
            studentObjective?.objective_number === objective.objective_number
        );
        const hideObjective =
          (showObjectiveColumn &&
            showObjectiveColumn !== objective.objective_number) ||
          isHideRow;
        return (
          <ScoreBox
            showScore={showScore}
            showObjectiveColumn={showObjectiveColumn}
            objectiveInfo={objectiveInfo}
            hideObjective={hideObjective}
            level={level}
          />
        );
      })}
      <td className="report__table-empty report__data-height"></td>
      {student?.tested ? (
        <>
          <td className="report__table-benchmark report__data-height">
            <div className="d-flex align-items-center justify-content-end">
              <span
                className={`report__table-questions ${
                  isHideRow ? "hidden" : ""
                }`}
              >
                {`(${student?.benchmark_given_formatives}/${student?.benchmark_total_formatives})`}
              </span>
              <span
                className={`${
                  isHideRow ? "hidden" : ""
                } ${evaluatePerfomanceLevel(
                  student?.benchmark_formative_percentage,
                  level
                )} report__table-numbers rounded-circle ${
                  student.benchmark_formative_percentage > 99 ? "fw-12" : ""
                } ${
                  student.benchmark_formative_percentage > 999 ? "fw-10" : ""
                }`}
              >
                {student.benchmark_formative_percentage}
              </span>
            </div>
          </td>
          <td className="report__table-summative report__data-height">
            <div className="d-flex align-items-center justify-content-end">
              <span
                className={`report__table-questions ${
                  isHideRow ? "hidden" : ""
                }`}
              >
                {`(${student.correct_summatives}/${student.total_summatives})`}
              </span>
              <span
                className={`${
                  isHideRow ? "hidden" : ""
                } ${evaluatePerfomanceLevel(
                  student?.percentage,
                  level
                )} report__table-numbers`}
              >
                {student.percentage}
              </span>
            </div>
          </td>
        </>
      ) : (
        <>
          <td className="report__table-benchmark report__data-height">
            <div className="d-flex align-items-center justify-content-end">
              <span className="report__table-questions report__table-numbers report__table-questions-not-tested-correct">{`-`}</span>
              <span className="report__table-numbers report__table-questions-not-tested-correct-bench">{`-`}</span>
            </div>
          </td>
          <td className="report__table-summative report__data-height">
            <div className="d-flex align-items-center justify-content-end">
              <span className="report__table-questions report__table-numbers report__table-questions-not-tested-benchmark">{`-`}</span>
              <span
                className={`report__table-numbers report__table-questions-not-tested-correct-bench`}
              >{`-`}</span>
            </div>
          </td>
        </>
      )}
    </tr>
  );
};

export default Student;
