import AssessmentMonitorCore from "./assessment-monitor";
import Reports from "./reports";

const AssessmentMonitor = () => {
    return (
        <div className="assessment-monitor-sec">
            <h2 className="secHeading">Assessment Monitor</h2>
            <div className="assessment-monitor-header">
                <AssessmentMonitorCore />
                <Reports />
            </div>
        </div>
    )
};

export default AssessmentMonitor;
