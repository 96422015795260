import PacingCalHeader from "./pacingCalenderHeader";
import AssesssmentsTable from "./assessmentTable";
import { useContext, useEffect, useState } from "react";
import { ClassroomTooltipContext } from "contexts/classroomTooltip";


const PacingCalenders = () => {

  const { tooltip } = useContext(ClassroomTooltipContext);
  const [modalDelShow, setModalDelShow] = useState(false);
  const [showTooltipPacing, setShowTooltipPacing] = useState(false);

  useEffect(() => {
    window.scrollTo(0,0);
  },[])

  const isShowTransparent = tooltip === 6 || tooltip === 7;
  return (
    <div className="PacingCalender">
      <div className="PacingCalender__container position-relative">
        {isShowTransparent && (
          <div className="toolTip-bg toolTip-bg-transparent"></div>
        )}
        <div className="PacingCalender__container__header d-sm-flex justify-content-between">
          <PacingCalHeader showTooltipPacing={showTooltipPacing} setModalDelShow={setModalDelShow} />
        </div>
        <div className="overflow-auto">
          <AssesssmentsTable setShowTooltipPacing={setShowTooltipPacing} modalDelShow={modalDelShow} setModalDelShow={setModalDelShow} />
        </div>
      </div>
    </div>
  );
};
export default PacingCalenders;
