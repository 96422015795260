import React from "react";

const ZipFIleIcon = () => {
  const titleId = "zipFileIcon";
  return (
    <svg
      aria-labelledby={titleId}
      id="zip_file_icon"
      data-name="zip file icon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="45.571"
      height="58"
      viewBox="0 0 45.571 58"
    >
      <title id={titleId}>Zip File</title>
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_232"
            data-name="Rectangle 232"
            width="45.571"
            height="58"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_107" data-name="Group 107" clipPath="url(#clip-path)">
        <path
          id="Path_193"
          data-name="Path 193"
          d="M79.918,58H42.856a2.3,2.3,0,0,1-2.3-2.3V2.3a2.3,2.3,0,0,1,2.3-2.3H73.6l8.614,8.614V55.7a2.3,2.3,0,0,1-2.3,2.3"
          transform="translate(-36.642)"
          fill="#f5f5f5"
        />
        <path
          id="Path_194"
          data-name="Path 194"
          d="M0,226.554l4.479,3.976.3-3.976Z"
          transform="translate(0 -204.665)"
          fill="#e27829"
        />
        <path
          id="Path_195"
          data-name="Path 195"
          d="M79.918,58H42.856a2.3,2.3,0,0,1-2.3-2.3V2.3a2.3,2.3,0,0,1,2.3-2.3H73.6l8.614,8.614V55.7a2.3,2.3,0,0,1-2.3,2.3M42.856,1.12A1.177,1.177,0,0,0,41.681,2.3V55.7a1.177,1.177,0,0,0,1.176,1.176H79.918A1.177,1.177,0,0,0,81.094,55.7V9.078L73.136,1.12Z"
          transform="translate(-36.642)"
          fill="#c9c9c9"
        />
        <path
          id="Path_196"
          data-name="Path 196"
          d="M381.27,12.663a2.575,2.575,0,0,0,2.575,2.575h5.71L381.27,6.953Z"
          transform="translate(-344.432 -6.281)"
          fill="#c9c9c9"
        />
        <rect
          id="Rectangle_224"
          data-name="Rectangle 224"
          width="32.695"
          height="13.716"
          transform="translate(0 8.191)"
          fill="#f7bd38"
        />
        <path
          id="Path_197"
          data-name="Path 197"
          d="M78,117.832l3.885-5.922H78.367v-1.673h5.962v1.2l-3.885,5.922H84.37v1.673H78Z"
          transform="translate(-70.466 -99.587)"
          fill="#f5f5f5"
        />
        <rect
          id="Rectangle_225"
          data-name="Rectangle 225"
          width="1.983"
          height="8.795"
          transform="translate(15.388 10.651)"
          fill="#f5f5f5"
        />
        <path
          id="Path_198"
          data-name="Path 198"
          d="M201.293,110.239h3.022c1.929,0,3.494.7,3.494,2.833,0,2.064-1.578,2.981-3.44,2.981h-1.093v2.981h-1.983Zm2.954,4.236c1.093,0,1.619-.485,1.619-1.4s-.594-1.254-1.673-1.254h-.917v2.657Z"
          transform="translate(-181.844 -99.588)"
          fill="#f5f5f5"
        />
        <rect
          id="Rectangle_226"
          data-name="Rectangle 226"
          width="4.815"
          height="2.749"
          transform="translate(19.931 26.761)"
          fill="#c9c9c9"
        />
        <rect
          id="Rectangle_227"
          data-name="Rectangle 227"
          width="4.815"
          height="2.749"
          transform="translate(24.745 29.51)"
          fill="#c9c9c9"
        />
        <rect
          id="Rectangle_228"
          data-name="Rectangle 228"
          width="4.815"
          height="2.749"
          transform="translate(19.931 32.26)"
          fill="#c9c9c9"
        />
        <rect
          id="Rectangle_229"
          data-name="Rectangle 229"
          width="4.815"
          height="2.749"
          transform="translate(24.745 35.009)"
          fill="#c9c9c9"
        />
        <rect
          id="Rectangle_230"
          data-name="Rectangle 230"
          width="4.815"
          height="2.749"
          transform="translate(19.931 37.758)"
          fill="#c9c9c9"
        />
        <rect
          id="Rectangle_231"
          data-name="Rectangle 231"
          width="4.815"
          height="2.749"
          transform="translate(24.745 40.508)"
          fill="#c9c9c9"
        />
        <path
          id="Path_199"
          data-name="Path 199"
          d="M232,459.787h-3.972l-.8,5.929a1.461,1.461,0,0,0,1.606,1.489h2.35a1.461,1.461,0,0,0,1.606-1.489Zm-3.568,4.979h0a1.2,1.2,0,0,1,1.2-.859h.774a1.2,1.2,0,0,1,1.2.859c.208.832-.558,1.613-1.582,1.613s-1.791-.781-1.582-1.613"
          transform="translate(-205.271 -415.363)"
          fill="#c9c9c9"
        />
      </g>
    </svg>
  );
};

export default ZipFIleIcon;
