import { useEffect, useLayoutEffect, useRef } from 'react';
import Header from '../Assessment/Header';
import Footer from './Footer';
import QuestionContainer from './QuestionContainer';
import QuestionnaireContextProvider from '../Assessment/QuestionnaireContext'

let isAddedScript: any = null;
const Assessment = () => {
    const highLighterRef = useRef<any>();
    const removeHighlightRef = useRef<any>();

    useLayoutEffect(() => {
        document.body.style.backgroundColor = "unset";

        return () => {
            document.body.style.backgroundColor = "#194792"
        }
    }, []);

    const onClickHighLighter = (setIsScreenMarked:Function) => {
        const highlightArea = document.getElementById('highlight');

        if (highlightArea) {
            highLighterRef.current = new (window.TextHighlighter as any)(highlightArea as HTMLElement, { isRemoveHighlight:false, setIsScreenMarked });
        }
    }

  
    const onRemoveHighlighter = () => {
        const highlightArea = document.getElementById('highlight');

        if(highlightArea){
            highLighterRef?.current?.removeHighlights(highlightArea);
        }
    }

    const removeHighlight = () => {
        const highlightArea = document.getElementById('highlight');

        if(highlightArea){
            removeHighlightRef.current = new (window.TextHighlighter as any)(highlightArea as HTMLElement, { isRemoveHighlight: true, setIsScreenMarked: () => {} });
        }
    }

    const onDestroyHighlighter = () => {
        const highlightArea = document.getElementById('highlight');

        if (highlightArea) {
            highLighterRef?.current?.destroy();
        }
    }

    const onDestroyHighlighterRemover = () => {
        const highlightArea = document.getElementById('highlight');

        if (highlightArea) {
            removeHighlightRef?.current?.destroy();
        }
    }

    useEffect(() => {
        if(!isAddedScript){
            isAddedScript = true;
            const calculatorScript = document.createElement("script");
            calculatorScript.src = '/calculator.js';
            document.head.appendChild(calculatorScript);
        }
    },[]);

    return (
        <QuestionnaireContextProvider>
            <Header />
            <QuestionContainer
                onRemoveHighlighter={onRemoveHighlighter}
            />
            <Footer
                onClickHighLighter={onClickHighLighter}
                onRemoveHighlighter={removeHighlight}
                onDestroyHighlighter={onDestroyHighlighter}
                onDestroyHighlighterRemover={onDestroyHighlighterRemover}
                onRemoveAllHighlights={onRemoveHighlighter}
            />
        </QuestionnaireContextProvider>
    );
}

export default Assessment;