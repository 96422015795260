import { useEffect, useState } from "react";
import ChatSessionTable from "./ChatSessionTable";
import SupportCenterHeader from "./Header";
import SupportTicketTable from "./SupportTicketTable";


const SupportChatCenter = () => {
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const [refreshTableOnClick, setRefreshTableOnClick] = useState<boolean>(false);

  const refreshTableHandler = () => setRefreshTable((rTable) => !rTable);
  const refreshTableOnClickHandler = () => setRefreshTableOnClick((rOnClickTable) => !rOnClickTable);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      refreshTableHandler();
    }, 30000);

    return () => clearInterval(interval);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="SupportCenter position-relative">
      <SupportCenterHeader
        refreshTable={refreshTable}
        refreshTableOnClick={refreshTableOnClick}
        refreshTableOnClickHandler={refreshTableOnClickHandler}
      />
      <SupportTicketTable 
        refreshTable={refreshTable}
        refreshTableOnClick={refreshTableOnClick}
      />
      <ChatSessionTable 
        refreshTable={refreshTable}
        refreshTableOnClick={refreshTableOnClick}
      />
    </div>
  );
};
export default SupportChatCenter;
