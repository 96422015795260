/* eslint-disable react-hooks/exhaustive-deps */

import moment from "moment"
import { useMemo } from "react"
import ChevronIcon from "assets/img/back-arrow.png";
import Collapsible from 'react-collapsible';

interface Props {
    student: any;
    index: number;
    questionId: number;
    questions: any
    tab: number
    xThreshold: number,
    dropDownLabel: string,
    expandedStudents: any
    onClickExpand: Function;
    setIsolatedStudent: Function;
}

const AnswerMap = {
    0: 'A',
    1: 'B',
    2: 'C',
    3: 'D'
}

const Student = ({ setIsolatedStudent, student, index, questionId, tab, questions, xThreshold, dropDownLabel, expandedStudents, onClickExpand }: Props) => {

    const { attempts, last_name, first_name, tabs } = useMemo(() => {
        if (student) {
            return {
                ...student,
                tabs: Math.ceil(questions.length / xThreshold),
            }
        }
        return {
            tabs: questions.length / xThreshold,
            answers: [],
            last_name: '',
            first_name: '',
            attempts: [],
            showAttempts: false
        }

    }, [student, questions, xThreshold])

    const isHideRow = student.isShowIsolated === false; // dont use not sign (!) with student.isShowIsolated

    const tableContent = useMemo(() => {

        let records = attempts.map((attempt: any, attemptIndex: number) => {
            const { answers, calculation , test_date } = attempt;
            return <tr className={index % 2 !== 0 ? "dark-gray" : ''}>
                <td onClick={() => setIsolatedStudent(student)} className={`report__itemTable-name report__data-height irr-table-name ${index % 2 !== 0 ? "dark-gray" : ''}`}>
                    {!attemptIndex ? <span className="ellipsis-animated">
                        <p className={`cursor-pointer ellipsis-animated-text ${last_name.concat(first_name).length > 14 && 'animated ellipsis-animation'} ${student.isShowIsolated ? 'font-bold' : isHideRow ? 'objective-isolation' : ''}`}>
                            {student.last_name + ', ' + student.first_name}
                        </p>
                    </span>
                        : ''
                    }
                </td>

                <div className={`${tab === 1 ? 'faded-right-boxes' : 'faded-left-boxes'}`}>
                    {[...Array(tabs)].map((_: any, idx: number) => {
                        const slicedAnswers = answers?.slice(tab * xThreshold, (tab * xThreshold) + xThreshold)
                        return <div className="" style={{ display: tab === idx ? '' : 'none' }}>
                            {slicedAnswers.map((_: any, i: number) => {
                                const Answer = answers?.find((singleAnswer: any) => singleAnswer?.question_id === questions[i + (idx * xThreshold)]?.question_id)
                                return (
                                    <td className="report__itemTable-numbers report__data-height" key={i}>
                                        {Answer?.response ?
                                            <span className={`${isHideRow || (questionId !== null && i !== questionId) ? 'hidden' : ""} ${Answer.info === 'correct' ? 'high' : 'low'} report__itemTable-rectangle`}>
                                                {Answer.info !== 'correct' && AnswerMap[Answer.response.trim() as keyof typeof AnswerMap]}
                                            </span>
                                            :
                                            <span className={`${isHideRow || (questionId !== null && i !== questionId) ? 'hidden' : ""} unattempted report__itemTable-rectangle`}>
                                            </span>
                                        }
                                    </td>
                                )
                            })}</div>
                    })
                    }
                </div>
                <td className="report__itemTable-attempt report__data-height d-flex justify-content-center align-items-center position-relative">
                    <span className={`${isHideRow ? 'hidden' : ''}`}>{
                        dropDownLabel === "Attempt" ? `${calculation?.attempt}` : (test_date && test_date.length && moment(test_date).format('M-D-YY')) || ''
                    }</span>
                    {attempts.length > 1 && <div style={{ display: 'inline-block', position:'absolute',right: `${dropDownLabel === 'Date' ? '-20px' :'0px'}` }} className="irr-summative-collapse-icon">{
                        <span onClick={() => onClickExpand(student.id)}>
                            {!attemptIndex ? <img
                                src={ChevronIcon}
                                className={`${expandedStudents[student.id] ? 'rotate' : 'rotate-90'} `}
                                alt="arrow"
                            /> : ''}
                        </span>}
                    </div>
                    }
                </td>

                <td className={`${isHideRow ? 'hidden' : ''} report__itemTable-score report__data-height irr-table-score`}>
                    <span>{`(${calculation?.total_correct}) ${calculation?.score_percentage}%`}</span>
                </td>

            </tr>
        })

        if (records.length > 1) {
            return <Collapsible
                tabIndex={1}
                handleTriggerClick={() => { }}
                open={!!expandedStudents[student.id]}
                overflowWhenOpen='unset'
                trigger={records[0]}>
                {records.slice(1)}
            </Collapsible>
        } else {
            return <div > {records[0]} </div>
        }

    }, [isHideRow, expandedStudents, tab ,attempts, dropDownLabel , questionId])


    return (
        <>{tableContent}</>
    )
}

export default Student