import React, { useEffect } from "react";
import IPage from "interfaces/page";
import logging from "config/logging";
import Theme from "components/theme/index";
import WayToAReport from "components/wayToAReport";
import WayToAReportProvider from "contexts/wayToAReport";


const OAGReport: React.FunctionComponent<IPage> = (props) => {
  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props.name]);

  return (
    <>
      <Theme>
        <WayToAReportProvider>
          <WayToAReport />
        </WayToAReportProvider>
      </Theme>
    </>
  );
};

export default OAGReport;
