interface ScoredSummative {
  id: number;
  fk_summative_id: number;
  attempt: number;
  total_correct: number;
  score_percentage: string;
  fk_student_id: number;
  fk_classroom_id: number;
}

interface ScoredFormative {
  dataValues: {
    id: number;
    fk_formative_id: number;
    attempt: number;
    total_correct: number;
    score_percentage: string;
    fk_student_id: number;
    fk_classroom_id: number;
  };
  _previousDataValues: any;
  uniqno: number;
  _changed: any;
  _options: any;
  isNewRecord: boolean;
  score_percentage: string;
}

interface Classroom {
  school_id: number;
  school_name: string;
  classroom_id: number;
  classroom_name: string;
  name: string;
  date: string;
  attempts: number;
  scored_summatives?: ScoredSummative[];
  scored_formatives?: ScoredFormative[];
  last_score: string;
  summative?: boolean;
  formative?: boolean;
}

interface Data {
  [key: string]: Classroom[];
}

interface DataAll {
  tests: Data;
  classroom_ids: { classroom_id: number; classroom_name: string }[];
}

export default function filterAndReorderClassrooms(
  dataAll: DataAll,
  classroomId: number,
  filterFlag: boolean
): Data {
  const result: Data = {};

  // Temporary variable to store the subject to be moved to the top
  let topSubject: string | null = null;

  const data = dataAll.tests;

  for (const subject in data) {
    if (data.hasOwnProperty(subject)) {
      const classrooms = data[subject];

      if (filterFlag) {
        // Filter classrooms based on classroomId
        const filteredClassrooms = dataAll.classroom_ids.filter(
          (classroom) => classroom.classroom_id === classroomId
        );

        if (
          filteredClassrooms.length > 0 &&
          subject.toLowerCase().trim() ===
            filteredClassrooms[0].classroom_name.toLowerCase().trim()
        ) {
          result[subject] = data[subject];
        }
      } else {
        // Reorder classrooms to move the one with classroomId to the top
        const selectedClassrooms: Classroom[] = [];
        const otherClassrooms: Classroom[] = [];

        for (const classroom of classrooms) {
          if (classroom.classroom_id === classroomId) {
            selectedClassrooms.push(classroom);
          } else {
            otherClassrooms.push(classroom);
          }
        }

        result[subject] = [...selectedClassrooms, ...otherClassrooms];

        // Check if the subject contains the classroomId to move it to the top
        if (selectedClassrooms.length > 0) {
          topSubject = subject;
        }
      }
    }
  }

  let finalObject: Data = result;

  // If reordering, move the subject with the classroomId to the top
  if (!filterFlag && topSubject) {
    const topSubjectData = result[topSubject];
    delete result[topSubject];

    const top: Data = {};

    top[topSubject] = topSubjectData;

    finalObject = { ...top, ...result };
  }

  return finalObject;
}
