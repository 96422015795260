export const SQUARE_ROOT_CHAR = '√';

export const getSqrtHtml = (expr: any) => {
    return `<div class="sqr-root">
                <span class="root">
                    <span class="radicand">
                        ${expr}
                    </span>
                </span>
            </div>&nbsp;`
}

const getParaElement = (textContent: string) => {
    return `<p class="sqr-root_inline">${textContent}</p>`
}

export const convertPreviousSqrt = (elem: any) => {
    if(elem.childNodes.length){
        elem.childNodes.forEach((item: any) => {
            convertPreviousSqrt(item);
        })
    }else{
        if(elem.nodeType === 3){
            const sqrtIndex = elem.nodeValue.indexOf(SQUARE_ROOT_CHAR);
            if(sqrtIndex < 0) return;

            let i = sqrtIndex+1;
            let sqrtExpression = "";
            while((i < elem.nodeValue.length) && elem.nodeValue[i] !== " "){
                sqrtExpression += elem.nodeValue[i];
                i++;
            }
            const sqrtHtml = getSqrtHtml(sqrtExpression);
            let newHtmlString = sqrtHtml;

            const prevText = elem.nodeValue.substring(0, sqrtIndex);
            if(prevText){
                const prevTextElement = getParaElement(prevText);
                newHtmlString = `${prevTextElement}${newHtmlString}`
            }

            const nextText = elem.nodeValue.substring(i+1);
            if(nextText){
                const nextTextElement = getParaElement(nextText);
                newHtmlString = `${newHtmlString}${nextTextElement}`
            }

            elem.textContent = "";
            elem.parentNode.innerHTML = newHtmlString;
        }
        return elem;
    }
}