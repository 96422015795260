import BenchmarkModal from "components/common/reportModal/benchmarkModal";
import FormativeAssessedModal from "components/common/reportModal/formativeModal";
import { useMemo, useEffect, useState, useRef } from "react";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";
import { useParams, useSearchParams } from "react-router-dom";
import { getIRRFormative } from "redux/actionCreators/classroom";
import SearchIconGray from "assets/img/search-icon-gray.svg";
import Cookies from "js-cookie";
import Report from "./report";
import Print from "./Print";
import ReportHeader from "components/common/report/header";
import Cookie from "js-cookie";
import { useQuery } from "hooks/useQuery";
import { replaceURL } from "util/replaceUrl";
import { AppCookies } from "util/constants";

const ItemResponseReportFormative = () => {
  const report = useSelector(
    (state: RootState) => state.classroom.irrFormative
  );
  const classrooms = useSelector(
    (state: RootState) => state.classroom.classrooms
  );
  const classroomId = useSelector(
    (state: RootState) => state.classroom.classroomId
  );
  const dispatch = useDispatch();
  const [showFormativeModal, setShowFormativeModal] = useState(false);
  const [showBenchmarkModal, setShowBenchMarkModal] = useState(false);
  const [search, setSearch] = useState("");
  const { id }: any = useParams();
  
  const classroom = (window as any)['classroomId'] || classroomId

  const componentRef = useRef(null);
  const query = useQuery();
  const print = query.get("print");
  const [dropDownLabel, setDropDownLabel] = useState<string>("Attempt");

  const [isLoading, setIsLoading] = useState(false);

  const [searchParams] = useSearchParams();

  let assessmentName = Cookies.get(AppCookies.test_name) || "";

  if (
    assessmentName.indexOf("- A1") > -1 ||
    assessmentName.indexOf("- A2") > -1 ||
    assessmentName.indexOf("- B") > -1
  ) {
    assessmentName = "";
  }

  useEffect(() => {
    const goalId = Number(searchParams.get("goal_id"));
    const taskId = Number(searchParams.get("task_id"));
    const selectedClassroomId = (window as any)['classroomId'] || classroomId;
    if(selectedClassroomId){
      dispatch(getIRRFormative(id, selectedClassroomId, goalId, taskId));
      replaceURL(`/classroom/report/item-response-report-formative/${id}/${selectedClassroomId}`);
    }

    setIsLoading(true);
  }, [dispatch, classroomId, id, searchParams]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useMemo(() => {
    if (report?.data) {
      setIsLoading(false);
    }
  }, [report]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `IRR_Formative_${
      report?.data?.response?.class_name
    }_${moment(new Date()).format("M/DD/YY hh:mmA")}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      print && window.close();
    },
  });

  useEffect(() => {
    if (report && print) {
      handlePrint();
    }
  }, [report, print, handlePrint]);

  const searchChangehandler = (e: any) => {
    setSearch(e.target.value);
  };

  const openAssessment = () => {
    const assessmentClass = classrooms.find(
      (cls: any) => cls.id === Number(classroom)
    );
    const isMath = assessmentClass.label.includes("Math");
    Cookie.set(AppCookies.isMath, isMath);
    Cookie.set(AppCookies.assessmentName, assessmentName);

    const { formative_id } = report?.data?.response;
    formative_id && window.open(`/preview/formatives/${formative_id}`);
  };

  return (
    <div>
      {!print && (
        <div className="report hide-on-print">
          <ReportHeader
            reportTitle="Item Response Report"
            reportGuide="Click on an objective to isolate."
            setPrint={handlePrint}
            testName={assessmentName}
            handleReviewTest={openAssessment}
            headerClassName="based-header report__transparent_header"
          />

          <div className="report-content">
            <div className="report__formative irr-summative">
              <div className="report__search formative-search">
                <div className="report__search-searcharea formative-searcharea">
                  {!isLoading && (
                    <img
                      src={SearchIconGray}
                      className="report__search-icon animated-fade"
                      alt=""
                    />
                  )}
                  <input
                    className={`form-control report__search-input ${
                      isLoading ? "margin-left" : ""
                    }`}
                    type="search"
                    name="Search"
                    placeholder="Search Students"
                    value={search}
                    onChange={searchChangehandler}
                  />
                </div>
              </div>

              <Report
                search={search}
                isLoading={isLoading}
                dropDownLabel={dropDownLabel}
                setDropDownLabel={setDropDownLabel}
              />
            </div>
          </div>

          <FormativeAssessedModal
            isShow={showFormativeModal}
            closeModal={() => setShowFormativeModal(false)}
          />

          <BenchmarkModal
            isShow={showBenchmarkModal}
            closeModal={() => setShowBenchMarkModal(false)}
          />
        </div>
      )}
      {report && (
        <div ref={componentRef} className="printData">
          <Print dropDownLabel={dropDownLabel} />
        </div>
      )}
    </div>
  );
};
export default ItemResponseReportFormative;
