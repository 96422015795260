import { PropsWithChildren } from "react"

const Conatiner = ({children, isDisabled, onClick}: PropsWithChildren<any>) => {
    return (
        <div className="goalsCard" role="button" tabIndex={0} onKeyDown={(e) => {
            if (e.key === 'Enter') {
                e.stopPropagation();
                onClick()
            }
        }}  >
            {children}
            {isDisabled && (
                <div className="overlay"></div>
            )}
        </div>
    )
};

export default Conatiner;
