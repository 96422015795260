import { useContext } from "react"
import Formatives from "./formative"
import Summatives from "./summatives"
import ReportClickAlertModal from "components/classroom/modals/ReportClickAlertModal";
import Cookie from "js-cookie";
import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment"
import PreviewHelpModel from "components/classroom/modals/previewHelpModel";
import { AppCookies } from "util/constants";

const Assessments = () => {
    const { showReportModal, setShowReportAlertModal, classrooms, classroomId, formatives, isLoading, showReviewHelpModel, setShowReviewHelpModel } = useContext(ProfessionalDevelopmentContext)

    const isClassAdmin = Cookie.get(AppCookies.role) === 'Classroom Admin';
    const has_kindergarten = Cookie.get(AppCookies.has_kindergarten) || Cookie.get(AppCookies.has_zero_students_classrooms);

    const selectedClass = classrooms.find((item: any) => item.id === classroomId)
    const isSingle = classrooms.length === 1 && has_kindergarten === 'true'
    const isKindergartenSelected = (selectedClass?.fk_grade_id === 9 || selectedClass?.students_count === 0) || isSingle

    if(isClassAdmin && isKindergartenSelected){
        return null
    }

    return <>
        <Summatives />
        
        { ( (isLoading || !classroomId) ||  (!!classroomId && !!formatives.length) ) && <Formatives /> }

        <ReportClickAlertModal
            show={!!showReportModal.length}
            assessment={showReportModal}
            closeModal={() => {
                setShowReportAlertModal('')
            }}
        />

        <PreviewHelpModel
            show={showReviewHelpModel}
            closeModal={() => setShowReviewHelpModel(false)}
        />

    </>
}
export default Assessments