import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  registerables,
  Chart,
} from "chart.js";
import moment from "moment";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import _ from "lodash";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import LinePlotIcon from "assets/img/line-plot-key.svg";

Chart.register(...registerables);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels,
  PointElement,
  LineElement
);

const formateLabel = (name: string) => {
  const label = name.split(",")[0].substring(0, 3);
  return `${label} ${name.split(",")[1]}`;
};

const getChartData = (props: any) => {
  const { data, tab } = props;
  let index = 0;
  let dataPointsTemplate = [];
  let dataLinesTemplate = [];
  let labels = [];

  if(!data){
    return { dataLinesTemplate: [], dataPointsTemplate:[], labels:[] }
  }
  if (tab === "teachers") {
    for (let key in data.active_teachers) {
      if (data.active_teachers.hasOwnProperty(key)) {
        const todayIndex = Object.keys(data.active_teachers).length - 1;
        labels.push(index === todayIndex && key.includes(moment().format('dddd')) ? "Today" : formateLabel(key));
        // dataLinesTemplate.push(data.active_teachers[key]);
        dataLinesTemplate.push(data.active_principals[key]);
        dataPointsTemplate.push(data.active_teachers[key]);

      }
      index++;
    }
  }

  else if (data.active_students && tab === "students") {
    for (let key in data.active_students) {
      if (data.active_students.hasOwnProperty(key)) {
        const todayIndex = Object.keys(data.active_students).length - 1;
        dataPointsTemplate.push(data.active_students[key]);
        labels.push(index === todayIndex && key.includes(moment().format('dddd')) ? "Today" : formateLabel(key));
      }
      index++;
    }
  }


  else if (tab === "assessments") {
    for (let key in data.formatives) {
      if (data.formatives.hasOwnProperty(key)) {
        const todayIndex = Object.keys(data.formatives).length - 1;
        dataPointsTemplate.push(data.formatives[key]);
        dataLinesTemplate.push(data.summatives[key]);
        labels.push(index === todayIndex && key.includes(moment().format('dddd')) ? "Today" : formateLabel(key));
      }
      index++;
    }
  }
  return { dataLinesTemplate, dataPointsTemplate, labels };
};

const screenWidth = window.innerWidth;
let fontSize = 16;

if (screenWidth < 767) {
  fontSize = 12;
}

if (screenWidth < 509) {
  fontSize = 8;
}

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  tooltips: {
    enabled: false,
  },
  title: {
    display: false,
  },
  legend: {
    display: false,
  },
  plugins: {
    htmlLegend: {

    },
    title: {
      display: true,
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      display: false,
      position: '',
      align: '',
      labels: {
        color: "#323232"
      }
    },
    datalabels: {
      align: "end",
      anchor: "end",
      color: "#323232",
      font: { size: 16 },
      backgroundColor: function (ctx: any) {
        if (ctx.dataset.type === 'bar') {
          return 'transparent'
        }
        else {
          return '#46AFB8'
        }
      },
      borderRadius: 17,
      padding: { left: 6, right: 6, top: 4, bottom: 3 },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        color: "#323232",
        font: {
          size: fontSize
        },
        beginAtZero: true,
      },
    },
    y: {
      grace: "1%",
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        color: "#323232",
        font: {
          size: fontSize,
        },
        beginAtZero: true,
      },
    },
  },
};

const getLegends = (tab: string, summativeLabel: string) => {
  switch (tab) {
    case 'assessments':
      return { lineLegend: summativeLabel, barLegend: 'Formatives' }
    case 'teachers':
      return { lineLegend: 'Teacher Supports', barLegend: 'Teachers' }
    default:
      return { lineLegend: 'Line Dataset', barLegend: 'Bar Dataset' }
  }
}

export const getData = (data1: number[], data2: number[], labels: string[], tab: string, summativeLabel: string) => {
  const { lineLegend, barLegend } = getLegends(tab, summativeLabel)

  return {
    labels,
    datasets: [
      {
        type: "line",
        label: lineLegend,
        data: data2,
        borderColor: "#46AFB8",
        pointBackgroundColor: "#46AFB8",
        pointBorderColor: "#46AFB8",
        pointRadius: 3,
      },
      {
        type: "bar",
        label: barLegend,
        data: data1,
        backgroundColor: [
          "#919191",
          "#3e3e3e",
          "#919191",
          "#3e3e3e",
          "#919191",
          "#3e3e3e",
        ],
      },
    ],
  };
};

export const getOptions = (tab: string) => {
  let updatedOptions: any = _.cloneDeep(options)

  if (["assessments", "teachers"].includes(tab)) {
    updatedOptions = {
      ...updatedOptions,
      layout: {
        padding: {
          right: 50,
        }
    },
    }
    updatedOptions.plugins.legend.display = false
    // updatedOptions.plugins.legend.position = 'right'
    // updatedOptions.plugins.legend.align = 'end'
    updatedOptions.plugins.lengend = {
      ...updatedOptions.plugins.lengend,
      position: 'right',
      align: 'end'
    }
    updatedOptions.plugins = {
      ...updatedOptions.plugins,
      htmlLegend: {
        containerID: 'legend-container'
      }
    }
  }

  return updatedOptions
}

const getOrCreateLegendList = (chart: any, id: any) => {
  const legendContainer: any = document.getElementById(id);
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.style.display = 'flex';
    listContainer.style.flexDirection = 'row';
    listContainer.style.margin = 0;
    listContainer.style.padding = 0;

    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart: any, args: any, options: any){
    const ul = getOrCreateLegendList(chart, options.containerID);

    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    const items = chart.options.plugins.legend.labels.generateLabels(chart);

    items.forEach((item: any) => {
      const li = document.createElement('li');
      li.style.alignItems = 'center';
      li.style.cursor = 'pointer';
      li.style.display = 'flex';
      li.style.flexDirection = 'row';
      li.style.marginLeft = '10px';
      li.setAttribute("tabIndex", '0');
      li.setAttribute("role", 'button')

      li.onkeydown = (e: any) => {
        if(e.key === "Enter"){
          const index = item.datasetIndex;
          var alreadyHidden = (chart.getDatasetMeta(index).hidden === null) ? false : chart.getDatasetMeta(index).hidden;

          chart.data.datasets.forEach(function(e: any, i: number) {
            var meta = chart.getDatasetMeta(i);

            if (i !== index) {
              if (!alreadyHidden) {
                meta.hidden = meta.hidden === null ? !meta.hidden : null;
              } else if (meta.hidden === null) {
                meta.hidden = true;
              }
            } else if (i === index) {
              meta.hidden = null;
            }
          });

          chart.update();
        }
      }
      li.onclick = () => {
        const index = item.datasetIndex;
        var alreadyHidden = (chart.getDatasetMeta(index).hidden === null) ? false : chart.getDatasetMeta(index).hidden;

        chart.data.datasets.forEach(function(e: any, i: number) {
          var meta = chart.getDatasetMeta(i);

          if (i !== index) {
            if (!alreadyHidden) {
              meta.hidden = meta.hidden === null ? !meta.hidden : null;
            } else if (meta.hidden === null) {
              meta.hidden = true;
            }
          } else if (i === index) {
            meta.hidden = null;
          }
        });

        chart.update();
      };

      // Color box
      const isLine = chart.data.datasets[item.datasetIndex].type === "line";
      let linePlotBox: HTMLImageElement = null!;
      let boxSpan: HTMLSpanElement = null!;
      if(isLine){
        linePlotBox = document.createElement('img');
        linePlotBox.setAttribute("alt", "line plot")
        linePlotBox.src = LinePlotIcon;
      }else{
        boxSpan = document.createElement('span');
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + 'px';
        boxSpan.style.display = 'inline-block';
        boxSpan.style.height = '10px';
        boxSpan.style.marginRight = '10px';
        boxSpan.style.width = '40px';
      }

      // Text
      const textContainer: any = document.createElement('p');
      textContainer.style.color = item.fontColor;
      textContainer.style.margin = 0;
      textContainer.style.padding = 0;
      textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

      const text = document.createTextNode(item.text);
      textContainer.appendChild(text);

      li.appendChild(isLine ? linePlotBox : boxSpan);
      li.appendChild(textContainer);
      ul.appendChild(li);
    });
  }
}

export function ColumnLineMixChart(props: any) {
  const { tab, loading } = props;
  const { dataLinesTemplate, dataPointsTemplate, labels } = getChartData(
    props
  );
  const finalData = getData(dataPointsTemplate, dataLinesTemplate, labels, tab, props?.data?.summative_label || 'Summative');

  const finalOption = getOptions(tab);
  return (
    <div className="graph">
      <div className="stateChart">
      {loading ? (
        <table><TableSkeleton count={1} columns={7} height={237} /></table>
      ) : (
        <>
          <div id="legend-container"></div>
          <Bar options={finalOption as any} data={finalData as any} plugins={tab !== 'students' ? [htmlLegendPlugin]: []} />
        </>
      )}
        
      </div>
    </div>
  );
}
