/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */

import React, { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import { changePassword, logout } from "redux/actionCreators/auth";
import { useDispatch, useSelector } from "react-redux";
import { encryptPassword } from "util/index";
import SuccessModal from "../common/successModal/index";
import { Input, CustomInput, ValidationHint } from "../common/inputComponent";
import { Button } from "../common/buttonComponent";
import { ReactComponent as ShowIconWhite } from "assets/img/show-icon.svg";
import { ReactComponent as HideIconWhite } from "assets/img/hide-icon.svg";
import { handlePress, handleKeyUp } from './../../util/index';
import CustomModal from "components/common/Modal";

interface ChangePasswordModalProps {
  isShow: boolean;
  closeModal: () => void;
  force?: boolean;
}

interface RootState {
  auth: any;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  isShow,
  closeModal,
  force,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(true);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [showAlert, setShowAlert] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  useEffect(() => {
    !isShow && handleCancel();
  }, [isShow]);

  useEffect(() => {
    checkDisabled();
  }, [newPassword, confirmPassword]);

  useEffect(() => {
    if (user.auth.type === "USER_CHANGE_PASSWORD_SUCCESS") {
      closeModal();
      setLoading(false);
      setShowSuccess(true);
    } else if (user.auth.type === "USER_CHANGE_PASSWORD_FAIL") {
      setShowAlert(true);
      setIsSubmitError(false);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const checkDisabled = () => {
    if (newPassword && confirmPassword) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }

  const onSubmit = () => {
    setLoading(true);
    const errors = handleValidation();
    const isError =
      errors.newPassword || errors.confirmPassword;
    if (!isError) {
      if (force) {
        let data = {
          new_password: encryptPassword(newPassword),
        };
        dispatch(changePassword(data));
      } else {
        let data = {
          old_password: encryptPassword(currentPassword),
          new_password: encryptPassword(newPassword),
        };
        dispatch(changePassword(data));
      }
    } else {
      setClientErrors(errors);
      setIsSubmitError(true);
      setLoading(false);
    }
  };

  const handleOnChange = (name: string, value: string) => {
    name === "currentPassword" && setCurrentPassword(value);
    name === "newPassword" && setNewPassword(value);
    name === "confirmPassword" && setConfirmPassword(value);
    var regex = /^(?=.*\d)[A-Za-z~!@#\$%\^\&*\)\(+=._-|/,;?\d]{6,}$/g

    if (isSubmitError) {
      const isValid =
        name === "confirmPassword"
          ? value.trim().length > 0 && value === newPassword
          :  name === "newPassword" ? value.trim().length > 0 && regex.test(value) : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  const handleValidation = () => {
    const errors: any = {};
    if (currentPassword.trim().length === 0) {
      errors["currentPassword"] = "required";
    }
    if (newPassword.trim().length === 0) {
      errors["newPassword"] = "required";
    }
    if (!/^(?=.*\d)[A-Za-z~!@#\$%\^\&*\)\(+=._-|/,;?\d]{6,}$/g.test(newPassword)) {
      errors["newPassword"] = "Requirements are not met";
    }
    if (newPassword.trim().length === 0) {
      errors["newPassword"] = "required";
    }
    if (confirmPassword.trim().length === 0) {
      errors["confirmPassword"] = "required";
    }
    if (confirmPassword.trim().length > 0 && confirmPassword !== newPassword) {
      errors["confirmPassword"] = "Passwords do not match";
    }
    return errors;
  };

  const handleCancel = () => {
    if (force) {
      dispatch(logout());
    }
    clearData();
    closeModal();
  };

  const clearData = () => {
    setCurrentPassword("");
    setConfirmPassword("");
    setNewPassword("");
    setDisabled(true);
    setIsSubmitError(false);
    setClientErrors({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setShowAlert(false);
    setLoading(false);
  };

  const closeSuccessModal = () => {
    setShowSuccess(false);
    dispatch(logout());
  };

  const triggerSubmitForm = (e: any) => {
    if (e.key === 'Enter' && !(loading || isDisabled)) onSubmit();
  }
  useEffect(() => {
    isShow && document.addEventListener('keydown', handlePress);
    isShow && document.addEventListener('keyup', handleKeyUp);

    return () => {
        document.removeEventListener('keydown', handlePress);
        document.removeEventListener('keyup', handleKeyUp);
    }
  }, []);

  return (
    <>
      <CustomModal
        centered
        show={isShow}
        onHide={handleCancel}
        onEscapeKeyDown={handleCancel}
      >
        <div className="modal__header">
          <h6 className="modal__header-title">Change Password</h6>
        </div>
        <div className="modal__body mb-1">
          {force && <p className="modal__password-text">You have logged in with a temporary password. Please change before proceeding.</p>}
          <div className="modal__form">
            <div className="mt-4"><ValidationHint isSubmitError={isSubmitError} /></div>
            {showAlert && (
              <Alert
                dismissible
                onClose={() => setShowAlert(false)}
                variant="danger"
              >
                Invalid password
              </Alert>
            )}
            {!force && 
             <div className='d-flex align-item-center'>
              <Input
                fieldName="currentPassword"
                isSubmitError={isSubmitError}
                errorPresentBefore={
                  clientErrors["currentPassword"] !== undefined
                }
                value={currentPassword}
                autoFocus={!force}
                type={passwordType}
                label="Current Password"
                onChange={handleOnChange}
                error={clientErrors.currentPassword}
              />
              {!force && <button className="modal__password-btn">
                {passwordType === "password" ? (
                  <ShowIconWhite
                    className="modal__password-eye"
                    onClick={() => setPasswordType("text")}
                  />
                ) : (
                  <HideIconWhite
                    className="modal__password-eye"
                    onClick={() => setPasswordType("password")}
                  />
                )}
              </button>
            }
            </div>
            }
            <div className={force ? 'd-flex align-item-center': 'modal__password-row'}>
              <CustomInput
                fieldName="newPassword"
                isSubmitError={isSubmitError}
                errorPresentBefore={
                  clientErrors["newPassword"] !== undefined
                }
                value={newPassword}
                autoFocus={force}
                type={passwordType}
                label="New Password"
                onChange={handleOnChange}
                error={clientErrors.newPassword}
                errorMessage="Requirements are not met"
                overlayClick={() =>
                  setClientErrors({
                    ...clientErrors,
                    newPassword: "required",
                  })
                }
              />
              {force && <button className="modal__password-btn">
                {passwordType === "password" ? (
                  <ShowIconWhite
                    className="modal__password-eye"
                    onClick={() => setPasswordType("text")}
                  />
                ) : (
                  <HideIconWhite
                    className="modal__password-eye"
                    onClick={() => setPasswordType("password")}
                  />
                )}
              </button>
             }
            </div>
            <div className="modal__password-row">
              <CustomInput
                fieldName="confirmPassword"
                isSubmitError={isSubmitError}
                errorPresentBefore={
                  clientErrors["confirmPassword"] !== undefined
                }
                value={confirmPassword}
                type={passwordType}
                label="Confirm New Password"
                onChange={handleOnChange}
                error={clientErrors.confirmPassword}
                errorMessage="Passwords do not match"
                overlayClick={() =>
                  setClientErrors({
                    ...clientErrors,
                    confirmPassword: "required",
                  })
                }
                onKeyDownProp = {(e: any) => triggerSubmitForm(e)}
              />
            </div>
          </div>
        </div>
        <div className="modal__password-requirement">
          <p>
            {
              isSubmitError && <span className={"red-error-circle"} />
            } Requirements:</p>
          <p><span>&#8226;</span> Six characters</p>
          <p><span>&#8226;</span> At least one number</p>
        </div>
        <div className="modal__footer change-password-modal">
          <Button
            buttonText={force ? "Log Out" : "Cancel"}
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />

          <Button
            buttonText="Change"
            onClick={onSubmit}
            className={`btn success-btn btn-lg  ${
              (loading || isDisabled) && "btn-disabled"
            }`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </CustomModal>
      {showSuccess && <SuccessModal
        bodyText="Your password has been changed. Please log back in."
        headerText="Success"
        isShow={showSuccess}
        closeModal={closeSuccessModal}
      />}
    </>
  );
};

export default ChangePasswordModal;
