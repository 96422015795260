/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash';
import { Spinner } from "react-bootstrap";
import SearchIcon from "assets/img/search-icon.svg";
import {
  getModalStudents,
  updateStudents,
} from "redux/actionCreators/classroom";
import { toast, ToastContainer } from "react-toastify";
import CustomModal from "components/common/Modal";

interface Props {
  show: boolean;
  summativeId?: number;
  formativeId?: number;
  setModalShow: any;
  classroomId: number;
}

interface studentType {
  is_available: boolean;
  id: number;
}

interface RootState {
  auth: any;
  activity: any;
  classroom: any;
}

const AvailabilityModal: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const app = useSelector((state: RootState) => state);
  const { show, summativeId, setModalShow, formativeId, classroomId } = props;

  const [students, setStudents] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [availableAll, setAvailableAll] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const onSearchStudents = async (e: any) => {
    setIsLoading(true);

    const getStudents = await getModalStudents({
      params: {
        summative_id: summativeId,
        formative_id: formativeId,
        name: e.target.value,
        classroom_id: classroomId,
      },
    });
    const data = _.orderBy(
      getStudents.data.data.studentsList,
      [(user) => user?.last_name.toLowerCase()],
      ["asc"]
    )
    setStudents(data);
    setIsLoading(false);
  };

  useEffect(() => {
    const init = async () => {
      setIsLoading(true);

      const getStudents = await getModalStudents({
        params: {
          summative_id: summativeId,
          formative_id: formativeId,
          classroom_id: classroomId,
        },
      });

      if (getStudents?.data?.data?.studentsList?.length === 0) {
        toast.error("Please enable availibility");
      }

      setStudents(getStudents?.data?.data?.studentsList);

      setIsLoading(false);
    };

    init();
  }, []);

  const handleSave = async () => {
    setIsSaving(true);
    dispatch(updateStudents({
      summative_id: summativeId,
      formative_id: formativeId,
      students: students.map((item: any) => {
        return {
          student_id: item.id,
          is_available: item.is_available,
        };
      }),
    }))
    setTimeout(() => {
      setIsSaving(false);
      closeModal();
    }, 1000);
  };

  const closeModal = () => {
    setStudents([]);
    setIsLoading(false);
    setAvailableAll(false);
    setModalShow(false);
  };

  const handleOnChange = (checked: boolean, student_id: number) => {
    const updatedStudentsList: any[] = students.length
      ? students.map((item: any) => {
          if (item.id === student_id && item.is_submitted === false) {
            item.is_available = checked ? true : false;
          }
          return item;
        })
      : [];
    setStudents(updatedStudentsList);
    setDisabled(false)
  };

  const handleOnChangeAll = (checked: boolean) => {
    setAvailableAll(checked);
    const updatedStudentsList: any[] = students.length
      ? students.map((item: any) => {
          if (item.is_submitted === false) {
            item.is_available = checked ? true : false;
          }
          return item;
        })
      : [];
    setStudents(updatedStudentsList);
    setDisabled(false)
  };

  return (
    <>
      <CustomModal
        centered
        show={show}
        onEscapeKeyDown={closeModal}
      >
        <div className="modal__header">
          <div>
            <h2 className="modal__header-title">
            Assessment Student Availability
            </h2>
            <p className="modal__header-paragraph">
               {summativeId ? app.classroom.summativeName: app.classroom.formativeName}
            </p>
            <p className="text-gray mb-0">
              To enable a new attempt for a submitted student, close this and go
              to the Monitor screen.
            </p>
          </div>
        </div>
        <div className="modal__body">
          <div className="edit__availiblity-checks">
            <p>Available to all students</p>
            <label className="switch switch-small mb-0">
              <input
                className="switch-checkbox"
                type="checkbox"
                defaultChecked={availableAll}
                onChange={(e) => handleOnChangeAll(e.target.checked)}
              />
              <span className="switch-slider switch-slider-small"></span>
            </label>
          </div>
          <div className="edit__availiblity-search">
            <a href="/#">
              <img src={SearchIcon} alt="search" />
            </a>
            <input
              type="text"
              className="form-control"
              placeholder="Search Students"
              onChange={onSearchStudents}
            />
          </div>

          <div className="edit__availiblity-content">
            <ul className="edit__availiblity-list">
              {isLoading === false ? (
                <>
                  {students?.length ? (
                    <>
                      {students.map((x: any, i: number) => {
                        return (
                          <>
                            <li>
                              <div
                                className={`edit__availiblity-item ${
                                  i % 2 === 0 ? "" : "darker"
                                }`}
                              >
                                <h3>
                                  {x.last_name}, {x.first_name}
                                </h3>
                                <div className="d-flex align-items-center">
                                  {x.is_submitted === true && (
                                    <p className="edit__availiblity-submitted">
                                      Submitted
                                    </p>
                                  )}
                                  <label className={`switch switch-small mb-0 ${availableAll || x.is_submitted ? "disabled" : ''}`}>
                                    <input
                                      className="switch-checkbox"
                                      type="checkbox"
                                      disabled={availableAll || x.is_submitted === true}
                                      defaultChecked={
                                        x.is_available === true ? true : false
                                      }
                                      name={`availablity-${x.id}`}
                                      onChange={async (e: any) =>
                                        handleOnChange(e.target.checked, x.id)
                                      }
                                      checked={
                                        students.length
                                          ? !!students.find(
                                              (item: studentType) =>
                                                item.id === x.id &&
                                                item.is_available === true
                                            )
                                          : false
                                      }
                                    />
                                    <span className="switch-slider switch-slider-small"></span>
                                  </label>
                                </div>
                              </div>
                            </li>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <div className="loading">
                      <p>No Students(s) found </p>
                    </div>
                  )}
                </>
              ) : (
                <div className="loading">
                  <p style={{ textAlign: "center" }}>
                    {" "}
                    <Spinner animation="border" variant="light" />{" "}
                  </p>
                </div>
              )}
            </ul>
          </div>
        </div>
        <div className="modal__footer">
          <button
            type="button"
            className="btn cancel-btn"
            data-bs-dismiss="modal"
            onClick={() => setModalShow(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            disabled={isLoading || isSaving || disabled}
            className="btn success-btn"
            onClick={handleSave}
          >
            {isSaving ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Save"
            )}
          </button>
        </div>
      </CustomModal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default AvailabilityModal;
