import { memo } from "react"

const AssessmentHeader = ({ index, date, setSeheduleAssesment, setModalSuggestedAssesment, isSuperAdmin, noData }: any) => {

    return <tr className="PacingCalender__container__table__headrow overflow-hidden">
        <td
            className="text-white fw-bold  td1"
            onClick={() => setSeheduleAssesment(true)}
        >
            {/* {date} */}
            Week {(index+1)}
        </td>
        <td
            onClick={() => setModalSuggestedAssesment(true)}
            className="text-white fw-bold  td2"
            style={{opacity: noData ? 0.4:""}}
        >
            Assessment
        </td>
        <td
            className="text-white fw-bold  td3"
            style={{opacity: noData ? 0.4:""}}
        >
            Description
        </td>
        {/* <td className="text-white fw-bold  td4 d-flex align-items-center">
            Add/Remove
        </td> */}
    </tr>
}
export default memo(AssessmentHeader)