import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useGetChartData } from 'hooks/useGetChartData';
import { useGetDataValues } from 'hooks/useGetDataValues';
import AnalysisBody from '../AnalysisBox/body';
import AnalysisContainer from '../AnalysisBox/container';
import AnalysisFooter from '../AnalysisBox/footer';
import AnalysisHeader from '../AnalysisBox/header';

ChartJS.register(ArcElement, Tooltip, Legend);

const Remediation = ({remediation}: any) => {

    const {remediated, not_remediated, status, text, percentage} = remediation || {};

    const {dataValues, backgroundColor, isNotData} = useGetDataValues({
        firstDatasetValue: remediated, 
        secondDatasetValue: not_remediated,
        percentage
    })
    const ChartData = useGetChartData({dataValues, backgroundColor, isNotData});

    return (
        <>
            <AnalysisContainer>
                <AnalysisHeader status={status} title="Remediation" />
                
                <AnalysisBody 
                    ChartData={ChartData}
                    text={text}
                    percentage={percentage}
                    key={isNotData}
                    isImage={isNotData}
                />

                <AnalysisFooter 
                    firstLabel="Remediated"
                    secondLabel="Not Remediated"
                />
            </AnalysisContainer>
        </>
    )
};

export default Remediation;
