/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { QuestionnaireContext } from '../QuestionnaireContext'
import { ReactComponent as SaveIcon} from 'assets/img/save-icon.svg';
import CustomModal from "components/common/Modal";

const BackModal: FC<{}> = () => {
    const {
        onSave,
        showBackModal,
        setBackModal,
        updatedQuestions
    } = useContext(QuestionnaireContext)

    const navigate = useNavigate();

    const onSaveAndClose = () => {
        onSave();
        navigate('/cms/select-assessment');
    }

    const handlePress = (event :any) => {
        if(event.keyCode === 27){
            setBackModal(false);
        }
    }

    useEffect(() => {
        showBackModal && document.addEventListener('keydown', handlePress, false);

        return () => {
            document.removeEventListener('keydown', handlePress, false);
        }
    },[showBackModal])

    return (
        <CustomModal
            centered
            show={showBackModal}
            size="lg"
        >
            <div >
                <div className="modal__header">
                    <SaveIcon className="modal__header-icon" />
                    <h6 className="modal__header-title">Unsaved Changes</h6>
                </div>
                <div className="modal__body">
                    <p>
                        {`You've made changes to ${updatedQuestions + 1} question${updatedQuestions > 1 ? 's':''}. 
                        Are you sure you want to close? Your changes will be lost.`}
                    </p>
                </div>
                <div className="modal__footer">
                    <button
                        onClick={() => setBackModal(false)}
                        className="btn cancel-btn btn-lg light"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onSaveAndClose}
                        className="btn success-btn mx-3"
                    >
                        Save and Close
                    </button>
                    <button
                        onClick={() => navigate('/cms/select-assessment')}
                        className="btn danger-btn btn-sm"
                    >
                        Close
                    </button>
                </div>
            </div>
        </CustomModal>
    );
};

export default BackModal;