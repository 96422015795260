import { useEffect, useRef, useState } from "react";

const Tool = ({name, src, onClick}:{name: string, src: string, onClick: () => void}) => {

    const titleRef = useRef<any>(null);
    const [difference, setDifference] = useState<number>(0);
    const [isHover, setIsHover] = useState(false);

    useEffect(() => {
        if(name && titleRef.current){
            setDifference(titleRef.current?.scrollWidth - titleRef.current?.clientWidth);
        }
    },[name, titleRef]);

    return (
        <div 
            tabIndex={0} role="button"  
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onClick()
                }
            }} 
            onClick={onClick} 
            className="cursor-pointer tool-box"
        >
            <div className="tool-img">
                <img src={src} alt="tool-icon" />
            </div>
            <p
                ref={titleRef}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                className="tool-name"
                style={{ 
                    width: isHover ? titleRef.current?.scrollWidth : 170, 
                    ...(isHover && {left: -difference, overflow:"unset"})
                }}
            >
                {name}
            </p>
        </div>
    )
};

export default Tool;
