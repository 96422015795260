/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { Modal } from "react-bootstrap";

interface Props {
  setModal: Function;
}

const SuggestedPacingCopyModal: React.FC<Props> = (props) => {
  const {setModal} = props;
//   const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setModal(false);
  };

  // const onClickPreviewSuggestedPacing = () => {
  //   setModalSuggestedShow(false);
  //   setModalSuggestedAssesment(true);
  // }

  return (
    <>
      <Modal centered show contentClassName="PacingCalender__SuggestedModel">
        <div>
          <div>
            <h2 className="modal__header-title px-2">Suggested Pacing Copied to Calendar</h2>
          </div>
        </div>
        <div className="PacingCalender__SuggestedModel__text">
          <p className="test-sm  ps-2 mt-3">
            The A+ Suggested Pacing has been copied to your calendar. 
            You can make adjustments by deleting assessments from the calendar and adding them on different dates.
          </p>
          <p className="test-sm  ps-2  mt-3">
            Summative Assessments are not part of Suggested Pacing. 
            You can schedule these by clicking the Add button on the date for which you wish to schedule.
          </p>
        </div>
        <div className="modal__footer flex-wrap justify-content-end">
          <button
            type="button"
            className="btn mt-3 PacingCalender__SuggestedModel__Createbtn"
            data-bs-dismiss="modal"
            onClick={handleSave}
          >
            Got It
          </button>
        </div>
        
      </Modal>
      <div 
        className="modalBackdrop" 
        onClick={() => {}}
      ></div>
    </>
  );
};

export default SuggestedPacingCopyModal;
