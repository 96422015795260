import { Fragment, useContext } from "react";
import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment";
import Tool from "./tool";
import LoadingSkeleton from "./loadingSkeleton";
import { getTeamMeetingData } from "services/professionalDevelopment";
import { getPdfData } from "util/getPdfData";
import { triggerGoalWithClassroom } from "services/common";
import { PDFDocument } from "pdf-lib";
import { GOALS_CONDITIONS } from "util/constants";

const TOOLS_SKELETON = [1, 2, 3, 4, 5, 6];

const Tools = () => {
  const { pdClassroom } = useContext(ProfessionalDevelopmentContext);
  const { tools, loading } = pdClassroom;

  const onclickPdf = async (label: string) => {
    const classroomId = sessionStorage.getItem("c_classroom_id");
    if (!classroomId) {
      return;
    }

    const teamMeetingData = await getTeamMeetingData(parseInt(classroomId));
    if (!teamMeetingData) {
      console.log("team meeting data not found!");
      return;
    }

    const pdfTeamData = getPdfData(teamMeetingData);

    const url = "/Data_Team_Meeting_Worksheet_24-25.pdf";

    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    pdfTeamData.forEach((data: any) => {
      const { x, y, text, size } = data;
      firstPage.drawText(text, {
        x,
        y,
        size,
      });
    });

    const pdfBytes = await pdfDoc.save();
    const file = new Blob([pdfBytes], { type: "application/pdf" });

    const fileURL = URL.createObjectURL(file);
    openLinkInNewTab(fileURL);
    const alink = document.createElement("a");
    alink.href = fileURL;
    alink.target = "_blank";
    alink.download = `${label}` || "data_team_worksheet.pdf";
    alink.click();
    triggerGoalWithClassroom({
      classroomId: parseInt(classroomId),
      conditionName: GOALS_CONDITIONS.Generate_DTM_Worksheet,
    });
  };

  const openLinkInNewTab = (link: string) => {
    if (!link) {
      return;
    }

    window.open(link);
  };

  const toolTypeLinkHandler = (tool: any) => {
    const { tool_type, link, mastery_video_id, label } = tool;
    const classroomId = sessionStorage.getItem("c_classroom_id");

    switch (tool_type) {
      case "PD Resource":
        openLinkInNewTab(link);
        break;
      case "Way to A Report":
        openLinkInNewTab(`/classroom/report/way-to-report/${classroomId}`);
        break;
      case "Mastery Video Series":
        openLinkInNewTab(`/mastery-videos?videoId=${mastery_video_id}`);
        break;
      case "Data Team Meeting Worksheet":
        onclickPdf(label);
        break;
      default:
    }
  };

  const onClickLabel = (tool: any) => {
    const { type, link } = tool;
    switch (type) {
      case "URL":
      case "FILE":
        openLinkInNewTab(link);
        break;
      case "RESOURCE":
        toolTypeLinkHandler(tool);
        break;
      default:
        break;
    }
  };

  const copyOfCopiedTools = tools.filter((tool) => {
    return (
      tool.mastery_video_id === 1 ||
      tool.mastery_video_id === 2 ||
      tool.mastery_video_id === 3 ||
      tool.mastery_video_id === 4 ||
      tool.mastery_video_id === 5 ||
      tool.mastery_video_id === null
    );
  });
  return (
    <div className="tools-sec">
      <h3 className="secHeading">Tools</h3>
      <div className="tools-row">
        {loading
          ? TOOLS_SKELETON.map((id: number) => {
              return (
                <Fragment key={id}>
                  <LoadingSkeleton />
                </Fragment>
              );
            })
          : copyOfCopiedTools.length > 0
          ? copyOfCopiedTools.map((tool) => {
              const { id, label, tools_icon } = tool;
              return (
                <Fragment key={id}>
                  <Tool
                    src={tools_icon}
                    name={label}
                    onClick={() => onClickLabel(tool)}
                  />
                </Fragment>
              );
            })
          : TOOLS_SKELETON.map((id: number) => {
              return (
                <Fragment key={id}>
                  <LoadingSkeleton />
                </Fragment>
              );
            })}
        {/* <div className="tool-box">
                    <div className="tool-img">
                        <svg xmlns="http://www.w3.org/2000/svg" width="57" height="57" viewBox="0 0 57 57" fill="none">
                            <path d="M56.3411 2.25123C56.3411 1.19769 55.6571 0.683978 54.8135 1.10578L19.6858 18.6696C19.2279 18.9518 18.8481 19.3444 18.5812 19.8114C18.3143 20.2784 18.1689 20.8049 18.1582 21.3427V55.707C18.1582 56.7606 18.8422 57.2743 19.6858 56.8525L54.8144 39.2886C55.2719 39.0065 55.6513 38.6142 55.918 38.1476C56.1847 37.6809 56.3302 37.1549 56.3411 36.6175V2.25123Z" fill="#B9B9B9"/>
                            <path d="M18.7014 56.895L16.8545 55.9721C16.8332 55.9624 16.8123 55.9521 16.7916 55.9412C16.6038 55.8101 16.4544 55.6312 16.3589 55.423C16.2635 55.2148 16.2255 54.9848 16.2489 54.757V20.3878C16.2596 19.85 16.405 19.3235 16.6719 18.8565C16.9388 18.3895 17.3186 17.997 17.7765 17.7148L52.9051 0.15092C53.1345 0.0239874 53.4003 -0.0210513 53.6588 0.0232185L53.6733 0L55.7668 1.04677C55.6146 0.980492 55.4489 0.951283 55.2832 0.961542C55.1176 0.971802 54.9567 1.02124 54.8139 1.10578L19.6852 18.6716C19.2273 18.9537 18.8475 19.3463 18.5807 19.8133C18.3138 20.2803 18.1683 20.8068 18.1577 21.3446V55.708C18.1335 55.9365 18.1712 56.1672 18.2667 56.3762C18.3621 56.5851 18.5119 56.7647 18.7004 56.896" fill="#424242"/>
                            <path d="M54.8142 4.44064C54.8142 3.65411 54.3034 3.27004 53.6736 3.58543L20.8262 20.0087C20.4842 20.2193 20.2007 20.5125 20.0014 20.8611C19.8021 21.2098 19.6935 21.6029 19.6855 22.0045V47.6687C19.6855 48.4552 20.1964 48.8393 20.8262 48.5239L53.6736 32.1016C54.0154 31.8909 54.299 31.5978 54.4982 31.2491C54.6975 30.9004 54.8061 30.5073 54.8142 30.1058V4.44064Z" fill="#194792"/>
                            <path d="M54.4723 35.5292C54.4723 35.1567 54.2305 34.9748 53.9315 35.1238L26.5017 48.8382C26.3396 48.9381 26.2052 49.077 26.1107 49.2423C26.0162 49.4076 25.9647 49.594 25.9609 49.7844C25.9609 50.1568 26.2028 50.3387 26.5017 50.1897L53.9315 36.4753C54.0936 36.3755 54.2281 36.2365 54.3226 36.0712C54.4171 35.9059 54.4685 35.7195 54.4723 35.5292Z" fill="#394148"/>
                            <path d="M42.63 41.4518C42.63 41.0793 42.3882 40.8974 42.0892 41.0464L25.3748 49.4041C25.2127 49.504 25.0782 49.643 24.9837 49.8083C24.8892 49.9736 24.8378 50.1599 24.834 50.3503C24.834 50.7227 25.0758 50.9046 25.3748 50.7556L42.0892 42.3979C42.2513 42.2981 42.3858 42.1592 42.4803 41.9938C42.5748 41.8285 42.6262 41.6422 42.63 41.4518Z" fill="#EA3F4C"/>
                            <path d="M22.9051 50.0603L20.2988 51.3634V52.1645L22.9051 50.8613V50.0603Z" fill="#394148"/>
                            <path d="M41.7592 39.9426C42.5931 39.5256 43.2703 40.0577 43.2703 41.1296C43.2639 41.67 43.1222 42.2 42.8583 42.6715C42.5943 43.143 42.2164 43.5409 41.7592 43.8288C40.9252 44.2458 40.248 43.7137 40.248 42.6417C40.2545 42.1014 40.3961 41.5713 40.6601 41.0999C40.924 40.6284 41.3019 40.2305 41.7592 39.9426Z" fill="#EA3F4C"/>
                            <path d="M43.313 22.2038C43.468 22.2295 43.6133 22.296 43.7342 22.3962C43.8552 22.4965 43.9473 22.6271 44.0013 22.7746C44.0553 22.9221 44.0692 23.0813 44.0416 23.236C44.014 23.3907 43.9458 23.5352 43.8441 23.6549L35.0888 33.9272C34.9855 34.0444 34.8491 34.1276 34.6976 34.1658C34.5462 34.204 34.3867 34.1955 34.2401 34.1414C34.0936 34.0873 33.9668 33.9901 33.8766 33.8626C33.7863 33.7351 33.7367 33.5832 33.7344 33.427V21.9842C33.7349 21.8163 33.7718 21.6505 33.8425 21.4983C33.9133 21.346 34.0162 21.2109 34.1442 21.1023C34.2722 20.9937 34.4223 20.9142 34.584 20.8692C34.7458 20.8242 34.9153 20.8148 35.081 20.8416L43.3159 22.2038" fill="#46AFB8"/>
                            <path d="M0.665997 33.4645C0.784992 33.1104 0.878834 32.825 0.976545 32.5406C2.79726 27.2339 4.61798 21.9275 6.4387 16.6214C7.34132 14.0045 8.24201 11.3895 9.12141 8.7658C9.1705 8.59035 9.26324 8.43019 9.39096 8.30027C9.51869 8.17034 9.67724 8.07489 9.85182 8.0228C13.4023 6.78255 16.9512 5.54003 20.4985 4.29526C20.6368 4.24689 20.7887 4.22754 21.0112 4.17627C20.9687 4.89508 20.9445 5.55003 20.8884 6.20789C20.8139 7.07858 20.7104 7.94251 20.6271 8.8103C20.5033 10.1054 20.3837 11.4008 20.2682 12.6965C20.1947 13.5353 20.1444 14.3769 20.0689 15.2118C19.9557 16.4772 19.8261 17.7407 19.7129 19.0061C19.6394 19.8304 19.5881 20.6566 19.5136 21.4808C19.4024 22.7143 19.2766 23.9468 19.1634 25.1813C19.0899 25.9891 19.027 26.7988 18.9603 27.6076C18.8983 28.3555 18.848 29.1042 18.761 29.8492C18.7435 29.9281 18.7091 30.0022 18.6602 30.0666C18.6112 30.1309 18.5489 30.1838 18.4775 30.2216C17.3601 30.7218 16.233 31.1988 15.0189 31.7212L14.5275 25.1561C13.8319 25.4667 13.1943 25.7434 12.5626 26.0336C10.5155 26.973 8.47226 27.9201 6.42419 28.8556C6.04301 29.0202 5.73652 29.3205 5.56414 29.6983C5.12395 30.6096 4.62379 31.4919 4.18457 32.4071C4.12135 32.6156 3.99502 32.7995 3.82296 32.9332C3.65091 33.067 3.44161 33.1441 3.2239 33.1539C2.55734 33.1946 1.89948 33.3639 1.23582 33.4655C1.04545 33.4784 0.85443 33.4784 0.664062 33.4655M14.0273 12.6771H13.9305L9.15333 22.6785C10.9208 21.9771 12.5713 21.3396 14.2101 20.6663C14.2922 20.6085 14.3596 20.5323 14.4068 20.4437C14.454 20.3552 14.4798 20.2568 14.482 20.1564C14.4501 19.1754 14.3659 18.1964 14.3146 17.2154C14.245 15.8668 14.1908 14.5162 14.1211 13.1676C14.102 13.0023 14.0717 12.8384 14.0302 12.6771" fill="#FFEB66"/>
                            <path d="M29.3101 15.4537L32.0537 14.5956C31.957 14.9825 31.8951 15.2563 31.8187 15.5243C31.5758 16.3756 31.3494 17.2318 31.066 18.0696C30.9672 18.3165 30.786 18.5216 30.5532 18.6501C29.876 19.0119 29.1582 19.2857 28.4945 19.663C28.2365 19.8341 28.0455 20.089 27.9537 20.3847C27.6364 21.4034 27.3965 22.4463 27.083 23.466C27.0413 23.5891 26.9755 23.7027 26.8897 23.8003C26.8039 23.8979 26.6996 23.9776 26.5829 24.0349C25.6474 24.3976 24.6886 24.7014 23.6341 25.0623L24.0027 21.8059L20.8295 23.257C20.8061 23.1661 20.796 23.0722 20.7995 22.9784C21.1691 21.3899 21.527 19.7984 21.9353 18.2205C22.0379 17.9603 22.2372 17.7498 22.4916 17.6333C22.9263 17.4473 23.3754 17.2969 23.8344 17.1835C24.7564 16.9174 24.4864 17.0267 24.6577 16.1541C24.7834 15.5127 24.7863 14.8481 24.8647 14.195C24.8714 14.0035 24.9478 13.8211 25.0794 13.6818C25.211 13.5425 25.3888 13.456 25.5796 13.4385C26.8799 13.1831 28.1685 12.8725 29.462 12.5872C29.6061 12.5552 29.7522 12.5446 30.0144 12.5088L29.312 15.4498" fill="#FFEB66"/>
                        </svg>
                    </div>
                    <p className="tool-name">A+ Basics</p>
                </div>
                <div className="tool-box">
                    <div className="tool-img">
                        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="58" viewBox="0 0 41 58" fill="none">
                            <mask id="mask0_358_10548" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="58">
                                <rect width="40.713" height="57.571" fill="white"/>
                            </mask>
                            <g mask="url(#mask0_358_10548)">
                                <path d="M39.7654 0L12.7734 15.584V57.025L13.7204 57.572L38.9264 40.954L40.7124 0.547L39.7654 0Z" fill="#EADDD5"/>
                                <path d="M13.7168 16.1331V57.5721L40.7168 41.9851V0.547119L13.7168 16.1331Z" fill="#F3EBE8"/>
                                <path d="M15.1035 35.046L25.6745 28.946V17.115L15.1035 23.215L15.1035 35.046Z" fill="#00A8E2"/>
                                <mask id="mask1_358_10548" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="0" y="1" width="41" height="59">
                                <rect y="1.99902" width="40.712" height="57.571" fill="white"/>
                                </mask>
                                <g mask="url(#mask1_358_10548)">
                                <g opacity="0.1">
                                    <mask id="mask2_358_10548" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="12" y="26" width="18" height="27">
                                    <rect x="12.7578" y="26.1641" width="16.914" height="26.504" fill="white"/>
                                    </mask>
                                    <g mask="url(#mask2_358_10548)">
                                    <path d="M26.9599 28.216H26.9769L26.8619 28.154C26.8234 28.1282 26.7833 28.1048 26.7419 28.084C26.0789 27.71 24.2149 26.66 23.9739 26.556C22.2639 25.836 20.0319 26.09 17.5929 27.499C15.7333 28.6079 14.0967 30.0535 12.7669 31.762L12.7559 51.845C12.8424 51.8907 12.92 51.9517 12.9849 52.025L13.0089 52.017C14.7749 53.037 17.2089 52.891 19.9089 51.335C25.2979 48.222 29.6659 40.655 29.6659 34.435C29.6659 31.414 28.6329 29.274 26.9569 28.218" fill="black"/>
                                    </g>
                                </g>
                                </g>
                                <path d="M28.6641 35.304V40.334L39.5641 34.043V29.009L28.6641 35.304Z" fill="#00A8E2"/>
                                <path d="M28.6641 15.3901V17.0231L39.5641 10.7341V9.09814L28.6641 15.3901Z" fill="#686868"/>
                                <path d="M28.6641 20.257L32.4291 18.085V16.448L28.6641 18.62V20.257Z" fill="#686868"/>
                                <path d="M33.6465 15.7431V17.3801L39.5635 13.9621V12.3311L33.6465 15.7431Z" fill="#686868"/>
                                <path d="M36.2109 17.6159V19.2509L39.5629 17.3189V15.6799L36.2109 17.6159Z" fill="#F74733"/>
                                <path d="M28.6641 23.6069L34.8911 20.0149V18.3779L28.6641 21.9699V23.6069Z" fill="#686868"/>
                                <path d="M28.6641 25.203V26.84L39.5641 20.546V18.915L28.6641 25.203Z" fill="#FF311D"/>
                                <path d="M35.0977 25.1271V26.6681L39.5637 24.0881V22.5471L35.0977 25.1271Z" fill="#686868"/>
                                <path d="M28.6641 30.3819L32.6901 28.0599V26.5129L28.6641 28.8409V30.3819Z" fill="#686868"/>
                                <path d="M28.6641 32.2091V33.7521L39.5641 27.4611V25.9141L28.6641 32.2091Z" fill="#686868"/>
                                <path d="M15.1035 38.2129L25.6745 32.1129V30.5659L15.1035 36.6659L15.1035 38.2129Z" fill="#686868"/>
                                <path d="M22.6348 37.1891L25.6728 35.4341V33.7981L22.6348 35.5531V37.1891Z" fill="#F74733"/>
                                <path d="M15.1035 41.538L21.4955 37.846V36.209L15.1035 39.901V41.538Z" fill="#F74733"/>
                                <path d="M15.1035 44.77L25.6745 38.67V37.031L15.1035 43.131L15.1035 44.77Z" fill="#F74733"/>
                                <path d="M21.5488 44.5881L25.6728 42.2091V40.6641L21.5488 43.0461V44.5881Z" fill="#F74733"/>
                                <path d="M15.1035 48.312L19.3035 45.886V44.342L15.1035 46.768V48.312Z" fill="#F74733"/>
                                <path d="M28.6641 42.0219V43.6589L39.5641 37.3649V35.7329L28.6641 42.0219Z" fill="#686868"/>
                                <path d="M28.6641 46.8911L32.4331 44.7151V43.0791L28.6641 45.2511V46.8911Z" fill="#686868"/>
                                <path d="M33.6523 42.3729V44.0099L39.5633 40.5979V38.9609L33.6523 42.3729Z" fill="#686868"/>
                                <path d="M15.1035 20.8581L28.0965 13.3581V9.90405L15.1035 17.4041L15.1035 20.8581Z" fill="#686868"/>
                                <path d="M32.4082 6.41303V9.87003L39.5642 5.73403V2.28003L32.4082 6.41303Z" fill="#686868"/>
                                <mask id="mask3_358_10548" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="0" y="-1" width="41" height="59">
                                <rect y="-0.000976562" width="40.712" height="57.571" fill="white"/>
                                </mask>
                                <g mask="url(#mask3_358_10548)">
                                <path d="M22.6461 28.5761C17.4301 31.5861 13.2031 38.9101 13.2031 44.9321C13.2031 50.9541 17.4311 53.3951 22.6461 50.3811C27.8611 47.3671 32.0871 40.0471 32.0871 34.0251C32.0871 28.0031 27.8601 25.5631 22.6461 28.5761Z" fill="white"/>
                                <path d="M25.2332 26.62C19.8932 26.836 16.7082 33.549 13.0332 36.653V47.423C13.7564 48.7202 14.8973 49.7345 16.2702 50.301C16.7157 50.4868 17.1149 50.7685 17.4392 51.126C19.5107 50.6285 21.5366 49.9571 23.4952 49.119C26.2312 47.98 28.0952 45.136 29.5812 42.667C30.7777 40.7429 31.434 38.5322 31.4812 36.267C30.8202 32.975 29.5742 26.45 25.2392 26.623" fill="#F2EAE5"/>
                                <path d="M25.2324 26.6199C20.4804 26.8119 17.4324 32.1529 14.2324 35.5149V48.9619C14.8203 49.5351 15.5129 49.99 16.2724 50.3019C16.718 50.4877 17.1171 50.7693 17.4414 51.1269C19.5129 50.6293 21.5388 49.958 23.4974 49.1199C26.2334 47.9809 28.0974 45.1369 29.5834 42.6679C30.7799 40.7438 31.4362 38.5331 31.4834 36.2679C30.8224 32.9759 29.5764 26.4509 25.2414 26.6239" fill="white"/>
                                <path d="M25.2325 26.6199C21.3485 26.7779 18.6035 30.3739 15.9785 33.5199V37.6289L29.3485 29.9119V29.3949C28.4255 27.7469 27.1235 26.5469 25.2315 26.6199" fill="#00A8E2"/>
                                <path d="M15.9785 41.636L29.3485 33.916V31.967L15.9785 39.684V41.636Z" fill="#959595"/>
                                <path d="M25.5078 40.3409L29.3498 38.1239V36.0559L25.5078 38.2659V40.3409Z" fill="#F74733"/>
                                <path d="M15.9785 45.842L24.0655 41.175V39.104L15.9785 43.775V45.842Z" fill="#F74733"/>
                                <path d="M15.9785 49.9301L29.3485 42.2131V40.1431L15.9785 47.8631V49.9301Z" fill="#F74733"/>
                                <path d="M24.1328 48.8101C25.4355 48.0564 26.5684 47.0419 27.4608 45.8301L24.1328 47.7501V48.8101Z" fill="#F74733"/>
                                <path d="M21.2935 49.9709V49.3899L18.9785 50.7259C19.7695 50.4989 20.5395 50.2389 21.2895 49.9709" fill="#F74733"/>
                                <path d="M20.3273 26.543C14.9363 29.654 10.5703 37.223 10.5703 43.443C10.5703 46.226 11.4383 48.266 12.8853 49.395C13.0393 49.517 15.7183 51.069 15.7183 51.069C16.5841 50.8423 17.427 50.536 18.2363 50.154C10.1443 44.932 16.4233 31.779 25.8103 28.01L29.7103 27.258C29.7103 27.258 27.0013 25.723 26.7103 25.599C24.9983 24.879 22.7703 25.133 20.3293 26.542" fill="#686868"/>
                                </g>
                                <mask id="mask4_358_10548" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="12" y="26" width="21" height="26">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4268 43.2769C15.707 38.2024 18.3861 33.5643 22.6418 30.7859C26.6198 28.4859 29.8528 30.3559 29.8528 34.9519C29.5751 40.025 26.899 44.6628 22.6458 47.4419C21.6784 48.0449 20.5699 48.3839 19.4308 48.4249C17.0618 48.4249 15.4308 46.5369 15.4308 43.2769M22.6458 27.8469C17.2548 30.9579 12.8848 38.5249 12.8848 44.7469C12.8848 50.9689 17.2548 53.4909 22.6458 50.3799C28.0368 47.2689 32.4048 39.7019 32.4048 33.4799C32.4048 29.0649 30.2048 26.5129 27.0048 26.5129C25.4601 26.5688 23.9571 27.0288 22.6458 27.8469Z" fill="white"/>
                                </mask>
                                <g mask="url(#mask4_358_10548)">
                                <rect x="0.880859" y="46.1602" width="33.201" height="30.723" transform="rotate(-58.579 0.880859 46.1602)" fill="url(#paint0_linear_358_10548)"/>
                                </g>
                                <mask id="mask5_358_10548" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="6" y="46" width="8" height="7">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5029 46.8951L6.91287 49.6221C6.70821 49.7733 6.60176 50.0237 6.63487 50.2761C6.68795 50.9817 7.07472 51.6197 7.67587 51.9931C7.886 52.138 8.15766 52.1594 8.38787 52.0491L12.9759 49.3231C13.1813 49.1723 13.2885 48.9218 13.2559 48.6691C13.2019 47.9639 12.8158 47.3265 12.2159 46.9521C12.0826 46.8721 11.9312 46.8273 11.7759 46.8221C11.6805 46.8209 11.5867 46.8462 11.5049 46.8951" fill="white"/>
                                </mask>
                                <g mask="url(#mask5_358_10548)">
                                <rect x="4.15039" y="48.5022" width="8.48304" height="8.13708" transform="rotate(-34.1986 4.15039 48.5022)" fill="url(#paint1_linear_358_10548)"/>
                                </g>
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_358_10548" x1="-5.16172" y1="79.9401" x2="-3.26927" y2="79.9401" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#939393"/>
                                <stop offset="0.241" stop-color="#939393"/>
                                <stop offset="0.684" stop-color="#BEBEBE"/>
                                <stop offset="1" stop-color="#BEBEBE"/>
                                </linearGradient>
                                <linearGradient id="paint1_linear_358_10548" x1="-1.55021" y1="61.5581" x2="0.324541" y2="61.5581" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#B6B6B6"/>
                                <stop offset="0.241" stop-color="#B6B6B6"/>
                                <stop offset="0.684" stop-color="#D6D6D6"/>
                                <stop offset="1" stop-color="#D6D6D6"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </div>
                    <p className="tool-name">October Newsletter</p>
                </div>
                <div className="tool-box">
                    <div className="tool-img">
                        <svg xmlns="http://www.w3.org/2000/svg" width="78" height="51" viewBox="0 0 78 51" fill="none">
                            <path d="M36.4145 5.24727L76.3118 28.2799L76.1451 31.4595C76.1237 31.6041 76.0641 31.7404 75.9726 31.8544C75.8811 31.9683 75.761 32.0559 75.6245 32.1082L43.8196 48.3158C43.4472 48.4799 43.0415 48.5543 42.6351 48.533C42.2287 48.5117 41.833 48.3953 41.4798 48.1932L2.19729 22.6794C2.0512 22.5881 1.93492 22.4562 1.86272 22.2998C1.79051 22.1434 1.76553 21.9693 1.79082 21.7989L1.91807 18.7784L34.0767 5.12475C34.4494 4.96034 34.8555 4.88579 35.2622 4.90711C35.669 4.92842 36.0651 5.04501 36.4185 5.24747" fill="#424242"/>
                            <path d="M2.43523 18.1305L34.2411 1.92186C34.6135 1.75777 35.0192 1.68338 35.4256 1.70468C35.832 1.72597 36.2277 1.84237 36.5809 2.04449L75.8654 27.5594C76.0043 27.614 76.1225 27.7108 76.2033 27.8363C76.2841 27.9618 76.3233 28.1094 76.3155 28.2585C76.3077 28.4075 76.2532 28.5503 76.1598 28.6666C76.0663 28.7829 75.9386 28.8669 75.7948 28.9066L43.9939 45.1145C43.6216 45.2786 43.2158 45.353 42.8095 45.3317C42.4031 45.3104 42.0074 45.194 41.6542 44.9919L2.36462 19.4776C2.22563 19.4228 2.10739 19.3256 2.0266 19.1999C1.94581 19.0742 1.90655 18.9263 1.91437 18.7771C1.92219 18.6279 1.97669 18.4849 2.07018 18.3683C2.16367 18.2518 2.29142 18.1675 2.43538 18.1275" fill="#373C44"/>
                            <path d="M72.7354 28.9455L42.9588 44.1139L5.49946 19.7901L5.60156 17.8418L72.8336 26.997L72.7354 28.9455Z" fill="#FFE7BD"/>
                            <path d="M72.8382 26.9982L43.0613 42.1716L5.60224 17.8428L35.3769 2.67427L72.8382 26.9982Z" fill="#FFFBF4"/>
                            <path d="M39.621 8.17496L28.0605 14.072L38.9455 21.1404L50.5097 15.2485L39.621 8.17496ZM30.073 14.1725L39.5601 9.33637L48.4927 15.1378L39.0029 19.9688L30.073 14.1725Z" fill="#194792"/>
                            <path d="M29.0548 17.8943L17.3044 23.8805L14.8835 22.3056L26.6319 16.3193L29.0548 17.8943Z" fill="#194792"/>
                            <path d="M6.98693 12.7094L26.5552 4.27397C26.9026 4.09987 27.2857 4.00873 27.6742 4.00772C28.0628 4.00671 28.4463 4.09586 28.7946 4.26816L33.3174 5.51758C33.2439 6.67732 33.2099 7.32677 33.2153 7.46591C33.2247 7.74478 33.0164 8.03127 32.6058 8.24307L13.6091 17.9231C13.2395 18.1013 12.8333 18.1906 12.4229 18.1838C12.0126 18.1771 11.6096 18.0746 11.2459 17.8844L7.21125 15.2697C7.10322 15.2141 7.01432 15.1274 6.956 15.0208C6.89769 14.9143 6.87265 14.7927 6.88409 14.6717C6.90536 14.5079 6.93957 13.8552 6.98672 12.7134" fill="#222222"/>
                            <path d="M12.3615 6.09965C13.5532 5.57315 14.8521 5.33435 16.1532 5.40254C17.4542 5.47072 18.721 5.84398 19.8512 6.49217C21.8579 7.79297 21.7562 9.73231 19.6243 10.8212C18.4326 11.3477 17.1337 11.5865 15.8326 11.5183C14.5316 11.4501 13.2648 11.0769 12.1346 10.4287C11.7167 10.2162 11.3632 9.89589 11.1105 9.50099C10.8578 9.10609 10.7151 8.65089 10.6972 8.1824L10.7792 6.61755C11.7024 6.33815 12.2305 6.16555 12.3635 6.09975" fill="#222222"/>
                            <path d="M56.1269 18.9015L44.5628 24.7913L55.4478 31.8597L67.012 25.9679L56.1269 18.9015ZM56.0597 20.1837L64.7891 25.8514L55.516 30.5775L46.7878 24.9079L56.0597 20.1837Z" fill="#194792"/>
                            <path d="M12.4502 4.44586C13.6413 3.91701 14.9405 3.67691 16.242 3.74512C17.5435 3.81333 18.8105 4.18791 19.9399 4.83838C21.9466 6.13918 21.8449 8.07852 19.713 9.16744C18.5213 9.69393 17.2224 9.93273 15.9213 9.86455C14.6203 9.79636 13.3535 9.4231 12.2233 8.77492C10.2169 7.46812 10.3185 5.52978 12.4502 4.44586Z" fill="#B9B9B9"/>
                            <path d="M44.2062 27.7339L32.4508 33.7199L30.0299 32.145L41.7803 26.1588L44.2062 27.7339Z" fill="#194792"/>
                            <path d="M56.1145 26.7336L53.1902 26.5873L53.1376 28.3169L58.0781 28.4937L57.926 21.8417L55.9997 21.8549L56.1145 26.7336Z" fill="#46AFB8"/>
                            <path d="M39.634 16.0004L36.7097 15.8542L36.6571 17.5838L41.5976 17.7606L41.4455 11.1085L39.5192 11.1217L39.634 16.0004Z" fill="#46AFB8"/>
                            <path d="M33.4343 20.7385L24.7477 25.1639L22.3269 23.587L31.0135 19.1616L33.4343 20.7385Z" fill="#194792"/>
                            <path d="M48.5879 30.578L40.1657 34.8701L37.7448 33.2952L46.167 29.0041L48.5879 30.578Z" fill="#194792"/>
                            <path d="M7.59869 12.0223L26.6583 2.30645C27.0054 2.13144 27.3886 2.03979 27.7773 2.03879C28.166 2.03778 28.5497 2.12744 28.8977 2.30064L32.929 4.92122C33.5187 5.30561 33.422 5.91037 32.7079 6.2755L13.7123 15.9546C13.3434 16.1339 12.9376 16.2242 12.5274 16.2182C12.1173 16.2122 11.7143 16.1101 11.3508 15.92L7.31658 13.2972C6.77819 12.9466 6.90226 12.3753 7.59869 12.0223Z" fill="#B9B9B9"/>
                        </svg>
                    </div>
                    <p className="tool-name">Data Team Meeting</p>
                </div>
                <div className="tool-box">
                    <div className="tool-img">
                        <img src={calendar} alt="Calendar"/>
                    </div>
                    <p className="tool-name">Way to an A Report</p>
                </div> */}
      </div>
    </div>
  );
};

export default Tools;
