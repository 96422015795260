const ClipPathGroupSvg = () => (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_940_5860" /* style={`mask-type:luminance`} */ maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="18">
            <path d="M13.4354 0.783203H0.523438V17.2172H13.4354V0.783203Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_940_5860)">
            <path d="M12.7848 17.2172H2.28477C2.11246 17.2169 1.94728 17.1484 1.82544 17.0265C1.7036 16.9047 1.63503 16.7395 1.63477 16.5672V1.4332C1.63503 1.26089 1.7036 1.09572 1.82544 0.973876C1.94728 0.852034 2.11246 0.783468 2.28477 0.783203H10.9958L13.4368 3.2242V16.5662C13.4365 16.7385 13.3679 16.9037 13.2461 17.0255C13.1243 17.1474 12.9591 17.2159 12.7868 17.2162" fill="#F5F5F5"/>
            <path d="M0.523438 6.98535L1.79244 8.11134L1.87744 6.98535H0.523438Z" fill="#214AA5"/>
            <path d="M12.7848 17.2172H2.28477C2.11246 17.2169 1.94728 17.1484 1.82544 17.0265C1.7036 16.9047 1.63503 16.7395 1.63477 16.5672V1.4332C1.63503 1.26089 1.7036 1.09572 1.82544 0.973876C1.94728 0.852034 2.11246 0.783468 2.28477 0.783203H10.9958L13.4368 3.2242V16.5662C13.4365 16.7385 13.3679 16.9037 13.2461 17.0255C13.1243 17.1474 12.9591 17.2159 12.7868 17.2162M2.28376 1.1002C2.19545 1.1002 2.11075 1.13529 2.0483 1.19774C1.98585 1.26019 1.95076 1.34489 1.95076 1.4332V16.5662C1.95076 16.6545 1.98585 16.7392 2.0483 16.8017C2.11075 16.8641 2.19545 16.8992 2.28376 16.8992H12.7838C12.8721 16.8992 12.9568 16.8641 13.0192 16.8017C13.0817 16.7392 13.1168 16.6545 13.1168 16.5662V3.3552L10.8638 1.1002H2.28376Z" fill="#C9C9C9"/>
            <path d="M10.9619 2.59163C10.9619 2.6875 10.9808 2.78242 11.0175 2.87099C11.0542 2.95956 11.108 3.04003 11.1758 3.10782C11.2435 3.17561 11.324 3.22938 11.4126 3.26606C11.5011 3.30275 11.5961 3.32163 11.6919 3.32163H13.3099L10.9619 0.973633V2.59163Z" fill="#C9C9C9"/>
            <path d="M9.78744 3.09961H0.523438V6.98561H9.78744V3.09961Z" fill="#2D80B4"/>
            <path d="M2.92188 4.21582H3.48888C3.85088 4.21582 4.14488 4.34782 4.14488 4.74782C4.14488 5.14782 3.84488 5.30782 3.49888 5.30782H3.29388V5.86782H2.92188V4.21582ZM3.47688 5.01582C3.68188 5.01582 3.77688 4.92482 3.77688 4.75282C3.77688 4.58082 3.66588 4.51682 3.46288 4.51682H3.29088V5.01682L3.47688 5.01582Z" fill="#F5F5F5"/>
            <path d="M4.43066 4.21582H4.81267L5.24367 5.03682L5.40567 5.40182H5.41566C5.40066 5.22682 5.36966 4.99382 5.36966 4.80182V4.21882H5.72467V5.86982H5.34267L4.91167 5.04682L4.74966 4.68682H4.73967C4.75467 4.86882 4.78566 5.08682 4.78566 5.28682V5.87182H4.43066V4.21582Z" fill="#F5F5F5"/>
            <path d="M6.03982 5.05257C6.03063 4.94168 6.04469 4.83009 6.08108 4.72494C6.11747 4.61978 6.17539 4.52336 6.25115 4.44186C6.32691 4.36036 6.41884 4.29557 6.52107 4.25161C6.62329 4.20765 6.73356 4.1855 6.84483 4.18658C6.94139 4.1854 7.03718 4.20385 7.1264 4.24081C7.21561 4.27776 7.29638 4.33246 7.36382 4.40157L7.16383 4.63958C7.12319 4.59796 7.07466 4.56485 7.0211 4.54219C6.96753 4.51953 6.91 4.50776 6.85183 4.50758C6.59583 4.50758 6.41583 4.70757 6.41583 5.03957C6.41583 5.37157 6.56982 5.57658 6.87882 5.57658C6.94176 5.57897 7.00381 5.56109 7.05583 5.52557V5.24158H6.78182V4.94157H7.38183V5.70158C7.23161 5.83413 7.03713 5.9055 6.83683 5.90157C6.72601 5.90694 6.61534 5.88843 6.51231 5.84727C6.40927 5.80612 6.31629 5.7433 6.23966 5.66307C6.16303 5.58284 6.10455 5.48707 6.06817 5.38226C6.03179 5.27744 6.01838 5.16603 6.02883 5.05558" fill="#F5F5F5"/>
            <path d="M7.10884 8.80957H6.25684V9.66157H7.10884V8.80957Z" fill="#C9C9C9"/>
            <path d="M8.81196 8.80957H7.95996V9.66157H8.81196V8.80957Z" fill="#C9C9C9"/>
            <path d="M10.5161 8.80957H9.66406V9.66157H10.5161V8.80957Z" fill="#C9C9C9"/>
            <path d="M6.2563 9.66113H5.4043V10.5131H6.2563V9.66113Z" fill="#C9C9C9"/>
            <path d="M7.9604 9.66113H7.1084V10.5131H7.9604V9.66113Z" fill="#C9C9C9"/>
            <path d="M9.6645 9.66113H8.8125V10.5131H9.6645V9.66113Z" fill="#C9C9C9"/>
            <path d="M7.10884 10.5146H6.25684V11.3666H7.10884V10.5146Z" fill="#C9C9C9"/>
            <path d="M8.81196 10.5146H7.95996V11.3666H8.81196V10.5146Z" fill="#C9C9C9"/>
            <path d="M10.5161 10.5146H9.66406V11.3666H10.5161V10.5146Z" fill="#C9C9C9"/>
            <path d="M6.2563 11.3662H5.4043V12.2182H6.2563V11.3662Z" fill="#C9C9C9"/>
            <path d="M7.9604 11.3662H7.1084V12.2182H7.9604V11.3662Z" fill="#C9C9C9"/>
            <path d="M9.6645 11.3662H8.8125V12.2182H9.6645V11.3662Z" fill="#C9C9C9"/>
            <path d="M7.10884 12.2178H6.25684V13.0698H7.10884V12.2178Z" fill="#C9C9C9"/>
            <path d="M8.81196 12.2178H7.95996V13.0698H8.81196V12.2178Z" fill="#C9C9C9"/>
            <path d="M10.5161 12.2178H9.66406V13.0698H10.5161V12.2178Z" fill="#C9C9C9"/>
            <path d="M6.2563 13.0703H5.4043V13.9223H6.2563V13.0703Z" fill="#C9C9C9"/>
            <path d="M7.9604 13.0703H7.1084V13.9223H7.9604V13.0703Z" fill="#C9C9C9"/>
            <path d="M9.6645 13.0703H8.8125V13.9223H9.6645V13.0703Z" fill="#C9C9C9"/>
            <path d="M7.10884 13.9219H6.25684V14.7739H7.10884V13.9219Z" fill="#C9C9C9"/>
            <path d="M8.81196 13.9219H7.95996V14.7739H8.81196V13.9219Z" fill="#C9C9C9"/>
            <path d="M10.5161 13.9219H9.66406V14.7739H10.5161V13.9219Z" fill="#C9C9C9"/>
            <path d="M5.40473 8.80957H4.55273V9.66157H5.40473V8.80957Z" fill="#C9C9C9"/>
            <path d="M5.40473 10.5146H4.55273V11.3666H5.40473V10.5146Z" fill="#C9C9C9"/>
            <path d="M5.40473 12.2178H4.55273V13.0698H5.40473V12.2178Z" fill="#C9C9C9"/>
            <path d="M5.40473 13.9219H4.55273V14.7739H5.40473V13.9219Z" fill="#C9C9C9"/>
        </g>
    </svg>
)
export default ClipPathGroupSvg;