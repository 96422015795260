import TaskTitle from "components/professionalDevelopment/goals/taskTitle";
import { useState } from "react"
import Checked from "../svgs/checked";
import Unchecked from "../svgs/unchecked";

const GoalsList = ({onClickTask, tasks}:any) => {
    const [scrollValue, setScrollValue] = useState(0);

    const goalsClass = tasks.length === 2 ? 'two-goals' : tasks.length > 2 ? 'three-goals' : '';
    return (
        <>
            <div onScroll={(e: any) => setScrollValue(e.target.scrollTop)} className={`goals-checkboxs-container ${goalsClass}`}>
                <div>
                    {tasks?.map((item: any) => {
                        const {task, task_action_name, id, checked} = item;
                        return (
                            <>
                                {task_action_name !== 'Open Mastery Video' || id === 1 || id === 2 || id === 3  || id === 4 || id === 5 ? (
                                    <div role="button" tabIndex={0} onKeyDown={ (e:any) => { 
                                        if(e.key === 'Enter') {
                                            e.stopPropagation();
                                            onClickTask(item)
                                        }
                                    } }  
                                    onClick={() => onClickTask(item)} className="cursor-pointer goalCheckBox">
                                        {checked ? (
                                            <Checked />
                                        ):(
                                            <Unchecked />
                                        )}
                                        
                                        <TaskTitle name={task} />
                                    </div>
                                ):(
                                    <></>
                                )}

                                {![1, 2, 3, 4, 5].includes(id) && task_action_name === 'Open Mastery Video' ? (
                                    <div className="goalCheckBox">
                                        {/* <div className={"coming-soon"}></div>
                                        <div className={"coming-soon-text"}>Coming Soon</div> */}
                                        {checked ? (
                                            <Checked />
                                        ):(
                                            <Unchecked />
                                        )}
                                        
                                        <TaskTitle name={task} />
                                    </div>
                                ):(
                                    <></>
                                )}
                                
                            </>
                        )
                    })}
                </div>
            </div>
            {tasks.length >= 3 && scrollValue < 5 && <div className="more-data"></div>}
        </>
    )
};

export default GoalsList;
