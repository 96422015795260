const NumberedLabel = ({number}: {number: number}) => {
    return (
        <span className="box-number">
            <span className="">
                {number}
            </span>
        </span>
    )
};

export default NumberedLabel;
