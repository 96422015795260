import PdfIcon from "components/common/svgs/pdfIcon";
import { ChangeEvent, useState } from "react"
import { uploadManagementResource } from "services/common";

interface IProps {
    fileNo: number;
    heading: string;
    teacherField: string;
    studentField: string;
    onClickPDF: (name: string, url: string) => void;
    isTeacherPdfUploaded: boolean;
    isStudentPdfUploaded: boolean;
    isUpload: boolean;
}

const PracticeFileUpload = ({isUpload, fileNo, isTeacherPdfUploaded, isStudentPdfUploaded, heading, onClickPDF, teacherField, studentField}:IProps) => {

    const [studentInputFocused, setStudentInputFocus] = useState(false);
    const [teacherInputFocused, setTeacherInputFocused] = useState(false); 

    const onUploadFile = async (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files ? e.target.files[0] : null;

        if(!file || file.type !== "application/pdf") return;

        const formData = new FormData();
        formData.append("file", file);
        const res = await uploadManagementResource(formData);
        if(res?.data?.url){
            onClickPDF(e.target.name, res?.data?.url);
        }
    }
    return (
        <div className="upload-file">
            <div className={`fileNumber ${(isTeacherPdfUploaded && isStudentPdfUploaded) ? 'file-uploaded' : ''}`}>
                {fileNo}
            </div>
            <div className="file-upload-detail">
                <p>{heading}</p>
                <div className="files-upload">
                    <div
                    style={{outline: isUpload && teacherInputFocused ? "1px solid #fff":""}}
                    tabIndex={isUpload ? -1 : 0}
                    onClick={() =>  !isUpload && onClickPDF(teacherField, "")}
                    onKeyDown={(e)=>{
                        if(e.key==="Enter"){
                            if(!isUpload)
                                onClickPDF(teacherField, "")
                           
                        }
                    }} 
                    className={`file-pdf ${!isUpload ? 'cursor-pointer' : ''}`}>
                        {isUpload && (
                            <input 
                            tabIndex={isUpload? 0: -1}   
                            name={teacherField}
                            type="file"
                            onFocus={()=>setTeacherInputFocused(true)} 
                            onBlur={()=>setTeacherInputFocused(false)}   
                            onChange={onUploadFile}  />
                        )}
                        <PdfIcon isDisabled={!isTeacherPdfUploaded} />
                        <span>Teacher PDF</span>
                    </div>
                    <div 
                    
                    style={{outline: isUpload && studentInputFocused ? "1px solid #fff":""}}
                    tabIndex={isUpload ? -1: 0}
                    onClick={() => !isUpload && onClickPDF(studentField, "")} 
                    onKeyDown={(e)=>{
                        if(e.key==="Enter"){
                            if(!isUpload)
                                onClickPDF(studentField, "")
                        }
                    }}
                    className={`file-pdf ${!isUpload ? 'cursor-pointer' : ''}`}>
                        {isUpload && (
                            <input  
                            tabIndex={isUpload? 0: -1} 
                            name={studentField} 
                            type="file"
                            onFocus={()=>setStudentInputFocus(true)}
                            onBlur={()=>setStudentInputFocus(false)}
                            onChange={onUploadFile}  />
                        )}
                        <PdfIcon isDisabled={!isStudentPdfUploaded} />
                        <span>Student PDF</span>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default PracticeFileUpload;
