import ClassroomTooltip from "components/common/classroomTooltip";
import HtmlParser from "components/common/htmlParser";
import { ClassroomTooltipContext } from "contexts/classroomTooltip";
import jwtDecode from "jwt-decode";
import { FC, useCallback, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "redux/reducers/combine";

interface Props{
    week_text: string;
    loading: boolean;
    pdClassroom: any
}

const Week: FC<Props> = (props) => {

    const { week_text, loading, pdClassroom } = props
    const isText = !!week_text;
    const auth = useSelector((state: RootState) => state.auth);

    const {tooltip, showNoGoalTooltip, setShowNoGoalTooltip, showNoScheduledTooltip} = useContext(ClassroomTooltipContext);
    const {pathname} = useLocation();

    const onClickHandler = useCallback(() => {
        setShowNoGoalTooltip(false);
        sessionStorage.setItem("goalTooltipSeen", "true");
        document.body.removeEventListener('click', onClickHandler, true)
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    useEffect(() => {
        if(showNoGoalTooltip === false){
            document.body.removeEventListener('click', onClickHandler, true)
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[showNoGoalTooltip]);

    useEffect(() => {
        const token = auth?.currentUser?.token;
        const decodedToken: any = jwtDecode(token);
        const isForgotPassword = decodedToken?.forgot_password;

        const isGoalTooltipSeen = sessionStorage.getItem("goalTooltipSeen");

        if(loading ||
          !auth.colorProfile ||
          (auth.colorProfile && auth.colorProfile.tooltips_seen === false) ||
          (pdClassroom && pdClassroom.show_no_goal_tooltip === false) ||
          isForgotPassword ||
          (showNoScheduledTooltip !== false) ||
          isGoalTooltipSeen
        ){
          return;
        }
        
        setShowNoGoalTooltip(true);
        document.body.addEventListener('click', onClickHandler, true);
    
        return () => {
          document.body.removeEventListener('click', onClickHandler, true)
        }
    
        /* eslint-disable react-hooks/exhaustive-deps */
    },[showNoScheduledTooltip]);

    const isShowTootip = pathname === '/classroom' && (tooltip === 3 || showNoGoalTooltip);
    return <div className="weekSec">
        {isShowTootip && (
            <ClassroomTooltip 
                arrowDirection="right" 
                className="ProfessionalDev__devSummary__toolTip goals-tour-step" 
                message="Complete these goals to stay <br /> on top of tasks that will lead <br /> you to success." 
            />
        )}
        <p className="weekHeader">This Week</p> 
        { loading ? 
            <div className="week-skeleton">
                    <div className="no-data-bg" style={{width: "100%",  maxWidth: "404px", height: "22px",borderRadius: "4px", marginTop: "-2px", marginBottom:"2px" }}>
                        <span className="skeleton"></span>
                    </div>
                    <div className="no-data-bg" style={{width: "100%",  maxWidth: "392px", height: "22px",borderRadius: "4px", marginBottom:"2px", }}>
                        <span className="skeleton"></span>
                    </div>
                    <div className="no-data-bg" style={{width: "100%",  maxWidth: "385px", height: "22px",borderRadius: "4px", marginBottom:"2px", }}>
                        <span className="skeleton"></span>
                    </div>
                    <div className="no-data-bg" style={{width: "100%",  maxWidth: "421px", height: "22px",borderRadius: "4px", marginBottom:"2px", marginTop: "16px" }}>
                        <span className="skeleton"></span>
                    </div>
                    <div className="no-data-bg" style={{width: "100%",  maxWidth: "165px", height: "22px",borderRadius: "4px", marginBottom:"2px", }}>
                        <span className="skeleton"></span>
                    </div>
            </div>
        : <div className="thisWeekBody">
            {isText ? (
                <p>
                    <HtmlParser html={week_text} />
                </p>
            ):(
                // <p className="week-no-desc text-center text-shadow mb-0">
                //     No description.
                // </p>
                <>
                    <div className="no-data-bg" style={{width: "100%",  maxWidth: "404px", height: "22px",borderRadius: "4px", marginTop: "-2px", marginBottom:"2px" }}></div>
                    <div className="no-data-bg" style={{width: "100%",  maxWidth: "392px", height: "22px",borderRadius: "4px", marginBottom:"2px", }}></div>
                    <div className="no-data-bg" style={{width: "100%",  maxWidth: "385px", height: "22px",borderRadius: "4px", marginBottom:"2px", }}></div>
                    <div className="no-data-bg" style={{width: "100%",  maxWidth: "421px", height: "22px",borderRadius: "4px", marginBottom:"2px", marginTop: "16px" }}></div>
                    <div className="no-data-bg" style={{width: "100%",  maxWidth: "165px", height: "22px",borderRadius: "4px", marginBottom:"2px", }}></div>
                </>
            )  
            }
        </div>
        }
    </div>
}
export default Week