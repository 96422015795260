import React from "react";

const CrossDelIcon = () => {
    const titleId= "deleteCrossIcon"
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
    >
        <title id={titleId}>Delete</title>
      <g opacity="0.375">
        <path
          d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
          fill="black"
        />
        <path
          d="M18.5 10C18.5 14.6944 14.6944 18.5 10 18.5C5.30558 18.5 1.5 14.6944 1.5 10C1.5 5.30558 5.30558 1.5 10 1.5C14.6944 1.5 18.5 5.30558 18.5 10Z"
          stroke="black"
        />
        <path d="M6.5 6.5L13.5 13.5" stroke="black" stroke-width="2" />
        <path d="M6.5 13.5L13.5 6.5" stroke="black" stroke-width="2" />
      </g>
      <path
        d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9Z"
        fill="#C44538"
      />
      <path d="M5.5 5.5L12.5 12.5" stroke="white" stroke-width="2" />
      <path d="M5.5 12.5L12.5 5.5" stroke="white" stroke-width="2" />
    </svg>
  );
};

export default CrossDelIcon;
