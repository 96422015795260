import { TableSelectCommon } from "components/common/selectComponent";
import Guide from "components/common/svgs/guide";
import { ProgressbarCore } from "components/theme/activityBar/progressBar";
import { useContext } from "react";
// import _ from 'lodash';
import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment";
import AvailabilityModal from "components/classroom/modals/availability";
import { ChooseQuestionOrder } from "components/classroom/modals/chooseQuestionOrder";
import { NoStudents } from "components/classroom/modals/noStudents";
import ReportClickAlertModal from "components/classroom/modals/ReportClickAlertModal";
import {
  Assessment,
  ReportsNavigation,
} from "interfaces/professionalDevelopment";
import QuestionMarkIcon from "assets/img/question-mark-tooltip-icon.png";
import PreviewHelpModel from "components/classroom/modals/previewHelpModel";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
import { capitalizeFirstLetter } from "util/index";
import PencilIcon from "components/common/svgs/pencil-icon";
import { useNavigate } from "react-router-dom";
import HtmlParser from "components/common/htmlParser";
import Toggle from "components/common/Toggle";

library.add(faCaretLeft);

// const options = [
//     { value: "Item Response Report", label: "Item Response Report" },
//     { value: "Standards at Risk", label: "Standards at Risk" },
//     { value: "Classroom Summative Report", label: "Classroom Summative Report" }
// ];

const ScheduledAssessment = ({
  assessment,
  lastActivityFormatted,
  enableAccess,
  totalAssessments,
  index,
  setToggle,
  setHelpTitle,
  selectReport,
  onChangeAvailable,
  onClickAvailableStudents,
  modalShow,
  openRandomModal,
  openNoStudentsModal,
  showReportAlertModal,
  setShowReportAlertModal,
  helpTitle,
  showReviewHelpModel,
  setShowReviewHelpModel,
  toggle,
  reportOptions,
  setModalShow,
  onCancelChooseQuestionModal,
  onChangeRandom,
  onCloseNoStudents,
  onMarkCompleted,
  isScheduled,
}: any) => {
  const navigate = useNavigate();
  const { pdClassroom, navigateTo, isLoading, classroomId } = useContext(
    ProfessionalDevelopmentContext
  );
  const {
    id,
    name,
    students_assessed,
    students_assessed_count,
    students_total_count,
    proficiency,
    description,
    can_be_edited,
    available_students,
    can_be_enabled,
    enabled,
    assessment_time,
    is_past_window,
    is_completed,
    scheduled_date,
    scheduled_color
  } = assessment;

  const isMarkCompletedDisabled =
    Math.floor((students_assessed_count / students_total_count) * 100) < 50;
  const scheduledDay = isScheduled
    ? moment(scheduled_date).format("dddd")
    : null;
  const scheduledDate = isScheduled
    ? moment(scheduled_date).format("MMM DD")
    : null;

    const pdEnabled = pdClassroom.is_enabled;

    const navigateToPacingCalendar = ()=>{
      if(pdEnabled){
        navigate("/pacing-calendar", {
          state: { date: scheduled_date },
        });
      }
    }
  return (
    <>
      <table className="table-main">
        <tbody className="main-tbody">
          <tr>
            <td className="assessment-date">
              {isLoading ? (
                <Skeleton
                  baseColor="#00000033"
                  highlightColor="#737373"
                  borderRadius={8}
                  width="100%"
                  height={24}
                />
              ) : (
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={(e: any) => {
                    if(e.key === "Enter"){
                     navigateToPacingCalendar();
                    }
                  }}
                  onClick={() => {
                   navigateToPacingCalendar();
                  }}
                  style={{cursor: pdEnabled? 'pointer':'default', backgroundColor: scheduled_color, color: scheduled_color === "#b9b9b9" || scheduled_color === "#B9B9B9" || scheduled_color === "#ffed7f" || scheduled_color === "#FFED7F" ? "#323232" : "#fff" }}>
                  {isScheduled ? scheduledDay : "Not"} <br />{" "}
                  {isScheduled ? scheduledDate : "Scheduled"}
                </div>
              )}
            </td>
            <td>
              <table>
                <tbody>
                  <tr>
                    {isLoading ? (
                      <td className="name">
                        <Skeleton
                          baseColor="#00000033"
                          highlightColor="#737373"
                          borderRadius={8}
                          width="65%"
                          height={22}
                        />
                        <Skeleton
                          baseColor="#00000033"
                          highlightColor="#737373"
                          borderRadius={8}
                          width="65%"
                          height={23}
                          style={{ margin: "5px 0px 0px" }}
                        />
                      </td>
                    ) : (
                      <td className="name">
                        <p>{name}</p>
                        <p className="open-until-label open-until-label-dark">
                          {capitalizeFirstLetter(
                            assessment_time.replace(/[{()}]/g, "")
                          )}
                        </p>
                        {/* {alignment_label && (
                                                    <p
                                                        style={{
                                                            position: "relative",
                                                        }}
                                                    >
                                                    <span
                                                        style={{
                                                        position: "absolute",
                                                        width: "max-content",
                                                        }}
                                                        className="objective-data-highlight mt-2"
                                                    >
                                                        {capitalizeFirstLetter(
                                                            alignment_label.replace(
                                                                /[{()}]/g,
                                                                ""
                                                            )
                                                        )}
                                                    </span>
                                                    </p>
                                                )} */}
                      </td>
                    )}
                    <td className="last-active">
                      {isLoading ? (
                        <Skeleton
                          baseColor="#00000033"
                          highlightColor="#737373"
                          borderRadius={12}
                          width="80%"
                          height={18}
                          style={{ marginTop: "2px" }}
                        />
                      ) : (
                        <>{lastActivityFormatted}</>
                      )}
                    </td>
                    <td className="student-assessed">
                      {false ? (
                        <Skeleton
                          baseColor="#4788ff"
                          highlightColor="#729bdd"
                          borderRadius={8}
                          width="80%"
                          height={19}
                        />
                      ) : (
                        <span className="progress-skeleton-loader">
                          <ProgressbarCore
                            width={Math.ceil(
                              (students_assessed_count / students_total_count) *
                                100
                            )}
                            isLoading={isLoading}
                          >
                            <p>{students_assessed}</p>
                          </ProgressbarCore>
                        </span>
                      )}
                    </td>
                    <td className="proficiency">
                      {false ? (
                        <Skeleton
                          baseColor="#4788ff"
                          highlightColor="#729bdd"
                          borderRadius={8}
                          width="80%"
                          height={19}
                          style={{ marginTop: "6px" }}
                        />
                      ) : (
                        <span className="progress-skeleton-loader">
                          <ProgressbarCore
                            isProficient={proficiency > 70}
                            width={proficiency}
                            isDisabled={proficiency === null}
                            color="#9080FF"
                            isLoading={isLoading}
                          >
                            <p>{proficiency}%</p>
                          </ProgressbarCore>
                        </span>
                      )}
                    </td>
                    {isLoading ? (
                      <td className="guide">
                        <Skeleton
                          baseColor="#00000033"
                          highlightColor="#737373"
                          borderRadius={4}
                          width="29px"
                          height={19}
                        />
                      </td>
                    ) : (
                      <td className="guide">
                        {name.includes("A1") ? (
                          ""
                        ) : enableAccess ? (
                          <div className="cursor-pointer">
                            <Guide
                              onClick={() =>
                                navigateTo(
                                  "summatives",
                                  ReportsNavigation.PREVIEW,
                                  assessment
                                )
                              }
                            />
                          </div>
                        ) : (
                          <div 
                            role="button"
                            tabIndex={0}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    setShowReviewHelpModel(true)
                                }
                            }}
                            className="cursor-pointer "
                            onClick={() => setShowReviewHelpModel(true)}
                          >
                            <img
                              className="question-mark-icon"
                              src={QuestionMarkIcon}
                              alt=""
                            />
                          </div>
                        )}
                      </td>
                    )}
                    <td className="answer">
                      <span
                        role="button" tabIndex={0} 
                        onKeyDown={ (e:any) => { 
                          if(e.key === 'Enter') {
                            if (can_be_edited && !isLoading) {
                              sessionStorage.setItem("SUMMATIVE_NAME", name);
                              navigateTo(
                                Assessment.summative,
                                ReportsNavigation.ANSWER,
                                assessment
                              );
                            }
                          }
                      } }  
                        onClick={() => {
                          if (can_be_edited && !isLoading) {
                            sessionStorage.setItem("SUMMATIVE_NAME", name);
                            navigateTo(
                              Assessment.summative,
                              ReportsNavigation.ANSWER,
                              assessment
                            );
                          }
                        }}
                        style={{
                          opacity: !isLoading && can_be_edited ? 1 : 0.3,
                        }}
                      >
                       <PencilIcon />
                      </span>
                    </td>
                    <td
                      className={`reports-dropdown ${
                        isLoading ? "disabled" : ""
                      }`}
                    >
                      <TableSelectCommon
                        customClassName={
                          totalAssessments === 2 && index === 1
                            ? "top-start"
                            : ""
                        }
                        isOpen={toggle}
                        toggleSelect={() => {
                          if (isLoading) return;
                          setToggle((prev: boolean) => !prev);
                          setHelpTitle("Summative reports");
                          !enableAccess &&
                            !proficiency &&
                            setShowReportAlertModal(true);
                        }}
                        options={reportOptions}
                        onChange={(value: any) => {
                          selectReport(value, id, name);
                        }}
                        isReport={true}
                      />
                    </td>
                    <td className="available-student">
                        <Toggle 
                          inputName="Avaiable Students"
                          ariaLabel="Avaiable Students"
                          onChange={onChangeAvailable}
                          checked = {enabled}
                          disabled = {!can_be_enabled || isLoading}
                          
                        />

                      
                      <p
                        className="cursor-pointer"
                        onClick={() => onClickAvailableStudents()}
                      >
                        {isLoading ? (
                          <Skeleton
                            baseColor="#00000033"
                            highlightColor="#737373"
                            borderRadius={12}
                            width="57%"
                            height={14}
                            style={{ marginTop: "3px" }}
                          />
                        ) : enabled ? (
                          <>{available_students} Students</>
                        ) : (
                          <></>
                        )}
                      </p>
                    </td>
                    {isLoading ? (
                      <td className="monitor-button">
                        <Skeleton
                          baseColor="#00000033"
                          highlightColor="#737373"
                          borderRadius={4}
                          width={88}
                          height={33}
                        />
                      </td>
                    ) : (
                      <td className="monitor-button" style={{ width: "135px" }}>
                        {enabled ? (
                          <button
                            tabIndex={0}
                            type="button"
                            className="button-green"
                            id={`monitor-$${id}`}
                            onClick={() =>
                              navigateTo(
                                Assessment.summative,
                                ReportsNavigation.MONITOR,
                                assessment
                              )
                            }
                          >
                            Monitor
                          </button>
                        ) : (
                          <div className="turnMonitorContainer">
                            {!is_past_window && (
                              <FontAwesomeIcon
                                name="Check"
                                icon={faCaretLeft}
                                width={9}
                                height={11}
                                style={{
                                  fontSize: 16,
                                  marginRight: "2px",
                                  marginTop: "2px",
                                }}
                              />
                            )}

                            <div>
                              <p
                                className="turnMonitor"
                                style={{ fontSize: 11 }}
                              >
                                {is_past_window
                                  ? "Availablity Closed"
                                  : can_be_enabled
                                  ? "Turn on to monitor"
                                  : capitalizeFirstLetter(
                                      assessment_time
                                        .split("until")[0]
                                        .replace(/[{()}]/g, "")
                                    )}
                              </p>
                            </div>
                          </div>
                        )}
                      </td>
                    )}
                  </tr>
                  <tr>
                    <td className="bottom-text" colSpan={7}>
                      {isLoading ? <></> : <HtmlParser html={description} />}
                    </td>
                    <td className="complete-toggle" colSpan={5}>
                      <div
                        className={`${
                          isLoading ? "disabled" : ""
                        } complete-toggle-container small-toggle`}
                      >
                        <p>Mark Completed</p>
                        <Toggle 
                          inputName="Mark Completed"  
                          ariaLabel="Mark Completed"
                          onChange={onMarkCompleted}
                          checked={is_completed}
                          disabled={isMarkCompletedDisabled || isLoading}
                          

                        />
                        
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

      {modalShow === true && (
        <AvailabilityModal
          show={modalShow}
          summativeId={id}
          formativeId={0}
          setModalShow={setModalShow}
          classroomId={classroomId}
        />
      )}

      {openRandomModal && (
        <ChooseQuestionOrder
          isShow={openRandomModal}
          onClose={onCancelChooseQuestionModal}
          onSave={onChangeRandom}
          isRandom={true}
          currentData={assessment}
          loading={false}
          title={`Make ${assessment.name} Available to Students?`}
          description="Choose the order of questions below to make the assessment available in the Student Portal."
        />
      )}

      {openNoStudentsModal && (
        <NoStudents
          isShow={openNoStudentsModal}
          onClose={onCloseNoStudents}
          loading={false}
        />
      )}

      {showReportAlertModal && (
        <ReportClickAlertModal
          show={showReportAlertModal}
          assessment="summative"
          closeModal={() => {
            // setO(0);
            setHelpTitle(null);
            setShowReportAlertModal(false);
          }}
          message={helpTitle}
        />
      )}

      <PreviewHelpModel
        show={showReviewHelpModel}
        closeModal={() => setShowReviewHelpModel(false)}
      />
    </>
  );
};

export default ScheduledAssessment;
