import { ContactSupportModal } from "components/ContactSupport/ContactSupportModal";
import ChangePasswordModal from "components/change-password/change-password-modal";
import LogoutModal from "components/classroom/modals/logoutModal";
import SuccessModal from "components/common/successModal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { clearDataOnLogout, logoutService } from "redux/actionCreators/auth";
import { isSuperAdmin } from "util/index";
import Menu from "./menu";
import FeedbackSurvey from "components/satisfaction-survey";

interface IProps {
    closeMenu: () => void;
    openDropdown: boolean;
}

const UserDropdownMenu = ({closeMenu, openDropdown}: IProps) => {
    const dispatch = useDispatch();

    const [showSupportModal, toggleSupportModal] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [satisfactionSurvey, setSatisfactionSurvey] = useState(false);

    const userLogout = async () => {
        // event.preventDefault();
    
        const isSupAdmin = isSuperAdmin();
        const {chat_exists, logout: isLogout} = await logoutService();
    
        if(isSupAdmin && !isLogout && chat_exists){
          setShowLogoutModal(true);
          return;
        }
    
        dispatch(clearDataOnLogout());
        // setLogout(true);
    };

    const onClickSurveyModal = () => {
        setSatisfactionSurvey(true);
        closeMenu();
    }

    const onClickChangePassword = () => {
        closeMenu();
        setShowChangePassword(true);
    }

    const onToggleSupportModal = (toggle: boolean) => {
        toggleSupportModal(true);
        closeMenu();
    }

    return (
        <>
            <Menu 
                onClickChangePassword={onClickChangePassword}
                toggleSupportModal={onToggleSupportModal}
                onClickSurveyModal={onClickSurveyModal}
                userLogout={userLogout}
                openDropdown={openDropdown}
                customClass={openDropdown ? 'show-dropdown' : ''}
                closeMenu={closeMenu}
            />
            
            {showSupportModal &&
                <ContactSupportModal
                    closeModal={() => toggleSupportModal(false)}
                    isShow={showSupportModal}
                    setShowSuccess={setShowSuccess}
                />
            }
            <SuccessModal
                bodyText="Thank you for contacting Alpha Plus Support. We will reach out to you within one business day. If your case is urgent, please call us at (405) 842-8408."
                headerText="Ticket Submitted"
                isShow={showSuccess}
                closeModal={() => setShowSuccess(false)}
            />

            <ChangePasswordModal
                isShow={showChangePassword}
                closeModal={() => setShowChangePassword(false)}
            />


            {showLogoutModal && (
                <LogoutModal setModalShow={setShowLogoutModal} />
            )}

            {satisfactionSurvey && (
                <FeedbackSurvey
                    satisfactionSurvey={satisfactionSurvey}
                    closeModal={() => setSatisfactionSurvey(false)}
                />
            )}
            
        </>
    )
};

export default UserDropdownMenu;
