/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import BlackSmithImg from "assets/img/black-smith.png";
import CustomModal from "components/common/Modal";

interface Props {
  show: boolean;
  setModalShow: any;
  studentName: string;
  student: {
    progress_data: any[];
    student_id: number;
    current_question: number;
  };
  onlineStudents: any;
}

type Color = keyof typeof ColorMap;

const ColorMap = {
  correct: "high_rating",
  wrong: "low_rating",
  unanswered: "average_rating",
};

const ShowProgress: React.FC<Props> = (props) => {
  const { student, show, setModalShow, studentName, onlineStudents } = props;

  return (
    <>
      <CustomModal
        centered
        show={show}
        onEscapeKeyDown={() => setModalShow(false)}
        className="modal-black-progress"
        contentClassName="modal-content-blackProgress"
      >
        <div className="modal__header">
          <img className="black__progress-icon" src={BlackSmithImg} alt="" />
          <h3 className="modal__header-title">{studentName}</h3>
        </div>
        <div className="modal__body">
          <div className="black__progress">
            <ul className="black__progress-list">
              {student.progress_data?.map((progress: any, index: number) => {
                const isOnline = onlineStudents?.find(
                  (std: any) => Number(std.student_id) === student.student_id
                );
                return (
                  <li>
                    {index + 1 <= 25 && <span>{index + 1}</span>}
                    <div
                      id={"monitorShowProgressModal"}
                      className={`${ColorMap[progress.info as Color]} ${
                        ((isOnline &&
                          !progress.is_active &&
                          progress.question_id === student.current_question) ||
                          progress.is_active) &&
                        "active"
                      }`}
                    >
                      {""}
                    </div>
                    {index + 1 >= 26 && <span>{index + 1}</span>}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="modal__footer">
          <button
            type="button"
            className="btn success-btn"
            onClick={() => setModalShow(false)}
          >
            Okay
          </button>
        </div>
      </CustomModal>
    </>
  );
};

export default ShowProgress;
