import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "assets/img/pencil-icon.svg";
import WarningIcon from "assets/img/warning-icon.png";
import DeleteIcon from "assets/img/trash-icon.svg";

import {
  deleteSchool,
  deleteSchoolAdmin,
  updateDistrict,
  updateSchool,
  updateSchoolAdmin,
  deleteDistrictAdmin,
} from "redux/actionCreators/district-csv-import";
import {
  SchoolAdminDeleteModal,
  SchoolDeleteModal,
  DistrictAdminDeleteModal,
} from "components/management/management-delete-modal";
import {
  CsvDistrictAdminUpdateModal,
  DistrictUpdateModal,
  SchoolAdminUpdateModal,
  SchoolUpdateCSVModal,
} from "components/management/management-update-modal";
import ActionType from "redux/actionTypes";

interface RootState {
  districtCsvImport: any;
}

const CsvResults = () => {
  const dispatch = useDispatch();
  const { districtCsvImport } = useSelector((state: RootState) => state);
  const [distrcitAdminIndex, setDistrcitAdminIndex] = useState<null | any>(
    null
  );
  const [districtAdminDeleteModal, setDistrictAdminDeleteModal] =
    useState(false);

  const [schoolAdminDeleteModal, setSchoolAdminDeleteModal] = useState(false);
  const [currentSchoolAdmin, setCurrentSchoolAdmin] = useState<null | any>(
    null
  );
  const [schoolAdminModal, setSchoolAdminModal] = useState(false);
  const [schoolAdminIndex, setSchoolAdminIndex] = useState<null | any>(null);

  const [schoolDeleteModal, setSchoolDeleteModal] = useState(false);
  const [currentSchool, setCurrentSchool] = useState<null | any>(null);
  const [schoolModal, setSchoolModal] = useState(false);
  const [currentDistrict, setCurrentDistrict] = useState<null | any>(null);
  const [districtModal, setDistrictModal] = useState(false);

  const [districtAdminModal, setDistrictAdminModal] = useState(false);
  const [currentDistrictAdmin, setCurrentDistrictAdmin] = useState<any | null>(
    null
  );
  const [currentDistrictAdminIndex, setCurrentDistrictAdminIndex] = useState<
    any | null
  >(null);

  const [schoolIndex, setSchoolIndex] = useState<null | any>(null);
  const [isLastSchool, setIsLastSchool] = useState(false);

  useEffect(() => {
    console.log("districtCsvImport", districtCsvImport);
  }, [districtCsvImport]);

  const doSetSchoolModal = (schoolIndex: number, item: any) => {
    console.log(item, "ITEM");
    setSchoolIndex(schoolIndex);
    setCurrentSchool(item);
    setSchoolModal(true);
  };
  const doSetDistrictModal = (item: any) => {
    console.log(item, "D");
    setCurrentDistrict(item);
    setDistrictModal(true);
  };
  const doSetSchoolDelModal = (schoolIndex: number, item: any) => {
    console.log(item, "ITEM");
    setIsLastSchool(districtCsvImport.csvResults.slice(1).length === 1);
    setSchoolIndex(schoolIndex);
    setSchoolDeleteModal(true);
    setCurrentSchool(item);
  };

  const doSetUpdateDistrictAdmin = (index: number, item: any) => {
    console.log(item, ".......................");
    setCurrentDistrictAdmin(item);
    setCurrentDistrictAdminIndex(index);
    setDistrictAdminModal(true);
  };

  const onUpdateSchool = (data: any) => {
    let finalData = {
      ...currentSchool,
      school_name: data.name,
      state: data.state,
      student_licenses: data.student_licenses,
      zip: data.zip,
      phone: data.phone,
      error: "",
      address: data.address_1,
      address_2: data.address_2,
      city: data.city,
    };
    dispatch(updateSchool(finalData, schoolIndex, setSchoolModal));
  };
  const doSetSchoolAdminModal = (
    schoolIndex: number,
    schoolAdminIndex: number,
    item: any
  ) => {
    setSchoolAdminIndex({ schoolIndex, schoolAdminIndex });
    setCurrentSchoolAdmin(item);
    setSchoolAdminModal(true);
  };

  const doUpdateData = (data: any) => {
    const finalData = {
      admin_first_name: data.first_name,
      admin_last_name: data.last_name,
      email: data.email,
      is_super_principal: data.is_super_principal ? "Y" : "N",
      error: "",
    };

    dispatch(
      updateSchoolAdmin(finalData, schoolAdminIndex, setSchoolAdminModal)
    );
  };

  const doSetSchoolAdminDelModal = (
    schoolIndex: number,
    schoolAdminIndex: number,
    item: any
  ) => {
    setSchoolAdminIndex({ schoolIndex, schoolAdminIndex });
    setSchoolAdminDeleteModal(true);
    setCurrentSchoolAdmin(item);
  };

  const doSetDistrictAdminDelModal = (
    districtAdminIndex: number,
    item: any
  ) => {
    setCurrentDistrictAdmin(item);
    setDistrcitAdminIndex(districtAdminIndex);
    setDistrictAdminDeleteModal(true);
  };

  const onDeleteSchoolAdmin = () => {
    console.log(schoolAdminIndex, "hello");
    dispatch(deleteSchoolAdmin(schoolAdminIndex, setSchoolAdminDeleteModal));
  };

  const onDeleteDistrictAdmin = () => {
    dispatch(
      deleteDistrictAdmin(distrcitAdminIndex, setDistrictAdminDeleteModal)
    );
  };

  const onDeleteSchool = () => {
    // console.log(schoolIndex)
    dispatch(deleteSchool(schoolIndex, setSchoolDeleteModal));
  };
  const onUpdateDistrict = (data: any) => {
    console.log(data, ".................district");
    console.log(currentDistrict, "C");
    let finalData = { ...currentDistrict, district_name: data.name, error: "" };
    dispatch(updateDistrict(finalData, setDistrictModal));
  };
  const onUpdateDistrictAdmin = (data: any) => {
    // console.log(data, "-----------------------------");
    // console.log(currentDistrictAdminIndex);

    // dispatch(
    //   updateDistrictAdmin(
    //     data,
    //     currentDistrictAdminIndex,
    //     setDistrictAdminModal
    //   )
    // );

    dispatch({
      type: ActionType.UPDATE_DISTRICT_ADMIN_LOCAL,
      payload: { data, index: currentDistrictAdminIndex },
    });
    setDistrictAdminModal(false);
  };
  return (
    <div className="section">
      <div className="section-header">
        <h3>Results</h3>
      </div>
      {districtCsvImport.csvUploadSuccess ? (
        <div className="results light-bg add-radius">
          <div className="performance__clark-container">
            <div>
              <div className="performance__clark">
                <div className="performance__clark-header">
                  <section className="performance__clark-heading">
                    <h3>District</h3>
                  </section>
                </div>
                {districtCsvImport.csvResults
                  ? districtCsvImport.csvResults
                      .slice(0, 1)
                      .map((item: any, i: number) => {
                        return (
                          <div className="results-table-container">
                            <div className="table-responsive">
                              <table className="testResult__table">
                                <thead>
                                  <tr className="testResult__table-row blue-row">
                                    <th className="testResult__table-name">
                                      District Name
                                    </th>
                                    <th className="testResult__table-option">
                                      Options
                                    </th>
                                    <th className="testResult__table-error">
                                      Error
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    key={i}
                                    className={
                                      i % 2 !== 0
                                        ? "testResult__table-row dark-row"
                                        : "testResult__table-row"
                                    }
                                  >
                                    <td className="name-data">
                                      <div>{item?.district_name}</div>
                                    </td>
                                    <td>
                                      <div className="d-flex justify-content-start align-items-center">
                                        <img
                                          onClick={() =>
                                            doSetDistrictModal(item)
                                          }
                                          src={EditIcon}
                                          alt="Edit"
                                          className="management__table-editBtn"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <p className="invalid-text">
                                        {item?.error}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="results__nested-container">
                              <div className="table-responsive results__nested-table">
                                <table className="nested__table">
                                  <thead>
                                    <tr className="nested__table-row blue-row">
                                      <th className="nested__table-admin">
                                        Admin Last Name
                                      </th>
                                      <th className="nested__table-name">
                                        First Name
                                      </th>
                                      <th className="nested__table-email">
                                        Email
                                      </th>
                                      <th className="nested__table-superPrinciple">
                                        Is Superintendent
                                      </th>
                                      {/* <th className="nested__table-options">
                                        Options
                                      </th> */}
                                      <th className="nested__table-options">
                                        Options
                                      </th>
                                      <th className="nested__table-error">
                                        Error
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {item.admins
                                      ? item.admins.map(
                                          (admin: any, j: number) => {
                                            return (
                                              <tr
                                                key={j}
                                                className={
                                                  j % 2 !== 0
                                                    ? "nested__table-row "
                                                    : "nested__table-row dark-row"
                                                }
                                              >
                                                <td className="admin-data">
                                                  <div className="text-right">
                                                    {admin.error ? (
                                                      <img
                                                        style={{
                                                          transform:
                                                            "translateX(-20px)",
                                                        }}
                                                        src={WarningIcon}
                                                        alt="warning-icon"
                                                        className="mx-2"
                                                      />
                                                    ) : (
                                                      <div></div>
                                                    )}
                                                    {admin?.admin_last_name}
                                                  </div>
                                                </td>
                                                <td className="">
                                                  <div>
                                                    {admin?.admin_first_name}
                                                  </div>
                                                </td>
                                                <td className="">
                                                  <div>{admin?.email}</div>
                                                </td>
                                                <td>
                                                  <div>
                                                    {admin?.is_super_principal.toUpperCase() ===
                                                    "Y"
                                                      ? "Y"
                                                      : "N"}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="d-flex justify-content-start align-items-center">
                                                    <img
                                                      onClick={() =>
                                                        doSetUpdateDistrictAdmin(
                                                          j,
                                                          admin
                                                        )
                                                      }
                                                      src={EditIcon}
                                                      alt="Edit"
                                                      className="management__table-editBtn"
                                                    />
                                                    {admin?.is_super_principle ===
                                                      "Y" ||
                                                    item.admins.length === 1 ? (
                                                      <></>
                                                    ) : (
                                                      <img
                                                        onClick={() =>
                                                          doSetDistrictAdminDelModal(
                                                            j,
                                                            admin
                                                          )
                                                        }
                                                        src={DeleteIcon}
                                                        alt="Delete"
                                                        className="management__table-editBtn"
                                                      />
                                                    )}
                                                  </div>
                                                </td>
                                                <td>
                                                  <p className="invalid-text">
                                                    {admin?.error}
                                                  </p>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      : ""}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        );
                      })
                  : ""}
              </div>
              <div className="performance__clark">
                <div className="performance__clark-header">
                  <section className="performance__clark-heading d-flex align-items-center">
                    <h3>Schools</h3>
                    {districtCsvImport.clientErrors ? (
                      <img
                        src={WarningIcon}
                        alt="warning-icon"
                        className="mx-2"
                      />
                    ) : (
                      ""
                    )}
                  </section>
                </div>
                {districtCsvImport.csvResults
                  ? districtCsvImport.csvResults
                      .slice(1)
                      .map((item: any, i: number) => {
                        return (
                          <div className="performance__table-container">
                            <div className="table-responsive">
                              <table className="performance__table">
                                <thead>
                                  <tr className="performance__table-row blue-row">
                                    <th className="performance__table-name">
                                      School Name
                                    </th>
                                    <th className="performance__table-address">
                                      Address 1
                                    </th>
                                    <th className="performance__table-secondAddess">
                                      Address 2
                                    </th>
                                    <th className="performance__table-city">
                                      City
                                    </th>
                                    <th className="performance__table-state">
                                      State
                                    </th>
                                    <th className="performance__table-zip">
                                      Zip
                                    </th>
                                    <th className="performance__table-phone">
                                      Phone
                                    </th>
                                    {/* <th className="school__table">
                                      Student Licenses
                                    </th> */}

                                    <th className="performance__table-options">
                                      Options
                                    </th>
                                    <th className="performance__table-error">
                                      Error
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    key={i}
                                    className={
                                      i % 2 !== 0
                                        ? "performance__table-row dark-row"
                                        : "performance__table-row"
                                    }
                                  >
                                    <td className="name-data">
                                      <div>
                                        {item.error ? (
                                          <img
                                            style={{
                                              transform: "translateX(-6px)",
                                            }}
                                            src={WarningIcon}
                                            alt="warning-icon"
                                            className="mx-2"
                                          />
                                        ) : (
                                          <div></div>
                                        )}
                                        {item?.school_name ? (
                                          item?.school_name
                                        ) : (
                                          <span className="error-ball"></span>
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        {/* {item?.address ? (
                                          item?.address
                                        ) : (
                                          <span className="error-ball"></span>
                                        )} */}
                                        {item?.address}
                                      </div>
                                    </td>
                                    <td>
                                      <div>{item?.address_2}</div>
                                    </td>
                                    <td>
                                      {/* <div>
                                        {item?.city ? (
                                          item?.city
                                        ) : (
                                          <span className="error-ball"></span>
                                        )}
                                      </div> */}
                                      <div>{item?.city}</div>
                                    </td>
                                    <td>
                                      <div>
                                        {/* {item?.state ? (
                                          item?.state
                                        ) : (
                                          <span className="error-ball"></span>
                                        )} */}
                                        {item?.state}
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        {/* {item?.zip ? (
                                          item?.zip
                                        ) : (
                                          <span className="error-ball"></span>
                                        )} */}
                                        {item?.zip}
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        {/*{item?.phone ? (*/}
                                        {/*item?.phone*/}
                                        {/*) : (*/}
                                        {/*<span className="error-ball"></span>*/}
                                        {/*)}*/}
                                        {item?.phone}
                                      </div>
                                    </td>
                                    {/* <td>
                                      <div>{item?.student_licenses}</div>
                                    </td> */}
                                    <td>
                                      <div className="d-flex justify-content-start align-items-center">
                                        <img
                                          onClick={() =>
                                            doSetSchoolModal(i + 1, item)
                                          }
                                          src={EditIcon}
                                          alt="Edit"
                                          className="management__table-editBtn"
                                        />
                                        <img
                                          onClick={() =>
                                            doSetSchoolDelModal(i + 1, item)
                                          }
                                          src={DeleteIcon}
                                          alt="Delete"
                                          className="management__table-editBtn"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <p className="error-data">
                                        {item?.error}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="results__nested-container">
                              <div className="table-responsive results__nested-table">
                                <table className="nested__table">
                                  <thead>
                                    <tr className="nested__table-row blue-row">
                                      <th className="nested__table-admin">
                                        Admin Last Name
                                      </th>
                                      <th className="nested__table-name">
                                        First Name
                                      </th>
                                      <th className="nested__table-email">
                                        Email
                                      </th>
                                      <th className="nested__table-principle">
                                        Is Principal
                                      </th>
                                      <th className="nested__table-options">
                                        Options
                                      </th>
                                      <th className="nested__table-error">
                                        Error
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {item.admins
                                      ? item.admins.map(
                                          (admin: any, j: number) => {
                                            return (
                                              <tr
                                                key={j}
                                                className={
                                                  j % 2 !== 0
                                                    ? "nested__table-row "
                                                    : "nested__table-row dark-row"
                                                }
                                              >
                                                <td className="admin-data">
                                                  <div className="text-right">
                                                    {admin.error ? (
                                                      <img
                                                        style={{
                                                          transform:
                                                            "translateX(-20px)",
                                                        }}
                                                        src={WarningIcon}
                                                        alt="warning-icon"
                                                        className="mx-2"
                                                      />
                                                    ) : (
                                                      <div></div>
                                                    )}
                                                    {admin?.admin_last_name ? (
                                                      admin?.admin_last_name
                                                    ) : (
                                                      <span className="error-ball"></span>
                                                    )}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div>
                                                    {admin?.admin_first_name ? (
                                                      admin?.admin_first_name
                                                    ) : (
                                                      <span className="error-ball"></span>
                                                    )}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div>
                                                    {admin?.email ? (
                                                      admin?.email
                                                    ) : (
                                                      <span className="error-ball"></span>
                                                    )}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div>
                                                    {admin?.is_super_principal &&
                                                    admin?.is_super_principal.toUpperCase() ===
                                                      "Y"
                                                      ? "Y"
                                                      : "N"}
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="d-flex justify-content-start align-items-center">
                                                    <img
                                                      onClick={() =>
                                                        doSetSchoolAdminModal(
                                                          i + 1,
                                                          j,
                                                          admin
                                                        )
                                                      }
                                                      src={EditIcon}
                                                      alt="Edit"
                                                      className="management__table-editBtn"
                                                    />
                                                    {admin?.is_super_principal.toUpperCase() ===
                                                    "Y" ? (
                                                      <></>
                                                    ) : (
                                                      <img
                                                        onClick={() =>
                                                          doSetSchoolAdminDelModal(
                                                            i + 1,
                                                            j,
                                                            admin
                                                          )
                                                        }
                                                        src={DeleteIcon}
                                                        alt="Delete"
                                                        className="management__table-editBtn"
                                                      />
                                                    )}
                                                  </div>
                                                </td>
                                                <td>
                                                  <p className="invalid-text">
                                                    {admin?.error}
                                                  </p>
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      : ""}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        );
                      })
                  : ""}
              </div>
            </div>
          </div>
          <SchoolAdminDeleteModal
            loading={false}
            isShow={schoolAdminDeleteModal}
            headerText={`Delete ${currentSchoolAdmin?.admin_first_name} ${currentSchoolAdmin?.admin_last_name} `}
            bodyText={`Would you like to remove this school admin ?`}
            onCancel={() => setSchoolAdminDeleteModal(false)}
            onDelete={onDeleteSchoolAdmin}
          />
          {districtAdminDeleteModal && (
            <DistrictAdminDeleteModal
              loading={false}
              isShow={districtAdminDeleteModal}
              headerText={`Delete ${
                currentDistrictAdmin && currentDistrictAdmin?.admin_first_name
              } ${currentDistrictAdmin?.admin_last_name}`}
              bodyText={`Are you sure you want to delete this district admin?`}
              onCancel={() => setDistrictAdminDeleteModal(false)}
              onDelete={onDeleteDistrictAdmin}
            />
          )}
          {schoolDeleteModal && (
            <SchoolDeleteModal
              loading={false}
              isShow={schoolDeleteModal}
              headerText={`Delete ${currentSchool?.school_name}`}
              bodyText={
                isLastSchool
                  ? `Are you sure you want to delete this school? District must contain at least one school.`
                  : `Are you sure you want to delete this school?`
              }
              onCancel={() => setSchoolDeleteModal(false)}
              onDelete={onDeleteSchool}
            />
          )}
          {schoolAdminModal && (
            <SchoolAdminUpdateModal
              isShow={schoolAdminModal}
              is_super_principal={
                currentSchoolAdmin?.is_super_principal || null
              }
              first_name={currentSchoolAdmin?.admin_first_name}
              last_name={currentSchoolAdmin?.admin_last_name}
              input_email={currentSchoolAdmin?.email}
              input_title={currentSchoolAdmin?.title || ""}
              onCancel={() => setSchoolAdminModal(false)}
              onUpdate={(data) => doUpdateData(data)}
              loading={false}
              csv={true}
            />
          )}
          {schoolModal && (
            <SchoolUpdateCSVModal
              isShow={schoolModal}
              loading={false}
              school={currentSchool}
              onCancel={() => setSchoolModal(false)}
              onUpdate={(data) => onUpdateSchool(data)}
            />
          )}
          {districtAdminModal && (
            <CsvDistrictAdminUpdateModal
              isShow={districtAdminModal}
              is_super_principal={currentDistrictAdmin?.is_super_principal}
              first_name={currentDistrictAdmin?.admin_first_name}
              last_name={currentDistrictAdmin?.admin_last_name}
              input_email={currentDistrictAdmin.email}
              onCancel={() => setDistrictAdminModal(false)}
              onUpdate={(data) => onUpdateDistrictAdmin(data)}
              loading={false}
            />
          )}
          {districtModal && (
            <DistrictUpdateModal
              isShow={districtModal}
              loading={false}
              onCancel={() => setDistrictModal(false)}
              onUpdate={(data) => onUpdateDistrict(data)}
              district_name={currentDistrict?.district_name}
              id={1}
            />
          )}
        </div>
      ) : (
        <div className="results__empty light-bg" style={{ borderRadius: 8 }}>
          <p className="results__empty-paragraph">
            Upload a file above to get started.
          </p>
        </div>
      )}
    </div>
  );
};

export default CsvResults;
