import axios from "axios";
import config from "config/config";

export const updateUserData = async (type?: string, body?: any) => {
  try {
    const {
      data: { data },
    } = await axios.put(
      `${config.defaults.api_url}/api/v3/admin/user/current${
        type ? `?type=${type}` : ""
      }`,
      body
    );
    return data.updated;
  } catch (error) {
    return null;
  }
};

export const triggerGoalWithClassroom = async ({
  goal_id,
  task_id,
  classroomId,
  conditionName,
}: any) => {
  try {
    const {
      data: { data },
    } = await axios.put(
      `${config.defaults.api_url}/api/v3/pd/classroom/${classroomId}/goals`,
      {
        condition_name: conditionName,
        ...(goal_id && { goal_id }),
        ...(task_id && { task_id }),
      }
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const isPrevYearSTLExists = async (schoolId: any) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${config.defaults.api_url}/api/v3/admin-reports/summative-test-log-report/schools/${schoolId}`
    );
    return data;
  } catch (error) {
    return null;
  }
};
export const isPrevYearClassroomSTLExists = async (
  schoolId: any,
  classroomId: number
) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${config.defaults.api_url}/api/v3/admin-reports/summative-test-log-report/schools/${schoolId}?classroom_id=${classroomId}`
    );
    return data;
  } catch (error) {
    console.log("err", error);
    return null;
  }
};

export const getActiveAdmins = async () => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${config.defaults.api_url}/api/v3/support/chat/active/admins`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const isPrevYearDistrictSTLExists = async (districtId: any) => {
  try {
    const {
      data: { data },
    } = await axios.get(
      `${config.defaults.api_url}/api/v3/admin-reports/summative-test-log-report/districts/${districtId}`
    );
    return data;
  } catch (error) {
    return null;
  }
};

export const getSurveyQuestionnaire = async () => {
  try {
    const res = await axios.get(
      `${config.defaults.api_url}/api/v3/survey-questionnaire`
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const updateSurveyQuestionnaire = async (body: {
  survey_status: "LATER" | "NO_THANKS";
}) => {
  try {
    const res = await axios.put(
      `${config.defaults.api_url}/api/v3/survey-questionnaire`,
      body
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const postUpdateSurveyQuestionnaire = async (body: { answers: any }) => {
  try {
    const res = await axios.post(
      `${config.defaults.api_url}/api/v3/survey-questionnaire`,
      body
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getClassroomActivity = async (schoolId?: number) => {
  try {
    const res = await axios.get(
      `${config.defaults.api_url}/api/v3/admin/classroom/activities${
        schoolId ? `?school_id=${schoolId}` : ""
      }`
    );
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getFeedProgressData = async (params: any) => {
  try {
    const res = await axios.get(
      `${config.defaults.api_url}/api/v3/activity/feed-progress?${Object.keys(
        params
      )
        .map((key) => key + "=" + params[key as keyof typeof params])
        .join("&")}`
    );
    return res;
  } catch (error: any) {
    throw new Error(
      error?.response?.data?.message || "Something went wrong..."
    );
  }
};

export const uploadManagementResource = async (formData: FormData) => {
  try {
    const res = await axios.post(
      `${config.defaults.api_url}/api/v3/admin/management/resources/upload`,
      formData
    );
    return res.data;
  } catch (error: any) {
    return null;
  }
};
