import { DiscussionsContext } from "contexts/discussions";
import { useContext } from "react";

const DiscussionIframe = () => {

  const {
    urlToLoad
  } = useContext(DiscussionsContext);

  return (
    <div className="discussions">
      <iframe
        src={urlToLoad}
        title="Website Iframe"
        className="frame_setting"
      />
    </div>
  );
};

export default DiscussionIframe;