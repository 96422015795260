import PracticeFileUpload from "./practiceFileUpload";

const LessonsBody = ({isUpload, title, data, onClickPDF}:{isUpload: boolean, title: string, data: any, onClickPDF: (fieldName: string, url: string) => void}) => {
    return (
        <div className="lesson-body-sec">
            <h2 className="secHeading">Lesson Files</h2>
            <p className="sec-desc">{title}</p>
            <PracticeFileUpload
                fileNo={1}
                heading="Lesson/Guided Practice"
                teacherField="lesson_guided_practice_teacher"
                studentField="lesson_guided_practice_student"
                isTeacherPdfUploaded={!!data.lesson_guided_practice_teacher}
                isStudentPdfUploaded={!!data.lesson_guided_practice_student}
                onClickPDF={onClickPDF}
                isUpload={isUpload}
            />
            <PracticeFileUpload 
                fileNo={2}
                heading="Independent Practice"
                teacherField="independent_practice_teacher"
                studentField="independent_practice_student"
                isTeacherPdfUploaded={!!data.independent_practice_teacher}
                isStudentPdfUploaded={!!data.independent_practice_student}
                onClickPDF={onClickPDF}
                isUpload={isUpload}
            />
            <PracticeFileUpload
                fileNo={3}
                heading="Continuous Practice"
                teacherField="continuos_practice_teacher"
                studentField="continuos_practice_student"
                isTeacherPdfUploaded={!!data.continuos_practice_teacher}
                isStudentPdfUploaded={!!data.continuos_practice_student}
                onClickPDF={onClickPDF}
                isUpload={isUpload}
            />
        </div>
    )
};

export default LessonsBody;
