import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionType from "redux/actionTypes";
import { downLoadStlReport } from "redux/actionCreators/management";

const StlSchoolButton = ({ previous_year }: { previous_year?: string }) => {
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState("");
  const { management } = useSelector((state: any) => state);
  const firstRender = useRef<any>(true);
  const dispatch = useDispatch();

  const download = async () => {
    if (management.stlSchoolLoading) {
      setLoading(true);
      console.log(3434, previous_year);
      await downLoadStlReport("SCHOOL_ADMIN", previous_year);
      dispatch({
        type: ActionType.SET_STL_SCHOOL_LOADING,
        payload: false,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (previous_year) {
      if (firstRender.current) {
        setButtonText("Download STL for 2023-24");
      } else {
        setTimeout(() => {
          setButtonText("Download STL for 2023-24");
        }, 1600);
      }
    } else {
      setButtonText("Download STL Report");
    }

    if (firstRender.current) {
      firstRender.current = false;
    }
  }, [previous_year]);

  return (
    <div className="download-report">
      <button
        onClick={download}
        className={`${
          previous_year ? "stl-school-download-btn" : ""
        } download-report__btn ${
          management.stlSchoolLoading ? "change-color" : ""
        }`}
      >
        {buttonText || "Download STL Report"}
        {loading && <div className="download-report__btn-flashing"></div>}
        <span
          className="download-report__btn-text"
          onClick={(e) => {
            dispatch({
              type: ActionType.SET_STL_SCHOOL_LOADING,
              payload: false,
            });
            e.stopPropagation();
          }}
        >
          <span className="download-report__btn-close">&times;</span>
        </span>
      </button>
    </div>
  );
};

export default StlSchoolButton;
