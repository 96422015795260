import { FC } from "react"

const TableBody: FC<{ student: any , onStudentClick? : Function ,index:number}> = ({ student,onStudentClick ,index}) => {
    const { student_id, student_name, score_percentage, proficiency } = student
    type Proficiency = keyof typeof ProficiencyMap
    const ProficiencyMap = {
        "BB": "Below Basic",
        "B": "Basic",
        "P": "Proficient",
        "A": "Advanced"
    }

    return <><tr className="SummativeStudentsReport__table__row">
        <td className="SummativeStudentsReport__table__row__studentId">
            {student_id?.slice(-5)}
        </td>
        <td className="SummativeStudentsReport__table__row__Name fontBold">

            <span className="ellipsis-animated cursor-pointer" 
            onClick={()=>onStudentClick && onStudentClick(student_id)}>
                <p className={`ellipsis-animated-text ${student_name.length > 18 ? 'animated ellipsis-animation' : ''}`}>{student_name}</p>
            </span>

        </td>
        <td className="SummativeStudentsReport__table__row__proficiency">
            <span className={`coloredBox box-${ProficiencyMap[proficiency as Proficiency]}`}></span>
            <span>{ProficiencyMap[proficiency as Proficiency]}</span>
        </td>
        <td className="SummativeStudentsReport__table__row__pecentage">
            <span className="percentageAlignment">{score_percentage}%</span>
        </td>
    </tr>
    </>
}
export default TableBody