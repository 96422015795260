import axios from "util/axios";
import FormData from "form-data";
import config from "config/config";

export const uploadDistrictLogo = async (uploadData: any) => {
  let data = new FormData();
  data.append("file", uploadData.file);
  data.append("district_id", uploadData.id);
  try {
    let response = await axios.post(
      `${config.defaults.api_url}/api/v3/district/district-logo`,
      data,
      {
        headers: {
          apiKey: config.defaults.api_key!,
          "content-type": "multipart/form-data"
        }
      }
    );
    console.log(response.data, "FILE..........................");
    return response.data;
  } catch (e) {
    console.log(e);
    return;
  }
};

export const removeDistrictLogo = async (district_id: string) => {
  console.log("in here")
  try {
    let response = await axios.put(
      `${config.defaults.api_url}/api/v3/admin/district/${district_id}/remove-logo`,
      {
        headers: {
          apiKey: config.defaults.api_key!,
        }
      }
    );
    console.log(response.data, "FILE..........................");
    return response;
  } catch (e) {
    console.log(e);
    return;
  }
};

export const uploadSupportTicketFiles = async (files: any) => {
  const data = new FormData();
  files.map((file: any) => data.append("files", file))
  try {
    let response = await axios.post(
      `${config.defaults.api_url}/api/v3/tickets/images`,
      data,
      {
        headers: {
          apiKey: config.defaults.api_key!,
          "content-type": "multipart/form-data"
        }
      }
    );
    console.log(response.data, "FILE..........................");
    return response.data
  } catch (e) {
    console.log(e);
    return null
  }
};
