import React, { CSSProperties } from "react";

const SearchIcon = ({fill, title, id, desc, style ={}}:{fill?:string, title: string, id?:string, desc?:string, style?: CSSProperties}) => {
  const titleId = "searchIcon"
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={18}
    fill="none"
    aria-labelledby={id || titleId}
    style={style}
  >
    <title id={id || titleId}>{title}</title>
    <path
      fill={fill || "#fff"}
      d="M2.121 2.773A7.242 7.242 0 0 1 7.24.68a7.242 7.242 0 0 1 5.123 2.093 7.05 7.05 0 0 1 2.117 5.05 7.036 7.036 0 0 1-1.783 4.696l4.021 3.967a.484.484 0 0 1 0 .69.5.5 0 0 1-.7 0l-4.02-3.971a7.243 7.243 0 0 1-4.758 1.759 7.242 7.242 0 0 1-5.119-2.09A7.048 7.048 0 0 1 0 7.825a7.05 7.05 0 0 1 2.121-5.051Zm.7 9.413a6.318 6.318 0 0 0 8.84 0 6.115 6.115 0 0 0 .002-8.723 6.274 6.274 0 0 0-4.42-1.8 6.275 6.275 0 0 0-4.42 1.8 6.117 6.117 0 0 0-.003 8.722h.001Z"
    />
  </svg>
  );
};

export default SearchIcon;
