import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useContext } from 'react';
import { WayToAReportContext } from 'contexts/wayToAReport';
import GoalCompletition from './GoalCompletition';
import AssessmentScheduled from './AssessmentScheduled';
import RunningProficiency from './RunningProficiency';
import FormativeProgress from './formativeProgress';
import Remediation from './Remediation';
import Header from "components/common/report/Print/header"
import { months } from 'util/index';
import PrintFooter from 'components/common/report/Print/footer';


ChartJS.register(ArcElement, Tooltip, Legend);

export const ChartData = {
    data: {
        datasets: [
            {
                label: '# of Votes',
                data: [86, 15],
                backgroundColor: [
                    '#3B792F',
                    '#C44538',
                ],
                borderColor: [
                    '#3B792F',
                    '#C44538',
                ],
                borderWidth: 1,
            },
        ],
    },
    options: {
        plugins: {
            datalabels: {
                display: true,
                align: 'top',
                backgroundColor: '#3E3E3E',
                borderRadius: 3,
                color: '#fff',
                font: {
                    size: 14,
                },
            },
        },
    },
}

const WayToAReportPrint = () => {
    const {wayToAReport} = useContext(WayToAReportContext);
    const {assessment_schedule, goal_completion, remediation, formative_progress, running_proficiency, month} = wayToAReport;
    const formattedMonth = months.find(m => m.toLowerCase() === month.toLowerCase());
    
    return (
        <>
            <div className="WayToReport">
            <div className="">
                <div className='heading'>
                    <Header isWayToReport title={`Way To A Report: ${formattedMonth}`} />
                </div>
            </div>
            <div className="WayToReport__body way-to-report-body-print">
                <div className="textInfo">
                    <p style={{color: '#000'}}>The Way to an A Report reflects your monthly progress with the Alpha Plus School Improvement Model. Use the sections below to determine which areas need more attention for student success.</p>
                </div>
                <div className='WayToReport__body__anylasisData'>
                    <>
                        <GoalCompletition goalCompletition={goal_completion} />
                        <AssessmentScheduled assessmentSchedule={assessment_schedule} />
                        <RunningProficiency runningProficiency={running_proficiency} />
                        <FormativeProgress formativeProgress={formative_progress} />
                        <Remediation remediation={remediation} />
                    </>
                </div>
            </div>
           
        </div>
        <div className='way-to-report-footer-print'>
            <PrintFooter />
        </div>
        </>
    )
}

export default WayToAReportPrint;