import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useGetChartData } from 'hooks/useGetChartData';
import { useGetDataValues } from 'hooks/useGetDataValues';
import { useNavigate } from 'react-router-dom';
import { getPrevMonth } from 'util/index';
import AnalysisBody from '../AnalysisBox/body';
import AnalysisContainer from '../AnalysisBox/container';
import AnalysisFooter from '../AnalysisBox/footer';
import AnalysisHeader from '../AnalysisBox/header';

ChartJS.register(ArcElement, Tooltip, Legend);

const AssessmentScheduled = ({assessmentSchedule}: any) => {

    const {on_time, late, status, text, percentage} = assessmentSchedule || {};

    const navigate = useNavigate();

    const {dataValues, backgroundColor, isNotData} = useGetDataValues({
        firstDatasetValue: on_time, 
        secondDatasetValue: late, 
        percentage
    })
    const ChartData = useGetChartData({dataValues, backgroundColor, isNotData});

    const onClickPacingCalender = () => {
        const prevMonth = getPrevMonth();
        navigate('/pacing-calendar', {state: {prevMonth}})
    }

    return (
        <AnalysisContainer>
            <AnalysisHeader status={status} title="Assessment Schedule" />
            
            <AnalysisBody 
                ChartData={ChartData}
                text={text}
                percentage={percentage}
                key={isNotData}
                isImage={isNotData}
            />

            <AnalysisFooter 
                firstLabel="On-Time"
                secondLabel="Late"
                onClick={onClickPacingCalender}
            />
        </AnalysisContainer>
    )
};

export default AssessmentScheduled;
