import React from "react";
import Theme from "components/theme/index";
import IPage from "interfaces/page";
import ProfessionalDevelopment from "components/oldprofessionalDevelopment";
import ProfesssionalDevelopmentProvider from "contexts/professionalDevelopment";

const ProfessionalDev: React.FunctionComponent<IPage> = (props) => {
  return (
    <Theme>
      <ProfesssionalDevelopmentProvider>
        <ProfessionalDevelopment />
      </ProfesssionalDevelopmentProvider>
    </Theme>
  );
};
export default ProfessionalDev;