import { initialState } from "contexts/pdAdmin"
import { PdAdminContextProvider } from "interfaces/pdAdmin"

export enum PdAdminActions {
    SET_MONTHS = 'SET_MONTHS',
    SET_CURRENT_MONTH_INDEX = 'SET_CURRENT_MONTH_INDEX',
    SET_CURRENT_SUBJECT_INDEX = 'SET_CURRENT_SUBJECT_INDEX',
    SET_SUBJECTS = 'SET_SUBJECTS',
    SET_SEARCH = 'SET_SEARCH',
    SET_WEEKLY_SCHEDULE = 'SET_WEEKLY_SCHEDULE',
    SET_WEEKLY_SCHEDULE_LOADING = 'SET_WEEKLY_SCHEDULE_LOADING',
    UNSET_SET_MONTHS=  'UNSET_SET_MONTHS',
    SET_PD_PREV_WEEK = 'SET_PREV_WEEK',
    SET_PD_WEEK = 'SET_PD_WEEK',
    SET_RESOURCES = 'SET_RESOURCES',
    SET_GOALS = 'SET_GOALS',
    SET_TOOLS = 'SET_TOOLS',
    DELETE_TOOL = 'DELETE_TOOL',
    SET_PD_PREV_TOOLS = 'SET_PD_PREV_TOOLS',
    SET_ADD_RESOURCE = 'SET_ADD_RESOURCE',
    SET_DELETE_RESOURCE = 'SET_DELETE_RESOURCE',
    SET_UPDATE_RESOURCE = 'SET_UPDATE_RESOURCE',
    SET_GOALS_LOADING = 'SET_GOALS_LOADING',
    SET_TOOLS_LOADING = 'SET_TOOLS_LOADING',
    SET_RESOURCES_LOADING = 'SET_RESOURCES_LOADING',
    SET_TOOLS_ICONS = 'SET_TOOLS_ICONS',
    SET_SELECTED_ICON = 'SET_SELECTED_ICON',
    ADD_TOOLS = 'ADD_TOOLS'
}


export const PdAdminReducer = (state: PdAdminContextProvider, action: any) => {
    const { type, payload } = action
    switch (type) {
        case PdAdminActions.ADD_TOOLS:
            return {
                ...state,
                selectedIcon: payload
            }
        case PdAdminActions.SET_SELECTED_ICON:
            return {
                ...state,
                selectedIcon: payload
            }
        case PdAdminActions.SET_TOOLS_ICONS:
            return {
                ...state,
                toolsIcons: payload
            }
        case PdAdminActions.SET_MONTHS:
            return {
                ...state,
                allMonths: payload
            }
        case PdAdminActions.UNSET_SET_MONTHS:
            return {
                ...state,
                allMonths: initialState.allMonths
            }
        case PdAdminActions.SET_CURRENT_MONTH_INDEX:
            return {
                ...state,
                currentMonthIndex: payload
            }
        case PdAdminActions.SET_CURRENT_SUBJECT_INDEX:
            return {
                ...state,
                currentSubjectIndex: payload
            }
        case PdAdminActions.SET_SUBJECTS:
            return {
                ...state,
                gradeSubjects: payload
            }
        case PdAdminActions.SET_SEARCH:
            return {
                ...state,
                search: payload
            }
        case PdAdminActions.SET_WEEKLY_SCHEDULE:
            return {
                ...state,
                weeklySchedule: {
                    ...payload,
                    loading: false
                }
            }
        case PdAdminActions.SET_WEEKLY_SCHEDULE_LOADING:
            return {
                ...state,
                weeklySchedule: {
                    ...state.weeklySchedule,
                    loading: payload
                }
            }
        case PdAdminActions.SET_PD_WEEK:
            return {
                ...state,
                weeklySchedule: payload
            }
        case PdAdminActions.SET_PD_PREV_WEEK:
            return {
                ...state,
                weeklySchedule: payload
            }
        case PdAdminActions.SET_RESOURCES_LOADING:
            return {
                ...state,
                resources: {
                    ...state.resources,
                    loading: payload
                }
            }
        case PdAdminActions.SET_RESOURCES:
            return {
                ...state,
                resources: {
                    ...payload,
                    loading: false
                }
            }
        case PdAdminActions.SET_GOALS_LOADING:
            return {
                ...state,
                goals: {
                    ...state.goals,
                    loading: payload
                }
            }
        case PdAdminActions.SET_GOALS:
            return {
                ...state,
                goals: {
                    ...payload
                }
            }
        case PdAdminActions.SET_TOOLS_LOADING:
            return {
                ...state,
                tools: {
                    ...state.tools,
                    loading: payload
                }
            }
        case PdAdminActions.SET_TOOLS:
            return {
                ...state,
                tools: {
                    ...payload,
                    loading: false
                }
            }
        case PdAdminActions.DELETE_TOOL:
            return {
                ...state,
                tools: {
                    ...state.tools,
                    weeks: state.tools.weeks.map(week => {
                        return week.date_week_id === payload.date_week_id
                        ? {
                            ...week,
                            tools: week.tools.filter(tool => tool.id !== payload.id)
                        }
                        : payload.nextWeekId && week.date_week_id === payload.nextWeekId
                        ? {
                            ...week,
                            previous_exists: false
                        }
                        : week
                    })
                }
            }
        case PdAdminActions.SET_PD_PREV_TOOLS:
            return {
                ...state,
                tools: payload
            }
        case PdAdminActions.SET_ADD_RESOURCE:
            return {
                ...state,
                resources: {
                    ...state.resources,
                    [payload.prop]: [...state.resources[payload.prop], payload.newResource] 
                }
            }
        case PdAdminActions.SET_DELETE_RESOURCE:
            return {
                ...state,
                resources: {
                    ...state.resources,
                    [payload.prop]: state.resources[payload.prop].filter((item:any) => item.id !== payload.resourceId) 
                }
            }
        case PdAdminActions.SET_UPDATE_RESOURCE:
            return {
                ...state,
                resources: {
                    ...state.resources,
                    [payload.prop]: state.resources[payload.prop].map((item:any) => item.id === payload.data.id ? {...item, ...payload.data} : item) 
                }
            }
        default:
            return state
    }
}
