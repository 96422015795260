/* eslint-disable no-loop-func */
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";
import Header from "components/common/report/Print/header";
import InfoSection from "./InfoSection";
import moment from "moment";
import { isFirefox, isSafari, browserHeights, isChrome } from "util/index";
import PrintFooter from "components/common/report/Print/footer";
import { useSchoolName } from "hooks/useSchoolName";

interface Props {
  level?: any;
}

const Print: FC<Props> = (level) => {
  const report = useSelector((state: RootState) => state.classroom.oagReport);
  const school_name = useSchoolName();
  const objectivesPerPage = 20;
  const studentsPerPage = useMemo(() => {
    if (isSafari) return 20;
    if (isFirefox) return 24;

    return 24;
  }, []);

  const { class_name, students, objectives } = useMemo(() => {
    if (report?.data) {
      return { ...report.data };
    } else {
      return { students: [], objectives: [], class_name: "" };
    }
  }, [report]);

  const totalTabs = Math.ceil(objectives.length / objectivesPerPage);

  const reportFooter: any = {
    total_tested: (
      <div className="report__tested-title oag-w-100">
        <span>Total Students Tested</span>
      </div>
    ),
    start_date: (
      <div className={`report__tested-title oag-w-100`}>
        <span className="report__tested-text oag-text ">Test Start Date </span>
      </div>
    ),
    proficient: (
      <div className="report__tested-title oag-w-100">
        <div className="d-flex justify-content-end align-items-center">
          <span style={{ marginTop: 2 }} className="oag-prof-bg">
            #
          </span>
          <span className="report__tested-text">Proficient</span>
        </div>
      </div>
    ),
    non_proficient: (
      <div className="report__tested-title oag-w-100">
        <div className="d-flex justify-content-end align-items-center">
          <span className="footer-non-prof"></span>
          <span className="report__tested-text">Non Proficient</span>
        </div>
      </div>
    ),
    // baseline_proficiency: <div className="report__tested-title oag-w-100">
    //     <span className="report__tested-text singal">Baseline Proficiency</span>
    // </div>,
    proficiency_retake: (
      <div className="report__tested-title below oag-w-100">
        <span className="report__tested-text singal">
          Proficiency after Retakes
        </span>
      </div>
    ),
    running_proficiency: (
      <div className="report__tested-title below oag-w-100">
        <span className="report__tested-text singal">Running Proficiency</span>
      </div>
    ),
  };

  const Students = useMemo(() => {
    if (!students) return;

    return students.sort((a: any, b: any) => {
      const student1 = a.name.split(",");
      const student2 = b.name.split(",");
      return student1[0].localeCompare(student2[0]);
    });
  }, [students]);

  const contentGenerator = useMemo(() => {
    let toPrint: any;

    if (!students) {
      return;
    }

    const studentsOnFirstPage = studentsPerPage - 5;

    return Array.from(Array(totalTabs).keys()).map((_, tabIndex) => {
      toPrint = [];

      const slicedObjectives = objectives?.slice(
        tabIndex * objectivesPerPage,
        tabIndex * objectivesPerPage + objectivesPerPage
      );

      let marginTop = 0;
      let labelNo = 0;
      let count = 0;
      for (let i = 0; i < slicedObjectives.length; i++) {
        if (count > 0) {
          count++;
        }

        if (slicedObjectives[i].divider) {
          if (count === 0) {
            count++;
            labelNo = slicedObjectives
              .slice(i, i + 5)
              .filter((item: any) => !!item.divider).length;
            if (marginTop < labelNo) {
              marginTop = labelNo;
            }
          }

          if (labelNo > 1) {
            slicedObjectives[i].labelClass = `step-${labelNo}`;
            labelNo--;
          }
        }

        if (count % 5 === 0) {
          labelNo = 0;
          count = 0;
        }
      }

      marginTop = marginTop * 25;

      for (
        let startIndex = 0,
          endIndex = tabIndex ? studentsPerPage : studentsOnFirstPage;
        startIndex === 0 || endIndex <= Students.length;

      ) {
        const slicedStudents = Students.slice(startIndex, endIndex);

        toPrint.push(
          <div
            key={tabIndex}
            className={`report__data objective-accountability-graph-report-print ${
              (tabIndex || startIndex) && "next_page_break"
            }`}
          >
            <div className={`${browserHeights}`}>
              <Header
                class_name={class_name || ""}
                title="Objective Accountability Graph"
                school_name={school_name}
              />
              {!tabIndex && !startIndex && <InfoSection level={level} />}
              <div className={`${tabIndex && "mt-5"}`}>
                <div className="report__data-header oag" style={{ marginTop:  marginTop}}>
                  <div className="report__data-objective oag-object"></div>
                  <div className="d-flex oag-header-width">
                    {slicedObjectives.map((objective: any, index: number) => (
                      <div
                        key={index}
                        className="report__data-questions oag-report-question"
                      >
                        {objective.divider && (
                          <div
                            className={`week-label ${
                              objective?.labelClass || ""
                            }`}
                          >
                            <p className="mb-0">{objective.divider}</p>
                          </div>
                        )}
                        <span className={`report__data-text oag-text`}>
                          <span className={`report__data-objectiveData`}>
                            {objective.objective_number}
                          </span>
                        </span>
                        <span
                          className={`report__data-count oag-border oag-count`}
                          style={{
                            position: "relative",
                            left: isChrome ? 3 : 0,
                          }}
                        ></span>
                      </div>
                    ))}
                  </div>
                  <div className="report__data-Date printDate">
                    <span className="report__bold">Date</span>
                  </div>
                  <div className="d-flex align-items-center mt-2 pt-5 text-center ms-3 oag-proficiency average-score">
                    <span>
                      Average
                      <br /> Score*
                    </span>
                  </div>
                </div>

                <div className="report-table-container print-table-body-container oag_table_width">
                  <table
                    className="report__table print-table-body"
                    style={{
                      borderCollapse: "collapse",
                      pageBreakInside: "auto",
                    }}
                  >
                    <tbody>
                      {slicedStudents.map((student: any, index: number) => {
                        const studentPoficiency =
                          student?.proficiency >= level.level?.FORMATIVE_PROFICIENCY
                            ? ""
                            : "border-non-proficient";
                        return (
                          <div
                            key={index}
                            className={`d-flex oag_width-align align-items-center ${
                              index % 2 !== 0 ? "dark-gray" : ""
                            }`}
                          >
                            <tr
                              style={{
                                pageBreakInside: "avoid",
                                pageBreakAfter: "auto",
                              }}
                              className={`${
                                index % 2 !== 0 ? "dark-gray" : ""
                              } ${index === 0 ? "first-row" : ""}`}
                            >
                              <td className="report__table-name oag-name">
                                {student.name}
                              </td>
                              <div className="d-flex align-items-center oag-print-table-body">
                                {slicedObjectives.map((o: any, idx: number) => {
                                  const objNumber = o.objective_number;
                                  const objective =
                                    student.objectives[objNumber];
                                  return (
                                    <td
                                      key={idx}
                                      style={{
                                        pageBreakInside: "avoid",
                                        pageBreakAfter: "auto",
                                      }}
                                      className={` report__table-data`}
                                    >
                                      {objective ? (
                                        <div
                                          className={`${
                                            objective?.proficiency >= 0
                                              ? objective?.proficiency >= level.level?.FORMATIVE_PROFICIENCY
                                                ? "small-box"
                                                : "border-non-proficient"
                                              : null
                                          } ${
                                            objective?.attempts > 1
                                              ? "prof-text"
                                              : ""
                                          }`}
                                        >
                                          <span
                                            className={`${
                                              objective.attempts > 1
                                                ? "pt-2"
                                                : ""
                                            } report__table-numbers font-12`}
                                          >
                                            {objective.proficiency}
                                          </span>
                                          {objective.attempts > 1 && (
                                            <span
                                              className={`oag-subscript report__table-numbers small-text`}
                                            >
                                              {objective.attempts}
                                            </span>
                                          )}
                                        </div>
                                      ) : (
                                        <div
                                          className={`${
                                            objective?.proficiency >= 0
                                              ? objective?.proficiency >= level.level?.FORMATIVE_PROFICIENCY
                                                ? ""
                                                : "border-non-proficient"
                                              : null
                                          } prof-text`}
                                        >
                                          <span
                                            className={`noObjective report__table-numbers font-12`}
                                          >
                                            -
                                          </span>
                                        </div>
                                      )}
                                    </td>
                                  );
                                })}
                              </div>
                            </tr>
                            <div className="report__data-Date bottom-11px">
                              <span className="report__bold">
                                {student?.test_date
                                  ? moment(student?.test_date).format("M-D-YY")
                                  : "-"}
                              </span>
                            </div>
                            <div
                              className={`report__table-benchmark proficiency-wrapper`}
                              style={{ marginLeft: "39px" }}
                            >
                              <div className="d-flex align-items-center">
                                {student.proficiency ? (
                                  <span
                                    className={`report__table-numbers ${studentPoficiency}`}
                                    style={{ position: "relative", left: 0 }}
                                  >
                                    {student.proficiency}
                                  </span>
                                ) : (
                                  <span
                                    className={`report__table-numbers noObjective font-12`}
                                    style={{ position: "relative", left: 0 }}
                                  >
                                    -
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                {Object.keys(reportFooter).map((row, index) => {
                  return (
                    <div
                      className={`${
                        index % 2 !== 0 ? "dark-gray" : ""
                      } report__tested oag-report`}
                    >
                      {reportFooter[row]}
                      <div className={`report__total-count`}>
                        {slicedObjectives.map((objective: any) => {
                          if (row === "start_date") {
                            const date = new Date(objective[row]);
                            const day = date.getDate();
                            const month = date.getMonth() + 1; // 1 is added because getMonth returns [0 as January , 11 as December]
                            if (objective[row]) {
                              return (
                                <div className={`report__tested-data`}>
                                  <span>
                                    <span className="report__total-count__DateEx">
                                      <span className="report__total-count__DateEx__day">
                                        {month}
                                      </span>
                                      <span className="report__total-count__DateEx__slash"></span>
                                      <span className="report__total-count__DateEx__month">
                                        {day}
                                      </span>
                                    </span>
                                  </span>
                                </div>
                              );
                            } else {
                              return (
                                <div className={`report__tested-data`}>
                                  <span>
                                    <span className="report__total-count__DateEx">
                                      -
                                    </span>
                                  </span>
                                </div>
                              );
                            }
                          } else if (row === "running_proficiency") {
                            return (
                              <div className="report__tested-questions">
                                <span
                                  className={`report__tested-data oag-belowData`}
                                >
                                  {objective.running_proficiency}
                                </span>
                                <span
                                  className={`report__tested-objective oag-print`}
                                >
                                  <span
                                    className={`report__tested-objectiveData objective`}
                                  >
                                    {objective.objective_number}
                                  </span>
                                </span>
                              </div>
                            );
                          } else {
                            return (
                              <div className={`report__tested-data `}>
                                <span>{objective[row]}</span>
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <PrintFooter
              text={
                <>
                  *Average Score includes all objectives, not just those
                  <br /> on this page.
                </>
              }
            />
          </div>
        );

        startIndex = endIndex;
        endIndex += Math.min(
          Math.max(1, Students.length - endIndex),
          studentsPerPage
        );
      }

      return <div>{[...toPrint]}</div>;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report, school_name, totalTabs]);

  return <div className="report-content">{contentGenerator}</div>;
};
export default Print;
