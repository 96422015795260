/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useDropzone } from "react-dropzone";
import JpgIcon from "assets/img/steps-jpg-icon.svg";
import PngIcon from "assets/img/steps-png-icon.svg";
import MovIcon from "assets/img/steps-mov-icon.svg";
import PdfIcon from "assets/img/pdf-alt.svg";
import { TicketType } from "./ContactSupportModal";

interface Step4Props {
  attachments: any[];
  onDrop: (attachment: any) => void;
  data: any;
}

export const Step4: React.FC<Step4Props> = ({ attachments, onDrop, data }) => {
  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop,
    accept: [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "application/pdf",
      "video/quicktime",
    ] as any,
  });

  return (
    <div className="support">
      <div className="support-container first-step">
        {data.option === TicketType.BUG && (
          <p className="support__upload-title">
            Upload screenshots demonstrating your bug or issue below by
            dragging to the dotted box or selecting Choose Files. Three
            files max (500 MB total file limit).
          </p>
        )}
        {data.option === TicketType.TEXT_BOOK_ERROR && (
          <p className="support__upload-title">
            Upload screenshots showing the textbook error by
            dragging to the dotted box or selecting Choose Files . Three
            files max (500 MB total file limit).
          </p>
        )}
        {data.option === TicketType.ASSESSMENT_ERROR && (
          <p className="support__upload-title">
            Upload screenshots showing the assessment
            error by dragging to the dotted box or selecting Choose Files . Three
            files max (500 MB total file limit).
          </p>
        )}
        {data.option === TicketType.FEEDBACK_FEATURE && (
          <p className="support__upload-title">
            Upload screenshots showing the suggestion or
            feedback by dragging to the dotted box or selecting Choose Files . Three
            files max (500 MB total file limit).
          </p>
        )}
        {data.option === TicketType.OTHER && (
          <p className="support__upload-title">
            Upload screenshots showing the other error by
            dragging to the dotted box or selecting Choose  Files . Three
            files max (500 MB total file limit).
          </p>
        )}
        <div className="support__upload">
          <div
            {...getRootProps({ className: "support__upload-content" })}
          >
            <input {...getInputProps()} />
            <div className="support__upload-wrapper">
              <div className="support__upload-icon">
                <img className="icon" src={PngIcon} alt="" />
                <img className="icon" src={JpgIcon} alt="" />
                <img className="icon" src={MovIcon} alt="" />
                <img className="icon" src={PdfIcon} alt="" />
              </div>
              <p>Drag Here</p>
            </div>
          </div>
          {attachments.map((resourceFile) => {
            return <p className="text-white mt-2 mb-0 text-center">{resourceFile?.name}</p>;
          })}
          <div className="uploads__pdf-button d-flex justify-content-center">
            <button
              className="btn success-btn"
              type="button"
              disabled={attachments.length === 3}
              onClick={() => {
                open();
              }}
            >
              Choose File
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
