import StudentSVG from 'assets/img/student-icon.svg'
import { FC } from 'react'

interface Props {
    objective_number: string,
    description: string,
    students: any[],
    fail: number,
    proficiency: number,
    onClickStudentView: Function
    total: number
}
const Record: FC<Props> = ({ objective_number, description, students, fail, proficiency, onClickStudentView, total }) => {

    return <tr className='risk-report__table-row'>
        <td className='risk-report__table-objective'>
            {objective_number}
        </td>
        <td className='risk-report__table-description'>
            {description}
        </td>
        <td className='risk-report__table-students'>
            {fail}
        </td>
        <td className='risk-report__table-proficient proficient-data'>
            <div className='risk-report__proficient'>
                <span className='risk-report__proficient-text' >{proficiency}%</span>
                <span className='risk-report__proficient-blue' style={{ width: `${proficiency}%` }}> </span>
            </div>
        </td>
        <td className='risk-report__table-view'>
            <button type='button' onClick={(e) => onClickStudentView({ objectiveNumber: objective_number, students, total })} disabled={fail === 0} className={`text-btn ${fail === 0 && 'disabled'}`}>
                <img src={StudentSVG} alt="" />
            </button>
        </td>
    </tr>
}
export default Record