
export const MainHeader = () => {

    return (
        <div className="mt-2 pe-2 d-flex justify-content-between align-items-end">

            <div className="report-risk-print__objective-col">
                <p className="text-dark font-bold mb-0 header-plain-text">objective</p>
            </div>

            <div className="d-flex report-risk-print__header-description">
                <p className="text-dark font-bold mb-0 header-plain-text">Description</p>
                <p className="text-dark font-bold report-risk-print__non-proficient-col mb-0 header-plain-text">Students <br/> Non-Proficient</p>
            </div>

            <div className="report-risk-print__proficient-col">
                <p className="text-dark font-bold mb-0 header-plain-text">Percent Proficient</p>
            </div>

        </div>
    )

}
export default MainHeader