const InfoSection = (level?: any) => {
  const performanceLevels = level?.level || { P: 70 };
  console.log("performanceLevels", performanceLevels);
  return (
    <div className="summative-objective-analysis__performance-performance">
      <p className="summative-objective-analysis__performance-title">
        Percentage Proficient in Class
      </p>
      <div className="summative-objective-analysis__performance-percentage">
        <div className="summative-objective-analysis__performance-row">
          <div className="d-flex align-items-center">
            <div className="summative-objective-analysis__performance-level proficient"></div>
            <p>70% or Above</p>
          </div>
        </div>
        <div className="summative-objective-analysis__performance-row">
          <div className="d-flex align-items-center">
            <div className="summative-objective-analysis__performance-level non-proficient"></div>
            <p>33% or Lower</p>
          </div>
        </div>
        <div className="summative-objective-analysis__Oas-objective">
          <p>OAS Objectives by Grade Level</p>
        </div>
        <div className="summative-objective-analysis__performance-row">
          <div className="d-flex align-items-center">
            <div className="summative-objective-analysis__performance-level high rounded"></div>
            <p>High Need (65%-85% not proficient)</p>
          </div>
        </div>
        <div className="summative-objective-analysis__performance-row">
          <div className="d-flex align-items-center">
            <div className="summative-objective-analysis__performance-level critical rounded"></div>
            <p>Critically Low (86%-100% not proficient)</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InfoSection;
