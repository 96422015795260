import React, { useEffect } from "react";
import IPage from "interfaces/page";
import logging from "config/logging";
import Theme from "components/theme/index";
import AnswersSummative from "components/classroom/answers/answers-summative";

const FormativeAddEditAnswers: React.FunctionComponent<IPage> = (props) => {
  
 useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props.name]);

  return (
    <>
      <Theme>
        <AnswersSummative/>
      </Theme>
    </>
  );
};

export default FormativeAddEditAnswers;
