import ActivityIcon from "components/common/svgs/activity-icon";
import ActivityIconActive from "components/common/svgs/activity-icon-active";
import ClassRoomIcon from "components/common/svgs/classroom-icon";
import ClassRoomIconActive from "components/common/svgs/classroon-icon-active";
import ManagementIcon from "components/common/svgs/magement-icon";
import ManagementIconActive from "components/common/svgs/management-icon-active";
import { useNavigate } from "react-router-dom";

interface IProps {
    name: string;
    isActive: boolean;
    tabIndex: number;
}

const NavItem = ({name, isActive, tabIndex}: IProps) => {
    const navigate = useNavigate();

    let navData = null;
    switch(name){
        case 'Activity':
            navData = {
                svg: (
                    <>
                       <ActivityIcon />
                       <ActivityIconActive />
                    </>
                ),
                to: '/activity'
            }
            break;
        case 'Classroom':
            navData = {
                svg: (
                    <>
                      <ClassRoomIcon />
                       <ClassRoomIconActive />
                    </>
                ),
                to: '/classroom'
            }
            break;
        case 'Management':
            navData = { 
                svg: (
                    <>
                        <ManagementIcon />
                       <ManagementIconActive />
                    </>
                ),
                to: '/management'
            }
            break;
        default:
            navData = {
                svg: (
                    <></>
                ),
                to: '/management'
            }
            break;

    }
    
    const { svg, to } = navData;

    return (
        <button type="button" onClick={() => navigate(to)} className={`header-links ${isActive ? 'active' : ''}`} tabIndex={tabIndex}>
            {svg}
            <p style={name === "Management" ?{marginTop: '2px'}:{}}>{name}</p>
        </button>
    )
};

export default NavItem;
