import IRoute from "interfaces/route";
import LoginPage from "pages/login";
import ForgotPasswordPage from "pages/forgotPassword";
import ActivityPage from "pages/activity";
// import ClassroomPage from "pages/classroom";
import ManagementPage from "pages/management";
import DistrictPage from "pages/district";
import SchoolPage from "pages/school";
import ClassPage from "pages/class";
import StudentPage from "pages/student";
import SummativeMonitor from "pages/monitor/summative";
import SummativeAddEditAnswers from "pages/answers/summative";
import FormativeAddEditAnswers from "pages/answers/formative";
// import FormativeMonitor from "pages/monitor/formative";
import DistrictBulkUpload from "pages/district-bulk-upload";
import ClassBulkUpload from "pages/class-bulk-upload";
import CMSSelectAssessment from "pages/cms/select-assessment";
import Assessment from "pages/cms/assessment";
import PreviewAssessment from "pages/cms/PreviewAssessment"
import Resources from "pages/resources";
import Objectives from "pages/objectives";
import GradeFrequencyDistributionReport from "components/classroom/reports/GradeFrequencyDistributionReport";
import SchoolProgressReport from "components/activity/reports/SchoolProgressReport";
import SummativeReport from "pages/SummativeReport";
import CleverBulkUpload from "pages/clever-bulk-upload";
import IRRSummative from "pages/item-response-report-summative";
import IRRFormative from "pages/item-response-report-formative";
import OAGReport from 'pages/oag-report';
import WayToAReport from 'pages/way-to-report';
import MasterReport from "pages/master-report";
import MasteryVideo from "pages/mastery-video";
import PacingCalender from "pages/pacing-calender";
import StandardRiskReport from "pages/standard-risk-report";
import StandardBasedReport from "pages/standard-based-report";
import SummativeObjectiveAnalysisReport from "pages/summative-objective-analysis";
import AuditReport from "pages/auditReport";
import SchoolAuditReport from "pages/schoolAuditReport";
import StudentsSummativeReport from "pages/studentsSummativeReport";
import SummativeStudentReport from "pages/summativeStudentReport";
import SupportTicket from "pages/supportTicket";
import SupportCenter from "pages/supportCenter";
import SuperAdminPacingCalender from "pages/super-admin-pacing-calender";
import SuperAdminTicketDetails from 'pages/SuperAdminTicketDetails'
import ProfessionalDev from "pages/professionaldev";
import OldProfessionalDev from "pages/oldprofessionaldev";
import AdminProfessionalDev from "pages/admin-professional-dev";
import Discussion from "pages/discussions";
// import TempPage from "pages/temp-page";
import ClassroomResources from "pages/classroomResources";
import ReportCards from "pages/report-cards";
// import TempPage from "pages/temp-page";


// const prodMode = window.location.host==="teacher.aplustesting.org" || window.location.host==="aplustesting.org";

const routes: IRoute[] = [
  {
    path: "/",
    name: "Login",
    component: LoginPage
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage
  },
  // {
  //   path: "/summer",
  //   name: "Summer",
  //   component: LoginPage
  // },
  {
    path: "/reset-password",
    name: "Reset",
    component: ForgotPasswordPage
  },
  {
    path: "/activity",
    name: "Activity",
    component: ActivityPage
  },
  {
    path: "/activity/report/formative-progress/:id",
    name: "FormativeProgressReport",
    component: SchoolProgressReport
  },
  {
    path: "/classroom",
    name: "Classroom",
    component: ProfessionalDev
  },
  {
    path: "/classroom_old",
    name: "Classroom",
    component: OldProfessionalDev
  },
  {
    path: "/classroom/resources",
    name: "Resources",
    component: ClassroomResources
  },
  {
    path: "/classroom/report-cards",
    name: "Report Cards",
    component: ReportCards
  },
  {
    path: "/classroom/:school/:classroom",
    name: "Classroom",
    component: ProfessionalDev
  },
  // {
  //   path: "/classroom/summative/monitor/:id/:classroom",
  //   name: "Summative Monitor",
  //   component: SummativeMonitor
  // },
  {
    path: "/classroom/monitor/:classroom",
    name: "Summative Monitor",
    component: SummativeMonitor
  },
  // {
  //   path: "/classroom/formative/monitor/:id/:classroom",
  //   name: "Formative Monitor",
  //   component: FormativeMonitor
  // },
  {
    path: "/classroom/summative/answer/:id/:classroom",
    name: "Summative Answers",
    component: SummativeAddEditAnswers
  },
  {
    path: "/classroom/formative/answer/:id/:classroom",
    name: "Formative Answers",
    component: FormativeAddEditAnswers
  },
  {
    path: "/management",
    name: "Management",
    component: ManagementPage
  },
  {
    path: "/management/district/:id",
    name: "Discrict",
    component: DistrictPage
  },
  {
    path: "/management/school/:id",
    name: "School",
    component: SchoolPage
  },
  {
    path: "/management/class/:id",
    name: "Class",
    component: ClassPage
  },
  {
    path: "/management/student/:id",
    name: "Student",
    component: StudentPage
  },
  {
    path: "/management/import/district",
    name: "Import District",
    component: DistrictBulkUpload
  },
  {
    path: "/management/import/class",
    name: "Import Class",
    component: ClassBulkUpload
  },
  {
    path: "/management/import/clever",
    name: "Import Clever",
    component: CleverBulkUpload
  },
  {
    path: "/cms/select-assessment",
    name: "Select Assessment",
    component: CMSSelectAssessment
  },
  {
    path: "/cms/:assessmentType/:id",
    name: "Assessment",
    component: Assessment
  },
  {
    path: "/preview/:assessmentType/:id",
    name: "Assessment",
    component: PreviewAssessment
  },
  {
    path: "/preview/:assessmentType/:id/:questionId",
    name: "Assessment",
    component: PreviewAssessment
  },
  {
    path: "/management/resources",
    name: "Resources",
    component: Resources
  },
  {
    path: "/management/objectives",
    name: "Objectives",
    component: Objectives
  },
  {
    path: "classroom/report/summative/:id/:classroom",
    name: "SummativeReports",
    component: SummativeReport
  },
  {
    path: "/classroom/report/grade-frequency-distribution/:id",
    name: "GradeFrequencyReport",
    component: GradeFrequencyDistributionReport
  },
  {
    path: "classroom/report/item-response-report-summative/:id/:classroom",
    name: "ItemResponseReportSummative",
    component: IRRSummative
  },
  {
    path: "classroom/report/item-response-report-formative/:id/:classroom",
    name: "ItemResponseReportFormative",
    component: IRRFormative
  },
  {
    path: "classroom/report/oag-report/:classroom",
    name: "OAGReport",
    component: OAGReport
  },
  {
    path: "classroom/report/master-report/:classroom_id",
    name: "MasterReport",
    component: MasterReport
  },
  {
    path: "/mastery-videos",
    name: "MasteryVideo",
    component: MasteryVideo
  },
  {
    path: "/pacing-calendar",
    name: "PacingCalender",
    component: PacingCalender
  },
  {
    path: "classroom/report/standard-at-risk/:classroom_id/:summative_id",
    name: "StandardRiskReport",
    component: StandardRiskReport
  },
  {
    path: "classroom/report/standard-base-report/:classroom_id",
    name: "StandardBasedReport",
    component: StandardBasedReport
  },
  {
    path: "/management/report/summative-objective-analysis/:id",
    name: "summativeObjectiveAnalysis",
    component: SummativeObjectiveAnalysisReport
  },
  {
    path: 'management/report/audit',
    name: 'Audit-Report',
    component: AuditReport
  },
  {
    path: 'management/report/audit/:id',
    name: 'School-Audit-Report',
    component: SchoolAuditReport
  },
  {
    path: '/classroom/report/summative/student/:classroomId/:summativeId',
    name: 'SummativeStudentReport',
    component: StudentsSummativeReport
  },
  {
    path: '/classroom/report/summative/student/:classroomId/:summativeId/:studentId',
    name: 'School-Audit-Report',
    component: SummativeStudentReport
  },
  {
    path: "/support-tickets",
    name: "Support Tickets",
    component: SupportTicket
  },
  {
    path: "/super-admin/support-center",
    name: "Support Center",
    component: SupportCenter
  },
  {
    path: "/super-admin/suggested-pacing",
    name: "SuperAdminPacingCalender",
    component: SuperAdminPacingCalender
  },
  {
    path: "/super-admin/ticket-details/:id",
    name: "SuperAdminTicketDetails",
    component: SuperAdminTicketDetails
  },
  {
    path: "/professional-development",
    name: "ProfessionalDevelopment",
    component: ProfessionalDev
  },
  {
    path: "/super-admin/professional-development",
    name: "AdminProfessionalDevelopment",
    component: AdminProfessionalDev
  },
  {
    path: "classroom/report/way-to-report/:id",
    name: "WayToAReport",
    component: WayToAReport
  },
  {
    path: "/discussions",
    name: "Discussions",
    component: Discussion
  }
];

export default routes;
