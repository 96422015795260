/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { Modal } from "react-bootstrap";

interface SendEmailConfirmationModalProps {
  isShow: boolean;
  onClose: () => void;
  message: string;
  onYes: () => void;
}

const SendEmailConfirmationModal: React.FC<SendEmailConfirmationModalProps> = ({
  isShow,
  onClose,
  onYes,
  message
}) => {
  
  return (
    <Modal centered show={isShow} onEscapeKeyDown={onClose}>
      <div className="modal__header">
        <h6 className="modal__header-title">
          Send Email
        </h6>
      </div>
      <div className="modal__body">
        <p className="modal__body-text">
         {message}
        </p>
      </div>
      <div className="modal__footer">
        <button
          className="btn cancel-btn"
          onClick={onClose} 
        >
          No
        </button>
        <button
          className="btn success-btn btn-lg"
          onClick={onYes} 
        >
          Yes
        </button>
      </div>
    </Modal>
  );
};

export default SendEmailConfirmationModal;