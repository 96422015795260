/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import SearchIcon from "assets/img/search-icon.svg";
import {default as SearchIconSVG} from "components/common/svgs/search-icon";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAddEditAnswersSummative,
  updateSummativeAnswers,
  addSummativeAnswers,
  deleteSummativeScore,
} from "redux/actionCreators/classroom";
import EditIcon from "assets/img/edit-table.svg";
import DeleteIcon from "assets/img/delete-table.svg";
import AnswerIcon from "assets/img/AddNewAnswers.svg";
import logIcon from "assets/img/answers-log-add.svg";
import QuestionsAnswersModal from "./questions-answers-modal";
import { DeleteConfirmationModal } from "../../common/deleteConfirmation";
import { LogListModal } from "../modals/logListModal";
//import ChevronIcon from "assets/img/chevron-down-table.svg";
import ChevronAddAnswersIcon from "assets/img/Chevron-Gray.svg";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import BackButton from "components/common/backButton";

interface RootState {
  auth: any;
  activity: any;
  classroom: any;
}

const AnswersSummative: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const app = useSelector((state: RootState) => state);
  const { id, classroom }: any = useParams();
  const [students, setStudents] = useState([]);
  const [openQuestionsModal, toggleQuestionsModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<any>(null);
  const [openIds, setOpenIds] = useState<any[]>([]);
  const [recordId, setRecordId] = useState(0);
  const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteScoreId, setDeleteScoreId] = useState(0);
  const [deleteScoreStId, setDeleteScoreStId] = useState(0);
  const [logModal, setLogModal] = useState(false);
  const [logs, setLogs] = useState();

  useEffect(() => {
    const init = async () => {
      await fetchStudents();
    };
    !app.classroom.updateAnswerLoading && init();
  }, [app.classroom.updateAnswerLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchStudents = async (search: string = "") => {
    setIsLoading(true);
    const students_summative = await getAddEditAnswersSummative(
      parseInt(classroom),
      parseInt(id)
    );
    const data: any = [];
    if (search) {
      const filtersData = students_summative.data.data.studentsList?.filter(
        (std: any) =>
          (std.students.last_name + ", " + std.students.first_name)
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          std.students.school_student_id.toString().includes(Number(search))
      );
      filtersData.map((x: any) => data.push(x.id));
      setStudents(filtersData);
      setIsLoading(false);
    } else {
      students_summative.data.data.studentsList.map((x: any) =>
        data.push(x.id)
      );
      setStudents(students_summative.data.data.studentsList);
      setIsLoading(false);
    }
  };

  const backButton = (e: any) => {
    if(e)
    e.preventDefault();
    navigate("/classroom");
  };

  const handleAddEditIconClick = (isEdit: boolean, data: any, rid: number) => {
    const x = _.cloneDeep(data);
    toggleQuestionsModal(true);
    setIsEdit(isEdit);
    if (isEdit) {
      setSelectedStudent(x);
    } else {
      const questions = [...Array(50)].map((ele, index) => {
        return { [`question_${index + 1}_response`]: "" };
      });
      x.scores.push({ student_answers: questions });
      setSelectedStudent(x);
    }
    setRecordId(isEdit ? rid : x.scores.length - 1);
  };

  const onChangeAnswer = (
    recordId: number,
    question_id: number,
    answer: string
  ) => {
    const scores = selectedStudent?.scores.map((item: any, index: number) => {
      if (index === recordId) {
        item.student_answers = item.student_answers.map(
          (que: any, i: number) => {
            if (i === question_id) {
              if (que[`question_${question_id + 1}_response`] === answer) {
                que[`question_${question_id + 1}_response`] = "";
              } else {
                que[`question_${question_id + 1}_response`] = answer;
              }
            }
            return que;
          }
        );
      }
      return item;
    });
    setSelectedStudent({ ...selectedStudent, scores });
  };

  const onSumbitAnswersModal = (
    edit_log: string,
    _id: number,
    questions: any[]
  ) => {
    if (isEdit) {
      let data = {
        student_id: selectedStudent.fk_student_id,
        summative_id: Number(id),
        classroom_id: Number(classroom),
        edit_log: edit_log,
        id: _id,
      };
      questions.map((item) => {
        data = { ...data, ...item };
      });
      dispatch(updateSummativeAnswers(data));
    } else {
      let data = {
        student_id: selectedStudent.fk_student_id,
        summative_id: Number(id),
        classroom_id: Number(classroom),
        edit_log: edit_log,
      };
      questions.map((item) => {
        data = { ...data, ...item };
      });
      dispatch(addSummativeAnswers(data));
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSkeletonLoad(false);
    }, 800);

    return () => {
      clearTimeout(timer);
      setSkeletonLoad(true);
    };
  }, [isLoading]);

  const tableBodyHeight = skeletonLoad ? "370px" : "auto";

  const handleCollapse = (student_id: number) => {
    if (openIds?.find((stId: any) => stId === student_id)) {
      setOpenIds(openIds.filter((stId) => stId !== student_id));
    } else {
      setOpenIds([...openIds, student_id]);
    }
  };

  const onCloseQuestionModal = () => {
    toggleQuestionsModal(false);
    setIsEdit(false);
    setSelectedStudent(null);
    setRecordId(0);
  };

  const openDeleteConfirmation = (score_id: number, studentId: number) => {
    setDeleteModal(true);
    setDeleteScoreId(score_id);
    setDeleteScoreStId(studentId);
  };

  const handleDelete = () => {
    dispatch(deleteSummativeScore(deleteScoreId, id, deleteScoreStId));
    setDeleteScoreId(0);
    setDeleteScoreStId(0);
    setTimeout(() => {
      setDeleteModal(false);
    }, 500);
  };

  return (
    <>
      <div className="text-white">
        <div className="section">
          <div className="section-header">
            <div className="section-header-heading answer-section-header-heading">              
              <BackButton onClick={backButton} onKeyDown = {(e: React.KeyboardEvent<HTMLSpanElement>)=>{e.key==="Enter"&&backButton(e)}} /> 
              <h3 className="section-header-title">
                Add/Edit Answers: {sessionStorage.getItem("SUMMATIVE_NAME")}
              </h3>
            </div>
          </div>
          <div className="section-search light-bg-gray add-border">
            <div className="d-flex align-items-center">
              <div className="section-search-field">
                <div className="icon">
                  <a href="javascript:void(0)">
                    <SearchIconSVG title="Search" />
                    
                  </a>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Students"
                  onChange={(e: any) => {
                    fetchStudents(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="answer-table-container light-bg-gray">
            <div className="table-responsive">
              <table className="answer__table dark-text-color">
                <thead>
                  <tr className="answer__table-row">
                    <th className="answer__table-arrow"></th>
                    <th className="answer__table-summativeName remove-shadow">
                      Student Name
                    </th>
                    <th className="answer__table-id remove-shadow">
                      Student ID <br />
                      Last 5
                    </th>
                    <th className="answer__table-attempt remove-shadow">
                      Attempt
                    </th>
                    <th className="answer__table-date remove-shadow">
                      Assessment <br /> Date
                    </th>
                    <th className="answer__table-score remove-shadow">Score</th>
                    <th className="answer__table-log remove-shadow">log</th>
                    <th className="answer__table-edit remove-shadow">
                      Edit <br /> Answers
                    </th>
                    <th className="answer__table-add remove-shadow">
                      Add <br /> New Answers
                    </th>
                    <th className="answer__table-delete remove-shadow">
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody style={{ height: tableBodyHeight }}>
                  {isLoading === false && skeletonLoad === false ? (
                    <>
                      {students.length ? (
                        <>
                          {students.map((x: any, i: any) => {
                            const data = x.scores?.length ? x.scores[0] : null;
                            return (
                              <>
                                <tr className="answer__table-row answer__table-row-border">
                                  <td className="arrow-data">
                                    <div
                                      tabIndex={0}
                                      role="button"
                                      onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                          handleCollapse(
                                            x.students.school_student_id
                                          )
                                        }
                                      }}
                                      className={
                                        x.scores?.length > 1
                                          ? "arrow"
                                          : "d-none"
                                      }
                                      onClick={() =>
                                        handleCollapse(
                                          x.students.school_student_id
                                        )
                                      }
                                    >
                                      <span
                                        className={`accordion__header-button table-btn ${!openIds.find(
                                          (stId) =>
                                            stId ===
                                            x.students.school_student_id
                                        )
                                            ? "rotate-0"
                                            : ""
                                          }`}
                                      >
                                        <img
                                          src={ChevronAddAnswersIcon}
                                          alt="arrow"
                                          className={
                                            openIds.find(
                                              (stId) =>
                                                stId ===
                                                x.students.school_student_id
                                            )
                                              ? "rotate"
                                              : ""
                                          }
                                        />
                                      </span>
                                    </div>
                                  </td>
                                  <td className="summativeName-data">
                                    {x.students.last_name},{" "}
                                    {x.students.first_name}
                                  </td>
                                  <td className="summativeId-data">
                                    {x.students.school_student_id?.slice(-5)}
                                  </td>
                                  <td className="text-center">
                                    {data?.attempts || "-"}
                                  </td>
                                  <td className="text-center">
                                    {data?.last_test
                                      ? moment(data?.last_test).format("M-D-YY")
                                      : "-"}
                                  </td>
                                  <td className="text-center">
                                    {Number(data?.score_percentage) >= 0
                                      ? data?.score_percentage + "%"
                                      : "-"}
                                  </td>
                                  <td className="log-data">
                                    {x?.edit_logs?.length ? (
                                      <img
                                        tabIndex={0}
                                        role="button"
                                        onKeyDown={(e) => {
                                          if (e.key === 'Enter') {
                                            setLogs(x);
                                            setLogModal(true);
                                          }
                                        }}
                                        src={logIcon}
                                        alt="Logo"
                                        className="management__table-editBtn"
                                        onClick={() => {
                                          setLogs(x);
                                          setLogModal(true);
                                        }}
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td className="text-center">
                                    {data?.attempts ? (
                                      <img
                                        tabIndex={0}
                                        role="button"
                                        onKeyDown={(e) => {
                                          if (e.key === 'Enter') {
                                            handleAddEditIconClick(true, x, 0)
                                          }
                                        }}
                                        onClick={() =>
                                          handleAddEditIconClick(true, x, 0)
                                        }
                                        src={EditIcon}
                                        alt="Edit"
                                        className="management__table-editBtn me-0"
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <img
                                      tabIndex={0}
                                      role="button"
                                      onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                          handleAddEditIconClick(false, x, 0)
                                        }
                                      }}
                                      onClick={() =>
                                        handleAddEditIconClick(false, x, 0)
                                      }
                                      src={AnswerIcon}
                                      alt="Answer"
                                      className="management__table-editBtn me-0"
                                    />
                                  </td>
                                  <td className="delete-data">
                                    {data?.attempts ? (
                                      <img
                                        tabIndex={0}
                                        role="button"
                                        onKeyDown={(e) => {
                                          if (e.key === 'Enter') {
                                            openDeleteConfirmation(
                                              data?.id,
                                              x.fk_student_id
                                            )
                                          }
                                        }}
                                        onClick={() =>
                                          openDeleteConfirmation(
                                            data?.id,
                                            x.fk_student_id
                                          )
                                        }
                                        src={DeleteIcon}
                                        alt="Trash"
                                        className="management__table-editBtn me-0"
                                      />
                                    ) : (
                                      <span className="delete-dash">-</span>
                                    )}
                                  </td>
                                </tr>
                                {openIds.find(
                                  (stId) =>
                                    stId === x.students.school_student_id
                                ) &&
                                  x.scores.map((item: any, j: number) => {
                                    if (j > 0) {
                                      return (
                                        <tr
                                          className={
                                            i % 2 === 0
                                              ? "answer__table-row"
                                              : "answer__table-row"
                                          }
                                        >
                                          <td
                                            className={i % 2 === 0 ? "" : ""}
                                          ></td>
                                          <td
                                            className={i % 2 === 0 ? "" : ""}
                                          ></td>
                                          <td
                                            className={i % 2 === 0 ? "" : ""}
                                          ></td>
                                          <td className="text-center">
                                            {item?.attempts || "-"}
                                          </td>
                                          <td className="text-center">
                                            {item?.last_test
                                              ? moment(item?.last_test).format(
                                                "M-D-YY"
                                              )
                                              : "-"}
                                          </td>
                                          <td className="text-center">
                                            {item?.score_percentage + "%"}
                                          </td>
                                          <td className="log-data">-</td>
                                          <td className="text-center">
                                            <img
                                              tabIndex={0}
                                              role="button"
                                              onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                  item?.attempts &&
                                                    handleAddEditIconClick(
                                                      true,
                                                      x,
                                                      j
                                                    )
                                                }
                                              }}
                                              onClick={() =>
                                                item?.attempts &&
                                                handleAddEditIconClick(
                                                  true,
                                                  x,
                                                  j
                                                )
                                              }
                                              src={EditIcon}
                                              alt="Edit"
                                              className={`management__table-editBtn ${item?.attempts
                                                  ? ""
                                                  : "disable-btn"
                                                }`}
                                            />
                                          </td>
                                          <td className="text-center"></td>
                                          <td className="delete-data">
                                            <img
                                              tabIndex={0}
                                              role="button"
                                              onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                  item?.attempts &&
                                                    openDeleteConfirmation(
                                                      item.id,
                                                      x.fk_student_id
                                                    )
                                                }
                                              }}

                                              onClick={() =>
                                                item?.attempts &&
                                                openDeleteConfirmation(
                                                  item.id,
                                                  x.fk_student_id
                                                )
                                              }
                                              src={DeleteIcon}
                                              alt="Delete"
                                              className={`management__table-editBtn ${item?.attempts
                                                  ? ""
                                                  : "disable-btn"
                                                }`}
                                            />
                                          </td>
                                        </tr>
                                      );
                                    }
                                  })}
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <td colSpan={10} className="no-admin">
                            No students found
                          </td>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <TableSkeleton columns={10} count={10} height={36} />
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {openQuestionsModal && (
        <QuestionsAnswersModal
          customClass=""
          isShow={openQuestionsModal}
          onClose={onCloseQuestionModal}
          onSave={onSumbitAnswersModal}
          isEdit={isEdit}
          selectedStudent={selectedStudent}
          recordId={recordId}
          onChangeAnswer={onChangeAnswer}
          students={students}
        />
      )}
      <DeleteConfirmationModal
        loading={app.classroom.updateAnswerLoading}
        isShow={deleteModal}
        headerText={`Delete Score`}
        bodyText={`Are you sure you want to delete this score ?`}
        onCancel={() => setDeleteModal(false)}
        onDelete={handleDelete}
      />
      <LogListModal
        isShow={logModal}
        logs={logs}
        onClose={() => setLogModal(false)}
      />
    </>
  );
};

export default AnswersSummative;
