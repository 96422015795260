import DrodownIcon from "assets/img/accordian-box-icon.png";
import HandGestureIcon from "assets/img/handGesture.svg";
import NoChatAvailable from "assets/img/no-chat-1.svg";
import NoChatHand from "assets/img/no-chat-hand.svg";
import { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import { Chat, STATUS, TYPE } from "interfaces/supportTicketSuperAdmin";
import { createAdminMessageObject } from "util/createMessagePayload";
import QuickResponseContent from "./quickResponseContent";
import ToolTip from "components/common/Tooltip";
import { SuperAdminChatboxContext } from "contexts/superAdminChatbox";
import MyTime from "./MyTime";
import { ChatSocketContext } from "contexts/socketContext";
import socketKeys from "util/types/socket-keys";
import { isMac } from "util/shared/OperatingSystem";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";
import { saveContactSupportTicket } from "services/suppot-ticket";
import { toast, ToastContainer } from "react-toastify";
import { StatusIds } from "interfaces/superAdminChatbox";
import ChatHeaderSkeleton from "components/common/skeleton/chatSkeletons/ChatHeaderSkeleton";
import AssignModal from "./AssignModal";
import { getAdmins } from "services/supportTicketSuperAdmin";
import NewTicketModel from "./newTicketModal";
// import checkMark from 'assets/img/checked-white.svg'
import { useOnHoverOutside } from "hooks/useOnHoverOutside";
import { playSound } from "util/index";
import { v4 as uuidv4 } from "uuid";

const resolveChatInitState = {
  id: "",
  respondent_id: "",
};
// Chat
const { SOCKET_SUPER_ADMIN_CONNECTION_COUNT } = socketKeys;

export const quickMessages: any = {
  "1": "Hello! How may I assist you today?",
  "2": "Please wait while I check your account.",
  "3": "Unfortunately, I am unable to resolve this issue, so I’m creating a Support Ticket. You’ll be able to check its status in the user menu (by your name, upper-right).",
  "4": "Thank you for using Alpha Plus! Come back anytime we can help.",
};

const AdminChatBox = ({ onClose }: any) => {
  const {
    setChatById,
    setUnreadMessagesCount,
    fetchActiveChats,
    fetchPreviousChatById,
    singleChatQuery,
    setChatQueryParams,
    resolveChat,
    activeChats,
    fetchChatById,
    putChat,
    singleAdminChat,
    unsetChatById,
    sendMessageSuperAdmin,
    onStatusChange,
    setActiveChatsAvailable,
    resolveChatFromReducer,
    assignChatToAdmin,
    unsetSuperAdminChat,
    videoOptions,
    addAttachmentToTicket,
    setGlobalStatus,
    setSuperAdminChatMessage,
    unsetSuperAdminChatMessage,
  } = useContext(SuperAdminChatboxContext);

  const {
    socketData,
    emitSuperAdminChatUnsubscribe,
    subscribeToAllChats,
    emitSuperAdminConnect,
    emitSuperAdminChatSubscribe,
    chatSubscribeNotification,
    setCurrentStatus,
    superAdminMessages,
    setSuperAdminChatOpen,
    emitTypingEvent,
    superAdminChatTyping,
    setChatResolved,
    setChatAssigned,
    setChatNotification,
  } = useContext(ChatSocketContext);

  const { auth } = useSelector((state: RootState) => state);

  const notificationsRef = useRef<any>(null!);
  const allowFetching = useRef<boolean>(true);
  const messageCountRef = useRef<number>(10);
  const textAreaRef = useRef<HTMLTextAreaElement>(null!);
  const classesDropdownRef = useRef<any>(null);
  const ticketsRef = useRef<any>(null!);

  const [chatToAssigned, setChatToAssigned] = useState<any>(null!);
  const [ticketAdmin, setTicketAdmin] = useState<any>(null!);
  const [flashName, setFlashName] = useState<any>(null!);
  const [showAssignModal, setShowAssignModal] = useState<Boolean>(false);
  const [currentAdmin, setCurrentAdmin] = useState<number>(null!);
  const [loading, setLoading] = useState(true);
  const [selectedChatId, setSelectedChatId] = useState<number>(null!);
  const [message, setMessage] = useState("");
  const [showQuickResponseContent, setShowQuickResponseContent] =
    useState(false);
  const [resolveChatID, setResolveChatID] = useState(-1);
  const [fadeOutChatBox, setFadeOutChatBox] = useState(-1);
  const [chatToResolve, setChatToResolve] = useState<any>(resolveChatInitState);
  const [clickedKeys, setClickedKeys] = useState<number>(-1);
  const [resolveChatCheckMark, setResolveChatCheckMark] =
    useState<Boolean>(false);
  const [createTicketCheckMark, setCreateTicketCheckMark] =
    useState<Boolean>(false);
  const [createTicketSpinner, setCreateTicketSpinner] =
    useState<Boolean>(false);
  const [isMenuDropDownOpen, setMenuDropDownOpen] = useState<Boolean>(false);
  const [isTicketMenuDropDownOpen, setTicketMenuDropDownOpen] =
    useState<Boolean>(false);
  const [allowAddMessage, setAllowAddMessage] = useState(true);

  const [displayCrossButton, setDisplayCrossButton] = useState<Boolean>(false);
  const [assignChatLoader, setAssignChatLoader] = useState<any>({
    chat_id: "",
    loader: false,
  });

  const {
    id,
    messages = [],
    messages_count,
    from_archived = false,
    district = "",
    sent_by,
    district_id,
    school,
    school_id,
    classrooms,
    tickets,
  } = (singleAdminChat || {}) as any;

  const classCount = classrooms?.length;
  const isChat = Object.keys(singleAdminChat || {}).length > 0;
  const {
    chats,
    available,
    chat_statuses,
    active_admins,
    status,
    global_status,
    assigned_chats,
  } = activeChats as any;
  const statusId =
    chat_statuses?.find((item: any) => status === item.status)?.id || 0;

  const remainingChats = chats?.length - 5;

  const isActiveAdmins = active_admins?.length > 0;

  const filteredChats = chats.slice(0, 5);
  const isChatsAvailable = assigned_chats || filteredChats.length > 0;
  const isSendButtonDisabled =
    !isChat ||
    !message.replace(/\s+/g, "") ||
    (statusId !== StatusIds.AVAILABLE && !isChatsAvailable);

  const [activeAdminHeight, activeAdminTop] =
    active_admins?.length === 1
      ? [40, -60]
      : active_admins?.length === 2
      ? [91, -88]
      : active_admins?.length >= 3
      ? [115, -110]
      : [64, -60];
  const [classesDropdownHeight, classesDropdownTop] =
    classrooms?.length === 1
      ? ["auto", -42]
      : classrooms?.length === 2
      ? ["auto", -73]
      : classrooms?.length >= 3
      ? [103, -104]
      : [43, -60];
  const [ticketDropdownHeight, ticketDropdownTop] =
    tickets?.length === 1
      ? ["auto", -42]
      : tickets?.length === 2
      ? ["auto", -73]
      : tickets?.length >= 3
      ? [103, -104]
      : [43, -60];

  const onChangeMessage = (e: any) => {
    // Has to put this condition because it was adding one extra line when we send message by clicking
    // on ctrl + Enter ... Because first it ran onKeyDown and then onChange. Safari issue only.
    if (allowAddMessage) {
      setMessage(e.target.value);
      emitTypingEvent(singleAdminChat?.id);
    } else {
      setAllowAddMessage(true);
    }
  };

  const getNextChatIndex = (chats: any[]) => {
    // const user = JSON.parse(Cookies.get('user') || "");
    let nextChatIndex = -1;
    let isUnreadChatFound = false;

    chats.forEach((item: any, index: number) => {
      if (!isUnreadChatFound && parseInt(item.unread_count) > 0) {
        isUnreadChatFound = true;
        nextChatIndex = index;
      }

      if (!isUnreadChatFound && nextChatIndex === -1) {
        nextChatIndex = index;
      }
    });

    return nextChatIndex;
  };

  const createMessagePayload = (message: string) => {
    const currentColorData = auth?.colorProfile?.selected;
    const colorCode =
      auth?.colorProfile?.colors?.find(
        (item: any) => item.id === currentColorData.fk_color_code_id
      )?.code || "";

    const messagePayload = createAdminMessageObject(
      messages,
      message,
      colorCode
    );

    setMessage("");

    const tempId = uuidv4();

    return { messagePayload, tempId };
  };

  const sendMessage = async (quickMessage: string = null!) => {
    const { messagePayload, tempId } = createMessagePayload(
      quickMessage || message
    );

    const isSuccess = await sendMessageSuperAdmin(
      {
        chatId: id,
        type: TYPE.MESSAGE,
      },
      { ...messagePayload, tempId }
    );
    !isSuccess && setMessage(quickMessage || message);
  };

  const onClickSend = async (quickMessage: string = null!) => {
    if (!quickMessage && (!message || !isChat)) {
      return;
    }

    textAreaRef.current?.focus();
    const msg = quickMessage ? quickMessage : message;
    if (!singleAdminChat.respondent_id) {
      // await assignChat(singleAdminChat);
      const { messagePayload, tempId } = createMessagePayload(msg);
      setSuperAdminChatMessage(messagePayload, tempId);
      const assigned = await assignChat(singleAdminChat);
      if (assigned) {
        const chat = await putChat({
          chatId: id,
          type: TYPE.MESSAGE,
          message: msg,
        });

        if (!chat) {
          unsetSuperAdminChatMessage(messagePayload, tempId);
        } else {
          setChatById(chat);
        }
      }
      return;
    }

    sendMessage(msg);
  };

  const onClickCross = async (chat: Chat) => {
    if (!chat.id) {
      return;
    }

    setChatToResolve(chat);
    setResolveChatID(chat.id);
  };

  const onClickResolveChat = async (action: string) => {
    if (!Object.keys(chatToResolve).length || !chatToResolve.respondent_id) {
      return;
    }

    if (action === "resolveChat") {
      setResolveChatCheckMark(true);
    } else if (action === "createTicket") {
      setCreateTicketSpinner(false);
      setCreateTicketCheckMark(true);
    }

    const chatID = chatToResolve.id;
    setFadeOutChatBox(chatID);

    await resolveChat({
      chatId: chatID,
      type: TYPE.STATUS,
      status: STATUS.RESOLVED,
    });

    setChatResolved(true);
    emitSuperAdminChatUnsubscribe(chatID);
    setChatToResolve(resolveChatInitState);

    // if (remainingChats > 0) {
    await fetchActiveChats();
    // }

    if (chats.length > 0) {
      const filteredChats = chats.filter((item: any) => chatID !== item.id);
      const nextChatIndex = getNextChatIndex(filteredChats);
      nextChatIndex !== -1 && getChatById(filteredChats[nextChatIndex].id);
    }

    if (action === "resolveChat") {
      setResolveChatCheckMark(false);
    } else if (action === "createTicket") {
      setCreateTicketCheckMark(false);
    }
  };

  const openTicketModal = async () => {
    setCreateTicketSpinner(true);
    const adminName = singleAdminChat.sent_by;
    const response = await getAdmins(adminName);
    setCreateTicketSpinner(false);
    if (response) {
      const { admins } = response;
      const selectedAdmin = admins.find(
        (item: any) => item.id === singleAdminChat.sent_by_id
      );
      setTicketAdmin(selectedAdmin);
    }
  };

  const onCreateTicket = async (data: any) => {
    const messagesToSave = "This ticket has been generated from Chat.";
    let payload: any = {
      attachments: data.attachments || [],
      preferred_contact: "",
      message: data.message || messagesToSave,
      issue_type: data.issue_type,
      ...(chatToResolve.school_id && { school: chatToResolve.school_id }),
      school_name: chatToResolve.school || "",
      classroom: chatToResolve.classrooms[0]?.classroom_id || 0,
      classroom_name: chatToResolve.classrooms[0]?.classroom || "",
      support_chat_id: singleAdminChat.id,
      ...(chatToResolve.district_id && { district: chatToResolve.district_id }),
      resource: data.resource,
      summary: data.summary,
      ticket_for_admin: ticketAdmin.id,
    };

    if (auth.currentUser.role === "District Admin") {
      payload.district = chatToResolve.district_id || 0;
      payload.district_name = chatToResolve.district || "";
    }

    // if (chatToResolve.messages.length > 0) {
    //     payload.summary = chatToResolve.messages[chatToResolve.messages.length - 1].message || "";
    //     payload.screen = chatToResolve.messages[chatToResolve.messages.length - 1].message || "";
    // }

    const response = await saveContactSupportTicket(payload);
    if (response?.data) {
      onClickResolveChat("createTicket");
      setTicketAdmin(null!);
    }

    return response?.code;
  };

  const getChatById = (chatId: any) => {
    setSelectedChatId(chatId);
    fetchChatById({ ...singleChatQuery, offset: 0, chatId });
  };

  const onClickChat = async (chat: Chat) => {
    if (chat.id === singleAdminChat?.id) {
      return;
    }
    if(resolveChatID){
      setChatToResolve(resolveChatInitState);
      setResolveChatID(-1);
    }
    getChatById(chat.id);
  };

  const assignChat = async (chat: Chat) => {
    const newChat = await putChat({
      chatId: chat.id,
      type: TYPE.ASSIGNEE,
    });
    emitSuperAdminChatSubscribe(newChat.id);
    setChatAssigned(true);
    localStorage.removeItem("chat_notification_sound");
    setChatNotification((prev: any) => {
      if (prev) {
        const { new_chats, ...otherData } = prev;
        return {
          ...otherData,
        };
      } else {
        return null!;
      }
    });
    return newChat;
  };

  const onClickAssignToMe = async (
    chat: Chat,
    respondent_id: number,
    e?: any
  ) => {
    e?.stopPropagation();
    if (
      chat.respondent_id ||
      (statusId !== StatusIds.AVAILABLE && !isChatsAvailable)
    ) {
      onClickChat(chat);
      const isActiveAdmins = active_admins.length > 1;
      if (isActiveAdmins && respondent_id) {
        setCurrentAdmin(respondent_id);
        setShowAssignModal(true);
      }

      if (!isActiveAdmins) {
        setFlashName(chat.id);
        setTimeout(() => {
          setFlashName(null!);
        }, 800);
      }
      return;
    }

    if (!chatToAssigned) {
      setAssignChatLoader(() => {
        return {
          chat_id: chat.id,
          loader: true,
        };
      });
      setChatToAssigned(chat.id);
      await assignChat(chat);
      setChatToAssigned(null);
      await fetchChats();
      onClickChat(chat);

      setAssignChatLoader(() => {
        return {
          chat_id: "",
          loader: false,
        };
      });
    }
  };

  const onClickClose = () => {
    // unsubscribeToAllChats(activeChats.chats);
    unsetChatById();
    onClose();
    setSuperAdminChatOpen(false);

    document.body.style.overflow = "unset";
  };

  const onClickMessage = (message: string) => {
    if (message) {
      onClickSend(message);
      setShowQuickResponseContent(false);
    }
  };

  const onScrollMessages = () => {
    if (!allowFetching.current) return;

    const scrollHeight = notificationsRef?.current?.scrollHeight;
    const scrollValue = notificationsRef?.current?.scrollTop * -1 + 400;
    const threshold = scrollHeight - scrollHeight * 0.15;

    if (scrollValue >= threshold && messageCountRef.current < messages_count) {
      allowFetching.current = false;
      messageCountRef.current += 50;
      setChatQueryParams({
        ...singleChatQuery,
        offset: 10 + singleChatQuery.offset * 50,
      });
    }
  };

  const fetchChats = async () => {
    const activeChats = await fetchActiveChats();
    setLoading(false);

    if (!activeChats?.chats?.length) {
      return;
    }

    const nextChatIndex = getNextChatIndex(activeChats.chats);
    if (nextChatIndex !== -1) {
      const nextChat = activeChats.chats[nextChatIndex];
      nextChatIndex === 0 ? setChatById(nextChat) : onClickChat(nextChat);
    }

    activeChats && subscribeToAllChats(activeChats.chats);
  };

  const uneadMessageeCountHandler = (chatEventData: any) => {
    if (chatEventData?.assigned) {
      chats.length ? fetchActiveChats() : fetchChats();
    } else if (id !== chatEventData?.thread_id) {
      setUnreadMessagesCount(chatEventData);
      playSound(chatEventData?.notification_id);
    } else {
      fetchChatById({
        limit: 20,
        offset: 0,
        chatId: chatEventData?.thread_id,
      });
    }
  };

  const onKeyDown = (e: any) => {
    if (
      e.ctrlKey &&
      e.key === "Enter" &&
      message.trim().length > 0 &&
      !isSendButtonDisabled
    ) {
      setAllowAddMessage(false);
      onClickMessage(message);
      setMessage("");
    }
  };

  useEffect(() => {
    if (clickedKeys > 48 && clickedKeys < 54) {
      const quickMessageKey = clickedKeys - 48;
      quickMessages[quickMessageKey] &&
        onClickMessage(quickMessages[quickMessageKey]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedKeys]);

  const quickResponseModalHandler = (e: any) => {
    const keyClicked = isMac() ? e.ctrlKey : e.altKey;

    if (
      (keyClicked && e.keyCode === 82) ||
      e.keyCode === 27 ||
      (e.keyCode > 48 && e.keyCode < 54)
    ) {
      setShowQuickResponseContent((previousState) => {
        if (keyClicked && e.keyCode === 82 && !previousState) {
          return true;
        } else if (e.keyCode === 27 && previousState) {
          return false;
        } else if (e.keyCode > 48 && e.keyCode < 54 && previousState) {
          setClickedKeys(e.keyCode);
        }

        return previousState;
      });
    }
  };

  const connectionCountHandler = (data: any) => {
    const { active_admins, active_count } = data;
    setActiveChatsAvailable({ active_count, active_admins });
  };

  const newChatHandler = (data: any) => {
    const { new_chats } = data;
    new_chats > 0 && fetchActiveChats();
  };

  const chatsubscribeNotificationHandler = (data: any) => {
    const { thread_assigned } = data;

    if (thread_assigned) {
      resolveChatFromReducer(thread_assigned);
      unsetChatById();

      if (chats?.length > 0) {
        const otherChats = chats.filter(
          (item: any) => thread_assigned !== item.id
        );
        const nextChatIndex = getNextChatIndex(otherChats);
        nextChatIndex !== -1 && getChatById(otherChats[nextChatIndex].id);
      }
      return;
    }

    newChatHandler(data);
  };

  const socketEventHandler = () => {
    const { type, key } = socketData;
    const eventKey = type || key;
    switch (eventKey) {
      case SOCKET_SUPER_ADMIN_CONNECTION_COUNT:
        connectionCountHandler(socketData);
        break;
      default:
        console.log("event key does not have any case: ", type);
    }
  };

  const onChangeStatus = (e: any) => {
    const status = parseInt(e.target.value);
    onStatusChange(status);

    emitSuperAdminConnect(status, (res: any) => {
      if (res && res.error) {
        toast.error(res.error);
        onStatusChange(statusId);
      } else {
        if (res.global_status) {
          const payload = {
            global_status: res.global_status,
            available: res.active_count,
          };
          setGlobalStatus(payload);
        }
      }
    });
  };

  const onAssignChatToAdmin = async (adminId: number) => {
    setFadeOutChatBox(singleAdminChat.id);

    const chat = await assignChatToAdmin({
      chatId: singleAdminChat.id,
      admin_id: adminId,
      type: "ASSIGNEE",
    });

    if (chat) {
      unsetSuperAdminChat(singleAdminChat.id);
      setShowAssignModal(false);
      setCurrentAdmin(null!);

      if (filteredChats.length > 0) {
        const otherChats = filteredChats.filter(
          (item: any) => singleAdminChat.id !== item.id
        );
        const nextChatIndex = getNextChatIndex(otherChats);
        nextChatIndex !== -1 && getChatById(otherChats[nextChatIndex].id);
      }
      setFadeOutChatBox(null!);
    } else {
      setFadeOutChatBox(null!);
    }
  };

  const closeHoverMenu = () => {
    setMenuDropDownOpen(false);
  };

  const closeHoverTicketMenu = () => {
    setTicketMenuDropDownOpen(false);
  };

  useOnHoverOutside(classesDropdownRef, closeHoverMenu);
  useOnHoverOutside(ticketsRef, closeHoverTicketMenu);

  const displayOrHideCrossButton = (chat_id: number, action: Boolean) => {
    if (chat_id === singleAdminChat?.id) {
      setDisplayCrossButton(action);
    }
  };

  useEffect(() => {
    if (!superAdminMessages) {
      return;
    }

    uneadMessageeCountHandler(superAdminMessages);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superAdminMessages]);

  useEffect(() => {
    if (!chatSubscribeNotification) {
      return;
    }

    chatsubscribeNotificationHandler(chatSubscribeNotification);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatSubscribeNotification]);

  useEffect(() => {
    if (!socketData) {
      return;
    }

    socketEventHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketData]);

  useEffect(() => {
    fetchChats();
    setSuperAdminChatOpen(true);

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (!isChatsAvailable || !selectedChatId) {
      return;
    }

    // attach the event listener
    document.addEventListener("keydown", quickResponseModalHandler);

    return () => {
      // remove the event listener
      document.removeEventListener("keydown", quickResponseModalHandler);
    };
  }, [chats, statusId]);

  useEffect(() => {
    if (!statusId) {
      return;
    }
    setCurrentStatus(statusId);

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [statusId]);

  useEffect(() => {
    singleAdminChat &&
      fetchPreviousChatById({ ...singleChatQuery, chatId: singleAdminChat.id });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [singleChatQuery]);

  useEffect(() => {
    singleAdminChat && setSelectedChatId(singleAdminChat.id);

    if (!allowFetching.current) allowFetching.current = true;
  }, [singleAdminChat]);

  const isTextareaDisabled =
    !isChatsAvailable ||
    !selectedChatId ||
    (statusId !== StatusIds.AVAILABLE && !isChatsAvailable);

  return (
    <>
      <div className="showAdminChatBox">
        <div className="chatBox">
          <div className="d-flex justify-content-between mob-spacing">
            <div className="d-flex flex-sm-row flex-column w-100">
              <div className="d-flex mr-3">
                <div>
                  <span
                    onClick={onClickClose}
                    className="cursor-pointer accordion__header-button downArrow"
                  >
                    <img src={DrodownIcon} alt="arrow" className="rotate-180" />
                  </span>
                </div>
                <h3 className="fw-bold text-white chatBox__alphaHeading">
                  Active Chats
                </h3>
              </div>
              <div className="chatBox__backTime">
                {loading ? (
                  <ChatHeaderSkeleton className={"circle"} />
                ) : global_status === "Available" ? (
                  <span></span>
                ) : global_status === "Not Available" ? (
                  <span className="redCircle"></span>
                ) : (
                  <span className="yellowCircle"></span>
                )}

                {loading ? (
                  <ChatHeaderSkeleton className={"chat-status-text"} />
                ) : (
                  <p>
                    {global_status}
                    {available > 0 && <> ({available})</>}
                  </p>
                )}

                <div
                  className={`available-tooltip`}
                  style={{
                    height: activeAdminHeight,
                    top: activeAdminTop,
                    ...(active_admins?.length && { overflow: "auto" }),
                  }}
                >
                  {isActiveAdmins && (
                    <div className="online-admin">
                      {active_admins.map((admin: any, index: number) => {
                        let activeAdminsLength = active_admins
                          ? active_admins.length - 1
                          : 0;
                        return (
                          <div
                            className={`d-flex align-items-center justify-content-between ${
                              index !== activeAdminsLength ? "mb-2" : ""
                            }`}
                          >
                            <p className="admin-name">{admin.name}</p>
                            <p
                              className={`admin-status ${
                                admin.status === "I am Available"
                                  ? "available"
                                  : "not-available"
                              }`}
                            >
                              {admin.status === "I am Available"
                                ? "Available"
                                : admin.status}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex">
                {loading ? (
                  <ChatHeaderSkeleton className={"chat-status-box"} />
                ) : (
                  <div
                    className={`availableDropdown ${
                      statusId === 2 || statusId === 3
                        ? "availableDropdown_back_in"
                        : statusId === 4
                        ? "availableDropdown_unavailable"
                        : ""
                    }`}
                  >
                    <select onChange={onChangeStatus} value={statusId}>
                      {chat_statuses?.map(({ status, id }: any) => (
                        <option key={id} value={id}>
                          {status === "Not Available"
                            ? "I am Not Available"
                            : status}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                <ToolTip
                  content={
                    <h6 className="info-tooltip mb-0">
                      Chat is available to users if at least one Super Admin is
                      available.
                    </h6>
                  }
                  top={25}
                >
                  <div className="info cursor-pointer">?</div>
                </ToolTip>
              </div>
            </div>
            <div className="chatbox-chatIcon">
              <svg
                width="51"
                height="51"
                viewBox="0 0 51 51"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8621 31.4685C12.9942 31.471 10.188 30.637 7.78707 29.0685L1.01807 31.4685L3.41807 24.6985C-0.0286584 19.3849 0.276607 12.4694 4.17813 7.4801C8.07965 2.4908 14.7175 0.527302 20.7054 2.5913C26.6933 4.6553 30.7115 10.2919 30.7101 16.6255"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <ellipse
                  cx="15.8618"
                  cy="16.625"
                  rx="4.688"
                  ry="5.54"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.6431 22.25L19.7681 22.875"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M47.0572 43.4499C51.2012 37.0692 49.8584 28.5894 43.9457 23.8013C38.0331 19.0131 29.4594 19.4624 24.0795 24.8422C18.6997 30.2221 18.2504 38.7958 23.0386 44.7084C27.8267 50.6211 36.3065 51.9639 42.6872 47.8199L49.4562 50.2199L47.0572 43.4499Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M34 30L30 41"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M34 30L38 41"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M32 37H37"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>

          <div className="chatBox__showProfiles">
            {loading ? (
              <div className="show-profile-skeleton">
                <p className="header"></p>

                <div className="body"></div>
              </div>
            ) : isChatsAvailable ? (
              filteredChats?.map((chat: any, index: number) => {
                const isSelectedChat = selectedChatId === chat.id;
                const {
                  sent_by,
                  district,
                  school,
                  respondent,
                  respondent_id,
                  unread_count = 0,
                  date_created,
                } = chat;
                const unreadMessages = +unread_count;
                const isFlashName = flashName === chat.id;
                const schoolDistrictName = school ? school : district;
                const isThisChatAssigning =
                  assignChatLoader.loader &&
                  assignChatLoader.chat_id === chat.id;
                // const isChatAssigning = chat.id === chatToAssigned;

                return (
                  <div
                    className={`chatbox-active ${
                      fadeOutChatBox === chat.id ? "fadeOut" : ""
                    }`}
                  >
                    {resolveChatID === chat.id ? (
                      <div style={{ width: "200px" }}>
                        <p className={`chatBox__showProfiles__profile__header`}>
                          Create Ticket?
                        </p>

                        <div className={`chatBox__showProfiles__profile__body`}>
                          <div className="d-flex justify-content-between px-2 group-btns">
                            <button
                              type="button"
                              className="btn cancel-btn noButton"
                              onClick={() => onClickResolveChat("resolveChat")}
                            >
                              {resolveChatCheckMark ? (
                                <div className={`${"checked-done"} `}></div>
                              ) : (
                                "No"
                              )}
                            </button>

                            <button
                              type="button"
                              className="btn cancel-btn  yesButton"
                              onClick={openTicketModal}
                            >
                              {createTicketSpinner ? (
                                <div className={`${"spinner-border"} `}>
                                  <span className="sr-only"></span>
                                </div>
                              ) : createTicketCheckMark ? (
                                <div className={`${"checked-done"} `}></div>
                              ) : (
                                "Yes"
                              )}
                            </button>
                          </div>
                          <div
                            className="ticketBorder__crossModalButton"
                            onClick={() => {
                              setResolveChatID(-1);
                            }}
                          >
                            <div className="ticketBorder__crossModalButton__crossModalInnerButton">
                              X
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        key={chat.id}
                        className={`chatBox__showProfiles__profile `}
                        onClick={() =>
                          chatToAssigned !== chat.id && onClickChat(chat)
                        }
                        onMouseLeave={() =>
                          displayOrHideCrossButton(chat.id, false)
                        }
                        onMouseEnter={() =>
                          displayOrHideCrossButton(chat.id, true)
                        }
                      >
                        <p
                          onClick={(e) =>
                            onClickAssignToMe(chat, respondent_id, e)
                          }
                          className={`chatBox__showProfiles__profile__${
                            isSelectedChat
                              ? "headerActive"
                              : !!respondent
                              ? "header"
                              : "header-red"
                          }`}
                        >
                          <span className="ellipsis-animated">
                            <span
                              className={`ellipsis-animated-text animate-wrapper ${
                                respondent?.length > 23
                                  ? "animated ellipsis-animation"
                                  : ""
                              } ${isFlashName ? "flashName" : ""}`}
                            >
                              {respondent
                                ? respondent
                                : isThisChatAssigning
                                ? "Assigning to you..."
                                : "Assign to Me"}

                              {!respondent && isThisChatAssigning && (
                                <div
                                  className={`${"spinner-border"} `}
                                  style={{
                                    marginLeft: "15px",
                                    height: "16px",
                                    width: "16px",
                                    borderWidth: "2px",
                                    position: "relative",
                                    top: "1px",
                                  }}
                                >
                                  <span className="sr-only"></span>
                                </div>
                              )}
                            </span>
                          </span>
                        </p>

                        <div
                          className={`chatBox__showProfiles__profile__${
                            isSelectedChat ? "bodyActive" : "body"
                          }`}
                        >
                          <div>
                            <div
                              style={{ width: "121px" }}
                              className="ellipsis-animated"
                            >
                              <p
                                className={`cursor-pointer ellipsis-animated-text ${
                                  schoolDistrictName?.length > 15
                                    ? "animated ellipsis-animation"
                                    : ""
                                } text-left chatBox__showProfiles__profile__${
                                  isSelectedChat ? "bodyActive" : "body"
                                }__${isSelectedChat ? "nameActive" : "name"}`}
                              >
                                {schoolDistrictName}
                              </p>
                            </div>
                            <div
                              className={` ellipsis-animated chatBox__showProfiles__profile__${
                                isSelectedChat ? "bodyActive" : "body"
                              }__${isSelectedChat ? "name2Active" : "name2"}`}
                            >
                              <p
                                className={`cursor-pointer ellipsis-animated-text ${
                                  sent_by.length > 14
                                    ? "animated ellipsis-animation"
                                    : ""
                                } text-left`}
                              >
                                {sent_by}
                              </p>
                            </div>
                          </div>
                          <p
                            className={`chatBox__showProfiles__profile__${
                              isSelectedChat ? "bodyActive" : "body"
                            }__${isSelectedChat ? "timeActive" : "time"}`}
                          >
                            <MyTime date={date_created} />
                          </p>
                        </div>

                        {!!unreadMessages && (
                          <div className="chatBox__showProfiles__profile__messages">
                            {unreadMessages}
                          </div>
                        )}

                        {isSelectedChat &&
                          !!respondent &&
                          displayCrossButton && (
                            // onClick={(e) => onClickCross(e, chat, index)}
                            <div
                              onClick={(e) => onClickCross(chat)}
                              className="chatBox__showProfiles__profile__close"
                            >
                              x
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div
                style={{ cursor: "default" }}
                className={`chatBox__showProfiles__profile noActiveChat`}
              >
                {/* // ${animateChatBox && index === 0 ? 'animated-transition left200' : '' } */}

                <p className={`chatBox__showProfiles__profile__header`}>
                  No Chats
                </p>

                <div className={`chatBox__showProfiles__profile__body`}>
                  <div>
                    <p
                      className={`chatBox__showProfiles__profile__body__name`}
                    ></p>
                    <p
                      className={`chatBox__showProfiles__profile__body__name2`}
                    ></p>
                  </div>
                  <p
                    className={`chatBox__showProfiles__profile__body__time`}
                  ></p>
                </div>
              </div>
            )}

            {remainingChats > 0 && (
              <div className="chatBox__showProfiles__moreProfiles">
                {remainingChats} More
              </div>
            )}
          </div>

          <div className="mob-spacing">
            <div
              className="chatBox__notifications"
              ref={notificationsRef}
              onScroll={onScrollMessages}
            >
              {loading ? (
                <>
                  <div className="chatbox-chatSkeleton">
                    <div className="profile"></div>
                    <div className="text"></div>
                  </div>

                  <div className="chatbox-chatSkeleton">
                    <div className="profile"></div>
                    <div className="text"></div>
                  </div>
                  <div className="chatbox-chatSkeleton">
                    <div className="profile"></div>
                    <div className="text"></div>
                  </div>

                  <div className="chatbox-chatSkeleton">
                    <div className="profile"></div>
                    <div className="text"></div>
                  </div>
                  <div className="chatbox-chatSkeleton">
                    <div className="profile"></div>
                    <div className="text"></div>
                  </div>

                  <div className="chatbox-chatSkeleton">
                    <div className="profile"></div>
                    <div className="text"></div>
                  </div>
                </>
              ) : messages?.length > 0 && isChatsAvailable ? (
                messages.map((item: any) => {
                  const { message, name_initials, date, color_hex, type } =
                    item;
                  return (
                    <div className="d-flex align-items-md-center py-2">
                      {type !== "NOTIFICATION" ? (
                        <>
                          <div
                            style={{ backgroundColor: color_hex }}
                            className="chatBox__notifications__name2"
                          >
                            {name_initials}
                          </div>
                          <div className="chatBox__notifications__messageWidth">
                            <p className="chatBox__notifications__message">
                              {message}
                            </p>
                            <p className="chatBox__notifications__time">
                              {moment(date).format("h:mm A")}
                            </p>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              backgroundColor: "#ffffff",
                              marginRight: "0px",
                            }}
                            className="chatBox__notifications__name2"
                          >
                            <div className="chatBox__notifications__handGestureIconWrapper ">
                              <img
                                src={HandGestureIcon}
                                alt="HandGestureIcon"
                              />
                            </div>
                          </div>
                          <p className="chatBox__notifications__message handGestureMsg">
                            <strong>{message}</strong>
                          </p>
                        </>
                      )}
                    </div>
                  );
                })
              ) : (
                <div className="d-flex align-items-center noChat">
                  {statusId === 1 ? (
                    <>
                      <div className="no-chat-img">
                        <img src={NoChatAvailable} alt="No-Chat" />
                      </div>
                      <p style={{ color: "black" }} className="mb-0 mx-3 text">
                        No Chat Session Requests
                      </p>
                    </>
                  ) : (
                    <>
                      <div className="no-chat-img">
                        <img src={NoChatHand} alt="No-Chat" />
                      </div>
                      <p
                        style={{ color: "black", paddingTop: "2px" }}
                        className="mb-0 mx-3 text"
                      >
                        No Chat Session Requests
                      </p>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          {superAdminChatTyping &&
            superAdminChatTyping.thread_id === singleAdminChat?.id && (
              <div className="chatbox-typing">
                <p>
                  <span>{sent_by} is typing...</span>
                </p>
              </div>
            )}

          {!showQuickResponseContent && (
            <div className="mt-3 chatBox__inputField">
              <textarea
                ref={textAreaRef}
                value={message}
                placeholder="Type your response here"
                disabled={isTextareaDisabled}
                onChange={onChangeMessage}
                onKeyDown={onKeyDown}
              />
            </div>
          )}

          <div className="mob-spacing">
            {showQuickResponseContent && !!chats.length ? (
              <div className="quickResponse-chat">
                <QuickResponseContent onClickMessage={onClickMessage} />
              </div>
            ) : (
              <div className="d-flex justify-content-between align-items-center mt-1">
                <button
                  onClick={() => setShowQuickResponseContent(true)}
                  className="chatBox__actionBtns quick_response_btn"
                  disabled={
                    !isChat ||
                    (statusId !== StatusIds.AVAILABLE && !isChatsAvailable)
                  }
                >
                  Quick Response ({isMac() ? "Ctrl" : "Alt"}+R)
                </button>

                {loading ? (
                  <div className="chatbox-footer-skeleton"></div>
                ) : (
                  isChat && (
                    <div className="chatBox__centerBtns d-md-flex d-none">
                      {
                        <button
                          type="button"
                          className="chatBox__centerBtns__btn sent-info"
                        >
                          {sent_by?.length > 12 ? (
                            <ToolTip
                              top={-10}
                              left={-40}
                              content={`${sent_by} Info`}
                            >
                              {sent_by} Info
                            </ToolTip>
                          ) : (
                            <>{sent_by} Info</>
                          )}
                        </button>
                      }

                      {from_archived && (
                        <div className="deleteBtn">
                          <div onClick={() => {}} className="btn">
                            Deleted Admin
                          </div>
                        </div>
                      )}

                      {district.length > 0 && (
                        <button
                          onClick={() =>
                            window.open(
                              `/management/district/${district_id}?district_name=${district}`,
                              "_blank"
                            )
                          }
                          type="button"
                          className="chatBox__centerBtns__btn"
                        >
                          {district?.length > 22 ? (
                            <ToolTip top={-10} left={-120} content={district}>
                              {district}
                            </ToolTip>
                          ) : (
                            <>{district}</>
                          )}
                        </button>
                      )}

                      {school.length > 0 && school_id && (
                        <button
                          onClick={() =>
                            window.open(
                              `/management/school/${school_id}`,
                              "_blank"
                            )
                          }
                          type="button"
                          className="chatBox__centerBtns__btn school-name"
                        >
                          {school?.length > 10 ? (
                            <ToolTip top={-10} left={-70} content={school}>
                              {school}
                            </ToolTip>
                          ) : (
                            <>{school}</>
                          )}
                        </button>
                      )}

                      <div
                        ref={classesDropdownRef}
                        className="position-relative"
                      >
                        {classCount === 0 ? (
                          <button
                            type="button"
                            className="chatBox__centerBtns__btn rounded-0"
                            style={{
                              pointerEvents: "none",
                            }}
                          >
                            No Classes
                          </button>
                        ) : classCount === 1 &&
                          !!classrooms[0]?.classrooms.id ? (
                          <button
                            type="button"
                            className="chatBox__centerBtns__btn rounded-0"
                            onClick={() =>
                              window.open(
                                `/management/class/${classrooms[0]?.classrooms.id}`,
                                "_blank"
                              )
                            }
                          >
                            {classCount + " Class"}
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="chatBox__centerBtns__btn rounded-0"
                            onMouseOver={() => setMenuDropDownOpen(true)}
                          >
                            {classCount + " Classes"}
                          </button>
                        )}

                        {isMenuDropDownOpen &&
                          classrooms?.length > 0 &&
                          !!classrooms[0]?.id && (
                            <div
                              className="classes-tooltip"
                              style={{
                                height: classesDropdownHeight,
                                top: classesDropdownTop,
                              }}
                            >
                              {
                                <div className="tooltip-buttons">
                                  {classrooms.map((classroom: any) => {
                                    return (
                                      !!classroom?.classrooms?.id && (
                                        <button
                                          onClick={() =>
                                            window.open(
                                              `/management/class/${classroom.classrooms.id}`,
                                              "_blank"
                                            )
                                          }
                                          type="button"
                                          className="chatBox__centerBtns__btn"
                                        >
                                          {classroom?.classrooms?.name || ""}
                                        </button>
                                      )
                                    );
                                  })}
                                </div>
                              }
                            </div>
                          )}
                      </div>

                      <div ref={ticketsRef} className="position-relative">
                        <button
                          type="button"
                          className="chatBox__centerBtns__btn"
                          onMouseOver={() =>
                            tickets.length > 1 &&
                            setTicketMenuDropDownOpen(true)
                          }
                          style={{
                            pointerEvents:
                              tickets.length === 0 ? "none" : "auto",
                          }}
                          onClick={() =>
                            tickets.length === 1
                              ? !!tickets[0] &&
                                window.open(
                                  `/super-admin/ticket-details/${tickets[0]}`,
                                  "_blank"
                                )
                              : setTicketMenuDropDownOpen((prev) => !prev)
                          }
                        >
                          {tickets.length === 0
                            ? "No Tickets"
                            : tickets.length === 1
                            ? `${tickets.length} Ticket`
                            : `${tickets.length} Tickets`}
                        </button>

                        {isTicketMenuDropDownOpen &&
                          tickets?.length > 0 &&
                          !!tickets[0] && (
                            <div
                              className="classes-tooltip"
                              style={{
                                height: ticketDropdownHeight,
                                top: ticketDropdownTop,
                              }}
                            >
                              {
                                <div className="tooltip-buttons">
                                  {tickets.map((ticket: any) => {
                                    return (
                                      !!ticket && (
                                        <button
                                          onClick={() =>
                                            window.open(
                                              `/super-admin/ticket-details/${ticket}`,
                                              "_blank"
                                            )
                                          }
                                          type="button"
                                          className="chatBox__centerBtns__btn"
                                        >
                                          {"Ticket #" + ticket}
                                        </button>
                                      )
                                    );
                                  })}
                                </div>
                              }
                            </div>
                          )}
                      </div>

                      {/* <button type="button" className="chatBox__centerBtns__btn">
                                                            {
                                                                tickets === 1 ? tickets + ' Ticket' : tickets + ' Tickets'
                                                            }
                                                        </button> */}
                    </div>
                  )
                )}
                <button
                  onClick={() => onClickSend()}
                  className="chatBox__actionBtns"
                  disabled={isSendButtonDisabled}
                >
                  Send (Ctrl+Enter)
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* {<ResolveChatModal show={showResolveChatModal} onClickYes={createTicket} onClickNo={ onClickResolveChat} onClose={() => setShowResolveChatModal(false)}/>} */}

      {/* {  
                showSuccess &&  (
                    <SuccessModal
                        bodyText="Thank you for contacting Alpha Plus Support. We will reach out to you within one business day. If your case is urgent, please call us at (405) 842-8408."
                        headerText="Ticket Submitted"
                        isShow={showSuccess}
                        closeModal={() => setShowSuccess(false)}
                    />
                )
            } */}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      {showAssignModal && (
        <AssignModal
          loading={false}
          active_admins={active_admins}
          currentAdmin={currentAdmin}
          onAdd={(adminId: number) => onAssignChatToAdmin(adminId)}
          onCancel={() => setShowAssignModal(false)}
        />
      )}

      {ticketAdmin && (
        <NewTicketModel
          videoOptions={videoOptions}
          show
          onSubmit={(data: any) => onCreateTicket(data)}
          onClose={() => setTicketAdmin(null!)}
          addAttachmentToTicket={addAttachmentToTicket}
          admin={ticketAdmin}
          chat={singleAdminChat}
          setChatQueryParams={setChatQueryParams}
        />
      )}
    </>
  );
};

export default AdminChatBox;
