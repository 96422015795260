import React from "react";
import Theme from "components/theme/index";
import IPage from "interfaces/page";

import SupportTickets from "components/supportTickets";
import SupportTicketProvider from "contexts/supportTicket";

const SupportTicket: React.FunctionComponent<IPage> = (props) => {
  return (
    <Theme>
      <SupportTicketProvider>
        <SupportTickets />
      </SupportTicketProvider>
    </Theme>
  )
}

export default SupportTicket
