const InfoSection = (level?: any) => {
  const performanceLevels = level?.level || { P: 70 };
  return (
    <>
      <div className="data-text-print report__performance-guide">
        <p className="report__color6A6A6A mb-0">
          Data for the OAG comes directly from formative assessments
          administered. Each formative aligns with a single OAS objective. Use
          this report to identify objectives that need remediation as students
          progress toward proficiency on summative assessments.
        </p>
      </div>
      <div className="report__performance-performance oag-performance">
        <p className="report__performance-title">
          Performance Level (Percentage Score Range)
        </p>
        <div className="report__performance-percentage max-width">
          <div className="report__performance-row ">
            <div className="d-flex align-items-center">
              <div
                className="mb-1 report__performance-level border-proficient"
                style={{ boxShadow: "unset" }}
              >
                <span style={{ color: "#000", fontSize: 14 }}>%</span>
              </div>
              <p>Proficient</p>
            </div>
            <p>
              ({" "}
              {performanceLevels.FORMATIVE_PROFICIENCY
                ? `${performanceLevels.FORMATIVE_PROFICIENCY}% - 100%`
                : "-"}{" "}
              )
            </p>
          </div>
          <div className="report__performance-row">
            <div className="d-flex align-items-center">
              <div
                className="report__performance-level border-non-proficient"
                style={{ backgroundColor: "#eadb4a" }}
              ></div>
              <p>Non-Proficient</p>
            </div>
            <p>
              ({" "}
              {performanceLevels.FORMATIVE_PROFICIENCY
                ? `0% - ${performanceLevels.FORMATIVE_PROFICIENCY - 1}%`
                : "-"}{" "}
              )
            </p>
          </div>
        </div>

        <div className="report__performance-indicator oag-score">
          <sup className="report__performance-indicatorText mb-1 me-1">
            Score on Objective{" "}
            <span className="border-top d-inline-block"></span>
          </sup>
          <div className="report__performance-rectangle prof-text border-proficient">
            <span className="pt-2 oag-bg report__table-numbers oag-font-12 small-font">
              %
            </span>
            <span className="oag-bg report__table-numbers small-text">#</span>
          </div>
          <sub className="report__performance-indicatorText mt-2 ms-1">
            <span className="border-top d-inline-block"></span>Multiple Attempt
            Count
          </sub>
        </div>
      </div>
    </>
  );
};

export default InfoSection;
