/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

interface AddLicenseConfirmModalProps {
  isShow: boolean;
  header: string;
  onClose: () => void;
  message: string;
  onYes: () => void;
  loading: boolean;
}

const AddLicenseConfirmModal: React.FC<AddLicenseConfirmModalProps> = ({
  isShow,
  onClose,
  onYes,
  message,
  header,
  loading
}) => {
  return (
    <Modal centered show={isShow} onEscapeKeyDown={onClose}>
      <div className="modal__header">
        <h6 className="modal__header-title">{header}</h6>
      </div>
      <div className="modal__body">
        <p className="modal__body-text">{message}</p>

      </div>
      <div className="modal__footer">
        <button className="btn cancel-btn" onClick={onClose} disabled={loading}>
          Cancel
        </button>
        <button className="btn success-btn btn-lg" onClick={onYes}>
          {loading ? <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
           /> : 'Add'
          }
        </button>
      </div>
    </Modal>
  );
};

export default AddLicenseConfirmModal;
