/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from "react";
import moment from "moment";
import _ from 'lodash';
import { ReactComponent as LogIcon } from "assets/img/log.svg";
import { ReactComponent as ReasonIcon } from "assets/img/comment-alt-solid.svg";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import { TextAreaInput } from "../../common/inputComponent";
import CustomModal from "components/common/Modal";


interface LogListModalProps {
  isShow: boolean;
  logs: any;
  onClose: () => void;
}

export const LogListModal: React.FC<LogListModalProps> = ({
  logs,
  onClose,
  isShow,
}) => {
  const [selected, setSelected] = useState<any>(null);

  const handleReasonOnClick = (current: any) => {
    setSelected(current);
  };

  const backButton = () => {
    setSelected(null);
  };

  const handleOnClose = () => {
    setSelected(null);
    onClose();
  };

  return (
    <CustomModal
      centered
      show={isShow}
      onEscapeKeyDown={handleOnClose}
      contentClassName="modal-content-edit p-0"
      className="modal-log-container"
    >
      { !selected ? 
      (<div className="modal__header modal__header-log">
        <span className="modal__header-icon">
          <LogIcon />
        </span>
        <h6 className="modal__header-title">
          {logs?.students?.first_name + " " + logs?.students?.last_name} Log
        </h6>
        
      </div>) : ( 
      <div className="modal__header modal__header-logDetail">
        <span className="section-header-link me-1">
          <img onClick={backButton} src={ArrowIcon} alt="arrow" />
        </span>
        <h6 className="modal__header-title">
          {logs?.students?.first_name + " " + logs?.students?.last_name}{" "}
              Attempt Details
        </h6>
      </div>
      )}
      <div className="modal__body p-0">
        <div className="logs">
          <table className="logs__table">
            <thead>
              <tr className="logs__table-row">
                <th className="text-center attempt-data">Attempt</th>
                <th className="date-data">Date and Time</th>
                <th className="action-data">Action</th>
                <th className="name-data">Admin Name</th>
                <th className="reason-data">{selected ? "" : "Reason"}</th>
              </tr>
            </thead>
            {!selected ? (
              <tbody>
                {_.orderBy(logs?.edit_logs, ['created_at'],['desc']).map((item: any, i: number) => {
                  const teacherColor = item.action.includes("Teacher");
                  const studentColor = item.action.includes("Student");
                  const classroomColor = item.action.includes("Classroom");
                  return (
                    <tr
                      className={
                        i % 2 === 0
                          ? "logs__table-row gray-row"
                          : "logs__table-row"
                      }
                    >
                      <td className="text-center attempt">{item.attempt}</td>
                      <td className="date-data">
                        {moment(item.created_at).format(
                          "M-D-YY hh:mm A"
                        )}
                      </td>
                      <td className="action-data">
                        <div
                          className={
                            teacherColor
                              ? "teacherBg"
                              : studentColor
                              ? "studentBg"
                              : classroomColor
                              ? "classroomBg"
                              : "schoolBg"
                          }
                        >
                          {item.action}
                        </div>
                      </td>
                      <td className="name-data">{item.admin_name}</td>
                      {
                        !item.action.includes('Student') ?
                        <td className="reason-data">
                          <ReasonIcon 
                            className="cursor-pointer"
                            onClick={() =>
                              handleReasonOnClick({
                                ...item,
                                teacherColor,
                                studentColor,
                                classroomColor,
                              })
                            }
                          />
                        </td> : <td className="reason-data"></td>
                      }
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <tr className="logs__table-row gray-row">
                  <td className="text-center attempt">{selected.attempt}</td>
                  <td className="date-data"> {moment(selected.created_at).format(
                          "M-D-YY hh:mm A"
                        )}</td>
                  <td className="action-data">
                    <div
                      className={
                        selected.teacherColor
                          ? "teacherBg"
                          : selected.studentColor
                          ? "studentBg"
                          : selected.classroomColor
                          ? "classroomBg"
                          : "schoolBg"
                      }
                    >
                      {selected.action}
                    </div>
                  </td>
                  <td className="name-data">{selected.admin_name}</td>
                  <td></td>
                </tr>
              </tbody>
            )}
          </table>
          {selected && (
            <TextAreaInput
              fieldName="reason"
              isSubmitError={false}
              errorPresentBefore={false}
              value={selected.message}
              label="Reason for Edit"
              onChange={() => {}}
              error=""
              customClass="logs__textarea"
              isReadOnly={true}
            />
          )}
        </div>
      </div>

      <div className="modal__footer modal__footer-log">
        <button
          className="btn success-btn btn-medium"
          onClick={handleOnClose}
        >
          Okay
        </button>
      </div>
    </CustomModal>
  );
};
