const socketKeys = {
  SOCKET_ACTIVITY_FEED: "socket-activity-feed",
  SOCKET_TEACHER_CONNECT_CLASSROOM_TEST:
    "socket-teacher-connect-classroom-test",
  SOCKET_ONLINE_STUDENT: "socket-online-student",
  //done
  SOCKET_STUDENT_JOIN_ROOM: "socket-student-join-room",
  SOCKET_CLASS_BY_ID_CHANNEL: "socket-class-by-id-channel",
  //done
  SOCKET_STUDENT_UPDATE_ANSWER: "socket-student-update-answer",
  SOCKET_STUDENT_UPDATE_STATUS: "socket-student-update-status",
  //done
  SOCKET_STUDENT_UPDATE_POSITION_QUESTION:
    "socket-student-update-position-question",
  //done
  SOCKET_STUDENT_SUBMIT: "socket-student-submit-test",
  SOCKET_TEACHER_REQUEST_ONLINE_NUMBER: "socket-teacher-request-online-number",
  //done
  SOCKET_TEACHER_PAUSE_RESUME: "socket-teacher-pause-resume",
  //done
  SOCKET_TEACHER_CLEAR_ANSWER: "socket-teacher-clear-answer",
  //done
  SOCKET_TEACHER_FORCE_SUBMIT: "socket-teacher-force-submit",
  SOCKET_TEACHER_NEW_ATTEMPT: "socket-teacher-new-attempt",
  //done
  SOCKET_TEACHER_KICK_STUDENT: "socket-teacher-kick-student",
  //DONE
  SOCKET_STUDENT_TEST_TIME: "socket-student-test-time",
  SOCKET_TEACHER_KICK_STUDENT_MERGE_OR_TRANSFER:
    "socket-kick-student-merge-or-transfer",
  SOCKET_TEACHER_ENABLE_TEST: "socket-teacher-enable-test",
  SOCKET_DISCONNECT_EVENT: "socket-disconnect-student",
  SOCKET_TEACHER_UNSUBSCRIBE_EVENT: "socket-teacher-unsubscribe-event",
  SOCKET_ACTIVITY_FEED_COUNT: "socket-activity-feed-count",
  SOCKET_TEACHER_RERENDER_MONITOR: "socket-teacher-rerender-monitor",

  SOCKET_TEACHER_VIEW_UPDATE: "socket-teacher-view-update",

  //EVENTS
  PAUSE_SESSION: "pause_session",
  NEW_ATTEMPT: "new_attempt",
  CLEAR_ANSWERS: "clear_answers",
  FORCE_SUBMIT_CLOSE: "force_submit_close",
  SINGLE_SUBMIT: "single_submit",
  SINGLE_CLEAR: "single_clear",

  // SUPER ADMIN CHAT
  SOCKET_SUPER_ADMIN_CONNECT: "socket-super-admin-connect",
  SOCKET_SUPER_ADMIN_CHAT_SUBSCRIBE: "socket-super-admin-chat-subscribe",
  SOCKET_SUPER_ADMIN_CHAT_EVENTS: "socket-super-admin-chat-events",
  SOCKET_SUPER_ADMIN_NOTIFICATIONS: "socket-super-admin-notifications",
  SOCKET_SUPER_ADMIN_CONNECTION_COUNT: "socket-super-admin-connection-count",
  SOCKET_SUPER_ADMIN_CHAT_SUBSCRIBE_NOTIFICATIONS:
    "socket-super-admin-chat-subscribe-notifications",
  SOCKET_SUPER_ADMIN_CHAT_UNSUBSCRIBE: "socket-super-admin-chat-unsubscribe",
  SOCKET_ADMIN_ACTIVE: "socket-admin-active",
  SOCKET_SUPER_ADMIN_TYPING_EVENT: "socket-super-admin-typing-event",
  SOCKET_CLASSROOM_SUBSCRIBE_EVENT: "socket-classroom-subscribe-event",
};

export default socketKeys;
