/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef, useCallback } from "react";
import cryptoRandomString from "crypto-random-string";
import _ from "lodash";
import moment from "moment";
import { Collapse } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "assets/img/edit-table.svg";
import DeleteIcon from "assets/img/delete-table.svg";
import RPIcon from "assets/img/chat-close.svg";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import SearchIcon from "assets/img/search-icon.svg";
import SearchIconGray from "assets/img/search-icon-gray.svg";
import Pagination from "../pagination/pagination";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import { LimitSelect } from "components/common/selectComponent";
import {
  ResetPasswordModal,
  SchoolAdminAddModal,
} from "./management-add-modal";
import { SchoolAdminDeleteModal } from "./management-delete-modal";
import { SchoolAdminUpdateModal } from "./management-update-modal";
import {
  getSchoolAdmins,
  deleteSchoolAdmin,
  updateSchoolAdminDetail,
  resetPasswordAdmin,
  addSchoolAdmin,
  setFlagForFetch,
} from "../../redux/actionCreators/management";
import useSortBy from "hooks/useSortBy";
import SortIndicatorIcon from "components/common/svgs/sort-indicator-icon";

interface RootState {
  management: any;
  auth: any;
}

const SchoolAdmins: React.FC<{ districtId?: string }> = ({ districtId }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const { management, auth } = useSelector((state: RootState) => state);
  const [open, setOpen] = useState(false);
  const [offSet, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<null | any>(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [modalBorder, setModalBorder] = useState(false);
  const [resetPassModdal, setResetPassModdal] = useState(false);
  const [randomPass, setRandomPass] = useState("");
  const [addSchoolAdminModal, setAddSchoolAdminModal] = useState(false);
  const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [lastAddedId, setLastAddedId] = useState(0);

  const {sortBy, OrderByColumn, sortDataBy} = useSortBy("last_name", "ASC");

  

  useEffect(() => {
    let params = {
      offSet,
      limit,
      searchTerm,
      districtId,
      sortBy,
      OrderByColumn,
    };
    dispatch(getSchoolAdmins(params));
  }, [
    offSet,
    limit,
    management.schoolAdminFlag,
    dispatch,
    sortBy,
    OrderByColumn,
    districtId,
  ]);

  const handleSearch = useCallback(
    _.debounce((val: any) => {
      console.log(val);
      setOpen(true);
      setCurrentPage(1);
      setOffSet(0);
      setModalBorder(!modalBorder);
      let params = {
        offSet: 0,
        limit,
        searchTerm: val,
        districtId,
        sortBy,
        OrderByColumn,
      };
      dispatch(getSchoolAdmins(params));
      if (inputRef.current !== null) {
        inputRef.current.focus();
      }
    }, 1000),
    []
  );

  useEffect(() => {
    /* Fetch page number for last added record*/
    if (management.lastAddedSchoolAdminId > 0) {
      setLastAddedId(management.lastAddedSchoolAdminId);
      setSearchTerm("");
      let params = {
        offSet: 0,
        limit: 10,
        searchTerm: "",
        districtId,
        sortBy,
        OrderByColumn,
        new_admin_id: management.lastAddedSchoolAdminId,
      };
      dispatch(getSchoolAdmins(params));
    }
    /* Set page and fetch record of that page */
    if (management.schoolAdminPageNumber > 0) {
      setLimit(10);
      setCurrentPage(management.schoolAdminPageNumber);
      setOffSet(management.schoolAdminPageNumber * 10 - 10);
      management.schoolAdminPageNumber === currentPage &&
        dispatch(setFlagForFetch("SET_SCHOOL_ADMIN_FLAG"));
      setTimeout(() => {
        setLastAddedId(0);
      }, 2000);
    }
  }, [management.lastAddedSchoolAdminId, management.schoolAdminPageNumber]);

  const doChangePage = (pageNo: number) => {
    let newOffset = pageNo * limit - limit;
    setCurrentPage(pageNo);
    setOffSet(newOffset);
  };
  const doSetUpdateModal = (item: any) => {
    setUpdateModal(true);
    setCurrentItem(item);
  };

  const onUpdateSchoolAdmin = (data: any) => {
    dispatch(updateSchoolAdminDetail(currentItem?.id, data, setUpdateModal));
  };

  const doSetDelModal = (item: any) => {
    setCurrentItem(item);
    setDeleteModal(true);
  };

  const setPreviousPage = () => {
    if (currentPage > 1 && management.schoolAdmins.length === 1) {
      doChangePage(currentPage - 1);
    } else {
      dispatch(setFlagForFetch("SET_SCHOOL_ADMIN_FLAG"));
    }
  };

  const onDeleteSchoolAdmin = () => {
    let data = {
      school_id: currentItem?.school_id,
      admin_id: currentItem.id,
    };
    dispatch(deleteSchoolAdmin(data, setDeleteModal, setPreviousPage, true));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalBorder(true);
    }, 300);

    return () => {
      clearTimeout(timer);
      setModalBorder(false);
    };
  }, [open]);

  const doSetPasModal = (item: any) => {
    setCurrentItem(item);
    let newPass = cryptoRandomString({ length: 10, type: "url-safe" });
    setRandomPass(newPass);
    setResetPassModdal(true);
  };
  const onCreatePassword = () => {
    let data = {
      admin_id: currentItem?.id,
      password: randomPass,
    };
    console.log(currentItem);
    dispatch(resetPasswordAdmin(data, setResetPassModdal));
    // setCurrentItem(null)
  };

  const onAddSchoolAdmin = (data: any) => {
    dispatch(addSchoolAdmin(data, setAddSchoolAdminModal));
    !open && setOpen(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSkeletonLoad(false);
    }, 800);

    return () => {
      clearTimeout(timer);
      setSkeletonLoad(true);
    };
  }, [management.schoolAdminsLoading]);

  return (
    <>
      <div
        onClick={(e) => {
          if (
            (e.target as Element).nodeName === "INPUT" ||
            (e.target as Element).nodeName === "BUTTON" ||
            (e.target as Element).nodeName === "A"
          ) {
            return;
          }
          setOpen(!open);
        }}
        className={`accordion__header school-admin-header ${
          open ? "open" : ""
        } ${modalBorder ? "closeDelay" : ""}`}
      >
        <div className="accordion__header-heading">
          <span className="accordion__header-button">
            <img src={ArrowIcon} alt="arrow" className={open ? "rotate" : ""} />
          </span>
          <h6>School Admins</h6>
          {!open ? (
            <div className="accordion__header-search animated">
              <img src={SearchIcon} alt="Search" />
              <input
                value={searchTerm}
                onChange={(e) => {
                  handleSearch(e.target.value);
                  setSearchTerm(e.target.value);
                }}
                type="text"
                className="form-control"
                placeholder="Search School Admins"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        {auth && auth.currentUser?.role === "Super Admin" ? (
          <div>
            <div className="accordion__header-buttons">
              <div className="accordion__header-btnContainer">
                {/* <a className="btn outline-btn outline-btn-lg" href="/#">
                  Add School Admin
                </a> */}
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div className="accordion__header-buttons">
          <div className="accordion__header-btnContainer-add-school-admin">
            <button
              onClick={() => setAddSchoolAdminModal(true)}
              className="btn outline-btn outline-btn-lg"
            >
              Add School Admin
            </button>
          </div>
        </div>
      </div>
      <Collapse in={open}>
        <div>
          <div className="accordion__content">
            <div className="section-search">
              <div className="section-search-field animated">
                <div className="icon">
                  <img src={SearchIconGray} alt="search" />
                </div>
                <input
                  ref={inputRef}
                  value={searchTerm}
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    setSearchTerm(e.target.value);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Search School Admins"
                />
              </div>
              <div className="d-flex align-items-center show-classes-dropdown">
                <span>Show</span>
                <LimitSelect
                  disableHandler={management?.schoolAdmins?.length < 10}
                  onChangeHandler={(e: any) => setLimit(Number(e?.value))}
                />
                <span>Admins</span>
              </div>
            </div>
            <div className="admins-table-container">
              <div className="table-responsive">
                <table className="admins__table">
                  <thead>
                    <tr className="admins__table-row">
                      <th
                        tabIndex={0}
                        className="admins__table-name"
                        onClick={() => sortDataBy("last_name")}
                        onKeyDown={(e)=>{
                          if(e.key==="Enter"){
                            sortDataBy("last_name");
                          }
                        }}
                      >
                        Admin Name
                        {sortBy === "last_name" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} stroke="#323233" />
                        
                        )}
                      </th>
                      <th
                        tabIndex={0}
                        className="admins__table-email"
                        onClick={() => sortDataBy("email")}
                        onKeyDown={(e)=>{
                          if(e.key==="Enter"){
                            sortDataBy("email");
                          }
                        }}
                      >
                        Email{" "}
                        {sortBy === "email" && (
                        <SortIndicatorIcon OrderByColumn={OrderByColumn} stroke="#323233" />
                          
                        )}
                      </th>
                      <th
                        tabIndex={0}
                        className="admins__table-title"
                        onClick={() => sortDataBy("title")}
                        onKeyDown={(e)=>{
                          if(e.key==="Enter"){
                            sortDataBy("title");
                          }
                        }}
                      >
                        Title{" "}
                        {sortBy === "title" && (
                         <SortIndicatorIcon OrderByColumn={OrderByColumn} stroke="#323233" />
                        )}{" "}
                      </th>
                      <th className="admins__table-admin">
                        Admin is <br /> Principal
                      </th>
                      <th
                      tabIndex={0}
                        className="admins__table-school"
                        onClick={() => sortDataBy("schools_count")}
                        onKeyDown={(e)=>{
                          if(e.key==="Enter"){
                            sortDataBy("schools_count");
                          }
                        }}
                      >
                        Schools{" "}
                        {sortBy === "schools_count" && (
                         <SortIndicatorIcon OrderByColumn={OrderByColumn} stroke="#323233" />
                        )}
                      </th>
                      <th
                      tabIndex={0}
                        className="admins__table-login"
                        onClick={() => sortDataBy("last_login")}
                        onKeyDown={(e)=>{
                          if(e.key==="Enter"){
                            sortDataBy("last_login");
                          }
                        }}
                      >
                        Last Login
                        {sortBy === "last_login" && (
                         <SortIndicatorIcon OrderByColumn={OrderByColumn} stroke="#323233" />
                        )}
                      </th>
                      {auth && auth.currentUser?.role === "Super Admin" && (
                        <th className="admins__table-login">PIN</th>
                      )}
                      <th className="admins__table-options">Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    {skeletonLoad ? (
                      <TableSkeleton columns={8} count={4} height={36} />
                    ) : (
                      ""
                    )}
                    {management?.schoolAdmins?.length && !skeletonLoad ? (
                      management.schoolAdmins.map((item: any, i: number) => {
                        return (
                          <tr
                            key={i}
                            className={
                              lastAddedId === item.id
                                ? "admins__table-row highlight-row"
                                : i % 2 !== 0
                                ? "admins__table-row"
                                : "admins__table-row"
                            }
                            id={
                              lastAddedId === item.id ? "school-admin-data" : ""
                            }
                          >
                            <td className="name-data fw-normal">
                              {item?.last_name}, {item?.first_name}
                            </td>
                            <td className="email-data">{item?.email}</td>
                            <td className="title-data">{item?.title}</td>
                            <td>
                              <div className="text-center">
                                {item?.is_principal ? "Yes" : "No"}
                              </div>
                            </td>
                            <td className="text-center">
                              {item?.schools_count ? item?.schools_count : "-"}
                            </td>
                            <td className="text-center">
                              {item?.last_login
                                ? moment(item?.last_login).format("M-D-YY")
                                : "-"}
                            </td>
                            {auth &&
                              auth.currentUser?.role === "Super Admin" && (
                                <td className="text-center">
                                  {item?.support_pin || ""}
                                </td>
                              )}
                            <td className="options-data school-admins-option-data">
                              <div className="d-flex align-items-center">
                                <img
                                  onClick={() => doSetUpdateModal(item)}
                                  src={EditIcon}
                                  alt="Edit"
                                  className="management__table-editBtn"
                                />
                                {auth &&
                                auth.currentUser?.role === "Super Admin" ? (
                                  <img
                                    onClick={() => doSetPasModal(item)}
                                    src={RPIcon}
                                    alt="Report"
                                    className="management__table-editBtn"
                                  />
                                ) : (
                                  ""
                                )}
                                <img
                                  onClick={() => doSetDelModal(item)}
                                  src={DeleteIcon}
                                  alt="Delete"
                                  className="management__table-editBtn"
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        {management?.schoolAdmins?.length === 0 &&
                          !skeletonLoad && (
                            <td colSpan={8} className="no-admin">
                              No Admins
                            </td>
                          )}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Pagination
            totalCount={management.schoolAdminsCount}
            currentPage={currentPage}
            pageSize={limit}
            onPageChange={doChangePage}
          />
        </div>
      </Collapse>
      <SchoolAdminDeleteModal
        loading={management.schoolAdminDeleteLoading}
        isShow={deleteModal}
        headerText={`Delete ${currentItem?.first_name} ${currentItem?.last_name} `}
        bodyText={`Are you sure you want to delete this school admin?`}
        onCancel={() => setDeleteModal(false)}
        onDelete={onDeleteSchoolAdmin}
      />
      {addSchoolAdminModal && (
        <SchoolAdminAddModal
          isShow={addSchoolAdminModal}
          loading={management.schoolAdminAddLoading}
          onCancel={() => setAddSchoolAdminModal(false)}
          onAdd={(data) => onAddSchoolAdmin(data)}
          active={2}
          district_id={districtId}
        />
      )}
      {updateModal && (
        <SchoolAdminUpdateModal
          is_super_principal={currentItem?.is_principal || null}
          isShow={updateModal}
          first_name={currentItem?.first_name}
          last_name={currentItem?.last_name}
          input_email={currentItem?.email}
          input_title={currentItem?.title}
          onCancel={() => setUpdateModal(false)}
          onUpdate={(data) => onUpdateSchoolAdmin(data)}
          loading={management.schoolAdminUpdateLoading}
        />
      )}
      {resetPassModdal && (
        <ResetPasswordModal
          isShow={resetPassModdal}
          loading={management?.resetPwLoading}
          onCancel={() => setResetPassModdal(false)}
          onAdd={onCreatePassword}
          password={randomPass}
          title={`${currentItem?.first_name} ${currentItem?.last_name}`}
        />
      )}
    </>
  );
};

export default SchoolAdmins;
