import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import ArrowDownImg from "assets/img/drop-black-icon-down.svg";
import PerformanceSets from "components/common/studentInfoComponents/PerformanceSets";
import Skeleton from "react-loading-skeleton";
import { useEffect, useMemo, useState } from "react";
import ReportCardSkeleton from "./ReportCardSkeleton";
import AttemptVariant from "components/common/AttemptVariant";
import { useNavigate } from "react-router-dom";
import { LimitSelect } from "components/common/selectComponent";

const Performance = ({
  performance,
  classrooms,
  handleSortClick,
  getClassName,
  skeletonLoad,
  showLastThreeAttempts,
  showAllClasses,
  performanceSets,
  isPrev = false,
  showActions=false,
  handlEnroll,
  setClassId
}: any) => {
  const navigate = useNavigate();

  // const [attemptTwoExists, setAttemptTwoExists] = useState(false);
  // const [attemptThreeExists, setAttemptThreeExists] = useState(false);

  const initialState = useMemo(() => {
    const keys = Object.keys(performance?.tests || {});
    if (keys.length === 0 || !showActions) {
      return {};
    }
    return keys
      .map((_: any, index: number) => ({ [index]: 10 }))
      .reduce((acc: any, curr: any) => ({ ...acc, ...curr }), {});
  }, [performance?.tests, showActions]);

  const [limits, setLimits] = useState<Record<number, number>>(initialState);

  const handleLimitChange = (id: number, newLimit: number) => {
    setLimits((prevLimits) => ({
      ...prevLimits,
      [id]: newLimit,
    }));
  };

  useEffect(()=>{
    setLimits(initialState)
  },[initialState])

  return (
    <div className="studentPerformance">
      {!skeletonLoad &&
      performance &&
      Object.keys(performance?.tests || {}).length ? (
        Object.keys(performance?.tests || {})?.map((item: any, i: number) => {
          let subject = 2;

          if (item?.indexOf("Math") > -1) {
            subject = 1;
          } else if (item?.indexOf("Science") > -1) {
            subject = 3;
          }

          const showFormatives = subject === 3 ? performance?.scienceEnabled : true;
          const formativeData = classrooms[item]?.formatives.slice(
            0,
            //@ts-ignore
            limits[i]
          );
          const classroom_id = performance.classroom_ids.find(
            (cls: any) => cls.classroom_name === item
          )?.classroom_id;

          let attemptTwoExists = false;
          let attemptThreeExists = false;

          classrooms?.[item]?.["summatives"].forEach((test: any) => {
            if (test?.scored_summatives[1]) {
              attemptTwoExists = true;
            }

            if (test?.scored_summatives[2]) {
              attemptThreeExists = true;
            }
          });

          return (
            <>
              {showAllClasses && !isPrev && i > 0 && (
                <PerformanceSets
                  performance={performanceSets}
                  performanceEnabled={true}
                  showPerformanceLevelForScience = {performance?.scienceEnabled}
                  subject={subject}
                ></PerformanceSets>
              )}
              <div className="reportCardPerformanceDetails" key={i}>
                <div className="reportCardPerformanceDetails__header">
                  <section className="reportCardPerformanceDetails__header-heading">
                    <h3 className="reportCardPerformanceDetails__header-title">
                      {item}
                    </h3>
                    {!isPrev && showActions && (
                      <>
                        <button
                          className="btn unenroll-btn"
                          onClick={() => {
                            handlEnroll && handlEnroll(
                              performance?.tests[item][0]?.classroom_id ??
                                classroom_id
                            );
                            setClassId && setClassId(
                              performance?.tests[item][0]?.classroom_id ??
                                classroom_id
                            );
                          }}
                        >
                          Unenroll
                        </button>
                        <button
                          className="btn unenroll-btn to-classroom-btn"
                          onClick={() => {
                            navigate(
                              `/management/class/${
                                performance?.tests[item][0]?.classroom_id ??
                                classroom_id
                              }`
                            );
                          }}
                        >
                          To Classroom
                        </button>
                      </>
                    )}
                  </section>

                  {showActions && (
                    <div className="d-flex align-items-center testResults__header-select">
                      <span>Show</span>
                      <LimitSelect
                        disableHandler={performance?.tests[item]?.length < 10}
                        onChangeHandler={(e: any) =>
                          handleLimitChange(i, Number(e?.value))
                        }
                        isAssessment
                      />
                      <span>Assessments</span>
                    </div>
                  )}
                </div>
                  
                <div className="reportCardPerformanceDetails-table-box">
                  <div className="reportCardPerformanceDetails-table-container">
                    <div className="table-responsive">
                      <table className="reportCardPerformanceDetails__table">
                        <thead className="reportCardPerformanceDetails__table-header">
                          <tr className="reportCardPerformanceDetails__table-row">
                            <th className="reportCardPerformanceDetails__table-completed">
                              Completed Summative
                            </th>
                            <th
                              className="reportCardPerformanceDetails__table-date-attempts"
                              onClick={() => {
                                handleSortClick({
                                  className: item,
                                  isSummative: true,
                                });
                              }}
                              onKeyDown={(e)=>{
                                if(e.key==="Enter"){
                                  handleSortClick({
                                    className: item,
                                    isSummative: true,
                                  });
                                }
                              }}
                            >
                              Date
                              {showActions && (
                                <img
                                  src={ArrowDownImg}
                                  alt="arrow icon"
                                  className={getClassName({
                                    className: item,
                                    isSummative: true,
                                  })}
                                />
                              )}
                            </th>
                            <th className="reportCardPerformanceDetails__table-score attempts-count">
                              Attempts
                            </th>
                            {showLastThreeAttempts ? (
                              <>
                                <th className="reportCardPerformanceDetails__table-score">
                                  Attempt 1
                                </th>

                                {attemptTwoExists ? (
                                  <th className="reportCardPerformanceDetails__table-score">
                                    Attempt 2
                                  </th>
                                ) : (
                                  <th className="reportCardPerformanceDetails__table-score"></th>
                                )}

                                {attemptThreeExists ? (
                                  <th className="reportCardPerformanceDetails__table-score">
                                    Attempt 3
                                  </th>
                                ) : (
                                  <th className="reportCardPerformanceDetails__table-score"></th>
                                )}
                                {/* <th className="reportCardPerformanceDetails__table-score"></th> */}
                              </>
                            ) : (
                              <>
                                <th className="reportCardPerformanceDetails__table-score">
                                  Last Score
                                </th>
                                <th className="reportCardPerformanceDetails__table-score"></th>
                                <th className="reportCardPerformanceDetails__table-score"></th>
                                <th className="reportCardPerformanceDetails__table-score"></th>
                              </>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {!skeletonLoad &&
                          classrooms[item]?.summatives?.length > 0 ? (
                            classrooms[item]["summatives"].map(
                              (test: any, i: number) => {
                                const attempts = test?.scored_summatives || [];

                                return (
                                  <tr
                                    key={i}
                                    className="reportCardPerformanceDetails__table-row reportCardPerformanceDetails__table-row-border"
                                  >
                                    <td className="completed-data">
                                      {test?.name}
                                    </td>
                                    <td>{test?.date ? test?.date : "-"}</td>
                                    <td className="reportCardPerformanceDetails__table-attempts">
                                      {attempts?.length}
                                    </td>
                                    {showLastThreeAttempts ? (
                                      <>
                                        <td>
                                          {attempts[0]?.score_percentage && (
                                            <span
                                              className={`level-box level-box-${attempts[0]?.score_percentage} level-box-radius`}
                                            ></span>
                                          )}
                                          {attempts[0]?.score_percentage}
                                        </td>
                                        <td>
                                          {attempts[1]?.score_percentage && (
                                            <span
                                              className={`level-box level-box-${attempts[1]?.score_percentage} level-box-radius`}
                                            ></span>
                                          )}
                                          {attempts[1]?.score_percentage}
                                        </td>
                                        <td>
                                          {attempts[2]?.score_percentage && (
                                            <span
                                              className={`level-box level-box-${attempts[2]?.score_percentage} level-box-radius`}
                                            ></span>
                                          )}
                                          {attempts[2]?.score_percentage}
                                        </td>
                                      </>
                                    ) : (
                                      <td>
                                        {test?.last_score && (
                                          <span
                                            className={`level-box level-box-${test?.last_score} level-box-radius`}
                                          ></span>
                                        )}
                                        {test?.last_score}
                                      </td>
                                    )}
                                  </tr>
                                );
                              }
                            )
                          ) : (
                            <tr>
                              <td
                                colSpan={showLastThreeAttempts ? 6 : 4}
                                className="reportCardPerformanceDetails__table_no-data"
                              >
                                No Records
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  {showFormatives && (
                    <div className="reportCardPerformanceDetails-table-container">
                      <div className="table-responsive">
                        <table className="reportCardPerformanceDetails__table">
                          <thead className="reportCardPerformanceDetails__table-header">
                            <tr className="reportCardPerformanceDetails__table-row">
                              <th className="reportCardPerformanceDetails__table-completed">
                                Completed Formatives
                              </th>
                              <th
                                className="reportCardPerformanceDetails__table-date-attempts"
                                onClick={() =>
                                  handleSortClick({
                                    className: item,
                                    isSummative: false,
                                  })
                                }
                              >
                                Date
                                <img
                                  src={ArrowDownImg}
                                  className={getClassName({
                                    className: item,
                                    isSummative: false,
                                  })}
                                  alt="arrow icon"
                                />
                              </th>
                              <th className="reportCardPerformanceDetails__table-score attempts-count">
                                Attempts
                              </th>
                              {showLastThreeAttempts ? (
                                <>
                                  <th className="reportCardPerformanceDetails__table-score">
                                    Attempt 1
                                  </th>
                                  <th className="reportCardPerformanceDetails__table-score">
                                    Attempt 2
                                  </th>
                                  <th className="reportCardPerformanceDetails__table-score">
                                    Attempt 3
                                  </th>
                                  {/* <th className="reportCardPerformanceDetails__table-score"></th> */}
                                </>
                              ) : (
                                <>
                                  <th className="reportCardPerformanceDetails__table-score">
                                    Last Score
                                  </th>
                                  <th className="reportCardPerformanceDetails__table-score"></th>
                                  <th className="reportCardPerformanceDetails__table-score"></th>
                                  <th className="reportCardPerformanceDetails__table-score"></th>
                                </>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {/* {skeletonLoad ? (
                              <TableSkeleton
                                columns={showLastThreeAttempts ? 6 : 4}
                                count={10}
                                height={36}
                              />
                            ) : (
                              ""
                            )} */}
                            {formativeData?.length > 0 ? (
                              formativeData.map(
                                (test: any, i: number) => {
                                  const attempts =
                                    test?.scored_formatives || [];
                                  return (
                                    <tr
                                      key={i}
                                      className="reportCardPerformanceDetails__table-row reportCardPerformanceDetails__table-row-border"
                                    >
                                      <td className="completed-data">
                                        {test?.name}
                                      </td>
                                      <td>{test?.date ? test?.date : "-"}</td>
                                      <td className="reportCardPerformanceDetails__table-attempts">
                                        {attempts?.length}
                                      </td>
                                      {showLastThreeAttempts ? (
                                        <>
                                          <td>
                                            {
                                             performance?.variants?.length>1 && attempts[0]?.variant && (
                                                <AttemptVariant isPerformance= {true} attemptVariant={attempts[0]?.variant} />
                                              )
                                            }
                                            {attempts[0]?.score_percentage && (
                                              <span
                                                className={`level-box level-box-${attempts[0]?.score_percentage} level-box-radius`}
                                              ></span>
                                            )}
                                            {attempts[0]?.score_percentage}
                                          </td>
                                          <td>
                                          {
                                            performance?.variants?.length>1 && attempts[1]?.variant && (
                                              <AttemptVariant isPerformance= {true} attemptVariant={attempts[1]?.variant} />
                                              )
                                            }
                                            {attempts[1]?.score_percentage && (
                                              <span
                                                className={`level-box level-box-${attempts[1]?.score_percentage} level-box-radius`}
                                              ></span>
                                            )}
                                            {attempts[1]?.score_percentage}
                                          </td>
                                          <td>
                                          {
                                            performance?.variants?.length>1 && attempts[2]?.variant && (
                                              <AttemptVariant isPerformance= {true} attemptVariant={attempts[2]?.variant} />
                                              )
                                            }
                                            {attempts[2]?.score_percentage && (
                                              <span
                                                className={`level-box level-box-${attempts[2]?.score_percentage} level-box-radius`}
                                              ></span>
                                            )}
                                            {attempts[2]?.score_percentage}
                                          </td>
                                        </>
                                      ) : (
                                        <td>
                                           {
                                            performance?.variants?.length>1 && test?.last_attempt_variant && (
                                              <AttemptVariant isPerformance= {true} attemptVariant={test?.last_attempt_variant} />
                                              )
                                            }
                                          {test?.last_score && (
                                            <span
                                              className={`level-box level-box-${test?.last_score} level-box-radius`}
                                            ></span>
                                          )}
                                          {test?.last_score}
                                        </td>
                                      )}
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td
                                  colSpan={showLastThreeAttempts ? 6 : 4}
                                  className="reportCardPerformanceDetails__table_no-data"
                                >
                                  No Records
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          );
        })
      ) : (
        <div className="reportCardPerformanceDetails">
          <div className="reportCardPerformanceDetails__header">
            <section className="reportCardPerformanceDetails__header-heading">
              <h3 className="reportCardPerformanceDetails__header-title">
                <Skeleton
                  baseColor="#9c9fa343"
                  highlightColor="#d2d5db32"
                  width={220}
                />
              </h3>
            </section>
          </div>
          <div className="reportCardPerformanceDetails-table-box">
            <div className="reportCardPerformanceDetails-table-container">
              <div className="table-responsive">
                <table className="reportCardPerformanceDetails__table">
                  <thead className="reportCardPerformanceDetails__table-header">
                    <tr className="reportCardPerformanceDetails__table-row">
                      <TableSkeleton columns={6} count={1} height={36} />
                    </tr>
                  </thead>
                  <tbody>
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((id) => {
                      return <ReportCardSkeleton loading i={id - 1} />;
                    })}

                    {/* <TableSkeleton
                      columns={showLastThreeAttempts ? 7 : 4}
                      count={10}
                      height={36}
                    /> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Performance;
