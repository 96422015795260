import { Button } from "components/common/buttonComponent";

const ChangeIcon = ({url, onClickChangeIcon}: any) => {
  return (
    <>
        <div className="d-flex justify-content-center body-icon">
            <p>Icon</p>
            <div className="toolImg">
                <img src={url} alt="Tool" />
            </div>
        </div>
        <Button 
            buttonText="Change Icon"
            onClick={() => onClickChangeIcon()}
            className="btn change-icon mx-auto"
            type="button" 
        />
    </>
  )
};

export default ChangeIcon;
