const Checked = ({onClick}:{onClick?: () => void}) => {
    const titleId = "checkedGoalSVG";

    return (
        <svg aria-labelledby={titleId} onClick={() => onClick && onClick()} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <title id={titleId}>Selected</title>
            <path d="M25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25C19.4036 25 25 19.4036 25 12.5Z" fill="#46AFB8"/>
            <mask id="mask0_358_10631" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="4" y="5" width="17" height="14">
                <path d="M4.34766 5.88062H20.652V18.9241H4.34766V5.88062Z" fill="white"/>
            </mask>
            
            <g mask="url(#mask0_358_10631)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1668 6.43894L20.1264 6.39669C19.4788 5.70827 18.4183 5.70827 17.7707 6.39669L10.085 14.5656L7.22879 11.5295C6.58118 10.8411 5.52073 10.8411 4.87312 11.5295L4.83337 11.5725C4.18575 12.2616 4.18575 13.3867 4.83337 14.0762L8.90763 18.4075C9.55558 19.096 10.6157 19.096 11.2636 18.4075L20.1668 8.94268C20.8137 8.2539 20.8137 7.12736 20.1668 6.43894Z" fill="white"/>
            </g>
            
        </svg>
    )
};

export default Checked;
