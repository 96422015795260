import Discussion from "components/common/svgs/discussion";
import Calendar from "components/common/svgs/pacing-calendar";
import ReportCard from "components/common/svgs/report-card";
import Resource from "components/common/svgs/resource";
import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment";
import { HeaderTabs } from "interfaces/professionalDevelopment";
import { useContext } from "react";

const TABS = [
  {
    name: HeaderTabs.Resources,
    Icon: Resource,
  },
  {
    name: HeaderTabs.Pacing_Calender,
    Icon: Calendar,
  },
  {
    name: HeaderTabs.Discussion,
    Icon: Discussion,
  },
  {
    name: HeaderTabs.Report_Cards,
    Icon: ReportCard,
  },
];

type TabsProps = {
  defaultTabs?: boolean;
};

const Tabs = ({ defaultTabs = true }: TabsProps) => {
  const { pdClassroom, updateActiveHeaderTab, classroomId } = useContext(
    ProfessionalDevelopmentContext
  );

  const { is_enabled } = pdClassroom;
  const modifiedTabs = TABS.map((tab) => {
    let isEnabled = false;

    if (tab.name === HeaderTabs.Pacing_Calender) {
      isEnabled = is_enabled && !!classroomId;
    } else {
      isEnabled = !!classroomId;
    }

    return {
      ...tab,
      isEnabled,
      // isEnabled: (tab.label === HeaderTabs.Pacing_Calender || tab.label === HeaderTabs.THIS_WEEK)  ? (is_enabled && !!classroomId) : tab.label === HeaderTabs.Discussion ? (is_enabled && true) : !!classroomId
    };
  });

  return (
    <div className="classroomTabs">
      {defaultTabs ? (
        modifiedTabs.map((item, index: number) => {
          const { name, Icon, isEnabled } = item;
          return (
            <button
              type="button"
              tabIndex={0}
              key={index}
              onClick={() => isEnabled && updateActiveHeaderTab(name)}
              className={`tab ${isEnabled ? "cursor-pointer" : ""}`}
              style={{ opacity: isEnabled ? 1 : 0.5 }}
            >
              <Icon />
              <p className="">{name}</p>
            </button>
          );
        })
      ) : (
        <button
          type="button"
          tabIndex={0}
          key={1}
          onClick={() => updateActiveHeaderTab(HeaderTabs.Discussion)}
          className={`tab cursor-pointer`}
          style={{ opacity: 1 }}
        >
          <Discussion />
          <p className="">{HeaderTabs.Discussion}</p>
        </button>
      )}
    </div>
  );
};

export default Tabs;
