/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { getGradeFrequencySummativeReport } from "redux/actionCreators/classroom";
import { RootState } from "redux/reducers/combine";
import { GradeFrequencyGraph } from "../graphs/GradeFrequencyGraph";
import PrintFooter from "components/common/report/Print/footer";
import { browserHeights} from "util/index";

const GradeFrequencyDistributionPrint = () => {
  const dispatch = useDispatch();
  const { id }: any = useParams();
  const report = useSelector((state: RootState) => state).classroom
    .gradeFrequencySummativeReport;

  useEffect(() => {
    dispatch(getGradeFrequencySummativeReport(id));
  }, [id]);

  return (
    <div className="grade-frequency-report-print">
      <div className={`text-white ${browserHeights}`}>
        <div className="print-content">

          <div className="print-report__header">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h3 className="title">Grade Frequency and Distribution Report</h3>
                <p className="print-report__header-text">
                  {report?.data?.summative_name || "-"}
                </p>
              </div>
              <div>
                <h3 className="title text-right">
                  {moment(new Date()).format("M-D-YY")}
                </h3>
                <p className="print-report__header-text text-right">
                  {report?.data?.class_name}
                </p>
              </div>
            </div>
          </div>

          <div className="bg-print">
          <div className="report__levels">
              <div className="report__levels-container">
                <p className="report__levels-title">
                  Performance Level (Percentage Score Range)
                </p>

                <div className="report__levels-percentage">
                  <div className="report__levels-row">
                    <div className="d-flex align-items-center">
                      <div className="report__levels-rectangle advanced"></div>
                      <p>Advanced</p>
                    </div>
                    <p>( 85% - 100%)</p>
                  </div>
                  <div className="report__levels-row">
                    <div className="d-flex align-items-center">
                      <div className="report__levels-rectangle proficient"></div>
                      <p>Proficient</p>
                    </div>
                    <p>( 70% - 84%)</p>
                  </div>
                  <div className="report__levels-row">
                    <div className="d-flex align-items-center">
                      <div className="report__levels-rectangle basic"></div>
                      <p>Basic</p>
                    </div>
                    <p>( 52% - 69%)</p>
                  </div>
                  <div className="report__levels-row">
                    <div className="d-flex align-items-center">
                      <div className="report__levels-rectangle below"></div>
                      <p>Below Basic</p>
                    </div>
                    <p>( 0% - 51%)</p>
                  </div>
                </div>

        
                <div className="d-flex align-items-center">
                  <span className="report__levels-number">N</span>
                  <p className="report__levels-text mb-0">
                    Number of scored assessments in performance level
                  </p>
                </div>
              </div>
            </div>
            {report?.data && (
              <div className="mt-5">
                <div className="report__printChart">
                  <div className="report__printChart-container">
                    <GradeFrequencyGraph data={report.data} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <PrintFooter />
    </div>
  );
};
export default GradeFrequencyDistributionPrint;
