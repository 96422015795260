import { SuperAdminChatbox, globalStatusInterface } from "interfaces/superAdminChatbox"
import { createContext, useEffect, useReducer, useState } from "react"
import { getActiveChats, getSuperAdminChatById, putSuperAdminChat, } from "services/supportTicketSuperAdmin"
import { SuperadminChatBoxActions, SuperAdminChatboxReducer } from "./reducers/superAdminChatbox"
import { getMasteryVideos, uploadImageToS3 } from "services/suppot-ticket"

export const SuperAdminChatboxContext = createContext<SuperAdminChatbox>(null!)

export const initialState: SuperAdminChatbox = {
    videoOptions: [],
    assignChatToAdmin: null!,
    resolveChatFromReducer: null!,
    setChatById: null!,
    setUnreadMessagesCount: null!,
    resolveChat: null!,
    fetchPreviousChatById: null!,
    fetchActiveChats: null!,
    fetchChatById: null!,
    putChat: null!,
    singleAdminChat: null!,
    unsetChatById: null!,
    activeChats: {
        status: '',
        global_status: '',
        chats: [],
        assigned_chats: 0,
        unassigned_chats: 0,
        originalTicket: "",
        active_admins: []
    },
    sendMessageSuperAdmin: null!,
    onStatusChange: () => {},
    setActiveChatsAvailable: null!,
    setChatQueryParams: null!,
    singleChatQuery: {
        limit: 50,
        offset: 0
    },
    unsetSuperAdminChat: null!,
    addAttachmentToTicket: null!,
    setGlobalStatus: null!,
    setSuperAdminChatMessage: null!,
    unsetSuperAdminChatMessage: null!
}

const SuperAdminChatboxProvider = ({ children }: any) => {
    const [{ activeChats, singleAdminChat, videoOptions }, dispatch] = useReducer(SuperAdminChatboxReducer, initialState)
    const [singleChatQuery, setChatQueryParams] = useState(initialState.singleChatQuery)

    const setUnreadMessagesCount = (data: any) => {
        dispatch({
            type: SuperadminChatBoxActions.SET_UNREAD_MESSAGES_COUNT,
            payload: data
        })
    }

    const setActiveChatsAvailable = (availableCount: any) => {
        dispatch({
            type: SuperadminChatBoxActions.SET_ACTIVE_CHATS_AVAILABLE,
            payload: availableCount
        })
    }

    const setGlobalStatus = (payload: globalStatusInterface) => {
        
        payload.global_status && dispatch({
            type: SuperadminChatBoxActions.SET_ACTIVE_CHATS_GLOBAL_STATUS,
            payload
        });
        
        return activeChats;
    }

    const fetchActiveChats = async (markRead: boolean = true) => {
        const activeChats = await getActiveChats(markRead);
        activeChats && dispatch({
            type: SuperadminChatBoxActions.SET_ACTIVE_CHATS,
            payload: activeChats
        });

        return activeChats;
    }

    const fetchPreviousChatById = async (payload: any) => {
        const { messages, messages_count } = await getSuperAdminChatById(payload);

        messages && dispatch({
            type: SuperadminChatBoxActions.ADD_MESSAGES_TO_CHAT,
            payload: {messages, messages_count}
        })
    }

    const unsetChatById = async () => {
        dispatch({
            type: SuperadminChatBoxActions.UNSET_CHAT_BY_ID,
        })
    }

    const resolveChatFromReducer = (chatId: any) => {
        dispatch({
            type: SuperadminChatBoxActions.RESOLVE_CHAT,
            payload: chatId
        });
    }

    const resolveChat = async (payload: any) => {
        const chat = await putSuperAdminChat(payload);
        if(chat){
            resolveChatFromReducer(chat.id);
            unsetChatById();
        }
        return chat;
    }

    const putChat = async (payload: any) => {
        const chat = await putSuperAdminChat(payload);
        chat && dispatch({
            type: SuperadminChatBoxActions.SET_SUPER_ADMIN_CHAT,
            payload: chat
        })
        return chat;
    }
    
    const unsetSuperAdminChat = async (chatId: number) => {
        dispatch({
            type: SuperadminChatBoxActions.UNSET_SUPER_ADMIN_CHAT,
            payload: chatId
        })
    }

    const assignChatToAdmin = async (payload: {type:string, admin_id: number, chatId: number}) => {
        const chat = await putSuperAdminChat(payload);
        return chat;
    }

    const setChatById = (chat: any) => {
        dispatch({
            type: SuperadminChatBoxActions.SET_CHAT_BY_ID,
            payload: chat
        });
        setUnreadMessagesCount({unread_messages:0, thread_id: chat.id});
    }

    const fetchChatById = async (payload: any) => {
        const chat = await getSuperAdminChatById(payload);
        chat && setChatById(chat);
    }

    const setSuperAdminChatMessage = (messageData: any, tempId: any) => {
        dispatch({
            type: SuperadminChatBoxActions.SET_SUPER_ADMIN_CHAT_MESSAGE,
            payload: {messageData, tempId}
        })
    }

    const unsetSuperAdminChatMessage = (messageData: any, tempId: any) => {
        dispatch({
            type: SuperadminChatBoxActions.UNSET_SUPER_ADMIN_CHAT_MESSAGE,
            payload: {messageData, tempId}
        });
    }

    const sendMessageSuperAdmin = async (payload: any, messageData: any) => {
        const { message } = messageData;
        setSuperAdminChatMessage(messageData, messageData.tempId);

        const chat = await putChat({ ...payload, message });

        if(!chat){
            unsetSuperAdminChatMessage(messageData, messageData.tempId)
            return null;
        }
        // dispatch({
        //     type: SuperadminChatBoxActions.SET_SUPER_ADMIN_CHAT_MESSAGE,
        //     payload: {messageData: chat.messages.length > 0 ? chat.messages[0] : messageData, tempId: messageData.tempId}
        // })

        return chat;
    }

    const fetchMasteryVideos = async () => {
        const response = await getMasteryVideos()
        const { videos } = response || {};
        videos && dispatch({
            type: SuperadminChatBoxActions.SET_MASTERY_VIDEOS,
            payload: videos
        })
    }

    const addAttachmentToTicket = async (file: any) => {
        const response = await uploadImageToS3(file)
        return response[0]
    }


    const onStatusChange = (status: number) => {
        // setStatus(status);
        dispatch({
            type: SuperadminChatBoxActions.SET_ACTIVE_CHATS_STATUS,
            payload: status
        });
    }

    useEffect(() => {
        fetchMasteryVideos();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    
    return <>
        <SuperAdminChatboxContext.Provider value={{
            setUnreadMessagesCount,
            setChatQueryParams,
            singleChatQuery,
            setActiveChatsAvailable,
            resolveChat,
            sendMessageSuperAdmin,
            fetchChatById,
            fetchActiveChats,
            unsetChatById,
            fetchPreviousChatById,
            activeChats,
            singleAdminChat,
            putChat,
            onStatusChange,
            setChatById,
            resolveChatFromReducer,
            assignChatToAdmin,
            unsetSuperAdminChat,
            videoOptions,
            addAttachmentToTicket,
            setGlobalStatus,
            setSuperAdminChatMessage,
            unsetSuperAdminChatMessage
        }}>
            {children}
        </SuperAdminChatboxContext.Provider>
    </>
}
export default SuperAdminChatboxProvider