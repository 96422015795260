import AttemptVariant from 'components/common/AttemptVariant';
import { FooterSkeleton } from 'components/common/skeleton/ReportsSkeleton'
import React, { FC } from 'react'
interface Props {
    answers: any
    questionId: number; 
    isLoading: boolean;
    variant: string;
    variantBIsPresent?:boolean
    variants:string[]
}



const CorrectAnswers:FC<Props> = ({answers, isLoading, questionId, variant, variantBIsPresent, variants}) => {
    
    const mapArray = new Array(5).fill(0);
    const variantOption = variant ==="Var A"? "A": "B";
  return (
            <div className={`report__total ${variant==="Var A" && variantBIsPresent ? "radius dark-gray var-padding":""}`} style={{height: "37px"}}>
                
                {isLoading ?
                <div style={{marginTop: "15px"}} className="report__total-title formative-title">Correct Answer</div>
                :
                <div className="report__total-title formative-title">Correct Answer {variants.length>1 && <AttemptVariant isPerformance attemptVariant={variantOption} />}</div>
            }
                <div className="report__total-count">
                    {!isLoading ? mapArray.map((_:any, index:number) => {
                        return <span className={`${questionId && answers?.length ? answers[index]?.id === questionId ? '' : 'hidden' : ''} report__total-total answer`}>
                            {answers[index]?.info}
                        </span>
                       
                    })
                    :<div style={{transform: 'translateX(-5.5px)'}}>
                       <FooterSkeleton rowColumns={5} />
                       <FooterSkeleton rowColumns={5} />

                    </div>
                }
                </div>
                <div className="report__numbers-attempt"></div>
                <div className="report__numbers-score formative-empty"></div>
            </div>
  )
}

export default CorrectAnswers
