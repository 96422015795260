/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import ArrowIcon from "assets/img/accordian-box-icon.png";
import DropdownWithLabel from "components/common/DropdownWithLabel";
import ButtonDropDown from "components/common/buttonDropDown";
import LabelWithToggle from "components/common/labelWithToggle";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PerformanceSets from "components/common/studentInfoComponents/PerformanceSets";
import {
  performanceOptions,
  yearOptions,
} from "components/reportCard/types/type";
import classNames from "util/classNames";
import moment from "moment";
import { REPORT_OPTIONS } from "components/reportCard";

const StudentHeader = ({
  performance,
  students,
  triggerUpdate,
  performanceEnabled = true,
  showLastThreeAttempts,
  setShowLastThreeAttempts,
  showAllClasses,
  setShowAllClasses,
  triggerPrint,
  studentDetail = false,
  studentData = null,
  subject = 2,
  isPrev = false,
  selectedPerformance,
  setSelectedPerformance,
  download,
  downloadAll,
  loadingText, 
  prevYearExists, 
  isStudentDetail
}: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [studentOptions, setStudentOptions] = useState([]);
  const [openStudentModal, setOpenStudentModal] = useState<
    boolean | undefined
  >();
  const [openYearModal, setOpenYearModal] = useState<boolean | undefined>();
  const [openPerformanceModal, setOpenPerformanceModal] = useState<
    boolean | undefined
  >();
  const [selectedStudent, setSelectedStudent] = useState<any>();
  const [selectedYear, setSelectedYear] = useState<any>(yearOptions[0]);

  const [nextVal, setNextVal] = useState(0);
  const [backVal, setBackVal] = useState(0);

  const handleOptionChange = (option: string) => {
    if (option === "download") {
      download();
      return;
    }
    if (option === "download-all") {
      downloadAll();
      return;
    }

    if (performanceEnabled) {
      triggerPrint(option, REPORT_OPTIONS.PERFORMANCE_BASED); // 1 for performance
    } else {
      triggerPrint(option, REPORT_OPTIONS.STANDARD_BASED); // 2 for SBR
    }
  };

  const dropdownOptions: any[] = [
    { label: "Print", value: "print" },
    { label: "Print All", value: "print_all" },
    { label: "Download PDF", value: "download" },
    { label: "Download PDFs All", value: "download-all" },
  ];

  useEffect(() => {
    if (!studentData?.science_enabled) {
      setSelectedPerformance(performanceOptions[0]);
    }
  }, [subject]);

  useEffect(() => {
    if (location.state?.student) {
      const std = location.state?.student;
      const singleOption = {
        id: std.id,
        value: std.id,
        label: `${std.last_name}, ${std.first_name}`,
        item: std,
        has_year: std.has_previous_year || false,
      };
      setStudentOptions([{ ...singleOption }] as any);
      setSelectedStudent(singleOption);
    } else if (students && students?.length) {
      const options = students?.map((student: any) => {
        return {
          id: student.id,
          value: student.id,
          label: student.full_name,
          item: student,
          has_year: student.has_previous_year,
        };
      });

      if (options && options.length) setSelectedStudent(options[0]);
      setStudentOptions(options);
    } else if (studentData) {
      const singleOption = {
        id: studentData.id,
        value: studentData.id,
        label: `${studentData.last_name}, ${studentData.first_name}`,
        item: studentData,
        has_year: studentData?.previous_year_id ? true : false,
      };
      setSelectedStudent(singleOption);
    }
  }, [students]);

  useEffect(() => {
    if (openStudentModal) {
      setOpenPerformanceModal(false);
      setOpenYearModal(false);
    } else if (openYearModal) {
      setOpenStudentModal(false);
      setOpenPerformanceModal(false);
    } else if (openPerformanceModal) {
      setOpenStudentModal(false);
      setOpenYearModal(false);
    }
  }, [openStudentModal, openYearModal, openPerformanceModal]);

  useEffect(() => {
    triggerUpdate(selectedStudent?.id, 1);

    if (!selectedStudent?.has_year) {
      setSelectedYear(yearOptions[0]);
    }
  }, [selectedStudent]);

  useEffect(() => {
    triggerUpdate(selectedYear?.id, 2);
  }, [selectedYear]);

  useEffect(() => {
    triggerUpdate(selectedPerformance?.id, 3);
  }, [selectedPerformance]);

  function getRandomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Handler function for keydown event
  const handleKeyDown = (event: any) => {
    switch (event.key) {
      case "ArrowUp":
        console.log("Up arrow key pressed");
        // Add your logic here for up arrow key
        break;
      case "ArrowDown":
        console.log("Down arrow key pressed");
        // Add your logic here for down arrow key
        break;
      case "ArrowLeft":
        console.log("Left arrow key pressed");
        const randomNumberBack = getRandomNumber(1, 100);
        setBackVal(backVal + randomNumberBack);
        break;
      case "ArrowRight":
        const randomNumberNext = getRandomNumber(1, 100);
        setNextVal(nextVal + randomNumberNext);
        break;
      default:
        break;
    }
  };


  //if we are coming from classroom reports, then we need to check if it is a science classroom and the science enabled or not for this student
  const getDisabledCondition = () => {
    return (
      performance?.classroom_ids?.length === 0 ||
      (!isStudentDetail&& subject === 3 && !studentData?.science_enabled)
    );
  };


  // Add event listener on component mount and clean up on unmount
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  
 
  return (
    <section
      className={classNames(
        "reportCards__header text-white",
        studentDetail ? "reportCards__header-student" : ""
      )}
    >
      <div className="reportCards__student-performance">
        <div
          className={classNames(
            "reportCards__student-performance-report-card",
            !studentDetail ? "card-all-students" : ""
          )}
        >
          {studentDetail && students.length === 0 && (
            <div className="student-info">
              <div className="student-info-item">
                <div className="student-info-item-status">
                  <p>
                    <b>Student ID:</b>
                  </p>
                  <p>{studentData?.school_student_id}</p>
                </div>
              </div>
              <div className="student-info-item">
                <div className="student-info-item-status">
                  <p>
                    <b>Last Login:</b>
                  </p>
                  {studentData?.last_login ? (
                    <p>{moment(studentData?.last_login).format("M-D-YY")}</p>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            </div>
          )}
          <div
            className={classNames(
              "reportCards__student-performance-report-card-text",
              studentDetail
                ? "reportCards__student-performance-report-card-text-student-detail"
                : ""
            )}
          >
            {!studentDetail && (
              <div className="showAsRow">
                <span
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      navigate(-1);
                    }
                  }}
                  tabIndex={0}
                  role="button"
                  className="accordion__header-button"
                  onClick={() => navigate(-1)}
                >
                  <img src={ArrowIcon} alt="arrow" className="rotate-90" />
                </span>
                <h6 className="reportCards__student-performance-report-card-title">
                  Student Report Cards
                </h6>
              </div>
            )}

            {studentDetail && students.length === 0 && (
              <div className="showAsRow">
                <h6 className="reportCards__student-performance-report-card-title">
                  Report Cards
                </h6>
              </div>
            )}

            <div className="showAsRowToggles">
              {performanceEnabled && (
                <LabelWithToggle
                  initialCompleted={showLastThreeAttempts}
                  label="Show Last 3 Attempts"
                  onMarkCompleted={setShowLastThreeAttempts}
                ></LabelWithToggle>
              )}

              {!studentDetail && (
                <LabelWithToggle
                  initialCompleted={showAllClasses}
                  label="Show All Classes"
                  onMarkCompleted={setShowAllClasses}
                  isMarkCompletedDisabled={
                    performance?.classroom_ids?.length > 1 ||
                    studentData?.performance?.classroom_ids?.length > 1
                      ? false
                      : true
                  }
                ></LabelWithToggle>
              )}
            </div>
          </div>
          <div
            className={classNames(
              !studentDetail
                ? "reportCards__student-performance-report-card-dropdowns"
                : ""
            )}
          >
            <div className="reportCards__student-performance-report-card-dropdowns-container">
              {!studentDetail && (
                <DropdownWithLabel
                  containerStyle="dropdownsCommon-width250"
                  label="Student"
                  options={studentOptions}
                  selectStyle="admin-selects__marginTop0"
                  placeHolder="Select Student"
                  onSelect={(option) => {
                    setSelectedStudent(option);
                  }}
                  open={openStudentModal}
                  setOpen={setOpenStudentModal}
                  onChangeHandler={(option) => {
                    setSelectedStudent(option);
                  }}
                  noOptionsMessage={"No Students Found"}
                  value={selectedStudent}
                  enableForwardBack={true}
                  triggerBackClick={backVal}
                  triggerNextClick={nextVal}
                ></DropdownWithLabel>
              )}

              <DropdownWithLabel
                disableHandler={prevYearExists ? false: true}
                label="Year"
                options={yearOptions}
                onSelect={(option) => {
                  setSelectedYear(option);
                }}
                selectStyle="admin-selects__marginTop0"
                placeHolder="Select Year"
                containerStyle="dropdownsCommon-width150"
                open={openYearModal}
                setOpen={setOpenYearModal}
                onChangeHandler={(option) => {
                  setSelectedYear(option);
                }}
                noOptionsMessage={""}
                value={selectedYear}
              ></DropdownWithLabel>

              <DropdownWithLabel
              
                disableHandler = {getDisabledCondition()}
                containerStyle="dropdownsCommon-width200"
                label="Type"
                options={performanceOptions}
                onSelect={(option) => {
                  setSelectedPerformance(option);
                }}
                selectStyle="admin-selects__marginTop0"
                placeHolder="Select Type"
                open={openPerformanceModal}
                setOpen={setOpenPerformanceModal}
                onChangeHandler={(option) => {
                  setSelectedPerformance(option);
                }}
                noOptionsMessage={""}
                value={selectedPerformance}
              ></DropdownWithLabel>
              <div className="reportCards__dropdownButton">
                <ButtonDropDown
                  onChange={handleOptionChange}
                  options={dropdownOptions}
                  biggerWidth={false}
                  showDropDown={students.length === 0 ? false : true}
                  loadingText={loadingText}
                ></ButtonDropDown>
              </div>
            </div>
          </div>
        </div>
        <PerformanceSets
          performance={performance}
          performanceEnabled={performanceEnabled}
          subject={subject}
          isPrev={isPrev}
          showPerformanceLevelForScience = {studentData?.science_enabled}
        ></PerformanceSets>
      </div>
    </section>
  );
};

export default StudentHeader;
