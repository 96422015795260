import PersonIcon from "components/common/svgs/person-icon";
import Toggle from "components/common/Toggle";
import { ProgressbarCore } from "components/theme/activityBar/progressBar";
import { SingleFormative } from "interfaces/professionalDevelopment";
import moment from "moment";
import { FORMATIVE_PROFICIENY } from "util/constants";

interface IProps {
    loading: boolean;
    formative: SingleFormative;
    handleOnChangeToggleFormative: any;
    onMarkCompleted: any;
    onClickRemediate: any;
    completed:boolean;
}

const CollapsedFormatives = ({loading, completed, formative, onMarkCompleted, handleOnChangeToggleFormative, onClickRemediate}: IProps) => {

    const {
        id,
        students_assessed_count,
        students_total_count,
        remediation_count,
        enabled,
        last_activity,
        proficiency
    } = formative;

    const lastActivityFormatted = last_activity ? moment(last_activity).format("M-D-YY") : 'Not Yet';

    const onChangeAvailable = (e: any) => {
        handleOnChangeToggleFormative(e)
    };

    const isMarkCompletedDisabled = Math.floor((students_assessed_count/students_total_count)*100) < 50;

    return (
        <span className="close-report-bar">
            <span className="report-name">Formative</span>
            <span className="last-activity">
                <p className="label">Last Activity</p>
                <p className="date">{loading ? 'Not Yet' : lastActivityFormatted}</p>
            </span>
            {loading ? (
                <div className="progressBlock skeleton">
                    <div></div>
                    <p></p>
                </div>
            ):(
                <ProgressbarCore
                    width={Math.floor((students_assessed_count/students_total_count)*100)}
                >
                    <p>{students_assessed_count} / {students_total_count}</p>
                </ProgressbarCore>
            )}

            {loading || proficiency === null ? (
                <div className="progressBlock skeleton">
                    <div></div>
                    <p></p>
                </div>
            ):(
                <ProgressbarCore
                    isProficient={proficiency >= FORMATIVE_PROFICIENY}
                    width={proficiency}
                    color="#9080FF"
                >
                    <p>{proficiency}%</p>
                </ProgressbarCore>
            )}
            <span
                className="remediate-column"
                tabIndex={remediation_count ? 0 : -1}
                role="button"
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        e.stopPropagation();
                        if (remediation_count !== 0 && !loading) {
                            onClickRemediate(id);
                        }
                    }
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    if (remediation_count !== 0 && !loading) {
                        onClickRemediate(id);
                    }
                }}
                style={{ cursor: remediation_count !== 0 && !loading ? 'pointer' : '', opacity: (remediation_count === 0 || loading) ? 0.26 : 1 }}
            >
               <PersonIcon width="15" height="21" />
                <span>{remediation_count}</span>
            </span>

            <span className="complete-toggle-container small-toggle">
                <p>Available</p>    
                <Toggle 
                    onClick={(e)=>e.stopPropagation()} 
                    inputName="Available"
                    ariaLabel="Available"
                    onChange={onChangeAvailable}
                    checked={!!(enabled || loading)}
                    disabled={loading}
                    />
                


               
            </span>
            <span
                className="complete-toggle-container small-toggle"
            >
                <p>Completed</p>

                <Toggle
                    inputName="Completed"
                    ariaLabel="Completed"
                    onChange={(e: any) => !loading && onMarkCompleted(e, formative)}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    checked={completed}
                    disabled={isMarkCompletedDisabled || loading}
                /> 

                
            </span>
        </span>
    )
};

export default CollapsedFormatives;
