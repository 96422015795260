/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import File from "assets/img/steps-png-icon.svg";
import NoAttachment from 'assets/img/black_file.png'
// import ChatIcon from "assets/img/welcome-box-img.svg";
import ChatIconNew from "assets/img/comment-icon-new.svg";
import Close from "assets/img/remove-icon.svg";
import RichEditor from "components/common/RichEditor";
import { SupportTicketSuperAdminContext } from "contexts/supportTicketSuperAdmin";
import AddLink from "./addLinkModal";
import SubmitModal from "./submitModal";
import { TicketType } from "components/ContactSupport/ContactSupportModal";
import { handlePress, handleKeyUp, checkIfNumber } from 'util/index';
import CustomModal from "components/common/Modal";

const options = [
  { name: TicketType.BUG, label: "Bug or Connection/Data Issue" },
  { name: TicketType.TEXT_BOOK_ERROR, label: "Textbook Error" },
  { name: TicketType.ASSESSMENT_ERROR, label: "Assessment Error" },
  {
    name: TicketType.FEEDBACK_FEATURE,
    label: "Feedback or Suggest a Feature",
  },
  { name: TicketType.OTHER, label: "Other" },
];

interface Props {
  show: boolean;
  summativeId?: number;
  formativeId?: number;
  onClose: any;
  onSubmit: (data: any) => Promise<any>;
}



const NewTicketModel: React.FC<Props> = (props) => {
  const fileRef = useRef<any>(null!)
  const editorRef = useRef<any>(null!);

  const { videoOptions, addAttachmentToTicket, unsetAdmins } = useContext(SupportTicketSuperAdminContext)
  const { show, onClose, onSubmit } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving] = useState(false);
  const [, setModalSuggestedAssesment] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState<boolean>(false);
  const [lastUsedLinkModal, setLastUsedLinkModal] = useState<string>('attachments');
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [sendButtonEnabled, setSendButtonEnabled] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const [newTicketData, setNewTicketData] = useState<any>({
    adminDetails: null!,
    message: '',
    attachments: [],
    resource: '',
    issue_type: '',
    summary: '',
    deviceBrowser: '',
    text_book_name: '',
    page_number: '',
    assessment_name: '',
    ticket_for_admin: 0
  });

  const inputCountRef = useRef(0)
  const { message: editorMessage, adminDetails, summary, deviceBrowser, issue_type, resource, message, text_book_name, assessment_name } = newTicketData;

  const handleSave = async () => {
    setIsLoading(true);
    await onSubmit(newTicketData);
    setIsLoading(false);
  };

  const closeModal = () => {
    setIsLoading(false);
    onClose(false);
  };

  const createpacingHandler = () => {
    onClose(false);
    setModalSuggestedAssesment(true);
  };

  const onClickFile = () => {
    fileRef.current.click();
  }

  const onChangeResource = (e: any) => {
    setNewTicketData((prev: any) => ({ ...prev, resource: e.target.value }));
  }

  const onUploadFile = async (e: any) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    try {
      const data = new FormData()
      data.append('files', file)

      const url = await addAttachmentToTicket(data)
      inputCountRef.current < 3 && inputCountRef.current++

      setNewTicketData((prev: any) => ({ ...prev, attachments: [...prev.attachments, url] }));

    } catch (error) {
      console.log("Error inonUploadFile", error)
    }
  }

  const showSelectionModal = (displayedFrom: string) => {
    setLastUsedLinkModal(displayedFrom);
    setShowLinkModal(true);
  }

  const saveAsLink = (value: string, selectedValueID: any, selectedText: any) => {

    let asLink = '';
    let newVal: string = ''
    let innerHTML: any = '';
    
    const oldTextareaDiv: any = document.getElementsByClassName('jodit-wysiwyg');
    const isStringAlreadyExist: number = oldTextareaDiv[0]?.innerHTML.indexOf(value);  
    
    if(lastUsedLinkModal === 'mastery_videos') {
      const selectedMasteryVideo: any = videoOptions.filter((option) => {
        return option.label.trim() === resource.trim();
      });

      const video_url = `${window.location.origin.toString()}/mastery-videos?videoId=${selectedMasteryVideo.at(0)?.id}`
      asLink = selectedMasteryVideo.at(0)?.id ? `<a target="_blank" href=${video_url}>${value}</a>` : '';
    } else {

      asLink = `<a href=${newTicketData.attachments[inputCountRef.current - 1]}>${value}</a>`;
    }
    
    if(isStringAlreadyExist === -1){
      // if the element written in the popup does not exists in the textarea.

      newVal = asLink;

      const isSelectionExists = selectedText.trim().length; // means if the user has selected any text from textarea.
      
      if(isSelectionExists) {
        console.log('selectedValueID: ', selectedValueID);
        selectedValueID.innerHTML =  newVal; // here we'll update the selected text by the user with the link.

      } else {  

        // const oldTextareaDiv: any = document.getElementsByClassName('jodit-wysiwyg');
        const lastChildOfOldHtml: any = oldTextareaDiv[0]?.lastChild;
        const lastChildInnerHTMLOfOldHtml: any = lastChildOfOldHtml.innerHTML;
        lastChildOfOldHtml.innerHTML =  lastChildInnerHTMLOfOldHtml +  '<p>' + newVal + '</p>'; // here we'll concatenate the text written in the popup by the user at the end of all content.

      }

      const updatedTextAreaHTML: any = document.getElementsByClassName('jodit-wysiwyg');

      innerHTML = updatedTextAreaHTML[0]?.innerHTML;
      
    } else {
      console.log('ajeeb', selectedValueID, selectedValueID.textContent);
      // if the element written in the popup Exists in the textarea.
      const textContentOfParent: any = selectedValueID.textContent;
      console.log('textContentOfParent: ', textContentOfParent);


      const regExp = new RegExp(value, 'g')
      const matches = textContentOfParent.match(regExp);
      const isValueExistMultipleTimesInSameParent: boolean = matches != null && matches.length > 1;
      console.log('isValueExistMultipleTimesInSameParent: ', isValueExistMultipleTimesInSameParent);

      if(isValueExistMultipleTimesInSameParent){
        // this case left unattended yet.

        const completeParent: string = selectedValueID.innerHTML;
        console.log('completeParent: ', completeParent);

        const replacedString: string = completeParent.replace(/<\/?span[^>]*>/i, asLink).replace('</span>', '');

        // text.replace(/microsoft/i, "W3Schools");
        console.log('replacedString: ', replacedString);

        const arr: any = replacedString.split(asLink);

        console.log('arr0', arr[0]);
        console.log('arr1', arr[1]);

        const charactersAfterTrim = arr[1].trim().slice(value.length);
        console.log('charactersAfterTrim: ', charactersAfterTrim);


        console.log('arr[0]: ', arr[0]);
        console.log('asLink: ', asLink);
        console.log('charactersAfterTrim: ', charactersAfterTrim);

        const remakeParent = arr[0] + asLink + charactersAfterTrim;

        // const newlyAddedLinkIndex: number = replacedString.indexOf(asLink);
        newVal = remakeParent;

      } else {
        newVal = textContentOfParent.replace(value, asLink);
      }

      selectedValueID.innerHTML =  newVal;

      const updatedTextAreaHTML: any = document.getElementsByClassName('jodit-wysiwyg');

      innerHTML = updatedTextAreaHTML[0]?.innerHTML;
    }

    setNewTicketData((prev: any) => ({ ...prev, message: innerHTML }));
    
    setLastUsedLinkModal('attachments'); // default case.
    setShowLinkModal(false);
            
  }

  // const disableSelect = (e: Event | undefined) => {
  //   e = e || window.event;
  //   e?.preventDefault();
  // }

  useEffect(() => {
    if(isSubmit && newTicketData?.message){
      document.getElementById("save-button")?.click();
    }
  },[isSubmit, newTicketData])

  const onClickRemove = () => {
    setNewTicketData((prev:any) => ({...prev, adminDetails: null!}));
    unsetAdmins();
  }
  
  const onCancel = () => {
    setShowSubmitModal(false);
    unsetAdmins();
  }

  const addLinkModalCloseHandler = () => {
    setLastUsedLinkModal('attachments'); // default case.
    setShowLinkModal(false);
  }

  useEffect(() => {
    document.addEventListener('keydown', handlePress);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handlePress);
      document.removeEventListener('keyup', handleKeyUp);
    }
  }, []);
  
  const isFieldEmpty = issue_type === 'TEXT_BOOK_ERROR' ? !text_book_name : issue_type === 'ASSESSMENT_ERROR' ? !assessment_name : !summary;
  return (
    <>
      <div>
        <CustomModal
          centered
          show={show}
          onEscapeKeyDown={closeModal}
          className="SupportTicket__ReplayModal supportCenterNewTicketBtn new-ticket-modal"
          backdropClassName="SupportTicket__ReplayModal-backdrop"
        >
          <div>
            <div className="SupportTicket__ReplayModal__ModalHeader">
              New Ticket
            </div>
            <div className="SupportTicket__ReplayModal__BodyContent">
              <div className="SupportTicket__ReplayModal__LeftSec">

                <div className={`modal__form-row SupportTicket__ReplayModal__LeftSec__submit ${Object.keys(adminDetails || {}).length > 0 ? '' : 'add-admin-mb'}`}>
                  
                  <p className="label-top">Submit on Behalf of Admin</p>
                  {adminDetails && (
                    <div className="admin-detail">
                      <div className="detail-cols">
                        <div className="d-flex">
                          <p className="labels">Admin Name:</p>
                          <span className="ellipsis-animated value">
                            <p className="cursor-pointer ellipsis-animated-text animated ellipsis-animation">{adminDetails.first_name}{adminDetails.last_name ? ` ${adminDetails.last_name}`: ''}</p>
                          </span>
                        </div>
                        <div className="d-flex">
                          <p className="labels">Email:</p>
                          <span className="ellipsis-animated value">
                            <p className="cursor-pointer ellipsis-animated-text animated ellipsis-animation">{adminDetails.email}</p>
                          </span>
                        </div>
                        {adminDetails.district && (
                          <div className="d-flex">
                            <p className="labels">District:</p>
                          <span className="ellipsis-animated value">
                            <a className="cursor-pointer ellipsis-animated-text animated ellipsis-animation fw-bold" href="#/" >{adminDetails.district}</a>
                          </span>
                          </div>
                        )}
                      </div>
                      <div className="detail-cols">
                        {adminDetails.school && (
                          <div className="d-flex">
                            <p className="labels">School:</p>
                          <span className="ellipsis-animated value">
                            <p className="cursor-pointer ellipsis-animated-text animated ellipsis-animation fw-bold">{adminDetails.school}</p>
                          </span>
                          </div>
                        )}
                        {adminDetails.classrooms.length > 0 && (
                          <div className="d-flex">
                            <p className="labels">Classes:</p>
                          <span className="ellipsis-animated value">
                            <p className="cursor-pointer ellipsis-animated-text animated ellipsis-animation fw-bold">{adminDetails.classrooms.length > 0 ? adminDetails.classrooms[0].classrooms.name : ''}</p>
                          </span>
                          </div>
                        )}
                        <div className="d-flex">
                          <p className="labels"></p>
                          <a className="value fw-bold" href="#/" >{adminDetails.classrooms.length > 1 ? `${adminDetails.classrooms.length-1} Others` : ''}</a>
                        </div>
                      </div>
                      <div className="removeBtn">
                        <button onClick={onClickRemove} type="button">Remove</button>
                      </div>
                    </div>
                  )}
                  
                  {!adminDetails && (
                    <button
                      type="button"
                      className="btn PacingCalender__SuggestedModel__Suggestion mx-auto"
                      onClick={() => setShowSubmitModal(true)}
                    >
                      Add Admin
                    </button>
                  )}
                  
                </div>

                <div className="SupportTicket__ReplayModal__LeftSec__ticketType d-md-flex justify-content-between">
                  <div className={`modal__form-row mb-2 ${!!issue_type && `required-fields`}`}>
                    <select className="form-select welcome-edit-select"
                      onChange={(e:any) => setNewTicketData((prev:any) => ({...prev, issue_type: e.target.value}))}
                      value={issue_type}
                    >
                      <option disabled selected value=""> Ticket type</option>
                      {options.map(item => {
                        const {name, label} = item;
                        return <option value={name}>{label}</option>
                      })}
                      
                    </select>
                    {issue_type === 'TEXT_BOOK_ERROR' ? (
                      <>
                        <div className="input-fields">
                          <label>Textbook Name*</label>
                          <input type="text" value={newTicketData.text_book_name} onChange={(e:any) => setNewTicketData((prev:any) => ({...prev, text_book_name: e.target.value}))} />
                        </div>
                        <div className="input-fields page-number-field">
                          <label>Page Number</label>
                          <input type="number" value={newTicketData.page_number} onChange={(e:any) => setNewTicketData((prev:any) => ({...prev, page_number: e.target.value}))} onKeyDown = {(event: any) => checkIfNumber(event)} />
                        </div>
                      </>

                    ): issue_type === 'ASSESSMENT_ERROR' ? (
                      <>
                        <div className="input-fields">
                          <label>Assessment Name*</label>
                          <input type="text" value={newTicketData.assessment_name} onChange={(e:any) => setNewTicketData((prev:any) => ({...prev, assessment_name: e.target.value}))} />
                        </div>
                        <div className="input-fields page-number-field">
                          <label>Question #</label>
                          <input type="number" value={newTicketData.page_number} onChange={(e:any) => setNewTicketData((prev:any) => ({...prev, page_number: e.target.value}))} onKeyDown = {(event: any) => checkIfNumber(event)}/>
                        </div>
                      </>
                    ): issue_type && issue_type !== 'ASSESSMENT_ERROR' && issue_type !== 'TEXT_BOOK_ERROR' ? (
                      <>
                        <div className="input-fields">
                          <label>Summary*</label>
                          <input type="text" value={summary} onChange={(e:any) => setNewTicketData((prev:any) => ({...prev, summary: e.target.value}))} />
                        </div>
                        <div className="input-fields">
                          <label>Device/Browser</label>
                          <input type="text" value={deviceBrowser} onChange={(e:any) => setNewTicketData((prev:any) => ({...prev, deviceBrowser: e.target.value}))} />
                        </div>
                      </>
                    ):(
                      <p>Select Ticket Type Above</p>
                    )}
                    
                  </div>

                  
                  {issue_type && (
                    <div>
                      <p className="required">*Required Field</p>
                    </div>
                  )}
                </div>
                
                <div ref={editorRef} id="RichEditorDivID" className="SupportTicket__ReplayModal__LeftSec__Editor">
                  <RichEditor
                    value={editorMessage}
                    onChange={(value) => setNewTicketData((prev: any) => ({ ...prev, message: value }))}
                    setSendButtonEnabled={(isEnabled: any) => setSendButtonEnabled(isEnabled)}
                    setIsSubmit={setIsSubmit}
                  />
                </div>
                <div className="d-flex flex-wrap justify-content-end">
                  <button
                    type="button"
                    className="btn mt-3 PacingCalender__SuggestedModel__Createbtn cancelBtn me-3"
                    data-bs-dismiss="modal"
                    onClick={createpacingHandler}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`btn mt-3 PacingCalender__SuggestedModel__Suggestion sendBtn ${(!issue_type || !summary || !message) ? 'PacingCalender__SuggestedModel__Pacingdisabled' : ''}`}
                    onClick={handleSave}
                    disabled={isFieldEmpty || !sendButtonEnabled || isLoading}
                    id="save-button"
                  >
                    {isSaving || isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
              <div className="SupportTicket__ReplayModal__RightSec">
                <div className="SupportTicket__ReplayModal__RightSec__RightContent">
                  <p className="SupportTicket__ReplayModal__RightSec__RightContent__Heading">
                    Resources
                  </p>
                  <label className="SupportTicket__ReplayModal__RightSec__RightContent__Label">
                    Mastery Videos
                  </label>
                  <div className="SupportTicket__ReplayModal__RightSec__RightContent__SubjectDropDown">
                    <select onChange={onChangeResource} name="subjects">
                      <option value="">Choose resource</option>
                      {
                        videoOptions?.map((option: any) => {
                          return (
                            <option value={option.value}>{option.label}</option>
                          )
                        })
                      }
                    </select>
                    <span onClick={() => {showSelectionModal('mastery_videos')}} className="chatIcon cursor-pointer">
                      <img className="w-100 h-100" src={ChatIconNew} alt="Chat" />
                    </span>
                  </div>
                  <p className="SupportTicket__ReplayModal__RightSec__RightContent__Desc">
                    Click the text bubble to insert into comment.
                  </p>
                </div>
                <div className="SupportTicket__ReplayModal__RightSec__RightContent RightContentBottom">
                  <p className="SupportTicket__ReplayModal__RightSec__RightContent__Heading">
                    Attachments
                  </p>
                  <input onChange={onUploadFile} ref={fileRef} style={{ display: 'none' }} type="file" />
                  {
                    Array(3).fill(null).map((_, index) => {
                      const isCurrentInput = index === inputCountRef.current
                      const file = newTicketData?.attachments[index]?.split('/')
                      return <>
                        <div style={{ ...(!isCurrentInput && { cursor: 'default' }) }} className="SupportTicket__ReplayModal__RightSec__RightContent__FilesAttachment">
                          <div onClick={() => isCurrentInput && onClickFile()}>
                            <div className="SupportTicket__ReplayModal__RightSec__RightContent__FilesAttachment__File">
                              <img src={file ? File : NoAttachment} alt="PDF" />
                            </div>
                            <p className="SupportTicket__ReplayModal__RightSec__RightContent__FilesAttachment__FileName">
                              {index < inputCountRef.current ? (file[file?.length - 1] || '') : isCurrentInput ? 'Add Attachment' : ''}
                            </p>
                          </div>
                          {
                            index <= inputCountRef.current - 1 ?
                              <div className="SupportTicket__ReplayModal__RightSec__RightContent__FilesAttachment__CloseChat">
                                <span className=" cursor-pointer close">
                                  <img onClick={() => { 
                                    const updatedAttachments = newTicketData.attachments.filter((_: any, idx: number) => idx !== index);
                                    setNewTicketData((prev: any) => ({...prev, attachments: updatedAttachments}));
                                    inputCountRef.current = updatedAttachments.length;
                                   }} className="w-100 h-100" src={Close} alt="Close" />
                                </span>
                                <span onClick={() => {showSelectionModal('attachments')}} className="chatIcon cursor-pointer">
                                  <img className="w-100 h-100" src={ChatIconNew} alt="Chat" />
                                </span>
                              </div>
                              : null
                          }
                        </div>
                      </>
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
      {showSubmitModal && (
        <SubmitModal loading={false} onAdd={(data) => setNewTicketData((prev:any) => ({...prev, adminDetails: data, ticket_for_admin: data.id}))} onCancel={onCancel} />
      )

      }
      {
        showLinkModal
          ?
          <AddLink
            onClose={() => addLinkModalCloseHandler()} 
            onSave={saveAsLink}
            show={showLinkModal}
            defaultText={newTicketData?.resource}
          />
          : null
      }
    </>
  );
};

export default NewTicketModel;
