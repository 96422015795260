import { SupportTicketSuperAdminContext } from "contexts/supportTicketSuperAdmin";
import { useContext, useEffect, useMemo, useState } from "react";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import { useNavigate } from "react-router-dom";
import NewTicketModel from "./newTicketModal";
import Skeleton from "react-loading-skeleton";
import StatusBar from "./statusBar";
import ReloadIconSvg from "assets/csvs/ReloadIconSvg";
import useDidMountEffect from "hooks/useDidMountEffect";

interface IProps {
  refreshTable: Boolean;
  refreshTableOnClick: Boolean;
  refreshTableOnClickHandler: Function;
}

const SupportCenterHeader = (props: IProps) => {
  const navigate = useNavigate();
  const {
    supportTicketStats,
    fetchSupportTicketManagementStats,
    fetchSupportTicketManagementStatsWithoutPageLoad,
    createNewTicket,
  } = useContext(SupportTicketSuperAdminContext);

  const [newTicketModal, setNewTicketModal] = useState(false);

  const [filter, setFilter] = useState("year");
  const [reloadPageClass, setReloadPageClass] = useState("");

  const {
    loading,
    new_tickets,
    new_chats,
    ticket_assigned,
    tickets_by_status,
  } = supportTicketStats;

  const { submitted, in_progress, need_more_info, resolved } =
    tickets_by_status;

  const distributions = useMemo(() => {
    return Object.keys(tickets_by_status).map(
      (key) => tickets_by_status[key as keyof typeof tickets_by_status]
    );
  }, [tickets_by_status]);

  const maxDistribution = Math.max(...distributions);

  const submittedPercentage = maxDistribution
    ? Math.floor((submitted / maxDistribution) * 100)
    : 0;
  const inProgressPercentage = maxDistribution
    ? Math.floor((in_progress / maxDistribution) * 100)
    : 0;
  const needMorePercentage = maxDistribution
    ? Math.floor((need_more_info / maxDistribution) * 100)
    : 0;
  const resolvedPercentage = maxDistribution
    ? Math.floor((resolved / maxDistribution) * 100)
    : 0;

  const onClickDuration = (duration: string) => {
    setFilter(duration);
    fetchSupportTicketManagementStats(duration);
  };

  const onClickNewTicket = () => {
    setNewTicketModal(true);
  };

  const onCreateNewTicket = async (data: any) => {
    const isSuccess = await createNewTicket(data);
    if (isSuccess) {
      await fetchSupportTicketManagementStats();
      setNewTicketModal(false);
    }
  };

  const reloadButtonHandler = () => {
    setReloadPageClass("reloadPage");
    props.refreshTableOnClickHandler();
  };

  useEffect(() => {
    if (!loading) {
      setReloadPageClass("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // useEffect(() => {
  //   fetchSupportTicketManagementStatsWithoutPageLoad();
  // }, [props.refreshTable])

  // useEffect(() => {
  //   fetchSupportTicketManagementStats();
  // }, [props.refreshTableOnClick])

  useDidMountEffect(() => {
    fetchSupportTicketManagementStatsWithoutPageLoad();
  }, [props.refreshTable]);

  useDidMountEffect(() => {
    fetchSupportTicketManagementStats();
  }, [props.refreshTableOnClick]);

  return (
    <>
      {newTicketModal && (
        <NewTicketModel
          show={newTicketModal}
          onSubmit={onCreateNewTicket}
          onClose={() => setNewTicketModal(false)}
        />
      )}
      <div className="d-flex flex-lg-row flex-column">
        <div className="SupportCenter__Header">
          <div className="SupportCenter__Header__titleRow d-flex align-items-center justify-content-between ">
            <div className="d-flex align-items-center m-lg-0 m-2">
              <span className="accordion__header-button">
                <img
                  onClick={() => navigate(-1)}
                  src={ArrowIcon}
                  alt="arrow"
                  className="rotate-90"
                />
              </span>
              <h2 className="title ">Support Center</h2>
            </div>

            <div className="d-flex align-items-center ps-3">
              <span
                onClick={reloadButtonHandler}
                className="cursor-pointer me-3 reload-icon"
                title="Reload"
              >
                <ReloadIconSvg class={reloadPageClass} />
              </span>

              <button
                onClick={onClickNewTicket}
                className="newTicketBtn m-lg-0 m-2"
                type="button"
              >
                New Ticket
              </button>
            </div>
          </div>
          <p className="SupportCenter__Header__stats">Stats</p>
          <div className="SupportCenter__Header__ticketInfo d-flex align-items-center justify-content-sm-between  justify-content-center">
            <div className="d-flex align-items-center my-lg-0 my-2 mx-lg-0 mx-4 ticket-stats">
              <p className="infoText">New Tickets Today</p>
              <span className="d-flex align-items-center justify-content-center infoNumber">
                {new_tickets}
              </span>
            </div>
            <div className="d-flex align-items-center my-lg-0 my-2 mx-lg-0 mx-4 ticket-stats">
              <p className="infoText">New Chats Today</p>
              <span className="d-flex align-items-center justify-content-center infoNumber">
                {new_chats}
              </span>
            </div>
            <div className="d-flex align-items-center my-lg-0 my-2 mx-lg-0 mx-4 ticket-stats">
              <p className="infoText">Open Tickets Assigned to Me</p>
              <span className="d-flex align-items-center justify-content-center infoNumber">
                {ticket_assigned}
              </span>
            </div>
          </div>
        </div>
        <div className="SupportCenter__status">
          <p className="SupportCenter__status__heading">
            Ticket Status Distribution
          </p>
          <div className="SupportCenter__status__progress">
            <div className="progressElements d-flex align-items-center ">
              <p className="textprogress">Submitted</p>
              <div className="progressElements__progressBars">
                {loading ? (
                  <Skeleton
                    baseColor="#9c9fa343"
                    highlightColor="#d2d5db32"
                    width={220}
                  />
                ) : (
                  <StatusBar
                    value={submitted}
                    animationName="submitted"
                    className="SubmittedBar"
                    to={submittedPercentage}
                  />
                )}
              </div>
            </div>
            <div className="progressElements d-flex align-items-center ">
              <p className="textprogress">In Progress</p>
              <div className="progressElements__progressBars">
                {loading ? (
                  <Skeleton
                    baseColor="#9c9fa343"
                    highlightColor="#d2d5db32"
                    width={220}
                  />
                ) : (
                  <StatusBar
                    value={in_progress}
                    animationName="progress"
                    className="ProgressBar"
                    to={inProgressPercentage}
                  />
                )}
              </div>
            </div>
            <div className="progressElements d-flex align-items-center ">
              <p className="textprogress">Need More Info</p>
              <div className="progressElements__progressBars">
                {loading ? (
                  <Skeleton
                    baseColor="#9c9fa343"
                    highlightColor="#d2d5db32"
                    width={220}
                  />
                ) : (
                  <StatusBar
                    value={need_more_info}
                    animationName="needmore"
                    className="MoreInfo"
                    to={needMorePercentage}
                  />
                )}
              </div>
            </div>
            <div className="progressElements d-flex align-items-center ">
              <p className="textprogress">Resolved</p>
              <div className="progressElements__progressBars">
                {loading ? (
                  <Skeleton
                    baseColor="#9c9fa343"
                    highlightColor="#d2d5db32"
                    width={220}
                  />
                ) : (
                  <StatusBar
                    value={resolved}
                    animationName="resolved"
                    className="Resolved"
                    to={resolvedPercentage}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="SupportCenter__status__footer d-flex align-items-center justify-content-between mt-1">
            <div className="activeTime d-flex align-items-center">
              <p
                onClick={() => onClickDuration("day")}
                className={`mb-0 ${filter === "day" ? "active" : ""}`}
              >
                D
              </p>
              <p
                onClick={() => onClickDuration("year")}
                className={`mb-0 ${filter === "year" ? "active" : ""}`}
              >
                Y
              </p>
              <p
                onClick={() => onClickDuration("month")}
                className={`mb-0 ${filter === "month" ? "active" : ""}`}
              >
                M
              </p>
            </div>
            <div className="centeredElement d-flex align-items-center">
              <p className="mb-0">0</p>
              {/* <p className="mb-0">4</p>
              <p className="mb-0">5</p> */}
            </div>
            <div className="maxElement d-flex align-items-center">
              <p className="mb-0">{maxDistribution}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SupportCenterHeader;
