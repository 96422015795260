/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import Attachment from "assets/img/steps-png-icon.svg";
import NoAttachment from "assets/img/black_file.png";

import ChatIcon from "assets/img/comment-icon-new.svg";
import Close from "assets/img/remove-icon.svg";
import RichEditor from "components/common/RichEditor";
import { SupportTicketSuperAdminContext } from "contexts/supportTicketSuperAdmin";
import HtmlParser from "components/common/htmlParser";
import AddLink from "./addLinkModal";
// import ClipPathGroupSvg from "assets/csvs/ClipPathGroupSvg";
import { handlePress, handleKeyUp } from "util/index";
import CustomModal from "components/common/Modal";

interface Props {
  show: boolean;
  summativeId?: number;
  formativeId?: number;
  onClose: any;
}

const TicketReplayModel: React.FC<Props> = (props) => {
  const fileRef = useRef<any>(null!);
  const editorRef = useRef<any>(null!);
  const { selectedMessage, addReply, videoOptions, addAttachmentToTicket } =
    useContext(SupportTicketSuperAdminContext);
  const { show, onClose } = props;
  const [, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [, setModalSuggestedAssesment] = useState(false);
  const [, setSeheduleAssesment] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState<boolean>(false);
  const [lastUsedLinkModal, setLastUsedLinkModal] =
    useState<string>("attachments");
  const [sendButtonEnabled, setSendButtonEnabled] = useState(false);

  const [replyData, setReplyData] = useState<any>({
    message: "",
    attachments: [],
    resource: selectedMessage?.resource || "",
  });

  const inputCountRef = useRef(0);
  const { message: editorMessage, resource } = replyData;

  const handleSave = async () => {
    setIsSaving(true);
    setSeheduleAssesment(true);
    await addReply(replyData);
    setIsSaving(false);
    onClose();
  };

  const closeModal = () => {
    setIsLoading(false);
    onClose(false);
  };

  const createpacingHandler = () => {
    onClose(false);
    setModalSuggestedAssesment(true);
  };

  const onClickFile = () => {
    fileRef.current.click();
  };

  const onChangeResource = (e: any) => {
    setReplyData((prev: any) => ({ ...prev, resource: e.target.value }));
  };

  const onUploadFile = async (e: any) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    try {
      const data = new FormData();
      data.append("files", file);

      const url = await addAttachmentToTicket(data);
      inputCountRef.current < 3 && inputCountRef.current++;

      setReplyData((prev: any) => ({
        ...prev,
        attachments: [...prev.attachments, url],
      }));
    } catch (error) {
      console.log("Error inonUploadFile", error);
    }
  };

  const showSelectionModal = (displayedFrom: string) => {
    setLastUsedLinkModal(displayedFrom);
    setShowLinkModal(true);
  };

  const saveAsLink = (
    value: string,
    selectedValueID: any,
    selectedText: any
  ) => {
    let asLink = "";
    let newVal: string = "";
    let innerHTML: any = "";

    const oldTextareaDiv: any =
      document.getElementsByClassName("jodit-wysiwyg");
    const isStringAlreadyExist: number =
      oldTextareaDiv[0]?.innerHTML.indexOf(value);

    if (lastUsedLinkModal === "mastery_videos") {
      const selectedMasteryVideo: any = videoOptions.filter((option) => {
        return option.label.trim() === resource.trim();
      });

      console.log("test", selectedMasteryVideo, resource);
      const video_url = `${window.location.origin.toString()}/mastery-videos?videoId=${
        selectedMasteryVideo.at(0)?.id
      }`;
      asLink = selectedMasteryVideo.at(0)?.id
        ? `<a target="_blank" href=${video_url}>${value}</a>`
        : "";
      // asLink = selectedMasteryVideo.at(0)?.video_url ? `<a href=${selectedMasteryVideo[0].video_url}>${value}</a>` : '';
    } else {
      asLink = `<a href=${
        replyData.attachments[inputCountRef.current - 1]
      }>${value}</a>`;
    }

    if (isStringAlreadyExist === -1) {
      // if the element written in the popup does not exists in the textarea.

      newVal = asLink;

      const isSelectionExists = selectedText.trim().length; // means if the user has selected any text from textarea.

      if (isSelectionExists) {
        selectedValueID.innerHTML = newVal; // here we'll update the selected text by the user with the link.
      } else {
        // const oldTextareaDiv: any = document.getElementsByClassName('jodit-wysiwyg');
        const lastChildOfOldHtml: any = oldTextareaDiv[0]?.lastChild;
        const lastChildInnerHTMLOfOldHtml: any = lastChildOfOldHtml.innerHTML;
        lastChildOfOldHtml.innerHTML =
          lastChildInnerHTMLOfOldHtml + "<p>" + newVal + "</p>"; // here we'll concatenate the text written in the popup by the user at the end of all content.
      }

      const updatedTextAreaHTML: any =
        document.getElementsByClassName("jodit-wysiwyg");

      innerHTML = updatedTextAreaHTML[0]?.innerHTML;
    } else {
      // if the element written in the popup Exists in the textarea.
      const textContentOfParent: any = selectedValueID.textContent;

      const regExp = new RegExp(value, "g");
      const matches = textContentOfParent.match(regExp);
      const isValueExistMultipleTimesInSameParent: boolean =
        matches != null && matches.length > 1;

      if (isValueExistMultipleTimesInSameParent) {
        // this case left unattended yet.

        const completeParent: string = selectedValueID.innerHTML;

        const replacedString: string = completeParent
          .replace(/<\/?span[^>]*>/i, asLink)
          .replace("</span>", "");

        const arr: any = replacedString.split(asLink);

        const charactersAfterTrim = arr[1].trim().slice(value.length);

        const remakeParent = arr[0] + asLink + charactersAfterTrim;

        // const newlyAddedLinkIndex: number = replacedString.indexOf(asLink);
        newVal = remakeParent;
      } else {
        newVal = textContentOfParent.replace(value, asLink);
      }

      selectedValueID.innerHTML = newVal;

      const updatedTextAreaHTML: any =
        document.getElementsByClassName("jodit-wysiwyg");

      innerHTML = updatedTextAreaHTML[0]?.innerHTML;
    }

    setReplyData((prev: any) => ({ ...prev, message: innerHTML }));

    setLastUsedLinkModal("attachments"); // default case.
    setShowLinkModal(false);
  };

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  if (!selectedMessage) {
    return null!;
  }

  const { from, message } = selectedMessage;
  // attachmentCount

  const handleSaveHandler = () => {
    if (!isSaving) {
      handleSave();
    }
  };

  return (
    <>
      <div>
      <CustomModal
          centered
          show={show}
          onEscapeKeyDown={closeModal}
          className="SupportTicket__ReplayModal new-ticket-modal"
          backdropClassName="SupportTicket__ReplayModal-backdrop"
        >
          <div>
            <div className="SupportTicket__ReplayModal__ModalHeader">
              Ticket Reply {from?.startsWith("To") ? "" : "To"} {from}
            </div>
            <div className="SupportTicket__ReplayModal__BodyContent">
              <div className="SupportTicket__ReplayModal__LeftSec">
                <div className="SupportTicket__ReplayModal__LeftSec__LeftContent">
                  <p className="SupportTicket__ReplayModal__LeftSec__LeftContent__replyTo">
                    Message Being Replied To
                  </p>
                  <p className="SupportTicket__ReplayModal__LeftSec__LeftContent__text">
                    <HtmlParser html={message || ""} />
                  </p>
                  {/* <div className="d-flex justify-content-end align-items-center position-absolute bottom-0 pe-5 mb-2 w-100">
                    {!!attachmentCount && (
                      <div className="d-flex align-items-center filesAttach">
                        <span style={{marginRight: '4px', position: 'relative', bottom: '5px'}}>
                          <ClipPathGroupSvg />
                        </span>
                        <p className="mb-0">{attachmentCount} Attachment</p>
                      </div>
                    )}
                    <a href="#/" onClick={() => onClose()} className="viewMessage">
                      View Message
                    </a>
                  </div> */}
                </div>
                <div
                  ref={editorRef}
                  className="SupportTicket__ReplayModal__LeftSec__Editor"
                >
                  <RichEditor
                    value={editorMessage}
                    onChange={(value) =>
                      setReplyData((prev: any) => ({ ...prev, message: value }))
                    }
                    setSendButtonEnabled={(isEnabled: any) =>
                      setSendButtonEnabled(isEnabled)
                    }
                  />
                </div>
                <div className="d-flex flex-wrap justify-content-end">
                  <button
                    type="button"
                    className="btn mt-3 PacingCalender__SuggestedModel__Createbtn me-3 cancelBtn"
                    data-bs-dismiss="modal"
                    onClick={createpacingHandler}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={`btn mt-3 PacingCalender__SuggestedModel__Suggestion sendBtn ${
                      !editorMessage
                        ? "PacingCalender__SuggestedModel__Pacingdisabled"
                        : ""
                    }`}
                    onClick={handleSaveHandler}
                    disabled={!sendButtonEnabled}
                    id="save-button"
                  >
                    {isSaving ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      "Send"
                    )}
                  </button>
                </div>
              </div>
              <div className="SupportTicket__ReplayModal__RightSec">
                <div className="SupportTicket__ReplayModal__RightSec__RightContent">
                  <p className="SupportTicket__ReplayModal__RightSec__RightContent__Heading">
                    Resources
                  </p>
                  <label className="SupportTicket__ReplayModal__RightSec__RightContent__Label">
                    Mastery Videos
                  </label>
                  <div className="SupportTicket__ReplayModal__RightSec__RightContent__SubjectDropDown">
                    <select
                      value={replyData.resource}
                      onChange={onChangeResource}
                      name="subjects"
                    >
                      <option value="">Choose resource</option>
                      {videoOptions?.map((option: any) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>
                    <span
                      onClick={() => {
                        showSelectionModal("mastery_videos");
                      }}
                      className="chatIcon"
                    >
                      <img className="w-100 h-100" src={ChatIcon} alt="Chat" />
                    </span>
                  </div>
                  <p className="SupportTicket__ReplayModal__RightSec__RightContent__Desc">
                    Click the text bubble to insert into comment.
                  </p>
                </div>
                <div className="SupportTicket__ReplayModal__RightSec__RightContent">
                  <p className="SupportTicket__ReplayModal__RightSec__RightContent__Heading">
                    Attachments
                  </p>
                  <input
                    onChange={onUploadFile}
                    ref={fileRef}
                    style={{ display: "none" }}
                    type="file"
                  />
                  {Array(3)
                    .fill(null)
                    .map((_, index) => {
                      const isCurrentInput = index === inputCountRef.current;
                      const file = replyData.attachments[index]
                        ?.split("/")
                        .pop();

                      return (
                        <>
                          <div
                            style={{
                              ...(!isCurrentInput && { cursor: "default" }),
                            }}
                            className="SupportTicket__ReplayModal__RightSec__RightContent__FilesAttachment"
                          >
                            <div
                              onClick={() => isCurrentInput && onClickFile()}
                            >
                              <div className="SupportTicket__ReplayModal__RightSec__RightContent__FilesAttachment__File">
                                <img
                                  src={file ? Attachment : NoAttachment}
                                  alt="PDF"
                                />
                              </div>
                              <p className="SupportTicket__ReplayModal__RightSec__RightContent__FilesAttachment__FileName">
                                {index < inputCountRef.current
                                  ? file || ""
                                  : isCurrentInput
                                  ? "Add Attachment"
                                  : ""}
                              </p>
                            </div>
                            {index <= inputCountRef.current - 1 ? (
                              <div className="SupportTicket__ReplayModal__RightSec__RightContent__FilesAttachment__CloseChat">
                                <span className=" cursor-pointer close">
                                  <img
                                    onClick={() => {
                                      const updatedAttachments =
                                        replyData.attachments.filter(
                                          (_: any, idx: number) => idx !== index
                                        );
                                      setReplyData((prev: any) => ({
                                        ...prev,
                                        attachments: updatedAttachments,
                                      }));
                                      inputCountRef.current =
                                        updatedAttachments.length;
                                    }}
                                    className="w-100 h-100"
                                    src={Close}
                                    alt="Close"
                                  />
                                </span>
                                <span
                                  onClick={() =>
                                    showSelectionModal("attachments")
                                  }
                                  className="chatIcon cursor-pointer"
                                >
                                  <img
                                    className="w-100 h-100"
                                    src={ChatIcon}
                                    alt="Chat"
                                  />
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
      {showLinkModal ? (
        <AddLink
          onClose={() => setShowLinkModal(false)}
          onSave={saveAsLink}
          show={showLinkModal}
          defaultText={replyData?.resource}
        />
      ) : null}
    </>
  );
};

export default TicketReplayModel;
