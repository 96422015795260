export const useSurveyPagination = () => {
    const innerWidth = window.innerWidth;
    let perPage = 11;
    switch(true){
        case (innerWidth >= 300 && innerWidth <= 850):
            perPage = 7
            break;
        default:
    }

    return perPage;
}