/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getOAGReport } from "redux/actionCreators/classroom";
import { RootState } from "redux/reducers/combine";
import SearchIconGray from "assets/img/search-icon-gray.svg";
import NavPrev from "assets/img/nav-prev-icon.png";
import NavNext from "assets/img/nav-next-icon.png";
import Navtop from "assets/img/nav-top-icon.png";
import NavBottom from "assets/img/nav-bottom-icon.png";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import Print from "./Print";
import Student from "./Student";
import ReportTabs from "components/common/report/tabs/ReportTabs";
import InfoSection from "./InfoSection";
import QuestionIconDark from "assets/img/question-mark-tooltip-icon.svg";
import newTabIcon from "assets/img/newTab.svg";
import disabledNewTabIcon from "assets/img/disabledNewTab.svg";
import { useNavigate } from "react-router-dom";

import RunningProficiencyModal from "./RunningProficiencyModal";
import ReportHeader from "components/common/report/header";
import {
  TabsSkeleton,
  HeaderSkeleton,
  BodySkeleton,
  FooterSkeleton,
} from "components/common/skeleton/ReportsSkeleton";
import { useQuery } from "hooks/useQuery";
import Tooltip, { ObjectiveTooltipContent } from "components/common/Tooltip";
import Cookies from "js-cookie";
import { replaceURL } from "util/replaceUrl";
import Toggle from "components/common/Toggle";
import { AppCookies } from "util/constants";

const OAGReport = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const report = useSelector((state: RootState) => state.classroom.oagReport);
  const classroom_id = useSelector((state: RootState) => state.classroom.classroomId);
  const classroomId = location?.state?.classroomId;
  
  const classroom = (window as any)['classroomId'] || classroomId || classroom_id;

  const [showRunningProficiencyModal, setRunningProficiencyModal] =
    useState(false);
  const [showScore, setShowScore] = useState(true);
  const [search, setSearch] = useState("");
  // const { classroom }: any = useParams();
  const [showObjectiveColumn, setShowObjectiveColumn] = useState<string>(null!);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isNavLeftDisabled, setIsNavLeftDisabled] = useState(true);
  const [isNavTopDisabled, setIsNavTopDisabled] = useState(true);
  const [isolatedStudent, setIsolatedStudent] = useState<any>(null!);
  const componentRef = useRef(null);
  const query = useQuery();
  const print = query.get("print");
  const objectivesPerPage = 25;
  const isIsolated = (objective_number: string) =>
    !!(showObjectiveColumn && showObjectiveColumn !== objective_number);
  const navigate = useNavigate();
  useEffect(() => {
    const selectedClassroomId = (window as any)['classroomId'] || location?.state?.classroomId || classroom_id;
    if(selectedClassroomId){
      dispatch(getOAGReport(selectedClassroomId));
      replaceURL(`/classroom/report/oag-report/${selectedClassroomId}`);
    }
    setIsLoading(true);
  }, [dispatch, classroom_id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { students, objectives, slicedObject, class_name, topMargin, level } =
    useMemo(() => {
      if (report?.data) {
        const modifiedSlicedObject: any = report.data.objectives?.slice(
          tabIndex * objectivesPerPage,
          tabIndex * objectivesPerPage + objectivesPerPage
        );

        let marginTop = 0;
        let labelNo = 0;
        let count = 0;
        for (let i = 0; i < modifiedSlicedObject.length; i++) {
          if (count > 0) {
            count++;
          }

          if (modifiedSlicedObject[i].divider) {
            if (count === 0) {
              count++;
              labelNo = modifiedSlicedObject
                .slice(i, i + 5)
                .filter((item: any) => !!item.divider).length;
              if (marginTop < labelNo) {
                marginTop = labelNo;
              }
            }

            if (labelNo > 1) {
              modifiedSlicedObject[i].labelClass = `step-${labelNo}`;
              labelNo--;
            }
          }

          if (count % 5 === 0) {
            labelNo = 0;
            count = 0;
          }
        }

        setIsLoading(false);
        marginTop = marginTop * 25;
        return {
          ...report.data,
          slicedObject: modifiedSlicedObject,
          topMargin: marginTop,
        };
      } else {
        return {
          students: [],
          objectives: [],
          slicedObject: [],
          class_name: "",
        };
      }
    }, [report, tabIndex]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `ObjectiveAccountabilityGraph_${class_name}_${moment(
      new Date()
    ).format("M/DD/YY hh:mmA")}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      print && window.close();
    },
  });

  useEffect(() => {
    if (report && print) {
      handlePrint();
    }
  }, [report]);

  const reportFooter: any = {
    total_tested: (
      <div className="report__tested-title oag-title">
        <span>Total Students Tested</span>
      </div>
    ),
    start_date: (
      <div
        className={`report__tested-title oag-title ${
          !isLoading ? "dark-bg" : ""
        }`}
      >
        <span className="report__tested-text oag-text">Test Start Date </span>
      </div>
    ),
    proficient: (
      <div className="report__tested-title oag-title">
        <div className="d-flex justify-content-end align-items-center">
          <span
            style={{ backgroundColor: "unset", marginRight: 6 }}
            className="border-proficient"
          >
            #
          </span>
          <span className="report__tested-text">Proficient</span>
        </div>
      </div>
    ),
    non_proficient: (
      <div
        className={`report__tested-title oag-title ${
          !isLoading ? "dark-bg" : ""
        }`}
      >
        <div className="d-flex justify-content-end align-items-center">
          <span
            style={{ backgroundColor: "#eadb4a" }}
            className="report__tested-rectangle border-non-proficient"
          ></span>
          <span className="report__tested-text">Non Proficient</span>
        </div>
      </div>
    ),
    proficiency_retake: (
      <div className="report__tested-title below oag-title">
        <span className="report__tested-text oag-text">
          Proficiency after Retakes
        </span>
      </div>
    ),
    running_proficiency: (
      <div
        className={`report__tested-title  below oag-title ${
          !isLoading ? "dark-bg" : ""
        }`}
      >
        <span className="report__tested-text oag-text">
          <img
            src={QuestionIconDark}
            alt=""
            onClick={() =>
              setRunningProficiencyModal(!showRunningProficiencyModal)
            }
          />
          Running Proficiency
        </span>
      </div>
    ),
    item_response_report: (
      <div className={`report__tested-title  below oag-title`}>
        <span className="report__tested-text oag-text">
          Item Response Report
        </span>
      </div>
    ),
  };

  const searchedStudents = useMemo(() => {
    if (!students) return;

    if (search) {
      return students
        .filter((student: any, _index: number) => {
          return student.name.toLowerCase().includes(search.toLowerCase());
        })
        .sort((a: any, b: any) => {
          const student1 = a.name.split(",");
          const student2 = b.name.split(",");
          return student1[0].localeCompare(student2[0]);
        });
    }
    return students.sort((a: any, b: any) => {
      const student1 = a.name.split(",");
      const student2 = b.name.split(",");
      return student1[0].localeCompare(student2[0]);
    });
  }, [search, students]);

  const FilteredStudents = useMemo(() => {
    if (!isolatedStudent) {
      return searchedStudents.map((item: any) => ({
        ...item,
        isShowIsolated: null,
      }));
    }

    return searchedStudents.map((st: any) => ({
      ...st,
      isShowIsolated: st.id === isolatedStudent.id,
    }));
  }, [searchedStudents, isolatedStudent]);

  const objectiveClickHandler = (objective: any) => {
    if (objective === showObjectiveColumn) {
      setShowObjectiveColumn(null!);
      return;
    }
    setShowObjectiveColumn(objective);
  };

  const ObjectivesContent = useMemo(() => {
    if (isLoading) return <HeaderSkeleton rowColumns={25} height={70} />;
    else if (!isLoading && slicedObject?.length)
      return slicedObject.map((objective: any, index: number) => {
        return (
          <div key={index} className="report__data-questions oag-questions">
            {objective.divider && (
              <div className={`week-label ${objective?.labelClass || ""}`}>
                <p className="mb-0">{objective.divider}</p>
              </div>
            )}

            <span
              className={`report__data-text oag-text ${
                isIsolated(objective.objective_number) && "objective-isolation"
              }`}
              onClick={(e) => objectiveClickHandler(objective.objective_number)}
            >
              <Tooltip
                content={
                  <ObjectiveTooltipContent
                    name={objective.objective_name}
                    number={objective.objective_number}
                    description={objective.objective_description}
                  />
                }
                top={60}
              >
                <span
                  className={`${
                    showObjectiveColumn
                      ? showObjectiveColumn === objective.objective_number
                        ? "font-bold"
                        : ""
                      : ""
                  } report__data-objectiveData`}
                >
                  {objective.objective_number}
                </span>
              </Tooltip>
            </span>

            <span className={`report__data-count oag-count oag-border`}></span>
          </div>
        );
      });
  }, [slicedObject, isLoading, showObjectiveColumn]);

  const onScroll = () => {
    if (contentRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = contentRef.current;
      if (scrollLeft + clientWidth === scrollWidth) {
        setIsNavLeftDisabled(false);
      }
      if (scrollLeft === 0) {
        setIsNavLeftDisabled(true);
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsNavTopDisabled(true);
    }, 800);
  };

  const scrollToBottom = () => {
    const target = document.getElementById("footer-content") as any;
    if (target) {
      window.scrollTo(0, target.offsetTop - window.innerHeight);
    }
    setTimeout(() => {
      setIsNavTopDisabled(false);
    }, 800);
  };

  const scrollToLeft = () => {
    if (contentRef.current) {
      contentRef.current.scrollLeft += -800;
    }
    setIsNavLeftDisabled(true);
  };

  const scrollToRight = () => {
    if (contentRef.current) {
      contentRef.current.scrollLeft += 800;
    }
    setIsNavLeftDisabled(false);
  };

  const onTabChange = (tabIndex: number) => {
    setTabIndex(tabIndex);
    setShowObjectiveColumn(null!);
    setIsolatedStudent(null!);

    if (contentRef.current) {
      contentRef.current.scrollLeft = 0;
    }
  };

  const handleIrrClick = (objective: any) => {
    const { objective_id, objective_name, objective_number } = objective;
    Cookies.set(AppCookies.test_name, `${objective_number} - ${objective_name}`);

    const irrWindow:any = window.open(
      `/classroom/report/item-response-report-formative/${objective_id}/${classroom}`,
      "_blank"
    );
    irrWindow['classroomId'] = classroom;
  };

  const onClickStudentName = (student: any) => {
    setIsolatedStudent((prev: any) =>
      !prev ? student : prev.id === student.id ? null! : student
    );
  };

  return (
    <>
      {!print && (
        <div className="report hide-on-print">
          <ReportHeader
            reportTitle="Objective Accountability Graph"
            reportGuide="Click on an objective to isolate."
            setPrint={handlePrint}
            headerClassName="report__transparent_header"
            onBack={()=>navigate(-1)}
          />

          <div className="report-content">
            <div className="report__performance">
              <InfoSection level={level} />
              <div className="report__performance-objective oag-nn-bg">
                <div className="report__performance-rectangle">%</div>
                <p>Show Objective Score Percentage</p>

                 <Toggle 
                    isSmall 
                    checked = {showScore} 
                    onChange={(e)=>setShowScore(!showScore)} 
                    role="switch"   
                    labelClassName="mb-0"
                    inputId = "flexSwitchCheckChecked"
                    />  
              </div>

              <div className="report__navigation">
                <p className="mb-0">Navigation</p>
                <div className="report__navigation-buttons">
                  <div className="report__navigation-right">
                    <button
                      type="button"
                      disabled={isNavLeftDisabled === true ? true : false}
                      onClick={scrollToLeft}
                      className="report__navigation-btn next"
                    >
                      <img src={NavPrev} alt="icon" />
                    </button>
                    <button
                      type="button"
                      disabled={isNavLeftDisabled === false ? true : false}
                      onClick={scrollToRight}
                      className="report__navigation-btn next ms-2"
                    >
                      <img src={NavNext} alt="icon" />
                    </button>
                  </div>
                  {FilteredStudents?.length > 10 && (
                    <div className="report__navigation-bottom">
                      <button
                        type="button"
                        onClick={scrollToTop}
                        disabled={isNavTopDisabled === true ? true : false}
                        className="report__navigation-btn mb-2"
                      >
                        <img src={Navtop} alt="icon" />
                      </button>
                      <button
                        type="button"
                        onClick={scrollToBottom}
                        disabled={isNavTopDisabled === false ? true : false}
                        className="report__navigation-btn"
                      >
                        <img src={NavBottom} alt="icon" />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="report__search oag-search">
              <div className="report__search-searcharea">
                {!isLoading && (
                  <img
                    src={SearchIconGray}
                    className="report__search-icon animated-fade"
                    alt=""
                  />
                )}
                <input
                  className={`form-control report__search-input ${
                    isLoading ? "margin-left" : ""
                  }`}
                  type="search"
                  name="Search"
                  placeholder="Search Students"
                  value={search}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearch(e.target.value)
                  }
                />
              </div>

              {!isLoading ? (
                <ReportTabs
                  objectivesPerPage={objectivesPerPage}
                  tabIndex={tabIndex}
                  onTabChange={onTabChange}
                  objectives={objectives}
                  customClassName="oag-report-switch"
                />
              ) : (
                <TabsSkeleton customClass={""} width={145} />
              )}
            </div>

            <div
              className="report__data oag-data"
              ref={contentRef}
              onScroll={onScroll}
            >
              <div
                className="report__data-header oag-header"
                style={{ marginTop: topMargin || 25 }}
              >
                <div className="report__data-objective oag-objective"></div>
                <div className="d-flex">{ObjectivesContent}</div>

                <div className="report__data-Date">
                  <span className="report__bold">Date</span>
                </div>

                <div className="report__data-proficiency">
                  <span className="report__bold">
                    Average
                    <br /> Score
                  </span>
                </div>
              </div>

              <div className="report-table-container print-table-body-container">
                <table
                  className={`report__table print-table-body report__table-oag-table-view ${
                    FilteredStudents && FilteredStudents.length > 15
                      ? "report__table-oag-height"
                      : ""
                  }`}
                  style={{
                    borderCollapse: "collapse",
                    pageBreakInside: "auto",
                  }}
                >
                  <tbody>
                    {!slicedObject?.length && !isLoading && (
                      <tr className="dark-gray">
                        <td className="report__table-notFound">
                          No Objectives Found
                        </td>
                      </tr>
                    )}
                    {FilteredStudents &&
                      !FilteredStudents.length &&
                      !isLoading && (
                        <tr className="dark-gray">
                          <td className="report__table-notFound">
                            No Students Found
                          </td>
                        </tr>
                      )}

                    {!isLoading ? (
                      FilteredStudents?.map((student: any, index: number) => {
                        return (
                          <Student
                            key={index}
                            index={index}
                            student={student}
                            showScore={showScore}
                            showObjectiveColumn={showObjectiveColumn}
                            objectives={slicedObject}
                            setIsolatedStudent={onClickStudentName}
                            level={level}
                          />
                        );
                      })
                    ) : (
                      <div style={{ marginLeft: "2.5px" }}>
                        <BodySkeleton
                          rowColumns={25}
                          circle={false}
                          circleAttempt={false}
                          scoreBox={false}
                          scoreColumn={false}
                          attemptWidth={"196px"}
                          scoreWidth={"113px"}
                        />
                      </div>
                    )}
                  </tbody>
                </table>
              </div>

              <div
                className={`report__footer oag-footer ${
                  FilteredStudents && FilteredStudents.length > 15
                    ? "move-up oag-move-up"
                    : ""
                }`}
              >
                {Object.keys(reportFooter).map((row, index) => {
                  return (
                    <div
                      key={index}
                      className={`${
                        !isLoading && index % 2 !== 0 && "dark-gray"
                      } report__tested oag-tested ${
                        row === "running_proficiency" &&
                        "oag-report__footer-objectives"
                      } ${
                        row === "running_proficiency" && !isLoading && "dark"
                      }`}
                    >
                      {reportFooter[row]}
                      <div className={`report__total-count`}>
                        {!isLoading ? (
                          slicedObject?.map((objective: any, idx: number) => {
                            const hideObjective =
                              showObjectiveColumn &&
                              showObjectiveColumn !==
                                objective.objective_number;
                            if (row === "start_date") {
                              const date = new Date(objective[row]);
                              const day = date.getDate();
                              const month = date.getMonth() + 1; // 1 is added because getMonth returns [0 as January , 11 as December]
                              if (objective[row]) {
                                return (
                                  <div
                                    key={idx}
                                    className={`report__tested-data oag-border-fix ${
                                      hideObjective && "oag-no-border"
                                    }`}
                                  >
                                    <span
                                      className={`${
                                        hideObjective && "visibility-hidden"
                                      }`}
                                    >
                                      <span className="report__total-count__DateEx">
                                        <span className="report__total-count__DateEx__day">
                                          {month}
                                        </span>
                                        <span className="report__total-count__DateEx__slash"></span>
                                        <span className="report__total-count__DateEx__month">
                                          {day}
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    className={`report__tested-data oag-border-fix ${
                                      hideObjective && "oag-no-border"
                                    }`}
                                  >
                                    <span
                                      className={`${
                                        hideObjective && "visibility-hidden"
                                      }`}
                                    >
                                      <span className="report__total-count__DateEx">
                                        -
                                      </span>
                                    </span>
                                  </div>
                                );
                              }
                            } else if (row === "item_response_report") {
                              const isIRREnabled = !!objective.total_tested;
                              return (
                                <div className="report__tested-questions">
                                  <div
                                    className={`report__tested-data oag-border-fix ${
                                      hideObjective && "oag-no-border"
                                    }`}
                                  >
                                    <img
                                      className={`${
                                        hideObjective && "visibility-hidden"
                                      } ${isIRREnabled && "cursor-pointer"}`}
                                      onClick={() =>
                                        isIRREnabled &&
                                        handleIrrClick(objective)
                                      }
                                      src={
                                        isIRREnabled
                                          ? newTabIcon
                                          : disabledNewTabIcon
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <span
                                    onClick={(_e) =>
                                      objectiveClickHandler(
                                        objective.objective_number
                                      )
                                    }
                                    className={`${
                                      isIsolated(objective.objective_number) &&
                                      "objective-isolation"
                                    } report__tested-objective`}
                                  >
                                    <Tooltip
                                      content={
                                        <ObjectiveTooltipContent
                                          name={objective.objective_name}
                                          number={objective.objective_number}
                                          description={
                                            objective.objective_description
                                          }
                                        />
                                      }
                                      top={30}
                                    >
                                      <span
                                        className={`${
                                          !isIsolated(
                                            objective.objective_number
                                          ) && "font-bold"
                                        } report__tested-objectiveData oag-border-fix`}
                                      >
                                        {objective.objective_number}
                                      </span>
                                    </Tooltip>
                                  </span>
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  className={`report__tested-data oag-border-fix ${
                                    hideObjective && "oag-no-border"
                                  }`}
                                >
                                  <span
                                    className={`${
                                      hideObjective && "visibility-hidden"
                                    } ${
                                      row === "running_proficiency" &&
                                      "basic-no"
                                    }`}
                                  >
                                    {objective[row]}
                                  </span>
                                </div>
                              );
                            }
                          })
                        ) : row !== "item_response_report" ? (
                          <FooterSkeleton rowColumns={25} />
                        ) : (
                          <HeaderSkeleton rowColumns={25} height={120} />
                        )}
                      </div>
                      <div className="report__data-proficiency"></div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <RunningProficiencyModal
            isShow={showRunningProficiencyModal}
            closeModal={() => setRunningProficiencyModal(false)}
          />
        </div>
      )}
      {report && (
        <div className="printData" ref={componentRef}>
          <Print level={level} />
        </div>
      )}
    </>
  );
};

export default OAGReport;
