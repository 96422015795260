import CustomModal from "components/common/Modal";

const PasteModal = ({closeModal, error}:{closeModal: Function, error: string}) => {
    return (
        <CustomModal
            centered
            show
            onHide={() => closeModal()}
            onEscapeKeyDown={() => closeModal()}
        >
            <div className="modal__header">
                <h6 className="modal__header-title">
                    Error
                </h6>
            </div>
            <div className="modal__body">
                <p>
                    Pasting image is not allowed in editor. Please use the image uploader in editor options to add image. 
                </p>
            </div>
            <div className="modal__footer">
                <button className="btn cancel-btn mx-2" onClick={() => closeModal()}>
                    Close
                </button>
            </div>
        </CustomModal>
    )
};

export default PasteModal;
