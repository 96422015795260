import React from "react";
import Theme from "components/theme/index";
import IPage from "interfaces/page";
import AdminProfessionalDevelopment from "components/PDAdmin";
import PdAdminProvider from "contexts/pdAdmin";
import PacingCalenderProvider from "contexts/pacingCalender";

const AdminProfessionalDev: React.FunctionComponent<IPage> = (props) => {
  return (
    <Theme>
      <PacingCalenderProvider>
        <PdAdminProvider>
          <AdminProfessionalDevelopment />
        </PdAdminProvider>
      </PacingCalenderProvider>
    </Theme>
  );
};
export default AdminProfessionalDev;
