import { Button } from "components/common/buttonComponent";
import { SupportTicketSuperAdminContext } from "contexts/supportTicketSuperAdmin";
import { TYPE } from "interfaces/supportTicketSuperAdmin";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

interface SuperAdminAddProps {
  onCancel: () => void;
  onAdd: (data: any) => void;
  loading: boolean;
}

  
const AssignModal: React.FC<SuperAdminAddProps> = ({
    loading,
    onCancel
}) => {
    const {superAdmins, updateSupportTicketStatus, fetchSuperAdmin} = useContext(SupportTicketSuperAdminContext);
    const {super_admins} = superAdmins;
    const [adminId, setAdminId] = useState(null!);
    
    const onSubmit = () => {
      updateSupportTicketStatus({
        type: TYPE.ASSIGNEE,
        admin_id: adminId
      });
      onCancel();
    };
  
    const handleCancel = () => {
      onCancel();
    };

    const handleOnChangeDropdown = (e: any) => {
      setAdminId(e.target.value);
    }

    useEffect(() => {
      fetchSuperAdmin('for_ticket');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    
    return (
      <Modal centered show={true} onEscapeKeyDown={handleCancel}>
        <div>
          <div className="modal__header">
            <h6 className="modal__header-title">Assign Ticket</h6>
          </div>
          <div className="modal__body">
            <div className="modal__form">
              {/* <ValidationHint isSubmitError={isSubmitError} /> */}
              <div className="modal__form-row mb-2">
                <label className="lg">Admin</label>
                <select className="form-select welcome-edit-select"
                  onChange={(e: any) => handleOnChangeDropdown(e) }
                  disabled={loading}
                  value={adminId}
                >
                  <option disabled selected>Select Admin</option>
                  {super_admins.map(({value, label, first_name, last_name}:any, i) => 
                    <option value={value}>{last_name}, {first_name}</option>)
                  }
                </select>
              </div>
            </div>
          </div>
          <div className="modal__footer">
            <Button
              buttonText="Cancel"
              onClick={handleCancel}
              className="btn cancel-btn"
              disabled={loading}
              type="button"
            />
            <Button
              disabled={adminId === null}
              buttonText="Assign"
              onClick={onSubmit}
              className={`btn success-btn btn-lg ${
                (loading) && "btn-disabled"
              }`}
              loading={loading}
              type="button"
              id='save-button'
            />
          </div>
        </div>
      </Modal>
    );
};

export default AssignModal;