/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import ActionType from "redux/actionTypes";
import CustomModal from "components/common/Modal";

interface SuccessModalProps {
  isShow: boolean;
  id: number;
  name: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({ isShow, id, name }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch({
      type: ActionType.CLOSE_MODAL,
    });

    navigate(`/management/district/${id}`,{state: {name: name}});
  };

  return (
    <CustomModal
      centered
      show={isShow}
      onHide={closeModal}
      onEscapeKeyDown={closeModal}
    >
      <div className="modal__header">
        <h6 className="modal__header-title">Clever CSV Import Success</h6>
      </div>
      <div className="modal__body">
        <p>
          Your Students was successfully imported
        </p>
      </div>
      <div className="modal__footer">
        <button className="btn success-btn mx-2" onClick={closeModal}>
          View District
        </button>
      </div>
    </CustomModal>
  );
};

export default SuccessModal;
