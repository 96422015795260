
import React, { useState } from "react";
import { Button } from "./buttonComponent";
import CustomModal from "./Modal";

interface DeleteConfirmationModalProps {
    loading?: boolean;
    submitButtonName?: string
    isShow: boolean;
    headerText: string;
    bodyText: string;
    onCancel: () => void;
    onSubmit: () => Promise<any>;
    type?: string;
}

export const ConfirmModal: React.FC<DeleteConfirmationModalProps> = ({
    isShow,
    headerText,
    bodyText,
    onCancel,
    onSubmit,
    submitButtonName,
    type
  }) => {
    const [loading, setLoading] = useState(false);

    const onConfirm = async () => {
      setLoading(true);
      await onSubmit();
      setLoading(false);
    }

    return (
      <CustomModal onHide={onCancel} centered show={isShow} onEscapeKeyDown={onCancel}>
        <div className="modal__header">
          <h6 className="modal__header-title">{headerText}</h6>
        </div>
        <div className="modal__body">
          <p style={{...(type === 'success' && {fontSize: 15})}}>{bodyText}</p>
        </div>
        <div className="modal__footer delete-carmit-modal">
          <Button
            buttonText="Cancel"
            onClick={onCancel}
            className='btn cancel-btn'
            disabled={loading}
            type='button'
          />
          <Button
            buttonText={submitButtonName || 'Confirm'}
            onClick={onConfirm}
            className={type === 'success' ? 'btn success-btn' : 'btn danger-btn'}
            disabled={loading}
            loading={loading}
            type='button'
          />
        </div>
      </CustomModal>
    );
  };