/* eslint-disable react-hooks/exhaustive-deps */

import { FC } from "react";
import QuestionMarkIcon from "assets/img/question-mark-tooltip-icon.png";
import CustomModal from "components/common/Modal";



interface Props {
    show: boolean;
    closeModal: any
}

const PreviewHelpModel: FC<Props> = (props) => {
    const { show, closeModal} = props;

    return (
        <CustomModal
            centered
            show={show}
            onEscapeKeyDown={closeModal}
        >
            <div className="modal__header">
                <img className="question-mark-icon modal__header-icon mt-0" src={QuestionMarkIcon} alt="" />
                <h2 className="modal__header-title">
                    Review
                </h2>
            </div>
            <div className="modal__body review-modal">
                <p className="modal__body-text mb-0">
                    After at least one student has taken the assessment, 
                    the review option will become available. Use this 
                    option for remediation to go over questions students
                    struggled with.
                </p>
            </div>
            <div className="modal__footer">
                <button
                    type="button"
                    className="btn success-btn"
                    onClick={closeModal}
                >
                    Got it
                </button>
            </div>
        </CustomModal>
    );
};

export default PreviewHelpModel;
