import { SupportTicketEmptyRecord } from 'components/common/EmptyRecord';
import { CustomSkeleton } from 'components/common/skeleton/TableSkeleton';
import { SupportTicketContext } from 'contexts/supportTicket';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { StatusClassMapper, TicketDetailsStatusMapper } from 'util/mappers';

const SupportTicketTable = () => {
    const { updateMessageSeen, supportTickets: { tickets, loading }, setSingleMessage, setSingleSupportTicket, singleMessage, fetchSupportTicketMessages, supportTicketMessages } = useContext(SupportTicketContext)
    const isTickets = tickets.length;

    const [queryParams, setQueryParams] = useState({
        page: 1,
        limit: 10,
        search: '',
        id: null!
    });

    const {id: ticketId} = queryParams;

    const onClickMessage = (message: any) => {
        const {attachments, seen, id} = message;

        if(id){
            if(!seen){
                updateMessageSeen(message.id, ticketId);
            }
            const count = attachments.length;
            setSingleMessage({...message, attachmentCount: count});
        }
        
    }

    const onClickTicket = (ticket: any) => {

        // if same ticket is clicked, no need to send req again
        if(ticket.id === queryParams.id){
            return;
        }

        setQueryParams((prev:any) => ({...prev, id: ticket.id, page: 1}));
        setSingleSupportTicket(ticket);
        
    }

    useEffect(() => {
        if(queryParams.id){
            const offset = (queryParams.page-1)*queryParams.limit;
            fetchSupportTicketMessages({...queryParams, offset});
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[queryParams]);

    useEffect(() => {
        if(tickets?.length > 0){
            onClickTicket(tickets[0]);
        }
    },[tickets]);

    useEffect(() => {
        const {messages} = supportTicketMessages;
        if(messages.length > 0){
            onClickMessage(messages[0]);
        }else{
            setSingleMessage(null!);
        }
    },[supportTicketMessages]);

    const modifiedMessages = useMemo(() => {
        const DEFAULT_MESSAGE_PAYLOAD = {
            attachments: [],
            from: '',
            id: null,
            message: '',
            reply_of: null,
            resource: '',
            seen: true,
            sender: '',
            sender_id: 0,
            sent: ''
        }
        const {messages} = supportTicketMessages;
        let _modifiedMessages:any = [];

        messages.forEach((item: any) => {
            _modifiedMessages.push(item);
        });

        for(let i = _modifiedMessages.length; i<5; i++){
            _modifiedMessages[i] = DEFAULT_MESSAGE_PAYLOAD;
        }

        return _modifiedMessages;

    },[supportTicketMessages]);

    const onScroll = (e: any) => {
        const isAtBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

        const {count, messages} = supportTicketMessages;
        
        if(isAtBottom && count > messages.length){
            setQueryParams((prev: any) => ({...prev, page: prev.page+1}));
        }
    }
    
    return (
        <>
            {isTickets ? (<div className='SupportTicket__supportTicketsTables'>
                <div className='SupportTicket__supportTicketsTables__table__tableLeft'>
                    <div className='d-flex'>
                        <div className='SupportTicket__supportTicketsTables__table__th summary d-flex align-items-center'>
                            <div className='bullet'>
                                
                            </div>
                            <p className='mb-0'>Type/Summary</p>
                        </div>
                        <div className='SupportTicket__supportTicketsTables__table__th update'>Last Update</div>
                        <div className='SupportTicket__supportTicketsTables__table__th status'>Status</div>
                    </div>
                    <div className='tableLeftLength'>
                        <div className='tableLeftLength__accordian'>
                            {loading ?
                                <CustomSkeleton columns={3} count={4} height={45} />
                            : (tickets && isTickets) && (
                                tickets.map((ticket) => {
                                    const {type, summary, last_update, status, unread_messages} = ticket;
                                    const isSelected = ticketId === ticket.id;
                                    return (
                                        <div onClick={() => onClickTicket(ticket)} className={`SupportTicket__supportTicketsTables__table__tableLeft__row ${isSelected ? 'selected-row' : 'rows'}`}>
                                            <div className='SupportTicket__supportTicketsTables__table__td summary d-flex align-items-center '>
                                                <div className='bullet'>
                                                    {unread_messages && <span></span>}
                                                </div>
                                                <p className='mb-0'>{type}{summary ? ` - ${summary}` : ''}</p>
                                            </div>
                                            <div className='SupportTicket__supportTicketsTables__table__td update'>{(!last_update || last_update === 'Not Yet') ? 'Not Yet' : moment(last_update).format("M-D-YY h:mm A")}</div>
                                            <div className='SupportTicket__supportTicketsTables__table__td status'>
                                                <span className={StatusClassMapper[status as keyof typeof StatusClassMapper] || ''}>
                                                    <p>{TicketDetailsStatusMapper[status as keyof typeof TicketDetailsStatusMapper] || ''}</p>
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })
                            )}
                            <div className='tableLeftLength__accordian__tableRight'>
                                <div className='d-flex'>
                                    <div className='SupportTicket__supportTicketsTables__table__th ticketMessage d-flex align-items-center'>
                                        <div className='bullet'>
                                            
                                        </div>
                                        <p className='mb-0'>Ticket Messages</p>
                                    </div>
                                    <div className='SupportTicket__supportTicketsTables__table__th sent'>Sent</div>
                                </div>
                                <div className='tableRightLength'>
                                    <div className='SupportTicket__supportTicketsTables__table__tableRight__row selected-row'>
                                        <div className='td  ticketMessage d-flex align-items-center '>
                                            <div className='bullet'>
                                                <span></span>
                                            </div>
                                            <p className='mb-0'>From Alpha Plus</p>
                                        </div>
                                        <div className='td sent'>9/19/23 3:30 PM</div>
                                    </div>
                                    <div className='SupportTicket__supportTicketsTables__table__tableRight__row'>
                                        <div className='td ticketMessage d-flex align-items-center '>
                                            <div className='bullet'>
                                            </div>
                                            <p className='mb-0'>To Alpha Plus</p>
                                        </div>
                                        <div className='td sent'>9/19/23 2:15 PM</div>
                                    </div>
                                    <div className='SupportTicket__supportTicketsTables__table__tableRight__row'>
                                        <div className='td  ticketMessage d-flex align-items-center '>
                                            <div className='bullet'>
                                            </div>
                                            <p className='mb-0'>From Alpha Plus</p>
                                        </div>
                                        <div className='td sent'>9/18/23 1:08 PM</div>
                                    </div>
                                    <div className='SupportTicket__supportTicketsTables__table__tableRight__row'>
                                        <div className='td  ticketMessage d-flex align-items-center '>
                                            <div className='bullet'>
                                            </div>
                                            <p className='mb-0'>Original Ticket</p>
                                        </div>
                                        <div className='td sent'>9/18/23 9:21 AM</div>
                                    </div>
                                    <div className='SupportTicket__supportTicketsTables__table__tableRight__row'>
                                        <div className=' td  ticketMessage d-flex align-items-center '>
                                            <div className='bullet'>
                                            </div>
                                            <p className='mb-0'>From Alpha Plus</p>
                                        </div>
                                        <div className='td sent'>9/19/23 3:30 PM</div>
                                    </div>
                                    <div className='SupportTicket__supportTicketsTables__table__tableRight__row'>
                                        <div className=' td  ticketMessage d-flex align-items-center '>
                                            <div className='bullet'>
                                            </div>
                                            <p className='mb-0'>From Alpha Plus</p>
                                        </div>
                                        <div className='td sent'>9/19/23 3:30 PM</div>
                                    </div>
                                    <div className='SupportTicket__supportTicketsTables__table__tableRight__row'>
                                        <div className=' td  ticketMessage d-flex align-items-center '>
                                            <div className='bullet'>
                                            </div>
                                            <p className='mb-0'>From Alpha Plus</p>
                                        </div>
                                        <div className='td sent'>9/19/23 3:30 PM</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='tableLeftLength__accordian'>
                            <div className='SupportTicket__supportTicketsTables__table__tableLeft__row rows'>
                                <div className='SupportTicket__supportTicketsTables__table__td summary d-flex align-items-center '>
                                    <div className='bullet'>
                                    </div>
                                    <p className='mb-0'>Bug - One student not reporting accurate score</p>
                                </div>
                                <div className='SupportTicket__supportTicketsTables__table__td update'>9/19/23 3:30 PM</div>
                                <div className='SupportTicket__supportTicketsTables__table__td status'>
                                    <span className='resolved'>
                                        <p>Resolved</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='tableLeftLength__accordian'>
                            <div className='SupportTicket__supportTicketsTables__table__tableLeft__row rows'>
                                <div className='SupportTicket__supportTicketsTables__table__td summary d-flex align-items-center '>
                                    <div className='bullet'>
                                    </div>
                                    <p className='mb-0'>Bug - One student not reporting accurate score</p>
                                </div>
                                <div className='SupportTicket__supportTicketsTables__table__td update'>9/19/23 3:30 PM</div>
                                <div className='SupportTicket__supportTicketsTables__table__td status'>
                                    <span className='resolved'>
                                        <p>Resolved</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='tableLeftLength__accordian'>
                            <div className='SupportTicket__supportTicketsTables__table__tableLeft__row rows'>
                                <div className='SupportTicket__supportTicketsTables__table__td summary d-flex align-items-center '>
                                    <div className='bullet'>
                                    </div>
                                    <p className='mb-0'>Bug - One student not reporting accurate score</p>
                                </div>
                                <div className='SupportTicket__supportTicketsTables__table__td update'>9/19/23 3:30 PM</div>
                                <div className='SupportTicket__supportTicketsTables__table__td status'>
                                    <span className='resolved'>
                                        <p>Resolved</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='tableLeftLength__accordian'>
                            <div className='SupportTicket__supportTicketsTables__table__tableLeft__row rows'>
                                <div className='SupportTicket__supportTicketsTables__table__td summary d-flex align-items-center '>
                                    <div className='bullet'>
                                    </div>
                                    <p className='mb-0'>Bug - One student not reporting accurate score</p>
                                </div>
                                <div className='SupportTicket__supportTicketsTables__table__td update'>9/19/23 3:30 PM</div>
                                <div className='SupportTicket__supportTicketsTables__table__td status'>
                                    <span className='resolved'>
                                        <p>Resolved</p>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='tableLeftLength__accordian'>
                            <div className='SupportTicket__supportTicketsTables__table__tableLeft__row rows'>
                                
                            </div>
                        </div>
                        <div className='tableLeftLength__accordian'>
                            <div className='SupportTicket__supportTicketsTables__table__tableLeft__row rows'>
                                
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className='SupportTicket__supportTicketsTables__table__tableRight'>
                    <div className='d-flex'>
                        <div className='SupportTicket__supportTicketsTables__table__th ticketMessage d-flex align-items-center'>
                            <div className='bullet'>
                                
                            </div>
                            <p className='mb-0'>Ticket Messages</p>
                        </div>
                        <div className='SupportTicket__supportTicketsTables__table__th sent'>Sent</div>
                    </div>
                    <div onScroll={onScroll} className='tableRightLength'>
                        {supportTicketMessages.loading 
                        ? <CustomSkeleton columns={2} count={5} height={37} />
                        : singleMessage?.id && modifiedMessages?.length > 0 ?
                            modifiedMessages?.map((message: any) => {
                                const {from, sent, id, seen} = message;
                                const isSelected = singleMessage?.id === id;
                                return (
                                    <div style={{cursor: id===null ? 'default': 'pointer'}} onClick={() => onClickMessage(message)} className={`SupportTicket__supportTicketsTables__table__tableRight__row ${isSelected ? 'selected-row' : 'rows'}`}>
                                        <div className='td  ticketMessage d-flex align-items-center '>
                                            <div className='bullet'>
                                                {!seen && <span></span>}
                                            </div>
                                            {from && (
                                                <p className='mb-0'>{from}</p>
                                            )}
                                        </div>
                                        {sent && (
                                            <div className='td sent'>
                                                {moment(sent).format("M-D-YY h:mm A")}
                                            </div>
                                        )}
                                    </div>
                                )
                            })
                        :<SupportTicketEmptyRecord 
                            height={180} 
                            text={!!singleMessage?.id ? 'No Messages.' : 'Please select a ticket from left.'}
                            backgroundColor="#b9b9b9"
                        />
                        }
                    </div>
                </div>
                
            </div>
            ):(
                <table>
                    <SupportTicketEmptyRecord text="No Tickets Yet" />
                </table>
            )}
        </>
    )
}

export default React.memo(SupportTicketTable);