/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck

import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import ReactQuill from "react-quill";
import EditorToolbar, {
  modules,
  formats,
} from "components/quill/EditorToolbar";
import {
  getGradeAndSubjectsList,
  welcomeMessage,
  getWelcomeMessage,
  updateWelcomeMessage
} from "redux/actionCreators/management";
import ApiModal from "components/common/Reload/ApiModal";
import { ValidationHint } from "components/common/inputComponent";
import { ToastContainer, toast } from "react-toastify";
import CustomModal from "components/common/Modal";

interface Props {
  show: boolean;
  setModalShow: any;
}

const roleOptions = [
  {
   label: 'General',
   value: 'GENERAL'
  },
  {
    label: 'District Admins',
    value: 'ROLE_DISTRICTS_ADMIN',
  },
  {
    label: 'School Admins',
    value: 'ROLE_SCHOOLS_ADMIN',
  },
  {
    label: 'All Teachers',
    value: 'ROLE_ALL_TEACHERS',
  },
  {
    label: 'Teachers (Subject and Grade)',
    value: 'SUBJECT_AND_GRADE',
  },
]; 

const WelcomeModal: React.FC<Props> = (props) => {
  const { show, setModalShow } = props;
  const [type, setType] = useState("GENERAL");
  const [gradeId, setGradeId] = useState(0);
  const [subjectId, setSubjectId] = useState(0);
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [useGeneralMessage, setUseGeneralMessage] = useState(false)
  const [useAllTeacher, setUseAllTeacher] = useState(false)
  const [fetchingMsgLoader, setFetchingMsgLoader] = useState(false);
  const [generalDescription, setGeneralDescription] = useState("");
  const [allTechersDescription, setAllTechersDescription] = useState("");
  const [generalTitle, setGeneralTitle] = useState("");
  const [allTechersTitle, setAllTechersTitle] = useState("");
  const [allTechersUseGeneral, setAllTechersUseGeneral] = useState(false);
  const [Id, setId] = useState(0);
  const [roleId, setRoleId] = useState(0);
  const [data, setData] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState('');
  const [validationError, setValidationError] = useState({
    title: false,
    description: false
  })

  useEffect(() => {
    setFetchingMsgLoader(true)
    fetchWelcomeMessage(type,roleId,0,0)
  }, []);

  const saveWelcomeMessage = async () => {
    if(disabled) {
      return;
    }

    const isDescriptionError = !description || description === '<p><br></p>';
    if(!title || isDescriptionError){
      setValidationError(prev => ({
        ...prev,
        title: !title,
        description: isDescriptionError
      }));

      return;
    }else{
      setValidationError(prev => ({
        ...prev,
        title: false,
        description: false
      }))
    }

    setLoading(true);
    try {
      if (Id > 0) {
        await updateWelcomeMessage(Id, {
          message: description,
          type,
          grade_id: gradeId,
          subject_id: subjectId,
          role_id: roleId,
          use_general: useAllTeacher && type === 'SUBJECT_AND_GRADE' ? false : useGeneralMessage,
          use_all_teachers: type === 'SUBJECT_AND_GRADE' ? useAllTeacher : false,
          title: title ?? "-"
        });
      } else {
         await welcomeMessage({
          message: description,
          type,
          grade_id: gradeId,
          subject_id: subjectId,
          role_id: roleId,
          use_general: useAllTeacher && type === 'SUBJECT_AND_GRADE' ? false : useGeneralMessage,
          use_all_teachers: type === 'SUBJECT_AND_GRADE' ? useAllTeacher : false,
          title: title ?? "-"
        });
      }
      toast.success('Welcome message updated successfully.')
      // setSuccessMessage(true);
      // setTimeout(() => {
      //   setSuccessMessage(false);
      // },2000)
      setLoading(false);
      // setModalShow(false);
      setDisabled(true);
    } catch (e: any) {
      console.log(e.message);
      e?.response?.data?.message && setError(e.response.data.message)
      setLoading(false);
    }
  }

  const fetchWelcomeMessage = async (paramType: string, role_id: number, grade_id: number, subject_id: number ) => {
    const params = {
      type: paramType,
      grade_id,
      subject_id,
      role_id
    }
    try {
      const message = await getWelcomeMessage(params);
      if(paramType === 'SUBJECT_AND_GRADE' && grade_id === 0 && subject_id === 0){
        setId(0);
        setDescription('')
        setTitle('')
        setUseAllTeacher(false)
        setUseGeneralMessage(false)
      }
      else{
        if(message.data?.data?.welcome_messages){
          const { welcome_messages, general_message, all_teachers_message } = message.data.data;
          const general_msg = general_message?.message || "";
          const all_teachers_msg = all_teachers_message?.message || "";
          const general_title = general_message?.title || "";
          const all_teachers_title= all_teachers_message?.title || "";
          if(paramType === 'SUBJECT_AND_GRADE'){
            setUseAllTeacher(welcome_messages.use_all_teachers ? true : false)
          }
          else{
            setUseGeneralMessage(welcome_messages.use_general ? true : false)
          }
          /* Description */
          setDescription(welcome_messages.use_all_teachers ? all_teachers_message.use_general ? general_msg : all_teachers_msg :
            welcome_messages.use_general 
          ? general_msg : welcome_messages.message);
          setGeneralDescription(general_msg)
          setAllTechersDescription(all_teachers_msg)
          /* Title */
          setTitle(welcome_messages.use_all_teachers ? all_teachers_message.use_general ? general_title : all_teachers_title :
            welcome_messages.use_general 
          ? general_title : welcome_messages.title);
          setGeneralTitle(general_title)
          setAllTechersTitle(all_teachers_title)

          setAllTechersUseGeneral(all_teachers_message?.use_general || false)
          setId(welcome_messages.id);
        }
        else{
          const { general_message, all_teachers_message} = message.data.data;
          const general_msg = general_message?.message || "";
          const all_teachers_msg = all_teachers_message?.message || "";
          const general_title = general_message?.title || "";
          const all_teachers_title = all_teachers_message?.title || "";
          const use_general = all_teachers_message?.use_general || false;
          if(paramType === 'SUBJECT_AND_GRADE'){
            setUseAllTeacher(true)
            setUseGeneralMessage(false)
            setDescription(use_general ? general_msg : all_teachers_msg);
            setTitle(use_general ? general_title : all_teachers_title);
          }
          else{
            setUseGeneralMessage(paramType === 'GENERAL' ? false : true)
            setDescription(general_msg);
            setTitle(general_title);
          }
          setGeneralDescription(general_msg)
          setAllTechersDescription(all_teachers_msg)
          setGeneralTitle(general_title)
          setAllTechersTitle(all_teachers_title)
          setAllTechersUseGeneral(all_teachers_message?.use_general || false)
          setId(0);
        }  
      }
    } catch (e: any) {
      console.log(e.message);
    }
    setFetchingMsgLoader(false)

  }

  const handleOnChangeDropdown = async (e) => {
    setValidationError({
      title: '',
      description: ''
    })
    setFetchingMsgLoader(true)
    const inputValue = e.target.value;
    let paramType = inputValue;
    let role_id = roleId;

    if(inputValue === 'ROLE_DISTRICTS_ADMIN' || inputValue === "ROLE_SCHOOLS_ADMIN" || inputValue === "ROLE_ALL_TEACHERS") {
      paramType = "ROLE"
      setType("ROLE");
    } else {
      setType(inputValue);
    }
    role_id = inputValue === "ROLE_DISTRICTS_ADMIN"  ? 2 : inputValue === "ROLE_SCHOOLS_ADMIN" ? 3 : inputValue === "ROLE_ALL_TEACHERS" ? 4 : 0;
    if (inputValue === "SUBJECT_AND_GRADE") {
      const res = await getGradeAndSubjectsList()
      setData(res.data.data)
      setUseGeneralMessage(false)
    }
    else{
      setData([])
      setGradeId(0);
      setSubjectId(0);
      setUseAllTeacher(false)
      let subject = document.querySelector('#subject-select');
      subject[0].selected = true;
    }  
    setRoleId(role_id);
    await fetchWelcomeMessage(paramType,role_id,0,0);
  }

  const handleOnChangeSubject = async(e) => {
    setValidationError({
      title: '',
      description: ''
    })
    setFetchingMsgLoader(true)
    const grade = parseInt(e.target.selectedOptions[0].getAttribute("data-grade-id"));
    const subject = parseInt(e.target.selectedOptions[0].getAttribute("data-subject-id"));
    setGradeId(grade);
    setSubjectId(subject)
    await fetchWelcomeMessage(type,0,grade,subject);
  }
 
  const handleUserGeneralToggle = () => {
    setDisabled(false)
    setUseGeneralMessage(!useGeneralMessage)
    useAllTeacher && setUseAllTeacher(false)
    setDescription(!useGeneralMessage ? generalDescription : '') 
    setTitle(!useGeneralMessage ? generalTitle : '') 
  }

  const handleAllTechersToggle = () => {
     setDisabled(false)
     setUseAllTeacher(!useAllTeacher)
     useGeneralMessage && setUseGeneralMessage(false)
     setDescription(!useAllTeacher ? allTechersUseGeneral ? generalDescription : allTechersDescription: '')
     setTitle(!useAllTeacher ? allTechersUseGeneral ? generalTitle : allTechersTitle: '') 
  }

  const handleReadOnly = () => {
    let readOnly = false;
    if(type === 'SUBJECT_AND_GRADE'){
      if(gradeId === 0 && subjectId === 0){
        readOnly = true
      }
      else{
        readOnly = useAllTeacher
      }
    }
    else if(type === 'GENERAL'){
      readOnly = false
    }
    else{
      readOnly = useGeneralMessage
    }
    return readOnly
  }

  let keys = {
    a: false,
    s: false,
  };

  const handleKeyPress = (event: any) => {
    if (event.keyCode === 91 || event.keyCode === 93 || event.keyCode === 17 || event.keyCode === 224) {
      keys.a = true;
    }

    if (event.key === "Enter") {
      keys.s = true;
    }

    if(keys.a === true && keys.s === true){
      document.getElementById('welcome-save-button')?.click();
    }

  }

  const handleKeyUp = () => {
    keys.a = false;
    keys.s = false;
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    document.addEventListener('keyup', handleKeyUp);
    return () => {
        document.removeEventListener('keydown', handleKeyPress);
        document.removeEventListener('keyup', handleKeyUp);
    }
  }, []);

  const isDescriptionError = !description || description === '<p><br></p>';

  return (
    <>
      <CustomModal
        centered
        show={show}
        size="lg"
        contentClassName="modal-content-edit welcome-modal"
        onEscapeKeyDown={() => setModalShow(false)}
      >
        <div className="modal__header">
          <h3 className="modal__header-title">Edit Welcome</h3>
        </div>
        <div className="modal__body">
          <div className="modal__form-welcome">
            <ValidationHint isSubmitError={validationError.title} />
            <div className="modal__form-row mb-2">
              <label className="lg">Type</label>
              <select tabIndex={0} className="form-select welcome-edit-select customFieldsFocused-active"
                onChange={(e: any) => handleOnChangeDropdown(e) }
                disabled={loading || fetchingMsgLoader}
              >
                <option disabled> Choose type</option>
                {roleOptions.map(({value, label}, i) => 
                  <option selected={i === 0} value={value}>{label}</option>)
                }
              </select>
            </div>
            <div className={`modal__form-row ${type === "SUBJECT_AND_GRADE" ? '' : 'disabled'}`}>
              <label className="lg">
                Subject and Grade
              </label>
              <select className="form-select welcome-edit-select" id='subject-select'
                disabled={type === "SUBJECT_AND_GRADE" ? false : true}
                onChange={(e: any) => handleOnChangeSubject(e)}
              >
                <option selected disabled>Choose</option>
                {data.length &&
                  data.map((x: any, i) => {
                    return (
                      <option value={x.id} key={i} data-subject-id={x.subject_id} data-grade-id={x.grade_id}>
                        {x.label}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="modal__form-switch justify-content-start">
            <p className="shadow-text">Title</p>
            <div className="d-flex justify-content-center align-items-center">
              {type !== 'GENERAL' && type !== 'SUBJECT_AND_GRADE' && <>
                <p className="text-gray ms-2">Use General</p>
                <label className="switch switch-small mb-0">
                  <input
                    tabIndex={0}
                    className="switch-checkbox customFieldsFocused-active"
                    type="checkbox"
                    defaultChecked={useGeneralMessage}
                    checked={useGeneralMessage}
                    onChange={() => handleUserGeneralToggle()}
                  />
                  <span className="switch-slider switch-slider-small"></span>
                </label>
              </>
              }
            </div>
            {
              type === "SUBJECT_AND_GRADE" && subjectId > 0 && gradeId > 0 && 
              <div className="d-flex justify-content-center align-items-center">
                <p className="text-gray ms-2">Use All Teachers</p>
                <label className="switch switch-small mb-0">
                  <input
                    className="switch-checkbox"
                    type="checkbox"
                    defaultChecked={useAllTeacher}
                    checked={useAllTeacher}
                    onChange={() => handleAllTechersToggle()}
                  />
                  <span className="switch-slider switch-slider-small"></span>
                </label>
              </div>
            }

          </div>
         <div className={`modal__form-row pt-2 ${validationError.title ? 'custom-padding' : ''}`}>
            <input
              type='text'
              value={title}
              onChange={(e) => {
                  setTitle(e.target.value)
                  setDisabled(false)
                }
              }
              disabled={handleReadOnly()}
              className={handleReadOnly() ? 'disabled-item':''}
            />
            <span
              className={validationError.title ? !title ? "red-circle" : "green-circle" : 'd-none'}
            />
         </div>
          <p className="shadow-text mt-3">Message</p>
          <div className={`modal__editor editor-middle-body ${validationError.description ? 'custom-padding' : ''}`}>
              {fetchingMsgLoader && <Row className="loading-spinner d-flex justify-content-center"><Spinner animation="border" variant="light" /></Row>} 
              <Row>
                <Col className="text-white">
                  <EditorToolbar readOnly={useGeneralMessage || useAllTeacher || fetchingMsgLoader || (type === "SUBJECT_AND_GRADE" && !subjectId && !gradeId) }/>
                  <ReactQuill
                    readOnly={handleReadOnly()} 
                    style={{ opacity : `${useGeneralMessage || useAllTeacher || fetchingMsgLoader || (type === "SUBJECT_AND_GRADE" && !subjectId && !gradeId) ?  0.56 : 1}`}} 
                    modules={modules} 
                    formats={formats} 
                    value={description} 
                    onChange={((value: any) => {
                      !fetchingMsgLoader && setDisabled(false)
                      setDescription(value);
                    })} 
                  />
                </Col>
              </Row>
              <span
                className={validationError.description ? isDescriptionError ? "red-circle" : "green-circle" : 'd-none'}
              />
          </div>
          
          {/* <p className={`success-msg ${successMessage ? 'fadeIn' : ''}`}>Welcome message updated successfully!</p> */}
        </div>
        <div className="modal__footer">
          <button type="button" className="btn cancel-btn"
            onClick={() => setModalShow(false)}
          >
            Close
          </button>
          <button type="button" id="welcome-save-button" className={`btn success-btn btn-lg ${(loading || disabled || !title || isDescriptionError) && 'btn-disabled'}`}
            onClick={saveWelcomeMessage}
            // disabled={(loading || disabled ? true : false)}
          >
            {loading ? <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          /> : "Save"}
          </button>
        </div>
      </CustomModal>

      {error && (
        <ApiModal 
          setIsShow={() => setError('')}
          title="Edit Welcome Failed"
          description={error}
        />
      )}

      <ToastContainer
        style={{ position: 'absolute', zIndex: 9999 }}
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default WelcomeModal;
