import AddDate from "assets/img/add-icon.svg"
import { PacingCalenderContext } from "contexts/pacingCalender";
import { useContext, useState} from "react";
import { useGetDate } from "hooks/useGetDate";
import { DeleteConfirmationModal } from "components/common/deleteConfirmation";
import EditIcon from "assets/img/edit-icon.svg";
import ImportantDateSection from "./importDateSection";

const HighlightedRecord = ({ setShowModal, dateId, searchedValue }: any) => {

    const { deletePacingCalenderDates } = useContext(PacingCalenderContext);
    const date = useGetDate(dateId);

    const [deleteModal, setDeleteModal] = useState(false);

    const onClickDelete = async () => {
        const isDeleted = await deletePacingCalenderDates(dateId);
        isDeleted && setDeleteModal(false);
    }

    if(!dateId || searchedValue){
        return null;
    }


    return <>

    <ImportantDateSection text={date?date.title : ''}>
        {
            date ? (
                <img
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e: any) => {
                        if (e.key === "Enter") {
                            setShowModal({dateId, type: 'editModal', data: date.title})
                        }
                      }}
                    className={`edit-important-date cursor-pointer`}
                    src={EditIcon}
                    height="21px"
                    width="21px"
                    alt=""
                    onClick={() => setShowModal({dateId, type: 'editModal', data: date.title})}
                />
            ):(
                <img
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e: any) => {
                        if (e.key === "Enter") {
                            setShowModal(dateId)
                        }
                      }}
                    className={`add-important-date cursor-pointer`}
                    src={AddDate}
                    height="21px"
                    width="21px"
                    alt=""
                    onClick={() => setShowModal(dateId)}
                />
            )
        }
    </ImportantDateSection>
    
        {/* <tr className="PacingCalender__container__table__bodyrow">
            <td className="d-flex align-items-center bulbDiv text-white td1">
                
                <Bulb /> 
                {date ? <p style={{paddingLeft: '5px'}}>{date.title}</p> : ''}
            </td>
            <td className="text-white td2 ">
            </td>
            <td className="text-white  td3"></td>
            <td
                className="text-white  td4"
            >
                {date ? (
                    <img
                        className={`deleteIcon cursor-pointer`}
                        src={EditIcon}
                        height="21px"
                        width="21px"
                        alt=""
                        onClick={() => setShowModal({dateId, type: 'editModal', data: date.title})}
                    />
                ):(
                    <img
                        className={`cursor-pointer`}
                        src={AddDate}
                        height="21px"
                        width="21px"
                        alt=""
                        onClick={() => setShowModal(dateId)}
                    />
                )}

                

            </td>
        </tr> */}

        <DeleteConfirmationModal
            loading={false}
            isShow={deleteModal}
            headerText={`Delete Date`}
            bodyText={`Are you sure you want to delete this date ?`}
            onCancel={() => setDeleteModal(false)}
            onDelete={onClickDelete}
        />
    </>
}


export default HighlightedRecord