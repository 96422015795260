
const getDateYaxisValue = {
    0: 568,
    1: 516,
    2: 465,
    3: 413,
    4: 363,
    5: 312,
    6: 260,
    7: 210,
}

const getPercentageYaxisValue = {
    0: 585,
    1: 534,
    2: 483,
    3: 432,
    4: 381,
    5: 329,
    6: 279,
    7: 228,
}

const getStudentYaxisValue = {
    0: 580,
    1: 527,
    2: 478,
    3: 425,
    4: 375,
    5: 323,
    6: 272,
    7: 220,
}

const generatePdfData = (data: any[], index?: number) => {
    const teamDataArray = Object.keys(data).map((key: string) => {
        const value = data[key as keyof typeof data]?.toString() || '';

        switch(key){
            case 'objective':
                return {
                    text: value,
                    x: 150,
                    y: 715,
                    size: 10
                }
            case 'grade/subject':
                return {
                    text: value,
                    x: 380,
                    y: 686,
                    size: 12
                }
            case 'school':
                return {
                    text: value,
                    x: 380,
                    y: 715,
                    size: 10
                }
            case 'schoolLastName':
                return {
                    text: value,
                    x: 380,
                    y: 715,
                    size: 10
                }
            case 'date':
                return {
                    text: value,
                    x: 489,
                    y: 686,
                    size: 12
                }
            case 'student':
                return {
                    text: value,
                    x: 67,
                    y: getStudentYaxisValue[index as keyof typeof getStudentYaxisValue], //index !== undefined ? (580 - (index*53)) : 580,
                    size: 12
                }
            case 'date_first_attempt':
                return {
                    text: value,
                    x: 210,
                    y: getDateYaxisValue[index as keyof typeof getDateYaxisValue],
                    size: 12
                }
            case 'date_second_attempt':
                return {
                    text: value,
                    x: 340,
                    y: getDateYaxisValue[index as keyof typeof getDateYaxisValue],
                    size: 12
                }
            case 'date_third_attempt':
                return {
                    text: value,
                    x: 463,
                    y: getDateYaxisValue[index as keyof typeof getDateYaxisValue],
                    size: 12
                }
            case 'interventionist':
                return {
                    text: value,
                    x: 153,
                    y: 686,
                    size: 10
                }
            case 'first_attempt':
                return {
                    text: !!value ? `${value}%` : value,
                    x: 185,
                    y: getPercentageYaxisValue[index as keyof typeof getPercentageYaxisValue],
                    size: 10
                }
            case 'second_attempt':
                return {
                    text: !!value ? `${value}%` : value,
                    x: 315,
                    y: getPercentageYaxisValue[index as keyof typeof getPercentageYaxisValue],
                    size: 10
                }
            case 'third_attempt':
                return {
                    text: !!value ? `${value}%` : value,
                    x: 439,
                    y: getPercentageYaxisValue[index as keyof typeof getPercentageYaxisValue],
                    size: 10
                }
            default:
                return {
                    text: '',
                    x: 0,
                    y: 0,
                    size:0
                }
        }
    });

    return teamDataArray;

}
export const generateRemmediationReportData = (remmediationReportData: any) => {

    const {students, ...restReport} = remmediationReportData;
    const reportHeaderData = generatePdfData(restReport);
    let studentsData: any[] = [];
    students.forEach((element: any, index: number) => {
        if(index < 8){
            const singleStudentData = generatePdfData(element, index);
            studentsData = [...studentsData, ...singleStudentData]
        }
    });

    return [...reportHeaderData, ...studentsData];
}