/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import DeleteIcon from "assets/img/trash-icon.svg";
import ObjectiveIcon from "assets/img/objective-add.svg";
import { useDispatch, useSelector } from "react-redux";
import ActionType from "redux/actionTypes";
import { handlePress, handleKeyUp } from "util/index";
import { superscript } from "util/shared/OperatingSystem";
import {
  CustomInput,
  TextAreaInput,
  ValidationHint,
  Input,
} from "components/common/inputComponent";
import Select from "react-select";
import UploadFiles from "./uploadFiles";
import CustomModal from "components/common/Modal";

interface AddObjectiveModalProps {
  isShow: boolean;
  onCancel: () => void;
  onAdd: (data: any) => void;
  loading: boolean;
}

const options = [
  { label: "EN Description", value: "EN Description" },
  { label: "ES Description", value: "ES Description" },
];

export const AddObjectiveModal: React.FC<AddObjectiveModalProps> = ({
  isShow,
  loading,
  onCancel,
  onAdd,
}) => {
  const { objectives } = useSelector((state: any) => state);
  const [objectiveNumber, setObjectiveNumber] = useState("");
  const [objectiveName, setObjectiveName] = useState("");
  const [alignmentLabel, setAlignmentLabel] = useState("");
  const [description, setDescription] = useState("");
  const [descriptiones, setDescriptionES] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [dropDownLabel, setDropDownLabel] = useState<string>("EN Description");
  const [data, setData] = useState({
    lesson_guided_teacher_pdf: "",
    lesson_guided_student_pdf: "",
    continuos_teacher_pdf: "",
    continuos_student_pdf: "",
    independent_teacher_pdf: "",
    independent_student_pdf: "",
    power_standard: false,
  });

  const modalRef = useRef<any>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setClientErrors({
      ...clientErrors,
      objectiveNumber: objectives.objectiveAlreadyExist,
    });
    if (objectives.objectiveAlreadyExist) {
      setIsSubmitError(true);
    }
  }, [objectives.objectiveAlreadyExist]);

  useEffect(() => {
    clearData();
  }, [isShow]);

  useEffect(() => {
    checkDisabled();
  }, [objectiveName, objectiveNumber, description]);

  const onSubmit = (files: any) => {
    let errorsCopy = { ...clientErrors };
    let isError = false;

    const name = objectiveName.trim();
    if (!name) {
      errorsCopy.objectiveName = "required";
      isError = true;
    }

    const objective_number = objectiveNumber.trim();
    if (!objective_number) {
      errorsCopy.objectiveNumber = "required";
      isError = true;
    }

    const description_value = description.trim();
    if (!description_value) {
      errorsCopy.description = "required";
      isError = true;
    }
    if (objective_number.length > 14) {
      errorsCopy.objectiveNumber = "Objective Id cannot exceed 14 char";
      isError = true;
    }
    if (isError) {
      setClientErrors(errorsCopy);
      setIsSubmitError(true);
      // setObjectiveNumber("");
      // setObjectiveName("");
      // setDescription("");
    } else {
      const updatedDescription = superscript(description_value);
      const updatedDescriptionEs = superscript(descriptiones);
      const newFiles = { ...files };
      Object.keys(newFiles).forEach((key) => {
        const val = newFiles[key];
        if (!val && key !== "power_standard") {
          delete newFiles[key];
        }
      });
      let data = {
        objective_number: objective_number,
        name: name,
        description: updatedDescription,
        alignment_label: alignmentLabel?.trim() || "",
        description_es: updatedDescriptionEs?.trim() || "",
        ...newFiles,
      };
      onAdd(data);
      // clearData();
    }
  };

  const clearData = () => {
    setDescription("");
    setObjectiveName("");
    setObjectiveNumber("");
    setIsSubmitError(false);
    setClientErrors({});
    dispatch({
      type: ActionType.ADD_OBJECTIVES_ERROR,
      objectiveAlreadyExist: null,
    });
  };

  const handleOnChange = (name: string, value: any) => {
    name === "objectiveNumber" && setObjectiveNumber(value);
    name === "objectiveName" && setObjectiveName(value);
    name === "description" && setDescription(value);
    name === "descriptiones" && setDescriptionES(value);
    name === "alignmentLabel" && setAlignmentLabel(value);
    if (isSubmitError) {
      const isValid =
        name === "objectiveNumber"
          ? value.trim().length > 4 && value.trim().length < 14
          : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  const checkDisabled = () => {
    if (objectiveName && objectiveNumber && description) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  useEffect(() => {
    isShow && document.addEventListener("keydown", handlePress);
    isShow && document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  // We don't want to close the modal when we click outside the modal.
  // useOutsideClick(modalRef, () => {
  //   onCancel();
  //   clearData();
  // }, true);

  return (
    <CustomModal
      centered
      show={isShow}
      // onEscapeKeyDown={() => {
      //   onCancel();
      //   clearData();
      // }}
      className="objective-modal"
    >
      <div ref={modalRef} className="objective-modal-content">
        <div className="objective-form">
          <div className="modal__header">
            <img src={ObjectiveIcon} alt="objective" className="modal__header-icon" />
            <h6 className="modal__header-title">Add Objective</h6>
          </div>
          <div className="modal__body">
            <div className="modal__form">
              <ValidationHint isSubmitError={isSubmitError} />
              <CustomInput
                fieldName="objectiveNumber"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors.objectiveNumber !== undefined}
                type="text"
                value={objectiveNumber}
                label="Objective ID"
                autoFocus={true}
                onChange={handleOnChange}
                errorMessage={clientErrors.objectiveNumber}
                error={clientErrors.objectiveNumber}
                overlayClick={() =>
                  setClientErrors({
                    ...clientErrors,
                    objectiveNumber: "required",
                  })
                }
              />

              <CustomInput
                fieldName="objectiveName"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors.objectiveName !== undefined}
                type="text"
                value={objectiveName}
                label="Objective Strand"
                onChange={handleOnChange}
                error={clientErrors.objectiveName}
                errorMessage={clientErrors.objectiveName}
                overlayClick={() =>
                  setClientErrors({
                    ...clientErrors,
                    objectiveName: "required",
                  })
                }
              />
              <Input
                fieldName="alignmentLabel"
                isSubmitError={isSubmitError}
                errorPresentBefore={false}
                type="text"
                value={alignmentLabel}
                label="Alignment Label"
                onChange={handleOnChange}
                disabled
              />
              <TextAreaInput
                fieldName={
                  dropDownLabel === "EN Description"
                    ? "description"
                    : "descriptiones"
                }
                isSubmitError={isSubmitError}
                errorPresentBefore={
                  dropDownLabel === "EN Description"
                    ? clientErrors["description"] !== undefined
                    : false
                }
                value={
                  dropDownLabel === "EN Description"
                    ? description
                    : descriptiones
                }
                label={
                  <Select
                    onChange={(e: any) => setDropDownLabel(e.label)}
                    value={options.find((item) => item.label === dropDownLabel)}
                    options={options}
                    isSearchable={false}
                    className="dropdown-diabled"
                    classNamePrefix="objective-select"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                }
                onChange={handleOnChange}
                error={
                  dropDownLabel === "EN Description"
                    ? clientErrors.description
                    : ""
                }
                customLabel={true}
                customClass="mt-4"
              />
            </div>
          </div>
        </div>
        <UploadFiles
          onClick={() => {
            onCancel();
            clearData();
          }}
          onSubmit={onSubmit}
          submitDisabled={isDisabled}
          data={data}
          setData={setData}
          loading={loading}
        />
      </div>
    </CustomModal>
  );
};

interface UpdateObjectiveModalProps {
  currentObjective: any;
  isShow: boolean;
  onCancel: () => void;
  onUpdate: (data: any) => void;
  loading: boolean;
}

export const UpdateObjectiveModal: React.FC<UpdateObjectiveModalProps> = ({
  currentObjective,
  isShow,
  loading,
  onCancel,
  onUpdate,
}) => {
  const [objectiveNumber, setObjectiveNumber] = useState(
    currentObjective?.objective_number
  );
  const { objectives } = useSelector((state: any) => state);
  const [objectiveName, setObjectiveName] = useState(currentObjective?.name);
  const [description, setDescription] = useState(currentObjective?.description);
  const [alignmentLabel, setAlignmentLabel] = useState(
    currentObjective?.alignment_label
  );
  const [isDisabled, setDisabled] = useState(true);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [descriptiones, setDescriptionES] = useState(
    currentObjective?.description_es
  );
  const [dropDownLabel, setDropDownLabel] = useState<string>("EN Description");
  const [data, setData] = useState({
    lesson_guided_practice_teacher:
      currentObjective?.lesson_guided_practice_teacher || "",
    lesson_guided_practice_student:
      currentObjective?.lesson_guided_practice_student || "",
    continuos_practice_teacher:
      currentObjective?.continuos_practice_teacher || "",
    continuos_practice_student:
      currentObjective?.continuos_practice_student || "",
    independent_practice_teacher:
      currentObjective?.independent_practice_teacher || "",
    independent_practice_student:
      currentObjective?.independent_practice_student || "",
    power_standard: currentObjective?.power_standard,
  });

  // console.log('currentObjective', currentObjective, data);

  const modalRef = useRef<any>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setClientErrors({
      ...clientErrors,
      objectiveNumber: objectives.objectiveAlreadyExist,
    });
    if (objectives.objectiveAlreadyExist) {
      setIsSubmitError(true);
    }
  }, [objectives.objectiveAlreadyExist]);

  useEffect(() => {
    checkDisabled();
  }, [
    objectiveName,
    objectiveNumber,
    description,
    alignmentLabel,
    descriptiones,
    data,
  ]);

  const checkDisabled = () => {
    if (
      objectiveName !== currentObjective?.name ||
      objectiveNumber !== currentObjective?.objective_number ||
      description !== currentObjective?.description ||
      descriptiones !== currentObjective?.description_es ||
      alignmentLabel !== currentObjective?.alignment_label ||
      data.continuos_practice_student !==
        currentObjective?.continuos_practice_student ||
      data.continuos_practice_teacher !==
        currentObjective?.continuos_practice_teacher ||
      data.independent_practice_teacher !==
        currentObjective?.independent_practice_teacher ||
      data.independent_practice_student !==
        currentObjective?.independent_practice_student ||
      data.lesson_guided_practice_teacher !==
        currentObjective?.lesson_guided_practice_teacher ||
      data.lesson_guided_practice_student !==
        currentObjective?.lesson_guided_practice_student ||
      data.power_standard !== currentObjective?.power_standard
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const onSubmit = (files: any) => {
    let errorsCopy = { ...clientErrors };
    let isError = false;

    const objective_name = objectiveName.trim();
    if (!objective_name) {
      errorsCopy.objectiveName = "required";
      isError = true;
    }

    const objective_number = objectiveNumber.trim();
    console.log("objective_number", objective_number);
    if (objective_number.length > 14) {
      errorsCopy.objectiveNumber = "Objective Id cannot exceed 14 char";
      isError = true;
    }

    if (!objective_number) {
      errorsCopy.objectiveNumber = "required";
      isError = true;
    }

    const description_value = description.trim();
    if (!description_value) {
      errorsCopy.description = "required";
      isError = true;
    }

    if (isDisabled && !isError) {
      return cancel();
    }
    if (isError) {
      setClientErrors({ ...errorsCopy });
      setIsSubmitError(true);
    } else {
      const updatedDescription = superscript(description_value);
      const updatedDescriptionEs = superscript(descriptiones);

      const newFiles = { ...files };
      Object.keys(newFiles).forEach((key) => {
        const val = newFiles[key];
        if (!val && key !== "power_standard") {
          delete newFiles[key];
        }
      });

      let data = {
        objective_number: objective_number,
        name: objective_name,
        description: updatedDescription,
        alignment_label: alignmentLabel?.trim(),
        description_es: updatedDescriptionEs?.trim(),
        ...newFiles,
      };
      onUpdate(data);
    }
  };

  const cancel = () => {
    onCancel();
    setClientErrors({});
    setIsSubmitError(false);
    dispatch({
      type: ActionType.UPDATE_OBJECTIVES_ERROR,
      payload: "",
    });
  };

  const handleOnChange = (name: string, value: any) => {
    name === "objectiveNumber" && setObjectiveNumber(value);
    name === "objectiveName" && setObjectiveName(value);
    name === "description" && setDescription(value);
    name === "alignmentLabel" && setAlignmentLabel(value);
    name === "descriptiones" && setDescriptionES(value);
    if (isSubmitError) {
      const isValid =
        name === "objectiveNumber"
          ? value.trim().length > 4 && value.trim().length < 14
          : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };
  useEffect(() => {
    isShow && document.addEventListener("keydown", handlePress);
    return () => {
      document.removeEventListener("keydown", handlePress);
    };
  }, []);

  // For Edit modals, don't close modal when clicking outside the modal.
  // useOutsideClick(modalRef, cancel, true);

  return (
    <CustomModal
      className="objective-modal"
      centered
      show={isShow} /*onEscapeKeyDown={cancel}*/
    >
      <div ref={modalRef} className="objective-modal-content">
        <div className="objective-form">
          <div className="modal__header">
            <img src={ObjectiveIcon} className="modal__header-icon" alt="Objective" />
            <h6 className="modal__header-title">Edit Objective</h6>
          </div>
          <div className="modal__body">
            <div className="modal__form">
              <ValidationHint isSubmitError={isSubmitError} />
              <CustomInput
                fieldName="objectiveNumber"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors.objectiveNumber !== undefined}
                type="text"
                value={objectiveNumber}
                autoFocus={true}
                label="Objective ID"
                onChange={handleOnChange}
                error={clientErrors.objectiveNumber}
                errorMessage={clientErrors.objectiveNumber}
                overlayClick={() =>
                  setClientErrors({
                    ...clientErrors,
                    objectiveNumber: "required",
                  })
                }
              />
              <CustomInput
                fieldName="objectiveName"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors.objectiveName !== undefined}
                type="text"
                value={objectiveName}
                label="Objective Strand"
                onChange={handleOnChange}
                error={clientErrors.objectiveName}
                errorMessage={clientErrors.objectiveName}
                overlayClick={() =>
                  setClientErrors({
                    ...clientErrors,
                    objectiveName: "required",
                  })
                }
              />
              <Input
                fieldName="alignmentLabel"
                isSubmitError={isSubmitError}
                errorPresentBefore={false}
                type="text"
                value={alignmentLabel}
                label="Alignment Label"
                onChange={handleOnChange}
                disabled
              />
              <TextAreaInput
                fieldName={
                  dropDownLabel === "EN Description"
                    ? "description"
                    : "descriptiones"
                }
                isSubmitError={isSubmitError}
                errorPresentBefore={
                  dropDownLabel === "EN Description"
                    ? clientErrors["description"] !== undefined
                    : false
                }
                value={
                  dropDownLabel === "EN Description"
                    ? description
                    : descriptiones
                }
                label={
                  <Select
                    onChange={(e: any) => setDropDownLabel(e.label)}
                    value={options.find((item) => item.label === dropDownLabel)}
                    options={options}
                    isSearchable={false}
                    className="dropdown-diabled"
                    classNamePrefix="objective-select"
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                }
                onChange={handleOnChange}
                error={
                  dropDownLabel === "EN Description"
                    ? clientErrors.description
                    : ""
                }
                customLabel={true}
                customClass="mt-4"
              />
            </div>
          </div>
        </div>

        <UploadFiles
          currentObjective={currentObjective}
          onClick={() => cancel()}
          onSubmit={onSubmit}
          submitDisabled={isDisabled}
          setData={setData}
          data={data}
          loading={loading}
        />
      </div>
    </CustomModal>
  );
};

interface DeleteObjectiveModalProps {
  loading: boolean;
  isShow: boolean;
  headerText: string;
  bodyText: string;
  onCancel: () => void;
  onDelete: () => void;
}

export const DeleteObjectiveModal: React.FC<DeleteObjectiveModalProps> = ({
  isShow,
  headerText,
  bodyText,
  loading,
  onCancel,
  onDelete,
}) => {
  function handleEsc(event: any) {
    if (event.keyCode === 27) {
      onCancel();
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <CustomModal centered show={isShow}>
      <div className="modal__header">
        <img alt="trash" src={DeleteIcon} className="modal__header-icon" />
        <h6 className="modal__header-title">{headerText}</h6>
      </div>
      <div className="modal__body">
        <p>{bodyText}</p>
      </div>
      <div className="modal__footer">
        <button
          disabled={loading}
          className="btn cancel-btn"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          disabled={loading}
          className="btn danger-btn"
          onClick={onDelete}
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Delete"
          )}
        </button>
      </div>
    </CustomModal>
  );
};

interface CreateFormativeModalProps {
  loading: boolean;
  isShow: boolean;
  headerText: string;
  bodyText: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export const CreateFormativeModal: React.FC<CreateFormativeModalProps> = ({
  isShow,
  headerText,
  bodyText,
  loading,
  onCancel,
  onConfirm,
}) => {
  function handleEsc(event: any) {
    if (event.keyCode === 27) {
      onCancel();
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <CustomModal centered show={isShow}>
      <div className="modal__header">
        <img alt="trash" src={DeleteIcon} className="modal__header-icon" />
        <h6 className="modal__header-title">{headerText}</h6>
      </div>
      <div className="modal__body">
        <p>{bodyText}</p>
      </div>
      <div className="modal__footer">
        <button
          disabled={loading}
          className="btn cancel-btn"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          disabled={loading}
          className="btn danger-btn"
          onClick={onConfirm}
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Yes"
          )}
        </button>
      </div>
    </CustomModal>
  );
};

interface DeleteFormativeModalProps {
  loading: boolean;
  isShow: boolean;
  headerText: string;
  bodyText: string;
  onCancel: () => void;
  onDelete: () => void;
}

export const DeleteFormativeOnlyModal: React.FC<DeleteFormativeModalProps> = ({
  isShow,
  headerText,
  bodyText,
  loading,
  onCancel,
  onDelete,
}) => {
  function handleEsc(event: any) {
    if (event.keyCode === 27) {
      onCancel();
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, []);

  return (
    <CustomModal centered show={isShow}>
      <div className="modal__header">
        <img alt="trash" src={DeleteIcon} className="modal__header-icon" />
        <h6 className="modal__header-title">{headerText}</h6>
      </div>
      <div className="modal__body">
        <p>{bodyText}</p>
      </div>
      <div className="modal__footer">
        <button
          disabled={loading}
          className="btn cancel-btn"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          disabled={loading}
          className="btn danger-btn"
          onClick={onDelete}
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Delete"
          )}
        </button>
      </div>
    </CustomModal>
  );
};
