import React from 'react'

const WarningIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22.286" viewBox="0 0 24 22.286">
  <g id="warning_icon" data-name="warning icon" transform="translate(-523 -851)">
    <g id="Polygon_3" data-name="Polygon 3" transform="translate(523 851)" fill="#ffed7f">
      <path d="M 22.24658966064453 19.78571891784668 L 1.7534099817276 19.78571891784668 C 1.491269946098328 19.78571891784668 1.362349987030029 19.60928916931152 1.319090008735657 19.5334300994873 C 1.275820016860962 19.45756912231445 1.189610004425049 19.25676918029785 1.323070049285889 19.03114891052246 L 11.5696496963501 1.709539413452148 C 11.70069026947021 1.488019347190857 11.9142599105835 1.464109420776367 12 1.464109420776367 C 12.0857400894165 1.464109420776367 12.29930973052979 1.488019347190857 12.4303503036499 1.709539413452148 L 22.67692947387695 19.03114891052246 C 22.81039047241211 19.25676918029785 22.72418022155762 19.45756912231445 22.68091011047363 19.5334300994873 C 22.63764953613281 19.60928916931152 22.50872993469238 19.78571891784668 22.24658966064453 19.78571891784668 Z" stroke="none"/>
      <path d="M 12 1.964099884033203 L 12.00000286102295 1.964103698730469 C 12.00000190734863 1.964105606079102 12.00000095367432 1.964107513427734 12 1.964109420776367 L 1.753419876098633 19.28571891784668 C 1.753419876098633 19.28571891784668 1.753419876098633 19.28571891784668 1.753410339355469 19.28571891784668 L 22.24658012390137 19.28571891784668 L 12 1.964099884033203 M 12 0.9641017913818359 C 12.33355522155762 0.9641017913818359 12.66711044311523 1.127723693847656 12.86069011688232 1.45496940612793 L 23.10726928710938 18.77657890319824 C 23.50160026550293 19.44318962097168 23.02109909057617 20.28571891784668 22.24658966064453 20.28571891784668 L 1.753410339355469 20.28571891784668 C 0.9789009094238281 20.28571891784668 0.4983997344970703 19.44318962097168 0.892730712890625 18.77657890319824 L 11.13930988311768 1.45496940612793 C 11.33288955688477 1.127723693847656 11.66644477844238 0.9641017913818359 12 0.9641017913818359 Z" stroke="none" fill="#000"/>
    </g>
    <text id="_" data-name="!" transform="translate(535 868.286)" font-size="14" font-family="Nunito-ExtraBold, Nunito" font-weight="800"><tspan x="-1.82" y="0">!</tspan></text>
  </g>
</svg>
  )
}

export default WarningIcon
