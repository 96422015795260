/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useMemo, useRef } from "react"
import ReportTable from "./ReportTable/table"
import MainHeader from "./mainHeader"
import ReportHeader from "components/common/report/Print/header"
import PrintFooter from "components/common/report/Print/footer";
import {browserHeights, isSafari} from "util/index";
import { useSchoolName } from "hooks/useSchoolName";

interface Props {
    records: any
    class_name: string
    time: Date,
    test_name: string
}


const RiskPrint: FC<Props> = ({ class_name, records, time, test_name }) => {

    const indexRef = useRef<number>(0)
    const school_name = useSchoolName();
    const contentGenerator = useMemo(() => {
        const toPrint: any = [];
        indexRef.current = 0

        const tablesOnSinglePage = () => {
            let pageSize = 1000;
            if(isSafari)
                pageSize = 800
            const tablePrint: any = []

            while (indexRef.current < records.length) {
                const record = records[indexRef.current];

                const tableSize = 100 + (record.students.length * 20)

                if (tableSize > pageSize && tablePrint.length) {
                    return tablePrint
                }

                pageSize = pageSize - tableSize
                indexRef.current = indexRef.current + 1;

                tablePrint.push(
                    <div>
                        <ReportTable
                            record={record}
                            time={time}
                        />
                    </div>
                )
            }

            return tablePrint
        }

        while (indexRef.current < records.length) {

            toPrint.push(
                <div className={`print-report standard-risk-report-print`} >
                    <ReportHeader
                        class_name={class_name}
                        title="Standards at Risk"
                        test_name={test_name}
                        school_name={school_name}
                    />

                    <div className={`report-risk-print  mx-auto`} >
                        <div className={`${browserHeights}`}>
                            <MainHeader />
                            {tablesOnSinglePage()}
                        </div>
                        <PrintFooter/>
                    </div>
                </div >
            )
        }

        return toPrint;
    }, [records, school_name])


    return (
        <>
            {contentGenerator}
        </>

    )
}
export default RiskPrint