import { ProfessionalDevelopment } from "interfaces/professionalDevelopment";

export enum ProfessionalDevelopmentActions {
  SET_FORMATIVES = "SET_FORMATIVES",
  SET_SUMMATIVES = "SET_SUMMATIVES",
  SET_ACTIVE_TAB = "SET_ACTIVE_TAB",
  SET_PD_CLASSROOM = "SET_PD_CLASSROOM",
  SET_PD_CLASSROOM_TILL_DATE = "SET_PD_CLASSROOM_TILL_DATE",
  SET_SUMMATIVE_MARK_COMPLETED = "SET_SUMMATIVE_MARK_COMPLETED",
  SET_FORMATIVE_MARK_COMPLETED = "SET_FORMATIVE_MARK_COMPLETED",
  SET_PD_CLASSROOM_LOADING = "SET_PD_CLASSROOM_LOADING",
  SET_CLASSROOM_TOOLTIP = "SET_CLASSROOM_TOOLTIP",
  SET_SELECTED_GOAL = "SET_SELECTED_GOAL",
  SET_PREV_STL_EXISTS = "SET_PREV_STL_EXISTS",
  SET_PREV_CLASSROOM_STL_EXISTS = "SET_PREV_CLASSROOM_STL_EXISTS",
  SET_FORMATIVE_REMEDIATE_DATA = "SET_FORMATIVE_REMEDIATE_DATA",
  SET_ASSESSMENT_MONITOR = "SET_ASSESSMENT_MONITOR",
}

export const ProfessionalDevelopmentReducer = (
  state: ProfessionalDevelopment,
  Action: any
) => {
  const { payload, type } = Action;
  switch (type) {
    case ProfessionalDevelopmentActions.SET_ASSESSMENT_MONITOR:
      return {
        ...state,
        assessmentMonitor: payload,
      };
    case ProfessionalDevelopmentActions.SET_CLASSROOM_TOOLTIP:
      return {
        ...state,
        tooltip: payload,
      };
    case ProfessionalDevelopmentActions.SET_FORMATIVES:
      return {
        ...state,
        formatives: payload,
      };
    case ProfessionalDevelopmentActions.SET_SUMMATIVES:
      return {
        ...state,
        summatives: payload,
      };
    case ProfessionalDevelopmentActions.SET_ACTIVE_TAB:
      return {
        ...state,
        activeHeaderTab: payload,
      };
    case ProfessionalDevelopmentActions.SET_PD_CLASSROOM_LOADING:
      return {
        ...state,
        pdClassroom: {
          ...state.pdClassroom,
          loading: payload,
        },
      };
    case ProfessionalDevelopmentActions.SET_PD_CLASSROOM:
      return {
        ...state,
        pdClassroom: {
          ...payload,
          loading: false,
        },
      };

    case ProfessionalDevelopmentActions.SET_PD_CLASSROOM_TILL_DATE:
      return {
        ...state,
        goalsList: {
          ...payload,
          loading: false,
        },
      };
    case ProfessionalDevelopmentActions.SET_SUMMATIVE_MARK_COMPLETED:
      return {
        ...state,
        summatives: state.summatives.map((assessment: any) => {
          return assessment.id === payload.summative_id
            ? {
                ...assessment,
                is_completed: payload.status,
                ...(payload.color && { scheduled_color: payload.color }),
              }
            : assessment;
        }),
      };
    case ProfessionalDevelopmentActions.SET_FORMATIVE_MARK_COMPLETED:
      const updatedFormatives = state.formatives.map((assessment: any) => {
        if (assessment.id === payload.formative_id) {
          return {
            ...assessment,
            is_completed: payload.status,
            ...("moved" in payload && { moved: payload.moved }),
            ...(payload.color && { scheduled_color: payload.color }),
          };
        }
        return assessment;
      });

      console.log("isAllMoved", payload.isAllMoved);

      if (payload.isAllMoved) {
        updatedFormatives.forEach((item) => {
          delete item.moved;
        });
      }
      return {
        ...state,
        formatives: updatedFormatives,
      };
    case ProfessionalDevelopmentActions.SET_SELECTED_GOAL:
      return {
        ...state,
        selectedGoal: payload,
      };
    case ProfessionalDevelopmentActions.SET_PREV_STL_EXISTS:
      return {
        ...state,
        prevSTLexists: payload,
      };
    case ProfessionalDevelopmentActions.SET_PREV_CLASSROOM_STL_EXISTS:
      return {
        ...state,
        prevSTLClassroomExists: payload,
      };
    case ProfessionalDevelopmentActions.SET_FORMATIVE_REMEDIATE_DATA:
      return {
        ...state,
        formativeRemediateList: payload,
      };
    default:
      return state;
  }
};
