/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */

import React, { useState, useEffect, useRef } from "react";
import PdfIcon from "assets/img/pdf-alt.svg";
import wwwIcon from "assets/img/www.png";
import ZipIcon from "assets/img/zip-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getActivityResources } from "redux/actionCreators/activity";
import { getWelcomeMessageClassroom } from "redux/actionCreators/classroom";
import { downloadPdfFile } from "util/index";
import Cookies from "js-cookie";
import { getGradeList } from "services/get-grades-list";
import { GradeSelect } from "components/common/selectComponent";
import { CustomSkeleton } from "components/common/skeleton/TableSkeleton";
import { setFilters } from "redux/actionCreators/activity";
import ResourceNameHeading from "./ResourceNameHeading";
import { AppCookies } from "util/constants";


interface RootState {
  auth: any;
  activity: any;
  classroom: any;
}

interface Props {
  book_access: string;
}
const Resources: React.FC<Props> = ({ book_access }) => {
  const has_books_access = !(book_access === "No" || book_access === "None");
  const dispatch = useDispatch();
  const app = useSelector((state: RootState) => state);
  const [books, setBooks] = useState([]);
  const [assessments16, setAssessments16] = useState([]);
  const [assessments21, setAssessments21] = useState([]);
  const [pd, setPd] = useState([]);
  const [other, setOther] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [, setIsClassroom] = useState(false);
  const userRole = Cookies.get(AppCookies.role);
  const [grades, setGradeList] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [selectedTab, setSelectedTab] = useState(1);

  const tabRefs = {
    books: useRef(null),
    assessments2024: useRef(null),
    assessments2023: useRef(null),
    pd: useRef(null),
    other: useRef(null),
  };

  const formattedSearch = search.toLowerCase();
  const filteredbooks = books.filter(
    (book: any) =>
      book.filename?.toLowerCase()?.includes(formattedSearch) ||
      book.description?.toLowerCase()?.includes(formattedSearch)
  );
  const filteredassessments16 = assessments16.filter(
    (book: any) =>
      book.filename?.toLowerCase()?.includes(formattedSearch) ||
      book.description?.toLowerCase()?.includes(formattedSearch)
  );
  const filteredassessments21 = assessments21.filter(
    (book: any) =>
      book.filename?.toLowerCase()?.includes(formattedSearch) ||
      book.description?.toLowerCase()?.includes(formattedSearch)
  );
  const filteredpd = pd.filter(
    (book: any) =>
      book.filename?.toLowerCase()?.includes(formattedSearch) ||
      book.description?.toLowerCase()?.includes(formattedSearch)
  );
  const filteredother = other.filter(
    (book: any) =>
      book.filename?.toLowerCase()?.includes(formattedSearch) ||
      book.description?.toLowerCase()?.includes(formattedSearch)
  );

  const searchResults = filteredbooks
    .concat(filteredassessments16)
    .concat(filteredassessments21)
    .concat(filteredpd)
    .concat(filteredother);

  useEffect(() => {
    setBooks([]);
    setAssessments16([]);
    setAssessments21([]);
    setPd([]);
    setOther([]);
    const fetchGrades = async () => {
      let gradeResponse = await getGradeList();
      setGradeList(gradeResponse?.data?.grades);
      setSelectedGrade(app.classroom.grade);
    };
    fetchGrades();
  }, [app.classroom.classroomSchoolId]);

  useEffect(() => {
    setSelectedGrade(app.classroom.grade);
  }, [app.classroom.grade]);

  const fetchWelcomeMessage = (
    paramType: string,
    role_id: number,
    grade_id: number,
    subject_id: number
  ) => {
    const params = {
      type: paramType,
      grade_id,
      subject_id,
      role_id,
    };
    try {
      dispatch(getWelcomeMessageClassroom(params));
    } catch (e: any) {
      console.log(e.message);
    }
  };

  const changeMessage = () => {
    if (app.classroom.subject > 0 && app.classroom.grade > 0) {
      setIsClassroom(true);
      fetchWelcomeMessage(
        "SUBJECT_AND_GRADE",
        0,
        app.classroom.grade,
        app.classroom.subject
      );
    } else {
      setIsClassroom(false);
    }
  };

  useEffect(() => {
    dispatch(setFilters(true));
  }, []);

  useEffect(() => {
    const init = async () => {
      if (
        app.activity.type === "SET_FILTERS" &&
        app.activity.applyFilters === true
      ) {
        if (app.classroom.classroomId > 0) {
          setIsLoading(true);
          const allResources: any = await getActivityResources(
            app.classroom.grade,
            app.classroom.subject,
            app.classroom.classroomId
          );
          if (allResources.data.data) {
            setBooks(allResources.data.data.books);
            setAssessments16(allResources.data.data.assessments_2016);
            setAssessments21(allResources.data.data.assessments_2021);
            setOther(allResources.data.data.other);
            setPd(allResources.data.data.pd);
          }
          changeMessage();
          setIsLoading(false);
          // setTimeout(() => {setIsLoading(false)}, 2000);
        } else {
          setBooks([]);
          setAssessments16([]);
          setAssessments21([]);
          setOther([]);
          setPd([]);
          setIsClassroom(false);
        }
      } else {
        setIsLoading(false);
      }
    };
    init();
  }, [app.activity.type, app.activity.applyFilters, app.classroom.classroomId]);

  const handleClickRecord = (item: any) => {
    if (item.is_file) {
      downloadPdfFile(item.file_url, item.filename, item.file_type);
    } else {
      let url = item.file_url;
      window.open(
        url.includes("http") ? item.file_url : "https://".concat(item.file_url),
        "_blank"
      );
    }
  };
  const school = app.activity.schoolsList?.find(
    (sch: any) => sch.id === app.classroom.classroomSchoolId
  );
  const access_resources =
    app.classroom.classroomSchoolId > 0 && app.classroom.classroomId > 0
      ? school?.has_pdf_access
      : true;

  const classInfo = app.classroom.classrooms?.find(
    (item: any) => item.id === app.classroom.classroomId
  );

  const isResourceOnly = classInfo?.students_count === 0;
  const isOnlyResourcesClass = isResourceOnly || classInfo?.fk_grade_id === 9;

  const changeResources = async (gradeId: any) => {
    setIsLoading(true);
    const allResources: any = await getActivityResources(
      gradeId,
      app.classroom.subject,
      app.classroom.classroomId
    );
    setIsLoading(false);
    if (allResources.data.data) {
      setBooks(allResources.data.data.books);
      setAssessments16(allResources.data.data.assessments_2016);
      setAssessments21(allResources.data.data.assessments_2021);
      setOther(allResources.data.data.other);
      setPd(allResources.data.data.pd);

      if (tabRefs["books"].current) {
        //@ts-ignore
        tabRefs["books"]?.current?.click();

        if (selectedTab !== 1) {
          let tab =
            selectedTab === 2
              ? "assessments2024"
              : selectedTab === 4
              ? "pd"
              : "other";

          //@ts-ignore
          tabRefs[tab]?.current?.click();
        }
      }
    }
  };

  const show_22_23_assessment = false;
  console.log(selectedGrade, isResourceOnly, access_resources);
  return (
    <>
      <div className="classroom">
        <div className="section resources-section">
          <div className="classroom__tabs">
            <div className="activity__welcome-center">
              <div className="activity__welcome-centerContent"></div>

              <>
                <div className="classroom__tabs-container">
                  <div
                    className={`classroom__tabs-inner ${
                      access_resources ? "" : "disabled-resources"
                    }`}
                  >
                    <nav className="classroom__tabs-header position-relative resources-content">
                      <div className="grade-wrapper">
                        <label>Grade</label>
                        {app.classroom.subject ? (
                          <GradeSelect
                            onChangeHandler={(e: any) => {
                              setSelectedGrade(e.id);
                              changeResources(e.id);
                            }}
                            options={grades}
                            selected={selectedGrade}
                            isDisabled={selectedTab === 5 ? true : false}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        className="nav nav-tabs classroom__tabs-buttons resources-content__tabs-buttons"
                        id="nav-tab"
                        role="tablist"
                      >
                        <button
                          className="nav-link btn text-btn active"
                          id="nav-books-tab"
                          ref={tabRefs.books}
                          data-bs-toggle="tab"
                          data-bs-target="#nav-books"
                          type="button"
                          role="tab"
                          aria-controls="nav-books"
                          aria-selected={selectedTab === 1}
                          disabled={access_resources && !search ? false : true}
                          onClick={() => {
                            setSelectedTab(1);
                          }}
                        >
                          Books
                        </button>

                        <button
                          ref={tabRefs.assessments2024}
                          className="nav-link btn text-btn"
                          id="nav-assessments-21-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-assessments-21"
                          type="button"
                          role="tab"
                          aria-controls="nav-assessments-21"
                          aria-selected={selectedTab === 2}
                          disabled={ isResourceOnly || selectedGrade === 9 ? true : access_resources && !search ? false : true}
                          onClick={() => {
                            setSelectedTab(2);
                          }}
                        >
                          2024-25 Assessments
                        </button>
                        

                        {show_22_23_assessment && (
                          <button
                            ref={tabRefs.assessments2023}
                            className="nav-link btn text-btn"
                            id="nav-assessments-16-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-assessments-16"
                            type="button"
                            role="tab"
                            aria-controls="nav-assessments-16"
                            aria-selected={selectedTab === 3}
                            disabled={
                              isResourceOnly ? true : (access_resources && !search) ? false : true
                            }
                            onClick={() => {
                              setSelectedTab(3);
                            }}
                          >
                            2023-24 Assessments
                          </button>
                        )}

                        <button
                          ref={tabRefs.pd}
                          className="nav-link btn text-btn"
                          id="nav-pd-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-pd"
                          type="button"
                          role="tab"
                          aria-controls="nav-pd"
                          aria-selected={selectedTab === 4}
                          disabled={ isResourceOnly && selectedGrade !==9 ? true : (access_resources && !search)  ? false : true}
                          onClick={() => {
                            setSelectedTab(4);
                          }}
                        >
                          PD
                        </button>
                        <button
                          ref={tabRefs.other}
                          className="nav-link btn text-btn"
                          id="nav-other-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-other"
                          type="button"
                          role="tab"
                          aria-controls="nav-other"
                          aria-selected={selectedTab === 5}
                          disabled={ isResourceOnly && selectedGrade !== 9 ? true : (access_resources && !search) ? false : true}
                          onClick={() => {
                            setSelectedTab(5);
                          }}
                        >
                          Other
                        </button>
                      </div>
                      <div className="search-box">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="18"
                          viewBox="0 0 17 18"
                          fill="none"
                        >
                          <path
                            d="M2.1174 2.77292C3.48395 1.43179 5.3222 0.68045 7.2369 0.68045C9.15161 0.68045 10.9899 1.43179 12.3564 2.77292C13.6522 4.04397 14.4099 5.76451 14.4726 7.57857C14.5353 9.39263 13.8983 11.1614 12.6934 12.5189L16.7144 16.4859C16.7603 16.5309 16.7968 16.5846 16.8217 16.6438C16.8466 16.703 16.8594 16.7667 16.8594 16.8309C16.8594 16.8952 16.8466 16.9588 16.8217 17.018C16.7968 17.0773 16.7603 17.1309 16.7144 17.1759C16.6209 17.2675 16.4953 17.3188 16.3644 17.3188C16.2335 17.3188 16.1079 17.2675 16.0144 17.1759L11.9944 13.2089C10.605 14.3994 8.81974 15.0256 6.99112 14.964C5.1625 14.9023 3.42346 14.1573 2.1174 12.8759C1.44641 12.2172 0.913431 11.4313 0.549597 10.5642C0.185762 9.69713 -0.00162888 8.76624 -0.00162888 7.82592C-0.00162888 6.8856 0.185762 5.95471 0.549597 5.08764C0.913431 4.22056 1.44641 3.43468 2.1174 2.77592V2.77292ZM2.8174 12.1859C3.99819 13.342 5.58491 13.9894 7.2374 13.9894C8.8899 13.9894 10.4766 13.342 11.6574 12.1859C12.2362 11.6166 12.6958 10.9379 13.0096 10.1891C13.3234 9.44042 13.485 8.63673 13.485 7.82492C13.485 7.01311 13.3234 6.20942 13.0096 5.46069C12.6958 4.71197 12.2362 4.03319 11.6574 3.46392C10.4766 2.30785 8.8899 1.66042 7.2374 1.66042C5.58491 1.66042 3.99819 2.30785 2.8174 3.46392C2.23873 4.03311 1.77911 4.71177 1.46535 5.46037C1.15159 6.20896 0.989955 7.01252 0.989861 7.82421C0.989768 8.6359 1.15122 9.43949 1.46481 10.1882C1.7784 10.9368 2.23786 11.6156 2.8164 12.1849L2.8174 12.1859Z"
                            fill="white"
                          />
                        </svg>
                        <input
                          placeholder="Search Resources"
                          value={search}
                          onChange={(e) => {
                            document.getElementById("nav-books-tab")?.click();
                            setSearch(e.target.value);
                          }}
                        />
                      </div>
                    </nav>
                    {isLoading && app.classroom.classroomId > 0 ? (
                      <CustomSkeleton columns={2} count={6} height={54} />
                    ) : (
                      <div className={`tab-content`} id="nav-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="nav-books"
                          role="tabpanel"
                          aria-labelledby="nav-books-tab"
                        >
                          <div
                            role="table"
                            className="classroom__tabs-content resources-section__tabs_content"
                          >
                            {(
                              search
                                ? searchResults.length
                                : has_books_access && filteredbooks.length
                            ) ? (
                              <>
                                {(filteredbooks || searchResults) &&
                                  (search ? searchResults : filteredbooks).map(
                                    (x: any, i: any) => {
                                      return (
                                        <>
                                          <div
                                            tabIndex={0}
                                            role="row"
                                            onClick={() => handleClickRecord(x)}
                                            onKeyDown={(e: any) => {
                                              if (e.key === "Enter") {
                                                handleClickRecord(x)
                                              }
                                            }}
                                            className="cursor-pointer classroom__tabs-contentRow"
                                          >
                                            <div
                                              role="cell"
                                              className={`classroom__tabs-contentPdf`}
                                            >
                                              {x.is_file ? (
                                                <img
                                                  src={
                                                    x.file_type === "ZIP"
                                                      ? ZipIcon
                                                      : PdfIcon
                                                  }
                                                  alt={
                                                    x.file_type === "ZIP"
                                                      ? "zip-icon........"
                                                      : "pdf-icon........"
                                                  }
                                                  className="zip-icon file-type-icon"
                                                />
                                              ) : (
                                                <img
                                                  src={wwwIcon}
                                                  alt="url"
                                                  className="file-type-icon"
                                                />
                                              )}
                                                <ResourceNameHeading name={x.filename.split("/").pop()}  />
                                            </div>
                                            <div
                                              role="cell"
                                              className={`classroom__tabs-contentText`}
                                            >
                                              <p>{x.description}</p>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </>
                            ) : (
                              <>
                                <div className="classroom__tabs-empty resources-section__tabs_content__empty_table">
                                  <p>
                                    {app.classroom.classroomId > 0
                                      ? !has_books_access
                                        ? "You don't have access to books resources."
                                        : "No resources found"
                                      : "Select a classroom above to view resources."}
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-assessments-16"
                          role="tabpanel"
                          aria-labelledby="nav-assessments-tab"
                        >
                          <div
                            role="table"
                            className="classroom__tabs-content resources-section__tabs_content"
                          >
                            {filteredassessments16.length ? (
                              <>
                                {!search &&
                                  filteredassessments16 &&
                                  filteredassessments16.map(
                                    (x: any, i: any) => {
                                      return (
                                        <>
                                          <div
                                            role="row"
                                            tabIndex={0}
                                            onClick={() => handleClickRecord(x)}
                                            onKeyDown={(e: any) => {
                                              if (e.key === "Enter") {
                                                handleClickRecord(x)
                                              }
                                            }}
                                            className={`cursor-pointer classroom__tabs-contentRow ${
                                              isOnlyResourcesClass &&
                                              userRole === "Classroom Admin"
                                                ? "disabled-resources"
                                                : ""
                                            }`}
                                          >
                                            <div
                                              role="cell"
                                              className={`classroom__tabs-contentPdf`}
                                            >
                                              {x.is_file ? (
                                                <img
                                                  src={
                                                    x.file_type === "ZIP"
                                                      ? ZipIcon
                                                      : PdfIcon
                                                  }
                                                  alt={
                                                    x.file_type === "ZIP"
                                                      ? "zip-icon........"
                                                      : "pdf-icon........"
                                                  }
                                                  className="zip-icon file-type-icon"
                                                />
                                              ) : (
                                                <img
                                                  src={wwwIcon}
                                                  alt="url"
                                                  className="file-type-icon"
                                                />
                                              )}
                                              <p>
                                                {x.filename.split("/").pop()}
                                              </p>
                                            </div>
                                            <div
                                              role="cell"
                                              className={`classroom__tabs-contentText`}
                                            >
                                              <p>{x.description}</p>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </>
                            ) : (
                              <>
                                <div className="classroom__tabs-empty resources-section__tabs_content__empty_table">
                                  <p>
                                    {isOnlyResourcesClass &&
                                    userRole === "Classroom Admin"
                                      ? ""
                                      : app.classroom.classroomId > 0
                                      ? "No resources found"
                                      : "Select a classroom above to view resources."}
                                  </p>
                                </div>
                              </>
                            )}
                            {isOnlyResourcesClass &&
                              userRole === "Classroom Admin" && (
                                <div className="permission tab-permission permission-resources-box">
                                  <div className="permission-text">
                                    <p>
                                      Your class doesn't have access to these
                                      resources. Please contact your school
                                      administrator and let them know you are
                                      interested in our assessment PDFs.
                                    </p>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-assessments-21"
                          role="tabpanel"
                          aria-labelledby="nav-assessments-tab"
                        >
                          <div
                            role="table"
                            className="classroom__tabs-content resources-section__tabs_content"
                          >
                            {filteredassessments21.length ? (
                              <>
                                {!search &&
                                  filteredassessments21 &&
                                  filteredassessments21.map(
                                    (x: any, i: any) => {
                                      return (
                                        <>
                                          <div
                                            role="row"
                                            tabIndex={0}
                                            onClick={() => handleClickRecord(x)}
                                            onKeyDown={(e: any) => {
                                              if (e.key === "Enter") {
                                                handleClickRecord(x)
                                              }
                                            }}
                                            className={`cursor-pointer classroom__tabs-contentRow ${
                                              isOnlyResourcesClass &&
                                              userRole === "Classroom Admin"
                                                ? "disabled-resources"
                                                : ""
                                            }`}
                                          >
                                            <div
                                              role="cell"
                                              className={`classroom__tabs-contentPdf`}
                                            >
                                              {x.is_file ? (
                                                <img
                                                  src={
                                                    x.file_type === "ZIP"
                                                      ? ZipIcon
                                                      : PdfIcon
                                                  }
                                                  alt={
                                                    x.file_type === "ZIP"
                                                      ? "zip-icon........"
                                                      : "pdf-icon........"
                                                  }
                                                  className="zip-icon file-type-icon"
                                                />
                                              ) : (
                                                <img
                                                  src={wwwIcon}
                                                  alt="url"
                                                  className="file-type-icon"
                                                />
                                              )}
                                              <p>
                                               <ResourceNameHeading  name={x.filename.split("/").pop()} />
                                              </p>
                                            </div>
                                            <div
                                              role="cell"
                                              className={`classroom__tabs-contentText`}
                                            >
                                              <p>{x.description}</p>
                                            </div>
                                          </div>
                                        </>
                                      );
                                    }
                                  )}
                              </>
                            ) : (
                              <>
                                <div className="classroom__tabs-empty resources-section__tabs_content__empty_table">
                                  <p>
                                    {isOnlyResourcesClass &&
                                    userRole === "Classroom Admin"
                                      ? ""
                                      : app.classroom.classroomId > 0
                                      ? "No resources found"
                                      : "Select a classroom above to view resources."}
                                  </p>
                                </div>
                              </>
                            )}
                            {isOnlyResourcesClass &&
                              userRole === "Classroom Admin" && (
                                <div className="permission tab-permission permission-resources-box">
                                  <div className="permission-text">
                                    <p>
                                      Your class doesn't have access to these
                                      resources. Please contact your school
                                      administrator and let them know you are
                                      interested in our assessment PDFs.
                                    </p>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-pd"
                          role="tabpanel"
                          aria-labelledby="nav-pd-tab"
                        >
                          <div
                            role="table"
                            className="classroom__tabs-content resources-section__tabs_content"
                          >
                            {filteredpd.length ? (
                              <>
                                {!search &&
                                  filteredpd &&
                                  filteredpd.map((x: any, i: any) => {
                                    return (
                                      <>
                                        <div
                                          role="row"
                                          tabIndex={0}
                                          onClick={() => handleClickRecord(x)}
                                          onKeyDown={(e: any) => {
                                            if (e.key === "Enter") {
                                              handleClickRecord(x)
                                            }
                                          }}
                                          className="cursor-pointer classroom__tabs-contentRow"
                                        >
                                          <div
                                            role="cell"
                                            className={`classroom__tabs-contentPdf`}
                                          >
                                            {x.is_file ? (
                                              <img
                                                src={
                                                  x.file_type === "ZIP"
                                                    ? ZipIcon
                                                    : PdfIcon
                                                }
                                                alt={
                                                  x.file_type === "ZIP"
                                                    ? "zip-icon........"
                                                    : "pdf-icon........"
                                                }
                                                className="zip-icon file-type-icon"
                                              />
                                            ) : (
                                              <img
                                                src={wwwIcon}
                                                alt="url"
                                                className="file-type-icon"
                                              />
                                            )}
                                            <ResourceNameHeading name = {x.filename.split("/").pop()} />
                                          </div>
                                          <div
                                            role="cell"
                                            className={`classroom__tabs-contentText`}
                                          >
                                            <p>{x.description}</p>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                              </>
                            ) : (
                              <>
                                <div className="classroom__tabs-empty resources-section__tabs_content__empty_table">
                                  <p>
                                    {app.classroom.classroomId > 0
                                      ? "No resources found"
                                      : "Select a classroom above to view resources."}
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-other"
                          role="tabpanel"
                          aria-labelledby="nav-other-tab"
                        >
                          <div
                            role="table"
                            className="classroom__tabs-content resources-section__tabs_content"
                          >
                            {has_books_access && filteredother.length ? (
                              <>
                                {!search &&
                                  filteredother &&
                                  filteredother.map((x: any, i: any) => {
                                    return (
                                      <>
                                        <div
                                          role="row"
                                          tabIndex={0}
                                          onClick={() => handleClickRecord(x)}
                                          onKeyDown={(e: any) => {
                                            if (e.key === "Enter") {
                                              handleClickRecord(x)
                                            }
                                          }}
                                          className="cursor-pointer classroom__tabs-contentRow"
                                        >
                                          <div
                                            role="cell"
                                            className={`classroom__tabs-contentPdf`}
                                          >
                                            {x.is_file ? (
                                              <img
                                                src={
                                                  x.file_type === "ZIP"
                                                    ? ZipIcon
                                                    : PdfIcon
                                                }
                                                alt={
                                                  x.file_type === "ZIP"
                                                    ? "zip-icon........"
                                                    : "pdf-icon........"
                                                }
                                                className="zip-icon file-type-icon"
                                              />
                                            ) : (
                                              <img
                                                src={wwwIcon}
                                                alt="url"
                                                className="file-type-icon"
                                              />
                                            )}
                                            <ResourceNameHeading name={x.filename.split("/").pop()} />
                                          </div>
                                          <div
                                            role="cell"
                                            className={`classroom__tabs-contentText`}
                                          >
                                            <p>{x.description}</p>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                              </>
                            ) : (
                              <>
                                <div className="classroom__tabs-empty resources-section__tabs_content__empty_table">
                                  <p>
                                    {!access_resources
                                      ? ""
                                      : app.classroom.classroomId > 0
                                      ? "No resources found"
                                      : "Select a classroom above to view resources."}
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {!access_resources && (
                    <div className="permission permission-resources-box">
                      <div className="permission-text">
                        <p>
                          Your school doesn’t have access to these resources.
                          Please contact your school administrator and let them
                          know you’re interested in our interactive textbooks
                          and PDFs.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resources;
