/* eslint-disable react-hooks/exhaustive-deps */

import React, { Fragment, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Warning from "assets/img/cms-warning-icon.svg";
import { WayToAReportContext } from "contexts/wayToAReport";
import moment from "moment";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import EmptyRecord from "components/common/EmptyRecord";

interface Props {
  summativeId?: number;
  formativeId?: number;
  onClose: () => void;
}

const RemediationModal: React.FC<Props> = ({onClose}) => {

  const {remediatedList, fetchRemediatedList} = useContext(WayToAReportContext);
  const {objectives, loading} = remediatedList;

  const closeModal = () => {
    onClose();
  };

  const onClickOk = () => {
    onClose();
  }
  
  useEffect(() => {
    fetchRemediatedList();
  },[])
  
  return (
    <>
      <Modal
        className="PacingCalender__AssessmentModel wayReport-modal assesment-remediated"
        centered
        show
        onEscapeKeyDown={closeModal}
      >
        <div>
          <div className="d-flex modal-header">
            <img
              alt=""
              className=""
              src={Warning}
            />
            <div>
              <h2 className="modal__header-title">Assessments Not Remediated</h2>
              <h4 className="header-desc">
                Students Below Were Non-Proficient and Did Not Complete Another Attempt
              </h4>
            </div>
          </div>

          {loading ? (
            <>
              <table>
                <tbody>
                  <TableSkeleton columns={4} count={5} height={30} />
                </tbody>
              </table>

              <table className="mb-2" style={{marginTop: "25px"}}>
                <tbody>
                  <TableSkeleton columns={4} count={5} height={30} />
                </tbody>
              </table>
            </>
          ):
            objectives.length > 0 ? 
              <div className="way-report-modal-table-wrapper">
                {objectives.map((item) => {
                  const {objective_number, objective_id, students} = item;
                  return (
                  
                    <div key={objective_id} className="overflow-auto rounded-2  PacingCalender__AssessmentModel__container">
                      <div className="rounded-2 PacingCalender__AssessmentModel__container__main">
                        <table className="<PacingCalender__AssessmentModel__container__table modal-table rounded-2">
                          <span className="report-No">{objective_number}</span>
                          <thead className="PacingCalender__AssessmentModel__container__table__head">
                            <tr className=" headrow overflow-hidden">
                              <td className="text-white fw-bold td1 text-right">
                                Student Last
                              </td>
                              <td className="text-white fw-bold td2">
                                First Name
                              </td>
                              <td className="text-white fw-bold td3">
                                Score
                              </td>
                              <td className="text-white fw-bold td4">
                                <p>
                                  Assessment Date
                                </p>
                              </td>
                            </tr>
                          </thead>
                          <tbody className="PacingCalender__AssessmentModel__container__table__body">
                            {students.length > 0 ? 
                              students.map((student) => {
                                const {first_name, last_name, id, assessment_date, score} = student;
                                const formattedDate = moment(assessment_date).format('M-D-YY')
                                return (
                                  <Fragment key={id}>
                                    <tr className="">
                                      <td className="text-white td1 text-right">
                                        {last_name}
                                      </td>
                                      <td className="text-white td2 ">
                                        {first_name}
                                      </td>
                                      <td className="text-white td3">
                                        {`${score}%`}
                                      </td>
                                      <td className="text-white td4">
                                        <p>
                                          {formattedDate}
                                        </p>
                                      </td>
                                    </tr>
                                  </Fragment>
                                )
                              })
                            :
                            <EmptyRecord message="No data available!"/>
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )
                })}
              </div>
              :
              <div
                style={{
                  height: 335,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <p
                  style={{
                    fontWeight: 'bold'
                  }}
                >
                  No data available!
                </p>
              </div>
          }
          <div className="text-right">
            <button onClick={onClickOk} type="button" className="okayBtn ms-auto">
              Okay
            </button>
          </div>
          
        </div>
      </Modal>
    </>
  );
};

export default RemediationModal;
