
import CustomModal from "components/common/Modal";
import { Button } from "components/common/buttonComponent";
import React, { useState } from "react";

interface DeleteConfirmationModalProps {
    loading?: boolean;
    submitButtonName?: string;
    cancelButtonName?: string;
    isShow: boolean;
    headerText: string;
    bodyText: string;
    onCancel: () => void;
    onSubmit: Function;
    type?: string;
}

export const NewAttemptModal = React.forwardRef<any, DeleteConfirmationModalProps>(({
    isShow,
    headerText,
    bodyText,
    onCancel,
    onSubmit,
    submitButtonName,
    cancelButtonName,
    type
  }, ref) => {
    const [loading, setLoading] = useState(false);

    const onConfirm = async () => {
      setLoading(true);
      onSubmit();
      setLoading(false);
    }

    return (
      <CustomModal centered show={isShow} onEscapeKeyDown={onCancel} className="attempt-assign-modal">
        <div ref={ref}>
          <div className="modal__header">
            <div className="yellow"></div>
            <h6 className="modal__header-title">{headerText}</h6>
          </div>
          <div className="modal__body">
            <p style={{...(type === 'success' && {fontSize: 15})}}>{bodyText}</p>
          </div>
          <div className="modal__footer">
            <Button
              buttonText={cancelButtonName || 'Cancel'}
              onClick={onCancel}
              className='btn cancel-btn'
              disabled={loading}
              type='button'
            />
            
            <Button
              buttonText={submitButtonName || 'Confirm'}
              onClick={onConfirm}
              className={type === 'success' ? 'btn success-btn' : 'btn danger-btn'}
              disabled={loading}
              loading={loading}
              type='button'
            />
          </div>
        </div>
      </CustomModal>
    );
});