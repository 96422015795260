export interface SuperAdminChatbox {
    assignChatToAdmin: Function;
    resolveChatFromReducer: Function;
    setChatById: Function;
    setUnreadMessagesCount: Function;
    setActiveChatsAvailable: Function;
    resolveChat: Function;
    fetchPreviousChatById: Function,
    fetchActiveChats: Function,
    fetchChatById: Function,
    putChat: Function,
    singleAdminChat: any,
    unsetChatById: Function,
    activeChats: ActiveChats,
    sendMessageSuperAdmin: Function;
    singleChatQuery: any;
    setChatQueryParams: Function;
    onStatusChange: Function;
    unsetSuperAdminChat: Function;
    videoOptions: any[];
    addAttachmentToTicket: Function;
    setGlobalStatus: Function;
    setSuperAdminChatMessage: Function,
    unsetSuperAdminChatMessage: Function
}

export interface ActiveChats {
    status: string,
    global_status: string,
    chats: Array<any>,
    assigned_chats: number,
    unassigned_chats: number,
    originalTicket: string;
    active_admins: any[]
}

export enum StatusIds {
    AVAILABLE = 1,
    BACK_IN_30_MINS = 2,
    BACK_IN_60_MINS = 3,
    UNAVAILABLE = 4
}

export interface globalStatusInterface {
    global_status: string;
    available: number;
}