import axios from "util/axios";
import config from "config/config";
import { GetTicketManagement, PutSupportTicketComment, PutSupportTicketStatus } from "interfaces/supportTicketSuperAdmin";


export const getAdmins = async (search: string) => {
  try {
    let { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/support/ticket/admins/search?search=${search}`)
    return data

  } catch (error) {
    console.log("Error in [getSupportTickets] : ", error)
    return null
  }
}

export const putSuperAdminChat = async (payload: any) => {
  const { chatId, ...body } = payload;
  try {
    let { data: { data } } = await axios.put(`${config.defaults.api_url}/api/v3/support/chat/${chatId}`, body)
    return data

  } catch (error) {
    console.log("Error in [getSupportTickets] : ", error)
    return null
  }
}

export const getSuperAdminChatById = async (payload: any) => {
  const { offset, limit, chatId } = payload;
  try {
    let { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/support/chat/${chatId}?limit=${limit}&offset=${offset}`)
    return data

  } catch (error) {
    console.log("Error in [getSupportTickets] : ", error)
    return null
  }
}

export const getSuperAdmins = async (superAdminQuery: any) => {
  const { offset, limit, order_by, sort_by, search, for_ticket } = superAdminQuery;
  try {
    let { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/admin/super-admins?limit=${limit}&offset=${offset}${order_by ? `&order_by=${order_by}` : ''}${sort_by ? `&sort_by=${sort_by}` : ''}${search ? `&search=${search}` : ''}${for_ticket ? `&for_ticket=${for_ticket}` : ''}`)
    return data

  } catch (error) {
    console.log("Error in [getSupportTickets] : ", error)
    return null
  }
}

export const getActiveChats = async (markRead: boolean=true) => {
  try {
    let { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/support/chat/active?mark_read=${markRead}`)
    return data

  } catch (error) {
    console.log("Error in [getSupportTickets] : ", error)
    return null
  }
}

export const getSupportChat = async (payload: GetTicketManagement) => {
  const { limit, offset, search, assigneeId, status, sort_by, order_by, admin_created_id } = payload;
  try {
    let { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/support/chat/management?limit=${limit}&offset=${offset}${assigneeId ? `&assignee_id=${assigneeId}` : ''}${search ? `&search=${search}` : ''}${status ? `&status=${status}` : ''}${sort_by ? `&sort_by=${sort_by}` : ''}${order_by ? `&order_by=${order_by}` : ''}${admin_created_id ? `&admin_created_id=${admin_created_id}` : ''}`)
    return data

  } catch (error) {
    console.log("Error in [getSupportTickets] : ", error)
    return null
  }
}

export const getTicketDetails = async (ticketId: string) => {
  try {
    let { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/support/ticket/${ticketId}`)
    return data

  } catch (error) {
    console.log("Error in [getSupportTickets] : ", error)
    return null
  }
}

export const getSupportTicketManagement = async (payload: GetTicketManagement) => {
  const { limit, offset, search, assigneeId, status, sort_by, order_by, created_by } = payload;
  try {
    let { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/support/ticket/management?limit=${limit}&&offset=${offset}${assigneeId ? `&assignee_id=${assigneeId}` : ''}${search ? `&search=${search}` : ''}${status ? `&status=${status}` : ''}${sort_by ? `&sort_by=${sort_by}` : ''}${order_by ? `&order_by=${order_by}` : ''}${created_by ? `&created_by=${created_by}` : ''}`) 
    return data

  } catch (error) {
    console.log("Error in [getSupportTickets] : ", error)
    return null
  }
}

export const putSupportTicketManagementStatus = async (payload: PutSupportTicketStatus) => {
  const { ticketId, ...body } = payload;

  try {
    let { data: { data } } = await axios.put(`${config.defaults.api_url}/api/v3/support/ticket/${ticketId}/status`, body)
    return data

  } catch (error) {
    console.log("Error in [getSupportTickets] : ", error)
    return null
  }
}

export const getSupportTicketStats = async (filter?: string) => {
  try {
    let { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/support/ticket/management/stats${filter ? `?filter=${filter}` : ''}`)
    return data

  } catch (error) {
    console.log("Error in [getSupportTickets] : ", error)
    return null
  }
}

export const putSupportTicketManagementComment = async (payload: PutSupportTicketComment) => {
  const { ticketId, ...body } = payload;

  try {
    let { data: { data } } = await axios.put(`${config.defaults.api_url}/api/v3/support/ticket/${ticketId}/comment`, body)
    return data

  } catch (error) {
    console.log("Error in [getSupportTickets] : ", error)
    return null
  }
}

export const getSupportTicketManagementComment = async (payload: any) => {
  const {ticketId, limit, offset, search} = payload
  try {
    let { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/support/ticket/${ticketId}/comments?offset=${offset}&limit=${limit}${search ? `&search=${search}` : ''}`)
    return data

  } catch (error) {
    console.log("Error in [getSupportTickets] : ", error)
    return null
  }
}

