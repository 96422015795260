import { initialState } from "contexts/supportTicketSuperAdmin"
import { SupportTicketSuperAdminContextProvider } from "interfaces/supportTicketSuperAdmin"

export enum SupportTicketSuperAdminActions {
    SET_SUPPORT_TICKETS = 'SET_SUPPORT_TICKETS',
    UNSET_SUPPORT_TICKETS = 'UNSET_SUPPORT_TICKETS',
    SET_SUPPORT_CHAT = 'SET_SUPPORT_CHAT',
    UNSET_SUPPORT_CHAT = 'UNSET_SUPPORT_CHAT',
    SET_SUPPORT_TICKET_STATS = 'SET_SUPPORT_TICKET_STATS',
    UNSET_SUPPORT_TICKET_STATS = 'UNSET_SUPPORT_TICKET_STATS',
    SET_TICKET_DETAILS = 'SET_TICKET_DETAILS',
    UNSET_TICKET_DETAILS = 'UNSET_TICKET_DETAILS',
    SET_SUPPORT_MANAGEMENT_STATUS = 'SET_SUPPORT_MANAGEMENT_STATUS',
    SET_SUPPORT_MANAGEMENT_COMMENT = 'SET_SUPPORT_MANAGEMENT_COMMENT',
    SET_ACTIVE_CHATS = 'SET_ACTIVE_CHATS',
    SET_SUPER_ADMINS = 'SET_SUPER_ADMINS',
    SET_CHAT_BY_ID = 'SET_CHAT_BY_ID',
    UNSET_CHAT_BY_ID = 'UNSET_CHAT_BY_ID',
    SET_SUPER_ADMIN_CHAT = 'PUT_SUPER_ADMIN_CHAT',
    SET_SUPER_ADMIN_CHAT_MESSAGE = 'SET_SUPER_ADMIN_CHAT_MESSAGE',
    SET_ADMINS = 'SET_ADMINS',
    UNSET_ADMINS = 'UNSET_ADMINS',
    SET_SUPPORT_TICKETS_LOADING = 'SET_SUPPORT_TICKETS_LOADING',
    SET_SUPPORT_CHAT_LOADING = 'SET_SUPPORT_CHAT_LOADING',
    SET_TICKET_DETAILS_LOADING = 'SET_TICKET_DETAILS_LOADING',
    SET_SUPPORT_TICKET_STATS_LOADING = 'SET_SUPPORT_TICKET_STATS_LOADING',
    SET_COMMENT_LOADING = 'SET_COMMENT_LOADING',
    SET_TICKET_DETAILS_MESSAGES = 'SET_TICKET_DETAILS_MESSAGES',
    SET_MESSAGES_LOADING = 'SET_MESSAGES_LOADING'
}


export const SupportTicketSuperAdminReducer = (state: SupportTicketSuperAdminContextProvider, action: any) => {
    const { type, payload } = action
    switch (type) {
        case SupportTicketSuperAdminActions.SET_MESSAGES_LOADING:
            return {
                ...state,
                messagesLoading: payload
            }
        case SupportTicketSuperAdminActions.SET_TICKET_DETAILS_MESSAGES:
            return {
                ...state,
                ticketDetails: {
                    ...state.ticketDetails,
                    messages: payload.messages,
                    message_count: payload.count
                }
            }
        case SupportTicketSuperAdminActions.SET_COMMENT_LOADING:
            return {
                ...state,
                commentLoading: payload
            }
        case SupportTicketSuperAdminActions.SET_SUPPORT_TICKETS:
            return {
                ...state,
                supportTickets: {
                    loading: false,
                    ...payload
                }
            }
        case SupportTicketSuperAdminActions.SET_SUPPORT_TICKETS_LOADING:
            return {
                ...state,
                supportTickets: {
                    ...state.supportTickets,
                    loading: payload
                }
            }
        case SupportTicketSuperAdminActions.UNSET_SUPPORT_TICKETS:
            return {
                ...state,
                supportTickets: initialState.supportTickets
            }
        case SupportTicketSuperAdminActions.SET_SUPPORT_CHAT_LOADING:
            return {
                ...state,
                supportChat: {
                    ...state.supportChat,
                    loading: payload
                }
            }
        case SupportTicketSuperAdminActions.SET_SUPPORT_CHAT:
            return {
                ...state,
                supportChat: {
                    ...payload,
                    loading: false
                }
            }
        case SupportTicketSuperAdminActions.UNSET_SUPPORT_CHAT:
            return {
                ...state,
                supportChat: initialState.supportChat
            }
        case SupportTicketSuperAdminActions.SET_SUPPORT_TICKET_STATS_LOADING:
            return {
                ...state,
                supportTicketStats: {
                    ...state.supportTicketStats,
                    loading: payload
                }
            }
        case SupportTicketSuperAdminActions.SET_SUPPORT_TICKET_STATS:
            return {
                ...state,
                supportTicketStats: {
                    ...payload,
                    loading: false
                }
            }
        case SupportTicketSuperAdminActions.UNSET_SUPPORT_TICKET_STATS:
            return {
                ...state,
                supportTicketStats: initialState.supportTicketStats
            }
        case SupportTicketSuperAdminActions.SET_TICKET_DETAILS_LOADING:
            return {
                ...state,
                ticketDetails: {
                    ...state.ticketDetails,
                    loading: true
                }
            }
        case SupportTicketSuperAdminActions.SET_TICKET_DETAILS:
            return {
                ...state,
                ticketDetails: {
                    ...payload,
                    loading: false
                }
            }
        case SupportTicketSuperAdminActions.UNSET_TICKET_DETAILS:
            return {
                ...state,
                ticketDetails: initialState.ticketDetails
            }
        case SupportTicketSuperAdminActions.SET_SUPPORT_MANAGEMENT_STATUS:
            return {
                ...state,
                ticketDetails: payload
            }
        case SupportTicketSuperAdminActions.SET_SUPPORT_MANAGEMENT_COMMENT:
            return {
                ...state,
                ticketDetails: {
                    ...state.ticketDetails,
                    comment_count: payload.count,
                    comments: payload.comments
                }
            }
        case SupportTicketSuperAdminActions.SET_ACTIVE_CHATS:
            return {
                ...state,
                activeChats: payload
            }
        case SupportTicketSuperAdminActions.SET_SUPER_ADMINS:
            return {
                ...state,
                superAdmins: {
                    ...payload.superAdmins,
                    super_admins: payload.superAdmins.super_admins.map((admin:any) => {
                        const {first_name, last_name, id} = admin;
                        let fullName = `${first_name}${last_name ? ` ${last_name}` : ''}`;
                        if (payload.for_ticket) {
                            fullName = `${last_name}, ${first_name ? ` ${first_name}` : ''}`;
                        }
                    
                        return {
                            ...admin, 
                            label: fullName,
                            value: id
                        }
                    })
                }
            }
        case SupportTicketSuperAdminActions.SET_SUPER_ADMIN_CHAT:
            return {
                ...state,
                activeChats: {
                    ...state.activeChats,
                    chats: state.activeChats.chats.map((chat: any) => {
                        return chat.id === payload.id
                        ? {...payload, }
                        : chat
                    })
                }
            }
        case SupportTicketSuperAdminActions.SET_CHAT_BY_ID:
            return {
                ...state,
                singleAdminChat: payload
            }
        case SupportTicketSuperAdminActions.UNSET_CHAT_BY_ID:
            return {
                ...state,
                singleAdminChat: initialState.singleAdminChat
            }
        case SupportTicketSuperAdminActions.SET_SUPER_ADMIN_CHAT_MESSAGE:
            return {
                ...state,
                singleAdminChat: payload
            }
        case SupportTicketSuperAdminActions.SET_ADMINS:
            return {
                ...state,
                admins: payload
            }
        case SupportTicketSuperAdminActions.UNSET_ADMINS:
            return {
                ...state,
                admins: initialState.admins
            }
        default:
            return state
    }
}
