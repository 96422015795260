import React from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { useSkeletonColors } from "hooks/useThemeColor";

export const CustomSkeleton: React.FC<{columns: number; count: number; height: number;}> = ({
    columns,
    count,
    height,
}) => {
    const {baseColor, highlightColor} = useSkeletonColors();
    return (
        <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor} width={'100%'}>
            <table>
                <tbody>
                    <tr>
                        {[...new Array(columns)].map((item: any, i:number) => {
                            return (
                                <td key={i} style={{padding: '0px 1px 0px 0px'}}>
                                    <Skeleton count={count} height={height} duration={2} borderRadius={0} inline={true} />
                                </td>
                            )
                        })}
                    </tr>
                </tbody>
            </table>
        </SkeletonTheme>
    );
};

export const TableSkeleton: React.FC<{columns: number; count: number; height: number; firstColumnWidth?: number; baseColor?: string; highlightColor?: string}> = ({
    columns,
    count,
    height,
    firstColumnWidth,
}) => {
    const {baseColor, highlightColor} = useSkeletonColors();
    return (
        <SkeletonTheme baseColor={baseColor || "#a4a4a4"} highlightColor={highlightColor || "#c4c4c4"} width={'100%'}>
            <tr>
                {[...new Array(columns)].map((item: any, i:number) => {
                    return (
                        <td 
                            key={i} 
                            className="skeleton-td"
                            style={{
                                padding: '0px 1px 0px 0px',
                                width: firstColumnWidth && i === 0 ? firstColumnWidth : ''
                            }} 
                        >
                            <Skeleton count={count} height={height} duration={2} borderRadius={0} inline={true} />
                        </td>
                    )
                })}
            </tr>
        </SkeletonTheme>
    );
};

// Skeleton for Progress Table Label
export const TableLabelSekelton = () => {
    return (
        <SkeletonTheme baseColor="#2a5391" highlightColor="#2a5391" width={'100%'}>
            <tr className="progress__table-badge" style={{height: '100%', zIndex: '99'}}>
                <td className="progress__table-badgeContent" style={{height: '100%'}}>
                <Skeleton count={1} height="100%" duration={0} enableAnimation={false} borderRadius={0} inline={true} />
                </td>
            </tr>
        </SkeletonTheme>
    )
}
