/* eslint-disable react-hooks/exhaustive-deps */

import CustomModal from "components/common/Modal";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { logout } from "redux/actionCreators/auth";

interface Props {
    setModalShow: Function;
}

const LogoutModal: React.FC<Props> = ({setModalShow}) => {

  const dispatch = useDispatch();

  const [loading, setIsLoading] = useState(false);
  // const [isSaving] = useState(false);

  // const handleSave = async () => {
  //   clearPacingCalenderAssessments('all');
  // };

  const closeModal = () => {
    setIsLoading(false);
    setModalShow(false)
  };

  const onClickClear = async () => {
    setIsLoading(true);
    dispatch(logout(true));
    setIsLoading(false);
  }

  return (
    <>
      <CustomModal centered show onEscapeKeyDown={closeModal}>
        <div className="PacingCalender__DeleteModel">
          <div>
            <h2 className="modal__header-title px-2">Active Chat Sessions</h2>
          </div>
        </div>
        <div>
          <p className="test-sm  px-2 mt-3">
            There are active chat sessions assigned to you. Logging out will close these sessions. Are you sure you want to do this?
          </p>
        </div>
        <div className="modal__footer  justify-content-end flex-wrap">
          <button
            type="button"
            className="btn cancel-btn PacingCalender__DeleteModel__cancelBtn"
            data-bs-dismiss="modal"
            onClick={() => setModalShow(false)}
          >
            Cancel
          </button>
          <div>
            <button
              type="button"
              className="btn PacingCalender__DeleteModel__clearMonth"
              data-bs-dismiss="modal"
              onClick={onClickClear}
              disabled={loading}
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Yes'
              )}
              
            </button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default LogoutModal;
