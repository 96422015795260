const EmptyRecord = (
  { message, withoutBackground }: any = { withoutBackground: false }
) => {
  return (
    <tr>
      <td
        colSpan={10}
        className="notfound__data"
        style={{
          ...(withoutBackground && { backgroundColor: "transparent" }),
          fontWeight: "bold",
        }}
      >
        <div className={`notfound__data-container`}>
          <p className="text-center mb-0">{message || "Nothing foud"}</p>
        </div>
      </td>
    </tr>
  );
};
export default EmptyRecord;

export const SupportTicketEmptyRecord = ({
  text,
  height,
  backgroundColor,
}: any) => {
  return (
    <div
      style={{
        height: height || 200,
        backgroundColor: backgroundColor || "#496faa",
        borderRadius: "8px",
      }}
      className="classroom__tabs-empty mx-auto"
    >
      <p>{text || "No record found."}</p>
    </div>
  );
};

export const EmptyRecordPdAdmin = ({ text }: any) => {
  return (
    <div className="empty-pdAdmin classroom__tabs-empty mx-auto">
      <p>{text || "No record found."}</p>
    </div>
  );
};

export const EmptyRecordMasteryVideo = (
  { onClick, message, withoutBackground }: any = { withoutBackground: false }
) => {
  return (
    <tr>
      <td
        colSpan={10}
        className="notfound__data mastery-video-noComments"
        style={{ ...(withoutBackground && { backgroundColor: "transparent" }) }}
      >
        <div className={`notfound__data-container`}>
          <div className="newComment"></div>
          {message ? (
            <p className="messageText">{message}</p>
          ) : (
            <p className="messageText">
              Start the discussion! Post a{" "}
              <span onClick={onClick} style={{ cursor: "pointer" }}>
                New Comment.
              </span>
            </p>
          )}
        </div>
      </td>
    </tr>
  );
};
