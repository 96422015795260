const GradeAndSubjectDropdown = ({disabled, label, options, handleOnChange, selectedValue}: any) => {
  return (
    <div className="modal__form-row">
        <select
            tabIndex={0}
            className="form-select welcome-edit-select customFieldsFocused-active"
            value={selectedValue}
            onChange={(e) => handleOnChange(e.target.value)}
            placeholder=""
            disabled={disabled}
            style={{backgroundColor: '#fff', color: disabled ? '#919191' : '#000'}}
        >
        <option value={-1} disabled>
            {label}
        </option>
        {options
            ? options.map((item: any, i: number) => {
                return (
                <option key={i} value={i}>
                    {item.name}
                </option>
                );
            })
            : ""}
        </select>
    </div>
  )
};

export default GradeAndSubjectDropdown;
