import React, { useEffect } from "react";
import IPage from "interfaces/page";
import logging from "config/logging";
import Theme from "components/theme/index";
import EditObjectives from "components/edit-objectives/edit-objectives";
import cookie from "js-cookie";
import { AppCookies } from "util/constants";

const Objectives: React.FunctionComponent<IPage> = (props) => {
  const userRole = cookie.get(AppCookies.role);

  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props.name]);

  return (
    <>
      {userRole === "Super Admin" && (
        <Theme>
          <div className="management-st-wrap management-accordian text-white manage-objective-container">
            <EditObjectives />
          </div>
        </Theme>
      )}
    </>
  );
};

export default Objectives;
