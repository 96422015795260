import { FC } from "react"
import moment from "moment";


interface Props {
    class_name?: string,
    test_name?: string,
    title: string,
    customClass?: string,
    isWayToReport?: boolean;
    school_name?: string;
}

const Header: FC<Props> = (props) => {
    const { isWayToReport, class_name, school_name, title, customClass } = props

    return (
        <div className="print-report__header">
            <div className="d-flex align-items-end justify-content-between">
                <div>
                    <h3 className={`way-report-title-print title ${customClass}`}>{title}</h3>
                    <p className={`print-report__header-text ${customClass}`}>{moment(new Date()).format("M-D-YY")}</p>
                    {/* {title !== "Audit Report" && <p className={`print-report__header-text ${customClass}`}>{test_name || ""}</p>} */}
                </div>

                <div className="text-right">
                    {!isWayToReport && <h3 className={`title ${customClass}`}>{title !== "Audit Report" ? class_name : moment(new Date()).format("HH:mm A")}</h3>}
                    <p className={`print-report__header-text text-right ${customClass}`}>{school_name || ''}</p>
                </div>
            </div>
        </div>
    )
}

export default Header;