import React from "react";

const DownArrow = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={13}
    height={8}
    fill="none"
  >
    <path
      stroke="#303030"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m1 1 5.5 5.5L12 1"
    />
  </svg>
  );
};

export default DownArrow;
