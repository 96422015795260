import { WayToAReportContextProvider } from "interfaces/wayToAReport"
import { createContext, useCallback, useEffect, useReducer, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom";
import { getRemediatedList, getWayToAReport, getGoalsList } from "services/wayToAReport";
import { WayToAReportReducer, WayToAReportActions } from "./reducers/wayToAReport"
import { GOALS_CONDITIONS } from "util/constants";
import { triggerGoalWithClassroom } from "services/common";

export const initialState: WayToAReportContextProvider = {
    wayToAReport: {
        month: '',
        assessment_schedule: null!,
        formative_progress: null!,
        goal_completion: null!,
        remediation: null!,
        running_proficiency: null!,
        loading: true
    },
    remediatedList: {
        objectives: [],
        loading: true
    },
    fetchRemediatedList: null!,
    goalsList: {
        month: '',
        year: '',
        week_goals: [],
        recurring_week_goals: [],
        week_goals_available: [],
        week_goals_upcoming: []
    },
    fetchGoalsList: null!,
    apiErrorModal: false,
    setApiErrorModal: null!
}

export const WayToAReportContext = createContext<WayToAReportContextProvider>(null!)

const WayToAReportProvider = ({ children }: any) => {
    const [{ wayToAReport, remediatedList, goalsList }, dispatch] = useReducer(WayToAReportReducer, initialState);
    const [apiErrorModal, setApiErrorModal] = useState(false);

    const {id} = useParams();
    const [searchParams] = useSearchParams();

    const setWayToAReportLoading = (loading: boolean) => {
        dispatch({
            type: WayToAReportActions.SET_WAY_TO_A_REPORT_LOADING,
            payload: loading
        });
    }

    const fetchWayToAReport = useCallback(async (search: string) => {

        setWayToAReportLoading(true)

        const report = await getWayToAReport(search);

        setWayToAReportLoading(false)

        if(report){
            dispatch({
                type: WayToAReportActions.SET_WAY_TO_A_REPORT,
                payload: report
            });
            const taskId = searchParams.get("task_id");
            const goalId = searchParams.get("goal_id");

            if(taskId && goalId){
                triggerGoalWithClassroom({task_id: parseInt(taskId), goal_id: parseInt(goalId), classroomId: id, conditionName: GOALS_CONDITIONS.Open_W2A_Report})
            }
        }

        console.log('report', report);
        if(!report){
            setApiErrorModal(true);
        }

        return report;

        /* eslint-disable react-hooks/exhaustive-deps */
    },[]);

    const setRemediatedListLoading = (loading: boolean) => {
        dispatch({
            type: WayToAReportActions.SET_REMEDIATED_LIST_LOADING,
            payload: loading
        });
    }

    const fetchRemediatedList = async () => {
        if(!id){
            return;
        }
        
        setRemediatedListLoading(true)

        const report = await getRemediatedList(id);

        setRemediatedListLoading(false)

        report && dispatch({
            type: WayToAReportActions.SET_REMEDIATED_LIST,
            payload: report
        });

        return report;
    };


    const fetchGoalsList = useCallback(async (search: string) => {

        // setWayToAReportLoading(true)

        const goalsList = await getGoalsList(search);

        // setWayToAReportLoading(false)

        goalsList && dispatch({
            type: WayToAReportActions.GET_GOALS_LIST,
            payload: goalsList
        });

        return goalsList;
    },[]);

    
    useEffect(() => {
        if(!id){
            return;
        }

        fetchWayToAReport(id);

    }, [id, fetchWayToAReport])

    return <WayToAReportContext.Provider value={{
        remediatedList,
        wayToAReport,
        fetchRemediatedList,
        goalsList,
        fetchGoalsList,
        apiErrorModal,
        setApiErrorModal
    }}>
        {children}
    </WayToAReportContext.Provider>
}
export default WayToAReportProvider