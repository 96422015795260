import axios from "axios"
import config from "config/config";


export const reEnableTest = async ({formative_id, classroom_id, student_id}: any) => {
    try {
        const { data: { data } } = await axios.put(`${config.defaults.api_url}/api/v3/classroom/monitor/formative/re-enable-test`, {classroom_id, formative_id, student_id});
        return data

    } catch (error) {
        return null
    }
}

export const triggerProfessionalDevelopmentGoal = async ({goalId, taskId, classroomId, actionName}:{goalId: number, taskId: number, classroomId: number, actionName: string}) => {
    try {
        const { data: { data } } = await axios.put(`${config.defaults.api_url}/api/v3/pd/goals/${goalId}/task/${taskId}`, {classroom_id: classroomId, action_name: actionName});
        return data

    } catch (error) {
        return null
    }
}

export const updateProfessionalDevelopmentGoal = async ({goalId, ...payload}:{goalId: number, instructional_text: string, goal: string}) => {
    try {
        let { data: { data } } = await axios.put(
            `${config.defaults.api_url}/api/v3/pd/goals/${goalId}`,
            payload
        );
        return data;
    } catch (error) {
        return null
    }
}

export const getPdAdminSelectedGoal = async (id: number, grade_id: any, subject_id: any) => {
    try {
        const { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/pd/goals/${id}?grade_id=${grade_id}&subject_id?=${subject_id}`);
        return data

    } catch (error) {
        return null
    }
}

export const getSelectedGoal = async (id: number, classroomId: any) => {
    try {
        const { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/pd/classroom/${classroomId}/goals/${id}`);
        return data

    } catch (error) {
        return null
    }
}

export const markCompleted = async (payload: any) => {
    const {classroom_id} = payload;
    try {
        let { data: { data } } = await axios.put(`${config.defaults.api_url}/api/v3/pacing-calendar/classroom/${classroom_id}`, payload)
        return data

    } catch (error) {
        return null
    }

}

export const getAllAssessments = async (classroomId: number) => {
    try {

        let { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/classroom/${classroomId}/assessments`)
        return data

    } catch (error) {
        console.log('getAllAssessments : ', error)
        return null
    }
}

export const getAllFormatives = async (schoolId: number, classroomId: number, order_by?: string) => {
    try {

        let { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/classroom/formatives?school_id=${schoolId}&classroom_id=${classroomId}${order_by ? `&order_by=${order_by}` : ''}`)
        return data

    } catch (error) {
        console.log('getAllFormatives : ', error)
        return null
    }
}

export const getAllSummatives = async (schoolId: number, classroomId: number) => {
    try {

        let { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/classroom/summatives?school_id=${schoolId}&classroom_id=${classroomId}`)
        return data

    } catch (error) {
        console.log('getAllSummatives : ', error)
        return null
    }
}

export const getProfessionalDevelopmentClassroom = async (classroomId: number) => {
    try {
        const { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/pd/classroom/${classroomId}?classroom_id=${classroomId}`)
        return data
    } catch (error) {
        console.log('getProfessionalDevelopmentClassroom : ', error)
        return null
    }
}

export const triggerAdvanceWeek = async (classroomId: number) => {
    try {
        const { data: { data } } = await axios.put(`${config.defaults.api_url}/api/v3/pd/classroom/${classroomId}`);
        return data;

    } catch (error) {
        return null;
    }
}

export const getProfessionalDevelopmentClassroomTillDate = async (classroomId: number, month: any, days: any) => {
    try {
        const { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/pd/classroom/${classroomId}/goals?month=${month}&days=${days}`);
        // let data = await axios.get('/week_days.json');
        // data = data.data;
        return data
    } catch (error) {
        console.log('getProfessionalDevelopmentClassroomTillDate : ', error)
        return null
    }
}

export const getTeamMeetingData = async (classroomId: number) => {
    try {
        const { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/classroom/${classroomId}`);
        return data
    } catch (error: any) {
        console.log('team meeting data error: ', error?.response?.data?.error || '')
        return null
    }
}

export const getRemmediationReportData = async (classroomId: number, objectiveId: number) => {
    try {
        const { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/classroom/${classroomId}/objective/${objectiveId}/remediation`);
        return data
    } catch (error: any) {
        console.log('team meeting data error: ', error?.response?.data?.error || '')
        return null
    }
}

export const getFormativeRemediationData = async (classroomId: number, formativeId: number) => {
    try {
        const { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/classroom/${classroomId}/formative/${formativeId}/students`);
        return data
    } catch (error: any) {
        console.log('team meeting data error: ', error?.response?.data?.error || '')
        return null
    }
}

export const getAssessmentMonitorData = async (classroomId: any) => {
    try {
        const { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/classroom/${classroomId}/assessment`);
        return data

    } catch (error) {
        return null
    }
}