import { Quill } from "react-quill";
import axios from 'util/axios'
import config from "config/config";

const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

async function saveToServer(image) {
  // const fd = new FormData();
  // fd.append("file", image);

  console.log({ image })
  const signedResponse = await axios
    .post(`${config.defaults.api_url}/api/v3/cms/signed/url`, {
      "file_name": image.name,
      "file_type": image.type
    })

  const signedUrl = signedResponse.data?.data.url

  if (!signedUrl)
    return

  console.log({ signedUrl })
  fetch(signedUrl, {
    method: 'PUT',
    body: image
  }).then((res) => {
    // DO WHATEVER YOU WANT
    console.log('res', res)
  })
}

const imageHandler = (a) => {
  const input = document.createElement("input");
  input.setAttribute("type", "file");
  input.setAttribute("accept", "image/*");
  input.click();

  input.onchange = () => {
    const file = input.files[0];

    // file type is only image.
    if (/^image\//.test(file.type)) {
      console.log({ file })
      saveToServer(file);
    } else {
      console.warn("You could only upload images.");
    }
  };
};

const Size = Quill.import("attributors/style/size");
Size.whitelist = ["10px", "14px", "16px", "18px", '20px', '32px'];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import("formats/font");
Font.whitelist = [
  `"Nunito", sans-serif`
];
Quill.register(Font, true);

export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      undo: undoChange,
      redo: redoChange,
      image: imageHandler
    }
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};

export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block"
];

// Quill Toolbar component
export const QuillToolbar = (props) => (
  <div id={props.toolbarID || 'toolbar'} style={{ opacity : `${props.readOnly ?  0.56 : 1}`}}>
    <span className="ql-formats">
      {/* <select className="ql-font" defaultValue={`"Nunito", sans-serif`}>
        <option value={`"Nunito", sans-serif`}>Nunito</option>
        <option value="arial">Arial</option>
        <option value="comic-sans">Comic Sans</option>
        <option value="courier-new">Courier New</option>
        <option value="georgia">Georgia</option>
        <option value="helvetica">Helvetica</option>
        <option value="lucida">Lucida</option>
      </select> */}
      <select className="ql-size">
        <option value="10px">10px</option>
        <option value="14px">14px</option>
        <option value="16px">16px</option>
        <option value="18px">18px</option>
        <option value="20px">20px</option>
        <option value="32px">32px</option>
      </select>
      <select className="ql-header" defaultValue="3">
        <option value="1">Heading</option>
        <option value="2">Subheading</option>
        <option value="3">Normal</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
    </span>
    <span className="ql-formats">
      <button className="ql-script" value="super" />
      <button className="ql-script" value="sub" />
      <button className="ql-blockquote" />
      <button className="ql-direction" />
    </span>
    <span className="ql-formats">
      <select className="ql-align" />
      <select className="ql-color" >
        <option value="#000000" />
        <option value="#e60000" />
        <option value="#ff9900" />
        <option value="#ffff00" />
        <option value="#e60000" />
        <option value="##0066cc" />
        <option value="#9933ff" />
        <option value="#ffffff" />
        <option value="#facccc" />
        <option value="#ffebcc" />
        <option value="#cce8cc" />
        <option value="#cce0f5" />
        <option value="#ebd6ff" />
        <option value="#bbbbbb" />
        <option value="#f06666" />
        <option value="#ffc266" />
        <option value="#ffff66" />
        <option value="#66b966" />
        <option value="#66a3e0" />
        <option value="#c285ff" />
        <option value="#888888" />
        <option value="#a10000" />
        <option value="#b26b00" />
        <option value="#b2b200" />
        <option value="#006100" />
        <option value="#0047b2" />
        <option value="#6b24b2" />
        <option value="#444444" />
        <option value="#5c0000" />
        <option value="#663d00" />
        <option value="#666600" />
        <option value="#003700" />
        <option value="#002966" />
        <option value="#3d1466" />
        <option value="#e60000" />
      </select>
      <select className="ql-background" />
    </span>
    <span className="ql-formats">
      <button className="ql-link" />
      <button className="ql-image" />
      <button className="ql-table" />
      {/* <button className="ql-video" /> */}
    </span>
    <span className="ql-formats">
      <button className="ql-formula" />
      <button className="ql-code-block" />
      <button className="ql-clean" />
    </span>
    <span className="ql-formats">
      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-redo">
        <CustomRedo />
      </button>
    </span>
  </div>
);

export default QuillToolbar;

export const QuillThisWeekToolbar = () => (
  <div id="toolbar-container">
    <span className="ql-formats">
      <select className="ql-size">
        <option value="10px">10px</option>
        <option value="14px">14px</option>
        <option value="16px">16px</option>
        <option value="18px">18px</option>
        <option value="20px">20px</option>
        <option value="32px">32px</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-link" />
      <select className="ql-color" />
    </span>
  </div>
);