/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { Button } from "../../common/buttonComponent";
import ToolTip from "components/common/Tooltip";
import CustomModal from "components/common/Modal";

interface SelectTestTypeModalProps {
  isShow: boolean;
  onClose: () => void;
  onSave: (x: any, y: any) => void;
  currentData: any;
  loading: boolean;
  handleOnChange: (checked: boolean, classroom_id: number) => void;
}

export const CopyAssessmentTransferModal: React.FC<
  SelectTestTypeModalProps
> = ({ isShow, onClose, onSave, currentData, loading, handleOnChange }) => {
  const onSubmit = (bindOldData: any) => {
    onSave({ ...currentData }, bindOldData);
  };

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={onClose}>
      <div className="modal__header">
        <h6 className="modal__header-title">
          {`Transfer Student and Unenroll`}{" "}
        </h6>
      </div>
      <div className="modal__body">
        <p>
          {`${currentData?.student_name} is currently enrolled in another class of the same grade and subject. The student will be unenrolled from that class. Do you want to transfer the student's assessment data?`}
        </p>
        <div className="edit__student-copy-unenroll-checks">
          <p>From Classroom</p>
        </div>

        <div className="edit__student-copy-unenroll-content edit__student-copy-unenroll-corners">
          <ul className="edit__student-copy-unenroll-list">
            {currentData?.classrooms?.map((x: any, i: number) => {
              return (
                <>
                  <li>
                    <div
                      className={`edit__student-copy-unenroll-item ${
                        i % 2 === 0 ? "" : "darker"
                      }`}
                    >
                      {currentData?.classrooms?.length !== 1 && (
                        <div className="d-flex align-items-center">
                          <label className={`switch switch-small mb-0`}>
                            <input
                              className="switch-checkbox"
                              type="checkbox"
                              disabled={
                                x?.total_assessments === 0 ? true : false
                              }
                              defaultChecked={
                                x?.total_assessments === 0 ? false : true
                              }
                              name={`availablity-${x.id}`}
                              onChange={async (e: any) =>
                                handleOnChange(e.target.checked, x.id)
                              }
                              checked={
                                x?.total_assessments > 0 && x?.is_available
                                  ? true
                                  : false
                              }
                            />
                            <span className="switch-slider switch-slider-small"></span>
                          </label>
                        </div>
                      )}
                      <div className="tool-tooltip">
                        <ToolTip content={`${x.name}`}>
                          <h3>
                            {x.name.length > 20
                              ? x.name.slice(0, 20) + "..."
                              : x.name}
                          </h3>
                        </ToolTip>
                      </div>

                      <div className="d-flex align-items-center">
                        <p className="edit__student-copy-unenroll-submitted">
                          {x?.total_assessments}{" "}
                          {x?.total_assessments === 1
                            ? "assessment"
                            : "assessments"}
                        </p>
                      </div>
                    </div>
                  </li>
                </>
              );
            })}
          </ul>
        </div>
        <div className="edit__student-copy-unenroll-checks">
          <p>To Classroom</p>
        </div>

        <div className="edit__student-copy-unenroll-content edit__student-copy-unenroll-corners">
          <ul className="edit__student-copy-unenroll-list">
            <li>
              <div className={`edit__student-copy-unenroll-item`}>
                <h3>
                  {currentData?.classroom_name?.length > 40
                    ? currentData?.classroom_name?.slice(0, 40) + "..."
                    : currentData?.classroom_name}
                </h3>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="modal__footer">
        <Button
          buttonText="Cancel"
          onClick={() => onClose()}
          className="btn cancel-btn"
          type="button"
        />
        <Button
          buttonText="Transfer"
          onClick={() => onSubmit(false)}
          className="btn cancel-btn"
          type="button"
        />
        <Button
          buttonText="Transfer with Data"
          onClick={() => onSubmit(true)}
          disabled={
            currentData?.classrooms?.filter(
              (classData: any) => classData.is_available === true
            ).length > 0
              ? false
              : true
          }
          className="btn danger-un-enroll-btn"
          type="button"
          style={{ width: 200 }}
          loading={loading}
        />
      </div>
    </CustomModal>
  );
};
