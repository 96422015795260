import { Button } from "components/common/buttonComponent";
import {  useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getActiveAdmins } from "services/common";
// import { getSuperAdmins } from "services/supportTicketSuperAdmin";

interface SuperAdminAddProps {
  onCancel: () => void;
  onAdd: (data: any) => Promise<any>;
  active_admins: [],
  currentAdmin: number,
  loading: boolean;
}

  
const AssignModal: React.FC<SuperAdminAddProps> = ({
    onCancel,
    onAdd,
    active_admins,
    currentAdmin
}) => {
    const [loading, setLoading] = useState(false);
    const [adminId, setAdminId] = useState<number>(null!);

    const [activeAdmins, setActiveAdmins] = useState([])
  //   const [superAdminQuery] = useState({
  //     limit: 10,
  //     offset: 0,
  //     search: '',
  //     sort_by: '',
  //     order_by: ''
  // });
    
    const onSubmit = async () => {
      setLoading(true);
      await onAdd(adminId);
      setLoading(false);
    };
  
    const handleCancel = () => {
      onCancel();
    };

    const handleOnChangeDropdown = (e: any) => {
      setAdminId(parseInt(e.target.value));
    }

    const fetchActiveAdmins = async () => {
      const activeAdmins = await getActiveAdmins();
      if(activeAdmins){
        const modifiedAdmins = activeAdmins?.active_admins.map((item: any) => {
          return {
            ...item,
            value: item.id,
            label: `${item.first_name}${item.last_name ? ` ${item.last_name}` : ''}`
          }
        })
        setActiveAdmins(modifiedAdmins);
      }
    }

    useEffect(() => {
      fetchActiveAdmins();

      /* eslint-disable react-hooks/exhaustive-deps */
    },[]);
  
    return (
      <Modal centered show={true} onEscapeKeyDown={handleCancel}>
        <div>
          <div className="modal__header">
            <h6 className="modal__header-title">Assign Chat</h6>
          </div>
          <div className="modal__body">
            <div className="modal__form">
              {/* <ValidationHint isSubmitError={isSubmitError} /> */}
              <div className="modal__form-row mb-2">
                <label className="lg">Admin</label>
                <select className="form-select welcome-edit-select"
                  onChange={(e: any) => handleOnChangeDropdown(e) }
                  value={adminId}
                >
                  <option disabled selected>Select Admin</option>
                  {activeAdmins.map(({id, name}:any, i) => {
                    return (
                      id !== currentAdmin &&  <option value={id}>{name}</option>
                    )
                  })
                    
                  }
                </select>
              </div>
            </div>
          </div>
          <div className="modal__footer">
            <Button
              buttonText="Cancel"
              onClick={handleCancel}
              className="btn cancel-btn"
              disabled={loading}
              type="button"
            />
            <Button
              disabled={adminId === null}
              buttonText="Assign"
              onClick={onSubmit}
              className={`btn success-btn btn-lg ${
                (loading) && "btn-disabled"
              }`}
              loading={loading}
              type="button"
              id='save-button'
            />
          </div>
        </div>
      </Modal>
    );
};

export default AssignModal;