import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import IPage from "interfaces/page";
import logging from "config/logging";
import Theme from "components/theme/index";
import StudentDetails from "components/management/student-details";

const StudentPage: React.FunctionComponent<IPage> = (props) => {
  const { id } = useParams();

  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props.name]);

  return (
    <>
      <Theme>
        <div className="management-st-wrap management-accordian text-white">
          {id && (
            <StudentDetails studentId={id || ""} />
          )}
        </div>
      </Theme>
    </>
  );
};

export default StudentPage;
