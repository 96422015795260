import AssessmentBody from "./table/body"
import AssessmentHeader from "./table/header"
import Sidebar from "./table/sidebar"
import SearchIcon from "assets/img/search-icon.svg";
import { useCallback, useContext, useMemo, useState, useEffect, useRef } from "react";
import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment";
import { Assessment, ReportsNavigation } from "interfaces/professionalDevelopment";
import { debounce } from "lodash";
import ClassroomTooltip from "components/common/classroomTooltip";
import { ClassroomTooltipContext } from "contexts/classroomTooltip";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useCheckGoalTooltipExists } from "hooks/useGoalTooltip";
import RemediationModal from "./remediateModal";
import NewAttemptInfoModal from "./newAttemptInfoModal";
import { NewAttemptModal } from "./newAttemptModal";

export enum FormativesFilters {
    Scheduled = 'Scheduled',
    Upcoming = 'Upcoming',
    Objective = 'Objective'
}

const Formatives = () => {

    const { fetchFormativeRemediateData, formativeRemediateList, reEnableAssessments, classroomId, formatives, navigateTo, isLoading, schoolId, fetchFormatives } = useContext(ProfessionalDevelopmentContext);
    const {tooltip, showNoScheduledTooltip, showNoGoalTooltip} = useContext(ClassroomTooltipContext);

    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useSearchParams();
    const tooltipText = searchParams.get("text");

    const modalRef = useRef<any>(null!);
    const infoModalRef = useRef<any>(null!);

    const [search, setSearch] = useState('');
    const [showNewAttemptModal, setShowNewAttemptModal] = useState(false);
    const [filter, setFilter] = useState(FormativesFilters.Scheduled);
    const [cellHeights, setCellHeights] = useState<any>([]);
    const [formativeLoading, setFormativeLoading] = useState(false);
    const [incompleteFormativeTooltip, setIncompleteFormativeTooltip] = useState(searchParams.get("firstIncompleteFormative") === 'true');
    const [completedFormativeTooltip, setCompletedFormativeTooltip] = useState(searchParams.get("formativeMarkCompleted") === 'true');
    const [openRemediateModal, setOpenRemediateModal] = useState(searchParams.get("openRemediateModal") === 'true');
    const [tooltipTop, setTooltipTop] = useState<any>(null!);
    const [showRemmediationModal, setShowRemmediationModal] = useState<any>(null!);
    const [newAttemptInfoModal, setNewAttemptInfoModal] = useState(false);
    const [formativeId, setFormativeId] = useState<number>(null!);
    const newAttemptModalRef = useRef<any>(null!);

    const tableRef = useRef<HTMLTableElement>(null!)

    const filteredFormatives = useMemo(() => {
        if (search) {
            return formatives?.filter((report: any) => report?.name?.toLowerCase().includes(search.toLowerCase()))
        }else{
            return formatives;
        }
    },[search, formatives])

    const scheduledDates = useMemo(() => {
        return filteredFormatives.map((formative, index) => {
            let minHeight = formative.description?.length > 250 ? 146 : formative.description?.length > 121 ? 126: 106
            if(cellHeights[index]){
                minHeight = cellHeights[index] + 64;
            }
            return {
                date: formative.scheduled_date === null ? 'Not Scheduled' : formative.scheduled_date, 
                color: formative?.scheduled_color,
                minHeight,
            }
        })
    }, [filteredFormatives, cellHeights])

    // eslint-disable-next-line 
    const updateSearch = useCallback(debounce((value) => { setSearch(value) }, 1000), [])

    const isAllNotSubmitted = formatives
    ?.map((x: any) => !!parseInt(x.students_assessed.split("/")[0]))
    .find((item: any) => item === true);

    const enableReportButtons = classroomId && isAllNotSubmitted;

    const clickHandler = useCallback(() => {
        searchParams.delete("text");
        if(completedFormativeTooltip){
            searchParams.delete("formativeMarkCompleted");
            setCompletedFormativeTooltip(false);
        }
        
        if(incompleteFormativeTooltip){
            searchParams.delete("incompleteFormativeTooltip");
            setIncompleteFormativeTooltip(false);
        }
        
        setSearchParams(searchParams);
        setTooltipTop(null!);
        document.removeEventListener('click', clickHandler);

        /* eslint-disable react-hooks/exhaustive-deps */
    },[]);

    useEffect(() => {
        const handleClick = (event: any) => {
          if(!newAttemptModalRef.current?.contains(event.target)){
            setShowNewAttemptModal(false);
            setShowRemmediationModal(null!)
          }
        }
    
        if(!showNewAttemptModal){
          document.removeEventListener('click', handleClick, true);
          return;
        }
    
        document.addEventListener('click', handleClick, true);
    
        return () => {
          document.removeEventListener('click', handleClick, true);
        }
    },[showNewAttemptModal])
    
    useEffect(() => {
        const handleClick = (event: any) => {
          if(!infoModalRef.current?.contains(event.target)){
            if(infoModalRef.current){
              setNewAttemptInfoModal(false);
              return;
            }
          }
    
          if(!modalRef.current?.contains(event.target)){
            if(infoModalRef.current?.contains(event.target)){
              return;
            }
            
            setShowRemmediationModal(false);
            return;
          }
        }
    
        if(!showRemmediationModal){
          document.removeEventListener('click', handleClick, true);
          return;
        }
    
        document.addEventListener('click', handleClick, true);
    
        return () => {
          document.removeEventListener('click', handleClick, true);
        }
    },[showRemmediationModal])

    useEffect(() => {
        if(!formatives.length || !tableRef.current){
            return
        }

        if((incompleteFormativeTooltip || completedFormativeTooltip) && formatives && formatives.length > 0){
            document.addEventListener('click', clickHandler)
        }

        setTimeout(() => {
            const table = tableRef.current

            const tBody = table?.getElementsByTagName('tbody')[0];
            const tableRow = tBody?.getElementsByTagName('tr');
            
            let tooltipHeight = 0
            let index = -1;
            let isFound = false
            let heights = [];
            
            for (var t = 0; t < tableRow?.length; t++){
                if(tableRow[t].id?.includes("second-row")){
                    heights.push(tableRow[t].clientHeight);
                    if(!isFound && t > 0){
                        if(incompleteFormativeTooltip){
                            if(
                                (tableRow[t].querySelector(".switch-checkbox") as any)?.checked
                                // (tableRow[t].querySelector(".switch-checkbox") as any).disabled
                            ){
                                tooltipHeight += tableRow[t].clientHeight + tableRow[t-1].clientHeight
                            }else{
                                if((tableRow[t].querySelector(".switch-checkbox") as any)?.checked === false){
                                    index = t;
                                    isFound = true
                                }
                                 
                            }
                        }
                        
                        if(completedFormativeTooltip){
                            const offset = searchParams.get("offset") || '0';
                            if(
                                (tableRow[t].querySelector(".switch-checkbox") as any)?.checked || (Math.floor(t/2) + 1) !== (parseInt(offset)+1)
                                // (tableRow[t].querySelector(".switch-checkbox") as any).disabled
                            ){
                                tooltipHeight += tableRow[t].clientHeight + tableRow[t-1].clientHeight
                            }else{
                                if((tableRow[t].querySelector(".switch-checkbox") as any)?.checked === false){
                                    index = t;
                                    isFound = true
                                }
                                 
                            }
                        }
                    }
                    
                }
            }

            if((incompleteFormativeTooltip || completedFormativeTooltip) && isFound){
                setTooltipTop({
                    tooltipHeight,
                    index: Math.floor(index/2)
                });
            }
            setCellHeights(heights);
        }, 1000)

        /* eslint-disable react-hooks/exhaustive-deps */
    }, [formatives]);

    useEffect(() => {
        if(tooltipTop !== null){
            if(tooltipTop.index > -1){
                const el = document.getElementById(`first-row-${tooltipTop.index}`);
                el?.scrollIntoView();
            }
            
        }
    },[tooltipTop])

    const {pathname} = useLocation();

    const isGoalTooltip = useCheckGoalTooltipExists();

    const isBlur = pathname === '/classroom' && (tooltip === 1 || tooltip === 2 || tooltip === 3);
    const isTransparent = pathname === '/classroom' && (tooltip === 4 || tooltip === 5 || isGoalTooltip || showNoGoalTooltip || showNoScheduledTooltip);
    const isShowFirstTootip = tooltip === 4 && pathname === '/classroom';
    const isShowSecondTootip = tooltip === 5 && pathname === '/classroom';

    const getFormatives = async (order_by: string) => {
        if(schoolId && classroomId){
          setFormativeLoading(true);
          await fetchFormatives(schoolId, classroomId, order_by);
          setFormativeLoading(false);
        }
    }
    
    const onChangeFilter = (selectedfilter: any) => {
        setFilter(selectedfilter);

        switch (selectedfilter) {
            case FormativesFilters.Scheduled:
            getFormatives(FormativesFilters.Scheduled);
            break;
    
            case FormativesFilters.Objective:
            getFormatives(FormativesFilters.Objective);
            break;
            case FormativesFilters.Upcoming:
            getFormatives(FormativesFilters.Upcoming);
            break;
    
            default:
            break;
        }
    }

    const showRemediateModal = (formativeId: number) => {
        setShowRemmediationModal(true);
        setFormativeId(formativeId)
    }

    useEffect(() => {
        if(openRemediateModal && formatives?.length > 0 && !isLoading){
            const objectiveId = Number(searchParams.get("objectiveId"))
            const selectedFormativeIndex = formatives.findIndex((item: any) => item?.fk_objective_id === objectiveId);
            if(selectedFormativeIndex > -1){
                const selectedFormative = formatives[selectedFormativeIndex];
                const el = document.getElementById(`first-row-${selectedFormativeIndex}`);
                el?.scrollIntoView();

                setTimeout(() => {
                    setFormativeId(selectedFormative?.id);
                    setShowRemmediationModal(true);
                },1000)

            }
            
        }
    },[openRemediateModal, formatives, isLoading])

    return (
        <>
            <div id="tooltip-continer" className="lessons-sec fomative-assessment-table">
                {isShowFirstTootip && <ClassroomTooltip message="When you create a schedule, it will be reflected here. You can also click these." className="schedule-tooltip" arrowDirection="left" />}
                {isShowSecondTootip && <ClassroomTooltip message="To enable assesments in the student portal, toggle this on. Then click Monitor." className="student-toogle-tooltip" arrowDirection="right" />}
                <div className="table-header d-flex align-items-center justify-content-between">
                    <h1 className="lesson-heading">Formative Assessments</h1>
                    {/* <div className="paced-switch d-flex">
                        <p className="mark-comp me-3">Paced</p>
                        <label className="switch mb-0 me-3 mark-switch">
                            <input
                                type="checkbox"
                                className="switch-checkbox"
                            />
                            <span className="switch-slider"></span>
                        </label>
                    </div> */}
                    <p className="lesson-desc">Use the Pacing Calendar to schedule lessons.</p>
                    <div className="d-sm-flex align-items-center headerBtnContainer headerBtnContainerFormative">
                        <div 
                            style={{ ...(!enableReportButtons && { opacity: '0.56' }) }} 
                            onClick={() => navigateTo(Assessment.formative, ReportsNavigation.SBR, { enableReportButtons: !!enableReportButtons })} 
                            className={`header-btns ${enableReportButtons ? 'cursor-pointer' : 'cursor-default'}`}
                        >
                            Standards Based Report
                        </div>

                        <div 
                            style={{ ...(!enableReportButtons && { opacity: '0.56' }) }} 
                            onClick={() => navigateTo(Assessment.formative, ReportsNavigation.OAG, { enableReportButtons : !!enableReportButtons })} 
                            className={`header-btns mt-sm-0 mt-3 objective-btn ${enableReportButtons ? 'cursor-pointer' : 'cursor-default'}`}
                        >
                            Objective Accountability Graph
                        </div>
                    </div>
                </div>
                <div className="lesson-divider"></div>
                <div className="search-formative d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                        <div className="search-icon">
                            <img src={SearchIcon} alt="" />
                        </div>
                        <div className="assessment">
                            <input 
                                onChange={(e) => updateSearch(e.target.value)} 
                                type="text" 
                                placeholder="Search Assessments" 
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <p className="show-text">Order By</p>
                        <select onChange={(e: any) => onChangeFilter(e.target.value)} className="all-formatives-dropdown">
                            <option value={FormativesFilters.Scheduled}>Scheduled</option>
                            <option value={FormativesFilters.Upcoming}>Upcoming</option>
                            <option value={FormativesFilters.Objective}>Objective</option>
                        </select>
                    </div>
                </div>
                <div className="d-flex overflow-auto w-100 ">
                    <Sidebar 
                        show={!!classroomId && !!scheduledDates.length && !isLoading && !formativeLoading} 
                        isFormative={true} 
                        dates={scheduledDates}
                    />
                    <div className="summative-table-container">
                        <div className="table-responsive">
                            {!formativeLoading && tooltipTop && (incompleteFormativeTooltip || completedFormativeTooltip) && (
                                <ClassroomTooltip customStyle={{top: 210+(tooltipTop?.tooltipHeight || 0)}} className="ProfessionalDev__devSummary__toolTip incomplete-formative" message={tooltipText} />
                            )}
                            <table ref={tableRef} className="summative__table text-white ps-3">
                                <AssessmentHeader />
                                <AssessmentBody
                                    classroomId={classroomId}
                                    filter={filter}
                                    scheduledDates={scheduledDates}
                                    formatives={filteredFormatives}
                                    formativeLoading={formativeLoading}
                                    setShowRemmediationModal={showRemediateModal}
                                />
                            </table>
                        </div>
                    </div>
                </div>
                {isBlur && (
                    <div className="toolTip-bg"></div>
                )}

                {isTransparent && (
                    <div className="toolTip-bg toolTip-bg-transparent"></div>
                )}
            </div>
            {!!showRemmediationModal && !showNewAttemptModal && (
                <RemediationModal
                    ref={modalRef}
                    onClose={() => {
                        setShowRemmediationModal(null!); 
                        setOpenRemediateModal(false);
                    }} 
                    onClickQuestionMark={() => {
                      setNewAttemptInfoModal(true);
                    }}
                    fetchFormativeRemediateData={fetchFormativeRemediateData}
                    formativeRemediateList={formativeRemediateList}
                    formativeId={formativeId}
                    reEnableAssessments={reEnableAssessments as any}
                    classroomId={classroomId}
                    onNewAttemptAssigned={() => {
                      setShowNewAttemptModal(true);
                    }}
                />
            )}

            {showNewAttemptModal && (
                <NewAttemptModal
                  ref={newAttemptModalRef}
                  isShow
                  bodyText={`
                    The assessment has been re-enabled and new attempts have been assigned to the non-proficient students who have not yet 
                    completed 3 attempts and do not have an attempt in progress.
                  `}
                  headerText={`${formativeRemediateList?.objective} New Attempts Assigned`}
                  cancelButtonName="Okay"
                  submitButtonName="Go to Monitor"
                  onCancel={() => {
                    setShowNewAttemptModal(false);
                    setShowRemmediationModal(null!);
                  }}
                  type="success"
                  onSubmit={() => {
                    navigate(`/classroom/formative/monitor/${formativeId}/${classroomId}`);
                    setShowRemmediationModal(null!);
                  }}
                />
            )}
        
            {newAttemptInfoModal && (
                <NewAttemptInfoModal
                    ref={infoModalRef}
                    show
                    closeModal={() => setNewAttemptInfoModal(false)}  
                />
            )}
        </>
    )
}
export default Formatives