import Select from "react-select";

interface IProps {options?: any[], excludeDefault?: boolean, allOptions?: any[], onChangeHandler: any, isDisabled?: boolean, defaultSelected?: any, customClass?: string, placeholder?: string}
export const AssigneeDropdown = (props: IProps) => {
    
    const { onChangeHandler, allOptions, excludeDefault, isDisabled, options, customClass, placeholder } = props;

    const dropdownOptions = allOptions ? allOptions : [
        { value: '', label: 'All' },
        ...(options ? options : [])
    ];

    const defaultSelected: any = props.defaultSelected ? props.defaultSelected : dropdownOptions[0];
    
    return (
        <Select
            key={defaultSelected?.value}
            isDisabled={isDisabled}
            isSearchable={false}
            options={dropdownOptions}
            placeholder={placeholder}
            defaultValue={excludeDefault ? undefined : defaultSelected}
            onChange={onChangeHandler}
            className={`assessment-selects__wrapper ${customClass || ''}`}
            classNamePrefix='assessment-selects'
            components={{
                IndicatorSeparator: () => null,
            }}
        />
    )
}
export const StatusFilter = ({ options, onChangeHandler, isDisabled }: any) => {

    return (
        <Select
            isDisabled={isDisabled}
            isSearchable={false}
            options={options}
            defaultValue={options[0]}
            onChange={onChangeHandler}
            className='assessment-selects__wrapper'
            classNamePrefix='assessment-selects'
            components={{
                IndicatorSeparator: () => null,
            }}
        />
    )
}

