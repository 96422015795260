/* eslint-disable react-hooks/exhaustive-deps */

import { useMemo } from "react"
import Collapsible from "react-collapsible"

import moment from "moment";
import AttemptVariant from "components/common/AttemptVariant";


interface Props {
    student: any;
    index: number;
    questionId: number;
    expandedStudents: any
    onClickExpand: Function,
    dropDownLabel: string;
    setIsolatedStudent: Function;
    variants: string[]
}

const AnswerMap = {
    0: 'A',
    1: 'B',
    2: 'C',
    3: 'D'
}

const Student = ({ student, index, questionId, expandedStudents, onClickExpand, dropDownLabel, setIsolatedStudent, variants }: Props) => {
    const isHideRow = student.isShowIsolated === false; // dont use not sign (!) with student.isShowIsolated

    const studentContent = useMemo(() => {
        const { attempts } = student;

        let records = attempts.map((attempt: any, attemptIndex: number) => {
            const { answers, calculation, test_date } = attempt;

            return <tr className={index % 2 !== 0 ? "dark-gray" : ''}>
                <td onClick={() => setIsolatedStudent(student)} className="report__itemTable-name report__data-height radius">
                    {!attemptIndex ? <span className="ellipsis-animated">
                        <p className={`cursor-pointer ellipsis-animated-text ${student.student_name.length > 16 ? 'animated ellipsis-animation' : ''} ${student.isShowIsolated ? 'font-bold' : isHideRow ? 'objective-isolation' : ''}`}>{student.student_name}</p>
                    </span>
                        : ''
                    }
                </td>
                <div className="d-flex data-report__numbers-container">
                    {answers
                        .map((s: any, i: number) => {

                            if (s.answer) {
                                return <td className="report__itemTable-numbers report__data-height" key={i}>
                                    <span className={`${questionId ? (s.question_id !== questionId || isHideRow) ? 'visibility-hidden' : '' : isHideRow ? 'visibility-hidden' : ''} ${s.correct ? 'high' : 'low'} report__itemTable-rectangle`}>
                                        {!s.correct && AnswerMap[s.answer.trim() as keyof typeof AnswerMap]}
                                    </span>
                                </td>
                            }
                            return <td className="report__itemTable-numbers  report__data-height" key={i}>
                                <span className={`${questionId ? (isHideRow || s.question_id !== questionId) ? 'visibility-hidden' : '' : isHideRow ? 'visibility-hidden' : ''} report__itemTable-rectangle unattempted`}>

                                </span>
                            </td>
                        })
                    }
                </div>
                <td className="report__itemTable-attempt formative-attempt report__data-height  ">
                    {/*  */}
                    <div className={`position-relative`}>
                        <span className={`${isHideRow ? 'visibility-hidden' : ''}`}>{
                            dropDownLabel === "Attempt" ? variants.length>1? <div className="pe-1"><AttemptVariant givePadding={true} attemptVariant={calculation?.attempt_variant} /></div> : calculation?.attempt  : (test_date && test_date.length && moment(test_date).format('M-D-YY')) || ''
                        }</span>
                        {attempts.length > 1 && <div onClick={() => onClickExpand(student.student_id)} style={{ display: 'inline-block', position:'absolute', top: -7,  right: `${dropDownLabel === 'Date' ? '-2px' :'-2px'}` }} className="irr-summative-collapse-icon">{
                            <>
                                {/* {!attemptIndex ? <img
                                    src={ChevronIcon}
                                    className={`${expandedStudents[student.student_id] ? 'rotate' : 'rotate-90'} `}
                                    alt="arrow"
                                /> : ''} */}

                                {!attemptIndex ?
                                    <svg className={`${expandedStudents[student.student_id] ? 'rotate' : 'rotate-180'} `} xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
                                        <path d="M11.8632 0.635553C11.7752 0.548753 11.6559 0.5 11.5315 0.5C11.4072 0.5 11.2879 0.548753 11.1999 0.635553L6.32593 5.43892C6.23778 5.52577 6.11835 5.57454 5.99385 5.57454C5.86934 5.57454 5.74991 5.52577 5.66176 5.43892L0.787799 0.634706C0.698638 0.553385 0.581058 0.509335 0.459727 0.5118C0.338397 0.514264 0.222749 0.563051 0.137048 0.647923C0.0513478 0.732795 0.00225676 0.847156 7.58863e-05 0.967009C-0.00210499 1.08686 0.0427938 1.20289 0.125351 1.29075L4.99846 6.09327C5.26262 6.35372 5.62061 6.5 5.99385 6.5C6.36708 6.5 6.72507 6.35372 6.98923 6.09327L11.8623 1.29075C11.9503 1.20393 11.9998 1.08617 12 0.963327C12.0002 0.840482 11.951 0.722595 11.8632 0.635553Z" fill="#323233" />
                                    </svg>
                                    :
                                    ''}
                            </>}
                        </div>
                        }
                    </div>
                </td>

                <td className={`${isHideRow ? 'hidden' : ''} report__itemTable-score score-align report__data-height radius`}>
                    <span>{`(${calculation.total_correct || 0}) `}</span>
                    <span>
                        {`${calculation.percentage || 0}%`}
                    </span>
                </td>

            </tr >
        })

        if (records.length > 1) {
            return <Collapsible
                tabIndex={1}
                handleTriggerClick={() => { }}
                open={!!expandedStudents[student.student_id]}
                trigger={records[0]}>
                {records.slice(1)}
            </Collapsible>
        } else {
            return <div > {records[0]} </div>
        }

    }, [isHideRow, student, index, expandedStudents, dropDownLabel, questionId])

    return (<>{studentContent}</>
    )
}

export default Student