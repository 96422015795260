import { FC } from "react";


interface Props {
    src: string;
}

const DiscussionIframe: FC<Props> = (props) => {

  const { src } = props;

  return (
    <div className="discussions-admin">
      <iframe
        src={src}
        title="Website Iframe"
        className="frame_setting"
      />
    </div>
  );
};

export default DiscussionIframe;