/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef, useCallback } from "react";
import _ from "lodash";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "assets/img/edit-table.svg";
import DeleteIcon from "assets/img/delete-table.svg";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import ExcelReportIconGray from "assets/img/excel-report-gray-icon.svg";
import ArrowDownImg from "assets/img/drop-black-icon-down.svg";
import { default as SearchIconSVG } from "components/common/svgs/search-icon";
import SearchIconGray from "assets/img/search-icon-gray.svg";
import Pagination from "../pagination/pagination";
import { SchoolUpdateModal } from "./management-update-modal";
import { SchoolAddModal, SchoolAdminAddModal } from "./management-add-modal";
import { SchoolDeleteModal } from "./management-delete-modal";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import {
  LimitSelect,
  TableSelectCommon,
} from "components/common/selectComponent";
import Select from "react-select";
import {
  addSchool,
  getSchools,
  getSingleSchool,
  updateSchool,
  deleteSchool,
  addSchoolAdmin,
  setFlagForFetch,
  updateResourcesAccess,
  requestStlReport,
  updatePDAndBookAccess,
} from "../../redux/actionCreators/management";
import PermissionHintModal from "./modals/permissionHintModal";
import ActionType from "redux/actionTypes";
import { isPrevYearDistrictSTLExists } from "services/common";
import ButtonDropDown from "components/common/buttonDropDown";
import SortIndicatorIcon from "components/common/svgs/sort-indicator-icon";
import PencilIconManagement from "components/common/svgs/pencil-icon-management";
import TrashIcon from "components/common/svgs/trash-icon";
import useSortBy from "hooks/useSortBy";
import Toggle from "components/common/Toggle";

interface RootState {
  management: any;
  auth: any;
}

const Schools: React.FC<{
  page: string;
  districtId?: string;
  onNavigate?: () => void;
}> = ({ page, districtId, onNavigate }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { management, auth } = useSelector((state: RootState) => state);
  const [open, setOpen] = useState(false);
  const [offSet, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [addModal, setAddModal] = useState(false);
  const [addSchoolAdminModal, setAddSchoolAdminModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<null | any>(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [modalBorder, setModalBorder] = useState(false);
  // const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [skeletonCount, setSkeletonCount] = useState(10);
  const { sortBy, OrderByColumn, sortDataBy } = useSortBy("name", "ASC");
  const [addClassToAdjustDropDown, setAddClassToAdjustDropDown] = useState(false);
  const [lastAddedId, setLastAddedId] = useState(0);
  const [reportDisableModal, setReportDisableModal] = useState(false);
  const [selectedId, setSelectedId] = useState<any>(null!);
  const [prevExists, setPrevExists] = useState(false);
  const [STLType, setSTLType] = useState("");

  useEffect(() => {
    dispatch(
      getSchools(offSet, limit, searchTerm, districtId, OrderByColumn, sortBy)
    );
  }, [
    offSet,
    limit,
    management.schoolFlag,
    sortBy,
    OrderByColumn,
    dispatch,
    districtId,
  ]);

  const handleSearch = useCallback(
    _.debounce((val: any) => {
      setOpen(true);
      setCurrentPage(1);
      setOffSet(0);
      dispatch(getSchools(0, limit, val, districtId, OrderByColumn, sortBy));
      if (inputRef.current !== null) {
        inputRef.current.focus();
      }
    }, 1000),
    []
  );

  useEffect(() => {
    if (management.lastAddedSchoolId > 0) {
      setLastAddedId(management.lastAddedSchoolId);
      setSearchTerm("");
      dispatch(
        getSchools(
          0,
          10,
          "",
          districtId,
          OrderByColumn,
          sortBy,
          management.lastAddedSchoolId
        )
      );
    }
    if (management.schoolPageNumber > 0) {
      setLimit(10);
      setCurrentPage(management.schoolPageNumber);
      setOffSet(management.schoolPageNumber * 10 - 10);
      management.schoolPageNumber === currentPage &&
        dispatch(setFlagForFetch("SET_SCHOOL_FLAG"));
      setTimeout(() => {
        setLastAddedId(0);
      }, 2000);
    }
  }, [management.lastAddedSchoolId, management.schoolPageNumber]);

  useEffect(() => {
    if (lastAddedId > 0 && !management.schoolsLoading) {
      const element = document.getElementById("school-data");
      element?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [management.schools, management.schoolsLoading]);

  const doChangePage = (pageNo: number) => {
    let newOffset = pageNo * limit - limit;
    setCurrentPage(pageNo);
    setOffSet(newOffset);
  };
  const doSetUpdateModal = (item: any) => {
    setUpdateModal(true);
    setCurrentItem(item);
    dispatch(getSingleSchool(item.id));
  };

  const onUpdateSchool = (data: any) => {
    dispatch(updateSchool(currentItem.id, data, setUpdateModal));
  };

  const onAddSchool = (data: any) => {
    dispatch(
      addSchool({ ...data, district_id: Number(districtId) }, setAddModal)
    );
    !open && setOpen(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalBorder(true);
    }, 300);

    return () => {
      clearTimeout(timer);
      setModalBorder(false);
    };
  }, [open]);

  const setPreviousPage = () => {
    if (currentPage > 1 && management.schools.length === 1) {
      doChangePage(currentPage - 1);
    } else {
      dispatch(setFlagForFetch("SET_SCHOOL_FLAG"));
    }
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const onDeleteSchool = () => {
    dispatch(
      deleteSchool(currentItem.id, closeDeleteModal, setPreviousPage, true)
    );
  };

  const onAddSchoolAdmin = (data: any) => {
    dispatch(addSchoolAdmin(data, setAddSchoolAdminModal));
  };

  const requestStl = async (data: string) => {
    const isPrevYear = data === "2023-24";
    dispatch({ type: ActionType.SET_SINGLE_STL_LOADING, payload: true });
    dispatch({ type: ActionType.SET_SINGLE_STL_PERIOD, payload: data });
    localStorage.setItem("stlSingleLoading", "true");
    const request = await requestStlReport(districtId, isPrevYear);
    localStorage.setItem("singleId", request.data.data.id);
    if (districtId) {
      localStorage.setItem("district_id", districtId);
    }
    dispatch({
      type: ActionType.SET_SINGLE_STL_ID,
      payload: request.data.data.id,
    });
  };

  useEffect(() => {
    if (page === "district") {
      setOpen(true);
      setModalBorder(!modalBorder);
    }
  }, [page]);

  useEffect(() => {
    // const timer = setTimeout(() => {
    //   setSkeletonLoad(false);
    // }, 800);

    if (limit - (currentPage * limit - management.schoolsCount) < limit) {
      setSkeletonCount(limit - (currentPage * limit - management.schoolsCount));
    } else {
      setSkeletonCount(limit);
    }

    return () => {
      // clearTimeout(timer);
      // setSkeletonLoad(true);
    };
  }, [management.schoolsLoading, currentPage]);

  const tableBodayHeight = skeletonCount * 37 + "px";

  const getSTL = async () => {
    const res = await isPrevYearDistrictSTLExists(districtId);
    res && setPrevExists(res.previous_year_exists);
  };

  useEffect(() => {
    districtId && getSTL();
  }, [districtId]);

  return (
    <>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={(e: any) => {
          if (
            e.key === "Enter" &&
            !(
              (e.target as Element).nodeName === "INPUT" ||
              (e.target as Element).nodeName === "BUTTON" ||
              (e.target as Element).nodeName === "path" ||
              (e.target as Element).nodeName === "A" ||
              (e.target as Element).nodeName === "svg" ||
              (e.target as Element).nodeName === "LI" ||
              (e.target as Element).nodeName === "SPAN" ||
              (e.target as Element).nodeName === "IMG" ||
              (((e.target as Element).nodeName === "LI" ||
                (e.target as Element).nodeName === "SPAN" ||
                (e.target as Element).nodeName === "IMG") &&
                ((e.target as Element).innerHTML === "Summative Test Log" ||
                  (e.target as Element).innerHTML === "Current" ||
                  (e.target as Element).innerHTML === "2022-23"))
            )
          ) {
            setOpen(!open);
          }
        }}
        onClick={(e) => {
          console.log(2323, (e.target as Element).nodeName);
          if (
            (e.target as Element).nodeName === "INPUT" ||
            (e.target as Element).nodeName === "BUTTON" ||
            (e.target as Element).nodeName === "path" ||
            (e.target as Element).nodeName === "A" ||
            (e.target as Element).nodeName === "svg" ||
            (e.target as Element).nodeName === "LI" ||
            (e.target as Element).nodeName === "SPAN" ||
            (e.target as Element).nodeName === "IMG" ||
            (((e.target as Element).nodeName === "LI" ||
              (e.target as Element).nodeName === "SPAN" ||
              (e.target as Element).nodeName === "IMG") &&
              ((e.target as Element).innerHTML === "Summative Test Log" ||
                (e.target as Element).innerHTML === "Current" ||
                (e.target as Element).innerHTML === "2022-23"))
          ) {
            return;
          }
          setOpen(!open);
        }}
        className={`accordion__header schools-header-overflow schools-header select-school-text ${
          open ? "open" : ""
        } ${modalBorder ? "closeDelay" : " "}`}
      >
        <div className="accordion__header-heading">
          <span className="accordion__header-button">
            <img
              onClick={() => setOpen(!open)}
              src={ArrowIcon}
              alt="arrow"
              className={open ? "rotate" : ""}
            />
          </span>
          <h6>Schools</h6>
          {!open ? (
            <div className="accordion__header-search animated">
              <SearchIconSVG
                title="Search Schools"
                style={{ marginRight: "10px" }}
              />
              <input
                value={searchTerm}
                onChange={(e) => {
                  handleSearch(e.target.value);
                  setSearchTerm(e.target.value);
                }}
                type="text"
                className="form-control"
                placeholder="Search Schools"
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="accordion__header-text d-none d-lg-block">
          {page === "district" ? <p>Select a school below to manage.</p> : ""}
        </div>

        {auth &&
        (auth.currentUser?.role === "Super Admin" ||
          auth.currentUser?.role === "District Admin") ? (
          <div className="schools-btns year-dropDown">
            {page === "district" ? (
              <div className="accordion__header-buttons">
                <>
                  {prevExists ? (
                    <div className="d-sm-flex align-items-center headerBtnContainer">
                      <ButtonDropDown
                        options={[
                          {
                            label: "Summative Test Log",
                            value: "Current",
                          },
                          {
                            label: "2023-24",
                            value: "2023-24",
                          },
                        ]}
                        onChange={(selectedValue: any) => {
                          setSTLType(selectedValue);
                          requestStl(selectedValue);
                        }}
                        biggerWidth={true}
                      />
                    </div>
                  ) : (
                    <div className="accordion__header-btnContainer">
                      <button
                        className="btn outline-btn outline-btn-lg"
                        onClick={() => requestStl("Current")}
                      >
                        Summative Test Log
                      </button>
                    </div>
                  )}
                </>
                <div className="accordion__header-btnContainer">
                  <button
                    onClick={() => setAddModal(true)}
                    className="btn outline-btn outline-btn-lg"
                  >
                    Add School
                  </button>
                </div>
              </div>
            ) : (
              <div className="accordion__header-buttons">
                {/* <div className="accordion__header-btnContainer">
                  <a className="btn outline-btn outline-btn-lg" href="/#">
                    Import CSV
                  </a>
                </div> */}
                {/* <div className="accordion__header-btnContainer">
                  <button
                    onClick={() => setAddSchoolAdminModal(true)}
                    className="btn outline-btn outline-btn-lg"
                  >
                    Add Admin
                  </button>
                </div> */}
              </div>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <Collapse in={open}>
        <div>
          <div className="accordion__content">
            <div className="section-search pe-none">
              <div className="section-search-field animated">
                <div className="icon">
                  <img src={SearchIconGray} alt="search" />
                </div>
                <input
                  ref={inputRef}
                  value={searchTerm}
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    setSearchTerm(e.target.value);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Search Schools"
                />
              </div>
              <div className="d-flex align-items-center show-classes-dropdown">
                <span>Show</span>
                <LimitSelect
                  disableHandler={management?.schools?.length < 10}
                  onChangeHandler={(e: any) => setLimit(Number(e?.value))}
                />
                <span>Schools</span>
              </div>
            </div>
            <div className="school-table-container">
              <div className="table-responsive">
                <table className="school__table">
                  <thead>
                    <tr className="school__table-row">
                      <th
                        tabIndex={0}
                        style={{
                          ...(auth &&
                            auth.currentUser?.role === "Super Admin" && {
                              width: 210,
                            }),
                        }}
                        className="school__table-name"
                        onClick={() => sortDataBy("name")}
                        onKeyDown={(e) =>
                          e.key === "Enter" && sortDataBy("name")
                        }
                      >
                        School Name
                        {sortBy === "name" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th className="school__table-district">District Name</th>
                      <th
                        tabIndex={0}
                        className="school__table-last"
                        onClick={() => sortDataBy("last_test_time")}
                        onKeyDown={(e) =>
                          e.key === "Enter" && sortDataBy("last_test_time")
                        }
                      >
                        Last <br /> Assessment
                        {sortBy === "last_test_time" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th
                        tabIndex={0}
                        className="school__table-class"
                        onClick={() => sortDataBy("classroom_count")}
                        onKeyDown={(e) =>
                          e.key === "Enter" && sortDataBy("classroom_count")
                        }
                      >
                        Classes
                        {sortBy === "classroom_count" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th className="school__table-report">
                        Summative <br /> Obj. Analysis <br /> Report
                      </th>
                      <th className="school__table-available">
                        Available <br /> Student <br /> Licenses
                      </th>
                      {auth && auth.currentUser?.role === "Super Admin" && (
                        <th className="school__table-last">Facilitator</th>
                      )}
                      {auth && auth.currentUser?.role === "Super Admin" && (
                        <th className="school__table-available-access">
                          Book <br /> Access
                        </th>
                      )}
                      {auth && auth.currentUser?.role === "Super Admin" && (
                        <th className="school__table-available-access">
                          PD <br /> Access
                        </th>
                      )}
                      <th className="school__table-options">Options</th>
                    </tr>
                  </thead>
                  <tbody style={{ height: tableBodayHeight }}>
                    {/* {skeletonLoad ? (
                      <TableSkeleton columns={9} count={skeletonCount} height={36} />
                    ) : (
                      ""
                    )} */}

                    {management.schoolsLoading ? (
                      <TableSkeleton
                        columns={10}
                        count={skeletonCount}
                        height={36}
                      />
                    ) : management?.schools && management?.schools?.length ? (
                      management.schools.map((item: any, i: number) => {
                        if(i>2){
                          const list = document.getElementById("bookAccessDropdown1");
                          list?.classList.remove("adjustDropDownClass");
                        }
                        return (
                          <tr
                            key={i}
                            className={
                              lastAddedId === item.id
                                ? "school__table-row highlight-row"
                                : i % 2 !== 0
                                ? "school__table-row"
                                : "school__table-row"
                            }
                            id={lastAddedId === item.id ? "school-data" : ""}
                          >
                            <td className="name-data">
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  navigate(`/management/school/${item?.id}`);
                                  onNavigate && onNavigate();
                                }}
                              >
                                {item?.school_name}
                              </span>
                            </td>
                            <td className="district-data">
                              <span
                                className="cursor-pointer"
                                onClick={() => {
                                  onNavigate && onNavigate();
                                  navigate(`/management/school/${item?.id}`);
                                }}
                              >
                                {item?.districts?.name || ""}
                              </span>
                            </td>
                            <td className="text-center">
                              {item?.last_assessment
                                ? moment(item?.last_assessment).format("M-D-YY")
                                : "-"}
                            </td>
                            <td className="text-center">{item?.classrooms}</td>
                            <td className="report-data">
                              <img
                                role="button"
                                tabIndex={0}
                                onKeyDown={(e: any) => {
                                  if (
                                    e.key === "Enter" &&
                                    (item?.classrooms === 0 || !item.has_data)
                                  ) {
                                    setReportDisableModal(true);
                                  } else if (e.key === "Enter") {
                                    onNavigate && onNavigate();
                                    navigate(
                                      `/management/report/summative-objective-analysis/${item?.fk_district_id}?school_id=${item.id}`
                                    );
                                  }
                                }}
                                src={ExcelReportIconGray}
                                alt="Report"
                                className={
                                  item?.classrooms === 0 || !item.has_data
                                    ? "disabled-item"
                                    : "cursor-pointer"
                                }
                                onClick={() => {
                                  if (
                                    item?.classrooms === 0 ||
                                    !item.has_data
                                  ) {
                                    setReportDisableModal(true);
                                  } else {
                                    onNavigate && onNavigate();
                                    navigate(
                                      `/management/report/summative-objective-analysis/${item?.fk_district_id}?school_id=${item.id}`
                                    );
                                  }
                                }}
                              />
                            </td>
                            <td className="text-center px-0">
                              {item?.student_licenses}
                            </td>
                            {auth &&
                              auth.currentUser?.role === "Super Admin" && (
                                <td className="text-center">
                                  {item?.facilitator_initials || "-"}
                                </td>
                              )}
                            {auth &&
                              auth.currentUser?.role === "Super Admin" && (
                                <td className="vertical-center text-center book-access-data">
                                  <TableSelectCommon
                                    index = {i}
                                    adjustDropDownClass = {i <=2 ? "adjustDropDownClass" : "" }
                                    nextMonth={item.book_access}
                                    isOpen={selectedId === i + 1}
                                    toggleSelect={() => {
                                      if (selectedId !== i + 1) {
                                        setSelectedId(i + 1);
                                      } else {
                                        setSelectedId(0);
                                      }
                                    }}
                                    options={[
                                      { value: "Reading", label: "Reading" },
                                      { value: "Math", label: "Math" },
                                      { value: "All", label: "All" },
                                      { value: "None", label: "None" },
                                    ]}
                                    onChange={(value: any) => {
                                      dispatch(
                                        updatePDAndBookAccess(
                                          "book_access",
                                          value,
                                          item.id,
                                          management
                                        )
                                      );
                                    }}
                                  />
                                </td>
                              )}
                            {auth &&
                              auth.currentUser?.role === "Super Admin" && (
                                <td className="vertical-center text-center">
                                  <Toggle
                                    inputName="Has Pd Access"
                                    ariaLabel="Has Pd Access"
                                    labelClassName="mb-0"
                                    isSmall= {true}
                                    tabIndex={0}
                                    role="button"
                                    checked={
                                      item?.has_pd_access ? true : false
                                    }
                                    onChange={(e) => {
                                      dispatch(
                                        updatePDAndBookAccess(
                                          "has_pd_access",
                                          e.target.checked,
                                          item.id,
                                          management
                                        )
                                      );
                                    }}
                                  />
                                </td>
                              )}
                            <td className="p-0 school__table-options-row">
                              <div className="d-flex justify-content-start align-items-center">
                                <button
                                  tabIndex={0}
                                  onKeyDown={(e: any) => {
                                    if (e.key === "Enter") {
                                      doSetUpdateModal(item);
                                    }
                                  }}
                                  onClick={() => doSetUpdateModal(item)}
                                  className="management__table-editBtn"
                                  style={{ border: "none", background: "none" }}
                                >
                                  <PencilIconManagement fill="#323233" />
                                </button>
                                <button
                                  tabIndex={0}
                                  onKeyDown={(e: any) => {
                                    if (e.key === "Enter") {
                                      setCurrentItem(item);
                                      setDeleteModal(true);
                                    }
                                  }}
                                  onClick={() => {
                                    setCurrentItem(item);
                                    setDeleteModal(true);
                                  }}
                                  className="management__table-editBtn"
                                  style={{ border: "none", background: "none" }}
                                >
                                  <TrashIcon fill="#323233" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        {management?.schools?.length === 0 && (
                          <td colSpan={12} className="no-admin">
                            No Schools
                          </td>
                        )}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Pagination
            totalCount={management.schoolsCount}
            currentPage={currentPage}
            pageSize={limit}
            onPageChange={doChangePage}
          />
        </div>
      </Collapse>
      {updateModal && management.currentSchool && (
        <SchoolUpdateModal
          isShow={updateModal}
          school={management.currentSchool}
          superAdmins={management.schoolSuperAdmins}
          onCancel={() => setUpdateModal(false)}
          onUpdate={(data) => onUpdateSchool(data)}
          loading={management.schoolUpdateLoading}
          isSummativeShow={
            auth && auth.currentUser?.role === "Super Admin" ? true : false
          }
        />
      )}
      {addSchoolAdminModal && (
        <SchoolAdminAddModal
          isShow={addSchoolAdminModal}
          loading={management.schoolAdminAddLoading}
          onCancel={() => setAddSchoolAdminModal(false)}
          onAdd={(data) => onAddSchoolAdmin(data)}
          active={2}
        />
      )}
      {addModal && (
        <SchoolAddModal
          superAdmins={management.schoolSuperAdmins}
          isShow={addModal}
          onAdd={(data) => onAddSchool(data)}
          onCancel={() => setAddModal(false)}
          loading={management.schoolAddLoading}
          isSummativeShow={
            auth && auth.currentUser?.role === "Super Admin" ? true : false
          }
        />
      )}
      {deleteModal && (
        <SchoolDeleteModal
          loading={management.schoolDeleteLoading}
          isShow={deleteModal}
          headerText={`Delete ${currentItem?.school_name}`}
          bodyText={`Are you sure you want to delete this school?`}
          onCancel={closeDeleteModal}
          onDelete={onDeleteSchool}
        />
      )}
      {reportDisableModal && (
        <PermissionHintModal
          isShow={reportDisableModal}
          onClose={() => setReportDisableModal(false)}
          onYes={() => setReportDisableModal(false)}
          message={`There is currently no assessment data for this report. Please try again once assessments have been submitted.`}
          customHeader="SOA Report Disabled"
        />
      )}
    </>
  );
};

export default Schools;
