import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment";
import { debounce } from "lodash";
import moment from "moment";
import CompletedFormatives from "./completedFormatives";
import InCompletedFormatives from "./incompletedFormatives";
import FormativeModal from "./formativeModal";
import Assessment from "./assessment";

export enum FormativesFilters {
    Scheduled = 'Scheduled',
    Upcoming = 'Upcoming',
    Objective = 'Objective'
}

const Formatives = () => {
    const { formatives, fetchFormatives, classroomId, schoolId, isLoading } = useContext(ProfessionalDevelopmentContext);

    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState(FormativesFilters.Scheduled);
    const [formativeLoading, setFormativeLoading] = useState(false);
    // const [openedAccordians, setOpenedAccordians] = useState<any>([]);
    const [selectedFormativeId, setSelectedFormativeId] = useState<any>(null);

    const getFormatives = async (order_by: string) => {
        if(schoolId && classroomId){
          setFormativeLoading(true);
          await fetchFormatives(schoolId, classroomId, order_by);
          setFormativeLoading(false);
        }
    }

    const onChangeFilter = (selectedfilter: any) => {
        setFilter(selectedfilter);

        switch (selectedfilter) {
            case FormativesFilters.Scheduled:
            getFormatives(FormativesFilters.Scheduled);
            break;
    
            case FormativesFilters.Objective:
            getFormatives(FormativesFilters.Objective);
            break;
            case FormativesFilters.Upcoming:
            getFormatives(FormativesFilters.Upcoming);
            break;
    
            default:
            break;
        }
    }

     // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateSearch = useCallback(debounce((value) => {
        setSearch(value);

    }, 600), []);

    const isAssessmentScheduledToday = (date: string) => {
        if(date && date !== "Not Scheduled"){
            const today = moment().format("YYYY-MM-DD");
            return today === date;
        }
        
        return false;
    }

    const filteredFormatives = useMemo(() => {
        if (search) {
            return formatives?.filter((report: any) => report?.name?.toLowerCase().includes(search.toLowerCase())).map((item) => {
                const scheduledToday = isAssessmentScheduledToday(item.scheduled_date);
                return {
                    ...item,
                    is_scheduled_today: scheduledToday
                }
            })
        }else{
            return formatives.map((item, index) => {
                const scheduledToday = isAssessmentScheduledToday(item.scheduled_date);
                return {
                    ...item,
                    is_scheduled_today: scheduledToday
                }
            });
        }
    },[search, formatives]);

    const [showModal, setshowModal] = useState(false);
    const setOpen = (formative: any) => {
        setshowModal(!!formative);
        setSelectedFormativeId(formative ? formative.id : formative);
        // let newAccordians = [];
        // if(isOpened){
        //     newAccordians = openedAccordians.filter((acc: number) => acc !== formative.id);
        // }else{
        //     newAccordians = [...openedAccordians, formative.id];
        // }

        // setOpenedAccordians(newAccordians);
        // const filtered = newAccordians.filter((accord: number) => {
        //     const formativeAssessment = filteredFormatives.find(item => item.id === accord);
        //     return !formativeAssessment?.is_scheduled_today;
        // })
        // localStorage.setItem("opened_accordians", JSON.stringify(filtered));
    }

    useEffect(() => {
        if(!isLoading && !formativeLoading && formatives.length > 0){
            // const todayScheduled = filteredFormatives
            // .filter(formative => {
            //     return isAssessmentScheduledToday(formative.scheduled_date)
            // })
            // .map(assessment => assessment.id);
            
            const openedFormativeId = sessionStorage.getItem("open_formative");
            const parsedFormativeId = openedFormativeId ? JSON.parse(openedFormativeId) : null;
            
            if(parsedFormativeId){
                setSelectedFormativeId(parsedFormativeId);
                sessionStorage.removeItem("open_formative");
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isLoading, formativeLoading, formatives]);

    const inCompleted = filteredFormatives.filter(item => !item.is_completed || (item.is_completed && item.moved));
    const completed = filteredFormatives.filter(item => item.is_completed || (!item.is_completed && item.moved === false));
    const selectedFormative = filteredFormatives.find(item => item.id === selectedFormativeId);

    return (
        <>
            <InCompletedFormatives
                filter={filter}
                updateSearch={updateSearch}
                selectedFormative={selectedFormative && !selectedFormative.is_completed ? selectedFormative : null}
                setOpen={setOpen}
                formatives={inCompleted}
                formativeLoading={formativeLoading}
                onChangeFilter={onChangeFilter}
            />

            {classroomId > 0 && (
                <CompletedFormatives
                    selectedFormative={selectedFormative && selectedFormative.is_completed ? selectedFormative : null}
                    setOpen={setOpen}
                    formatives={completed}
                    formativeLoading={formativeLoading}
                />
            )}

            {showModal && (
                
                <FormativeModal closeModal={() => setOpen(null)}>
                    <div className="formative-assessment-sec">
                        <Assessment
                            formative={selectedFormative}
                            setOpen={() => setOpen(null)}
                            open={true}
                        />
                    </div>
                </FormativeModal>
            
            )}
        </>
    )
}
export default Formatives;