/* eslint-disable react-hooks/exhaustive-deps */

import HtmlParser from "components/common/htmlParser";
import React, { useEffect, useRef, useState } from "react";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import moment from "moment";
import { getRemmediationReportData, getTeamMeetingData, triggerProfessionalDevelopmentGoal } from "services/professionalDevelopment";
import { getPdfData } from "util/getPdfData";
import { PDFDocument } from 'pdf-lib'
import { triggerGoalWithClassroom } from "services/common";
import { GOALS_CONDITIONS } from "util/constants";
import EditIcon from "assets/img/edit-icon.svg"
import { generateRemmediationReportData } from "util/getRemmediationReportData";
import MultiStepGoalIcon from "components/common/multiStepGoalIcon";
import CustomModal from "components/common/Modal";
import Title from "./title";
import BackButton from "components/common/backButton";
import ScrollingText from "components/common/ScrollingText";

interface Props {
    closeModal: Function;
    closeGoalcompletitionModal: Function;
    selectedGoal: any;
    loading: boolean;
    executeAction?: boolean;
    summatives?: any[];
    setSelectedGoal?: Function;
    pdAdminGoal?: boolean;
    setDisplayEditModal?: (show: boolean) => void;
}

let timeoutId: any = null;

const GoalModal: React.FC<Props> = ({
    setSelectedGoal,
    loading,
    closeModal,
    closeGoalcompletitionModal,
    selectedGoal,
    executeAction=true,
    pdAdminGoal,
    setDisplayEditModal
}) => {
    const modalRef = useRef<any>(null);
    const videoDescRef = useRef<any>();
    const descriptionParaRef = useRef<any>();

    const [isScrolled, setIsScrolled] = useState<any>(null!);
    const [showIndicator, setShowIndicator] = useState(false);
    const { tasks=[], goal, instructional_text, is_multi_step, is_recurring, complete_by, completed_tasks_count, tasks_count, id: goal_id } = selectedGoal || {};



    useEffect(() => {
        closeGoalcompletitionModal();
    }, []);

    const onScrollText = (e: any) => {
        // const isAtBottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        // console.log('isAtBottom', e.target.scrollHeight, e.target.clientHeight, e.target.scrollTop);
        if(e.target.scrollTop > 10){
            setIsScrolled(true);
        }else{
            setIsScrolled(false);
        }
    }

    useEffect(() => {
        if(loading){
            return;
        }
        const goalTextElement = descriptionParaRef.current;
        const textHeight = goalTextElement?.offsetHeight || 0;
        if(!textHeight){
            return;
        }
        if(textHeight > 567){
            setIsScrolled(false);
        }else{
            setIsScrolled(true);
        }
    },[selectedGoal, loading]);

    const isChecked = completed_tasks_count === tasks_count;

    const goToExternalUrl = (link: string) => {
        if(!link){
            return;
        }

        // const alink = document.createElement('a');
        const downloadLink = link.startsWith('https') || link.startsWith('http') ? link : `//${link}`;
        window.open(downloadLink);
        // alink.click();
    }

    const onclickPdf = async (label: string) => {

        const classroomId = sessionStorage.getItem("c_classroom_id");
        if(!classroomId){
            return;
        }

        const teamMeetingData = await getTeamMeetingData(parseInt(classroomId));
        if(!teamMeetingData){
            console.log('team meeting data not found!');
            return;
        }

        const pdfTeamData = getPdfData(teamMeetingData);

        const url = '/data_Team_Meeting_Worksheet.pdf';

        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        const pages = pdfDoc.getPages()
        const firstPage = pages[0];

        pdfTeamData.forEach((data: any) => {
            const {x, y, text, size} = data;
            firstPage.drawText(text, {
                x,
                y,
                size
            });
        })

        const pdfBytes = await pdfDoc.save();
        const file = new Blob([pdfBytes], { type: "application/pdf" });

        const fileURL = URL.createObjectURL(file);

        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = `${label}` || 'data_team_worksheet.pdf';
        alink.click();

        triggerGoalWithClassroom({
            classroomId: parseInt(classroomId),
            conditionName: GOALS_CONDITIONS.Generate_DTM_Worksheet
        })
    }

    const getUrlFromTarget = (task: any) => {
        const {task_action_value, task_condition_value} = task;

        switch(task_action_value['target']){
            case 'summative_a1_available_to_students_toggle':
                return `/classroom?studentAvailableTooltip=true&text=${task_action_value['text']}`;
            case 'summative-a1-mark-completed-toggle':
                return `/classroom?summativeA1MarkCompleted=true&text=${task_action_value['text']}`;
            case 'first-incomplete-formative':
                return `/classroom?firstIncompleteFormative=true&text=${task_action_value['text']}`;
            case 'formative-mark-completed':
                return `/classroom?formativeMarkCompleted=true&text=${task_action_value['text']}&offset=${task_condition_value['key']?.x}`;
            case 'summative-b-availability-toggle':
                return `/classroom?summativeBAvailabilityToggle=true&text=${task_action_value['text']}`;
            case 'summative-b-mark-completed-toggle':
                return `/classroom?summativeBMarkCompleted=true&text=${task_action_value['text']}`;
            case 'summative-a2-mark-completed-toggle':
                return `/classroom?summativeA2MarkCompleted=true&text=${task_action_value['text']}`;
            case 'summative_a2_available_to_students_toggle':
                return `/classroom?summativeA2AvailableToggle=true&text=${task_action_value['text']}`;
            case 'openMasterReport':
                return `/classroom?openMasterReport=true&text=${task_action_value['text']}`;
            default:
                return ''
        }
    }

    const downloadDTMRemmediationReport = async (objectiveId: number) => {
        const _classroomId = sessionStorage.getItem("c_classroom_id");
        const classroomId = _classroomId ? parseInt(_classroomId) : 0;
        const remmediationReportData = await getRemmediationReportData(classroomId, objectiveId);

        if(!remmediationReportData){
            console.log('something went wrong in remmediation report API');
        }else{

            const url = '/DTM_Worksheet-Remediation_Report_fPDF-1_5.pdf';

            const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
            const pdfDoc = await PDFDocument.load(existingPdfBytes);

            const pages = pdfDoc.getPages()
            const firstPage = pages[0];

            const remmediatedReport = generateRemmediationReportData(remmediationReportData);

            remmediatedReport.forEach((data: any) => {
                const {x, y, text, size} = data;
                firstPage.drawText(text, {
                    x,
                    y,
                    size
                });
            });

            const pdfBytes = await pdfDoc.save();
            const file = new Blob([pdfBytes], { type: "application/pdf" });

            const fileURL = URL.createObjectURL(file);

            const alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'remmediation_worksheet.pdf';
            alink.click();
        }

    }

    const downloadFileOnly = (task: any) => {
        if(!executeAction){
            return;
        }
        const {task_action_name, task_action_value} = task;

        const taskName = task_action_name.toLowerCase();

        switch(taskName){
            case 'download file':
            case 'open file pdf':
            case 'open file':
                !!task_action_value['url'] && goToExternalUrl(task_action_value['url'])
                break;
            default:
                break;
        }
    }

    const onClickTask = async (task: any) => {
        if(!executeAction){
            return;
        }

        const {task_condition_value, task_action_name, task_action_value, checked, task_condition_name, id} = task;
        const _classroomId = sessionStorage.getItem("c_classroom_id");
        const classroomId = _classroomId ? parseInt(_classroomId) : 0;

        let hasCase = false;
        const taskName = task_action_name.toLowerCase();
        switch(taskName){
            case 'download file':
            case 'open file pdf':
            case 'open file':
                hasCase = !!task_action_value['url']
                hasCase && goToExternalUrl(task_action_value['url'])
                break;
            case 'open mastery video':
                // hasCase = !!task_action_value['video-id']
                !!task_action_value['video-id'] && window.open(`/mastery-videos?videoId=${task_action_value['video-id']}`);
                break;
            case 'open pacing calendar':
                // hasCase = true
                window.open(`/pacing-calendar?task_id=${id}&goal_id=${selectedGoal.id}`);
                break;
            case 'open w2a report':
                // hasCase = true
                window.open(`/classroom/report/way-to-report/${classroomId}?task_id=${id}&goal_id=${selectedGoal.id}`);
                break;
            case 'open master report':
                // hasCase = true
                window.open(`/classroom/report/master-report/${classroomId}?task_id=${task.id}&goal_id=${selectedGoal.id}`);
                break;
            case 'open oag':
                // hasCase = true
                window.open(`/classroom/report/oag-report/${classroomId}`);
                break;
            case 'open summative irr':
                const summativeId = task_action_value['value'];
                // hasCase = !!summativeId
                summativeId && window.open(`/classroom/report/item-response-report-summative/${summativeId}/${classroomId}?task_id=${task.id}&goal_id=${selectedGoal.id}`);
                break;
            case 'open formative irr':
                const objectiveId = task_action_value['objective'];
                // hasCase = !!objectiveId
                objectiveId && window.open(`
                    /classroom/report/item-response-report-formative/${objectiveId}/${classroomId}?task_id=${task.id}&goal_id=${selectedGoal.id}
                `);
                break;
            case 'generate dtm worksheet':
                hasCase = true
                onclickPdf('data_team_meeting_report')
                break;
            case 'open standards based report':
            case 'open sbr in new tab':
                // hasCase = true
                window.open(`${window.location.origin.toString()}/classroom/report/standard-base-report/${classroomId}?task_id=${id}&goal_id=${selectedGoal.id}`);
                break;
            case 'show tooltip':
                const targetUrl = getUrlFromTarget(task);
                // hasCase = !!targetUrl
                targetUrl && window.open(targetUrl)
                break;
            case 'open discussion':
                hasCase = true
                window.open("/discussions")
                break;
            case 'open classroom summative report':{
                const summativeId = task_action_value['value'];
                if(summativeId){
                    const summativeWindow:any = window.open(`/classroom/report/summative/${summativeId}/${classroomId}?task_id=${id}&goal_id=${selectedGoal.id}`);
                    summativeWindow['classroomId'] = classroomId;
                }
                break;
            }
            case 'generate remediation report': {
                const objectiveId = task_action_value['objective'];
                hasCase = !!objectiveId;
                objectiveId && downloadDTMRemmediationReport(objectiveId);
                break;
            }
            case 'open remediate report': {
                const objectiveId = task_condition_value?.key?.objective_id;
                objectiveId && window.open(`/classroom?openRemediateModal=${true}&objectiveId=${objectiveId}`);
                break;
            }
            case 'open sar in new tab': {
                const summativeId = task_action_value['value'];
                summativeId && window.open(`/classroom/report/standard-at-risk/${classroomId}/${summativeId}?task_id=${id}&goal_id=${selectedGoal.id}`)
                break;
            }
            default:
                hasCase = false
                break;
        }

        if(hasCase && !checked){
            console.log({ id, goal_id })
            setSelectedGoal && setSelectedGoal(task.id)
            if(task_condition_name?.toLowerCase() === "download one-time file"){
                triggerGoalWithClassroom({goal_id: selectedGoal.id, task_id: task.id, classroomId: classroomId, conditionName: GOALS_CONDITIONS.Download_One_Time_File});
            }
            else{
                triggerProfessionalDevelopmentGoal({goalId: selectedGoal.id, taskId: task.id, classroomId, actionName: task.task_action_name})
            }
        }
    }


    useEffect(() => {
        if(isScrolled === null || showIndicator === null){
            return;
        }

        if(isScrolled){
            timeoutId && clearTimeout(timeoutId);
            setShowIndicator(null!)
        }else{
            timeoutId = setTimeout(() => {
                setShowIndicator(true);
            },5000)
        }

        return () => {
            timeoutId && clearTimeout(timeoutId);
        }
    },[isScrolled])
    return (
        <>
            <CustomModal
                centered
                show
                contentClassName="PacingCalender__SuggestedModel goals-modal"
                onEscapeKeyDown={() => closeModal()}
            >
                {loading
                    ? <table><TableSkeleton count={1} columns={1} height={638} /></table>
                    : <div ref={modalRef} className="content-wrapper">
                        <div className="left-sec">
                            <div className="d-flex align-items-center">
                                <BackButton 
                                    onKeyDown={(e: any) => {
                                        if (e.key === "Enter") {
                                            closeModal()
                                        }
                                    }}
                                    onClick={() => closeModal()}
                                />
                                <div className="d-flex align-items-center gap-2">
                                    <p className="heading">Goal Details</p>
                                    {setDisplayEditModal && (
                                        <div className="d-flex justify-content-between">
                                            <div role="button" tabIndex={0} 
                                                onKeyDown={(e: any) => {
                                                    if (e.key === "Enter") {
                                                        setDisplayEditModal(true)
                                                    }
                                                }}  
                                                onClick={() => setDisplayEditModal(true)} 
                                                style={{ cursor: 'pointer' }}
                                            >
                                                <img src={EditIcon} alt="Edit" />
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="watch-video">

                                {tasks.every((item: { checked: any; }) => item.checked) ? (
                                    <label className="d-flex justify-content-center goals-modal-checkbox">
                                        <input onChange={() => { }} checked={true} type="checkbox" id="pacing-calendar" value="" />
                                        <span style={{marginRight: 10}} className="checkbox"></span>
                                    </label>
                                ) :
                                    is_multi_step ? (
                                        <div className="loader">
                                            <MultiStepGoalIcon
                                                completed_tasks={completed_tasks_count}
                                                tasks={tasks_count}
                                            />
                                        </div>
                                    ) : (
                                        <label className="d-flex justify-content-center goals-modal-checkbox">
                                            <input onChange={() => { }} type="checkbox" id="pacing-calendar" value="" checked={isChecked} />
                                            <span style={{marginRight: 10}} className="checkbox"></span>
                                        </label>
                                    )
                                }


                            </div>
                            <div className="steps-labels">

                                {complete_by && (
                                    <span className="step complete goal-completed-label">
                                        {`Complete by ${moment(complete_by).format('MMMM D')}`}
                                    </span>
                                )}
                                {is_multi_step && (
                                    <span className="step complete">
                                        Multi-Step Goal
                                    </span>
                                )}
                                {is_recurring && (
                                    <span className="step complete">
                                        Recurring
                                    </span>
                                )}
                            </div>
                            <div className="video-descs-container">
                                <div id="descr" onScroll={onScrollText} ref={videoDescRef} className={`video-descs`}>
                                    <div>
                                        <p id="goal-text-desc" ref={descriptionParaRef}>
                                            <HtmlParser html={instructional_text || ''} />
                                        </p>
                                        {showIndicator && (
                                            <div className="scroll-indicator"></div>
                                        )}
                                        <div className={`seeMore-btn ${!isScrolled ? '' : 'd-none'}`}>
                                            {/* <button onClick={onClickSeeMore}>See more</button> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="right-sec">
                            <div className="header">
                                <h3 className="heading">To Complete This Goal:</h3>
                                <p className="desc">Click Tasks Below for Actions</p>
                            </div>
                           
                            <div className="to-complete-goal">
                                <ScrollingText
                                    containerStyles={{paddingLeft:"8px", paddingRight:"8px"}} 
                                    elementStyles={{
                                        fontSize:"22px",
                                        lineHeight:"29.96px",
                                        fontWeight:700,
                                        fontFamily:"Open Sans",
                                        color:"#323233"
                                    }}
                                    animateFirstTime={false}
                                    tag="h3"
                                >
                                    <HtmlParser html={goal || ''} />
                                </ScrollingText>
        
                                <div className="task-complete-sec">
                                    {tasks.map((item: any, index:number) => {
                                        const { task, task_action_name, group, checked, is_enabled, label = '', id } = item as any;
                                        const isPrevGroupSame = index > 0 ? group === tasks[index - 1].group : false;
                                        // const isHeader = false;
                                        return (
                                            <div>
                                                {!isPrevGroupSame && group && (
                                                    <p className="goals-title">{group}</p>
                                                )}
                                                <div className={`${label ? 'abc' : ''} ${group ? 'goals-body ' : 'complete-goals-body '}`}>
                                                    {label && <div className="header-total">Total Formatives Completed: 6</div>}
                                                    {(task_action_name !== 'Open Mastery Video' || id === 1 || id === 2 || id === 3 || id === 4 || id === 5) && (
                                                        <div className={`complete-goal-checkbox ${is_enabled ? '' : 'disbale'}`}>
                                                            <label onClick={() => pdAdminGoal ? downloadFileOnly(item) : onClickTask(item)} className="d-flex justify-content-center goals-modal-checkbox">
                                                                <input onChange={() => { }} checked={checked} type="checkbox" id="pacing-calendar" value="" />
                                                                <span className="checkbox"></span>
                                                            </label>
                                                            <Title task={task} onClick={() => pdAdminGoal ? downloadFileOnly(item) : onClickTask(item)} />
                                                        </div>
                                                    )}
                                                    {((![1, 2, 3, 4, 5].includes(id)) && task_action_name === 'Open Mastery Video') && <div className={`complete-goal-checkbox disbale coming-soon-top`}>
                                                        <div className={"coming-soon"}></div>
                                                        <div className={"coming-soon-text"}>Coming Soon</div>
                                                        <label className="d-flex justify-content-center goals-modal-checkbox">
                                                            <input onChange={() => { }} checked={checked} type="checkbox" id="pacing-calendar" value="" />
                                                            <span className="checkbox"></span>
                                                        </label>
                                                        <Title task={task} onClick={() => {}} />
                                                    </div>}
                                                </div>
                                            </div>

                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>}

            </CustomModal>

        </>
    );
};

export default GoalModal;