/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as HoweIconWhite } from "assets/img/howe-icon-white.svg";

interface viewClassesModalProps {
  isShow: boolean;
  data: any;
  onClose: () => void;
}

const IconStyle= {
  marginRight: 16,
  width: 22,
  height: 20
}

export const ViewClassesModal: React.FC<viewClassesModalProps> = ({
  isShow,
  data,
  onClose,
}) => {
  return (
    <Modal centered show={isShow} onEscapeKeyDown={onClose}>
      <div className="modal__header">
        <h6 className="modal__header-title">
          <HoweIconWhite style={IconStyle}/>{data?.last_name} {data?.first_name} Classes
        </h6>
      </div>
      <div className="modal__body">
        <p className="modal__body-text">
          {data.admin_classrooms.length ? 
            <table className="admins__table">
              <thead>
                <tr className="admins__table-row">
                  <th className="text-left">Class Name</th>
                  <th className="text-right">Is Teacher</th>
                </tr>
              </thead>
              <tbody>
                {data.admin_classrooms.map((item: any) => {
                  return (
                    <tr>
                      <td className="text-left shadow-text fw-bold">{item.classrooms?.name}</td>
                      <td className="text-right shadow-text fw-bold">{item.is_teacher ? 'Yes': 'No'}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table> 
            : <p className="text-center mt-2">No classes available for this admin</p>
          }
        </p>
      </div>
      <div className="modal__footer">
        <button className="btn success-btn btn-lg" onClick={onClose}>
          Got it
        </button>
      </div>
    </Modal>
  );
};
