import { FC, useMemo, useRef } from "react"
import PrintHeader from "components/common/report/Print/header";
import InfoSection from "../infoSection";
import TableHeader from "../Table/header"
import TableBody from "../Table/body"
import PrintFooter from "components/common/report/Print/footer";
import { isChrome, isFirefox, isSafari ,browserHeights } from "util/index";

interface Props {
    records: any,
    total_students: number
}

const AuditPrint: FC<Props> = ({ records, total_students }) => {


    const indexRef = useRef<number>(0)
    const Content = useMemo(() => {
        
        const toPrint = []
        let currentPage = 0
        indexRef.current = 0

        const PageHeight = () => {
            if (isFirefox )
                return 900
            else if(isSafari){
                return 850
            }
            else if (isChrome)
                return 1000

            return 900
        }

        const recordsGenerator = () => {

            const infoHeight = 125
            const rowHeight = 25

            let pageHeight = PageHeight();
            const recordsToPrint = []

            pageHeight = currentPage ? pageHeight : pageHeight - infoHeight

            while (pageHeight >= rowHeight) {
                const record = records[indexRef.current]
                if (indexRef.current >= records.length) {
                    break
                }
                if (record) {
                    recordsToPrint.push(
                        <TableBody
                            record={record}
                        />
                    )
                }
                indexRef.current++
                pageHeight -= rowHeight
            }
            return recordsToPrint
        }



        while (indexRef.current < records.length) {
            toPrint.push(<div className={`${currentPage ? 'add-page-break' : ''}`}>
                <PrintHeader
                    title="Audit Report"
                />
                <div className="auditReport__mainContainer audit-report-print">
                    <div className={`${browserHeights}`}>

                        {
                            !currentPage && <div className="auditReport__header-info">
                                <div className="auditReport__search"></div>
                                <InfoSection
                                    total_students={total_students}
                                />
                            </div>
                        }
                        <div className="auditReport__overflow">
                            <table className="auditReport__table">
                                <TableHeader />
                                <tbody>
                                    {recordsGenerator()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <PrintFooter />
            </div>)
            currentPage++;
        }
        return toPrint
    }, [records, total_students])

    return <>
        {Content}
    </>
}
export default AuditPrint