/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import QuestionMarkIcon from "assets/img/question-mark-tooltip-icon.png";
import AngleLeft from "assets/img/triangle-left.svg";
import AngleRight from "assets/img/triangle-right.svg";
import CustomModal from "components/common/Modal";

interface Props {
  show: boolean;
  setModalShow: any;
  isRandom: boolean
}

const HowToReadSummative: React.FC<Props> = (props) => {
  const { show, setModalShow, isRandom } = props;

  return (
    <>
      <CustomModal
        centered
        show={show}
        onEscapeKeyDown={() => setModalShow(false)}
        contentClassName="modal-read" >
        <div className="modal__header">
          <img
            src={QuestionMarkIcon}
            alt=""
            className="modal__header-iconQuestion"
          />
          <h2 className="modal__header-title">How to Read Progress</h2>
        </div>
        <div className="modal__subheader">
          <p className="modal__body-text mb-0">
            Questions are in order from left to right. The second row picks up
            where the top row left off.
          </p>
        </div>
        <div className="modal__body ps-0 pe-0">
          <div className="read__progress-content">
            <div className="read__progress-slider">
              <div className="read__progress-btn previous-btn">
                <span>1</span>
                <img src={AngleRight} alt="" />
              </div>
              <ul className="read__progress-list">
                {[...new Array(25)].map((item: any, i: number) => {
                  return (
                    <li key={i}></li>
                  )
                })}
              </ul>
              <div className="read__progress-btn next-btn">
                <img src={AngleLeft} alt="" />
                <span>25</span>
              </div>
            </div>
            <div className="read__progress-slider">
              <div className="read__progress-btn previous-btn">
                <span>26</span>
                <img src={AngleRight} alt="" />
              </div>
              <ul className="read__progress-list">
                {[...new Array(25)].map((item: any, i: number) => {
                  return (
                    <li key={i}></li>
                  )
                })}
              </ul>
              <div className="read__progress-btn next-btn">
                <img src={AngleLeft} alt="" />
                <span>50</span>
              </div>
            </div>

            <p className="read__progress-paragraph">
              As the student responds, the colors will change to reflect the
              correctness of their answer. The <strong>white</strong> outline
              indicates they are currently on that question. The box will be
              <strong> green</strong> if they answered{" "}
              <strong>correctly</strong> and <strong>red</strong> if the
              response was incorrect. Click <strong>anywhere</strong> on the
              boxes to see a <strong>detailed view</strong>.
            </p>
            <div className="read__progress-showCase">
              <ul className="read__progress-showList">
                <li>
                </li>
                <li>
                </li>
                <li className="fail-bg">
                </li>
                <li>
                </li>
                <li>
                </li>
                <li className="fail-bg">
                </li>
                <li>
                </li>
                <li className="fail-bg">
                </li>
                <li>
                </li>
                <li>
                </li>
                <li className="fail-bg">
                </li>
                <li className="fail-bg">
                </li>
                <li>
                </li>
                <li>
                </li>
                <li>
                </li>
                <li>
                </li>
                <li className="fail-bg">
                </li>
                <li className="fail-bg">
                </li>
                <li>
                </li>
                <li>
                </li>
                <li>
                </li>
                <li>
                </li>
                <li>
                </li>
                <li className="fail-bg">
                </li>
                <li className="fail-bg">
                </li>
                <li>
                </li>
                <li>
                </li>
                <li className="fail-bg">
                </li>
                <li>
                </li>
                <li>
                </li>
                <li>
                </li>
                <li className="fail-bg">
                </li>
                <li>
                </li>
                <li>
                </li>
                <li>
                </li>
                <li>
                </li>
                <li>
                </li>
                <li>
                </li>
                <li>
                </li>
                <li className="fail-bg">
                </li>
                <li className="fail-bg">
                </li>
                <li>
                </li>
                <li>
                </li>
                <li className="fail-bg">
                </li>
                <li>
                </li>
                <li>
                </li>
                <li>
                </li>
                <li className="fail-bg">
                </li>
                <li>
                </li>
                <li>
                </li>
                <li className="average-bg active">
                </li>
                <li className="average-bg">
                </li>
              </ul>
            </div>
          </div>
          {
            isRandom &&
            <p className="read__progress-paragraph">
              If the session is set to <strong>random choice order</strong>, each student will have their own unique random order of questions in the Student Portal. The question order shown here in Progress is the original order of the assessment and won’t match the student question numbers.
            </p>
          }
        </div>
        <div className="modal__footer">
          <button
            type="button"
            className="btn success-btn"
            onClick={() => setModalShow(false)}
          >
            Got It
          </button>
        </div>
      </CustomModal>
    </>
  );
};

export default HowToReadSummative;
