const Guide = ({onClick}:{onClick: () => void}) => {
    const titleId = "guidIcon"
    return (
        <span 
            onClick={(e) => {
                e.stopPropagation();
                onClick();
            }}
        >
            <svg aria-labelledby={titleId} xmlns="http://www.w3.org/2000/svg" width="30" height="21" viewBox="0 0 30 21" fill="none">
                <title id={titleId}>View</title>
                <path d="M4.35742 3.90479V17.1578H13.8764V3.90479H4.35742ZM12.2994 15.5808H5.93542V5.48279H12.2994V15.5808Z" fill="#232323"/>
                <path d="M16.1367 3.90479H25.6557V5.48279H16.1367V3.90479Z" fill="#232323"/>
                <path d="M16.1367 7.06104H22.5007V8.63904H16.1367V7.06104Z" fill="#232323"/>
                <path d="M16.1367 11.2681H25.6557V12.8461H16.1367V11.2681Z" fill="#232323"/>
                <path d="M16.1367 14.4238H22.5007V16.0018H16.1367V14.4238Z" fill="#232323"/>
                <path d="M4 1H26C27.933 1 29.5 2.567 29.5 4.5V16.5C29.5 18.433 27.933 20 26 20H4C2.067 20 0.5 18.433 0.5 16.5V4.5C0.5 2.567 2.067 1 4 1Z" stroke="#323233"/>
            </svg>
        </span>
    )
};

export default Guide;
