/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  Fragment,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from "react";
import { Modal, Spinner } from "react-bootstrap";
import BackArrow from "assets/img/remediate-arrow.png";
import moment from "moment";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import Checkbox from "assets/img/checkbox.svg";
import { useReactToPrint } from "react-to-print";
import Print from "./print";

interface Props {
  onClose: () => void;
  onClickQuestionMark: Function;
  fetchFormativeRemediateData: Function;
  formativeRemediateList: any;
  formativeId: any;
  reEnableAssessments: (payload: any) => Promise<any>;
  classroomId: any;
  onNewAttemptAssigned: Function;
}

const RemediateModal = forwardRef<any, Props>(
  (
    {
      onNewAttemptAssigned,
      classroomId,
      reEnableAssessments,
      formativeId,
      formativeRemediateList,
      fetchFormativeRemediateData,
      onClickQuestionMark,
      onClose,
    },
    ref
  ) => {
    // console.log('formativeRemediateList', formativeRemediateList);

    const [loading, setLoading] = useState(true);
    const [assignLoading, setAssignLoading] = useState(false);
    const componentRef = useRef(null);

    const { students, objective, status_key, non_proficient } =
      formativeRemediateList;
    const isDataExists = students.length > 0;

    const studentsToAttempt = students?.filter((student: any) => {
      const isNonProficient = !student?.proficient;
      const isFirstAttemptAvailable = student?.score === null;
      const isSecondAttemptAvailable =
        student?.attempt_2_score === null &&
        student?.status_attempt_2 !== "In Progress";
      const isThirdAttemptAvailable =
        student?.attempt_3_score === null &&
        student?.status_attempt_3 !== "In Progress";

      return (
        isNonProficient &&
        (isFirstAttemptAvailable ||
          isSecondAttemptAvailable ||
          isThirdAttemptAvailable)
      );
    });

    const isAttemptButtonEnabled = studentsToAttempt?.length > 0;

    const closeModal = () => {
      onClose();
    };

    const fetchData = async (formativeId: any) => {
      await fetchFormativeRemediateData(formativeId);
      setLoading(false);
    };

    useEffect(() => {
      fetchData(formativeId);
    }, [formativeId]);

    const onClickAssignNewAttempt = async () => {
      setAssignLoading(true);
      const results = await Promise.all(
        studentsToAttempt?.map((item: any) => {
          return reEnableAssessments({
            student_id: item.id,
            formative_id: formativeId,
            classroom_id: classroomId,
          });
        })
      );
      setAssignLoading(false);

      const isSuccess = results.every((result: boolean) => result);
      if (isSuccess) {
        onNewAttemptAssigned();
      }
    };

    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: `Remediate_Non-Proficient_Students_${
        formativeRemediateList?.objective
      }_${moment(new Date()).format("M/DD/YY hh:mmA")}`,
      removeAfterPrint: true,
      onAfterPrint: () => {
        window.close();
      },
    });

    const onClickPrint = () => {
      handlePrint();
    };

    const isAttemptDisabled =
      !isAttemptButtonEnabled || loading || assignLoading || !isDataExists;

    return (
      <>
        <Modal
          className="PacingCalender__AssessmentModel hide-on-print wayReport-modal assesment-remediated remediate-labels"
          centered
          show
          onEscapeKeyDown={closeModal}
        >
          <div id="remediate-modal" ref={ref}>
            <div className="d-flex modal-header">
              <div className="d-flex justify-content-between align-items-center">
                <div className="back-arrow" onClick={closeModal}>
                  <img
                    src={BackArrow}
                    alt="arrow"
                    className="cursor-pointer rotate-90"
                  />
                </div>
                <div>
                  <h2 className="modal__header-title">
                    Remediate Non-Proficient Students
                  </h2>
                  <h4 className="header-desc">
                    Students Below Were Non-Proficient and Have Yet to Complete
                    New Attempts
                  </h4>
                </div>
              </div>
              <button onClick={onClickPrint} className="print-btn">
                Print
              </button>
            </div>

            {non_proficient !== null && (
              <div className="proficient-perc-label">
                {non_proficient}% of Class is Non-proficient
              </div>
            )}

            {loading ? (
              <>
                <table>
                  <tbody>
                    <TableSkeleton columns={4} count={5} height={30} />
                  </tbody>
                </table>
              </>
            ) : students.length > 0 ? (
              <div className="way-report-modal-table-wrapper">
                <div className="overflow-auto rounded-2  PacingCalender__AssessmentModel__container">
                  <div className="rounded-2 PacingCalender__AssessmentModel__container__main">
                    <table className="PacingCalender__AssessmentModel__container__table modal-table rounded-2">
                      <span className="report-No">{objective}</span>
                      <thead className="PacingCalender__AssessmentModel__container__table__head">
                        <tr className=" headrow overflow-hidden">
                          <td className="text-white fw-bold td1 text-right">
                            Student Last
                          </td>
                          <td className="text-white fw-bold td2">First Name</td>
                          <td className="text-white fw-bold td3">
                            Attempt 1 <br /> Score
                          </td>
                          <td className="text-white fw-bold td3">
                            Attempt 2 <br /> Score
                          </td>
                          <td className="text-white fw-bold td3">
                            Attempt 3 <br /> Score
                          </td>
                          <td className="text-white fw-bold td4">
                            <p>Proficient</p>
                          </td>
                        </tr>
                      </thead>
                      <tbody className="PacingCalender__AssessmentModel__container__table__body">
                        {students.map((student: any) => {
                          const {
                            status_attempt_2,
                            status_attempt_3,
                            attempt_2_date,
                            attempt_3_date,
                            proficient,
                            first_name,
                            last_name,
                            id,
                            assessment_date,
                            score,
                            attempt_2_score,
                            attempt_3_score,
                          } = student;

                          return (
                            <Fragment key={id}>
                              <tr className="">
                                <td
                                  style={{ opacity: proficient ? 0.4 : 1 }}
                                  className="text-white td1 text-right"
                                >
                                  {last_name}
                                </td>
                                <td
                                  style={{ opacity: proficient ? 0.4 : 1 }}
                                  className="text-white td2 "
                                >
                                  {first_name}
                                </td>
                                <td
                                  style={{ opacity: proficient ? 0.4 : 1 }}
                                  className="text-white td3"
                                >
                                  <div className="d-flex justify-content-center align-items-center">
                                    <p>{`${score}%`}</p>
                                    {!!assessment_date && (
                                      <p className="label-date">
                                        {moment(assessment_date).format("M/D")}
                                      </p>
                                    )}
                                  </div>
                                </td>
                                <td
                                  style={{ opacity: proficient ? 0.4 : 1 }}
                                  className="text-white td3"
                                >
                                  <div className="d-flex justify-content-center align-items-center">
                                    {status_attempt_2 === "In Progress" ? (
                                      <div className="yellow"></div>
                                    ) : (
                                      <p>
                                        {attempt_2_score !== null
                                          ? `${attempt_2_score}%`
                                          : ""}
                                      </p>
                                    )}
                                    {!!attempt_2_date &&
                                      (attempt_2_score !== null ||
                                        status_attempt_2 === "In Progress") && (
                                        <p className="label-date">
                                          {moment(attempt_2_date).format("M/D")}
                                        </p>
                                      )}
                                  </div>
                                </td>
                                <td
                                  style={{ opacity: proficient ? 0.4 : 1 }}
                                  className="text-white td3"
                                >
                                  <div className="d-flex justify-content-center align-items-center">
                                    {status_attempt_3 === "In Progress" ? (
                                      <div className="yellow"></div>
                                    ) : (
                                      <p>
                                        {attempt_3_score !== null
                                          ? `${attempt_3_score}%`
                                          : ""}
                                      </p>
                                    )}
                                    {!!attempt_3_date &&
                                      (attempt_3_score !== null ||
                                        status_attempt_3 === "In Progress") && (
                                        <p className="label-date">
                                          {moment(attempt_3_date).format("M/D")}
                                        </p>
                                      )}
                                  </div>
                                </td>
                                <td
                                  style={{ opacity: proficient ? 0.4 : 1 }}
                                  className="text-white td4"
                                >
                                  {proficient ? (
                                    <div className="checked-img">
                                      <img src={Checkbox} alt="Checked" />
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </td>
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  height: 335,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  No data available!
                </p>
              </div>
            )}
            <div className="remediate-modal-footer">
              <div className="d-flex">
                {isDataExists && (
                  <>
                    <div className="label">Key</div>
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <div className="yellow"></div>
                        <p className="status">{status_key}</p>
                      </div>
                      <div className="label-date">Date</div>
                    </div>
                  </>
                )}
              </div>

              <div className="d-flex justify-content-end align-items-center">
                {isDataExists && (
                  <div
                    onClick={() => onClickQuestionMark()}
                    className="cursor-pointer info"
                  >
                    ?
                  </div>
                )}
                <button
                  style={{ opacity: isAttemptDisabled ? 0.4 : 1 }}
                  disabled={isAttemptDisabled}
                  onClick={onClickAssignNewAttempt}
                  type="button"
                  className="assign-attempt-btn"
                >
                  {assignLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Assign New Attempts"
                  )}
                </button>
              </div>
            </div>
          </div>
        </Modal>
        {formativeRemediateList && (
          <div ref={componentRef} className="printData">
            <Print report={formativeRemediateList} />
          </div>
        )}
      </>
    );
  }
);

export default RemediateModal;
