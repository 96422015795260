import PacingCalenders from "components/pacingCalender";
import Theme from "components/theme";
import PacingCalenderProvider from "contexts/pacingCalender";

const PacingCalender = () => {
  
  return (
    <Theme>
      <PacingCalenderProvider>
        <div className="mt-8 w-100">
          <PacingCalenders />
        </div>
      </PacingCalenderProvider>
    </Theme>
  );
};

export default PacingCalender;
