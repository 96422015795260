/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useEffect } from "react";
import { QuestionnaireContext } from '../QuestionnaireContext'
import { ReactComponent as SaveIcon} from 'assets/img/save.svg';
import CustomModal from "components/common/Modal";

const RemoveGroupingModal: FC<{}> = () => {
    const {
        groupedPassageDataForQuestion,
        removeGrouping,
        setShowGroupRemoveWarningModal,
        showGroupRemoveWarningModal,
        triggerPassageRemoveGroupingFor,
        setTriggerPassageRemoveGroupingFor,
    } = useContext(QuestionnaireContext)

    const onRemoveGrouping = async () => {
        if (triggerPassageRemoveGroupingFor === 2) {
            //@ts-ignore
            removeGrouping(groupedPassageDataForQuestion, true);
        } else {
            //@ts-ignore
            await removeGrouping(groupedPassageDataForQuestion, true, true);
        }
        setTriggerPassageRemoveGroupingFor(0);
        setShowGroupRemoveWarningModal(false);
    }

    const handlePress = (event :any) => {
        if(event.keyCode === 27){
            setShowGroupRemoveWarningModal(false);
        }
    }

    useEffect(() => {
        showGroupRemoveWarningModal && document.addEventListener('keydown', handlePress, false);

        return () => {
            document.removeEventListener('keydown', handlePress, false);
        }
    },[showGroupRemoveWarningModal])

    return (
        <CustomModal
            centered
            show={showGroupRemoveWarningModal}
            size="lg"
        >
            <div className="modal__header">
                <SaveIcon className="modal__header-icon" />
                <h6 className="modal__header-title">Remove Grouping?</h6>
            </div>
            <div className="modal__body">
                    <p className="para-margin">
                        {`This question is part of a group. Groups maintain their sequence when the assessment is randomized. Are you sure you want to clear this group? All questions in the group will be ungrouped.`}
                    </p>
            </div>
            <div className="modal__footer ">
                <button
                    onClick={() => setShowGroupRemoveWarningModal(false)}
                    className="btn cancel-btn btn-lg light mx-3"
                >
                    Cancel
                </button>
                <button
                    onClick={onRemoveGrouping}
                    className="btn success-btn btn-xl"
                >
                    Yes
                </button>
            </div>
        </CustomModal>
    );
};

export default RemoveGroupingModal;