import React, { CSSProperties } from 'react'


const SortIndicatorIcon = ({OrderByColumn, style={}, stroke}:{OrderByColumn: string, style?:CSSProperties, stroke?: string}) => {

const titleId = "sortIcon"
  return (
    <svg
    aria-labelledby={titleId}
    style={style}
    className={`${
      OrderByColumn === "ASC"
        ? "arrow-up-classname"
        : "arrow-down-classname"
    }`}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
  >
    <title id={titleId}>Sort</title>
    <path
      d="M7 10.0154L12.259 1L1.74104 1L7 10.0154ZM7 12L0 0L14 0L7 12Z"
      fill={stroke || "#323233"}
    />
  </svg>
  )
}

export default SortIndicatorIcon
