/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */

import React, { useState, useEffect } from "react";
import PdfIcon from "assets/img/pdf-alt.svg";
import wwwIcon from "assets/img/www.png";
import ZipIcon from "assets/img/zip-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { getActivityResources } from "redux/actionCreators/activity";
import { getWelcomeMessageClassroom } from "redux/actionCreators/classroom";
import { downloadPdfFile } from "util/index";
import Cookies from "js-cookie";
import { getGradeList } from "services/get-grades-list";
import { GradeSelect } from "components/common/selectComponent";
import { CustomSkeleton } from "components/common/skeleton/TableSkeleton";
import { setFilters } from "redux/actionCreators/activity";
import { AppCookies } from "util/constants";

interface RootState {
  auth: any;
  activity: any;
  classroom: any;
}

interface Props {
  book_access: string;
}
const Resources: React.FC<Props> = ({ book_access }) => {
  const has_books_access = !(book_access === "No" || book_access === "None");
  const dispatch = useDispatch();
  const app = useSelector((state: RootState) => state);
  const [books, setBooks] = useState([]);
  const [assessments16, setAssessments16] = useState([]);
  const [assessments21, setAssessments21] = useState([]);
  const [pd, setPd] = useState([]);
  const [other, setOther] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [, setIsClassroom] = useState(false);
  const userRole = Cookies.get(AppCookies.role);
  const [grades, setGradeList] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState([]);

  useEffect(() => {
    setBooks([]);
    setAssessments16([]);
    setAssessments21([]);
    setPd([]);
    setOther([]);
    const fetchGrades = async () => {
      let gradeResponse = await getGradeList();
      setGradeList(gradeResponse?.data?.grades);
      setSelectedGrade(app.classroom.grade);
    };
    fetchGrades();
  }, [app.classroom.classroomSchoolId]);

  useEffect(() => {
    setSelectedGrade(app.classroom.grade);
  }, [app.classroom.grade]);

  const fetchWelcomeMessage = (
    paramType: string,
    role_id: number,
    grade_id: number,
    subject_id: number
  ) => {
    const params = {
      type: paramType,
      grade_id,
      subject_id,
      role_id,
    };
    try {
      dispatch(getWelcomeMessageClassroom(params));
    } catch (e: any) {
      console.log(e.message);
    }
  };

  const changeMessage = () => {
    if (app.classroom.subject > 0 && app.classroom.grade > 0) {
      setIsClassroom(true);
      fetchWelcomeMessage(
        "SUBJECT_AND_GRADE",
        0,
        app.classroom.grade,
        app.classroom.subject
      );
    } else {
      setIsClassroom(false);
    }
  };

  useEffect(() => {
    dispatch(setFilters(true));
  }, []);

  useEffect(() => {
    const init = async () => {
      if (
        app.activity.type === "SET_FILTERS" &&
        app.activity.applyFilters === true
      ) {
        if (app.classroom.classroomId > 0) {
          setIsLoading(true);
          const allResources: any = await getActivityResources(
            app.classroom.grade,
            app.classroom.subject,
            app.classroom.classroomId
          );
          const allGlobalResources: any = await getActivityResources(
            0,
            0,
            app.classroom.classroomId
          );
          if (allResources.data.data) {
            setBooks(allResources.data.data.books);
            setAssessments16(allResources.data.data.assessments_2016);
            setAssessments21(allResources.data.data.assessments_2021);
            setOther(allGlobalResources.data.data.other);
            setPd(allResources.data.data.pd);
          }
          changeMessage();
          setIsLoading(false);
          // setTimeout(() => {setIsLoading(false)}, 2000);
        } else {
          setBooks([]);
          setAssessments16([]);
          setAssessments21([]);
          setOther([]);
          setPd([]);
          setIsClassroom(false);
        }
      } else {
        setIsLoading(false);
      }
    };
    init();
  }, [app.activity.type, app.activity.applyFilters, app.classroom.classroomId]);

  const handleClickRecord = (item: any) => {
    if (item.is_file) {
      downloadPdfFile(item.file_url, item.filename, item.file_type);
    } else {
      let url = item.file_url;
      window.open(
        url.includes("http") ? item.file_url : "https://".concat(item.file_url),
        "_blank"
      );
    }
  };
  const school = app.activity.schoolsList?.find(
    (sch: any) => sch.id === app.classroom.classroomSchoolId
  );
  const access_resources =
    app.classroom.classroomSchoolId > 0 && app.classroom.classroomId > 0
      ? school?.has_pdf_access
      : true;

  const classInfo = app.classroom.classrooms?.find(
    (item: any) => item.id === app.classroom.classroomId
  );
  const isOnlyResourcesClass =
    classInfo?.students_count === 0 || classInfo?.fk_grade_id === 9;

  const changeResources = async (gradeId: any) => {
    const allResources: any = await getActivityResources(
      gradeId,
      app.classroom.subject,
      app.classroom.classroomId
    );
    const allGlobalResources: any = await getActivityResources(
      0,
      0,
      app.classroom.classroomId
    );
    if (allResources.data.data) {
      setBooks(allResources.data.data.books);
      setAssessments16(allResources.data.data.assessments_2016);
      setAssessments21(allResources.data.data.assessments_2021);
      setOther(allGlobalResources.data.data.other);
      setPd(allResources.data.data.pd);
    }
  };

  const show_22_23_assessment =
    app?.classroom?.classroomId > 0 &&
    app?.classroom?.subject !== 1 &&
    app?.classroom?.subject !== 2;

  return (
    <>
      <div className="classroom">
        <div className="section light-bg resources-section">
          <div className="classroom__tabs">
            <div className="activity__welcome-center">
              <div className="activity__welcome-centerContent"></div>

              <>
                <div className="classroom__tabs-container">
                  <div
                    className={`classroom__tabs-inner ${
                      access_resources ? "" : "disabled-resources"
                    }`}
                  >
                    <nav className="classroom__tabs-header position-relative resources-content">
                      <div
                        className="nav nav-tabs classroom__tabs-buttons"
                        id="nav-tab"
                        role="tablist"
                      >
                        <button
                          className="nav-link btn text-btn active"
                          id="nav-books-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-books"
                          type="button"
                          role="tab"
                          aria-controls="nav-books"
                          aria-selected="true"
                          disabled={access_resources ? false : true}
                        >
                          Books
                        </button>

                        <button
                          className="nav-link btn text-btn"
                          id="nav-assessments-21-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-assessments-21"
                          type="button"
                          role="tab"
                          aria-controls="nav-assessments-21"
                          aria-selected="false"
                          disabled={access_resources ? false : true}
                        >
                          2024-25 Assessments
                        </button>

                        {show_22_23_assessment && (
                          <button
                            className="nav-link btn text-btn"
                            id="nav-assessments-16-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-assessments-16"
                            type="button"
                            role="tab"
                            aria-controls="nav-assessments-16"
                            aria-selected="false"
                            disabled={access_resources ? false : true}
                          >
                            2023-24 Assessments
                          </button>
                        )}

                        <button
                          className="nav-link btn text-btn"
                          id="nav-pd-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-pd"
                          type="button"
                          role="tab"
                          aria-controls="nav-pd"
                          aria-selected="false"
                          disabled={access_resources ? false : true}
                        >
                          PD
                        </button>
                        <button
                          className="nav-link btn text-btn"
                          id="nav-other-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#nav-other"
                          type="button"
                          role="tab"
                          aria-controls="nav-other"
                          aria-selected="false"
                          disabled={access_resources ? false : true}
                        >
                          Other
                        </button>
                      </div>

                      <div className="grade-wrapper">
                        {app.classroom.subject ? (
                          <GradeSelect
                            onChangeHandler={(e: any) => {
                              setSelectedGrade(e.id);
                              changeResources(e.id);
                            }}
                            options={grades}
                            selected={selectedGrade}
                            isDisabled={false}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </nav>
                    {isLoading && app.classroom.classroomId > 0 ? (
                      <CustomSkeleton columns={5} count={6} height={35} />
                    ) : (
                      <div className={`tab-content`} id="nav-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="nav-books"
                          role="tabpanel"
                          aria-labelledby="nav-books-tab"
                        >
                          <div className="classroom__tabs-content">
                            {has_books_access && books.length ? (
                              <>
                                {books &&
                                  books.map((x: any, i: any) => {
                                    return (
                                      <>
                                        <div
                                          onClick={() => handleClickRecord(x)}
                                          className="cursor-pointer classroom__tabs-contentRow"
                                        >
                                          <div
                                            className={`classroom__tabs-contentPdf dark-background`}
                                          >
                                            {x.is_file ? (
                                              <img
                                                src={
                                                  x.file_type === "ZIP"
                                                    ? ZipIcon
                                                    : PdfIcon
                                                }
                                                alt={
                                                  x.file_type === "ZIP"
                                                    ? "zip-icon........"
                                                    : "pdf-icon........"
                                                }
                                                className="zip-icon"
                                              />
                                            ) : (
                                              <img src={wwwIcon} alt="url" />
                                            )}
                                            <p>{x.filename.split("/").pop()}</p>
                                          </div>
                                          <div
                                            className={`classroom__tabs-contentText light-background`}
                                          >
                                            <p>{x.description}</p>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                              </>
                            ) : (
                              <>
                                <div className="classroom__tabs-empty">
                                  <p>
                                    {app.classroom.classroomId > 0
                                      ? !has_books_access
                                        ? "You don't have access to books resources."
                                        : "No resources found"
                                      : "Select a classroom above to view resources."}
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-assessments-16"
                          role="tabpanel"
                          aria-labelledby="nav-assessments-tab"
                        >
                          <div className="classroom__tabs-content">
                            {assessments16.length ? (
                              <>
                                {assessments16 &&
                                  assessments16.map((x: any, i: any) => {
                                    return (
                                      <>
                                        <div
                                          onClick={() => handleClickRecord(x)}
                                          className={`cursor-pointer classroom__tabs-contentRow ${
                                            isOnlyResourcesClass &&
                                            userRole === "Classroom Admin"
                                              ? "disabled-resources"
                                              : ""
                                          }`}
                                        >
                                          <div
                                            className={`classroom__tabs-contentPdf dark-background`}
                                          >
                                            {x.is_file ? (
                                              <img
                                                src={
                                                  x.file_type === "ZIP"
                                                    ? ZipIcon
                                                    : PdfIcon
                                                }
                                                alt={
                                                  x.file_type === "ZIP"
                                                    ? "zip-icon........"
                                                    : "pdf-icon........"
                                                }
                                                className="zip-icon"
                                              />
                                            ) : (
                                              <img src={wwwIcon} alt="url" />
                                            )}
                                            <p>{x.filename.split("/").pop()}</p>
                                          </div>
                                          <div
                                            className={`classroom__tabs-contentText light-background`}
                                          >
                                            <p>{x.description}</p>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                              </>
                            ) : (
                              <>
                                <div className="classroom__tabs-empty">
                                  <p>
                                    {app.classroom.classroomId > 0
                                      ? "No resources found"
                                      : "Select a classroom above to view resources."}
                                  </p>
                                </div>
                              </>
                            )}
                            {isOnlyResourcesClass &&
                              userRole === "Classroom Admin" && (
                                <div className="permission tab-permission">
                                  <div className="permission-text">
                                    <p>
                                      Your class doesn't have access to these
                                      resources. Please contact your school
                                      administrator and let them know you are
                                      interested in our assessment PDFs.
                                    </p>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-assessments-21"
                          role="tabpanel"
                          aria-labelledby="nav-assessments-tab"
                        >
                          <div className="classroom__tabs-content">
                            {assessments21.length ? (
                              <>
                                {assessments21 &&
                                  assessments21.map((x: any, i: any) => {
                                    return (
                                      <>
                                        <div
                                          onClick={() => handleClickRecord(x)}
                                          className={`cursor-pointer classroom__tabs-contentRow ${
                                            isOnlyResourcesClass &&
                                            userRole === "Classroom Admin"
                                              ? "disabled-resources"
                                              : ""
                                          }`}
                                        >
                                          <div
                                            className={`classroom__tabs-contentPdf dark-background`}
                                          >
                                            {x.is_file ? (
                                              <img
                                                src={
                                                  x.file_type === "ZIP"
                                                    ? ZipIcon
                                                    : PdfIcon
                                                }
                                                alt={
                                                  x.file_type === "ZIP"
                                                    ? "zip-icon........"
                                                    : "pdf-icon........"
                                                }
                                                className="zip-icon"
                                              />
                                            ) : (
                                              <img src={wwwIcon} alt="url" />
                                            )}
                                            <p>{x.filename.split("/").pop()}</p>
                                          </div>
                                          <div
                                            className={`classroom__tabs-contentText light-background`}
                                          >
                                            <p>{x.description}</p>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                              </>
                            ) : (
                              <>
                                <div className="classroom__tabs-empty">
                                  <p>
                                    {app.classroom.classroomId > 0
                                      ? "No resources found"
                                      : "Select a classroom above to view resources."}
                                  </p>
                                </div>
                              </>
                            )}
                            {isOnlyResourcesClass &&
                              userRole === "Classroom Admin" && (
                                <div className="permission tab-permission">
                                  <div className="permission-text">
                                    <p>
                                      Your class doesn't have access to these
                                      resources. Please contact your school
                                      administrator and let them know you are
                                      interested in our assessment PDFs.
                                    </p>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-pd"
                          role="tabpanel"
                          aria-labelledby="nav-pd-tab"
                        >
                          <div className="classroom__tabs-content">
                            {pd.length ? (
                              <>
                                {pd &&
                                  pd.map((x: any, i: any) => {
                                    return (
                                      <>
                                        <div
                                          onClick={() => handleClickRecord(x)}
                                          className="cursor-pointer classroom__tabs-contentRow"
                                        >
                                          <div
                                            className={`classroom__tabs-contentPdf dark-background`}
                                          >
                                            {x.is_file ? (
                                              <img
                                                src={
                                                  x.file_type === "ZIP"
                                                    ? ZipIcon
                                                    : PdfIcon
                                                }
                                                alt={
                                                  x.file_type === "ZIP"
                                                    ? "zip-icon........"
                                                    : "pdf-icon........"
                                                }
                                                className="zip-icon"
                                              />
                                            ) : (
                                              <img src={wwwIcon} alt="url" />
                                            )}
                                            <p>{x.filename.split("/").pop()}</p>
                                          </div>
                                          <div
                                            className={`classroom__tabs-contentText light-background`}
                                          >
                                            <p>{x.description}</p>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                              </>
                            ) : (
                              <>
                                <div className="classroom__tabs-empty">
                                  <p>
                                    {app.classroom.classroomId > 0
                                      ? "No resources found"
                                      : "Select a classroom above to view resources."}
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-other"
                          role="tabpanel"
                          aria-labelledby="nav-other-tab"
                        >
                          <div className="classroom__tabs-content">
                            {has_books_access && other.length ? (
                              <>
                                {other &&
                                  other.map((x: any, i: any) => {
                                    return (
                                      <>
                                        <div
                                          onClick={() => handleClickRecord(x)}
                                          className="cursor-pointer classroom__tabs-contentRow"
                                        >
                                          <div
                                            className={`classroom__tabs-contentPdf dark-background`}
                                          >
                                            {x.is_file ? (
                                              <img
                                                src={
                                                  x.file_type === "ZIP"
                                                    ? ZipIcon
                                                    : PdfIcon
                                                }
                                                alt={
                                                  x.file_type === "ZIP"
                                                    ? "zip-icon........"
                                                    : "pdf-icon........"
                                                }
                                                className="zip-icon"
                                              />
                                            ) : (
                                              <img src={wwwIcon} alt="url" />
                                            )}
                                            <p>{x.filename.split("/").pop()}</p>
                                          </div>
                                          <div
                                            className={`classroom__tabs-contentText light-background`}
                                          >
                                            <p>{x.description}</p>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                              </>
                            ) : (
                              <>
                                <div className="classroom__tabs-empty">
                                  <p>
                                    {app.classroom.classroomId > 0
                                      ? "No resources found"
                                      : "Select a classroom above to view resources."}
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {!access_resources && (
                    <div className="permission">
                      <div className="permission-text">
                        <p>
                          Your school doesn’t have access to these resources.
                          Please contact your school administrator and let them
                          know you’re interested in our interactive textbooks
                          and PDFs.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resources;
