import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";

export const useSchoolName = () => {
    const schoolId = useSelector(
        (state: RootState) => state.classroom.classroomSchoolId
    );
    const schools = useSelector(
        (state: RootState) => state.activity.schoolsList
    );

    const selectedSchool = schools.find((item: any) => item.id === schoolId);

    return selectedSchool?.name || ''
}