/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from "react";
import SearchIconGray from "assets/img/search-icon-gray.svg";
import StudentTableHeader from "components/StandardReports/Based/objectiveTableHeader";
import { DetailSkeleton } from "components/common/skeleton/ReportsSkeleton";
import Record from "components/StandardReports/Based/detailsRecord";

type StandardBasedType = {
  isLoading: boolean;
  detailsRef: any;
  data: any;
  setDescriptionRowsHeights: (val: any) => void;
  lang: string;
};

const StandardBasedReport = ({
  isLoading,
  detailsRef,
  data,
  setDescriptionRowsHeights,
  lang = "en",
}: StandardBasedType) => {
  const [search, setSearch] = useState<string>("");
  const { objectives } = useMemo(() => {
    if (data) {
      return {
        ...data,
      };
    }
    return {
      objectives: [],
      student_name: "",
      student_id: "",
      id: 0,
    };
  }, [data]);

  useEffect(() => {
    const data = objectives;
    if (data?.length && detailsRef?.current) {
      setDescriptionRowsHeights(
        [...detailsRef.current.childNodes].map(
          (node: any) => node.offsetHeight * 0.68
        )
      );
    }
  }, [isLoading]);

  const information = useMemo(() => {
    if (search) {
      return objectives.filter(
        (record: any) =>
          record?.objective_number
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          record[
            lang === "en" ? "objective_description" : "objective_description_es"
          ]
            ?.toLowerCase()
            .startsWith(search.toLowerCase())
      );
    }
    return objectives;
  }, [objectives, search]);

  return (
    <div className="reportStandardBased">
      <div className="reportStandardBased__header">
        <section className="reportStandardBased__header-heading">
          <h3 className="reportStandardBased__header-title">
            {data?.classroom_name}
          </h3>
        </section>
      </div>
      <div className="reportStandardBased_based-reports-container">
        <div className="based-report__info">
          <div className="based-report__info-search">
            {!isLoading && (
              <img
                src={SearchIconGray}
                className="report__search-icon animated-fade"
                alt="Search"
              />
            )}
            <input
              className={`form-control report__search-input ${
                isLoading ? "margin-left" : ""
              }`}
              type="search"
              name="Search"
              placeholder="Search Objectives"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <div className="based-report__main based-report-main">
          <div className="based-report-detail-container">
            <table className="based-report__detail">
              <StudentTableHeader />
              <tbody ref={detailsRef}>
                {!isLoading && information?.length === 0 && (
                  <tr className="based-report__detail-row">
                    <td className="report__table-notFound w-100 text-center">
                      No Objectives Found
                    </td>
                  </tr>
                )}
                {!isLoading ? (
                  information?.map((info: any) => {
                    return <Record {...info} lang={lang} rounded={true} />;
                  })
                ) : (
                  <DetailSkeleton rows={20} />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StandardBasedReport;
