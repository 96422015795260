export enum SuperadminChatBoxActions {
    SET_SUPER_ADMIN_CHAT_MESSAGE = 'SET_SUPER_ADMIN_CHAT_MESSAGE',
    UNSET_CHAT_BY_ID = 'UNSET_CHAT_BY_ID',
    SET_SUPER_ADMIN_CHAT = 'SET_SUPER_ADMIN_CHAT',
    SET_ACTIVE_CHATS = 'SET_ACTIVE_CHATS',
    SET_CHAT_BY_ID = 'SET_CHAT_BY_ID',
    ADD_MESSAGES_TO_CHAT = 'ADD_MESSAGES_TO_CHAT',
    RESOLVE_CHAT = 'RESOLVE_CHAT',
    SET_ACTIVE_CHATS_AVAILABLE = 'SET_ACTIVE_CHATS_AVAILABLE',
    SET_UNREAD_MESSAGES_COUNT = 'SET_UNREAD_MESSAGES_COUNT',
    SET_ACTIVE_CHATS_STATUS = 'SET_ACTIVE_CHATS_STATUS',
    ASSIGN_CHAT_TO_SUPER_ADMIN = 'ASSIGN_CHAT_TO_SUPER_ADMIN',
    UNSET_SUPER_ADMIN_CHAT = 'UNSET_SUPER_ADMIN_CHAT',
    SET_MASTERY_VIDEOS = 'SET_MASTERY_VIDEOS',
    UNSET_SUPER_ADMIN_CHAT_MESSAGE = 'UNSET_SUPER_ADMIN_CHAT_MESSAGE',
    SET_ACTIVE_CHATS_GLOBAL_STATUS = 'SET_ACTIVE_CHATS_GLOBAL_STATUS',    
}

export const SuperAdminChatboxReducer = (state: any, Action: any) => {
    const { type, payload } = Action

    switch (type) {
        case SuperadminChatBoxActions.SET_MASTERY_VIDEOS:
            return {
                ...state,
                videoOptions: payload.map((item: any) => {
                    return {
                        ...item,
                        label: item.name,
                        value: item.name
                    }
                })
            }
        case SuperadminChatBoxActions.SET_UNREAD_MESSAGES_COUNT:
            return {
                ...state,
                activeChats: {
                    ...state.activeChats,
                    chats: state.activeChats.chats.map((chat: any) => {
                        return payload.thread_id === chat.id 
                        ? {
                            ...chat,
                            unread_count: payload.unread_messages
                        }
                        : chat
                    })
                }
            }
        case SuperadminChatBoxActions.SET_ACTIVE_CHATS_AVAILABLE:
            return {
                ...state,
                activeChats: {
                    ...state.activeChats,
                    available: payload.active_count,
                    active_admins: payload.active_admins
                }
            }
        case SuperadminChatBoxActions.SET_ACTIVE_CHATS_STATUS:
            const newStatus = state.activeChats.chat_statuses.find((item: any) => item.id === payload);
            return {
                ...state,
                activeChats: {
                    ...state.activeChats,
                    status: newStatus?.status || ''
                }
            }
        case SuperadminChatBoxActions.SET_ACTIVE_CHATS:
            return {
                ...state,
                activeChats: payload
            }
        case SuperadminChatBoxActions.SET_ACTIVE_CHATS_GLOBAL_STATUS:
            
            const updatedActiveChats = {
                ...state.activeChats,
                global_status: payload.global_status,
                available: payload.available,
            }
            
            return {
                ...state,
                activeChats: updatedActiveChats
            }

        case SuperadminChatBoxActions.RESOLVE_CHAT:
            return {
                ...state,
                activeChats: {
                    ...state.activeChats,
                    chats: state.activeChats.chats.filter((chat: any) => chat.id !== payload)
                }
            }
        case SuperadminChatBoxActions.SET_SUPER_ADMIN_CHAT:
            return {
                ...state,
                activeChats: {
                    ...state.activeChats,
                    chats: state.activeChats.chats.map((chat: any) => {
                        return chat.id === payload.id
                            ? { ...payload, }
                            : chat
                    })
                }
            }
        case SuperadminChatBoxActions.SET_CHAT_BY_ID:
            return {
                ...state,
                singleAdminChat: payload
            }
        case SuperadminChatBoxActions.UNSET_CHAT_BY_ID:
            return {
                ...state,
                singleAdminChat: null!
            }
        case SuperadminChatBoxActions.SET_SUPER_ADMIN_CHAT_MESSAGE:{
            const updatedMessages = state.singleAdminChat.messages.filter((item: any) => item.tempId !== payload.tempId)
            return {
                ...state,
                singleAdminChat: {
                    ...state.singleAdminChat,
                    messages: [payload.messageData, ...updatedMessages],
                    messages_count: state.singleAdminChat.messages_count + 1
                }
            }
        }
        case SuperadminChatBoxActions.UNSET_SUPER_ADMIN_CHAT_MESSAGE:
            const filteredMessages = state.singleAdminChat.messages.filter((item: any) => item.tempId !== payload.tempId)
            return {
                ...state,
                singleAdminChat: {
                    ...state.singleAdminChat,
                    messages: filteredMessages,
                    messages_count: state.singleAdminChat.messages_count - 1
                }
            }
        case SuperadminChatBoxActions.ADD_MESSAGES_TO_CHAT: {
            const updatedMessages = [...state.singleAdminChat.messages, ...payload.messages];
            return {
                ...state,
                singleAdminChat: {
                    ...state.singleAdminChat,
                    messages: updatedMessages,
                    messages_count: payload.messages_count
                }
            }
        }
        case SuperadminChatBoxActions.UNSET_SUPER_ADMIN_CHAT:
            const returnedObj = {
                ...state,
                activeChats: {
                    ...state.activeChats,
                    assigned_chats: state.activeChats.assigned_chats - 1,
                    chats: state.activeChats.chats.filter((chat: any) => chat.id !== payload)
                }
            }
            return returnedObj
        default:
            return state
    }
}