/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from "react";
import { Button } from "../../common/buttonComponent";
import CustomModal from "components/common/Modal";

interface SelectTestTypeModalProps {
  isShow: boolean;
  onClose: () => void;
  onSave: (x: any) => void;
  currentData: any;
  hasTested: boolean;
  studentName: string;
  loading: boolean;
}

export const UnroleModel: React.FC<SelectTestTypeModalProps> = ({
  isShow,
  onClose,
  onSave,
  currentData,
  hasTested,
  studentName,
  loading,
}) => {
  const onSubmit = () => {
    onSave({ ...currentData });
  };

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={onClose}>
      <div className="modal__header">
        <h6 className="modal__header-title">
          {`Unenroll Student: ${studentName}`}{" "}
        </h6>
      </div>
      <div className="modal__body">
        <p>
          {hasTested
            ? "This student has assessments in progress. To transfer this progress when enrolling in another class with the same grade/subject, make sure the in-progress assessments are available to students in the new class."
            : "Student will be removed from the class but completed assessments will be retained for a future transfer."}
        </p>
      </div>
      <div className="modal__footer">
        <Button
          buttonText="Cancel"
          onClick={onClose}
          className="btn cancel-btn"
          type="button"
        />
        <Button
          buttonText="Unenroll"
          onClick={onSubmit}
          className="btn success-btn btn-lg"
          type="button"
          loading={loading}
        />
      </div>
    </CustomModal>
  );
};
