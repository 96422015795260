import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  registerables,
  Chart
} from "chart.js";
import { Bar } from "react-chartjs-2";
import _ from "lodash";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { ordinal_suffix_of } from "util/index";
import { externalToopTipHandler } from "util/externalTooltip";
import { useState } from "react";


Chart.register(...registerables);

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels,
  PointElement,
  LineElement,
);



const getChartData = ( data: any) => {
  let labels: string[] = [];
  let mathDataLinesTemplate: any[] = [];
  let ELADataLinesTemplate: any[] = [];
  let mathDataPointsTemplate: any[] = [];
  let ELADataPointsTemplate: any[] = [];
  const gradeMapper:any = {};
  data = _.orderBy(data,)
  data?.map((item:any) => {
    for (let key in item) {
      const [grade, subj] = key.split("-");
      if(!gradeMapper[grade as keyof typeof gradeMapper]){
        gradeMapper[grade as keyof typeof gradeMapper] = true;
        labels.push(`${ordinal_suffix_of(Number(grade))} Grade`);
      }

      if(subj.includes("Math")){
        mathDataPointsTemplate.push(item[key]['avg_proficiency']);
        mathDataLinesTemplate.push(item[key]['avg_formatives_tested']);
        if(!data.find((item: any)=>item.hasOwnProperty((grade+'-ELA').trim()))){
            ELADataPointsTemplate.push(null);
            ELADataLinesTemplate.push(null);
        }
      }else{
        ELADataPointsTemplate.push(item[key]['avg_proficiency']);
        ELADataLinesTemplate.push(item[key]['avg_formatives_tested']); 
        if(!data.find((item: any)=>item.hasOwnProperty((grade+'-Math').trim()))){
          mathDataPointsTemplate.push(null);
          mathDataLinesTemplate.push(null);
      }
      }
    }

    return item;
  })

  return { mathDataLinesTemplate, mathDataPointsTemplate, ELADataPointsTemplate, ELADataLinesTemplate, labels };
};



export const getOptions = (totalData:any, barData: any, animations: boolean | undefined) => {
  return {
    animations: animations,
    maintainAspectRatio: false,
    responsive: true,
    stacked: false,
    plugins: {
      tooltip: {
        enabled: false, 
        position: 'nearest',
        external: externalToopTipHandler
      },
      title: {
        display: true,
      },
      legend: {
        display: false,
      },
      datalabels: {
        align: "end",
        anchor: "end",
        color:  function(ctx:any) {
          if(ctx.dataset.type === 'bar'){
            return "#454545"
          }
          else{
            return 'white'
          }
        },
       font: { size: 16 },
        formatter: function (value: any, ctx: any) {
          if(ctx.dataset.type === 'bar'){
           return value > 0 && ctx.dataset.type === 'bar' ? value+ '%' : "";
          }else {
            return value.y
          }
        },
        backgroundColor: function(ctx:any) {
          if(ctx.dataset.type === 'bar'){
              return 'transparent'
          }
          else{
              return '#46AFB8'
          }
        },
        borderRadius: 17,
        padding: { left: 6, right: 6, top: 4, bottom: 3 },
      },
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        ticks:{
          minTicksLimit: 5,
          color: '#46AFB8',
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
        ticks: {
          color: '#537AB7',
          callback: function(value:any) {
              return value + '%';
          },
        },
      }
    },
  };
}

const getCombinedLineData = (ELAData: any[], mathData: any[], labels: string[]) => {
 
 const combinedData: any[] = []; 
 if(!mathData.length){
  labels.map((label: string , index: number)=>{
    combinedData.push({x: label, y: ELAData[index]} || null);
    return null;
  })
  }
  if(!ELAData.length){
    labels.map((label: string , index: number)=>{
      combinedData.push({x: label, y: mathData[index]}|| null);
      return null;
    })
  }
  if(mathData.length && ELAData.length)
  labels.map((label: string , index: number)=>{
    const elaDataPoint = ELAData[index] === 0 || ELAData[index] ? ELAData[index] : null;
    const mathDataPoint = mathData[index] === 0 || mathData[index] ? mathData[index] : null;
    combinedData.push({x: label, y: elaDataPoint});
    combinedData.push({x: label, y: mathDataPoint});
    return null;
  })
 
  return combinedData;

}

export const getData = (data1: number[], mathLineData: number[], data2: number[], ELALineData: number[], labels: string[]) => {
  const combinedLineData = getCombinedLineData(ELALineData,mathLineData, labels);

  return {
    labels,
    datasets: [
      {
        type: "bar",
        label: "ELA Dataset",
        data: data2,
        backgroundColor: '#30DBDB',
        yAxisID: 'y1',
        order: 1,
        datalabels: {
          align: "end",
          anchor: "end",
          color:  "#454545",
          font: { size: 16 },
          formatter: function (value: any, ctx: any) {
            return value > 0 && ctx.dataset.type === 'bar' ? value+ '%' : "";
          },
          backgroundColor: function(ctx:any) {
            return 'transparent'
          },
          borderRadius: 17,
          padding: { left: 6, right: 6, top: 4, bottom: 3 },
        },
      },
      {
        type: "bar",
        label: "Math Dataset",
        data: data1,
        backgroundColor: '#306FDC',
        yAxisID: 'y1',
        order: 1,
        datalabels: {
          align: "end",
          anchor: "end",
          color:  "#454545",
          font: { size: 16 },
          formatter: function (value: any, ctx: any) {
            return value > 0 && ctx.dataset.type === 'bar' ? value+ '%' : "";
          },
          backgroundColor: function(ctx:any) {
            return 'transparent'
          },
          borderRadius: 17,
          padding: { left: 6, right: 6, top: 4, bottom: 3 },
        },
      },
      {
        type: "line",
        label: "Combined Dataset",
        data: combinedLineData,
        borderColor: "#46AFB8",
        pointBackgroundColor: "#46AFB8",
        pointBorderColor: "#46AFB8",
        pointRadius: 3,
        yAxisID: 'y',
        order: 0,
        spanGaps: true,
        datalabels: {
          align: function(ctx:any){
            const dataPoint1 = ctx.chart.data.datasets[ctx.datasetIndex].data[ctx.dataIndex].y;
            if(!dataPoint1) return "end";

            const barDataIndex = Math.floor(ctx.dataIndex/2);
            
            const diff = ctx.chart.getDatasetMeta(ctx.dataIndex%2).data[barDataIndex].y - ctx.chart.getDatasetMeta(2).data[ctx.dataIndex].y;
            const absDiff = Math.abs(diff);
            
            if(absDiff <= 20){
              return 'start';
            }
            return "end";
          },
          anchor: function(ctx:any){
            const dataPoint1 = ctx.chart.data.datasets[ctx.datasetIndex].data[ctx.dataIndex].y;
            if(!dataPoint1) return "end";

            const barDataIndex = Math.floor(ctx.dataIndex/2);

            const diff = ctx.chart.getDatasetMeta(ctx.dataIndex%2).data[barDataIndex].y - ctx.chart.getDatasetMeta(2).data[ctx.dataIndex].y;
            const absDiff = Math.abs(diff)
            if(absDiff <= 20){
                return 'start';
            }
            return "end";
          },
          color:  "white",
          font: { size: 16 },
          formatter: function (value: any, ctx: any) {
            return value.y
          },
          backgroundColor: function(ctx:any) {
            return '#46AFB8'
          },
          borderRadius: 17,
          padding: { left: 6, right: 6, top: 4, bottom: 3 },
        },
      },
    ],
  };
};

export function SchoolProgressChart(props: any) {
  const [update, setUpdate] = useState(false);
  const { data, animations } = props;
  let { mathDataLinesTemplate, mathDataPointsTemplate, ELADataPointsTemplate, ELADataLinesTemplate, labels } = getChartData(data);
  const finalData = getData(mathDataPointsTemplate, mathDataLinesTemplate, ELADataPointsTemplate, ELADataLinesTemplate, labels);
  const isMathMax = Math.max(...mathDataLinesTemplate) > Math.max(...ELADataLinesTemplate);
  const isMathBarMax = Math.max(...mathDataPointsTemplate) > Math.max(...ELADataPointsTemplate);
  const options = getOptions(isMathMax ? mathDataLinesTemplate : ELADataLinesTemplate, isMathBarMax ? mathDataPointsTemplate : ELADataPointsTemplate, animations);

  const lineAlignment = {
    id: 'lineAlignment',
    beforeDatasetsDraw(chart:any){
      const {data} = chart;
      const lastDataset = data.datasets.length-1;
      const xCoor: any = [];

      for(let i = 0; i<data.labels.length; i++) {
        if(chart.getDatasetMeta(0).data.length)
        xCoor.push(chart.getDatasetMeta(0).data[i]?.x);
        if(chart.getDatasetMeta(1).data.length)
        xCoor.push(chart.getDatasetMeta(1).data[i]?.x);
      }

      chart.getDatasetMeta(lastDataset).data.forEach((dataPoint:any, index:number)=>dataPoint.x=xCoor[index]);
    },
    afterDatasetsUpdate: function(chart:any){
      
      if(!update && chart._metasets[2].data.length){
        setTimeout(()=>setUpdate(true),1000);
      }
    }
  }

  return (
    <div>
      <div >
        <Bar key={{...data}} options={options as any} plugins={[lineAlignment]} data={finalData as any}  className="report__printChart-chart"/>
      </div>
    </div>
  );
}


