import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "redux/actionCreators/auth";
import { Alert, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import IPage from "interfaces/page";
import logging from "config/logging";
import logo from "assets/img/login-logo.svg";
import * as Yup from "yup";
import Footer from "components/theme/footer";

interface RootState {
  auth: any;
}

const ForgotPassword: React.FunctionComponent<IPage> = (props) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const user = useSelector((state: RootState) => state);
  const [errors, setErrors] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props.name]);

  useEffect(() => {
    if (user.auth.type === "USER_FORGOT_PASSWORD_SUCCESS") {
      setIsSubmitting(false);
      setErrors(false);
      navigate("/login");
    } else if (user.auth.type === "USER_FORGOT_PASSWORD_FAIL") {
      setIsSubmitting(false);
      setErrors(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const initialValues = { email: "" };

  const onSubmit = (values: any) => {
    setIsSubmitting(true);

    const email = values.email;
    setIsSubmitting(true);
    dispatch(
      forgotPassword({
        email,
      })
    );
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Email is invalid").required("Email is required"),
  });

  const isFixFooter = pathname === "/reset-password";
  return (
    <>
      <section
        className={`vh-100 login ${isFixFooter ? "footer-login-page" : ""}`}
      >
        <div className="login-background"></div>
        <div className="login-container h-100">
          <div className="row login__content">
            <div className="col-sm-12 col-md-9 col-lg-7 col-xl-6 login__content-column p-0">
              <div className="card login__card">
                <div className="login__card-header">
                  <div className="">
                    <div className="brand-logo login__card-logo">
                      <img alt="alpha" src={logo} />
                    </div>
                    <div className="login__card-text">
                      <p>Password Reset</p>
                    </div>
                  </div>
                </div>
                <div className="card-body login__card-body text-center">
                  <div className="login__card-inputs">
                    <p className="login__card-description">
                      Enter your email below and click Send to receive a
                      password reset email.
                    </p>
                    {errors && (
                      <Alert
                        className="login-alert"
                        dismissible
                        onClose={() => setErrors(false)}
                        variant="danger"
                      >
                        Email not found
                      </Alert>
                    )}

                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({ errors, status, touched }) => (
                        <Form className="login__card-form">
                          <div className="form-outline">
                            <label>Email</label>
                            <Field
                              name="email"
                              type="text"
                              className={
                                "form-control" +
                                (errors.email && touched.email
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                          </div>

                          <div className="login__buttons forgot-password-btns">
                            <div className="d-flex">
                              <button
                                type="button"
                                className="btn btn-lg cancel-btn back-to-login"
                                onClick={() => navigate("/login")}
                              >
                                Back to Login
                              </button>
                              <button
                                className="btn login__card-btn"
                                type="submit"
                              >
                                {isSubmitting ? (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  "Send"
                                )}
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-md-9 col-lg-7 col-xl-6 login__content-column p-0">
              <div className="login__info">
                <p className="mb-0">
                  If your school hasn’t signed up for 2024-25, please call (405)
                  842-8408.
                </p>
              </div>
            </div>
          </div>

          <Footer showChatBox={false} />
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
