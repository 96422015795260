// types.ts

export interface YearOption {
  id: number;
  value: string;
  label: string;
  item: string;
}

export interface PerformanceOption {
  id: number;
  value: string;
  label: string;
  item: string;
}

export interface Student {
  id: string;
  full_name: string;
  has_previous_year: boolean;
}

export const yearOptions: YearOption[] = [
  { id: 1, value: "current", label: "Current", item: "" },
  { id: 2, value: "previous", label: "2023-24", item: "" },
];

export const performanceOptions: PerformanceOption[] = [
  { id: 1, value: "performance", label: "Performance", item: "" },
  { id: 2, value: "standard-based", label: "Standards-Based", item: "" },
];
