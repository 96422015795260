import React from "react";
import Theme from "components/theme/index";
import IPage from "interfaces/page";
import MasteryVideos from "components/masteryVideo";

const MasteryVideo: React.FunctionComponent<IPage> = (props) => {
  return (
    <Theme>
      <MasteryVideos />
    </Theme>
  );
};
export default MasteryVideo;
