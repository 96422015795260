import PreviewAssessmentContainer from "components/CMS/PreviewAssessment";
import Layout from "components/CMS/Layout";


const PreviewAssessment = () => {
    
    return(
        <Layout>
            <PreviewAssessmentContainer />
        </Layout>
    );
}

export default PreviewAssessment;