/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import classnames from "classnames";
import ArrowIcon from "assets/img/steps-arrow.svg";
import { TicketType } from "./ContactSupportModal";

interface Step1Props {
  goToNext: (nextStep: number) => void;
  handleOption: (name: string, value: any) => void;
  options: any[]
}

export const Step1: React.FC<Step1Props> = ({ goToNext, handleOption, options }) => {
  const handleClickOption = (name: string, value: any) => {
    handleOption(name, value);
    if (value === TicketType.BUG) {
      goToNext(2);
    }
    else {
      goToNext(3);
    }
  };

  return (
    <div className="supportStep support">

      <div className="support-container first-step">
        <p className="support-title">Please choose an option below:</p>
        {options.map((item, i) => {
          return (
            <div
              className={classnames(
                "support__steps",
                i % 2 === 0 ? "" : "dark",
                i === 0 ? "first" : "",
                i === options.length - 1 ? "last" : "",
              )}
              onClick={() => handleClickOption("option", item.name)}
            >
              <span>{item.label}</span>
              <img src={ArrowIcon} alt='select' className="support__steps-icon" />
            </div>
          );
        })}
      </div>

    </div>
  );
};
