import { ChangeEvent, useCallback, useContext, useMemo, useState } from "react";
import { PacingCalenderContext } from "contexts/pacingCalender";
import HeaderDropDown from "./HeaderDropdown";
import SearchIcon from "assets/img/search-icon.svg";
// import DeleteImage from "assets/img/del-img-icon-black-outline.svg";
import { debounce } from "lodash";
// import CheckHead from "assets/img/check-header.png";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import { useNavigate } from "react-router-dom";
import ClassroomTooltip from "components/common/classroomTooltip";
import {
  enableAssessment,
  enableAllMathClasses,
} from "services/pacingCalender";
import { ChooseQuestionOrder } from "components/classroom/modals/chooseQuestionOrder";
import { ConfirmModal } from "components/common/confirmModal";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";
import Toggle from "components/common/Toggle";

const PacingCalHeader = ({ setModalDelShow, showTooltipPacing }: any) => {
  const app = useSelector((state: RootState) => state);

  const selectedClassroom = app?.classroom?.classrooms?.find(
    (item: any) => item.value === app?.classroom?.classroomId
  );

  const {
    currentGradeIndex,
    updateCurrentGrade,
    handleNextPreviousGrade,
    isSuperAdmin,
    allMonths,
    updateCurrentMonth,
    currentMonthIndex,
    handleNextPreviousMonth,
    gradeSubjects,
    updateSearch,
    assessments,
    setAssesments,
    classroomId,
  } = useContext(PacingCalenderContext);

  const {
    sharing_schedule,
    admin_name,
    grade,
    subject,
    automatically_enable_scheduled,
    is_random,
    same_classroom_exists,
  } = assessments;

  const [openRandomModal, setOpenRandomModal] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const navigate = useNavigate();

  const { months, subjects } = useMemo(() => {
    const months =
      allMonths?.map((month, index) => ({
        value: index,
        label: month.month,
      })) || [];
    const subjects =
      gradeSubjects?.map((subject, index) => ({
        value: index,
        label: subject.name,
      })) || [];
    return {
      months,
      subjects,
    };
  }, [allMonths, gradeSubjects]);

  /* eslint-disable */
  const handleInputChange = useCallback(
    debounce((value) => updateSearch(value), 1000),
    [updateSearch]
  );
  /* eslint-enable */

  const backButton = (e: any) => {
    e.preventDefault();
    const isNewTab = window.history.length < 1;

    isNewTab ? navigate("/classroom") : navigate(-1);
  };

  const onEnableAssessment = async (
    checked: boolean,
    isRandom: boolean | null
  ) => {
    setLoading(true);
    if (isRandom === null) {
      setAssesments((prev: any) => ({
        ...prev,
        ...(isRandom !== null && { is_random: isRandom }),
        automatically_enable_scheduled: checked,
      }));
    }
    await enableAssessment(classroomId, {
      automatically_enable_scheduled: checked,
      ...(isRandom !== null && { is_random: isRandom }),
    });
    if (isRandom !== null) {
      setAssesments((prev: any) => ({
        ...prev,
        ...(isRandom !== null && { is_random: isRandom }),
        automatically_enable_scheduled: checked,
      }));
    }
    setLoading(false);
    setOpenRandomModal(null);
  };

  const onUseAllMathClasses = async (shareSchedule: boolean) => {
    shareSchedule &&
      setAssesments((prev: any) => ({
        ...prev,
        sharing_schedule: shareSchedule,
      }));
    await enableAllMathClasses(classroomId, shareSchedule);
    !shareSchedule &&
      setAssesments((prev: any) => ({
        ...prev,
        sharing_schedule: shareSchedule,
      }));
    !shareSchedule && setConfirmModal(false);
  };

  const onChangeRandom = async (data: any) => {
    await onEnableAssessment(openRandomModal, data.show_random_option);
  };

  const onToggleUseAllClasses = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.checked ? onUseAllMathClasses(true) : setConfirmModal(true);
  };

  return (
    <>
      <div className="pacing-header-navigation">
        <div className="d-flex align-items-center">
          <span
            tabIndex={0}
            role="button"
            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                backButton(e);
              }
            }}
            onClick={backButton}
            className="report__header-link"
          >
            <img className="report__header-icon" src={ArrowIcon} alt="arrow" />
          </span>
          <h2 className="text-white title">
            {isSuperAdmin ? "Suggested Pacing" : "Pacing Calendar"}
          </h2>
          <button
            type="button"
            onClick={() => setModalDelShow(true)}
            className="btn outline-btn pacing-clear-color-fix"
          >
            Clear
          </button>
        </div>
        <p className="desc">
          Schedule assessments to align with your curriculum and stay organized
          on the Classroom Screen.
        </p>
      </div>
      <div className="pacing-calendar-header-right">
        {!isSuperAdmin && (
          <div className="switch-toggle-container">
            <div className="complete-toggle-container small-toggle">
              <p>Automatically Make Assessment Available on Schedule</p>
               
                <Toggle 
                  tabIndex={0} 
                  checked = {automatically_enable_scheduled} 
                  isPacingCalender = {true}
                  onChange={(e)=>{
                   if (e.target.checked) {
                    setOpenRandomModal(e.target.checked);
                  } else {
                    onEnableAssessment(e.target.checked, null);
                  }
                }}
                />
            </div>
            {same_classroom_exists && (
              <div className="complete-toggle-container small-toggle">
                <p>
                  Use for All{admin_name ? ` ${admin_name}` : ""}
                  {subject ? ` ${subject}` : ""}
                  {grade ? ` ${grade}` : ""} Classes
                </p>
                <label className="switch">
                  <input
                    checked={sharing_schedule}
                    tabIndex={0}
                    type="checkbox"
                    className="switch-checkbox customFieldsFocused-active"
                    onChange={onToggleUseAllClasses}
                  />
                  <span className="switch-slider"></span>
                </label>
              </div>
            )}
          </div>
        )}
        <div className="dropdown-search-section">
          <div className="pacing-dropdowns">
            {showTooltipPacing && (
              <ClassroomTooltip
                className="ProfessionalDev__devSummary__toolTip pacing-calender-position"
                message="Select the month you want to schedule assessments in."
              />
            )}
            {isSuperAdmin && (
              <HeaderDropDown
                options={subjects}
                optionIndex={currentGradeIndex}
                handleNextPrevious={handleNextPreviousGrade}
                onChangeHandler={updateCurrentGrade}
              />
            )}

            <HeaderDropDown
              options={months}
              optionIndex={currentMonthIndex}
              handleNextPrevious={handleNextPreviousMonth}
              onChangeHandler={updateCurrentMonth}
            />
          </div>

          <div className="keyBox">
            <div className="keyLabel">Key</div>
            <div className="d-flex align-items-center ps-3">
              {/* <img src={CheckHead} alt="Check Circle" /> */}
              <svg
                width="19"
                height="16"
                viewBox="0 0 19 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="19" height="16" rx="8" fill="#46AFB8" />
              </svg>

              <p>Completed</p>
            </div>
          </div>

          <div className="section-search PacingCalender__container__table__bodyrow align-items-center">
            {/* <img
              onClick={() => setModalDelShow(true)}
              className={`deleteIcon cursor-pointer`}
              src={DeleteImage}
              style={{pointerEvents: 'auto'}}
              alt=""
            /> */}
            <div className="section-search-field animated">
              <div className="icon ms-md-4 ms-2">
                <img src={SearchIcon} alt="Search" />
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Search Calendar"
                onChange={(e) => handleInputChange(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      {openRandomModal !== null && (
        <ChooseQuestionOrder
          isShow={true}
          onClose={() => setOpenRandomModal(null)}
          onSave={onChangeRandom}
          isRandom={is_random}
          currentData={{}}
          loading={loading}
        />
      )}

      {confirmModal && (
        <ConfirmModal
          onSubmit={() => onUseAllMathClasses(false)}
          onCancel={() => setConfirmModal(false)}
          isShow
          submitButtonName="Yes"
          headerText={`Delete ${admin_name} ${subject} ${grade} Schedule(s)`}
          bodyText={`This will delete the Pacing Calendar schedule for all other ${admin_name} ${subject} ${grade} classes. It will not delete the schedule for this class ${
            selectedClassroom?.hour
              ? "(" + selectedClassroom?.hour + " hour)"
              : ""
          }. Are you sure?`}
        />
      )}
    </>
  );
};
export default PacingCalHeader;
