const config = {
    defaults: {
        namespace: 'Application',
        api_url:process.env.REACT_APP_API_URL || "https://dev-api-v3.aplustesting.org" ,
        api_key:process.env.REACT_APP_API_KEY || "d2e621a6646a4211768cd68e26f21228a81",
        clever_client_id: process.env.REACT_APP_CLEVER_CLIENT_ID || "ad4dde22373e1bae53bd",
        redirect_url: process.env.REACT_APP_CLEVER_REDIRECT_URL || "https://dev-api-v3.aplustesting.org",
        s3BucketURL: process.env.REACT_APP_S3_BUCKET_URL || 'https://dev-teacher-v3.aplustesting.org',
        aws_region: process.env.REACT_APP_AWS_REGION || 'us-east-2',
        aws_poolId: process.env.REACT_APP_AWS_POOLID || '27af6776-9c25-4860-84fc-39a4d79ca0fd',
        discussions_url: process.env.REACT_APP_DISCUSSIONS || 'https://dev-discussions.aplustesting.org'
    }
}

export default config;
