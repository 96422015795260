/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import Theme from "components/theme/index";
import { useDispatch, useSelector } from "react-redux";
import {
  getGradeFrequencySummativeReport,
  resetGradeFrequencySummativeReport,
} from "redux/actionCreators/classroom";
import { RootState } from "redux/reducers/combine";
import { GradeFrequencyGraph } from "../graphs/GradeFrequencyGraph";
import GradeFrequencyDistributionPrint from "./GradeFrequencyDistributionPrint";
import { useQuery } from "hooks/useQuery";
import { isSafari } from "util/index";
import { Link } from "react-router-dom";

const GradeFrequencyDistributionReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id }: any = useParams();
  const componentRef = useRef(null);
  const { pathname } = useLocation();
  const query = useQuery();
  const print = query.get("print");

  const report = useSelector((state: RootState) => state).classroom
    .gradeFrequencySummativeReport;

  useEffect(() => {
    dispatch(getGradeFrequencySummativeReport(id));
    return () => {
      dispatch(resetGradeFrequencySummativeReport());
    };
  }, []);

  const backButton = (e: any) => {
    e.preventDefault();
    dispatch(resetGradeFrequencySummativeReport());
    navigate("/classroom");
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `GradeFrequency&DistributionReport_${
      report?.data?.class_name
    }_${moment(new Date()).format("M/DD/YY hh:mmA")}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      print && window.close();
    },
  });

  useEffect(() => {
    if (report && print) {
      handlePrint();
    }
  }, [report]);

  return (
    <>
      {!print && (
        <Theme>
          <div className="report">
            <div className="report__header">
              <div className="d-flex align-items-center">
                <span className="report__header-link">
                  <img
                    className="report__header-icon"
                    onClick={backButton}
                    src={ArrowIcon}
                    alt="arrow"
                  />
                </span>
                <div>
                  <h3 className="report__header-title">
                    Grade Frequency and Distribution Report
                  </h3>
                  <p className="report__header-text">
                    {report?.data?.summative_name || "-"}
                  </p>
                </div>
              </div>
              {isSafari ? (
                <Link to={`${pathname}?print=true`} target="_blank">
                  <button className="btn outline-btn ms-2">Print</button>
                </Link>
              ) : (
                <button onClick={handlePrint} className="btn outline-btn ms-2">
                  Print
                </button>
              )}
            </div>

            <div className="report-content">
              <div className="report__levels">
                <div className="report__levels-container">
                  <p className="report__levels-title">
                    Performance Level (Percentage Score Range)
                  </p>

                  <div className="report__levels-percentage">
                    <div className="report__levels-row">
                      <div className="d-flex align-items-center">
                        <div className="report__levels-rectangle advanced"></div>
                        <p>Advanced</p>
                      </div>
                      <p>( 85% - 100%)</p>
                    </div>
                    <div className="report__levels-row">
                      <div className="d-flex align-items-center">
                        <div className="report__levels-rectangle proficient"></div>
                        <p>Proficient</p>
                      </div>
                      <p>( 70% - 84%)</p>
                    </div>
                    <div className="report__levels-row">
                      <div className="d-flex align-items-center">
                        <div className="report__levels-rectangle basic"></div>
                        <p>Basic</p>
                      </div>
                      <p>( 52% - 69%)</p>
                    </div>
                    <div className="report__levels-row">
                      <div className="d-flex align-items-center">
                        <div className="report__levels-rectangle below"></div>
                        <p>Below Basic</p>
                      </div>
                      <p>( 0% - 51%)</p>
                    </div>
                  </div>

                  <p className="report__levels-text">
                    (***%) = Percentage of questions answered correctly
                  </p>
                  <div className="d-flex align-items-center jusify-content-center">
                    <span className="report__levels-number">N</span>
                    <p className="report__levels-text mb-0">
                      Number of scored assessments in performance level
                    </p>
                  </div>
                </div>
              </div>

              <div className="report__chart">
                <div className="report__chart-container">
                  {report?.data && <GradeFrequencyGraph data={report?.data} />}
                </div>
              </div>
            </div>
          </div>
        </Theme>
      )}
      {report?.data?.frequency && (
        <div ref={componentRef} className="printData">
          <GradeFrequencyDistributionPrint />
        </div>
      )}
    </>
  );
};
export default GradeFrequencyDistributionReport;
