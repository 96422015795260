const StudentTableHeader = () => {
    return (
        <thead>
            <tr>
                <th className='based-report__table-id'>
                    Student ID <br/> Last 5
                </th>
                <th className='based-report__table-student'>
                    Student
                </th>
                <th className='based-report__table-english'>
                    View <br />English
                </th>
                <th className='based-report__table-spanish'>
                   View <br />Spanish
                </th> 
            </tr>           
        </thead>
    )
}
export default StudentTableHeader