import { isSafari } from "util/index";
import { Link, useLocation } from "react-router-dom";
import { setIsPrinted } from "redux/actionCreators/print";
import { useDispatch, useSelector } from "react-redux";
import { isPrintedSelector } from "redux/reducers/print";

const PrintButton = ({handlePrint}:any) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation()
    const isPrinted = useSelector(isPrintedSelector);

    const addPrintCount = () => {
        !isPrinted && dispatch(setIsPrinted(true))
      }
    
    const handleReportPrint = () => {
        addPrintCount(); 
        handlePrint()
    }
    return (
        <>
            {isSafari && isPrinted ?
                <Link to={`${pathname}?print=true`} target="_blank">
                  <button className="btn outline-btn" >Print</button>
                </Link>
                :
                <button  onClick={handleReportPrint} className="btn outline-btn">
                  Print
                </button>
              }
        </>
    )
};

export default PrintButton;
