import { useCallback, useContext, useEffect, useMemo } from 'react'
import { ProfessionalDevelopmentContext } from 'contexts/professionalDevelopment'
import { HeaderTabs } from 'interfaces/professionalDevelopment'
import ClassroomTooltip from 'components/common/classroomTooltip'
import { ClassroomTooltipContext } from 'contexts/classroomTooltip'
import { RootState } from 'redux/reducers/combine'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import jwtDecode from 'jwt-decode'

const tabs = [
    {
        label: HeaderTabs.THIS_WEEK,
        icon: <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="25.74" height="23.2" viewBox="0 0 28.242 25.699">
                <defs>
                    <clipPath id="clip-path">
                    <rect id="Rectangle_1250" data-name="Rectangle 1250" width="28.242" height="25.699" transform="translate(0 0)" fill="none"/>
                    </clipPath>
                </defs>
                <g id="this_week_icon" data-name="this week icon" transform="translate(0 0)">
                    <g id="Group_1450" data-name="Group 1450" clipPath="url(#clip-path)">
                    <rect id="Rectangle_1249" data-name="Rectangle 1249" width="25.742" height="23.199" rx="1.311" transform="translate(1.25 1.25)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
                    <line id="Line_108" data-name="Line 108" x2="9.354" transform="translate(11.79 8.007)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"/>
                    <line id="Line_109" data-name="Line 109" x2="9.354" transform="translate(11.79 12.866)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"/>
                    <path id="Path_25714" data-name="Path 25714" d="M8.224,17.356a1.25,1.25,0,1,1-1.25-1.25,1.25,1.25,0,0,1,1.25,1.25" transform="translate(0.369 0.369)" fill="#fff"/>
                    <path id="Path_25739" data-name="Path 25739" d="M8.224,17.356a1.25,1.25,0,1,1-1.25-1.25,1.25,1.25,0,0,1,1.25,1.25" transform="translate(0.369 -4.506)" fill="#fff"/>
                    <path id="Path_25740" data-name="Path 25740" d="M8.224,17.356a1.25,1.25,0,1,1-1.25-1.25,1.25,1.25,0,0,1,1.25,1.25" transform="translate(0.369 -9.349)" fill="#fff"/>
                    <line id="Line_110" data-name="Line 110" x2="9.354" transform="translate(11.79 17.725)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5"/>
                    </g>
                </g>
              </svg>
    },
    {
        label: HeaderTabs.Resources,
        icon: <svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M20.1 0.47604H26.711C27.5562 0.473112 28.368 0.805514 28.9685 1.40034C29.5689 1.99517 29.909 2.80386 29.914 3.64904V16.343C29.909 17.1886 29.5687 17.9976 28.9678 18.5924C28.3669 19.1873 27.5545 19.5195 26.709 19.516H20.103C19.0495 19.5153 18.0191 19.8244 17.14 20.405L14.956 21.839L12.774 20.399C11.8967 19.8123 10.8664 19.4962 9.811 19.49L3.205 19.511C2.36023 19.5142 1.54862 19.1825 0.947906 18.5886C0.347194 17.9946 0.00634366 17.1868 0 16.342V3.64904C0.00502594 2.80351 0.345328 1.99452 0.946211 1.39963C1.54709 0.804749 2.35946 0.472581 3.205 0.47604H9.812C11.2838 0.476006 12.7231 0.908603 13.951 1.72004L14.951 2.37904L15.951 1.72004C17.182 0.907211 18.6249 0.474575 20.1 0.47604ZM27.4648 17.0919C27.6651 16.8936 27.7784 16.6239 27.78 16.342V3.64904C27.7792 3.50897 27.7508 3.37044 27.6963 3.24139C27.6419 3.11234 27.5625 2.9953 27.4627 2.89701C27.3629 2.79871 27.2447 2.72108 27.1149 2.66858C26.985 2.61607 26.8461 2.58972 26.706 2.59104H20.1C19.0483 2.58986 18.0194 2.89759 17.141 3.47604L14.957 4.91604L12.775 3.47604C11.8954 2.89719 10.865 2.58977 9.812 2.59204H3.205C2.92333 2.59097 2.65272 2.70164 2.45252 2.89979C2.25232 3.09793 2.13885 3.36737 2.137 3.64904V16.342C2.13859 16.6239 2.25194 16.8936 2.45217 17.0919C2.6524 17.2903 2.92315 17.4011 3.205 17.4H9.802C11.2772 17.398 12.7203 17.8307 13.951 18.644L14.951 19.303L15.951 18.644C17.182 17.8312 18.6249 17.3986 20.1 17.4H26.712C26.9938 17.4011 27.2646 17.2903 27.4648 17.0919Z" fill="white"/>
                <path d="M4.97852 7.5H11.9785C12.5308 7.5 12.9785 7.94772 12.9785 8.5C12.9785 9.05228 12.5308 9.5 11.9785 9.5H4.97852C4.42623 9.5 3.97852 9.05228 3.97852 8.5C3.97852 7.94772 4.42623 7.5 4.97852 7.5Z" fill="white"/>
                <path d="M17.9785 7.5H24.9785C25.5308 7.5 25.9785 7.94772 25.9785 8.5C25.9785 9.05228 25.5308 9.5 24.9785 9.5H17.9785C17.4262 9.5 16.9785 9.05228 16.9785 8.5C16.9785 7.94772 17.4262 7.5 17.9785 7.5Z" fill="white"/>
                <path d="M4.97852 11.5H11.9785C12.5308 11.5 12.9785 11.9477 12.9785 12.5C12.9785 13.0523 12.5308 13.5 11.9785 13.5H4.97852C4.42623 13.5 3.97852 13.0523 3.97852 12.5C3.97852 11.9477 4.42623 11.5 4.97852 11.5Z" fill="white"/>
                <path d="M17.9785 11.5H24.9785C25.5308 11.5 25.9785 11.9477 25.9785 12.5C25.9785 13.0523 25.5308 13.5 24.9785 13.5H17.9785C17.4262 13.5 16.9785 13.0523 16.9785 12.5C16.9785 11.9477 17.4262 11.5 17.9785 11.5Z" fill="white"/>
              </svg>
    },
    {
        label: HeaderTabs.Pacing_Calender,
        icon: <svg width="28" height="25" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.15039 8.19598H27.0944V25.468H1.15039V8.19598Z" stroke="white" strokeWidth="2"/>
                <path d="M1.15039 4.34094H27.0944V4.74994H1.15039V4.34094Z" stroke="white" strokeWidth="2"/>
                <path d="M6.47559 12.12H9.02659C9.16466 12.12 9.27659 12.2319 9.27659 12.37V14.921C9.27659 15.0591 9.16466 15.171 9.02659 15.171H6.47559C6.33751 15.171 6.22559 15.0591 6.22559 14.921V12.37C6.22559 12.2319 6.33751 12.12 6.47559 12.12Z" stroke="white" strokeWidth="1.5"/>
                <path d="M6.47559 18.4919H9.02659C9.16466 18.4919 9.27659 18.6039 9.27659 18.7419V21.2929C9.27659 21.431 9.16466 21.5429 9.02659 21.5429H6.47559C6.33751 21.5429 6.22559 21.431 6.22559 21.2929V18.7419C6.22559 18.6039 6.33751 18.4919 6.47559 18.4919Z" stroke="white" strokeWidth="1.5"/>
                <path d="M12.8477 18.4919H15.3987C15.5367 18.4919 15.6487 18.6039 15.6487 18.7419V21.2929C15.6487 21.431 15.5367 21.5429 15.3987 21.5429H12.8477C12.7096 21.5429 12.5977 21.431 12.5977 21.2929V18.7419C12.5977 18.6039 12.7096 18.4919 12.8477 18.4919Z" stroke="white" strokeWidth="1.5"/>
                <path d="M19.2188 18.4919H21.7698C21.9078 18.4919 22.0198 18.6039 22.0198 18.7419V21.2929C22.0198 21.431 21.9078 21.5429 21.7698 21.5429H19.2188C19.0807 21.5429 18.9688 21.431 18.9688 21.2929V18.7419C18.9688 18.6039 19.0807 18.4919 19.2188 18.4919Z" stroke="white" strokeWidth="1.5"/>
                <path d="M12.8477 12.12H15.3987C15.5367 12.12 15.6487 12.2319 15.6487 12.37V14.921C15.6487 15.0591 15.5367 15.171 15.3987 15.171H12.8477C12.7096 15.171 12.5977 15.0591 12.5977 14.921V12.37C12.5977 12.2319 12.7096 12.12 12.8477 12.12Z" stroke="white" strokeWidth="1.5"/>
                <path d="M19.2188 12.12H21.7698C21.9078 12.12 22.0198 12.2319 22.0198 12.37V14.921C22.0198 15.0591 21.9078 15.171 21.7698 15.171H19.2188C19.0807 15.171 18.9688 15.0591 18.9688 14.921V12.37C18.9688 12.2319 19.0807 12.12 19.2188 12.12Z" stroke="white" strokeWidth="1.5"/>
                <path d="M6.54102 1.30896V3.59396" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M21.958 1.30896V3.59396" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
    },
    {
        label: HeaderTabs.Discussion,
        icon: <svg width="25.46" height="23.14" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.007 1.00104H2.53104C1.90636 1.01728 1.3072 1.25227 0.838036 1.66504C0.578137 1.89189 0.371634 2.17341 0.233325 2.48945C0.0950166 2.80548 0.0283272 3.1482 0.038036 3.49304V15.865C0.038301 16.5256 0.30083 17.1591 0.767926 17.6261C1.23502 18.0932 1.86846 18.3558 2.52904 18.356H9.31004L8.77204 24.136L16.847 18.356H23.008C23.6688 18.356 24.3025 18.0936 24.7698 17.6265C25.2371 17.1594 25.4998 16.5258 25.5 15.865V3.49304C25.5 3.1657 25.4355 2.84157 25.3102 2.53916C25.185 2.23675 25.0013 1.96199 24.7698 1.73057C24.5383 1.49916 24.2634 1.31562 23.961 1.19044C23.6585 1.06527 23.3344 1.00091 23.007 1.00104Z" stroke="white" strokeWidth="1.97" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
    },
]

const Header = () => {
    const { activeHeaderTab, updateActiveHeaderTab, pdClassroom, classroomId } = useContext(ProfessionalDevelopmentContext);
    const {tooltip, setShowNoScheduledTooltip, showNoScheduledTooltip} = useContext(ClassroomTooltipContext);
    const auth = useSelector((state: RootState) => state.auth);

    const onClickHandler = useCallback(() => {
        setShowNoScheduledTooltip(false);
        sessionStorage.setItem("scheduleTooltipSeen", "true");
    
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    const modifiedTabs = useMemo(() => {
        const {is_enabled} = pdClassroom;
        return tabs.map((tab) => {
            let isEnabled = false;
            
            if(tab.label === HeaderTabs.Pacing_Calender)  {
                isEnabled = is_enabled && !!classroomId;
            } else {
                isEnabled = !!classroomId;
            }
            
            return {
                ...tab,
                isEnabled
                // isEnabled: (tab.label === HeaderTabs.Pacing_Calender || tab.label === HeaderTabs.THIS_WEEK)  ? (is_enabled && !!classroomId) : tab.label === HeaderTabs.Discussion ? (is_enabled && true) : !!classroomId
            }
        })
    },[pdClassroom, classroomId]);

    useEffect(() => {
        const token = auth?.currentUser?.token;
        const decodedToken: any = jwtDecode(token);
        const isForgotPassword = decodedToken?.forgot_password;
        const scheduleTooltipSeen = sessionStorage.getItem("scheduleTooltipSeen") === 'true';
        // console.log('auth', auth.colorProfile, pdClassroom.loading, );
        if(pdClassroom.loading ||
            !auth.colorProfile ||
            (auth.colorProfile && auth.colorProfile.tooltips_seen === false) ||
            isForgotPassword ||
            scheduleTooltipSeen
        ){
            return;
        }

        if(pdClassroom && pdClassroom.show_no_schedule_tooltip === false){
            setShowNoScheduledTooltip(pdClassroom.show_no_schedule_tooltip);
            return;
        }
        
        setShowNoScheduledTooltip(true);
        document.body.addEventListener('click', onClickHandler, true);

        return () => {
          document.body.removeEventListener('click', onClickHandler, true);
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[pdClassroom])

    const {pathname} = useLocation();
    
    
    // console.log('isDiscussionEnabled', !pdClassroom.is_enabled, );
    const isShowTooltip = (tooltip === 2 || showNoScheduledTooltip) && pathname === '/classroom';
    return (
        <div className='ProfessionalDev__devSummary__header d-md-flex align-items-center justify-content-between'>
            <h3 className='text-white pg-heading'>Professional Development</h3>
            <div className='d-flex flex-wrap align-items-center justify-content-md-end justify-content-center headerTabs'>
                {isShowTooltip && (
                    <ClassroomTooltip 
                    className="classRoom-toolTip" 
                    message={
                        showNoScheduledTooltip 
                        ? 'Schedule your assessments<br /> here. This will help you stay on <br /> pace for 100 Days of Mastery.'
                        : "Schedule your summative and formative assessment here."
                    } />
                )}
                {
                    modifiedTabs.map((tab, index) => {
                        const { label, icon, isEnabled } = tab;
                        // const comp = <icon />
                        const isTabEnabled = !pdClassroom.is_enabled && (
                            label === HeaderTabs.Discussion ||
                            label === HeaderTabs.Resources ||
                            label === HeaderTabs.THIS_WEEK
                        );
                        return (
                            <div 
                                key={index} 
                                onClick={() => isEnabled && updateActiveHeaderTab(label)} 
                                className={`
                                    ProfessionalDev__devSummary__header__rightElements d-flex align-items-center 
                                    ${label === HeaderTabs.Pacing_Calender && 'HeaderPacingIcon'} 
                                    ${label === activeHeaderTab && 'active'} ${!isEnabled && 'disabled'}
                                `}
                                style={{position: isTabEnabled ? 'relative' : 'unset', zIndex: isTabEnabled ? 999 : 'unset'}}
                            >
                                <div className='tab-icon'> 
                                    {icon}
                                </div>
                                <p className='mb-0 tabsText'>{label}</p>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Header