import React from "react";
import Theme from "components/theme/index";
import IPage from "interfaces/page";

import TicketDetails from "components/SuperAdminTicketDetails";
import SupportTicketSuperAdminProvider from "contexts/supportTicketSuperAdmin";

const SupportTicket: React.FunctionComponent<IPage> = (props) => {
  return (
    <Theme>
      <SupportTicketSuperAdminProvider>
        <TicketDetails />
      </SupportTicketSuperAdminProvider>
    </Theme>
  )
}

export default SupportTicket
