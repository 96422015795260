import { FC } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";

interface Props {
    dates: Array<{
        color: string;
        date: string;
        minHeight: number
    }>;
    isFormative: boolean; 
    show: boolean;
}

const Sidebar: FC<Props> = ({ dates, isFormative, show }) => {
    const navigate = useNavigate();

    if(!show){
        return null
    }

    const onClickDate = (date: string) => {
        console.log(isFormative);
        navigate('/pacing-calendar', {state: {date}});
    }

    return (
        <div className="pace-column">
            <p className="pace-header text-center">{ "Pace"}</p>
            {
                dates?.map((singleDate, index: number) => {
                    const { color, date, minHeight } = singleDate
                    const zIndex = dates.length - index;

                    let showDate = 'Not Scheduled'
                    let day: string | null = null;

                    if (date !== 'Not Scheduled') {
                        const incommingDate = moment(date)
                        // showDate = incommingDate.format('M/D')
                        showDate = incommingDate.format('MMM D')
                        day = incommingDate.format('dddd')
                    }

                    return  <div key={index} style={{ minHeight }} className={`overflow-hidden pace-column-bg ${minHeight >= 126 ? 'column-align' : ''}`} >
                                <div onClick={() => onClickDate(date)} className={`pace-date position-relative cursor-pointer`} 
                                       >
                                    <div className="column-box-shadow" style={{ backgroundColor: color, zIndex}}>
                                        {
                                            day && <p style={{...(color === '#EADB4A' && {fontWeight: 'normal'}), color: (color === '#3C809F' || color === '#3c809f' || color === '#3260D4' || color === '#3260d4') ? '#fff' : '#424242'}} className="day mb-0" >{day}</p>
                                        }
                                        <p style={{...(color === '#EADB4A' && {fontWeight: 'normal'}), color: (color === '#3C809F' || color === '#3c809f' || color === '#3260D4' || color === '#3260d4') ? '#fff' : '#424242'}} className="date mb-0">{showDate}</p>
                                    </div>
                                </div>
                            </div>
                })
            }
        </div >
    )
}
export default Sidebar