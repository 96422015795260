import { FC, useContext, useState } from "react";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import { useNavigate } from "react-router-dom";
import { ContactSupportModal } from "components/ContactSupport/ContactSupportModal";
import SuccessModal from "components/common/successModal";
import { SupportTicketContext } from "contexts/supportTicket";

const SupportTicketHeader: FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const navigate = useNavigate();

  const { setSupportTickets } = useContext(SupportTicketContext);

  const onSubmit = (data: any) => {
    data && setSupportTickets(data);
  }
  
  return (
    <>
      <div className="SupportTicket__TicketInfo__Header d-flex align-items-center justify-content-between ">
        <div className="d-flex align-items-center">
          <div className="SupportTicket__TicketInfo__Header__backArrow">
            <span className="accordion__header-button">
              <img
                onClick={() => navigate(-1)}
                src={ArrowIcon}
                alt="arrow"
                className="rotate-90"
              />
            </span>
          </div>
          <h3 className="text-white SupportTicket__TicketInfo__Header__text">Support Tickets</h3>
        </div>
        <button onClick={() => setShowModal(true)} className="SupportTicket__TicketInfo__Header__button">New Ticket</button>
      </div>
      {showModal && (
        <ContactSupportModal
          closeModal={() => setShowModal(false)}
          isShow={showModal}
          setShowSuccess={setShowSuccess}
          onSubmit={onSubmit}
        />
      )}

        <SuccessModal
          bodyText="Thank you for contacting Alpha Plus Support. We will reach out to you within one business day. If your case is urgent, please call us at (405) 842-8408."
          headerText="Ticket Submitted"
          isShow={showSuccess}
          closeModal={() => setShowSuccess(false)}
        />
    </>
  );
};
export default SupportTicketHeader;
