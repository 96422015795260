const StudentTableHeader = () =>{

    return (
        <thead>
            <tr className='risk-report__table-row'>
                <th className='risk-report__table-objective d-flex justify-content-end align-items-end'>
                    <span>Objective</span>
                </th>
                <th className='risk-report__table-description d-flex align-items-end'>
                    <span>Description</span>
                </th>
                <th className='risk-report__table-students'>
                    Students <br/> Non-Proficient
                </th>
                <th className='risk-report__table-proficient'>
                    Percent Proficient
                </th>
                <th className='risk-report__table-view'>
                    View Students
                </th>
            </tr>
        </thead>
    )
}
export default StudentTableHeader