import { FC } from "react"
import WarrningIcon from 'assets/img/warning-icon.png'
import { useNavigate } from "react-router-dom"


interface Props{
    record:any
}

const TableBody : FC<Props> = ({record}) => {
    const {district_name , tested , enrolled ,licensed , school_name} = record
    const navigate = useNavigate()

    const showWarning = enrolled > licensed-5
    
    const onRowClick = (schoold:number) =>{
        navigate(`${schoold}`)
    }
    
    return <tr className={`${showWarning && "warningText"}`}>
        <td className="auditReport__table__row__directName">{district_name}</td>
        <td className={`auditReport__table__row__schoolName`}>
            <span onClick={()=>onRowClick(record.school_id)}>
                {school_name}
            </span>
        </td>
        <td className="auditReport__table__row__warning">{showWarning && <img src={WarrningIcon} alt="warning" />}</td>
        <td className={`auditReport__table__row__enrolledStudent`}>{enrolled}</td>
        <td className={`auditReport__table__row__testedStudent`}>{tested}</td>
        <td className={`auditReport__table__row__Licensed`}>
            <span className="licensed-data">{licensed}</span>
        </td>
    </tr>
}
export default TableBody