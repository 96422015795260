import ToolTip from "components/common/Tooltip";

const MAX_WIDTH = 200;

const StatusBar = ({value, to, className, animationName}: any) => {
    const styles = {
        animation: `${animationName} 1s`,
        width: `${to}%`
    }

    const statusBarWidth = Math.floor((to / 100) * MAX_WIDTH)
    const left = statusBarWidth + 40;
    return (
        <>
            <ToolTip top={43} left={left} content={value}>
                <span style={styles} className={className}></span>
            </ToolTip>
            <style>{`
                @keyframes ${animationName} {
                    0% { width: 0%; }
                    100% { width: ${to}%; }
                }
            `}</style>
        </>
    )
};

export default StatusBar;
