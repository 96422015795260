import { useContext } from "react";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import { useNavigate } from "react-router-dom";
import { DiscussionsContext } from "contexts/discussions";

const DiscussionHeader = () => {
  const {
    isSuperAdmin,
  } = useContext(DiscussionsContext);
  
  const navigate = useNavigate()

  const backButton = (e: any) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <>
      <div className="d-flex align-items-center discussions-header-navigation">
        <span onClick={backButton} tabIndex={0}
            role="button" className="report__header-link"   onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              backButton(e)
            }
          }}  >
          <img
            className="report__header-icon"
            src={ArrowIcon}
            alt="arrow"
          />
        </span>
        <h2 className="text-white title">
          {isSuperAdmin ? "Discussion - Admin" : "Discussion"}
        </h2>
      </div>
    </>
  );
};
export default DiscussionHeader;
