import ScrollingText from "../ScrollingText";


interface IProps {
  heading: string;
  onClickGoal: () => void;
  goalId: number;
}

const Heading = ({heading, onClickGoal, goalId}: IProps) => {

    return (
      <ScrollingText
        elementStyles={{
          fontSize: "20px",
          fontWeight: 700,
          lineHeight: "28px",
          color: "#323233",
          fontFamily: "Open Sans",
        }}
        id={goalId}
        containerStyles={{ paddingRight: "9px", paddingLeft: "14px", marginBottom:"5px" }}
        animateFirstTime={true}
        onClick = {onClickGoal}
        tag="h3"
      >
        {heading}
      </ScrollingText>
  );
};

export default Heading;
