import ToolsTable from "./table";

const Resources = () => {

  return (
    <div>
      <div className="w-100 overflow-auto roundedBorder">
        <ToolsTable />
      </div>
    </div>
  );
};

export default Resources;
