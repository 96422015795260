// import { useId } from 'react'
import DrodownIcon from "assets/img/accordian-box-icon.png"
import { ChatBoxContext } from "contexts/chatBox";
import { ChatSocketContext } from "contexts/socketContext";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { createMessageObject } from "util/createMessagePayload";
import NoChatHand from 'assets/img/no-chat-hand.svg'
import { ContactSupportModal } from "components/ContactSupport/ContactSupportModal";
import HandGestureIcon from "assets/img/handGesture.svg"
import ChatHeaderSkeleton from 'components/common/skeleton/chatSkeletons/ChatHeaderSkeleton';
import { v4 as uuidv4 } from "uuid";
//import { getProfessionalDevelopmentClassroom } from "services/professionalDevelopment";
import { getSupportChats } from "services/suppot-ticket";

let timeoutId: any = null;
const THIRTY_MINUTES_IN_MS = 1000*60*35;

const ChatBox = (props: any) => {
    // const uniqueId = useId();
    const notificationsRef = useRef<any>(null!)
    const allowFetching = useRef<boolean>(true)
    const [showSupportModal, toggleSupportModal] = useState(false);
    const [, setShowSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
   // const [pdDisabled, setPdDisabled] = useState(false);
    const [allowAddMessage, setAllowAddMessage] = useState(true);
    const [isExpired, setIsExpired] = useState(false);

    const { fetchSupportChat, supportChat, sendUserMessage, unsetSupportChat, setSupportChatStatus, setSupportChatStatus_id, setChatBoxMessage } = useContext(ChatBoxContext);
    let { messages, status, messages_count, status_id } = supportChat;

    const {normalAdminMessages, setNormalAdminMessages, emitTypingEvent, chatTyping, adminActive, setNormalAdminChatOpen, setCurrentStatus, emitSuperAdminChatSubscribe} = useContext(ChatSocketContext);
    const textAreaRef = useRef<HTMLTextAreaElement>(null!);

    const { onClose } = props;
    const [message, setMessage] = useState('');
    const [chatQueryParams, setChatQueryParams] = useState({
        limit: 50,
        offset: 0
    })

    
    const onClickSend = async () => {
        if (!message) {
            return;
        }

        textAreaRef.current?.focus();
        const messagePayload = createMessageObject(messages, message);

        setMessage('');
        const uniqueId = uuidv4();
        const chat = await sendUserMessage(message, {...messagePayload, tempId: uniqueId});
        !chat && setMessage(message);

        if(supportChat.messages.length === 0){
            emitSuperAdminChatSubscribe(chat.thread_id, (message: any) => {
                setChatBoxMessage(message);
            });
        }
    }

    useEffect(() => {
        if(!normalAdminMessages){
            return;
        }

        if(!normalAdminMessages.resolved){
            if(timeoutId){
                clearTimeout(timeoutId);
            }
            fetchSupportChat({...chatQueryParams, offset: 0});
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[normalAdminMessages])

    useEffect(() => {
        const getSuportChat = async () => {
            const tickets = await fetchSupportChat(chatQueryParams);    
            setLoading(false); 
            tickets && tickets.status_id !== null && setCurrentStatus(tickets.status_id);
        }
        
        getSuportChat();
        
        /* eslint-disable react-hooks/exhaustive-deps */
    }, [chatQueryParams])

    useEffect(() => {
        if (!allowFetching.current)
            allowFetching.current = true

    }, [messages]);

    // const getPdAccess = async () => {
    //     const _classroomId = sessionStorage.getItem("c_classroom_id");
    //     const classroomId = _classroomId ? parseInt(_classroomId) : 0;
    //     const pdClassroom = classroomId ? await getProfessionalDevelopmentClassroom(classroomId) : null;
    //     setLoading(false);
        
    //     if(pdClassroom){
    //         setPdDisabled(pdClassroom.is_enabled === false);
    //     }
    // }

    useEffect(() => {
      //  getPdAccess();
        setNormalAdminChatOpen(true);
        return () => unsetSupportChat();
    }, []);

    const onScrollMessages = () => {
        if (!allowFetching.current)
            return

        const scrollHeight = notificationsRef?.current?.scrollHeight
        const scrollValue = (notificationsRef?.current?.scrollTop * -1) + 400
        const threshold = scrollHeight - (scrollHeight * 0.15)

        if (scrollValue >= threshold && messages.length < messages_count) {
            allowFetching.current = false
            setChatQueryParams((prev: any) => {
                return {
                    ...prev,
                    offset: prev.offset+1
                }
            })
        }
    }

    const onTypeMessage = (e: any) => {
        // Has to put this condition because it was adding one extra line when we send message by clicking
        // on ctrl + Enter ... Because first it ran onKeyDown and then onChange. Safari issue only.
        if(allowAddMessage){
            setMessage(e.target.value);
            emitTypingEvent(supportChat.thread_id);
        } else {
            setAllowAddMessage(true);
        }

    }

    useEffect(() => {
        if(!adminActive){
            return;
        }
        
        setSupportChatStatus_id(adminActive?.id || 4); // id = status_id
        setSupportChatStatus(adminActive?.status || '');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[adminActive]);

    const fetchUnclaimedChatInfo = async () => {
        const chat = await getSupportChats(20, 0, supportChat?.thread_id);

        const isExpired = chat?.unclaimed?.status?.toLowerCase() === 'expired';
        if(isExpired){
            setIsExpired(true);
        }
    }

    useEffect(() => {
        let chatExpirationTime = new Date((new Date()).getTime() + THIRTY_MINUTES_IN_MS).getTime();
        if(supportChat?.created_at){
            chatExpirationTime = new Date((new Date(supportChat.created_at)).getTime() + THIRTY_MINUTES_IN_MS).getTime();
        }
        const nowInMs = new Date().getTime();
        if(supportChat?.unclaimed?.status === "" && nowInMs < chatExpirationTime){
            if(timeoutId){
                clearTimeout(timeoutId);
            }
            let timeTaken = THIRTY_MINUTES_IN_MS;
            if(supportChat?.created_at){
                timeTaken = chatExpirationTime - nowInMs;
            }
            
            timeoutId = setTimeout(() => {
                fetchUnclaimedChatInfo();
            },timeTaken);
        }
        
        return () => {
            clearTimeout(timeoutId);
        }
    },[supportChat])

    const onClickClose = () => {
        setNormalAdminChatOpen(false);
        onClose(false);

        normalAdminMessages?.resolved && setNormalAdminMessages(null!);
        document.body.style.overflow = 'unset';
    }

    const onClickSendHandler = () => {
        setAllowAddMessage(false);
        onClickSend();
    }


    /* 
        TODO: Grabbig super admin name from chats where message is a NOTIFICATION.
        We may want to change it later to some other reliable way of grabbing super admin. 
    */
    const notifications_by_superadmin = messages.length===0 ? [] : messages.filter( (m:any)=>m.type==="NOTIFICATION");
    const send_by = notifications_by_superadmin.length===0 ? '' : notifications_by_superadmin[notifications_by_superadmin.length-1].sent_by;

    const isSendButtonDisabled = !message.replace(/\s+/g, '') || isExpired || normalAdminMessages?.resolved ; /*status_id === 4  && --- || (!supportChat.thread_id || (!!adminActive?.active_chat_ids.length && !adminActive?.active_chat_ids?.includes(supportChat.thread_id)))*/ 
    const isTextareaDisabled = isExpired || normalAdminMessages?.resolved  ||  (status_id === 4 && messages.length === 0) ; /* || (/*status_id === 4 && !supportChat.thread_id || (!!adminActive?.active_chat_ids.length && !adminActive?.active_chat_ids?.includes(supportChat.thread_id))) */
    const textareaPlaceholder = (status_id === 4 && messages.length === 0) ? "Currently unavailable" : "Type your question here";

    return (
        <>
            <div className="showChatBox">
                <div className="chatBox">
                    <div className="d-flex mb-4 justify-content-between chatBox__header">
                        <div className="d-flex justify-space-between flex-sm-row flex-column">
                            <div onClick={onClickClose} className="cursor-pointer">
                                <span className="accordion__header-button">
                                    <img
                                        src={DrodownIcon}
                                        alt="arrow"
                                        className="rotate-180"
                                    />
                                </span>
                            </div>
                            <div className="mx-3">
                                <h3 className="fw-bold text-white chatBox__alphaHeading">Chat with Alpha Plus</h3>
                                <p className="mb-0">Alpha Plus Live Support</p>
                            </div>
                            <div className="chatBox__backTime">
                                {/* <span className={status_id === 1 ? 'active' : (status_id === 2 || status_id === 3) ? 'not-active' : 'unavailable'}></span>
                                <p className="mb-0 ms-4 ">{status}</p> */}

                                {
                                    loading ? (
                                        <ChatHeaderSkeleton className={'circle'} />
                                    ) : (
                                        <span className={status_id === 1 ? 'active' : (status_id === 2 || status_id === 3) ? 'not-active' : 'unavailable'}></span>
                                    )
                                }

                               {    
                                    loading ? (
                                        <ChatHeaderSkeleton className={'chat-status-text'} />
                                    ) : (
                                        <p className="mb-0 ms-4">{status}</p>
                                    )
                                }
                            </div>
                        </div>
                        <div className="chatbox-chatIcon">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none">
                                <mask id="mask0_1007_1793" style={{"maskType":"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="55" height="55">
                                <path d="M55 0.998047H0V54.998H55V0.998047Z" fill="white"/>
                                </mask>
                                <g mask="url(#mask0_1007_1793)">
                                <path d="M18.863 33.4286C15.9952 33.4311 13.1889 32.597 10.788 31.0286L4.01904 33.4286L6.41904 26.6586C5.16247 24.7214 4.37526 22.5177 4.12 20.2228C3.86475 17.9279 4.1485 15.6051 4.94869 13.4392C5.74887 11.2732 7.04342 9.32381 8.72937 7.74607C10.4153 6.16832 12.4462 5.00574 14.6604 4.35077C16.8746 3.69581 19.2111 3.56653 21.484 3.97323C23.757 4.37993 25.9037 5.31138 27.7534 6.6935C29.6031 8.07562 31.1048 9.87028 32.1391 11.9348C33.1733 13.9992 33.7116 16.2765 33.711 18.5856" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M18.8628 24.1249C21.4519 24.1249 23.5508 21.6446 23.5508 18.5849C23.5508 15.5253 21.4519 13.0449 18.8628 13.0449C16.2737 13.0449 14.1748 15.5253 14.1748 18.5849C14.1748 21.6446 16.2737 24.1249 18.8628 24.1249Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M19.644 24.21L22.769 24.835" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M50.0581 45.4098C52.0589 42.329 52.8482 38.6165 52.2736 34.9883C51.6991 31.36 49.8014 28.073 46.9466 25.7612C44.0919 23.4493 40.4822 22.2764 36.8137 22.4686C33.1453 22.6608 29.678 24.2046 27.0804 26.8021C24.4829 29.3996 22.9392 32.867 22.7469 36.5354C22.5547 40.2039 23.7276 43.8135 26.0395 46.6683C28.3513 49.5231 31.6383 51.4208 35.2666 51.9953C38.8949 52.5699 42.6074 51.7806 45.6881 49.7798L52.4571 52.1798L50.0581 45.4098Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M37 31.998L33 42.998" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M37 31.998L41 42.998" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M35 38.998H40" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                            </svg> */}
                            <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.8621 31.4685C12.9942 31.471 10.188 30.637 7.78707 29.0685L1.01807 31.4685L3.41807 24.6985C-0.0286584 19.3849 0.276607 12.4694 4.17813 7.4801C8.07965 2.4908 14.7175 0.527302 20.7054 2.5913C26.6933 4.6553 30.7115 10.2919 30.7101 16.6255" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <ellipse cx="15.8618" cy="16.625" rx="4.688" ry="5.54" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.6431 22.25L19.7681 22.875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M47.0572 43.4499C51.2012 37.0692 49.8584 28.5894 43.9457 23.8013C38.0331 19.0131 29.4594 19.4624 24.0795 24.8422C18.6997 30.2221 18.2504 38.7958 23.0386 44.7084C27.8267 50.6211 36.3065 51.9639 42.6872 47.8199L49.4562 50.2199L47.0572 43.4499Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M34 30L30 41" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M34 30L38 41" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M32 37H37" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>

                    {
                        loading 
                        ? (
                            // <div style={{flexDirection: 'unset', alignItems: 'center', justifyContent: 'center'}} className="chatBox__notifications">
                            //     <Spinner animation="border" variant="primary" />
                            // </div>
                            <div className='normal-admin-skeleton'>
                                 <div className="chatbox-chatSkeleton">
                                    <div className="profile"></div>
                                    <div className="text"></div>
                                </div>
                                
                                <div className="chatbox-chatSkeleton">
                                    <div className="profile"></div>
                                    <div className="text"></div>
                                </div>

                                <div className="chatbox-chatSkeleton">
                                    <div className="profile"></div>
                                    <div className="text"></div>
                                </div>
                                <div className="chatbox-chatSkeleton">
                                    <div className="profile"></div>
                                    <div className="text"></div>
                                </div>

                                <div className="chatbox-chatSkeleton">
                                    <div className="profile"></div>
                                    <div className="text"></div>
                                </div>
                                <div className="chatbox-chatSkeleton">
                                    <div className="profile"></div>
                                    <div className="text"></div>
                                </div>

                                <div className="chatbox-chatSkeleton">
                                    <div className="profile"></div>
                                    <div className="text"></div>
                                </div>
                            </div>
                        )
                        : 
                         messages.length > 0 && (!!supportChat.thread_id || (!!adminActive?.active_chat_ids?.length && adminActive?.active_chat_ids?.includes(supportChat.thread_id)))
                            ?
                        
                                <div className="chatBox__notifications" ref={notificationsRef} onScroll={onScrollMessages}>
                                    {isExpired && (
                                        <div className="expire-chat">
                                            <div className="chat-unavailable">
                                                <span 
                                                    className=""
                                                >
                                                    Sorry, it appears no one is home but we left the lights on. Please create a ticket and we'll respond when we return.<span className="create-supportTicket" onClick={() => toggleSupportModal(true)}>Create a Support Ticket</span>
                                                </span>
                                                
                                            </div>
                                        </div>
                                    )}

                                    {
                                        normalAdminMessages?.ticket_created ? (
                                            <div className="d-flex align-items-center mb-2">
                                                <div className="no-chat-img">
                                                    <img src={NoChatHand} alt="No-Chat" />
                                                </div>
                                                <p style={{color: 'black'}} className="mb-0 text">
                                                    A support ticket has been created concerning your issue and this chat has ended. Thank you.
                                                </p>
                                            </div>
                                        ) : (
                                            normalAdminMessages?.resolved ? 
                                                <div className="d-flex align-items-center mb-2">
                                                    <div className="no-chat-img">
                                                        <img src={NoChatHand} alt="No-Chat" />
                                                    </div>
                                                    <p style={{color: 'black'}} className="mb-0 text">
                                                        This chat has ended. Thank you.
                                                    </p>
                                                </div> 
                                            : 
                                            ''
                                        )
                                    }
                                    {messages.map((item: any) => {

                                        const { message, sent_by_initials, date, color_hex, type } = item;
                                        return (
                                            <div className="d-flex align-items-md-center py-2">

                                            {
                                                type !== "NOTIFICATION" ?
                                                    <>
                                                        <div style={{ backgroundColor: color_hex }} className="chatBox__notifications__name2">
                                                            {sent_by_initials}
                                                        </div>

                                                        <div className="chatBox__notifications__messageWidth">
                                                            <p className="chatBox__notifications__message">{message}</p>
                                                            <p className="chatBox__notifications__time">
                                                                {moment(date).format('h:mm A')}
                                                            </p>
                                                        </div>

                                                        
                                                    </>
                                                    :
                                                    <>
                                                        <div style={{ backgroundColor: '#ffffff', marginRight: '0px' }} className="chatBox__notifications__name2">
                                                            <div className="chatBox__notifications__handGestureIconWrapper ">
                                                                <img src={HandGestureIcon} alt="HandGestureIcon" />
                                                            </div>
                                                        </div>
                                                        <p className="chatBox__notifications__message handGestureMsg" style={{marginLeft: '7px'}}><strong>{message}</strong></p>
                                                    </>
                                            }




                                            </div>
                                        )
                                    })}
                                </div>

                            :  (

                                
                             (status_id === 4 && messages.length === 0)  /*|| (!!supportChat.thread_id )) || (!!adminActive?.active_chat_ids?.length && adminActive?.active_chat_ids?.includes(supportChat.thread_id)) */ // status_id === 4 &&
                                ?  ( 
                                    // This means that there is no Super Admin Available for Chat right now.
                                    <div className="chatBox__notification">
                                        <div className="chat-unavailable">
                                            <span 
                                                className=""
                                            >
                                                Chat is currently unavailable.
                                            </span>
                                            <span className="create-supportTicket" onClick={() => toggleSupportModal(true)}>Create a Support Ticket</span>
                                        </div>
                                    </div>
                                ) 
                                : (
                                    <div className="chatBox__notification">
                                        { 
                                            <div>
                                                <p>Type your question below and we will respond shortly.</p>
                                            </div>
                                        }
                                    </div>
                                )
                            )
                    }

                    {chatTyping && (
                        <div className='chatbox-typing'>
                            <p><span>{send_by ? send_by + " is typing..." : "typing..."}</span></p>
                        </div>
                    )}

                    <div className="mt-3 chatBox__inputField">
                        <textarea 
                            ref={textAreaRef} 
                            value={message} 
                            placeholder={textareaPlaceholder} 
                            disabled={isTextareaDisabled} 
                            onChange={onTypeMessage} 
                            onKeyDown={(e) => {e.ctrlKey && e.key === 'Enter' && onClickSendHandler()}} 
                        />
                    </div>
                    <div className="chatBox__SendBtn">
                        <button 
                            disabled={isSendButtonDisabled} 
                            onClick={onClickSend} 
                            className="chatBox__SendBtn__btn"
                        >
                            &nbsp;&nbsp;&nbsp;&nbsp;Send (Ctrl+Enter)&nbsp;&nbsp;&nbsp;&nbsp;
                        </button>
                    </div>
                </div>

            </div>

            {showSupportModal &&
                <ContactSupportModal
                    closeModal={() => toggleSupportModal(false)}
                    isShow={showSupportModal}
                    setShowSuccess={setShowSuccess}
                />
            }
        </>
    )

}

export default ChatBox