import { FC } from "react";
import WarningIcon from "assets/img/warning-icon.svg";
import moment from "moment";
import CustomModal from "components/common/Modal";
interface Props {
  isShow: boolean;
  closeModal: () => void;
  objectiveNumber: string;
  classroomName: string;
  students: any;
  total: number;
  date: Date;
  test_type: number;
}
const RiskModal: FC<Props> = ({ isShow, closeModal, objectiveNumber, classroomName, students, date, test_type }) => {
  return (
    <CustomModal
      centered
      show={isShow}
      onHide={closeModal}
      className="risk-report-modal"
      contentClassName="modal-content-risk-report"
    >
      <div className="modal__header">
        <img src={WarningIcon} className="modal__header-icon" alt="" />
        <h6 className="modal__header-title">{`${objectiveNumber} - ${classroomName}`}</h6>
      </div>
      <div className="modal__header-subTitle">
        <p>Students at Risk Based on Summative {test_type === 1 ? 'A1' : test_type === 2  ? 'B' : 'A2'} Performance</p>
      </div>

      <div className="modal__body risk-modal-body">
        <div className="risk-modal__table-wrapper">
        <div className="risk-modal__table-container">
          <table className="modal__body__table risk-modal__table">
          <thead className="risk-report-modal__table">
          <tr className="risk-modal__table-headerRow">
            <th className="risk-modal__table-count">
              {students.length}
            </th>
            <th className="risk-modal__table-name">Last Name</th>
            <th className="risk-modal__table-last">First Name</th>
            <th className="risk-modal__table-id">Student ID <br/> Last 5</th>
            <th className="risk-modal__table-date">Assessment Date</th>
            <th className="risk-modal__table-proficiency">Proficiency</th>
            <th className="risk-modal__table-questions"># Questions Correct</th>
            <th></th>
          </tr>
        </thead>
            <tbody className="risk-modal__table-body">
              {
                students.map((student: any) => {
                  const { first_name, last_name, proficiency, correct, total_questions, school_student_id } = student
                  return <tr className="risk-modal__table-row">
                    <td></td>
                    <td className="risk-modal__table-name">{last_name}</td>
                    <td className="risk-modal__table-last">{first_name}</td>
                    <td className="risk-modal__table-id">{school_student_id?.slice(-5)}</td>
                    <td className="risk-modal__table-date">{moment(date).format('l')}</td>
                    <td className="risk-modal__table-proficiency">{proficiency} %</td>
                    <td className="risk-modal__table-questions">{`${correct}/${total_questions}`}</td>
                    <td></td>
                  </tr>
                })
              }
            </tbody>
          </table>
        </div>
        </div>
      </div>
      <div className="modal__footer">
        <button
          className="btn cancel-btn"
          onClick={closeModal}
        >
          Okay
        </button>
      </div>
    </CustomModal>
  )
}
export default RiskModal
