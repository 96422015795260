import { Button } from "components/common/buttonComponent";
import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { handlePress, handleKeyUp } from "util/index";
import {
  formats,
} from "components/quill/EditorToolbar";
import ReactQuill from "react-quill";
import CustomModal from "components/common/Modal";

interface SuperAdminAddProps {
  onCancel: () => void;
  onSave: (data: any) => Promise<any>;
  data: any;
}
  
const EditGoalModal: React.FC<SuperAdminAddProps> = ({
  onCancel,
  onSave,
  data
}) => {
    const {textHeader, instructional_text, short_description} = data;
    const [description, setDescription] = useState(instructional_text || '');
    const [title, setTitle] = useState(data?.title || '');
    const [shortDescription, setShortDescription] = useState(short_description || '');
    const [loading, setLoading] = useState(false);

    const quillRef = useRef<any>();

    const isDescriptionChanged = description !== instructional_text;
    const isShortDescriptionChanged = shortDescription !== short_description;
    const isTitleChanged = title !== data?.title;
    
    const onSubmit = async () => {
      setLoading(true);
      await onSave({
        ...(isDescriptionChanged && {instructional_text: description}),
        ...(isShortDescriptionChanged && {short_description: shortDescription}),
        ...(isTitleChanged && {goal: title})
      });
      setLoading(false);
      onCancel();
    };
  
    const handleCancel = () => {
      onCancel();
    };

    useEffect(() => {

      document.addEventListener('keydown', handlePress);
      document.addEventListener('keyup', handleKeyUp);
      return () => {
        document.removeEventListener('keydown', handlePress);
        document.removeEventListener('keyup', handleKeyUp);
      }
    }, []);


    const isDisabled = !isDescriptionChanged && !isShortDescriptionChanged && !isTitleChanged;
    return (
      <CustomModal 
        centered 
        show={true} 
        onEscapeKeyDown={handleCancel}
        onHide={handleCancel}
        contentClassName="PacingCalender__SuggestedModel goal-edit-modal"
      >
        <div>
          <div className="modal__header">
            <h6 className="modal__header-title">{textHeader}</h6>
          </div>
          <div className="modal__body editGoalEditor">
            <div className="modal__form">
              <div className="modal__editor editor-middle-body">
                <Row>
                  <Col className="">
                    <div className="modal__form-row">
                      <label>Title</label>
                      <input
                        name="label"
                        type="text"
                        value={title}
                        onChange={(e: any) => setTitle(e.target.value)}
                      />
                      <span className="d-none"></span>
                    </div>
                    <div className="modal__form-row">
                      <h5 className="text-white">Short Description:</h5>
                      <ReactQuill
                        ref={quillRef}
                        defaultValue={shortDescription}
                        modules={{
                          toolbar: [
                            ["bold", "italic", "underline", "link"],
                            [{'color': ["#000000","#e60000","#ff9900","#ffff00","#e60000","##0066cc","#9933ff","#ffffff","#facccc","#ffebcc","#cce8cc","#cce0f5","#ebd6ff","#bbbbbb","#f06666","#ffc266","#ffff66","#66b966","#66a3e0","#c285ff","#888888","#a10000","#b26b00","#b2b200","#006100","#0047b2","#6b24b2","#444444","#5c0000","#663d00","#666600","#003700","#002966","#3d1466","#e60000"]}]
                          ],
                          clipboard: {
                            matchVisual: false
                          }
                        }} 
                        formats={formats} 
                        value={shortDescription} 
                        onChange={((value: any) => {
                          setShortDescription(value);
                        })}
                      />
                    </div>
                  </Col>
                  <Col className="instruction-column">
                    
                    <h5 className="text-white">Instructions:</h5>
                      <ReactQuill
                        ref={quillRef}
                        defaultValue={description}
                        modules={{
                          toolbar: [
                            ["bold", "italic", "underline", "link"],
                            [{'color': ["#000000","#e60000","#ff9900","#ffff00","#e60000","##0066cc","#9933ff","#ffffff","#facccc","#ffebcc","#cce8cc","#cce0f5","#ebd6ff","#bbbbbb","#f06666","#ffc266","#ffff66","#66b966","#66a3e0","#c285ff","#888888","#a10000","#b26b00","#b2b200","#006100","#0047b2","#6b24b2","#444444","#5c0000","#663d00","#666600","#003700","#002966","#3d1466","#e60000"]}]
                          ],
                          clipboard: {
                            matchVisual: false
                          }
                        }} 
                        formats={formats} 
                        value={description} 
                        onChange={((value: any) => {
                          setDescription(value);
                        })}
                      />
                    
                  </Col>
                </Row>
            
              </div>
            </div>
          </div>
          <div className="modal__footer">
            <Button
              buttonText="Cancel"
              onClick={handleCancel}
              className="btn cancel-btn"
              disabled={loading}
              type="button"
            />
            <Button
              disabled={isDisabled}
              buttonText="Save"
              onClick={onSubmit}
              className={`btn success-btn btn-lg ${
              (loading || isDisabled ) && "btn-disabled"
              }`}
              loading={loading}
              type="button"
              id='save-button'
            />
          </div>
        </div>
      </CustomModal>
    );
};

export default EditGoalModal;