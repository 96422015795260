import { useContext, useEffect, useState } from "react";
import cookie from "js-cookie";
import SelectDropdown from "assets/img/profile-dropdown-icon.svg";
import ChangePasswordModal from "../change-password/change-password-modal";
import { ContactSupportModal } from "../ContactSupport/ContactSupportModal";
import SuccessModal from "../common/successModal/index";
import { ChatSocketContext } from "contexts/socketContext";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FeedbackSurvey from "components/satisfaction-survey";
import { AppCookies } from "util/constants";
// import { useShowSurvey } from "hooks/useShowSurvey";

interface Props {
  userLogout: (event: any) => void;
  containerClassName?: string;
  setProfileColor: (color: any) => void;
}

interface RootState {
  auth: any;
}

const UserDropDown: React.FC<Props> = (props) => {
  const app = useSelector((state: RootState) => state);
  // const dispatch = useDispatch();

  // const {getShowSurvey} = useShowSurvey();

  const { headerNotification, settingHeaderState } =
    useContext(ChatSocketContext) || {};
  const {
    userLogout,
    containerClassName = "header__user",
    setProfileColor,
  } = props;
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showSupportModal, toggleSupportModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [satisfactionSurvey, setSatisfactionSurvey] = useState(false);
  const fullName: any = `${cookie.get(AppCookies.first_name)} ${cookie.get(
    AppCookies.last_name
  )}`;

  const supportPin: any = `${cookie.get(AppCookies.support_pin)}`;

  const profileColorCode: any = `${cookie.get(AppCookies.profile_color_code)}`;

  const colorProfile = app.auth.colorProfile;

  const [selectedColor, setSelectedColor] = useState(0);
  const [colors, setColors] = useState<any[]>([]);
  const [colorIndex, setColorIndex] = useState(0);

  const initials = fullName.match(/\b(\w)/g).join("");
  const userRole = cookie.get(AppCookies.role);

  const onClickSurveyModal = () => {
    setSatisfactionSurvey(true);
  };

  useEffect(() => {
    if (colorProfile) {
      setSelectedColor(colorProfile.selected.fk_color_code_id);
      setColors(colorProfile.colors);
      setColorIndex(
        colorProfile.colors.findIndex(
          (color: any) => color.id === colorProfile.selected.fk_color_code_id
        )
      );
    }
  }, [colorProfile]);

  const handleColorClick = (index: number) => {
    setProfileColor(colors[index]);
    setSelectedColor(colors[index].id);
    setColorIndex(index);
    cookie.set(AppCookies.profile_color_code, colors[index].code);
  };

  // const headerNotificationToDisplay = headerNotification ? headerNotification : app?.auth?.colorProfile?.support_ticket_notification_count;

  useEffect(() => {
    settingHeaderState(
      app?.auth?.colorProfile?.support_ticket_notification_count || null
    );
  }, [
    app?.auth?.colorProfile?.support_ticket_notification_count,
    settingHeaderState,
  ]);

  const isShowSurvey = colorProfile?.show_survey;
  return (
    <div className={containerClassName}>
      <div className="login-account-info dropdown">
        <a
          href="!#"
          className="text-decoration-none dropdown-toggle login-account-profile"
          id="loginuser"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span
            className="username-box"
            style={{
              backgroundColor:
                colors && colors[colorIndex]?.code
                  ? colors[colorIndex]?.code
                  : profileColorCode,
            }}
          >
            {headerNotification && (
              <span
                className={`userCounter ${
                  headerNotification.toString().length > 2
                    ? "userMaxCounter"
                    : ""
                }`}
              >
                {headerNotification > 99 ? "99+" : headerNotification}
              </span>
            )}
            {initials}
          </span>

          <div className="login-user-info">
            <p className="login-user-name">{fullName}</p>
            <p className="login-user-detail">{userRole}</p>
          </div>
          <span className="user-dropdown-icon">
            <img src={SelectDropdown} alt="selectDropdown" />
          </span>
        </a>
        <ul
          className="dropdown-menu text-small login-user-dropdown"
          aria-labelledby="loginuser"
        >
          <li>
            <a
              className="dropdown-item"
              href="https://alphaplus-prod-teacher-ui-v3.s3.us-east-2.amazonaws.com/resources/goals/QuickStart_APO_24-25.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Quick Start Guide
            </a>
          </li>
          {colorProfile?.pd_enabled && (
            <li>
              <Link
                className="dropdown-item"
                to="/mastery-videos"
                rel="noopener noreferrer"
              >
                Mastery Video Series
              </Link>
            </li>
          )}
          <li onClick={() => setShowChangePassword(true)}>
            <span className="dropdown-item">Change Password</span>
          </li>
          <li>
            {app.auth?.currentUser?.role === "Super Admin" ? (
              <Link
                className="dropdown-item"
                to="/super-admin/support-center"
                rel="noopener noreferrer"
              >
                <div className="dropdown-support-center">
                  Support Center{" "}
                  {headerNotification && (
                    <span
                      className={`userCounter ${
                        headerNotification.toString().length > 2
                          ? "userMaxCounter"
                          : ""
                      }`}
                    >
                      {headerNotification > 99 ? "99+" : headerNotification}
                    </span>
                  )}
                </div>
              </Link>
            ) : (
              <Link
                className="dropdown-item"
                to="/support-tickets"
                rel="noopener noreferrer"
              >
                <div className="dropdown-support-center">
                  Support Inbox{" "}
                  {headerNotification && (
                    <span
                      className={`userCounter ${
                        headerNotification.toString().length > 2
                          ? "userMaxCounter"
                          : ""
                      }`}
                    >
                      {headerNotification > 99 ? "99+" : headerNotification}
                    </span>
                  )}
                </div>
              </Link>
            )}
          </li>

          <li>
            <span
              className="dropdown-item"
              onClick={() => toggleSupportModal(true)}
            >
              Create Support Ticket
            </span>
          </li>

          {isShowSurvey && (
            <li>
              <span className="dropdown-item" onClick={onClickSurveyModal}>
                Feedback Survey
              </span>
            </li>
          )}

          {supportPin && supportPin !== "0" && (
            <li>
              <span className="dropdown-item">
                <div className="dropdown-support-pin">
                  Support PIN <span className="pin-color">{supportPin}</span>
                </div>
              </span>
            </li>
          )}

          {colors && colors.length > 0 && (
            <>
              <li>
                <hr className="dropdown-separator" />
              </li>
              <li>
                <div className="color-panel">
                  {colors.length &&
                    colors?.map((color, index) => {
                      return (
                        <div
                          key={index}
                          className="color-box"
                          style={{
                            backgroundColor: color?.code,
                            border:
                              selectedColor === color?.id
                                ? "2px solid white"
                                : "none",
                          }}
                          onClick={() => {
                            handleColorClick(index);
                          }}
                        ></div>
                      );
                    })}
                </div>
              </li>
              <li>
                <hr className="dropdown-separator" />
              </li>
            </>
          )}
          <li>
            <a className="dropdown-item" href="!#" onClick={userLogout}>
              Log Out
            </a>
          </li>
        </ul>

        {showSupportModal && (
          <ContactSupportModal
            closeModal={() => toggleSupportModal(false)}
            isShow={showSupportModal}
            setShowSuccess={setShowSuccess}
          />
        )}
        <SuccessModal
          bodyText="Thank you for contacting Alpha Plus Support. We will reach out to you within one business day. If your case is urgent, please call us at (405) 842-8408."
          headerText="Ticket Submitted"
          isShow={showSuccess}
          closeModal={() => setShowSuccess(false)}
        />

        <ChangePasswordModal
          isShow={showChangePassword}
          closeModal={() => setShowChangePassword(false)}
        />

        {satisfactionSurvey && (
          <FeedbackSurvey
            satisfactionSurvey={satisfactionSurvey}
            closeModal={() => setSatisfactionSurvey(false)}
          />
        )}
      </div>
    </div>
  );
};

export default UserDropDown;
