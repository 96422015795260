import LeftArrowYellow from "components/common/svgs/left-arrow-yellow";
import RightArrowYellow from "components/common/svgs/right-arrow-yellow";
import React from "react"

const Pagination = () => {
    return (
        <div className="splide__arrows goals-card-pagination" >
            <button className="splide__arrow--prev pagination-arrow">
                <LeftArrowYellow />
            </button>
            <ul className="splide__pagination splide__pagination--ltr"></ul>
            <button className="splide__arrow--next pagination-arrow">
               <RightArrowYellow />
            </button>
        </div>
    )
};

export default Pagination;
