import React from "react";
const titleId = "pencilIconSummative"

const PencilIcon = () => {
  return (
    <svg
      aria-label="edit"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      aria-labelledby={titleId}
    >
      <title id={titleId}>Edit</title>
      <path
        d="M17.8946 1.19885C17.2805 0.645663 16.4718 0.359037 15.6464 0.401987C14.821 0.444938 14.0464 0.813955 13.4931 1.42789L1.79563 14.4094C1.71606 14.4969 1.66025 14.6032 1.63344 14.7184L0.316116 20.6649C0.289478 20.7845 0.295099 20.9091 0.332402 21.0259C0.369704 21.1427 0.437335 21.2475 0.528409 21.3295C0.619485 21.4116 0.730737 21.468 0.85076 21.493C0.970782 21.518 1.09528 21.5106 1.21153 21.4717L6.98509 19.5435C7.09683 19.5049 7.19686 19.4383 7.27557 19.3501L18.98 6.36919C19.5324 5.75473 19.8186 4.94623 19.7756 4.12107C19.7327 3.2959 19.3642 2.52147 18.7509 1.96767L17.8946 1.19885ZM3.3254 14.7786L12.8997 4.15531L16.3259 7.24255L6.75663 17.8676L3.3254 14.7786ZM2.74301 16.1181L5.47553 18.5803L1.93113 19.7665L2.74301 16.1181ZM17.9491 5.44218L17.254 6.21359L13.8268 3.12641L14.5229 2.35496C14.8304 2.01379 15.2607 1.8087 15.7193 1.78484C16.178 1.76097 16.6273 1.92027 16.9685 2.22769L17.8228 2.99661C18.1635 3.30432 18.3683 3.7346 18.3921 4.19306C18.416 4.65152 18.256 5.10078 17.9491 5.44218Z"
        fill="#323233"
      />
    </svg>
  );
};

export default PencilIcon;
