import { useContext } from "react";
import Resources from "./resources";
import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment";
import { useNavigate } from "react-router-dom";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import Heading from "components/common/heading";
import { RootState } from "redux/reducers/combine";
import { useSelector } from "react-redux";
import Tabs from "../tabs";
import Tools from "../tools";
import { useSelectedClassroom } from "hooks/useSelectedClassroom";

const ResourcesPage = () => {
  const navigate = useNavigate();

  const { pdClassroom } = useContext(ProfessionalDevelopmentContext);
  const { book_access } = pdClassroom;
  const app = useSelector((state: RootState) => state);
  const selectedClassroom = useSelectedClassroom();
  return (
    <>
      {(app?.classroom?.grade === 9 || selectedClassroom?.students_count === 0) && (
        <>
          <Tabs defaultTabs={false}></Tabs>
          <Tools />
        </>
      )}

      <div
        style={{
          marginTop: 22,
          marginLeft: app?.classroom?.grade === 9 ? 10 : 0,
        }}
        className="accordion__header-heading"
      >
        {app?.classroom?.grade !== 9 && (
          <span tabIndex={0} 
           role="button"   
           onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              navigate(-1)
            }
          }}  
          onClick={() => navigate(-1)}  
          className="accordion__header-button">
            <img
            
              src={ArrowIcon}
              alt="arrow"
              className="rotate-90"
            />
          </span>
        )}
        <Heading className="ps-0 mb-0" text="Resources" />
      </div>
      <Resources book_access={book_access} />
    </>
  );
};

export default ResourcesPage;
