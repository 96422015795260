import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment"
import { useContext } from "react";
import SummativeAssessment from "./summatives/assessment";

const Summatives = () => {
    const { summatives, isLoading, classroomId } = useContext(ProfessionalDevelopmentContext);

    return (
        <div className="summative-assessment-sec">
            <h2 className="secHeading">Summative Assessments</h2>
            <div className="summative-assessment-table">
                {isLoading ? (
                    [0,1,2].map(id => {
                        return (
                            <SummativeAssessment 
                                assessment={{is_past_window: id === 2 ? false : true}}
                                index={id}
                            />
                        )
                    })
                ):summatives?.length > 0 ? (
                    summatives.map((assessment, index: number) => {
                    
                        return (
                            <>
                                <SummativeAssessment 
                                    assessment={assessment}
                                    index={index}
                                />
                            </>
                        )
                    })
                ):(
                    <table>
                        <tbody>
                            <tr>
                                <td colSpan={10} className="notfound__data">
                                <div className="notfound__data-container">
                                    {classroomId ? (
                                    <p className="text-center text-shadow mb-0">
                                        No{" "}
                                        {"Summative "}
                                        Found!
                                    </p>
                                    ):(
                                    <p className="text-center text-shadow mb-0">
                                        Select a classroom above to view{" "}
                                        {"Summative "}
                                        Assessments.
                                    </p>
                                    )}
                                </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    )
}
export default Summatives