/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useState } from "react";
import { CustomSelect } from "components/common/selectComponent";
import SearchIcon from "assets/img/search-icon.svg";
import { PacingCalenderContext } from "contexts/pacingCalender";
import ScheduledAssessmentTable from "./scheduledTable";
import moment from "moment";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import CustomModal from "components/common/Modal";

interface Props {
  show: boolean;
  summativeId?: number;
  formativeId?: number;
  setModalShow: any;
  setModalSuggestedShow: (show: boolean) => void;
}

const FILTER_OPTIONS = [
  { id: 1, value: 1, label: "All" },
  { id: 2, value: 2, label: "Scheduled" },
  { id: 3, value: 3, label: "Not Scheduled" },
];

const SheduledAssesmant: React.FC<Props> = (props) => {
  const {
    isSuperAdmin,
    updateOrderBy,
    scheduledDate,
    scheduledAssessments: { has_suggested, summatives, formatives, loading },
  } = useContext(PacingCalenderContext);

  const { show, setModalShow } = props;

  const [search, setSearch] = useState("");
  const [, setIsLoading] = useState(false);

  const schedule_assessment_filter = Number(
    localStorage.getItem("schedule_assessment_filter")
  );

  const [filter, setFilter] = useState<number>(schedule_assessment_filter || 3);
  const selectedFilter = FILTER_OPTIONS.find((item) => item.value === filter);

  const filteredSummative = search
    ? summatives.filter(
      (item: any) =>
        item.name.toLowerCase().includes(search.toLowerCase()) ||
        item.description.toLowerCase().includes(search.toLowerCase())
    )
    : summatives.filter((e: { scheduled_date: string | any[] }) => {
      if (selectedFilter?.label === "Scheduled") {
        return e.scheduled_date.length > 0;
      } else if (selectedFilter?.label === "Not Scheduled") {
        return e.scheduled_date.length === 0;
      }
      return true;
    });

  const filteredFormative = search
    ? formatives.filter((item: any) => {
      return (
        item.name.toLowerCase().includes(search.toLowerCase()) ||
        item.description.toLowerCase().includes(search.toLowerCase())
      );
    })
    : formatives.filter((e: { scheduled_date: string | any[] }) => {
      if (selectedFilter?.label === "Scheduled") {
        return e.scheduled_date && e.scheduled_date.length > 0;
      } else if (selectedFilter?.label === "Not Scheduled") {
        return !e.scheduled_date;
      }
      return true;
    });

  const onChangeFilter = (option: { label: string; value: number }) => {
    setFilter(option.value);
    localStorage.setItem(
      "schedule_assessment_filter",
      JSON.stringify(option.value)
    );
  };

  const closeModal = () => {
    setIsLoading(false);
    setModalShow(false);
    updateOrderBy(null!);
  };

  const onChange = (e: any) => {
    setSearch(e.target.value);
  };

  const formattedScheduledDate = moment(scheduledDate).format("dddd, MMMM D");
  const isNotScheduled = selectedFilter?.label === "Not Scheduled";
  return (
    <>
      <CustomModal
        className="PacingCalender__SheduledAssesment"
        centered
        show={show}
        onEscapeKeyDown={closeModal}
      >
        <div>
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div className="d-flex align-items-center mt-2">
              <span className="adminProfessional__container__header__backAdmin__back dsdsd">
                <span onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    closeModal()
                  }
                }} tabIndex={0} role="button" className="accordion__header-button" onClick={closeModal}>
                  <img
                    src={ArrowIcon}
                    alt="arrow"
                    className="rotate-90"
                  />
                </span>
              </span>
              <div className="">
                <h2 className="modal__header-title">
                  {isSuperAdmin ? "Add Assessment" : "Schedule Assessment"}
                </h2>
                <h4 className="modal__header-desc">
                  Select an Assessment for {formattedScheduledDate}{" "}
                </h4>
              </div>
            </div>
            <div className="mt-2">
              <div className="section-search-field mx-md-0 mx-3 justify-content-end">
                <div className="icon">
                  <img src={SearchIcon} alt="" />
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Assessments"
                  onChange={onChange}
                  value={search}
                  style={{ paddingTop: "3px" }}
                />
              </div>
              <div className="d-md-flex align-items-center d-none mt-2 show-assessment-dropdown">
                <span className="text-white">Show</span>
                <CustomSelect
                  options={FILTER_OPTIONS}
                  value={selectedFilter}
                  disableHandler={search || loading}
                  onChangeHandler={onChangeFilter}
                />
              </div>
            </div>
          </div>
          <div>
            <p className="px-3 fw-bold bg-transparent summative-modal-header schedule-title">
              Summative Assessments
            </p>
          </div>
          <div className="overflow-auto  PacingCalender__SheduledAssesment__container mt-1 rounded-2">
            <div className="PacingCalender__SheduledAssesment__container__main">
              <ScheduledAssessmentTable
                isNotScheduled={isNotScheduled}
                message={
                  !isSuperAdmin && selectedFilter?.label === "Scheduled"
                    ? "No Objectives Scheduled"
                    : ""
                }
                isSummative
                loading={loading}
                data={filteredSummative}
                onCloseModal={closeModal}
              />
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between">
            <p className="px-3 fw-bold bg-transparent summative-modal-header schedule-title">
              Formative Assessments
            </p>
            {has_suggested && (
              <div className="d-flex align-items-center">
                <p className=" px-2 bg-transparent mb-0  orderbyText">
                  Order By
                </p>
                <select
                  className="PacingCalender__SheduledAssesment__container__orderDropDown"
                  onChange={(e: any) => updateOrderBy(e.target.value)}
                  defaultValue={has_suggested ? "suggested" : ""}
                >
                  <option value="objective">Objective</option>
                  <option value="suggested">Suggested</option>
                </select>
              </div>
            )}
          </div>
          <div className="overflow-auto  PacingCalender__SheduledAssesment__container rounded-2">
            <div className="PacingCalender__SheduledAssesment__container__main">
              <ScheduledAssessmentTable
                isNotScheduled={isNotScheduled}
                message={
                  !isSuperAdmin && selectedFilter?.label === "Scheduled"
                    ? "No Objectives Scheduled"
                    : ""
                }
                loading={loading}
                data={filteredFormative}
                onCloseModal={closeModal}
              />
            </div>
          </div>
          <div className="d-flex align-items-baseline justify-content-between mt-4">
            <button
              type="button"
              className="btn cancel-btn PacingCalender__DeleteModel__cancelBtn"
              data-bs-dismiss="modal"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default SheduledAssesmant;
