import { FC } from "react";

interface Props {
  info: any;
  isESPresent: boolean;
  onClickStudentView: (details: any, lang: string) => void;
  setIsolatedStudent: Function;
}

const Record: FC<Props> = ({
  info,
  onClickStudentView,
  isESPresent,
  setIsolatedStudent,
}) => {
  const { student_name, student_id } = info;
  const isHideRow = info.isShowIsolated === false; // dont use not sign (!) with student.isShowIsolated
  return (
    <tr className="based-report__table-row">
      <td
        onClick={() => setIsolatedStudent(info)}
        className={`cursor-pointer ${
          isHideRow ? "objective-isolation" : ""
        } based-report__table-id`}
      >
        {student_id?.slice(-5)}
      </td>
      <td className="based-report__table-student">
        <span className="ellipsis-animated">
          <p
            className={`ellipsis-animated-text ${
              student_name.length > 17 ? "animated ellipsis-animation" : ""
            } ${isHideRow ? "d-none" : ""}`}
          >
            {student_name}
          </p>
        </span>
      </td>
      <td
        className={`based-report__table-english`}
        onClick={() => onClickStudentView(info, "en")}
      >
        <span
          className={`${
            isHideRow ? "d-none" : ""
          } based-report__table-language`}
        >
          en
        </span>
      </td>
      <td
        className={`${
          isESPresent
            ? "based-report__table-spanish"
            : "based-report__table-spanish disabled-item"
        }`}
        onClick={() => isESPresent && onClickStudentView(info, "es")}
      >
        <span
          className={`${
            isHideRow ? "d-none" : ""
          } based-report__table-language spanish`}
        >
          es
        </span>
      </td>
    </tr>
  );
};
export default Record;
