/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, useCallback } from "react";
import Tabs from "components/common/Tabs/Tabs";
import { Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import PdfIcon from "assets/img/big_pdf.svg";
import PdfImg from "assets/img/pdf-img.png";
import ZipIcon from "assets/img/zip-icon.svg";
import DeleteIcon from "assets/img/trash-icon.svg";
import {
  Input,
  CustomInput,
  TextAreaInput,
  ValidationHint,
} from "../common/inputComponent";
import { validateURL, handlePress, handleKeyUp } from "util/index";
import { Button } from "../common/buttonComponent";
import CustomModal from "components/common/Modal";

interface AddRecourceModalProps {
  isShow: boolean;
  onCancel: () => void;
  onAdd: (data: any) => void;
  loading: boolean;
}

export const AddRecourceModal: React.FC<AddRecourceModalProps> = ({
  isShow,
  loading,
  onCancel,
  onAdd,
}) => {
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [title, setTitle] = useState("");
  const [resourceFile, setResourceFile] = useState<any | null>(null);
  const [isDisabled, setDisabled] = useState(true);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [currentTab, setCurrentTab] = useState("File Upload");
  const [isSubmitError, setIsSubmitError] = useState(false);

  useEffect(() => {
    if (currentTab === "File Upload") {
      checkDisabled();
    }
  }, [resourceFile, description, currentTab]);

  useEffect(() => {
    if (currentTab === "URL") {
      checkURLDisabled();
    }
  }, [description, url, title, currentTab]);

  const onSubmit = () => {
    const errors = validateFields();
    let isError = false;
    if (currentTab === "File Upload") {
      isError = errors.resourceFile || errors.description;
    } else {
      isError = errors.url || errors.title || errors.description;
    }
    if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      let data = {};
      if (currentTab === "File Upload") {
        data = {
          file: resourceFile,
          description: description,
          index: 1,
        };
      } else {
        data = {
          url: url,
          title: title,
          description: description,
          index: 1,
        };
      }
      onAdd(data);
      // handleCancel();
    }
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    console.log(acceptedFiles, "FILES.....................");
    setResourceFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop,
  });

  const checkDisabled = () => {
    if (resourceFile && description) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const checkURLDisabled = () => {
    if (url && title && description) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const validateFields = () => {
    const errors: any = {};
    if (currentTab === "File Upload") {
      if (resourceFile === null) {
        errors.resourceFile = "Required";
      }
    }
    if (currentTab === "URL") {
      if (url.trim().length > 0 && !validateURL(url)) {
        errors.url = "Invalid URL";
      }
      if (url.trim().length === 0) {
        errors.url = "Required";
      }
      if (title.trim().length === 0) {
        errors.title = "Required";
      }
    }
    if (description.length === 0) {
      errors.description = "Required";
    }
    return errors;
  };

  const handleOnChange = (name: string, value: string) => {
    name === "url" && setUrl(value);
    name === "title" && setTitle(value);
    name === "description" && setDescription(value);
    if (isSubmitError) {
      const isValid =
        name === "url" ? validateURL(value.trim()) : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "Required" });
      }
    }
  };

  const clearData = () => {
    setDescription("");
    setUrl("");
    setTitle("");
    setResourceFile(null);
    setDisabled(false);
    setClientErrors({});
    setIsSubmitError(false);
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  useEffect(() => {
    document.addEventListener('keydown', handlePress);
    document.addEventListener('keyup', handleKeyUp);
    return () => {
        document.removeEventListener('keydown', handlePress);
        document.removeEventListener('keyup', handleKeyUp);
    }
  }, []);

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div className="resources-modal">
        <div className="modal__header">
          <h6 className="modal__header-title">Add Resource</h6>
        </div>
        <div className="modal__tabs add-resources-tabs">
          <Tabs
            options={["URL", "File Upload"]}
            currentTab={currentTab}
            onChangeTab={(option: string) => {
              clearData();
              setCurrentTab(option);
            }}
            customClass='custom-padding'
            minWidth={currentTab === 'URL' ? 55 : 110}
          />
        </div>
        <div className="modal__body">
          {currentTab === "File Upload" ? (
            <div className="modal__form">
              <p>Drag the resource to the area below or click Choose File</p>
              <small className="px-2 text-danger">
                {clientErrors.resourceFile}
              </small>
              <div className="uploads__pdf">
                <div {...getRootProps({ className: "uploads__pdf-content" })}>
                  <input {...getInputProps()} />
                  <div className="uploads__pdf-icon">
                    {resourceFile ? (
                      ""
                    ) : (
                      <div>
                        <img src={PdfIcon} alt="Pdf" />
                        <img className="zip-icon ms-2" src={ZipIcon} alt="Zip" />
                      </div>
                    )}

                    {resourceFile?.name.endsWith(".pdf") ? (
                      <img src={PdfImg} alt="" />
                    ) : (
                      ""
                    )}
                    {resourceFile?.name.endsWith(".zip") ? (
                      <img className="" src={ZipIcon} alt="" />
                    ) : (
                      ""
                    )}
                    <p>Drag Here</p>
                  </div>
                </div>
                <p className="text-white mt-1 mb-0">{resourceFile?.name}</p>
                <div className="uploads__pdf-button">
                  <button
                    className="btn success-btn"
                    type="button"
                    onClick={() => {
                      open();
                    }}
                  >
                    Choose File
                  </button>
                </div>
              </div>
              <TextAreaInput
                fieldName="description"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors["description"] !== undefined}
                value={description}
                label="Description"
                onChange={handleOnChange}
                error={clientErrors.description}
              />
            </div>
          ) : (
            <div className="modal__form">
              <ValidationHint isSubmitError={isSubmitError} />
              <CustomInput
                fieldName="url"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors["url"] !== undefined}
                value={url}
                type="text"
                label="URL"
                autoFocus={true}
                onChange={handleOnChange}
                error={clientErrors.url}
                errorMessage="Invalid URL"
                overlayClick={() =>
                  setClientErrors({
                    ...clientErrors,
                    url: "required",
                  })
                }
              />
              <Input
                fieldName="title"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors["title"] !== undefined}
                value={title}
                type="text"
                label="Title"
                onChange={handleOnChange}
                error={clientErrors.title}
              />
              <TextAreaInput
                fieldName="description"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors["description"] !== undefined}
                value={description}
                label="Description"
                onChange={handleOnChange}
                error={clientErrors.description}
              />
            </div>
          )}
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText={currentTab === 'File Upload' ? "Upload": "Add"}
            onClick={onSubmit}
            className={`btn success-btn btn-lg ${(loading || isDisabled) && 'btn-disabled'}`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};

interface DeleteRecourceModalProps {
  loading: boolean;
  isShow: boolean;
  headerText: string;
  bodyText: string;
  onCancel: () => void;
  onDelete: () => void;
}
export const DeleteRecourceModal: React.FC<DeleteRecourceModalProps> = ({
  isShow,
  headerText,
  bodyText,
  loading,
  onCancel,
  onDelete,
}) => {

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={onCancel}>
      <div className="modal__header">
        <img alt="trash" src={DeleteIcon} className="modal__header-icon" />
        <h6 className="modal__header-title">{headerText}</h6>
      </div>
      <div className="modal__body">
        <p>{bodyText}</p>
      </div>
      <div className="modal__footer">
        <button
          disabled={loading}
          className="btn cancel-btn"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          disabled={loading}
          className="btn danger-btn delete-button-width"
          onClick={onDelete}
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Delete"
          )}
        </button>
      </div>
    </CustomModal>
  );
};

interface UpdateRecourceModalProps {
  isShow: boolean;
  resource: any;
  onCancel: () => void;
  onUpdate: (data: any) => void;
  loading: boolean;
}
export const UpdateRecourceModal: React.FC<UpdateRecourceModalProps> = ({
  isShow,
  loading,
  onCancel,
  onUpdate,
  resource,
}) => {
  const [filename, setFilename] = useState(resource.filename.split("/").pop());
  const [description, setDescription] = useState(resource?.description);
  const [url, setUrl] = useState(resource.file_url);
  const [isDisabled, setDisabled] = useState(true);
  const [clientErrors, setClientErrors] = useState<any>({});
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [closeModal, setCloseModal] = useState(true);

  useEffect(() => {
    checkDisabled();
  }, [filename, description, url]);

  const checkDisabled = () => {
    if (
      filename !== resource.filename.split("/").pop() ||
      description !== resource?.description ||
      url !== resource?.file_url
    ) {
      setDisabled(false);
      setCloseModal(false);
    } else {
      setDisabled(true);
      setCloseModal(true);
    }
  };

  const onSubmitEditResource = () => {
    const errors = validateFields();
    const isError = errors.filename || errors.description || errors.url;
    if(isDisabled && !isError) {
      return handleCancel();
    }else if (isError) {
      setClientErrors(errors);
      setIsSubmitError(true);
    } else {
      let data: any = {
        filename: filename,
        description: description,
        category: resource?.category,
        subject_id: resource?.subject_id,
        grade_id: resource?.grade_id,
        index: resource?.index,
      };
      if(!resource.is_file){
        data = {...data, file_url: url}
      }
      onUpdate(data);
      handleCancel();
    }
  };

  const validateFields = () => {
    const errors: any = {};
    if(!resource.is_file){
      if (url.trim().length > 0 && !validateURL(url)) {
        errors.url = "Invalid URL";
      }
      if (url.trim().length === 0) {
        errors.url = "required";
      }
    }
    if (filename.trim().length === 0) {
      errors.filename = "required";
    }
    if (description.trim().length === 0) {
      errors.description = "required";
    }
    return errors;
  };

  const clearData = () => {
    setDescription("");
    setDisabled(false);
    setUrl("");
    setClientErrors({});
    setFilename("");
    setIsSubmitError(false);
  };

  const handleOnChange = (name: string, value: string) => {
    name === "filename" && setFilename(value);
    name === "url" && setUrl(value);
    name === "description" && setDescription(value);
    if (isSubmitError) {
      const isValid =
        name === "url" ? validateURL(value.trim()) : value.trim().length > 0;
      if (clientErrors[name] === undefined && isValid) {
        return;
      } else {
        setClientErrors({ ...clientErrors, [name]: isValid ? "" : "required" });
      }
    }
  };

  const handleCancel = () => {
    clearData();
    onCancel();
  };

  useEffect(() => {
    document.addEventListener('keydown', handlePress);
    document.addEventListener('keyup', handleKeyUp);
    return () => {
        document.removeEventListener('keydown', handlePress);
        document.removeEventListener('keyup', handleKeyUp);
    }
  }, []);

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={handleCancel}>
      <div>
        <div className="modal__header">
          <h6 className="modal__header-title">Edit Resource</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <ValidationHint isSubmitError={isSubmitError} />
             {!resource.is_file && 
             <CustomInput
                fieldName="url"
                isSubmitError={isSubmitError}
                errorPresentBefore={clientErrors["url"] !== undefined}
                value={url}
                type="text"
                label="URL"
                onChange={handleOnChange}
                error={clientErrors.url}
                errorMessage="Invalid URL"
                overlayClick={() =>
                  setClientErrors({
                    ...clientErrors,
                    url: "required",
                  })
                }
              />
             }
            <Input
              fieldName="filename"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["filename"] !== undefined}
              value={filename}
              autoFocus={true}
              type="text"
              label="Filename"
              onChange={handleOnChange}
              error={clientErrors.filename}
            />
            <TextAreaInput
              fieldName="description"
              isSubmitError={isSubmitError}
              errorPresentBefore={clientErrors["description"] !== undefined}
              value={description}
              label="Description"
              onChange={handleOnChange}
              error={clientErrors.description}
            />
          </div>
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={handleCancel}
            className="btn cancel-btn mx-3"
            disabled={loading}
            type="button"
          />
          <Button
            buttonText="Save"
            onClick={onSubmitEditResource}
            className={`btn success-btn btn-lg ${(loading || isDisabled) && 'btn-disabled'}`}
            loading={loading}
            type="button"
            id="save-button"
          />
        </div>
      </div>
    </CustomModal>
  );
};
