/* eslint-disable react-hooks/exhaustive-deps */

import CustomModal from "components/common/Modal";
import { FC } from "react";

interface Props {
    show: boolean;
    closeModal: any;
    message?: string;
    assessment: string;
}

const ReportClickAlertModal: FC<Props> = (props) => {
    const { show, closeModal, message, assessment} = props;

    return (
        <CustomModal
            centered
            show={show}
            onEscapeKeyDown={closeModal}
        >
            <div className="modal__header">
                <h2 className="modal__header-title">
                  Report
                </h2>
            </div>
            <div className="modal__body review-modal">
                <p className="modal__body-text mb-0">
                  {message ?? `This report`} will be available when at least one student has submitted a {assessment} assessment.
                </p>
            </div>
            <div className="modal__footer">
                <button
                    type="button"
                    className="btn success-btn"
                    onClick={closeModal}
                >
                    Got it
                </button>
            </div>
        </CustomModal>
    );
};

export default ReportClickAlertModal;
