// import React, { useState } from 'react'
import { Modal } from "react-bootstrap";
import BackArrow from "assets/img/accordian-box-icon.png";

import { useEffect, useRef } from "react";
import moment from "moment";
import GoalContainer from "./goalContainer";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";

interface Props {
    onClose: () => void;
    goalsList: any;
    onClickGoal: Function;
    loading: boolean
}


const GoalCompletionModalOld : React.FC<Props> = ({onClose, goalsList, onClickGoal, loading}) => {

    const modalRef = useRef<any>(null);

    // console.log('goalsList', goalsList);
    const {week_goals, recurring_week_goals=[]} = goalsList;
    const isNoData = week_goals.length === 0 && recurring_week_goals.length === 0;
    const isSingle = (week_goals.length> 0 && recurring_week_goals.length === 0) || (week_goals.length===0 && recurring_week_goals.length>0);


    useEffect(() => {
        const outsideClick = (e: any) => {
            if(modalRef.current && modalRef.current.contains(e.target)){
                return;
            }

            onClose();
        }

        document.addEventListener('click', outsideClick, true);

        return () => {
            document.removeEventListener('click', outsideClick, true);
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[]);

    if(loading){
        return (
            <Modal
                className="wayReport-modal way-to-report-goals"
                centered
                show
                onEscapeKeyDown={onClose}
            >
            <div className="no-data-goal-skeleton">
                <table>
                    <TableSkeleton count={1} columns={1} height={487} />
                </table>
            </div>
        </Modal>
        )
    }

    return (
        <Modal
            className={`wayReport-modal way-to-report-goals ${isSingle ? 'seeAllModal' : ''}`}
            centered
            show
            onEscapeKeyDown={onClose}
        >
            <div ref={modalRef}>
                {!loading && (
                    <div className="d-flex align-items-center modal-header">
                        <div className="backArrow cursor-pointer">
                            <img src={BackArrow} alt="Back Arrow" onClick={() => onClose()} />
                        </div>
                        
                            <div>
                                <h2 className="modal__header-title">
                                    Current Goals
                                </h2>
                                <h4 className="header-desc">
                                    Click on a goal to view details
                                </h4>
                            </div>
                        
                    </div>
                )}

                <div className={`see-all-goals ${isNoData ? 'no-data-goal' : ''}`}>
                    {/* {loading && (
                        <div className="no-data-goal-skeleton">
                            <table>
                                <TableSkeleton count={1} columns={1} height={415} />
                            </table>
                        </div>
                    )} */}
                    {!loading && week_goals.length > 0 && (
                        <div className="see-goals-sec">
                            <p className="goalsHeading">Goals</p>
                            <div className="see-goals-body">
                                {week_goals.map((item: any, index: number) => {
                                const {available_date, goals, is_available} = item;
                                const formattedDate = moment(available_date).format('dddd, MMMM D')
                                return (
                                    <div key={index} className="available-date">
                                        <div className="available-header">
                                            {is_available ? 'First' : 'Will Be'} Available {formattedDate}
                                        </div>
                                        <div className="available-body">
                                            {goals.map((item: any, index: number) => {
                                                const {goal, is_multi_step, marked, completed_tasks, tasks, complete_by, is_enabled=true} = item;
                                                const heading = `Complete by ${moment(complete_by).format('MMMM D')}`;
                                                return (
                                                    <GoalContainer
                                                        key={index}
                                                        completed_tasks={completed_tasks}
                                                        tasks={tasks}
                                                        is_enabled={is_enabled}
                                                        goal={goal}
                                                        heading={heading}
                                                        onClickGoal={() => onClickGoal(item)}
                                                        marked={marked}
                                                        is_multi_step={is_multi_step}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </div>
                                )})}
                            </div>
                        </div>
                    )}
                    {!loading && recurring_week_goals.length > 0 && (
                        <div className="see-goals-sec">
                            <p className="goalsHeading">Recurring Goals</p>
                            <div className="see-goals-body">
                                {recurring_week_goals.map((item: any, index: number) => {
                                    const {available_date, is_available, goals} = item;
                                    const heading = available_date !== 'This Week' ? `${is_available ? 'First' : 'Will be'} Available ${moment(available_date).format('dddd, MMMM D')}` : available_date;
                                    return (
                                        <div key={index} className="available-date">
                                            <div className="available-header">
                                                {heading}
                                            </div>
                                            <div className="available-body">
                                                {goals.map((item: any, index: number) => {
                                                    const {is_multi_step, marked, complete_by, completed_tasks, tasks, is_enabled, goal} = item;
                                                    const heading = `Complete by ${moment(complete_by).format('MMMM D')}`;
                                                    
                                                    return (
                                                        <GoalContainer
                                                            key={index}
                                                            completed_tasks={completed_tasks}
                                                            tasks={tasks}
                                                            is_enabled={is_enabled}
                                                            goal={goal}
                                                            heading={heading}
                                                            onClickGoal={() => onClickGoal(item)}
                                                            marked={marked}
                                                            is_multi_step={is_multi_step}
                                                        />
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                })}
                            
                            </div>
                        </div>
                    )}
                    {!loading && isNoData && (
                        <p>No data available.</p>
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default GoalCompletionModalOld