const InfoSection = (level?: any) => {
  const performanceLevels = level?.level || [];
  return (
    <div className="master-report__info">
      <div className="master-report__info-box">
        <p>
          The Master Report shows the results of all summative assessments for
          the classroom. The scores are color categorized by performance level,
          accompanied by the state performance level abbreviations. Click on the
          summative name dropdowns to view related reports.
        </p>
      </div>

      <div className="master-report__info-score">
        <h6 className="master-report__info-title">
          Performance Level (Percentage Score Range*)
        </h6>
        <div className="master-report__info-row">
          <div className="d-flex">
            <div className="master-report__info-item advance level-box-radius"></div>
            <p className="text-dark mb-0">Advanced</p>
          </div>
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html: `( ${performanceLevels[0]?.string || '-'} )`,
              }}
            ></span>
          </div>
        </div>
        <div className="master-report__info-row">
          <div className="d-flex">
            <div className="master-report__info-item proficient level-box-radius"></div>
            <p className="text-dark mb-0">Proficient</p>
          </div>
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html: `( ${performanceLevels[1]?.string || '-'} )`,
              }}
            ></span>
          </div>
        </div>
        <div className="master-report__info-row">
          <div className="d-flex">
            <div className="master-report__info-item basic level-box-radius"></div>
            <p className="text-dark mb-0">Basic</p>
          </div>
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html: `( ${performanceLevels[2]?.string || '-'} )`,
              }}
            ></span>
          </div>
        </div>
        <div className="master-report__info-row">
          <div className="d-flex">
            <div className="master-report__info-item belowBasic level-box-radius"></div>
            <p className="text-dark mb-0">Below Basic</p>
          </div>
          <div>
            <span
              dangerouslySetInnerHTML={{
                __html: `( ${performanceLevels[3]?.string || '-'} )`,
              }}
            ></span>
          </div>
        </div>
        <div className="master-report__info-subtitle mt-2">
          <p className="text-dark mb-0">
            *Percentage of questions answered correctly
          </p>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
