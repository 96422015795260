const Calendar = () => {
    const titleId = "pacingCalendarIcon"
    return (
        <svg style={{position: "relative", top: "-1px"}} xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none" aria-labelledby={titleId}>
           <title id = {titleId}>Pacing Calendar</title>
            <path d="M1.15039 8.49561H27.0944V27.2936H1.15039V8.49561Z" stroke="white" stroke-width="2"/>
            <path d="M1.15039 4.2998H27.0944V4.74494H1.15039V4.2998Z" stroke="white" stroke-width="2"/>
            <path d="M6.47656 12.7664H9.02756C9.16563 12.7664 9.27756 12.8882 9.27756 13.0384V15.8148C9.27756 15.9651 9.16563 16.0869 9.02756 16.0869H6.47656C6.33849 16.0869 6.22656 15.9651 6.22656 15.8148V13.0384C6.22656 12.8882 6.33849 12.7664 6.47656 12.7664Z" stroke="white" stroke-width="1.5"/>
            <path d="M6.47656 19.7012H9.02756C9.16563 19.7012 9.27756 19.823 9.27756 19.9733V22.7496C9.27756 22.8999 9.16563 23.0217 9.02756 23.0217H6.47656C6.33849 23.0217 6.22656 22.8999 6.22656 22.7496V19.9733C6.22656 19.823 6.33849 19.7012 6.47656 19.7012Z" stroke="white" stroke-width="1.5"/>
            <path d="M12.8477 19.7012H15.3987C15.5367 19.7012 15.6487 19.823 15.6487 19.9733V22.7496C15.6487 22.8999 15.5367 23.0217 15.3987 23.0217H12.8477C12.7096 23.0217 12.5977 22.8999 12.5977 22.7496V19.9733C12.5977 19.823 12.7096 19.7012 12.8477 19.7012Z" stroke="white" stroke-width="1.5"/>
            <path d="M19.2188 19.7012H21.7698C21.9078 19.7012 22.0198 19.823 22.0198 19.9733V22.7496C22.0198 22.8999 21.9078 23.0217 21.7698 23.0217H19.2188C19.0807 23.0217 18.9688 22.8999 18.9688 22.7496V19.9733C18.9688 19.823 19.0807 19.7012 19.2188 19.7012Z" stroke="white" stroke-width="1.5"/>
            <path d="M12.8477 12.7664H15.3987C15.5367 12.7664 15.6487 12.8882 15.6487 13.0384V15.8148C15.6487 15.9651 15.5367 16.0869 15.3987 16.0869H12.8477C12.7096 16.0869 12.5977 15.9651 12.5977 15.8148V13.0384C12.5977 12.8882 12.7096 12.7664 12.8477 12.7664Z" stroke="white" stroke-width="1.5"/>
            <path d="M19.2188 12.7664H21.7698C21.9078 12.7664 22.0198 12.8882 22.0198 13.0384V15.8148C22.0198 15.9651 21.9078 16.0869 21.7698 16.0869H19.2188C19.0807 16.0869 18.9688 15.9651 18.9688 15.8148V13.0384C18.9688 12.8882 19.0807 12.7664 19.2188 12.7664Z" stroke="white" stroke-width="1.5"/>
            <path d="M17.541 1V3.48689" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M11 1V3.48689" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
};

export default Calendar;
