import { memo, useContext, useEffect, useState } from "react";
// import EditIcon from "assets/img/edit-icon.svg"

// import EditIcon from "assets/img/edit-icon.svg"
// import EditIcon from "assets/img/pencil-icon.svg";


import Dots from "assets/img/drag-icon.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { PdAdminContext } from "contexts/pdAdmin";
import PdfIcon from "assets/img/pdf-alt.svg";
import {
  AddRecourceModal,
  DeleteRecourceModal,
  UpdateRecourceModal,
} from "components/edit-resources/resources-modals";
import { downloadPdfFile } from "util/index";
import wwwIcon from "assets/img/www.png";

import { CustomSkeleton } from "components/common/skeleton/TableSkeleton";
import ResourceNameHeading from "components/professionalDevelopment/resources/ResourceNameHeading";
import ZipFIleIcon from "components/common/svgs/zip-file-icon";
import PDFFileIcon from "components/common/svgs/pdf-icon";
import PencilIconManagement from "components/common/svgs/pencil-icon-management";
import TrashIcon from "components/common/svgs/trash-icon";


const tabs = [
  {
    type: "BOOKS",
    title: "Books",
    prop: "books",
  },
  {
    type: "CURRENT_YEAR",
    title: "2024-25 Assessments",
    prop: "assessments_2021",
  },
  {
    type: "LAST_YEAR",
    title: "2023-24 Assessments",
    prop: "assessments_2016",
  },
  {
    type: "PD",
    title: "PD",
    prop: "pd",
  },
  {
    type: "OTHER",
    title: "Other",
    prop: "other",
  },
];

const MODAL_INIT_STATE = {
  type: "",
  data: null!,
};

const AssesssmentsTable = () => {
  const {
    resources,
    gradeSubjects,
    fetchAllResources,
    currentSubjectIndex,
    putResources,
    addNewResource,
    deletePdAdminResource,
    updatePdAdminResource,
  } = useContext(PdAdminContext);

  const [loading, setLoading] = useState(false);

  const [modal, setModal] = useState<any>(MODAL_INIT_STATE);
  const isAddModal = modal.type === "add";
  const isUpdateModal = modal.type === "update";
  const isDeleteModal = modal.type === "delete";

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const { type: selectedType, prop } = selectedTab;

  const selectedTabResources = resources[prop];

  useEffect(() => {
    if (gradeSubjects.length > 0) {
      fetchAllResources();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gradeSubjects, currentSubjectIndex]);

  const handleEnd = (result: any) => {
    if (
      !result?.destination ||
      !result?.source ||
      result?.source?.index === result?.destination?.index
    ) {
      return;
    }

    let resourcesCopy = { ...resources };
    let selectedResourcesCopy = [...resourcesCopy[prop]];
    const [reorderedItem] = selectedResourcesCopy.splice(
      result.source.index,
      1
    );
    selectedResourcesCopy.splice(result.destination.index, 0, reorderedItem);
    resourcesCopy[prop] = selectedResourcesCopy;
    putResources(resourcesCopy, selectedResourcesCopy, selectedType);
  };

  const onAddResource = async (data: any) => {
    let finalData = {
      category: selectedType,
      ...data,
      prop,
    };

    const currentSubGrade = gradeSubjects[currentSubjectIndex];
    if (currentSubGrade) {
      finalData = {
        ...finalData,
        ...(selectedType !== "OTHER" && { grade_id: currentSubGrade.grade_id }),
        ...(selectedType !== "OTHER" && {
          subject_id: currentSubGrade.subject_id,
        }),
      };
    }

    setLoading(true);
    const resourceAdded = await addNewResource(finalData);
    setLoading(false);
    resourceAdded && setModal(MODAL_INIT_STATE);
  };

  const onDeleteResource = async () => {
    const { data } = modal;
    setLoading(true);
    const isDeleted = await deletePdAdminResource(data.id, prop);
    setLoading(false);
    isDeleted && setModal(MODAL_INIT_STATE);
  };

  const onUpdateResource = async (data: any) => {
    const payload = { id: modal.data.id, prop, ...data };
    const isUpdated = await updatePdAdminResource(payload);
    isUpdated && setModal(MODAL_INIT_STATE);
  };

  const handleClickRecord = (item: any) => {
    if (item.is_file) {
      downloadPdfFile(item.file_url, item.filename, item.file_type);
    } else {
      let url = item.file_url;
      window.open(
        url.includes("http") ? item.file_url : "https://".concat(item.file_url),
        "_blank"
      );
    }
  };

  const filteredTabs = tabs.filter((item: any) => item?.type !== "LAST_YEAR");
  const selectedGrade = gradeSubjects[currentSubjectIndex];
  return (
    <>
      <div className="adminProfessional__container__adminResources">
        <div className="adminProfessional__container__adminResources__header">
          <div className="adminProfessional__container__adminResources__header__tabs">
            {filteredTabs.map((item) => {
              const { type, title, prop } = item;
              const isDisabled = prop === "assessments_2021" && selectedGrade?.grade_id === 9;
              return (
                <button
                  style={{opacity: isDisabled ? 0.4 : 1}}
                  className={`resources__tabs-btn ${
                    selectedType === type ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab(item)}
                  type="button"
                  disabled={isDisabled}
                >
                  {title}
                </button>
              );
            })}
          </div>
          <button
            onClick={() => setModal({ type: "add", data: null! })}
            className="adminProfessional__container__adminResources__header__addResourceBtn"
          >
            Add Resource
          </button>
        </div>
        <div className="adminProfessional__container__adminResources__booksContent">
          <table>
            <tbody>
              {resources.loading ? (
                <CustomSkeleton columns={3} count={10} height={56} />
              ) : (
                <DragDropContext onDragEnd={handleEnd}>
                  <Droppable droppableId={prop}>
                    {(provided) => (
                      <div
                        role="rowgroup"
                        aria-label="Resources"
                        className={`resources__files-list ${
                          selectedTabResources && selectedTabResources?.length
                            ? "padding-bottom"
                            : ""
                        }`}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {selectedTabResources &&
                        selectedTabResources?.length ? (
                          selectedTabResources.map(
                            (item: any, index: number) => (
                              <Draggable
                                key={index}
                                draggableId={index.toString()}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  // <div
                                  //   style={{ cursor: "grabbing" }}

                                  //   ref={provided.innerRef}

                                  //   key={index}
                                  //   className={
                                  //     snapshot.isDragging
                                  //       ? "resources__files"
                                  //       : "resources__files"
                                  //   }
                                  //   {...provided.dragHandleProps}
                                  //   {...provided.draggableProps}
                                  // >
                                  <tr //className="pdfActions"
                                    style={{ cursor: "grabbing" }}
                                    ref={provided.innerRef}
                                    key={index}
                                    className={`contentRow ${
                                      snapshot.isDragging
                                        ? "resources__files"
                                        : "resources__files"
                                    }`}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                  >
                                    <td
                                      tabIndex={0}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          handleClickRecord(item);
                                        }
                                      }}
                                      onClick={() => handleClickRecord(item)}
                                      className={`d-flex align-items-center pdf resources__files-name ${
                                        index % 2 === 0 ? "" : "light"
                                      }`}
                                    >
                                      <span>
                                        {item.is_file ? (
                                            item.file_type === "ZIP"
                                                ? <ZipFIleIcon />
                                                : <PDFFileIcon />
                                        ) : (
                                          <img
                                            src={wwwIcon}
                                            alt="url-icon........"
                                          />
                                          // <WebLinkIcon />
                                        )}
                                      </span>
                                      <ResourceNameHeading name={item.filename} />
                                    </td>
                                    <td className="pdfDesc">
                                      <p>{item.description}</p>
                                    </td>
                                    <td className="d-flex justify-content-end pdfActionsColumn">
                                      <button
                                        onClick={() =>
                                          setModal({
                                            type: "update",
                                            data: item,
                                          })
                                        }
                                        type="button"
                                        className="editBtn"
                                      >
                                        <PencilIconManagement fill="white" />
                                        
                                      </button>
                                      <button
                                        onClick={() =>
                                          setModal({
                                            type: "delete",
                                            data: item,
                                          })
                                        }
                                        type="button"
                                        className="delBtn"
                                      >
                                        <TrashIcon fill="white" />
                                        
                                      </button>
                                      {/* <button type="button" className="dots"> */}
                                      <img
                                        className="dots"
                                        src={Dots}
                                        alt="Dots"
                                      />
                                      {/* </button> */}
                                    </td>
                                  </tr>
                                  // </div>
                                )}
                              </Draggable>
                            )
                          )
                        ) : (
                          <div role="row" className="resources__empty">
                            <div
                              role="cell"
                              className="resources__empty-content"
                            >
                              <img
                                className="resources__empty-icon"
                                src={PdfIcon}
                                alt="PDF icon"
                              />
                              <p className="resources__empty-paragraph">
                                Click Add Resource to upload files
                              </p>
                            </div>
                          </div>
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {isAddModal && (
        <AddRecourceModal
          isShow={isAddModal}
          loading={loading}
          onAdd={(data: any) => onAddResource(data)}
          onCancel={() => setModal(MODAL_INIT_STATE)}
        />
      )}

      {isDeleteModal && (
        <DeleteRecourceModal
          loading={loading}
          isShow={modal.data}
          headerText={`Delete Resource`}
          bodyText={`Are you sure you want to delete this resource?`}
          onCancel={() => setModal(MODAL_INIT_STATE)}
          onDelete={onDeleteResource}
        />
      )}

      {isUpdateModal && (
        <UpdateRecourceModal
          resource={modal.data}
          isShow={isUpdateModal}
          loading={resources.updateResourceLoading}
          onUpdate={(data) => onUpdateResource(data)}
          onCancel={() => setModal(MODAL_INIT_STATE)}
        />
      )}
    </>
  );
};
export default memo(AssesssmentsTable);
