import Bulb from "components/common/svgs/bulb";

const ImportantDateSection = ({text, children}: {text:string, children?:any}) => {
    return (
        <tr className="PacingCalender__container__table__bodyrow note">
            <td colSpan={3} className="PacingCalender__container__table__bodyrow__infotext">                        
                {
                    text ?
                        <span>
                            <Bulb title={"Add Important Date"}  />
                            {text} 
                        </span>
                    : <Bulb title="Add Important Date"/>
                }
                {children}
            </td>
        </tr>
    )
}

export default ImportantDateSection;