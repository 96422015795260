import { useEffect, useRef, useState } from "react";
import DropdownIndicatorIcon from "../dropdownIndicator";

const STLDropdown = ({onChange}: any) => {
    const menuRef = useRef<any>(null!);
    const [open, setOpen] = useState(false);

    const onClickDropdown = () => {
        setOpen(prev => !prev);
    }

    const onClickOption = (period: string) => {
        setOpen(false);
        onChange(period);
    }

    useEffect(() => {
        const clickHandler = (event: any) => {
            if(menuRef.current?.contains(event.target)){
                return;
            }

            setOpen(false);
        }

        if(open){
            document.addEventListener('click', clickHandler, true)
        }

        return () => {
            document.removeEventListener('click', clickHandler, true)
        }
    },[open])

    return (
        <div ref={menuRef} className="stl">
            <div onClick={onClickDropdown} className="stl__dropdown-container">
                <span className="stl__dropdown-placeholder">Summative Test Log</span>
                <span>
                    <DropdownIndicatorIcon />
                </span>
            </div>
            {open && (
                <div className="stl__menu">
                    <ul className="stl__menu__list">
                        <li onClick={() => onClickOption('Current')} className="listItem">Current</li>
                        <li onClick={() => onClickOption('2022-23')} className="listItem">2022-23</li>
                    </ul>
                </div>
            )}
        </div>
    )
};

export default STLDropdown;
