import { useEffect } from "react";

export const useOutsideClick = (ref: any, handler: Function, useCapture: boolean = false) => {
    useEffect(() => {
        const clickHandler = (e: any) => {
            if(ref.current?.contains(e.target)){
                return;
            }

            handler();
        }
        
        document.addEventListener('click', clickHandler, useCapture);

        return () => {
            document.removeEventListener('click', clickHandler, useCapture);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
}