/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step3 } from "./Step3";
import { Step4 } from "./Step4";
import { Step5 } from "./Step5";
import ArrowIcon from "assets/img/remediate-arrow.png";
import { saveContactSupportTicket } from "services/suppot-ticket";
import { uploadSupportTicketFiles } from "services/upload-file";
import { Button } from "../common/buttonComponent";
import Alert from "react-bootstrap/Alert";
import CustomModal from "components/common/Modal";

interface ContactSupportProps {
  isShow: boolean;
  setShowSuccess: (showSuccess: boolean) => void;
  closeModal: () => void;
  onSubmit?: Function;
}

export enum TicketType {
  BUG = 'BUG',
  TEXT_BOOK_ERROR = 'TEXT_BOOK_ERROR',
  ASSESSMENT_ERROR = 'ASSESSMENT_ERROR',
  FEEDBACK_FEATURE = 'FEEDBACK_FEATURE',
  OTHER = 'OTHER'
}

const options = [
  { name: TicketType.BUG, label: "Report Bug or Connection/Data Issue" },
  { name: TicketType.TEXT_BOOK_ERROR, label: "Report Textbook Error" },
  { name: TicketType.ASSESSMENT_ERROR, label: "Report Assessment Error" },
  {
    name: TicketType.FEEDBACK_FEATURE,
    label: "Give feedback or suggest a feature",
  },
  { name: TicketType.OTHER, label: "Other" },
];

export const ContactSupportModal: React.FC<ContactSupportProps> = ({
  isShow,
  closeModal,
  setShowSuccess,
  onSubmit,
}) => {
  const { auth, activity } = useSelector((state: RootState) => state);
  const [step, setStep] = useState(1);
  const [data, setData] = useState<any>({});
  const [summary, setSummary] = useState("");
  const [description, setDescription] = useState("");
  const [textbookName, setTextbookName] = useState("");
  const [assessmentName, setAssessmentName] = useState("");
  const [pageNumber, setPageNumber] = useState<any>("");
  const [attachments, setAttachments] = useState<any[]>([]);
  const [preferredContact, setPreferredContact] = useState<any>("");
  const [urls, setUrls] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    const districtsList = activity.districtsList;
    const schoolsList = activity.schoolsList;

    if (auth.currentUser.role === "Classroom Admin") {
      setData({
        ...data,
        district_name: schoolsList[0]?.district_name,
        school_name: schoolsList[0]?.name,
        school_id: schoolsList[0]?.id,
        district_id: schoolsList[0]?.fk_district_id,
      });
    } else if (auth.currentUser.role === "School Admin") {
      setData({
        ...data,
        district_name: schoolsList[0]?.district_name,
        school_name: schoolsList[0].name,
        school_id: schoolsList[0]?.id,
        district_id: schoolsList[0]?.fk_district_id,
      });
    } else if (auth.currentUser.role === "District Admin") {
      setData({
        ...data,
        district_name: districtsList[0]?.district_name,
        district_id: districtsList[0]?.id,
      });
    }
  }, []);

  const goToNext = (nextStep: number) => {
    setStep(nextStep);
  };

  const handleOption = (name: string, value: any) => {
    setData({ ...data, [name]: value });
  };

  const clearData = () => {
    setStep(1);
    setData({});
    setSummary("");
    setDescription("");
    setTextbookName("");
    setPageNumber("");
    setAttachments([]);
    setPreferredContact("");
    setLoading(false);
    setShowSuccess(false);
    setUrls([]);
  };

  const handleCancel = () => {
    clearData();
    closeModal();
  };

  const backButton = () => {
    if (step === 3 && data.option !== TicketType.BUG) {
      setStep(1);
      clearData()
    }
    else {
      setStep(step - 1);
    }
    if (step === 2) {
      clearData()
    }
  };

  const handleOnChange = (name: string, value: any) => {
    name === "summary" && setSummary(value);
    name === "description" && setDescription(value);
    name === "preferredContact" && setPreferredContact(value);
    name === "textbookName" && setTextbookName(value);
    name === "pageNumber" && setPageNumber(value);
    name === 'assessmentName' && setAssessmentName(value);
  };

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      if (attachments.length < 3) {
        setAttachments([...attachments, acceptedFiles[0]]);
      }
    },
    [attachments]
  );

  const handleSave = async () => {
    if (step === 3) {
      handleOption("info", { summary, description, textbookName, pageNumber, assessmentName });
      goToNext(4);
    } else if (step === 2) {
      goToNext(3);
    } else if (step === 4) {
      if (attachments.length > 0) {
        setLoading(true);
        let response = await uploadSupportTicketFiles(attachments);
        if (response) {
          setUrls(response?.data);
        }
        handleOption("attachments", attachments);
        setLoading(false);
      }
      goToNext(5);
    } else if (step === 5) {
      setLoading(true);
      let payload: any = {
        attachments: urls || [],
        preferred_contact: preferredContact,
        message: description,
        issue_type: data.option,
        school: data.school_id || 0,
        school_name: data.school_name || '',
        classroom: data.classroom_id || 0,
        classroom_name: data.classroom_name || '',
      }
      if (auth.currentUser.role === "District Admin") {
        payload.district = data.district_id || 0;
        payload.district_name = data.district_name || '';

      }
      if (data.option === TicketType.BUG) {
        payload.summary = data.info.summary;
        payload.screen = data.screen || "";
      }
      else if (data.option === TicketType.TEXT_BOOK_ERROR) {
        payload.text_book_name = data.info.textbookName;
        payload.page_number = data.info.pageNumber;
      }
      else if (data.option === TicketType.ASSESSMENT_ERROR) {
        payload.text_book_name = data.info.assessmentName;
        payload.page_number = data.info.pageNumber;
      }
      else {
        payload.summary = data.info.summary;
      }
      let response = await saveContactSupportTicket(payload);
      if (response?.code === 200) {
        onSubmit && onSubmit(response?.data);
        handleCancel();
        setShowSuccess(true);
      } else {
        setShowError(true);
      }
      setLoading(false);
    }
  };

  let disablNext = false
  if (data.option === TicketType.BUG || data.option === TicketType.FEEDBACK_FEATURE || data.option === TicketType.OTHER) {
    disablNext = !summary || !description
  }
  else if (data.option === TicketType.TEXT_BOOK_ERROR) {
    disablNext = !textbookName || !pageNumber || !description
  }
  else if (data.option === TicketType.ASSESSMENT_ERROR) {
    disablNext = !assessmentName || !description
  }

  let keys = {
    a: false,
    s: false,
  };

  const handleKeyPress = (event: any) => {
    if (event.keyCode === 91 || event.keyCode === 93 || event.keyCode === 17 || event.keyCode === 224) {
      keys.a = true;
    }

    if (event.key === "Enter") {
      keys.s = true;
    }

    if (keys.a === true && keys.s === true) {
      document.getElementById('next-button')?.click();
    }

  }

  const handleKeyUp = () => {
    keys.a = false;
    keys.s = false;
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      document.removeEventListener('keyup', handleKeyUp);
    }
  }, []);

  return (
    <>
      <CustomModal
        centered
        show={isShow}
        onEscapeKeyDown={handleCancel}
        contentClassName="modal-content-contactSupport contact-support-modal"
        size="lg"
      >
        <div className="modal__header-support">
          <div className="modal__header modal__header-supportDetail">
            {step !== 1 && <div onClick={backButton} className="back-arrow"><img src={ArrowIcon} alt="arrow" /></div>}
            <div>
              <h6 className="modal__header-title">Contact Support</h6>
              {step !== 1 && (
                <p className="modal__header-title">
                  {options.find((item) => item.name === data.option)?.label}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="modal__body support-body">
          {showError && (
            <Alert
              dismissible
              onClose={() => setShowError(false)}
              variant="danger"
            >
              Something Went Wrong, Please Try Again
            </Alert>
          )}
          {step === 1 && (
            <Step1
              goToNext={goToNext}
              handleOption={handleOption}
              options={options}
            />
          )}
          {step === 2 && (
            <Step2 goToNext={goToNext} handleOption={handleOption} />
          )}
          {step === 3 && (
            <Step3
              summary={summary}
              description={description}
              handleOnChange={handleOnChange}
              data={data}
              textbookName={textbookName}
              pageNumber={pageNumber}
              assessmentName={assessmentName}
            />
          )}
          {step === 4 && <Step4 attachments={attachments} onDrop={onDrop} data={data} />}
          {step === 5 && (
            <Step5
              preferredContact={preferredContact}
              handleOnChange={handleOnChange}
              data={data}
              urls={urls}
            />
          )}
        </div>
        <div
          className="modal__footer support-footer"
        >
          <div className={`support__footer ${step === 1 || step === 4 || step === 5 ? 'fourth-footer' : ''}`}>
            {step === 2 && (
              <div className="support__footer-text">
                Looking for troubleshooting help? Go <b>Here</b>.
              </div>
            )}
            {step === 3 && (
              <div className="support__footer-text">
                You can upload screenshots on the next step.
              </div>
            )}
            <div className="d-flex ms-1">
              <Button
                buttonText="Cancel"
                onClick={handleCancel}
                className="btn cancel-btn btn-lg"
                disabled={false}
                type="button"
              />
              {step === 4 && (
                <Button
                  buttonText="Skip"
                  onClick={() => goToNext(5)}
                  className="btn gray-btn ms-3 btn-lg"
                  disabled={false}
                  type="button"
                />
              )}
              {step > 2 && (
                <Button
                  buttonText={step < 5 ? "Next" : "Submit"}
                  onClick={handleSave}
                  className={`btn success-btn btn-lg ms-3`}
                  disabled={disablNext || loading || (step === 4 && !attachments.length)}
                  id={`${step < 5 ? 'next-button' : 'save-button'}`}
                  loading={loading}
                  type="button"
                />
              )}
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};
