import { FC, useContext, useState, useEffect, useMemo } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";
import { QuestionnaireContext } from "../QuestionnaireContext";
import {
  addObjective,
  getAllObjectives,
  clearAlreadyExists,
} from "redux/actionCreators/objectives";
import Cookies from "js-cookie";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ReactComponent as WarningIcon } from "assets/img/cms-warning-icon.svg";
import { handlePress, handleKeyUp } from "util/index";
import { useLocation } from "react-router-dom";
import CustomModal from "components/common/Modal";
import { AppCookies } from "util/constants";

const ChangeObjectiveModal: FC<{}> = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const isFormative = location.pathname.includes("formatives");
  const { show, setShow, currentQuestion, onChangeObjective } =
    useContext(QuestionnaireContext);

  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const formik = useFormik({
    initialValues: {
      code: "",
      name: "",
      description: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      code: Yup.string().required("Objective Code is required"),
      name: Yup.string().required("Objective Name is required"),
    }),
    onSubmit: async (objective, { resetForm }) => {
      const grade_id = Cookies.get(AppCookies.grade_id) || "";
      const subject_id = Cookies.get(AppCookies.subject_id) || "";

      const data = {
        objective_number: objective.code,
        name: objective.name,
        description: objective.description,
        grade_id: +grade_id,
        subject_id: +subject_id,
      };

      setLoading(true);
      dispatch(
        addObjective(data, () => {
          setLoading(false);
          getObjectives();
          resetForm();
          setTabIndex(0);
        })
      );
    },
  });

  const { allObjectives, allObjectivesLoading, objectiveAlreadyExist } =
    useSelector((state: RootState) => state.objectives);

  const [objectiveId, setObjectiveId] = useState(-1);

  useEffect(() => {
    setObjectiveId(currentQuestion.objective_id);
  }, [currentQuestion]);

  const updateObjectives = async (id: number) => {
    onChangeObjective(id);
    await getObjectives();
  };

  const getObjectives = async () => {
    const grade_id = Cookies.get(AppCookies.grade_id);
    const subject_id = Cookies.get(AppCookies.subject_id);

    if (grade_id && subject_id) {
      await dispatch(
        getAllObjectives(parseInt(grade_id), parseInt(subject_id))
      );
    }
  };

  useEffect(() => {
    getObjectives();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const handlePress = (event: any) => {
      if (event.keyCode === 27) {
        setShow(null!);
      }
    };

    show && document.addEventListener("keydown", handlePress, false);
    show && document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handlePress, false);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [show, setShow]);

  useEffect(() => {
    document.addEventListener("keydown", handlePress);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handlePress);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const showWarning = useMemo(() => {
    const objective = allObjectives?.find(
      (objective: any) => objective.id === objectiveId
    );
    const has_formative = objective?.has_formative || false;

    return (
      has_formative && !tabIndex && objectiveId !== currentQuestion.objective_id
    );
  }, [allObjectives, objectiveId, tabIndex, currentQuestion]);

  return (
    <>
      <CustomModal centered show={show}>
        <div>
          <div className="modal__header">
            <h6 className="modal__header-title">Change Objective</h6>
          </div>
          <div className="modal__body">
            <div className="modal__form object-tabs">
              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
              >
                <TabList>
                  <Tab>
                    <span className="react-tabs__button">Search</span>
                  </Tab>
                  <Tab>
                    <span className="react-tabs__button">Create New</span>
                  </Tab>
                  <input
                    type="radio"
                    id="objective-radio"
                    className="react-tabs__radio"
                    checked={tabIndex === 1 ? true : false}
                  />
                  <span className="react-tabs__slider"></span>
                </TabList>

                <TabPanel>
                  <div className="modal__form-row pt-0">
                    <select
                      disabled={allObjectivesLoading}
                      value={objectiveId}
                      onChange={(event) =>
                        setObjectiveId(parseInt(event.target.value))
                      }
                      className="form-select cms-assessment-select"
                    >
                      <option value="-1" disabled>
                        Select Objective
                      </option>
                      {allObjectives?.map((item: any, index: number) => (
                        <option key={index} value={item.id}>
                          {item.objective}
                        </option>
                      ))}
                    </select>
                  </div>
                </TabPanel>

                <TabPanel>
                  <div className="modal__form-row">
                    <label>Objective Code</label>
                    <input
                      autoFocus={true}
                      name="code"
                      value={formik.values.code}
                      onChange={formik.handleChange}
                    />

                    <span
                      className={`error-hint ${
                        (formik.errors.code && formik.touched.code) ||
                        (formik.errors.name && formik.touched.name) ||
                        objectiveAlreadyExist
                          ? ""
                          : "d-none"
                      }`}
                    >
                      <span className="red_dot me-2"></span>
                      <span>Required Field</span>
                    </span>

                    {!objectiveAlreadyExist &&
                    formik.errors.code &&
                    formik.touched.code ? (
                      <span className="cms-overlay">
                        <span className="red-circle"></span>
                        <span>{formik.errors.code}</span>
                        <span
                          className="cms-overlay-close"
                          onClick={() => {
                            formik.setTouched({ code: false });
                          }}
                        >
                          &times;
                        </span>
                      </span>
                    ) : (
                      <span
                        className={`cms-overlay ${
                          !objectiveAlreadyExist ? "d-none" : ""
                        }`}
                      >
                        <span className="red-circle"></span>
                        <span>Objective Code Already Exists</span>
                        <span
                          className="cms-overlay-close"
                          onClick={() => dispatch(clearAlreadyExists())}
                        >
                          &times;
                        </span>
                      </span>
                    )}
                  </div>

                  <div className="modal__form-row mt-2">
                    <label>Objective Name</label>
                    <input
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                    {/* {formik.errors.name && formik.touched.name && <div style={{ color: 'red' }}>{formik.errors.name}</div>} */}

                    {formik.errors.name && formik.touched.name && (
                      <span className="cms-overlay">
                        <span className="red-circle"></span>
                        <span>{formik.errors.name}</span>
                        <span
                          className="cms-overlay-close"
                          onClick={() => {
                            formik.setTouched({ name: false });
                          }}
                        >
                          &times;
                        </span>
                      </span>
                    )}
                  </div>

                  <div className="modal__form-row mt-2">
                    <label>Description</label>
                    <textarea
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      placeholder=""
                    />
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
          <div className="modal__footer">
            <button
              disabled={loading}
              onClick={() => setShow(false)}
              className="btn cancel-btn light"
            >
              Cancel
            </button>
            {!!tabIndex ? (
              <button
                id="save-button"
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
                className={`btn success-btn btn-lg ${
                  formik.values.code.length === 0 ||
                  formik.values.name.length === 0
                    ? "btn-disabled"
                    : ""
                }`}
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Done"
                )}
              </button>
            ) : (
              <button
                onClick={() => updateObjectives(objectiveId)}
                className="btn success-btn btn-lg"
                disabled={objectiveId === currentQuestion.objective_id}
              >
                Change
              </button>
            )}
          </div>
          {showWarning && isFormative && (
            <div className="modal-content archice-modal">
              <div className="modal-alert">
                <WarningIcon />
              </div>
              <div className="modal-desc">
                Changing the objective to another active objective will cause
                the target objective’s formative to be archived. The current
                objective for this formative will no longer have a formative
                until assigned.
              </div>
            </div>
          )}
        </div>
      </CustomModal>
    </>
  );
};

export default ChangeObjectiveModal;
