import ScrollingText from "components/common/ScrollingText";
// import { useEffect, useRef, useState } from "react";

interface IProps {
    setClassroomFilters: () => void;
    animatedClassName: string;
    classroom_name: string;
}

const ClassroomName = ({setClassroomFilters, classroom_name}:IProps) => {

    return (
        <>
            <ScrollingText 
                isClassName={true}
                role="button" 
                tabIndex={0} 
                onKeyDown={(e:any)=>{
                    if(e.key==="Enter"){
                        setClassroomFilters()
                    }
                }}   
                animateFirstTime={true}
                onClick={() => setClassroomFilters()}
                tag="p">
                {classroom_name}
            </ScrollingText>
        </>
    )
};

export default ClassroomName;
