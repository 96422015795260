import { useRef, useEffect } from 'react';
import { SQUARE_ROOT_CHAR, convertPreviousSqrt } from 'util/convertSqrt';
import { ElementTohtml, htmlToElement } from 'util/htmlToElement';

export default function HtmlParser(props: { html: string; id?:string }) {
    const spanRef = useRef<HTMLSpanElement>(null);
    const { html, id } = props

    useEffect(() => {
        if (spanRef.current) {
            let updatedHtml = html;
            const isSqrtExists = updatedHtml?.includes(SQUARE_ROOT_CHAR);
            if(isSqrtExists){
                const htmlElement = htmlToElement(html);
                convertPreviousSqrt(htmlElement);
                updatedHtml = ElementTohtml(htmlElement);
            }
            
            spanRef.current.innerHTML = updatedHtml;
        }
    }, [spanRef,html]);
    
    return <span id={id} ref={spanRef} />
}