import ArrowIcon from "assets/img/accordian-box-icon.png";
import { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
// import LeftArrowIcon from "assets/img/left-arrow-polygon.svg"
// import RightArraowIcon from "assets/img/right-arrow-polygon.svg"
import LeftArraowIcon from "assets/img/back_arrow_yellow.svg";
import RightArraowIcon from "assets/img/forward_arrow_yellow.svg";
import { useMemo } from "react"
import { useSelector } from "react-redux";
import { allMasteryVideosSelector } from "redux/reducers/mastery-videos";
import DropDownArrow from "assets/img/drop-down-select.svg";

interface Props {
  videoId?: number;
  setVideoId: Function;
}

const MesteryHeader: FC<Props> = ({setVideoId}) => {

  const navigate = useNavigate();
  const masteryVideos = useSelector(allMasteryVideosSelector);
  const [selectedIndex, setSelectedIndex] = useState<number>(null!);

  const [searchParams] = useSearchParams();

  const { videoOptions } = useMemo(() => {
    
    if (masteryVideos) {
      return {
        videoOptions: masteryVideos?.map((video: any, index: number) => ({
          value: { id: video.id, index: index },
          label: video.name
        })),
      }
    }
    return {
      videoOptions: []
    }
  }, [masteryVideos])

  useEffect(() => {
    if(masteryVideos.length > 0){
      const videoId = searchParams.get("videoId") || '0';
      
      const selectedVideoIndex = masteryVideos.findIndex((item: any) => item.id === parseInt(videoId));
      if(selectedVideoIndex !== -1){
        onChange(selectedVideoIndex);
      }else{
        onChange(0);
      }
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  },[masteryVideos])

  const onClickBack = () => {
    
    if (window.history.state && window.history.state.idx > 1) {
      navigate(-1);
    } else {
      navigate("/classroom");
    }
  };

  const onChange = (index: number) => {
    const selectedIndex = index;
    const video = videoOptions[selectedIndex]
    setSelectedIndex(selectedIndex);
    setVideoId(video.value.id);
  }

  const onClickNext = () => {
    setSelectedIndex(selectedIndex+1);
    onChange(selectedIndex+1);
  }

  const onClickPrev = () => {
    setSelectedIndex(selectedIndex-1);
    onChange(selectedIndex-1);
  }

  return (
    <div className={`report__header masteryHeader`}>
      <div className="d-flex align-items-center">
        <span tabIndex={0} role="button"   
        onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              onClickBack();
            }
          }} className="report__header-link" onClick={onClickBack} >
          <img src={ArrowIcon} alt="" className="report__header-icon" />
        </span>
        <div>
          <h3 className="report__header-title">Mastery Video Series</h3>
        </div>
      </div>
      <div className="table-dropdown-container">
        <button onClick={onClickPrev} type="button" className="text-btn" disabled={selectedIndex <= 0}>
          <img src={LeftArraowIcon} alt="LeftArrow"  />
        </button>
        {/* <CustomTableSelectCommon
          isOpen={show}
          toggleSelect={() => setShow(!show)}
          options={videoOptions}
          onChange={onChange}
          name="mastery-videos"
          placeholder="Select Option"
        /> */}
        <div className="table-dropdown dropdown">
          <select
            value={selectedIndex}
            onChange={(e:any) => onChange(e.target.value)}
          >
            {videoOptions.map((item:any, index:number) => {
              const {label} = item;
              return (
                <option key={index} value={index}>{label}</option>
              )
            })}
          </select>
          <img style={{opacity: 0.4}} className="dropDownArrow" src={DropDownArrow} alt="Down Arrow" />
        </div>

        <button onClick={onClickNext} type="button" className="text-btn" disabled={selectedIndex >= videoOptions.length-1}>
          <img src={RightArraowIcon} alt="RightArraow" />
        </button>
      </div>
    </div>
  );
};
export default MesteryHeader;
