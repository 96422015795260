/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import QuestionMarkIcon from "assets/img/question-mark-tooltip-icon.svg";
import CustomModal from "components/common/Modal";

interface Props {
    isShow: boolean;
    closeModal: () => void;
    clearHandler: Function
}

const ClearModal: React.FC<Props> = ({
    isShow,
    closeModal,
    clearHandler
}) => {
    return (
        <CustomModal centered show={isShow} onHide={closeModal} onEscapeKeyDown={closeModal}>

            <div className="modal__header">
                <img src={QuestionMarkIcon} className="modal__header-icon" alt="" />
                <h6 className="modal__header-title">{'Clear all Student Answers'}</h6>
            </div>
            <div className="modal__body">
                <p className="modal__body-text">
                    Are you sure you want to clear all student answers for this attempt?
                </p>
            </div>
            <div className="modal__footer">
                <button
                    className="btn cancel-btn"
                    onClick={closeModal}
                >
                    Cancel
                </button>

                <button
                    className="btn success-btn btn-lg"
                    onClick={() => clearHandler()}
                >
                    Clear
                </button>
            </div>

        </CustomModal>
    );
};

export default ClearModal;