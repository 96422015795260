import { useEffect } from "react";
import { Modal } from "react-bootstrap";

const CustomModal = ({children, ...props}: any) => {

    
    useEffect(() => {
        const htmlTag = document.getElementsByTagName("html");
        const chatIconContainer = document.querySelector(".chatIcon-container");
        if(props.show){
            if(htmlTag.length){
                htmlTag[0].style.overflow = "hidden";
            }
            if(chatIconContainer){
                chatIconContainer.classList.add("open-modal-space-remove")
            }
        }else{
            if(htmlTag.length){
                htmlTag[0].style.overflow = "";
            }
            if(chatIconContainer){
                chatIconContainer.classList.remove("open-modal-space-remove")
            }
        }

        return () => {
            const htmlTag = document.getElementsByTagName("html");
            const chatIconContainer = document.querySelector(".chatIcon-container");
            if(htmlTag.length){
                htmlTag[0].style.overflow = "";
            }
            if(chatIconContainer){
                chatIconContainer.classList.remove("open-modal-space-remove")
            }
        }
    },[props.show]);
    return (
        <Modal
            {...props}
        >
            {children}
        </Modal>
    )
};

export default CustomModal;
