import { Modal } from "react-bootstrap";
import { Button } from "components/common/buttonComponent";
import { useEffect, useState } from "react";

const ChangeIconModal = ({selectedIcon, setSelectedIcon, onCancel, onSubmit, icons}: any) => {
    const [selectedIndex, setSelectedIndex] = useState<any>(null!);
    
    const onClickIcon = (index: number) => {
        setSelectedIndex(index);
    }

    const onSave = () => {
        setSelectedIcon(icons[selectedIndex]);
        onSubmit();
    }

    useEffect(()=>{
        const index = icons?.findIndex((icon: any)=> icon?.id===selectedIcon?.id);
        setSelectedIndex(index);
    },[selectedIcon, icons])


    return (
    
        <Modal 
            centered 
            show 
            onEscapeKeyDown={onCancel}
            contentClassName="modal-content-changeIcon"
        >
            <div className="changeIconModal">
                <div className="modal__header">
                    <h6 className="modal__header-title">Change Icon</h6>
                    <p>Select an Icon Below</p>
                </div>
                <div className="modal__body">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                        {icons.map((icon: any, index: number) => {
                            return (
                                <div role="button" tabIndex={0} onKeyDown={ (e:any) => { 
                                    if(e.key === 'Enter') {
                                        e.stopPropagation();
                                        onClickIcon(index)
                                    }
                                    }}
                                  onClick={() => onClickIcon(index)} className={selectedIndex === index ? 'cursor-pointer toolImgs selected' : 'cursor-pointer toolImgs'}>
                                    <img src={icon.url} alt="Tool" />
                                </div>
                            )
                        })}
                        
                    </div>
                </div>
                <div className="modal__footer">
                <Button
                    buttonText="Cancel"
                    onClick={onCancel}
                    className="btn cancel-btn"
                    // disabled={loading}
                    type="button"
                />
                <Button
                    buttonText="Select"
                    onClick={onSave}
                    className={`btn success-btn btn-lg ${(false) && 'btn-disabled'}`}
                    // loading={loading}
                    type="button"
                    id="save-button"
                    disabled={selectedIcon && icons.length>selectedIndex && selectedIcon.id===icons[selectedIndex]?.id}
                />
                </div>
            </div>
        </Modal>
    );
};

export default ChangeIconModal;