/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck

import React, { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import cookie from "js-cookie";
import {
  clearDataOnLogout,
  logout,
  logoutService,
} from "redux/actionCreators/auth";
import {
  getTeachersBySchools,
  setTeacherId as setTeacherIdState,
  setSchoolId,
  setGradeId as setGradeIdState,
  setDistrictId as setDistrictIdState,
  setFilters,
  setSubjectId,
  setActivityClassroomId,
} from "redux/actionCreators/activity";
import {
  fetchClassrooms,
  setClassroomSchoolId,
  setClassroomId,
  setGrade as setClassroomGrade,
  setSubject as setClassroomSubject,
  setDistrictId,
  fetchSchoolClassrooms,
} from "redux/actionCreators/classroom";
import {
  setDistrictMId,
  setSchoolMId,
  setClassroomMId,
} from "redux/actionCreators/management";
import { fetchUserMenu, updateProfileColor } from "redux/actionCreators/auth";
import HeaderTopbar from "./headerTopbar";
import { useQuery } from "hooks/useQuery";
import { ClassroomTooltipContext } from "contexts/classroomTooltip";
import { isSuperAdmin } from "util/index";
import LogoutModal from "components/classroom/modals/logoutModal";
import ClassroomTooltip from "components/common/classroomTooltip";
import { updateUserData } from "services/common";
import { TooltipType } from "interfaces/common";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import ActionType from "redux/actionTypes";
import AssessmentStats from "./assessmentStats";
import ActivityBar from "./activityBar";
import { ActivityResponse } from "./activityBar";
import { AppCookies } from "util/constants";

interface RootState {
  auth: any;
  activity: any;
  classroom: any;
  management: any;
}


const Header: React.FC<{}> = () => {
  const { tooltip, setTooltip } = useContext(ClassroomTooltipContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const app = useSelector((state: RootState) => state);
  const { pathname } = useLocation();
  const [isLogout, setLogout] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const userRole = cookie.get(AppCookies.role);
  const [hasLastAssesment, setHasLastAssement] = useState(false);
  const districts = app.activity.districtsList;
  const classrooms = app.classroom.classrooms;
  const isManagementPage = pathname.includes("/management");
  const isActivityActive = pathname.includes("/activity");
  const classroom = app.classroom;
  const isClassroomPage = pathname.includes("classroom");
  const classroomId = isManagementPage
    ? app.management?.classroom_m_id || classrooms[0]?.id
    : classroom.classroomId;


  const [activityData, setActivityData] = useState<ActivityResponse[]>([]);
  const selectedClassroomData = activityData.find(
    (item) => item.classroom_id === classroomId
  );


  const isActivityScreen = pathname.indexOf("activity") > -1;
  const isClassroomScreen =
    (pathname.indexOf("classroom") > -1 ||
      pathname.indexOf("pacing-calendar") > -1 ||
      pathname.indexOf("discussions") > -1 ||
      pathname.indexOf("professional-development") > -1 ||
      pathname.indexOf("mastery-video") > -1 ||
      pathname.indexOf("report-cards") > -1) &&
    pathname.indexOf("super-admin") === -1;

  const isManagementScreen = pathname.indexOf("management") > -1;

  const query = useQuery();
  const print = query.get("print");

  useEffect(() => {
    const userRole = cookie.get(AppCookies.role);
    if(userRole === 'Classroom Admin' && app.activity.schoolsList?.length && app.activity.schoolsList[0]?.id){
      dispatch(setSchoolMId(app.activity.schoolsList[0]?.id))
    }
  },[]);

  useEffect(() => {
    isActivityScreen && setActivityScreenData();
    isManagementScreen && setManagementData();
    setUserMenuData();
  },[pathname])

  useEffect(() => {
    isClassroomScreen && app.activity.districtsList.length > 0 && setClassroomScreenData();
  }, [pathname, app.activity.districtsList]);

  useEffect(() => {
    if (isLogout) {
      navigate({ pathname: "/" }, { replace: true });
    }
  }, [isLogout]);

  const applyFilters = () => {
    dispatch(setFilters(true));
  };

  const setUserMenuData = async () => {
    await dispatch(fetchUserMenu());
  };

  const setManagementData = async () => {
    const district_id = Number(sessionStorage.getItem("district_m_id"));
    const school_id = Number(sessionStorage.getItem("school_m_id"));
    const classroom_id = Number(sessionStorage.getItem("classroom_m_id"));

    if (district_id > 0) {
      dispatch(setDistrictMId(district_id));
    }
    if (school_id > 0) {
      dispatch(setSchoolMId(school_id));
    }
    if (classroom_id > 0) {
      school_id > 0 && dispatch(fetchSchoolClassrooms(school_id));
      dispatch(setClassroomMId(classroom_id));
    }
  };

  const setActivityScreenData = async () => {
    const district_id = Number(sessionStorage.getItem("district_id"));
    const school_id = Number(sessionStorage.getItem("school_id"));
    const teacher_id = Number(sessionStorage.getItem("teacher_id"));
    const grade_id = Number(sessionStorage.getItem("grade_id"));
    const subject_id = Number(sessionStorage.getItem("subject_id"));
    const classroom_id = Number(sessionStorage.getItem("classroom_id"));
    if (district_id > 0) {
      dispatch(setDistrictIdState(district_id));
      userRole !== "Classroom Admin" &&
        district_id &&
        !school_id &&
        !classroom_id &&
        !teacher_id &&
        applyFilters();
    } else {
      applyFilters();
    }
    if (school_id > 0) {
      dispatch(setSchoolId(school_id));
      if (userRole === "Classroom Admin") {
        await dispatch(fetchSchoolClassrooms(school_id));
      } else {
        await dispatch(
          getTeachersBySchools({
            params: {
              school_id: school_id,
            },
          })
        );
        district_id &&
          school_id &&
          !classroom_id &&
          !teacher_id &&
          !grade_id &&
          !subject_id &&
          applyFilters();
      }
    }
    if (teacher_id > 0) {
      dispatch(setTeacherIdState(teacher_id));
      userRole !== "Classroom Admin" &&
        district_id &&
        school_id &&
        teacher_id &&
        !grade_id &&
        !subject_id &&
        applyFilters();
    }
    if (grade_id > 0) {
      dispatch(setGradeIdState(grade_id));
      dispatch(setSubjectId(subject_id));
      userRole !== "Classroom Admin" &&
        district_id &&
        school_id &&
        grade_id &&
        subject_id &&
        applyFilters();
    }
    if (classroom_id > 0) {
      dispatch(setGradeIdState(grade_id));
      dispatch(setSubjectId(subject_id));
      dispatch(setActivityClassroomId(classroom_id));
      applyFilters();
    }
  };

  const setClassroomScreenData = async () => {
    const district_id = Number(sessionStorage.getItem("c_district_id"));
    const school_id = Number(sessionStorage.getItem("c_school_id"));
    const c_classroom_id = Number(sessionStorage.getItem("c_classroom_id"));
    const grade_id = Number(sessionStorage.getItem("c_grade_id"));
    const subject_id = Number(sessionStorage.getItem("c_subject_id"));
    if (district_id > 0) {
      dispatch(setDistrictId(district_id));
      district_id && !school_id && !c_classroom_id && applyFilters();
    }
    if (school_id > 0) {
      dispatch(setClassroomSchoolId(school_id));
      await dispatch(fetchSchoolClassrooms(school_id));
      district_id && school_id && !c_classroom_id && applyFilters();
    }

    if (c_classroom_id && c_classroom_id) {
      dispatch(setClassroomGrade(grade_id));
      dispatch(setClassroomSubject(subject_id));
      dispatch(setClassroomId(c_classroom_id));
      district_id && school_id && c_classroom_id && applyFilters();
    }
  };

  const userLogout = async (event: any) => {
    event.preventDefault();

    const isSupAdmin = isSuperAdmin();
    const { chat_exists, logout: isLogout } = await logoutService();

    if (isSupAdmin && !isLogout && chat_exists) {
      setShowLogoutModal(true);
      return;
    }

    dispatch(clearDataOnLogout());
    setLogout(true);
  };

  const updateProfile = async (color: any) => {
    await dispatch(updateProfileColor(color.id));
  };

  const onClickHandler = useCallback(() => {
    // setTooltip((prev: any) => {
    //   return prev + 1;
    // });

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const allAreNoStudents =
    app.classroom.classrooms?.filter((item: any) => item.students_count === 0)
      ?.length === app.classroom.classrooms?.length;

  const hideActivity =
    userRole === "Classroom Admin" &&
    allAreNoStudents &&
    app.classroom.classrooms.length > 0;

  useEffect(() => {
    const onCompleteTooltip = async () => {
      if (pathname === "/activity" && tooltip === 5) {
        setTooltip(null!);
        const updated = await updateUserData(TooltipType.ACTIVITY);
        if (updated) {
          dispatch({
            type: ActionType.USER_PROFILE_DATA,
            payload: { ...app.auth?.colorProfile, activity_tooltip_seen: true },
          });
        }
        window.scrollTo(0, 0);
        document.body.removeEventListener("click", onClickHandler, true);
      }
    };

    onCompleteTooltip();
  }, [tooltip]);

  useEffect(() => {
    const token = app.auth?.currentUser?.token;
    const decodedToken = token ? jwtDecode(token) : null;
    const isForgotPassword = decodedToken?.forgot_password;
    if (
      !app.auth.colorProfile ||
      (app.auth.colorProfile && app.auth.colorProfile?.activity_tooltip_seen) ||
      !(pathname === "/activity") ||
      hideActivity ||
      isForgotPassword
    ) {
      return;
    }

    setTooltip(null) //setTooltip(1);
    document.body.addEventListener("click", onClickHandler, true);

    return () => {
      document.body.removeEventListener("click", onClickHandler, true);
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [app.auth.colorProfile]);

  if (print) {
    return null;
  }

  const isBlur =
    (pathname === "/classroom" && (tooltip === 4 || tooltip === 5)) ||
    (pathname === "/activity" && tooltip === 4);
  const isTransparent =
    (pathname === "/classroom" && (tooltip === 1 || tooltip === 2)) ||
    (pathname === "/pacing-calendar" && (tooltip === 6 || tooltip === 7));
  // const isActivityTooltip = tooltip === 1 && isActivityScreen;
  // const isClassroomTooltip = tooltip === 2 && isActivityScreen;
  // const isManagementTooltip = tooltip === 3 && isActivityScreen;

  // const isActivityBlur = pathname === '/activity' && (tooltip === 1 || tooltip === 2 || tooltip === 3);

  return (
    <>
      <header className={`header`}>
        <div className="header-container">
        <HeaderTopbar lastAssementClass = { selectedClassroomData?.last_test_time && (isManagementPage || isClassroomPage) ? "classroom-admin-radius":"" } />
          <ActivityBar
            activityData = {activityData}
            setActivityData = {setActivityData}
            selectedClassroomData = {selectedClassroomData}
            isActivityScreen={isActivityScreen}
            isClassroomScreen={isClassroomScreen}
            isManagementScreen={isManagementScreen}
          />
          {isBlur && <div className="toolTip-bg"></div>}

          {isTransparent && (
            <div className="toolTip-bg toolTip-bg-transparent"></div>
          )}
        </div>
      </header>

      {showLogoutModal && <LogoutModal setModalShow={setShowLogoutModal} />}
    </>
  );
};

export default Header;