import ScrollingText from "components/common/ScrollingText";
interface IProps {
    assessment_name: string;
}
const AssessmentName = ({assessment_name}: IProps) => {

    return (
        <ScrollingText
          elementStyles={{
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "22px",
          color: "white",
        }}
        
        containerStyles={{ width:"265px" }}
        tag="p"
      >
        {assessment_name}
      </ScrollingText>
        
        
    )
};

export default AssessmentName;
