import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "redux/reducers/combine";
import Skeleton from "react-loading-skeleton";
import SuccessModal from "components/common/successModal";

const AssessmentMonitorCore = () => {
    const navigate = useNavigate()
    const { fetchAssessmentMonitorData, setAssessmentMonitor, assessmentMonitorLoading, assessmentMonitor } = useContext(ProfessionalDevelopmentContext);
    const { online_students, open_assessments } = assessmentMonitor;

    const classroom = useSelector((state: RootState) => state.classroom);
    const [showModal,setShowModal] = useState(false);

    const getAssessmentMonitorData = async () => {
        await fetchAssessmentMonitorData();
    }

    useEffect(() => {
        if(classroom.classroomId > 0){
            getAssessmentMonitorData();
        }else{
            setAssessmentMonitor({
                open_assessments: 0,
                online_students: 0,
                assessments: []
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[classroom.classroomId]);

    const classroomId = classroom.classroomId;
    const noData = !online_students && !open_assessments;
    const isDisabled = noData || !classroomId;
    return (
        <>
            <div className="monitor">
                <div className="activity-monitor">
                    <p className="monitor-desc">Monitor all active test sessions here.</p>
                    <div className="activity-monitor__assessmentsTags">
                    {!classroomId || assessmentMonitorLoading ? (
                        <Skeleton enableAnimation={classroomId > 0 && assessmentMonitorLoading} baseColor="#00000033" highlightColor="#737373" borderRadius={12} width={128} height={22} />
                    ):(
                        <p className="monitor-assessment-student assessments-label">
                            {open_assessments || 0} Assessment{open_assessments === 1 ? '' : 's'}
                        </p>
                    )}
                    {!classroomId || assessmentMonitorLoading ? (
                        <Skeleton enableAnimation={classroomId > 0 && assessmentMonitorLoading} baseColor="#00000033" highlightColor="#737373" borderRadius={12} width={128} height={22} />
                    ):(
                        <p className="monitor-assessment-student student-online-label">
                            {online_students || 0} Student{online_students === 1 ? '' : 's'} Online
                        </p>
                    )}
                    
                    </div>
                </div>
                <div>
                    <button 
                        type="button" 
                        tabIndex={0}
                        disabled={!classroomId}
                        className="button-green"
                        style={{opacity: isDisabled ? 0.5 : 1}}
                        onClick={() => {
                            if(noData){
                                setShowModal(true)
                            }else{
                                navigate(`/classroom/monitor/${classroomId}`, {state: {id: -1}})
                            }
                        }}
                    >
                        Monitor
                    </button>
                </div>
            </div>
            {showModal && (
                <SuccessModal
                    isShow
                    headerText="Assessment Monitor"
                    bodyText="Please enable Available to Students on an assessment to monitor."
                    closeModal={() => setShowModal(false)}
                />
            )}
        </>
    )
};

export default AssessmentMonitorCore;
