import { Button } from "components/common/buttonComponent";
import { SupportTicketSuperAdminContext } from "contexts/supportTicketSuperAdmin";
import { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import AdminSelect from "./adminSelect";

interface SuperAdminAddProps {
  onCancel: () => void;
  onAdd: (data: any) => void;
  loading: boolean;
}
  
const SubmitModal: React.FC<SuperAdminAddProps> = ({
    loading,
    onCancel,
    onAdd,
}) => {
    const {admins, fetchSupportTicketAdmins} = useContext(SupportTicketSuperAdminContext);
    const [selectedAdmin, setSelectedAdmin] = useState<number>(null!);
    
    useEffect(() => {
      onSearchAdmin('', () => {});
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    const onSubmit = () => {
      if (selectedAdmin) {
        onAdd(selectedAdmin);
        onCancel();
      }
    };
  
    const handleCancel = () => {
        onCancel();
    };
  
    const onSearchAdmin = async (input: string, callBack: Function) => {
        const admins = await fetchSupportTicketAdmins(input);
        callBack(admins);
    }

    const onChangeAdmin = (value: any) => {
        console.log(value);
        setSelectedAdmin(value);
    }
    

    return (
      <Modal centered show={true} onEscapeKeyDown={handleCancel} className="submit-behalf-admin">
        <div>
          <div className="modal__header">
            <h6 className="modal__header-title">Submit on Behalf of Admin</h6>
          </div>
          <div className="modal__body">
            <div className="modal__form">
              <div className="modal__form-row mb-2">
                <div className="submit-behalf-dropdown" style={{width: '100%'}}>
                    <AdminSelect 
                        options={admins}
                        onSearch={onSearchAdmin}
                        onChange={onChangeAdmin}
                    />
                </div>
                {/* <select className="form-select welcome-edit-select"
                  onChange={(e: any) => handleOnChangeDropdown(e) }
                  disabled={loading}
                  value={selectedAdminIndex}
                >
                  <option disabled selected> Choose Admin</option>
                  {admins.map(({label}:any, i) => 
                    <option value={i}>
                        <div style={{ display: "flex" }}>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div>{selectedAdmin.first_name}</div>
                            </div>
                            <div style={{ marginLeft: "10px", color: "#ccc", fontSize: 12, marginTop: 5 }}>
                                {selectedAdmin.email}
                            </div>
                        </div>
                    </option>)
                  }
                </select> */}
              </div>
            </div>
          </div>
          <div className="modal__footer">
            <Button
              buttonText="Cancel"
              onClick={handleCancel}
              className="btn cancel-btn"
              disabled={loading}
              type="button"
            />
            <Button
              buttonText="Add"
              onClick={onSubmit}
              className={`btn success-btn btn-lg`}
              loading={loading}
              disabled={!selectedAdmin}
              type="button"
              id='save-button'
            />
          </div>
        </div>
      </Modal>
    );
};

export default SubmitModal;