import classNames from "util/classNames";
import checkIcon from "assets/img/checked-green-1x.svg";
type Props = {
  performanceEnabled?: boolean;
  performance: any;
  subject?: number;
  isPrev?: boolean;
  showPerformanceLevelForScience?: boolean
};

const PerformanceSets = ({
  performanceEnabled = false,
  performance = {},
  subject = 2,
  isPrev = false,
  showPerformanceLevelForScience
}: Props) => {
  let performanceLoaded = performance?.level;

  if (subject === 1) {
    performanceLoaded = performance?.level_math;
  } else if (subject === 3) {
    performanceLoaded = performance?.level_science;
  }

  let subjectSet = "ELA";

  if (subject === 1) {
    subjectSet = "Math";
  } else if (subject === 3) {
    subjectSet = "Science";
  }

  if (isPrev) {
    performanceLoaded = performance.level_previous;
    subjectSet = "";
  }

  return (
    <div className="reportCards__student-performance-container">
      <h6
        className={classNames(
          !performanceEnabled
            ? "reportCards__student-performance-margin30"
            : "",
          "reportCards__student-performance-title"
        )}
      >
        {subjectSet} Performance Level (Percentage Score Range)
      </h6>

      <div className="report-progress">
        {performance &&
          performanceEnabled &&
          performanceLoaded?.map((item: any, i: number) => {
            
            return (
              <div
                key={i}
                className={classNames(
                  "reportCards__student-performance-score",
                  i === 4 ? "add-top-margin" : ""
                )}
              >
                <div className="summ_form_label">
                  {i === 0 && ((subject === 1 || subject === 2) ?(
                    <p>Summatives</p>
                  ): subject === 3 && showPerformanceLevelForScience ? <p>Summatives</p>:<></> )}

                  {i === 4 && ((subject === 1 || subject === 2)? (
                    <p>Formatives</p>
                  ): subject === 3 && showPerformanceLevelForScience?<p>Formatives</p>:<></>)}
                </div>
                {subject === 3 && !showPerformanceLevelForScience && i > 3 ? (
                  <></>
                ) : (
                  <div className="summ_form">
                    <p>
                      <span
                        className={`level-box level-box-${item?.type} level-box-radius`}
                      ></span>{" "}
                      {item.type}
                      {item.type === "A" ||
                      item.type === "P" ||
                      item.type === "B" ||
                      item.type === "BB" ||
                      item.type === "NP"
                        ? ":"
                        : ""}{" "}
                      {item.type === "A"
                        ? "Advanced"
                        : item.type === "P"
                        ? "Proficient"
                        : item.type === "B"
                        ? "Basic"
                        : item.type === "BB"
                        ? "Below Basic"
                        : item.type === "NP"
                        ? "Non-Proficient"
                        : ""}
                    </p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: `( ${item?.string} )`,
                      }}
                    ></p>
                  </div>
                )}
              </div>
            );
          })}
      
        {!performanceEnabled && (
          <>
            <div className="reportCards__student-performance-score spacing">
              <p>
                <img src={checkIcon} alt="" />
                Proficient
              </p>

              <p style={{ marginLeft: "67px" }}>(80% - 100%)</p>
            </div>
            <div className="reportCards__student-performance-score spacing">
              <p>
                <span
                  className={`level-box level-box-BB level-box-radius add-space`}
                ></span>
                Non-Proficient
              </p>

              <p style={{ marginLeft: "50px" }}>(0% - 79%)</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PerformanceSets;
