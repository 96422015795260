import React from "react";
const titleId = "statsIcon";
const StatsIcon = ({ currentTab }: { currentTab: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="25"
      viewBox="0 0 28 25"
      fill="none"
      aria-labelledby={titleId}
    >
      <title id={titleId}>Stats</title>
      <path
        d="M19.193 0V24.922H27.502V0H19.193ZM21.27 2.077H25.424V22.845H21.27V2.077ZM0.501953 6.23V24.922H8.80895V6.23H0.501953ZM2.57895 8.307H6.73195V22.845H2.57895V8.307ZM9.84795 12.461V24.922H18.155V12.461H9.84795ZM11.925 14.538H16.079V22.845H11.925V14.538Z"
        fill={currentTab === "stats" ? "white" : "#3e3e3e"}
      />
    </svg>
  );
};

export default StatsIcon;
