import cookie    from "js-cookie";
import axios     from "axios";
import appConfig from "config/config"
import { clearAllData } from "../util/index" 
import { logoutAction } from "index";
import { AppCookies } from "./constants";
// import { dispatchApiError } from "index";

axios.interceptors.request.use(async (config:any) => {
  config.headers.Authorization = `Bearer ${cookie.get(AppCookies.token)}`;
  config.headers.apiKey = appConfig.defaults.api_key
  return config;
});

const UNAUTHORIZED = 401;

axios.interceptors.request.use(
  request =>{
    return request
  },
  error=>error
  
)

axios.interceptors.response.use(
  response => response,
  error => {
    const {status} = error.response;
    if (status === UNAUTHORIZED) {
      clearAllData(() => {
        window.location.href = "/"
      });
      // return window.location.href = "/"
    } else if (status === 407) {
      logoutAction();
      window.location.href = "/"
    }else {
      // console.log("Dispatching api Error")
      // dispatchApiError()
      return Promise.reject(error);
    }
 }
)

export default axios;