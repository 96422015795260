const PdfIcon = ({isDisabled}:{isDisabled?: boolean}) => (

  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="22" viewBox="0 0 12 22" fill="none">
  <g style={{opacity: isDisabled ? 0.4 : 1}} clip-path="url(#clip0_563_1634)">
    <path d="M7.7046 0.293395C7.7046 0.293395 7.539 0.146093 7.4874 0.078703C7.4364 0.0113135 7.2702 0.0441137 7.2702 0.0441137L0.4218 3.97418C0.189 4.10776 0 4.43278 0 4.69995V10.595L0.4344 10.8443L11.5932 3.97358V2.525C9.4368 2.80589 7.7046 0.293395 7.7046 0.293395Z" fill="#B61A27"/>
    <path d="M0 10.5951V21.4514C0 21.5838 0.0462 21.6774 0.1218 21.7215C0.198 21.7663 0.5688 21.9768 0.5688 21.9768L11.1708 15.5557C11.4036 15.4221 11.5926 15.0971 11.5926 14.8299V3.97363L0.4344 10.8444L0 10.5951Z" fill="#DEDEDE"/>
    <path d="M7.7044 0.293457L0.855394 4.22352C0.622594 4.35711 0.433594 4.68213 0.433594 4.9493V10.8444L11.9992 4.20742V2.75884C9.5752 3.07492 7.80999 0.845097 7.70379 0.293457H7.7044Z" fill="#ED1A27"/>
    <path d="M0.433594 21.7008C0.433594 21.9679 0.622594 22.0765 0.855394 21.9429L11.578 15.7896C11.8108 15.656 11.9998 15.331 11.9998 15.0638V4.20752L0.433594 10.8445V21.7008Z" fill="#F6F6F6"/>
    <path d="M3.68359 20.32L11.579 15.7894C11.8118 15.6558 12.0008 15.3308 12.0008 15.0636V4.21924C11.6018 4.89313 12.404 13.7581 3.68419 20.32H3.68359Z" fill="#F0F0F0"/>
    <path d="M7.00907 4.89836C6.93587 5.71658 7.31627 5.6307 7.76447 5.39752L11.9993 2.76516L7.36787 0.48584L7.00907 4.89896V4.89836Z" fill="#CF1A27"/>
    <path d="M11.5939 2.9922C11.7319 2.88605 12.0013 2.75843 12.0013 2.75843C11.9425 2.72026 7.5625 0.0151367 7.5625 0.0151367C7.8769 2.01595 11.4559 3.09836 11.5939 2.9922Z" fill="#F32733"/>
    <path d="M7.36893 0.486084L7.32812 4.60937C7.34072 5.03219 7.94013 5.03935 7.94013 5.03935L11.6943 2.6014L7.70493 0.293457L7.36953 0.486084H7.36893Z" fill="#B61A27"/>
    <path d="M7.70312 4.73997C7.70312 5.00715 7.89212 5.11569 8.12492 4.9821L11.9991 2.75884L7.70312 0.293457V4.73997Z" fill="#F32733"/>
    <path d="M7.27013 0.0442893C7.49513 0.0609876 7.36853 0.486198 7.36853 0.486198L7.70393 0.293571C7.70393 -0.155494 7.26953 0.0442893 7.26953 0.0442893H7.27013Z" fill="#B61A27"/>
    <path d="M1.73438 16.7007L10.8004 11.4974V11.2153L1.73438 16.418V16.7007Z" fill="#454545"/>
    <path d="M1.73438 18.1617L10.8004 12.9589V12.6763L1.73438 17.8796V18.1617Z" fill="#454545"/>
    <path d="M1.73438 19.623L10.8004 14.4203V14.1382L1.73438 19.3409V19.623Z" fill="#454545"/>
    <path d="M1.73047 15.2036L5.64187 12.9588V12.6768L1.73047 14.9209V15.2036Z" fill="#454545"/>
    <path d="M1.73047 13.7431L5.64187 11.4984V11.2163L1.73047 13.4604V13.7431Z" fill="#454545"/>
    <path d="M5.99609 13.2476L10.7949 10.4936V6.85693L5.99609 9.61036V13.2476Z" fill="#31AEFF"/>
    <path d="M8.39513 10.6247L10.6415 9.33537C10.6415 9.33537 9.64793 8.8708 9.41873 8.86186C9.18953 8.85291 8.53253 10.3003 8.39453 10.6247H8.39513Z" fill="#425056"/>
    <path d="M7.43244 9.44128C7.34064 9.37091 6.40824 11.3503 6.14844 12.0957C6.14844 12.0957 6.19404 12.2215 7.03464 11.7218C7.87524 11.222 8.65464 10.4766 8.65464 10.4766C8.65464 10.4766 7.79904 9.58203 7.43184 9.44188L7.43244 9.44128Z" fill="#556267"/>
    <path d="M5.99609 12.0897V13.2473L10.7949 10.4933V9.24805C10.7949 9.24805 9.44969 9.66908 8.42609 10.5195C7.40189 11.3699 6.77549 11.9055 5.99609 12.0891V12.0897Z" fill="#31C23D"/>
    <path d="M10.1268 7.54447C9.93605 7.6542 9.78125 7.92078 9.78125 8.13965C9.78125 8.35851 9.93605 8.44797 10.1268 8.33824C10.3176 8.22851 10.4724 7.96193 10.4724 7.74306C10.4724 7.5242 10.3176 7.43474 10.1268 7.54447Z" fill="#FFE000"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.6816 5.78945C1.9396 5.64155 2.1082 5.55687 2.1862 5.53599C2.2768 5.51154 2.356 5.51691 2.4244 5.55269C2.4472 5.56462 2.6554 5.68508 2.6554 5.68508C2.6554 5.68508 2.5876 6.28503 2.5408 6.41265C2.4934 6.54087 2.434 6.65418 2.362 6.75318C2.29 6.85277 2.2168 6.93269 2.1418 6.99411C2.0404 7.07522 1.8934 7.17123 1.7014 7.28156L1.4242 7.44019L1.228 8.72537L1 8.59417V6.18067L1.6816 5.78945ZM1.9924 6.66611C2.0488 6.60886 2.0932 6.54385 2.125 6.47169L2.1244 6.47229C2.1568 6.40013 2.1724 6.32857 2.1724 6.257C2.1724 6.16874 2.1502 6.1097 2.1052 6.07809C2.0608 6.04648 2.0038 6.0435 1.9348 6.06914C1.8844 6.08704 1.7824 6.14011 1.6294 6.22778L1.4242 6.34526V7.02989L1.6564 6.89631C1.8238 6.80029 1.936 6.72336 1.9924 6.66611Z" fill="#D7D7D7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.13303 4.42727C4.25603 4.39865 4.36163 4.41177 4.44983 4.46782H4.45043C4.53803 4.52448 4.67843 4.59903 4.67843 4.59903C4.67843 4.59903 4.71983 5.04869 4.71983 5.27471C4.71983 5.4733 4.69763 5.65639 4.65503 5.82516C4.60283 6.0315 4.52783 6.21637 4.43003 6.38097C4.35623 6.50621 4.25663 6.62727 4.13123 6.74535C4.03763 6.83302 3.91283 6.92188 3.75563 7.01193L3.18503 7.60114L2.95703 7.46994V5.05644L3.73283 4.61095C3.90803 4.51076 4.04123 4.44934 4.13303 4.42727ZM4.12643 6.26826C4.17143 6.1955 4.20863 6.09591 4.23743 5.97186H4.23803C4.26683 5.84722 4.28123 5.69157 4.28123 5.50491C4.28123 5.31824 4.26683 5.18287 4.23803 5.09997C4.20923 5.01648 4.16903 4.96162 4.11743 4.93538C4.06523 4.90854 4.00043 4.90854 3.92063 4.93418C3.86123 4.95327 3.74483 5.01171 3.57203 5.11131L3.38123 5.22044V6.8193L3.69803 6.63741C3.81683 6.56942 3.90263 6.51277 3.95483 6.46685C4.02383 6.40781 4.08083 6.34161 4.12643 6.26826Z" fill="#D7D7D7"/>
    <path d="M6.75243 3.14048L6.52383 3.00928L5.08203 3.83704V6.25054L5.31063 6.38114L5.50683 4.98146L6.61323 4.20022L6.38463 4.06902L5.50683 4.57295V4.00163L6.75243 3.14048Z" fill="#D7D7D7"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.90816 5.92035C2.16616 5.77245 2.33476 5.68776 2.41276 5.66689H2.41336C2.53396 5.63409 2.63476 5.65496 2.71636 5.72951C2.79736 5.80465 2.83816 5.93764 2.83816 6.12967C2.83816 6.27757 2.81476 6.41593 2.76796 6.54355C2.72056 6.67177 2.66116 6.78508 2.58916 6.88408C2.51716 6.98367 2.44396 7.06358 2.36896 7.12501C2.26756 7.20611 2.12056 7.30213 1.92856 7.41246L1.65136 7.57109V8.48115L1.22656 8.72506V6.31156L1.90816 5.92035ZM2.21896 6.79701C2.27536 6.73975 2.31976 6.67475 2.35156 6.60259L2.35096 6.60319C2.38336 6.53103 2.39896 6.45946 2.39896 6.3879C2.39896 6.29964 2.37676 6.24059 2.33176 6.20899C2.28676 6.17738 2.22976 6.1744 2.16076 6.20004C2.11036 6.21793 2.00836 6.27101 1.85536 6.35868L1.65016 6.47616V7.16079L1.88296 7.0272C2.05036 6.93119 2.16256 6.85426 2.21896 6.79701Z" fill="#FAFAFA"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.3635 4.55862C4.4865 4.52999 4.5921 4.54311 4.6803 4.59917L4.6797 4.59858C4.7673 4.65523 4.8345 4.74707 4.8807 4.87469C4.9269 5.00232 4.9497 5.17944 4.9497 5.40546C4.9497 5.60405 4.9281 5.78714 4.8855 5.95591C4.8333 6.16225 4.7583 6.34713 4.6605 6.51172C4.5867 6.63696 4.4871 6.75802 4.3617 6.8761C4.2681 6.96377 4.1433 7.05263 3.9861 7.14268L3.1875 7.60129V5.18779L3.9633 4.7423C4.1385 4.64211 4.2717 4.58068 4.3635 4.55862ZM4.3569 6.39961C4.4019 6.32685 4.4391 6.22726 4.4679 6.10321C4.4967 5.97857 4.5111 5.82292 4.5111 5.63626C4.5111 5.44959 4.4967 5.31422 4.4679 5.23132C4.4391 5.14783 4.3989 5.09296 4.3473 5.06672C4.2957 5.03989 4.2303 5.03989 4.1511 5.06553C4.0917 5.08462 3.9753 5.14306 3.8025 5.24265L3.6117 5.35179V6.95065L3.9285 6.76876C4.0473 6.70077 4.1331 6.64412 4.1853 6.5982C4.2543 6.53916 4.3113 6.47296 4.3569 6.39961Z" fill="#FAFAFA"/>
    <path d="M5.3125 6.38129L5.7367 6.13797V5.11222L6.6151 4.60829V4.20037L5.7367 4.7037V4.13238L6.7543 3.54914V3.14062L5.3125 3.96779V6.38129Z" fill="#FAFAFA"/>
  </g>
  <defs>
    <clipPath id="clip0_563_1634">
      <rect width="12" height="22" fill="white"/>
    </clipPath>
  </defs>
</svg>
 
)
export default PdfIcon
