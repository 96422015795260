
import React from "react";
import DeleteIcon from "assets/img/trash-icon.svg";
import { Button } from "./buttonComponent";
import CustomModal from "./Modal";

interface DeleteConfirmationModalProps {
    loading: boolean;
    isShow: boolean;
    headerText: string;
    bodyText: string;
    onCancel: () => void;
    onDelete: () => void;
    showIcon?: boolean
}

export const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
    isShow,
    headerText,
    bodyText,
    loading,
    onCancel,
    onDelete,
    showIcon
  }) => {
    return (
      <CustomModal centered show={isShow} onEscapeKeyDown={onCancel}>
        <div className="modal__header">
          {showIcon && (
            <img alt="trash" src={DeleteIcon} className="modal__header-icon" />
          )}
          <h6 className="modal__header-title">{headerText}</h6>
        </div>
        <div className="modal__body">
          <p style={{...(!showIcon && {fontSize: 15})}}>{bodyText}</p>
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={onCancel}
            className='btn cancel-btn'
            disabled={loading}
            type='button'
          />
          <Button
            buttonText="Delete"
            onClick={onDelete}
            className='btn danger-btn delete-button-width'
            disabled={loading}
            loading={loading}
            type='button'
          />
        </div>
      </CustomModal>
    );
  };