import {useEffect} from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { fetchUserMenu } from 'redux/actionCreators/auth';
import { useDispatch } from 'react-redux';
import { AppCookies } from 'util/constants';


const Layout = (props:any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get(AppCookies.token);

  useEffect(() => {
    if(!token){
      navigate("/");
    }

    dispatch(fetchUserMenu());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token,navigate]);

  return (
    <>
      {props.children}
    </>
  );

}

export default Layout;