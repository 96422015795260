import { useMemo } from "react"
import { useSelector } from "react-redux"
import { RootState } from "redux/reducers/combine";
import PrintReport from "./printReport";
import PrintFooter from "components/common/report/Print/footer";
import { browserHeights, isFirefox, isSafari } from "util/index";
import { useSchoolName } from "hooks/useSchoolName";

const threshold = (isFirefox || isSafari)? 20 : 30;

const IRRFormativePrint = ({dropDownLabel}:{dropDownLabel:string}) => {

    const report = useSelector((state: RootState) => state.classroom.irrFormative);
    const school_name = useSchoolName();

    const { Students , isSingleColumn } = useMemo(() => {

        const students = report?.data?.response?.students
        if (!students) {
            return { Students: [] , isSingleColumn:true }
        }

        return {
            Students: students?.sort((a: any, b: any) => {
                return a.last_name.localeCompare(b.last_name)
            }),
            isSingleColumn : students.length <= threshold
        }
    }, [report])


    // const assessmentName = Cookies.get('test_name') || '';

    return (
        <div style={{ marginTop: 18 }} className={`${isFirefox && 'Irr-formative-firefox-print'}`}>
            {new Array(Math.ceil(Students ? (Students.length / threshold) / 2 : 0)).fill(0).map((_, indx: number) => {
                return <>
                    <div className="d-flex align-items-center">
                        <div className="irr-main-div d-flex align-items-center justify-content-between"
                            style={{ borderBottom: '0.5px solid #8CA3C9' }}
                        >

                            <div className="response-first lineHeight1" >
                                <h3 className="report__header-title lineHeight1 marginBottom0" style={{ color: '#000000'}}>Item Response Report</h3>
                                <p className="report__header-text lineHeight1 marginBottom0" style={{ color: '#000000'}}>{new Date().toLocaleDateString()}</p>
                            </div>
                            <div className="lineHeight1">
                                <h3 className="d-flex justify-content-end print__header-title lineHeight1 marginBottom0" >{report?.data?.response?.class_name || ''}</h3>
                                <p className="report__header-text print__header-title lineHeight1 marginBottom0" >{school_name || ''}</p>
                            </div>
                        </div>
                    </div>

                    <div className="irr-report-break irr-formative-report-print lineHeight1 marginBottom0" >
                        <div className={`${ isSingleColumn ?'singleColumn' :'multipleColumns' }  ${browserHeights} ${Math.ceil((Students?.slice(indx * threshold * 2, (indx + 1) * threshold * 2)).length / threshold) > 1 ? '' : 'justify-content-center'} d-flex`}>
                            {new Array(Math.ceil((Students?.slice(indx * threshold * 2, (indx + 1) * threshold * 2)).length / threshold)).fill(0).map((_, idx) => {
                                return <>
                                    <div
                                        style={{
                                            borderRight: idx === 0 && Math.ceil((Students?.slice(indx * threshold * 2, (indx + 1) * threshold * 2)).length / threshold) > 1 ?
                                                '1px solid #707070'
                                                :
                                                'none'
                                        }}
                                        key={idx}
                                    >
                                        <PrintReport
                                            indx={indx * 2 + idx}
                                            dropDownLabel={dropDownLabel}
                                        />
                                    </div>
                                </>
                            })}
                        </div>
                        <PrintFooter
                            text={<>
                                *Totals in the footer are for all students, not just<br /> the students in that
                                column.
                            </>}
                        />
                    </div>

                </>
            })}
        </div>
    )

}


export default IRRFormativePrint