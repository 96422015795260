import DropDownArrow from "assets/img/drop-down-select.svg";

const ToolTypeDropdown = ({handleOnChange, toolType}: any) => {
  return (
    <div className="dropDown">
        <select
            tabIndex={0}
            className="w-100 customFieldsFocused-active"
            onChange={(e:any) => handleOnChange('toolType', e.target.value)}
            value={toolType}
        >
            <option value="Data Team Meeting Worksheet">Data Team Meeting Worksheet</option>
            <option value="PD Resource">PD Resource</option>
            <option value="Way to A Report">Way to A Report</option>
            <option value="Mastery Video Series">Mastery Video Series</option>
        </select>
        <img src={DropDownArrow} alt="Arrow Down" />
    </div>
  )
};

export default ToolTypeDropdown;
