import GoalsTable from "./table";

const Goals = () => {

  return (
    <div className="w-100 roundedBorder goals-card-data">
      <GoalsTable />
    </div>
  );
};

export default Goals;
