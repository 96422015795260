export const showDesmosCalculator = (grade: number, element: HTMLElement) => {
    let calculator: any = null;
    switch(grade){
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
            calculator = Desmos.FourFunctionCalculator(element, {additionalFunctions: ['sqrt', 'percent']});
            break;
        case 8:
            calculator = Desmos.ScientificCalculator(element);
            break;
        default:
            break;
    }
    return calculator;
}