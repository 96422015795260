

import { FC, useCallback, useMemo } from "react"

interface Props {
    schools: any;
    tabIndex: number;
    setTabIndex: (tabIndex: number) => void;
    classesPerTab: number,
    setShowClassColumn:Function
}

const ReportTabs: FC<Props> = (props) => {
    const { schools, tabIndex, setTabIndex, classesPerTab ,setShowClassColumn} = props;
    
    const onChangeTab = useCallback((tab : number) =>{
        setTabIndex(tab)
        setShowClassColumn()
    },[setTabIndex,setShowClassColumn])

    const generateTabs = useMemo(() => {
        const tabs = [];
        let startIndex = 0,classCount = 0
        for (let index = 0, tab = 0; index < schools.length; index++) {
            classCount += schools[index].classrooms.length

            if (classCount >= classesPerTab) {
                tabs.push(
                    <>
                        <input
                            type="radio"
                            className="report-switch-radio"
                            id={`radio-${tab + 1}`}
                            name="report"
                            checked={tabIndex === tab}
                            onChange={() => { }}
                            onClick={(e: any) => onChangeTab(tab-1)}
                        />
                        <label htmlFor={`radio-${tab + 1}`} className="report-switch-tab">
                            {`${schools[startIndex]?.name}-${schools[index]?.name}`}
                        </label>
                    </>
                )
                if (classCount === classesPerTab) {
                    classCount = 0;
                    startIndex = index+1;
                }
                else {
                    classCount = classCount - classesPerTab;
                    startIndex = index;
                }
                tab++;
            }
            if (index === schools.length - 1 && classCount < classesPerTab) {
                tabs.push(<>
                    <input
                        type="radio"
                        className="report-switch-radio"
                        id={`radio-${tab + 1}`}
                        name="report"
                        checked={tabIndex === tab}
                        onChange={() => { }}
                        onClick={(e: any) =>  setTabIndex(tab)}
                    />
                    
                    <label htmlFor={`radio-${tab + 1}`} className="report-switch-tab ">
                    <span className="report-switch-span">
                        {`${schools[startIndex]?.name}-${schools[index]?.name}`}
                        </span>
                    </label>
                    
                </>
                )
            }
        }
        return tabs

    }, [classesPerTab, schools, tabIndex,setTabIndex,onChangeTab])

    const tabCount = generateTabs.length

    if(tabCount<=1){
        return <></>
    }
    return (
        <div className={`summative-report-tabs ${tabCount>=3 && "tabs-margin"}`}>
            <div className="report-switch aog-switch animated-fade">
                {generateTabs}
                <span className="report-switch-slider"></span>
            </div>
        </div>
    )

}

export default ReportTabs