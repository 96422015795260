import Skeleton from "react-loading-skeleton";


const GoalSkeleton = ({isLoading}: any) => {

    return (
        <div className="goalsCard goals-card-skeleton">
            <Skeleton enableAnimation={isLoading} baseColor="#323233b3" highlightColor="#5371b732" borderRadius={12} width="97%" height={26}style={{margin: "4px 0 5px"}} />
            <Skeleton enableAnimation={isLoading} baseColor="#323233b3" highlightColor="#5371b732" borderRadius={12} width="97%" height={18} style={{margin: "6px 0px 0px"}} />
            <Skeleton enableAnimation={isLoading} baseColor="#323233b3" highlightColor="#5371b732" borderRadius={12} width="97%" height={18} style={{margin: "-1px 0px 0px"}} />
            <Skeleton enableAnimation={isLoading} baseColor="#323233b3" highlightColor="#5371b732" borderRadius={12} width="97%" height={18} style={{margin: "-1px 0px 0px"}} />
            <Skeleton enableAnimation={isLoading} baseColor="#323233b3" highlightColor="#5371b732" borderRadius={8} width="97%" height={47} style={{margin: "102px 0px 0px"}} />
        </div>
    )
};

export default GoalSkeleton;
