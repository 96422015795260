import axios from "util/axios";
import config from "config/config";

export const getDistrictAdmins = async (val: string, district_id: any) => {
  try {
    let response = await axios.get(
      `${config.defaults.api_url}/api/v3/admin/district/search?search=${val}&district_id=${district_id}`,
      {
        headers: {
          apiKey: config.defaults.api_key!,
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const getSchoolAdmins = async (val: string, school_id: any) => {
  try {
    let response = await axios.get(
      `${config.defaults.api_url}/api/v3/admin/schools/search?search=${val}&school_id=${school_id || 0}`,
      {
        headers: {
          apiKey: config.defaults.api_key,
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const getClassAdmins = async (val: string, classroom_id: number = 0) => {
  try {
    let response = await axios.get(
      `${config.defaults.api_url}/api/v3/admin/classroom/search?search=${val}&classroom_id=${classroom_id}`,
      {
        headers: {
          apiKey: config.defaults.api_key,
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const fetchSchoolStudents = async (val: string, school_id: any, classroom_id: any) => {
  try {
    let response = await axios.get(
      `${config.defaults.api_url}/api/v3/admin/schools/${school_id}/${classroom_id}/other-students?search=${val}`,
      {
        headers: {
          apiKey: config.defaults.api_key,
        },
      }
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const getAllClassAdmins = async (schoolId: any, classId? : any) => {
  let url = `${
    config.defaults.api_url
  }/api/v3/admin/classroom/classroom-admins?offset=${0}&limit=${100}&school_id=${schoolId}`;
  if(classId){
    url = url + `&classroom_id=${classId}`
  }
  try {
    let response = await axios.get(url, {
      headers: {
        apiKey: config.defaults.api_key,
      },
    });
    return response.data;
  } catch (e) {
    console.log(e);
  }
};
