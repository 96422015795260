import React, { FC } from 'react'
interface Props {
    attemptVariant: string;
    givePadding?:boolean;
    isPerformance?:boolean
}

const AttemptVariant: FC<Props> = ({attemptVariant, givePadding, isPerformance}) => {
    const atmptVrnt = !isPerformance? attemptVariant.split("-"): attemptVariant;
    return(!isPerformance ? 
    <div className={`d-flex justify-content-end align-item-center ${givePadding ? "pe-3":""}`}>
        {atmptVrnt[0]} <span className="variant">Ver {atmptVrnt[1]}</span>
      </div> 
      : 
      <span className="variant">Ver  {atmptVrnt}</span>
    )
}

export default AttemptVariant
