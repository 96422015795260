export type SupportTicketSuperAdminContextProvider = {
    fetchSupportTicketMessages: Function;
    commentLoading: boolean;
    setSupportTicketsLoading: Function;
    fetchSupportTicketManagementComment: Function;
    unsetAdmins: Function;
    singleAdminChat: Chat;
    fetchSupportTicketManagementStats: Function;
    fetchSupportTicketManagementStatsWithoutPageLoad: Function;
    fetchSupportTicketManagement: Function,
    fetchSupportTicketManagementWithoutPageLoad: Function,
    fetchSupportTicketDetails: Function,
    ticketDetails: SupportTicketDetails,
    supportTickets: SupportTicketsResponse,
    supportTicketStats: SupportTicketStatsResponse
    supportChat: SupportChatResponse,
    fetchSupportChat: Function,
    fetchSupportChatWithoutPageLoad: Function;
    onClickReply: Function,
    updateSupportTicketStatus: Function;
    updateSupportTicketComment: Function
    ticketDetilsComments: TicketDetilsCommentsResponse,
    selectedMessage: SingleTicketMessage;
    setSelectedMessage: Function;
    addReply: Function;
    fetchActiveChats: Function;
    activeChats: ActiveChats;
    superAdmins: SuperAdmins;
    putChat: Function;
    fetchChatById: Function;
    unsetChatById: Function;
    sendMessageSuperAdmin: Function;
    originalTicket: any;
    videoOptions: Array<any>,
    addAttachmentToTicket: Function;
    createNewTicket: Function;
    admins: Admin[],
    fetchSupportTicketAdmins: Function,
    fetchPreviousChatById: Function,
    fetchSuperAdmin: Function,
    setSupportChat: Function
}


export interface Admin {
    id: 831,
    first_name: string,
    last_name: string,
    email: string,
    district: string,
    school: string,
    classrooms: Classroom[]
}

export interface Classroom {
    id: number,
    fk_classroom_id: number,
    classrooms: {
        id: number,
        name: string
    }
}

export interface SuperAdmins {
    count: number,
    super_admins: any[]
}

export interface ChatMessage {
    id: number,
    message: string,
    color_hex: string,
    type: string,
    date: string,
    name: string,
    name_initials: string
}

export interface Chat {
    id: number,
    status: string;
    sent_by: string;
    respondent: string;
    school: string;
    district: string;
    classrooms: any[];
    messages: ChatMessage[];
    messages_count: number;
    date_created: string;
    unread_count: string;
}

export interface ActiveChats {
    status: string,
    chats: Chat[],
    assigned_chats: number,
    unassigned_chats: number
    addReply?: Function,
    originalTicket: any,
    videoOptions?: Array<any>,
    addAttachmentToTicket?: Function
}

export interface TicketDetilsCommentsResponse {
    comments: Comment[],
    count: number
}

export interface Comment {
    color_hex: string;
    comment: string
    date: string
    id: number
    name: string
    name_initials: string
    ticket_id: number
}

export interface SupportTicketStatsResponse {
    loading: boolean;
    new_tickets: number,
    new_chats: number,
    ticket_assigned: number,
    tickets_by_status: {
        submitted: number,
        in_progress: number,
        need_more_info: number,
        resolved: number
    }
}

export interface SupportTicketsResponse {
    count: number,
    tickets: SupportTicket[];
    loading: boolean;
}

export interface SupportChatResponse {
    chats: Chat[],
    count: number,
    loading: boolean
}

export interface Chat {
    id: number;
    type: string,
    summary: string,
    status: string,
    created_at: string,
    last_used: string,
    admin_assigned: string;
    unread_messages: boolean;
    respondent_id: number;
    last_reply: string;
    name: string;
}

export interface SupportTicket {
    id: number,
    type: string,
    summary: string,
    status: string,
    created_at: string,
    last_update: string,
    admin_assigned: string;
    unread_messages: boolean;
    submitter: string;
}

export enum SupportTicketFilters {
    LIMIT = 'LIMIT',
    OFFSET = 'OFFSET',
    SEARCH = 'SEARCH',
    ASSIGNEE = 'ASSIGNEE',
    STATUS = 'STATUS'
}

export interface PutSupportTicketStatus {
    ticketId: string,
    type: string,
    status: string,
    adminId: string
}

export enum TYPE {
    STATUS = 'STATUS',
    ASSIGNEE = 'ASSIGNEE',
    MESSAGE = 'MESSAGE',
}

export enum STATUS {
    RESOLVED = 'RESOLVED',
    TICKET_CREATED = 'TICKET_CREATED'
}

export interface PutSupportTicketComment {
    ticketId: string,
    type: string,
    status?: string,
    adminId?: string
}

export type SupportTicketDetails = {
    loading: boolean;
    details: SingleTicketDetails,
    messages: Array<SingleTicketMessage>,
    comments: Array<SingleTicketComment>,
    change_history: Array<SingleTicketChangeHistory>,
    comment_count: number;
    chat_history: boolean;
    message_count: number;
    support_chat_id: number | null;
}

export type SingleTicketDetails = {
    created_date: string,
    from: string;
    from_archived: boolean;
    summary: string,
    district: string,
    school: string,
    classroom: string,
    assigned_to: string,
    statuses: Array<string>
    status: string;
    classrooms: any;
    district_id: number;
    school_id: number;
    assessment_name: string,
    text_book_name: string,
    page_number: number;
    from_admin_id: number;
}

export type SingleTicketMessage = {
    id: number,
    sender_id: string,
    from: string,
    sender: string,
    sent: string,
    message: string,
    attachment_1: string,
    attachment_2: string,
    attachment_3: string,
    resource: string,
    attachmentCount: number;
    attachments: any[];
    is_reply: boolean;
    reply_of: number;
    seen: Boolean;
}

export type SingleTicketChangeHistory = {
    id: number,
    change: string,
    done_by: string,
    date_time: string
}

export type SingleTicketComment = {
    id: number,
    comment: string,
    color_hex: string,
    sent: string,
    sent_by: string;
    name_initials: string;
    name: string;
    ticket_id: number;
    date: string;
    replies: any[];
}

export interface GetTicketManagement { 
    limit: number, 
    offset: number, 
    search?: string, 
    assigneeId?: number, 
    status?: string, 
    sort_by?: string, 
    order_by?: string,
    created_by?: number,
    admin_created_id?: number
}