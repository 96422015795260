import React from "react";

const ActivityIcon = () => {
    const titleId = "activityIcon"
  return (
    <svg
      className="none-active"
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
      aria-labelledby={titleId}
    >
        <title id={titleId}>Activity</title>
      <path
        d="M16.8747 7.27295V16.5909H13.3567V12.5609C13.3567 12.4769 13.3402 12.3937 13.308 12.316C13.2759 12.2384 13.2287 12.1678 13.1693 12.1084C13.1099 12.049 13.0393 12.0018 12.9617 11.9697C12.884 11.9375 12.8008 11.9209 12.7167 11.9209H8.21673C8.047 11.9209 7.88421 11.9884 7.76419 12.1084C7.64416 12.2284 7.57673 12.3912 7.57673 12.5609V16.5909H4.07673V7.27295H2.80273V17.2299C2.80273 17.3997 2.87016 17.5625 2.99019 17.6825C3.11021 17.8025 3.273 17.8699 3.44273 17.8699H8.21873C8.38847 17.8699 8.55126 17.8025 8.67128 17.6825C8.79131 17.5625 8.85873 17.3997 8.85873 17.2299V13.2009H12.0787V17.2309C12.0787 17.315 12.0953 17.3982 12.1275 17.4759C12.1596 17.5535 12.2068 17.6241 12.2662 17.6835C12.3256 17.7429 12.3962 17.7901 12.4738 17.8222C12.5515 17.8544 12.6347 17.8709 12.7187 17.8709H17.5187C17.6028 17.8709 17.686 17.8544 17.7637 17.8222C17.8413 17.7901 17.9119 17.7429 17.9713 17.6835C18.0307 17.6241 18.0779 17.5535 18.11 17.4759C18.1422 17.3982 18.1587 17.315 18.1587 17.2309V7.27295H16.8747Z"
        fill="#194792"
      />
      <path
        d="M20.1922 8.98088L10.9142 0.302883C10.7959 0.193241 10.6405 0.132324 10.4792 0.132324C10.3179 0.132324 10.1625 0.193241 10.0442 0.302883L0.599201 8.97688C0.479278 9.09295 0.409437 9.25127 0.404569 9.41809C0.399702 9.58492 0.460191 9.74704 0.573142 9.8699C0.686093 9.99277 0.842569 10.0667 1.00921 10.0758C1.17585 10.0849 1.33948 10.0286 1.4652 9.91888L10.4742 1.64188L19.3162 9.91488C19.4412 10.0236 19.6036 10.0796 19.7691 10.0711C19.9346 10.0626 20.0903 9.99026 20.2035 9.86927C20.3167 9.74827 20.3786 9.58808 20.3761 9.42239C20.3736 9.2567 20.307 9.09843 20.1902 8.98088H20.1922Z"
        fill="#003b93"
      />
    </svg>
  );
};

export default ActivityIcon;
