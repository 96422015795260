import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useGetChartData } from 'hooks/useGetChartData';
import { useGetDataValues } from 'hooks/useGetDataValues';
// import { useNavigate } from 'react-router-dom';
import AnalysisBody from './AnalysisBox/body';
import AnalysisContainer from './AnalysisBox/container';
import AnalysisFooter from './AnalysisBox/footer';
import AnalysisHeader from './AnalysisBox/header';
import { useContext, useState } from 'react';
import GoalCompletionModal from './goalCompletionModal';
import { WayToAReportContext } from 'contexts/wayToAReport';
import GoalModal from 'components/oldprofessionalDevelopment/development/goal/goalModal';
import { useParams } from 'react-router-dom';
import { getSelectedGoal } from 'services/professionalDevelopment';

ChartJS.register(ArcElement, Tooltip, Legend);

const GoalCompletition = ({goalCompletition, fetchGoalsList}: any) => {

    const {completed, not_completed, status, text, percentage, partially_completed} = goalCompletition || {};
    const {goalsList} = useContext(WayToAReportContext);

    const {id} = useParams();
    const [showGoalCompletionModal, setShowGoalCompletionModal] = useState(false);
    const [goalDetailsLoading, setGoalDetailsLoading] = useState(false);
    const [goalCompletitionLoading, setGoalCompletitionLoading] = useState(true);
    const [selectedGoal, setSelectedGoal] = useState<any>(null!);

    // const navigate = useNavigate();
    const {dataValues, backgroundColor, isNotData} = useGetDataValues({
        firstDatasetValue:completed, 
        secondDatasetValue: not_completed,
        ...(partially_completed && {thirdDatasetValue: partially_completed}),
        percentage,
        isGoalCompletetion: true
    });
    const ChartData = useGetChartData({dataValues, backgroundColor, isNotData});

    const onClickGoal = async (goal: any) => {
        setSelectedGoal(goal.id);
        setGoalDetailsLoading(true);
        const selectedGoal = await getSelectedGoal(goal.id, id);
        selectedGoal && setSelectedGoal(selectedGoal)
        setGoalDetailsLoading(false);

        setShowGoalCompletionModal(false);
    }

    const onCloseGoalModal = () => {
        setSelectedGoal(null!);
        setShowGoalCompletionModal(true);

    }
    const onClickView = async () => {
        setShowGoalCompletionModal(true);

        setGoalCompletitionLoading(true);
        await fetchGoalsList(id);
        setGoalCompletitionLoading(false);
    }
    
    return (
        <>
            <AnalysisContainer>
                <AnalysisHeader status={status} title="Goal Completion" />
                
                <AnalysisBody 
                    ChartData={ChartData}
                    text={text}
                    percentage={percentage}
                    key={isNotData}
                    isImage={isNotData}
                />

                <AnalysisFooter 
                    firstLabel="Completed"
                    secondLabel="Not Completed"
                    buttonText='View'
                    onClick={onClickView}
                    isGoalCompletetion
                />
            </AnalysisContainer>

            {showGoalCompletionModal && (
                <GoalCompletionModal 
                    onClickGoal={onClickGoal} 
                    onClose={() => setShowGoalCompletionModal(false)} 
                    goalsList = {goalsList}
                    loading={goalCompletitionLoading}
                />
            )}

            {!!selectedGoal && (
                <GoalModal 
                    selectedGoal={selectedGoal} 
                    closeModal={onCloseGoalModal}
                    closeGoalcompletitionModal={() => setShowGoalCompletionModal(false)}
                    loading={goalDetailsLoading}
                    executeAction={false}
                />
            )}

        </>
    )
};

export default GoalCompletition;
