/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import cryptoRandomString from "crypto-random-string";
import { debounce } from "lodash";
import moment from "moment";
import { Collapse } from "react-bootstrap";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import SearchIcon from "components/common/svgs/search-icon";
import Pagination from "../pagination/pagination";
import { ResetPasswordModal } from "./management-add-modal";
import { DistrictAdminDeleteModal } from "./management-delete-modal";
import { DistrictAdminUpdateModal } from "./management-update-modal";
import { useDispatch, useSelector } from "react-redux";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import { LimitSelect } from "components/common/selectComponent";
import {
  getDistrictAdmins,
  deleteDistrictAdmins,
  updateDistrictAdmins,
  resetPasswordAdmin,
  setFlagForFetch,
} from "../../redux/actionCreators/management";
import SortIndicatorIcon from "components/common/svgs/sort-indicator-icon";
import PencilIconManagement from "components/common/svgs/pencil-icon-management";
import TrashIcon from "components/common/svgs/trash-icon";
import ResetPasswordIcon from "components/common/svgs/reset-password-icon";
import useSortBy from "hooks/useSortBy";

interface RootState {
  management: any;
  auth: any;
}

const DistrictAdmins = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const { management, auth } = useSelector((state: RootState) => state);
  const [open, setOpen] = useState(false);
  const [offSet, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentItem, setCurrentItem] = useState<null | any>(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [modalBorder, setModalBorder] = useState(false);
  const [resetPassModdal, setResetPassModdal] = useState(false);
  const [randomPass, setRandomPass] = useState("");
  // const [skeletonLoad, setSkeletonLoad] = useState(true);

  const {sortBy, OrderByColumn, sortDataBy} = useSortBy("last_name", "ASC")

  useEffect(() => {
    dispatch(
      getDistrictAdmins(offSet, limit, searchTerm, null, sortBy, OrderByColumn)
    );
  }, [
    offSet,
    limit,
    management.districtAdminsFlag,
    dispatch,
    sortBy,
    OrderByColumn,
  ]);

  // useEffect(() => {
  //   if (searchTerm) {
  //     setTimeout(() => {
  //       setOpen(true);
  //           dispatch(getDistrictAdmins(offSet, limit,searchTerm));
  //       if (inputRef.current !== null) {
  //         inputRef.current.focus();
  //       }
  //     }, 4000);

  //   }
  // }, [searchTerm]);

  const handleSearch = useCallback(
    debounce((val: any) => {
      console.log(val);
      setOpen(true);
      setCurrentPage(1);
      setOffSet(0);
      dispatch(getDistrictAdmins(0, limit, val, null, sortBy, OrderByColumn));
      if (inputRef.current !== null) {
        inputRef.current.focus();
      }
    }, 1000),
    []
  );

  const doChangePage = (pageNo: number) => {
    let newOffset = pageNo * limit - limit;
    setCurrentPage(pageNo);
    setOffSet(newOffset);
  };

  const doSetDelModal = (item: any) => {
    setCurrentItem(item);
    setDeleteModal(true);
  };

  const doSetUpdateModal = (item: any) => {
    console.log(item, ".................");
    setCurrentItem(item);
    setUpdateModal(true);
  };

  const setPreviousPage = () => {
    if (currentPage > 1 && management.districtAdmins.length === 1) {
      doChangePage(currentPage - 1);
    } else {
      dispatch(setFlagForFetch("SET_DISTRICT_ADMIN_FLAG"));
    }
  };
  const onDeleteDistrictAdmin = () => {
    dispatch(
      deleteDistrictAdmins(currentItem.id, setDeleteModal, setPreviousPage)
    );
  };

  const onUpdateDistrictAdmin = (data: any) => {
    dispatch(updateDistrictAdmins(currentItem.id, data, setUpdateModal));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalBorder(true);
    }, 300);

    return () => {
      clearTimeout(timer);
      setModalBorder(false);
    };
  }, [open]);

  const doSetPasModal = (item: any) => {
    setCurrentItem(item);
    let newPass = cryptoRandomString({ length: 10, type: "url-safe" });
    setRandomPass(newPass);
    setResetPassModdal(true);
  };
  const onCreatePassword = () => {
    let data = {
      admin_id: currentItem?.id,
      password: randomPass,
    };
    console.log(currentItem);
    dispatch(resetPasswordAdmin(data, setResetPassModdal));
    // setCurrentItem(null)
  };

 

  useEffect(() => {
    // const timer = setTimeout(() => {
    //   setSkeletonLoad(false);
    // }, 800);

    return () => {
      // clearTimeout(timer);
      // setSkeletonLoad(true);
    };
  }, [management.districtAdminsLoading]);

  return (
    <>
      <div
        role="button"
        tabIndex={0}

        onKeyDown={(e: any) => {
          if (e.key === "Enter" && !(
              (e.target as Element).nodeName === "INPUT" ||
              (e.target as Element).nodeName === "BUTTON" ||
              (e.target as Element).nodeName === "A") ) {
                setOpen(!open);
            }
          }
        }
        onClick={(e) => {
          if (
            (e.target as Element).nodeName === "INPUT" ||
            (e.target as Element).nodeName === "BUTTON" ||
            (e.target as Element).nodeName === "A"
          ) {
            return;
          }
          setOpen(!open);
        }}
        className={`accordion__header ${open ? "open" : ""} ${
          modalBorder ? "closeDelay" : ""
        }`}
      >
        <div className="accordion__header-heading">
          <span className="accordion__header-button">
            <img
              src={ArrowIcon}
              alt="arrow"
              className={`${open ? "rotate" : ""}`}
            />
          </span>
          <h6>District Admins</h6>
          {!open ? (
            <div className="accordion__header-search animated">
              {/* <img src={SearchIcon} alt="Search" /> */}
              <SearchIcon style = {{marginRight: "10px"}} title="Search Admins" />
              <input
                value={searchTerm}
                onChange={(e) => {
                  handleSearch(e.target.value);
                  setSearchTerm(e.target.value);
                }}
                type="text"
                className="form-control"
                placeholder="Search Admins"
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <Collapse in={open}>
        <div>
          <div className="accordion__content">
            <div className="section-search">
              <div className="section-search-field animated">
                <div className="icon">
                  <SearchIcon fill="#323233" id="searchDistrictAdmins" title="Search Admins"/>
                </div>
                <input
                  ref={inputRef}
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    handleSearch(e.target.value);
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Search Admins"
                />
              </div>

              <div className="d-flex align-items-center show-classes-dropdown">
                <span>Show</span>
                <LimitSelect
                  disableHandler={
                    management.districtAdmins &&
                    management.districtAdmins.length < 10
                  }
                  onChangeHandler={(e: any) => setLimit(Number(e?.value))}
                />
                <span>Admins</span>
              </div>
            </div>
            <div className="management-table-container district-admin-table-container">
              <div className="table-responsive">
                <table className="management__table">
                  <thead>
                    <tr className="management__table-row">
                      <th
                        className="management__table-admin"
                        tabIndex={0}
                        onClick={() => sortDataBy("last_name")}
                        onKeyDown={(e) => e.key === "Enter" && sortDataBy("last_name")}
                      >
                        Admin Name
                        {sortBy === "last_name" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th
                        className="management__table-email"
                        tabIndex={0}
                        onClick={() => sortDataBy("email")}
                        onKeyDown={(e) => e.key === "Enter" && sortDataBy("email")}
                      >
                        Email
                        {sortBy === "email" && (
                          <SortIndicatorIcon OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      <th className="management__table-title">Title</th>
                      <th
                        className="management__table-login"
                        tabIndex={0}
                        onClick={() => sortDataBy("last_login")}
                        onKeyDown={(e) => e.key === "Enter" && sortDataBy("last_login")}
                      >
                        Last Login{" "}
                        {sortBy === "last_login" && (
                         <SortIndicatorIcon  OrderByColumn={OrderByColumn} />
                        )}
                      </th>
                      {auth && auth.currentUser?.role === "Super Admin" && (
                        <th className="management__table-login">PIN</th>
                      )}
                      <th className="management__table-options">Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {skeletonLoad ? (
                      <TableSkeleton columns={6} count={limit} height={36} />
                    ) : (
                      ""
                    )} */}

                    {management.districtAdminsLoading ? (
                      <TableSkeleton columns={6} count={limit} height={36} />
                    ) : management.districtAdmins &&
                      management?.districtAdmins?.length ? (
                      management.districtAdmins.map((item: any, i: number) => {
                        if(item?.admins_districts?.length > 1){
                          console.log('admin', item);
                        }
                        return (
                          <tr
                            key={i}
                            className={
                              i % 2 !== 0
                                ? "management__table-row"
                                : "management__table-row"
                            }
                          >
                            <td className="admin-data">
                              {item?.last_name}, {item?.first_name}
                            </td>
                            <td className="email-data">{item?.email}</td>
                            <td className="title-data">{item?.title}</td>
                            <td className="login-data">
                              {item?.last_login
                                ? moment(item?.last_login).format("M-D-YY")
                                : "-"}
                            </td>
                            {auth &&
                              auth.currentUser?.role === "Super Admin" && (
                                <td className="login-data">
                                  {item?.admins_districts[0]?.support_pin || ""}
                                </td>
                              )}
                            <td>
                              <div className="d-flex justify-content-start align-items-center">
                              
                              <button 
                               tabIndex={0}
                               onKeyDown={(e: any) => {
                                 if (e.key === "Enter") {
                                      doSetUpdateModal(item)
                                    }
                                  }} 
                                onClick={()=>doSetUpdateModal(item)} 
                                className="management__table-editBtn" 
                                style={{border:"none", background:"none"}}>
                                <PencilIconManagement fill="#323233"/>
                                </button>
                                {auth &&
                                auth.currentUser?.role === "Super Admin" ? (	
								<button 
                               tabIndex={0}
                               onKeyDown={(e: any) => {
                                 if (e.key === "Enter") {
									doSetPasModal(item)
                                    }
                                  }} 
                                onClick={()=> doSetPasModal(item)} 
                                className="management__table-editBtn" 
                                style={{border:"none", background:"none"}}>
                                <ResetPasswordIcon />
                                </button>
								  
                                ) : (
                                  ""
                                )}
								<button 
                               tabIndex={0}
                               onKeyDown={(e: any) => {
                                 if (e.key === "Enter") {
									doSetDelModal(item)
                                    }
                                  }} 
                                onClick={()=>doSetDelModal(item)} 
                                className="management__table-editBtn" 
                                style={{border:"none", background:"none"}}>
								<TrashIcon fill="#323233" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        {management?.districtAdmins?.length === 0 &&
                          !management.districtAdminsLoading && (
                            <td colSpan={12} className="no-admin">
                              No Admins
                            </td>
                          )}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Pagination
            totalCount={management.districtAdminsCount}
            currentPage={currentPage}
            pageSize={limit}
            onPageChange={doChangePage}
          />
        </div>
      </Collapse>
      {deleteModal && (
        <DistrictAdminDeleteModal
          loading={management.districtAdminsDeleteLoading}
          isShow={deleteModal}
          headerText={`Delete ${currentItem?.first_name} ${currentItem?.last_name}`}
          bodyText={`Are you sure you want to delete this district admin?`}
          onCancel={() => setDeleteModal(false)}
          onDelete={onDeleteDistrictAdmin}
        />
      )}
      {updateModal && (
        <DistrictAdminUpdateModal
          isShow={updateModal}
          first_name={currentItem?.first_name}
          last_name={currentItem?.last_name}
          input_email={currentItem?.email}
          input_title={currentItem?.title}
          onCancel={() => setUpdateModal(false)}
          onUpdate={(data) => onUpdateDistrictAdmin(data)}
          loading={management.districtAdminsUpdateLoading}
        />
      )}
      {resetPassModdal && (
        <ResetPasswordModal
          isShow={resetPassModdal}
          loading={management?.resetPwLoading}
          onCancel={() => setResetPassModdal(false)}
          onAdd={onCreatePassword}
          password={randomPass}
          title={`${currentItem?.first_name} ${currentItem?.last_name}`}
        />
      )}
    </>
  );
};

export default DistrictAdmins;
