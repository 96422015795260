/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from "react";
import { Button } from "../../common/buttonComponent";
import CustomModal from "components/common/Modal";

interface SelectTestTypeModalProps {
  isShow: boolean;
  onClose: () => void;
  onSave: (x: any, y: any) => void;
  currentData: any;
  loading: boolean;
}

export const AssessmentTransferModal: React.FC<SelectTestTypeModalProps> = ({
  isShow,
  onClose,
  onSave,
  currentData,
  loading,
}) => {
  const onSubmit = (bindOldData: any) => {
    onSave({ ...currentData }, bindOldData);
  };

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={onClose}>
      <div className="modal__header">
        <h6 className="modal__header-title">{`Transfer Assessment Data`} </h6>
      </div>
      <div className="modal__body">
        <p>
          {`${currentData[0].student_name?.toUpperCase()} has existing assessment data for ${
            currentData[0].subject
          } ${
            currentData[0].grade
          }. Do you want to transfer this data to ${currentData[0]?.classroom_name.trim()}?`}
        </p>
      </div>
      <div className="modal__footer">
        <Button
          buttonText="No"
          onClick={() => onSubmit(false)}
          className="btn cancel-btn"
          type="button"
        />
        <Button
          buttonText="Yes"
          onClick={() => onSubmit(true)}
          className="btn success-btn btn-lg"
          type="button"
          loading={loading}
        />
      </div>
    </CustomModal>
  );
};
