/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
interface RootState {
  management: any;
  auth:any;
  activity:any;
}

const Breadcrumbs: React.FC<{ page: string }> = ({ page }) => {
  const navigate = useNavigate();
  const {management , auth, activity } = useSelector((state: RootState) => state);

  return (
    <div className="breadcrumb">
      {auth && auth.currentUser?.role === "Super Admin" ? (
        <div
          onClick={() =>
            navigate(
              `/management`
            )
          }
          className="breadcrumb__item primary"
        >
          <p className="breadcrumb__item-text">
            Districts
          </p>
        </div>
      ) : (
        ""
      )}

      {page !== "district" && management.breadcrumbs && management.breadcrumbs?.district_name && (auth.currentUser?.role === "Super Admin" || auth.currentUser?.role === "District Admin") ? (
        <div
          onClick={() =>
             navigate(
              `/management/district/${management.breadcrumbs?.district_id}`, {state: {name: management.breadcrumbs?.district_name}}
             )
          }
          style={{cursor: activity?.schoolsList?.length === 1 ? 'default': 'pointer'}}
          className={activity?.schoolsList?.length === 1 ? "breadcrumb__item primary": "breadcrumb__item secondary"}
        >
          <p className="breadcrumb__item-text">
            {management.breadcrumbs?.district_name }
          </p>
        </div>
      ) : (
        ""
      )}

      {page !== "school"  &&  page !== "district" &&
       management.breadcrumbs &&
        management.breadcrumbs?.school_name ? (
        <div
          onClick={() =>
            navigate(
              `/management/school/${management.breadcrumbs?.school_id}`
            )
          }
          className="breadcrumb__item secondary"
        >
          <p className="breadcrumb__item-text">
            {management.breadcrumbs?.school_name}
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Breadcrumbs;
