import Theme from "components/theme/index";
import PacingCalenders from "components/pacingCalender";
import PacingCalenderProvider from "contexts/pacingCalender";

const SuperAdminPacingCalender = () => {
    return <Theme>
        <PacingCalenderProvider>
            <PacingCalenders />
        </PacingCalenderProvider>
    </Theme>
}
export default SuperAdminPacingCalender