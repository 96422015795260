import { TableSelectCommon } from "components/common/selectComponent";
import { ProgressbarCore } from "components/theme/activityBar/progressBar";
import Skeleton from "react-loading-skeleton";

const ActivitySkeleton = ({showSecondLastCol, i}: any) => {
    return (
        <>
            <tr
                className={
                    i % 2 === 0
                    ? "progress__table-row"
                    : "progress__table-row"
                }>
                <td className='className-data'>
                    <Skeleton baseColor="#00000033" highlightColor="#737373" width={120} height={19} borderRadius={8} style={{marginTop: 1}}  />
                    <Skeleton baseColor="#00000033" highlightColor="#737373" width={90} height={19} borderRadius={8}  style={{marginTop: 1}}/>
                </td>
                <td className="hour-column">
                    <Skeleton baseColor="#00000033" highlightColor="#737373" width={44} height={27} borderRadius={8}  />
                </td>
                <td className='assesment-data'>
                    <Skeleton baseColor="#00000033" highlightColor="#737373" width={100} height={19} borderRadius={8}  />
                </td>
                <td>
                    <ProgressbarCore
                        color="#9080FF"
                        width={0}
                        isLoading
                    >
                        <></>
                    </ProgressbarCore>
                </td>
                <td>
                    <ProgressbarCore
                        color="#9080FF"
                        width={0}
                        isLoading
                    >
                        <></>
                    </ProgressbarCore>
                </td>
                <td>
                    <ProgressbarCore
                        color="#9080FF"
                        width={0}
                        isLoading
                    >
                        <></>
                    </ProgressbarCore>
                </td>

                <td>
                    <ProgressbarCore
                        color="#9080FF"
                        width={0}
                        isLoading
                    >
                        <></>
                    </ProgressbarCore>
                </td>
                <td>
                    {showSecondLastCol && (
                        <ProgressbarCore
                            color="#9080FF"
                            width={0}
                            isLoading
                        >
                            <></>
                        </ProgressbarCore>
                    )}
                </td>
                <td
                    tabIndex={0}
                    className="options disabled"
                >
                    <div>
                        <TableSelectCommon
                            isOpen={false}
                            toggleSelect={() => {}}
                            options={[]}
                            onChange={() => {}}
                            value=""
                        />
                    </div>
                </td>
            </tr>
        </>
    )
}

export default ActivitySkeleton;