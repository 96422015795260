/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return*/
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { orderBy } from "lodash";
import cookie from "js-cookie";
import { useReactToPrint } from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "assets/img/pencil-icon.svg";
import { StudentDeleteModal } from "./management-delete-modal";
import { StudentUpdateModal } from "./management-update-modal";
import {
  beforeMergeStudentsNew,
  getStudentDetail,
  getStudentSBRData,
  mergeStudents,
  transferStudent,
} from "redux/actionCreators/management";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import {
  deleteStudent,
  updateStudent,
  checkTestInProgress,
} from "../../redux/actionCreators/management";
import { UnroleModel } from "components/classroom/modals/unroleModel";
import { unenrollStudentFormClassroom } from "redux/actionCreators/classroom";
import { MergeStudentModal } from "components/classroom/modals/mergeStudentModal";
import {
  TransferStudentModal,
  TransferSuccessModal,
} from "components/classroom/modals/transferStudentModal";
import { useQuery } from "hooks/useQuery";
import StudentHeader from "components/common/StudentDetailHeader";
import StandardBasedReport from "components/common/StandardBased";
// import StudentDetailsPrint from "./student-details-print";
import BasedReportPrint from "../../components/StandardReports/Based/Print";
import BackButton from "components/common/backButton";
import { performanceOptions } from "components/reportCard/types/type";
import Performance from "components/reportCard/Performance";
import StudentPerformancePrint from "components/reportCard/print/StudentPerformancePrint";
import { AppCookies } from "util/constants";

interface RootState {
  management: any;
}

const StudentDetails: React.FC<{ studentId: string }> = ({ studentId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const management = useSelector((state: RootState) => state.management);

  const [yearSelected, setYearSelected] = useState(1);
  const [deleteModal, setDeleteModal] = useState(false);
  const [unenrollModal, setUnenrollModal] = useState(false);
  const [mergeStudentModal, setMergeStudentModal] = useState(false);
  const [transferStudentModal, setTransferStudentModal] = useState(false);
  const [transferSuccessModal, setTransferSuccessModal] = useState(false);
  const [transferInfo, setTransferInfo] = useState({});
  const [currentItem, setCurrentItem] = useState<null | any>(null);
  const [updateModal, setUpdateModal] = useState(false);
  const [skeletonLoad, setSkeletonLoad] = useState(true);
  const [classId, setClassId] = useState<any>(null);
  const [deleted, setDeleted] = useState<any>(false);
  const [sortInfo, setSortInfo] = useState<any>([]);
  const [hasTested, setHasTested] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);
  const componentRef = useRef(null);
  const componentSBRRef = useRef(null);
  const userRole = cookie.get(AppCookies.role);
  const studentName =
    management?.studentDetails?.student?.first_name +
    " " +
    management?.studentDetails?.student?.last_name;
    const scienceEnabled = management?.studentDetails?.science_enabled || false;
     const assessmentVariants = management?.studentDetails?.variants || [];
    const prevYearExists = management?.studentDetails?.previous_year_data_exists;
  const query = useQuery();
  const print = query.get("print");
  const [isPerformance, setIsPerformance] = useState(true);
  const [showLastThreeAttempts, setShowLastThreeAttempts] = useState(true);
  const [selectedPerformance, setSelectedPerformance] = useState<any>(
    performanceOptions[0]
  );

  const [studentSBRData, setStudentSBRData] = useState<any>([]);

  const [descriptionRowsHeights, setDescriptionRowsHeights] = useState<any>([]);

  const detailsRef = useRef<any>(null);

  const roles = ["Super Admin", "District Admin", "School Admin"];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setSkeletonLoad(true);
    dispatch(getStudentDetail(studentId));
  }, [deleted, management.studentFlag]);

  useEffect(() => {
    if (management.studentSBRDetails?.data)
      setStudentSBRData(management.studentSBRDetails?.data);

    console.log("descriptionRowsHeights", descriptionRowsHeights);
  }, [management.studentSBRDetails]);

  const doSetUpdateModal = (item: any) => {
    setCurrentItem(item);
    setUpdateModal(true);
  };

  const doSetMergeModal = (item: any) => {
    setCurrentItem(item);
    setMergeStudentModal(true);
  };

  const doSetTransferModal = (item: any) => {
    setCurrentItem(item);
    setTransferStudentModal(true);
  };

  const doSetDelModal = (item: any) => {
    setCurrentItem(item);
    setDeleteModal(true);
  };

  const onDeleteSuccess = (success: boolean) => {
    if (!success) {
      navigate(-1);
    }
  };

  const onDeletStudent = (type: string) => {
    let data = {
      student_id: currentItem?.id,
      delete_from: type,
    };
    dispatch(deleteStudent(data, onDeleteSuccess));
  };
  const onUpdateStudent = (data: any) => {
    dispatch(updateStudent(currentItem.id, data, setUpdateModal));
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setSkeletonLoad(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [skeletonLoad]);

  const unenrollStudent = () => {
    //!call the api here
    const unenroll = async () => {
      setLoading(true);
      await unenrollStudentFormClassroom(classId, +studentId);
      setHasTested(false);
      setUnenrollModal(false);
      setLoading(false);
      setDeleted(!deleted);
    };
    unenroll();
  };

  const onBeforeMergeStudent = (students: any) => {
    let data = {
      old_student_id: students.old_student_id,
      new_student_id: students.new_student_id,
    };
    dispatch(beforeMergeStudentsNew(data));
  };

  const onMergeStudent = async (students: any) => {
    let data = {
      old_student_id: students.old_student_id,
      new_student_id: students.new_student_id,
    };
    try {
      const mergeResp = await mergeStudents(data);
      if (mergeResp?.data?.data?.updated) {
        await dispatch(getStudentDetail(studentId));
      }
    } catch (e) {
      await dispatch(getStudentDetail(studentId));
      console.error(e);
    }
  };

  const onTransferStudent = async (data: any, testInfo: any) => {
    try {
      const transferResp = await transferStudent(data);
      if (transferResp?.data?.data?.transfered) {
        setTransferStudentModal(false);
        setTransferInfo(testInfo);
        setTransferSuccessModal(true);

        if (userRole === "School Admin") {
          navigate(-1);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleSortClick = (sortData: any) => {
    const info = sortInfo.find(
      (item: any) =>
        item.className === sortData.className &&
        item.isSummative === sortData.isSummative
    );
    if (info) {
      const updatedData = sortInfo.map((data: any) => {
        if (data === info) {
          data.orderBy = info.orderBy === "ASC" ? "DESC" : "ASC";
        }
        return data;
      });
      setSortInfo([...updatedData]);
    } else {
      sortData.orderBy = "DESC";
      setSortInfo([...sortInfo, sortData]);
    }
  };

  const getClassName = (sortData: any) => {
    const info = sortInfo.find(
      (item: any) =>
        item.className === sortData.className &&
        item.isSummative === sortData.isSummative
    );

    if (info?.orderBy === "DESC") {
      return "arrow-down-classname";
    } else {
      return "arrow-up-classname";
    }
  };

  let classrooms_previous: any = {};
  management?.studentDetails?.previous_year_data?.performance?.tests &&
    Object.keys(
      management?.studentDetails?.previous_year_data?.performance?.tests
    ).map((item: any, i: number) => {
      if (
        management?.studentDetails?.previous_year_data?.performance?.tests[item]
          .length > 0
      ) {
        management?.studentDetails?.previous_year_data?.performance?.tests[
          item
        ].map((sub: any) => {
          if (sub.summative === true) {
            if (
              item in classrooms_previous &&
              classrooms_previous[item]["summatives"]
            ) {
              classrooms_previous[item]["summatives"].push(sub);
            } else {
              classrooms_previous = {
                ...classrooms_previous,
                [item]: { ...classrooms_previous[item], summatives: [sub] },
              };
            }
          } else {
            if (
              item in classrooms_previous &&
              classrooms_previous[item]["formatives"]
            ) {
              classrooms_previous[item]["formatives"].push(sub);
            } else {
              classrooms_previous = {
                ...classrooms_previous,
                [item]: { ...classrooms_previous[item], formatives: [sub] },
              };
            }
          }
        });
      } else {
        classrooms_previous = {
          ...classrooms_previous,
          [item]: { summatives: [], formatives: [] },
        };
      }
    });

  Object.keys(classrooms_previous).map((cls) => {
    const sortDataSummative = sortInfo.find(
      (item: any) => item.className === cls && item.isSummative === true
    );
    const sortDataFormative = sortInfo.find(
      (item: any) => item.className === cls && item.isSummative === false
    );
    classrooms_previous[cls].summatives = orderBy(
      classrooms_previous[cls]["summatives"],
      [(sum) => moment(sum?.date)],
      [
        sortDataSummative
          ? sortDataSummative.orderBy === "DESC"
            ? "desc"
            : "asc"
          : "asc",
      ]
    );
    classrooms_previous[cls].formatives = orderBy(
      classrooms_previous[cls]["formatives"],
      [(sum) => moment(sum?.date)],
      [
        sortDataFormative
          ? sortDataFormative.orderBy === "DESC"
            ? "desc"
            : "asc"
          : "asc",
      ]
    );
  });

  const triggerUpdate = (value: any, type: number) => {
    if (!value) return;

    if (type === 2) {
      setYearSelected(value);

      if (!isPerformance) {
        console.log(123);
        dispatch(
          getStudentSBRData({
            classroomId:
              value === 1
                ? management?.studentDetails?.performance?.classroom_ids[0]
                    ?.classroom_id
                : management?.studentDetails?.previous_year_data?.performance
                    ?.classroom_ids[0]?.classroom_id,
            studentId:
              value === 1
                ? studentId
                : management?.studentDetails?.student?.previous_year_id,
            previousYear: value === 1 ? false : true,
          })
        );
      }
    } else if (type === 3) {
      setIsPerformance(value !== 2);

      if (value === 1) {
      } else {
        dispatch(
          getStudentSBRData({
            classroomId:
              yearSelected === 1
                ? management?.studentDetails?.performance?.classroom_ids[0]
                    ?.classroom_id
                : management?.studentDetails?.previous_year_data?.performance
                    ?.classroom_ids[0]?.classroom_id,
            studentId:
              yearSelected === 1
                ? studentId
                : management?.studentDetails?.student?.previous_year_id,
            previousYear: value === 1 ? false : true,
          })
        );
      }
    }
  };

  let classrooms: any = {};
  management?.studentDetails?.performance?.tests &&
    Object.keys(management?.studentDetails?.performance?.tests).map(
      (item: any, i: number) => {
        if (management?.studentDetails?.performance?.tests[item].length > 0) {
          management?.studentDetails?.performance?.tests[item].map(
            (sub: any) => {
              if (sub.summative === true) {
                if (item in classrooms && classrooms[item]["summatives"]) {
                  classrooms[item]["summatives"].push(sub);
                } else {
                  classrooms = {
                    ...classrooms,
                    [item]: { ...classrooms[item], summatives: [sub] },
                  };
                }
              } else {
                if (item in classrooms && classrooms[item]["formatives"]) {
                  classrooms[item]["formatives"].push(sub);
                } else {
                  classrooms = {
                    ...classrooms,
                    [item]: { ...classrooms[item], formatives: [sub] },
                  };
                }
              }
            }
          );
        } else {
          classrooms = {
            ...classrooms,
            [item]: { summatives: [], formatives: [] },
          };
        }
      }
    );

  Object.keys(classrooms).map((cls) => {
    const sortDataSummative = sortInfo.find(
      (item: any) => item.className === cls && item.isSummative === true
    );
    const sortDataFormative = sortInfo.find(
      (item: any) => item.className === cls && item.isSummative === false
    );
    classrooms[cls].summatives = orderBy(
      classrooms[cls]["summatives"],
      [(sum) => moment(sum?.date)],
      [
        sortDataSummative
          ? sortDataSummative.orderBy === "DESC"
            ? "desc"
            : "asc"
          : "asc",
      ]
    );

    classrooms[cls].formatives = orderBy(
      classrooms[cls]["formatives"],
      [(sum) => moment(sum?.date)],
      [
        sortDataFormative
          ? sortDataFormative.orderBy === "DESC"
            ? "desc"
            : "asc"
          : "asc",
      ]
    );
  });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `StudentDetailsReport_${studentName}_${moment(
      new Date()
    ).format("M/DD/YY hh:mmA")}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      print && window.close();
    },
  });

  const handlePrintSBR = useReactToPrint({
    content: () => componentSBRRef.current,
    documentTitle: `StudentDetailsReport_${studentName}_${moment(
      new Date()
    ).format("M/DD/YY hh:mmA")}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      print && window.close();
    },
  });

  useEffect(() => {
    if (classrooms && print) {
      handlePrint();
    }
  }, [classrooms, print]);

  const printFunc = (option: string, value: number) => {
    if (value === 1) {
      handlePrint();
    } else {
      handlePrintSBR();
    }
  };

  const handlEnroll = async (class_id: any) => {
    const res = await checkTestInProgress(
      Number(studentId),
      Number(class_id || 0)
    );
    setHasTested(!!res);
    setUnenrollModal(true);
  };

  const formatStudentName = (student_name: string) => {
    const nameArray = student_name?.split(", ");
    if(nameArray.length){
      return `${nameArray[nameArray.length-1]}${nameArray.length > 1 ? ` ${nameArray[0]}` : ''}`
    }

    return "";
  }

  let subject = 2;

  const classroomKeys = Object.keys(classrooms);

  if (classroomKeys[0]?.indexOf("Math") > -1) {
    subject = 1;
  } else if (classroomKeys[0]?.indexOf("Science") > -1) {
    subject = 3;
  }

  const printPerformance = ()=>{
    if(management?.studentDetails?.performance)
    return {...management?.studentDetails?.performance, variants: assessmentVariants, scienceEnabled:scienceEnabled }
  }

  return (
    <>
      {!print && (
        <div className="hide-on-print">
          {userRole !== "Classroom Admin" && <Breadcrumbs page="" />}
          <div className="accordion__header school-detail-header open cursor-default accordion__header-noBorderBottom">
            <div className="accordion__header-heading">
              <BackButton />
              <h6>
                Student:{" "}
                {management && management?.studentDetails?.student?.first_name}{" "}
                {management?.studentDetails?.student?.last_name}
                {/* <span>Student: </span>
            <span> {management && management?.studentDetails?.student?.first_name}</span>
            <span> {management?.studentDetails?.student?.last_name}</span> */}
              </h6>
              <img
                onClick={() =>{
                  doSetUpdateModal(management?.studentDetails?.student)
                }}
                src={EditIcon}
                alt="arrow"
                className="ms-4 cursor-pointer"
              />
            </div>
            <div className="school-detail-btns">
              <div className="accordion__header-buttons">
                {userRole !== "Classroom Admin" && (
                  <div className="accordion__header-btnContainer">
                    <button
                      onClick={() =>
                        doSetDelModal(management?.studentDetails?.student)
                      }
                      className="btn outline-btn outline-btn-lg"
                    >
                      Delete
                    </button>
                  </div>
                )}
                {userRole === "Super Admin" && (
                  <div className="accordion__header-btnContainer">
                    <button
                      className="btn outline-btn outline-btn-lg"
                      onClick={() =>
                        doSetMergeModal(management?.studentDetails?.student)
                      }
                    >
                      Merge
                    </button>
                  </div>
                )}
                {roles.includes(userRole || "") && (
                  <div className="accordion__header-btnContainer">
                    <button
                      className="btn outline-btn outline-btn-lg"
                      onClick={() =>
                        doSetTransferModal(management?.studentDetails?.student)
                      }
                    >
                      Transfer
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="">
            {userRole === "Classroom Admin" && (
              <section className="accordion__student accordion__student_box-radius">
                <div className="accordion__student-item">
                  <div className="accordion__student-status">
                    <p>
                      <b>Student ID:</b>
                    </p>
                  </div>

                  <p>
                    {management?.studentDetails?.student?.school_student_id}
                  </p>
                </div>
                <div className="accordion__student-item">
                  <div className="accordion__student-status">
                    <p>
                      <b>Last Login:</b>
                    </p>
                  </div>

                  <p>
                    {management?.studentDetails?.student?.last_login
                      ? moment(
                          management?.studentDetails?.student?.last_login
                        ).format("M-D-YY")
                      : ""}
                  </p>
                </div>
              </section>
            )}
          </div>
          <div className="open cursor-default">
            <StudentHeader
              performance={
                yearSelected === 2
                  ? management?.studentDetails?.previous_year_data?.performance
                  : management?.studentDetails?.performance
              }
              students={[]}
              triggerUpdate={triggerUpdate}
              performanceEnabled={isPerformance}
              showLastThreeAttempts={showLastThreeAttempts}
              setShowLastThreeAttempts={setShowLastThreeAttempts}
              showAllClasses={false}
              setShowAllClasses={() => {}}
              triggerPrint={printFunc}
              studentDetail={userRole === "Classroom Admin" ? false : true}
              studentData={management?.studentDetails?.student}
              subject={subject}
              isPrev={yearSelected === 1 ? false : true}
              setSelectedPerformance={setSelectedPerformance}
              selectedPerformance={selectedPerformance}
              prevYearExists = {prevYearExists}
              isStudentDetail = {true}
             
            ></StudentHeader>
          </div>

          {isPerformance ? (
            <>
              <Performance
                performance={yearSelected === 2
                  ? management?.studentDetails?.previous_year_data
                      ?.performance
                  : {...management?.studentDetails?.performance, variants: assessmentVariants, scienceEnabled: scienceEnabled } 
                }
                classrooms={classrooms}
                handleSortClick={handleSortClick}
                getClassName={getClassName}
                skeletonLoad={skeletonLoad}
                showLastThreeAttempts={showLastThreeAttempts}
                showAllClasses={true}
                performanceSets={yearSelected === 2
                  ? management?.studentDetails?.previous_year_data
                      ?.performance
                  : management?.studentDetails?.performance}
                isPrev={yearSelected === 1 ? false : true}
                handlEnroll={handlEnroll}
                setClassId={setClassId}
                showActions
              />
              {/* <StudentPerformanceDetail
                performance={
                  yearSelected === 2
                    ? management?.studentDetails?.previous_year_data
                        ?.performance
                    : management?.studentDetails?.performance
                }
                handlEnroll={handlEnroll}
                setClassId={setClassId}
                handleSortClick={handleSortClick}
                getClassName={getClassName}
                skeletonLoad={skeletonLoad}
                classrooms={
                  yearSelected === 1 ? classrooms : classrooms_previous
                }
                isPrev={yearSelected === 1 ? false : true}
                showLastThreeAttempts={showLastThreeAttempts}
                scienceEnabled = {scienceEnabled}
                variants = {assessmentVariants}
              /> */}
            </>
          ) : (
            studentSBRData?.map((studentSBR: any, index: number) => {
              const showSBR = studentSBR?.classroom_name?.indexOf("Science") >-1 ? scienceEnabled : true
              if (showSBR) {
                return (
                  <StandardBasedReport
                    key={index}
                    isLoading={skeletonLoad}
                    detailsRef={detailsRef}
                    data={studentSBR}
                    setDescriptionRowsHeights={setDescriptionRowsHeights}
                    lang="en"
                  />
                );
              }else {
                return <></>
              }
            })
          )}

          <StudentDeleteModal
            loading={management.studentDeleteLoading}
            isShow={deleteModal}
            headerText={`Delete ${currentItem?.first_name} ${currentItem?.last_name} `}
            bodyText={`Would you like to delete this student entirely from the district?`}
            onCancel={() => setDeleteModal(false)}
            onDelete={(type) => onDeletStudent(type)}
          />
          {updateModal && (
            <StudentUpdateModal
              showSwitch={false}
              isShow={updateModal}
              first_name={currentItem?.first_name}
              last_name={currentItem && currentItem.last_name}
              std_id={currentItem && currentItem.school_student_id}
              notes={currentItem && currentItem.notes}
              classroom_id={Number(classId)}
              onCancel={() => setUpdateModal(false)}
              onUpdate={(data) => onUpdateStudent(data)}
              loading={management.studentUpdateLoading}
              serverError={management.studentUpdateError}
            />
          )}
          {unenrollModal && (
            <UnroleModel
              isShow={unenrollModal}
              onClose={() => setUnenrollModal(false)}
              onSave={unenrollStudent}
              currentData={classId}
              hasTested={hasTested}
              studentName={studentName}
              loading={loading}
            />
          )}
          {MergeStudentModal && (
            <MergeStudentModal
              isShow={mergeStudentModal}
              onClose={() => setMergeStudentModal(false)}
              onMerge={onMergeStudent}
              onBeforeMerge={onBeforeMergeStudent}
              name={`${currentItem?.first_name} ${currentItem?.last_name}`}
              studentId={currentItem?.school_student_id}
            />
          )}
          {TransferStudentModal && (
            <TransferStudentModal
              isShow={transferStudentModal}
              onClose={() => setTransferStudentModal(false)}
              onTransfer={onTransferStudent}
              name={`${currentItem?.first_name} ${currentItem?.last_name}`}
              studentId={currentItem?.school_student_id}
              studentClasses={
                management?.studentDetails?.performance?.tests
                  ? Object.keys(management?.studentDetails?.performance?.tests)
                  : []
              }
              studentAssessments={
                management?.studentDetails?.performance?.tests
                  ? management?.studentDetails?.performance?.tests
                  : {}
              }
              studentDbId={management?.studentDetails?.student?.id}
            />
          )}
          {TransferSuccessModal && (
            <TransferSuccessModal
              isShow={transferSuccessModal}
              onClose={() => {
                setTransferSuccessModal(false);
                dispatch(getStudentDetail(studentId));
              }}
              info={transferInfo}
            />
          )}
        </div>
      )}
      {selectedPerformance?.value === "performance" && (
        <div ref={componentRef} className="printData">
          {/* <StudentDetailsPrint
            classrooms={yearSelected === 1 ? classrooms : classrooms_previous}
            student_id={studentId}
            yearSelected={yearSelected}
            subject={subject}
            isPrev={yearSelected === 1 ? false : true}
            schoolName={management?.studentDetails?.breadcrumb?.school_name || ""}
            variants = {assessmentVariants}
            scienceEnabled = {scienceEnabled}
            showLastThreeAttempts = {showLastThreeAttempts}
          /> */}
          <StudentPerformancePrint
            classrooms={yearSelected === 1 ? classrooms : classrooms_previous}
            performance={
              yearSelected === 2
              ? management?.studentDetails?.previous_year_data?.performance
              : printPerformance() 
              //:management?.studentDetails?.performance
            }
            studentData={management?.studentDetails?.student}
            performanceHeaderData={management?.studentDetails?.performance}
            showLastThreeAttempts={showLastThreeAttempts}
            subject={subject}
            isPrev={yearSelected === 2 ? true : false}
            school_name={management?.studentDetails?.breadcrumb?.school_name || ""}
            isSingle = {true}
          />
        </div>
      )}
      {selectedPerformance?.value === "standard-based" && descriptionRowsHeights && studentSBRData.length > 0 && (
        <div ref={componentSBRRef} className="printData">
          {studentSBRData?.map((studentSBR: any, index: number) => {
             const showSBR = studentSBR?.classroom_name?.indexOf("Science") >-1 ? scienceEnabled : true
            const student_formatted_name = formatStudentName(studentSBR.student_name)
            if(showSBR){
            return (
              <div
                key={index}
                className={`printData ${index && "add-page-break"}`}
              >
                <BasedReportPrint
                  data={studentSBR.objectives}
                  classroom_name={studentSBR?.classroom_name}
                  lang={"en"}
                  test_name={`Student: ${studentSBR.student_name}`}
                  answerRowsHeights={100}
                  school_name={
                    management?.studentDetails?.breadcrumb?.school_name || ""
                  }
                  student_name={student_formatted_name}
                />
              </div>
            )}else {
              return <></>
            }
          })}
        </div>
      )}
    </>
  );
};

export default StudentDetails;
