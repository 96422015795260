import axios from "util/axios";
import config from "config/config";
import { AllMonths, DeletePacingCalenderAssessment, PacingAssessments, PutSuggestedAssessment, SuggestedAssessment } from "interfaces/pacingCalender";
import { toast } from "react-toastify";

export const getPacingCalenderScheduledAssessments = async ({ classroomId, date, order }: { classroomId: string, date: string, order: string }) => {
    try {
        let response = await axios.get(
            `${config.defaults.api_url}/api/v3/pacing-calendar/classroom/${classroomId}/objectives?date=${date}${order ? `&order_by=${order}` : ''}`,
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return response.data.data
    } catch (error) {
        console.log("Error in getScheduledAssessments : ", error)
    }
}

export const postPacingCalender = async (payload: { classroomId: string, scheduled_date: string, summative_id: number, formative_id: number }): Promise<PutSuggestedAssessment[]> => {
    const { classroomId, ...otherPayload } = payload;
    try {
        let response = await axios.post(
            `${config.defaults.api_url}/api/v3/pacing-calendar/classroom/${classroomId}`,
            { ...otherPayload },
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            },

        );
        return response.data.data;
    } catch (e) {
        console.log(e);
        return null!
    }

}

export const putSuggestedPacingCalender = async ({ classroomId, month, year }: { classroomId: string, month: string, year: string }): Promise<PacingAssessments> => {
    try {
        let response = await axios.put(
            `${config.defaults.api_url}/api/v3/pacing-calendar/classroom/${classroomId}/suggested?month=${month}&year=${year}`,
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return response.data.data;
    } catch (e: any) {
        console.log(e);
        e?.response?.data?.message && toast.error(e.response.data.message);
        return null!
    }

}

export const getPacingCalenderSuggestedAssessments = async ({ subjectId, gradeId, month }: { subjectId: string, gradeId: string, month: string }): Promise<SuggestedAssessment[]> => {
    try {
        let response = await axios.get(
            `${config.defaults.api_url}/api/v3/pacing-calendar/suggested?subject_id=${subjectId}&grade_id=${gradeId}&month=${month}`,
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return response.data.data.suggested_pacing_calendar;
    } catch (e) {
        console.log(e);
        return null!
    }

}

export const deleteSuperAdminPacingCalenderAssessments = async ({ assessmentId, gradeId, subjectId, month }: { assessmentId?: number, gradeId: number, subjectId: number, month: string }): Promise<DeletePacingCalenderAssessment> => {
    try {
        let response = await axios.delete(
            `${config.defaults.api_url}/api/v3/pacing-calendar/suggested?month=${month}${assessmentId !== undefined ? `&assessment_id=${assessmentId}` : ''}&grade_id=${gradeId}&subject_id=${subjectId}`,
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return response.data.data
    } catch (e: any) {
        console.log(e);
        e?.response?.data?.http_code === 404 && toast.error(e.response.data.message);
        return null!
    }

}

export const deletePacingCalenderAssessments = async ({ assessmentId, classroomId, month }: { assessmentId?: number, classroomId: string, month: string }): Promise<DeletePacingCalenderAssessment> => {
    try {
        let response = await axios.delete(
            `${config.defaults.api_url}/api/v3/pacing-calendar/classroom/${classroomId}?month=${month}${assessmentId !== undefined ? `&assessment_id=${assessmentId}` : ''}`,
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return response.data.data
    } catch (e) {
        console.log(e);
        return null!
    }

}

export const getPacingCalenderMonths = async ({ classroomId }: { classroomId: string | null }): Promise<AllMonths> => {
    try {
        let response = await axios.get(
            `${config.defaults.api_url}/api/v3/pacing-calendar/months${classroomId ? '?classroom_id=' + classroomId: ''}`,
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return response.data.data
    } catch (e) {
        console.log(e);
        return null!
    }

}

export const getPacingCalenderAssessments = async ({ month, year, classroomId, search }: { month: string, year: number, classroomId: string, search: string }): Promise<any> => {
    try {
        let response = await axios.get(
            `${config.defaults.api_url}/api/v3/pacing-calendar/classroom/${classroomId}?month=${month}&year=${year}&search=${search}`,
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return response.data.data
    } catch (e) {
        console.log(e);
        return null!
    }

}

export const getPacingCalenderObjectives = async ({ subjectId, gradeId, date, order }: { subjectId: number, gradeId: number, date: string, order: string }) => {
    try {
        let response = await axios.get(
            `${config.defaults.api_url}/api/v3/pacing-calendar/subject/${subjectId}/grade/${gradeId}/objectives?date=${date}${order ? `&order_by=${order}` : ''}`,
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return response.data.data
    } catch (error) {
        console.log("Error in getScheduledAssessments : ", error)
    }
}

// SuperAdmin
export const getPacingCalenderDates = async () => {
    try {
        let response = await axios.get(
            `${config.defaults.api_url}/api/v3/pacing-calendar/dates`,
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return response.data.data
    } catch (error) {
        console.log("Error in getPacingCalenderDates : ", error)
    }
}

export const getPacingGradesSubjects = async (resourse: string) => {
    try {
        let { data: { data } } = await axios.get(
            `${config.defaults.api_url}/api/v3/admin/grades/subjects?resource=true`,
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return data
    } catch (error) {
        console.log("Error in getPacingGradeSubjects : ", error)
    }
}

export const editPacingDates = async (payload: {date_id: number, title: string}) => {
    const {date_id, title} = payload;
    try {
        let { data: { data } } = await axios.put(
            `${config.defaults.api_url}/api/v3/pacing-calendar/dates/${date_id}`, {title},
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return data
    } catch (err) {
        console.log("Error in postPacingCalenderDates : ", err)
    }
}

export const postPacingDates = async (payload: object) => {
    try {
        let { data: { data } } = await axios.post(
            `${config.defaults.api_url}/api/v3/pacing-calendar/dates`, payload,
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return data
    } catch (err) {
        console.log("Error in postPacingCalenderDates : ", err)
    }
}

export const deletePacingDates = async (dateId: number) => {
    try {
        let { data: { data } } = await axios.delete(
            `${config.defaults.api_url}/api/v3/pacing-calendar/dates/${dateId}`,
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return data
    } catch (err) {
        console.log("Error in deletePacingDates : ", err)

    }
}

export const getSuperAdminSuggestedPacing = async (payload: {
    gradeId: any,
    subjectId: any,
    month: string,
    year: number
}) => {
    const {
        gradeId,
        subjectId,
        month,
        year
    } = payload
    try {
        let { data: { data } } = await axios.get(
            `${config.defaults.api_url}/api/v3/pacing-calendar/suggested/grade/${gradeId}/subject/${subjectId}?month=${month}&year=${year}`,
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return data.suggested_pacing_calendar
    } catch (err) {
        console.log("Error in deletePacingDates : ", err)

    }
}

export const enableAssessment = async (classroomId: any, body: any) => {
    try {
        let response = await axios.put(
            `${config.defaults.api_url}/api/v3/pacing-calendar/classroom/${classroomId}`,
            body,
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return response.data.data;
    } catch (e) {
        console.log(e);
        return null!
    }

}

export const enableAllMathClasses = async (classroomId: any, sharing_schedule: boolean) => {
    try {
        let response = await axios.put(
            `${config.defaults.api_url}/api/v3/pacing-calendar/classroom/${classroomId}`,
            {sharing_schedule},
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return response.data.data;
    } catch (e) {
        console.log(e);
        return null!
    }

}

export const postSuperAdminSuggestedPacing = async (payload: object) => {
    try {
        let { data: { data } } = await axios.post(
            `${config.defaults.api_url}/api/v3/pacing-calendar/suggested`, payload,
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return data
    } catch (err) {
        console.log("Error in postPacingCalenderDates : ", err)
    }
}