/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import CustomModal from "../Modal";

interface SuccessModalProps {
  isShow: boolean;
  headerText: string;
  bodyText: string;
  closeModal: () => void;
  buttonText?: string;
  btnCustomClass?: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  isShow,
  headerText,
  bodyText,
  closeModal,
  buttonText,
  btnCustomClass
}) => {
  return (
    <CustomModal centered show={isShow} onHide={closeModal} onEscapeKeyDown={closeModal}>
      <div className="modal__header">
        <h6 className="modal__header-title">{headerText}</h6>
      </div>
      <div className="modal__body">
        <p>{bodyText}</p>
      </div>
      <div className="modal__footer">
        <button
          className={`btn ${btnCustomClass || 'success-btn'} mx-2`}
          onClick={closeModal}
          
        >
          {buttonText || 'Got it'}
        </button>
      </div>
    </CustomModal>
  );
};

export default SuccessModal;