import Discussions from "components/discussions";
import Theme from "components/theme";
import DiscussionsProvider from "contexts/discussions";

const Discussion = () => {
  
  return (
    <Theme>
      <DiscussionsProvider>
        <div className="mt-8 w-100">
          <Discussions />
        </div>
      </DiscussionsProvider>
    </Theme>
  );
};

export default Discussion;
