/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSchoolProgressReport,
  resetSchoolProgressReport,
} from "redux/actionCreators/activity"
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { SchoolProgressSkeleton } from "components/common/skeleton/GraphSkeleton";
import Theme from "components/theme/index";
import { RootState } from "redux/reducers/combine";
import { SchoolProgressChart } from "../graphs/SchoolProgressChart";
import SchoolProgressReportPrint from "./SchoolProgressReportPrint";
import { ReactComponent as LinePlotIcon } from "assets/img/line-plot-key.svg";
import { useQuery } from "hooks/useQuery";
import BackButton from "components/common/backButton";
import PrintButton from "./printButton";

const SchoolProgressReport = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id }: any = useParams();
  const componentRef = useRef(null);
  const { schoolProgressReport, schoolsList } = useSelector((state: RootState) => state).activity;
  const school = schoolsList?.find((item: any) => item.id === Number(id))
  const query = useQuery();
  const print = query.get("print")
  

  useEffect(() => {
    dispatch(getSchoolProgressReport(id));
    return () => {
      dispatch(resetSchoolProgressReport());
    };
  }, []);


  useEffect(() => {
    if (print) {
      setTimeout(() => {
        handlePrint()
      }, 2000)
    }
  }, [schoolProgressReport, print]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timeout);
      setIsLoading(true);
    }
  }, []);

  const backButton = (e: any) => {
    e.preventDefault();
    navigate("/activity");
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `SchoolProgressReport_${school?.school_name}_${moment(
      new Date()
    ).format("M/DD/YY hh:mmA")}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      print && window.close()
    }
  });


  

  return (
    <>
      {
        !print &&
        <Theme>
          <div className="report hide-on-print">
            <div className="report__header">
              <div className="d-flex align-items-center">
                <BackButton onClick={backButton} />
                <div>
                  <h3 className="report__header-title">Formative Progress Report</h3>
                </div>
              </div>
              <PrintButton handlePrint={handlePrint} />
            </div>
            <div className="report-content">
              <div className="report__performance">
                <div className="report__performance-average">
                  <div className="report__performance-row">
                    <div className="d-flex align-items-center">
                      <div className="report__performance-avg averagep"></div>
                      <p>Average Proficiency for Math</p>
                    </div>
                  </div>
                  <div className="report__performance-row">
                    <div className="d-flex align-items-center">
                      <div className="report__performance-avg averagep ELA"></div>
                      <p>Average Proficiency for ELA</p>
                    </div>
                  </div>
                  <div className="report__performance-row">
                    <div className="d-flex align-items-center">
                      <div className="report__performance-avg averageo"><LinePlotIcon /></div>
                      <p>Average Number of OAS Objectives Assessed Per Class</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="report__chart">
                <div className="report__chart-container">
                  {!isLoading && schoolProgressReport ? <SchoolProgressChart data={schoolProgressReport} /> : <SchoolProgressSkeleton columns={9} width={'100%'} height={506} customClass={'report__chart-skelton-item'} />}
                </div>
              </div>
            </div>
          </div>
        </Theme>
      }
      <div ref={componentRef} className="printData">
        <SchoolProgressReportPrint key={schoolProgressReport} data={schoolProgressReport} school={school} />
      </div>
    </>
  );
};
export default SchoolProgressReport;
