import HtmlParser from "components/common/htmlParser";
import { useTitleHeight } from "hooks/useTitleHeight";
import { useRef } from "react";

const TaskTitle = ({name}: any) => {
    const taskNameRef = useRef<any>();
    const addClass = useTitleHeight(name, taskNameRef);
    return (
        <p ref={taskNameRef} className={`${addClass ? 'text-adjust' : ''}`}>
            <HtmlParser html={name} />
        </p>
    )
};

export default TaskTitle;
