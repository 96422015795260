import { ChatBoxContextProvider } from "interfaces/chatBox"
import { createContext, useReducer } from "react"
import { getSupportChats, postChat } from "services/suppot-ticket"
import { ChatBoxReducer, ChatBoxActions } from "./reducers/ChatBoxs";

const initialState: ChatBoxContextProvider = {
    setSupportChatStatus: null!,
    setSupportChatStatus_id: null!,
    setChatBoxMessage: null!,
    fetchSupportChat: null!,
    supportChat: {
        status_id: null!,
        status: '',
        active: '',
        messages: [],
        messages_count: 0,
        thread_id: 0,
        unclaimed: null!,
        created_at: ''
    },
    sendUserMessage: null!,
    unsetSupportChat: null!,
    chatBoxMessage: null!
}

export const ChatBoxContext = createContext<ChatBoxContextProvider>(null!)


const ChatBoxProvider = ({ children }: any) => {
    const [{ supportChat, chatBoxMessage }, dispatch] = useReducer(ChatBoxReducer, initialState);
    // const socketInstance = Socket.getInstance();

    const setChatBoxMessage = (message: any) => {
        dispatch({
            type: ChatBoxActions.SET_CHATBOX_MESSAGE,
            payload: message
        });
    }

    const sendUserMessage = async (message: string, messageData: any) => {
        dispatch({
            type: ChatBoxActions.SET_CHAT_MESSAGE,
            payload: {messageData, isReplaceMessage: true}
        });

        const chat = await postChat({ message });

        if(!chat){
            dispatch({
                type: ChatBoxActions.RESET_CHAT_MESSAGE,
                payload: messageData
            })
            return null;
        }
        
        dispatch({
            type: ChatBoxActions.SET_CHAT_MESSAGE,
            payload: {messageData, chat}
        });
        return chat;
    }

    const setSupportChatStatus = (status: string) => {
        dispatch({
            type: ChatBoxActions.SET_SUPPOERT_CHAT_STATUS,
            payload: status
        })
    }

    const setSupportChatStatus_id = (status_id: number) => {
        dispatch({
            type: ChatBoxActions.SET_SUPPORT_CHAT_STATUS_ID,
            payload: status_id
        })
    }

    const fetchSupportChat = async ({ limit, offset, chatId }: { limit: number, offset: number, chatId?: number }) => {
        const tickets = await getSupportChats(limit, offset, chatId);

        if(!tickets){
            return null;
        }

        dispatch({
            type: ChatBoxActions.SET_SUPPORT_CHAT,
            payload: {tickets, offset}
        });

        return tickets;
    }

    const unsetSupportChat = () => {
        dispatch({
            type: ChatBoxActions.UNSET_SUPPORT_CHAT
        })
    }

    // const setSingleChatBox = (ticket: ChatBox) => {
    //     dispatch({
    //         type: ChatBoxActions.SET_SINGLE_SUPPORT_TICKET,
    //         payload: ticket
    //     })
    // }

    return <ChatBoxContext.Provider value={{
        setSupportChatStatus,
        setSupportChatStatus_id,
        setChatBoxMessage,
        fetchSupportChat,
        supportChat,
        sendUserMessage,
        unsetSupportChat,
        chatBoxMessage
    }}>
        {children}
    </ChatBoxContext.Provider>
}
export default ChatBoxProvider