import { useLocation } from 'react-router-dom';
import SupportChatIcon from "components/common/supportChat";
import SocketChatProvider from 'contexts/socketContext';

const ChatIconWrapper = () => {
    const { pathname } = useLocation();

    return (
        pathname !== '/login' &&  pathname !== '/' && pathname !== '/reset-password' && !pathname.includes('cms') && !pathname.includes('preview') ? (
            <SocketChatProvider>
                <SupportChatIcon />
            </SocketChatProvider>
        ) : (
            <></>
        )
    )
}

export default ChatIconWrapper