import useStopwatch from "hooks/useStopWatch";

const MyTime = ({date, autoStart, endDate = null}: any) => {
  let stopwatchOffset = new Date();
  const createdDate = new Date(date);

  if (endDate !== null) {
    stopwatchOffset = new Date(endDate);
  } 
  
  const timeDiffMs = Math.abs(stopwatchOffset.getTime() - createdDate.getTime());
  const timeDiffSeconds = Math.round(timeDiffMs/1000);

  stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + timeDiffSeconds);
  

  const {
    seconds,
    minutes
  } = useStopwatch({ autoStart: autoStart !== undefined ? autoStart : true, offsetTimestamp: stopwatchOffset });

  const secondTime: any = seconds < 10 ? `0${seconds}` : `${seconds}`;
  let minuteTime: any = minutes < 10 ? `0${minutes}` : `${minutes}`;
  let hours: any = '';
  let hour: any = '';
  
  if(minutes >= 60) {
    hour = +Math.floor(minutes / 60);
    hours =  `${parseInt(hour)}` ;
    minuteTime = minutes % 60 ? `:${Math.floor(minutes % 60)}` : '';
  }


  return (
    <div>
      <div>
        <span>{hours}{minuteTime}</span>
        {/* <span>
          {(hours.length === 1 || hours.toString().charAt(0) === '0') ? hours.toString().substring(1)+'h' : hours}
          {(minuteTime.length === 1 || minuteTime.toString().charAt(0) === '0') ? minuteTime.toString().substring(1)+'m' : minuteTime}
        </span> */}
        <span>{hours.trim() < 1 ? `:${secondTime}`  : ''}</span>
      </div>
    </div>

  );

};

export default MyTime;