/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Alert } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import Warning from "assets/img/warning-icon.png";
import {
  uploadCsv,
  cancelCsv,
  importData,
} from "redux/actionCreators/district-csv-import";
import SuccessModal from "../SuccessModal";
import BackButton from "components/common/backButton";

interface RootState {
  districtCsvImport: any;
}

const CsvFileUploader = () => {
  const dispatch = useDispatch();
  const { districtCsvImport } = useSelector((state: RootState) => state);
  const [validationError, setValidationError] = useState<string>("");
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [districtData, setData] = useState<any>(null);

  const onDrop = useCallback((acceptedFiles: any) => {
    setValidationError("");
    dispatch(uploadCsv(acceptedFiles[0]));
  }, []);

  useEffect(()=>{
    return(()=>{
      dispatch(cancelCsv())
    })
  },[])

  useEffect(() => {
    let errorsData =
      districtCsvImport &&
      districtCsvImport?.csvResults &&
      districtCsvImport?.csvResults?.filter(
        (result: any) =>
          result.error ||
          result.admins?.filter((admin: any) => admin.error).length
      );

    if (
      errorsData &&
      errorsData.length &&
      !districtCsvImport.importDataSuccess
    ) {
      setValidationError("Still some errors please resolve");
    } else {
      setValidationError("");
      setData(districtCsvImport.district_info);
      setIsSuccess(districtCsvImport.importDataSuccess);
    }
  }, [districtCsvImport]);

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop,
  });

  const doImportData = () => {
    setValidationError("");
    let errorsData = districtCsvImport?.csvResults?.filter(
      (result: any) =>
        result.error ||
        result.admins?.filter((admin: any) => admin.error).length
    );

    if (errorsData?.length) {
      setValidationError("Still some errors please resolve");
    } else {
      dispatch(
        importData(districtCsvImport.csvResults, districtCsvImport.csvUrl)
      );
    }
  };
  return (
    <>
      <div className="accordion__header open taller district-csv-upload cursor-default">
        <div className="accordion__header-heading">
          <BackButton />
          <h6>District CSV Upload</h6>
        </div>
        <div className="district-csv-btns">
          <div className="accordion__header-buttons">
            <div className="accordion__header-btnContainer">
              <a href="/district_template.csv" download>
                <button className="btn outline-btn outline-btn-lg">
                  Download Template
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="accordion__content border-radious-bottom">
          {districtCsvImport?.csvUploadError ? (
            <Alert variant="danger" className="text-center">
              {districtCsvImport?.csvUploadError}
            </Alert>
          ) : (
            ""
          )}
          {!districtCsvImport.csvUploadSuccess ? (
            <div className="uploads">
              <div
                {...getRootProps({ className: "uploads__content bordered" })}
              >
                <input {...getInputProps()} />
                <h6 className="uploads__content-title">Upload District CSV</h6>
                <p className="uploads__content-paragraph">
                  Drag a file here or select Choose File below to upload CSV for
                  review.
                </p>
                <button
                  disabled={districtCsvImport.csvLoading}
                  className="btn success-btn btn-medium uploads__content-choose"
                  type="button"
                  onClick={open}
                >
                  { districtCsvImport.csvLoading ? 
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                   : 'Choose File'
                  }
                </button>
              </div>
            </div>
          ) : (
            <div className="uploads animated-fade">
              <div className="uploads__content">
                <h6 className="uploads__content-title">
                  {districtCsvImport?.csvName}
                </h6>
                {validationError && (
                  <p className="uploads__content-paragraph">
                    <img src={Warning} alt="Warning icon" />
                    There are errors below. These must be resolved before Import
                    is enabled.
                  </p>
                )}

                <div className="uploads__content-buttons">
                  <button
                    disabled={districtCsvImport.importDataLoading}
                    onClick={() => dispatch(cancelCsv())}
                    className="btn cancel-btn btn-medium"
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={
                      districtCsvImport.importDataLoading ||
                      validationError !== ""
                    }
                    onClick={doImportData}
                    className="btn success-btn btn-medium"
                    type="button"
                  >
                   {districtCsvImport.importDataLoading ? 
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> : 
                      'Import'
                    }
                  </button>
                </div>
                {/* <p className="text-danger">{validationError}</p> */}
              </div>
            </div>
          )}
        </div>
      </div>
      {isSuccess && districtData && (
        <SuccessModal
          isShow={true}
          id={districtData?.district_id}
          name={districtData?.district_name}
        />
      )}
    </>
  );
};

export default CsvFileUploader;
