export const isMac = () =>  window.navigator.appVersion.indexOf('Mac') !== -1;

export const isLinux = () => window.navigator.appVersion.indexOf('Linux') !== -1;

export const isWindows = () => window.navigator.appVersion.indexOf('Windows') !== -1;

export const isUnix = () =>  window.navigator.appVersion.indexOf('X11') !== -1;

const SUPS_LIST: any = {
    '0': '⁰',
    '1': '¹',
    '2': '²',
    '3': '³',
    '4': '⁴',
    '5': '⁵',
    '6': '⁶',
    '7': '⁷',
    '8': '⁸',
    '9': '⁹',
    'A': 'ᴬ',
    'B': 'ᴮ',
    'C': 'ᶜ',
    'D': 'ᴰ',
    'E': 'ᴱ',
    'F': 'ᶠ',
    'G': 'ᴳ',
    'H': 'ᴴ',
    'I': 'ᴵ',
    'J': 'ᴶ',
    'K': 'ᴷ',
    'L': 'ᴸ',
    'M': 'ᴹ',
    'N': 'ᴺ',
    'O': 'ᴼ',
    'P': 'ᴾ',
    'Q': 'ᵠ',
    'R': 'ᴿ',
    'S': 'ˢ',
    'T': 'ᵀ',
    'U': 'ᵁ',
    'V': 'ⱽ',
    'W': 'ᵂ',
    'X': 'ˣ',
    'Y': 'ʸ',
    'Z': 'ᶻ',
    'a': 'ᵃ',
    'b': 'ᵇ',
    'c': 'ᶜ',
    'd': 'ᵈ',
    'e': 'ᵉ',
    'f': 'ᶠ',
    'g': 'ᵍ',
    'h': 'ʰ',
    'i': 'ⁱ',
    'j': 'ʲ',
    'k': 'ᵏ',
    'l': 'ˡ',
    'm': 'ᵐ',
    'n': 'ⁿ',
    'o': 'ᵒ',
    'p': 'ᵖ',
    'q': 'ᵠ',
    'r': 'ʳ',
    's': 'ˢ',
    't': 'ᵗ',
    'u': 'ᵘ',
    'v': 'ᵛ',
    'w': 'ʷ',
    'x': 'ˣ',
    'y': 'ʸ',
    'z': 'ᶻ',
    ' ': ' ',
    '+': '⁺',
    '-': '⁻',
    '*': 'ˣ',
    '=': '⁼',
    '(': '⁽',
    ')': '⁾',
    '?': 'ˀ',
    '<': '˂',
    '>': '˃',
    '^': 'ˆ',
    '|': 'ˈ',
    "'": 'ˈ',
    '_': 'ˉ',
    '`': 'ˋ',
    ',': 'ˏ'
}

// ⁺ ⁻ ⁼ ⁽ ⁾ ⁿ ˀ ˁ ˂ ˃ ˄ ˅ ˆ ˇ ˈ ˉ ˊ ˋ ˌ ˍ ˎ ˏ
 
export const superscript = (str: any) => {
    const pattern = /<sup>(.*?)<\/sup>/g;
    let match: any;
    while ( (match = pattern.exec(str)) != null) {
        const originalSupTag = match[0], textPartOfSupTag = match[1];
        const updatedSupText = textPartOfSupTag.split('').map((c:any)=>SUPS_LIST[c]).join('');
        str = str.replace( originalSupTag, updatedSupText);
    }
    return str;
}