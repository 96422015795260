import { FC } from "react";

interface Props {
  student: any;
  index: number;
  isHideRow: boolean;
  level: any;
}

const ProficiencyMap = {
  Advanced: "A",
  BelowBasic: "BB",
  Basic: "B",
  Proficient: "P",
};

type Proficiency = keyof typeof ProficiencyMap;

const ScoreBox: FC<Props> = ({ student, index, isHideRow, level }) => {
  if (!student) {
    return (
      <td
        className={`d-flex master-report__table-marks__data ${
          index % 2 !== 0 && "color537AB7"
        }`}
      >
        <span className="master-report__table-marks__no-value">-</span>
      </td>
    );
  }

  const advancePercent = level.length > 0 ? level[0].percentage : 0;
  const profPercent = level.length > 1 ? level[1].percentage : 0;
  const basicPercent = level.length > 2 ? level[2].percentage : 0;

  const { proficient } = student;
  const proficiencyLevel =
    proficient >= advancePercent
      ? "Advanced"
      : proficient >= profPercent && proficient <= (advancePercent-1)
      ? "Proficient"
      : proficient >= basicPercent && proficient <= (profPercent-1)
      ? "Basic"
      : "BelowBasic";

  return (
    <td
      className={`d-flex master-report__table-marks__data ${
        index % 2 !== 0 && "color537AB7"
      }`}
    >
      <div
        className={`${
          isHideRow ? "d-none" : ""
        } master-report__table-marks__box ${proficiencyLevel} level-box-radius`}
      ></div>
      <span
        className={`${
          isHideRow ? "d-none" : ""
        } master-report__table-marks__value`}
      >{`(${
        ProficiencyMap[proficiencyLevel as Proficiency]
      }) ${proficient}`}</span>
    </td>
  );
};
export default ScoreBox;
