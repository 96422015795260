import React from "react";

const QuestionMarkIcon = ({fill}:{fill: string}) => {
  const titleId = "questioMarkIconId";
  return (
    <svg
      aria-labelledby={titleId}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <title id={titleId}>Review</title>
      <g
        id="question_mark_tooltip_icon"
        data-name="question mark tooltip icon"
        transform="translate(-819 -1130)"
      >
        <g
          id="Path_18"
          data-name="Path 18"
          transform="translate(819 1130)"
          fill="none"
        >
          <path d="M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Z" stroke="none" />
          <path
            d="M 12 1.5 C 6.21027946472168 1.5 1.5 6.21027946472168 1.5 12 C 1.5 17.78972053527832 6.21027946472168 22.5 12 22.5 C 17.78972053527832 22.5 22.5 17.78972053527832 22.5 12 C 22.5 6.21027946472168 17.78972053527832 1.5 12 1.5 M 12 0 C 18.62742042541504 0 24 5.372579574584961 24 12 C 24 18.62742042541504 18.62742042541504 24 12 24 C 5.372579574584961 24 0 18.62742042541504 0 12 C 0 5.372579574584961 5.372579574584961 0 12 0 Z"
            stroke="none"
            fill={fill}
          />
        </g>
        <text
          id="_"
          data-name="?"
          transform="translate(831 1148)"
          fill={fill}
          font-size="16"
          font-family="Nunito-Bold, Nunito"
          font-weight="700"
        >
          <tspan x="-3.672" y="0">
            ?
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default QuestionMarkIcon;
