import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useGetChartData } from 'hooks/useGetChartData';
import { useGetDataValues } from 'hooks/useGetDataValues';
import AnalysisBody from './AnalysisBox/body';
import AnalysisContainer from './AnalysisBox/container';
import AnalysisHeader from './AnalysisBox/header';

ChartJS.register(ArcElement, Tooltip, Legend);

const RunningProficiency = ({runningProficiency}: any) => {

    const {status, text, percentage} = runningProficiency || {}

    const {dataValues, backgroundColor, isNotData} = useGetDataValues({
        percentage
    })
    const ChartData = useGetChartData({dataValues, backgroundColor, isNotData: true});

    return (
        <AnalysisContainer>
            <AnalysisHeader status={status} title="Running Proficiency" />
            
            <AnalysisBody 
                ChartData={ChartData}
                text={text}
                percentage={percentage}
                key={isNotData}
                isImage={isNotData}
            />
        </AnalysisContainer>
    )
};

export default RunningProficiency;
