import { useSelector } from "react-redux"
import { RootState } from "redux/reducers/combine"

const skeletonColorMapper = {
    BLUE: {
        baseColor: "#2a539143",
        highlightColor: "#5371b732"
    },
    GREY: {
        baseColor: "#1A1A1A",
        highlightColor: "#2b2b2b"
    }
}

export const useSkeletonColors = () => {
    const auth = useSelector((state: RootState) => state.auth);
    const selectedTheme = auth.colorProfile?.background_theme || localStorage.getItem("background_theme") || "BLUE"
    return skeletonColorMapper[selectedTheme as keyof typeof skeletonColorMapper];
}