/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spinner, Alert } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import Warning from "assets/img/warning-icon.png";
import {
  uploadCsv,
  cancelCsv,
  importData,
} from "redux/actionCreators/clever-csv-import";
import SuccessModal from "../SuccessModal";

interface RootState {
  cleverCsvImport: any;
}

interface propState {
  districtData : {
    district_id: number;
    district_name: string;
  }
}

const CsvFileUploader = () => {
  const location = useLocation();
  let { districtData} = location.state as propState;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cleverCsvImport } = useSelector((state: RootState) => state);
  const [validationError, setValidationError] = useState<string>("");
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const onDrop = useCallback((acceptedFiles: any) => {
    setValidationError("");
    dispatch(uploadCsv(acceptedFiles[0], districtData.district_id));
  }, []);
 
  useEffect(()=>{
    return(()=>{
      setIsSuccess(false)
      dispatch(cancelCsv())
    })
  },[])

  useEffect(() => {
    let errorsData =
    cleverCsvImport &&
    cleverCsvImport?.csvResults &&
    cleverCsvImport?.csvResults?.filter(
        (result: any) =>
          result.error ||
          result.students?.filter((item: any) => item.studentError).length
      );

    if (errorsData && errorsData.length && !cleverCsvImport.importDataSuccess) {
      setValidationError("Still some errors please resolve");
    } else {
      setValidationError("");
      setIsSuccess(cleverCsvImport.importDataSuccess);
    }
  }, [cleverCsvImport?.csvResults]);

  const { getRootProps, getInputProps, open } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
    onDrop,
  });
  const doImportData = () => {
    setValidationError("");
    console.log("cleverCsvImport.csvResultscleverCsvImport.csvResults",cleverCsvImport.csvResults)
    let errorsData = cleverCsvImport.csvResults.filter(
      (student: any) => student.cleverError || student.studentError
    );
    if (errorsData.length) {
      setValidationError("Still some errors please resolve");
    } else {
      dispatch(importData(cleverCsvImport.csvResults, districtData.district_id));
    }
  };

  const isError = cleverCsvImport?.csvResults?.find((item:any) => item.studentError);

  return (
    <>
      <div className="accordion__header open taller school-detail-header cursor-default">
        <div className="accordion__header-heading">
          <span className="accordion__header-button">
            <img
              onClick={() => navigate(-1)}
              src={ArrowIcon}
              alt="arrow"
              className="rotate-90"
            />
          </span>
          <h6>Student and Clever IDs CSV Upload</h6>
        </div>
        <div className="school-detail-btns">
          <div className="accordion__header-buttons">
            <div className="accordion__header-btnContainer">
              <a href="/clever_template.csv" download>
                <button className="btn outline-btn outline-btn-lg">
                  Download Template
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="accordion__content border-radious-bottom">
          {cleverCsvImport?.csvUploadError ? (
            <Alert variant="danger" className="text-center">
              {cleverCsvImport?.csvUploadError}
            </Alert>
          ) : (
            ""
          )}
          {!cleverCsvImport.csvUploadSuccess ? (
            <div className="uploads">
              <div
                {...getRootProps({ className: "uploads__content bordered" })}
              >
                <input {...getInputProps()} />
                <h6 className="uploads__content-title">
                  Upload Student and Clever IDs CSV
                </h6>
                <p className="uploads__content-paragraph">
                  Drag a file here or select Choose File below to upload CSV for
                  review.
                </p>
                <button
                  disabled={cleverCsvImport.csvLoading}
                  className="btn success-btn btn-medium uploads__content-choose"
                  type="button"
                  onClick={open}
                >
                  { cleverCsvImport.csvLoading ?
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                   : 'Choose File'
                  }
                </button>
              </div>
            </div>
          ) : (
            <div className="uploads">
              <div className="uploads__content">
                <h6 className="uploads__content-title">
                  {cleverCsvImport?.csvName}
                </h6>
                  {validationError || isError ? (<p className="uploads__content-paragraph">
                  <img src={Warning} alt="Warning icon" />
                  There are errors below. These must be resolved before Import
                  is enabled.
                  </p>) : "" }
                <div className="uploads__content-buttons">
                  <button
                    disabled={cleverCsvImport.importDataLoading}
                    onClick={() => dispatch(cancelCsv())}
                    className="btn cancel-btn btn-medium"
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    disabled={cleverCsvImport.importDataLoading || isError}
                    onClick={doImportData}
                    className="btn success-btn btn-medium"
                    type="button"
                  >
                    {cleverCsvImport.importDataLoading ? 
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> : 
                      'Import'
                    }
                  </button>
                </div>
                <p className="text-danger mt-4">{validationError}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {isSuccess && (
        <SuccessModal
          isShow={true}
          id={districtData?.district_id}
          name={districtData?.district_name}
        />
      )}
    </>
  );
};

export default CsvFileUploader;
