/* eslint-disable react-hooks/exhaustive-deps */
import SearchIconGray from "assets/img/search-icon-gray.svg";
import ReportHeader from "components/common/report/header";
import { TableSelectCommon } from "components/common/selectComponent";
import { useEffect, useMemo, useState, useRef } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import {
  getMasterReport,
  resetReportData,
} from "redux/actionCreators/classroom";
import { RootState } from "redux/reducers/combine";
import ReportFooter from "./footer";
import InfoSection from "./infoSection";
import MasterPrint from "./Print";
import Student from "./student";
import {
  MasterHeaderSkeleton,
  MasterBodySkeleton,
} from "components/common/skeleton/ReportsSkeleton";
import { useQuery } from "hooks/useQuery";
import { triggerGoalWithClassroom } from "services/common";
import { GOALS_CONDITIONS } from "util/constants";
import { replaceURL } from "util/replaceUrl";

const options = [
  // { value: 'Grade Frequency and Distribution', label: 'Grade Frequency and Distribution' },
  {
    value: "itemResponseReport",
    label: "Item Response Report",
    disabled: false,
  },
  { value: "Standards at Risk", label: "Standards at Risk", disabled: false },
  { value: "summative", label: "Classroom Summative Report", disabled: false },
  {
    value: "Student Summative Report",
    label: "Student Summative Report",
    disabled: false,
  },
];
const Report = () => {
  const dispatch = useDispatch();
  const report = useSelector(
    (state: RootState) => state.classroom.masterReport
  );
  const location = useLocation();

  const classroomID = useSelector(
    (state: RootState) => state.classroom.classroomId
  );
  const classroomId = location?.state?.classroomId;

  const classroom_id =
    (window as any)["classroomId"] || classroomId || classroomID;

  // const { classroom_id }: any = useParams();
  const [searchParams] = useSearchParams();
  const [isolatedStudent, setIsolatedStudent] = useState<any>(null!);
  const [search, setSearch] = useState<string>("");
  const [dropdownIndex, setdropdownIndex] = useState<number>(null!);
  const [isLoading, setIsLoading] = useState(false);
  const componentRef = useRef(null);
  const firstRender = useRef<any>(true);

  const query = useQuery();
  const print = query.get("print");

  useEffect(() => {
    return () => {
      dispatch(resetReportData("RESET_MASTER_REPORT"));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { students, test_stats, testNames, class_name, level } = useMemo(() => {
    if (report?.data) {
      //// remove this after work
      const students = report.data.students
        .sort((a: any, b: any) => {
          return a.last_name.localeCompare(b.last_name);
        })
        .map((st: any) => ({
          ...st,
          isShowIsolated: !isolatedStudent
            ? null
            : st.id === isolatedStudent.id,
        }));

      const testNamesData = report.data.test_stats;
      setIsLoading(false);
      return {
        ...report.data,
        students: students,
        testNames: _.orderBy(testNamesData, ["test_type"], ["asc"]).map(
          (test: any) => test.test_name
        ),
      };
    }
    return {
      students: [],
      test_stats: [],
      testNames: [],
    };
  }, [report, isolatedStudent]);

  const Students = useMemo(() => {
    if (!students) return;

    if (search) {
      return students.filter((student: any) => {
        return (
          `${student.last_name + student.first_name}`
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          student.id?.toString().includes(search.toLowerCase())
        );
      });
    }
    return students;
  }, [search, students]);

  useEffect(() => {
    const selectedClassroomId =
      (window as any)["classroomId"] ||
      location?.state?.classroomId ||
      classroomID;
    const task_id = searchParams.get("task_id");
    const goal_id = searchParams.get("goal_id");
    if (task_id && goal_id) {
      triggerGoalWithClassroom({
        classroomId: selectedClassroomId,
        conditionName: GOALS_CONDITIONS.Open_Master_Report,
        task_id: parseInt(task_id),
        goal_id: parseInt(goal_id),
      });
    }
    dispatch(getMasterReport(parseInt(selectedClassroomId)));
    setIsLoading(true);
    if (!firstRender.current) {
      replaceURL(`/classroom/report/master-report/${selectedClassroomId}`);
    } else {
      firstRender.current = false;
    }
  }, [dispatch, classroomID, searchParams]);

  const selectReport = (reportName: string, summativeId: number) => {
    if (reportName === "summative") {
      const summativeWindow: any = window.open(
        `/classroom/report/summative/${summativeId}/${classroom_id}`,
        "_blank"
      );
      summativeWindow["classroomId"] = classroom_id;
    }
    // if (reportName === 'Grade Frequency and Distribution') {
    //   window.open(`/classroom/report/grade-frequency-distribution/${classroom_id}`, '_blank')
    // }

    if (reportName === "Standards at Risk") {
      const sarWindow: any = window.open(
        `/classroom/report/standard-at-risk/${classroom_id}/${summativeId}`
      );
      sarWindow["classroomId"] = classroom_id;
    }

    if (reportName === "itemResponseReport") {
      const irrWindow: any = window.open(
        `/classroom/report/item-response-report-summative/${summativeId}/${classroom_id}`,
        "_blank"
      );
      irrWindow["classroomId"] = classroom_id;
    }

    if (reportName === "Student Summative Report") {
      const ssrWindow: any = window.open(
        `/classroom/report/summative/student/${classroom_id}/${summativeId}`,
        "_blank"
      );
      ssrWindow["classroomId"] = classroom_id;
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `MasterReport_${class_name}_${moment(new Date()).format(
      "M/DD/YY hh:mmA"
    )}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      print && window.close();
    },
  });

  useEffect(() => {
    if (report && print) {
      handlePrint();
    }
  }, [report]);

  const onClickStudentName = (student: any) => {
    setIsolatedStudent((prev: any) =>
      !prev ? student : prev.id === student.id ? null! : student
    );
  };

  const tests = useMemo(()=>{
    return report?.data?.test_stats.sort((testA: any, testB:any)=>testA.test_type-testB.test_type)
  },[report])
  return (
    <>
      {!print && (
        <div className="master-report hide-on-print">
          <ReportHeader
            reportTitle="Master Report"
            reportGuide=""
            testName="Summatives"
            setPrint={handlePrint}
            headerClassName={"report__transparent_header"}
          />
          <div className="master-report-content">
            <InfoSection level={level} />

            <div className="master-report__search">
              <div className="master-report__search-bar">
                {!isLoading && (
                  <img
                    src={SearchIconGray}
                    className="report__search-icon animated-fade"
                    alt=""
                  />
                )}
                <input
                  className={`form-control report__search-input ${
                    isLoading ? "margin-left" : ""
                  }`}
                  type="search"
                  name="Search"
                  placeholder="Search Students"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setSearch(e.target.value)
                  }
                />
              </div>
            </div>

            <div className={`master-report-body column-${testNames.length}`}>
              <div className="master-report__selects tabel-outside-header">
                <div className="master-report__table-header">
                  <span className="master-report__table-id">
                    Student ID <br /> Last 5
                  </span>
                  <span className="master-report__table-name">Student</span>
                </div>
                <div className="master-report__selects-wrapper">
                  {!isLoading ? (
                    testNames.map((name: string, index: number) => {
                      const newOptions = [...options].map((option: any) => {
                        if (tests[index].tested === 0) {
                          return { ...option, disabled: true };
                        }
                        return option;
                      });
                      return (
                        <div
                          className="master-report__selects-select"
                          key={index}
                        >
                          <TableSelectCommon
                            isOpen={
                              dropdownIndex !== null && dropdownIndex === index
                            }
                            toggleSelect={() => {
                              if (dropdownIndex !== null) {
                                if (dropdownIndex !== index) {
                                  setdropdownIndex(index);
                                  return;
                                }
                                setdropdownIndex(null!);
                              } else {
                                setdropdownIndex(index);
                              }
                            }}
                            options={newOptions}
                            onChange={(value: any) =>
                              selectReport(
                                value,
                                _.orderBy(test_stats, ["test_type"], ["asc"])[
                                  index
                                ].id
                              )
                            }
                            isReport={true}
                            name={"Summ " + name.split(" ").pop()}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <MasterHeaderSkeleton rowColumns={3} borderRadius={0} />
                  )}
                </div>
              </div>

              <div
                className={`master-report__table-container ${
                  Students.length > 15 && !isLoading ? "increase-height" : ""
                }`}
              >
                <table className="master-report__table">
                  <tbody className="master-report__body__table__body">
                    {!Students.length && !isLoading && (
                      <tr className="dark-gray text-center">
                        <td className="report__table-notFound">
                          No Students Found
                        </td>
                      </tr>
                    )}
                    {!isLoading ? (
                      Students.map((student: any, index: number) => (
                        <Student
                          student={student}
                          tests={_.orderBy(test_stats, ["test_type"], ["asc"])}
                          index={index}
                          setIsolatedStudent={onClickStudentName}
                          level={level || []}
                        />
                      ))
                    ) : (
                      <MasterBodySkeleton rows={15} />
                    )}
                  </tbody>
                </table>
              </div>

              <table
                className={`master-report__results ${
                  Students.length > 15 && !isLoading ? "move-up" : ""
                }`}
              >
                <div className="result">
                  <tbody>
                    {
                      <ReportFooter
                        tests={_.orderBy(test_stats, ["test_type"], ["asc"])}
                        isLoading={isLoading}
                      />
                    }
                  </tbody>
                </div>
              </table>
            </div>
          </div>
        </div>
      )}
      <div className="printData" ref={componentRef}>
        <MasterPrint
          students={students}
          tests={_.orderBy(test_stats, ["test_type"], ["asc"])}
          testNames={testNames}
          class_name={class_name || ""}
          level={level}
        />
      </div>
    </>
  );
};
export default Report;
