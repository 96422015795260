import { FC, useMemo } from 'react'
import ScoreBox from './ScoreBox';
import PathIcon from "assets/img/path.svg";
import ToolTip, { ObjectiveTooltipContent } from 'components/common/Tooltip';

interface Props {
    index: number;
    showScore: boolean;
    showClassColumn: number;
    objective: any;
    classrooms: any
    selectedObjectiveIndex?: number,
    setSelectedObjectiveIndex?: Function,
    totalClassrooms: number,
}

const ObjectiveRecord: FC<Props> = (props) => {
    const { index, showScore, objective, classrooms, showClassColumn, selectedObjectiveIndex, setSelectedObjectiveIndex, totalClassrooms } = props;
    const { less_proficient_classroom_count, average } = objective

    const proficiencyLevel = useMemo(() => {
        const percentage = (less_proficient_classroom_count / totalClassrooms) * 100;

        if (percentage >= 65 && percentage <= 85)
            return "high"
        else if (percentage > 85 && percentage <= 100)
            return "critical"
    }, [less_proficient_classroom_count, totalClassrooms])


    const display = useMemo(() => {
        return selectedObjectiveIndex !== null && selectedObjectiveIndex !== index ? 'hidden' : ''
    }, [selectedObjectiveIndex, index])

    const onObjectiveClick = (index: number) => {
        console.log({ index })
        if (index !== selectedObjectiveIndex) {
            setSelectedObjectiveIndex && setSelectedObjectiveIndex(index)
            return
        }
        setSelectedObjectiveIndex && setSelectedObjectiveIndex(null!)
    }


    return (
        <tr className={`${index % 2 !== 0 ? "dark-gray" : ''} ${index === 0 ? 'first-row' : ''} `}>

            <td className={`pathIcon-row-column ${index % 2 !== 0 ? "dark-bg" : ''}`}> 
                <span className='pathIcon-row'>
                    {objective.nearly_proficient && <img src={PathIcon} alt="icon" />}
                </span>
            </td>

            <td className={`summative-objective-analysis__objective cursor-pointer ${index % 2 !== 0 ? "dark-bg" : ''}`}
                onClick={() => onObjectiveClick(index)}
                style={{
                    fontWeight: selectedObjectiveIndex !== null && selectedObjectiveIndex === index ? 'bold' : 'normal',
                    opacity: selectedObjectiveIndex !== null && selectedObjectiveIndex !== index ? '0.2' : '1'

                }}
            >
                <span className={`objective-width ${proficiencyLevel}`}>
                    <ToolTip top={20} content={<ObjectiveTooltipContent
                        name={objective.objective_name}
                        number={objective.objective_number}
                        description={objective.objective_description}
                    />}>
                        <span className={` ${objective?.objective_number?.length > 8 ? 'annimation' : ''}`}>
                            {objective.objective_number}
                        </span>
                    </ToolTip>
                </span>
            </td>

            <td className={`summative-objective-analysis__no-question ${index % 2 !== 0 ? "dark-bg" : ''} ${display}`} >{objective.count}</td>

            {
                classrooms?.map((classroom: any, index: number) => {
                    const { classrooms } = objective;

                    const classroomInfo = classrooms.find((room: any) => room?.classroom_id === classroom.classroom_id)

                    return (
                        <ScoreBox
                            showScore={showScore}
                            showClassColumn={showClassColumn}
                            classroomInfo={classroomInfo}
                            index={index}
                            display={display}
                        />

                    )
                })
            }
            <td className={`summative-objective-analysis__table-empty summative-objective-analysis__data-height ${display}`}></td>
            <td className={`summative-objective-analysis__table-benchmark summative-objective-analysis__data-height ${display}`}>
                <div className="less-proficient">
                    <span className={`summative-objective-analysis__table-questions rounded ${proficiencyLevel}`}>{`${less_proficient_classroom_count}`}</span>
                </div>
            </td>
            <td className={`summative-objective-analysis__table-summative summative-objective-analysis__data-height ${display}`}>
                <div className="d-flex align-items-center justify-content-center">
                    <span className="summative-objective-analysis__table-questions average-content">{`${average || 0}`}</span>
                </div>
            </td>
        </tr>
    )
}
export default ObjectiveRecord
