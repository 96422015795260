import { PacingCalenderContext } from "contexts/pacingCalender";
import { useContext, useMemo } from "react";
import { createDate } from "util/createDate";


export const extractDate = (date: string, month: string, important_dates: any[], currentGradeSubject: any) => {
    const currentMonthImportantDates = important_dates.find(item => item.month === month);
    if(currentMonthImportantDates){
        const importantDate = currentMonthImportantDates.dates.find(
            (item:any) => item.date === date && 
            item.grade_id === currentGradeSubject.grade_id && 
            item.subject_id === currentGradeSubject.subject_id
        );
        
        return importantDate
    }

    return null;
}

export const useGetDate = (dateId: any) => {
    const { currentMonthIndex, currentGradeIndex, gradeSubjects ,allMonths, adminPacingCalenderDates: { important_dates }, currentMonth } = useContext(PacingCalenderContext);
    const date = useMemo(() => {
        const dateString = createDate(allMonths, currentMonthIndex, dateId);
        const currentGradeSubject = gradeSubjects.length > 0 ? gradeSubjects[currentGradeIndex] : null;
        if(!dateString || !currentGradeSubject){
            return null
        }

        const extractedDate = extractDate(dateString, currentMonth?.month || '', important_dates, currentGradeSubject);

        return extractedDate;

        /* eslint-disable */

    },[allMonths, currentMonthIndex, important_dates, currentGradeIndex, gradeSubjects]);

    return date

}