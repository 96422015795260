import axios from "axios";
import config from "config/config";

export const getToolsIcon = async () => {
    try {
        let response = await axios.get(
            `${config.defaults.api_url}/api/v3/pd/tools/icons`,
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return response.data.data;
    } catch (e) {
        console.log(e);
        return null!
    }
}

export const updateResource = async ({id, ...data}: any) => {
    try {
        const response = await axios.put(
            `${config.defaults.api_url}/api/v3/admin/management/resources/${id}`,
            data,
            {
              headers: {
                apiKey: config.defaults.api_key!,
                // "content-type": "multipart/form-data"
              }
            }
          );
          return response.data.data
    } catch (e) {
        console.log(e);
        return null!
    }
}

export const deleteResource = async (resourceId: number) => {
    try {
        const response = await axios.delete(
          `${config.defaults.api_url}/api/v3/admin/management/resources/${resourceId}`,
          {
            headers: {
              apiKey: config.defaults.api_key!
            }
          }
        );
        return response.data.data
    } catch (e) {
        console.log(e);
        return null!
    }
}

export const addResource = async (finalData: any) => {
    try {
        let response = await axios.post(
          `${config.defaults.api_url}/api/v3/admin/management/resources`,
          finalData,
          {
            headers: {
              apiKey: config.defaults.api_key!,
              "content-type": "multipart/form-data"
            }
          }
        );
        
        return response.data.data
    } catch (e) {
        console.log(e);
        return null!
    }
}

export const putPdToolsData = async (payload: any) => {
    try {
        let response = await axios.put(
            `${config.defaults.api_url}/api/v3/pd/tools`,
            payload,
            {
                headers: {
                    apiKey: config.defaults.api_key!,
                    "content-type": "multipart/form-data"
                }
            }
        );
        return response.data.data;
    } catch (e) {
        console.log(e);
        return null!
    }
}

export const UpdatePdToolsData = async (payload: any) => {
    
    try {
        let response = await axios.put(
            `${config.defaults.api_url}/api/v3/pd/tools/${payload.get('id')}`,
            payload,
            {
                headers: {
                    apiKey: config.defaults.api_key!,
                    "content-type": "multipart/form-data"
                }
            }
        );
        return response.data.data;
    } catch (e) {
        console.log(e);
        return null!
    }
}

export const putPdPrevToolsData = async (payload: any) => {
    try {
        let response = await axios.put(
            `${config.defaults.api_url}/api/v3/pd/tools/date/${payload.date_week_id}`,
            payload,
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return response.data.data;
    } catch (e) {
        console.log(e);
        return null!
    }
}

export const deleteTool = async ({id,grade_id,subject_id}: {id: number, grade_id?: number, subject_id?: number}) => {

    try {
        let response = await axios.delete(
            `${config.defaults.api_url}/api/v3/pd/tools/${id}${grade_id ? `?grade_id=${grade_id}` : ''}${subject_id ? `&subject_id=${subject_id}` : ''}`,
            {
            headers: {
                apiKey: config.defaults.api_key!
            }
            }
        );
        return response.data.data;
    } catch (e) {
        console.log(e);
        return null!
    }
}

export const getAllTools = async ({month, year, gradeId, subjectId, search}: any) => {
    try {
        let response = await axios.get(
            `${config.defaults.api_url}/api/v3/pd/tools?month=${month}&year=${year}&grade_id=${gradeId}&subject_id=${subjectId}${search ? `&search=${search}`: ''}`,
            {
            headers: {
                apiKey: config.defaults.api_key!
            }
            }
        );
        return response.data.data;
    } catch (e) {
        console.log(e);
        return null!
    }
}

export const getAllGoals = async ({month, year, gradeId, subjectId, search}: any) => {
    try {
        let response = await axios.get(
            `${config.defaults.api_url}/api/v3/pd/goals?month=${month}&year=${year}&grade_id=${gradeId}&subject_id=${subjectId}${search ? `&search=${search}`: ''}`,
            {
            headers: {
                apiKey: config.defaults.api_key!
            }
            }
        );
        return response.data.data;
    } catch (e) {
        console.log(e);
        return null!
    }
}

export const sortResource = async (resources: any, data: any, category: any) => {
    
    try {
      let finalData = {
        category_type: category,
        resource_ids: data.map((item: any) => item.id)
      };
      let response = await axios.put(
        `${config.defaults.api_url}/api/v3/admin/management/resources`,
        finalData,
        {
          headers: {
            apiKey: config.defaults.api_key!
          }
        }
      );
      return response.data.data;
    } catch (e) {
        console.log(e);
        return null!
    }
  };

export const getAllResources = async ({gradeId, subjectId}: any) => {
    try {
        let response = await axios.get(
            `${config.defaults.api_url}/api/v3/activity/admin/resources/${gradeId}/${subjectId}`,
            {
            headers: {
                apiKey: config.defaults.api_key!
            }
            }
        );
        return response.data.data;
    } catch (e) {
        console.log(e);
        return null!
    }
}

export const getPdWeeklySchedule = async ({month, year, gradeId, subjectId, search}: any) => {
    try {
        let response = await axios.get(
            `${config.defaults.api_url}/api/v3/pd/weeks?month=${month}&year=${year}&grade_id=${gradeId}&subject_id=${subjectId}${search ? `&search=${search}`: ''}`,
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return response.data.data;
    } catch (e) {
        console.log(e);
        return null!
    }
}

export const putPdPrevWeekData = async (payload: any) => {
    try {
        let response = await axios.put(
            `${config.defaults.api_url}/api/v3/pd/weeks/date/${payload.date_week_id}`,
            payload,
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return response.data.data;
    } catch (e) {
        console.log(e);
        return null!
    }
}

export const putPdWeekData = async (payload: any) => {
    try {
        let response = await axios.put(
            `${config.defaults.api_url}/api/v3/pd/weeks`,
            payload,
            {
                headers: {
                    apiKey: config.defaults.api_key!
                }
            }
        );
        return response.data.data;
    } catch (e) {
        console.log(e);
        return null!
    }
}