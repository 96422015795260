/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import {
  DistrictDropDown,
  SchoolDropDown,
  ClassroomDropDown,
} from "./headerDropdowns";
import {
  setClassroomSchoolId,
  setDistrictId,
} from "redux/actionCreators/classroom";
import { useLocation } from "react-router-dom";
import { getReportFieldName } from "util/index";
// import { useShowSurvey } from "hooks/useShowSurvey";
// import Cookies from "js-cookie";
interface RootState {
  auth: any;
  activity: any;
  classroom: any;
  management: any;
}

const ClassroomDropDowns = ({
  onDistrictChange,
  onSchoolChange,
  userRole,
  classrooms,
  onChangeClassroom,
  isOtherScreen,
  onClickClassroomDropdown,
  setSchoolOpen,
  schoolOpen,
  classroomOpen,
  setClassroomOpen,
}: any) => {
  const app = useSelector((state: RootState) => state);

  const isDistrictSelected = !!Number(sessionStorage.getItem("c_district_id"));
  const isSchoolSelected = !!Number(sessionStorage.getItem("c_school_id"));
  const isClassroomSelected = !!Number(
    sessionStorage.getItem("c_classroom_id")
  );

  const dropdowns = useRef<any>({
    shouldDistrictOpen: null,
    shouldSchoolOpen: null,
    shouldClassroomOpen: null
  });

  const districtSchools = app.activity.schoolsList;
  const classroomId = app.classroom.classroomId;
  const school_id = app.classroom.classroomSchoolId;
  const district_id = app.classroom.districtId;
  const districts = app.activity.districtsList;
  const dispatch = useDispatch();
  // const { getShowSurvey } = useShowSurvey();
  // const { isTooltipSeen } = useTooltipSeen();
  const { pathname }: any = useLocation();
  const discussionPage = pathname.indexOf("discussions") === -1;
  const [districtOpen, setDistrictOpen] = useState<boolean>();
  // const [schoolOpen, setSchoolOpen] = useState<boolean>();
  // const [classroomOpen, setClassroomOpen] = useState<boolean>();

  useEffect(() => {
    const setSingleData = async () => {
      if (districts.length && districtSchools.length) {
        const isOnlyOneDistrict = districts.length === 1;
        if (isOnlyOneDistrict && district_id === 0) {
          sessionStorage.setItem(
            "c_district_id",
            JSON.stringify(districts[0].id)
          );
          const isOnlyOneSchool = districtSchools.length === 1;
          if (isOnlyOneSchool && school_id === 0) {
            sessionStorage.setItem(
              "c_school_id",
              JSON.stringify(districtSchools[0].id)
            );
          }
          dispatch(setDistrictId(districts[0].id));
          if (isOnlyOneSchool && school_id === 0) {
            dispatch(setClassroomSchoolId(districtSchools[0].id));
          }
        }
      }
    };
    setSingleData();
  }, [districts]);

  useEffect(() => {
    if (
      districts.length &&
      districtSchools.length &&
      district_id > 0 &&
      school_id > 0
    ) {
      const isOnlyOneClass = classrooms.length === 1;
      if (isOnlyOneClass && classroomId === 0) {
        sessionStorage.setItem(
          "c_classroom_id",
          JSON.stringify(classrooms[0].id)
        );
        onChangeClassroom({
          id: classrooms[0].id,
          fk_grade_id: classrooms[0].fk_grade_id,
          fk_subject_id: classrooms[0].fk_subject_id,
        });
      }
    }
  }, [classrooms]);

  useEffect(() => {
    if (app.activity.districtLoading || isOtherScreen) return;

    dropdowns.current.shouldDistrictOpen = dropdowns.current.shouldDistrictOpen === null ? !isDistrictSelected : dropdowns.current.shouldDistrictOpen
    dropdowns.current.shouldSchoolOpen = dropdowns.current.shouldSchoolOpen === null ? isDistrictSelected ? !isSchoolSelected : null : dropdowns.current.shouldSchoolOpen
    dropdowns.current.shouldClassroomOpen = dropdowns.current.shouldClassroomOpen === null ? isDistrictSelected && isSchoolSelected ? !isClassroomSelected : null : dropdowns.current.shouldClassroomOpen

    if (districts.length > 1 && dropdowns.current.shouldDistrictOpen) {
      setDistrictOpen(true);
      dropdowns.current.shouldDistrictOpen = false;
    } else if (
      districtSchools.filter((scl: any) => scl.district_id === district_id)
        .length > 1 &&
      dropdowns.current.shouldSchoolOpen
    ) {
      setSchoolOpen(true);
      dropdowns.current.shouldSchoolOpen = false;
    } else if (
      classrooms?.length > 1 &&
      dropdowns.current.shouldClassroomOpen
    ) {
      setClassroomOpen(true);
      dropdowns.current.shouldClassroomOpen = false;
    }
  }, [
    classrooms,
    district_id,
    app.activity.districtLoading,
    districtSchools,
    districts,
  ]);

  const formatOptionLabel = (data: any) => {
    return (
      <div className="classRoom-selectOption">
        <div className="selectOption-text">{data.label}</div>
        {data?.id !== 0 && data?.hour && (
          <div className="dropDown-chip">{data.hour}</div>
        )}
      </div>
    );
  };

  const onClickBackdrop = async () => {
    setClassroomOpen(undefined);
    setDistrictOpen(undefined);
    setSchoolOpen(undefined);
  };

  const fieldName = pathname.includes("report") ? getReportFieldName(pathname) : "";
  const selectedClassroom = classrooms?.find((item: any) => item.value === classroomId);
  
  return (
    <>
      <ul
        className={` ps-0 mb-0
          ${userRole === "Classroom Admin" ? "d-none" : "header__dropdown-wrapper"}
        `}
      >
        {userRole !== "Super Admin" && districts.length === 1 ? (
          ""
        ) : (
          <li className="header__dropdown-item header__dropdown-itemSchool active">
            <span>
              <small>Districts</small>
              <div
                style={{ position: "relative" }}
                className={` header__dropdown-zIndex ${districtOpen ? "mobileZindex" : ""}  ${district_id === 0 ? `header__dropdown-flashing` : ``} `}
              >
                <DistrictDropDown
                  isDisabled={districts.length && discussionPage ? false : true}
                  onDistrictChange={(val: any, actionMeta: any) => {
                    onDistrictChange(val);
                    setDistrictOpen(undefined);
                  }}
                  districts={districts || []}
                  isClearable={!_.isEmpty(app.classroom.filters) === false}
                  selectedDistrict={districts?.find((item: any) => item.value === district_id) || null}
                  isOtherScreen={isOtherScreen}
                  isOpen={districtOpen}
                  setOpen={(open: any) => {
                    setDistrictOpen(open);
                    if(open){
                      setSchoolOpen(undefined)
                      setClassroomOpen(undefined);
                    }
                  }}
                />
              </div>
            </span>
          </li>
        )}
        {(userRole === "School Admin" || userRole === "Classroom Admin") &&
        districtSchools.length === 1 ? (
          ""
        ) : (
          <li className="header__dropdown-item header__dropdown-itemSchool classroom-arrow active">
            <span>
              <small className={districtSchools.length === 0 ? "disabled" : ""}>
                School
              </small>
              <div
                className={` header__dropdown-zIndex  ${schoolOpen ? "mobileZindex" : ""} ${
                  district_id > 0 &&
                  school_id === 0 &&
                  (districts.length === 1 || districtSchools.length > 1)
                    ? `header__dropdown-flashing`
                    : ""}
                `}
                style={{ position: "relative"}}
              >
                <SchoolDropDown
                  isDisabled={
                    districtSchools.length && district_id > 0 && discussionPage
                      ? false
                      : true
                  }
                  onSchoolChange={(val: any) => {
                    onSchoolChange(val);
                    setSchoolOpen(undefined);
                  }}
                  isSearchable={true}
                  isClearable={true}
                  selectedSchool={districtSchools?.find((item: any) => item.value === school_id) || null}
                  districtSchools={districtSchools.filter(
                    (school: any) => school.fk_district_id === district_id
                  )}
                  isOtherScreen={isOtherScreen}
                  setOpen={(open: any) => {
                    setSchoolOpen(open);
                    if(open){
                      setClassroomOpen(undefined)
                      setDistrictOpen(undefined);
                    }
                  }}
                  isOpen={schoolOpen}
                />
              </div>
            </span>
          </li>
        )}
        <li className="header__dropdown-item header__dropdown-itemClass">
          <span>
            <small className={classrooms.length === 0 ? "disabled" : ""}>
              Classroom
            </small>
            {selectedClassroom?.hour && (
              <small
                style={{
                  width: "80px",
                  float: "right",
                  height: "18px",
                  marginTop: "3px",
                  paddingLeft: "0px",
                }}
              >
                Hour
              </small>
            )}
            <div
              className={` header__dropdown-zIndex  ${classroomOpen ? "mobileZindex" : ""} ${
                classroomId === 0 &&
                school_id > 0 &&
                (districtSchools.length === 1 || classrooms.length > 1)
                  ? `header__dropdown-flashing`
                  : ""
              }`}
              style={{ position: "relative"}}
            >
              <ClassroomDropDown
                fieldName={fieldName}
                isDisabled={
                  classrooms.length &&
                  school_id &&
                  district_id &&
                  discussionPage
                    ? false
                    : true
                }
                onChangeClassroom={(val: any) => {
                  onChangeClassroom(val);
                  setClassroomOpen(undefined);
                }}
                classrooms={classrooms}
                selectedClassroom={selectedClassroom || null}
                isOtherScreen={isOtherScreen}
                setOpen={(open: any) => {
                  setClassroomOpen(open);
                  if(open){
                    setSchoolOpen(undefined)
                    setDistrictOpen(undefined);
                  }
                }}
                isOpen={classroomOpen}
                formatOptionLabel={formatOptionLabel}
              />
            </div>
          </span>
        </li>
      </ul>
      {userRole === "Classroom Admin" && (
        <div className="header__dropdown-wrapper">
          <li className="header__dropdown-item header__dropdown-itemClass">
            <span>
              <small className={classrooms.length === 0 ? "disabled" : ""}>
                Classroom
              </small>
              <div
                className={
                  classroomId === 0 &&
                  school_id > 0 &&
                  (districtSchools.length === 1 || classrooms.length > 1)
                    ? `header__dropdown-flashing`
                    : ""
                }
                onTouchEnd={()=>{
                  const isEnabled = !isOtherScreen && classrooms.length > 1 &&
                  school_id &&
                  district_id &&
                  discussionPage
                  
                  isEnabled && onClickClassroomDropdown && onClickClassroomDropdown()
                }}
                onClick={() => {
                  const isEnabled = !isOtherScreen && classrooms.length > 1 &&
                  school_id &&
                  district_id &&
                  discussionPage
                  
                  isEnabled && onClickClassroomDropdown && onClickClassroomDropdown()
                }}
              >
                <ClassroomDropDown
                  isDisabled={
                    classrooms.length &&
                    school_id &&
                    district_id &&
                    discussionPage
                      ? false
                      : true
                  }
                  onChangeClassroom={(val: any) => {
                    onChangeClassroom(val);
                    setClassroomOpen(false);
                  }}
                  onKeyDown={(e:any) => e.key === "ArrowDown" && !isOtherScreen && onClickClassroomDropdown && onClickClassroomDropdown()}
                  classrooms={classrooms}
                  selectedClassroom={classrooms?.find((item: any) => item.value === classroomId) || null}
                  isOtherScreen={isOtherScreen}
                  formatOptionLabel={formatOptionLabel}
                  isOpen={
                    userRole === "Classroom Admin" ? false : classroomOpen
                  }
                />
              </div>
            </span>
          </li>
        </div>
      )}
      {(classroomOpen || schoolOpen || districtOpen) &&
        userRole !== "Classroom Admin" && (
          <div onClick={onClickBackdrop} className="modalBackdrop"></div>
        )}
    </>
  );
};

export default ClassroomDropDowns;
