import { useRef } from "react";
import { selectStyles } from "./dropdownStyles";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { AppCookies } from "util/constants";

const getPlaceholderStyles = (defaultStyles: any, state?: any) => {
  return {
    ...defaultStyles,
    color: "#323233",
    opacity: state?.isFocused ? 0.5 : 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineHeight: '21.82px'
  };
}

const getSingleValue = (defaultStyles: any, isFocused?: any) => {
  return {
    ...defaultStyles,
    color: "#4788FF",
    opacity: isFocused ? 0.5 : 1
  };
}

export const DistrictDropDown = ({
  isDisabled,
  onDistrictChange,
  districts,
  selectedDistrict,
  isFromActivity,
  isOtherScreen,
  isOpen,
  setOpen
}: any) => {
  const isOnlyOneDistrict = districts.length === 1;
  let options = districts;
  
  options = isFromActivity
    ? [{ label: "All Districts", value: 0, id: 0 }, ...options]
    : options;

  const focusedRef = useRef<any>(false);
  return (
    <Select
      isDisabled={isDisabled || isOnlyOneDistrict || isOtherScreen}
      onChange={onDistrictChange}
      onMenuOpen={() => setOpen && setOpen(true)}
      onMenuClose={() => setOpen && setOpen(undefined)}
      blurInputOnSelect
      options={options}
      className="dropdown-diabled"
      onFocus={() => focusedRef.current = true}
      onBlur={() => focusedRef.current = false}
      placeholder={isFromActivity ? null : 'Select a District'}
      value={selectedDistrict}
      classNamePrefix="react-select"
      styles={{
        ...selectStyles,
        placeholder: (defaultStyles: any, state: any) => getPlaceholderStyles(defaultStyles, state),
        singleValue: (defaultStyles: any) => getSingleValue(defaultStyles, focusedRef.current)
      }}
      isClearable={true}
      components={{
        IndicatorSeparator: () => null,
      }}
      menuIsOpen={isOpen}
    />
  );
};

export const SchoolDropDown = ({
  isFromActivity,
  districtSchools,
  isDisabled,
  onSchoolChange,
  isSearchable,
  selectedSchool,
  isClearable,
  isOtherScreen,
  isOpen,
  setOpen
}: any) => {
  const focusedRef = useRef<any>(false);
  let options = districtSchools.map((x: any) => {
    x.label = x.school_name || x.name;
    x.value = x.id;
    return x;
  });
  options = isFromActivity
    ? [{ label: "All Schools", value: 0, id: 0 }, ...options]
    : options;
  const isOnlyOneSchool = districtSchools.length === 1;
  return (
    <Select
      isDisabled={isDisabled || isOnlyOneSchool || isOtherScreen}
      onChange={(e: any) => onSchoolChange(e, true)}
      blurInputOnSelect
      isSearchable={isSearchable}
      isClearable={isClearable}
      onMenuOpen={() => setOpen && setOpen(true)}
      onMenuClose={() => setOpen && setOpen(undefined)}
      onFocus={() => focusedRef.current = true}
      onBlur={() => focusedRef.current = false}
      placeholder={isFromActivity ? null : 'Select a School'}
      value={selectedSchool}
      options={options}
      className="dropdown-diabled"
      classNamePrefix="react-select"
      styles={{
        ...selectStyles,
        placeholder: (defaultStyles: any, state) => getPlaceholderStyles(defaultStyles, state),
        valueContainer: (defaultStyles: any) => getSingleValue(defaultStyles, focusedRef.current)
      }}
      components={{
        IndicatorSeparator: () => null,
      }}
      menuIsOpen={isOpen}
    />
  );
};

export const TeacherDropDown = ({
  teachers,
  school_id,
  setTeacherId,
  selectedTeacher,
  isOtherScreen,
}: any) => {
  const focusedRef = useRef<any>(false);
  const options = [
    { label: "All Teachers", value: 0, id: 0 },
    ...teachers.map((x: any) => {
      x.label = x.first_name + " " + x.last_name;
      x.value = x.id;
      return x;
    }),
  ];
  return (
    <Select
      isDisabled={
        teachers.length === 0 || school_id === 0 ? true : false || isOtherScreen
      }
      blurInputOnSelect
      onFocus={() => focusedRef.current = true}
      onBlur={() => focusedRef.current = false}
      onChange={setTeacherId}
      isSearchable={true}
      value={selectedTeacher}
      options={options}
      className="dropdown-diabled"
      classNamePrefix="react-select"
      styles={{
        ...selectStyles,
        singleValue: (defaultStyles: any) => getSingleValue(defaultStyles, focusedRef.current)
      }}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
};

export const ClassroomDropDown = ({
  selectedClassroom,
  onChangeClassroom,
  classrooms,
  isDisabled,
  isOtherScreen,
  disbledDefualt,
  hideOption,
  isOpen,
  formatOptionLabel,
  fieldName,
  setOpen,
  onKeyDown
}: any) => {
  const {pathname} = useLocation();
  const focusedRef = useRef<any>(false);
  const testType = Cookies.get(AppCookies.test_name)?.trim().split("-")[1].trim();
  const isMasterReport = pathname.includes("master-report");
  let options = classrooms.map((x: any, index: number) => {
    x.label = x.name;
    x.value = x.id;
    if(Array.isArray(fieldName)){
      
     if(!isMasterReport && testType === "A1"){
      x.isDisabled = x[fieldName[0]]===false
     } 
     if(!isMasterReport && testType === 'A2'){
      x.isDisabled = x[fieldName[1]]===false
     }
     if(!isMasterReport && testType === 'B'){
      x.isDisabled = x[fieldName[2]]===false
     }
        
    }else{
      x.isDisabled = x[fieldName] === false;
    }
    return x;
  });
  options = hideOption
    ? options.filter(
        (item: any) => item.fk_grade_id !== 9 && item.students_count > 0
      )
    : options;
  const isOnlyOneClass = options.length === 1

  // console.log("dropdowns cond", isDisabled, isOtherScreen, isOnlyOneClass);
  return (
    <Select
      isDisabled={isDisabled || isOtherScreen || isOnlyOneClass}
      onChange={(e: any) => {
        onChangeClassroom(e, true);
      }}
      blurInputOnSelect
      onMenuOpen={() => setOpen && setOpen(true)}
      onMenuClose={() => setOpen && setOpen(undefined)}
      onFocus={() => focusedRef.current = true}
      onBlur={() => focusedRef.current = false}
      isSearchable={true}
      onKeyDown={(e) => onKeyDown && onKeyDown(e)}
      options={options}
      value={selectedClassroom}
      className="dropdown-diabled"
      classNamePrefix="react-select"
      placeholder="Select a Classroom"
      styles={{
        ...selectStyles,
        placeholder: (defaultStyles: any, state) => getPlaceholderStyles(defaultStyles, state),
        singleValue: (defaultStyles: any) => getSingleValue(defaultStyles, focusedRef.current)
      }}
      components={{
        IndicatorSeparator: () => null
      }}
      menuIsOpen={isOpen}
      formatOptionLabel={formatOptionLabel}
    />
  );
};

export const GradeDropDown = ({
  isDisabled,
  setGradeId,
  selectedGrade,
  grades,
  isOtherScreen,
}: any) => {
  const focusedRef = useRef<any>(false);
  const options = [
    { label: "All Grades", value: 0, id: 0 },
    ...(grades?.map((x: any) => {
      x.label = x.name;
      x.value = x.grade_id + "" + x.subject_id;
      x.id = x.grade_id;
      return x;
    }) || []),
  ];
  return (
    <Select
      isDisabled={isDisabled || isOtherScreen}
      blurInputOnSelect
      onFocus={() => focusedRef.current = true}
      onBlur={() => focusedRef.current = false}
      onChange={setGradeId}
      isSearchable={true}
      value={selectedGrade}
      options={options}
      className="dropdown-diabled"
      classNamePrefix="react-select"
      styles={{
        ...selectStyles,
        singleValue: (defaultStyles: any) => getSingleValue(defaultStyles, focusedRef.current)
      }}
      components={{
        IndicatorSeparator: () => null,
      }}
    />
  );
};
