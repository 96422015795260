/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useMemo } from "react";
import moment from "moment";
import Record from "./detailsRecord";
import StudentTableHeader from "./objectiveTableHeader";
import checkIcon from "assets/img/checked-green.svg";
import { isFirefox, isChrome } from "util/index";

interface Props {
  data: any[];
  classroom_name?: string;
  test_name?: string;
  lang: string;
  answerRowsHeights: any;
  school_name?: string;
  student_name?: string;
}

const BasedPrint: FC<Props> = (props) => {
  const {
    classroom_name,
    data: answers,
    test_name,
    lang,
    answerRowsHeights,
    school_name,
    student_name,
  } = props;

  const { answersSlices } = useMemo(() => {
    const patches = [];

    const PageHeight = () => {
      if (isFirefox) return 840;
      else if (isChrome) return 860;

      return 840;
    };

    let maxPageHeight = PageHeight();
    let heightSum = 0;
    for (let startIndex = 0, endIndex = 0; endIndex < answers.length; ) {
      heightSum += answerRowsHeights[endIndex];
      if (heightSum > maxPageHeight - (!!startIndex ? 0 : 150)) {
        const slicedAnswers = answers.slice(startIndex, endIndex);
        patches.push(
          slicedAnswers?.map((answer: any, index: number) => (
            <Record {...answer} lang={lang} index={index} />
          ))
        );
        startIndex = endIndex;
        heightSum = 0;
      } else if (heightSum === maxPageHeight - (!!startIndex ? 0 : 150)) {
        endIndex++;
        const slicedAnswers = answers.slice(startIndex, endIndex);
        patches.push(
          slicedAnswers?.map((answer: any, index: number) => (
            <Record {...answer} lang={lang} index={index} />
          ))
        );
        startIndex = endIndex;
        heightSum = 0;
      } else if (endIndex === answers.length - 1) {
        const slicedAnswers = answers.slice(startIndex, answers.length);
        patches.push(
          slicedAnswers?.map((answer: any, index: number) => (
            <Record {...answer} lang={lang} index={index} />
          ))
        );
        endIndex++;
      } else {
        endIndex++;
      }
    }
    return {
      answersSlices: patches,
    };
  }, [answers, answerRowsHeights]);

  const PrintContent = useMemo(() => {
    return answersSlices.map((slice: any, index: number) => (
      <div
        className={`${index ? "add-page-break" : ""}`}
        style={{ border: "none !important" }}
      >
        <div
          className="based-report-download"
          id="based-report"
          style={{ background: "#fff !important" }}
        >
          <div
            className="based-report-download__main"
            style={{ background: "#fff !important" }}
          >
            <div style={{ background: "#fff !important" }}>
              <div className="Download-pdf-header">
                {!index && (
                  <div className="print-report__header">
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h3 className="title">
                          Standard Based Report
                          {student_name?.length ? `: ${student_name}` : ""}
                        </h3>
                        <p className="print-report__header-text mb-0">
                          {moment(new Date()).format("M-D-YY")}
                        </p>
                      </div>
                      <div>
                        <h3 className="title text-right">{classroom_name}</h3>
                        <p className="print-report__header-text text-right mb-0">
                          {school_name}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {!index && (
                  <div className="report__levels">
                    <div className="report__levels-container download">
                      <p className="report__levels-title">
                        Performance Level (Percentage Score Range)
                      </p>

                      <div className="report__levels-percentage download-percentage">
                        <div className="report__levels-row">
                          <div className="d-flex align-items-center">
                            <div className="box cheked-mark">
                              <img src={checkIcon} alt="" />
                            </div>
                            <p>Proficient</p>
                          </div>
                          <p>( 80% - 100%)</p>
                        </div>
                        <div className="report__levels-row">
                          <div className="d-flex align-items-center">
                            <div className="box non-proficient"></div>
                            <p>Non-Proficient</p>
                          </div>
                          <p>( 0% - 79%)</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="table d-flex justify-content-center">
                  <table
                    id="sbr-table"
                    className="based-report-download__table"
                  >
                    <StudentTableHeader />
                    <tbody>{slice}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div
            className="footer__content-copyright"
            style={{ border: "none !important" }}
          >
            &copy; {new Date().getFullYear()} Alpha Plus Educational Systems
          </div>
        </div>
      </div>
    ));
  }, [answersSlices, test_name, student_name]);

  return <>{PrintContent}</>;
};

export default BasedPrint;
