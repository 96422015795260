import { FC, useMemo } from "react"
import StudentTable from "./student/studentTable"

export const ReportTable: FC<{ record: any, time: Date }> = ({ record, time }) => {

    const { objective_number, description, students, fail, proficiency, total } = useMemo(() => {
        return {
            ...record,
        }
    }, [record])

    return (
        <>
            <div className="mt-4 report-risk-print__student-table">

                <div className="mx-2 mb-2 report-risk-print__objective-col">
                    <p className="text-dark font-bold mb-0 sub-heading numeric-values">{objective_number}</p>
                </div>

                <div className="mx-2 report-risk-print__description-col">
                    <div className="d-flex justify-content-between">
                        <p className="text-dark font-bold mb-1 sub-heading">{description}</p>
                        <p className="text-dark font-bold report-risk-print__non-proficient-col col-print-desc mb-1">{fail}</p>
                    </div>
                </div>

                <div className="mx-2 report-risk-print__proficient-col">
                    <p className="text-dark font-bold numeric-values">{proficiency} %</p>
                </div>
            </div>
            <div>
                <StudentTable
                    students={students}
                    total={total}
                    time={time}
                />
            </div>
        </>
    )
}
export default ReportTable