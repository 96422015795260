import MultiStepGoalIcon from "components/common/multiStepGoalIcon";

const GoalContainer = ({is_multi_step, heading, is_enabled, completed_tasks, tasks, goal, onClickGoal}: any) => {
    const isCompleted = completed_tasks === tasks;
    return (
        // <div className="available-body">
            <div className={`availibility ${is_enabled ? '' : 'disabled'}`}>
                <p>{heading}</p>
                <div onClick={() => is_enabled && onClickGoal()} className={`status ${is_enabled ? 'cursor-pointer' : ''}`}>
                    {is_multi_step && !isCompleted ? (
                        <div className="loader">
                        <MultiStepGoalIcon 
                            completed_tasks={completed_tasks}
                            tasks={tasks}
                        />
                    </div>
                    ):(
                        <label className={`d-flex justify-content-center goals-modal-checkbox ${is_enabled ? 'cursor-pointer' : ''}`}>
                            <input type="checkbox" id="pacing-calendar" value="" onChange={() => {}} checked={isCompleted} />
                            <span className="checkbox"></span>
                        </label>
                    )}
                    
                    <p>{goal}</p>
                </div>
            </div>
        // </div>
    )
};

export default GoalContainer;
