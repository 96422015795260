import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface RootState {
  auth: any;
}
interface Props {
  checked: boolean;
  disabled?: boolean;
  inputName?: string;
  onChange: (e: any) => void;
  ariaLabel?: string;
  onClick?:(e:any)=>void;
  labelClassName?: string;
  tabIndex?:number;
  role?:string;
  isSmall?:boolean;
  activeDisable?:boolean;
  inputClassName?:string;
  inputId?:string;
  isPacingCalender?:boolean
}

const Toggle: FC<Props> = ({
  checked,
  disabled = false,
  inputName = "",
  ariaLabel = "",
  onChange,
  onClick, 
  labelClassName = "", 
  tabIndex = 0, 
  role="",  
  isSmall, 
  activeDisable, 
  inputClassName="",
  inputId = "", 
  isPacingCalender = false
 
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const { auth } = useSelector(
    (state: RootState) => state
  );
 
  useEffect(() => {
      setIsChecked(checked);
  }, [checked]);

  const isSuperAdmin =  auth.currentUser?.role === "Super Admin";
 

  return (
    <label
      className={`switch ${isSmall ? "switch-small" : ""} ${labelClassName}
  ${
    disabled && isSuperAdmin
      ? "disabled"
      : !isSuperAdmin && (activeDisable ? "disabled-item" : disabled ? "disabled" : "")
  }`}
      onClick={onClick && onClick}
    >
      <input
        name={inputName}
        aria-label={ariaLabel}
        tabIndex={tabIndex}
        id={inputId}
        role={role}
        type="checkbox"
        className={`switch-checkbox ${inputClassName || "customFieldsFocused-active"}`}
        onChange={(e: any) => {
          onChange(e);
          if(activeDisable || isPacingCalender){
              return;
          }
          setIsChecked((chk) => !chk);      
        }}
        onKeyDown={(e: any) => {
          e.stopPropagation();
          if (e.key === "Enter") {
            onChange(
              {
              ...e,
              target: {
                ...e.target,
                checked: !e.target.checked,
              },
            });
            if(activeDisable || isPacingCalender){
              return;
          }
          setIsChecked((chk) => !chk);
           
          }
        }}
        checked={isChecked}
        disabled={!activeDisable && disabled}
      />
      <span  className={`switch-slider ${isSmall ? "switch-slider-small" : ""} `}></span>
    </label>
  );
};

export default Toggle;