import { useMemo, useState } from "react";
import { useSelector } from "react-redux"
import { RootState } from "redux/reducers/combine";
import Student from "./Student";
import Footer from './Footer';
import { HeaderSkeleton, FormativeBodySkeleton } from "components/common/skeleton/ReportsSkeleton";
import { TableSelectCommon } from "components/common/selectComponent"

const Report = ({ search, isLoading,dropDownLabel, setDropDownLabel }: { search: string; isLoading: boolean,dropDownLabel :string, setDropDownLabel: Function }) => {

	const report = useSelector((state: RootState) => state.classroom.irrFormative);
	const [questionId, setQuestionId] = useState<number>(null!);
	const [isolatedStudent, setIsolatedStudent] = useState<any>(null!);
	const [expandedStudents, setExpandedStudents] = useState<any>({})
	const [showDropDown, setSHowDropDown] = useState<boolean>();
	

	const OnClickQuestionNo = (id: number) => {
		id === questionId ? setQuestionId(null!) : setQuestionId(id)
	}

	const { answers, response: { objective_description, objective_name, students, objective_number }, total_correct, total_incorrect, total_students, variants } = useMemo(() => {
		if (report?.data) {
			return {
				...report.data,
				response: {
					...report.data?.response,
					students: report?.data?.response.students.sort((a: any, b: any) => {
						return a.last_name.localeCompare(b.last_name)
					})
					.map((item: any) => ({
						...item,
						isShowIsolated: !isolatedStudent ? null : item.student_id === isolatedStudent.student_id
					}))
				}
			}
		}
		else {
			return { answers: [],  response: { students: [], objective_number: '' }, total_correct: [], total_incorrect: [], total_students: [], variants:[] }
		}
	}, [report, isolatedStudent]);

	const FilteredStudents = useMemo(() => {
		if (search) {
			return students.filter((student: any) => {
				return student.student_name.toLowerCase().includes(search.toLowerCase())
			})
		}
		return students
	}, [search, students])

	const onExpandStudentClicked = (studentId: number) => {
		let newExpandedStudents = { ...expandedStudents };
		if (newExpandedStudents[studentId]) {
			delete newExpandedStudents[studentId]
		} else {
			newExpandedStudents[studentId] = true
		}
		setExpandedStudents(newExpandedStudents)
	}

	const onClickStudentName = (student: any) => {
		setIsolatedStudent((prev: any) => !prev ? student : prev.id === student.id ? null! : student);
	}

	return (
		<div className="report-item-container formative-container">
			<div className="irr-summative-container">
				<div className="report__numbers formative-numbers justify-content-center">

					<div className="report__numbers-name">
						<p>Student Name</p>
					</div>

					<div className="report__numbers-container">
						{!isLoading ? answers.map((answer: any, i: number) => ( i<5 && 
							<span
								style={{ cursor: 'pointer' }}
								onClick={() => OnClickQuestionNo(answer.id)}
								className={`${questionId === answer.id ? 'question-border' : ''} report__numbers-count`}
							>
								{i + 1}
							</span>
						))
							: <div style={{ marginLeft: '-10px' }}>
								<HeaderSkeleton rowColumns={5} height={28} />
							</div>
						}
					</div>


					<div className="itemResponseReport-Attempt">
						<TableSelectCommon
							customClassName={``}
							isOpen={showDropDown}
							toggleSelect={() => {
								setSHowDropDown(!showDropDown)
							}}
							options={dropDownLabel === "Attempt" ? [{ label: 'Date', value: 'Date' }] : [{ label: 'Attempt', value: 'Attempt' }]}
							onChange={(value: string) => {
								setDropDownLabel(value)
							}
							}
							name={dropDownLabel}
						/>
					</div>

					<div className="report__numbers-score formative-score">
						<span>(# Correct) <br /> Score</span>
					</div>
				</div>

				<table className={`report__itemTable report__formative-table ${FilteredStudents.length > 15 ? 'table-height' : ''}`}>

					<tbody>
						{!FilteredStudents.length && !isLoading &&
							<tr className="dark-gray d-flex justify-content-center">
								<td className="report__table-notFound">No Students Found</td>
							</tr>
						}
						<div>
							{!isLoading ? FilteredStudents?.map((student: any, index: number) => {

								return (
									<Student
										index={index}
										student={student}
										questionId={questionId}
										expandedStudents={expandedStudents}
										onClickExpand={onExpandStudentClicked}
										dropDownLabel={dropDownLabel}
										setIsolatedStudent={onClickStudentName}
										variants = {variants || []}
									/>
								)
							})
								: <div>
									<FormativeBodySkeleton />
								</div>
							}
						</div>
					</tbody>


				</table>

				<Footer
					answers={answers}
					questionId={questionId}
					totalCorrect={total_correct}
					total_students={total_students}
					totalIncorrect={total_incorrect}
					totalStudents={FilteredStudents}
					objectiveNumber={objective_number}
					isLoading={isLoading}
					objective_description={objective_description}
					objective_name={objective_name}
					variants = {variants || []}
				/>
			</div>
		</div>
	)
}

export default Report;