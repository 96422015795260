
import React from "react";
import DeleteIcon from "assets/img/trash-icon.svg";
import CustomModal from "components/common/Modal";
import { Button } from "components/common/buttonComponent";

interface DeleteConfirmationModalProps {
    loading: boolean;
    deleteAllLoading: boolean;
    isShow: boolean;
    headerText: string;
    bodyText: string;
    onCancel: () => void;
    onDelete: (isDeleteAll: boolean) => void;
    isAllSubjectGrade?: boolean;
    subjectGrade?: string;
}

const DeleteToolModal: React.FC<DeleteConfirmationModalProps> = ({
    isShow,
    headerText,
    bodyText,
    loading,
    deleteAllLoading,
    onCancel,
    onDelete,
    isAllSubjectGrade,
    subjectGrade
  }) => {
    return (
      <CustomModal className={isAllSubjectGrade ? 'delete-tool-modal' : ''} centered show={isShow} onEscapeKeyDown={onCancel}>
        <div className="modal__header">
          {!isAllSubjectGrade && (
            <img alt="trash" src={DeleteIcon} className="modal__header-icon" />
          )}
          <h6 className="modal__header-title">{headerText}</h6>
        </div>
        <div className="modal__body">
          <p style={{...(isAllSubjectGrade && {fontSize: 15})}}>{bodyText}</p>
        </div>
        <div className="modal__footer">
          <Button
            buttonText="Cancel"
            onClick={onCancel}
            className='btn cancel-btn'
            disabled={deleteAllLoading || loading}
            type='button'
          />
          <Button
            buttonText={isAllSubjectGrade ? "Delete All" : "Delete"}
            onClick={() => onDelete(true)}
            className='btn danger-btn'
            disabled={deleteAllLoading || loading}
            loading={loading}
            type='button'
            style={{...(isAllSubjectGrade && {width: 138})}}
          />
          {isAllSubjectGrade && (
            <Button
              buttonText={`Delete ${subjectGrade}`}
              onClick={() => onDelete(false)}
              className='btn danger-btn'
              disabled={loading || deleteAllLoading}
              loading={deleteAllLoading}
              type='button'
              style={{...(isAllSubjectGrade && {width: 'auto'})}}
            />
          )}
        </div>
      </CustomModal>
    );
  };

  export default DeleteToolModal