import AttachmentImage from "assets/img/steps-png-icon.svg";
import NoAttachment from "assets/img/black_file.png";
import HtmlParser from "components/common/htmlParser";
import { SupportTicketContext } from "contexts/supportTicket";
import { useContext } from "react";
import { TicketDetailsStatusMapper } from "util/mappers";
import ClipPathGroupSvg from "assets/csvs/ClipPathGroupSvg";
import ScrollingText from "components/common/ScrollingText";

const TicketMessageViewer = () => {
  const {
    onClickReply,
    singleSupportTicket,
    singleMessage,
    supportTicketMessages,
    setSingleMessage,
  } = useContext(SupportTicketContext);
  const { status } = singleSupportTicket || {};
  const { message, reply_of, attachments, sender } = singleMessage || {};
  const { messages } = supportTicketMessages;

  const replyMessage = messages?.find((item: any) => item.id === reply_of);
  const { attachments: replyAttachments } = replyMessage || {};

  const formattedStatus = singleMessage
    ? TicketDetailsStatusMapper[
        status as keyof typeof TicketDetailsStatusMapper
      ]
    : "";

  const onClickViewMessages = () => {
    if (replyMessage) {
      setSingleMessage(replyMessage);
    }
  };

  const onClickFile = (file: any) => {
    if (!file) {
      return;
    }

    const { url, filename } = file;

    if (!url) {
      return;
    }

    const alink = document.createElement("a");
    alink.href = url;
    alink.download = `${filename}` || "file_from_alpha";
    alink.click();
  };

  return (
    <div className="SupportTicket__messageViewer">
      <div className="SupportTicket__messageViewer__header d-flex align-items-center justify-content-between ">
        <h3 className="text-white SupportTicket__messageViewer__text">
          Ticket Message Viewer
        </h3>
        {singleMessage && (
          <button
            onClick={onClickReply as any}
            type="button"
            className="SupportTicket__messageViewer__header__btn"
          >
            Reply
          </button>
        )}
      </div>
      <div className="SupportTicket__messageViewer__body d-flex ">
        <div className="SupportTicket__messageViewer__body__info">
          {singleMessage ? (
            <>
              <div className="d-flex align-items-center justify-content-between tagline flex-wrap ">
                <p className="text-black mb-0">
                  <strong className="me-2">From:</strong> {sender}
                </p>
                <p className="text-black mb-0">
                  <strong className="me-2">Status Change:</strong>{" "}
                  {formattedStatus}
                </p>
              </div>
              <div className="text-black pb-3 pt-2 message-box">
                <HtmlParser html={message || ""} />
              </div>
              {reply_of && (
                <div className="SupportTicket__messageViewer__body__info__replied">
                  <p className="mb-0 fw-bold SupportTicket__messageViewer__body__info__replied__tag">
                    Message Being Replied To
                  </p>
                  <p className=" SupportTicket__messageViewer__body__info__replied__message">
                    <HtmlParser html={replyMessage?.message || ""} />
                  </p>
                  <div className="d-flex align-items-center justify-content-end">
                    {!!replyAttachments?.length &&
                      replyAttachments.length > 0 && (
                        <p className="mb-0 SupportTicket__messageViewer__body__info__replied__attachment">
                          <span
                            style={{
                              marginRight: "4px",
                              position: "relative",
                              bottom: "3px",
                            }}
                          >
                            <ClipPathGroupSvg />
                          </span>
                          {replyAttachments.length} Attachment
                        </p>
                      )}
                    <p
                      onClick={onClickViewMessages}
                      className="mb-0 SupportTicket__messageViewer__body__info__replied__viewMessage cursor-pointer"
                    >
                      View Message
                    </p>
                  </div>
                </div>
              )}
            </>
          ) : (
            <p className="SupportTicket__messageViewer__body__info__selectMessage">
              Please select a message above
            </p>
          )}
        </div>

        {/* <div className="SupportTicket__messageViewer__body__attachments">
          <p className="SupportTicket__messageViewer__body__attachments__text text-white">Attachments</p>
          {
            Array(3).fill(null).map((_, index) => {
              let attatchment = message ? message[`attachment_${index + 1}` as any] : null;
              return <div className="SupportTicket__messageViewer__body__attachments__Image">
                <img src={attatchment ? AttachmentImage : AttachmentImage} alt="pngImage" />
                <p className="mx-auto py-2">add-edit.png</p>
              </div>
            }
            )
          }
        </div> */}

        <div className="SupportTicket__messageViewer__body__attachments">
          <p className="SupportTicket__messageViewer__body__attachments__text text-white">
            Attachments
          </p>
          {Array(3)
            .fill(null)
            .map((_, index) => {
              const file = attachments ? attachments[index]?.filename : null;
              return (
                <div
                  onClick={() => onClickFile(attachments[index])}
                  className={`${
                    file ? "cursor-pointer" : ""
                  } SupportTicket__messageViewer__body__attachments__Image`}
                >
                  <img
                    src={file ? AttachmentImage : NoAttachment}
                    alt="pngImage"
                  />
                <ScrollingText tag="p">
                  {file || ""}
                </ScrollingText>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default TicketMessageViewer;
