import React from 'react'

const titleId = "personIcon"

const PersonIcon = ({width, height}: {width:string, height:string}) => {
  return (
    <svg aria-labelledby={titleId} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 15 21" fill="none">
    <title id={titleId}>Students</title>
    <g clip-path="url(#clip0_3168_7650)">
        <path d="M0.691406 18.0503V14.4503C0.691406 13.8203 0.891406 13.2103 1.27141 12.7103C1.65141 12.2103 2.18141 11.8403 2.79141 11.6703L5.75141 10.8203L7.19141 12.2703L8.64141 10.8203L11.6014 11.6703C12.2014 11.8403 12.7414 12.2103 13.1114 12.7103C13.4914 13.2103 13.6914 13.8203 13.6914 14.4503V18.0503C13.6914 18.2403 13.6514 18.4303 13.5814 18.6003C13.5114 18.7803 13.4014 18.9303 13.2714 19.0703C13.1414 19.2003 12.9814 19.3103 12.8014 19.3803C12.6214 19.4503 12.4414 19.4903 12.2514 19.4903H2.14141C1.95141 19.4903 1.76141 19.4503 1.59141 19.3803C1.41141 19.3103 1.26141 19.2003 1.12141 19.0703C0.991406 18.9403 0.881406 18.7803 0.811406 18.6003C0.741406 18.4203 0.701406 18.2403 0.701406 18.0503H0.691406Z" fill="#4ABCEE" />
        <path d="M0.691406 18.0503V14.4503C0.691406 13.8203 0.891406 13.2103 1.27141 12.7103C1.65141 12.2103 2.18141 11.8403 2.79141 11.6703L5.75141 10.8203L7.19141 12.2703L8.64141 10.8203L11.6014 11.6703C12.2014 11.8403 12.7414 12.2103 13.1114 12.7103C13.4914 13.2103 13.6914 13.8203 13.6914 14.4503V18.0503C13.6914 18.2403 13.6514 18.4303 13.5814 18.6003C13.5114 18.7803 13.4014 18.9303 13.2714 19.0703C13.1414 19.2003 12.9814 19.3103 12.8014 19.3803C12.6214 19.4503 12.4414 19.4903 12.2514 19.4903H2.14141C1.95141 19.4903 1.76141 19.4503 1.59141 19.3803C1.41141 19.3103 1.26141 19.2003 1.12141 19.0703C0.991406 18.9403 0.881406 18.7803 0.811406 18.6003C0.741406 18.4203 0.701406 18.2403 0.701406 18.0503H0.691406Z" stroke="#323233" stroke-width="1.38" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10.8198 4.3102C10.8198 5.0202 10.6098 5.7202 10.2098 6.3202C9.80984 6.9102 9.24984 7.3802 8.58984 7.6502C7.92984 7.9202 7.19984 7.9902 6.49984 7.8602C5.79984 7.7302 5.14984 7.3802 4.64984 6.8702C4.13984 6.3602 3.79984 5.7202 3.65984 5.0202C3.51984 4.3202 3.58984 3.5902 3.86984 2.9302C4.13984 2.2702 4.60984 1.7002 5.19984 1.3102C5.78984 0.910195 6.48984 0.700195 7.20984 0.700195C7.67984 0.700195 8.14984 0.790195 8.58984 0.980195C9.02984 1.1602 9.42984 1.4302 9.75984 1.7602C10.0998 2.1002 10.3598 2.4902 10.5398 2.9302C10.7198 3.3702 10.8098 3.8402 10.8098 4.3102H10.8198Z" fill="#FE9E2C" />
        <path d="M10.8198 4.3102C10.8198 5.0202 10.6098 5.7202 10.2098 6.3202C9.80984 6.9102 9.24984 7.3802 8.58984 7.6502C7.92984 7.9202 7.19984 7.9902 6.49984 7.8602C5.79984 7.7302 5.14984 7.3802 4.64984 6.8702C4.13984 6.3602 3.79984 5.7202 3.65984 5.0202C3.51984 4.3202 3.58984 3.5902 3.86984 2.9302C4.13984 2.2702 4.60984 1.7002 5.19984 1.3102C5.78984 0.910195 6.48984 0.700195 7.20984 0.700195C7.67984 0.700195 8.14984 0.790195 8.58984 0.980195C9.02984 1.1602 9.42984 1.4302 9.75984 1.7602C10.0998 2.1002 10.3598 2.4902 10.5398 2.9302C10.7198 3.3702 10.8098 3.8402 10.8098 4.3102H10.8198Z" stroke="#323233" stroke-width="1.38" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3.58203 19.4906V16.6006" stroke="#323233" stroke-width="1.38" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M10.8203 16.6006V19.4906" stroke="#323233" stroke-width="1.38" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
        <clipPath id="clip0_3168_7650">
            <rect width="14.4" height="20.19" fill="white" />
        </clipPath>
    </defs>
</svg>
  )
}

export default PersonIcon
