import { FC } from "react"
import { HeaderSkeleton, ObjavtiveSec } from "components/common/skeleton/ReportsSkeleton";

interface Props {
    Objectives: any,
    tabClassrooms: any
    objectivesCount: number
    isLoading?: boolean
}

const TableFooter: FC<Props> = ({ Objectives, tabClassrooms, objectivesCount, isLoading = false }) => {


    const proficiencyLevel = (less_proficient_objective_count: number) => {

        const percentage = (less_proficient_objective_count / objectivesCount) * 100

        if (percentage >= 65 && percentage <= 85)
            return "high"
        else if (percentage >= 86 && percentage <= 100)
            return "critical"

        return ""
    }

    return (
        <div className={`summative-objective-analysis__footer summative-objective-analysis__footer-bottom ${Objectives && Objectives?.length > 15 ? 'move-up' : ''}`}>
            <div className="footer-wrapper" >
                <div className="summative-objective-analysis__tested-title below report-name" style={{ marginRight: '5px' }}>
                    <div className="d-flex justify-content-center align-items-center">
                        {isLoading ?
                            <div className="objectiveLoading"><ObjavtiveSec /></div>
                            :
                            <span className="summative-objective-analysis__tested-text" >{'# of Objectives < 33% Proficient'}</span>
                        }
                    </div>
                </div>

                {isLoading ?
                    <div className="footerLoading" style={{ transform: 'translateX(27px)'}}><HeaderSkeleton rowColumns={21} height={88} /></div>
                    :
                    <div className={`d-flex`}>
                        {
                            tabClassrooms?.map((classroom: any, index: number) => {
                                let { less_proficient_objective_count, classroom_name } = classroom
                                return <div className="summative-objective-analysis__tested-questions">
                                    <span className={`summative-objective-analysis__tested-belowData`}>
                                        <span className={`summative-objective-analysis__tested-belowData rounded ${proficiencyLevel(less_proficient_objective_count)}`}><span>{less_proficient_objective_count}</span></span>
                                    </span>
                                    <span className={`summative-objective-analysis__tested-objective`}>
                                    <span className={`summative-objective-analysis__tested-objectiveData`}>
                                        <span>{classroom_name}</span>
                                    </span>
                                    </span>
                                </div>
                            })
                        }
                    </div>
                }
                <div className="summative-objective-analysis__footer-empty"></div>
            </div>
        </div>
    )
}
export default TableFooter