import TicketDetailsHeader from "./ticketDetailsHeader";
import TicketMessageViewer from "./ticketMessageViewer";
import SupportTicketDetailsInfo from "./SupportTicketDetails";
import MessageTable from "./MessageTable";
import ChangeHistoryTable from "./ChangeHistoryTable";
import CommentSection from "./ticketDetailComment";
import { useEffect } from "react";


const SupportTickets = () => {

  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  return (
    <div className="SupportTicket SupportTicketDetails">
      <div className="SupportTicket__TicketInfo">
        <TicketDetailsHeader />
        <div className="tableContainer">
          <SupportTicketDetailsInfo />
        </div>
      </div>
      <div className=" SupportTicketDetails__detailedInfo ">
        <MessageTable />
        <ChangeHistoryTable />
      </div>
      <TicketMessageViewer />
      <CommentSection />
    </div>
  );
};

export default SupportTickets;
