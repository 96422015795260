import HtmlParser from "components/common/htmlParser"
import { useTitleHeight } from "hooks/useTitleHeight";
import { useRef } from "react";

const Title = ({task, onClick}: any) => {
    const taskNameRef = useRef<any>();
    const isLargeHeight = useTitleHeight(task, taskNameRef);
    return (
        <h2
            ref={taskNameRef} 
            onClick={onClick} 
            className={`modal__header-title px-2 ${isLargeHeight ? 'text-adjust' : ''}`}
        >
            <HtmlParser html={task} />
        </h2>
    )
}

export default Title;