import { createContext, useState } from "react"

const initialState: any = {
    tooltip: null!,
    setTooltip: null!,
    classroomId: null!,
    setShowNoScheduledTooltip: null!,
    showNoScheduledTooltip: false,
    setShowNoGoalTooltip: null!,
    showNoGoalTooltip: false
}

export const ClassroomTooltipContext = createContext<any>(null!)


const ClassroomTooltipProvider = ({ children }: any) => {
    // const location = useLocation();
    const [tooltip, setTooltip] = useState<any>(initialState.tooltip);
    const[showNoScheduledTooltip, setShowNoScheduledTooltip] = useState(null!);
    const[showNoGoalTooltip, setShowNoGoalTooltip] = useState(null!);
    const classroomId = Number(sessionStorage.getItem("c_classroom_id"));
    // const isClassroom = location.pathname === '/classroom';


    return <ClassroomTooltipContext.Provider value={{
        setTooltip,
        tooltip,
        classroomId,
        setShowNoScheduledTooltip,
        showNoScheduledTooltip,
        setShowNoGoalTooltip,
        showNoGoalTooltip
    }}>
        {children}
    </ClassroomTooltipContext.Provider>
}
export default ClassroomTooltipProvider