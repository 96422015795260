 const getOrCreateToolTip = (chart: any)=>{
    let tooltipEl = chart.canvas.parentNode.querySelector('div');
    if(!tooltipEl){
      tooltipEl = document.createElement('DIV');
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
      tooltipEl.style.borderRadius = '6px';
      tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .2s ease';
      const table = document.createElement('table');
      table.style.margin = '0px';
  
      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
  
    return tooltipEl;
  
  }

  export const externalToopTipHandler = (context: any)=>{
    const {chart, tooltip} = context;
    const tooltipEl = getOrCreateToolTip(chart);
  
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }
  
    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
       
  
      let tooltipPoints:any = [];
      const dataLabel = tooltip.dataPoints[0].dataset.label;
      const pointData = tooltip.dataPoints?.length ? tooltip.dataPoints[0] : null;
      if(pointData){
        const datasetIndex = pointData.datasetIndex;
        if(datasetIndex === 2){
          const dataIndex = pointData.dataIndex
          const data1 = chart.data.datasets[datasetIndex].data[dataIndex];
          tooltipPoints.push({
            text: `Average Formatives Tested: ${data1.y}`,
            bgColor: chart.data.datasets[datasetIndex].pointBackgroundColor
          })
          let data2 = null;
          const equalLength = (chart.data.datasets[1].data.length === pointData.dataset.data.length || 
                               chart.data.datasets[0].data.length === pointData.dataset.data.length );
          if(equalLength){
            if(chart.data.datasets[1].data.length && !(chart.data.datasets[1].data.every===null)){
            data2 = chart.data.datasets[1].data[dataIndex]
            tooltipPoints.push({
              text: `Math Average Proficiency: ${data2}%`,
              bgColor: chart.data.datasets[1].backgroundColor
            })
  
              }else if(chart.data.datasets[0].data.length && !(chart.data.datasets[0].data.every === null)){
                data2 = chart.data.datasets[0].data[dataIndex]
                tooltipPoints.push({
                  text: `ELA Average Proficiency: ${data2}%`,
                  bgColor: chart.data.datasets[0].backgroundColor
                })
              }
          }else
          if(dataIndex%2){
            const dataset2Index = (dataIndex-1)/2;
            data2 = chart.data.datasets[1].data[dataset2Index]
            tooltipPoints.push({
              text: `Math Average Proficiency: ${data2}%`,
              bgColor: chart.data.datasets[1].backgroundColor
            })
          }else{
            const dataset2Index = dataIndex/2; 
            data2 = chart.data.datasets[0].data[dataset2Index]
            tooltipPoints.push({
              text: `ELA Average Proficiency: ${data2}%`,
              bgColor: chart.data.datasets[0].backgroundColor
            })
          }
          
        }else if(datasetIndex === 0){
          const dataIndex = pointData.dataIndex
          const data1 = chart.data.datasets[datasetIndex].data[dataIndex];
          tooltipPoints.push({
            text: `ELA Average Proficiency: ${data1}%`,
            bgColor: chart.data.datasets[datasetIndex].backgroundColor
          })
          let data2 = null;
          if(dataLabel.includes("ELA")){
            const equalLength = (chart.data.datasets[2].data.length === pointData.dataset.data.length)
            const dataset2Index = equalLength ? dataIndex : (dataIndex * 2);
            data2 = chart.data.datasets[2].data[dataset2Index]
            tooltipPoints.push({
              text: `Average Formatives Tested: ${data2.y}`,
              bgColor: chart.data.datasets[2].pointBackgroundColor
            })
          }
        }else if(datasetIndex === 1){
          const dataIndex = pointData.dataIndex
          const data1 = chart.data.datasets[datasetIndex].data[dataIndex];
          tooltipPoints.push({
            text: `Math Average Proficiency: ${data1}%`,
            bgColor: chart.data.datasets[datasetIndex].backgroundColor
          })
          let data2 = null;
          if(dataLabel.includes("Math")){
            const equalLength = (chart.data.datasets[2].data.length === pointData.dataset.data.length);
            const dataset2Index = equalLength ? dataIndex : (2*dataIndex  + 1);
            
            data2 = chart.data.datasets[2].data[dataset2Index]
            tooltipPoints.push({
              text: `Average Formatives Tested: ${data2.y}`,
              bgColor: chart.data.datasets[2].pointBackgroundColor
            })
          }
  
  
        }
      }
  
      const tableHead = document.createElement('thead');
  
      titleLines.forEach((title: any) => {
        const tr = document.createElement('tr');
        tr.style.borderWidth = "0";
  
        const th = document.createElement('th');
        th.style.borderWidth = "0";
        const text = document.createTextNode(title);
  
        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });
  
      const tableBody = document.createElement('tbody');
      tooltipPoints.forEach((body:any, i:number) => {
        const colors = body.bgColor;
        const span = document.createElement('span');
        span.style.background = colors;
        span.style.borderWidth = '2px';
        span.style.marginRight = '10px';
        span.style.height = '10px';
        span.style.width = '10px';
        span.style.display = 'inline-block';
  
        const tr = document.createElement('tr');
        tr.style.backgroundColor = 'inherit';
        tr.style.borderWidth = "0";
  
        const td = document.createElement('td');
        td.style.borderWidth = "0";
  
        const text = document.createTextNode(body.text);
  
        td.appendChild(span);
        td.appendChild(text);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });
  
      const tableRoot = tooltipEl.querySelector('table');
  
      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
  
      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }
  
    const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
  
    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX-90 + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  
  
  
  }