/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  array-callback-return */

import { useEffect, useMemo, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { getStandardBaseReport } from "redux/actionCreators/classroom";
import { InfoSection } from "./infoSection";
import SearchIconGray from "assets/img/search-icon-gray.svg";
import ReportHeader from "components/common/report/header";
import Record from "./record";
import Details from "./details";
import StudentTableHeader from "./studnetTableHeader";
import BasedReportPrint from "./Print/index";
import AllRecords from "./allRecords";
import { BasedSkeleton } from "components/common/skeleton/ReportsSkeleton";
import { useQuery } from "hooks/useQuery";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";
import { replaceURL } from "util/replaceUrl";

const BasedReport = () => {
  const classroomId = useSelector((state: RootState) => state.classroom.classroomId);

  const [isolatedStudent, setIsolatedStudent] = useState<any>(null!);
  const [search, setSearch] = useState<string>("");
  const [report, setData] = useState<any>(null);
  const [studentsDetails, setDetails] = useState<any>(null);
  const [lang, setLang] = useState<any>("en");
  const [printLang, setPrintLang] = useState<any>("");

  const componentRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [descriptionRowsHeights, setDescriptionRowsHeights] = useState<any>([]);
  const [allRowsHeights, setAllRowsHeights] = useState<any>([]);
  const answerRef = useRef<any>(null);
  const detailsRef = useRef<any>(null);

  const query = useQuery();
  const print = query.get("print");
  const langParam = query.get("lang");
  const detailId = query.get("detailId");

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const selectedClassroomId = (window as any)['classroomId'] || classroomId;
      if(selectedClassroomId){
        const response: any = await getStandardBaseReport(parseInt(selectedClassroomId));
        replaceURL(`/classroom/report/standard-base-report/${selectedClassroomId}`);
        setData(response.data);
      }
    };
    fetchData();
    const detailData = sessionStorage.getItem("std-details") || "";
    setDetails(detailData ? JSON.parse(detailData) : null);
    setLang(sessionStorage.getItem("std-lang") || null);
    return () => {
      sessionStorage.removeItem("std-details");
      sessionStorage.removeItem("std-lang");
    };
  }, [classroomId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { students, classroom_name } = useMemo(() => {
    if (report?.data) {
      setIsLoading(false);
      return {
        ...report.data,
        students: !isolatedStudent
          ? report.data.students.map((item: any) => ({
              ...item,
              isShowIsolated: null,
            }))
          : report.data.students.map((st: any) => ({
              ...st,
              isShowIsolated: st.student_id === isolatedStudent.student_id,
            })),
      };
    }
    return {
      students: [],
      classroom_name: "",
    };
  }, [report, isolatedStudent]);

  const information = useMemo(() => {
    if (search) {
      return students.filter(
        (record: any) =>
          record?.student_name?.toLowerCase().includes(search.toLowerCase()) ||
          record?.student_id?.includes(search.toLowerCase())
      );
    }
    return students;
  }, [students, search]);

  useEffect(() => {
    if (langParam) {
      if (detailId && information) {
        const details = information.find(
          (student: any) => student.id === parseInt(detailId)
        );
        if (details) {
          setDetails(details);
        }
      }
      setPrintLang(langParam);
      setLang(langParam);
    }
  }, [langParam, information]);

  useEffect(() => {
    if (answerRef?.current) {
      let sum = 0;
      const result = [...answerRef.current.childNodes].map(
        (node: any) => node.offsetHeight * 0.68
      );
      setAllRowsHeights(
        students.map((student: any) =>
          result.slice(sum, (sum += student.objectives.length))
        )
      );
    }
  }, [answerRef?.current, students]);

  useEffect(() => {
    if (report && (print || printLang) && !detailId && allRowsHeights.length) {
      handlePrint();
    }
  }, [report, printLang, allRowsHeights]);

  const clickViewStudents = (stdDetails: any, langValue: string) => {
    sessionStorage.setItem("std-details", JSON.stringify(stdDetails));
    sessionStorage.setItem("std-lang", langValue);
    setDetails(stdDetails);
    setLang(langValue);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `StandardBasedReport_${
      studentsDetails ? studentsDetails.student_name : classroom_name
    }_${moment(new Date()).format("M/DD/YY hh:mmA")}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      setPrintLang("");
      print && window.close();
    },
  });

  useEffect(() => {
    if (detailId && studentsDetails && descriptionRowsHeights) {
      handlePrint();
    }
  }, [detailId, studentsDetails, descriptionRowsHeights]);

  let allData: any = { objectives: [] };
  students.map((item: any) => {
    if (allData?.objectives?.length) {
      allData["objectives"] = [...allData.objectives, ...item.objectives];
    } else {
      allData["objectives"] = [...item.objectives];
    }
  });

  const setESPrint = () => {
    setPrintLang("es");
  };

  const setENPrint = () => {
    setPrintLang("en");
  };

  const onClickStudentName = (student: any) => {
    setIsolatedStudent((prev: any) =>
      !prev ? student : prev.id === student.id ? null! : student
    );
  };

  return (
    <>
      {
        <div style={{ visibility: print ? "hidden" : "visible" }}>
          <AllRecords answerRef={answerRef} lang={lang} data={allData} />
          {studentsDetails ? (
            <Details
              data={studentsDetails}
              lang={lang}
              setDetails={setDetails}
              setLang={setLang}
              handlePrint={handlePrint}
              isLoading={isLoading}
              classroom_name={classroom_name}
              detailsRef={detailsRef}
              setDescriptionRowsHeights={setDescriptionRowsHeights}
              answerRowsHeights={descriptionRowsHeights}
            />
          ) : (
            <div className="based-report hide-on-print">
              <ReportHeader
                setPrint={handlePrint}
                reportTitle="Standard Based Report"
                setESPrint={setESPrint}
                setENPrint={setENPrint}
                headerClassName="based-header"
              />
              <div className="based-report-container">
                <div className="based-report__info">
                  <InfoSection />
                </div>

                <div className="based-report__search">
                  {!isLoading && (
                    <img
                      src={SearchIconGray}
                      className="report__search-icon animated-fade"
                      alt="Search"
                    />
                  )}
                  <input
                    className={`form-control report__search-input ${
                      isLoading ? "margin-left" : ""
                    }`}
                    type="search"
                    name="Search"
                    placeholder="Search Students"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>

                <div className="based-report-main">
                  <div className="based-report-table-container">
                    <table className="based-report__table">
                      <StudentTableHeader />
                      <tbody>
                        {!isLoading && information?.length === 0 && (
                          <tr className="based-report__table-row">
                            <td
                              colSpan={4}
                              className="report__table-notFound text-center radius"
                            >
                              No Students Found
                            </td>
                          </tr>
                        )}
                        {!isLoading ? (
                          information?.map((info: any) => {
                            return (
                              <Record
                                info={info}
                                onClickStudentView={clickViewStudents}
                                isESPresent={info?.objectives?.find(
                                  (item: any) =>
                                    item.objective_description_es !== null &&
                                    item.objective_description_es.trim() !== ""
                                )}
                                setIsolatedStudent={onClickStudentName}
                              />
                            );
                          })
                        ) : (
                          <BasedSkeleton columns={4} count={20} height={36} />
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      }

      {((studentsDetails && descriptionRowsHeights) ||
        (students.length && allRowsHeights.length)) && (
        <div ref={componentRef}>
          {(studentsDetails ? [studentsDetails] : students).map(
            (student: any, index: number) => {
              return (
                <div className={`printData ${index && "add-page-break"}`}>
                  <BasedReportPrint
                    data={student.objectives}
                    classroom_name={classroom_name}
                    lang={studentsDetails ? lang : printLang}
                    test_name={`Student: ${student.student_name}`}
                    answerRowsHeights={
                      studentsDetails
                        ? descriptionRowsHeights
                        : allRowsHeights[index]
                    }
                  />
                </div>
              );
            }
          )}
        </div>
      )}
    </>
  );
};
export default BasedReport;
