import EmptyRecord from "components/common/EmptyRecord";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import { SupportTicketSuperAdminContext } from "contexts/supportTicketSuperAdmin";
import { SingleTicketChangeHistory } from "interfaces/supportTicketSuperAdmin";
import moment from "moment";
import { useContext } from "react";
import { StatusClassMapper, TicketDetailsStatusMapper } from "util/mappers";

const ChangeHistoryTable = () => {
  const {
    ticketDetails: { change_history, loading },
  } = useContext(SupportTicketSuperAdminContext);

  return (
    <div className="SupportTicketDetails__detailedInfo__messages">
      <div className="SupportTicketDetails__detailedInfo__messages__header">
        <h2>Change History</h2>
      </div>
      <div className="overflow-auto tableContainer">
        <div className="messageTable">
          <table className="SupportTicketDetails__detailedInfo__messages__table historyTable">
            <thead className="SupportTicketDetails__detailedInfo__messages__table__header">
              <tr>
                <td className="pe-4 column1">Change</td>
                <td className="ps-4 ">Done By</td>
                <td className="px-4">Date & Time</td>
              </tr>
            </thead>
            <tbody className="SupportTicketDetails__detailedInfo__messages__table__body  historyTable__body">
              {loading ? (
                <TableSkeleton columns={3} count={5} height={37} />
              ) : (
                <>
                  {change_history?.length ? (
                    change_history?.map(
                      ({
                        change,
                        done_by,
                        date_time,
                      }: SingleTicketChangeHistory) => {
                        const formattedStatus =
                          TicketDetailsStatusMapper[
                            change as keyof typeof TicketDetailsStatusMapper
                          ] || change;
                        let statusClass =
                          StatusClassMapper[
                            change as keyof typeof StatusClassMapper
                          ];
                        statusClass =
                          statusClass !== undefined ? statusClass : "assigned";
                        return (
                          <tr className="bodyRowHistory">
                            <td className="pe-4 column1">
                              <p className={`mb-0 tags ${statusClass}`}>
                                {formattedStatus}
                              </p>
                            </td>
                            <td className="px-4">{done_by}</td>
                            <td className="px-4">
                              {moment(date_time).format("M-D-YY h:mm A") || ""}
                            </td>
                          </tr>
                        );
                      }
                    )
                  ) : (
                    <EmptyRecord message={"No Changes"} />
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default ChangeHistoryTable;
