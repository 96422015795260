import CustomModal from "components/common/Modal";
import { FC } from "react";


interface Props {
    isShow: boolean;
    closeModal: () => void;
}

const ObjectiveModal: FC<Props> = ({ isShow, closeModal }) => {

    return (
        <CustomModal centered show={isShow} onHide={closeModal}>

            <div className="modal__header">
               <h6 className="modal__header-title">Oklahoma Academic Standards</h6>
            </div>
            <div className="modal__body">
                <p className="modal__body-text mb-0">Each question on the summative assessment is aligned to an OAS objective. For each objective, the number of questions is noted. Hover (or tap if on tablet) to view the full standard name for the objective.</p>
            </div>
            <div className="modal__footer">
                <button
                    className="btn success-btn"
                    onClick={closeModal}
                >
                    Got it
                </button>
            </div>
        </CustomModal>
    )
}
export default ObjectiveModal