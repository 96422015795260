import React from "react";

const LeftArrowWhite = () => {
  return (
    <svg aria-label="arrow" xmlns="http://www.w3.org/2000/svg" width="11" height="17" viewBox="0 0 11 17" fill="none">
    <path d="M11 2.30202L8.65506 0L0 8.4996L8.65506 17L10.9992 14.698L4.68907 8.4996L11 2.30202Z" fill="white"/>
</svg>
  );
};

export default LeftArrowWhite;
