import { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";

const NextButton = ({updateQuizIndex, answer, setCorrectAns, submitAnswer}: any) => {

    const [count, setCount] = useState<any>(null!);
    const intervalRef = useRef<any>(null!);

    const onClickNext = () => {
        submitAnswer();
        setCount(5);
        const intervalId = setInterval(() => {
            setCount((prev: any) => prev-1);
        },1000);
        intervalRef.current = intervalId;
        setCorrectAns(true);
    }

    useEffect(() => {
        if(count === -1){
            updateQuizIndex();
            setCount(null!);
            setCorrectAns(false);
            clearInterval(intervalRef.current);
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    },[count]);

    const isNextButtonDisabled = count!==null || !answer;
    return (
        <button 
            type="button" 
            id="welcome-save-button" 
            className={`btn success-btn btn-lg next-btn ${isNextButtonDisabled && 'btn-disabled'}`}
            onClick={onClickNext}
            disabled={isNextButtonDisabled}
        >
            {
                false 
                ?   <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /> 
                : 
                count === null ? 'Next' : count
            }
        </button>
    )
};

export default NextButton;
