import{ useContext, useState } from "react";
import { TabPanel, Tabs } from "react-tabs";
import ProfessionalDevelopmentHeader from "components/common/professionalDevelopment/Header";
import Goals from "./Goals";
import Resources from "./Resources";
import AssessmentTable from "components/pacingCalender/assessmentTable";
import Tools from './Tools';
import { PdAdminContext } from "contexts/pdAdmin";
import DiscussionIframe from "components/oldprofessionalDevelopment/development/discussions";
import config from "config/config";


const AdminProfessionalDevelopment = () => {
  const [modalDelShow, setModalDelShow] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const {search} = useContext(PdAdminContext);

  const urlToLoad = `${config.defaults.discussions_url}/admin`;

  return (
    <div>
      <div className="adminProfessional">
        <Tabs selectedIndex={tabIndex}>
          <div className="adminProfessional__container">
            <ProfessionalDevelopmentHeader
              tabIndex={tabIndex}
              setTabIndex={setTabIndex}
            />
            <div className="w-100 overflow-auto rounded-8">
              {/* <TabPanel>
                <ThisWeek />
              </TabPanel> */}
              <TabPanel>
                <Resources />
              </TabPanel>
              <TabPanel>
              <div className="w-100 overflow-auto roundedBorder">
                <AssessmentTable
                  modalDelShow={modalDelShow}
                  setModalDelShow={setModalDelShow}
                  search={search}
                />
              </div>
              </TabPanel>
              <TabPanel>
                <Tools />
              </TabPanel>
              <TabPanel>
                <Goals />
              </TabPanel>
              <TabPanel>
                <DiscussionIframe src={urlToLoad}></DiscussionIframe>
              </TabPanel>
            </div>
          </div>
        </Tabs>
      </div>
    </div>
  );
};

export default AdminProfessionalDevelopment;
