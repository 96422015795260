/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { Button } from "../../common/buttonComponent";
import CustomModal from "components/common/Modal";

interface NewAttemptConfirmationModalProps {
  isShow: boolean;
  onClose: () => void;
  onSave: () => void;
  student_name: string;
}

export const NewAttemptConfirmationModal: React.FC<NewAttemptConfirmationModalProps> = ({
  isShow,
  onClose,
  onSave,
  student_name
}) => {
  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={onClose}>
      <div className="modal__header">
        <h6 className="modal__header-title">New Attempt</h6>
      </div>
      <div className="modal__body"><p>{`Enable new attempt for ${student_name}?`}</p></div>
      <div className="modal__footer">
        <Button
          buttonText="Cancel"
          onClick={onClose}
          className="btn cancel-btn"
          type="button"
        />
        <Button
          buttonText="Yes"
          onClick={onSave}
          className="btn success-btn btn-lg"
          type="button"
        />
      </div>
    </CustomModal>
  );
};
