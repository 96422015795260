// eslint-disablenext-line react-hooks/exhaustive-deps
import { useContext, useEffect, useMemo, useState } from "react"
import Lock from "assets/img/use-previous-icon.svg";
import RemoveIcon from "assets/img/remove-icon-bold.svg";
import AddIcon from "assets/img/add-tool-icon.svg";
// import DTM from "assets/img/dtm.png"
import { PdAdminContext } from "contexts/pdAdmin"
import moment from "moment";
import { AddToolModal } from "./addToolModal"
import { ConfirmModal } from "components/common/confirmModal"
import { EmptyRecordPdAdmin } from "components/common/EmptyRecord";
import LoadingSkeleton from "../../../../src/components/professionalDevelopment/tools/loadingSkeleton";
import { TOOLS_LIMIT } from "util/constants";
import ToolTitle from "./toolTitle";
import DeleteToolModal from "./deleteModal";

const ToolsTable = () => {
    const {
        fetchPdAdminTools,
        allMonths,
        gradeSubjects,
        currentMonthIndex,
        currentSubjectIndex,
        tools,
        search,
        deletePdAdminTool,
        putPrevToolsData,
        addTools,
        updateTools,
        fetchToolsIcon,
        toolsIcons,
        resources,
        fetchAllResources
    } = useContext(PdAdminContext);


    const selectedGradeSubject = gradeSubjects.length ? gradeSubjects[currentSubjectIndex] : null;
    const { weeks, loading } = tools;

    const [addWarningModal, setAddWarningModal] = useState<any>(null!);
    const [deleteAllLoading, setDeleteAllLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [selectedWeekData, setSelectedWeekData] = useState<any>(null!);
    const [deleteTool, setDeleteTool] = useState<any>(null!);
    const [prevData, setPrevData] = useState<any>(null!);

    // const WeeksWithFormattedTools = useMemo(() => {

    //     return weeks.map(week => {

    //         const copiedTools = [...week.tools];
    //         const formattedToolsArray = [];

    //         while (copiedTools.length) {
    //             formattedToolsArray.push(copiedTools.splice(0, 2))
    //         }

    //         return {
    //             ...week,
    //             formattedTools: formattedToolsArray
    //         }
    //     })
    // }, [weeks]);

    const formattedWeeks = useMemo(() => {
        if((weeks.length % 2)){
            return [
                ...weeks,
                {
                    date_week_id: "",
                    end_date: "",
                    month: "",
                    pd_week_id: '',
                    previous_exists: false,
                    previous_week_id: null,
                    start_date: "",
                    tools: [],
                    isDummy: true

                }
            ]
        }

        return weeks;
    },[weeks])

    const onDeleteTool = (toolId: number, all_grades_subjects: boolean, week: any, nextIndex: number) => {
        const { date_week_id, tools: weekTools} = week;
        const nextWeekId = weeks.length > nextIndex && weekTools.length === 1 ? weeks[nextIndex].date_week_id : null;
        setDeleteTool({id: toolId, date_week_id, nextWeekId, all_grades_subjects});
    }

    const handleDelete = async (isDeleteAll: boolean) => {
        const { id, date_week_id, nextWeekId, all_grades_subjects } = deleteTool;
        isDeleteAll ? setDeleteLoading(true) : setDeleteAllLoading(true);
        if(!all_grades_subjects || (all_grades_subjects && isDeleteAll)){
            const isDeleted = await deletePdAdminTool({id}, date_week_id, nextWeekId);
            isDeleted && setDeleteTool(null!);
        }else{
            const isDeleted = await deletePdAdminTool(
                {id, grade_id: selectedGradeSubject?.grade_id, subject_id: selectedGradeSubject?.subject_id}, 
                date_week_id, 
                nextWeekId
            );
            isDeleted && setDeleteTool(null!);
        }
        
        isDeleteAll ? setDeleteLoading(false) : setDeleteAllLoading(false);
    }

    useEffect(() => {
        fetchToolsIcon();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (allMonths.months.length > 0 && gradeSubjects.length > 0) {
            fetchPdAdminTools();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allMonths, gradeSubjects, currentMonthIndex, currentSubjectIndex, search]
    );

    const addPrevToolsData = async (payload: any) => {
        const isSuccess = await putPrevToolsData(payload);
        return isSuccess;
    }

    const onClickPrev = (has_all_grade_subject_data_previous: boolean, date_week_id: number, previous_week_id: number, formattedTools: any[],  formattedDate: string) => {
        const isAlreadyExists = formattedTools.length > 0;

        if(has_all_grade_subject_data_previous || isAlreadyExists){
            setPrevData({ has_all_grade_subject_data_previous, date_week_id, previous_week_id, formattedDate, month: allMonths?.months[currentMonthIndex]?.month.toUpperCase() || ''  })
        }else{
            addPrevToolsData({ date_week_id, previous_week_id, month: allMonths?.months[currentMonthIndex]?.month.toUpperCase() || '' })
        }

    }

    const addToolsApi = async (payload: any) => {
        const tools = await addTools(payload);
        setSelectedWeekData(null!);
        setAddWarningModal(null!)
        return tools;
    }

    const onAddTool = async (data: any) => {
        const { description, index, ...otherData } = data;
        const { pd_week_id, date_week_id } = selectedWeekData;
        // console.log('otherData', otherData);

        const payload = {
            pd_week_id,
            date_week_id,
            ...otherData
        }

        if(data?.all_grades_subjects){
            setAddWarningModal(payload);
        }else{
            const tools = await addToolsApi(payload);
            return !!tools;
        }
    }

    const onUpdateTools = async (data: any) => {
        const { description, index, ...otherData } = data;
        const { pd_week_id, date_week_id } = selectedWeekData;

        const payload = {
            pd_week_id,
            date_week_id,
            ...otherData
        }

        const tools = await updateTools(payload);
        return tools;
    }

    const onSubmitConfirm = async () => {
        const {has_all_grade_subject_data, ...rest} = prevData;
        const success = await addPrevToolsData(rest);
        success && setPrevData(null!);
    }

    const onClickEditTool = async (data: any) => {
        setSelectedWeekData({...data, isEdit: true});   
    }

    return (
        <>
            <div className="adminProfessional__container__thisWeek">
                {loading ? (
                    [1,2,3,4,5,6].map(id => {
                        return (
                            <div key={id} className="adminProfessional__container__thisWeek__boxes">
                                <div className="d-flex align-items-center adminProfessional__container__thisWeek__boxes__contentHeader">
                                    <div>
                                        <div className="d-flex align-items-center justify-content-end bttns">
                                            <div className="d-flex align-items-center">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="adminProfessional__container__thisWeek__boxes__contentBody tools-body">
                                    <div className="adminProfessional__container__thisWeek__boxes__contentBody__bodyText addToolBox">
                                        <div className="d-flex align-items-center flex-wrap gy-2">
                                            <LoadingSkeleton isLoading />
                                            <LoadingSkeleton isLoading />
                                            <LoadingSkeleton isLoading />
                                            <LoadingSkeleton isLoading />
                                            <LoadingSkeleton isLoading />
                                            <LoadingSkeleton isLoading />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                ):formattedWeeks.length > 0
                    ? formattedWeeks.map((week, weekIndex: number) => {
                        const { has_all_grade_subject_data_previous, start_date, end_date, previous_exists, date_week_id, previous_week_id, isDummy } = week as any;
                        const formattedDate = `${moment(start_date).format("MMMM D")} - ${moment(end_date).format("MMMM D")}`;
                        // const boxBgColor = darkBoxesIndices.includes(weekIndex+1) ? '#496faa' : '#537ab7'
                        return (
                            <div key={date_week_id} className="adminProfessional__container__thisWeek__boxes">
                                <div className="d-flex align-items-center adminProfessional__container__thisWeek__boxes__contentHeader">
                                    <p>{!isDummy ? formattedDate : ''}</p>
                                    {/* {!isDummy && <p>Add/Previous</p>} */}
                                    {!isDummy &&
                                        <div>
                                            <div className="d-flex align-items-center justify-content-end bttns">
                                                <div className="d-flex align-items-center">
                                                    <button disabled={week.tools.length >= TOOLS_LIMIT} onClick={() => week.tools.length < TOOLS_LIMIT && setSelectedWeekData({ ...week, formattedDate })} type="button" className="addbttn">
                                                        <img src={AddIcon} alt="Add" />
                                                    </button>
                                                    <button onClick={() => onClickPrev(has_all_grade_subject_data_previous, date_week_id, previous_week_id, week.tools, formattedDate)} className="position-relative" type="button" disabled={!previous_exists || !!search}>
                                                        <img src={Lock} alt="Lock" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>}
                                </div>
                                <div className="adminProfessional__container__thisWeek__boxes__contentBody tools-body">
                                    <div className="adminProfessional__container__thisWeek__boxes__contentBody__bodyText addToolBox">
                                        <div className={`tools-section-responsive d-flex align-items-center flex-wrap gy-2`}>
                                            {week.tools.length > 0 ? week.tools.map((tool: any, index: number) => {
                                                const { id, label, tools_icon } = tool;
                                                return (
                                                    <div className="option">
                                                        <div>
                                                            <button onClick={() => onDeleteTool(id, tool.all_grades_subjects, week, weekIndex + 1)} type="button">
                                                                <img src={RemoveIcon} alt="Close" />
                                                            </button>
                                                            <img tabIndex={0} role="button" onKeyDown={(e) => {
                                                                if (e.key === 'Enter') {
                                                                    e.stopPropagation();
                                                                    onClickEditTool({ tool, formattedDate, pd_week_id: week.pd_week_id, date_week_id: week.date_week_id })
                                                                }
                                                            }}
                                                                onClick={() => onClickEditTool({ tool, formattedDate, pd_week_id: week.pd_week_id, date_week_id: week.date_week_id })} className="cursor-pointer" src={tools_icon.url} alt="Aplus" />

                                                        </div>
                                                        <ToolTitle label={label} />
                                                    </div>
                                                )
                                            }) : (
                                                <>
                                                    <LoadingSkeleton />
                                                    <LoadingSkeleton />
                                                    <LoadingSkeleton />
                                                    <LoadingSkeleton />
                                                    <LoadingSkeleton />
                                                    <LoadingSkeleton />
                                                </>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    })
                    :
                    <EmptyRecordPdAdmin />
                }
            </div>
            {!!deleteTool && (
                <DeleteToolModal
                    loading={deleteLoading}
                    deleteAllLoading={deleteAllLoading}
                    isShow
                    headerText="Delete Tool"
                    bodyText={`${deleteTool?.all_grades_subjects ? `Delete for all subjects/grades or just ${selectedGradeSubject?.name}?` : 'Are you sure you want to delete this tool?'}`}
                    onCancel={() => setDeleteTool(null!)}
                    onDelete={handleDelete}
                    isAllSubjectGrade={deleteTool?.all_grades_subjects}
                    subjectGrade={selectedGradeSubject?.name}
                />
            )}
            {!!selectedWeekData && !addWarningModal && (
                <AddToolModal
                    isShow
                    onCancel={() => {
                        setSelectedWeekData(null!);
                    }}
                    onAdd={onAddTool}
                    onUpdate ={onUpdateTools}
                    data={selectedWeekData}
                    gradeAndSubjects={gradeSubjects}
                    currSubAndGradeIndex={currentSubjectIndex}
                    resources={resources}
                    fetchAllResources={fetchAllResources}
                    toolsIcons={toolsIcons}
                    key={selectedWeekData}
                />
            )}
            {!!prevData && (
                <ConfirmModal
                    isShow
                    onCancel={() => setPrevData(null!)}
                    onSubmit={onSubmitConfirm}
                    headerText={prevData?.has_all_grade_subject_data ? "Previous All Subjects and Grades" : "Replace Tools with Previous"}
                    bodyText={
                        prevData?.has_all_grade_subject_data
                            ? 'The Previous week has at least one tool that is for all subjects and grades. The copied tool(s) will be for all subjects and grades in the target week also.'
                            : `${prevData.formattedDate} tools will be overwritten. Are you sure?`
                    }
                    submitButtonName={prevData?.has_all_grade_subject_data ? 'Copy' : 'Confirm'}
                    type={prevData?.has_all_grade_subject_data ? "success" : ''}
                />
            )}
            {addWarningModal && (
                <ConfirmModal
                    isShow
                    onCancel={() => {
                        const toolIcon = toolsIcons.find((item: any) => item.id === addWarningModal?.tool_icon_id)
                        setSelectedWeekData((prev: any) => {
                            // console.log('prev', prev);
                            return {...prev, isAdd: true, tool: {...addWarningModal, tools_icon: toolIcon || addWarningModal.tool_icon}}
                        })
                        setAddWarningModal(null!);

                    }}
                    onSubmit={() => addToolsApi(addWarningModal)}
                    headerText="Add to All Subjects and Grades"
                    bodyText={`This will add the tool to all subjects and grades for the week of ${selectedWeekData?.formattedDate} if there is an available slot.`}
                    submitButtonName='Add'
                    type="success"
                />
            )}
        </>
    )
};

export default ToolsTable;
