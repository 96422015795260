/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import EditIcon from "assets/img/pencil-icon.svg";
import WarningIcon from "assets/img/warning-icon.png";
import DeleteIcon from "assets/img/trash-icon.svg";
import SearchIcon from "assets/img/search-icon.svg";

import {
  deleteClass,
  deleteSchool,
  deleteStudent,
  updateClass,
  updateStudent,
} from "redux/actionCreators/class-csv-import";
import {
  ClassDeleteModal,
  SchoolDeleteModal,
  StudentDeleteModal,
} from "components/management/management-delete-modal";
import {
  ClassAndTeacherUpdateModal,
  CsvStudentUpdateModal,
} from "components/management/management-update-modal";
import { LimitSelect } from "components/common/selectComponent";

interface RootState {
  classCsvImport: any;
}

const CsvResult = () => {
  const dispatch = useDispatch();
  const [isFiltered, setFiltered] = useState(true);
  const [limit, setLimit] = useState<string | number>(10);
  const { classCsvImport: csvData } = useSelector((state: RootState) => state);
  const [currentClass, setCurrentClass] = useState<any | null>(null);
  const [classCsvImport, setClassCsvImport] = useState<any | null>(csvData);
  const [currentClassIndex, setCurrentClassIndex] = useState<any | null>(null);
  const [classUpdateModal, setClassUpdateModal] = useState(false);
  const [classDeleteModal, setClassDeleteModal] = useState(false);
  const [stdUpdateModal, setStdUpdateModal] = useState(false);
  const [stdDeleteModal, setStdDeleteModal] = useState(false);
  const [currentStdIndex, setCurrentStdIndex] = useState<any | null>(null);
  const [currentStd, setCurrentStd] = useState<any | null>(null);

  const [currentSchoolIndex, setCurrentSchoolIndex] = useState<any | null>(
    null
  );
  const [currentSchool, setCurrentSchool] = useState<any | null>(null);
  const [schoolDelModal, setSchoolDelModal] = useState(false);

  useEffect(() => {
    const search = (document.getElementById("search-class") as HTMLInputElement)
      ?.value;
    handleSearch(search);
    if (!csvData.clientErrors) {
      setFiltered(false);
    }
  }, [csvData]);

  const doSetSchoolDelModal = (index: number, item: any) => {
    setCurrentSchool(item);
    setCurrentSchoolIndex(index);
    setSchoolDelModal(true);
  };
  const doSetClassModal = (
    schoolIndex: number,
    classIndex: number,
    item: any
  ) => {
    setCurrentClass(item);
    setCurrentClassIndex({ schoolIndex, classIndex });
    setClassUpdateModal(true);
  };

  const doSetDeleteModal = (
    schoolIndex: number,
    classIndex: number,
    item: any
  ) => {
    setCurrentClass(item);
    setCurrentClassIndex({ schoolIndex, classIndex });
    setClassDeleteModal(true);
  };

  const onUpdateClass = (data: any) => {
    let finalData = {
      ...currentClass,
      ...data,
      error: "",
    };
    if (finalData?.grade === "9" || finalData?.grade === "K") {
      finalData.students = [];
    }
    dispatch(updateClass(currentClassIndex, finalData, setClassUpdateModal));
  };

  const onDeleteClass = () => {
    dispatch(deleteClass(currentClassIndex, setClassDeleteModal));
  };

  const doSetStdUpdateModal = (
    schoolIndex: number,
    classIndex: number,
    stdIndex: number,
    item: any
  ) => {
    setCurrentStd(item);
    setCurrentStdIndex({ schoolIndex, classIndex, stdIndex });
    setStdUpdateModal(true);
  };

  const doSetStdDeleteModal = (
    schoolIndex: number,
    classIndex: number,
    stdIndex: number,
    item: any
  ) => {
    setCurrentStd(item);
    setCurrentStdIndex({ schoolIndex, classIndex, stdIndex });
    setStdDeleteModal(true);
  };

  const onUpdateStd = (data: any) => {
    let finalData = {
      ...currentStd,
      ...data,
      error: "",
    };
    dispatch(updateStudent(currentStdIndex, finalData, setStdUpdateModal));
  };

  const onStdDelete = () => {
    dispatch(deleteStudent(currentStdIndex, setStdDeleteModal));
  };

  const onDeleteSchool = () => {
    dispatch(deleteSchool(currentSchoolIndex, setSchoolDelModal));
  };

  const createClassName = (item: any) => {
    let name = `${item?.teacher_last_name} ${item?.teacher_first_name[0]} ${
      item?.subject
    } ${item?.grade} ${item.period_or_hour ? "-" + item.period_or_hour : ""}`;
    return name;
  };

  const handleSearch = (searchValue: string) => {
    const newData = _.cloneDeep(csvData);
    if (
      searchValue &&
      newData.csvResults &&
      searchValue !== "on" &&
      !isFiltered
    ) {
      let csvResults = newData.csvResults.map((row: any) => {
        row.classrooms = row.classrooms.filter((cls: any) => {
          const name = createClassName(cls);
          const search = searchValue.toLowerCase();
          return (
            name.toLowerCase().includes(search) ||
            cls.students.filter(
              (std: any) =>
                std.first_name.toLowerCase().includes(search) ||
                std.last_name.toLowerCase().includes(search)
            ).length > 0
          );
        });
        return row;
      });

      setClassCsvImport({ ...classCsvImport, csvResults });
    } else {
      setClassCsvImport(newData);
    }
  };

  const schoolsList = classCsvImport.csvResults
    ? isFiltered
      ? classCsvImport.csvResults
      : classCsvImport.csvResults.slice(0, limit)
    : [];
  return (
    <div className="section">
      <div className="section-header">
        <h3>Results</h3>
      </div>
      {classCsvImport.csvUploadSuccess ? (
        <div>
          <div className="results light-bg add-radius">
            <div className="results-content">
              <div className="results__header">
                <div className="d-flex align-items-center">
                  <h3 className="results__header-title">Classes</h3>
                  {classCsvImport.clientErrors ? (
                    <img
                      src={WarningIcon}
                      alt="warning-icon"
                      className="mx-2"
                    />
                  ) : (
                    ""
                  )}
                  {classCsvImport.clientErrors ? (
                    <label className="switch switch-small mb-0">
                      <input
                        type="checkbox"
                        onChange={(e) => setFiltered(!isFiltered)}
                        checked={isFiltered}
                        className="switch-checkbox"
                        id="search-class"
                      />
                      <span className="switch-slider switch-danger switch-slider-small"></span>
                    </label>
                  ) : (
                    ""
                  )}

                  <div className="section-search-field results-search">
                    <div className="icon">
                      <a href="/#">
                        <img src={SearchIcon} alt="search-icon" />
                      </a>
                    </div>
                    <input
                      type="text"
                      className="form-control results-input"
                      placeholder="Search Classes or Students"
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </div>
                </div>

                {!isFiltered && (
                  <div className="d-flex align-items-center">
                    <span>Show</span>
                    <LimitSelect
                      disableHandler={false}
                      onChangeHandler={(e: any) => setLimit(Number(e?.value))}
                    />
                    <span>Schools</span>
                  </div>
                )}
              </div>
              {schoolsList.length
                ? schoolsList.map((school: any, i: number) => {
                    const notShowSchool =
                      school?.classrooms.filter((itemt: any, i: number) => {
                        if (
                          itemt.error ||
                          itemt.students.filter((std: any) => std.error).length
                        ) {
                          return true;
                        }
                        return false;
                      }).length > 0 || school.error;
                    return (
                      <>
                        <div
                          className={`results__subheader ${
                            isFiltered && !notShowSchool ? "d-none" : ""
                          }`}
                        >
                          <div className="results__subheader-heading">
                            <p className="results__subheader-title">
                              {school?.school_name}
                            </p>
                            <img
                              onClick={() =>
                                doSetSchoolDelModal(school.index, school)
                              }
                              src={DeleteIcon}
                              alt=""
                              role="button"
                            />
                          </div>
                          <p className="results__subheader-error">
                            {school.error}
                          </p>
                        </div>
                        {school?.classrooms.length
                          ? school?.classrooms.map((item: any, i: number) => {
                              if (isFiltered) {
                                if (
                                  item.error ||
                                  item.students.filter((std: any) => std.error)
                                    .length
                                ) {
                                  return (
                                    <div className="results-table-container">
                                      <div className="table-responsive">
                                        <table className="results__table">
                                          <thead>
                                            <tr className="results__table-row blue-row">
                                              <th className="results__table-name">
                                                Class Name
                                              </th>
                                              <th className="results__table-grade">
                                                Grade
                                              </th>
                                              <th className="results__table-subject">
                                                Subject
                                              </th>
                                              <th className="results__table-teacher">
                                                Teacher Last
                                              </th>
                                              <th className="results__table-firstName">
                                                First Name
                                              </th>
                                              <th className="results__table-email">
                                                Email
                                              </th>
                                              <th className="results__table-options">
                                                Options
                                              </th>
                                              <th className="results__table-error">
                                                Error
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              key={i}
                                              className={
                                                i % 2 !== 0
                                                  ? "results__table-row"
                                                  : "results__table-row"
                                              }
                                            >
                                              <td className="name-data">
                                                <div>
                                                  {item.error ? (
                                                    <img
                                                      style={{
                                                        transform:
                                                          "translateX(-20px)",
                                                      }}
                                                      src={WarningIcon}
                                                      alt="warning-icon"
                                                      className="mx-2"
                                                    />
                                                  ) : (
                                                    <div></div>
                                                  )}
                                                  {/* {item?.teacher_last_name +
                                                      item?.subject +
                                                      item.grade}
                                                    {item?.period_or_hour
                                                      ? `-${item?.period_or_hour}`
                                                      : ""} */}
                                                  {createClassName(item)}
                                                </div>
                                              </td>
                                              <td className="text-center">
                                                <div>
                                                  {item?.grade ? (
                                                    item?.grade === "9" ? (
                                                      "K"
                                                    ) : (
                                                      item?.grade
                                                    )
                                                  ) : (
                                                    <span className="error-ball"></span>
                                                  )}
                                                </div>
                                              </td>
                                              <td className="subject-data">
                                                <div>
                                                  {item?.subject ? (
                                                    item?.subject
                                                  ) : (
                                                    <span className="error-ball"></span>
                                                  )}
                                                </div>
                                              </td>
                                              <td className="text-right">
                                                <div>
                                                  {item?.teacher_last_name ? (
                                                    item?.teacher_last_name
                                                  ) : (
                                                    <span className="error-ball"></span>
                                                  )}
                                                </div>
                                              </td>
                                              <td className="firstName-data">
                                                <div>
                                                  {item?.teacher_first_name ? (
                                                    item?.teacher_first_name
                                                  ) : (
                                                    <span className="error-ball"></span>
                                                  )}
                                                </div>
                                              </td>
                                              <td>
                                                <div>
                                                  {item?.teacher_email ? (
                                                    item.teacher_email
                                                  ) : (
                                                    <span className="error-ball"></span>
                                                  )}
                                                </div>
                                              </td>
                                              <td>
                                                <div className="d-flex justify-content-start align-items-center">
                                                  <img
                                                    onClick={() =>
                                                      doSetClassModal(
                                                        school.index,
                                                        item.index,
                                                        item
                                                      )
                                                    }
                                                    src={EditIcon}
                                                    alt="Edit"
                                                    className="management__table-editBtn"
                                                  />
                                                  <img
                                                    onClick={() =>
                                                      doSetDeleteModal(
                                                        school.index,
                                                        item.index,
                                                        item
                                                      )
                                                    }
                                                    src={DeleteIcon}
                                                    alt="Delete"
                                                    className="management__table-editBtn"
                                                  />
                                                </div>
                                              </td>
                                              <td className="">
                                                <p className="invalid-text">
                                                  {item?.error}
                                                </p>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      <div className="results__nested-container">
                                        <div className="table-responsive results__nested-table">
                                          <table className="nested__table">
                                            <thead>
                                              <tr className="nested__table-row blue-row rounded">
                                                <th className="nested__table-count">
                                                  <div>
                                                    {item?.students?.length}
                                                  </div>
                                                </th>
                                                <th className="nested__table-student">
                                                  Student Last
                                                </th>
                                                <th className="nested__table-firstName">
                                                  First Name
                                                </th>
                                                <th className="nested__table-id">
                                                  Student ID
                                                </th>
                                                <th className="nested__table-notes">
                                                  Notes
                                                </th>
                                                <th className="nested__table-options">
                                                  Options
                                                </th>
                                                <th className="nested__table-error">
                                                  Error
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {item?.students &&
                                              item.students.length
                                                ? item.students.map(
                                                    (
                                                      student: any,
                                                      j: number
                                                    ) => {
                                                      if (isFiltered) {
                                                        if (student?.error) {
                                                          return (
                                                            <tr
                                                              key={j}
                                                              className={
                                                                j % 2 !== 0
                                                                  ? "nested__table-row "
                                                                  : "nested__table-row dark-row"
                                                              }
                                                            >
                                                              <td>
                                                                {student?.error ? (
                                                                  <img
                                                                    src={
                                                                      WarningIcon
                                                                    }
                                                                    alt="warning-icon"
                                                                    className="mx-1"
                                                                    style={{
                                                                      transform:
                                                                        "translateX(0px)",
                                                                    }}
                                                                  />
                                                                ) : (
                                                                  ""
                                                                )}
                                                              </td>
                                                              <td className="text-right">
                                                                <div>
                                                                  {student.last_name ? (
                                                                    student?.last_name
                                                                  ) : (
                                                                    <span className="error-ball"></span>
                                                                  )}
                                                                </div>
                                                              </td>
                                                              <td className="firstName-data">
                                                                <div>
                                                                  {student?.first_name ? (
                                                                    student?.first_name
                                                                  ) : (
                                                                    <span className="error-ball"></span>
                                                                  )}
                                                                </div>
                                                              </td>
                                                              <td className="">
                                                                <div>
                                                                  {student?.student_id ? (
                                                                    student?.student_id
                                                                  ) : (
                                                                    <span className="error-ball"></span>
                                                                  )}
                                                                </div>
                                                              </td>
                                                              <td className="">
                                                                {student?.Notes
                                                                  ? student?.Notes
                                                                  : null}
                                                              </td>
                                                              <td>
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                  <img
                                                                    onClick={() =>
                                                                      doSetStdUpdateModal(
                                                                        school.index,
                                                                        item.index,
                                                                        student.index,
                                                                        student
                                                                      )
                                                                    }
                                                                    src={
                                                                      EditIcon
                                                                    }
                                                                    alt="Edit"
                                                                    className="management__table-editBtn"
                                                                  />
                                                                  <img
                                                                    onClick={() =>
                                                                      doSetStdDeleteModal(
                                                                        school.index,
                                                                        item.index,
                                                                        student.index,
                                                                        student
                                                                      )
                                                                    }
                                                                    src={
                                                                      DeleteIcon
                                                                    }
                                                                    alt="Delete"
                                                                    className="management__table-editBtn"
                                                                  />
                                                                </div>
                                                              </td>
                                                              <td className="">
                                                                <p className="invalid-text">
                                                                  {
                                                                    student?.error
                                                                  }
                                                                </p>
                                                              </td>
                                                            </tr>
                                                          );
                                                        }
                                                      } else {
                                                        return (
                                                          <tr
                                                            key={j}
                                                            className={
                                                              j % 2 !== 0
                                                                ? "nested__table-row "
                                                                : "nested__table-row dark-row"
                                                            }
                                                          >
                                                            <td>
                                                              {student?.error ? (
                                                                <img
                                                                  src={
                                                                    WarningIcon
                                                                  }
                                                                  alt="warning-icon"
                                                                  className="mx-1"
                                                                  style={{
                                                                    transform:
                                                                      "translateX(0px)",
                                                                  }}
                                                                />
                                                              ) : (
                                                                ""
                                                              )}
                                                            </td>
                                                            <td className="text-right">
                                                              <div>
                                                                {student.last_name ? (
                                                                  student?.last_name
                                                                ) : (
                                                                  <span className="error-ball"></span>
                                                                )}
                                                              </div>
                                                            </td>
                                                            <td className="firstName-data">
                                                              <div>
                                                                {student?.first_name ? (
                                                                  student?.first_name
                                                                ) : (
                                                                  <span className="error-ball"></span>
                                                                )}
                                                              </div>
                                                            </td>
                                                            <td className="">
                                                              <div>
                                                                {student?.student_id ? (
                                                                  student?.student_id
                                                                ) : (
                                                                  <span className="error-ball"></span>
                                                                )}
                                                              </div>
                                                            </td>
                                                            <td className="">
                                                              {student?.Notes
                                                                ? student?.Notes
                                                                : null}
                                                            </td>
                                                            <td>
                                                              <div className="d-flex justify-content-start align-items-center">
                                                                <img
                                                                  onClick={() =>
                                                                    doSetStdUpdateModal(
                                                                      school.index,
                                                                      item.index,
                                                                      student.index,
                                                                      student
                                                                    )
                                                                  }
                                                                  src={EditIcon}
                                                                  alt="Edit"
                                                                  className="management__table-editBtn"
                                                                />
                                                                <img
                                                                  onClick={() =>
                                                                    doSetStdDeleteModal(
                                                                      school.index,
                                                                      item.index,
                                                                      student.index,
                                                                      student
                                                                    )
                                                                  }
                                                                  src={
                                                                    DeleteIcon
                                                                  }
                                                                  alt="Delete"
                                                                  className="management__table-editBtn"
                                                                />
                                                              </div>
                                                            </td>
                                                            <td className="">
                                                              <p className="invalid-text">
                                                                {student?.error}
                                                              </p>
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    }
                                                  )
                                                : ""}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      {/* <br /> */}
                                    </div>
                                  );
                                }
                              } else {
                                return (
                                  <div className="results-table-container">
                                    <div className="table-responsive">
                                      <table className="results__table">
                                        <thead>
                                          <tr className="results__table-row blue-row">
                                            <th className="results__table-name">
                                              Class Name
                                            </th>
                                            <th className="results__table-grade">
                                              Grade
                                            </th>
                                            <th className="results__table-subject">
                                              Subject
                                            </th>
                                            <th className="results__table-teacher">
                                              Teacher Last
                                            </th>
                                            <th className="results__table-firstName">
                                              First Name
                                            </th>
                                            <th className="results__table-email">
                                              Email
                                            </th>
                                            <th className="results__table-options">
                                              Options
                                            </th>
                                            <th className="results__table-error">
                                              Error
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            key={i}
                                            className={
                                              i % 2 !== 0
                                                ? "results__table-row"
                                                : "results__table-row"
                                            }
                                          >
                                            <td>
                                              <div className="name-data">
                                                {item.error ? (
                                                  <img
                                                    style={{
                                                      transform:
                                                        "translateX(-20px)",
                                                    }}
                                                    src={WarningIcon}
                                                    alt="warning-icon"
                                                    className="mx-2"
                                                  />
                                                ) : (
                                                  <div></div>
                                                )}
                                                {/* {item?.teacher_last_name +
                                                    item?.subject +
                                                    item.grade}
                                                  {item?.period_or_hour
                                                    ? `-${item?.period_or_hour}`
                                                    : ""} */}
                                                {createClassName(item)}
                                              </div>
                                            </td>
                                            <td className="text-center">
                                              <div>
                                                {item?.grade ? (
                                                  item?.grade === "9" ? (
                                                    "K"
                                                  ) : (
                                                    item?.grade
                                                  )
                                                ) : (
                                                  <span className="error-ball"></span>
                                                )}
                                              </div>
                                            </td>
                                            <td className="subject-data">
                                              <div>
                                                {item?.subject ? (
                                                  item?.subject
                                                ) : (
                                                  <span className="error-ball"></span>
                                                )}
                                              </div>
                                            </td>
                                            <td className="text-right">
                                              <div>
                                                {item?.teacher_last_name ? (
                                                  item?.teacher_last_name
                                                ) : (
                                                  <span className="error-ball"></span>
                                                )}
                                              </div>
                                            </td>
                                            <td className="firstName-data">
                                              <div>
                                                {item?.teacher_first_name ? (
                                                  item?.teacher_first_name
                                                ) : (
                                                  <span className="error-ball"></span>
                                                )}
                                              </div>
                                            </td>
                                            <td>
                                              <div>
                                                {item?.teacher_email ? (
                                                  item.teacher_email
                                                ) : (
                                                  <span className="error-ball"></span>
                                                )}
                                              </div>
                                            </td>
                                            <td>
                                              <div className="d-flex justify-content-start align-items-center">
                                                <img
                                                  onClick={() =>
                                                    doSetClassModal(
                                                      school.index,
                                                      item.index,
                                                      item
                                                    )
                                                  }
                                                  src={EditIcon}
                                                  alt="Edit"
                                                  className="management__table-editBtn"
                                                />
                                                <img
                                                  onClick={() =>
                                                    doSetDeleteModal(
                                                      school.index,
                                                      item.index,
                                                      item
                                                    )
                                                  }
                                                  src={DeleteIcon}
                                                  alt="Delete"
                                                  className="management__table-editBtn"
                                                />
                                              </div>
                                            </td>
                                            <td className="">
                                              <p className="invalid-text">
                                                {item?.error}
                                              </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="results__nested-container">
                                      <div className="table-responsive results__nested-table">
                                        <table className="nested__table">
                                          <thead>
                                            <tr className="nested__table-row blue-row rounded">
                                              <th className="nested__table-count">
                                                <div>
                                                  {item?.students?.length}
                                                </div>
                                              </th>
                                              <th className="nested__table-student">
                                                Student Last
                                              </th>
                                              <th className="nested__table-firstName">
                                                First Name
                                              </th>
                                              <th className="nested__table-id">
                                                Student ID
                                              </th>
                                              <th className="nested__table-notes">
                                                Notes
                                              </th>
                                              <th className="nested__table-options">
                                                Options
                                              </th>
                                              <th className="nested__table-error">
                                                Error
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {item?.students &&
                                            item.students.length &&
                                            item.grade !== "9" &&
                                            item.grade !== "K" ? (
                                              item.students.map(
                                                (student: any, j: number) => {
                                                  if (isFiltered) {
                                                    if (student?.error) {
                                                      return (
                                                        <tr
                                                          key={j}
                                                          className={
                                                            j % 2 !== 0
                                                              ? "nested__table-row "
                                                              : "nested__table-row dark-row"
                                                          }
                                                        >
                                                          <td>
                                                            {student?.error ? (
                                                              <img
                                                                src={
                                                                  WarningIcon
                                                                }
                                                                alt="warning-icon"
                                                                className="mx-1"
                                                                style={{
                                                                  transform:
                                                                    "translateX(0px)",
                                                                }}
                                                              />
                                                            ) : (
                                                              ""
                                                            )}
                                                          </td>
                                                          <td className="text-right">
                                                            <div>
                                                              {student.last_name ? (
                                                                student?.last_name
                                                              ) : (
                                                                <span className="error-ball"></span>
                                                              )}
                                                            </div>
                                                          </td>
                                                          <td className="firstName-data">
                                                            <div>
                                                              {student?.first_name ? (
                                                                student?.first_name
                                                              ) : (
                                                                <span className="error-ball"></span>
                                                              )}
                                                            </div>
                                                          </td>
                                                          <td className="">
                                                            <div>
                                                              {student?.student_id ? (
                                                                student?.student_id
                                                              ) : (
                                                                <span className="error-ball"></span>
                                                              )}
                                                            </div>
                                                          </td>
                                                          <td className="">
                                                            {student?.Notes
                                                              ? student?.Notes
                                                              : null}
                                                          </td>
                                                          <td>
                                                            <div className="d-flex justify-content-start align-items-center">
                                                              <img
                                                                onClick={() =>
                                                                  doSetStdUpdateModal(
                                                                    school.index,
                                                                    item.index,
                                                                    student.index,
                                                                    student
                                                                  )
                                                                }
                                                                src={EditIcon}
                                                                alt="Edit"
                                                                className="management__table-editBtn"
                                                              />
                                                              <img
                                                                onClick={() =>
                                                                  doSetStdDeleteModal(
                                                                    school.index,
                                                                    item.index,
                                                                    student.index,
                                                                    student
                                                                  )
                                                                }
                                                                src={DeleteIcon}
                                                                alt="Delete"
                                                                className="management__table-editBtn"
                                                              />
                                                            </div>
                                                          </td>
                                                          <td className="">
                                                            <p className="invalid-text">
                                                              {student?.error}
                                                            </p>
                                                          </td>
                                                        </tr>
                                                      );
                                                    }
                                                  } else {
                                                    return (
                                                      <tr
                                                        key={j}
                                                        className={
                                                          j % 2 !== 0
                                                            ? "nested__table-row "
                                                            : "nested__table-row dark-row"
                                                        }
                                                      >
                                                        <td>
                                                          {student?.error ? (
                                                            <img
                                                              src={WarningIcon}
                                                              alt="warning-icon"
                                                              className="mx-1"
                                                              style={{
                                                                transform:
                                                                  "translateX(0px)",
                                                              }}
                                                            />
                                                          ) : (
                                                            ""
                                                          )}
                                                        </td>
                                                        <td className="text-right">
                                                          <div>
                                                            {student.last_name ? (
                                                              student?.last_name
                                                            ) : (
                                                              <span className="error-ball"></span>
                                                            )}
                                                          </div>
                                                        </td>
                                                        <td className="firstName-data">
                                                          <div>
                                                            {student?.first_name ? (
                                                              student?.first_name
                                                            ) : (
                                                              <span className="error-ball"></span>
                                                            )}
                                                          </div>
                                                        </td>
                                                        <td className="">
                                                          <div>
                                                            {student?.student_id ? (
                                                              student?.student_id
                                                            ) : (
                                                              <span className="error-ball"></span>
                                                            )}
                                                          </div>
                                                        </td>
                                                        <td className="">
                                                          {student?.Notes
                                                            ? student?.Notes
                                                            : null}
                                                        </td>
                                                        <td>
                                                          <div className="d-flex justify-content-start align-items-center">
                                                            <img
                                                              onClick={() =>
                                                                doSetStdUpdateModal(
                                                                  school.index,
                                                                  item.index,
                                                                  student.index,
                                                                  student
                                                                )
                                                              }
                                                              src={EditIcon}
                                                              alt="Edit"
                                                              className="management__table-editBtn"
                                                            />
                                                            <img
                                                              onClick={() =>
                                                                doSetStdDeleteModal(
                                                                  school.index,
                                                                  item.index,
                                                                  student.index,
                                                                  student
                                                                )
                                                              }
                                                              src={DeleteIcon}
                                                              alt="Delete"
                                                              className="management__table-editBtn"
                                                            />
                                                          </div>
                                                        </td>
                                                        <td className="">
                                                          <p className="invalid-text">
                                                            {student?.error}
                                                          </p>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                }
                                              )
                                            ) : (
                                              <tr>
                                                {
                                                  <td
                                                    colSpan={7}
                                                    className="no-admin"
                                                  >
                                                    No Students
                                                  </td>
                                                }
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    {/* <br /> */}
                                  </div>
                                );
                              }
                              return null;
                            })
                          : ""}
                      </>
                    );
                  })
                : ""}
            </div>
          </div>
          <ClassDeleteModal
            loading={false}
            isShow={classDeleteModal}
            headerText={`Delete Class`}
            bodyText={`Are you sure you want to delete this class?`}
            onCancel={() => setClassDeleteModal(false)}
            onDelete={onDeleteClass}
          />
          {classUpdateModal && (
            <ClassAndTeacherUpdateModal
              csvClass={currentClass}
              isShow={classUpdateModal}
              loading={false}
              onCancel={() => setClassUpdateModal(false)}
              onUpdate={(data) => onUpdateClass(data)}
            />
          )}
          {stdUpdateModal && (
            <CsvStudentUpdateModal
              isShow={stdUpdateModal}
              loading={false}
              csvStudent={currentStd}
              onCancel={() => setStdUpdateModal(false)}
              onUpdate={(data) => onUpdateStd(data)}
            />
          )}
          <StudentDeleteModal
            loading={false}
            isShow={stdDeleteModal}
            headerText={`Delete ${currentStd?.first_name} ${currentStd?.last_name} `}
            bodyText={`Would you like to remove this student ?`}
            onCancel={() => setStdDeleteModal(false)}
            onDelete={(type) => onStdDelete()}
            fromCsv={true}
          />
          <SchoolDeleteModal
            loading={false}
            isShow={schoolDelModal}
            headerText={`Delete ${currentSchool?.school_name}`}
            bodyText={`Would you like to remove this school ?`}
            onCancel={() => setSchoolDelModal(false)}
            onDelete={onDeleteSchool}
          />
        </div>
      ) : (
        <div className="results__empty light-bg" style={{ borderRadius: 8 }}>
          <p className="results__empty-paragraph">
            Select a district and then upload a file to get started.
          </p>
        </div>
      )}
    </div>
  );
};

export default CsvResult;
