import AssessmentContainer from "components/CMS/Assessment";
import Layout from "components/CMS/Layout";


const Assessment = () => {
    
    return(
        <Layout>
            <AssessmentContainer />
        </Layout>
    );
}

export default Assessment;