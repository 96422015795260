import { CustomSkeleton } from "components/common/skeleton/TableSkeleton";
import GoalModal from "components/oldprofessionalDevelopment/development/goal/goalModal";
import { PdAdminContext } from "contexts/pdAdmin";
import moment from "moment";
import { Fragment, memo, useContext, useEffect, useRef, useState } from "react";
import { getPdAdminSelectedGoal, updateProfessionalDevelopmentGoal } from "services/professionalDevelopment";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import GoalSkeleton from "components/professionalDevelopment/goals/skeleton";
import Card from "components/professionalDevelopment/goals/card";
import Pagination from "components/professionalDevelopment/goals/pagination";
import EditGoalModal from "components/oldprofessionalDevelopment/development/goal/editGoalModal";

const GOALS = [1, 2, 3];

// interface ISaveGoalInstruction {
//   goalId: number;
//   instructional_text: string;
// }

const GoalsTable = () => {
  const {
    fetchPdAdminGoals,
    allMonths,
    gradeSubjects,
    currentMonthIndex,
    currentSubjectIndex,
    goals,
    setGoals,
    search
  } = useContext(PdAdminContext);

  const splideRef = useRef<any>(null);

  // const [isScrolled, setIsScrolled] = useState<any>(null!);
  const [selectedGoal, setSelectedGoal] = useState<any>(null!);
  const [goalDetailsLoading, setGoalDetailsLoading] = useState(false);
  const [displayEditModal, setDisplayEditModal] = useState(false);
  const { weeks, loading } = goals;



  useEffect(() => {
    if (allMonths.months.length > 0 && gradeSubjects.length > 0) {
      fetchPdAdminGoals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMonths,
    gradeSubjects,
    currentMonthIndex,
    currentSubjectIndex,
    search]);

  const onClickGoal = async (goalId: any, date_week_id: number) => {
    const selectedWeek = weeks.find(item => item.date_week_id === date_week_id);
    if(!selectedWeek) return;

    const selectedGoal = selectedWeek.goals.find(goalItem => goalItem.id === goalId);
    if(!selectedGoal) return;
    
    const gradeSubjectData = gradeSubjects[currentSubjectIndex];
    const {grade_id, subject_id} = gradeSubjectData || {};

    if(grade_id && subject_id){
      setSelectedGoal(selectedGoal.id);
      setGoalDetailsLoading(true);
      const selectedGoalDetail = await getPdAdminSelectedGoal(selectedGoal.id, grade_id, subject_id);
      if(selectedGoalDetail) {
        setSelectedGoal(
          {
            ...selectedGoalDetail,
            id: selectedGoal.id
          }
        )
      }
      setGoalDetailsLoading(false);
    }
  }

  const onSaveGoalDetails = async (data: any) => {

    const res = await updateProfessionalDevelopmentGoal({goalId: selectedGoal.id, ...data});
    setSelectedGoal((prevGoal:any) => ({
      ...prevGoal,
      ...data
    }));

    const updatedWeeks = weeks.map(weekItem => {
      return {
        ...weekItem,
        goals: weekItem.goals.map(item => {
          if(item.id === selectedGoal.id){
            return {
              ...item,
              ...data,
            }
          }
  
          return item
        })
      }
    });

    setGoals({
      ...goals,
      weeks: updatedWeeks
    })

    return res;
}

  return (
    <>
      <div className="adminProfessional__container__goals">
        {loading ?
          <CustomSkeleton columns={1} count={4} height={150} />
          : weeks.map((week, index) => {
            const { start_date, end_date, goals, date_week_id } = week;
            const isPagination = goals.length > 3;
            let formattedGoals: any = goals;
            let i = goals.length;
            while (i < 3) {
              formattedGoals.push({ id: i, isSkeleton: true });
              i++;
            }
            return (
              <div key={date_week_id} className="adminProfessional__container__goals__goalsBody">
                  <div className="date">
                    <p>{`${moment(start_date).format("MMM D")} - ${moment(end_date).format("MMM D")}`}</p>
                  </div>
                <div className={`goals-body-scroll`}>
                  <div className="goals-sec">
                    <div className="cardsContainer">

                      {!loading && formattedGoals.length > 0 ? (
                        <Splide
                          ref={splideRef}
                          hasTrack={false}
                          options={{
                            classes: {
                              page: 'splide__pagination__page goals-pagination-dot',
                            
                            },
                            gap: "14px",
                            perPage: 3,
                            pagination: isPagination,
                            arrows: isPagination,
                            breakpoints: {
                              1200: {
                                perPage: 2,
                              },
                              767: {
                                perPage: 1,
                              },
                            },
                          }}
                        >
                          <SplideTrack>
                              {formattedGoals.map((goal: any) => {
                                const { id, isSkeleton } = goal;
                                const completedByHeading = `Complete by ${moment(goal.completed_by).format('MMMM D')}`
                                return (
                                  <SplideSlide key={id}>
                                    {isSkeleton ? (
                                      <GoalSkeleton isLoading={false} />
                                    ) : (
                                      <Card
                                        completedBy={completedByHeading}
                                        heading={goal.goal}
                                        description={goal.description}
                                        tasks={goal.tasks}
                                        goalId={id}
                                        onClickGoal={(goalId: any) => onClickGoal(goalId, date_week_id)}
                                        updateGoalTask={() => {}}
                                        shortDescription={goal?.short_description || ""}
                                        executeAction={false}
                                      />
                                    )}
                                  </SplideSlide>
                                );
                              })}
                          </SplideTrack>
                          
                          <Pagination />
                         
                        </Splide>
                      ) : (
                        GOALS.map((id) => {
                          return (
                            <Fragment key={id}>
                              <GoalSkeleton isLoading={loading} />
                            </Fragment>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )
          })
          
        }
      </div>

      {!!selectedGoal && !displayEditModal && (
        <GoalModal
          selectedGoal={selectedGoal} 
          closeModal={() => setSelectedGoal(null!)}
          closeGoalcompletitionModal={() => {}}
          loading={goalDetailsLoading}
          executeAction={false}
          pdAdminGoal
          setDisplayEditModal={setDisplayEditModal}
        />
      )}

      {displayEditModal && (
        <EditGoalModal
          onSave={onSaveGoalDetails} 
          data={{ 
            title: selectedGoal?.goal, 
            short_description: selectedGoal?.short_description, 
            instructional_text: selectedGoal?.instructional_text, 
            textHeader: 'Edit Goal' 
          }} 
          onCancel={() => setDisplayEditModal(false)} 
        />
      )}
    </>
  );
};
export default memo(GoalsTable);
