import React, { PropsWithChildren } from "react"
import Skeleton from "react-loading-skeleton";

interface IPropsCore {
    isDisabled?: boolean;
    width?: number;
    color?: string;
    isProficient?: boolean;
    isLoading?: boolean;
}
export const ProgressbarCore = ({isDisabled, width, isProficient, color="#306FDC", isLoading, children}:PropsWithChildren<IPropsCore>) => {
    return (
        <div className="progressBlock">
            
            {isLoading ? (
                <>
                    <Skeleton baseColor="#ADADAD" highlightColor="#989898" width={98} height={19} borderRadius={8} style={{margin: "0 auto"}} />
                    <Skeleton baseColor="#7e7e7e66" highlightColor="#989898" width={40} height={18} borderRadius={8}  />
                </>
            ):isDisabled ? (
                <>
                    <div className="no-data-progress-skeleton">
                        <div></div>
                        <p></p>
                    </div>
                </>
            ):(
                <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="98" height="19" viewBox="0 0 98 19" fill="none">
                        <rect y="0.000244141" width="98" height="19" rx="8" fill="#ADADAD"/>
                        <rect y="0.000244141" width={width || 0} height="19" rx="8" fill={isProficient ? '#3C809F' : color} />
                    </svg>
                    {children}
                </>
            )}
        </div>
    )
}

interface IProps extends IPropsCore {
    title?: string;
}
const Progressbar = ({color="#306FDC", isDisabled, title, width, isProficient, isLoading, children}:PropsWithChildren<IProps>) => {
    return (
        <>
            {title ? (
                <div className={`status ${isDisabled ? 'disabled' : ''}`}>
                    {title && <div className="subHeader-fieldLabel">{title}</div>}
                    <ProgressbarCore
                        isDisabled={isDisabled}
                        width={width}
                        color={color}
                        isProficient={isProficient}
                        isLoading={isLoading}
                    >
                        {children}
                    </ProgressbarCore>
                </div>
            ):(
                <ProgressbarCore
                    isDisabled={isDisabled}
                    width={width}
                    color={color}
                    isProficient={isProficient}
                    isLoading={isLoading}
                >
                    {children}
                </ProgressbarCore>
            )}
        </>
        
    )
};

export default Progressbar;
