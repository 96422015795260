import React, { useEffect, useRef, useState } from "react"
import HtmlParser from "../htmlParser";

const Description = ({description, descriptionHeight, onClickGoal}: any) => {
    const descriptionRef = useRef<any>();
    const [blur, setBlur] = useState(false);
    const [descriptionScroll, setDescriptionScroll] = useState(0);

    useEffect(() => {
        if(description && descriptionRef.current){
            setBlur(descriptionRef.current.scrollHeight - descriptionRef.current.clientHeight > 0);
        }

        // eslint-disablenext-line react-hooks/exhaustive-deps
    },[description]);
    return (
        <div
            ref={descriptionRef}
            onScroll={(e: any) => setDescriptionScroll(e.target.scrollTop)} 
            style={{height: descriptionHeight}} 
            className="card-desc"
            onClick={onClickGoal}
        >
            <p className="">
                <HtmlParser
                    html={description || ''}
                />
            </p>
            {blur && descriptionScroll < 5 && (
                <div className="more-data"></div>
            )}
        </div>
    )
};

export default Description;
