/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import { useMemo } from "react";
import Record from "./detailsRecord";
import StudentTableHeader from "./objectiveTableHeader";

const BasedReportAllDetails = (props: any) => {
  const { lang, answerRef } = props;
  const { objectives } = useMemo(() => {
    if (props?.data) {
      return {
        ...props.data,
      };
    }
    return {
      objectives: [],
      student_name: "",
      student_id: "",
      id: 0,
    };
  }, [props]);

  const information = useMemo(() => {
    return objectives;
  }, [objectives]);

  return (
    <div className="based-report" style={{ height: 0, marginTop: 0 }}>
      <div className="based-report-container">
        <div className="based-report__main based-report-main">
          <div className="based-report-detail-container">
            <table lassName="based-report__detail">
              <StudentTableHeader />
              <tbody ref={answerRef}>
                {information?.map((info: any) => {
                  return <Record {...info} lang={lang} />;
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BasedReportAllDetails;
