import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "redux/reducers/combine";

export const useSelectedDistrict = () => {
  const { auth } = useSelector((state: RootState) => state);
  const userRole = auth.currentUser?.role;
  const { pathname } = useLocation();

  const screen =
    pathname.indexOf("activity") > -1
      ? "activity"
      : pathname.indexOf("classroom") > -1 ||
        pathname.indexOf("pacing-calendar") > -1
      ? "classroom"
      : pathname.indexOf("management") > -1
      ? "management"
      : "";

  const getSelectedDistrict = () => {
    if (userRole === "Super Admin" || userRole === "District Admin") {
      return sessionStorage.getItem("district_m_id");
    } else {
      return sessionStorage.getItem("c_district_id");
    }
  };

  switch (screen) {
    case "activity":
      return sessionStorage.getItem("district_id");
    case "classroom":
      return sessionStorage.getItem("c_district_id");
    case "management":
      return getSelectedDistrict();
    default:
      return null;
  }
};
