import moment from "moment";

export const getPdfData = (teamMeetingData: any) => {
    console.log('teamMeetingData.date: ', teamMeetingData.date);
    const date =  moment(teamMeetingData.date).format('M-D-YY');
    console.log('date: ', date);

    const dateParts: any = date ? date.split('-') : [];

    let {school_district, ...filteredTeamMeetingData} = teamMeetingData;
    const schoolDistrictLen = school_district.length;
    school_district = schoolDistrictLen <= 44 ? school_district : school_district.slice(0, 44)+ ' ...';
    const [school, district] = school_district?.split('/') || [];
    const isDistrict = !!district;
    const modifiedTeamData = {
        ...filteredTeamMeetingData,
        mm: dateParts[0] || '',
        dd: dateParts[1] || '',
        yy: dateParts[2] ? dateParts[2] % 100 : '',
        ...(school && {school}),
        ...(isDistrict && {district})
    }

    const teamDataArray = Object.keys(modifiedTeamData).map((key: string) => {
        const value = modifiedTeamData[key]?.toString() || '';

        switch(key){
            case 'formatives_given':
                const total_formatives_digits = value ? value.length : 1;
                return {
                    text: value,
                    x: total_formatives_digits > 1 ? 440 : 444,
                    y: 118,
                    size: 10
                }
            case 'grade_subject':
                return {
                    text: value,
                    x: 355,
                    y: 684,
                    size: value && value?.length > 12 ? 10 : 12
                }
            case 'total_formatives':
                const formatives_given_digits = value ? value.length : 1;
                return {
                    text: value,
                    x: formatives_given_digits > 1 ? 283 : 287,
                    y: 103,
                    size: 10
                }
            case 'date':

                const date =  moment(value).format('M-D-YY');
                return {
                    text: date,
                    x: 474,
                    y: 684,
                    size: 12
                }
            case 'principal':
                return {
                    text: value,
                    x: 380,
                    y: 710,
                    size: 12
                }
            case 'running_proficiency':
                const running_proficiency_digits = value ? value.length : 1;
                return {
                    text: value,
                    x: running_proficiency_digits > 1 ? 346 : 350,
                    y: 139,
                    size: 10
                }
            case 'school':
                return {
                    text: `${value}${isDistrict ? '/' : ''}`,
                    x: 185,
                    y: isDistrict ? 722 : 710,
                    size: 11
                }
            case 'district':
                return {
                    text: value,
                    x: 185,
                    y: 710,
                    size: 11
                }
            case 'teacher':
                return {
                    text: value,
                    x: 115,
                    y: 684,
                    size: 12
                }
            case 'mm':
                return {
                    text: value,
                    x: 394,
                    y: 139,
                    size: 10
                }
            case 'yy':
                return {
                    text: value,
                    x: 439,
                    y: 139,
                    size: 10
                }
            case 'dd':
                return {
                    text: value,
                    x: 415,
                    y: 139,
                    size: 10
                }
            default:
                return {
                    text: '',
                    x: 0,
                    y: 0,
                    size:0
                }
        }
    });

    return teamDataArray;
}