import { ClassroomTooltipContext } from "contexts/classroomTooltip"
import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment"
import { Assessment, ReportsNavigation } from "interfaces/professionalDevelopment"
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import AssessmentBody from "./table/body"
import AssessmentHeader from "./table/header"
import Sidebar from "./table/sidebar"
import { useSearchParams } from "react-router-dom"
import ClassroomTooltip from "components/common/classroomTooltip"
import { useCheckGoalTooltipExists } from "hooks/useGoalTooltip"
// import { TableSelectCommon } from "components/common/selectComponent"
// import { downLoadStlReport } from "redux/actionCreators/management"
import { useDispatch, useSelector } from "react-redux"
import ActionType from "redux/actionTypes"
import { RootState } from "redux/reducers/combine"
import STLDropdown from "components/common/stlDropdown/old"

const Summatives = () => {
    const { classroomId, navigateTo, summatives, isLoading, pdClassroom, prevSTLexists } = useContext(ProfessionalDevelopmentContext);
    const {tooltip} = useContext(ClassroomTooltipContext);

    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const {auth, classroom} = useSelector((state: RootState) => state);

    const tooltipText = searchParams.get("text");
    
    const [openMasterReport, setOpenMasterReport] = useState(searchParams.get("openMasterReport") === 'true');
    const [summativeA1MarkCompleted, setSummativeA1MarkCompleted] = useState(searchParams.get("summativeA1MarkCompleted") === 'true');
    const [summativeBMarkCompleted, setSummativeBMarkCompleted] = useState(searchParams.get("summativeBMarkCompleted") === 'true');
    const [summativeA2AvailableToggle, setSummativeA2AvailableToggle] = useState(searchParams.get("summativeA2AvailableToggle") === 'true')
    const [summativeA2MarkCompleted, setSummativeA2MarkCompleted] = useState(searchParams.get("summativeA2MarkCompleted") === 'true')
    const [summativeBAvailableToggle, setSummativeBAvailableToggle] = useState(searchParams.get("summativeBAvailabilityToggle") === 'true')
    const [showStudentToggleTooltip, setShowStudentToggleTooltip] = useState(searchParams.get("studentAvailableTooltip") === 'true')
    const [cellHeights, setCellHeights] = useState<any>([]);
    // const [STLDropdown, openSTLDropdown] = useState(false);

    const isGoalTooltip = useCheckGoalTooltipExists();

    const tableRef = useRef<any>();

    const scheduledDates = useMemo(() => {
        return summatives.map((summative, index) => {
            let minHeight =  summative.description?.length > 130 ? 126 : 108;
            if(cellHeights[index]){
                minHeight = cellHeights[index]+64;
            }
            return { 
                date: summative.scheduled_date === null ? 'Not Scheduled' : summative.scheduled_date, 
                color: summative.scheduled_color,
                minHeight
            }
        })
    }, [summatives, cellHeights])

    const isAllNotSubmitted = summatives
    ?.map((x: any) => !!parseInt(x.students_assessed.split("/")[0]))
    .find((item: any) => item === true);

    const enableReportButtons = classroomId && isAllNotSubmitted;

    useEffect(() => {
        if(!summatives.length || !tableRef.current){
            return
        }

        setTimeout(() => {
            const table = tableRef.current

            const tBody = table?.getElementsByTagName('tbody')[0];
            const tableRow = tBody?.getElementsByTagName('tr');
            
            let heights = [];
            for (var t = 0; t < tableRow?.length; t++){
                if(tableRow[t].id?.includes("second-row")){
                    heights.push(tableRow[t].clientHeight);
                }
            }
            setCellHeights(heights);
        }, 1000);

    }, [summatives]);

    const clickHandler = useCallback(() => {
        searchParams.delete("text");
        

        if(summativeA1MarkCompleted){
            searchParams.delete("summativeA1MarkCompleted");
            setSummativeA1MarkCompleted(false);
        }
        
        if(openMasterReport){
            searchParams.delete("openMasterReport");
            setOpenMasterReport(false);
        }

        if(summativeBAvailableToggle){
            searchParams.delete("summativeBAvailableToggle");
            setSummativeBAvailableToggle(false);
        }
        
        if(showStudentToggleTooltip){
            searchParams.delete("studentAvailableTooltip");
            setShowStudentToggleTooltip(false);
        }
        
        if(summativeA2MarkCompleted){
            searchParams.delete("summativeA2MarkCompleted");
            setSummativeA2MarkCompleted(false);
        }

        if(summativeA2AvailableToggle){
            searchParams.delete("summativeA2AvailableToggle");
            setSummativeA2AvailableToggle(false);
        }

        if(summativeBMarkCompleted){
            searchParams.delete("summativeBMarkCompleted");
            setSummativeBMarkCompleted(false);
        }
        
        
        setSearchParams(searchParams);
        document.removeEventListener('click', clickHandler);

        /* eslint-disable react-hooks/exhaustive-deps */
    },[]);

    const downloadSTL = async () => {
        // await downLoadStlReport('SCHOOL_ADMIN');
        dispatch({
            type: ActionType.SET_STL_SCHOOL_LOADING,
            payload: true
        });
    }

    useEffect(() => {
        
        if((summativeA1MarkCompleted || openMasterReport || summativeBMarkCompleted || showStudentToggleTooltip || summativeBAvailableToggle || summativeA2MarkCompleted || summativeA2AvailableToggle) && summatives && summatives.length > 0){
            document.addEventListener('click', clickHandler)
            const el = document.getElementById("summative-container");
            el?.scrollIntoView();
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[summatives]);

    useEffect(() => {
        return () => {
            document.removeEventListener('click', clickHandler)
        }
    },[]);

    const requestStl = () => {
        dispatch({
          type: ActionType.SET_STL_CLASS_LOADING,
          payload: true
        });
    };

    const classInfo = classroom?.classrooms.length ? classroom.classrooms[0] : null;
    const isOnlyResourcesClass = classInfo?.students_count === 0 || classInfo?.fk_grade_id === 9;
    const isAnySummativesScheduled = summatives.some((item: any) => item.scheduled_date !== 'Not Scheduled')
    const isShowTabs = pdClassroom.is_enabled || isAnySummativesScheduled;
    
    const isBlur = !!classroomId && (tooltip === 1 || tooltip === 2 || tooltip === 3);
    const isTransparent = !!classroomId && (tooltip === 4 || tooltip === 5 || isGoalTooltip);
    return (
        <div id="summative-container" className="lessons-sec">
            
            <div className="table-header d-flex align-items-center justify-content-between">
                {!!summatives.length && openMasterReport && (
                    <ClassroomTooltip className="ProfessionalDev__devSummary__toolTip open-master-report" message={tooltipText} />
                )}
                <h1 className="lesson-heading">Summative Assessments</h1>
                <div className="d-sm-flex align-items-center headerBtnContainer sumative-dropdown">
                    {auth?.currentUser?.role === 'Classroom Admin' && (classroom?.classrooms?.length === 1 || classroom?.classrooms?.length > 1) && !isOnlyResourcesClass &&
                        <div className="accordion__header-btnContainer">
                            <button className="btn outline-btn outline-btn-lg" onClick={requestStl}>
                                Summative Test Log
                            </button>
                        </div>
                    }
                    {auth?.currentUser?.role === 'School Admin' && (
                        <>
                            {prevSTLexists ? (
                                <>
                                <STLDropdown 
                                    onChange={(selectedValue: any) => {
                                        dispatch({type: ActionType.SET_SCHOOL_STL_PERIOD, payload: selectedValue});
                                        downloadSTL();
                                    }} 
                                />
                                </>
                            ):(
                                <div 
                                    // style={{ ...(!enableReportButtons && { opacity: '0.56' }) }} 
                                    className={`header-btns ms-sm-3 mt-sm-0 mt-3 cursor-pointer`}
                                    onClick={downloadSTL}
                                >
                                    Summative Test Log
                                </div>
                            )}
                        </>
                    )}
                    <div 
                        style={{ ...(!enableReportButtons && { opacity: '0.56' }) }} 
                        className={`header-btns ms-sm-3 mt-sm-0 mt-3 ${enableReportButtons ? 'cursor-pointer' : 'cursor-default'}`}
                        onClick={() => navigateTo(Assessment.summative, ReportsNavigation.MASTER, { enableReportButtons })}
                    >
                        Master Report
                    </div>
                    
                </div>
            </div>
            
            <div className="lesson-divider"></div>
            <div className="d-flex overflow-auto w-100 ">
                {isShowTabs && (
                    <Sidebar 
                        show={!!classroomId && !!scheduledDates.length && !isLoading} 
                        isFormative={false} 
                        dates={scheduledDates}
                    />
                )}
                <div className="summative-table-container">              
                    <div className="table-responsive">

                        {!!summatives.length && summativeA2MarkCompleted && (
                            <ClassroomTooltip arrowDirection="bottom" className="ProfessionalDev__devSummary__toolTip summative-a2-mark-completed" message={tooltipText} />
                        )}

                        {!!summatives.length && showStudentToggleTooltip && (
                            <ClassroomTooltip className="ProfessionalDev__devSummary__toolTip students-toggle" message={tooltipText} />
                        )}
                        {!!summatives.length && summativeA1MarkCompleted && (
                            <ClassroomTooltip className="ProfessionalDev__devSummary__toolTip summative-a1-mark-completed" message={tooltipText} />
                        )}
                        {!!summatives.length && summativeBAvailableToggle && (
                            <ClassroomTooltip className="ProfessionalDev__devSummary__toolTip summativeb-toggle" message={tooltipText} />
                        )}
                        {!!summatives.length && summativeA2AvailableToggle && (
                            <ClassroomTooltip arrowDirection="bottom" className="ProfessionalDev__devSummary__toolTip summative-a2-toggle" message={tooltipText} />
                        )}
                        {!!summatives.length && summativeBMarkCompleted && (
                            <ClassroomTooltip arrowDirection="bottom" className="ProfessionalDev__devSummary__toolTip summative-b-mark-completed" message={tooltipText} />
                        )}
                        
                        <table ref={tableRef} className="summative__table text-white ps-3">
                            <AssessmentHeader isSummative={true} summativeLength={summatives.length} />
                            <AssessmentBody 
                                isSummative={true} 
                                classroomId={classroomId}
                                scheduledDates={scheduledDates}
                                summatives={summatives}
                            />
                        </table>
                    </div>
                </div>
            </div>
            {isBlur && (
                <div className="toolTip-bg"></div>
            )}
            
            {isTransparent && (
                <div className="toolTip-bg toolTip-bg-transparent"></div>
            )}
        </div>
    )
}
export default Summatives