import { SchoolProgressSkeleton } from "components/common/skeleton/GraphSkeleton";
import {ProgressChart} from "./chart";

const FormativeProgressChart = ({classrooms, legendPadding, isLoading, assessmentType}: any) => {
    return (
        <>
        <p className="text-center fw-bold mb-0 chart-title">Formative Progress Chart</p>
            <div className="report hide-on-print">
                
                <div className="report-content">
                    <div style={{...(legendPadding && {paddingBottom: legendPadding}), ...(isLoading && {paddingBottom: 20})}} className="d-flex justify-content-center">
                        <div className="formative-test">
                            <div></div>
                            <p>Formative Tests Taken (#)</p>
                        </div>
                        <div className="running-rate">
                            <div></div>
                            <p>Running Proficiency Rate (%)</p>
                        </div>
                    </div>
                    
                    <div className="report__chart mt-10">
                        <div className="report__chart-container">
                            {isLoading ? (
                                <SchoolProgressSkeleton columns={9} width={'100%'} height={484} customClass={'report__chart-skelton-item'} />
                            ) : (
                                <ProgressChart 
                                    data={classrooms || []}
                                    assessmentType={assessmentType}
                                />
                                
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default FormativeProgressChart;
