import React from "react";
import classNames from "util/classNames";

interface LabelWithToggleProps {
  label?: string;
  onMarkCompleted: (completed: boolean) => void;
  initialCompleted?: boolean;
  isMarkCompletedDisabled?: boolean;
}

const LabelWithToggle: React.FC<LabelWithToggleProps> = ({
  label = "",
  onMarkCompleted,
  initialCompleted = false,
  isMarkCompletedDisabled = false,
}) => {
  const handleToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const completed = e.target.checked;
    onMarkCompleted(completed);
  };

  return (
    <div className="small-common-toggle">
      {label && <p className="text-white">{label}</p>}
      <label
        className={classNames(
          isMarkCompletedDisabled ? "disabled-item" : "",
          "switch studentCheakBox"
        )}
      >
        <input
          tabIndex={0}
          type="checkbox"
          className={"switch-checkbox customFieldsFocused-active"}
          onChange={handleToggleChange}
          onKeyDown={(e: any)=>{
            if(e.key === "Enter"){
              handleToggleChange(
                {
                  ...e,
                  target: {
                    ...e.target,
                    checked: !e.target.checked,
                  },
                }
              )
            }
          }}
          checked={initialCompleted}
          disabled={isMarkCompletedDisabled}
        />
        <span className="switch-slider"></span>
      </label>
    </div>
  );
};

export default LabelWithToggle;
