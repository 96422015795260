import { TableSelectCommon } from "components/common/selectComponent";
import Skeleton from "react-loading-skeleton";

const MontorRowSkeleton = () => {
    return (
        <tr className="monitor__table-row">
            <td className="status-data skeleton">
                <Skeleton
                    baseColor="#00000033"
                    highlightColor="#737373"
                    borderRadius="50%"
                    width={15}
                    height={15}
                />
            </td>

            <td className="text-right name-skeleton">
                <Skeleton
                    baseColor="#00000033"
                    highlightColor="#737373"
                    borderRadius={4}
                    width={120}
                    height={18}
                />
            </td>
            <td className="id-data skeleton">
                <Skeleton
                    baseColor="#00000033"
                    highlightColor="#737373"
                    borderRadius={4}
                    width={70}
                    height={18}
                />
            </td>
            <td className="assessment-label-report skeleton">
                <Skeleton
                    baseColor="#00000033"
                    highlightColor="#737373"
                    borderRadius={4}
                    width={60}
                    height={18}
                />
            </td>
            <td className="text-center attempt-skeleton">
                <Skeleton
                    baseColor="#00000033"
                    highlightColor="#737373"
                    borderRadius={4}
                    width={40}
                    height={18}
                />
            </td>

            <td className="progress-data-formative skeleton">
                <ul className="progress__ratings-large cursor-pointer">
                    <Skeleton
                        baseColor="#00000033"
                        highlightColor="#737373"
                        borderRadius={4}
                        width={24}
                        height={24}
                    />
                    <Skeleton
                        baseColor="#00000033"
                        highlightColor="#737373"
                        borderRadius={4}
                        width={24}
                        height={24}
                    />
                    <Skeleton
                        baseColor="#00000033"
                        highlightColor="#737373"
                        borderRadius={4}
                        width={24}
                        height={24}
                    />
                    <Skeleton
                        baseColor="#00000033"
                        highlightColor="#737373"
                        borderRadius={4}
                        width={24}
                        height={24}
                    />
                    <Skeleton
                        baseColor="#00000033"
                        highlightColor="#737373"
                        borderRadius={4}
                        width={24}
                        height={24}
                    />
                </ul>
            </td>
            <td className="score-data skeleton">
                <Skeleton
                    baseColor="#00000033"
                    highlightColor="#737373"
                    borderRadius={4}
                    width={30}
                    height={18}
                />
            </td>
            <td
                className="options-right-data skeleton"
            >
                <TableSelectCommon
                    isOpen={false}
                    toggleSelect={() => {}}
                    options={[]}
                    onChange={(value: any) => {}}
                />
            </td>
        </tr>
    )
};

export default MontorRowSkeleton;
