import BackArrow from "../../assets/img/backPage.png";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useContext, useRef } from "react";
import { WayToAReportContext } from "contexts/wayToAReport";
import GoalCompletition from "./GoalCompletition";
import AssessmentScheduled from "./AssessmentScheduled";
import RunningProficiency from "./RunningProficiency";
import FormativeProgress from "./formativeProgress";
import Remediation from "./Remediation";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import { months } from "util/index";
import Print from "./print";
import { useReactToPrint } from "react-to-print";
import { useQuery } from "hooks/useQuery";
import { useNavigate } from "react-router-dom";
import ApiModal from "components/common/Reload/ApiModal";

ChartJS.register(ArcElement, Tooltip, Legend);

export const ChartData = {
  data: {
    datasets: [
      {
        label: "# of Votes",
        data: [86, 15],
        backgroundColor: ["#3B792F", "#C44538"],
        borderColor: ["#3B792F", "#C44538"],
        borderWidth: 1,
      },
    ],
  },
  options: {
    plugins: {
      datalabels: {
        display: true,
        align: "top",
        backgroundColor: "#3E3E3E",
        borderRadius: 3,
        color: "#fff",
        font: {
          size: 14,
        },
      },
    },
  },
};

const WayToAReport = () => {
  const { apiErrorModal, wayToAReport, fetchGoalsList, setApiErrorModal } =
    useContext(WayToAReportContext);
  const {
    assessment_schedule,
    goal_completion,
    remediation,
    formative_progress,
    running_proficiency,
    month,
    loading,
  } = wayToAReport;
  const formattedMonth = months.find(
    (m) => m.toLowerCase() === month.toLowerCase()
  );
  const componentRef = useRef<any>(null);
  const query = useQuery();
  const print = query.get("print");
  // const printRef = useRef<any>(true);

  // const [printRef, setPrintRef] = useState(true);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `Way to an A Report: ${formattedMonth}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      console.log("onAfter");
      print && window.close();
      //   setPrintRef(true)
    },
  });

  const navigate = useNavigate();

  return (
    <>
      {!print && (
        <div className="WayToReport way-to-report-print">
          <header className="WayToReport__header">
            <div className="heading">
              <span className="back-arrow">
                <img
                  onClick={() => navigate("/classroom")}
                  src={BackArrow}
                  alt=""
                  className="cursor-pointer"
                />
              </span>
              <h3 className="heading">Way to an A Report: {formattedMonth}</h3>
            </div>
            <button onClick={handlePrint} className="printButton">
              Print
            </button>
          </header>
          <main className="WayToReport__body">
            <section className="textInfo">
              <p>
                The Way to an A Report reflects your monthly progress with the
                Alpha Plus School Improvement Model. Use the sections below to
                determine which areas need more attention for student success.
              </p>
            </section>
            <section className="WayToReport__body__anylasisData">
              {loading ? (
                <table>
                  <TableSkeleton columns={2} count={3} height={200} />
                </table>
              ) : (
                <>
                  <GoalCompletition
                    goalCompletition={goal_completion}
                    fetchGoalsList={fetchGoalsList}
                  />
                  <AssessmentScheduled
                    assessmentSchedule={assessment_schedule}
                  />
                  <RunningProficiency
                    runningProficiency={running_proficiency}
                  />
                  <FormativeProgress formativeProgress={formative_progress} />
                  <Remediation remediation={remediation} />
                </>
              )}
            </section>
          </main>
        </div>
      )}
      {wayToAReport && (
        <div ref={componentRef} className="printData">
          <Print />
        </div>
      )}

      {apiErrorModal && (
        <ApiModal
          setIsShow={() => {
            setApiErrorModal(false);
            navigate("/classroom");
          }}
          closeButtonName="OK"
        />
      )}
    </>
  );
};

export default WayToAReport;
