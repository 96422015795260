import { useEffect, useState } from "react";

export const useTitleHeight = (name: string, taskNameRef:any) => {
    const [addClass, setAddClass] = useState(false);

    useEffect(() => {
        if(name && taskNameRef.current){
            // console.log(taskNameRef.current.clientHeight);
            setAddClass(taskNameRef.current.clientHeight > 37);
        }
    },[name, taskNameRef]);

    return addClass;
}