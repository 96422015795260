import { FC } from "react"
import ScoreBox from "./scoreBox"

interface Props {
    student: any,
    tests: any,
    index : number;
    setIsolatedStudent: Function;
    level: any
}



const Student: FC<Props> = ({ level, student, tests , index, setIsolatedStudent }) => {
    const { last_name, first_name, school_student_id } = student;
    const isHideRow = student.isShowIsolated === false; // dont use not sign (!) with student.isShowIsolated
    return <tr className="master-report__table-row">
        <div className='d-flex'>
            <td 
            onClick={() => setIsolatedStudent(student)}
            onKeyDown={(e)=>{
                if(e.key==="Enter"){
                    setIsolatedStudent(student)
                }
            }}
            tabIndex={0} className={`cursor-pointer master-report__table-studentId ${isHideRow ? 'objective-isolation' : ''}`}>{school_student_id.slice(-5)}</td>
            <td className='master-report__table-studentName'>
                <span className="ellipsis-animated">
                    <p className={`${isHideRow ? 'd-none' : ''} ellipsis-animated-text ${last_name.concat(first_name).length > 11 ? 'animated ellipsis-animation' : ''}`}>{`${last_name}, ${first_name}`}</p>
                </span>
            </td>
        </div>
        <div className={`master-report__table-marks column-${tests.length}`}>
            {
                tests.map((test: any) => {
                    const studentTest = test.studentsArr.find((studentInTest: any) => studentInTest.id === parseInt(student.id))
                    return (
                        <ScoreBox 
                            student={studentTest}
                            index = {index}
                            isHideRow={isHideRow}
                            level={level}
                        />
                    )

                })
            }
        </div>
    </tr>
}
export default Student