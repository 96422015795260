import React from 'react'

const PDFFileIcon = () => {
    const titleId = "pdfIcon"
  return (
    <svg aria-labelledby={titleId} id="pdf_alt" data-name="pdf alt" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="33.78" height="42.97" viewBox="0 0 33.78 42.97">
   <title id={titleId}>PDF File</title>
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_208" data-name="Rectangle 208" width="33.78" height="42.97" fill="none"/>
      </clipPath>
    </defs>
    <g id="Group_103" data-name="Group 103" clipPath="url(#clip-path)">
      <path id="Path_170" data-name="Path 170" d="M70.822,42.887h-27.4a1.7,1.7,0,0,1-1.7-1.7V1.7a1.7,1.7,0,0,1,1.7-1.7H66.15l6.369,6.369V41.19a1.7,1.7,0,0,1-1.7,1.7" transform="translate(-38.739)" fill="#f5f5f5"/>
      <path id="Path_171" data-name="Path 171" d="M0,227.713l3.312,2.94.221-2.94Z" transform="translate(0 -211.445)" fill="#c32120"/>
      <path id="Path_172" data-name="Path 172" d="M69.663,44.046h-27.4a1.7,1.7,0,0,1-1.7-1.7V2.856a1.7,1.7,0,0,1,1.7-1.7H64.991L71.36,7.528v34.82a1.7,1.7,0,0,1-1.7,1.7M42.258,1.987a.87.87,0,0,0-.869.869V42.349a.87.87,0,0,0,.869.869h27.4a.87.87,0,0,0,.869-.869V7.871L64.648,1.987Z" transform="translate(-37.663 -1.076)" fill="#c9c9c9"/>
      <path id="Path_173" data-name="Path 173" d="M381.27,12.334a1.9,1.9,0,0,0,1.9,1.9H387.4L381.27,8.112Z" transform="translate(-354.031 -7.532)" fill="#c9c9c9"/>
      <rect id="Rectangle_203" data-name="Rectangle 203" width="24.176" height="10.142" transform="translate(0 6.127)" fill="#fe2e2e"/>
      <path id="Path_174" data-name="Path 174" d="M62.234,111.574h2.2c1.4,0,2.541.51,2.541,2.061,0,1.5-1.148,2.169-2.5,2.169h-.795v2.168H62.234Zm2.149,3.081c.795,0,1.177-.353,1.177-1.02s-.432-.912-1.217-.912h-.667v1.933Z" transform="translate(-57.788 -103.603)" fill="#f5f5f5"/>
      <path id="Path_175" data-name="Path 175" d="M144.1,111.575H145.9c1.953,0,3.209.971,3.209,3.169a2.88,2.88,0,0,1-3.13,3.228H144.1Zm1.717,5.23c1.089,0,1.815-.53,1.815-2.061s-.726-2-1.815-2h-.275V116.8Z" transform="translate(-133.803 -103.604)" fill="#f5f5f5"/>
      <path id="Path_176" data-name="Path 176" d="M231.32,111.574h4.033v1.217h-2.59v1.482h2.218v1.217h-2.218v2.483H231.32Z" transform="translate(-214.794 -103.603)" fill="#f5f5f5"/>
      <rect id="Rectangle_204" data-name="Rectangle 204" width="8.793" height="1.195" transform="translate(17.866 22.354)" fill="#c9c9c9"/>
      <rect id="Rectangle_205" data-name="Rectangle 205" width="8.793" height="1.195" transform="translate(17.866 26.537)" fill="#c9c9c9"/>
      <rect id="Rectangle_206" data-name="Rectangle 206" width="16.724" height="1.195" transform="translate(9.935 30.72)" fill="#c9c9c9"/>
      <rect id="Rectangle_207" data-name="Rectangle 207" width="8.61" height="1.195" transform="translate(9.935 34.903)" fill="#c9c9c9"/>
      <path id="Path_177" data-name="Path 177" d="M143.934,317.533h-3.875v-3.643h3.875Zm.994-4.636h-5.862v5.63h5.862Z" transform="translate(-129.13 -290.543)" fill="#c9c9c9"/>
    </g>
  </svg>
  )
}

export default PDFFileIcon
