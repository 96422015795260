const Bulb = ({title=""}:{title?:string}) => {
    const titleId="bulbIcon"
    return <svg aria-labelledby={titleId} xmlns="http://www.w3.org/2000/svg" width="14" height="21" viewBox="0 0 14 21" fill="none">
               <title id={titleId}>{title}</title>
                <mask id="mask0_369_15850" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="21">
                <path d="M13.3811 14.0448V7.10753C13.3811 3.54621 10.4941 0.65918 6.93273 0.65918C3.3714 0.65918 0.484375 3.54621 0.484375 7.10754V14.0448C0.484375 17.6062 3.3714 20.4932 6.93273 20.4932C10.4941 20.4932 13.3811 17.6061 13.3811 14.0448Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_369_15850)">
                <path d="M13.369 6.66051C13.4726 8.22668 12.9934 9.77604 12.0227 11.0135C10.8676 12.4565 10.2306 14.2421 10.2129 16.0865V16.1065C10.2129 16.2768 10.1449 16.4401 10.0239 16.5605C9.90281 16.6809 9.73863 16.7485 9.56743 16.7485H4.30599C4.1348 16.7485 3.97062 16.6809 3.84956 16.5605C3.72851 16.4401 3.66051 16.2768 3.66051 16.1065V16.0855C3.65364 14.2557 3.02547 12.4818 1.87787 11.0515C1.24978 10.2628 0.817068 9.33811 0.614709 8.35227C0.412351 7.36644 0.44602 6.34708 0.712997 5.37658C0.979973 4.40609 1.47277 3.51167 2.15155 2.76562C2.83034 2.01957 3.67608 1.44281 4.62042 1.08196C5.56476 0.721107 6.5812 0.586283 7.58762 0.688382C8.59404 0.790481 9.56218 1.12664 10.4138 1.66969C11.2655 2.21274 11.9767 2.94745 12.4901 3.81444C13.0034 4.68144 13.3045 5.65639 13.369 6.66051Z" fill="#FFC300"/>
                <path d="M9.92582 17.9953H3.93343C3.88062 17.9953 3.82832 17.985 3.77952 17.9649C3.73073 17.9448 3.68639 17.9153 3.64904 17.8782C3.6117 17.841 3.58208 17.7969 3.56187 17.7484C3.54166 17.6999 3.53125 17.6479 3.53125 17.5953C3.53125 17.5428 3.54166 17.4908 3.56187 17.4423C3.58208 17.3937 3.6117 17.3496 3.64904 17.3125C3.68639 17.2754 3.73073 17.2459 3.77952 17.2258C3.82832 17.2057 3.88062 17.1953 3.93343 17.1953H9.92582C9.97863 17.1953 10.0309 17.2057 10.0797 17.2258C10.1285 17.2459 10.1729 17.2754 10.2102 17.3125C10.2476 17.3496 10.2772 17.3937 10.2974 17.4423C10.3176 17.4908 10.328 17.5428 10.328 17.5953C10.328 17.6479 10.3176 17.6999 10.2974 17.7484C10.2772 17.7969 10.2476 17.841 10.2102 17.8782C10.1729 17.9153 10.1285 17.9448 10.0797 17.9649C10.0309 17.985 9.97863 17.9953 9.92582 17.9953Z" fill="#B9B9B9"/>
                <path d="M9.70685 19.2444H4.16389C4.05722 19.2444 3.95493 19.2022 3.87951 19.1272C3.80409 19.0522 3.76172 18.9504 3.76172 18.8444C3.76172 18.7383 3.80409 18.6365 3.87951 18.5615C3.95493 18.4865 4.05722 18.4443 4.16389 18.4443H9.70685C9.81351 18.4443 9.91582 18.4865 9.99124 18.5615C10.0667 18.6365 10.109 18.7383 10.109 18.8444C10.109 18.9504 10.0667 19.0522 9.99124 19.1272C9.91582 19.2022 9.81351 19.2444 9.70685 19.2444Z" fill="#B9B9B9"/>
                <path d="M8.40972 20.4933H5.46078C5.35411 20.4933 5.25181 20.4512 5.17639 20.3762C5.10097 20.3012 5.05859 20.1995 5.05859 20.0934C5.05859 19.9873 5.10097 19.8855 5.17639 19.8105C5.25181 19.7355 5.35411 19.6934 5.46078 19.6934H8.40972C8.51638 19.6934 8.61867 19.7355 8.69409 19.8105C8.76951 19.8855 8.81188 19.9873 8.81188 20.0934C8.81188 20.1995 8.76951 20.3012 8.69409 20.3762C8.61867 20.4512 8.51638 20.4933 8.40972 20.4933Z" fill="#9B9C9E"/>
                </g>
            </svg>
}

export default Bulb;