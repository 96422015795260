import React from "react"

const Unchecked = () => {
    const titleId = "selectGoalSVG";
    return (
        <svg aria-labelledby={titleId} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
           <title id={titleId}>Select</title>
            <path d="M25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5C0 19.4036 5.59644 25 12.5 25C19.4036 25 25 19.4036 25 12.5Z" fill="#C9C9C9"/>
        </svg>
    )
};

export default Unchecked;
