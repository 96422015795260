/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck

import React, { useEffect, useRef, useState } from "react";
import {  Spinner } from "react-bootstrap";
import { postMasteryVideoQuiz, putMasteryQuizQuestion } from "redux/actionCreators/mastery-videos";
import NextButton from "./nextButton";

interface Props {
  setModalShow: any;
  quizes: any[];
  video: any;
  setQuizes: Function;
  message: string;
}

const optionNumberToOptionMapper = {
    1: 'A',
    2: 'B',
    3: 'C',
    4: 'D'
}

const QuizModal: React.FC<Props> = (props) => {
  
    const {setModalShow, quizes, video, setQuizes, message} = props;
    
    const [selectedQuizIndex, setSelectedQuizIndex] = useState<any>(0);
    const [showCorrectAns, setCorrectAns] = useState(false);
    const [answer, setAnswer] = useState<any>(null!);
    const [loading, setLoading] = useState(false);

    const correctAnswersCount = useRef<number>(0);
    const completedQuestionsCount = useRef<number>(0);

    const isNextQuestion = selectedQuizIndex < quizes.length;
    const selectedQuestion = quizes.length > 0 && isNextQuestion ? quizes[selectedQuizIndex] : null;
    const {question='', option_1='', option_2='', option_3='', option_4='', correct_option} = selectedQuestion || {};
    const isAnswerCorrect = answer === correct_option;

    const submitQuiz = async () => {
        setLoading(true);
        const isSuccess = await postMasteryVideoQuiz(video.id);
        isSuccess && isSuccess.submitted && setModalShow();
        setLoading(false);
        setModalShow();
    }

    const submitAnswer = async () => {
        const payload = {
            question_id: selectedQuestion.id,
            answer
        } 
        const quizData = await putMasteryQuizQuestion(video.id, payload);
        setQuizes(quizData.quiz);
    }

    const nextQuestion = async () => {
        completedQuestionsCount.current++
        if(isAnswerCorrect){
            correctAnswersCount.current++
        }
        setSelectedQuizIndex((prev: number) => prev+1);
    }

    const updateQuizIndex = () => {
        isNextQuestion 
        ? nextQuestion()
        : submitQuiz();
    }

    const onClickOption = (answer: any) => {
      setAnswer(answer)
    }

    const setRightAnswer = (ans: boolean) => {
        setCorrectAns(ans);
        !ans && setAnswer(null!)
    }
    
    const [firstName, ...surname] = video && video.name ? video.name.split(' ') : [];

    
    return (
        <>
            <div className="modal-content mastery-quiz">
                <div className="modal__header">
                    <h3 className="modal__header-title">{video?.name || ''} Mastery Quiz</h3>
                    <p>Complete This Quiz To Check Off the {video?.name || ''} Goal</p>
                </div>

                <div className="modal__body">
                    {isNextQuestion ? (
                        <>
                            <p className="radio-title">
                                {question}
                            </p>
                            {showCorrectAns
                            ?    <>
                                    <div className="wrong-ans-correct">
                                        <div className="quiz-answer">
                                            <div style={{width: "auto", maxWidth: "78%"}}>
                                                {!isAnswerCorrect && (
                                                    <div className="answer">
                                                        <span className="yourAnsWrong">{optionNumberToOptionMapper[answer] || ''}</span>
                                                        <p>{selectedQuestion ? selectedQuestion[`option_${answer}`] : ''}</p>
                                                    </div>
                                                )}
                                                <div className="answer">
                                                    <span className="correctAns">{optionNumberToOptionMapper[correct_option] || ''}</span>
                                                    <p>{selectedQuestion ? selectedQuestion[`option_${correct_option}`] : ''}</p>
                                                </div>
                                            </div>
                                            <div style={{...(isAnswerCorrect && {paddingTop: 2})}} className="quiz-labels">
                                                {!isAnswerCorrect && (
                                                    <span className="wrongLabel">Your Answer</span>
                                                )}
                                                <span className="correctLabel">{isAnswerCorrect ? 'Correct!' : 'Correct Answer'}</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </>
                            :
                                <>
                                    <div className="mastery-radio-btn">
                                        <input onChange={() => {}} checked={answer === 1} type="radio" name="option" id="option1" value="option1" />
                                        <label onClick={() => onClickOption(1)} className="radio-label" htmlFor="option1">
                                            <span className="radio-button">A</span>
                                            <span className="radio-text">{option_1}</span>
                                        </label>
                                    </div>
                                    <div className="mastery-radio-btn">
                                        <input onChange={() => {}} checked={answer === 2} type="radio" name="option" id="option2" value="option2" />
                                        <label onClick={() => onClickOption(2)} className="radio-label" htmlFor="option2">
                                            <span className="radio-button">B</span>
                                            <span className="radio-text">{option_2}</span>
                                        </label>
                                    </div>
                                    <div className="mastery-radio-btn">
                                        <input onChange={() => {}} checked={answer === 3} type="radio" name="option" id="option3" value="option3" />
                                        <label onClick={() => onClickOption(3)} className="radio-label" htmlFor="option3">
                                            <span className="radio-button">C</span>
                                            <span className="radio-text">{option_3}</span>
                                        </label>
                                    </div>
                                    <div className="mastery-radio-btn">
                                        <input onChange={() => {}} checked={answer === 4} type="radio" name="option" id="option4" value="option4" />
                                        <label onClick={() => onClickOption(4)} className="radio-label" htmlFor="option4">
                                            <span className="radio-button">D</span>
                                            <span className="radio-text">{option_4}</span>
                                        </label>
                                    </div>
                                </>
                            }
                        </>
                    ):(
                        <div className="quiz-complete-modal">
                            <p className="title">{!!message ? message : 'Quiz completed. Goal checked!'}</p>
                            {!message && (
                                <>
                                    <label className="d-flex justify-content-center quiz-complete-checkBox cursor-pointer">
                                        <input checked type="checkbox" id="pacing-calendar" value="" />
                                        <span className="checkbox"></span>
                                        <span>View {firstName || ''} <b>{surname || ''} Video</b></span>
                                    </label>
                                    <div className="d-flex align-items-center justify-content-center" style={{gap: "37px"}}>
                                        <div className="wrongBtn">{quizes.length - correctAnswersCount.current} Wrong {quizes.length - correctAnswersCount.current > 1 ? 'Answers' : 'Answer'}</div>
                                        <div className="correctBtn"> {correctAnswersCount.current} Correct {correctAnswersCount.current > 1 ? 'Answers' : 'Answer'}</div>
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>

                <div className="modal__footer">
                    {isNextQuestion && (
                        <div className="dots">
                            {quizes.map((item: any, index: number) => {
                                return (
                                    <span className={`${completedQuestionsCount.current > (index-1) ? 'fill' : ''}`}></span>
                                )
                            })}
                            
                            {/* <span className={`${completedQuestionsCount.current > 0 ? 'fill' : ''}`}></span>
                            <span className={`${completedQuestionsCount.current > 1 ? 'fill' : ''}`}></span>
                            <span className={`${completedQuestionsCount.current > 2 ? 'fill' : ''}`}></span>
                            <span className={`${completedQuestionsCount.current > 3 ? 'fill' : ''}`}></span> */}
                        </div>
                    )}
                    <div className="d-flex align-items-center">
                        {isNextQuestion && <button style={{cursor: showCorrectAns ? 'default' : 'cursor'}} type="button" className={`btn cancel-btn ${showCorrectAns ? 'btn-disabled' : ''}`}
                            onClick={() => !showCorrectAns && setModalShow()}
                        >
                            Cancel
                        </button>}

                        {isNextQuestion && 
                            <NextButton
                                setCorrectAns={(ans) => setRightAnswer(ans)} 
                                answer={answer}
                                updateQuizIndex={() => updateQuizIndex()}
                                submitAnswer={submitAnswer}
                            />
                        }

                        {!isNextQuestion && <button type="button" id="welcome-save-button" className={`btn success-btn btn-lg next-btn finishBtn ${(loading) && 'btn-disabled'}`}
                            onClick={() => !!message ? setModalShow(false) : updateQuizIndex()}
                        >
                            {loading ? <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        /> : message.length > 0 ? 'Close' : 'Finish'}
                        </button>}
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuizModal;
