import CommentSection from "./commentSection";
import MesteryHeader from "./masteryHeader";
import ReactPlayer from 'react-player'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMasteryVideoQuiz, getMasteryVideos } from "redux/actionCreators/mastery-videos"
import { allMasteryVideosSelector, masteryVideoDetailsSelector, masteryVideosLoadingSelector } from "redux/reducers/mastery-videos";
import thumbnail from "assets/img/thumbnail-only.jpg"
import thumbnail_video_2 from "assets/img/thumbnail_video_2.png"
import thumbnail_video_3 from "assets/img/mvs-cover.png"
import thumbnail_video_5 from "assets/img/thumbnail_5.jpg";
import thumbnail_video_4 from "assets/img/thumnail_4.png";
// import largerPlayIcon from "assets/img/play-icon-svg.svg";
import QuizModal from "./quizModal";
import ApiModal from "components/common/Reload/ApiModal";
import { RootState } from "redux/reducers/combine";
import jwtDecode from "jwt-decode";
import Skeleton from "react-loading-skeleton";


const PlayIconElement = () => {
    return(
        <div className="image-container">
            {/* <img
                className={`playBigBtn image2`} 
                src={largerPlayIcon} 
                alt="Play no Icon"
            /> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="164" height="155" viewBox="0 0 164 155" fill="none">
                <g filter="url(#filter0_d_4391_6951)">
                    <path d="M53.723 5.08266L155.654 63.9326L53.8118 122.629L53.723 5.08266Z" fill="#46AFB8"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M52.723 3.34997L52.8144 124.359L157.657 63.9335L52.723 3.34997ZM153.654 63.932L54.8118 120.899L54.7256 6.81559L153.654 63.932Z" fill="white"/>
                </g>
                <defs>
                    <filter id="filter0_d_4391_6951" x="46.7227" y="0.349609" width="116.934" height="133.009" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="3"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4391_6951"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4391_6951" result="shape"/>
                    </filter>
                </defs>
            </svg>
        </div>
    )

}

const MasteryVideos = () => {
    const dispatch = useDispatch();

    const [playing, setPlaying] = useState(false);
    const [quizes, setQuizes] = useState<any>([]);
    const [showQuizModal, setShowQuizModal] = useState(false);
    const [message, setMessage] = useState('');
    const [videoId, setVideoId] = useState<any>(null!);

    const [thumb, setThumb] = useState(thumbnail);

    const auth = useSelector((state: RootState) => state.auth);

    const videos = useSelector(allMasteryVideosSelector);
    const masteryVideosLoading = useSelector(masteryVideosLoadingSelector);

    const selectedVideo = videos.find((item: any) => item?.id === videoId);
    const videoDetails = useSelector(masteryVideoDetailsSelector);
    const { video, ...comments } = videoDetails;

    useEffect(() => {
        dispatch(getMasteryVideos());
    }, [dispatch]);

    const getUserRoleId = () => {
        const token = auth?.currentUser?.token;
        if(token){
            const decodeToken: any = jwtDecode(token);
            const roleId = decodeToken?.role;
            return roleId;
        }
        return 0;
    }

    const onVideoEnd = async () => {
        const roleId = getUserRoleId();
        const isClassroomAdmin = roleId === 4;

        if(isClassroomAdmin){
            const {data, message} = await getMasteryVideoQuiz(videoDetails?.video?.id);
            if(!data){
                setMessage(message);
            }else{
                quizes && setQuizes(data.quiz);
                setShowQuizModal(true);
            }
        }
    }

    useEffect(() => {
        if (videoDetails?.video?.id === 1) {
            setThumb(thumbnail)
        } else if (videoDetails?.video?.id === 2) {
            setThumb(thumbnail_video_2)
        } else if (videoDetails?.video?.id === 3) {
            setThumb(thumbnail_video_3)
        } else if (videoDetails?.video?.id === 4) {
            setThumb(thumbnail_video_4);
        } else if (videoDetails?.video?.id === 5) {
            setThumb(thumbnail_video_5);
        }
        if(videoDetails?.video?.video_url){
            
        }
    },[videoDetails])

    return (
        <>
            <div className="MasteryVideos">
                <MesteryHeader setVideoId={(id: number) => {
                    setVideoId(id);
                }} />
                <div className="MasteryVideos__padding">
                    <div className="MasteryVideos__videoSec">
                        <div className="MasteryVideos__videoItemContainer">
                            {/* <div className="MasteryVideos__itemHeader">
                                <p>{video?.name}</p>
                            </div> */}
                            <div className="MasteryVideos__videoItems">
                                {masteryVideosLoading ? (
                                    <Skeleton baseColor="#2a539143" highlightColor="#5371b732" width={857} height={482} />
                                ):(
                                    <ReactPlayer
                                        height={456}
                                        width={857}
                                        url={selectedVideo?.video_url} 
                                        controls={true} 
                                        light={thumb}
                                        playIcon={<PlayIconElement />}
                                        onEnded={onVideoEnd}
                                        playing={playing}
                                        onClickPreview={() => setPlaying(true)}
                                    />
                                )}
                                {/* <video
                                    id="my-video-player"
                                    height='100%'
                                    width='100%'
                                    playsInline
                                /> */}
                            </div>
                            {showQuizModal && (
                                <QuizModal
                                    setModalShow={() => setShowQuizModal(false)}
                                    quizes={quizes}
                                    video={video}
                                    setQuizes={setQuizes}
                                    message={message}
                                />
                            )}
                        </div>
                    </div>
                    <div className="MasteryVideos__discription">
                        <div className=" MasteryVideos__header">
                            <h2 className="text-white">Description</h2>
                        </div>
                        <div className="MasteryVideos__discription__text">
                            <p className="text-dark">
                                {video?.description}
                            </p>
                        </div>
                    </div>
                    
                    <CommentSection {...comments} key={videoId} videoId={videoId} />
                </div>
            </div>
            {!!message && (
                <ApiModal
                    setIsShow={() => setMessage('')}
                />
            )}
        </>
    );
};
export default MasteryVideos;
