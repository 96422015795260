import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { LimitSelect } from "components/common/selectComponent";
import SearchIcon from "assets/img/search-icon.svg";
import { AssigneeDropdown, StatusFilter } from "./dropdowns";
import Pagination from "components/pagination/pagination";
import { SupportTicketSuperAdminContext } from "contexts/supportTicketSuperAdmin";
import _ from "lodash";
import {
  StatusClassMapper,
  SupportTicketTypeMapper,
  TicketDetailsStatusMapper,
} from "util/mappers";
import ArrowIcon from "assets/img/accordian-box-icon.png";
import { Collapse } from "react-bootstrap";
import moment from "moment";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { SupportTicketFilters } from "interfaces/supportTicketSuperAdmin";
import EmptyRecord from "components/common/EmptyRecord";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import ToolTip from "components/common/Tooltip";
import ArrowDownImg from "assets/img/drop-black-icon-down.svg";

import useDidMountEffect from "hooks/useDidMountEffect";

import { grabInitialsIfMoreThanOneWord } from "util/shared/GrabInitials";

interface IProps {
  refreshTable: Boolean;
  refreshTableOnClick: Boolean;
}

const SupportTicketTable = (props: IProps) => {
  const { pathname } = useLocation();
  const {
    supportTickets,
    fetchSupportTicketManagement,
    fetchSupportTicketManagementWithoutPageLoad,
    superAdmins,
  } = useContext(SupportTicketSuperAdminContext);

  const { super_admins } = superAdmins;

  const [searchParams] = useSearchParams();

  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [open, setOpen] = useState(
    searchParams.get("chatHistory") === "1" ? false : true
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState("last_updated");
  const [OrderByColumn, setOrderBy] = useState("DESC");

  const [supportTicketQueryParams, setSupportTicketQueryParams] = useState({
    limit: 10,
    offset: 0,
    search: "",
    assigneeId: "",
    status: "",
    sort_by: sortBy,
    order_by: OrderByColumn,
    created_by: searchParams.get("created_by") || "",
  });

  const { limit } = supportTicketQueryParams;
  const { count, tickets, loading } = supportTickets;

  useEffect(() => {
    navigate(pathname, { replace: true });

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [navigate]);

  const formattedTickets = useMemo(() => {
    return tickets.map((ticket) => {
      const { last_update, created_at, type, status, submitter } = ticket;
      const formattedType =
        SupportTicketTypeMapper[type as keyof typeof SupportTicketTypeMapper];
      const formattedStatus =
        TicketDetailsStatusMapper[
          status as keyof typeof TicketDetailsStatusMapper
        ];
      const statusClass =
        StatusClassMapper[status as keyof typeof StatusClassMapper];
      const formattedCreatedDate = moment(created_at).format("M-D-YY h:mm A");
      const formattedLastUpdate =
        last_update === "Not Yet" || !last_update
          ? "Not Yet"
          : moment(last_update).format("M-D-YY h:mm A");

      return {
        ...ticket,
        submitter,
        formattedType,
        formattedCreatedDate,
        formattedLastUpdate,
        formattedStatus,
        statusClass,
      };
    });
  }, [tickets]);

  const onChangeFilter = (key: string, data: any) => {
    switch (key) {
      case SupportTicketFilters.LIMIT: {
        setCurrentPage(1);
        setSupportTicketQueryParams((prev) => ({
          ...prev,
          limit: data,
          offset: 0,
        }));
        return;
      }
      case SupportTicketFilters.OFFSET: {
        setCurrentPage(data);
        setSupportTicketQueryParams((prev) => ({
          ...prev,
          offset: data * prev.limit - prev.limit,
        }));
        return;
      }
      case SupportTicketFilters.STATUS: {
        setCurrentPage(1);
        setSupportTicketQueryParams((prev) => ({
          ...prev,
          status: data.value,
          offset: 0,
        }));
        return;
      }
      case SupportTicketFilters.SEARCH: {
        setCurrentPage(1);
        setSupportTicketQueryParams((prev) => ({
          ...prev,
          search: data,
          offset: 0,
        }));
        return;
      }
      case SupportTicketFilters.ASSIGNEE: {
        setCurrentPage(1);
        setSupportTicketQueryParams((prev) => ({
          ...prev,
          assigneeId: data.value,
          offset: 0,
        }));
        return;
      }
    }
  };
  /* eslint-disable react-hooks/exhaustive-deps */
  const handleSearch = useCallback(
    _.debounce((value) => {
      onChangeFilter(SupportTicketFilters.SEARCH, value);
    }, 1000),
    []
  );

  useEffect(() => {
    fetchSupportTicketManagement(supportTicketQueryParams);
  }, [supportTicketQueryParams, props.refreshTableOnClick]);

  // useEffect(() => {
  //     fetchSupportTicketManagementWithoutPageLoad(supportTicketQueryParams)
  // }, [props.refreshTable]);

  useDidMountEffect(() => {
    fetchSupportTicketManagementWithoutPageLoad(supportTicketQueryParams);
  }, [props.refreshTable]);

  // useEffect(() => {
  //     setCurrentPage(1)
  //     setSupportTicketQueryParams(
  //         prev => (
  //             {
  //                 ...prev,
  //                 sort_by: sortBy,
  //                 order_by: OrderByColumn,
  //                 offset: 0
  //             }
  //         )
  //     );

  //     /* eslint-disable react-hooks/exhaustive-deps */
  // }, [sortBy, OrderByColumn]);

  useDidMountEffect(() => {
    setCurrentPage(1);
    setSupportTicketQueryParams((prev) => ({
      ...prev,
      sort_by: sortBy,
      order_by: OrderByColumn,
      offset: 0,
    }));
  }, [sortBy, OrderByColumn]);

  const hideFilter = () => {
    setCurrentPage(1);
    setSupportTicketQueryParams((prev) => ({
      ...prev,
      created_by: "",
      offset: 0,
    }));
  };

  return (
    <>
      <div id="chat-session-cont" className="SupportTicket pt-0">
        <div className="">
          <div
            className={`SupportCenter__Header__titleRow d-flex align-items-center justify-content-between SupportTicket__header ${
              !open ? "borderRadius" : ""
            }`}
          >
            <div className="d-flex align-items-center m-lg-0 m-2">
              <div
                onClick={(e) => {
                  setOpen(!open);
                }}
                className={`${open ? "open" : ""}`}
              >
                <div className="accordion__header-heading">
                  <span className="accordion__header-button">
                    <img
                      src={ArrowIcon}
                      alt="arrow"
                      className={`${open ? "rotate" : ""}`}
                    />
                  </span>
                </div>
              </div>
              <h2 className="title ">Support Tickets</h2>
            </div>
            <div className="d-flex align-items-center  m-lg-0 m-2">
              <p className="mb-0">Show</p>
              <LimitSelect
                disableHandler={count <= 10}
                value={limit}
                onChangeHandler={(e: any) =>
                  onChangeFilter(SupportTicketFilters.LIMIT, e?.value)
                }
              />
            </div>
            <div className="d-flex  m-lg-0 m-2 align-items-center SupportCenter__Header__Assigneedropdown">
              <span className="text-white">Assignee</span>
              <AssigneeDropdown
                options={super_admins}
                onChangeHandler={(e: any) =>
                  onChangeFilter(SupportTicketFilters.ASSIGNEE, e)
                }
                customClass="assignee-zIndex"
              />
            </div>
            <div className="d-flex m-lg-0 m-2 align-items-center SupportCenter__Header__Statusdropdown">
              <span className="text-white">Status</span>
              <StatusFilter
                onChangeHandler={(e: any) =>
                  onChangeFilter(SupportTicketFilters.STATUS, e)
                }
                options={[
                  { value: "", label: "All" },
                  { value: "SUBMITTED", label: "Submitted" },
                  { value: "NEED_MORE_INFO", label: "Need More Info" },
                  { value: "IN_PROGRESS", label: "In Progress" },
                  { value: "RESOLVED", label: "Resolved" },
                ]}
              />
            </div>
            <div className="section-search-field  m-lg-0 m-2 search-filter">
              <div className="icon">
                <img src={SearchIcon} alt="search-icon" />
              </div>
              {!!supportTicketQueryParams.created_by && !loading ? (
                <div
                  className="text-white"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: "14px",
                    width: "185px",
                    alignItems: "center",
                    height: "21px",
                    lineHeight: "21px",
                    paddingTop: "4px",
                    paddingLeft: "5px",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "-3px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        marginRight: "2.5px",
                        height: "19px",
                      }}
                    >
                      Filter:
                    </span>
                    {!!formattedTickets && formattedTickets?.length > 0 && (
                      <ToolTip
                        styles={{ height: "19px" }}
                        content={formattedTickets[0].submitter}
                      >
                        <span
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: "80px",
                            display: "inline-block",
                            margin: 0,
                            whiteSpace: "nowrap",
                            height: "19px",
                            position: "relative",
                            top: "3px",
                          }}
                        >
                          {formattedTickets[0].submitter}
                        </span>
                      </ToolTip>
                    )}
                    {/* <span 
                                                style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    width: "80px",
                                                    display: "inline-block",
                                                    margin: 0,
                                                    whiteSpace: "nowrap"
                                                }}
                                            >
                                                {
                                                    !!formattedTickets && formattedTickets?.length > 0 && (
                                                        formattedTickets[0].submitter
                                                    )
                                                }
                                            </span> */}
                  </span>

                  <span
                    onClick={hideFilter}
                    className="cursor-pointer"
                    style={{
                      marginLeft: "8px",
                      marginTop: "-4.5px",
                      //     width: "18px",
                      //     height: "18px",
                      //     borderRadius: "50%",
                      //     backgroundColor: "red",
                      //     display: "flex",
                      //     justifyContent: "center",
                      //     fontSize: "16px",
                      //     fontWeight: 500,
                      //     lineHeight: "16px",
                      //     color: "white",
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                    >
                      <g
                        id="remove_attachment_icon"
                        data-name="remove attachment icon"
                        transform="translate(-1121 -639)"
                      >
                        <g
                          id="close_field_icon"
                          data-name="close field icon"
                          transform="translate(645 89)"
                          opacity="0.375"
                        >
                          <g
                            id="Ellipse_41"
                            data-name="Ellipse 41"
                            transform="translate(477 551)"
                            stroke="#000"
                            strokeWidth="1"
                          >
                            <circle cx="9" cy="9" r="9" stroke="none" />
                            <circle cx="9" cy="9" r="8.5" fill="none" />
                          </g>
                          <line
                            id="Line_45"
                            data-name="Line 45"
                            x2="7"
                            y2="7"
                            transform="translate(482.5 556.5)"
                            fill="none"
                            stroke="#000"
                            strokeWidth="2"
                          />
                          <line
                            id="Line_46"
                            data-name="Line 46"
                            y1="7"
                            x2="7"
                            transform="translate(482.5 556.5)"
                            fill="none"
                            stroke="#000"
                            strokeWidth="2"
                          />
                        </g>
                        <g
                          id="close_field_icon-2"
                          data-name="close field icon"
                          transform="translate(644 88)"
                        >
                          <circle
                            id="Ellipse_41-2"
                            data-name="Ellipse 41"
                            cx="9"
                            cy="9"
                            r="9"
                            transform="translate(477 551)"
                            fill="#c44538"
                          />
                          <line
                            id="Line_45-2"
                            data-name="Line 45"
                            x2="7"
                            y2="7"
                            transform="translate(482.5 556.5)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                          />
                          <line
                            id="Line_46-2"
                            data-name="Line 46"
                            y1="7"
                            x2="7"
                            transform="translate(482.5 556.5)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                          />
                        </g>
                      </g>
                    </svg>
                  </span>
                </div>
              ) : (
                <input
                  value={search}
                  type="text"
                  className="form-control"
                  placeholder="Search Tickets"
                  id="search-input-formative"
                  onChange={(e) => {
                    handleSearch(e.target.value);
                    setSearch(e.target.value);
                  }}
                />
              )}
            </div>
          </div>
          <div className="overflow-auto">
            <Collapse in={open}>
              <div>
                <table className="SupportTicket__table">
                  <thead className="SupportTicket__table__thead">
                    <tr>
                      <td className="SupportTicketCircle"></td>
                      <td className="summary">Type/Summary</td>
                      <td className="submitter">Submitter</td>

                      <td
                        className="lastUpdate cursor-pointer"
                        onClick={() => {
                          setSortBy("last_updated");
                          if (
                            OrderByColumn === "DESC" &&
                            sortBy === "last_updated"
                          ) {
                            setOrderBy("ASC");
                          } else if (
                            OrderByColumn === "ASC" &&
                            sortBy === "last_updated"
                          ) {
                            setOrderBy("DESC");
                          } else {
                            setOrderBy("DESC");
                          }
                        }}
                      >
                        Last Update
                        {sortBy === "last_updated" && (
                          <img
                            src={ArrowDownImg}
                            alt="sort icon"
                            className={`${
                              OrderByColumn === "ASC"
                                ? "arrow-up-classname"
                                : "arrow-down-classname"
                            }`}
                          />
                        )}
                      </td>
                      <td
                        className="createdTime cursor-pointer"
                        onClick={() => {
                          setSortBy("createdAt");
                          if (
                            OrderByColumn === "DESC" &&
                            sortBy === "createdAt"
                          ) {
                            setOrderBy("ASC");
                          } else if (
                            OrderByColumn === "ASC" &&
                            sortBy === "createdAt"
                          ) {
                            setOrderBy("DESC");
                          } else {
                            setOrderBy("DESC");
                          }
                        }}
                      >
                        Created
                        {sortBy === "createdAt" && (
                          <img
                            src={ArrowDownImg}
                            alt="sort icon"
                            className={`${
                              OrderByColumn === "ASC"
                                ? "arrow-up-classname"
                                : "arrow-down-classname"
                            }`}
                          />
                        )}
                      </td>
                      <td className="SupportTicketstatus">Status</td>
                      <td className="assignee">Assignee</td>
                    </tr>
                  </thead>
                  <tbody className="SupportTicket__table__tbody">
                    {loading ? (
                      <TableSkeleton
                        columns={7}
                        count={limit}
                        height={36.5}
                        firstColumnWidth={55}
                      />
                    ) : (
                      <>
                        {formattedTickets.length > 0 ? (
                          formattedTickets.map((ticket) => {
                            const {
                              id,
                              unread_messages,
                              submitter,
                              statusClass,
                              summary,
                              formattedLastUpdate,
                              formattedType,
                              formattedCreatedDate,
                              formattedStatus,
                              admin_assigned,
                            } = ticket;

                            return (
                              <tr
                                key={id}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate(`/super-admin/ticket-details/${id}`)
                                }
                              >
                                <td className="" style={{ width: "55px" }}>
                                  {unread_messages && (
                                    <p className="redCircle"></p>
                                  )}
                                </td>
                                <td className="fw-bold summary">{`${formattedType}${
                                  summary ? ` - ${summary}` : ""
                                }`}</td>
                                <td>
                                  <ToolTip styles={{}} content={submitter}>
                                    {grabInitialsIfMoreThanOneWord(submitter)}
                                  </ToolTip>
                                </td>
                                <td
                                  className={`${
                                    formattedLastUpdate === "Not Yet"
                                      ? "text-center"
                                      : "text-left"
                                  }`}
                                  style={{ paddingLeft: "4px" }}
                                >
                                  {formattedLastUpdate === "Not Yet" ? (
                                    formattedLastUpdate
                                  ) : (
                                    <>
                                      <span
                                        style={{ width: "73px" }}
                                        className="text-right d-inline-block me-1"
                                      >
                                        {moment(formattedLastUpdate).format(
                                          "M-D-YY "
                                        )}
                                      </span>

                                      <span
                                        style={{ width: "72px" }}
                                        className="text-right d-inline-block"
                                      >
                                        {moment(formattedLastUpdate).format(
                                          "h:mm A"
                                        )}
                                      </span>
                                    </>
                                  )}
                                </td>

                                <td style={{ paddingLeft: "4px" }}>
                                  {
                                    <>
                                      <span
                                        style={{ width: "73px" }}
                                        className="text-right d-inline-block me-1"
                                      >
                                        {moment(formattedCreatedDate).format(
                                          "M-D-YY "
                                        )}
                                      </span>

                                      <span
                                        style={{ width: "72px" }}
                                        className="text-right d-inline-block"
                                      >
                                        {moment(formattedCreatedDate).format(
                                          "h:mm A"
                                        )}
                                      </span>
                                    </>
                                  }
                                </td>
                                <td className="SupportTicket__table__tbody__status">
                                  <p className={statusClass}>
                                    {formattedStatus}
                                  </p>
                                </td>
                                <td className="assignee-name">
                                  <ToolTip styles={{}} content={admin_assigned}>
                                    {grabInitialsIfMoreThanOneWord(
                                      admin_assigned
                                    )}
                                  </ToolTip>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <EmptyRecord message="No Tickets" />
                        )}
                      </>
                    )}
                  </tbody>
                </table>
                <Pagination
                  totalCount={count}
                  currentPage={currentPage}
                  pageSize={limit}
                  onPageChange={(page: number) =>
                    onChangeFilter(SupportTicketFilters.OFFSET, page)
                  }
                />
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportTicketTable;
