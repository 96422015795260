import { FC, useMemo } from "react"

const SideBar: FC<{ sideBarContent: Array<any> }> = ({ sideBarContent }) => {
    const contentGenerator = useMemo(() => {
        let count = 1;
        return sideBarContent.map((info: any, index: number) => {
            let height;
            if (info?.objective_name !== sideBarContent[index + 1]?.objective_name) {
                height = count * 37;
                if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
                    height = count * 26;
                }
                count = 1;
                return <>
                    <div className="print-structure" style={{ height: `${height}px` }} >
                        <span className="percentage">{info.percentage}%</span>
                        <span data-tip={info.objective_name} className="main-content">
                            <span className="content">{info.objective_name}</span>
                        </span>
                    </div>
                    <div className="after-line"></div>
                </>
            }
            count++;
            return null
        })

    }, [sideBarContent])

    return <>{contentGenerator}</>
}
export default SideBar