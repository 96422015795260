import { Chart as ChartJS, ArcElement, Tooltip, Legend, registerables } from 'chart.js';
import { useGetChartData } from 'hooks/useGetChartData';
// import { useNavigate } from 'react-router-dom';
import AnalysisBody from '../AnalysisBox/body';
import AnalysisContainer from '../AnalysisBox/container';
import AnalysisFooter from '../AnalysisBox/footer';
import AnalysisHeader from '../AnalysisBox/header';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useGetDataValues } from 'hooks/useGetDataValues';

ChartJS.register(...registerables);
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);


const FormativeProgress = ({formativeProgress}: any) => {

    const {completed, remaining, status, text, percentage} = formativeProgress || {};

    // const navigate = useNavigate();

    const {dataValues, backgroundColor, isNotData} = useGetDataValues({
        firstDatasetValue:completed, 
        secondDatasetValue: remaining,
        secondDatasetColor: '#537AB7',
        percentage
    })
    const ChartData = useGetChartData({dataValues, backgroundColor, isNotData});

    return (
        <AnalysisContainer>
            <AnalysisHeader status={status} title="Formative Progress" />
            
            <AnalysisBody 
                ChartData={ChartData}
                text={text}
                percentage={percentage}
                key={isNotData}
                isImage={isNotData}
            />

            <AnalysisFooter 
                firstLabel="Completed"
                secondLabel="Remaining"
                secondColor='#537AB7'
            />
        </AnalysisContainer>
    )
};

export default FormativeProgress;
