import React, { PropsWithChildren } from "react"

const BodyContainer = ({children, isAddClass}:PropsWithChildren<any>) => {
    return (
        <div className={`card-body-container ${isAddClass ? 'card-body-height' : ''}`}>
            {children}
        </div>
    )
};

export default BodyContainer;
