/* eslint-disable react-hooks/exhaustive-deps */

import { Mention, MentionsInput } from "react-mentions";
import React, { useEffect, useMemo, useState } from "react";
import { Spinner } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import {handlePress} from 'util/index';
import MentionWrapperStyle from "./SCSS/MentionWrapperStyle";
import { trimStringToMentionFormat } from "util/shared/TextManipulation";
import CustomModal from "components/common/Modal";

interface Props {
  show: boolean;
  reply: string,
  onSave: (comment: string) => Promise<any>;
  onClose: () => void;
  title?:string;
  data?: string;
  placeholder?: string;
  submitButtonName?: string;
  label?: string;
  disabled?: boolean;
  super_admins?: any
}


const CommentModal: React.FC<Props> = (props) => {
  const {
    show,
    reply,
    onSave,
    onClose,
    title,
    data,
    submitButtonName,
    label,
    disabled,
    placeholder
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState(data || "");


  const isReply = !!reply

  const handleSave = async () => {
    setIsLoading(true);
    await onSave(comment);
    setIsLoading(true);
  };

  const closeModal = () => {
    setIsLoading(false);
    onClose()
  };

  const updateSuperAdmins = useMemo( () => {
      if(!props.super_admins) return [];
      
      return props.super_admins?.map((admin: any) => {
        return {
            ...admin, 
            'display': admin.label
          }
      })
    },
      [props.super_admins]
  );

  const handleCommentValue = (e: any) => {
    setComment(e.target.value);
  } 

  useEffect(() => {
    document.addEventListener('keydown', handlePress);
    document.addEventListener('keyup', handlePress);
    return () => {
      document.removeEventListener('keydown', handlePress);
      document.removeEventListener('keyup', handlePress);
    }
  }, []);


  let replyContent = null;
  if(isReply) {
    const username = reply.match(/\[[^\]]*]/g); 
    let replyToDisplay = reply.trim().length > 0 && username !== null? trimStringToMentionFormat(reply, username) : reply;  
    replyContent = <div>
    <p className="test-sm mt-3 reply-to-label rounded-pill">Replying To</p>
    <p style={{whiteSpace:"pre-wrap"}} className="px-4 test-sm text-break white-space">
      {replyToDisplay}
    </p>
  </div>
  }

  return (
    <>
      <CustomModal centered show={show} onEscapeKeyDown={closeModal}>
        <div className="modal__header">
          <div>
            <h2 className="modal__header-title">
              {title ? title : isReply ? "Reply" : "New Comment"}
            </h2>
          </div>
        </div>
        
        {replyContent}

        <div className={` textArea mb-4 px-2  ${isReply ? 'mt-1' : 'mt-3'}`}>
          <p className="bg-dark text-sm text-white mb-0 px-3 rounded-pill comment-text">
            {label || "Comment"}
          </p>
          {!props.super_admins ? (
            <textarea
              className="w-100 p-3 text-area"
              placeholder={placeholder || "Enter Comment here"}
              rows={4}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          ):(
            <div className="text-area2">
              <MentionsInput
              className="textarea-child"
                value={comment}
                onChange={handleCommentValue}
                style={MentionWrapperStyle}
                placeholder={placeholder || "Enter Comment here"}
              >

                <Mention
                  trigger="@"
                  data={updateSuperAdmins}
                  appendSpaceOnAdd={true}
                  style={{
                    backgroundColor: "#373c44",
                    position: "relative",
                    zIndex: "99999",
                    top: "4px",
                    left: "5px",
                    color: 'white'
                  }}
                />
              </MentionsInput>
            </div>
          )
          }
        </div>
        <div className="modal__footer">
          <button
            type="button"
            className="btn cancel-btn newCommentCancel"
            data-bs-dismiss="modal"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type="button"
            disabled={isLoading || (!comment && (disabled === undefined ? true : disabled))}
            className="btn success-btn newCommentsave"
            onClick={handleSave}
            id="save-button"
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              submitButtonName || "Save"
            )}
          </button>
        </div>
      </CustomModal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default CommentModal;
