/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "../../common/buttonComponent";
import { getTransferInfo } from "../../../redux/actionCreators/management";
import { ValidationHintWithMsg } from "components/common/inputComponent";
import { getSubjectList } from "services/get-subject-list";
import { handlePress, handleKeyUp } from "../../../util/index";
import CustomModal from "components/common/Modal";

interface TransferStudentModalProps {
  isShow: boolean;
  onClose: () => void;
  onTransfer: (x: any, y: any) => void;
  name: string;
  studentId: string;
  studentClasses: any;
  studentAssessments: any;
  studentDbId: any;
}
export const TransferStudentModal: React.FC<TransferStudentModalProps> = ({
  isShow,
  onClose,
  onTransfer,
  name,
  studentId,
  studentClasses,
  studentAssessments,
  studentDbId,
}) => {
  const [isDisabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<any>({});

  const [subjects, setSubjects] = useState([]);
  const [availableClassrooms, setAvailableClassrooms] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedClassrooms, setSelectedClassrooms] = useState<any>([]);
  const [selectedClassroomsTests, setSelectedClassroomsTests] = useState<any>(
    []
  );
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    !isShow && handleCancel();
    if (studentId) {
      getStudentTransferInfo(studentId, studentDbId);
      getAllSubjectsList()
    }
  }, [isShow]);

  const getStudentTransferInfo = async (val: string, id: any) => {
    try {
      let response = await getTransferInfo(val, id);

      setData(response?.data?.data);
    } catch (e) {
      console.log(e);
    }
  };

  const getAllSubjectsList = async () => {
    const resp = await getSubjectList();
    let subjects = resp?.data?.subjects.map((sub: any) => sub.name)
    setSubjects(subjects);
  }

  useEffect(() => {
    checkDisabled();
  }, [selectedSchool, selectedClassrooms]);

  const checkDisabled = () => {
    if ((selectedSchool && selectedClassrooms?.length)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const clearData = () => {
    setAvailableClassrooms([]);
    setSelectedSchool(null);
    setSelectedClassrooms([]);
    setSelectedClassroomsTests([]);
    setLoading(false);
    setIsError(false);
  };

  const handleCancel = () => {
    clearData();
    onClose();
  };

  const onSubmit = () => {
    const filteredClassrooms = [...selectedClassrooms].filter(Boolean);
    const filteredTests = [...selectedClassroomsTests].filter(Boolean);
    let dataPayload = {
      new_school_id: selectedSchool,
      old_school_id: data?.from_school?.id,
      student_id: studentId,
      classroom_ids: filteredClassrooms,
      db_student_id: studentDbId,
    };
    let testsInfo = {
      name,
      transferedClasses: filteredClassrooms.length,
      classesTests: filteredTests.reduce(
        (partialSum: number, a: any) => Number(partialSum) + Number(a),
        0
      ),
    };

    let seen = new Set();
    var hasDuplicates = filteredClassrooms.some(function(currentObject) {
        return seen.size === seen.add(currentObject.new_class_id).size;
    });

    if (hasDuplicates) {
      setIsError(true);
    } else {
      onTransfer(dataPayload, testsInfo);
      setIsError(false);
    }
  };

  const onChangeSchool = (event: any) => {
    setIsError(false);
    const { value } = event.target;
    if (value !== "-1") {
      setSelectedSchool(value);
      let availableClasses = data?.to_school.find(
        (school: any) => school.id === Number(value)
      );

      if (availableClasses && availableClasses?.classrooms.length) {
        setAvailableClassrooms(availableClasses.classrooms);
        setSelectedClassrooms([]);
        setSelectedClassroomsTests([]);
      } else {
        setAvailableClassrooms([]);
      }
    }
  };

  const onChangeClassroom = (event: any, index: any) => {
    setIsError(false);
    let { value } = event.target;
    if (value !== "-1") {
      const oldClass = event.target[event.target.selectedIndex].dataset.classvalue;
      setSelectedClassrooms((current: any) => {
        let selectedClassroomsCopy = [...current];
        selectedClassroomsCopy[index] = { new_class_id: value, old_class_id: oldClass };
        return selectedClassroomsCopy;
      });
      const classTests =
        event.target[event.target.selectedIndex].dataset.testcount;
      setSelectedClassroomsTests((current: any) => {
        let selectedClassroomsTestsCopy = [...current];
        selectedClassroomsTestsCopy[index] = classTests;
        return selectedClassroomsTestsCopy;
      });
    } else {
      setSelectedClassrooms((current: any) => {
        let selectedClassroomsCopy = [...current];
        selectedClassroomsCopy[index] = null;
        return selectedClassroomsCopy;
      });
      setSelectedClassroomsTests((current: any) => {
        let selectedClassroomsTestsCopy = [...current];
        selectedClassroomsTestsCopy[index] = "0";
        return selectedClassroomsTestsCopy;
      });
    }
  };

  useEffect(() => {
    isShow && document.addEventListener('keydown', handlePress);
    isShow && document.addEventListener('keyup', handleKeyUp);
    return () => {
        document.removeEventListener('keydown', handlePress);
        document.removeEventListener('keyup', handleKeyUp);
    }
  }, []);

  return (
    <CustomModal
      centered
      show={isShow}
      onEscapeKeyDown={onClose}
      contentClassName="modal-content-transferStudent"
      size="lg"
    >
      <div className="modal__header">
        <h6 className="modal__header-title">Transfer Student</h6>
      </div>
      <div className="modal__body">
        <div className="transfer__body">
          <div className="transfer__body-container">
            <div className="modal__form">
              <ValidationHintWithMsg isError={isError} message='Duplicate class selected' extraClass='error-hint-password' />
            </div>

            <div className="transfer__header">
              <div className="transfer__header-name">
                <p className="transfer__header-text">From School</p>
              </div>
              <div className="transfer__header-select">
                <p className="transfer__header-text">To School</p>
              </div>
            </div>

            <div className="transfer__content">
              <div className="transfer__content-name">
              <span>
              <p className={`transfer__content-text ${data?.from_school?.school_name.length > 28 ? 'animated' : ''}`}>{data?.from_school?.school_name}</p>
              </span>
              </div>
              <div className="transfer__content-select">
                <select
                  // disabled={!data?.from_school?.school_name}
                  onChange={(e) => onChangeSchool(e)}
                  className="form-select transfer-select"
                >
                  <option value="-1">Select School</option>
                  {data?.to_school && data?.to_school.length ? (
                    data?.to_school.map((item: any, index: number) => (
                      <option className="mt-5" key={index} value={item.id}>
                        [{item?.district_name}] {item?.school_name}
                      </option>
                    ))
                  ) : (
                    <></>
                  )}
                </select>
              </div>
              <div className="transfer__content-label"></div>
            </div>

            <div className="transfer__header">
              <div className="transfer__header-name">
                <p className="transfer__header-text">From Classroom</p>
              </div>
              <div className="transfer__header-select">
                <p className="transfer__header-text">To Classroom</p>
              </div>
            </div>

            <div className="transfer__content-wrapper">
              {studentClasses && studentClasses.length ? (
                studentClasses.map((className: any, i: number) => {
                  return (
                    <div key={i} className="transfer__content radius">
                      <div className="transfer__content-name">
                        <span>
                        <p className={`transfer__content-text ${className.length > 28 ? 'animated' : ''}`}>{className}</p>
                        </span>
                      </div>
                        <div className="transfer__content-select">
                          <select
                            disabled={!selectedSchool}
                            onChange={(e) => onChangeClassroom(e, i)}
                            className="form-select transfer-select"
                          >
                            <option value="-1">Select Classroom</option>
                            {availableClassrooms.length ? (
                              availableClassrooms.map((item: any, index: number) => {
                                let result = null;
                                let classNameSplited = className.split(" ").map((ele: string) => ele.toLowerCase());
                                let foundSubject: any = subjects.find((sub: string) => sub.toLowerCase() === classNameSplited.find((s: string) => s === sub.toLowerCase()))
                                let matchedSubjects = item.classroom_name.split(" ").find((sub: string) => sub.toLowerCase() === foundSubject.toLowerCase());
                                if (matchedSubjects) {
                                  let matchGrade1 = item.classroom_name.match(new RegExp(`${matchedSubjects}\\s\\d+`));
                                  let matchGrade2 = className.match(new RegExp(`${matchedSubjects}\\s\\d+`));
                                  if (matchGrade1 && matchGrade2 && matchGrade1[0] === matchGrade2[0]) {
                                    result = item.classroom_name
                                  }
                                }
                                if (result) {
                                  return (
                                    <option
                                      className="mt-5"
                                      key={index}
                                      value={item.id}
                                      data-classvalue={data.classrooms[className.trim()][0].id}
                                      data-testcount={
                                        // @ts-ignore
                                        (Object.values(studentAssessments)[i]).length
                                      }
                                    >
                                      {item.classroom_name}
                                    </option>
                                  );
                                } else {
                                  return <></>;
                                }
                              })
                            ) : (
                              <></>
                            )}
                          </select>
                        </div>
                          <div className="transfer__content-label">
                            <span className="transfer__content-pill">
                              {`${selectedClassroomsTests[i] ? 
                                selectedClassroomsTests[i]
                                : 0 } 
                              assessments`}
                            </span>
                          </div>
                    </div>
                  );
                })
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="modal__footer">
        <Button
          buttonText="Cancel"
          onClick={onClose}
          className="btn cancel-btn"
          type="button"
        />
        <Button
          buttonText="Transfer"
          onClick={onSubmit}
          disabled={isDisabled}
          className="btn success-btn btn-lg"
          type="button"
          loading={loading}
          id="save-button"
        />
      </div>
    </CustomModal>
  );
};

interface TransferSuccessModalProps {
  isShow: boolean;
  onClose: () => void;
  info: any;
}
export const TransferSuccessModal: React.FC<TransferSuccessModalProps> = ({
  isShow,
  onClose,
  info,
}) => {
  useEffect(() => {
    !isShow && handleCancel();
  }, [isShow]);

  const handleCancel = () => {
    onClose();
  };

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={onClose}>
      <div className="modal__header">
        <h6 className="modal__header-title">Student Transferred</h6>
      </div>
      <div className="modal__body">
        <p className="modal__body-text mt-1">
          {info.name} has been successfully transferred to{" "}
          {info.transferedClasses} classes with a total of {info.classesTests}{" "}
          assessments
        </p>
      </div>
      <div className="modal__footer">
        <Button
          buttonText="Okay"
          onClick={handleCancel}
          className="btn success-btn btn-lg"
          type="button"
        />
      </div>
    </CustomModal>
  );
};
