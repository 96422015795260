import {
  HeaderSkeleton,
  FooterSkeleton,
} from "components/common/skeleton/ReportsSkeleton";
import ToolTip, { ObjectiveTooltipContent } from "components/common/Tooltip";
import CorrectAnswers from "./CorrectAnswers";

interface Props {
  answers: any;
  questionId: number;
  totalCorrect: any;
  totalIncorrect: any;
  objectiveNumber: string;
  isLoading: boolean;
  totalStudents: any;
  total_students: any;
  objective_description: string;
  objective_name: string;
  variants: string[]
}

const Footer = ({
  objective_description,
  objective_name,
  answers,
  questionId,
  totalCorrect,
  totalIncorrect,
  totalStudents,
  isLoading,
  objectiveNumber,
  total_students,
  variants
}: Props) => {
  const var_a_answers = answers.filter((ans: any) => ans.variant === "A") || [];
  const var_b_answers = answers.filter((ans: any) => ans.variant === "B") || [];
  return (
    <div
      id="footer"
      className={`report__footer formative-footer ${
        totalStudents.length > 15 ? "move-up" : ""
      }`}
      style={{marginTop:"8px"}}
    >
      <CorrectAnswers
        answers={var_a_answers}
        isLoading={isLoading}
        questionId={questionId}
        variant="Var A"
        variantBIsPresent = {variants.length>1}
        variants = {variants}
      />
     {variants.length>1 ? <CorrectAnswers
        answers={var_b_answers}
        isLoading={isLoading}
        questionId={questionId}
        variant="Var B"
        variants = {variants}
      />:<></>} 
      <div
        className={`report__total radius ${
          isLoading ? "" : "dark-gray"
        }`}
      >
        <div className="report__total-title formative-title">
          Total Students
        </div>
        <div className="report__total-count">
          {!isLoading && Array.isArray(total_students) ? (
            total_students?.map((count: any) => {
              return (
                <span
                  className={`${
                    questionId
                      ? count.question_id === questionId
                        ? ""
                        : "hidden"
                      : ""
                  } report__total-total`}
                >
                  {count.correct}
                </span>
              );
            })
          ) : (
            <div style={{ transform: "translateX(-5.5px)" }}>
              <FooterSkeleton rowColumns={5} />
            </div>
          )}
        </div>
        <div className="report__numbers-attempt"></div>
        <div className="report__numbers-score formative-empty"></div>
      </div>

      <div className={`report__total`}>
        <div className="report__total-title formative-title">
          <div className="d-flex align-items-center justify-content-between">
            <span className="report__total-rectangle high"></span>
            <span>Total Correct</span>
          </div>
        </div>
        <div className="report__total-count">
          {!isLoading ? (
            totalCorrect?.map((count: any) => {
              return (
                <span
                  className={`${
                    questionId
                      ? count.question_id === questionId
                        ? ""
                        : "hidden"
                      : ""
                  } report__total-total`}
                >
                  {count.correct}
                </span>
              );
            })
          ) : (
            <div style={{ transform: "translateX(-5.5px)" }}>
              <FooterSkeleton rowColumns={5} />
            </div>
          )}
        </div>
        <div className="report__numbers-attempt"></div>
        <div className="report__numbers-score formative-empty"></div>
      </div>

      <div
        className={`report__total ${
          isLoading ? "" : "dark-after"
        }`}
      >
        <div className="report__total-title formative-title incorrect">
          <div className="d-flex align-items-center justify-content-between">
            <span className="report__total-rectangle low"></span>
            <span>Total Incorrect</span>
          </div>
        </div>
        <div className="report__total-incorrect">
          {!isLoading ? (
            totalIncorrect?.map((count: any) => {
              return (
                <div className="report__total-incorrectContainer">
                  <span
                    className={`${
                      questionId
                        ? count.question_id === questionId
                          ? ""
                          : "hidden"
                        : ""
                    } report__total-total`}
                  >
                    {count.correct}
                  </span>
                  <span
                    className={`report__total-empty ${
                      questionId
                        ? count.question_id === questionId
                          ? ""
                          : "d-none"
                        : ""
                    }`}
                  ></span>
                  <span
                    className={`${
                      questionId
                        ? count.question_id === questionId
                          ? ""
                          : "hidden"
                        : ""
                    } report__total-objective`}
                  >
                    <ToolTip
                      content={
                        <ObjectiveTooltipContent
                          name={objective_name}
                          number={objectiveNumber}
                          description={objective_description}
                        />
                      }
                    >
                      <span
                        className={`${
                          questionId
                            ? count.question_id === questionId
                              ? "font-bold"
                              : ""
                            : ""
                        } report__total-objectiveData`}
                      >
                        {objectiveNumber}
                      </span>
                    </ToolTip>
                  </span>
                </div>
              );
            })
          ) : (
            <div style={{ transform: "translateX(-5.5px)" }}>
              <HeaderSkeleton rowColumns={5} height={104} />
            </div>
          )}
        </div>
        <div className="report__numbers-attempt"></div>
        <div className="report__numbers-score formative-empty"></div>
      </div>
    </div>
  );
};

export default Footer;
