/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from "react";
import { Button } from "../../common/buttonComponent";
import CustomModal from "components/common/Modal";

interface SelectTestTypeModalProps {
  isShow: boolean;
  onClose: () => void;
  onSave: (x: any) => Promise<void>;
  isRandom: boolean;
  currentData: any;
  loading: boolean;
  title?: string;
  description?: string;
}
export const ChooseQuestionOrder: React.FC<SelectTestTypeModalProps> = ({
  isShow,
  onClose,
  onSave,
  isRandom,
  currentData,
  loading,
  title,
  description
}) => {
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);
    await onSave({ ...currentData, show_random_option: checked });
    setIsLoading(false);
  };

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={onClose} className="student-available-modal">
      <div className="modal__header">
        <h6 className="modal__header-title">{title || 'Choose Question Order'}</h6>
      </div>
      <div className="modal__body">
        {description && (
          <p>
            {description}
          </p>
        )}
        <div className="status">
          <div className="status__row">
            <input
              className="form-check-input offline"
              type="radio"
              name="flexRadioDefault1"
              id="flexRadioDefault1"
              checked={!checked}
              onChange={() => setChecked(false)}
              onKeyDown={(e) => e.key === "Enter" && setChecked(false)}
            />
            <label className="status__row-label text-white" htmlFor="flexRadioDefault1">Fixed Order</label>
          </div>
          <div className="status__row">
            <input
              className="form-check-input offline"
              type="radio"
              name="flexRadioDefault2"
              id="flexRadioDefault2"
              checked={checked}
              onChange={() => setChecked(true)}
              onKeyDown={(e) => e.key === "Enter" && setChecked(true)}
            />
            <label className="status__row-label text-white" htmlFor="flexRadioDefault2">Random Order</label>
          </div>
        </div>
      </div>
      <div className="modal__footer">
        <Button
          buttonText="Cancel"
          onClick={onClose}
          className="btn cancel-btn"
          disabled={loading}
          type="button"
        />
        <Button
          buttonText="Make Available"
          onClick={onSubmit}
          className="btn success-btn btn-lg"
          disabled={isLoading || loading}
          loading={isLoading || loading}
          type="button"
        />
      </div>
    </CustomModal>
  );
};
