import { FC, useMemo, useRef } from "react"
interface Props {
    schools: any
    classrooms: any
}
const Scale: FC<Props> = ({ schools, classrooms }) => {
    const containerRef = useRef<HTMLDivElement>(null!)

    const generateScale = useMemo(() => {
        const Scale = []
        let nextPosition = 'center'
        const length = classrooms.length
        for (let index = 0; index < length; index++) {
            if (classrooms[index]?.fk_school_id !== classrooms[index + 1]?.fk_school_id) {
                const school = schools.find((school: any) => school.id === classrooms[index]?.fk_school_id)
                const {name, classrooms: schoolClassrooms} = school
                const classCount = schoolClassrooms.length
                const isFit = nextPosition === "center" ? !Math.floor((name.length / 3) / classCount  ) : false;
                const style={ width: `${classCount * 28}px` }

                if(isFit){
                    Scale.push(
                        <div className="schoolFirst-align" style={style}>
                            <span className="text-align"><span>{name}</span></span>
                        </div>
                    )
                }
                else {
                    if (nextPosition === "center") {
                        Scale.push(
                            <div className="schoolFirst-center" style={style}>
                                <span className="text-first"><span>{name}</span></span>
                            </div>
                        )
                        nextPosition = 'top'
                    }
                    else if (nextPosition === "top") {
                        Scale.push(
                            <div className="schoolFirst-top" style={style}>
                                <span className="text-top"><span>{name}</span></span>
                            </div>)
                        nextPosition = 'bottom'
                    }
                    else {
                        containerRef.current?.classList.add("ScaleContainerHeight")
                        
                        Scale.push(
                            <div className="schoolFirst-bottom" style={style}>
                                <span className="text-bottom"><span>{name}</span></span>
                            </div>)
                        nextPosition = 'center'
                    }
                }
            }
        }
        return Scale
    }, [classrooms, schools])

    // d-flex align-items-center justify-content-center summative-objective-analysis__scale-width
    return <div className="">
        <div className="position-relative new-scale">
            <div className="new-scale__school">
                <div ref={containerRef} className="schoolFirst d-flex">
                    {generateScale}
                    
                </div>
            </div>
        </div>
    </div>
}

export default Scale