const MentionWrapperStyle = {
    control: {
      fontSize: 18,
    },
    "&multiLine": {
      control: {
        fontFamily: "monospace",
        minHeight: 63,
      },
      highlighter: {
        padding: 10,
        border: "2px solid transparent",
      },
      input: {
        padding: 10,
        border: "2px solid silver",
      },
    },
    "&singleLine": {
      display: "inline-block",
      width: 180,
      highlighter: {
        padding: 1,
        border: "2px inset transparent",
      },
      input: {
        padding: 1,
        border: "2px inset",
      },
    },
    suggestions: {
      list: {
        backgroundColor: "white",
        border: "1px solid #333",
        fontSize: 18,
      },
      item: {
        padding: "10px 20px",
        borderBottom: "1px solid #333",
        "&focused": {
          backgroundColor: "#F6A9C6",
        },
      },
    },
  };

  export default MentionWrapperStyle