import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useGetChartData } from 'hooks/useGetChartData';
import { useGetDataValues } from 'hooks/useGetDataValues';
// import { useNavigate } from 'react-router-dom';
import AnalysisBody from '../AnalysisBox/body';
import AnalysisContainer from '../AnalysisBox/container';
import AnalysisFooter from '../AnalysisBox/footer';
import AnalysisHeader from '../AnalysisBox/header';

ChartJS.register(ArcElement, Tooltip, Legend);

const GoalCompletition = ({goalCompletition}: any) => {

    const {completed, not_completed, status, text, percentage} = goalCompletition || {};

    // const navigate = useNavigate();
    const {dataValues, backgroundColor, isNotData} = useGetDataValues({
        firstDatasetValue:completed, 
        secondDatasetValue: not_completed, 
        percentage
    })
    const ChartData = useGetChartData({dataValues, backgroundColor, isNotData});

    return (
        <AnalysisContainer>
            <AnalysisHeader status={status} title="Goal Completion" />
            
            <AnalysisBody 
                ChartData={ChartData}
                text={text}
                percentage={percentage}
                key={isNotData}
                isImage={isNotData}
            />

            <AnalysisFooter 
                firstLabel="Completed"
                secondLabel="Not Completed"
                onClick={() => {}}
            />
        </AnalysisContainer>
    )
};

export default GoalCompletition;
