/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext} from "react";
import StatsIcon from "components/common/svgs/stats-icon";
import { getStats, getWelcomeMessage } from "redux/actionCreators/activity";
import { useDispatch, useSelector } from "react-redux";
import { ColumnLineMixChart } from "components/activity/graphs/ColumnLineMixChart";
import cookie from "js-cookie";
import HtmlParser from "components/common/htmlParser";
import socketKeys from 'util/types/socket-keys';
import Socket from "services/core/socket";
import { ClassroomTooltipContext } from "contexts/classroomTooltip";
import { useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import MessageIcon from "components/common/svgs/message-icon";
import { AppCookies } from "util/constants";


const {
  SOCKET_ONLINE_STUDENT,
  SOCKET_ACTIVITY_FEED,
} = socketKeys;

interface RootState {
  auth: any;
  activity: any;
  classroom: any;
}

const ActivityStats: React.FC<{}> = () => {
  const {tooltip} = useContext(ClassroomTooltipContext);

  const {pathname} = useLocation();
  const app = useSelector((state: RootState) => state);
  const activity = useSelector((state: RootState) => state.activity);
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState("welcome");
  const [data, setData] = useState([]);
  const [currentNav, setCurrentNav] = useState("assessments");
  const [activeStudentsToday, setActiveStudentsToday] = useState(0);
  const [activeStudentsNow, setActiveStudentsNow]     = useState(0);
  const userRole = cookie.get(AppCookies.role);
  const [socketMessage, setSocketMessage] = useState(null!);

  useEffect(() => {
    if(!app.auth?.socketData || app.auth?.socketData?.type !== SOCKET_ONLINE_STUDENT){
      return;
    }

    setSocketMessage(app.auth.socketData?.studentActiveNow || 0);
  },[app])

  useEffect(() => {
    setActiveStudentsNow(socketMessage);
  }, [socketMessage])


  const fetchWelcomeMessage = (paramType: string ,role_id: number, grade_id: number,subject_id: number) => {
    const params = {
      type: paramType,
      grade_id,
      subject_id,
      role_id
    }
    try {
      dispatch(getWelcomeMessage(params));
    }catch (e: any) {
      console.log(e.message);
    }
  } 

  const changeWelcomeMessage = () => {
    if(userRole === 'Super Admin'){
        if(app.activity.subject_id > 0 && app.activity.grade_id > 0){
          fetchWelcomeMessage('SUBJECT_AND_GRADE', 0, app.activity.grade_id , app.activity.subject_id )
        }
        else if(app.activity.teacher_id > 0){
          fetchWelcomeMessage('ROLE', 4, 0, 0)

        }
        else if(app.activity.school_id > 0){
          fetchWelcomeMessage('ROLE', 3, 0, 0)
        }
        else if(app.activity.district_id > 0){
          fetchWelcomeMessage('ROLE', 2, 0, 0)
        }
        else{
          fetchWelcomeMessage('ROLE', 1, 0, 0)
        }
    }
    else if(userRole === 'District Admin'){
      fetchWelcomeMessage('ROLE', 2, 0, 0)
    }
    else if(userRole === 'School Admin'){
      fetchWelcomeMessage('ROLE', 3, 0, 0)
    }
    else if(userRole === 'Classroom Admin'){
      if(app.activity.classroom_id > 0 && app.activity.subject_id > 0 && app.activity.grade_id > 0){
        fetchWelcomeMessage('SUBJECT_AND_GRADE', 0, app.activity.grade_id , app.activity.subject_id )
      }
      else{
        fetchWelcomeMessage('ROLE', 4, 0, 0)
      }
    }
  }

  useEffect(() => {
    if (
      app.activity.type === "SET_FILTERS" &&
      app.activity.applyFilters === true
    ) {
      dispatch(
        getStats({
          params: {
            school_id: app.activity.school_id,
            teacher_id: app.activity.teacher_id,
            grade_id: app.activity.grade_id,
            district_id: app.activity.district_id,
            subject_id:  app.activity.subject_id,
            classroom_id: app.activity.classroom_id
          },
        })
      );

      changeWelcomeMessage();
    }

    if (app.activity.type === "GET_ACTIVITY_STATS") {
      const filters = {
        school_id: app.activity.school_id,
        teacher_id: app.activity.teacher_id,
        grade_id: app.activity.grade_id,
        district_id: app.activity.district_id,
        subject_id:  app.activity.subject_id,
        classroom_id: app.activity.classroom_id
      }

      const socketInstance = Socket.getInstance();

      socketInstance.emit(SOCKET_ACTIVITY_FEED,app.activity?.district_id > 0 ? filters: null);
      
      setData(app.activity?.payload?.data?.data);
      setActiveStudentsToday(
        app.activity?.payload?.data?.data?.active_students_today
      );
    } else if (app.classroom.type === "GET_ACTIVITY_STATS_FAIL") {
    }
  }, [activity, currentNav]);

  const isBlur = pathname === '/activity' && (tooltip === 1 || tooltip === 2 || tooltip === 3 || tooltip === 4);

  return (
    <>
      <div className="activity__welcome-wrapper">
        
          <div style={{...(isBlur && {position: 'relative'})}} className="section light-bg-gray">
            {isBlur && (
              <div className="toolTip-bg"></div>
            )}
            <div className="activity__welcome">
              <div className="activity__welcome-leftCenter">

                <div className="activity__welcome-left">
                  <div className="d-flex activity__welcome-leftContent">
                    <button
                    type="button"
                    tabIndex={0}
                      
                      className={`activity__welcome-leftLink ${
                        currentTab === "welcome" ? "active" : ""
                      }`}
                      onClick={() => setCurrentTab("welcome")}
                    >
                      <MessageIcon currentTab={currentTab}/>
                      <p>Welcome</p>
                    </button>
                    <button
                      type="button"
                      tabIndex={0}
                      //href="#!"
                      className={`activity__welcome-leftLink ${
                        currentTab === "stats" ? "active" : ""
                      }`}
                      onClick={() => setCurrentTab("stats")}
                    >
                      <StatsIcon currentTab= {currentTab} />
                      <p>Stats</p>
                    </button>
                  </div>
                </div>
                <div className="activity__welcome-center">
                  <div className="activity__welcome-centerContent">
                    {currentTab === "welcome" && (
                      <div className="activity__welcome-content">
                        <div className="activity__welcome-title">
                        {app.activity.loading ? (
                          <h4>
                            <Skeleton baseColor="#00000033" highlightColor="#737373" borderRadius={4} width="100%" height={20} />
                          </h4>
                        ):(
                          <h4>{app.activity.welcome_title ?? 'Welcome to the 2022-23 School Year'}</h4>
                        )}
                        </div>
                        <div className="ql-container ql-snow activity__welcome-centerText activity-welcome-centerText">
                          <div className="ql-editor">
                            {app.activity.loading ? (
                              <>
                                <Skeleton baseColor="#00000033" highlightColor="#737373" borderRadius={4} width="70%" height={15} />
                                <Skeleton baseColor="#00000033" highlightColor="#737373" borderRadius={4} width="100%" height={15} />
                                <Skeleton baseColor="#00000033" highlightColor="#737373" borderRadius={4} width="100%" height={15} />
                                <Skeleton baseColor="#00000033" highlightColor="#737373" borderRadius={4} width="80%" height={15} />
                              </>
                            ):(
                              <HtmlParser html={app.activity.welcome_message || ""}/>
                            )}
                          </div>
                        </div>
                      </div>
                    )}

                    {currentTab === "stats" && (
                      <>
                        <div className="activity__status">
                          <div className="cl-top-tabs activity__status-tabs">
                            <div
                              className="nav nav-tabs activity__status-header"
                              id="nav-tab"
                              role="tablist"
                            >
                              <button
                                className={`nav-link btn text-btn btn-lg ${
                                  currentNav === "assessments" ? "active" : ""
                                }`}
                                id="nav-books-tab"
                                type="button"
                                onClick={() => setCurrentNav("assessments")}
                              >
                                Assessments Taken
                              </button>
                              <button
                                className={`nav-link btn text-btn btn-lg ${
                                  currentNav === "teachers" ? "active" : ""
                                }`}
                                type="button"
                                onClick={() => setCurrentNav("teachers")}
                              >
                                Teacher Logins
                              </button>
                              <button
                                className={`nav-link btn text-btn btn-lg ${
                                  currentNav === "students" ? "active" : ""
                                }`}
                                type="button"
                                onClick={() => setCurrentNav("students")}
                              >
                                Student Logins
                              </button>
                            </div>
                            {currentNav === "assessments" && (
                              <ColumnLineMixChart   
                                data={data}
                                tab="assessments"
                                loading={app.activity.loading}
                              />
                            )}

                            {currentNav === "teachers" && (
                              <ColumnLineMixChart   
                                data={data}
                                tab="teachers"
                                loading={app.activity.loading}
                              />
                            )}

                            {currentNav === "students" && (
                              <ColumnLineMixChart   
                                data={data}
                                tab="students"
                                loading={app.activity.loading}
                              />
                            )}
                            
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="d-flex activity__welcome-right">
                {app.activity.loading ? (
                  <div className="activity__welcome-rightActive">
                    <Skeleton baseColor="#00000033" highlightColor="#737373" borderRadius={4} width={40} height={26} />
                    <div className="active-text-skeleton">
                      <Skeleton baseColor="#00000033" highlightColor="#737373" borderRadius={8} width={80} height={16} />
                      <Skeleton baseColor="#00000033" highlightColor="#737373" borderRadius={8} width={100} height={16} />
                    </div>
                  </div>
                ):(
                  <div className="activity__welcome-rightActive">
                    <h2>{activeStudentsNow??0}</h2>
                    <p className="shadow-text">Students Active Now</p>
                  </div>
                )}
                {app.activity.loading ? (
                  <div className="activity__welcome-rightOnline">
                    <Skeleton baseColor="#00000033" highlightColor="#737373" borderRadius={4} width={40} height={26} />
                    <div className="active-text-skeleton">
                      <Skeleton baseColor="#00000033" highlightColor="#737373" borderRadius={8} width={80} height={16} />
                      <Skeleton baseColor="#00000033" highlightColor="#737373" borderRadius={8} width={100} height={16} />
                    </div>
                </div>
                ):(
                  <div className="activity__welcome-rightOnline">
                    <h2>{activeStudentsToday}</h2>
                    <p className="shadow-text">Students Active Today</p>
                  </div>
                )}
              </div>
            </div>
          </div>
      </div>
    </>
  );
};

export default ActivityStats;
