import ReviewIcon from "assets/img/review-test-btn.svg";
import EditIcon from "assets/img/edit-icon.svg";
import { Fragment, useContext, useMemo, useRef, useState } from "react";
import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment";
import { TableSelectCommon } from "components/common/selectComponent";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import _ from 'lodash';
import { AppCookies, SCROLL_VALUE_SUMMATIVE } from "util/constants";
import useWindowScrollPosition from "hooks/useWindowScrollPosition";
import {
  assignFormatives,
  assignSummatives,
} from "redux/actionCreators/classroom";
import { Assessment, ReportsNavigation, SingleFormative, SingleSummative } from "interfaces/professionalDevelopment";
import { ChooseQuestionOrder } from "components/classroom/modals/chooseQuestionOrder";
import QuestionMarkIcon from "assets/img/question-mark-tooltip-icon.png";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import ReportClickAlertModal from "components/classroom/modals/ReportClickAlertModal";
import AvailabilityModal from "components/classroom/modals/availability";
import { useDispatch } from "react-redux";
import ActionType from "redux/actionTypes";
import { NoStudents } from "components/classroom/modals/noStudents";

library.add(faCaretLeft);

const options = [
  // {
  //   value: "Grade Frequency and Distribution",
  //   label: "Grade Frequency and Distribution",
  // },
  { value: "Item Response Report", label: "Item Response Report" },
  { value: "Standards at Risk", label: "Standards at Risk" },
  { value: "Classroom Summative Report", label: "Classroom Summative Report" },
  // { value: "Student Test Report", label: "Student Test Report" },
  // { value: "Student Summative Report", label: "Student Summative Report" },
];

// navigate(`/classroom/summative/monitor/${x.id}/${classroomId}`);

const AssessmentBody = ({ setShowRemmediationModal, classroomId, isSummative, filter, scheduledDates, formativeLoading, summatives, formatives }: any) => {
  const { setSummatives, fetchFormatives, schoolId, markAssessmentCompleted, setFormatives, navigateTo, setShowReviewHelpModel, fetchSummatives, isLoading } = useContext(ProfessionalDevelopmentContext)
  const checkboxRef = useRef(null);
  const dispatch = useDispatch();
  

  const reports = useMemo(() => {
    let reports = (isSummative ? summatives : formatives) as Array<SingleFormative | SingleSummative>

    return reports
  }, [summatives, formatives, isSummative]);

  const modifiedReports = useMemo(() => {
    return reports
    .map((formm: any, index: number) => {
      const heightForm = scheduledDates && scheduledDates.length > 0 ? scheduledDates[index]?.minHeight : '';
      return {
          ...formm,
          height: heightForm-64
      }
    })

    /* eslint-disable react-hooks/exhaustive-deps */
  },[reports, scheduledDates]);

  const navigate = useNavigate();

  const [modalShow, setModalShow] = useState(false);
  const [openNoStudentsModal, setOpenNoStudentsModal] = useState(false);

  // const [showRemmediationModal, setShowRemmediationModal] = useState<any>(null!);
  const [openRandomModal, setOpenRandomModal] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const scrollValue = useWindowScrollPosition(SCROLL_VALUE_SUMMATIVE, !isLoading);
  const [isFlag, setFlag] = useState(false);
  const [currentFormative, setCurrentFormative] = useState<any>(null);
  const [currentSummative, setCurrentSummative] = useState<any>(null);
  const [helpTitle, setHelpTitle] = useState<any>(null);
  const [showReportAlertModal, setShowReportAlertModal] = useState(false);

  const selectReport = (
    reportName: string,
    summativeId: number,
    assessmentName: string
  ) => {
    sessionStorage.setItem(SCROLL_VALUE_SUMMATIVE, `${scrollValue}`)

    if (reportName === "Classroom Summative Report") {
      navigate(`report/summative/${summativeId}/${classroomId}`);
    }
    // if (reportName === "Grade Frequency and Distribution") {
    //   navigate(`/classroom/report/grade-frequency-distribution/${classroomId}`);
    // }

    if (reportName === "Item Response Report") {
      Cookies.set(AppCookies.test_name, assessmentName);

      navigate(
        `/classroom/report/item-response-report-summative/${summativeId}/${classroomId}`
      );
    }

    if (reportName === "Standards at Risk") {
      Cookies.set(AppCookies.test_name, assessmentName);

      navigate(
        `/classroom/report/standard-at-risk/${classroomId}/${summativeId}`
      );
    }

    // if (reportName === "Student Summative Report") {
    //   navigate(
    //     `/classroom/report/summative/student/${classroomId}/${summativeId}`
    //   );
    // }

  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const onClickRemediate = (formativeId: any) => {
    setShowRemmediationModal(formativeId);
  }

  const handleSummativeAvailable = (e: any, x: any) => {
    if (!summatives[0].students_total_count) {
      setOpenNoStudentsModal(true);
      return;
    }
    const currentToggleState = e.target.checked;

    const updatedSummatives = summatives.map((summ: any) => {
      if (summ.id === x.id) {
        return {
          ...summ,
          enabled: currentToggleState
        };
      }
      return summ;
    });
    setSummatives(updatedSummatives)
    

    if (x.show_random_option && e.target.checked) {
      setOpenRandomModal(true);
    } else {
      handleAvaibilityToggle(x, e.target.checked);
    }
  }

  const handleAvaibilityToggle = async (x: any, checked: any) => {
    if (isSummative) {
      await assignSummatives(
        {
          classroom_id: classroomId,
          summative_id: x.id,
          is_enabled: checked,
          is_random: x.show_random_option,
        },
        switchFlag
      );
      fetchSummatives(schoolId, classroomId);
    } else {
      await assignFormatives(
        {
          classroom_id: classroomId,
          formative_id: x.id,
          is_enabled: checked,
          is_random: x.show_random_option,
        },
        switchFlag
      );
      fetchFormatives(schoolId, classroomId, filter)
    }

    if (checked) {
      if(document.getElementById(`monitor-$${x.id}`) as HTMLInputElement){
        (
          document.getElementById(`monitor-$${x.id}`) as HTMLInputElement
        ).disabled = false;
      }
    } else {
      if(document.getElementById(`monitor-$${x.id}`) as HTMLInputElement){
        (
          document.getElementById(`monitor-$${x.id}`) as HTMLInputElement
        ).disabled = true;
      }
    }
  };

  const onChangeAvailable = (e: any, x: any) => {
    checkboxRef.current = { ...x }
    if (isSummative) {
      setCurrentSummative(x);
      handleSummativeAvailable(e, x)
    }
    else {
      setCurrentFormative(x)
      handleOnChangeToggleFormative(e, x)
    }

  };

  const handleOnChangeToggleFormative = (e: any, x: any) => {
    if (!formatives[0].students_total_count) {
      setOpenNoStudentsModal(true);
      return;
    }

    const currentToggleState = e.target.checked;

    const updatedFormatives = formatives.map((formative: any) => {
      if (formative.id === x.id) {
        return {
          ...formative,
          enabled: currentToggleState
        }
      }
      return formative;
    });
    
    setFormatives(updatedFormatives);

    if (x.show_random_option && e.target.checked) {
      setOpenRandomModal(true);
    } else {
      handleAvaibilityToggle(x, e.target.checked);
    }
  };

  const onChangeRandom = async (x: any) => {
    handleAvaibilityToggle(x, true);
    setTimeout(() => {
      setOpenRandomModal(false);
      isSummative ? setCurrentSummative(null) : setCurrentFormative(null)
    }, 1000);
  };

  const switchFlag = () => {
    setFlag(!isFlag);
  };

  const onCancelChooseQuestionModal = () => {
    const { id } = checkboxRef.current as any
    const reports = isSummative ? summatives : formatives;
    const reportIndex = reports.findIndex(
      (repo: any) => repo.id === id
    );
    if (reportIndex > -1) {
      const tempReports = [...reports];
      tempReports[reportIndex].enabled = false;
      isSummative ? setSummatives(tempReports) : setFormatives(tempReports);
    }
    setOpenRandomModal(false);
  };

  const onMarkCompleted = (e: any, report: any) => {
    markAssessmentCompleted({
      isSummative,
      status: e.target.checked,
      [isSummative ? 'summative_id' : 'formative_id']: report.id,
      classroom_id: classroomId
    });
  }

  const onClickAvailableStudents = (report: any) => {
    setModalShow(true);
    isSummative ? setCurrentSummative(report) : setCurrentFormative(report);
    dispatch({
      type: isSummative ? ActionType.SUMMATIVE_NAME : ActionType.FORMATIVE_NAME,
      payload: report.name,
    });
  }

  if (isLoading || formativeLoading) {
    return (
      <tbody style={{ height: 321 }}>
        <TableSkeleton columns={isSummative ? 9 : 10} count={3} height={106} />
      </tbody>
    )
  }


  const onCloseNoStudents = () => {
    setOpenNoStudentsModal(false);
  };
  
  return (
    <>
      <tbody>
        {classroomId && modifiedReports.length ? (
          <>
            {
              modifiedReports?.map((report, index) => {

                const {
                  available_students, last_activity, name, students_assessed, proficiency, students_assessed_count, description, id, assessment_time,
                  can_be_edited, can_be_enabled, enabled, students_total_count, is_past_window, alignment_label, remediation_count
                } = report as any;
                
                const [dd, mm, yy] = last_activity && last_activity !== 'Not Yet' ? last_activity.split('/') : ['', '', ''];
                const year = yy[yy.length-2] + '' + yy[yy.length-1];
                const lastActivity = year ? dd + '/' + mm + '/' + year : last_activity;

                const isMarkCompletedDisabled = Math.floor((students_assessed_count/students_total_count)*100) < 50;

                const enableAccess = !!students_assessed_count;

                const reportOptions = _.cloneDeep(options).map(
                  (item: any) => {
                    item.disabled = !enableAccess && !proficiency;
                    return item;
                  }
                );

                return <Fragment key={id}>
                  <tr
                    className={`summative__table-row tableRowHeight ${!(index % 2) ? 'dark-row' : "light-row"}`}
                    id={`first-row-${index}`}
                  >          
                    <td className="objective-data labled">

                      {/* <span className="ellipsis-animated cursor-pointer">
                        <p onClick={() => navigateTo(isSummative ? Assessment.summative : Assessment.formative, ReportsNavigation.PREVIEW, report)} >
                          {name}
                        </p>
                      </span> */}

                      {
                        enableAccess ? (
                          <span className={`ellipsis-animated ${!isSummative && "cursor-pointer"}`}>
                            <p onClick={() => !isSummative && navigateTo(isSummative ? 'summatives' : 'formatives', ReportsNavigation.PREVIEW, report)} >
                              {name}
                            </p>
                          </span>
                        ) : (

                          <span className={`ellipsis-animated ${!isSummative && "cursor-pointer"}`}>
                            <p onClick={() =>
                               !isSummative && setShowReviewHelpModel(true)
                            }>
                              {name}
                            </p>
                          </span>
                        )
                      }

                      {assessment_time && (
                        <p
                          style={{
                            position: "relative",
                            top: 6
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              width: "max-content",
                            }}
                            className="objective-data-highlight"
                          >
                            {capitalizeFirstLetter(
                              assessment_time.replace(
                                /[{()}]/g,
                                ""
                              )
                            )}
                          </span>
                        </p>
                      )}

                      {alignment_label && (
                        <p
                          style={{
                            position: "relative",
                          }}
                        >
                          <span
                            style={{
                              position: "absolute",
                              width: "max-content",
                            }}
                            className="objective-data-highlight mt-2"
                          >
                            {capitalizeFirstLetter(
                              alignment_label.replace(
                                /[{()}]/g,
                                ""
                              )
                            )}
                          </span>
                        </p>
                      )}

                    </td>

                    <td className="text-center activiy-data last_activity_date">
                      {!enableAccess && !proficiency ? (
                        <p className="warning-flag">
                          Not Yet
                        </p>
                      ) : (lastActivity || "-")}
                    </td>

                    <td>
                      <div className="assessed">
                        <div className={"track"}>
                          <div
                            className={`${!!students_assessed_count && "fill"}`}
                            style={{
                              width:
                                (students_assessed_count * 100) /
                                students_total_count + "%"
                            }}
                          >
                            <p>{students_assessed}</p>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <div className="proficiency">
                        <div className="track">
                          <div
                            className={`fill ${proficiency > 69
                              ? "succeed"
                              : "failed"
                              }`}
                            style={{ width: (proficiency || 0) + "%" }}
                          >
                            <p>{proficiency || students_assessed_count > 0 ? proficiency+"%" : "-"}</p>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className="text-center">
                      {name.includes('A1') ? '' :
                        enableAccess ? (
                          <div className="cursor-pointer">
                            <img src={ReviewIcon} alt=""
                              onClick={() => navigateTo(isSummative ? 'summatives' : 'formatives', ReportsNavigation.PREVIEW, report)}
                            />
                          </div>
                        ) : (
                          <div
                            className="cursor-pointer "
                            onClick={() =>
                              setShowReviewHelpModel(true)
                            }
                          >
                            <img
                              className="question-mark-icon"
                              src={QuestionMarkIcon}
                              alt=""
                            />
                          </div>
                        )
                      }
                    </td>
                    <td className="text-center">
                      <img
                        src={EditIcon}
                        alt=""
                        className={isSummative ? can_be_edited ? 'cursor-pointer' : '' : 'cursor-pointer'}
                        style={{ opacity: isSummative ? can_be_edited ? 1 : 0.56 : 1 }}
                        onClick={() => {
                          if(isSummative){
                            if(can_be_edited){
                              sessionStorage.setItem("SUMMATIVE_NAME", report.name);
                              navigateTo(isSummative ? Assessment.summative : Assessment.formative, ReportsNavigation.ANSWER, report)
                            }
                          }else{
                            sessionStorage.setItem("FORMATIVE_NAME", report.name);
                            navigateTo(isSummative ? Assessment.summative : Assessment.formative, ReportsNavigation.ANSWER, report)
                          }
                        }}
                      />
                    </td>
                    <td>
                      {isSummative ? (
                        <TableSelectCommon
                          customClassName={
                            summatives?.length === 2 && index === 1
                              ? "top-start"
                              : ""
                          }
                          isOpen={selectedId === index + 1}
                          toggleSelect={() => {
                            if (selectedId !== index + 1) {
                              setSelectedId(index + 1);
                            } else {
                              setSelectedId(0);
                            }
                            setHelpTitle("Summative reports");
                            !enableAccess &&
                              !proficiency &&
                              setShowReportAlertModal(true);
                          }}
                          options={reportOptions}
                          onChange={(value: any) => {
                            selectReport(value, id, name);
                          }}
                          isReport={true}
                        />
                      ) : (
                        <button
                          onClick={() => {
                            if(!enableAccess && !proficiency){
                              setShowReportAlertModal(true)
                            }else{
                              Cookies.set(AppCookies.test_name, name);
                              navigateTo('formatives', ReportsNavigation.IRR, report);
                              sessionStorage.setItem(SCROLL_VALUE_SUMMATIVE, `${scrollValue}`);
                            }
                          }}

                          style={{
                            opacity:
                              !enableAccess && !proficiency
                                ? 0.26
                                : 1,
                          }}

                          className="btn outline-btn outline-btn-sm IrrBlock"
                        >
                          {/* {proficiency} {enableAccess ? 'true' : 'false'} */}
                          IRR
                        </button>
                      )}
                    </td>
                    {!isSummative && <td className="" style={{ opacity: remediation_count === 0 ? 0.26 : 1}}>
                      <div  onClick={() => remediation_count !== 0 ? onClickRemediate(report.id) : {} } className={`d-flex justify-content-center align-items-center ${remediation_count !== 0 ? 'cursor-pointer' : ''}`}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="24" viewBox="0 0 17 24" fill="none">
                          <g clip-path="url(#clip0_103_2239)">
                            <mask id="mask0_103_2239" style={{maskType:'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="24">
                              <path d="M16.434 0H0V23.007H16.434V0Z" fill="white"/>
                            </mask>
                            <g mask="url(#mask0_103_2239)">
                              <path d="M0.821045 20.5426V16.4486C0.821059 15.7343 1.05388 15.0393 1.48423 14.4691C1.91459 13.8989 2.51905 13.4845 3.20605 13.2886L6.57405 12.3276L8.21604 13.9706L9.86105 12.3276L13.227 13.2896C13.9139 13.4857 14.5181 13.9002 14.9483 14.4704C15.3784 15.0406 15.6111 15.7354 15.611 16.4496V20.5436C15.6112 20.7594 15.5688 20.9731 15.4862 21.1725C15.4037 21.3719 15.2827 21.5531 15.1301 21.7057C14.9775 21.8583 14.7963 21.9793 14.5969 22.0618C14.3976 22.1444 14.1838 22.1868 13.968 22.1866H2.46405C2.2482 22.1866 2.03447 22.1441 1.83507 22.0615C1.63566 21.9788 1.45449 21.8577 1.30192 21.7051C1.14934 21.5524 1.02833 21.3711 0.945824 21.1717C0.863314 20.9722 0.820914 20.7585 0.821045 20.5426Z" fill="#4ABCEE"/>
                              <path d="M0.821045 20.5426V16.4486C0.821059 15.7343 1.05388 15.0393 1.48423 14.4691C1.91459 13.8989 2.51905 13.4845 3.20605 13.2886L6.57405 12.3276L8.21604 13.9706L9.86105 12.3276L13.227 13.2896C13.9139 13.4857 14.5181 13.9002 14.9483 14.4704C15.3784 15.0406 15.6111 15.7354 15.611 16.4496V20.5436C15.6112 20.7594 15.5688 20.9731 15.4862 21.1725C15.4037 21.3719 15.2827 21.5531 15.1301 21.7057C14.9775 21.8583 14.7963 21.9793 14.5969 22.0618C14.3976 22.1444 14.1838 22.1868 13.968 22.1866H2.46405C2.2482 22.1866 2.03447 22.1441 1.83507 22.0615C1.63566 21.9788 1.45449 21.8577 1.30192 21.7051C1.14934 21.5524 1.02833 21.3711 0.945824 21.1717C0.863314 20.9722 0.820914 20.7585 0.821045 20.5426Z" stroke="#323233" strokeWidth="1.573" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M12.325 4.9298C12.3248 5.74224 12.0837 6.53639 11.6322 7.21181C11.1807 7.88724 10.539 8.41361 9.78838 8.72438C9.03772 9.03516 8.21177 9.11637 7.41497 8.95774C6.61816 8.79912 5.88628 8.4078 5.31186 7.83324C4.73745 7.25869 4.3463 6.52671 4.18787 5.72986C4.02944 4.93302 4.11086 4.10709 4.42181 3.35651C4.73276 2.60593 5.2593 1.9644 5.93483 1.51305C6.61037 1.06171 7.40457 0.820801 8.21701 0.820801C8.75657 0.820801 9.29083 0.92709 9.78931 1.1336C10.2878 1.34011 10.7407 1.64279 11.1222 2.02436C11.5036 2.40593 11.8062 2.85891 12.0126 3.35743C12.219 3.85595 12.3251 4.39025 12.325 4.9298Z" fill="#FE9E2C"/>
                              <path d="M12.325 4.9298C12.3248 5.74224 12.0837 6.53639 11.6322 7.21181C11.1807 7.88724 10.539 8.41361 9.78838 8.72438C9.03772 9.03516 8.21177 9.11637 7.41497 8.95774C6.61816 8.79912 5.88628 8.4078 5.31186 7.83324C4.73745 7.25869 4.3463 6.52671 4.18787 5.72986C4.02944 4.93302 4.11086 4.10709 4.42181 3.35651C4.73276 2.60593 5.2593 1.9644 5.93483 1.51305C6.61037 1.06171 7.40457 0.820801 8.21701 0.820801C8.75657 0.820801 9.29083 0.92709 9.78931 1.1336C10.2878 1.34011 10.7407 1.64279 11.1222 2.02436C11.5036 2.40593 11.8062 2.85891 12.0126 3.35743C12.219 3.85595 12.3251 4.39025 12.325 4.9298Z" stroke="#323233" strokeWidth="1.573" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M4.10803 22.1859V18.8989" stroke="#323233" strokeWidth="1.573" strokeLinecap="round" strokeLinejoin="round"/>
                              <path d="M12.3251 18.8989V22.1859" stroke="#323233" strokeWidth="1.573" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                          </g>
                          <defs>
                            <clipPath id="clip0_103_2239">
                              <rect width="16.434" height="23.007" fill="white"/>
                            </clipPath>
                          </defs>
                        </svg>
                        <span className="fw-bold ps-2">{remediation_count}</span>
                      </div>
                    </td>}
                    
                    <td className="text-center position-relative professionalDevCheaks">
                      <label className={`${isSummative ? !can_be_enabled && 'disabled' : ''} switch mb-0 studentCheakBox`}>
                        <input
                          type="checkbox"
                          className="switch-checkbox"
                          onChange={(e) => onChangeAvailable(e, report)}
                          checked={enabled}
                          disabled={isSummative ? !can_be_enabled : false}
                        />
                        
                        <span className="switch-slider"></span>
                      </label>
                      
                      <p
                        className={`available-data-text lower ${enabled ? "" : "d-none"}`}
                        id={`total-students-formative`}
                      >
                        <a href="#/" onClick={() => onClickAvailableStudents(report)} className="text-white text-decoration-none">
                          {available_students} students
                        </a>
                      </p>

                    </td>
                    <td>
                      {enabled ? (
                        <button
                          className={`btn success-btn ms-3 py-2`}
                          id={`monitor-$${id}`}
                          onClick={() => navigateTo(isSummative ? Assessment.summative : Assessment.formative, ReportsNavigation.MONITOR, report)}
                        >
                          Monitor
                        </button>
                      ) : (
                        <div className="turnMonitorContainer">
                          {!is_past_window && (
                            <FontAwesomeIcon
                              name="Check"
                              icon={faCaretLeft}
                              width={9}
                              height={11}
                              style={{ fontSize: 16, marginRight: '2px', marginTop: '2px' }}
                            />
                          )}
                        
                          <div>
                            <p className="turnMonitor" style={{ fontSize: 12 }}>
                              {/* {isSummative ? capitalizeFirstLetter(assessment_time.replace(/[{()}]/g, "")) : 'Turn on to monitor'} */}
                             
                              {isSummative ? is_past_window ? 'Availablity Closed' : ( can_be_enabled ? "Turn on to monitor" : capitalizeFirstLetter(assessment_time.split('until')[0].replace(/[{()}]/g, "")) ) : 'Turn on to monitor'}
                            </p>
                            {/* <p className="notAvailable">Not Available</p> */}
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr id={`second-row-${index}`} className="summative__table-row dark-row" style={{ ...(!!report.height && {height: report.height}), backgroundColor: index % 2 ? '#537AB7' : '' }}>
                    <td colSpan={7} className="full-data full-desc">
                      <p className="full-desc-details">{description}</p>
                    </td>
                    {!isSummative && <td>
                      
                    </td>}
                    <td colSpan={2} className="text-right markComp">
                      <p className="mark-comp me-3">Mark Completed</p>
                      <label className={`switch mb-0 me-3 mark-switch ${isMarkCompletedDisabled ? 'disabled' : ''}`}>
                        <input
                          type="checkbox"
                          className="switch-checkbox"
                          onChange={(e: any) => onMarkCompleted(e, report)}
                          checked={report.is_completed}
                          disabled={isMarkCompletedDisabled}
                        />
                        <span className="switch-slider"></span>
                      </label>
                    </td>
                  </tr>
                </Fragment>
              })

            }
          </>
        ) : (
          <tr>
            <td colSpan={10} className="notfound__data">
              <div className="notfound__data-container">
                {classroomId ? (
                  <p className="text-center text-shadow mb-0">
                    No{" "}
                    {isSummative ? "Summative " : "Formative "}
                    Found!
                  </p>
                ):(
                  <p className="text-center text-shadow mb-0">
                    Select a classroom above to view{" "}
                    {isSummative ? "Summative " : "Formative "}
                    Assessments.
                  </p>
                )}
              </div>
            </td>
          </tr>
        )}
      </tbody>

      {modalShow === true && (
        <AvailabilityModal
          show={modalShow}
          summativeId={isSummative ? currentSummative?.id : 0}
          formativeId={isSummative ? 0 : currentFormative?.id}
          setModalShow={setModalShow}
          classroomId={classroomId}
        />
      )}

      {openRandomModal && (
        <ChooseQuestionOrder
          isShow={openRandomModal}
          onClose={onCancelChooseQuestionModal}
          onSave={onChangeRandom}
          isRandom={true}
          currentData={isSummative ? currentSummative : currentFormative}
          loading={false}
        />
      )}

      {openNoStudentsModal && (
        <NoStudents
          isShow={openNoStudentsModal}
          onClose={onCloseNoStudents}
          loading={false}
        />
      )}

      

      {showReportAlertModal && (
        <ReportClickAlertModal
          show={showReportAlertModal}
          assessment="summative"
          closeModal={() => {
            setSelectedId(0);
            setHelpTitle(null);
            setShowReportAlertModal(false);
          }}
          message={helpTitle}
        />
      )}
      
      {/* {!!showRemmediationModal && !showNewAttemptModal && (
        <div >
          <RemediationModal 
            ref={modalRef}
            onClose={() => setShowRemmediationModal(null!)} 
            onClickQuestionMark={() => {
              setNewAttemptInfoModal(true);
            }}
            fetchFormativeRemediateData={fetchFormativeRemediateData}
            formativeRemediateList={formativeRemediateList}
            formativeId={showRemmediationModal}
            reEnableAssessments={reEnableAssessments as any}
            classroomId={classroomId}
            onNewAttemptAssigned={() => {
              setShowNewAttemptModal(true);
            }}
          />
        </div>
      )} */}
    </>
  );
};
export default AssessmentBody;
