import CustomModal from "../Modal";

const ApiModal = ({setIsShow, title, description, closeButtonName}: any) => {

    const logoutStudent = () => {
        setIsShow();
    }


    return <div className={'mainContent-backDrop'}> <CustomModal centered show onHide={() => { }} onEscapeKeyDown={() => { }}>
        <div className="modal__header">
            <span className="rounded"></span>
            <h6 className="modal__header-title">
                {
                    title || 'Access Denied'
                }
            </h6>
        </div>
        <div className="modal__body">
            <p className="BodyParagraph">
                {description || 'Something went wrong. Please try again later.'}
            </p>
        </div>
        <div className="modal__footer">
            <button
                className={"btn cancel-btn cursor-pointer"}
                onClick={logoutStudent}
            >
                {closeButtonName || 'Close'}
            </button> 
           
        </div>
    </CustomModal>
    </div>
}
export default ApiModal;