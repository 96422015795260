/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { Modal } from "react-bootstrap";
import { ToastContainer } from "react-toastify";

interface Props {
  assessmentToDelete: any;
  summativeId?: number;
  formativeId?: number;
  setModalShow: any;
  isSuperAdmin: boolean;
}

const AddAssesmentWarningModal: React.FC<Props> = (props) => {
  const { setModalShow } = props;

//   const {date_formatted} = assessmentToDelete;

//   const [loading, setIsLoading] = useState(false);
  // const [isSaving] = useState(false);

  // const handleSave = async () => {
  //   clearPacingCalenderAssessments('all');
  // };

  const closeModal = () => {
    // setIsLoading(false);
    setModalShow(false);
  };

  return (
    <>
      <Modal centered show onEscapeKeyDown={closeModal}>
        <div className="PacingCalender__DeleteModel">
          <div>
            <h2 className="modal__header-title px-2">No Assessments Available</h2>
          </div>
        </div>
        <div>
          <p className="test-sm  px-2 mt-3">
            All assessments are currently scheduled. To reschedule, first delete an assessment and then add it to another date.
          </p>
        </div>
        <div className="modal__footer  justify-content-end flex-wrap">
          <button
            type="button"
            className="btn cancel-btn PacingCalender__DeleteModel__cancelBtn"
            data-bs-dismiss="modal"
            onClick={() => setModalShow(false)}
          >
            OK
          </button>
          <div>
            {/* <button
              type="button"
              className="btn mt-3 PacingCalender__DeleteModel__clearMonth"
              data-bs-dismiss="modal"
              onClick={onClickClear}
              disabled={loading}
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                'Clear Assessment'
              )}
              
            </button> */}
        
          {/* <button
            type="button"
            className="btn mt-3 PacingCalender__DeleteModel__clearMonth"
            onClick={handleSave}
          >
            {isSaving ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Clear All"
            )}
          </button> */}
          </div>
        </div>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default AddAssesmentWarningModal;
