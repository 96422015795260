const InfoSection = (level?: any) => {
  const performanceLevels = level?.level?.level || level?.level || { P: 70 };

  return (
    <div className="report__performance">
      <div className="data-text-print print-note">
        <p>
          Data for the OAG comes directly from formative assessments
          administered. Each formative aligns with a single OAS objective. Use
          this report to identify objectives that need remediation as students
          progress toward proficiency on summative assessments.
        </p>
      </div>

      <div className="report__performance-performance">
        <p className="report__performance-title">
          Performance Level (Percentage Score Range)
        </p>
        <div className="report__performance-percentage max-width">
          <div className="report__performance-row ">
            <div className="d-flex align-items-center">
              <div
                style={{ backgroundColor: "unset", border: "unset" }}
                className="mb-1 oag-prof-bg header-prof"
              >
                %
              </div>
              <p>Proficient</p>
            </div>
            <p>( {performanceLevels.FORMATIVE_PROFICIENCY}% - 100%)</p>
          </div>
          <div className="report__performance-row">
            <div className="d-flex align-items-center">
              <div className="report__performance-level oag-non-prof border-non-proficient"></div>
              <p>Non-Proficient</p>
            </div>
            <p>( 0% - {performanceLevels.FORMATIVE_PROFICIENCY - 1}%)</p>
          </div>
        </div>
        <div className="report__performance-indicator oag-score print-oag-score">
          <sup className="report__performance-indicatorText mb-1 me-1">
            Score on Objective{" "}
            <span className="border-top d-inline-block"></span>
          </sup>
          <div className="report__performance-rectangle prof-text border-proficient large-box">
            <span className="pt-2 oag-bg report__table-numbers oag-font-12">
              %
            </span>
            <span className="oag-bg oag-bg report__table-numbers small-text">
              #
            </span>
          </div>
          <sub className="report__performance-indicatorText mt-2 ms-1">
            <span className="border-top d-inline-block"></span>Multiple Attempt
            Count
          </sub>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
