import Card from "./card";
import { Fragment, useContext, useRef, useState } from "react";
import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment";
import GoalSkeleton from "./skeleton";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import GoalModal from "components/oldprofessionalDevelopment/development/goal/goalModal";
import GoalCompletionModal from "components/wayToAReport/goalCompletionModal";
import Pagination from "./pagination";
import { getSelectedGoal } from "services/professionalDevelopment";

const GOALS = [1, 2, 3];

const Goals = () => {
  const splideRef = useRef<any>(null);
  const {
    pdClassroom,
    goalsList,
    summatives,
    setGoalsList,
    fetchProfessionalDevelopmentClassroomTillDate,
    classroomId,
    setPdClassroom,
  } = useContext(ProfessionalDevelopmentContext);
  const { goals, loading } = pdClassroom;
  const isPagination = goals.length > 3;

  const seeAllRef = useRef<boolean>(false);

  const [goalDetailsLoading, setGoalDetailsLoading] = useState(false);
  const [showGoalCompletionModal, setShowGoalCompletionModal] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState<any>(null!);
  // const [currentPage, setCurrentPage] = useState(0);
  const [goalCompletitionLoading, setGoalCompletitionLoading] = useState(false);
  const [openGoalModal, setOpenGoalModal] = useState(false);
  
  const fetchSelectedGoal = async (goalId: any) => {
    setGoalDetailsLoading(true);
    const selectedGoal = await getSelectedGoal(goalId, classroomId);
    setGoalDetailsLoading(false);
    return selectedGoal;
  };

  const onClickGoalFromCompletitionModal = async (goalId: any) => {
    setShowGoalCompletionModal(false);
    setOpenGoalModal(true);
   // setSelectedGoal(goalId);

    const selectedGoal = await fetchSelectedGoal(goalId);
    if(selectedGoal){
      setSelectedGoal(selectedGoal);
      seeAllRef.current = true;
    }
    
  };

  const onCloseGoalModal = () => {
    setOpenGoalModal(false)
    setSelectedGoal(null!);
    if (seeAllRef.current) {
      setShowGoalCompletionModal(true);
      seeAllRef.current = false;
    }
  };

  const updateGoalTask = (taskId: number, goalId: number) => {
    if (selectedGoal) {
      setSelectedGoal((prevGoal: any) => ({
        ...prevGoal,
        completed_tasks_count:
          prevGoal.completed_tasks_count < prevGoal.tasks_count
            ? prevGoal.completed_tasks_count + 1
            : prevGoal.completed_tasks_count,
        tasks: prevGoal.tasks.map((item: any) => {
          return item.id === taskId
            ? {
                ...item,
                checked: true,
              }
            : item;
        }),
      }));
    }

    setPdClassroom({
      ...pdClassroom,
      goals: pdClassroom.goals.map((item: any) => {
        return item.id === goalId
          ? {
              ...item,
              tasks: item.tasks.map((taskItem: any) =>
                taskItem.id === taskId
                  ? { ...taskItem, checked: true }
                  : taskItem
              ),
              completed_tasks:
                item.completed_tasks < item.tasks.length
                  ? item.completed_tasks + 1
                  : item.completed_tasks,
              ...(item.completed_tasks + 1 === item.tasks.length && {
                marked: true,
              }),
            }
          : item;
      }),
    });

    if (goalsList) {
      setGoalsList({
        ...goalsList,
        week_goals_available: goalsList.week_goals_available.map(
          (item: any) => {
            return {
              ...item,
              goals: item.goals.map((goalItem: any) => {
                return goalItem.id === goalId
                  ? {
                      ...goalItem,
                      tasks: goalItem.tasks.map((taskItem: any) =>
                        taskItem.id === taskId
                          ? { ...taskItem, checked: true }
                          : taskItem
                      ),
                      completed_tasks:
                        goalItem.completed_tasks < goalItem.tasks
                          ? goalItem.completed_tasks + 1
                          : goalItem.completed_tasks,
                      ...(goalItem.completed_tasks + 1 === goalItem.tasks && {
                        marked: true,
                      }),
                    }
                  : goalItem;
              }),
            };
          }
        ),
        week_goals_upcoming: goalsList.week_goals_upcoming.map((item: any) => {
          return {
            ...item,
            goals: item.goals.map((goalItem: any) => {
              return goalItem.id === goalId
                ? {
                    ...goalItem,
                    tasks: goalItem.tasks.map((taskItem: any) =>
                      taskItem.id === taskId
                        ? { ...taskItem, checked: true }
                        : taskItem
                    ),
                    completed_tasks:
                      goalItem.completed_tasks < goalItem.tasks
                        ? goalItem.completed_tasks + 1
                        : goalItem.completed_tasks,
                    ...(goalItem.completed_tasks + 1 === goalItem.tasks && {
                      marked: true,
                    }),
                  }
                : goalItem;
            }),
          };
        }),
      });
    }
  };

  const onClickGoal = async (goalId: any) => {
    
    setOpenGoalModal(true);
    const selectedGoal = await fetchSelectedGoal(goalId);
    selectedGoal && setSelectedGoal({ ...selectedGoal, id: goalId });
  }

  const onClickSeeAll = async () => {
    
    setShowGoalCompletionModal(true);

    setGoalCompletitionLoading(true);
    await fetchProfessionalDevelopmentClassroomTillDate();
    setGoalCompletitionLoading(false);
  };

  let formattedGoals = goals;
  let i = goals.length;
  while (i < 3) {
    formattedGoals.push({ id: `skeleton-${i}`, isSkeleton: true });
    i++;
  }

  return (
    <>
      <div className="goals-sec">
        <div className="d-flex align-items-center gap-2">
          <h1 className="secHeading">Goals</h1>
          {goals.length > 0 && (
            <button tabIndex={0} type="button" className="see-all-btn btn" onClick={onClickSeeAll}>
              See All
            </button>
          )}
        </div>
        <div className="cardsContainer">
          {classroomId > 0 && pdClassroom && !pdClassroom.is_enabled && (
            <>
              <div className="permission">
              </div>
              <div className="d-flex justify-content-center align-items-center position-absolute top-0 left-0 w-100 h-100">
                <div className="permission-text classroom-permission">
                  <p>
                    Your school doesn’t have access to Professional Development.
                    Please contact your school administrator and let them know
                    you’re interested in these new features.
                  </p>
                </div>
              </div>
            </>
          )}

          {classroomId > 0 && !loading && goals.length > 0 ? (
            <Splide
              ref={splideRef}
              hasTrack={false}
              options={{
                classes: {
                  page      : 'splide__pagination__page goals-pagination-dot',
                },
                gap: "14px",
                perPage: 3,
                pagination: goals.length > 3,
                arrows: goals.length > 3,
                breakpoints: {
                  1200: {
                    perPage: 2,
                  },
                  767: {
                    perPage: 1,
                  },
                },
              }}
            >
              <SplideTrack>
                {formattedGoals.map((goal) => {
                  const { id, isSkeleton } = goal;
                  return (
                    <SplideSlide key={id}>
                      {isSkeleton ? (
                        <GoalSkeleton isLoading={false} />
                      ) : (
                        <Card
                          heading={goal.goal}
                          description={goal.description}
                          tasks={goal.tasks}
                          goalId={id}
                          onClickGoal={onClickGoal}
                          updateGoalTask={updateGoalTask}
                          shortDescription={goal?.short_description || ""}
                        />
                      )}
                    </SplideSlide>
                  );
                })}
              </SplideTrack>
              {isPagination && (
                <Pagination />
              )}
            </Splide>
          ) : (
            GOALS.map((id) => {
              return (
                <Fragment key={id}>
                  <GoalSkeleton isLoading={loading && classroomId > 0} />
                </Fragment>
              );
            })
          )}
        </div>
      </div>
      {openGoalModal && (
        <GoalModal
          selectedGoal={selectedGoal}
          closeModal={onCloseGoalModal}
          closeGoalcompletitionModal={() => setShowGoalCompletionModal(false)}
          loading={goalDetailsLoading}
          summatives={summatives}
          setSelectedGoal={(taskId: number) =>
            updateGoalTask(taskId, selectedGoal.id)
          }
        />
      )}
      {showGoalCompletionModal && (
        <GoalCompletionModal
          onClose={() => setShowGoalCompletionModal(false)}
          goalsList={goalsList}
          onClickGoal={onClickGoalFromCompletitionModal}
          loading={goalCompletitionLoading}
          updateGoalTask={updateGoalTask}
        />
      )}
    </>
  );
};

export default Goals;
