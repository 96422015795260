import axios from "util/axios";
import config from "config/config";


export const getWayToAReport = async (id: string) => {
  try {
    let { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/admin-reports/way-to-a/${id}`)
    return data

  } catch (error) {
    console.log("Error in [getSupportTickets] : ", error)
    return null
  }
}

export const getRemediatedList = async (id: string) => {
  try {
    let { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/admin-reports/way-to-a/${id}/students`)
    return data

  } catch (error: any) {
    console.log("Error in [getSupportTickets] : ", error);
    return null
  }
}

export const getGoalsList = async (id: string) => {
  try {
    const { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/admin-reports/way-to-a/${id}/goals`);
    // let data = await axios.get('/week_days.json');
    // data = data.data;
    return data;

  } catch (error: any) {
    console.log("Error in [getGoalsList] : ", error);
    return null
  }
}