import DownArrow from "components/common/svgs/down-arrow";
import SearchIcon from "components/common/svgs/search-icon";
import { FormativesFilters } from "components/oldprofessionalDevelopment/assessments/formative";

const Filters = ({
    updateSearch,
    onChangeFilter,
    filter
}: any) => {
    return (
        <div className="filter-right">
            <div className="search-assment">
                <span>
                    <SearchIcon title="Search Assesments"/>
                </span>
                <input 
                    type="search" 
                    name="search" 
                    placeholder="Search Assessments"
                    onChange={(e) => updateSearch(e.target.value)} 
                />
            </div>
            <div className="schedule-dropdown">
                <p className="dropdown-label">Order By</p>
                <div className="select-box">
                    <select 
                        onChange={(e: any) => onChangeFilter(e.target.value)}
                        value={filter}
                        onKeyDown={ (e:any) => { 
                            if(e.key === 'Enter') {
                                onChangeFilter(e.target.value)
                            }
                        } } 
                    >
                        <option value={FormativesFilters.Scheduled}>Scheduled</option>
                        <option value={FormativesFilters.Upcoming}>Upcoming</option>
                        <option value={FormativesFilters.Objective}>Objective</option>
                    </select>
                   <DownArrow />
                </div>
            </div>
        </div>
    )
};

export default Filters;
