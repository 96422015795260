export const useGetChatTime = (date_created: string, closed_at: string) => {
    if(closed_at && date_created){
        const msDifference = closed_at && date_created ? new Date(closed_at).getTime() - new Date(date_created).getTime() : -1;
        let seconds: any = Math.floor(msDifference / 1000);
        let minutes: any = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        
        seconds = seconds % 60;
        seconds = seconds < 10 ? `0${seconds}` : seconds;
        minutes = minutes % 60;
        minutes = minutes < 10 ? `0${minutes}` : minutes;

        const chatTime = hours >= 1 ? `${hours}:${minutes}` : `${minutes}:${seconds}`;
        return chatTime;
    }else{
        return '00:00'
    }
    
}