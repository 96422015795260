import React from "react"
import AssessmentStats from "./assessmentStats";
import Skeleton from "react-loading-skeleton";

const LoadingSkeleton = () => {
    return (
        <div
            onClick={() => {}}
            className="select-class-row cursor-pointer"
        >
            <div className="classRoom">
                <p className="subHeader-fieldLabel">Classroom</p>
                <p className="class_name">
                    <Skeleton baseColor="#00000033" highlightColor="#737373" />    
                </p>
            </div>
            <div className="hour-column">
                <p className="subHeader-fieldLabel">Hour</p>
                <div className="chip">
                    <Skeleton baseColor="#00000033" highlightColor="#737373" />  
                </div>
            </div>
            <div className="last-assessment">
                <div className="subHeader-fieldLabel">
                    Last Assessment
                </div>
                <p className="lastAssessment-date">
                    <Skeleton baseColor="#00000033" highlightColor="#737373" />  
                </p>
            </div>
            <AssessmentStats loading isThreeDropdowns={false} />
        </div>
    )
};

export default LoadingSkeleton;
