import { GoalsList } from "./wayToAReport";

export enum Assessment {
  summative = "summative",
  formative = "formative",
}
export interface ProfessionalDevelopment {
  reEnableAssessments: Function;
  assessmentMonitorLoading: boolean;
  formativeRemediateList: RemediatedList;
  fetchFormativeRemediateData: Function;
  setSTLType: Function;
  STLType: string;
  prevSTLexists: boolean;
  prevSTLClassroomExists: boolean;
  setGoalsList: Function;
  setPdClassroom: Function;
  fetchProfessionalDevelopmentClassroomTillDate: Function;
  fetchAssessmentMonitorData: Function;
  fetchSummatives: Function;
  setSummatives: Function;
  fetchFormatives: Function;
  setFormatives: Function;
  markAssessmentCompleted: Function;
  formatives: Array<SingleFormative>;
  summatives: Array<SingleSummative>;
  activeHeaderTab: string;
  classroomId: number;
  schoolId: number;
  navigateToScreen: Function;
  pdClassroom: PDClassroom;
  showReportModal: string;
  classrooms: Array<any>;
  showReviewHelpModel: boolean;
  isLoading: boolean;
  updateActiveHeaderTab: Function;
  setShowReportAlertModal: Function;
  navigateTo: Function;
  setShowReviewHelpModel: Function;
  setAssessmentMonitor: Function;
  setIsLoading: Function;
  goalsList: GoalsList;
  advanceWeek: Function;
  selectedGoal: SelectedGoal;
  assessmentMonitor: {
    open_assessments: number;
    online_students: number;
    assessments: any;
  };
}

export interface RemediatedList {
  objective_id: number;
  objective: string;
  status_key: string;
  students: any[];
}

export interface Goal {
  id: number;
  goal: string;
  marked: boolean;
}

export interface Task {
  checked: boolean;
  group: string;
  is_enabled: boolean;
  task: string;
}

export interface SelectedGoal {
  goal: string;
  complete_by: string;
  instructional_text: string;
  tasks: Task[];
  is_multi_step: boolean;
  is_recurring: boolean;
  tasks_count: number;
  completed_tasks_count: number;
}

export interface PDClassroom {
  goals: any[];
  is_enabled: boolean;
  tools: any[];
  week_text: string;
  loading: boolean;
  tooltips_seen: boolean;
  week_date: string;
  has_books_access: boolean;
  show_no_goal_tooltip: boolean;
  show_no_schedule_tooltip: boolean;
  book_access: string;
}

export enum ReportsNavigation {
  OAG = "OAG",
  IRR = "IRR",
  SBR = "SBR",
  MONITOR = "MONITOR",
  PREVIEW = "PREVIEW",
  ANSWER = "ANSWER",
  MASTER = "MASTER",
}

export interface SingleFormative {
  alignment_label: string;
  allowed_monitoring: boolean;
  available_students: number;
  description: string;
  enabled: boolean;
  fk_district_id: number;
  fk_objective_id: number;
  id: number;
  is_completed: boolean;
  last_activity: string;
  name: string;
  proficiency: number;
  scheduled_color: string;
  scheduled_date: string;
  show_random_option: boolean;
  students_assessed: string;
  students_assessed_count: number;
  students_total_count: number;
  remediation_count: number;
  moved?: boolean;
  power_standard: boolean;
}

export interface SingleSummative {
  allowed_monitoring: boolean;
  assessment_time: string;
  available_students: number;
  can_be_edited: boolean;
  can_be_enabled: boolean;
  description: string;
  enabled: boolean;
  fk_district_id: number;
  fk_grade_id: number;
  fk_subject_id: number;
  id: number;
  is_completed: boolean;
  last_activity: string;
  name: string;
  proficiency: number;
  scheduled_color: string;
  scheduled_date: string;
  show_random_option: boolean;
  students_assessed: string;
  students_assessed_count: number;
  students_total_count: number;
  is_past_window: boolean;
}

export enum HeaderTabs {
  THIS_WEEK = "This Week",
  Resources = "Resources",
  Pacing_Calender = "Pacing Calendar",
  Discussion = "Discussion",
  Report_Cards = "Report Cards",
}
