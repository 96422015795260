import React from 'react'

const CalenderIcon = ({fill, stroke}:{fill:string, stroke: string}) => {
  const titleId= "calendarIcon"
  return (
    <svg aria-labelledby={titleId} aria-label="calender" xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none">
    <title id={titleId}>Schedule</title>
    <g clip-path="url(#clip0_3313_1261)">
    <path d="M0.5 4.25H13.47V13.65H0.5V4.25Z" stroke={stroke}/>
    <path d="M0.5 2.15039H13.47V2.37039H0.5V2.15039Z" stroke={stroke}/>
    <path d="M3.15906 6.38086H4.43906C4.50906 6.38086 4.55906 6.44086 4.55906 6.52086V7.91086C4.55906 7.99086 4.49906 8.05086 4.43906 8.05086H3.15906C3.08906 8.05086 3.03906 7.99086 3.03906 7.91086V6.52086C3.03906 6.44086 3.09906 6.38086 3.15906 6.38086Z" stroke={stroke} stroke-width="0.75"/>
    <path d="M3.15906 9.84961H4.43906C4.50906 9.84961 4.55906 9.90961 4.55906 9.98961V11.3796C4.55906 11.4596 4.49906 11.5196 4.43906 11.5196H3.15906C3.08906 11.5196 3.03906 11.4596 3.03906 11.3796V9.98961C3.03906 9.90961 3.09906 9.84961 3.15906 9.84961Z" stroke={stroke} stroke-width="0.75"/>
    <path d="M6.35047 9.84961H7.63047C7.70047 9.84961 7.75047 9.90961 7.75047 9.98961V11.3796C7.75047 11.4596 7.69047 11.5196 7.63047 11.5196H6.35047C6.28047 11.5196 6.23047 11.4596 6.23047 11.3796V9.98961C6.23047 9.90961 6.29047 9.84961 6.35047 9.84961Z" stroke={stroke} stroke-width="0.75"/>
    <path d="M9.53016 9.84961H10.8102C10.8802 9.84961 10.9302 9.90961 10.9302 9.98961V11.3796C10.9302 11.4596 10.8702 11.5196 10.8102 11.5196H9.53016C9.46016 11.5196 9.41016 11.4596 9.41016 11.3796V9.98961C9.41016 9.90961 9.47016 9.84961 9.53016 9.84961Z" stroke={stroke} stroke-width="0.75"/>
    <path d="M6.35047 6.38086H7.63047C7.70047 6.38086 7.75047 6.44086 7.75047 6.52086V7.91086C7.75047 7.99086 7.69047 8.05086 7.63047 8.05086H6.35047C6.28047 8.05086 6.23047 7.99086 6.23047 7.91086V6.52086C6.23047 6.44086 6.29047 6.38086 6.35047 6.38086Z" stroke={stroke} stroke-width="0.75"/>
    <path d="M9.53016 6.38086H10.8102C10.8802 6.38086 10.9302 6.44086 10.9302 6.52086V7.91086C10.9302 7.99086 10.8702 8.05086 10.8102 8.05086H9.53016C9.46016 8.05086 9.41016 7.99086 9.41016 7.91086V6.52086C9.41016 6.44086 9.47016 6.38086 9.53016 6.38086Z" stroke={stroke} stroke-width="0.75"/>
    <path d="M8.69922 0.5V1.74" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M5.42969 0.5V1.74" stroke={stroke} stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_3313_1261">
        <rect width="13.97" height="14.15" fill={fill}/>
    </clipPath>
    </defs>
</svg>
  )
}

export default CalenderIcon
