import HtmlParser from "components/common/htmlParser";
import { FC,  useEffect,  useRef,  useState } from "react";
import GoalModal from "./goalModal";
import GoalCompletionModal from "components/wayToAReport/goalCompletedModalOld";
import { getSelectedGoal } from "services/professionalDevelopment";
// import { WayToAReportContext } from "contexts/wayToAReport";
import Checkbox from "../../../../assets/img/checkbox.svg";
import socketKeys from 'util/types/socket-keys'
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";
import MultiStepGoalIcon from "components/common/multiStepGoalIcon";

const {
  SOCKET_CLASSROOM_SUBSCRIBE_EVENT
} = socketKeys;

interface Props {
    goals: Array<any>;
    goalsList:any;
    loading: boolean;
    fetchProfessionalDevelopmentClassroomTillDate: Function;
    summatives: any[];
    setPdClassroom: Function;
    setGoalsList: Function;
}

const Goal: FC<Props> = (props) => {
    const { setGoalsList, goals, loading, goalsList, fetchProfessionalDevelopmentClassroomTillDate, summatives, setPdClassroom } = props;
    const [selectedGoal, setSelectedGoal] = useState<any>(null!);
    const [showGoalCompletionModal, setShowGoalCompletionModal] = useState(false);
    const [goalCompletitionLoading, setGoalCompletitionLoading] = useState(false);
    const [goalDetailsLoading, setGoalDetailsLoading] = useState(false);
    // const {wayToAReport} = useContext(WayToAReportContext);
    const seeAllRef = useRef<boolean>(false);
    const app = useSelector((state: RootState) => state);

    const isShowModal = !!selectedGoal;
    const classroomId = Number(sessionStorage.getItem("c_classroom_id"));

    const onClickGoal = async (goal: any) => {
        setSelectedGoal(goal.id)
        setGoalDetailsLoading(true);
        const selectedGoal = await getSelectedGoal(goal.id, classroomId);
        setGoalDetailsLoading(false);
        selectedGoal && setSelectedGoal({...selectedGoal, id: goal.id})
    }
    
    const onCloseGoalModal = () => {
        setSelectedGoal(null!);
        if(seeAllRef.current){
            setShowGoalCompletionModal(true);
            seeAllRef.current = false;
        }
    }
    
    const onClickGoalFromCompletitionModal = async (goal: any) => {
        setSelectedGoal(goal.id)
        setGoalDetailsLoading(true);
        const selectedGoal = await getSelectedGoal(goal.id, classroomId);
        setGoalDetailsLoading(false);
        selectedGoal && setSelectedGoal({...selectedGoal, id: goal.id})

        setShowGoalCompletionModal(false);
        seeAllRef.current = true;
    }

    const onClickSeeAll = async () => {
        setShowGoalCompletionModal(true);

        setGoalCompletitionLoading(true);
        await fetchProfessionalDevelopmentClassroomTillDate();
        setGoalCompletitionLoading(false);
    }

    useEffect(() => {
        if(!app.auth?.socketData || app.auth?.socketData?.type !== SOCKET_CLASSROOM_SUBSCRIBE_EVENT){
            return;
        }

        const {task_id, goal_id, goal_updated} = app.auth?.socketData;
        if(goal_updated){
            if(task_id){
                updateGoalTask(task_id, goal_id);
            }
    
            if(goal_id){
                setGoalsList(goal_id);
                setPdClassroom(goal_id);
            }
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[app.auth.socketData]);

    const updateGoalTask = (taskId: number, goalId: number) => {
        if(selectedGoal){
            setSelectedGoal((prevGoal:any) => ({
                ...prevGoal,
                completed_tasks_count: prevGoal.completed_tasks_count < prevGoal.tasks_count ? prevGoal.completed_tasks_count + 1 : prevGoal.completed_tasks_count,
                tasks: prevGoal.tasks.map((item: any) => {
                    return item.id === taskId
                    ? {
                        ...item,
                        checked: true
                    }
                    :item
                })
            }));
        }

        setPdClassroom(goalId);
        if(seeAllRef.current){
            setGoalsList(goalId);
        }   
    }

    return (
        <>
            <div className="goalSec">
                <p className="goalHeader d-flex align-items-center" style={{justifyContent: 'space-between'}}>
                    <span>
                        Goals
                    </span>
                    <span onClick={onClickSeeAll} style={{cursor: 'pointer'}} >
                        See All
                    </span>
                </p>
                {loading
                    ?   <div className="no-data-goals loader-skeleton">
                            <div className="no-data-sekelton">
                                <span></span>
                            </div>
                            <div className="no-data-sekelton">
                                <span></span>
                            </div>
                            <div className="no-data-sekelton">
                                <span></span>
                            </div>
                            <div className="no-data-sekelton">
                                <span></span>
                            </div>
                        </div>
                    :   <div className="goalBody">
                            {!!goals.length ? goals.map(goal => {
                                return (
                                    <label key={goal.id} onClick={() => onClickGoal(goal)} className="d-flex goal-check-box">
                                        {goal.is_multi_step && !(goal.tasks === goal.completed_tasks) ? (
                                            <div style={{marginRight: 8, paddingLeft: 10}} className="loader">
                                                <MultiStepGoalIcon 
                                                    completed_tasks={goal?.completed_tasks}
                                                    tasks={goal?.tasks}
                                                />
                                            </div>
                                        ) : (
                                            <label className={`d-flex justify-content-center goals-modal-checkbox ${'cursor-pointer'}`}>
                                                <input onChange={() => {}} type="checkbox" id="pacing-calendar" value="" checked={goal.tasks === goal.completed_tasks} />
                                                <span className="checkbox"></span>
                                            </label>
                                        )}

                                        <HtmlParser html={goal?.goal || ''} />
                                    </label>
                                )
                            }) :
                                <div className="no-data-goals">
                                    <div className="d-flex align-items-center no-goals-data">
                                        <div className="checked-img">
                                            <img src={Checkbox} alt="Checked" />
                                        </div>
                                        <p>No goals this week. You’re all caught up.</p>
                                    </div>
                                </div>
                            }
                        </div>
                }
            </div>
            {isShowModal && (
                <GoalModal 
                    selectedGoal={selectedGoal} 
                    closeModal={onCloseGoalModal}
                    closeGoalcompletitionModal={() => setShowGoalCompletionModal(false)}
                    loading={goalDetailsLoading}
                    summatives={summatives}
                    setSelectedGoal={(taskId: number) => updateGoalTask(taskId, selectedGoal.id)}
                />
            )}

            {showGoalCompletionModal && (
                <GoalCompletionModal 
                    onClose={() => setShowGoalCompletionModal(false)} 
                    goalsList = {goalsList}
                    onClickGoal={onClickGoalFromCompletitionModal}
                    loading={goalCompletitionLoading}
                />
            )}
            
        </>
    )
}
export default Goal