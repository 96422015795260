import { ProfessionalDevelopmentContext } from "contexts/professionalDevelopment"
import { HeaderTabs } from "interfaces/professionalDevelopment"
import { useContext } from "react"
import Goal from "./goal/goal"
import Resources from "./resources"
import Tool from "./tool"
import Week from "./week"

const PD_DISABLE_WEEK_TEXT = '<p>Our new Professional Development tools:</p><ul><li>OAS-aligned Interactive Pacing Calendar for 100 Days to Mastery.</li><li>Series of short how-to-videos.</li><li>Series of short how-to-videos on using the platform to improve instruction.</li><li>Goals that guide you activities for each week to adhere to the Alpha Plus Model of School Improvement.</li><li>Support Chat.</li><li>And more.</li></ul>'

const Development = () => {
    const { activeHeaderTab, pdClassroom, classroomId, goalsList, setPdClassroom, fetchProfessionalDevelopmentClassroomTillDate, summatives, setGoalsList } = useContext(ProfessionalDevelopmentContext);
    const { is_enabled, week_text, goals, tools, loading, book_access } = pdClassroom;

    const thisWeekText = is_enabled ? week_text : PD_DISABLE_WEEK_TEXT;

    const updatePdClassroom = (goalId: number) => {
        setPdClassroom({
            ...pdClassroom,
            goals: pdClassroom.goals.map((item: any) => {
                return item.id === goalId
                ?{
                    ...item,
                    completed_tasks: item.completed_tasks < item.tasks ? item.completed_tasks + 1 : item.completed_tasks,
                    ...(item.completed_tasks + 1 === item.tasks && {marked: true})
                }
                : item
            })
        })
    }

    const updateGoalsList = (goalId: number) => {
        if(goalsList){
            setGoalsList({
                ...goalsList,
                recurring_week_goals: goalsList.recurring_week_goals.map((item: any) => {
                    return {
                        ...item,
                        goals: item.goals.map((goalItem: any) => {
                            return goalItem.id === goalId
                            ? {
                                ...goalItem,
                                completed_tasks: goalItem.completed_tasks < goalItem.tasks ? goalItem.completed_tasks + 1 : goalItem.completed_tasks,
                                ...(goalItem.completed_tasks + 1 === goalItem.tasks && {marked: true})
                            }
                            :goalItem
                        })
                    }
                }),
                week_goals: goalsList.week_goals.map((item: any) => {
                    return {
                        ...item,
                        goals: item.goals.map((goalItem: any) => {
                            return goalItem.id === goalId
                            ? {
                                ...goalItem,
                                completed_tasks: goalItem.completed_tasks < goalItem.tasks ? goalItem.completed_tasks + 1 : goalItem.completed_tasks,
                                ...(goalItem.completed_tasks + 1 === goalItem.tasks && {marked: true})
                            }
                            :goalItem
                        })
                    }
                })
            });
        }
    }

    return (
        <div className="ProfessionalDev__devSummary__container">
            <div className="ProfessionalDev__devSummary__container__body d-flex align-items-center justify-content-between flex-lg-nowrap flex-wrap">
                {
                activeHeaderTab === HeaderTabs.THIS_WEEK &&
                    (
                        classroomId > 0 ? (
                            <>
                                {!pdClassroom.loading && !is_enabled && classroomId > 0 && (
                                    <div className="permission">
                                        <div className="permission-text classroom-permission">
                                            <p>Your school doesn’t have access to Professional Development. Please contact your school administrator and let them know you’re interested in these new features.</p>
                                        </div>
                                    </div>
                                )}
                                <Week pdClassroom={pdClassroom} loading={loading} week_text={thisWeekText} />
                                <Goal setGoalsList={updateGoalsList} setPdClassroom={updatePdClassroom} summatives={summatives} loading={loading} goals={goals} goalsList = {goalsList} fetchProfessionalDevelopmentClassroomTillDate={fetchProfessionalDevelopmentClassroomTillDate} />
                                <Tool loading={loading} tools={tools} />
                            </>
                        ) : (
                            <div className="classroom__tabs-empty mx-auto">
                                <p>
                                    {classroomId > 0 ? "No records found" : "Select a classroom above to view Professional Development."}
                                </p>
                            </div>
                        )
                    )
                }
                {
                    activeHeaderTab === HeaderTabs.Resources && <Resources book_access={book_access} />
                }

                {
                activeHeaderTab?.length === 0 &&
                    (
                        classroomId === 0 && (
                            <div className="classroom__tabs-empty mx-auto">
                                <p>
                                Select a classroom above to view Professional Development.
                                </p>
                            </div>
                        )
                    )
                }
            </div>     
        </div>
    )

}
export default Development