import React from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import ActionType from "redux/actionTypes";
import CustomModal from "components/common/Modal";
interface SuccessModalProps {
  districtName: string;
  setImportStatus: ()=>void;
  setIsSuccess: ()=>void;
  status: string;
  isShow: boolean;
  id: number;
  // name: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  districtName,
  setImportStatus,
  setIsSuccess,
  status,
  isShow,
  id,
  // name,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closeModal = () => {
    setImportStatus()
    dispatch({
      type: ActionType.CLOSE_MODAL,
    });
    navigate(`/management/district/${id?id:localStorage.getItem('import_district_id')}`,{state: {name: districtName?districtName:localStorage.getItem('import_district_name')}});
    localStorage.removeItem('import_district_name')
    localStorage.removeItem('import_district_id')
  };

  return (
    <CustomModal
      centered
      show={isShow}
      onHide={closeModal}
      onEscapeKeyDown={closeModal}
    >
      <div className="modal__header">
        <h6 className="modal__header-title">
        {
            status==='error'?
            `CSV Import Error`:
            `Success:`
          }
          </h6>
      </div>
      <div className="modal__body">
        <p>
          {
            status==='error'?
            `An error has occurred while processing the ${districtName?districtName:localStorage.getItem('import_district_name')} CSV. Import has been cancelled and no changes have been saved.`:
            `${districtName?districtName:localStorage.getItem('import_district_name')} CSV Import Success`
          }
          {/* Your Student and Classes CSV was successfully imported. Click on the
          individual schools on the District Management screen to view the newly
          imported classes and students. */}
        </p>
      </div>
      <div className="modal__footer">
        <button className="btn cancel-btn mx-2" onClick={()=>{setIsSuccess();setImportStatus(); dispatch({
      type: ActionType.CLOSE_MODAL,
    });}}>
          Close
        </button>
       { status==='success'?
        <button className="btn success-btn mx-2" onClick={closeModal}>
          View District
        </button>:null}
      </div>
    </CustomModal>
  );
};

export default SuccessModal;
