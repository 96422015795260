import { quickMessages } from "./AdminChatBox";

const QuickResponseContent = ({ onClickMessage }: any) => {

  return (

        <div className="chat_items_list">
            <div className="items-list">
            
                <div>
                    <div className="items" onClick={() => onClickMessage(quickMessages[1])}>
                        <div className="item-bullet">1</div>
                        <p className="content">
                            {quickMessages[1]}
                        </p>
                    </div>
                    <div className="items" onClick={() => onClickMessage(quickMessages[2])}>
                        <div className="item-bullet">2</div>
                        <p className="content">
                        {quickMessages[2]}
                        </p>
                    </div>
                </div>
                <div>
                    <div className="items" onClick={() => onClickMessage(quickMessages[3])}>
                        <div className="item-bullet">3</div>
                        <p className="content">
                            {quickMessages[3]}
                        </p>
                    </div>
                    <div className="items" onClick={() => onClickMessage(quickMessages[4])}>
                        <div className="item-bullet">4</div>
                        <p className="content">
                            {quickMessages[4]}
                        </p>
                    </div>
                    <div className="items" onClick={() => onClickMessage(quickMessages[5])}>
                        {/* <div className="item-bullet">5</div>
                        <p className="content">
                            {quickMessages[5]}
                        </p> */}
                    </div>
                </div>

            </div>
            <button type="button" className="close_btn">
                Type # or <span style={{ fontWeight: "700" }}>Close</span> (Esc)
            </button>
        </div>

  );
};

export default QuickResponseContent;