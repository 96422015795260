import React from "react";
import { Modal } from "react-bootstrap";
import QuestionMarkIcon from "assets/img/question-mark-tooltip-icon.svg";

interface Props {
    isShow: boolean;
    closeModal: () => void;
}

const FormativeAssessedModal: React.FC<Props> = ({
    isShow,
    closeModal,
}) => {
    return (
        <Modal centered show={isShow} onHide={closeModal} onEscapeKeyDown={closeModal}>
            <div className="modal__header">
                <img src={QuestionMarkIcon} className="modal__header-icon" alt="" />
                <h6 className="modal__header-title">{'Running Proficiency'}</h6>
            </div>
            <div className="modal__body">
                <p className="modal__body-text">
                    Running Proficiency calculates the average proficiency of all previous objectives, including the proficiency of the current objective in that column.
                </p>

            </div>
            <div className="modal__footer">
                <button
                    className="btn success-btn"
                    onClick={closeModal}
                >
                    Got it
                </button>
            </div>
        </Modal>
    );
};

export default FormativeAssessedModal;