import HtmlParser from "components/common/htmlParser";
import { Doughnut } from "react-chartjs-2";
import warningIcon from 'assets/img/warning-icon.svg';

const AnalysisBody = ({ChartData, text, percentage, isImage}: any) => {
  return (
    <div className='anylasisBody'>
        <div className='anylasisBody__goals'>
            <Doughnut 
              data={ChartData.data} 
              options={ChartData.options as any} 
              plugins={[
                {
                  id: "rep-analysis",
                  beforeDraw: function(chart) {
                    var width = chart.width,
                    height = chart.height,
                    ctx = chart.ctx;
                    ctx.restore();
                    if(isImage){
                      const image = new Image()
                      image.src = warningIcon
                      const imageSize = 35
                      ctx.drawImage(
                        image,
                        chart.width / 2 - imageSize / 2,
                        chart.height / 2 - imageSize / 2,
                        imageSize,
                        imageSize,
                      )
                    }else{
                      var fontSize = 22;
                      ctx.font = "700 " + fontSize + "px Nunito";
                      ctx.fillStyle = '#424242'
                      ctx.textBaseline = "top";
                      var text = `${percentage}%`,
                      textX = Math.round((width - ctx.measureText(text).width) / 2),
                      textY = height / 2.2;
                      ctx.fillText(text, textX, textY);
                    }
                    ctx.save();
                  },
                }
              ]}
            />
        </div>
        <div className='anylasisBody__Info'>
            <p>
                <HtmlParser html={text || ''} />
            </p>
        </div>
    </div>
  )
};

export default AnalysisBody;
