/* eslint-disable react-hooks/exhaustive-deps */

import PrintFooter from "components/common/report/Print/footer";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";

interface Props {
  report: any
}

const RemediateModal: React.FC<Props> = ({report}) => {

  const {students, objective, status_key, non_proficient, class_name} = report;
  // const isDataExists = students.length > 0;
  const activity = useSelector((state: RootState) => state.activity);
  const schoolId = Number(sessionStorage.getItem("c_school_id"));
  const school = activity?.schoolsList.find((item: any) => item.id === schoolId);

  return (
    <>
      <div className="remediation-report">
        
        <div className="remediate-header">
          <div className="remediate-header-title">
            <h1 className="remediate-title">{`${objective} Remediation Report`}</h1>
            <h3 className="remediate-desc">{`Students Below Were Non-Proficient and Have Yet to Complete New Attempts`}</h3>
            <p className="remediate-date">{moment().format('M-D-YY')}</p>
          </div>
          <div className="remediate-status-date">
            <div className="remediate-status">
              <span className="remediate-key-label">Key</span>
              <div className="remediate-yellow"></div>
              <p className="remediate-progress">{status_key}</p>
              <div className="remediate-date-label">Date</div>
            </div>

            <div className="remediate-date-name">
              <p className="remediate-class">{class_name || ''}</p>
              <p className="remediate-stu-name">{school?.school_name || ''}</p>
            </div>
          </div>
        </div>

        {non_proficient !== null && <div className="proficient-perc-label">{non_proficient}% of Class is Non-proficient</div>}
        
        <table className="remediate-table">
          <thead>
            <th className="remediate-fst-column remediate-th">
              Student
            </th>
            <th className="remediate-scnd-column remediate-th">
              Attempt 1 <br />Score
            </th>
            <th className="remediate-scnd-column remediate-th">
              Attempt 2 <br />Score
            </th>
            <th className="remediate-scnd-column remediate-th">
              Attempt 3 <br />Score
            </th>
            <th className="remediate-thrd-column remediate-th">       
              Proficient
            </th>
          </thead>
          <tbody>
            {students?.map((item:any) => {
              const {last_name, proficient, first_name, score, status_attempt_2, status_attempt_3, assessment_date, attempt_2_score, attempt_3_score, attempt_2_date, attempt_3_date} = item;
              return (
                <tr>
                  <td className={`remediate-fst-column remediate-td ${proficient ? 'remediate-td-disabled' : ''}`}>{last_name}, {first_name}</td>
                  <td className={`remediate-scnd-column remediate-td ${proficient ? 'remediate-td-disabled' : ''}`}>
                    <div className="remediate-attempt-score">
                    <span className="remediate-perc">{score}%</span>
                    {assessment_date && <span className="remediate-score">{moment(assessment_date).format("M/D")}</span>}
                    </div>
                  </td>
                  <td className={`remediate-scnd-column remediate-td ${proficient ? 'remediate-td-disabled' : ''}`}>
                    <div className="remediate-attempt-score">
                      {status_attempt_2 === 'In Progress' ? <div className="remediate-yellow"></div> : attempt_2_score!==null ? <span className="remediate-perc">{attempt_2_score}%</span> : ''}
                      {!!attempt_2_date && (attempt_2_score!==null || status_attempt_2 === 'In Progress') && <span className="remediate-score">{moment(attempt_2_date).format("M/D")}</span>}
                    </div>
                  </td>
                  <td className={`remediate-scnd-column remediate-td ${proficient ? 'remediate-td-disabled' : ''}`}>
                    <div className="remediate-attempt-score">
                      {status_attempt_3 === 'In Progress' ? <div className="remediate-yellow"></div> : attempt_3_score !== null ? <span className="remediate-perc">{attempt_3_score}%</span> : ''}
                      {!!attempt_3_date && (attempt_3_score!==null || status_attempt_3 === 'In Progress') && <span className="remediate-score">{moment(attempt_3_date).format("M/D")}</span>}
                    </div>
                  </td>
                  <td className={`remediate-thrd-column remediate-td text-center ${proficient ? 'remediate-td-disabled' : ''}`}>
                    {proficient ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15" fill="none">
                      <g opacity="0.4" clip-path="url(#clip0_2680_4295)">
                      <path d="M20 2.59382L17.4272 0L7.69228 9.81454L2.57058 4.65312L-0.00219727 7.24693L7.6901 15.0022L20 2.59382Z" fill="#46AFB8"/>
                      </g>
                      <defs>
                      <clipPath id="clip0_2680_4295">
                      <rect width="20" height="15" fill="white"/>
                      </clipPath>
                      </defs>
                    </svg> : ''}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div style={{position: 'fixed', bottom: '0px', left: '0', width: '100%'}}>
        <PrintFooter />
      </div>
    </>
  );
};

export default RemediateModal;
