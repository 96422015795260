/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  DistrictDropDown,
  SchoolDropDown,
  ClassroomDropDown,
} from "./headerDropdowns";
import ActionType from "redux/actionTypes";
import ButtonDropDown from "components/common/buttonDropDown";
import { useLocation } from "react-router-dom";

interface RootState {
  auth: any;
  activity: any;
  classroom: any;
  management: any;
}

const MngDropdowns = ({
  onDistrictChange,
  onSchoolChange,
  userRole,
  classrooms,
  onChangeClassroom,
  onClickClassroomDropdown,
  setClassroomOpen,
  isOtherScreen,
}: any) => {
  const app = useSelector((state: RootState) => state);
  const districts = app.activity.districtsList;
  const districtSchools = app.activity.schoolsList;
  const classroom_m_id = app.management?.classroom_m_id || classrooms[0]?.id;
  const school_m_id = app.management?.school_m_id || districtSchools[0]?.id;
  const district_m_id = app.management?.district_m_id || districts[0]?.id;
  const { pathname }: any = useLocation();
  const discussionPage = pathname.indexOf("discussions") === -1;
  const dispatch = useDispatch();

  const requestStlSchool = () => {
    dispatch({
      type: ActionType.SET_STL_SCHOOL_LOADING,
      payload: true,
    });
  };

  // const requestStlClass = () => {
  //   dispatch({
  //     type: ActionType.SET_STL_CLASS_LOADING,
  //     payload: true,
  //   });
  // };

  const requestStl = (period: string) => {
    dispatch({type: ActionType.SET_SCHOOL_STL_PERIOD, payload: period})
    dispatch({
      type: ActionType.SET_STL_SCHOOL_LOADING,
      payload: true
    });
  };

  const formatOptionLabel = (data: any) => {
    return (
      <div className="classRoom-selectOption">
        <div className="selectOption-text">{data.label}</div>
        {data?.id !== 0 && data?.hour && (
          <div className="dropDown-chip">{data.hour}</div>
        )}
      </div>
    );
  };


  // const classInfo = app.classroom.classrooms?.find((item:any) => item.id  === Number(classroom_m_id))
  // const isOnlyResourcesClass = classInfo?.students_count === 0 || classInfo?.fk_grade_id === 9

  return (
    <>
      <div className="header__dropdown-wrapper">
        {districts.length > 1 && userRole === "District Admin" && (
          <li className="header__dropdown-item header__dropdown-itemSchool">
            <span>
              <small>Districts</small>
              <DistrictDropDown
                isDisabled={districts.length ? false : true}
                onDistrictChange={onDistrictChange}
                districts={districts || []}
                selectedDistrict={
                  district_m_id > 0
                    ? districts.find((item: any) => item.value === district_m_id)
                    : null
                }
              />
            </span>
          </li>
        )}
      
        {districtSchools.length > 1 && userRole === "School Admin" && (
            <li className="header__dropdown-item header__dropdown-itemSchool management-dropdown position-relative">
              <span>
                <small>School</small>
                <SchoolDropDown
                  isDisabled={
                    districtSchools.length && district_m_id > 0 ? false : true
                  }
                  onSchoolChange={onSchoolChange}
                  selectedSchool={
                    school_m_id > 0
                      ? districtSchools.find((item: any) => item.value === school_m_id)
                      : null
                  }
                  districtSchools={districtSchools}
                />
              </span>
            </li>
        )}
        {userRole === "School Admin" && (
          <>
            {app?.management?.schoolStlExists ? (
              <ButtonDropDown
                options={[
                  {
                    label: "Summative Test Log",
                    value: "Current",
                  },
                  {
                    label: "2023-24",
                    value: "2023-24",
                  },
                ]}
                onChange={(selectedValue: any) => {
                  requestStl(selectedValue);
                }}
                biggerWidth={true}
              />
            ):(
              <button
                className="btn outline-btn outline-btn-lg btn-test-log"
                onClick={requestStlSchool}
              >
                Summative Test Log
              </button>
            )}
          </>
        )}
      
        {userRole === "Classroom Admin" && classrooms.length > 1 && (
          <li className="header__dropdown-item header__dropdown-itemSchool management-dropdown">
          <span>
            <small>Classroom</small>
            <div
                className={
                  classroom_m_id === 0 &&
                  school_m_id > 0 &&
                  (districtSchools.length === 1 || classrooms.length > 1)
                    ? `header__dropdown-flashing`
                    : ""
                }
                onTouchEnd={()=>{
                  const isEnabled = classrooms.length > 1 &&
                  school_m_id &&
                  district_m_id &&
                  discussionPage
                  
                  isEnabled && onClickClassroomDropdown && onClickClassroomDropdown()
                }}
                onClick={() => {
                  const isEnabled = classrooms.length > 1 &&
                  school_m_id &&
                  district_m_id &&
                  discussionPage
                  
                  isEnabled && onClickClassroomDropdown && onClickClassroomDropdown()
                }}
              >
                <ClassroomDropDown
                  isDisabled={
                    classrooms.length &&
                    school_m_id &&
                    district_m_id &&
                    discussionPage
                      ? false
                      : true
                  }
                  onChangeClassroom={(val: any) => {
                    onChangeClassroom(val);
                    setClassroomOpen(false);
                  }}
                  onKeyDown={(e:any) => e.key === "ArrowDown" && onClickClassroomDropdown && onClickClassroomDropdown()}
                  classrooms={classrooms}
                  selectedClassroom={classrooms?.find((item: any) => item.value === classroom_m_id) || null}
                  isOtherScreen={isOtherScreen}
                  formatOptionLabel={formatOptionLabel}
                  isOpen={
                    userRole === "Classroom Admin" ? false : true//classroomOpen
                  }
                />
              </div>
          </span>
          </li>
        )}
      </div>
      
      {/* {userRole === "Classroom Admin" && classrooms.length > 1 && !isOnlyResourcesClass && (
        <button
          className="btn outline-btn outline-btn-lg btn-test-log test-log-button"
          onClick={requestStlClass}
        >
          Summative Test Log
        </button>
      )} */}
    </>
  );
};

export default MngDropdowns;
