/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";
import { Input } from "../common/inputComponent";
import { TicketType } from "./ContactSupportModal";

interface Step5Props {
  preferredContact: string;
  data: any;
  urls: any[];
  handleOnChange: (name: string, value: any) => void;
}

export const Step5: React.FC<Step5Props> = ({
  preferredContact,
  handleOnChange,
  data,
  urls
}) => {
  const { auth } = useSelector((state: RootState) => state);
  return (
    <div className="support last-support">
      <p className="support-title">
        Please verify your details below and then submit your ticket.
      </p>
      <div className="support-container last-step">
        <div className="support__fieldset">
          <p className="support__fieldset-legend">Your Details</p>
          <div className="support__fieldset-info">

            <div className="support__fieldset-row">
              <div className="support__fieldset-label">
                <p>Name:</p>
              </div>
              <p>
                {auth.currentUser.first_name + " " + auth.currentUser.last_name}
              </p>
            </div>

            <div className="support__fieldset-row">
              <div className="support__fieldset-label">
                <p>Email:</p>
              </div>
              <p className="fw-bold">{auth.currentUser.email}</p>
            </div>

            {data.district_name && (
              <div className="support__fieldset-row">
                <div className="support__fieldset-label">
                  <p>District:</p>
                </div>
                <p className="fw-bold">{data.district_name}</p>
              </div>
            )}

            {data.school_name && (
              <div className="support__fieldset-row">
                <div className="support__fieldset-label">
                  <p>School:</p>
                </div>
                <p className="fw-bold">{data.school_name}</p>
              </div>
            )}

            {data.classroom_name && (
              <div className="support__fieldset-row">
                <div className="support__fieldset-label">
                  <p>Class:</p>
                </div>
                <p className="fw-bold">{data.classroom_name}</p>
              </div>
              // <p>
              //   <label>Class</label>:<span>{data.classroom_name}</span>Class:{" "}
              //   {data.classroom_name}
              // </p>
            )}
          </div>
        </div>

        <div className="mt-1">
          <Input
            fieldName="preferredContact"
            isSubmitError={false}
            errorPresentBefore={false}
            value={preferredContact}
            autoFocus={true}
            type="text"
            label="Preferred Contact (if different than email above)"
            onChange={handleOnChange}
            error={""}
          />
        </div>
      </div>

      <div className="support__details">
        <div className="support__details-left">
          {(data.option === TicketType.BUG ||
            data.option === TicketType.FEEDBACK_FEATURE ||
            data.option === TicketType.OTHER) && (
              <>
                <div className="support__details-row">
                  <h6 className="support__details-title">Summary</h6>
                  <p className="support__details-text">{data.info.summary}</p>
                </div>

                <div className="support__details-row">
                  <h6 className="support__details-title">Description</h6>
                  <p className="support__details-text">{data.info.description}</p>
                </div>
              </>
            )}
          {data.option === TicketType.TEXT_BOOK_ERROR && (
            <>
              <div className="support__details-row">
                <h6 className="support__details-title">Summary</h6>
                <p className="support__details-text">{data.info.textbookName}</p>
              </div>
              <div className="support__details-row">
                <h6 className="support__details-title">Description</h6>
                <p className="support__details-text">{data.info.description}</p>
              </div>
            </>
          )}
          {data.option === TicketType.ASSESSMENT_ERROR && (
            <>
              <div className="support__details-row">
                <h6 className="support__details-title">Summary</h6>
                <p className="support__details-text">{data.info.assessmentName}</p>
              </div>
              <div className="support__details-row">
                <h6 className="support__details-title">Description</h6>
                <p className="support__details-text">{data.info.description}</p>
              </div>
            </>
          )}
        </div>

        <div className="support__details-right">
          <div>
            <div>
              <h6 className="support__details-title">Files</h6>
              {data?.attachments ?
                data?.attachments?.map((item: any, index: number) => {
                  return <a href={urls[index]}><p className="support__details-text mb-2">{item?.name}</p></a>;
                })
                : <p className="support__details-text">No Attached Files</p>
              }
            </div>
            {/* {(data.option === "Textbook Error" ||
              data.option === "Assessment Error") && (
              <div className="step5__heading">
                <h6>Page Number</h6>
                <p>{data.info.pageNumber}</p>
              </div>
            )} */}
          </div>

        </div>
      </div>
    </div>
  );
};
