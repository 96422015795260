import React from "react";
import cookie from "js-cookie";
import ReactDOM from "react-dom";
import Application from "./application";
import 'react-quill/dist/quill.snow.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import 'react-loading-skeleton/dist/skeleton.css';
import "./assets/scss/app.scss";
import "react-toastify/dist/ReactToastify.css";
import "./assets/fonts/HelveticaNeue-Regular.ttf";
import './util/TextHighlighter'

import { store } from "./redux/store";
import { Provider } from "react-redux";
import { ActionType } from "redux/actionTypes/index";
import { logout } from "redux/actionCreators/auth";
import { AppCookies } from "util/constants";


if (cookie.get(AppCookies.token)) {
  let user = cookie.get(AppCookies.user);
  if (user) {
    store.dispatch({
      type: ActionType.USER_LOGIN_SUCCESS,
      payload: JSON.parse(user),
    });
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Application />
      <div id="tooltip" className="position-relative" style={{zIndex: 999999999 }}></div>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);


export const dispatchApiError = () => {
  store.dispatch({
    type: ActionType.SET_SHOW_API_ERROR_MODAl,
    payload: true
  })
}

export const logoutAction = () => {
  store.dispatch(logout())
}