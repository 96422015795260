import ReviewIconWhite from "assets/img/review-test-btn.svg";
import ReviewIcon from "assets/img/review-test-btn-black.svg";
import { PacingCalenderContext } from "contexts/pacingCalender";
import { useContext } from "react";
import checkIcon from "assets/img/circleIcon.svg";
import EmptyRecord from "components/common/EmptyRecord";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import moment from "moment";


const ScheduledAssessmentTable = ({ isNotScheduled, message, isSummative, loading, data, onCloseModal }: { isNotScheduled: boolean; message: string, isSummative?: boolean, loading: boolean, data: Array<any>, onCloseModal: Function }) => {
    const { pacingCalenderPostReq, isSuperAdmin } = useContext(PacingCalenderContext)

    const onObjectiveClick = (data: any) => {
        pacingCalenderPostReq(data)
        onCloseModal()
    }

    const isNoData = data.length === 0;
    return <table className="PacingCalender__SheduledAssesment__container__main__table ">
        <thead className="PacingCalender__SheduledAssesment__container__main__table__head">
                <th style={{opacity: isNoData ? 0.4 : 1}} className="text-white fw-bold px-3 py-2 td1">
                    Objective
                </th>
                <th style={{opacity: isNoData ? 0.4 : 1}} className="text-white fw-bold px-3 py-2 td2">
                    Description
                </th>
                <th
                    style={{opacity: isNoData || isNotScheduled ? 0.4 : 1}} 
                    className="text-white fw-bold px-2 py-2 td3"
                >
                    Scheduled Date
                </th>
        </thead>
        <tbody className="PacingCalender__SheduledAssesment__container__main__table__body">
            {loading
                ? <TableSkeleton columns={3} count={isSummative ? 3 : 15} height={45} />
                : data?.length ?
                    data?.map((item) => {
                        
                        const { name, description, scheduled_date, can_be_scheduled, summative_id, formative_id, is_completed, assessment_date_text } = item
                        const isDisabled = !!scheduled_date || (isSummative && !isSuperAdmin && !can_be_scheduled);
                        return <tr
                            key={item.id}
                            style={{cursor: isDisabled ? 'default' : 'pointer'}}
                            className={`${isDisabled ? '' : 'schedule-assessment-hover'}`}
                            onClick={() => !isDisabled && onObjectiveClick({
                                ...(summative_id && {summative_id: summative_id}),
                                ...(formative_id && {formative_id: formative_id}),
                            })}
                        >
                            <td className="text-white px-3 py-2 td1">
                                <span style={{opacity: isDisabled ? 0.4 : 1}} className="position-relative">
                                    {is_completed && <img className="TickIcon pe-2 position-absolute" height={'16px'} style={{left: "-27px"}} src={checkIcon} alt="" />}
                                    <span className="d-flex">
                                        <img className="TickIcon pe-2" height={'16px'} src={ReviewIcon} alt="Review" />
                                        <img className="TickIcon hover-white pe-2" height={'16px'} src={ReviewIconWhite} alt="Review" />
                                        <p className="mb-0">{name}</p>
                                    </span>
                                    { assessment_date_text && <div className="scheduledLabel">{assessment_date_text}</div>}
                                </span>
                                <span className="divider-table"></span>
                            </td>
                            <td className="text-white px-3 py-2 td2">
                                <span style={{opacity: isDisabled ? 0.4 : 1}}>
                                    {description}
                                </span>
                                <span className="divider-table"></span>
                            </td>

                            <td className="text-white px-2 py-2 td3">
                                {scheduled_date ? moment(scheduled_date).format('dddd, MMMM D') : ''}
                                <span style={{opacity: isNotScheduled || isDisabled ? 0.4 : 1}} className="divider-table"></span>
                            </td>
                        </tr>
                    }) :
                    <EmptyRecord message={message || "No Objectives Found"} />
            }

        </tbody>
    </table>
}
export default ScheduledAssessmentTable