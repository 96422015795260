import { ChatBoxContextProvider } from "interfaces/chatBox";
import _ from 'lodash'

export enum ChatBoxActions {
    SET_SUPPORT_CHAT = 'SET_SUPPORT_CHAT',
    UNSET_SUPPORT_CHAT = 'UNSET_SUPPORT_CHAT',
    SET_CHAT_MESSAGE = 'SET_CHAT_MESSAGE',
    CHAT_EVENT = 'CHAT_EVENT',
    SET_CHATBOX_MESSAGE = 'SET_CHATBOX_MESSAGE',
    SET_SUPPOERT_CHAT_STATUS = 'SET_SUPPOERT_CHAT_STATUS',
    SET_SUPPORT_CHAT_STATUS_ID = 'SET_SUPPORT_CHAT_STATUS_ID',
    RESET_CHAT_MESSAGE = 'RESET_CHAT_MESSAGE'
}


export const ChatBoxReducer = (state: ChatBoxContextProvider, action: any) => {
    const { type, payload } = action
    switch (type) {
        case ChatBoxActions.CHAT_EVENT:
            return {
                ...state,
                supportChat: payload
            }
        case ChatBoxActions.SET_SUPPORT_CHAT:
            const {tickets, offset} = payload;
            return {
                ...state,
                supportChat: offset === 0
                ? tickets
                : {
                    ...tickets,
                    messages: _.uniqWith([...(state.supportChat.messages.filter((message: any) => message.id !== undefined)), ...tickets.messages], _.isEqual),
                    messages_count: tickets.messages_count
                }
            }
        case ChatBoxActions.UNSET_SUPPORT_CHAT:
            return {
                ...state,
                supportChat: {
                    status: 0,
                    active: '',
                    messages: [],
                    messages_count: 0
                }
            }
        case ChatBoxActions.SET_CHAT_MESSAGE:
            const filteredMessages = state.supportChat.messages.filter((message: any) => message.tempId !== payload?.messageData?.tempId);
            const duplicateCount = state.supportChat.messages.length - filteredMessages.length;
            if(payload?.isReplaceMessage){
                return {
                    ...state,
                    supportChat: {
                        ...state.supportChat,
                        messages: [payload?.messageData, ...filteredMessages],
                        messages_count: state.supportChat.messages_count - duplicateCount + 1
                    }
                }
            }else{
                const { messages, messages_count, ...restChat } = payload?.chat;
                return {
                    ...state,
                    supportChat: {
                        ...state.supportChat,
                        ...restChat
                    }
                }
            }
            
        case ChatBoxActions.RESET_CHAT_MESSAGE:
            return {
                ...state,
                supportChat: {
                    ...state.supportChat,
                    messages: state.supportChat.messages.filter((message: any) => message.tempId !== payload.tempId),
                    messages_count: state.supportChat.messages_count - 1
                }
            }
        case ChatBoxActions.SET_CHATBOX_MESSAGE:
            return {
                ...state,
                chatBoxMessage: payload
            }
        case ChatBoxActions.SET_SUPPOERT_CHAT_STATUS:
            return {
                ...state,
                supportChat: {
                    ...state.supportChat,
                    status: payload
                }
            }

        case ChatBoxActions.SET_SUPPORT_CHAT_STATUS_ID:
            return {
                ...state,
                supportChat: {
                    ...state.supportChat,
                    status_id: payload
                }
            }
        default:
            return state
    }
}
