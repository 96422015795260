/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { QuestionnaireContext } from '../Assessment/QuestionnaireContext';
import ChangeObjectiveModal from '../Assessment/Modals/ChangeObjectiveModal';
import { ReactComponent as Highlighter } from "assets/img/highlighter.svg";
import Eraser from "assets/img/eraser.svg";
import Calculator from 'assets/img/calculator.svg';
import Close from 'assets/img/remove-icon-bold.svg';
import { initDrawing, bindDrawing, unbindDrawing, bindEraser, unbindEraser, eraseDrawing } from 'util/RaphaelInit'
import RedMarker from 'assets/img/red-marker.svg'
import Cookies from "js-cookie";
import { showDesmosCalculator } from "util/showDesmosCalculator";
import { AppCookies } from "util/constants";

     let calculator: any = null; 
    export const getCalculator = ()=>{
        return calculator;
    }
    export const setCalculator = (calc: any)=>{
        calculator = calc;
    }
interface Props {
    onRemoveHighlighter: () => void;
    onClickHighLighter: (value: Function) => void;
    onDestroyHighlighter: () => void;
    onDestroyHighlighterRemover: () => void;
    onRemoveAllHighlights: () => void;
}

const Footer: FC<Props> = (props) => {
    const {getCalculatorElement, closeCalculator, destroyCalculator,  questionIdx, currentQuestion, setShowHighlightEraser, showHighlightEraser, setIsScreenMarked, setShowCalculator, showCalculator, isPencilActive, setIsPencilActive, setIsHighlighterActive, isHighlighterActive, hasPreviousQuestion, hasNextQuestion, previousQuestion, nextQuestion, isPreview, setPreview, grade } = useContext(QuestionnaireContext);
    const { isScreenMarked } = currentQuestion
    const isMath = Cookies.get(AppCookies.isMath) === 'true'

    const [enableEraser, setEnableEraser] = useState(false);
    const [enableHighlightEraser, setEnableHighlightEraser] = useState(false);
    const [showHighlighterTooltip, setShowHighlighterTooltip] = useState(false);
    const [showPencilTooltip, setShowPencilTooltip] = useState(false);

    const markRef = useRef<any>(true);
    const eraseRef = useRef<any>(true);
    
    useEffect(() => {
        destroyCalculator();
        setShowHighlighterTooltip(false);
        setShowPencilTooltip(false);
        setEnableEraser(false);
        setEnableHighlightEraser(false);
    }, [questionIdx])

    useEffect(() => {
        if (enableEraser && !isScreenMarked) {
            setIsScreenMarked(true)
        }
    }, [enableEraser]);

    useEffect(() => {
        initDrawing();

        return () => {
            document.removeEventListener("click", handler);
            eraseDrawing();
            unbindDrawing();
        }
    }, []);

    useEffect(() => {
        if (isPencilActive) {
            eraseRef.current = false;
            bindDrawing(setIsScreenMarked);
        } else {
            eraseRef.current = true;
            markRef.current && unbindDrawing();

        }
    }, [isPencilActive]);

    const handler = useCallback((event: any) => {
        const elem = event.target;
        if(elem.nodeName === "path" && elem.parentNode.id === "sketch-container"){
            elem.remove();
        }
    },[]);

    useEffect(() => {
        if(enableEraser){
            markRef.current = false;
            setIsPencilActive(false);
            unbindDrawing();
            bindEraser(setIsScreenMarked);
            document.addEventListener("click", handler);
        }else{
            markRef.current = true;
            eraseRef.current && unbindEraser();
            document.removeEventListener("click", handler);
        }
    }, [enableEraser]);
    
    useEffect(() => {
        if (!isHighlighterActive) {
            props.onDestroyHighlighter();
        }
        else {
            props.onDestroyHighlighterRemover();
            props.onClickHighLighter((isMarked: boolean) => {
                setIsScreenMarked(isMarked);
                setEnableHighlightEraser(isMarked);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHighlighterActive]);

    useEffect(() => {
        if(showHighlightEraser){
            props.onRemoveHighlighter();
        }else{
            props.onDestroyHighlighterRemover();
        }
    },[showHighlightEraser]);

    const onClickHighlighter = (isShow: boolean) => {
        setShowHighlighterTooltip(isShow);
        setShowPencilTooltip(false);
        setIsHighlighterActive(isShow);
        setIsPencilActive(false);
        setEnableEraser(false);
        setShowHighlightEraser(false);
        closeCalculator();
    }

    const onSelectHighlighter = () => {
        setIsHighlighterActive(true);
        setIsPencilActive(false);
        setEnableEraser(false);
        setShowHighlightEraser(false);
    }

    const onSelectPencil = () => {
        setIsPencilActive(true);
        setIsHighlighterActive(false);
        setEnableEraser(false);
        setShowHighlightEraser(false);
    }

    const onClickPencil = (isShow: boolean) => {
        setShowPencilTooltip(isShow);
        setIsPencilActive(isShow);
        setShowHighlighterTooltip(false);
        setIsHighlighterActive(false);
        setEnableEraser(false);
        setShowHighlightEraser(false);
        closeCalculator()
    }

    const onClickEraser = () => {
        setEnableEraser(prev => !prev);
        setIsHighlighterActive(false);
        setShowHighlightEraser(false);
    }

    const onClickHighlightEraser = () => {
        setShowHighlightEraser(!showHighlightEraser);
        setIsHighlighterActive(false);
        setIsPencilActive(false);
        setEnableEraser(false);
        
    }

    const onClearAllPencil = () => {
        eraseDrawing();
        setEnableEraser(false);
    }

    const onClosePencilTooltip = () => {
        onClickPencil(false)
    }

    const onCloseHighlightTooltip = () => {
        onClickHighlighter(false)
    }


    const openCalculator = () => {
        const elt = getCalculatorElement();
        if(!elt || calculator) return;

        setShowCalculator(true);
        elt.style.display = 'block';
        elt.style.width = '400px';
        elt.style.height = '600px';
        console.log('openCalc', grade, elt);
        calculator = showDesmosCalculator(grade, elt);
        setTimeout(() => {
            elt.style.height = 'auto';
            elt.style.maxHeight = '650px'
        },0);
    }

    const onCalculatorClicked = () => {

        if(showCalculator){
            closeCalculator();
        }else{
            onClosePencilTooltip();
            onCloseHighlightTooltip();
            openCalculator();
        }
        
    }

    return (
        <footer className="cmsFooter">
            <ChangeObjectiveModal />
            <div className="pg-container">
                <div className="cmsFooter-wrapper">


                    <div className="cmsFooter__search"
                    >
                        <div style={{position: "relative"}}>
                            {showHighlighterTooltip && (
                                <div className="edit-options-tooltip">
                                    <span
                                        className={`cmsFooter__tools ${isHighlighterActive ? 'cmsFooter__tools-border' : ''}`}
                                    >
                                        <Highlighter

                                            style={{ cursor: "pointer" }}
                                            onClick={onSelectHighlighter}
                                        />
                                    </span>
                                    {enableHighlightEraser ?
                                        <span className={`footer-size ${showHighlightEraser ? 'cmsFooter__tools-border' : ''}`}>
                                            <img
                                                src={Eraser}
                                                alt="eraser"
                                                onClick={onClickHighlightEraser}
                                                style={{ 
                                                    cursor: "pointer"
                                                }}
                                            />
                                        </span>
                                        :
                                        <span className="footer-size">
                                            <img
                                                src={Eraser}
                                                alt="eraser"
                                                style={{ opacity: 0.5 }}
                                            />
                                        </span>
                                    }
                                    <button onClick={props.onRemoveAllHighlights}>Clear All</button>
                                    <button onClick={onCloseHighlightTooltip} type="button" className="closeTooltipBtn">
                                        <img src={Close} alt="Close" />
                                    </button>
                                </div>
                            )}
                            <span
                                className={`cmsFooter__tools ${showHighlighterTooltip ? 'cmsFooter__tools-border' : ''}`}
                                // style={{position: 'relative'}}
                            >
                                <Highlighter

                                    style={{ cursor: "pointer" }}
                                    onClick={() => onClickHighlighter(!showHighlighterTooltip)}
                                />
                            </span>
                        </div>
                        
                        <div style={{position: "relative"}}>
                            {showPencilTooltip && (
                                <div className="edit-options-tooltip">
                                    <span
                                        className={`cmsFooter__tools ${isPencilActive ? 'cmsFooter__tools-border' : ''}`}
                                    >
                                        <img
                                            alt="Pencil"
                                            style={{ cursor: 'pointer' }}
                                            src={RedMarker}
                                            onClick={onSelectPencil}
                                        />
                                    </span>
                                    {isScreenMarked ?
                                        <span className={`footer-size ${enableEraser ? 'cmsFooter__tools-border' : ''}`}>
                                            <img
                                                src={Eraser}
                                                alt="eraser"
                                                onClick={onClickEraser}
                                                style={{ 
                                                    cursor: "pointer"
                                                }}
                                            />
                                        </span>
                                        :
                                        <span className="footer-size">
                                            <img
                                                src={Eraser}
                                                alt="eraser"
                                                style={{ opacity: 0.5 }}
                                            />
                                        </span>
                                    }
                                    <button onClick={onClearAllPencil}>Clear All</button>
                                    <button onClick={onClosePencilTooltip} type="button" className="closeTooltipBtn">
                                        <img src={Close} alt="Close" />
                                    </button>
                                </div>
                            )}
                            <span
                                className={`cmsFooter__tools ${showPencilTooltip ? 'cmsFooter__tools-border' : ''}`}
                            >
                                <img
                                    alt="Pencil"
                                    style={{ cursor: 'pointer' }}
                                    src={RedMarker}
                                    onClick={() => onClickPencil(!showPencilTooltip)}
                                />
                            </span>
                        </div>

                        {
                            isMath && <span
                                className={`cmsFooter__tools ${showCalculator ? 'cmsFooter__tools-border' : ''}`}
                                onClick={onCalculatorClicked}
                            >
                                <img
                                    className="calculator-icon"
                                    id="calculator"
                                    style={{ cursor: "pointer" }}
                                    src={Calculator}
                                    alt="Calculator"
                                />
                            </span>
                        }


                    </div>

                    <div className="cmsFooter__buttons">
                        <div className="cmsFooter__buttons-wrapper">
                            <button onClick={() => { setPreview(!isPreview) }} className="btn cancel-btn btn-medium show-hide-btn">{`${!isPreview ? 'Show' : 'Hide'} Answer`}</button>
                            {/* <button onClick={() => {}} className="btn orange-btn light btn-medium">Pause/Exit</button> */}
                            <button disabled={!hasPreviousQuestion} onClick={previousQuestion} className="btn cancel-btn btn-medium">Back</button>
                            <button disabled={!hasNextQuestion} onClick={nextQuestion} className="btn cancel-btn btn-medium">Next</button>
                            {/* <button disabled={hasNextQuestion} onClick={() => { }} className="btn success-btn btn-medium">Finish</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
