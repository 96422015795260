const AnalysisFooter = ({
  firstLabel,
  secondLabel,
  firstColor,
  secondColor,
  onClick,
  buttonText,
  isGoalCompletetion,
}: any) => {
  return (
    <div
      className={`anylasisFooter ${
        isGoalCompletetion ? "anylasisFooterEnd" : ""
      }`}
    >
      <div
        className={`d-flex align-items-center justify-content-md-start justify-content-center ${
          isGoalCompletetion ? "partialComp" : ""
        }`}
      >
        <div className="anylasisFooter__markers">
          <div
            style={{ backgroundColor: firstColor || "#3B792F" }}
            className="identificationBox circle-icons"
          ></div>
          <p>{firstLabel}</p>
        </div>
        <div className="anylasisFooter__markers">
          <div
            style={{ backgroundColor: secondColor || "#C44538" }}
            className="identificationBox circle-icons"
          ></div>
          <p>{secondLabel}</p>
        </div>
        {isGoalCompletetion && (
          <div className="anylasisFooter__markers">
            <div
              style={{ backgroundColor: "#EADB4A" }}
              className="identificationBox circle-icons"
            ></div>
            <p>Partially Completed</p>
          </div>
        )}
      </div>
      {!!buttonText && (
        <button
          type="button"
          onClick={onClick}
          className="anylasisFooter__viewBtn"
        >
          {buttonText}
        </button>
      )}
    </div>
  );
};

export default AnalysisFooter;
