import { ChatSocketContext } from "contexts/socketContext";
import Cookies from "js-cookie";
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setThemeColor, updateProfileColor } from "redux/actionCreators/auth";
import ActionType from "redux/actionTypes";
import { RootState } from "redux/reducers/combine";
import { updateUserData } from "services/common";
import NotificationCircle from "./notificationCircle";
import { AppCookies } from "util/constants";

const bgColors = [
  {
    id: 1,
    code: "#194792",
    value: "BLUE",
  },
  {
    id: 2,
    code: "#323232",
    value: "GREY",
  },
];

interface IProps {
  onClickChangePassword: () => void;
  toggleSupportModal: (toggle: boolean) => void;
  userLogout: () => void;
  onClickSurveyModal: () => void;
  customClass: string;
  closeMenu?: () => void;
  openDropdown?: boolean;
}

const savedTheme = localStorage.getItem("background_theme");
const Menu = ({
  openDropdown,
  closeMenu,
  onClickChangePassword,
  toggleSupportModal,
  userLogout,
  onClickSurveyModal,
  customClass,
}: IProps) => {
  const { auth } = useSelector((state: RootState) => state);
  const dispatch = useDispatch();

  const { headerNotification } = useContext(ChatSocketContext) || {};

  const isShowSurvey = auth?.colorProfile?.show_survey;

  const updateProfile = async (color: any) => {
    dispatch(updateProfileColor(color.id));
  };

  const handleColorClick = (index: number) => {
    if (colorProfile.colors?.length > 0) {
      dispatch({
        type: ActionType.USER_PROFILE_DATA,
        payload: {
          ...auth?.colorProfile,
          selected: {
            ...auth?.colorProfile?.selected,
            fk_color_code_id: colorProfile.colors[index].id,
          },
        },
      });
      Cookies.set(AppCookies.profile_color_code, colorProfile.colors[index].code);
      updateProfile(colorProfile.colors[index]);
    }

    // setProfileColor && setProfileColor(colors[index]);
    // setSelectedColor(colors[index].id);
    // setColorIndex(index);
    // cookie.set('profile_color_code', colors[index].code);
  };

  const handleThemeColor = (color: any) => {
    dispatch(
      setThemeColor({ ...auth?.colorProfile, background_theme: color.value })
    );
    updateUserData("", { background_theme: color.value });
    localStorage.setItem("background_theme", color.value);
  };

  useEffect(() => {
    const bgTheme =
      auth?.colorProfile?.background_theme ||
      (savedTheme ? savedTheme : "BLUE");
    if (bgTheme) {
      document.body.setAttribute("data-theme", `theme-${bgTheme}`);
    }
  }, [auth?.colorProfile?.background_theme]);

  const supportPin: any = `${Cookies.get(AppCookies.support_pin)}`;

  const colors = auth?.colorProfile?.colors || [];
  const colorProfile = auth?.colorProfile;
  const colorIndex = colorProfile?.colors?.findIndex(
    (color: any) => color.id === colorProfile.selected.fk_color_code_id
  );
  const selectedColor =
    colors?.length > 0 && colors[colorIndex]?.id ? colors[colorIndex].id : null;

  const bgTheme =
    auth?.colorProfile?.background_theme || (savedTheme ? savedTheme : "BLUE");
  return (
    <div className={`loginUser-dropDown ${customClass}`}>
      <div className="colorPanelContainer">
        <p>User Icon Color</p>
        <div className="colorPanel">
          {colors.map((color: any, index: number) => {
            return (
              <div
                role="button"
                tabIndex={openDropdown ? 0 : -1}
                key={color.id}
                className="colorBox"
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    handleColorClick(index);
                  }
                }}
                style={{
                  backgroundColor: color?.code,
                  border: selectedColor === color?.id ? "2px solid white" : "",
                }}
                onClick={() => handleColorClick(index)}
              />
            );
          })}
        </div>
      </div>
      <div className="colorPanelContainer">
        <p>Background Color</p>
        <div className="colorPanel">
          {bgColors.map((color: any) => {
            return (
              <div
                role="button"
                tabIndex={openDropdown ? 0 : -1}
                key={color.id}
                className="colorBox"
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    handleThemeColor(color.code);
                  }
                }}
                style={{
                  backgroundColor: color?.code,
                  border: bgTheme === color?.value ? "2px solid white" : "",
                }}
                onClick={() => handleThemeColor(color)}
              ></div>
            );
          })}
        </div>
      </div>
      <a
        href="https://alphaplus-prod-teacher-ui-v3.s3.us-east-2.amazonaws.com/resources/goals/QuickStart_APO_24-25.pdf"
        target="_blank"
        rel="noopener noreferrer"
        className="dropDown-menu"
        tabIndex={openDropdown ? 0 : -1}
        role="button"
      >
        Quick Start Guide
      </a>
      {/* commented temporarily */}
      {/* <Link
        tabIndex={openDropdown ? 0 : -1}
        role="button"
        to="/mastery-videos"
        className="dropDown-menu"
      >
        Mastery Video Series
      </Link> */}
      <Link
        tabIndex={openDropdown ? 0 : -1}
        role="button"
        to="#/"
        onClick={() => onClickChangePassword()}
        className="dropDown-menu"
      >
        Change Password
      </Link>

      {auth?.currentUser?.role === "Super Admin" ? (
        <Link
          to="/super-admin/support-center"
          className="dropDown-menu"
          tabIndex={openDropdown ? 0 : -1}
        >
          Support Center
          {headerNotification && (
            <NotificationCircle count={headerNotification} />
          )}
        </Link>
      ) : (
        <Link
          to="/support-tickets"
          className="dropDown-menu"
          tabIndex={openDropdown ? 0 : -1}
        >
          Support Inbox
          {headerNotification && (
            <NotificationCircle count={headerNotification} />
          )}
        </Link>
      )}
      <Link
        to="#/"
        onClick={() => toggleSupportModal(true)}
        className="dropDown-menu borderBottom"
        tabIndex={openDropdown ? 0 : -1}
      >
        Create Support Ticket{" "}
      </Link>
      {isShowSurvey && (
        <Link to="#/" onClick={onClickSurveyModal} className="dropDown-menu">
          Feedback Survey
        </Link>
      )}
      {supportPin && supportPin !== "0" && (
        <Link
          to="#/"
          onClick={() => closeMenu && closeMenu()}
          className="dropDown-menu"
        >
          Support PIN <span className="pin-color">{supportPin}</span>
        </Link>
      )}
      <Link
        to="#/"
        onClick={userLogout}
        className="dropDown-menu"
        tabIndex={openDropdown ? 0 : -1}
      >
        Log Out
      </Link>
    </div>
  );
};

export default Menu;
