import DrodownIcon from "assets/img/accordian-box-icon.png";
import HandGestureIcon from "assets/img/handGesture.svg";
import moment from "moment";
import MyTime from "../supportChat/content/MyTime";
import { useEffect, useRef, useState } from "react";
import { useGetChatTime } from "hooks/useGetChatTime";
import ToolTip from "../Tooltip";
import { useOnHoverOutside } from "hooks/useOnHoverOutside";
import BackDrop from "../backDrop";

const ArchivedChat = ({ isActiveChat, chat, onClose, loading, setChatQueryParams }: any) => {
    
    const { respondent, status, date_created, from_archived=false, unread_count, messages_count, messages = [], district = '', sent_by, sent_by_id, district_id, school, school_id, classrooms, tickets, closed_at } = (chat || {}) as any;
    const notificationsRef = useRef<any>(null!);
    const messageCountRef = useRef<number>(50);
    const conatinerRef = useRef<any>(null!);
    const [isMenuDropDownOpen, setMenuDropDownOpen] = useState<Boolean>(false);  
    // const [isTicketMenuDropDownOpen, setTicketMenuDropDownOpen] = useState<Boolean>(false);  

    const onScrollMessages = () => {
        const currentMessageCount = messageCountRef.current;
        if (currentMessageCount < messages_count){
            const scrollHeight = notificationsRef?.current?.scrollHeight
            const scrollValue = (notificationsRef?.current?.scrollTop * -1) + 400
            const threshold = scrollHeight - (scrollHeight * 0.15)

            if (scrollValue >= threshold) {
                messageCountRef.current += 50;
                setChatQueryParams((prevQuery: any) => ({
                    ...prevQuery,
                    offset: (prevQuery.offset + 1)*prevQuery.limit
                }))
            }
        }
    }
    
    const isArchived = status === 'RESOLVED' || status === 'EXPIRED';
    const classesRef = useRef<any>(null!);
    const ticketsRef = useRef<any>(null!);

    const closeHoverMenu = () => {
        setMenuDropDownOpen(false);
    };

    const closeHoverTicketMenu = () => {
        // setTicketMenuDropDownOpen(false);
    };

    useOnHoverOutside(classesRef, closeHoverMenu);
    useOnHoverOutside(ticketsRef, closeHoverTicketMenu);

    useEffect(() => {
        const handleClick = (event: any) => {
            
            if(conatinerRef.current?.contains(event.target)){
                return;
            }

            onClose();
        }

        const handleKeyDown = (event: any) => {
            if (event.keyCode === 27) onClose();
        }

        document.addEventListener('click', handleClick);
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('click', handleClick);
            document.removeEventListener('keydown', handleKeyDown);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const chatendDate = closed_at ? closed_at : new Date().toString();
    const chatTime = useGetChatTime(date_created, chatendDate)
    // const chatTime = useGetChatTime(date, new Date().toString());

    const schoolDistrictName = school ? school : district;
    const [classesDropdownHeight, classesDropdownTop] = classrooms?.length === 1 ? ['auto', -42] : classrooms?.length === 2 ? ['auto', -73] : classrooms?.length >= 3 ? [103, -104] : [43, -60];
    // const [ticketDropdownHeight, ticketDropdownTop] = tickets?.length === 1 ? ['auto', -42] : tickets?.length === 2 ? ['auto', -73] : tickets?.length >= 3 ? [103, -104] : [43, -60];

    return (
        <>
            <div className="showAdminChatBox archiveBox">
                <div ref={conatinerRef} className="chatBox support-center-chat-session">
                    <div className="d-flex justify-content-between mob-spacing">
                        <div className="d-flex flex-sm-row flex-column w-100">
                            <div className="d-flex mr-3">
                                <div className="cursor-pointer" onClick={onClose}>
                                    <span className="accordion__header-button downArrow">
                                        <img
                                            src={DrodownIcon}
                                            alt="arrow"
                                            className="rotate-90"
                                        />
                                    </span>
                                </div>
                                {loading ? (
                                    <div className="chatbox-footer-skeleton"></div>
                                ):(
                                    <h3 className="fw-bold text-white chatBox__alphaHeading">{isArchived ? 'Archived' : 'Active'} Chat Session from {moment(date_created).format("M-D-YY")}</h3>
                                )}
                                
                            </div>
                        </div>
                        <div className="chatbox-chatIcon">
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none">
                        <mask id="mask0_1007_1793" style={{"maskType":"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="55" height="55">
                            <path d="M55 0.998047H0V54.998H55V0.998047Z" fill="white"/>
                            </mask>
                            <g mask="url(#mask0_1007_1793)">
                            <path d="M18.863 33.4286C15.9952 33.4311 13.1889 32.597 10.788 31.0286L4.01904 33.4286L6.41904 26.6586C5.16247 24.7214 4.37526 22.5177 4.12 20.2228C3.86475 17.9279 4.1485 15.6051 4.94869 13.4392C5.74887 11.2732 7.04342 9.32381 8.72937 7.74607C10.4153 6.16832 12.4462 5.00574 14.6604 4.35077C16.8746 3.69581 19.2111 3.56653 21.484 3.97323C23.757 4.37993 25.9037 5.31138 27.7534 6.6935C29.6031 8.07562 31.1048 9.87028 32.1391 11.9348C33.1733 13.9992 33.7116 16.2765 33.711 18.5856" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M18.8628 24.1249C21.4519 24.1249 23.5508 21.6446 23.5508 18.5849C23.5508 15.5253 21.4519 13.0449 18.8628 13.0449C16.2737 13.0449 14.1748 15.5253 14.1748 18.5849C14.1748 21.6446 16.2737 24.1249 18.8628 24.1249Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M19.644 24.21L22.769 24.835" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M50.0581 45.4098C52.0589 42.329 52.8482 38.6165 52.2736 34.9883C51.6991 31.36 49.8014 28.073 46.9466 25.7612C44.0919 23.4493 40.4822 22.2764 36.8137 22.4686C33.1453 22.6608 29.678 24.2046 27.0804 26.8021C24.4829 29.3996 22.9392 32.867 22.7469 36.5354C22.5547 40.2039 23.7276 43.8135 26.0395 46.6683C28.3513 49.5231 31.6383 51.4208 35.2666 51.9953C38.8949 52.5699 42.6074 51.7806 45.6881 49.7798L52.4571 52.1798L50.0581 45.4098Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M37 31.998L33 42.998" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M37 31.998L41 42.998" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M35 38.998H40" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                        </svg> */}
                            <svg width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.8621 31.4685C12.9942 31.471 10.188 30.637 7.78707 29.0685L1.01807 31.4685L3.41807 24.6985C-0.0286584 19.3849 0.276607 12.4694 4.17813 7.4801C8.07965 2.4908 14.7175 0.527302 20.7054 2.5913C26.6933 4.6553 30.7115 10.2919 30.7101 16.6255" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <ellipse cx="15.8618" cy="16.625" rx="4.688" ry="5.54" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M16.6431 22.25L19.7681 22.875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M47.0572 43.4499C51.2012 37.0692 49.8584 28.5894 43.9457 23.8013C38.0331 19.0131 29.4594 19.4624 24.0795 24.8422C18.6997 30.2221 18.2504 38.7958 23.0386 44.7084C27.8267 50.6211 36.3065 51.9639 42.6872 47.8199L49.4562 50.2199L47.0572 43.4499Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M34 30L30 41" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M34 30L38 41" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M32 37H37" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </div>

                    <div className="chatBox__showProfiles">

                        {loading ? (
                            <div className="show-profile-skeleton">

                                <p className="header"></p>

                                <div className="body">

                                </div>

                            </div>
                        ):(
                            <div className={`chatbox-active`}>
                                <div style={{cursor: 'default'}} className={`chatBox__showProfiles__profile`}>

                                    <p className={`chatBox__showProfiles__profile__headerActive`}>
                                        <span>{respondent?.trim() || "Assign to Me"}</span>
                                    </p>

                                    <div className={`chatBox__showProfiles__profile__bodyActive`}>
                                        <div>
                                            <div className={`chatBox__showProfiles__profile__bodyActive__name2Active ellipsis-animated`}>
                                                <p className={`chatBox__showProfiles__profile__bodyActive__nameActive cursor-pointer ellipsis-animated-text ${schoolDistrictName?.length > 18 ? 'animated ellipsis-animation' : ''} text-left`}>
                                                    {schoolDistrictName || ''}
                                                </p>
                                            </div>
                                            <div className={`chatBox__showProfiles__profile__bodyActive__name2Active ellipsis-animated`}>
                                                <p className={`cursor-pointer ellipsis-animated-text ${sent_by?.length > 16  ? 'animated ellipsis-animation' : ''} text-left`}>
                                                    {sent_by}
                                                </p>
                                            </div>
                                        </div>
                                        <p className={`chatBox__showProfiles__profile__bodyActive__timeActive`}>
                                            {
                                                isActiveChat 
                                                ? <MyTime autoStart={false} date={date_created} endDate={closed_at} />
                                                : chatTime
                                            }
                                        </p>
                                    </div>
                                    {!!parseInt(unread_count) && <div className="chatBox__showProfiles__profile__messages">{parseInt(unread_count)}</div>}
                                    
                                </div>
                            </div>
                        )}
                        
                    </div>

                    <div className="mob-spacing">
                        <div className="chatBox__notifications" ref={notificationsRef} onScroll={onScrollMessages}>
                            {
                                loading ?
                                    <>
                                        <div className="chatbox-chatSkeleton">
                                            <div className="profile"></div>
                                            <div className="text"></div>
                                        </div>

                                        <div className="chatbox-chatSkeleton">
                                            <div className="profile"></div>
                                            <div className="text"></div>
                                        </div>
                                        <div className="chatbox-chatSkeleton">
                                            <div className="profile"></div>
                                            <div className="text"></div>
                                        </div>

                                        <div className="chatbox-chatSkeleton">
                                            <div className="profile"></div>
                                            <div className="text"></div>
                                        </div>
                                        <div className="chatbox-chatSkeleton">
                                            <div className="profile"></div>
                                            <div className="text"></div>
                                        </div>

                                        <div className="chatbox-chatSkeleton">
                                            <div className="profile"></div>
                                            <div className="text"></div>
                                        </div>
                                    </>

                                :

                                messages?.length > 0 &&
                                messages.map((item: any) => {
                                    const { message, name_initials, date, color_hex, type, id } = item;
                                    return (
                                        <div key={id} className="d-flex align-items-md-center py-2 message-row">
                                            {
                                                type !== "NOTIFICATION" ?
                                                    <>
                                                        <div style={{ backgroundColor: color_hex }} className="chatBox__notifications__name2">
                                                            {name_initials}
                                                        </div>
                                                        <div className="chatBox__notifications__messageWidth">
                                                            <p className="chatBox__notifications__message">{message}</p>
                                                            <p className="chatBox__notifications__time">
                                                                {moment(date).format('h:mm A')}
                                                            </p>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div style={{ backgroundColor: '#ffffff', marginRight: '0px' }} className="chatBox__notifications__name2">
                                                            <div className="chatBox__notifications__handGestureIconWrapper ">
                                                                <img src={HandGestureIcon} alt="HandGestureIcon" />
                                                            </div>
                                                        </div>
                                                        <p className="chatBox__notifications__message handGestureMsg"><strong>{message}</strong></p>
                                                    </>
                                            }
                                        </div>
                                    )
                                })
                                
                            }

                        </div>
                    </div>


                    <div className="mob-spacing">
                        {
                                <div className="d-flex justify-content-center align-items-center mt-2">
                                    {
                                        loading ? (

                                            <div className="chatbox-footer-skeleton">
                                            </div>
                                        ) :

                                        chat && (
                                            <div className="chatBox__centerBtns d-md-flex d-none">

                                                {
                                                    <button type="button" className="chatBox__centerBtns__btn sent-info">
                                                        {sent_by?.length > 11 ? (
                                                            <ToolTip top={-40} left={-60} content={`${sent_by} Info`}>
                                                                {sent_by} Info
                                                            </ToolTip>
                                                        ):(
                                                            <>{sent_by} Info</>
                                                        )}
                                                    </button>
                                                }

                                                    {from_archived && (
                                                        <div className="deleteBtn">
                                                            <div onClick={() => {}}  className="btn">
                                                                Deleted Admin
                                                            </div>
                                                        </div>
                                                    )}
                                                {
                                                    district.length > 0 && (
                                                        <button onClick={() => window.open(`/management/district/${district_id}?district_name=${district}`, '_blank')} type="button" className="chatBox__centerBtns__btn">
                                                            {district?.length > 22 ? (
                                                                <ToolTip top={-40} left={-80}  content={district}>
                                                                    {district}
                                                                </ToolTip>
                                                            ):(
                                                                <>{district}</>
                                                            )}
                                                        </button>
                                                    )

                                                }

                                                {
                                                    school.length > 0 && (
                                                        <button onClick={() => window.open(`/management/school/${school_id || 443}`, '_blank')} type="button" className="chatBox__centerBtns__btn school-name">
                                                            {school?.length > 10 ? (
                                                                <ToolTip top={-40} left={-70} content={school}>
                                                                    {school}
                                                                </ToolTip>
                                                            ):(
                                                                <>{school}</>
                                                            )}
                                                        </button>
                                                    )
                                                }

                                                <div ref={classesRef} className="position-relative">
                                                    <button 
                                                        type="button" 
                                                        className="chatBox__centerBtns__btn rounded-0"
                                                        onMouseOver={() => classrooms.length > 1 && setMenuDropDownOpen(true)}
                                                        style={{
                                                            pointerEvents: classrooms.length === 0 ? 'none' : 'auto'
                                                        }}
                                                        
                                                        onClick={() => classrooms.length === 1 ? !!classrooms[0]?.classrooms.id && window.open(`/management/class/${classrooms[0]?.classrooms.id}`, '_blank') : setMenuDropDownOpen(prev => !prev)}
                                                    >
                                                        {
                                                            classrooms.length === 0 ? 'No Classes' : classrooms.length === 1 ?  `${classrooms.length} Class` :  `${classrooms.length} Classes`
                                                        }
                                                    </button>

                                                    {
                                                        isMenuDropDownOpen && classrooms?.length > 0 && !!classrooms[0]?.classrooms.id && (
                                                            <div className="classes-tooltip" style={{ height: classesDropdownHeight, top: classesDropdownTop}}>
                                                            
                                                                {
                                                                    <div className="tooltip-buttons">
                                                                        
                                                                        {classrooms.map((classroom: any) => {

                                                                            return (

                                                                                !!classroom?.classrooms?.id && (
                                                                                    <button onClick={() => window.open(`/management/class/${classroom.classrooms.id}`, '_blank')} type="button" className="chatBox__centerBtns__btn">
                                                                                        {
                                                                                            classroom?.classrooms?.name || ''
                                                                                        }
                                                                                    </button>
                                                                                )
                                                                            )
                                                                        })}
                                                                    </div>
                                                                }
                                                            </div>

                                                        )
                                                    }
                                                </div>


                                                <div ref={ticketsRef} className="position-relative">

                                                {/* window.open(`/super-admin/support-center?chatHistory=1&assigned_to=${classrooms[0]?.classrooms.id}`, '_blank') */}
                                                    
                                                    <button 
                                                        type="button" 
                                                        className="chatBox__centerBtns__btn"
                                                        style={{
                                                            pointerEvents: tickets.length === 0 ? 'none' : 'auto'
                                                        }}
                                                        
                                                        onClick={() => tickets.length !== 0 ? !!sent_by_id && window.open(`/super-admin/support-center?created_by=${sent_by_id}`, '_blank') : {}} // 
                                                    >
                                                        {
                                                            tickets.length === 0 ? 'No Tickets' : tickets.length === 1 ?  `${tickets.length} Ticket` :  `${tickets.length} Tickets`
                                                        }
                                                    </button>
                                                    
                                                    {/* <button 
                                                        type="button" 
                                                        className="chatBox__centerBtns__btn rounded-0"
                                                        onMouseOver={() => tickets.length > 1 && setTicketMenuDropDownOpen(true)}
                                                        style={{
                                                            pointerEvents: tickets.length === 0 ? 'none' : 'auto'
                                                        }}
                                                        
                                                        onClick={() => tickets.length === 1 ? !!tickets[0] && window.open(`/super-admin/ticket-details/${tickets[0]}`, '_blank') : setTicketMenuDropDownOpen(prev => !prev)}
                                                    >
                                                        {
                                                            tickets.length === 0 ? 'No Tickets' : tickets.length === 1 ?  `${tickets.length} Ticket` :  `${tickets.length} Tickets`
                                                        }
                                                    </button> */}

                                                    {/* {
                                                        isTicketMenuDropDownOpen && tickets?.length > 0 && !!tickets[0] && (
                                                            <div className="tickets-tooltip" style={{ height: ticketDropdownHeight, top: ticketDropdownTop}}>
                                                            
                                                                {
                                                                    <div className="tooltip-buttons">
                                                                        
                                                                        {tickets.map((ticket: any) => {

                                                                            return (

                                                                                !!ticket && (
                                                                                    <button onClick={() => window.open(`/super-admin/ticket-details/${ticket}`, '_blank')} type="button" className="chatBox__centerBtns__btn">
                                                                                        {
                                                                                            'Ticket #' + ticket
                                                                                        }
                                                                                    </button>
                                                                                )
                                                                            )
                                                                        })}
                                                                    </div>
                                                                }
                                                            </div>

                                                        )
                                                    } */}
                                                </div>


                                            </div>
                                        )

                                    }
                                </div>
                        }
                    </div>

                </div>

            </div>
            
            <BackDrop />

        </>
    )
}

export default ArchivedChat