export const htmlToElement = (html: string) => {
    var template = document.createElement('template');
    html = html?.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;

    return template.content;
}

export const ElementTohtml = (html: any) => {
    const tmp = document.createElement("div");
    tmp.appendChild(html);
    return tmp.innerHTML?.trim()
}