/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import IPage from "interfaces/page";
import logging from "config/logging";
import Theme from "components/theme/index";
import DistrictAdminDetail from "../components/management/district-admin-detail";
import SchoolAdmins from "../components/management/school-admins";
import Schools from "components/management/schools";
import Students from "components/management/students";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import StlSingleButton from "components/common/stlSingleDistrictButton";
import { useSelector } from "react-redux";
import cookie from "js-cookie";
import { AppCookies, SCROLL_VALUE_MANAGEMENT } from "util/constants";
import useWindowScrollPosition from "hooks/useWindowScrollPosition";

const DistrictPage: React.FunctionComponent<IPage> = (props) => {
  const { id } = useParams();
  let districtId: any = localStorage.getItem("district_id");
  const { management, activity } = useSelector(
    (state: any) => state
  );
  const navigate = useNavigate();
  const userRole = cookie.get(AppCookies.role);

  const scrollValue = useWindowScrollPosition(SCROLL_VALUE_MANAGEMENT, true);

  useEffect(() => {
    window.scrollTo(0,0);
  },[]);

  useEffect(() => {
    logging.info(`Loading ${props.name}`);
  }, [props.name]);

  useEffect(() => {
    if(userRole === 'District Admin' && management.district_m_id > 0 && activity.districtsList.length){
      const selectedDistrict = activity.districtsList.find(((item: any) => item.id  === management.district_m_id))
      navigate(`/management/district/${selectedDistrict.id}`,{state: {name: selectedDistrict?.district_name}})
     }
  }, [management.district_m_id,activity.districtsList]);

  const onNavigate = () => {
    sessionStorage.setItem(SCROLL_VALUE_MANAGEMENT, `${scrollValue}`)
  }

  return (
    <>
      <Theme>
        <div className="management-st-wrap management-accordian text-white">
        {districtId===id && management.stlSingleLoading && <StlSingleButton />}
          <Breadcrumbs page="district" />
            {id && (
              <DistrictAdminDetail
                districtId={id || ""}
              />
            )}
            {id && <SchoolAdmins districtId={id || ""} />}
            {id && <Schools onNavigate={onNavigate} page="district" districtId={id || ""} />}
            {id && <Students onNavigate={onNavigate} page="district" districtId={id || ""} />}
          </div>
      </Theme>
    </>
  );
};

export default DistrictPage;
