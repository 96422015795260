import React, { CSSProperties, PropsWithChildren, useEffect,  useRef, useState } from 'react';

export const IdsToAnimate:number[] = [];
interface IProps {
  animateFirstTime?: boolean;
  containerStyles?:CSSProperties, 
  elementStyles?:CSSProperties;
  tag: string;
  onClick?: () => void;
  id?: any,
  role?:any, 
  tabIndex?:number,
  onKeyDown?:(e:any)=>void
  isClassName?:boolean
  elementClassName?:string
  animateName?:boolean
}
const ScrollingText = ({animateName, containerStyles, tag, elementStyles, id, animateFirstTime, children, isClassName, elementClassName = "", ...props}: PropsWithChildren<IProps>) => {
  

  const [isHovering, setIsHovering] = useState(false);
  const [difference, setDifference] = useState(0);
  const headingRef = useRef<HTMLParagraphElement>(null);
  const [animate, setAnimate] = useState(false);

  
  const time = difference/80;

  useEffect(() => {
   
    if (children && headingRef.current) {
      const diff = headingRef.current.scrollWidth - headingRef.current.clientWidth;
      setDifference(diff);

      const isAlreadyAnimated = !isClassName && IdsToAnimate.includes(id);
      if (diff > 0 && animateFirstTime && !isAlreadyAnimated) {
        !isClassName && IdsToAnimate.push(id);
        setTimeout(() => {
          setAnimate(true);
        }, 500);
        setTimeout(() => {
          setAnimate(false);
        }, 4000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headingRef.current?.scrollWidth, children]);

  useEffect(()=>{
    if(animateName !== undefined) setIsHovering(animateName);
  },[animateName])

  const commonStyles: CSSProperties = {
    position:"relative",
    left:isHovering || animate ? -difference : "0px",  
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: isHovering || animate ? "unset":"ellipsis",
    transition: `left ${time}s linear, width ${time}s linear`,
    width: isHovering || animate ? headingRef.current?.scrollWidth: elementStyles?.width ? elementStyles.width:"100%"
  }
  let Element;
  switch(tag){
    case 'p':
      Element = <p
        {...props}
        ref={headingRef}
        onMouseEnter={() => animateName === undefined && setIsHovering(true)} 
        onMouseLeave={() => animateName === undefined && setIsHovering(false)} 
        style={{...elementStyles, ...commonStyles}}
        className={elementClassName}
      >
        {children}
      </p>;
      break;
    case 'h3':
      Element = <h3
        {...props}
        ref={headingRef}
        onMouseEnter={() => setIsHovering(true)} 
        onMouseLeave={() => setIsHovering(false)} 
        style={{...elementStyles, ...commonStyles}}
        className={elementClassName}
      >
        {children}
      </h3>;
      break;
    default:
      Element = null
  }

  if(!Element){
    return <></>;
  }
  
  return (
    <div style={{...(containerStyles ? containerStyles : {width:"100%"}), overflow:"hidden" }} >
      {Element}
    </div>
  )
}

export default ScrollingText
