import { FC, useMemo } from "react"

interface Props {
    questions: any;
    tab: number;
    onTabChange: (tab: number) => void;
    threshold: number
}

const ReportTabs: FC<Props> = (props) => {
    const {questions, tab, onTabChange, threshold} = props;

    const reportTabs = useMemo(() => {
		const tabs = []
		if (!!questions.length) {
			const totalTabs = Math.ceil(questions.length / threshold)

			for (let i = 0; i < totalTabs; i++) {
				tabs.push(
					<>
						<input
							type="radio"
							className="report-switch-radio"
							id={`radio-${i + 1}`}
							name="report"
							checked={tab === i}
							onChange = {()=>{}}
							onClick={(e: any) => onTabChange(i)}
						/>
						<label tabIndex={0} onKeyDown={(e) => {
							if (e.key === 'Enter') {
								onTabChange(i)
							}
						}} htmlFor={`radio-${i + 1}`} className="report-switch-tab">
							{`
								${(i * threshold)+1}-${i * threshold + threshold
								||
								questions[(questions.length) - 1]?.objective
								}
							`}
						</label>
					</>
				)
			}
		}
		return tabs
	}, [questions, tab, onTabChange, threshold])

    return (
        <>
        {reportTabs.length > 1 && (
            <div className="report-switch sm animated-fade">
                {reportTabs}
                <span className="report-switch-slider sm"></span>
            </div>
        )}
        </>
    )

}

export default ReportTabs