import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import { SupportTicketSuperAdminContext } from "contexts/supportTicketSuperAdmin";
import { TYPE } from "interfaces/supportTicketSuperAdmin";
import moment from "moment";
import { useContext, useRef, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { getStatuses } from "util/mappers";
import { useOnHoverOutside } from "hooks/useOnHoverOutside";

const SupportTicketDetailsInfo = () => {
  const { ticketDetails, updateSupportTicketStatus } = useContext(
    SupportTicketSuperAdminContext
  );
  const { details, loading } = ticketDetails || {};
  const {
    assigned_to = "",
    classrooms = [],
    district_id,
    school_id,
    from = "",
    from_archived,
    created_date = "",
    summary = "",
    school = "",
    status = "",
    district = "",
    statuses = [],
  } = details || {};
  const formattedDate = created_date
    ? moment(created_date).format("M-D-YY h:mm A")
    : "";
  const formattedStatuses = getStatuses(statuses);
  const [submitted = "", needMoreInfo = "", inProgress = "", resolved = ""] =
    formattedStatuses;
  const [SUBMITTED, NEEDMOREINFO, INPROGRESS, RESOLVED] = statuses;
  const noOfClasses = classrooms.length;
  // const [classesDropdownHeight, classesDropdownTop] = classrooms?.length === 1 ? ['auto', -42] : classrooms?.length === 2 ? ['auto', -73] : classrooms?.length >= 3 ? [103, -104] : [43, -60];

  const [isMenuDropDownOpen, setMenuDropDownOpen] = useState<Boolean>(false);
  const classesRef = useRef<any>(null!);

  // const navigate = useNavigate();

  const onClickStatus = (statusSelected: string) => {
    if (status !== statusSelected) {
      updateSupportTicketStatus({
        type: TYPE.STATUS,
        status: statusSelected,
      });
    }
  };

  const closeHoverMenu = () => {
    setMenuDropDownOpen(false);
  };

  useOnHoverOutside(classesRef, closeHoverMenu);

  return (
    <div className="SupportTicketDetails__infoTable">
      <table>
        <thead className="SupportTicketDetails__infoTable__header">
          <tr>
            <td className="px-4 detailsColumn">Details</td>
            <td className="px-4 admin-info">Admin Info</td>
            <td className="pe-4 check-status">Status (Click to Change)</td>
            <td className="px-4 assign-to">Assigned To</td>
          </tr>
        </thead>
        <tbody className="SupportTicketDetails__infoTable__body">
          {loading ? (
            <TableSkeleton columns={4} count={3} height={35} />
          ) : (
            <tr>
              <td className="px-4 pt-3 pb-2 detailsColumn">
                <div className="mb-1 d-flex">
                  <span>Created:</span>
                  <div className="ticket-details-column-text ellipsis-animated ms-2">
                    {" "}
                    <p
                      className={`ellipsis-animated-text overflow ${
                        formattedDate.length > 25
                          ? "animated ellipsis-animation"
                          : ""
                      }`}
                    >
                      {formattedDate}
                    </p>
                  </div>
                </div>
                <div className="mb-1 d-flex">
                  <span>From:</span>
                  <div className="ticket-details-column-text ellipsis-animated ms-2 ">
                    <p
                      className={`ellipsis-animated-text overflow d-flex align-items-center ${
                        from.length > 25 ? "animated ellipsis-animation" : ""
                      }`}
                    >
                      {from}

                      {from_archived && (
                        <span className="deleteBtn">
                          <span
                            onClick={() =>
                              window.open(
                                `/management/district/${district_id}?district_name=${district}`,
                                "_blank"
                              )
                            }
                            className="btn"
                          >
                            Deleted Admin
                          </span>
                        </span>
                      )}
                    </p>
                  </div>
                </div>
                <div className="mb-1 d-flex">
                  <span>Summary:</span>
                  <div className="ticket-details-column-text ellipsis-animated ms-2">
                    {" "}
                    <p
                      className={`ellipsis-animated-text overflow ${
                        summary.length > 25 ? "animated ellipsis-animation" : ""
                      }`}
                    >
                      {summary}
                    </p>
                  </div>
                </div>
              </td>
              <td className="px-4  pt-3 pb-2 admin-info">
                <div>
                  <span className="ellipsis-animated admin-info-w">
                    <p
                      // onClick={() => school_id && navigate(`/management/district/${school_id}`)}
                      onClick={() =>
                        school_id &&
                        window.open(`/management/school/${school_id}`, "_blank")
                      }
                      className={`cursor-pointer mb-1 fw-bold ellipsis-animated-text ${
                        school.length > 20 ? "animated ellipsis-animation" : ""
                      } text-left`}
                    >
                      {school}
                    </p>
                  </span>
                  <span className="ellipsis-animated admin-info-w">
                    <p
                      // onClick={() => district_id && navigate(`/management/district/${district_id}`)}
                      onClick={() =>
                        !!district_id &&
                        window.open(
                          `/management/district/${district_id}`,
                          "_blank"
                        )
                      }
                      className={`cursor-pointer mb-1 fw-bold ellipsis-animated-text ${
                        district.length > 20
                          ? "animated ellipsis-animation"
                          : ""
                      } text-left`}
                      style={{
                        pointerEvents: !district_id ? "none" : "auto",
                      }}
                    >
                      {district}
                    </p>
                  </span>
                  <div className="position-relative">
                    <span
                      className="ellipsis-animated admin-info-w"
                      ref={classesRef}
                    >
                      <p
                        // onClick={() =>  classrooms.length === 1 && classrooms[0].id && navigate(`/management/class/${classrooms[0].id}`)}
                        onClick={() =>
                          classrooms.length === 1 &&
                          classrooms[0]?.id &&
                          window.open(
                            `/management/class/${classrooms[0]?.id}`,
                            "_blank"
                          )
                        }
                        className={`mb-1 fw-bold ellipsis-animated-text text-left`}
                        style={{
                          cursor: noOfClasses === 1 ? "pointer" : "default",
                        }}
                        onMouseOver={() =>
                          classrooms.length >= 1 && setMenuDropDownOpen(true)
                        }
                      >
                        {noOfClasses === 0
                          ? "No Classes"
                          : noOfClasses === 1
                          ? `${noOfClasses} Class`
                          : `${noOfClasses} Classes`}
                      </p>

                      {isMenuDropDownOpen &&
                        classrooms?.length > 0 &&
                        !!classrooms[0]?.id && (
                          // <div className="classes-tooltip" style={{ height: classesDropdownHeight, top: classesDropdownTop}}>
                          <div
                            className="classes-tooltip"
                            style={{
                              maxHeight: "90px",
                              width: "160px",
                              top: noOfClasses ===1 ? "-43px":noOfClasses ===2 ?"-70px" : "-90px" ,
                              position: "absolute",
                              backgroundColor: "#2a2a2acc",
                              overflow: "auto",
                              backdropFilter: "blur(2px)",
                              padding: "8px 10px",
                              zIndex: 9999,
                            }}
                          >
                            {
                              <div className="ticket-details-btn-container">
                                {classrooms.map((classroom: any) => {
                                  console.log("classroom: ", classroom);
                                  return (
                                    !!classroom?.id && (
                                      <button
                                        onClick={() =>
                                          window.open(
                                            `/management/class/${classroom.id}`,
                                            "_blank"
                                          )
                                        }
                                        type="button"
                                        className="chatBox__centerBtns__btn"
                                        style={{
                                          border: "none",
                                          width: "145px",
                                          padding: "4px 0",
                                          fontSize: "14px",
                                          fontWeight: 700,
                                          color: "#fff",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {classroom?.name || ""}
                                       
                                      </button>
                                    )
                                  );
                                })}
                              </div>
                            }
                          </div>
                        )}
                    </span>
                  </div>
                </div>
                {/* 
                        {
                            district.length > 0 && (
                                <button onClick={() => window.open(`/management/district/${district_id}`, '_blank')} type="button" className="chatBox__centerBtns__btn">
                                    {
                                        district
                                    }
                                </button>
                            )

                        }

                        {
                            school.length > 0 && school_id && (
                                <button onClick={() => window.open(`/management/school/${school_id}`, '_blank')} type="button" className="chatBox__centerBtns__btn school-name">
                                    {
                                        school
                                    }
                                </button>
                            )
                        } */}

                {/* {
                            classCount === 1 && classrooms[0].id ? (
                                <button 
                                    type="button" 
                                    className="chatBox__centerBtns__btn"
                                    onClick={() => window.open(`/management/class/${classrooms[0].id}`, '_blank')}
                                >
                                    {classCount + ' Class'}
                                </button>
                            
                            ) : (
                                <button 
                                    type="button" 
                                    className="chatBox__centerBtns__btn"
                                    onMouseOver={() => setMenuDropDownOpen(true)}
                                >
                                    {classCount + ' Classes' }
                                </button>
                            )
                        } */}
              </td>
              <td className="pe-4 pt-3 pb-4 d-flex">
                <div className="">
                  <p
                    style={{ cursor: "pointer", backgroundColor:status===SUBMITTED ?"#EADB4A":"", color: status===SUBMITTED?"#424242":"" }}
                    onClick={() => onClickStatus(SUBMITTED)}
                    className={`mb-0 tags`}
                  >
                    {submitted}
                  </p>
                  <p
                    style={{ cursor: "pointer" , backgroundColor:status===INPROGRESS?"#194792":"" }}
                    onClick={() => onClickStatus(INPROGRESS)}
                    className={`mb-0 tags`}
                  >
                    {inProgress}
                  </p>
                </div>
                <div className="ms-4">
                  <p
                    style={{ cursor: "pointer", backgroundColor:status===NEEDMOREINFO?"#c44538":"" }}
                    onClick={() => onClickStatus(NEEDMOREINFO)}
                    className={`mb-0 tags`}
                  >
                    {needMoreInfo}
                  </p>

                  <p
                    style={{ cursor: "pointer", backgroundColor: status=== RESOLVED ? "#46afb8":"" }}
                    onClick={() => onClickStatus(RESOLVED)}
                    className={`mb-0 tags resolved`}
                    
                  >
                    {resolved}
                  </p>
                </div>
              </td>
              
              <td className="px-4 align-top pt-3 pb-2 assign-to">
                <p className="mb-1">{assigned_to}</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SupportTicketDetailsInfo;
