import { useMemo } from "react"
import { useSearchParams } from "react-router-dom";

export const useCheckGoalTooltipExists = () => {
    const [searchParams] = useSearchParams();
    const isGoalTooltip = useMemo(() => {
        return searchParams.get("openMasterReport") === 'true' ||
        searchParams.get("summativeA1MarkCompleted") === 'true' ||
        searchParams.get("summativeBMarkCompleted") === 'true' ||
        searchParams.get("summativeA2AvailableToggle") === 'true' ||
        searchParams.get("summativeA2MarkCompleted") === 'true' ||
        searchParams.get("summativeBAvailabilityToggle") === 'true' ||
        searchParams.get("studentAvailableTooltip") === 'true'
    },[searchParams]);

    return isGoalTooltip;
}