import APLUS from 'assets/img/A-plus.png';
import TooltipTopArrow from 'assets/img/tooltip-top-arrow.png';
import TooltipLeftArrow from 'assets/img/tooltip-left-arrow.png';
import TooltipRightArrow from 'assets/img/tooltip-right-arrow.png';
import TooltipBottomArrow from 'assets/img/tooltip-bottom-arrow.png';
import HtmlParser from './htmlParser';
import { useContext, useEffect } from 'react';
import { ClassroomTooltipContext } from 'contexts/classroomTooltip';
import { useLocation } from 'react-router-dom';

const ClassroomTooltip = ({message, className, arrowDirection, customStyle}: any) => {
    const {tooltip} = useContext(ClassroomTooltipContext);
    const {pathname} = useLocation();

    useEffect(() => {
        if((tooltip === 4 || tooltip === 5) && pathname === '/classroom'){
            document.getElementById("tooltip-continer")?.scrollIntoView()
        }

        if((tooltip === 1 || tooltip === 2 || tooltip === 3) && pathname === '/classroom'){
            window.scrollTo(0,0)
        }

        if(tooltip === 4 && pathname === '/activity'){
            window.scrollTo(0, document.body.scrollHeight);
        }
        
        /* eslint-disable react-hooks/exhaustive-deps */
    },[tooltip])
    return (
        <div id="tooltip-div" style={customStyle} className={`${className} cursor-pointer`}>
            <div className='toolTip-body'>
                <div className='toolTip-arrow'>
                    {arrowDirection === 'left' && (
                        <img src={TooltipLeftArrow} alt='A-Plus' />
                    )}

                    {arrowDirection === 'right' && (
                        <img src={TooltipRightArrow} alt='Arrow' />
                    )}
                    {!arrowDirection && (
                        <img src={TooltipTopArrow} alt="Arrow" />
                    )}
                    {arrowDirection === 'bottom' && (
                        <img src={TooltipBottomArrow} alt="Arrow" />
                    )}
                    
                </div>
                <div className='aplus-img'>
                    <img src={APLUS} alt='A-Plus' />
                </div>
                <p>
                    <HtmlParser html={message} />
                </p>
            </div>
        </div>
    )
}

export default ClassroomTooltip;