import config from "config/config";
import cookie    from "js-cookie";
import { AppCookies } from "util/constants";

export const downloadAuditReportFile = async () => {
  try {
    console.log(config.defaults, )
    return fetch(`${config.defaults.api_url}/api/v3/admin-reports/audit-report/download`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${cookie.get(AppCookies.token)}`,
            apiKey: config.defaults.api_key,
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
        })
        .then((response) => response.blob())
        .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
            new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
            'download',
            `Audit-Report.xlsx`,
        );
    
        // Append to html link element page
        document.body.appendChild(link);
    
        // Start download
        link.click();
    
        // Clean up and remove the link
        link.remove();
    });
  } catch (e) {
    console.log(e);
  }
};
