import axios from "util/axios";
import config from "config/config";

export const updateMessageSeen = async (messageId: number) => {
  try {
    let { data: { data } } = await axios.put(`${config.defaults.api_url}/api/v3/support/ticket/message/${messageId}`)
    return data

  } catch (error) {
    console.log("Error in [getSupportTickets] : ", error)
    return null
  }
}

export const putMessageSeen = async (id: number) => {
  try {
    let { data: { data } } = await axios.put(`${config.defaults.api_url}/api/v3/support/ticket/message/${id}`)
    return data

  } catch (error) {
    console.log("Error in [getSupportTickets] : ", error)
    return null
  }
}

export const getMessages = async ({id, search, offset, limit}: {id: number, search?: string, offset: number, limit: number}) => {
  try {
    let { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/support/ticket/${id}/messages?offset=${offset}&limit=${limit}${search ? `&search=${search}`: ''}`)
    return data

  } catch (error) {
    console.log("Error in [getSupportTickets] : ", error)
    return null
  }
}

export const postChat = async (body: {message: string}) => {
  try {
    let { data: { data } } = await axios.post(`${config.defaults.api_url}/api/v3/support/chat`, body)
    return data

  } catch (error) {
    console.log("Error in [getSupportTickets] : ", error)
    return null
  }
}

export const saveTicket = async (data: any) => {
  try {
    let response = await axios.post(
      `${config.defaults.api_url}/api/v3/tickets`,
      data,
      {
        headers: {
          apiKey: config.defaults.api_key!
        }
      }
    );
    return response.data
  } catch (e) {
    console.log("Error in [saveTicket] : ", e)
  }
};

export const saveContactSupportTicket = async (data: any) => {
  try {
    let response = await axios.post(
      `${config.defaults.api_url}/api/v3/support/ticket`,
      data,
      {
        headers: {
          apiKey: config.defaults.api_key!
        }
      }
    );
    return response.data
  } catch (e) {
    console.log("Error in [saveTicket] : ", e);
    return null
  }
};

export const getSupportChats = async (limit: number = 10, offset: number = 1, chatId?: number) => {
  try {
    let { data: { data } } = await axios.get(`
    ${config.defaults.api_url}/api/v3/support/chat?limit=${limit}&offset=${offset*limit}${chatId ? `&chat_id=${chatId}` : ''}
    `)
    return data

  } catch (error) {
    console.log("Error in [getSupportTickets] : ", error)
    return null
  }
}

export const getSupportTickets = async (limit: number = 50, offset: number = 0) => {
  try {
    let { data: { data } } = await axios.get(`
    ${config.defaults.api_url}/api/v3/support/ticket?limit=${limit}&offset=${offset}
    `)
    return data

  } catch (error) {
    console.log("Error in [getSupportTickets] : ", error)
    return null
  }
}

export const getSingleSupportTicket = async (ticketId: number) => {
  try {
    let { data: { data } } = await axios.get(`
    ${config.defaults.api_url}/api/v3/support/ticket/${ticketId}/messages
    `)
    return data
  } catch (error) {
    console.log("Error in [getSingleSupportTicket]  : ", error)
    return null
  }
}

export const addReplyToTicket = async (data: any) => {
  const { ticketId, ...body } = data;
  try {
    let { data: { data } } = await axios.put(`
    ${config.defaults.api_url}/api/v3/support/ticket/${ticketId}
    `, body)
    return data
  } catch (error) {
    console.log("Error in [addReplyToTicket]  : ", error)
    return null
  }
}

export const getMasteryVideos = async () => {
  try {
    let { data: { data } } = await axios.get(`${config.defaults.api_url}/api/v3/mastery/videos`)
    return data
  } catch (error) {
    console.log("Error in [getMasteryVideos]  : ", error)
    return null
  }
}

export const uploadImageToS3 = async (payload: any) => {
  try {
    let { data: { data } } = await axios.post(
      `${config.defaults.api_url}/api/v3/tickets/images`, payload
      , {
        headers: {
          "content-type": "multipart/form-data",
        }
      }
    )

    return data
  } catch (error) {
    console.log("Error in [uploadImageToS3]  : ", error)
    return null
  }
}