import React from "react";

const TrashIcon = ({fill}:{fill: string}) => {
  const titleId = "deleteIcon"
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.849"
      height="21.968"
      viewBox="0 0 17.849 21.968"
      aria-labelledby={titleId}
    >
      <title id={titleId}>Delete</title>
      <g
        id="trash_icon"
        data-name="trash icon"
        transform="translate(-1182 -1065.999)"
      >
        <path
          id="Vector"
          d="M16.133,2.746H12.357V2.06A2.062,2.062,0,0,0,10.3,0H7.552a2.062,2.062,0,0,0-2.06,2.06v.687H1.716A1.718,1.718,0,0,0,0,4.462v2.4a.687.687,0,0,0,.687.687h.375l.593,12.455a2.057,2.057,0,0,0,2.057,1.962H14.137a2.057,2.057,0,0,0,2.057-1.962l.593-12.455h.375a.687.687,0,0,0,.687-.687v-2.4A1.718,1.718,0,0,0,16.133,2.746ZM6.865,2.06a.687.687,0,0,1,.687-.687H10.3a.687.687,0,0,1,.687.687v.687H6.865Zm-5.492,2.4a.344.344,0,0,1,.343-.343H16.133a.344.344,0,0,1,.343.343V6.179H1.373Zm13.45,15.479a.686.686,0,0,1-.686.654H3.712a.686.686,0,0,1-.686-.654l-.59-12.39H15.413Z"
          transform="translate(1182 1065.999)"
          fill={fill}
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M6.225,16.3a.687.687,0,0,0,.687-.687V6.687a.687.687,0,0,0-1.373,0v8.925A.687.687,0,0,0,6.225,16.3Z"
          transform="translate(1184.7 1068.923)"
          fill={fill}
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M8.533,16.3a.687.687,0,0,0,.687-.687V6.687a.687.687,0,1,0-1.373,0v8.925A.687.687,0,0,0,8.533,16.3Z"
          transform="translate(1185.825 1068.923)"
          fill={fill}
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M3.917,16.3a.687.687,0,0,0,.687-.687V6.687a.687.687,0,1,0-1.373,0v8.925A.687.687,0,0,0,3.917,16.3Z"
          transform="translate(1183.575 1068.923)"
          fill={fill}
        />
      </g>
    </svg>
  );
};

export default TrashIcon;
