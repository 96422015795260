const SurveySkeleton = ({type}:{type: string}) => {
    return (
        <>
        {type === "OPEN" ? ( 
            <div className="skeleton-container">
                <div className="ques-ans-sec">
                    <p className="ques skeleton"></p>
                    <div className="textarea-skeleton skeleton">
                        
                    </div>
                </div>
            </div>
        ):(
            
            <div className="ques-body-container skeleton-container">
                <div className="quiz-container">
                    <p className="survey-ques skeleton"></p>
                    <div className="ans-container">
                    <div className="quiz-labels">
                        <div className="radio-col-1 skeleton"></div>
                        <div className="radio-col-1 skeleton"></div>
                        <div className="radio-col-1 skeleton"></div>
                        <div className="radio-col-1 skeleton"></div>
                        <div className="radio-col-1 skeleton"></div>
                        <div className="radio-col-1 skeleton"></div>
                        <div className="radio-col-1 skeleton"></div>
                        <div className="radio-col-1 skeleton"></div>
                        <div className="radio-col-1 skeleton"></div>
                    </div>
                    <div className="ans-quiz">
                        <div className="option">
                            <div className="radio-col-2 skeleton"></div>
                            <div className="radio-col-3 skeleton"></div>
                            <div className="radio-col-4 skeleton"></div>
                            <div className="radio-col-5 skeleton"></div>
                            <div className="radio-col-6 skeleton"></div>
                        </div>
                        <div className="option">
                            <div className="radio-col-2">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-3">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-4">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-5">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-6">
                                <span className="skeleton"></span>
                            </div>
                        </div>
                        <div className="option">
                            <div className="radio-col-2">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-3">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-4">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-5">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-6">
                                <span className="skeleton"></span>
                            </div>
                        </div>
                        <div className="option">
                            <div className="radio-col-2">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-3">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-4">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-5">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-6">
                                <span className="skeleton"></span>
                            </div>
                        </div>
                        <div className="option">
                            <div className="radio-col-2">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-3">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-4">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-5">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-6">
                                <span className="skeleton"></span>
                            </div>
                        </div>
                        <div className="option">
                            <div className="radio-col-2">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-3">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-4">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-5">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-6">
                                <span className="skeleton"></span>
                            </div>
                        </div>
                        <div className="option">
                            <div className="radio-col-2">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-3">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-4">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-5">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-6">
                                <span className="skeleton"></span>
                            </div>
                        </div>
                        <div className="option">
                            <div className="radio-col-2">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-3">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-4">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-5">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-6">
                                <span className="skeleton"></span>
                            </div>
                        </div>
                        <div className="option">
                            <div className="radio-col-2">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-3">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-4">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-5">
                                <span className="skeleton"></span>
                            </div>
                            <div className="radio-col-6">
                                <span className="skeleton"></span>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        )}
        </>
    )
};

export default SurveySkeleton;
