import Tooltip from 'components/common/Tooltip'
import { isNumber } from 'lodash'
import { FC, useMemo } from 'react'

interface Props {
    showScore: boolean;
    showClassColumn: number;
    classroomInfo: any;
    index: number;
    display: string
}

const ScoreBox: FC<Props> = (props) => {
    const { showScore, classroomInfo, showClassColumn, index, display } = props

    // this need to be reviewed
    const proficiency  = classroomInfo?.proficiency

    const proficiencyLevel = useMemo(() => {
        if (proficiency >= 70)
            return "proficient"
        else if (proficiency <= 33)
            return "non-proficient"
    }, [proficiency])

    return (<Tooltip display={!showScore} content={proficiency}>
        <td data-tip={proficiency} className={`summative-objective-analysis__table-data ${display}`}>
            <span className={`summative-objective-analysis__table-numbers ${showClassColumn !== null && showClassColumn !== index ? 'hidden' : ''} ${proficiencyLevel}`}>
                {showScore && (isNumber(proficiency) ?  proficiency :  '-')}
            </span>
        </td>
    </Tooltip>
    )
}

export default ScoreBox;
