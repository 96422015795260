import { useState } from "react";
import SatisfactionSurvey from "./satisfactionSurvey";
import SuccessModal from "components/common/successModal";

const FeedbackSurvey = ({satisfactionSurvey, closeModal}: {satisfactionSurvey: boolean, closeModal: Function}) => {
    const [feedbackSurvey, setFeedbackSurvey] = useState(satisfactionSurvey);
    const [successModal, setSuccessModal] = useState(false);

    return (
        <>
            {feedbackSurvey && (
                <SatisfactionSurvey
                    closeModal={() => closeModal()}
                    onSubmit={() => {
                        setFeedbackSurvey(false);
                        setSuccessModal(true);
                    }}
                />
            )}
            {successModal && (
                <SuccessModal
                    isShow
                    headerText="Thank You"
                    bodyText="Your feedback helps us improve Alpha Plus Online for 2024-25."
                    closeModal={() => {
                        closeModal();
                        setSuccessModal(false);
                    }}
                    buttonText="Great"
                    btnCustomClass="great-btn"
                />
            )}
        </>
    )
};

export default FeedbackSurvey;
