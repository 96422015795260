import { useMemo } from "react";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMinus } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import PrintFooter from "components/common/report/Print/footer";
import moment from "moment";
import Header from "components/common/report/Print/header";
import { isSafari, isChrome, browserHeights } from "util/index";
import { useSchoolName } from "hooks/useSchoolName";
import { AppCookies } from "util/constants";

library.add(faCheck);

const AnswerMap = {
    0: "A",
    1: "B",
    2: "C",
    3: "D",
};

interface IPrint {
    report: any;
    dropDownLabel: string;
}

const ItemResponseReport = ({ report, dropDownLabel }: IPrint) => {
    const questionsPerPage = 25;

    const getStudentsPerPage = () => {
        if (isSafari)
            return 26
        if (isChrome)
            return 36

        return 34
    }

    const studentsPerPage = getStudentsPerPage();

    const test_name = Cookies.get(AppCookies.test_name) || "";

    const school_name = useSchoolName();

    const { students, questions, class_name } = useMemo(() => {

        if (!report?.data) {
            return {
                students: [],
                questions: [],
                class_name: "",
            };
        }
        const students = report.data.students.sort((a: any, b: any) => {
            return a.last_name.localeCompare(b.last_name)
        })

        return {
            students: students,
            questions: report.data.questions,
            class_name: report.data.class_name,
        };
    }, [report]);
    const totalTabs = Math.ceil(questions.length / questionsPerPage);

    const contentGenerator = useMemo(() => {
        let toPrint: any;

        if (!students) {
            return;
        }

        return Array.from(Array(totalTabs).keys()).map((_, tabIndex) => {
            toPrint = [];

            const slicedQuestions = questions?.slice(
                tabIndex * questionsPerPage,
                tabIndex * questionsPerPage + questionsPerPage
            );

            for (
                let startIndex = 0,
                endIndex = studentsPerPage;
                startIndex === 0 || endIndex <= students.length;
            ) {
                const slicedStudents = students.slice(startIndex, endIndex);

                toPrint.push(
                    <div className={` ${(tabIndex || startIndex) && "next_page_break"} irr-summative-report-print`}>
                        <div className="irr-print-mozilla">
                            <Header
                                test_name={test_name}
                                class_name={class_name}
                                school_name={school_name}
                                title="Item Response Report"
                                customClass="irr-print"
                            />
                            <div className={`${browserHeights} report-item-container`}>
                                <table className="report__itemTable">
                                    <tbody>
                                        <div>
                                            <div
                                                className="report__numbers irr"
                                                style={{ marginTop: 31 }}
                                            >
                                                <div className="report__numbers-name">
                                                    <p>Student Name</p>
                                                </div>

                                                <div className="report__numbers-container print-irr-summative">
                                                    {slicedQuestions.map((q: any, i: number) => (
                                                        <span className={`report__numbers-count`}>
                                                            {tabIndex * questionsPerPage + i + 1}
                                                        </span>
                                                    ))}
                                                </div>

                                                <div className="report__numbers-attempt">
                                                    <span>{dropDownLabel}</span>
                                                </div>

                                                <div className="report__numbers-score irr-print-score">
                                                    <span>
                                                        (# Correct) <br /> Score
                                                    </span>
                                                </div>
                                            </div>
                                            {slicedStudents?.map((student: any, index: number) => {
                                                const { attempts, last_name, first_name } = student;
                                                return attempts.map((attempt: any, attemptNumber: number) => {
                                                    const { answers, calculation, test_date } = attempt
                                                    return (
                                                        <tr key={index}>
                                                            <td className="report__itemTable-name">
                                                                {last_name + ", " + first_name}
                                                            </td>
                                                            <div>
                                                                {slicedQuestions.map((question: any, i: number) => {
                                                                    const Answer = answers?.find(
                                                                        (singleAnswer: any) =>
                                                                            singleAnswer?.question_id ===
                                                                            question?.question_id
                                                                    );

                                                                    if (Answer?.response) {
                                                                        return (
                                                                            <td
                                                                                className="report__itemTable-numbers"
                                                                                key={i}
                                                                            >
                                                                                {Answer.info !== "correct" && (
                                                                                    <span
                                                                                        className={`print__itemTable-rectangle report__itemTable-rectangle`}
                                                                                    >
                                                                                        {
                                                                                            AnswerMap[
                                                                                            Answer.response.trim() as keyof typeof AnswerMap
                                                                                            ]
                                                                                        }
                                                                                    </span>
                                                                                )}
                                                                                {Answer?.info === "correct" && (
                                                                                    <span
                                                                                        className={`print__itemTable-check report__itemTable-rectangle`}
                                                                                    >
                                                                                        <FontAwesomeIcon
                                                                                            className="font-icon-tick"
                                                                                            name="Check"
                                                                                            icon={faCheck}
                                                                                        />
                                                                                    </span>
                                                                                )}
                                                                            </td>
                                                                        );
                                                                    }
                                                                    return (
                                                                        <td className="report__itemTable-numbers" key={i}>
                                                                            <span
                                                                                className={`print__itemTable-dash report__itemTable-rectangle`}
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    name="Minus"
                                                                                    icon={faMinus}
                                                                                />
                                                                            </span>
                                                                        </td>
                                                                    );
                                                                })}
                                                            </div>
                                                            <td className="report__itemTable-attempt">
                                                                <span>
                                                                    {dropDownLabel === "Attempt"
                                                                        ? `${calculation?.attempt}`
                                                                        : (test_date && test_date.length && moment(test_date).format("M-D-YY")) || ''
                                                                    }
                                                                </span>
                                                            </td>

                                                            <td className="report__itemTable-score">
                                                                <span>{`(${calculation?.total_correct}) ${calculation?.score_percentage}%`}</span>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            })}
                                            <div className="report__total ">
                                                <div className="report__total-title">Correct Answer</div>
                                                <div className="report__total-count">
                                                    {slicedQuestions.map((question: any, index: number) => (
                                                        <span
                                                            key={index}
                                                            className={`report__total-total items-print answer correct-bold`}
                                                        >
                                                            {
                                                                AnswerMap[
                                                                question.answer as keyof typeof AnswerMap
                                                                ]
                                                            }
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className="report__total">
                                                <div className="report__total-title">Total Students</div>
                                                <div className="report__total-count">
                                                    {slicedQuestions.map((question: any, index: number) => (
                                                        <span key={index} className={`report__total-total`}>
                                                            {question.total_students}
                                                        </span>
                                                    ))}

                                                </div>
                                            </div>

                                            <div className="report__total">
                                                <div className="report__total-title">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <span className="report__total-rectangle print__itemTable-check">
                                                            <FontAwesomeIcon
                                                                className="font-icon-tick correcticon-tick"
                                                                name="Check"
                                                                icon={faCheck}
                                                            />
                                                        </span>
                                                        <span>Total Correct</span>
                                                    </div>
                                                </div>
                                                <div className="report__total-count">
                                                    {slicedQuestions.map((question: any, index: number) => (
                                                        <span key={index} className={`report__total-total`}>
                                                            {question.total_correct}
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className="report__total">
                                                <div className="report__total-title incorrect">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <span className="report__total-rectangle print__itemTable-rectangle"></span>
                                                        <span>Total Incorrect</span>
                                                    </div>
                                                </div>
                                                <div className="report__total-incorrect">
                                                    {slicedQuestions.map((question: any, index: number) => (
                                                        <div key={index} className="report__total-incorrectContainer">
                                                            <span className={`report__total-total`}>
                                                                {question.total_wrong}
                                                            </span>
                                                            <span className={`report__total-objective`}>
                                                                <span className={`report__total-objectiveData`}>
                                                                    {question.objective}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                            <PrintFooter />
                        </div>
                    </div>
                );

                startIndex = endIndex;
                endIndex += Math.min(
                    Math.max(1, students.length - endIndex),
                    studentsPerPage
                );
            }

            return toPrint;
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [report, totalTabs, dropDownLabel]);

    return (
        <div className="report print">
            {contentGenerator}
        </div>
    );
};

export default ItemResponseReport;
