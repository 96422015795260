const InfoSection = ({ level }: any) => {
  console.log(12, level);
  return (
    <div className="risk-report__info">
      <p className="risk-report__info-text">
        The Standards at Risk report shows you the OAS Objectives on which less
        than 70% of students scored proficient. Each question on the summative
        is related to an OAS Objective. If the student performed below{" "}
        {level?.P || 70}% on the questions aligned to the objective, they are
        considered non-proficient for that objective.
      </p>
    </div>
  );
};
export default InfoSection;
