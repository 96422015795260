import InfoSection from "./infoSection";
import TableHeader from "./Table/header";
import Record from "./Table/body";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getStudentSummativeReport,
  studentSummativeReportSelector,
} from "redux/actionCreators/classroom";
import ReportHeader from "components/common/report/header";
import SearchIconGray from "assets/img/search-icon-gray.svg";
import SummativeStudentsPrint from "./Print";
import { AuditReportBodySkeleton } from "components/common/skeleton/ReportsSkeleton";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { useQuery } from "hooks/useQuery";
import { RootState } from "redux/reducers/combine";
import { replaceURL } from "util/replaceUrl";
import useClassroomMasterReport, {
  getSummativeId,
} from "hooks/useClassroomMasterReport";

const SummativeStudentsReport = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState<string>("");
  const { summativeId } = useParams();
  const classroom_id = useSelector(
    (state: RootState) => state.classroom.classroomId
  );

  const dispatch = useDispatch();
  const report = useSelector(studentSummativeReportSelector);
  const navigate = useNavigate();
  const componentRef = useRef(null);

  const query = useQuery();
  const print = query.get("print");

  const { masterReport } = useClassroomMasterReport();
  const fetchedSummativeIdRef = useRef<any>(null);
  const selectedClassroomIdRef = useRef<any>(null);
  const getreport = useCallback((classroomId: any, summativeId: any)=>{
    if (classroomId && summativeId) {
      dispatch(
        getStudentSummativeReport(parseInt(classroomId), parseInt(summativeId), setIsLoading)
      );
    }
  },[dispatch])
  
  useEffect(() => {
    const selectedClassroomId = (window as any)["classroomId"];
    if (selectedClassroomId) {
      selectedClassroomIdRef.current = selectedClassroomId;
      getreport(selectedClassroomId, summativeId);
      replaceURL(
        `/classroom/report/summative/student/${selectedClassroomId}/${summativeId}`
      );   
    }
    
  }, [classroom_id, summativeId, getreport]);

  useEffect(() => {
    const idToFetch = getSummativeId(masterReport);
    if(idToFetch){
    fetchedSummativeIdRef.current = idToFetch;
    selectedClassroomIdRef.current = null;
      setIsLoading(true);
      getreport(classroom_id, idToFetch);
      replaceURL(`/classroom/report/summative/student/${classroom_id}/${idToFetch}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[masterReport, getreport]);

 

  const { students, testName, level } = useMemo(() => {
    if (report) {
      if (search) {
        const { students } = report;
        const filteredStudents = students.filter((student: any) =>
          student.student_name
            .toLowerCase()
            .includes(
              search.toLowerCase() || student.student_id.includes(search)
            )
        );
        return {
          ...report,
          testName: report.test_name,
          students: filteredStudents,
        };
      }
      
      return {
        ...report,
        testName: report.test_name,
      };
    }
   
   
    return {
      students: [],
      testName: "",
    };
  }, [report, search]);

  const onStudentClick = (studentId: number) => {
    
    const classroomId =  selectedClassroomIdRef.current || classroom_id
    const summativeID = fetchedSummativeIdRef.current || summativeId;
    
    navigate(`/classroom/report/summative/student/${classroomId}/${summativeID}/${studentId}`);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `SummativeStudentsReport_${
      testName?.split("-").pop() || ""
    }_${moment(new Date()).format("M/DD/YY hh:mmA")}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      print && window.close();
    },
  });

  useEffect(() => {
    if (report && print) {
      handlePrint();
    }
  }, [report, print, handlePrint]);
  
  
  return (
    <>
      {!print && (
        <div className="SummativeStudentsReport hide-on-print">
          <ReportHeader
            reportTitle={`Student Summative ${
              testName?.split("-").pop() || ""
            } Report`}
            setPrint={handlePrint}
            testName={testName}
            headerClassName="report__transparent_header"
          />
          <div className="SummativeStudentsReport-content">
            <div className="SummativeStudentsReport__uperRow">
              <div className="SummativeStudentsReport__uperRow__centerText">
                <span>
                  Click on a student to review a breakdown of the assessment by
                  question.
                </span>
              </div>
              <div className="SummativeStudentsReport__info">
                <InfoSection level={level} />
              </div>
            </div>

            <div className="SummativeStudentsReport__uperRow__searchbar">
              <div className="SummativeStudentsReport__uperRow__searchbar-container">
                {!isLoading && (
                  <img
                    src={SearchIconGray}
                    alt="search"
                    className="animated-fade"
                  />
                )}
                <input
                  type="text"
                  placeholder="Search Students"
                  className={`form-control ${isLoading ? "margin-left" : ""}`}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            <div className="SummativeStudentsReport__tableContainer">
              <table className="SummativeStudentsReport__table">
                <thead className={`SummativeStudentsReport__table__head`}>
                  <TableHeader />
                </thead>
                <tbody>
                  {!isLoading ? (
                    students.map((student: any, index: number) => {
                      return (
                        <Record
                          student={student}
                          onStudentClick={onStudentClick}
                          index={index}
                        />
                      );
                    })
                  ) : (
                    <AuditReportBodySkeleton rows={10} columns={4} />
                  )}
                  {students.length === 0 && !isLoading && (
                    <tr>
                      <td
                        colSpan={4}
                        className="text-center report__table-notFound"
                      >
                        No Students Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      {students && (
        <div ref={componentRef} className="printData">
          <div className="SummativeStudentsReport">
            <SummativeStudentsPrint
              test_name={testName}
              students={students}
              level={level}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default SummativeStudentsReport;
