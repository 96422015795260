/* eslint-disable react-hooks/exhaustive-deps */

import CustomModal from "components/common/Modal";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";

interface Props {
  show: boolean;
  summativeId?: number;
  formativeId?: number;
  setModalSuggestedShow: any;
  setModalSuggestedAssesment: (show: boolean) => void;
  setSeheduleAssesment: (show: boolean) => void;
  onClickUseSuggestedPacing: Function;
  isCalenderExists?: boolean;
  setSuggestedPacingCopyModal: Function;
}

const SuggestionModel: React.FC<Props> = (props) => {
  const {show, isCalenderExists, setModalSuggestedShow, setSuggestedPacingCopyModal, setModalSuggestedAssesment, setSeheduleAssesment, onClickUseSuggestedPacing } = props;
  // const [, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setModalSuggestedShow(false);
    setSeheduleAssesment(true);
  };

  const onClickPreviewSuggestedPacing = () => {
    setModalSuggestedShow(false);
    setModalSuggestedAssesment(true);
  }

  const onSuggestedPacing = async () => {
    setIsSaving(true);
    const isSuccess = await onClickUseSuggestedPacing();
    setIsSaving(false);

    if(isSuccess){
      setModalSuggestedShow(false);
      setSuggestedPacingCopyModal(true)
    }
  }

  return (
    <>
      <CustomModal centered show={show} contentClassName="PacingCalender__SuggestedModel">
        <div>
          <div>
            <h2 className="modal__header-title px-2">Use Suggested Pacing?</h2>
          </div>
        </div>
        <div className="PacingCalender__SuggestedModel__text">
          <p className="test-sm  ps-2 mt-3">
            The Pacing Calendar allows you to schedule all assessments for the
            year. This will determine the assessment order on the Classroom
            Screen and enables color coding to help you stay on schedule.
            Reports and professional development features also use this
            schedule.
          </p>
          <p className="test-sm  ps-2  mt-3">
            You have the option of using the Alpha Plus suggested pacing or
            starting from scratch and creating a pace that aligns with your
            classroom curriculum. <a href="#/" onClick={onClickPreviewSuggestedPacing} className="fw-bold">Click Here to preview the suggested pacing.</a>
          </p>
        </div>
        <div className="modal__footer flex-wrap justify-content-between">
          <button
            type="button"
            className="btn PacingCalender__SuggestedModel__Createbtn"
            data-bs-dismiss="modal"
            onClick={handleSave}
          >
            Create My Own Pacing
          </button>
          <button
            type="button"
            className={`btn PacingCalender__SuggestedModel__Suggestion ${isCalenderExists && "PacingCalender__SuggestedModel__Pacingdisabled"}`}
            onClick={onSuggestedPacing}
            disabled={isCalenderExists}
          >
            {isSaving ?
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
             : (
              "Use Suggested Pacing"
            )}
          </button>
        </div>
      </CustomModal>
    </>
  );
};

export default SuggestionModel;
