import { useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";

export const useFormulaSheet = () => {
    const {grade} = useSelector((state: RootState) => state.classroom);
    let formulaSheetUrl = '';
    switch(grade){
        case 6:
            formulaSheetUrl = '/G6-Formula-Sheet_2024_ADA_OSTP.pdf';
            break
        case 7:
            formulaSheetUrl = '/G7-Formula-Sheet_2024_ADA_OSTP.pdf';
            break
        case 8:
            formulaSheetUrl = '/G8-Formula-Sheet_2024_ADA_OSTP.pdf';
            break
        default:
            break
    }

    return formulaSheetUrl;
}