import CustomModal from "components/common/Modal";

const FormativeModal = ({children, closeModal}: any) => {
    return (
        <>
            <CustomModal
                show
                centered
                onEscapeKeyDown={closeModal}
                onHide={closeModal}
                contentClassName="formative-modal-content"
            >
                {children}
            </CustomModal>
        </>
    )
};

export default FormativeModal;
