import { RootState } from "redux/reducers/combine";
import { ActivityResponse } from ".";
import Progressbar from "./progressBar";
import { useSelector } from "react-redux";

const AssessmentStats = ({
  loading,
  activityData,
  isThreeDropdowns,
}: {
  loading: boolean;
  activityData?: ActivityResponse;
  isThreeDropdowns: boolean;
}) => {
  const app = useSelector((state: RootState) => state);
  const {
    a1_proficient,
    a2_proficient,
    total_objectives,
    objectives_taken,
    b_proficient,
    proficiency,
  } = activityData || {};
  const hasValue =
    total_objectives !== null &&
    total_objectives !== undefined &&
    objectives_taken !== null &&
    objectives_taken !== undefined &&
    app?.classroom?.grade !== 9;
  const perc = hasValue
    ? Math.ceil((objectives_taken / total_objectives) * 100)
    : 0;

  return (
    <div className="statusBarContainer">
      <Progressbar
        title="Objectives"
        width={perc}
        isDisabled={!hasValue}
        isLoading={loading}
      >
        <p>
          {objectives_taken} / {total_objectives}
        </p>
      </Progressbar>
      <Progressbar
        isProficient={!!proficiency && proficiency >= 70}
        title="Proficiency"
        width={proficiency}
        isDisabled={!proficiency}
        isLoading={loading}
        color="#9080FF"
      >
        <p>{proficiency}%</p>
      </Progressbar>
      <Progressbar
        isProficient={!!a1_proficient && a1_proficient >= 70}
        title={isThreeDropdowns ? "A1 Prof" : "A1 Proficiency"}
        width={a1_proficient}
        isDisabled={!a1_proficient}
        isLoading={loading}
        color="#9080FF"
      >
        <p>{a1_proficient}%</p>
      </Progressbar>
      <Progressbar
        isProficient={!!b_proficient && b_proficient >= 70}
        title={isThreeDropdowns ? "B Prof" : "B Proficiency"}
        width={b_proficient}
        isDisabled={!b_proficient}
        isLoading={loading}
        color="#9080FF"
      >
        <p>{b_proficient}%</p>
      </Progressbar>
      <Progressbar
        isProficient={!!a2_proficient && a2_proficient >= 70}
        title={isThreeDropdowns ? "A2 Prof" : "A2 Proficiency"}
        width={a2_proficient}
        isDisabled={!a2_proficient}
        isLoading={loading}
        color="#9080FF"
      >
        <p>{a2_proficient}%</p>
      </Progressbar>
    </div>
  );
};

export default AssessmentStats;
