import AsyncSelect from "react-select/async";

const formatOptionLabel = ({ value, label, email, district, school }:any) => (
  <div>
    <div style={{display: 'flex', alignItems: 'center'}}>
      <div>{label}</div>
      <div style={{ marginLeft: "10px", color: "#6e6e6e", fontSize: 14, marginTop: 0 }}>{email}</div>
    </div>
    <div style={{display: 'flex'}}>
      <div style={{ marginLeft: "0px", color: "#919191", fontSize: 14, marginTop: 5 }}>
        {`${district} `}
      </div>
      <div style={{ marginLeft: "10px", color: "#919191", fontSize: 14, marginTop: 5 }}>
        {school}
      </div>
    </div>
  </div>
);



const  AdminSelect = ({options, onSearch, onChange}:any) => {

  return (
    <AsyncSelect
      defaultValue={options[0]}
      formatOptionLabel={formatOptionLabel}
      loadOptions={onSearch}
      onChange={onChange}
      defaultOptions={options}
    />
  )
};

export default AdminSelect;
