import TicketReplayModel from "components/supportTickets/ticketRepleyModal";
import { SupportTicketContextProvider } from "interfaces/supportTickets";
import { SupportTicket } from "interfaces/supportTicketSuperAdmin";
import { createContext, useEffect, useMemo, useReducer, useState } from "react";
import {
  addReplyToTicket,
  getMasteryVideos,
  getMessages,
  getSupportTickets,
  putMessageSeen,
  saveContactSupportTicket,
  uploadImageToS3,
} from "services/suppot-ticket";
import {
  SupportTicketReducer,
  SupportTicketActions,
} from "./reducers/supportTickets";
import { getAdmins } from "services/supportTicketSuperAdmin";

const initialState: SupportTicketContextProvider = {
  setSupportTickets: null!,
  admins: [],
  fetchSupportTicketAdmins: null!,
  createNewTicket: null!,
  originalTicket: null!,
  updateMessageSeen: null!,
  fetchSupportTicketMessages: null!,
  supportTickets: {
    tickets: [],
    loading: false,
    count: 0,
  },
  singleSupportTicket: null!,
  onClickReply: null!,
  setSingleSupportTicket: null!,
  videoOptions: [],
  addAttachmentToTicket: null!,
  singleMessage: null!,
  setSingleMessage: null!,
  supportTicketMessages: {
    loading: false,
    messages: [],
    count: 0,
    original_ticket_data: null!,
  },
  unsetAdmins: null!,
};

export const SupportTicketContext = createContext<SupportTicketContextProvider>(
  null!
);

const SupportTicketProvider = ({ children }: any) => {
  const [
    {
      supportTickets,
      singleSupportTicket,
      singleMessage,
      supportTicketMessages,
      admins,
    },
    dispatch,
  ] = useReducer(SupportTicketReducer, initialState);
  const [showReplyModal, setShowReplyModal] = useState<boolean>(false);
  const [videos, setVideos] = useState<Array<any>>([]);
  // const addNewTicket = async (payload: any) => {
  //     await saveTicket(payload)
  //     // rest of logic here
  // }

  const originalTicket = useMemo(() => {
    if (supportTicketMessages) {
      const { messages } = supportTicketMessages;
      return (
        messages?.filter(
          (message: any) => message.from === "Original Ticket"
        )[0] || null
      );
    }
    return null!;
  }, [supportTicketMessages]);

  const videoOptions = useMemo(() => {
    return (
      videos?.map((video: any, index: number) => ({
        ...video,
        value: video.name,
        label: video.name,
      })) || []
    );
  }, [videos]);

  const unsetAdmins = () => {
    dispatch({
      type: SupportTicketActions.UNSET_ADMINS,
    });
  };

  const setSupportTickets = (tickets: any) => {
    dispatch({
      type: SupportTicketActions.SET_SUPPORT_TICKETS,
      payload: tickets,
    });
  };

  const createNewTicket = async (payload: any) => {
    const data = await saveContactSupportTicket(payload);
    const tickets = data && data.data && data.data ? data.data : null;
    tickets && setSupportTickets(tickets);
    return !!tickets;
  };

  const updateMessageSeen = async (id: number, ticketId: number) => {
    const updatedMessages = await putMessageSeen(id);
    updatedMessages &&
      dispatch({
        type: SupportTicketActions.SET_MESSAGE_SEEN,
        payload: { id, ticketId },
      });
  };

  const setSupportTicketLoading = (loading: boolean) => {
    dispatch({
      type: SupportTicketActions.SET_SUPPORT_TICKETS_LOADING,
      payload: loading,
    });
  };

  const setSupportTicketMessagesLoading = (loading: boolean) => {
    dispatch({
      type: SupportTicketActions.SET_SUPPORT_TICKET_MESSAGES_LOADING,
      payload: loading,
    });
  };

  const fetchSupportTicketAdmins = async (search: string) => {
    const res = await getAdmins(search);
    const modifiedAdmins = res
      ? res.admins.map((item: any, indx: number) => {
          const { last_name, first_name } = item;
          return {
            ...item,
            label: `${last_name}${first_name ? `, ${first_name}` : ""}`,
            value: indx,
          };
        })
      : null;
    modifiedAdmins &&
      dispatch({
        type: SupportTicketActions.SET_ADMINS,
        payload: modifiedAdmins,
      });

    return modifiedAdmins;
  };

  const fetchSupportTicketMessages = async (payload: any) => {
    const { offset } = payload;
    setSupportTicketMessagesLoading(true);
    const messagesData = await getMessages(payload);
    messagesData
      ? dispatch({
          type: SupportTicketActions.SET_SUPPORT_TICKET_MESSAGES,
          payload: { ...messagesData, offset },
        })
      : setSupportTicketMessagesLoading(false);
  };

  const fetchSupportTickets = async () => {
    setSupportTicketLoading(true);
    const tickets = await getSupportTickets();
    tickets
      ? dispatch({
          type: SupportTicketActions.SET_SUPPORT_TICKETS,
          payload: tickets,
        })
      : setSupportTicketLoading(false);
  };

  const fetchMasteryVideos = async () => {
    const response = await getMasteryVideos();
    const { videos } = response || {};
    videos && setVideos(videos);
  };

  const setSingleSupportTicket = (ticket: SupportTicket) => {
    dispatch({
      type: SupportTicketActions.SET_SINGLE_SUPPORT_TICKET,
      payload: ticket,
    });
  };

  const setSingleMessage = (message: any) => {
    dispatch({
      type: SupportTicketActions.SET_SINGLE_MESSAGE,
      payload: message,
    });
  };

  // const fetchSingleSupportTicket = async (id: number) => {
  //     const ticket = await getSingleSupportTicket(id)
  //     ticket && dispatch({
  //         type: SupportTicketActions.SET_SINGLE_SUPPORT_TICKET,
  //         payload: ticket
  //     })
  // }

  const addReply = async (payload: any) => {
    // pass payload here
    const { offset } = payload;
    const data = await addReplyToTicket({
      ...payload,
      ticketId: singleSupportTicket.id,
      reply_of: singleMessage?.id,
    });

    data &&
      dispatch({
        type: SupportTicketActions.SET_SUPPORT_TICKET_MESSAGES,
        payload: {
          ...data,
          offset,
        },
      });

    data && setShowReplyModal(false);
  };

  // event handlers
  const onClickReply = () => {
    setShowReplyModal(!showReplyModal);
  };

  const addAttachmentToTicket = async (file: any) => {
    const response = await uploadImageToS3(file);
    return response[0];
  };
  // Effects
  useEffect(() => {
    fetchSupportTickets();
    fetchMasteryVideos();

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <SupportTicketContext.Provider
      value={{
        unsetAdmins,
        createNewTicket,
        originalTicket,
        updateMessageSeen,
        supportTicketMessages,
        fetchSupportTicketMessages,
        setSingleMessage,
        singleMessage,
        supportTickets,
        singleSupportTicket,
        onClickReply,
        setSingleSupportTicket,
        videoOptions,
        addAttachmentToTicket,
        fetchSupportTicketAdmins,
        admins,
        setSupportTickets,
      }}
    >
      {supportTickets && children}
      {showReplyModal && (
        <TicketReplayModel
          singleMessage={singleMessage}
          addAttachmentToTicket={addAttachmentToTicket}
          videoOptions={videoOptions}
          addReply={addReply}
          singleSupportTicket={singleSupportTicket}
          show={showReplyModal}
          onClose={() => {
            setShowReplyModal(false);
          }}
        />
      )}
    </SupportTicketContext.Provider>
  );
};
export default SupportTicketProvider;
