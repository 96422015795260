/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useMemo } from "react"
import Scale from "../CLassScale"
import ObjectiveRecord from "../ObjectiveRecord"
import ReportInfoContent from "../infoContent";
import Header from "components/common/report/Print/header";
import TableFooter from "../Table/footer";
import BorderedStartIcon from "assets/img/bordered-star-icon.svg";
import SideBar from "./sideBar";
import { useDispatch } from "react-redux";
import ActionType from "redux/actionTypes";
import { isChrome, browserHeights, isSafari } from "util/index";
import PrintFooter from "components/common/report/Print/footer";

interface Props {
    schoolName: string;
    summative: string;
    classrooms: any;
    schools: any;
    objectives: any;
    objective_by_percentage: any;
    totalClassrooms: number;
    testName: string;
    maximumProficientClassroom: number;

}
const Print: FC<Props> = ({ summative, schoolName, classrooms, schools, objectives, objective_by_percentage, totalClassrooms, testName, maximumProficientClassroom }) => {

    useEffect(() => {
        return () => {
            dispatch({
                type: ActionType.SET_IS_REPORT_LOADING,
                payload: true
            })
        }
    }, [])

    const classesPerTab = 25;

    const objectivesPerPage = useMemo(() => {
        if (isChrome) return 25
        if (isSafari) return 18

        return 20
    }, [])

    const dispatch = useDispatch()
    const sideBar = useMemo(() => {
        return objective_by_percentage.map((info: any) => {
            return Array.from(Array(info.uniqueObjectivesCount)).fill({ ...info })
        }).flat()
    }, [objective_by_percentage])

    const PrintContent = useMemo(() => {

        if (!objectives) {
            return
        }

        let toPrint = []
        const totalTabs = Math.ceil(classrooms.length / classesPerTab)

        const objectivesOnFirstPage = objectivesPerPage - 7
        const objectivesLength = objectives.length
        return Array.from(Array(totalTabs).keys()).map((_, tabIndex) => {
            toPrint = []
            const slicedClassrooms = classrooms?.slice(tabIndex * classesPerTab, (tabIndex * classesPerTab + classesPerTab))

            let endIndex = tabIndex ? objectivesPerPage :
                objectivesLength <= objectivesOnFirstPage ? objectivesLength : objectivesOnFirstPage

            for (let startIndex = 0; endIndex <= objectivesLength;) {
                const slicedObjectives = objectives.slice(startIndex, endIndex)
                const slicedSideBar = sideBar.slice(startIndex, endIndex)
                if (!slicedObjectives.length) {
                    break;
                }

                toPrint.push(
                    <div className={`summative-objective-analysis-content summative-objective-analysis-report-print ${(tabIndex || startIndex) && 'next_page_break'}`}>
                        <div className={`${browserHeights}`}>
                            <Header
                                title={`Summative ${summative} Objective Analysis`}
                                test_name={testName} // as district name
                                class_name={schoolName} // as test name
                            />
                            {
                                !tabIndex && !startIndex && <ReportInfoContent
                                    isPrint={true}
                                />
                            }
                            <Scale
                                classrooms={slicedClassrooms}
                                schools={schools}
                            />
                            <div className="summative-objective-analysis__data">

                                <div className="summative-objective-analysis__data-header summitive-data-header">
                                    <div className="summative-objective-analysis__data-objective summitive-objective-width d-flex">
                                        <div className="objective-title">
                                            <p>Objective</p>
                                        </div>
                                        <div className="questions-title">
                                            <p># of Stdts <span>&rarr;</span></p>
                                            <p># of Qs</p>
                                        </div>

                                    </div>
                                    {slicedClassrooms?.map((info: any, index: number) => {

                                        return (
                                            <div>
                                                <div className="summative-objective-analysis__data-questions">
                                                    <span
                                                        className="summative-objective-analysis__data-text"
                                                    >
                                                        <span className="summative-objective-analysis__data-objectiveData border-span">
                                                            <span>{info.classroom_name}</span>
                                                        </span>
                                                    </span>
                                                    <span className="summative-objective-analysis__data-count">
                                                        <span>{info.number_of_student}</span>
                                                        {info.classroom_id === maximumProficientClassroom && <img className="star" src={BorderedStartIcon} alt="star" />}
                                                    </span>
                                                </div>
                                            </div>
                                        )

                                    })

                                    }
                                    <div className="summative-objective-analysis__data-benchmark">
                                        <p className="summative-objective-analysis__data-benchmarkTitle">
                                            <span>{`# classes < 33% proficient`}</span>
                                        </p>
                                    </div>

                                    <div className="summative-objective-analysis__data-summative">
                                        <p className="summative-objective-analysis__data-summativeTitle">Average</p>
                                    </div>
                                </div>
                                <div className={`summative-objective-analysis-table-container ${(tabIndex || startIndex) && 'PrintPage'}`}>
                                    <div className="summative-objective-analysis-table-container__sidebar">
                                        <div className="printBorderTop"></div>
                                        <SideBar
                                            sideBarContent={slicedSideBar}
                                        />
                                    </div>
                                    <table className={`summative-objective-analysis__table summative-objective-analysis__table-summative-table ${objectives && objectives?.length > 15 ? 'summative-objective-analysis__table-summative-table-height' : ''}`}>

                                        <tbody className="print-body">
                                            {slicedObjectives?.map((objective: any, index: number) => {
                                                return (
                                                    <ObjectiveRecord
                                                        index={index}
                                                        objective={objective}
                                                        classrooms={slicedClassrooms}
                                                        showScore={true}
                                                        showClassColumn={null!}
                                                        totalClassrooms={totalClassrooms}
                                                        selectedObjectiveIndex={null!}
                                                    />

                                                )
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <TableFooter
                                    Objectives={slicedObjectives}
                                    tabClassrooms={slicedClassrooms}
                                    objectivesCount={objectives?.length}
                                />
                            </div>

                        </div>
                        <PrintFooter
                            text={<>*Includes all objectives, not just those on this page</>}
                        />
                    </div>
                )

                startIndex = endIndex;
                endIndex += Math.min(Math.max(1, objectives.length - (endIndex)), objectivesPerPage);
            }

            return <div className={`${tabIndex && 'next_page_break'}`}>{[...toPrint]}</div>
        })

    }, [classrooms, objectives, schools])

    return <div className="summative-objective-analysis">
        {PrintContent}
    </div>
}
export default Print