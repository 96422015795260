import moment from "moment"
import { FC } from "react"

const StudentTable: FC<{ students: any, total: number, time: Date }> = ({ students, total, time }) => {

    return (
        <div className="d-flex justify-content-center align-items-center mt-3">
            <div className="inner-table text-align--center p-3">
                <table className="risk-report__print-table"> 
                    <thead>
                        <tr>
                            <th className="report-risk-print__studentLast print-lastName">Student Last</th>
                            <th className="firstName print-firstName">First Name</th>
                            <th className="print-id">Student ID <br/> Last 5</th>
                            <th className="width88">Assessment Date</th>
                            <th className="width88">Proficiency</th>
                            <th className="width88"># Questions Correct</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            students.map((student: any) => {
                                const {first_name, last_name,correct, proficiency, total_questions, school_student_id} = student
                                return (
                                    <tr className="pt-2 report-risk-print__inner-table">
                                        <td className="report-risk-print__studentLast">{last_name}</td>
                                        <td>{first_name}</td>
                                        <td>{school_student_id?.slice(-5)}</td>
                                        <td className="width88">{moment(time).format("l")}</td>
                                        <td className="width88">{proficiency} %</td>
                                        <td className="width88">{`${correct}/${total_questions}`}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
export default StudentTable 