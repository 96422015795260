import { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";


interface Props {
    show: boolean;
    onSave: Function;
    onClose: () => void;
    defaultText: string;
}

const AddLink: React.FC<Props> = (props) => {
    const {
        show,
        onSave,
        onClose,
        defaultText
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [comment, setComment] = useState("");
    const [commentID, setCommentID] = useState("");
    const [selectedText, setSelectedText] = useState("");
    
    const handleSave = async () => {
        setIsLoading(false);
        onSave(comment, commentID, selectedText.trim());
    };

    const closeModal = () => {
        setIsLoading(false);
        onClose()
    };

    useEffect(() => {
        const focused = window?.getSelection() as any;
        const selectedValue = focused.toString();
        const selectedValueID = focused?.focusNode?.parentNode;

        setComment(focused.toString() || defaultText);
        setSelectedText(selectedValue);
        setCommentID(selectedValueID);
    }, [defaultText])

    return <Modal centered show={show} onEscapeKeyDown={closeModal}>
        <div className="modal__header">
            <div>
                <h2 className="modal__header-title">
                    Insert Mastery Video Link
                </h2>
            </div>
        </div>


        <div className={` textArea mb-4 px-2 mt-3`}>
            <p className="bg-dark text-sm text-white mb-0 px-3 rounded-pill comment-text">
                Display Text
            </p>
            <textarea
                className="w-100 rounded-2 p-3"
                placeholder="Enter here"
                rows={4}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
            />
        </div>
        <div className="modal__footer">
            <button
                type="button"
                className="btn cancel-btn"
                data-bs-dismiss="modal"
                onClick={closeModal}
            >
                Cancel
            </button>
            <button
                type="button"
                disabled={isLoading || !comment}
                className="btn success-btn"
                onClick={handleSave}
            >
                {false ? (
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    />
                ) : (
                    "Insert"
                )}
            </button>
        </div>
    </Modal>
}
export default AddLink