/* eslint-disable react-hooks/exhaustive-deps */

import ReportHeader from "components/common/report/header";
import { useEffect, useMemo, useRef, useState } from "react";

import BorderedStartIcon from "assets/img/bordered-star-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";
import ReportTabs from "./Tabs";
import {
  BodySkeleton,
  HeaderSkeleton,
  TabsSkeleton,
} from "components/common/skeleton/ReportsSkeleton";
import ObjectiveRecord from "components/SummativeObjectiveReport/ObjectiveRecord";
import { useParams, useSearchParams } from "react-router-dom";
import Scale from "./CLassScale";
import Print from "./print";
import ReportInfoContent from "./infoContent";
import QuestionMark from "assets/img/question-mark-dark.svg";
import NavPrev from "assets/img/nav-prev-icon.png";
import NavNext from "assets/img/nav-next-icon.png";
import Navtop from "assets/img/nav-top-icon.png";
import NavBottom from "assets/img/nav-bottom-icon.png";
import TableFooter from "./Table/footer";
import ObjectiveModal from "./Modals/objectiveByPercentage";
import ClassesModal from "./Modals/classessByPercentage";
import NearlyProficientModal from "./Modals/nearlyProficientModal";
import { isReportLoadingSelector } from "redux/actionCreators/management";
import Tooltip from "components/common/Tooltip";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import ActionType from "redux/actionTypes";
import { useQuery } from "hooks/useQuery";
import SearchIcon from "components/common/svgs/search-icon";

const ObjectiveAnalysisReport = () => {
  const [showScore, setShowScore] = useState(true);
  const [search, setSearch] = useState("");
  const isLoading = useSelector(isReportLoadingSelector);
  const report = useSelector(
    (state: RootState) => state.management.summativeAnalysisReport
  );
  const districtDetails = useSelector(
    (state: RootState) => state.management.districtDetails
  );
  const classesPerTab = 25;
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [showClassColumn, setShowClassColumn] = useState<number>(null!);
  const { id } = useParams();
  const [params] = useSearchParams();
  const school_id = params.get("school_id");
  const [selectedObjectiveIndex, setSelectiveObjctiveIndex] = useState<number>(
    null!
  );
  const [objectivesByPercentageModal, setObjectivesModal] =
    useState<boolean>(false);
  const [classesByPercentageModal, setClassesModal] = useState<boolean>(false);
  const [nearlyProficientModal, setNearlyProficientModal] =
    useState<boolean>(false);
  const tableRef = useRef<HTMLTableSectionElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isNavLeftDisabled, setIsNavLeftDisabled] = useState(true);
  const [isNavTopDisabled, setIsNavTopDisabled] = useState(true);
  const componentRef = useRef(null);
  const dispatch = useDispatch();

  const query = useQuery();
  const print = query.get("print");
  useEffect(() => {
    setShowClassColumn(null!);

    return () => {
      dispatch({
        type: ActionType.SET_DISTRICT_DETAILS,
        payload: null,
      });

      dispatch({
        type: ActionType.SET_SUMMATIVE_ANALYSIS_REPORT,
        payload: null,
      });
      dispatch({
        type: ActionType.SET_IS_REPORT_LOADING,
        payload: true,
      });
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //this memo generates variables depending on Report
  const {
    objective_by_percentage,
    objectives,
    allClassrooms,
    schools,
    totalClassrooms,
    maximumProficientClassroom,
    slicedClassrooms,
    summative,
    subjectName,
    level,
  } = useMemo(() => {
    if (report) {
      const { schools, objective_by_percentage, test_name, subject, level } =
        report;
      let lessProficientCount = Number.MAX_SAFE_INTEGER;
      let maximumProficientClassroom = null;

      schools?.map((school: any) => {
        const { classrooms } = school;
        const classroomsLength = classrooms.length;
        if (classroomsLength > 1) {
          for (let index = 0; index < classroomsLength; index++) {
            if (
              classrooms[index].has_submissions &&
              classrooms[index].less_proficient_objective_count <
                lessProficientCount
            ) {
              lessProficientCount =
                classrooms[index].less_proficient_objective_count;
              maximumProficientClassroom = classrooms[index].classroom_id;
            }
          }
        }
        return null;
      });

      const summative = test_name.split(" ")?.pop();
      const schoolName = schools.length === 1 ? schools[0].name : null!;

      const allClassrooms = schools
        .map((school: any) => school.classrooms)
        .flat();
      return {
        ...report,
        subjectName: subject,
        schoolName,
        summative,
        allClassrooms,
        totalClassrooms: schools.map((school: any) => school.classrooms).flat()
          .length,
        maximumProficientClassroom,
        slicedClassrooms: allClassrooms.slice(
          tabIndex * classesPerTab,
          tabIndex * classesPerTab + classesPerTab
        ),
        objective_by_percentage: objective_by_percentage.map((item: any) => {
          const uniqueObjectives = item.objective_ids?.filter(
            (id: any, pos: number) => {
              return item.objective_ids?.indexOf(id) === pos;
            }
          );

          return {
            ...item,
            height: `${uniqueObjectives.length * 37}px`,
            uniqueObjectivesCount: uniqueObjectives.length,
          };
        }),
        level,
      };
    } else {
      return {
        tabClassrooms: [],
        objectives: [],
        schools: [],
        objective_by_percentage: [],
        totalClassrooms: 0,
        allClassrooms: [],
        maximumProficientClassroom: null,
        slicedClassrooms: [],
        subjectName: "",
      };
    }
  }, [report, tabIndex]);

  const classClickHandler = (classroomId: any) => {
    if (classroomId === showClassColumn) {
      setShowClassColumn(null!);
      return;
    }
    setShowClassColumn(classroomId);
  };

  const { Objectives, tabClassrooms } = useMemo(() => {
    if (search) {
      const filteredObjectives = objectives?.filter((objective: any) =>
        objective.objective_number.toLowerCase().includes(search.toLowerCase())
      );
      const filteredClassrooms = slicedClassrooms?.filter((classroom: any) =>
        classroom.classroom_name.toLowerCase().includes(search.toLowerCase())
      );

      if (!!filteredObjectives.length) {
        return {
          Objectives: filteredObjectives,
          tabClassrooms: slicedClassrooms,
        };
      } else if (!!filteredClassrooms.length) {
        return {
          Objectives: objectives,
          tabClassrooms: filteredClassrooms,
        };
      } else {
        return {
          Objectives: [],
          tabClassrooms: [],
        };
      }
    }
    return {
      Objectives: objectives,
      tabClassrooms: slicedClassrooms,
    };
  }, [search, objectives, slicedClassrooms]);

  const HeaderContent = useMemo(() => {
    if (isLoading)
      return (
        <div style={{ marginLeft: "25px" }}>
          <HeaderSkeleton rowColumns={21} height={88} />
        </div>
      );
    else if (tabClassrooms?.length && Objectives.length) {
      return (
        <>
          <div className="summative-objective-analysis__data-objective summitive-objective-width d-flex">
            <div className="objective-title">
              <p>Objective</p>
            </div>
            <div className="questions-title">
              <p>
                # of Stdts <span>&rarr;</span>
              </p>
              <p># of Qs</p>
            </div>
          </div>
          {tabClassrooms?.map((info: any, index: number) => (
            <div key={index}>
              <div className="summative-objective-analysis__data-questions">
                <span className="summative-objective-analysis__data-text">
                  <Tooltip content={info.classroom_name}>
                    <span
                      className="summative-objective-analysis__data-objectiveData border-span"
                      onClick={(e) => classClickHandler(index)}
                      style={{
                        fontWeight:
                          showClassColumn !== null && showClassColumn === index
                            ? "bold"
                            : "normal",
                        opacity:
                          showClassColumn !== null && showClassColumn !== index
                            ? "0.2"
                            : "1",
                      }}
                    >
                      <span className="animation-text">
                        {info.classroom_name}
                      </span>
                    </span>
                  </Tooltip>
                </span>

                <span className="summative-objective-analysis__data-count">
                  <span>{info.number_of_student}</span>
                  {info.classroom_id === maximumProficientClassroom && (
                    <img className="star" src={BorderedStartIcon} alt="star" />
                  )}
                </span>
              </div>
            </div>
          ))}
          <div className="summative-objective-analysis__data-benchmark">
            <p className="summative-objective-analysis__data-benchmarkTitle">
              <span>
                {`# classes < 33% proficient`}
                <img
                  onClick={() => setClassesModal(true)}
                  src={QuestionMark}
                  alt="Question"
                />
              </span>
            </p>
          </div>

          <div className="summative-objective-analysis__data-summative">
            <p className="summative-objective-analysis__data-summativeTitle">
              Average
            </p>
          </div>
        </>
      );
    }
  }, [tabClassrooms, isLoading, showClassColumn, maximumProficientClassroom]);

  const BodyContent = useMemo(() => {
    if (isLoading)
      return (
        <BodySkeleton
          rowColumns={21}
          circle={true}
          circleAttempt={false}
          scoreBox={false}
          scoreColumn={true}
          attemptWidth={"114px"}
          scoreWidth={"100px"}
        />
      );
    else if (Objectives?.length && tabClassrooms.length) {
      return Objectives?.map((objective: any, index: number) => {
        return (
          <ObjectiveRecord
            index={index}
            showScore={showScore}
            showClassColumn={showClassColumn}
            objective={objective}
            classrooms={tabClassrooms}
            selectedObjectiveIndex={selectedObjectiveIndex}
            setSelectedObjectiveIndex={setSelectiveObjctiveIndex}
            totalClassrooms={totalClassrooms}
          />
        );
      });
    }
  }, [
    showScore,
    tabClassrooms,
    totalClassrooms,
    Objectives,
    selectedObjectiveIndex,
    showClassColumn,
    isLoading,
  ]);

  const onTabChange = (tabIndex: number) => {
    setTabIndex(tabIndex);
    setShowClassColumn(null!);
    setSelectiveObjctiveIndex(null!);

    if (contentRef.current) {
      contentRef.current.scrollLeft = 0;
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `SummativeObjectiveAnalysis_${moment(new Date()).format(
      "M/DD/YY hh:mmA"
    )}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      print && window.close();
    },
  });

  useEffect(() => {
    if (report && print) {
      handlePrint();
    }
  }, [report]);

  const onScroll = () => {
    if (contentRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = contentRef.current;
      if (scrollLeft + clientWidth === scrollWidth) {
        setIsNavLeftDisabled(false);
      }
      if (scrollLeft === 0) {
        setIsNavLeftDisabled(true);
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setIsNavTopDisabled(true);
    }, 800);
  };

  const scrollToBottom = () => {
    const target = document.getElementById("footer-content") as any;
    if (target) {
      window.scrollTo(0, target.offsetTop - window.innerHeight);
    }
    setTimeout(() => {
      setIsNavTopDisabled(false);
    }, 800);
  };

  const scrollToLeft = () => {
    if (contentRef.current) {
      contentRef.current.scrollLeft += -800;
    }
    setIsNavLeftDisabled(true);
  };

  const scrollToRight = () => {
    if (contentRef.current) {
      contentRef.current.scrollLeft += 800;
    }
    setIsNavLeftDisabled(false);
  };

  console.log("Rendering : ", districtDetails, report);
  return (
    <div className="summative-objective-analysis">
      <div className="hide-on-print">
        <ReportHeader
          reportTitle="Summative Objective Analysis"
          testName={
            districtDetails?.school_name || districtDetails?.name || "School"
          }
          setPrint={handlePrint}
          districtId={parseInt(id as string)}
          schoolId={school_id ? parseInt(school_id) : null!}
          headerClassName="soa-header report__transparent_header"
        />

        <div className="summative-objective-analysis-content">
          <div className="summative-objective-analysis__info-wrapper">
            <ReportInfoContent
              showScore={showScore}
              setShowScore={setShowScore}
              isPrint={false}
              setShowModal={() => setNearlyProficientModal(true)}
              level={level}
            />
            <div className="report__navigation">
              <p className="mb-0">Navigation</p>
              <div className="report__navigation-buttons">
                {allClassrooms.length > 5 && (
                  <div className="report__navigation-right">
                    <button
                      type="button"
                      disabled={isNavLeftDisabled === true ? true : false}
                      onClick={scrollToLeft}
                      className="report__navigation-btn next"
                    >
                      <img src={NavPrev} alt="icon" />
                    </button>
                    <button
                      type="button"
                      disabled={isNavLeftDisabled === false ? true : false}
                      onClick={scrollToRight}
                      className="report__navigation-btn next ms-2"
                    >
                      <img src={NavNext} alt="icon" />
                    </button>
                  </div>
                )}
                {objectives?.length > 10 && (
                  <div className="report__navigation-bottom">
                    <button
                      type="button"
                      onClick={scrollToTop}
                      disabled={isNavTopDisabled === true ? true : false}
                      className="report__navigation-btn mb-2"
                    >
                      <img src={Navtop} alt="icon" />
                    </button>
                    <button
                      type="button"
                      onClick={scrollToBottom}
                      disabled={isNavTopDisabled === false ? true : false}
                      className="report__navigation-btn"
                    >
                      <img src={NavBottom} alt="icon" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="summative-objective-analysis__search tabController">
            <div className="summative-objective-analysis__search-searcharea">
              {!isLoading && (
                <SearchIcon style={{marginRight:"10px"}} title="Search" fill="#323233" />
              )}
              <input
                className={`form-control summative-objective-analysis__search-input ${
                  isLoading ? "margin-left" : ""
                }`}
                type="search"
                name="Search"
                placeholder="Search Objectives or Classes"
                value={search}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.target.value)
                }
              />
            </div>
            {!isLoading ? (
              <ReportTabs
                tabIndex={tabIndex}
                setTabIndex={onTabChange}
                schools={schools}
                classesPerTab={classesPerTab}
                setShowClassColumn={() => setShowClassColumn(null!)}
              />
            ) : (
              <TabsSkeleton customClass={""} width={145} />
            )}
          </div>

          <div
            className="summative-objective-analysis__data"
            ref={contentRef}
            onScroll={onScroll}
          >
            <Scale classrooms={tabClassrooms} schools={schools} />

            {!!objective_by_percentage.length && (
              <div className="summative-objective-analysis__data__question-img">
                <img
                  className="cursor-pointer"
                  src={QuestionMark}
                  alt="question"
                  onClick={() => setObjectivesModal(true)}
                />
              </div>
            )}
            <div className="summative-objective-analysis__data-header summitive-data-header">
              {HeaderContent}
            </div>
            <div className="summative-objective-analysis-table-container">
              <div className="summative-objective-analysis-table-container__sidebar">
                {objective_by_percentage?.map((objective: any) => (
                  <Tooltip content={objective.objective_name}>
                    <div
                      className="print-structure"
                      style={{ height: objective.height }}
                    >
                      <span className="percentage">
                        {objective.percentage}%
                      </span>
                      <span
                        data-tip={objective.objective_name}
                        className="main-content"
                      >
                        <span className="content">
                          {objective.objective_name}
                        </span>
                      </span>
                    </div>
                  </Tooltip>
                ))}
              </div>

              <table
                className={`summative-objective-analysis__table summative-objective-analysis__table-summative-table ${
                  objectives && objectives?.length > 15
                    ? "summative-objective-analysis__table-summative-table-height"
                    : ""
                }`}
              >
                <tbody ref={tableRef}>
                  {(!Objectives.length || !allClassrooms.length) &&
                    !isLoading && (
                      <div className="mt-4">
                        <tr className="dark-gray">
                          <td className="report__table-notFound">
                            No Records Found
                          </td>
                        </tr>
                      </div>
                    )}
                  {BodyContent}
                </tbody>
              </table>
            </div>
            <TableFooter
              isLoading={isLoading}
              Objectives={Objectives}
              tabClassrooms={tabClassrooms}
              objectivesCount={objectives?.length}
            />
          </div>
        </div>
        <ObjectiveModal
          isShow={objectivesByPercentageModal}
          closeModal={() => setObjectivesModal(false)}
        />
        <ClassesModal
          isShow={classesByPercentageModal}
          closeModal={() => setClassesModal(false)}
        />
        <NearlyProficientModal
          isShow={nearlyProficientModal}
          closeModal={() => setNearlyProficientModal(false)}
        />
      </div>
      <div className="printData" ref={componentRef}>
        <Print
          summative={summative}
          schoolName={
            school_id
              ? `${districtDetails?.name}: ${districtDetails?.school_name}`
              : `${districtDetails?.name}`
          } // district name : school name if opened from school
          classrooms={allClassrooms}
          schools={schools}
          objectives={objectives}
          objective_by_percentage={objective_by_percentage}
          totalClassrooms={totalClassrooms}
          testName={subjectName}
          maximumProficientClassroom={maximumProficientClassroom}
        />
      </div>
    </div>
  );
};

export default ObjectiveAnalysisReport;
