import { FC, useEffect, useState } from "react";
import {
  getClassesAction,
  getAssessmentsAction,
  setSelectedAssessment,
} from "redux/actionCreators/cmsAssessment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/reducers/combine";
import { useNavigate } from "react-router-dom";
import cookie from "js-cookie";
import TestIcon from "assets/img/review-test-btn.svg";
import {
  CMSSelectAssessment,
  CMSSelectClassroom,
} from "components/common/selectComponent";
import CustomModal from "components/common/Modal";
import { AppCookies } from "util/constants";

const CMSAssessmentModal: FC<{}> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { classes, assessments } = useSelector(
    (state: RootState) => state?.cmsAssessment
  );

  const [classroomsData, setClassroomsData] = useState([]);

  const [assessmentData, setAssessmentData] = useState([]);

  const [classroomModal, setClassroomModal] = useState(false);

  const [assessmentModal, setAssessmentModal] = useState(false);

  const [selectedClass, setSelectedClass] = useState<any>(null!);
  const [selectedAssessment, setSelectedAssessmentData] = useState<any>(null!);
  const [assessmentId, setAssessmentId] = useState(-1);
  const [assessmentType, setAssessmentType] = useState("");
  const [assessmentName, setAssessmentName] = useState("");

  useEffect(() => {
    dispatch(getClassesAction());
  }, [dispatch]);

  useEffect(() => {
    setClassroomsData(
      classes.map((classroom: any, index: number) => {
        return {
          id: index + 1,
          value: index + 1,
          label: `${classroom?.label}`,
          item: classroom,
        };
      })
    );
  }, [classes]);

  useEffect(() => {
    assessments.sort((a: any, b: any) => {
      const aContainsArchive = a.name.includes("Archive");
      const bContainsArchive = b.name.includes("Archive");

      if (aContainsArchive && !bContainsArchive) {
        return 1; // a should come after b
      } else if (!aContainsArchive && bContainsArchive) {
        return -1; // a should come before b
      } else {
        return 0; // no change in order
      }
    });
    setAssessmentData(
      assessments.map((item: any) => {
        return {
          id: item.id,
          value: item.id,
          label: `${
            item.objective_number ? item.objective_number + " -" : ""
          } ${item.name}`,
          item: item,
        };
      })
    );
  }, [assessments]);

  const onSubmit = () => {
    dispatch(setSelectedAssessment(assessmentName));

    console.log("ass", assessmentName);
    const isMath = selectedClass.label.includes("Math");

    cookie.set(AppCookies.isMath, isMath);
    cookie.set(AppCookies.grade_id, selectedClass?.item?.grade_id);
    cookie.set(AppCookies.subject_id, selectedClass.item.subject_id);

    console.log("ass", assessmentType);
    console.log("23", assessmentId);
    navigate(`/cms/${assessmentType}/${assessmentId}`);
  };

  const onChangeClasses = (option: any) => {
    const indexOfItem = classroomsData?.findIndex((classroom: any) => {
      return (
        option?.item?.subject_id === classroom?.item?.subject_id &&
        option?.item?.grade_id === classroom?.item?.grade_id
      );
    });

    if (indexOfItem > -1) {
      setSelectedClass(option);

      dispatch(
        getAssessmentsAction(option?.item?.grade_id, option?.item?.subject_id)
      );
    }
  };

  const onChangeAssessment = (event: any) => {
    setSelectedAssessmentData(event);
    const { formative_id, id, type, key } = event.item;
    const assessmentType = type;
    const assessId = assessmentType === "formatives" ? formative_id : id;
    setAssessmentId(assessId);
    setAssessmentType(assessmentType);

    const selectedAssessment = assessments.find(
      (assessment: any) =>
        assessment.key === key && assessment.type === assessmentType
    );

    const assessmentName =
      (assessmentType === "formatives"
        ? selectedAssessment.objective_number + " -"
        : "") + selectedAssessment.name;
    setAssessmentName(assessmentName);
  };

  return (
    <CustomModal centered show={true} className="cmsAssessmentModal">
      <div className="">
        <div className="modal__header">
          <img src={TestIcon} alt="Test icon" className="modal__header-icon" />
          <h6 className="modal__header-title">Select Assessment</h6>
        </div>
        <div className="modal__body">
          <div className="modal__form">
            <div>
              <p>Select the assessment to edit below.</p>
            </div>

            <CMSSelectClassroom
              id={selectedClass?.id}
              options={classroomsData}
              disableHandler={false}
              onChangeHandler={(data: any) => onChangeClasses(data)}
              noOptionsMessage={
                <p className="no-admins">No Grade and Subject Found</p>
              }
              open={classroomModal}
              setOpen={(val: any) => {
                setClassroomModal(val);
                setAssessmentModal(false);
              }}
              isId={!!selectedClass?.id}
              onSave={onSubmit}
              isSubmitError={() => {}}
              label="Grade and Subject"
              selectedClass={selectedClass}
            ></CMSSelectClassroom>

            <div className={`${!selectedClass ? "" : ""}`}>
              <CMSSelectAssessment
                options={assessmentData}
                onChangeHandler={(data: any) => onChangeAssessment(data)}
                noOptionsMessage={
                  <p className="no-admins">No Assessment Found</p>
                }
                open={assessmentModal}
                setOpen={(val: any) => {
                  setAssessmentModal(val);
                  setClassroomModal(false);
                }}
                isId={!!assessmentId}
                onSave={onSubmit}
                isSubmitError={() => {}}
                label="Assessment"
                disableHandler={!selectedClass ? true : false}
                selectedAssessment={selectedAssessment}
              ></CMSSelectAssessment>
            </div>
          </div>
        </div>
        <div className="modal__footer">
          <button
            disabled={assessmentId === -1}
            onClick={onSubmit}
            className={`btn success-btn ${
              assessmentId === -1 ? "disabled" : ""
            }`}
          >
            Select
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default CMSAssessmentModal;
