import DeleteImage from "assets/img/del-img-icon-black-outline.svg";
import { memo, useMemo } from "react";
import HighlightedRecord from "./highlightedRecord";
import ClassroomTooltip from "components/common/classroomTooltip";
import { TableSkeleton } from "components/common/skeleton/TableSkeleton";
import ImportantDateSection from "./importDateSection";
import AssessmentName from "./assessmentName";
import PowerStandard from "components/common/svgs/power-standard";
import ToolTip from "components/common/Tooltip";
import NoDataMessage from "./nodataMessage";

const AssessmentBody = ({
    isSuperAdmin,
    assessments,
    searchedValue,
    onClickDelete,
    setModalSuggestedShow,
    setRemoveModal,
    dateId,
    isAddDisabled,
    idx,
    showPDAssessmentTooltip,
    showAddWarning,
    scheduledDate,
    postPacingCalendarLoading,
    noData
}: any) => {
    const tableContent = useMemo(() => {
        const assessmentsWithImportantDates: Array<{assessment: any, index: number}> = [];
        assessments.map((assessment: any, index: number)=>{
            if(assessment.important_date)
                assessmentsWithImportantDates.push({ assessment, index});
            return null;    
        })
       
        const impDateAbove = assessmentsWithImportantDates.length===1 && assessmentsWithImportantDates[0].index === 0 && !isSuperAdmin;
        const impDateBelow = assessmentsWithImportantDates.length===1 && assessmentsWithImportantDates[0].index=== assessments.length-1 && !isSuperAdmin;

        const assessmentCount = assessments.length;
        const noDataMsgIndex = Array.isArray(assessments) ? Math.floor(assessmentCount/2) : -1;
        const className = assessmentCount ===1 && isSuperAdmin ? 'no-pacing-data-pos-2': assessmentCount === 2 ? 'no-pacing-data-pos-2' : assessmentCount === 4 ? 'no-pacing-data-pos-4' : '';
        
        return (
            <>
                {assessments?.map((assessment: any, index: number) => {
                    const isAssessmentLessThanThree = assessments.filter((item: any) => item.date === assessment.date).length < 3;
                    const showAddIcon = isSuperAdmin ? isAssessmentLessThanThree && !assessment.isAlreadyExists : !assessment.isAlreadyExists
                    if (postPacingCalendarLoading && assessment.date === scheduledDate) {
                        return (
                            <TableSkeleton baseColor="#2a539143" highlightColor="#5371b732" columns={4} count={1} height={53} />
                        )
                    }

                    return (

                        <>
                            {!isSuperAdmin && index===0 && assessment.important_date ? <ImportantDateSection text={assessment.important_date} /> : null}
                            <tr
                                key={index}
                                className="PacingCalender__container__table__bodyrow"
                            >
                                <td className="text-white td1">
                                    <span className="d-flex align-items-center">
                                        {assessment.isAlreadyExists
                                            ? ""
                                            : <span>{assessment.date_formatted}</span>
                                        }


                                        {!isSuperAdmin && !!assessment.id ? (
                                            <>
                                                {showAddIcon && (
                                                    <button onClick={() => !isAddDisabled ? isAssessmentLessThanThree ? setModalSuggestedShow(assessment) : showAddWarning() : null} type="button" className={`btn outline-btn ${(assessment.disabled || isAddDisabled || !isAssessmentLessThanThree) && "opacity-disabled"} ${!isAddDisabled ? 'cursor-pointer' : ''}
                                                    `}>Add</button>
                                                )}
                                            </>
                                        ) : (

                                            <>
                                                {showAddIcon && (<button onClick={() => !isAddDisabled && setModalSuggestedShow(assessment)} type="button" className={`btn outline-btn ${(assessment.disabled || isAddDisabled) && "dopacity-isabled"} ${!isAddDisabled ? 'cursor-pointer' : ''}
                                                `}>Add</button>)}
                                            </>
                                        )}

</span>
                                    {
                                        idx === 1 && index === 2 && (showPDAssessmentTooltip) ? (
                                            <ClassroomTooltip
                                                arrowDirection={"left"}
                                                className="ProfessionalDev__devSummary__toolTip pacing-calender-position-assessment"
                                                message="Use this to select an assessment to schedule."
                                            />
                                            ) : null
                                    }

                                </td>

                                <td className="text-white td2">
                                    {
                                        (assessment.is_formative && assessment.objective_full_name) || assessment.summative_name ?
                                            <span className={`${!assessment.is_completed ? "assesment_not_completed" : ""}`}>
                                                
                                                {!!(assessment.objective_id || assessment.summative_id) && (
                                                    // <img className="TickIcon" src={ReviewBtn} alt="" />
                                                    <svg className="TickIcon" xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
                                                        <path d="M6.43319 3.49609C4.63633 3.49609 3.17969 4.95274 3.17969 6.7496V9.30159C3.17969 11.0985 4.63633 12.5551 6.43319 12.5551C8.23005 12.5551 9.68669 11.0985 9.68669 9.30159V6.74959C9.68669 4.95274 8.23005 3.49609 6.43319 3.49609ZM8.60769 9.30209C8.60769 10.5033 7.63391 11.4771 6.43269 11.4771C5.23147 11.4771 4.25769 10.5033 4.25769 9.30209V6.7521C4.25769 5.55088 5.23147 4.57709 6.43269 4.57709C7.63391 4.57709 8.60769 5.55087 8.60769 6.75209V9.30209Z" fill="white"/>
                                                        <path d="M11.8828 4.49481C11.8828 4.15681 12.1568 3.88281 12.4948 3.88281H18.6588C18.9968 3.88281 19.2708 4.15681 19.2708 4.49481C19.2708 4.83281 18.9968 5.10681 18.6588 5.10681H12.4948C12.1568 5.10681 11.8828 4.83281 11.8828 4.49481Z" fill="white"/>
                                                        <path d="M11.8828 6.94403C11.8828 6.60603 12.1568 6.33203 12.4948 6.33203H16.2098C16.5478 6.33203 16.8218 6.60603 16.8218 6.94403C16.8218 7.28203 16.5478 7.55603 16.2098 7.55603H12.4948C12.1568 7.55603 11.8828 7.28203 11.8828 6.94403Z" fill="white"/>
                                                        <path d="M11.8828 9.20917C11.8828 8.87117 12.1568 8.59717 12.4948 8.59717H18.6588C18.9968 8.59717 19.2708 8.87117 19.2708 9.20917C19.2708 9.54717 18.9968 9.82117 18.6588 9.82117H12.4948C12.1568 9.82117 11.8828 9.54717 11.8828 9.20917Z" fill="white"/>
                                                        <path d="M11.8828 11.6579C11.8828 11.3199 12.1568 11.0459 12.4948 11.0459H16.2098C16.5478 11.0459 16.8218 11.3199 16.8218 11.6579C16.8218 11.9959 16.5478 12.2699 16.2098 12.2699H12.4948C12.1568 12.2699 11.8828 11.9959 11.8828 11.6579Z" fill="white"/>
                                                        <path d="M17.9992 0.700195H3.99922C2.17668 0.700195 0.699219 2.17766 0.699219 4.0002V12.0002C0.699219 13.8227 2.17668 15.3002 3.99922 15.3002H17.9992C19.8218 15.3002 21.2992 13.8227 21.2992 12.0002V4.0002C21.2992 2.17766 19.8218 0.700195 17.9992 0.700195Z" stroke="white" stroke-width="1.4"/>
                                                    </svg>
                                                )}
                                                
                                                <AssessmentName
                                                    assessment_name={
                                                        assessment.is_formative
                                                        ? assessment.objective_full_name
                                                        : assessment.summative_name
                                                    }
                                                    
                                                />
                                                {assessment.power_standard && (
                                                    <ToolTip top={-30} content="This objective is a Power Standard">
                                                        <PowerStandard addMargin={false} />
                                                    </ToolTip>
                                                )}
                                                {!isSuperAdmin && !!assessment.id && !assessment.is_completed && (<img
                                                    onClick={() => !assessment.is_completed && onClickDelete(assessment)}
                                                    tabIndex={0}
                                                    role="button"
                                                    onKeyDown={ (e:any) => { 
                                                        if(e.key === 'Enter' &&  !assessment.is_completed) {
                                                             onClickDelete(assessment)
                                                        }
                                                    } }
                                                    className={`${"leftSpace"} deleteIcon ${!assessment.is_completed ? 'cursor-pointer' : ''} ${(assessment.disabled || assessment.is_completed) && "disabled"
                                                        }`}
                                                    src={assessment.addRemove ||  DeleteImage}
                                                    alt="Cross"
                                                />)}
                                                {isSuperAdmin && !!assessment.id && (
                                                    <img
                                                        onClick={() => onClickDelete(assessment)}
                                                        className={`${"leftSpace"} deleteIcon cursor-pointer ${assessment.disabled && "disabled"
                                                            }`}
                                                        src={assessment.addRemove || DeleteImage}
                                                        alt="Cross"
                                                    />
                                                )}

                                            </span>
                                            : null
                                    }
                                </td>
                                <td className="text-white td3">
                                    {index === noDataMsgIndex && noData ? (
                                        <NoDataMessage 
                                            className={`${className} ${impDateAbove ? "imp-date-pos-above": impDateBelow ? "imp-date-pos-below" :"" }`}
                                        />
                                    ):(
                                        assessment.description
                                    )}
                                </td>

                            </tr>

                            {!isSuperAdmin && assessments.length !==1 && index===assessments.length-1 && assessment.important_date ? <ImportantDateSection text={assessment.important_date} /> : null}

                        </>
                    );
                })}
            </>
        );
    }, [postPacingCalendarLoading, scheduledDate, showAddWarning, showPDAssessmentTooltip, idx, assessments, onClickDelete, setModalSuggestedShow, isSuperAdmin, isAddDisabled, noData]);

    const [startDate, endDate] = !!dateId ? dateId?.split(" - ") : [];
    const superAdminContent = (
        <>
            <HighlightedRecord searchedValue={searchedValue} dateId={startDate} setShowModal={setRemoveModal} />
            {tableContent}
            <HighlightedRecord searchedValue={searchedValue} dateId={endDate} setShowModal={setRemoveModal} />
        </>
    );

    return <>{isSuperAdmin ? superAdminContent : tableContent}</>;
};
export default memo(AssessmentBody);