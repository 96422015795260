// @ts-nocheck
import { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import JoditEditor from "jodit-react";
import { Jodit } from 'jodit'
import { debounce } from "lodash";
import { FileImage } from "components/CMS/Assessment/RichEditor/UploadToS3";

interface IRichEditor {
    value: string;
    onChange: (value: string) => void;
    minHeight?: string;
    setSendButtonEnabled?: Function
    setIsSubmit?: Function
}

const RichEditor: FC<IRichEditor> = ({ setIsSubmit, value, onChange, minHeight, setSendButtonEnabled }: IRichEditor) => {
    const [content, setContent] = useState<string>(null!)
    const firstUpdate = useRef(true);
    
    const joditKeyDownHandler = (e: any) => {
        if(e.ctrlKey && e.keyCode === 13){
            document.getElementById("save-button")?.focus();
            setIsSubmit && setIsSubmit(true);
        }
    }

    useLayoutEffect(() => {
        const imageUpload = (editor: any) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');
            input.click();

            input.onchange = async function () {

                if (!input.files || !input.files.length) {
                    return;
                }

                const imageFile = input.files[0];

                if (!imageFile) {
                    return;
                }

                if (!imageFile.name.match(/\.(jpg|jpeg|png)$/)) {
                    return;
                }

                const imageInfo = await FileImage(imageFile);;

                insertImage(editor, imageInfo);

            };
        }


        const addQuotes = (editor: any) => {
            // TODO select editorelement with uniqe class name
            const editorelement = document.querySelectorAll('.jodit-react-container')
            const length = editorelement.length
            const focusNode = window.getSelection().focusNode
            const blockNode = isBlockElement(focusNode) ? focusNode : focusNode?.parentNode;
            const isQuoted = blockNode.id === "quotesAppliedToNode"
            console.log({ isQuoted }, editorelement, editorelement[length - 1]?.contains(blockNode))
            if (editorelement[length - 1]?.contains(blockNode)) {
                blockNode.style.border = !isQuoted ? "2px black solid" : ''
                blockNode.style.fontSize = !isQuoted ? '18px' : ''
                blockNode.style.color = !isQuoted ? '#4B4B4B' : ''
                blockNode.style.padding = !isQuoted ? '20px' : ''
                blockNode.style.display = !isQuoted ? 'flex' : ''
                blockNode.style.justifyContent = !isQuoted ? 'center' : ''
                blockNode.style.alignItems = !isQuoted ? 'center' : ''
                blockNode.style.textAlign = !isQuoted ? 'center' : ''
                blockNode.setAttribute('id', !isQuoted ? 'quotesAppliedToNode' : '')
            }
        }

        const addFraction = (editor: any) => {
            editor.selection.insertHTML(`
            <div class="math_frac">
                <span>
                    <b>&nbsp;1</b>
                </span>
                <b>
                <span class="symbol">/</span>
                <span class="bottom">
                    <b>&nbsp;2</b></span>
                </b>
            </div>`
            );
        }

        const uploadImageButton = () => {

            Jodit.defaultOptions.controls.uploadImage = {
                name: 'Insert image',
                iconURL: "https://www.svgrepo.com/show/13704/picture.svg",
                exec: (async (editor) => {
                    await imageUpload(editor);
                })
            };
        }

        const addQuotesButton = () => {

            Jodit.defaultOptions.controls.addQuotes = {
                name: 'Add Quotes',
                iconURL: "https://www.shareicon.net/data/128x128/2015/12/09/684803_button_512x512.png",
                exec: (async (editor) => {
                    addQuotes(editor);
                })
            };
        }

        const addFractionButton = () => {

            Jodit.defaultOptions.controls.addFraction = {
                name: 'Add Fraction',
                iconURL: "/half.png",
                exec: (async (editor) => {
                    addFraction(editor);
                })
            };
        }


        const getStylesByTag = (tag: string) => {

            switch (tag) {
                case 'h1':
                    return {
                        fontSize: '20px',
                        color: '#4B4B4B',
                        bold: true,
                        italic: false
                    }
                case 'h2':
                    return {
                        fontSize: '19px',
                        color: '#4B4B4B',
                        bold: true,
                        italic: false
                    }
                case 'h3':
                    return {
                        fontSize: '19px',
                        color: '#4B4B4B',
                        bold: false,
                        italic: false
                    }
                case 'blockquote':
                    return {
                        fontSize: '18px',
                        color: '#4B4B4B',
                        bold: false,
                        italic: true
                    }
                default:
                    return {
                        fontSize: '19px',
                        color: '#4B4B4B',
                        bold: false,
                        italic: false
                    }
            }

        }

        const isBlockElement = (element) => {
            try {
                const cStyle = element.currentStyle || window.getComputedStyle(element, "");
                return cStyle.display === 'block';
            } catch {
                return false
            }

        }

        Jodit.defaultOptions.controls.paragraph.list = {
            h1: 'Heading 1',
            h2: 'Heading 2',
            h3: 'Heading 3',
            blockquote: 'Byline',
            p: 'Body',
        }


        Jodit.defaultOptions.controls.paragraph.exec = (editor, current, { control }) => {
            const tag = control.args && control.args[0]
            const focusNode = window.getSelection().focusNode

            const blockNode = isBlockElement(focusNode) ? focusNode : focusNode?.parentNode;
            const editorelement = document.querySelector('.jodit-react-container')
            if (editorelement?.contains(blockNode)) {

                const { fontSize, color, bold, italic } = getStylesByTag(tag)

                if (blockNode) {
                    blockNode.style.fontSize = fontSize
                    blockNode.style.color = color
                    blockNode.style.fontFamily = 'Arimo'
                    blockNode.style.fontWeight = bold ? 'bold' : 'normal'
                    blockNode.style.fontStyle = italic ? 'italic' : 'normal'
                }
            }

            return false;
        }

        Jodit.plugins.add('keyLogger', function (jodit) {
            jodit.events.on('keyup', e => {
                joditKeyDownHandler(e);
            });
        });

        uploadImageButton()
        addQuotesButton()
        addFractionButton()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    // some dirty work to handle editor content
    useEffect(() => {
        if (value && value !== content) {
            firstUpdate.current = true
            updateState(value)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])


    useEffect(() => {

        if (!firstUpdate.current && value !== content) {
            onChange(content)
            // setIsSaveEnable && setIsSaveEnable(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content])


    const EditConfig = useMemo(() => ({
        readonly: false,
        defaultFontSizePoints: "pt",
        placeholder: '',
        removeButtons: ['video', 'file', 'print', 'link', 'source', 'about', 'image'],
        removeButtonsMD: ['video', 'file', 'print', 'link', 'source', 'about', 'image'],
        removeButtonsSM: ['video', 'file', 'print', 'link', 'source', 'about', 'image'],
        removeButtonsXS: ['video', 'file', 'print', 'link', 'source', 'about', 'image'],
        buttons: "bold,italic,underline,strikethrough,eraser,ul,ol,fontsize,paragraph,classSpan,align,lineHeight,superscript,subscript,spellcheck,copyformat,cut,fullsize,source",
        extraButtons: ["uploadImage", "addQuotes", "addFraction"],
        minHeight: minHeight,
        showCharsCounter: false,
        showWordsCounter: false,
        showXPathInStatusbar: false,
        autofocus: false,
        controls: {
            classSpan: {
                list: {
                    enabled: 'enabled',
                    disabled: 'disabled',
                    activated: 'activated',
                    warning: 'warning',
                    error: 'error'

                }
            }
        },
        style: {
            fontFamily: 'Nunito, sans-serif',
            fontSize: '16px',
            color: '#464646'
        }
    }), [minHeight])


    const insertImage = (editor: any, url: any) => {
        const image = editor.selection.j.createInside.element('img');
        image.setAttribute('src', url);
        editor.selection.insertNode(image);
    }
    const htmlToElement = (html) => {
        var template = document.createElement('template');
        html = html?.trim(); // Never return a text node of whitespace as the result
        template.innerHTML = html;

        return template.content;
    }

    const ElementTohtml = (html) => {
        const tmp = document.createElement("div");
        tmp.appendChild(html);
        return tmp.innerHTML?.trim()
    }

    const formatContant = (content: string) => {
        const div = htmlToElement(content);
        const math = div.querySelectorAll(".math_frac")

        Array.from(math).forEach(fraction => {
            const prevElement = fraction.previousElementSibling
            const nextElement = fraction.nextElementSibling
            console.log("NEXT : ", { nextElement })
            if (prevElement && prevElement.nodeName === 'P') {
                fraction.previousElementSibling?.classList.add('math_frac_inline')
            }
            if (nextElement && nextElement.nodeName === 'P') {
                fraction.nextElementSibling?.classList.add('math_frac_inline')
            }

            const isSpanRequired = ['spanNextToFraction', 'math_frac_inline'].some(className => nextElement?.className.includes(className))
            console.log({ isSpanRequired })
            if (!nextElement || !isSpanRequired) {
                console.log("Inserting")
                const newSpanElement = document.createElement('span')
                newSpanElement.classList.add('spanNextToFraction')
                newSpanElement.innerText = "."
                fraction.insertAdjacentElement('afterend', newSpanElement)
            }

        })

        content = ElementTohtml(div)
        setContent(content)
    }

    const updateState = debounce((content) => {
        formatContant(content)
    }, 0)

    return (
        <Row className="w-100 mx-auto">
            <Col className="text-white p-0">
                <div
                    onClick={() => {
                        firstUpdate.current = false;
                    }}
                >
                    <JoditEditor
                        value={content}
                        config={EditConfig}
                        onChange={(newContent) => {
                            if(!firstUpdate.current){
                                newContent === '<p><br></p>' || newContent === ''
                                ? setSendButtonEnabled(false)
                                : setSendButtonEnabled(true)
                            }
                        }}
                        onBlur={(newContent) => {
                            updateState(newContent)
                        }}
                        tabIndex={1}
                    />
                </div>
            </Col>
        </Row>
    );
}

export default RichEditor;