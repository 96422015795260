import React from "react";
import Theme from "components/theme/index";
import IPage from "interfaces/page";
import SupportTicketSuperAdminProvider from "contexts/supportTicketSuperAdmin";
import SupportChatCenter from "components/SupportChatCenter";

const SupportCenter: React.FunctionComponent<IPage> = (props) => {
  return (
    <Theme>
      <SupportTicketSuperAdminProvider>
        <SupportChatCenter />
      </SupportTicketSuperAdminProvider>
    </Theme>
  );
};
export default SupportCenter;