import ReportHeader from "components/common/report/header";
import SearchIconGray from "assets/img/search-icon-gray.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  auditReportSelectror,
  getAuditReport,
} from "redux/actionCreators/management";
import { useEffect, useMemo, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import TableHeader from "./Table/header";
import InfoSection from "./infoSection";
import TableBody from "./Table/body";
import AuditPrint from "./Print";
import { AuditReportBodySkeleton } from "components/common/skeleton/ReportsSkeleton";
import ActionType from "redux/actionTypes";
import { useQuery } from "hooks/useQuery";

const Report = () => {
  const report = useSelector(auditReportSelectror);
  const [search, setSearch] = useState<string>("");
  const dispatch = useDispatch();
  const query = useQuery();
  const print = query.get("print");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { result, total_students } = useMemo(() => {
    if (report?.result) {
      const { result } = report;
      setIsLoading(false);
      return {
        ...report,
        result: search.length
          ? result.filter((record: any) =>
              record.school_name.toLowerCase().includes(search.toLowerCase())
            )
          : report.result,
      };
    }
    return { result: [], total_students: 0 };
  }, [report, search]);
  const componentRef = useRef(null);

  console.log({ total_students });
  useEffect(() => {
    dispatch(getAuditReport());
    return () => {
      dispatch({
        type: ActionType.SET_AUDIT_REPORT,
        payload: null,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `AuditReport_${moment(new Date()).format("M/DD/YY hh:mmA")}`,
    removeAfterPrint: true,
    onAfterPrint: () => {
      print && window.close();
    },
  });

  useEffect(() => {
    if (report && print) {
      handlePrint();
    }
  }, [report, handlePrint, print]);

  return (
    <div>
      {!print && (
        <div className="auditReport hide-on-print">
          <ReportHeader
            reportTitle="Audit Report"
            setPrint={handlePrint}
            headerClassName="report__transparent_header"
          />

          <div className="auditReport__mainContainer">
            <div className="auditReport__header-info">
              <div className="auditReport__search">
                <div className="auditReport__search-bar d-flex">
                  <img
                    src={SearchIconGray}
                    className="report__search-icon animated-fade"
                    alt=""
                  />
                  <input
                    className="auditReport__search-input"
                    type="search"
                    name="Search"
                    placeholder="Search Schools"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
              <InfoSection total_students={total_students} />
            </div>

            <div className="auditReport__overflow">
              <table className="auditReport__table">
                <thead className={`auditREport__table__head`}>
                  <TableHeader />
                </thead>
                <tbody>
                  {isLoading ? (
                    <AuditReportBodySkeleton rows={20} columns={6} />
                  ) : (
                    result?.map((record: any) => <TableBody record={record} />)
                  )}
                </tbody>
              </table>
              {!isLoading && !result.length && (
                <div className="mt-4">
                  <div className="dark-gray">
                    <div className="report__table-notFound d-flex justify-content-center">
                      No Records Found
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="auditReport printData" ref={componentRef}>
        <AuditPrint records={result} total_students={total_students} />
      </div>
    </div>
  );
};
export default Report;
