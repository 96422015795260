import {
  PacingCalenderContextProvider,
  PacingCalenderMonth,
  PacingAssessments,
  SuggestedAssessment,
  ScheduledAssessments,
  AdminPacingCalenderDates,
  GradeSubject,
} from "interfaces/pacingCalender";
import moment from "moment";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import {
  deletePacingCalenderAssessments,
  deletePacingDates,
  getPacingCalenderScheduledAssessments,
  getPacingCalenderAssessments,
  getPacingCalenderDates,
  getPacingCalenderMonths,
  getPacingCalenderObjectives,
  getPacingCalenderSuggestedAssessments,
  getPacingGradesSubjects,
  getSuperAdminSuggestedPacing,
  postPacingCalender,
  postPacingDates,
  postSuperAdminSuggestedPacing,
  putSuggestedPacingCalender,
  deleteSuperAdminPacingCalenderAssessments,
  editPacingDates,
} from "services/pacingCalender";
import { createDate } from "util/createDate";

const defaultPacingProvider: PacingCalenderContextProvider = {
  editPacingCalenderDates: null!,
  isAnyAssessment: false,
  fetchUserPacingCalendarSuggestedAssessments: null!,
  updateOrderBy: null!,
  pacingCalenderPostReq: null!,
  putPacingCalender: null!,
  suggestedAssessments: {
    pacings: [],
    loading: false,
  },
  setSuggestedAssessments: null!,
  currentMonth: null!,
  clearAssessments: null!,
  allMonths: [],
  updateCurrentMonth: null!,
  currentMonthIndex: 0,
  handleNextPreviousMonth: null!,
  assessments: {
    loading: true,
    pacing_calendar_dates: [],
    pacing_calendar_exists: null!,
    first_month: "",
    pacing_tooltips_seen: true,
    enable_assessment: false,
    all_math_classes: false,
    automatically_enable_scheduled: false,
    sharing_schedule: false,
    is_random: false,
    admin_name: "",
    grade: "",
    subject: "",
    same_classroom_exists: false,
  },
  scheduledAssessments: {
    loading: false,
    date: null!,
    formatives: [],
    summatives: [],
    has_suggested: false,
  },
  scheduledDate: "",
  setScheduledDate: null!,
  isSuperAdmin: false,
  adminPacingCalenderDates: {
    count: 0,
    important_dates: [],
  },
  currentDateIndex: 0,
  currentGradeIndex: 0,
  handleNextPreviousGrade: null!,
  handleNextPreviousDate: null!,
  updateCurrentDate: null!,
  gradeSubjects: [],
  search: "",
  updateSearch: null!,
  updateCurrentGrade: null!,
  postPacingCalenderDates: null!,
  deletePacingCalenderDates: null!,
  classroomId: null!,
  orderBy: null!,
  isAddDisabled: false,
  _setCurrentMonth: null!,
  setSuggestedPacingFetched: null!,
  suggestedPacingFetched: false,
  setSuggestedPacingCopyModal: null!,
  suggestedPacingCopyModal: null!,
  postPacingCalendarLoading: false,
  setAssesments: () => {},
};

export const PacingCalenderContext =
  createContext<PacingCalenderContextProvider>(defaultPacingProvider);

const PacingCalenderProvider = ({ children }: any) => {
  const location = useLocation();
  const isSuperAdmin = useMemo(
    () => location.pathname.includes("super-admin"),
    [location]
  );

  const sessionClassroomId = sessionStorage.getItem("c_classroom_id") || null!;
  const classroomId = parseInt(sessionClassroomId)
    ? sessionClassroomId
    : isSuperAdmin
    ? `${6194}`
    : null;

  const [allMonths, setAllMonths] = useState<Array<PacingCalenderMonth>>(
    defaultPacingProvider.allMonths
  );
  const [currentMonthIndex, setCurrentMonthIndex] = useState<number>(
    defaultPacingProvider.currentMonthIndex
  );
  const [suggestedPacingCopyModal, setSuggestedPacingCopyModal] = useState(
    null!
  );
  const [currentDateIndex, setCurrenDateIndex] = useState<number>(
    defaultPacingProvider.currentDateIndex
  );
  const [currentGradeIndex, setCurrentGradeIndex] = useState<number>(
    defaultPacingProvider.currentGradeIndex
  );
  const [assessments, setAssesments] = useState<PacingAssessments>(
    defaultPacingProvider.assessments
  );
  const [suggestedAssessments, setSuggestedAssessments] = useState<{
    pacings: SuggestedAssessment[];
    loading: boolean;
  }>(defaultPacingProvider.suggestedAssessments);
  const [scheduledAssessments, setScheduledAssessments] =
    useState<ScheduledAssessments>(defaultPacingProvider.scheduledAssessments);
  const [scheduledDate, setScheduledDate] = useState<string>("");
  const [adminPacingCalenderDates, setAdminPacingCalenderDates] =
    useState<AdminPacingCalenderDates>(
      defaultPacingProvider.adminPacingCalenderDates
    );
  const [gradeSubjects, setGradeSubjects] = useState<Array<GradeSubject>>(
    defaultPacingProvider.gradeSubjects
  );
  const [search, setSearch] = useState<string>("");
  const [currentMonth, setCurrentMonth] = useState<any>(null!);
  const [classroomMonth, setClassroomMonth] = useState<any>(null!);
  const [isAddDisabled, setIsAddDisabled] = useState(false);
  const [suggestedPacingFetched, setSuggestedPacingFetched] = useState(false);
  const [postPacingCalendarLoading, setPostPacingCalendarLoading] = useState(
    defaultPacingProvider.postPacingCalendarLoading
  );

  const currentSubject =
    gradeSubjects.length > 0 ? gradeSubjects[currentGradeIndex] : "";

  const isAnyAssessment = useMemo(() => {
    const pacings = isSuperAdmin
      ? suggestedAssessments.pacings
      : assessments.pacing_calendar_dates;

    if (!Array.isArray(pacings)) return false;
    return pacings.some((pacing: any) => {
      return Array.isArray(pacing.assessments)
        ? pacing.assessments.some((assessment: any) => !!assessment.id)
        : false;
    });
  }, [assessments, suggestedAssessments, isSuperAdmin]);

  const _setCurrentMonth = (monthsArray: any[], currentMonth: string) => {
    if (currentMonth) {
      const findCurrentMonthIndex = monthsArray.findIndex((month) => {
        return month.month === currentMonth;
      });
      const monthIndex =
        findCurrentMonthIndex > -1 && !isSuperAdmin ? findCurrentMonthIndex : 0;
      setCurrentMonthIndex(monthIndex);
      setCurrentMonth(monthsArray.length > 0 ? monthsArray[monthIndex] : null!);
      setClassroomMonth(currentMonth);
    }
  };

  const fetchMonths = async () => {
    const response = await getPacingCalenderMonths({ classroomId });
    const { months, current } = response || {};

    _setCurrentMonth(months, current.month);

    setIsAddDisabled(false);

    months && setAllMonths(months);
  };

  // const getUpdatedMonthForClassroom = async () => {
  //     const { current} = await getPacingCalenderMonths({classroomId});

  //     const findCurrentMonthIndex = allMonths.findIndex(month => {
  //         return month.month === current.month && month.year === current.year;
  //     });

  //     setCurrentMonthIndex(findCurrentMonthIndex > -1 ? findCurrentMonthIndex: 0);
  // }

  const [orderBy, setOrderBy] = useState<any>(isSuperAdmin ? "suggested" : "");

  const updateOrderBy = (value: any) => {
    setOrderBy(value);
  };
  const updateSearch = (value: string) => {
    setSearch(value);
  };

  const fetchUserPacingCalendarSuggestedAssessments = useCallback(async () => {
    if (!classroomId || orderBy === null || !scheduledDate) {
      return;
    }

    const assessments = await getPacingCalenderScheduledAssessments({
      classroomId,
      date: scheduledDate,
      order: orderBy,
    });
    if (!assessments) {
      return;
    }

    const fixedData = Array.isArray(assessments)
      ? {
          loading: false,
          date: null!,
          formatives: [],
          summatives: [],
          has_suggested: false,
        }
      : Object.keys(assessments || {}).length === 0
      ? {
          loading: false,
          date: null!,
          formatives: [],
          summatives: [],
          has_suggested: false,
        }
      : assessments;

    assessments && setScheduledAssessments({ ...fixedData, loading: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, scheduledDate]);

  const fetchDates = async () => {
    if (!isSuperAdmin) return;

    const response = await getPacingCalenderDates();
    response && setAdminPacingCalenderDates(response);
  };

  const fetchSubjectGrades = async () => {
    if (!isSuperAdmin) return;

    const { grade_subjects_array } = await getPacingGradesSubjects(
      classroomId as any
    );
    setGradeSubjects(grade_subjects_array);
  };

  const fetchScheduledAssessments = useCallback(async () => {
    if (
      !classroomId ||
      gradeSubjects.length <= 0 ||
      !scheduledDate ||
      orderBy === null
    )
      return;

    setScheduledAssessments((prev: any) => ({ ...prev, loading: true }));
    const { grade_id, subject_id } = gradeSubjects[currentGradeIndex];
    const response = await getPacingCalenderObjectives({
      subjectId: subject_id,
      gradeId: grade_id,
      date: scheduledDate,
      order: orderBy,
    });
    response && setScheduledAssessments({ ...response, loading: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduledDate, orderBy, gradeSubjects, currentGradeIndex]);

  const getPacingCalendarNewState = (prevpacings: any[], assessment?: any) => {
    return !assessment
      ? prevpacings.map((pacing: any) => {
          return {
            ...pacing,
            assessments: pacing.assessments
              .filter((item: any, index: number) => {
                const isAlreadyExists = pacing.assessments
                  .slice(0, index)
                  .some((assess: any) => assess.date === item.date);
                return !isAlreadyExists;
              })
              .map((item: any) => {
                return {
                  ...item,
                  id: null,
                  summative_name: "",
                  objective_name: "",
                  objective_full_name: "",
                  summative_id: 0,
                  objective_id: 0,
                  description: "",
                };
              }),
          };
        })
      : prevpacings.map((pacing: any) => {
          if (pacing.id === assessment.date_id) {
            const isMoreThanOne =
              pacing.assessments.filter(
                (item: any) => item.date === assessment.date
              ).length > 1;
            const filteredAssessments = pacing.assessments.filter(
              (item: any) => item.id !== assessment.id
            );
            return {
              ...pacing,
              assessments: assessment.isAlreadyExists
                ? filteredAssessments
                : isMoreThanOne
                ? filteredAssessments.map((item: any) => {
                    let isFound = false;
                    if (!isFound && item.id === assessment.id) {
                      isFound = true;
                      return {
                        ...item,
                        isAlreadyExists: false,
                      };
                    }
                    return item;
                  })
                : pacing.assessments.map((item: any) => {
                    return item.id === assessment.id
                      ? {
                          ...item,
                          id: null,
                          summative_name: "",
                          objective_name: "",
                          objective_full_name: "",
                          summative_id: 0,
                          objective_id: 0,
                          description: "",
                        }
                      : item;
                  }),
            };
          }

          return pacing;
        });
  };

  const deleteSuperAdminAssessment = async (
    assessment?: any,
    month?: string
  ) => {
    const gradeAndSubject = gradeSubjects[currentGradeIndex];

    const res = await deleteSuperAdminPacingCalenderAssessments({
      ...(assessment && { assessmentId: assessment.id }),
      month,
      gradeId: gradeAndSubject.grade_id,
      subjectId: gradeAndSubject.subject_id,
    });

    res &&
      res.deleted &&
      setSuggestedAssessments((prev: any) => {
        return {
          ...prev,
          pacings: getPacingCalendarNewState(prev.pacings, assessment),
        };
      });

    return res && res.deleted;
  };

  const clearAssessments = async (assessment?: any, type?: "all") => {
    const month = type === "all" ? type : currentMonth?.month.toLowerCase();

    if (!classroomId && !isSuperAdmin) {
      return;
    }

    if (isSuperAdmin) {
      const isDeleted = await deleteSuperAdminAssessment(assessment, month);
      return isDeleted;
    }

    const res = await deletePacingCalenderAssessments({
      ...(assessment && { assessmentId: assessment.id }),
      classroomId,
      month,
    });

    res &&
      res.deleted &&
      setAssesments((prev: any) => {
        return {
          ...prev,
          pacing_calendar_dates: getPacingCalendarNewState(
            prev.pacing_calendar_dates,
            assessment
          ),
        };
      });

    return res && res.deleted;
  };

  const fetchAssesments = useCallback(async () => {
    if (isSuperAdmin || !classroomId || currentMonth === null) return;

    setAssesments((prev: any) => ({ ...prev, loading: true }));
    const { month, year } = currentMonth;
    const response = await getPacingCalenderAssessments({
      month,
      year,
      classroomId,
      search,
    });
    setAssesments((prev) => ({
      ...(response ? response : prev),
      loading: false,
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMonth, search]);

  const fetchPacingCalenderSuggestedAssessments = async (payload: any) => {
    setSuggestedAssessments({ pacings: [], loading: true });
    const assessments = await getPacingCalenderSuggestedAssessments(payload);
    setSuggestedAssessments((prev) => ({
      pacings: assessments ? assessments : prev.pacings,
      loading: false,
    }));
    assessments &&
      suggestedPacingCopyModal === false &&
      setSuggestedPacingFetched(true);
  };

  const fetchAdminPacingCalenderSuggestedAssessment = useCallback(async () => {
    if (!currentMonth) {
      return;
    }

    setSuggestedAssessments({ pacings: [], loading: true });
    const { subject_id, grade_id } = gradeSubjects[currentGradeIndex];
    const { month, year } = currentMonth;

    const assessments = await getSuperAdminSuggestedPacing({
      subjectId: subject_id,
      gradeId: grade_id,
      month,
      year,
    });

    if (!assessments || !Array.isArray(assessments)) {
      return;
    }

    const modifiedAssessments = assessments.map((pacing: any) => {
      const pacingData =
        Object.keys(pacing || {}).length === 0
          ? { assessments: [], id: "", name: "" }
          : pacing;
      return {
        ...pacingData,
        assessments: Array.isArray(pacingData.assessments)
          ? pacingData.assessments
          : [],
      };
    });

    setSuggestedAssessments({ pacings: modifiedAssessments, loading: false });
  }, [currentMonth, currentGradeIndex, gradeSubjects]);

  const putPacingCalender = async () => {
    if (!classroomId) {
      return;
    }

    const { month, year } = allMonths[currentMonthIndex];

    const res = await putSuggestedPacingCalender({
      classroomId,
      month,
      year: year.toString(),
    });
    res && setAssesments(res);

    return !!res;
  };

  const postSuperAdminSuggestedPacings = async (payload: any) => {
    const res = await postSuperAdminSuggestedPacing(payload);
    res &&
      res.suggested_pacing_calendar &&
      setSuggestedAssessments({
        pacings: res.suggested_pacing_calendar,
        loading: false,
      });
  };

  const postTeacherPacings = async (payload: any) => {
    setPostPacingCalendarLoading(true);
    const response = (await postPacingCalender(payload)) as any;
    setPostPacingCalendarLoading(false);
    response && setAssesments(response);
  };

  const pacingCalenderPostReq = async (data: any) => {
    if (!classroomId) {
      return;
    }

    const date = moment(scheduledDate)
      .set("year", allMonths[currentMonthIndex].year)
      .format("YYYY-MM-DD");
    const payload = {
      classroomId,
      ...data,
      ...(isSuperAdmin && {
        grade_id: gradeSubjects[currentGradeIndex].grade_id,
        subject_id: gradeSubjects[currentGradeIndex].subject_id,
      }),
      scheduled_date: date,
    };
    isSuperAdmin
      ? postSuperAdminSuggestedPacings(payload)
      : postTeacherPacings(payload);
  };

  const editPacingCalenderDates = async (data: any) => {
    const { dates } = adminPacingCalenderDates.important_dates.find(
      (date) => date.month === currentMonth?.month || ""
    );
    const importantDate = dates.find((date: any) => date.date === data.date);

    const payload = {
      title: data.title,
      date_id: importantDate.id,
    };

    const response = await editPacingDates(payload);
    response && setAdminPacingCalenderDates(response);

    return !!response;
  };

  const postPacingCalenderDates = async (data: any) => {
    const { name, position, ...currGradeSubject } = currentSubject as any;
    const payload = {
      ...currGradeSubject,
      is_global: true,
      ...data,
    };
    const response = await postPacingDates(payload);
    response && setAdminPacingCalenderDates(response);

    return !!response;
  };

  const deletePacingCalenderDates = async (dateId: any) => {
    const { dates } = adminPacingCalenderDates.important_dates.find(
      (date) => date.month === currentMonth?.month || ""
    );
    const formattedDate = createDate(allMonths, currentGradeIndex, dateId);
    const importantDate = dates.find(
      (date: any) => date.date === formattedDate
    );
    const res = await deletePacingDates(importantDate.id);

    if (res && res.deleted) {
      setAdminPacingCalenderDates((prev) => ({
        ...prev,
        count: prev.count - 1,
        important_dates: prev.important_dates.map((item: any) => {
          if (item.month === currentMonth?.month || "") {
            return {
              ...item,
              dates: item.dates.filter(
                (data: any) => data.id !== importantDate.id
              ),
            };
          }

          return item;
        }),
      }));
    }

    return res.deleted;
  };

  const isPrevMonth = (index: number) => {
    const classroomMonthIndex = allMonths.findIndex(
      (item: any) => item.month === classroomMonth?.month
    );

    if (classroomMonthIndex === -1) {
      return false;
    }

    return index < classroomMonthIndex;
  };

  const checkIsAddDisbaled = (index: number) => {
    const isPreviousMonth = isPrevMonth(index);
    setIsAddDisabled(isPreviousMonth && !isSuperAdmin);
  };

  const updateCurrentMonth = (index: number) => {
    setCurrentMonthIndex(index);
    const selectedMonth = allMonths.length > 0 ? allMonths[index] : null!;
    setCurrentMonth(selectedMonth);
    checkIsAddDisbaled(index);
  };

  const updateCurrentDate = (index: number) => {
    setCurrenDateIndex(index);
  };

  const updateCurrentGrade = (index: number) => {
    setCurrentGradeIndex(index);
  };

  const handleNextPreviousMonth = (direction: string) => {
    //right icon clicked
    if (direction === "right" && currentMonthIndex < 11) {
      setCurrentMonthIndex((prev) => prev + 1);
      const selectedMonth =
        allMonths.length > 0 ? allMonths[currentMonthIndex + 1] : null!;
      setCurrentMonth(selectedMonth);
      checkIsAddDisbaled(currentMonthIndex + 1);
    }
    //left icon clicked
    if (direction === "left" && currentMonthIndex > 0) {
      setCurrentMonthIndex((prev) => prev - 1);
      const selectedMonth =
        allMonths.length > 0 ? allMonths[currentMonthIndex - 1] : null!;
      setCurrentMonth(selectedMonth);
      checkIsAddDisbaled(currentMonthIndex - 1);
    }
  };

  const handleNextPreviousGrade = (direction: string) => {
    //right icon clicked
    direction === "right" &&
      currentGradeIndex < gradeSubjects.length - 1 &&
      setCurrentGradeIndex((prev) => prev + 1);
    //left icon clicked
    direction === "left" &&
      currentGradeIndex > 0 &&
      setCurrentGradeIndex((prev) => prev - 1);
  };

  // useEffect(() => {
  //     if(!classroomId || isSuperAdmin){
  //         return;
  //     }

  //     fetchMonths();

  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[classroomId, isSuperAdmin])

  useEffect(() => {
    if (location?.state && allMonths.length > 0) {
      const date = location?.state.date;
      const prevMonth =
        location?.state.prevMonth ||
        moment(date && date !== "Not Scheduled" ? date : undefined).format(
          "MMMM"
        );
      const findMonthIndex = allMonths.findIndex(
        (item) => item.month === prevMonth
      );
      findMonthIndex !== -1 && updateCurrentMonth(findMonthIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, allMonths]);

  useEffect(() => {
    fetchMonths();
    fetchDates();
    classroomId && fetchSubjectGrades();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuperAdmin, classroomId]);

  useEffect(() => {
    isSuperAdmin && fetchScheduledAssessments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchScheduledAssessments, isSuperAdmin]);

  useEffect(() => {
    !isSuperAdmin && fetchUserPacingCalendarSuggestedAssessments();
  }, [fetchUserPacingCalendarSuggestedAssessments, isSuperAdmin]);

  useEffect(() => {
    // for non-super-admin-route
    !isSuperAdmin && fetchAssesments();
  }, [fetchAssesments, isSuperAdmin]);

  useEffect(() => {
    if (!isSuperAdmin) {
      const subjectId = sessionStorage.getItem("c_subject_id");
      const gradeId = sessionStorage.getItem("c_grade_id");

      subjectId &&
        gradeId &&
        currentMonth &&
        fetchPacingCalenderSuggestedAssessments({
          subjectId,
          gradeId,
          month: currentMonth?.month || "",
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMonth]);

  useEffect(() => {
    // call suggested pacings for admin
    const totalMonths = allMonths.length;
    const totalGrades = gradeSubjects.length;
    if (isSuperAdmin) {
      totalMonths &&
        totalGrades &&
        fetchAdminPacingCalenderSuggestedAssessment();
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMonths, fetchAdminPacingCalenderSuggestedAssessment, isSuperAdmin]);

  return (
    <PacingCalenderContext.Provider
      value={{
        isAddDisabled,
        editPacingCalenderDates,
        isAnyAssessment,
        fetchUserPacingCalendarSuggestedAssessments,
        orderBy,
        pacingCalenderPostReq,
        putPacingCalender,
        setSuggestedAssessments,
        suggestedAssessments,
        currentMonth,
        clearAssessments,
        allMonths,
        updateCurrentMonth,
        currentMonthIndex,
        handleNextPreviousMonth,
        assessments,
        scheduledAssessments,
        scheduledDate,
        setScheduledDate,
        isSuperAdmin,
        adminPacingCalenderDates,
        currentDateIndex,
        updateCurrentGrade,
        currentGradeIndex,
        handleNextPreviousGrade,
        handleNextPreviousDate: null!,
        updateCurrentDate,
        gradeSubjects,
        search,
        updateSearch,
        postPacingCalenderDates,
        deletePacingCalenderDates,
        classroomId,
        updateOrderBy,
        _setCurrentMonth,
        suggestedPacingFetched,
        setSuggestedPacingFetched,
        setSuggestedPacingCopyModal,
        suggestedPacingCopyModal,
        postPacingCalendarLoading,
        setAssesments,
      }}
    >
      {/* allmonths.length added because don't want to render below until months fetched*/}
      {children}
    </PacingCalenderContext.Provider>
  );
};
export default PacingCalenderProvider;
