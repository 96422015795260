import CustomModal from "components/common/Modal";
import { FC } from "react";


interface Props {
    isShow: boolean;
    closeModal: () => void;
}
const ClassesModal: FC<Props> = ({isShow , closeModal}) => {
    return (
        <CustomModal centered show={isShow} onHide={closeModal}>

            <div className="modal__header">
                <h6 className="modal__header-title">Low Proficiency Classes</h6>
            </div>
            <div className="modal__body">
                <p className="modal__body-text mb-0">Objectives with the most students not proficient. Classes with most objectives at risk are noted at bottom of report.</p>
            </div>
            <div className="modal__footer">
                <button
                    className="btn success-btn"
                    onClick={closeModal}
                >
                    Got it
                </button>
            </div>
        </CustomModal>
    )
}
export default ClassesModal