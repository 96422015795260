import Header from "components/CMS/SelectAssessment/Header";
import AssessmentModal from 'components/CMS/SelectAssessment/selectAssessmentModel';
import CMSRow from 'components/CMS/SelectAssessment/CMSRow';
import Footer from 'components/CMS/SelectAssessment/Footer';
import Layout from "components/CMS/Layout";

const CMSSelectAssessment = () => {

    return(
        <Layout>
            <Header />
            <CMSRow />
            <AssessmentModal />
            <Footer />
        </Layout>
    );
}

export default CMSSelectAssessment;