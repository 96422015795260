export const enum Status {
    Attention_Needed = 'Attention Needed',
    On_Track = 'On Track'
}

const AnalysisHeader = ({status, title}: any) => {

    return (
        <div className="anylasisHeader">
            <p className="anylasisHeader__name">{title}</p>
            <p className={`anylasisHeader__tag ${status === Status.On_Track ? 'onTrack' : 'attention'}`}>{status || ''}</p>
        </div>
    )
};

export default AnalysisHeader;
