/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import { Button } from "../../common/buttonComponent";
import CustomModal from "components/common/Modal";

interface SelectTestTypeModalProps {
  isShow: boolean;
  onClose: () => void;
  loading: boolean;
  title?: string;
}
export const NoStudents: React.FC<SelectTestTypeModalProps> = ({
  isShow,
  onClose,
  loading,
  title
}) => {

  return (
    <CustomModal centered show={isShow} onEscapeKeyDown={onClose}>
      <div className="modal__header">
        <h6 className="modal__header-title">{title || "Monitor Disabled"}</h6>
      </div>
      <div className="modal__body">
        <div className="status">
          <p>
            This classroom currently has no students. Monitoring is not
            available.
          </p>
        </div>
      </div>
      <div className="modal__footer">
        <Button
          buttonText="Close"
          onClick={onClose}
          className="btn cancel-btn"
          disabled={loading}
          type="button"
        />
      </div>
    </CustomModal>
  );
};
