import Raphael from './raphael.min'
import RedMarker from 'assets/img/red-marker.svg';
import Eraser from "assets/img/eraser.svg";

var g_masterPathArray;
var g_masterDrawingBox;
var masterBackground
var g_masterPaper
var st
var fun_setIsScreenMarked
const markerStyle = { stroke: "#EC7063", 'stroke-width': 3}

var start = function () {
    g_masterPathArray = [];
},
move = function () {
    if (g_masterPathArray.length === 0) {
        g_masterPathArray[0] = ["M", this.ox, this.oy+12];
        g_masterDrawingBox = g_masterPaper.path(g_masterPathArray);
        g_masterDrawingBox.attr(markerStyle);
    }
    else
        g_masterPathArray[g_masterPathArray.length] = ["L", this.ox, this.oy+12];

    g_masterDrawingBox.attr({ path: g_masterPathArray });
},
up = function () {
    st.push(g_masterDrawingBox)
};

export function initDrawing() {
    g_masterPaper = Raphael(0, 0, window.innerWidth, window.innerHeight);

    masterBackground = g_masterPaper.rect(0, 140, window.innerWidth, window.innerHeight);

    masterBackground.attr({ stroke: 0, fill: "#eee", 'fill-opacity': 0 });

    st = g_masterPaper.set();

    return g_masterPaper;
}

export function bindMouseEvnt(event) {
    var evt = event;
    var IE = document.all ? true : false;
    var x, y;
    if (IE) {
        x = evt.clientX + document.body.scrollLeft +
            document.documentElement.scrollLeft;
        y = evt.clientY + document.body.scrollTop +
            document.documentElement.scrollTop;
    }
    else {
        x = evt.pageX;
        y = evt.pageY;
    }

    // subtract paper coords on page
    this.ox = x-10;
    this.oy = y-10;
}


export function bindDrawing(setIsScreenMarked) {
    unbindEraser();
    g_masterPaper.canvas.id = "sketch-container"
    g_masterPaper.canvas.style['pointer-events'] = 'auto';
    g_masterPaper.canvas.style['cursor'] = `url(${RedMarker}) 14 41, move`
    g_masterPaper.canvas.style['z-index'] = 99
    g_masterPaper.canvas.style['top'] = 0
    masterBackground.drag(move, start, up);
    masterBackground.mousemove(bindMouseEvnt);
    fun_setIsScreenMarked = (event) => {
        setIsScreenMarked(true);
    }
    masterBackground.mouseup(fun_setIsScreenMarked);
}

export function unbindDrawing() {
    g_masterPaper.canvas.style['pointer-events'] = 'none'
    // g_masterPaper.canvas.style['cursor'] = 'default'
    masterBackground.unmouseup(fun_setIsScreenMarked);
    masterBackground.unmousemove(bindMouseEvnt);
    masterBackground.drag(move, start, up);
}

export function eraseDrawing() {
    st.forEach(function (el) {
        el?.remove();
    });

    st.clear()
}

export const setMarkings = (penMarkings) => {
    if (!penMarkings)
        return

    eraseDrawing()

    penMarkings?.forEach(function (paths) {
        g_masterDrawingBox = g_masterPaper.path(paths).attr(markerStyle);
        st.push(g_masterDrawingBox)
    });
}

export const getMarkings = () => {
    const penMarkings = [];

    st?.forEach(function (el) {
        el?.attrs?.path && penMarkings.push(el.attrs.path);
    });

    return penMarkings
}

export const bindEraser = (setIsScreenMarked) => {
    unbindDrawing();
    masterBackground.undrag()
    g_masterPaper.canvas.style['pointer-events'] = 'auto';
    g_masterPaper.canvas.style['cursor'] = `url(${Eraser}) 14 41, move`;
    g_masterPaper.canvas.style['z-index'] = 99;
    g_masterPaper.canvas.style['top'] = 0;
    // g_masterPaper.canvas.style['cursor'] = 'default';
    masterBackground.mousemove(bindMouseEvnt);
    function start(){

    }

    function up(){
        
    }
    masterBackground.drag(eraseMarkings, start, up);
    
}

export function unbindEraser() {
    g_masterPaper.canvas.style['pointer-events'] = 'none'
    g_masterPaper.canvas.style['cursor'] = 'default';
    masterBackground.unmousemove(bindMouseEvnt);
    masterBackground.undrag();
}

let prevPoint = null;
export function eraseMarkings(){
    if(!prevPoint){
        prevPoint = {x: this.ox, y: this.oy+12}
        return;
    }

    const x=this.ox, y=this.oy+12;

    st?.forEach(function (el) {
        let isDelete = false;
        const filteredPath = el.attrs?.path?.map((item, index) => {
            if(index === 0){
                return item;
            }

            const prevItem = el.attrs.path[index-1];
            
            const intersection = findIntersection(prevPoint.x, prevPoint.y, x,y, prevItem[1], prevItem[2], item[1], item[2])
            if(!intersection){
                return item;
            }

            isDelete = true;
            item[0] = "M";
            prevItem[0] = "M";
    
            return item;
        });
        filteredPath?.length && el.attr({ path: filteredPath });
        // const isModified = filteredPath?.every(item => item[0] === 'M');
        if(isDelete){
            // setIsScreenMarked(true);
            el.remove();
        }
    });
    
    prevPoint = {x, y}
}

function findIntersection(x1, y1, x2, y2, x3, y3, x4, y4) {
    let denominator = null;
    // Check if none of the lines are of length 0
    if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
        return false
    }
  
    denominator = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1))
  
    // Lines are parallel
    if (denominator === 0) {
        return false
    }
  
    let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator
    let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator
  
    // is the intersection along the segments
    if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
        return false
    }
  
    // Return a object with the x and y coordinates of the intersection
    let x = x1 + ua * (x2 - x1)
    let y = y1 + ua * (y2 - y1)

    return {x: Math.floor(x), y: Math.floor(y)}
}