import React from "react";

const OverlapingRectangles = () => {

  const titleId = "overLapingRectanglesIcon"

  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    fill="none"
    aria-label="accordion"
    aria-labelledby={titleId}
  >
    <title id= {titleId}>Details</title>
    <rect
      width={13.5}
      height={13.5}
      x={0.75}
      y={0.75}
      stroke="#686868"
      strokeWidth={1.5}
      rx={1.25}
    />
    <rect
      width={13.5}
      height={13.5}
      x={4.75}
      y={4.75}
      stroke="#686868"
      strokeWidth={1.5}
      rx={1.25}
    />
  </svg>
  );
};

export default OverlapingRectangles;
